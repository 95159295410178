import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const MainCnt = styled('div')({
  padding: '30px 70px',
});

export const Title = styled('div')({
  display: 'flex',
  gap: 10,
  marginBottom: 15,
  '& svg': {
    color: '#CCCBFF',
  },
  '& p': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28.8px',
    letterSpacing: '0.15px',
    color: colors.black,
  },
});
