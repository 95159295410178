// React
import { useState, useMemo } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import SubItemsList from './SubItemsList';

// Icons
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';

// Constants
import appConstants from 'utils/appConstants';

const SubItemsListWithPopper = ({ submissionReportsId }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const currentPath = useLocation().pathname;

  const isActive = useMemo(
    () =>
      currentPath.includes('/checklist') ||
      currentPath.includes('/submission-reports') ||
      currentPath.includes('/templates-table-view'),
    [currentPath],
  );

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [110, -1],
            },
          },
        ],
        sx: {
          '& .MuiPaper-root': {
            width: 256,
            boxShadow: '0px 4px 16px 4px rgba(0,0,0,0.16)',
            padding: '20px 20px 20px 36px',
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
          },
        },
        content: (
          <SubItemsList
            submissionReportsId={submissionReportsId}
            onClickItemCallback={() => setAnchorEl(null)}
          />
        ),
      }}
      buttonRenderer={
        <SidebarItem
          title={appConstants.checklist.plural.base}
          icon={<ChecklistIcon filled={isActive} />}
          isSelected={isActive}
          sidebarOpen={false}
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
        />
      }
    />
  );
};

export default SubItemsListWithPopper;
