// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Icons
import TimelineDotIcon from 'components/Icons/timelineDotIcon';
import { statusDropdownData } from 'helper/task/dropdownData';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px 8px 16px',
  width: '100%',
  height: 'calc(100vh - 115px)',
  overflow: 'auto',
}));

export const AttachmentsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 8,
  gap: 11,
  img: {
    width: '85px',
    height: '58.91px',
    borderRadius: 4,
  },
}));

export const ActivityPerformerName = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '18.84px',
  letterSpacing: '0.1px',
  color: 'rgba(0, 0, 0, 0.87)',
  whiteSpace: 'nowrap',
  marginRight: 3,
}));

export const StatusColor = styled(Typography)(({ status }) => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '0.15px',
  marginRight: 3,
  marginLeft: 3,
  textTransform: 'uppercase',
  color: statusDropdownData.find((s) => s.id === status)?.color,
}));

export const ActivityInfoDescription = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '17.16px',
  letterSpacing: '0.17px',
  whiteSpace: 'nowrap',
  color: 'rgba(0, 0, 0, 0.87)',
  svg: {
    fontSize: 17,
    marginLeft: 8,
    marginRight: 8,
  },
}));

export const ActivityInfoText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '17.16px',
  letterSpacing: '0.17px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginRight: 8,
}));

export const TimelineItem = styled(Box)(() => ({
  position: 'relative',
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  padding: '10px 8px 10px 0px',
  width: '100%',
}));

export const TimelineDate = styled(Box)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '0.15px',
  color: 'rgba(158, 158, 158, 1)',
  marginLeft: 'auto',
}));

export const DotIcon = styled(TimelineDotIcon)(() => ({
  position: 'absolute',
  left: '-30px',
  top: '50%',
  transform: 'translate(0, -50%)',
  fill: 'none',
  fontSize: '12px',
}));
