import { Box, Stack, Typography } from '@mui/material';
import styled from '@mui/system/styled';

export const DashboardEmptyStateCnt = styled('div')({
  margin: '0 auto',
  width: '90%',
  paddingTop: 72,
  '.widgetEmptyStateTile': {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    borderRadius: 12,
    padding: 20,
    border: '1px solid #E0E0E0',
    maxWidth: 374,
  },
  '.dashboardBuilderInfo': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 20,
    margin: '40px auto',
    maxWidth: 1200,
  },
  '.tileImageCnt': {
    height: 100,
  },
});
export const EmptyStateTitle = styled('h4')({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '21.86px',
  color: '#000000',
});

export const EmptyStateDescription = styled('p')({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '22px',
  color: '#212121',
  marginBottom: 5,
});

export const MainTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 800,
  lineHeight: '27.32px',
  color: '#000000',
  marginBottom: 10,
  textAlign: 'center',
});

export const SubTitle = styled(Typography)({
  fontSize: 15,
  fontWeight: 500,
  lineHeight: '24px',
  color: '#424242',
  marginBottom: 15,
  textAlign: 'center',
});
