import { createContext } from 'react';

interface SpreadsheetContextType {
  template: any;
  showDownloadOptions: boolean;
  selectedSubmission: any | null;
  selectedRows: any[];
  columns: { id: string; description: string; type: string }[];
  rows: any[];
  columnsConfig: any;
  advancedFilters: any[];
  advancedItemFilters: any[];
  searchTerm: string;
  setShowDownloadOptions: (show: boolean) => void;
  setSelectedSubmission: (submissions: any) => void;
  setSelectedRows: (rows: any[]) => void;
  handleArchiveLogs: (logIds: string[]) => void;
  handleClickOpenLog: (logId: string) => void;
  editColumnConfig: (columns: any) => Promise<void>;
  setColumnsConfig: (columns: any) => void;
  setAdvancedFilters: (filters: any[] | undefined) => void;
  setAdvancedItemFilters: (filters: any[] | undefined) => void;
  setSearchTerm: (term: string) => void;
  loadNextPage: () => Promise<void>;
  handleDeleteAllAdvancedFilters: () => void;
  handleClickStartButton: () => void;
}

export const SpreadsheetContext = createContext<
  Partial<SpreadsheetContextType>
>({});
