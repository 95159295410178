import { Box, styled } from '@mui/material';

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(100vh - 275px)',
  height: '100%',
  width: '100%',
});

export const Date = styled('span')({
  fontSize: 14,
  fontWeight: 700,
  color: '#616161',
  lineHeight: '19.12px',
  letterSpacing: '0.4px',
  marginBottom: 8,
  marginTop: 14,
  display: 'block',
});

export const ListingCnt = styled(Box)({ height: 'calc(100vh - 275px)' });
