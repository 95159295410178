import { createSvgIcon } from '@mui/material';

const UploadImage = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <svg
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.98427 22.1826L11.6802 14.4866C12.1423 14.0246 12.3733 13.7936 12.6396 13.707C12.874 13.6309 13.1264 13.6309 13.3607 13.707C13.6271 13.7936 13.8581 14.0246 14.3201 14.4866L21.9647 22.1312M15.3335 15.5L18.6802 12.1533C19.1422 11.6912 19.3733 11.4602 19.6396 11.3737C19.874 11.2975 20.1264 11.2975 20.3607 11.3737C20.6271 11.4602 20.8581 11.6912 21.3201 12.1533L24.6668 15.5M10.6668 8.5C10.6668 9.78866 9.62216 10.8333 8.3335 10.8333C7.04483 10.8333 6.00016 9.78866 6.00016 8.5C6.00016 7.21134 7.04483 6.16667 8.3335 6.16667C9.62216 6.16667 10.6668 7.21134 10.6668 8.5ZM6.9335 22.5H19.0668C21.027 22.5 22.0071 22.5 22.7558 22.1185C23.4144 21.783 23.9498 21.2475 24.2854 20.589C24.6668 19.8403 24.6668 18.8602 24.6668 16.9V7.1C24.6668 5.13982 24.6668 4.15972 24.2854 3.41103C23.9498 2.75247 23.4144 2.21703 22.7558 1.88148C22.0071 1.5 21.027 1.5 19.0668 1.5H6.9335C4.97331 1.5 3.99322 1.5 3.24453 1.88148C2.58596 2.21703 2.05053 2.75247 1.71497 3.41103C1.3335 4.15972 1.3335 5.13982 1.3335 7.1V16.9C1.3335 18.8602 1.3335 19.8403 1.71497 20.589C2.05053 21.2475 2.58596 21.783 3.24453 22.1185C3.99322 22.5 4.97331 22.5 6.9335 22.5Z"
          stroke="#6868FE"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>,
    'UploadImage',
  );
  return <IconCmp viewBox={'0 0 28 24'} {...props} />;
};

export default UploadImage;
