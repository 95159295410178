// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomButton from 'components/Button/CustomButton';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 270px)',
  width: '100%',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 82px)',
    padding: '0px 16px',
  },

  '.useCases': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    height: '500px',
    width: 'auto',
    minHeight: '100%',
  },
}));

export const Dot = styled('span')({
  margin: '7px 5px 0px 0px',
});

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '30px auto auto 50px',
  maxWidth: 550,
  [theme.breakpoints.down(1180)]: {
    maxWidth: 'calc(100% - 450px)',
  },
  [theme.breakpoints.down('md')]: {
    margin: 'auto 0px',
    maxWidth: '100%',
    alignItems: 'center',
  },
}));

export const Heading = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 800,
  letterSpacing: '0.23px',
  color: '#5555FF',
  lineHeight: '38.5px',
  marginBottom: 0,
}));

export const SubHeading = styled(Typography)(() => ({
  fontSize: 22,
  fontWeight: 700,
  letterSpacing: '0.23px',
  color: '#000000DE',
  lineHeight: '35.2px',
  marginBottom: 15,
}));

export const Statement = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: '0.23px',
  color: '#616161',
  lineHeight: '24px',
  marginBottom: 16,
}));

export const Content = styled(Typography)(() => ({
  gap: 10,
  marginBottom: 15,
}));

export const Row = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'start',
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: '0.23px',
  color: '#424242',
  lineHeight: '36px',
  marginBottom: 5,

  '& .title': {
    fontWeight: 700,
    marginRight: 3,
  },
}));

export const GetStarted = styled(CustomButton)(({ theme }) => ({
  borderRadius: 8,
  width: 122,
  height: 42,
  padding: '8px 22px 8px 22px !important',

  fontWeight: 500,
  fontSize: 15,
  lineHeight: '26px',
  letterSpacing: '0.46px',
}));
