import {
  NameCell,
  DateCell,
  CheckboxCell,
  CostCell,
  TemperatureCell,
  TextCell,
  NumberCell,
  AttachmentsCell,
  MultipleChoiceCell,
  PassFailCell,
  DateTimeCell,
  SignatureCell,
  DropdownCell,
  DropdownMenu,
  StatusRenderer,
  InstructionCell,
  GeoStampCell,
  HeaderCell,
  LocationCell,
  DurationCell,
} from './CellRenderers/CellRenderers';
import FullWidthCell from 'pages/TemplateDashboard/Tabs/Spreadsheet/Table/CellRenderers/FullWidthCell';

export const ss_defaultColDef = {
  resizable: true,
};

const getCellRenderer = (params) => {
  const { colDef, data, template, locationsMap, flaggedCategories } = params;

  const obj = data[colDef.field];
  const columnType = obj?.type;
  if (params?.node?.data?.custom) {
    return null;
  }

  switch (columnType) {
    case 'duration':
      return <DurationCell obj={obj} />;
    case 'textField':
      return <TextCell obj={obj} />;
    case 'number':
      return <NumberCell obj={obj} />;
    case 'name':
      return (
        <NameCell
          obj={obj}
          onClickOpenCallback={() => params?.onClickOpenCallback(data?.id)}
        />
      );
    case 'status':
      return <StatusRenderer obj={obj} />;
    case 'passFail':
      return <PassFailCell obj={obj} />;
    case 'multipleChoice':
      return (
        <MultipleChoiceCell obj={obj} flaggedCategories={flaggedCategories} />
      );
    case 'cost':
      return <CostCell obj={obj} />;
    case 'temperature':
      return <TemperatureCell obj={obj} />;
    case 'date':
      return <DateCell obj={obj} />;
    case 'procedure':
      return <CheckboxCell obj={obj} />;
    case 'takePhoto':
      return <AttachmentsCell obj={obj} />;
    case 'photoAnnotate':
      return <AttachmentsCell obj={obj} />;
    case 'dateTime':
      return <DateTimeCell obj={obj} />;
    case 'signature':
      return <SignatureCell obj={obj} />;
    case 'instruction':
      return <InstructionCell obj={obj} />;
    case 'dropdown':
      return <DropdownCell obj={obj} flaggedCategories={flaggedCategories} />;
    case 'geoStamp':
      return <GeoStampCell obj={obj} />;
    case 'location':
      return <LocationCell obj={obj} locations={locationsMap} />;
    case 'header':
      return <HeaderCell obj={obj} template={template} />;
    default:
      return null;
  }
};

export const getColumns = (props) => {
  const { columnsConfig, handleClickStartButton, ...rest } = props;

  const addedColumns = columnsConfig
    ?.filter((conf) => conf.visible)
    .map((conf) => ({
      field: conf.description,
      colId: conf.id,
      suppressMenu: true,
      lockPinned: true,
      headerTooltip: conf.description,
      cellRenderer: (params) => getCellRenderer({ ...params, ...rest }),
    }));

  const columns = addedColumns ?? [];

  return [
    {
      field: '',
      colId: 'checkbox',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      pinned: 'left',
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 70,
      maxWidth: 70,
      resizable: false,
      lockPosition: true,
      headerClass: 'checkbox-header',
      cellStyle: {
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        justifyContent: 'center',
      },
      cellRenderer: (params) => {
        if (params.node.rowPinned) {
          return (
            <FullWidthCell handleClickStartButton={handleClickStartButton} />
          );
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
    },
    {
      field: 'name',
      pinned: 'left',
      suppressMovable: true,
      suppressMenu: true,
      lockPosition: 'left',
      cellRenderer: (params) => getCellRenderer({ ...params, ...rest }),
    },
    {
      field: 'submissionDate',
      pinned: 'left',
      suppressMovable: true,
      suppressMenu: true,
      lockPosition: 'left',
      cellRenderer: (params) => getCellRenderer({ ...params, ...rest }),
    },
    {
      field: 'status',
      colId: 'status',
      pinned: 'left',
      suppressMovable: true,
      suppressMenu: true,
      lockPosition: 'left',
      cellRenderer: (params) => getCellRenderer({ ...params, ...rest }),
    },
    {
      field: 'duration',
      colId: 'duration',
      suppressMenu: true,
      cellRenderer: (params) => getCellRenderer({ ...params, ...rest }),
    },
    {
      field: 'startDate',
      suppressMenu: true,
      cellRenderer: (params) => getCellRenderer({ ...params, ...rest }),
    },
    ...columns,
    {
      field: '',
      colId: 'ellipsis',
      pinned: 'right',
      suppressMovable: true,
      suppressMenu: true,
      resizable: false,
      minWidth: 40,
      maxWidth: 40,
      lockPosition: true,
      cellRenderer: (params) => {
        if (params?.node?.rowPinned) return null;
        return (
          <DropdownMenu
            logId={params?.data?.id}
            handleArchiveLogs={props?.handleArchiveLogs}
            {...props}
          />
        );
      },
    },
  ];
};
