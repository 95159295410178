import React from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Styled
import { Header, BackButton, Heading } from './header.styled';

// Icons
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const NotificationsPageHeader = () => {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <Header>
      <BackButton
        onClick={handleClickBack}
        startIcon={<ArrowBackRoundedIcon />}
      >
        Back
      </BackButton>
      <Heading>All Notifications</Heading>
      <BackButton sx={{ opacity: 0, cursor: 'auto' }} />
    </Header>
  );
};

export default NotificationsPageHeader;
