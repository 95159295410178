import { useState } from 'react';
import { Stack } from '@mui/material';
import { getVerificationLink } from 'api/workspaceApi';
import { BoldText } from './style';
import CustomButton from 'components/Button/CustomButton';
import CheckIcon from '@mui/icons-material/Check';

const limit = 100;

const ConfigureSso = ({ isDone, disabled }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleVerifyDomain = async () => {
    setLoading(true);
    const response: any = await getVerificationLink('type=sso');
    window.open(response, '_blank');
    setLoading(false);
  };

  return (
    <Stack
      border={'1px solid #E0E0E0'}
      borderRadius={'8px'}
      padding={'12px 16px'}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      gap={2}
      mb={2}
    >
      <BoldText>
        <span
          className="stepCount"
          style={{
            ...(isDone && { background: '#4CAF50', borderColor: '#4CAF50' }),
          }}
        >
          <CheckIcon />
        </span>
        Step 2: Configure SSO
      </BoldText>
      <CustomButton
        variant={'contained'}
        color={'primary'}
        onClick={handleVerifyDomain}
        disabled={disabled}
        buttonLoader={loading}
        sx={{ borderRadius: '8px' }}
      >
        Configure SSO
      </CustomButton>
    </Stack>
  );
};

export default ConfigureSso;
