// React
import { useContext, useMemo } from 'react';
import { Stack } from '@mui/material';

// Custom components
import SearchInput from 'components/Form/TextField/SearchField.cmp';
import ListGridToggle from 'components/ListGridToggle';

// Context
import { ProjectsCtx } from 'pages/Projects/context';

// Utils
import AddProjectBtn from './addProjectBtn';
import useResponsive from 'utils/CustomHooks/useResponsive';

// Styles
import { HeaderCnt, ToggleContainer } from './header.style';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

interface ProjectsHeaderPropTypes {
  createProjectCallback?: () => void;
}

function ManageProjectsHeader({
  createProjectCallback,
}: ProjectsHeaderPropTypes) {
  const { isMobileDeviceSm } = useResponsive();
  const { searchTerm, handleChangeSearch, selectedView, setSelectedView } =
    useContext(ProjectsCtx);

  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const canCreateProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_CREATE_PROJECT);
  }, [PERMISSIONS]);

  return (
    <HeaderCnt>
      <div className={'lefCnt'}>
        <SearchInput
          fieldProps={{
            value: searchTerm,
            onChange: (e) => handleChangeSearch(e),
          }}
          sx={{ maxWidth: { sm: 'calc(100vw - 140px)', md: 250 } }}
        />
      </div>
      <Stack alignItems="center" gap="10px" flexDirection={'row'}>
        {/* {!isMobileDeviceSm && (
          <ToggleContainer>
            <ListGridToggle
              selectedView={selectedView}
              setSelectedView={setSelectedView}
            />
          </ToggleContainer>
        )} */}

        {canCreateProject && (
          <AddProjectBtn createProjectCallback={createProjectCallback} />
        )}
      </Stack>
    </HeaderCnt>
  );
}

export default ManageProjectsHeader;
