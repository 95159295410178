import { useDispatch, useSelector } from 'react-redux';
import LinesEllipsis from 'react-lines-ellipsis';
import { Box, Grid, Stack, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import LocationIcon from 'components/Icons/locationIcon';
import CustomButton from 'components/Button/CustomButton';
import AssetsIcon from 'components/Icons/assetsIcon';
import ScheduledIcon from 'components/Icons/sidebarIcons/scheduledIcon';
import EyeballIcon2 from 'components/Icons/eyeballIcon2';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { TALK_TO_SALES_URL } from 'utils/constants';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import BookMeetingIcon from 'public/assets/img/ImgBookCall.svg';
import { trackingEventsOnWorkspace } from 'api/workspaceApi';
import { ActionBox, Container, Footer, Title } from './style';

const MaintenanceChecklist = (props) => {
  const { onboardingStatus, getOnboardingChecklistDetails } = props;
  const user: any = useSelector(selectors.getUserProfile);
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const createLocationHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createLocation',
        data: {
          onSuccessCallback: (res) => {
            if (res) {
              getOnboardingChecklistDetails();
            }
          },
        },
      }),
    );
  };

  const createAssetHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createAsset',
        data: {
          onAssetCreateCb: (res) => {
            if (res) {
              getOnboardingChecklistDetails();
            }
          },
        },
      }),
    );
  };

  const scheduleAssetHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          onSuccessCallBack: (res) => {
            if (res) {
              getOnboardingChecklistDetails();
            }
          },
        },
      }),
    );
  };

  const viewScheduleHandler = () => {
    trackingEventsOnWorkspace({
      onboarding: {
        viewed: true,
      },
    });
    navigateWithWorkspaceUrl('/scheduled-work');
  };

  const bookCall = () => {
    dispatch(
      setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <Container>
      <Title>Get Started Checklist</Title>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <ActionBox isCompleted={onboardingStatus?.location}>
            {onboardingStatus?.location ? (
              <Box className="checkIconCnt">
                <CheckCircleOutlineOutlinedIcon />
              </Box>
            ) : (
              <Box className="iconCnt">
                <LocationIcon />
              </Box>
            )}

            <Typography className="title">Add Location</Typography>
            <Typography className="description">
              Add a location that you can use to locate assets.
            </Typography>

            {!onboardingStatus?.location && (
              <CustomButton
                variant="text"
                className="actionBtn"
                endIcon={<EastIcon />}
                onClick={createLocationHandler}
              >
                Create Location
              </CustomButton>
            )}
          </ActionBox>
        </Grid>

        <Grid item xs={3}>
          <ActionBox isCompleted={onboardingStatus?.asset}>
            {onboardingStatus?.asset ? (
              <Box className="checkIconCnt">
                <CheckCircleOutlineOutlinedIcon />
              </Box>
            ) : (
              <Box className="iconCnt">
                <AssetsIcon />
              </Box>
            )}
            <Typography className="title">Add Asset</Typography>
            <Typography className="description">
              Add an asset to the location you added.
            </Typography>

            {!onboardingStatus?.asset && (
              <CustomButton
                variant="text"
                className="actionBtn"
                endIcon={<EastIcon />}
                onClick={createAssetHandler}
              >
                Create Asset
              </CustomButton>
            )}
          </ActionBox>
        </Grid>

        <Grid item xs={3}>
          <ActionBox isCompleted={onboardingStatus?.task}>
            {onboardingStatus?.task ? (
              <Box className="checkIconCnt">
                <CheckCircleOutlineOutlinedIcon />
              </Box>
            ) : (
              <Box className="iconCnt">
                <ScheduledIcon />
              </Box>
            )}

            <Typography className="title">
              Schedule Asset for Maintenance
            </Typography>

            <LinesEllipsis
              className="description"
              text="Ensure zero downtime by scheduling the asset for maintenance."
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />

            {!onboardingStatus?.task && (
              <CustomButton
                variant="text"
                className="actionBtn"
                endIcon={<EastIcon />}
                onClick={scheduleAssetHandler}
              >
                Schedule Asset
              </CustomButton>
            )}
          </ActionBox>
        </Grid>

        <Grid item xs={3}>
          <ActionBox isCompleted={onboardingStatus?.viewed}>
            {onboardingStatus?.viewed ? (
              <Box className="checkIconCnt">
                <CheckCircleOutlineOutlinedIcon />
              </Box>
            ) : (
              <Box className="iconCnt">
                <EyeballIcon2
                  sx={{
                    fill: 'none',
                    '& path': {
                      stroke: 'black',
                      opacity: 0.7,
                    },
                  }}
                />
              </Box>
            )}

            <Typography className="title">View Maintenance Schedule</Typography>

            <LinesEllipsis
              className="description"
              text="View your schedule to see which assets are scheduled for
              maintenance and when."
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />

            {!onboardingStatus?.viewed && (
              <CustomButton
                variant="text"
                className="actionBtn"
                endIcon={<EastIcon />}
                onClick={viewScheduleHandler}
              >
                View Schedule
              </CustomButton>
            )}
          </ActionBox>
        </Grid>
      </Grid>

      <Footer
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" gap="10px" alignItems="center">
          <img src={BookMeetingIcon} />
          <Stack gap="4px">
            <Typography className="main">
              Discover how businesses{' '}
              {user.Hotel?.Industry?.name && (
                <span>
                  within the{' '}
                  <span className="industry">{user.Hotel?.Industry?.name}</span>{' '}
                  industry{' '}
                </span>
              )}
              leverage Xenia for their operations
            </Typography>

            <Typography className="sub">
              Book a complimentary call with our product specialist to explore
              how you can attain excellence in achieving your company goals.
            </Typography>
          </Stack>
        </Stack>

        <CustomButton
          variant="contained"
          className="bookBtn"
          onClick={bookCall}
        >
          Book a Free Call
        </CustomButton>
      </Footer>
    </Container>
  );
};

export default MaintenanceChecklist;
