// React
import { useContext } from 'react';

// Custom components
import TemplatePreview from 'components/TemplatePreview';
import SubmissionReport from 'components/TemplateDashboard/SubmissionReport';
import TemplateActivity from 'components/TemplateDashboard/TemplateActivity';

// Styled
import {
  PreviewTabWrapper,
  TemplatePreviewContainer,
  WidgetContainer,
} from './styled';

// Context
import { DashboardContext } from 'pages/TemplateDashboard/context';

const Preview = () => {
  const ctx = useContext(DashboardContext);

  const handleClickViewAll = () => {
    ctx?.setActiveTab(1);
  };

  return (
    <PreviewTabWrapper>
      <TemplatePreviewContainer>
        {!!ctx?.template && <TemplatePreview template={ctx.template} />}
      </TemplatePreviewContainer>
      {!!ctx?.templateId && (
        <WidgetContainer>
          <TemplateActivity
            templateTitle={ctx?.template?.name || ''}
            shouldUpdate={ctx?.shouldUpdate}
            templateId={ctx.templateId}
          />
          <SubmissionReport
            shouldUpdate={ctx?.shouldUpdate}
            templateId={ctx.templateId}
            handleClickViewAll={handleClickViewAll}
          />
        </WidgetContainer>
      )}
    </PreviewTabWrapper>
  );
};

export default Preview;
