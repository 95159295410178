import { createSvgIcon } from '@mui/material';

const LoginIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M8 6L11 9M11 9L8 12M11 9H1.25M1.50337 5.25C2.80015 3.00824 5.22394 1.5 8 1.5C12.1421 1.5 15.5 4.85786 15.5 9C15.5 13.1421 12.1421 16.5 8 16.5C5.22394 16.5 2.80015 14.9918 1.50337 12.75"
        stroke="#007DFF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LoginIcon',
  );
  return (
    <IconCmp
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      {...props}
    />
  );
};
export default LoginIcon;
