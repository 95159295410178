import FolderItem from 'pages/Templates/Folders/foldersItem/foldersItem';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { EmptyCnt, FoldersCnt } from 'pages/Templates/Folders/folders.style';
import FoldersListView from 'pages/Templates/FoldersListView/list';
import { useContext, useMemo } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useParams } from 'react-router-dom';

function Folders() {
  const { state = {}, updateState, isArchive } = useContext(MyTemplatesContext);
  const { data, searchTerm } = state;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const params = useParams();
  const updateFolder = (folder) => {
    const folders = data.map((f) => {
      if (f.id === folder.id) {
        return folder;
      }
      return f;
    });
    updateState({ data: folders });
  };
  const deleteFolder = (folderId) => {
    const folders = data.filter((f) => f.id !== folderId);
    updateState({ data: folders });
  };

  const actionDropdownProps = {
    updateFolderCallback: updateFolder,
    deleteFolderCallback: deleteFolder,
  };
  const handleFolderSelect = (folder) => {
    navigateWithWorkspaceUrl(
      params?.['*']
        ? `/checklist/folder/${params?.['*'] + '/' + folder?.id}`
        : `/checklist/folder/${folder?.id}`,
    );
  };

  return (
    <FoldersCnt>
      {/*<Typography variant={'h1'} className={'foldersViewHeading'}>*/}
      {/*  Folders{' '}*/}
      {/*</Typography>*/}

      {state?.selectedView === 'list' ? (
        <FoldersListView data={data} />
      ) : data?.length === 0 ? (
        <EmptyCnt>
          <NoRecordsFound />
        </EmptyCnt>
      ) : (
        <Grid container spacing={2}>
          {data?.map((f) => {
            return (
              <FolderItem
                folder={f}
                key={f?.id}
                actionDropdownProps={actionDropdownProps}
                isArchive={isArchive}
                onClick={handleFolderSelect}
              />
            );
          })}
        </Grid>
      )}
    </FoldersCnt>
  );
}

export default Folders;
