import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Stack } from '@mui/material';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';

import { AssetsList } from 'pages/Assets/AssetsList/assetsList.view';
import { useUserConfig } from 'controller/useUserConfig';

import { setDialog } from 'store/actions/dialogActions';
import { getAssetsState, getAuthAssetsState } from 'store/selectors/assets';
import useResponsive from 'utils/CustomHooks/useResponsive';

import AssetsHeader from '../AssetsHeader/assetsHeader';
import { AssetsGrid } from '../AssetGrid/assetsGrid.view';
import AssetsMobileHeader from '../AssetsHeader/assetsMobileHeader';

import { isMobileApp } from 'utils/constants';
import { postToMobileApp } from 'utils/globalFunction';
import { filter, includes, map } from 'lodash';

function AssetsMainView() {
  const dispatch = useDispatch();
  const [selectedView, setSelectedView] = useState<ViewType | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [configKey, setConfigKey] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const assetsListOriginal = useSelector(getAssetsState) ?? [];
  const authAssetsList = useSelector(getAuthAssetsState) ?? [];
  const [locationsFilter, setLocationsFilter] = useState<any>(null);

  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const { isMobileDeviceSm } = useResponsive();

  const assetsList = useMemo(() => {
    const authIds = map(authAssetsList, 'id');

    // Filter assets whose ids are in authIds
    return filter(assetsListOriginal, (asset) => includes(authIds, asset.id));
  }, [assetsListOriginal, authAssetsList]);

  const setAssetsViewType = async () => {
    setIsLoading(true);
    const userConfig = await getUserConfig(configKey);
    if (userConfig?.assetsViewType) {
      setSelectedView(userConfig.assetsViewType);
    } else {
      setSelectedView('list');
    }
    setIsLoading(false);
  };

  const setToggleStateConfig = async (value: ViewType) => {
    const prevView = selectedView;
    setSelectedView(value);
    const config = await editUserConfig(configKey, {
      assetsViewType: value,
    });
    if (!config) {
      setSelectedView(prevView);
    }
  };

  const handleChangeLocation = (locations: any[]) => {
    if (locations?.length) {
      setLocationsFilter(locations);
    } else {
      setLocationsFilter(null);
    }
  };

  const handleClearLocationFilter = () => {
    setLocationsFilter(null);
  };

  const onClickAdd = () => {
    if (isMobileApp) {
      postToMobileApp('navigateTo', 'CreateAsset');
    } else {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createAsset',
        }),
      );
    }
  };

  useEffect(() => {
    if (isMobileDeviceSm) {
      setSelectedView('grid');
    }
  }, [isMobileDeviceSm]);

  useEffect(() => {
    if (configKey) {
      setAssetsViewType();
    }
  }, [configKey]);

  useEffect(() => {
    if (userId) {
      setConfigKey(`assetsHomePageViewType::${userId}`);
    }
  }, [userId]);

  const filteredList = () => {
    return assetsList.filter((asset) => {
      return (
        locationsFilter?.some(
          (location) => location?.id === asset?.Location?.id,
        ) ||
        locationsFilter?.some((location) =>
          asset?.SubAssets?.some(
            (subAsset) => subAsset?.Location?.id === location?.id,
          ),
        )
      );
    });
  };

  const formattedAssetList = useMemo(() => {
    const filteredAssetsList =
      locationsFilter !== null ? filteredList() : assetsList;
    const reducedAssets = filteredAssetsList?.reduce((r, cv) => {
      r?.push({ ...cv, path: [cv?.id] });
      const subAssetsWithPath = cv?.SubAssets?.map((s) => ({
        ...s,
        path: [cv?.id, s?.id],
      }));
      r = subAssetsWithPath ? [...r, ...subAssetsWithPath] : r;
      return r;
    }, []);

    if (selectedView === 'grid' && searchTerm) {
      return reducedAssets.filter((asset) =>
        asset?.name?.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }
    return reducedAssets;
  }, [locationsFilter, searchTerm, assetsList, selectedView]);

  const isEmptyData =
    !searchTerm && !locationsFilter && formattedAssetList?.length === 0;

  return (
    <>
      {isLoading && (
        <Stack width="100%" pt="70px" alignItems={'center'}>
          <CircularProgress />
        </Stack>
      )}

      {!isLoading && !isMobileDeviceSm && selectedView && (
        <AssetsHeader
          selectedView={selectedView}
          setSelectedView={setToggleStateConfig}
          onSearch={(value) => setSearchTerm(value)}
          onClickAdd={onClickAdd}
          handleChangeLocation={handleChangeLocation}
          handleClearLocationFilter={handleClearLocationFilter}
        />
      )}
      {!isLoading && !isEmptyData && isMobileDeviceSm && (
        <AssetsMobileHeader
          onSearch={(value) => setSearchTerm(value)}
          handleChangeLocation={handleChangeLocation}
          locationsFilter={locationsFilter}
        />
      )}

      {!isLoading && selectedView === 'list' && (
        <AssetsList
          setSelectedView={setSelectedView}
          assetsList={formattedAssetList}
          isEmptyData={isEmptyData}
          locationsFilter={locationsFilter}
        />
      )}

      {!isLoading && selectedView === 'grid' && (
        <AssetsGrid
          assetsList={formattedAssetList}
          isLoading={isLoading}
          isEmptyData={isEmptyData}
          locationsFilter={locationsFilter}
        />
      )}
    </>
  );
}

export default AssetsMainView;
