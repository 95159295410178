import { createSvgIcon } from '@mui/material';
import React from 'react';

const UsersUsageIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="34" height="34" rx="8" fill="#4FC3F7" />
      <path
        opacity="0.12"
        d="M14.9168 16.1667C16.7578 16.1667 18.2502 14.6743 18.2502 12.8333C18.2502 10.9924 16.7578 9.5 14.9168 9.5C13.0759 9.5 11.5835 10.9924 11.5835 12.8333C11.5835 14.6743 13.0759 16.1667 14.9168 16.1667Z"
        fill="#0277BD"
      />
      <path
        d="M14.9165 16.1667C16.7575 16.1667 18.2498 14.6743 18.2498 12.8333C18.2498 10.9924 16.7575 9.5 14.9165 9.5C13.0756 9.5 11.5832 10.9924 11.5832 12.8333C11.5832 14.6743 13.0756 16.1667 14.9165 16.1667Z"
        fill="#E3F2FD"
      />
      <path
        d="M25.3332 24.5V22.8333C25.3332 21.2801 24.2709 19.9751 22.8332 19.605M19.9165 9.7423C21.1381 10.2368 21.9998 11.4344 21.9998 12.8333C21.9998 14.2322 21.1381 15.4299 19.9165 15.9244M21.1665 24.5C21.1665 22.9469 21.1665 22.1703 20.9128 21.5577C20.5745 20.741 19.9255 20.092 19.1088 19.7537C18.4962 19.5 17.7196 19.5 16.1665 19.5H13.6665C12.1134 19.5 11.3368 19.5 10.7242 19.7537C9.90747 20.092 9.25855 20.741 8.92024 21.5577C8.6665 22.1703 8.6665 22.9469 8.6665 24.5M18.2498 12.8333C18.2498 14.6743 16.7575 16.1667 14.9165 16.1667C13.0756 16.1667 11.5832 14.6743 11.5832 12.8333C11.5832 10.9924 13.0756 9.5 14.9165 9.5C16.7575 9.5 18.2498 10.9924 18.2498 12.8333Z"
        stroke="#0277BD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'UsersUsageIcon',
  );
  return <IconCmp viewBox={'0 0 34 34'} fill="none" {...props} />;
};

export default UsersUsageIcon;
