import Typography from '@mui/material/Typography';
import { FolderItemCnt } from 'pages/documents/listing/gridView/folder/style';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';

import { Grid } from '@mui/material';
import ArchiveFolderActionDropdown from 'pages/documents/actionDropdowns/folderActionDropdown/archiveFolderActionDropdown';
import FolderActionDropdown from 'pages/documents/actionDropdowns/folderActionDropdown/folderActionDropdown';

function FolderItem(props) {
  const {
    folder,
    folderDropdownProps,
    archivedFoldersDropdownProps,
    isArchive,
    onClick,
  } = props;

  return (
    <Grid item xs={12} sm={6} lg={3} onClick={() => onClick(folder)}>
      <FolderItemCnt>
        <TemplateFolderIcon
          primaryColor={folder?.meta?.primaryColor}
          secondaryColor={folder?.meta?.secondaryColor}
          sx={{ fontSize: 34 }}
        />
        <div className={'folderInfoCnt'}>
          <Typography variant={'h4'} className={'folderName'}>
            {folder?.title}
          </Typography>
          <Typography variant={'subtitle2'} className={'documentCount'}>
            {folder?.itemsCount || 0} items
          </Typography>
        </div>
        {isArchive ? (
          <ArchiveFolderActionDropdown
            folder={folder}
            {...archivedFoldersDropdownProps}
          />
        ) : (
          <FolderActionDropdown folder={folder} {...folderDropdownProps} />
        )}
      </FolderItemCnt>
    </Grid>
  );
}

export default FolderItem;
