import {
  EmptyStateCnt,
  EmptyStateHeading,
  EmptyStateSubText,
} from './taskListWidgetEmptyState.style';
import React from 'react';
import OverdueEmptyStateImage from 'public/assets/img/OverdueEmptyStateImage.svg';

const OverDueEmptyState = () => {
  return (
    <EmptyStateCnt>
      <img
        alt="overdueEmptyState"
        src={OverdueEmptyStateImage}
        style={{ width: 150, height: 150 }}
      />
      <EmptyStateHeading variant={'h3'}>
        Hooray, nothing overdue!
      </EmptyStateHeading>
      <EmptyStateSubText style={{ width: 368 }}>
        Great work! You have no overdue tasks. All your assignments are right on
        schedule.
      </EmptyStateSubText>
    </EmptyStateCnt>
  );
};

export default OverDueEmptyState;
