import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const WidgetCnt = styled(
  'div',
  {},
)(({ theme }) => ({
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  border: '1px solid #EEEEEE',
  display: 'flex',
  padding: '18px 24px',
  flexDirection: 'column',
  borderRadius: 12,
  height: '100%',
  overflowX: 'scroll',
}));
export const TileInnerCnt = styled(
  'div',
  {},
)(({ theme }) => ({
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  border: '1px solid #EEEEEE',
  display: 'flex',
  padding: '18px 24px',
  flexDirection: 'column',
  borderRadius: 12,
  '.statusText': {
    fontWeight: 700,
    fontSize: 15,
    margin: 0,
  },
  '.countsText': {
    fontWeight: 800,
    fontSize: 32,
    lineHeight: '44px',
    margin: 0,
    color: '#212121',
  },
}));
