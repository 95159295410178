import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import AvatarPopover from 'components/AvatarPopover';
import {
  Content,
  FiltersCnt,
  Header,
  MainCnt,
  TemplateRow,
  TeamRow,
  UserStatusChip,
} from './style';
import { colors } from 'components/theme/constants';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import classNames from 'classnames';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import SendIcon from 'components/Icons/sendIcon';
import CustomAvatarGroup from 'components/Avatar/AvatarGroup/AvatarGroup.cmp';
import UserAvatar from 'components/Avatar';
import DropdownIcon from 'components/Icons/dropdownIcon';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { find } from 'lodash';
import { userStatusColors } from 'helper/user/userStatus';
import { TeamEmptyState, UsersEmptyState } from './emptyStates';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const Filters: FC<any> = (props: any) => {
  const { teamTab, setTeamTab } = props;
  return (
    <FiltersCnt>
      <ToggleButtonGroup
        color="primary"
        value={teamTab}
        exclusive
        onChange={(e: any) => {
          setTeamTab(e?.target?.value);
        }}
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
      >
        <ToggleButton
          value="people"
          className={classNames({ selected: teamTab === 'people' })}
          style={{ marginRight: 7 }}
        >
          People
        </ToggleButton>
        <ToggleButton
          value="team"
          className={classNames({ selected: teamTab === 'team' })}
        >
          Teams
        </ToggleButton>
      </ToggleButtonGroup>
    </FiltersCnt>
  );
};

const UsersReport: FC = () => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const userProfile: any = useSelector(selectors.getUserProfile);

  const [teamTab, setTeamTab] = useState('people');
  const [openList, setOpenList] = useState(null);

  const usersList = useSelector(selectors.getUserAdmin);
  const hotelId = useSelector(selectors.getUserHotelId);

  const teams = useSelector(selectors.getTeams);

  const filteredUsersList = useMemo(() => {
    return usersList?.filter((user) => user.id !== userProfile?.id);
  }, [usersList]);

  const getAvailabilityStatus = (UserAvailabilityStatuses = []) => {
    let availabilityStatus: any = find(UserAvailabilityStatuses, {
      HotelId: hotelId,
    });
    availabilityStatus = availabilityStatus || { currentStatus: 'offline' };
    return availabilityStatus;
  };

  // User Status Chip component
  const StatusChip = ({ user }) => {
    const availabilityStatus = getAvailabilityStatus(
      user.UserAvailabilityStatuses,
    );
    return (
      <UserStatusChip
        style={{
          background:
            userStatusColors?.[availabilityStatus?.currentStatus]?.background,
        }}
      >
        {availabilityStatus?.currentStatus}
      </UserStatusChip>
    );
  };

  const usersRowRenderer = (row) => {
    return (
      <TemplateRow>
        <div className="firstColumn">
          <AvatarPopover
            userId={row?.id}
            nameStyle={{
              color: colors.black,
              fontWeight: 500,
              fontSize: 14,
            }}
          />
        </div>
        <div className="secondColumn">
          <div className="members">
            <StatusChip user={row} />
          </div>
        </div>
      </TemplateRow>
    );
  };

  const manageSubList = (id) => {
    openList === id ? setOpenList(null) : setOpenList(id);
  };

  const teamRowRenderer = (row: any) => {
    return (
      <TeamRow>
        <ListItemButton
          onClick={() => manageSubList(row.id)}
          onMouseDown={stopPropagation}
          onTouchStart={stopPropagation}
          sx={{ pt: 0, pb: 0 }}
          className="listItem"
        >
          <ListItemIcon className="iconBlock">
            <DropdownIcon
              color="#757575"
              style={{
                width: 12,
                height: 8,
                marginRight: 5,
                transform: openList === row.id ? 'rotate(180deg)' : '',
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <div className="content">
                <TeamAvatar teamId={row.id} width={26} height={26} />
                <CTypography className="mainLabel">{row.name}</CTypography>
                <CTypography className="usersCount">
                  {row.Members.length} Users
                </CTypography>
              </div>
            }
          />
          <div className="secondColumn">
            <div className="members">
              <CustomAvatarGroup
                members={row.Members.map((member) => member?.MemberId)}
              />
            </div>
          </div>
        </ListItemButton>
        <Collapse
          in={openList === row.id}
          timeout="auto"
          unmountOnExit
          className="subList"
        >
          <List component="div" disablePadding>
            {row?.Members?.map((member) => {
              const userData: any = usersList?.find(
                (item) => item.id === member?.MemberId,
              );
              return userData ? (
                <ListItemButton
                  key={member?.MemberId}
                  sx={{
                    pl: 6,
                    pt: 0,
                    pb: 0,
                    mh: 30,
                    display: 'flex',
                    justifyContent: 'spaceBetween',
                    backgroundColor: 'white !important',
                  }}
                >
                  <ListItemIcon className="iconBlock">
                    <AvatarPopover
                      userId={userData?.id}
                      nameStyle={{
                        color: colors.black,
                        fontWeight: 500,
                        fontSize: 14,
                      }}
                    />
                  </ListItemIcon>
                  <div className="secondColumn">
                    <div className="members">
                      <StatusChip user={userData} />
                    </div>
                  </div>
                </ListItemButton>
              ) : null;
            })}
          </List>
        </Collapse>
      </TeamRow>
    );
  };

  const content = teamTab === 'people' ? filteredUsersList : teams;

  return (
    <div key="usersReport" style={{ height: '100%' }}>
      {content?.length === 0 && teamTab === 'people' ? (
        <UsersEmptyState />
      ) : (
        <MainCnt>
          <Header>
            <CTypography className="title">My Team</CTypography>

            <CustomButton
              variant="contained"
              startIcon={<SendIcon primary={'#FFFFFF'} />}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
              onClick={() => {
                {
                  teamTab === 'people'
                    ? dispatch(
                        setDialog({
                          dialogId: 'inviteUserDialogV2',
                          open: true,
                        }),
                      )
                    : navigateWithWorkspaceUrl('/settings/teams');
                }
              }}
            >
              {teamTab === 'people' ? 'Invite more users' : 'Go To Teams'}
            </CustomButton>
          </Header>
          <hr />

          <Filters teamTab={teamTab} setTeamTab={setTeamTab} />

          {/* Rows Content */}
          <Content>
            <List>
              {content?.map((row: any) =>
                teamTab === 'people'
                  ? usersRowRenderer(row)
                  : teamRowRenderer(row),
              )}

              {content?.length === 0 && teamTab === 'team' && (
                <TeamEmptyState />
              )}
            </List>
          </Content>
        </MainCnt>
      )}
    </div>
  );
};

export default UsersReport;
