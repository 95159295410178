// MUI components
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const EmptyStateWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 'calc(100vh - 238px)',
  // height: '100%',
  flexDirection: 'column',
}));

export const CenterColumn = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 8,
  maxWidth: '300px',
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  letterSpacing: '0.15px',
  color: '#212121',
}));

export const GreyText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0.17px',
  color: '#616161',
  textAlign: 'center',
}));
