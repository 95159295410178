// React
import { useEffect, useState, useMemo } from 'react';

// React Router
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

// Custom components
import CTabs from 'components/CTabs';
import LocationDetailHeader from './LocationDetailHeader/locationDetailHeader';
import LocationDetailTab from './LocationDetailTab/LocationDetailTab';
import LocationHistory from './LocationHistory/LocationHistory.view';
import LocationMembers from './LocationMembers/LocationMembers.view';
import LocationSchedules from './LocationSchedules/LocationSchedules.view';
import LocationAssets from './LocationAssets/LocationAssets';
import LocationDetailFooter from './common/LocationDetailFooter';
import LocationTemplateSubmissions from './LocationTemplateSubmissions/LocationTemplateSubmissions.view';

import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';

function LocationDetailView() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { config } = useWorkspaceConfig();

  const [activeTab, setActiveTab] = useState(0);
  const [fetchSchedulesCounter, setFetchSchedulesCounter] = useState(0);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const tabNumber = Number(tab);
      if (tabNumber >= 0 && tabNumber <= 5) {
        setActiveTab(tabNumber);
      }
    }
  }, [searchParams]);

  const tabs = [
    {
      label: 'Details',
      content: <LocationDetailTab />,
    },
    {
      label: 'Members',
      content: <LocationMembers />,
    },
    {
      label: 'Task History',
      content: <LocationHistory />,
    },
    {
      label: 'Schedules',
      content: (
        <LocationSchedules fetchSchedulesCounter={fetchSchedulesCounter} />
      ),
    },
    {
      label: 'Template Submissions',
      content: <LocationTemplateSubmissions />,
    },
    {
      label: 'Assets',
      content: <LocationAssets />,
    },
  ];

  const onTabChange = (value: number) => {
    setActiveTab(value);
    navigate(`${location.pathname}?tab=${value}`);
  };

  const dynamicTabs = useMemo(() => {
    if (!config?.advancedLocationBasedAssignment) {
      return tabs.filter((tab) => tab.label !== 'Members');
    }
    return tabs;
  }, [config, searchParams, activeTab, fetchSchedulesCounter]);

  const onAssignWorkCb = () => {
    setFetchSchedulesCounter(fetchSchedulesCounter + 1);
  };

  return (
    <>
      <LocationDetailHeader onAssignWorkCb={onAssignWorkCb} />
      <CTabs callBack={onTabChange} activeTab={activeTab} data={dynamicTabs} />
      <LocationDetailFooter />
    </>
  );
}

export default LocationDetailView;
