/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addMemberToStreamChannel,
  getStreamMembers,
} from 'services/getStreamServices';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';

export const useAddNewMember = (channel): any => {
  const dispatch = useDispatch();
  const handleAddMemberSubmit = async (membersList) => {
    if (membersList.length > 0 && channel !== null) {
      const existMember = await getStreamMembers({
        sortBy: 'created_at',
        sortOrder: -1,
        channel: channel,
      });
      const existMemberUserId = existMember.members?.map((s) => {
        return s?.user_id;
      });
      const newMembers = membersList.filter(
        (x) => !existMemberUserId.includes(x),
      );
      if (newMembers?.length > 0) {
        const newupdateChannel = await addMemberToStreamChannel(
          channel,
          newMembers,
        );

        dispatch(actions.setIsRefreshing(true));
        return newupdateChannel;
      }
    }
  };

  return {
    handleAddMemberSubmit,
  };
};
