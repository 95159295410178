// React
import { useMemo, useState, useEffect } from 'react';

// Custom components
import WidgetLayout from 'components/TemplateDashboard/WidgetLayout';
import UserAvatar from 'components/Avatar';
import EmptyState from 'components/TemplateDashboard/EmptyState';
import Timeline from 'components/Timeline';
import DateRangeDropdown from '../DateRangeDropdown/DateRangeDropdown';
import CustomToolTip from 'components/Tooltip/tooltip';

// Styled
import {
  TimelinesWrapper,
  ActivityLineWrapper,
  Text,
  AnonymousAvatar,
} from './styled';

// Utils
import moment, { Moment } from 'moment-timezone';
import { groupBy } from 'lodash';
import xeniaApi from 'api/index';

// Icons
import GlobeIcon from 'components/Icons/globeIcon';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

// Context
import { ActivtyLogType } from 'pages/TemplateDashboard/context';

const TemplateActivity = ({
  templateId,
  shouldUpdate,
  templateTitle = '',
}: {
  templateId: string;
  templateTitle: string;
  shouldUpdate?: number;
}) => {
  const [activityLog, setActivityLog] = useState<ActivtyLogType[] | undefined>(
    undefined,
  );
  const { getDateInTz, getUtcToTzTime } = useDateTime();

  useEffect(() => {
    handleFilterActivityLogs();
  }, [shouldUpdate]);

  // filter activity logs by date
  const handleFilterActivityLogs = async (dateFilters?: {
    fromDate: string;
    toDate: string;
  }) => {
    const activity = await xeniaApi.getTemplateActivityLog(
      templateId as string,
      dateFilters ?? {},
    );
    if (activity?.data) setActivityLog(activity.data);
  };

  const handleDateChange = (range: {
    from: Moment | null;
    to: Moment | null;
  }) => {
    if (range.from && range.to) {
      handleFilterActivityLogs({
        fromDate: range.from.toString(),
        toDate: range.to.toString(),
      });
    } else {
      if (!range.from && !range.to) {
        handleFilterActivityLogs();
      }
    }
  };

  const formatLogString = (logData: ActivtyLogType) => {
    const fullName = logData.submitterName
      ? logData.submitterName
      : logData.User
      ? logData.User.fullName
      : 'Anonymous';
    const templateName = logData?.Template?.name || templateTitle;

    switch (logData.event) {
      case 'CREATE':
        return `${fullName} created the "${templateName}" template`;
      case 'EDIT':
        return `${fullName} edited the "${templateName}" template`;
      case 'START':
        return `${fullName} started the "${templateName}" template`;
      case 'SUBMIT':
        return `${fullName} submitted the "${templateName}" template`;
      default:
        return ``;
    }
  };

  const groupDataHelper = (logData: ActivtyLogType[]) => {
    const formattedDates = logData.map((log) => ({
      ...log,
      createdAtDate: moment(log.createdAt).format('MMM DD YYYY'),
      logString: formatLogString(log),
    }));
    const grouped = groupBy(formattedDates, 'createdAtDate');
    return grouped;
  };

  const groupedByDateActivity = useMemo(() => {
    if (activityLog && activityLog?.length) {
      return groupDataHelper(activityLog);
    }
    return null;
  }, [activityLog]);

  return (
    <WidgetLayout
      widgetHeading="Activity Log"
      actionButtons={<DateRangeDropdown handleDateChange={handleDateChange} />}
    >
      {!groupedByDateActivity ? (
        <EmptyState type="activity" />
      ) : (
        <TimelinesWrapper>
          {Object.keys(groupedByDateActivity).map((date) => (
            <Timeline
              timeline={{
                date,
                data: groupedByDateActivity[date].map((log) => ({
                  customRenderer: (
                    <ActivityLineWrapper>
                      {!log.User ? (
                        <CustomToolTip title="Submitted via Public Link">
                          <AnonymousAvatar>
                            <GlobeIcon
                              style={{ color: '#fff', fontSize: '18px' }}
                            />
                          </AnonymousAvatar>
                        </CustomToolTip>
                      ) : (
                        <UserAvatar
                          width={28}
                          height={28}
                          userId={log.User?.id}
                          firstName={
                            log.User ? log.User.firstName : 'Anonymous'
                          }
                        />
                      )}

                      <Text>{log.logString}</Text>
                    </ActivityLineWrapper>
                  ),
                  time: getUtcToTzTime(log.createdAt),
                })),
              }}
            />
          ))}
        </TimelinesWrapper>
      )}
    </WidgetLayout>
  );
};

export default TemplateActivity;
