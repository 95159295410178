import config from 'config/index';
import {
  CreateTaskFormDataModel,
  EditApiRequestType,
  ListBodyParam,
  TaskArchivePostParam,
  TaskStatusPostParam,
  TaskCommentFormDataModel,
} from 'models/index.m';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import {
  callApi,
  getItem,
  getHotelId,
  prepareFileFormData,
  showMessageNotification,
} from 'utils/globalFunction';
import { map } from 'lodash';

/*******************
  @Purpose : Used for create task api call
  @Parameter :
  @Author : INIC
  ******************/

export const getAllColumns = async () => {
  try {
    const columns = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.getTaskColumns,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return columns;
  } catch (res) {
    return res;
  }
};

export const updateTaskColumns = async (data) => {
  const postData = { config: data };
  try {
    const columns = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.updateTaskColumns,
      postData,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return columns;
  } catch (res) {
    return res;
  }
};
export const CreateTaskApi = async (
  postParam: CreateTaskFormDataModel,
  isBulk: boolean,
): Promise<any> => {
  try {
    const createTask = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.createTask,
      isBulk
        ? postParam
        : {
            ...postParam,
            HotelId: getHotelId() as string,
          },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      'task-created',
    );
    return createTask?.data?.data;
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for task comment add  api call
  @Parameter :
  @Author : INIC
  ******************/
export const TaskCommentAddApi = async (
  postParam: TaskCommentFormDataModel,
): Promise<any> => {
  try {
    const TaskCommentAdd = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.TaskCommentAdd,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (TaskCommentAdd !== null) {
      return TaskCommentAdd;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for create task api call
  @Parameter :
  @Author : INIC
  ******************/
export const UpdateTaskApi = async (
  postParam: CreateTaskFormDataModel,
): Promise<any> => {
  try {
    const taskData = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.updateTask,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Task updated',
      true,
    );

    if (taskData !== null) {
      return taskData?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for task mark as complete api call
  @Parameter :
  @Author : INIC
  ******************/
export const changeTaskStatus = async (
  postParam: TaskStatusPostParam,
  id: string,
): Promise<any> => {
  const { successMessage, ...rest } = postParam;
  try {
    const taskStatusChange = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.taskStatusChange + id,
      {
        ...rest,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      successMessage === null ? null : '',
      true,
      '',
      false,
    );

    if (taskStatusChange !== null) {
      if (taskStatusChange?.errorCode) {
        if (taskStatusChange?.errorCode === 405) {
          showMessageNotification(taskStatusChange?.error, 'info');
        } else {
          showMessageNotification(taskStatusChange?.error, 'error');
        }
        return null;
      }
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    console.log(error);
    return null;
  }
};

/*******************
  @Purpose : Used for task archive api call
  @Parameter :
  @Author : INIC
  ******************/
export const taskArchive = async (
  postParam: TaskArchivePostParam,
  id: string,
): Promise<any> => {
  try {
    const taskStatusChange = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.taskArchive + id,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (taskStatusChange !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const archiveSeries = async (parentId: string): Promise<any> => {
  try {
    const hotelId = getHotelId();
    const archiveSeries = await callApi(
      `${config.REACT_APP_BASE_URL}task/hotels/${hotelId}/tasks/${parentId}/archive-series`,
      {},
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (archiveSeries !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for task archive api call
  @Parameter :
  @Author : INIC
  ******************/
export const taskDetailChecklistData = async (id: string): Promise<any> => {
  try {
    const checklistdata = await callApi(
      `${config.REACT_APP_BASE_URL}task/tasks/${id}/checklist`,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (checklistdata !== null) {
      return checklistdata.data ? checklistdata.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for task activity data
  @Parameter :
  @Author : Usman
  ******************/
export const taskActivityData = async (id: string): Promise<any> => {
  try {
    const checklistdata = await callApi(
      `${config.REACT_APP_BASE_URL}task/hotels/${
        getHotelId() as string
      }/tasks/${id}/activity`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistdata !== null) {
      return checklistdata.data ? checklistdata.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for task reopen api call
  @Parameter :
  @Author : INIC
  ******************/
export const taskReopen = async (id: string): Promise<any> => {
  try {
    const taskStatusChange = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.taskReopen + id,
      {
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (taskStatusChange !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for task Assign Reassign api call
  @Parameter :
  @Author : INIC
  ******************/
export const taskAssignReassign = async (
  postData,
  id: string,
): Promise<any> => {
  try {
    const taskStatusChange = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.taskAssignReassign + id,
      {
        ...postData,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (taskStatusChange !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for create task file attachment api call
  @Parameter :
  @Author : INIC
  ******************/
export const CreateTaskAttchmentFilesApi = async (
  postParam: any,
): Promise<any> => {
  try {
    const formData = await prepareFileFormData(postParam);

    const picsUploadResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.createTaskUploadAttchment,
      formData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    if (picsUploadResponse !== null) {
      return picsUploadResponse?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get user listing
  @Parameter : data
  @Author : INIC
  ******************/
export const getTasksApi = async (
  data: any,
  queryParams: any = null,
): Promise<any> => {
  try {
    const apiEndpoint = API_ROUTES.taskList;

    let url = apiEndpoint;

    const queryString = map(
      queryParams,
      (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    ).join('&');

    if (queryString) {
      url = `${url}?${queryString}`;
    }

    const tasks = await callApi(
      config.REACT_APP_BASE_URL + url,
      {
        ...data,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );
    if (tasks?.data) {
      return tasks.data;
    }
    return null;
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get task comment listing
  @Parameter :
  @Author : INIC
  ******************/
export const taskCommentListing = async (
  postData: any,
  id: number,
): Promise<any> => {
  try {
    const taskSingleCommentList = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.taskCommentListing + id,
      {
        ...postData,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (taskSingleCommentList !== null) {
      return taskSingleCommentList?.data ? taskSingleCommentList?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const notificationUnreadCount = async (
  ListpostParam: any,
): Promise<any> => {
  try {
    const notificationListing = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.notificationUnreadCount,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (notificationListing !== null) {
      return notificationListing.data ? notificationListing.data : null;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

/*******************
  @Purpose : Used for get notification listing
  @Parameter :
  @Author : INIC
  ******************/
export const notificationListing = async (
  ListpostParam: ListBodyParam,
): Promise<any> => {
  try {
    const notificationListing = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.notificationListing,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (notificationListing !== null) {
      return notificationListing.data ? notificationListing.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get notification mark as read
  @Parameter :
  @Author : INIC
  ******************/
export const notificationMarkAsRead = async (
  postParam: any,
  userid: string,
): Promise<any> => {
  try {
    const notificationListing = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.notificationMarkAsRead + userid,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    if (notificationListing !== null) {
      return notificationListing?.data;
    } else {
      return false;
    }
  } catch (error: any) {
    return false;
  }
};

/*******************
  @Purpose : Used for get task singlelisting
  @Parameter :
  @Author : INIC
  ******************/
export const taskDetailSingleListing = async (
  EditApiRequestData: EditApiRequestType,
): Promise<any> => {
  try {
    const taskSingleList = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.taskDetailSingleListing +
        EditApiRequestData?.id,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (taskSingleList !== null) {
      return taskSingleList.data ? taskSingleList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getChecklistCountByTaskIds = async (
  hotelId: string,
  taskIds: string[],
): Promise<any> => {
  try {
    const response = await callApi(
      `${config.REACT_APP_BASE_URL}task/hotels/${hotelId}/tasks/checklists/count`,
      {
        taskIds,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getCommentsCountByTaskIds = async (
  hotelId: string,
  taskIds: string[],
): Promise<any> => {
  try {
    const response = await callApi(
      `${config.REACT_APP_BASE_URL}task/hotels/${hotelId}/tasks/comments/count`,
      {
        taskIds,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateTaskChecklistItemStatus = async (
  postParam: any,
  id: string,
): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.taskChecklistItemStatusUpdate + id,
      {
        ...postParam,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (response !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const bulkUpdateTasks = async (
  hotelId: string,
  data: {
    taskIds: string[];
    operationType:
      | 'status'
      | 'priority'
      | 'addAssignee'
      | 'removeAssignee'
      | 'archive'
      | 'unarchive'
      | 'delete';
    values?: any;
  },
): Promise<any> => {
  try {
    const response = await callApi(
      `${config.REACT_APP_BASE_URL}task/hotels/${hotelId}/tasks/bulk-update`,
      data,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      null,
      false,
      '',
      false,
    );

    if (response !== null) {
      if (response?.errorCode) {
        if (response?.errorCode === 405) {
          showMessageNotification(response?.error, 'info');
        } else {
          showMessageNotification(response?.error, 'error');
        }
        return null;
      }

      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const createWorkOrderCosts = async (data): Promise<any> => {
  try {
    const { taskId, costs, attachments } = data;

    const hotelId = getHotelId() as string;
    const response = await callApi(
      `${config.REACT_APP_BASE_URL}task/hotels/${hotelId}/tasks/${taskId}/costs`,
      { costs, attachments },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      null,
      false,
    );

    if (response !== null) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteWorkOrderCost = async (data): Promise<any> => {
  try {
    const { taskId, costId } = data;

    const hotelId = getHotelId() as string;
    const response = await callApi(
      `${config.REACT_APP_BASE_URL}task/hotels/${hotelId}/tasks/${taskId}/costs/${costId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      null,
      false,
    );

    if (response !== null) {
      return response.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const publicFileUploadApi = async (data: {
  workspaceId: string;
  attachments: any[];
}): Promise<any> => {
  try {
    const { attachments, workspaceId } = data;
    const formData = await prepareFileFormData(attachments);

    const uploadResponse = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${workspaceId}/public-upload`,
      formData,
      'post',
      false,
      false,
      '',
      '',
      false,
    );
    if (uploadResponse !== null) {
      return uploadResponse?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
