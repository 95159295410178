// MUI components
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import CTypography from 'components/CTypography';
import { colors } from 'components/theme/constants';

export const PageWrapper = styled(Box)(() => ({
  marginTop: 0,
  padding: '0 24px 20px 24px',
}));

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});

export const PageHeader = styled('div')({
  padding: '42px 0px 24px 0px',
  backgroundColor: colors.white,
});

export const PageTitle = styled(CTypography)({
  fontSize: 24,
  fontWeight: 700,
  color: '#000000DE',
});

export const BoldText = styled(Typography)(() => ({
  fontWeight: 700,
  color: '#212121',
  fontSize: '16px',
  display: 'flex',
  gap: 6,
  alignItems: 'center',
  '.stepCount': {
    display: 'flex',
    width: '22px',
    height: '22px',
    padding: '0px 7px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: '50%',
    border: '2px solid #BDBDBD',
    color: '#FFFFFF',
    '& svg': {
      fontSize: 16,
    },
  },
}));

export const GreyText = styled(Typography)(() => ({
  fontWeight: 400,
  color: '#212121',
  fontSize: '13px',
}));

export const InputField = styled('div')({
  // ...inputWithLabelMixin,
  // flex: 1,
  width: '350px',
  '& label': {
    fontSize: 14,
    fontWeight: 500,
    margin: 0,
    marginBottom: 8,
    color: '#000000',
  },
});
