import styled from '@mui/system/styled';

export const RequestDetailCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status' || prop !== 'canCreateTask',
})(({ theme, status, canCreateTask }) => {
  const isStatusPendingOrDecline = status !== 'Accepted' && canCreateTask;
  const contentHeight = isStatusPendingOrDecline
    ? 'calc(100vh - 309px)'
    : 'calc(100vh - 255px)';
  const contentHeightMobile = isStatusPendingOrDecline
    ? 'calc(100vh - 169px)'
    : 'calc(100vh - 115px)';
  return {
    position: 'relative',
    flex: 1,
    '& .str-chat__container': {
      height: contentHeight,
      [theme.breakpoints.down('md')]: {
        height: contentHeightMobile,
      },
    },
    '& .common-tab-wrap .tab-navbar.nav-pills': {
      paddingLeft: 0,
    },
    '.frame': {
      [theme.breakpoints.down('md')]: {
        padding: '0 20px',
      },
    },
    '& .task-details-form': {
      height: contentHeight,
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        height: contentHeightMobile,
      },
    },
    '& .common-tab-wrap': {
      '& .nav-link ': {
        padding: '12px 1rem',
      },
    },
    '& .checklist-form': {
      height: contentHeight,
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        height: contentHeightMobile,
      },
    },
    '& .activityContainer': {
      height: contentHeight,
      [theme.breakpoints.down('md')]: {
        height: contentHeightMobile,
      },
    },
    '& .requestDetailInfoCnt': {
      marginTop: isStatusPendingOrDecline ? 100 : 53,
      [theme.breakpoints.down('md')]: {
        marginTop: 53,
      },
    },
    '& .info-item': {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    '& .titlebox': {
      [theme.breakpoints.down('md')]: {
        marginBottom: 10,
      },
    },
    '.requestActionBtnCntBottom': {
      height: 54,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'none',
      gap: 10,
      '& button': {
        height: 38,
        [theme.breakpoints.down('md')]: {
          flex: 1,
          maxWidth: 150,
        },
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 111,
      background: '#fff',
    },
  };
});
export const RequestNotificationBar = styled('div')(({ theme }) => ({
  position: 'absolute',
  background: '#fff',
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  '& .requestTitleCnt': {
    height: 46,
    background: '#EBEAFF',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    '& .requestTitle': {
      fontSize: 20,
      color: '#4E48FA',
      fontWeight: 700,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 50px)',
      lineHeight: '36px',
    },
  },
  '.requestActionBtnCnt': {
    height: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
    '& button': {
      height: 38,
      minWidth: 129,
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
