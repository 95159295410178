import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const WidgetCnt = styled(
  'div',
  {},
)(() => ({
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  border: '1px solid #EEEEEE',
  display: 'flex',
  padding: '18px 24px 0px 24px',
  flexDirection: 'column',
  borderRadius: 12,
  height: 'inherit',
  overflowX: 'scroll',

  '& .recharts-legend-wrapper': {
    top: '-17px !important',
  },
}));

export const WidgetHeading = styled('h3')({
  fontSize: 18,
  fontWeight: 700,
  lineHeight: '24.59px',
  margin: '0 0 5px 0',
  color: '#000000',
});
export const WidgetSubHeading = styled('h3')({
  fontSize: 26,
  fontWeight: 800,
  lineHeight: '35.52px',
  color: '#000000',
  marginBottom: 30,
});

export const WidgetLoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});
