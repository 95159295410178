import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import CustomModal from 'components/CustomModal';
import CloseIcon from 'components/Icons/closeIcon';
import { BuilderComponent } from '@builder.io/react';
import { TALK_TO_SALES_URL } from 'utils/constants';
import { CloseButton } from './styles';

interface IProps {
  isOpen?: boolean;
  closeHandler?: () => void;
}

function TemplatesFileUploadSuccess(props: IProps) {
  const { isOpen = true, closeHandler } = props;
  const [builderContentJson, setBuilderContentJson] = useState({});
  const [open, setOpen] = useState(true);

  useEffect(() => {
    window?.builder
      .get('template-file-upload-success-section', {
        url: location.pathname,
      })
      .promise()
      .then(setBuilderContentJson);
  }, []);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setOpen(false);
    closeHandler?.();
  };

  return (
    <CustomModal
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: '900px',
          maxWidth: 'unset',
          maxHeight: 'unset',
        },
      }}
    >
      <Box>
        <CloseButton onClick={closeModal}>
          <CloseIcon sx={{ height: 20, width: 20 }} />
        </CloseButton>
        <Grid container>
          <Grid item xs={5}>
            <BuilderComponent
              model="template-file-upload-success-section"
              content={builderContentJson}
            />
          </Grid>

          <Grid item xs={7}>
            <iframe
              src={TALK_TO_SALES_URL}
              width="525"
              height="605"
              style={{
                border: 'none',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  );
}
export default TemplatesFileUploadSuccess;
