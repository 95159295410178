import { styled } from '@mui/material';

export const AssetNameCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isParent',
})(({ isParent }) => ({
  display: 'flex',
  lineHeight: isParent ? 'normal' : 'unset',
  alignItems: 'center',
  height: '100%',
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .parentAssetName': {
    color: 'rgba(111, 111, 111, 0.87)',
    fontSize: 12,
    fontWeight: 500,
  },

  '& .assetName': {
    color: '#212121',
    fontSize: 14,
    fontWeight: 500,
  },
  '& .colorIcon': {
    fontSize: 14,
  },
}));
