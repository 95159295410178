import { NUMBER_THRESHOLD, THRESHOLD } from 'components/CustomTilesGrid/utils';
import { IWidget } from 'pages/Dashboards/interfaces';

export const generateNewItemConfig = (
  dashboardConfig: any[],
  newItem: IWidget,
) => {
  const lastItem = dashboardConfig[dashboardConfig.length - 1];
  const xValue = lastItem.x <= 6 ? 6 : 0;
  return {
    x: lastItem.x >= 1 ? 0 : 6,
    y: xValue >= 1 ? lastItem.h + lastItem.y : lastItem.y,
    w: 6,
    h: 12,
    i: `.$${newItem.id}`,
    ...(newItem.type == 'table' && { w: 12, x: 0 }),
    ...THRESHOLD,
    ...(newItem.type == 'number' && { w: 3, h: 5, ...NUMBER_THRESHOLD }),
  };
};
export const firstItemConfig = (item: IWidget) => {
  return {
    x: 0,
    y: 0,
    w: item?.type == 'table' ? 12 : 6,
    h: 12,
    i: `.$${item?.id}`,
    ...THRESHOLD,
    ...(item.type == 'number' && { w: 3, h: 5, ...NUMBER_THRESHOLD }),
  };
};
