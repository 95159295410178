import SearchIcon from '@mui/icons-material/Search';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { debounce } from 'lodash';
import { TeamSearchInputCnt } from './style';

interface Props {
  onSearch?: (value: string) => void;
  hideIcon?: boolean;
}

const TeamsSearchInput = ({ onSearch, hideIcon = false }: Props) => {
  const handleInputChange = debounce(async (e) => {
    const value = e.target.value.trim();
    onSearch?.(value || '');
    globalThis?.teamsTable?.api?.setQuickFilter?.(value);
  }, 300);
  return (
    <TeamSearchInputCnt>
      <CustomInput
        id="locationsSearchInput"
        suppressErrorSpace={true}
        grayInput={true}
        fieldProps={{
          onChange: handleInputChange,
          ...(!hideIcon && { startAdornment: <SearchIcon /> }),
          placeholder: 'Search',
        }}
        label=""
      />
    </TeamSearchInputCnt>
  );
};

export default TeamsSearchInput;
