import React, { useRef } from 'react';
import { Divider } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import CustomButton from 'components/Button/CustomButton';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { useSnackbar } from 'notistack';
import { changePasswordApi } from 'api/authApi';
import { CTypographyStyled, LabelField } from 'pages/my-settings/styles';
import PasswordInput from 'components/Form/TextField/PasswordField.cmp';

const ChangePasswordForm = ({ userProfileData }) => {
  const formikRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { id: userId } = userProfileData;

  const initialValues = {
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const validationSchemaModel = Yup.object({
    password: Yup.string()
      .required('Please specify old password to change')
      .min(6, 'Password range must be 6-80 characters')
      .max(80, 'Password range must be 6-80 characters'),
    newPassword: Yup.string()
      .required('Please enter new password')
      .min(6, 'Password range must be 6-80 characters')
      .max(80, 'Password range must be 6-80 characters'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
      .required('Please confirm new password'),
  });

  const handleSubmit = async (values) => {
    const changePasswordData = {
      oldPassword: values.password,
      newPassword: values.newPassword,
      id: userId,
    };
    await changePasswordApi(changePasswordData);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaModel}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await handleSubmit(values);
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ touched, values, errors, getFieldProps, dirty, isSubmitting }) => (
        <Form autoComplete="off">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <CTypographyStyled>Password Settings</CTypographyStyled>
              <Divider sx={{ pt: 2, mb: 3 }} />
            </Grid>
            <Grid item xs={3}>
              <LabelField>Current Password</LabelField>
            </Grid>
            <Grid item xs={9}>
              <Field name="password">
                {(props) => (
                  <PasswordInput
                    error={touched.password && errors.password}
                    sx={{ mb: '10px' }}
                    suppressErrorSpace={true}
                    fieldProps={{
                      ...props.field,
                      sx: { width: 320 },
                      placeholder: 'Current Password',
                      ...getFieldProps('password'),
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <LabelField>New Password</LabelField>
            </Grid>
            <Grid item xs={9}>
              <Field name="newPassword">
                {(props) => (
                  <PasswordInput
                    error={touched.newPassword && errors.newPassword}
                    sx={{ mb: '10px' }}
                    suppressErrorSpace={true}
                    fieldProps={{
                      ...props.field,
                      sx: { width: 320 },
                      placeholder: 'New Password',
                      ...getFieldProps('newPassword'),
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <LabelField>Confirm New Password</LabelField>
            </Grid>
            <Grid item xs={9}>
              <Field name="confirmNewPassword">
                {(props) => (
                  <PasswordInput
                    error={
                      touched.confirmNewPassword && errors.confirmNewPassword
                    }
                    suppressErrorSpace={true}
                    sx={{ mb: '10px' }}
                    fieldProps={{
                      ...props.field,
                      sx: { width: 320 },
                      placeholder: 'Confirm New Password',
                      ...getFieldProps('confirmNewPassword'),
                    }}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sx={{ my: 2 }}>
              {dirty && (
                <CustomButton
                  variant="contained"
                  type="submit"
                  startIcon={<DoneIcon />}
                  buttonLoader={isSubmitting}
                >
                  Save Changes
                </CustomButton>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
