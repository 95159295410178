// React
import { FC } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import CTypography from 'components/CTypography';
import ChecklistIcon from 'components/Icons/checklistIcon';
import RoundedAddIcon from 'components/Icons/roundedAddIcon';
import { HasPermission } from 'components/HasPermission';

// MUI
import { Box } from '@mui/material';

// Icons
import LogIcon from 'components/Icons/logIcon';
import PhoneIcon from 'components/Icons/PhoneIcon';

// Constants
import DIALOGS from 'utils/dialogIds';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// Styles
import { GetStartedCnt } from './style';

const GetStarted: FC = () => {
  const { PERMISSIONS } = useCustomPermission();
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const handleOpenCreateRequest = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_REQUEST,
      }),
    );
  };
  return (
    <GetStartedCnt>
      <CTypography className="label">Get Started!</CTypography>
      <Box className="actionsCnt">
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
          <div
            className="box"
            onClick={() => {
              dispatch(
                actions.setDialog({
                  open: true,
                  dialogId: DIALOGS.CREATE_TASK,
                }),
              );
            }}
          >
            <div className="outerCnt">
              <div className="iconCnt workOrders">
                <RoundedAddIcon />
              </div>
              <CTypography>Create a new work order</CTypography>
            </div>
          </div>
          <div
            className="box"
            onClick={() => {
              navigateWithWorkspaceUrl('/checklist/new');
            }}
          >
            <div className="outerCnt">
              <div className="iconCnt checkList">
                <ChecklistIcon primary={'#512DA8'} secondary={'#F3E5F5'} />
              </div>
            </div>
            <CTypography>Create a new template</CTypography>
          </div>
          <div
            className="box"
            onClick={() => {
              dispatch(
                actions.setDialog({
                  dialogId: DIALOGS.TEMPLATE_LIBRARY,
                  open: true,
                }),
              );
            }}
          >
            {' '}
            <div className="outerCnt">
              <div className="iconCnt templates">
                <LogIcon />
              </div>
              <CTypography>
                Explore checklist, inspection, and log templates
              </CTypography>
            </div>
          </div>
        </HasPermission>

        <HasPermission permissions={[PERMISSIONS.CAN_REQUEST_TASK]}>
          <div className="box" onClick={() => handleOpenCreateRequest()}>
            <div className="outerCnt">
              <div className="iconCnt workOrders">
                <RoundedAddIcon />
              </div>
              <CTypography>Create new Request</CTypography>
            </div>
          </div>
        </HasPermission>

        <div
          className="box"
          onClick={() => {
            dispatch(
              actions.setDialog({
                dialogId: DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG,
                open: true,
              }),
            );
          }}
        >
          <div className="outerCnt">
            <div className="iconCnt phone">
              <PhoneIcon sx={{ fontSize: '17px' }} />
            </div>
            <CTypography>Open mobile app</CTypography>
          </div>
        </div>
      </Box>
    </GetStartedCnt>
  );
};

export default GetStarted;
