import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  EmptyCnt,
  TemplatesListCnt,
} from 'pages/Templates/TemplatesListing/templatesListing.style';
import TemplateItem from 'pages/Templates/TemplatesListing/templatesItem/templatesItem';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import TemplatesListView from 'pages/Templates/TemplateListView/list';
import { useContext, useMemo } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';
import actions from 'store/actions';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';

function TemplatesListing() {
  const {
    state = {},
    addToFolder,
    onMoveFolder,
    onMoveOutOfFolder,
    isArchive,
  } = useContext(MyTemplatesContext);
  const dispatch = useDispatch();
  const { data = [], searchTerm } = state;
  const { lastFolderId } = useCurrentFolderId();
  const myTemplatesData = useSelector(selectors.getChecklistListDataOnly);

  const deleteTemplateCallback = () => {
    dispatch(
      actions.getChecklistListApiCall({
        detailed: true,
        folderId: state?.lastFolderId || 'root',
        archived: isArchive,
      }),
    );
  };

  return (
    <TemplatesListCnt>
      <Typography variant={'h1'} className={'templatesViewHeading'}>
        Templates
      </Typography>
      {/*// )}*/}

      {state?.selectedView === 'list' ? (
        <TemplatesListView folders={data} selectedFolder={lastFolderId} />
      ) : searchTerm && myTemplatesData?.length === 0 ? (
        <EmptyCnt>
          <NoRecordsFound />
        </EmptyCnt>
      ) : (
        <Grid container spacing={2}>
          {myTemplatesData?.map((t) => (
            <TemplateItem
              template={t}
              key={t?.id}
              templates={myTemplatesData}
              folder={lastFolderId}
              actionDropdownProps={{
                onAddTemplateToFolder: addToFolder,
                onMoveFolder: onMoveFolder,
                onMoveOutOfFolder,
                deleteTemplateCallback: deleteTemplateCallback,
              }}
            />
          ))}
        </Grid>
      )}
    </TemplatesListCnt>
  );
}

export default TemplatesListing;
