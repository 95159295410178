import {
  MainCnt,
  Value,
  CellContentWrapper,
} from 'pages/documents/listing/listView/list.style';
import UserAvatar from 'components/Avatar';
import moment from 'moment-timezone';
import ListActions from 'pages/documents/listing/listView/renderers/actions/actions';
import Editors from 'pages/documents/listing/listView/renderers/editors/editors';
import Title from 'pages/documents/listing/listView/renderers/titleDetail';
import Viewers from 'pages/documents/listing/listView/renderers/viewers/viewers';

export const rowHeight = 62;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};

export const columns: any = (props) => {
  return [
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      width: 250,
      cellRenderer: cellRenderer.title,
    },
    {
      field: 'Creator',
      headerName: 'Created by',
      colId: 'Creator',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 200,
      valueGetter: valueGetters.createdBy,
      cellRenderer: cellRenderer.createdBy,
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      colId: 'createdAt',
      minWidth: 100,
      valueGetter: valueGetters.createdOn,
      cellRenderer: cellRenderer.createdOn,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Last updated',
      colId: 'updatedAt',
      minWidth: 100,
      valueGetter: valueGetters.lastUpdated,
      cellRenderer: cellRenderer.lastUpdated,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: 'DocumentAccess',
      headerName: 'Editors',
      colId: 'DocumentAccess',
      minWidth: 180,
      valueGetter: valueGetters.editors,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      cellRenderer: cellRenderer.editors,
    },

    {
      field: 'viewers',
      headerName: 'Viewers',
      colId: 'viewers',
      minWidth: 180,
      suppressMovable: true,
      suppressMenu: true,
      valueGetter: valueGetters.viewers,
      sortable: true,
      cellRenderer: cellRenderer.viewers,
    },

    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      width: 70,
      suppressMenu: true,
      cellClass: 'disableRowClick',
      cellRenderer: (params) => cellRenderer.actionColumn(params),
    },
  ];
};

// Cell Renderer
export const cellRenderer = {
  title: (params) => {
    if (!params?.data) return;

    return <Title data={params?.data} />;
  },
  createdBy: (params) => {
    if (!params?.data) return;
    const { Creator } = params.data;
    return (
      <MainCnt>
        <UserAvatar
          width={24}
          height={24}
          firstName={Creator?.firstName}
          profilePic={Creator?.photo}
          userId={Creator?.id}
        />
        <Value style={{ marginLeft: 10 }}>
          {Creator?.firstName} {Creator?.lastName}
        </Value>
      </MainCnt>
    );
  },

  editors: (params) => {
    if (!params?.data) return;
    return !params?.data?.fileType ? (
      <MainCnt>
        <Value>-</Value>
      </MainCnt>
    ) : (
      <Editors document={params?.data} />
    );
  },
  viewers: (params) => {
    if (!params?.data) return;
    return !params?.data?.fileType ? (
      <MainCnt>
        <Value>-</Value>
      </MainCnt>
    ) : (
      <Viewers document={params?.data} />
    );
  },

  createdOn: (params) => {
    if (!params?.data) return;
    const { createdAt } = params.data;
    return (
      <MainCnt>
        <Value>{moment(createdAt).format('MM/DD/YY')}</Value>
      </MainCnt>
    );
  },

  lastUpdated: (params) => {
    if (!params?.data) return;
    const { updatedAt } = params.data;
    return (
      <MainCnt>
        <Value>{moment(updatedAt).format('MM/DD/YY')}</Value>
      </MainCnt>
    );
  },
  actionColumn: (params) => {
    if (!params?.data) return;
    return <ListActions row={params?.data} />;
  },
};

// Value Getters

export const valueGetters = {
  title: (params) => {
    const { name } = params.data;
    return name || '-';
  },
  editors: (params) => {
    const { DocumentAccess } = params.data;
    return DocumentAccess || '-';
  },
  viewers: (params) => {
    const { viewers } = params.data;
    return viewers || '-';
  },
  templateAdmins: (params) => {
    const { templateAdmins } = params.data;
    return templateAdmins || '-';
  },
  createdBy: (params) => {
    const { Creator } = params.data;
    return Creator?.firstName || '-';
  },
  createdOn: (params) => {
    const { createdAt } = params.data;
    return createdAt || '-';
  },
  lastUpdated: (params) => {
    const { updatedAt } = params.data;
    return updatedAt || '-';
  },
};
