import { Stack } from '@mui/material';
import { Container, Title, Value } from './style';
import CircularProgressPercentage from 'components/CircularPercantage';
import { Schedule } from './renderers/renderers';

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};
export const cellRenderer = {
  title: (params) => {
    if (!params?.data) return;
    const { title } = params.data;
    return (
      <Container>
        <Title>{title}</Title>
      </Container>
    );
  },
  location: (params) => {
    if (!params?.data) return;
    const { name } = params.data;
    return (
      <Container>
        <Value>{name || '-'}</Value>
      </Container>
    );
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { recurringByEvery } = params.data;
    return <Schedule recurringByEvery={recurringByEvery} />;
  },
  completionOnTime: (row) => {
    if (!row?.data) return;
    const { CompletedOnTimePercentage } = row.data;
    return (
      <Container>
        <Stack direction="row" alignItems="center" gap="10px">
          <CircularProgressPercentage
            value={CompletedOnTimePercentage}
            primaryColor={'#4CAF50'}
            secondaryColor={'#E0E0E0'}
            containerProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />

          <Value>{CompletedOnTimePercentage}%</Value>
        </Stack>
      </Container>
    );
  },
  completedLate: (row) => {
    if (!row?.data) return;
    const { CompletedLatePercentage } = row.data;
    return (
      <Container>
        <Stack direction="row" alignItems="center" gap="10px">
          <CircularProgressPercentage
            value={CompletedLatePercentage}
            primaryColor={'#EF5350'}
            secondaryColor={'#E0E0E0'}
            containerProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />

          <Value>{CompletedLatePercentage}%</Value>
        </Stack>
      </Container>
    );
  },
  inComplete: (row) => {
    if (!row?.data) return;
    const { IncompletePercentage } = row.data;
    return (
      <Container>
        <Stack direction="row" alignItems="center" gap="10px">
          <CircularProgressPercentage
            value={IncompletePercentage}
            primaryColor={'#FB8C00'}
            secondaryColor={'#E0E0E0'}
            containerProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />

          <Value>{IncompletePercentage}%</Value>
        </Stack>
      </Container>
    );
  },
};

export const valueGetters = {
  title: (params) => {
    if (!params?.data) return;
    const { title } = params.data;
    return title;
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { recurringByEvery } = params.data;
    return recurringByEvery;
  },
  location: (params) => {
    if (!params?.data) return;
    const { name } = params.data;
    return name;
  },
  completionOnTime: (params) => {
    if (!params?.data) return;
    const { CompletedOnTimePercentage } = params.data;
    return CompletedOnTimePercentage;
  },
  completedLate: (params) => {
    if (!params?.data) return;
    const { CompletedLatePercentage } = params.data;
    return CompletedLatePercentage;
  },
  inComplete: (params) => {
    if (!params?.data) return;
    const { IncompletePercentage } = params.data;
    return IncompletePercentage;
  },
};

export const columns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      sortable: true,
      valueGetter: valueGetters.title,
      cellRenderer: cellRenderer.title,
      width: 250,
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      colId: 'schedule',
      sortable: true,
      valueGetter: valueGetters.schedule,
      cellRenderer: cellRenderer.schedule,
      resizable: true,
      width: 180,
    },
    {
      field: 'location',
      headerName: 'Location',
      colId: 'location',
      sortable: true,
      valueGetter: valueGetters.location,
      cellRenderer: cellRenderer.location,
      resizable: true,
      width: 180,
    },
    {
      field: 'completionOnTime',
      headerName: 'Completion on Time',
      colId: 'completionOnTime',
      valueGetter: valueGetters.completionOnTime,
      cellRenderer: cellRenderer.completionOnTime,
      sortable: true,
      width: 180,
    },
    {
      field: 'completedLate',
      headerName: 'Completed Late',
      colId: 'completedLate',
      valueGetter: valueGetters.completedLate,
      sortable: true,
      cellRenderer: cellRenderer.completedLate,
      width: 180,
    },
    {
      field: 'incomplete',
      headerName: 'Incomplete',
      colId: 'incomplete',
      sortable: true,
      cellRenderer: cellRenderer.inComplete,
      valueGetter: valueGetters.inComplete,
      width: 150,
    },
  ];
  return tableCells.filter((cell) => columnConfig[cell.field]);
};
