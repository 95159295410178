/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo, useCallback } from 'react';
import CustomListView from 'components/ListView/Listview.cmp';
import FullWidthCellRenderer from './createFixedRow';
import CModalConfirmation from 'components/CModalConfirmation';
import NoRecordsFound from './emptyState/noRecordsFound.cmp';
import { Columns } from './constants';
import { colors } from 'components/theme/constants';
import './styles.css';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

export const GetCategoriesListing: FC<any> = ({
  serviceList,
  handleShowRemove,
  updateHandler,
}: any) => {
  const pinnedTopRowData = useMemo(() => {
    return [
      {
        fullWidth: true,
      },
    ];
  }, []);
  const topAlertState = useSelector(selectors.getTopAlertState);
  //Fit columns to span to full width of the grid automatically
  const onGridReady = (event) => {
    setTimeout(() => {
      event.api.sizeColumnsToFit();
    }, 1000);
  };

  const onGridResize = (event) => {
    event.api.sizeColumnsToFit();
  };

  const isFullWidthRow = useCallback((params) => {
    return params.rowNode.data.fullWidth;
  }, []);

  const fullWidthCellRenderer = useMemo(() => {
    return FullWidthCellRenderer;
  }, []);

  const onBtStartEditing = useCallback((params) => {
    const { api } = globalThis?.CategoriesGrid || {};

    api?.stopEditing();
    api?.startEditingCell({
      rowIndex: params?.node?.rowIndex,
      colKey: params.columnApi.getAllColumns()[0].colId,
    });
  }, []);

  const onModelUpdated = () => {
    const { api } = globalThis.CategoriesGrid;
    const rowsCount = api.getDisplayedRowCount();

    if (rowsCount === 0) {
      api.showNoRowsOverlay();
    } else {
      api.hideOverlay();
    }
  };

  return (
    <>
      <CustomListView
        rootProps={{
          style: {
            height: topAlertState?.show
              ? 'calc(100vh - 242px)'
              : 'calc(100vh - 184px)',
            boxShadow: 'none',
            flex: 1,
            backgroundColor: `${colors.white}`,
          },
        }}
        onRowClick={() => {
          console.log('row clicked');
        }}
        gridProps={{
          ref: (ref) => {
            globalThis.CategoriesGrid = ref;
          },
          columnDefs: Columns({
            handleShowRemove,
            updateHandler,
            onBtStartEditing,
            serviceList,
          }),
          rowData: serviceList,
          pinnedTopRowData: pinnedTopRowData,
          isFullWidthRow: isFullWidthRow,
          fullWidthCellRenderer: fullWidthCellRenderer,
          onGridReady: onGridReady,
          onGridSizeChanged: onGridResize,
          suppressClickEdit: true,
          frameworkComponents: {
            customNoRowsOverlay: NoRecordsFound,
          },
          onModelUpdated: onModelUpdated,
        }}
      ></CustomListView>
      <CModalConfirmation
        dialogId="confirmDeleteCategory"
        title="Delete Category"
        handleCancelButtonText={'Cancel'}
        handleSaveButtonText={'Delete'}
        isDividers={false}
        actionsProps={{
          style: { padding: '0px 18px 18px 15px' },
        }}
        cancelBtnProps={{
          style: { minWidth: 80 },
        }}
        saveBtnProps={{
          style: { backgroundColor: '#D32F2F', marginLeft: 0 },
        }}
      />{' '}
    </>
  );
};
