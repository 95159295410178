import { Box } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';

export const ListCnt = styled('div')({
  width: '100%',
  '& .ag-row:last-of-type': {
    boxShadow: 'none',
  },
  '& .ag-header': {
    border: 'none',
    borderRadius: 0,
    marginBottom: 0,
    boxShadow: 'none',
  },
  '& .ag-header-row': {
    height: '36px !important',
  },
  '& .ag-header-cell': {
    minHeight: '36px',
  },
  '& .ag-header-cell-text': {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.17px',
    color: '#000000DE',
  },
});

export const MainCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .actionBtn': {
    padding: 5,
  },
});

export const Value = styled(CTypography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  letterSpacing: '-0.1px',
  color: '#212121',
});

export const ActionBtn = styled(CustomButton)({
  height: 30,
});

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  transform: 'translateY(-50px)',
});
