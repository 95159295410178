// React
import React, { useState, useEffect, useMemo } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import Header from './Header';
import FilterRow from '../FilterRow';
import SubHeader from './SubHeader';

// Icons
import { AddRounded } from '@mui/icons-material';

// Styled
import {
  Wrapper,
  FilterRowsWrapper,
  AddFilterButton,
  BottomRowWrapper,
  StyledDivider,
} from './styled';

// Utils
import xeniaApi from 'api/index';
import { isEqual } from 'lodash';
import ItemFilterRow from 'components/SubmissionsFilters/ItemFilterRow';

export type FilterType = {
  filterName: 'status' | 'date' | 'user' | '';
  comparator: 'is' | 'isNot' | '';
  value: any[];
  conditional: 'and' | 'or' | '';
};

export type ItemFilterType = {
  item: string;
  comparator: string;
  value: any[];
};

interface AdvancedFilterBuilderPropTypes {
  renderCloseIcon?: boolean;
  filters: any[];
  itemFilters: any[];
  templateItems: any[];
  handleClose?: () => void;
  handleSetFiltersCb: (filters: FilterType[], empty?: boolean) => void;
  handleSetItemFiltersCb: (filters: ItemFilterType[], empty?: boolean) => void;
}

const defaultFilterState: FilterType[] = [
  {
    filterName: '',
    comparator: '',
    value: [],
    conditional: 'and',
  },
];

const defaultItemFilterState: ItemFilterType[] = [
  {
    item: '',
    comparator: '',
    value: [],
  },
];

const AdvancedFilterBuilder = ({
  renderCloseIcon = true,
  filters = [],
  itemFilters = [],
  templateItems = [],
  handleClose,
  handleSetFiltersCb,
  handleSetItemFiltersCb,
}: AdvancedFilterBuilderPropTypes) => {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState<FilterType[]>(
    filters?.length ? filters : defaultFilterState,
  );
  const [itemFilterData, setItemFilterData] = useState<ItemFilterType[]>(
    itemFilters?.length ? itemFilters : defaultItemFilterState,
  );

  const templateItemsHash = useMemo(
    () =>
      templateItems.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
    [templateItems],
  );

  const fetchUsers = async () => {
    const result = await xeniaApi.getUserListApi();
    const data = result.data.length > 0 ? result.data : [];
    dispatch(actions.setAllUsers(data));
  };

  const handleDeleteRow = (idx: number) => {
    if (filterData.length === 1) {
      setFilterData(defaultFilterState);
      handleSetFiltersCb(defaultFilterState, true);
      return;
    }
    const filters = [...filterData];
    filters.splice(idx, 1);

    setFilterData(filters);
    handleSetFiltersCb(filters);
  };

  const setConditionForFilters = (filters: FilterType[]) => {
    const condition =
      filters.length >= 2 ? filters[1].conditional : filters[0].conditional;
    return filters.map((f) => ({ ...f, conditional: condition }));
  };

  const handleSetFilters = (
    idx: number,
    newFilters: {
      filterName?: 'status' | 'date' | 'user';
      comparator?: 'is' | 'isNot';
      value?: any[];
      conditional?: 'and' | 'or';
    },
  ) => {
    const filters = [...filterData];
    filters[idx] = { ...filters[idx], ...newFilters };
    const newFilterState = setConditionForFilters(filters);
    setFilterData(newFilterState);
    handleSetFiltersCb(newFilterState);
  };

  const handleAddFilter = () => {
    const filters = [
      ...filterData,
      {
        ...defaultFilterState[0],
        conditional: filterData[filterData.length - 1].conditional,
      },
    ];
    setFilterData(filters);
    handleSetFiltersCb(filters);
  };

  const handleSetItemFilters = (
    idx: number,
    newFilters: {
      itemId?: string;
      comparator?: string;
      value?: any[];
    },
  ) => {
    const filters = [...itemFilterData];
    filters[idx] = { ...filters[idx], ...newFilters };
    setItemFilterData(filters);
    handleSetItemFiltersCb(filters);
  };

  const handleAddItemFilter = () => {
    const filters = [...itemFilterData, defaultItemFilterState[0]];
    setItemFilterData(filters);
    handleSetItemFiltersCb(filters);
  };

  const handleDeleteItemRow = (idx: number) => {
    if (itemFilterData.length === 1) {
      setItemFilterData(defaultItemFilterState);
      handleSetItemFiltersCb(defaultItemFilterState, true);
      return;
    }
    const filters = [...itemFilterData];
    filters.splice(idx, 1);

    setItemFilterData(filters);
    handleSetItemFiltersCb(filters);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Wrapper>
      <Header renderCloseIcon={renderCloseIcon} handleClose={handleClose} />
      <StyledDivider />
      <SubHeader heading="Submission Filters" />
      <FilterRowsWrapper>
        {filterData.map((f, idx) => (
          <FilterRow
            handleDeleteRow={handleDeleteRow}
            key={Math.random()}
            index={idx}
            handleSetFilters={handleSetFilters}
            filter={filterData[idx]}
          />
        ))}
      </FilterRowsWrapper>
      <BottomRowWrapper>
        <AddFilterButton
          onClick={handleAddFilter}
          fullWidth={false}
          startIcon={<AddRounded />}
        >
          Add Filter
        </AddFilterButton>
      </BottomRowWrapper>
      <StyledDivider />
      <SubHeader heading="Response Filters" />
      <FilterRowsWrapper>
        {itemFilterData.map((f, idx) => (
          <ItemFilterRow
            handleDeleteRow={handleDeleteItemRow}
            key={Math.random()}
            index={idx}
            handleSetFilters={handleSetItemFilters}
            filter={f}
            templateItems={templateItems}
            templateItemsHash={templateItemsHash}
          />
        ))}
      </FilterRowsWrapper>
      <BottomRowWrapper>
        <AddFilterButton
          onClick={handleAddItemFilter}
          fullWidth={false}
          startIcon={<AddRounded />}
        >
          Add Filter
        </AddFilterButton>
      </BottomRowWrapper>
    </Wrapper>
  );
};

const arePropsEqual = (
  prevProps: AdvancedFilterBuilderPropTypes,
  nextProps: AdvancedFilterBuilderPropTypes,
) =>
  isEqual(
    {
      filters: prevProps.filters,
      itemFilters: prevProps.itemFilters,
      templateItems: prevProps.templateItems,
    },
    {
      filters: nextProps.filters,
      itemFilters: nextProps.itemFilters,
      templateItems: nextProps.templateItems,
    },
  );

export default React.memo(AdvancedFilterBuilder, arePropsEqual);
