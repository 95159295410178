import { Box, styled } from '@mui/material';
import { colors } from 'components/theme/constants';

export const MessageHeaderStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '.addedMembers': {
    display: 'flex',
    alignItems: 'center',
    '& .btn': {
      marginLeft: 0,
    },
    '& span': {
      margin: 0,
    },
  },
  '.title': {
    fontSize: 16,
    display: 'flex',
    color: '#151339',
  },
  '.chatTitle': {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 700,
    fontSize: 18,
  },
  '& span': {
    fontWeight: 'normal',
    fontSize: '12px !important',
    lineHeight: '15px !important',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '& .dropdown': {
    '& .dropdownToggle': {
      color: '#3f51b5',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  '.avatar': {
    display: 'flex',
    alignItems: 'center',
  },
}));
