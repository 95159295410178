import {
  EmptyStateCnt,
  EmptyStateText,
} from './taskListWidgetEmptyState.style';
import NumberIcon from 'components/Icons/templateStepIcons/numberIcon';

const NumberWidgetEmptyState = (props) => {
  return (
    <EmptyStateCnt>
      <NumberIcon />
      <EmptyStateText>No record available</EmptyStateText>
    </EmptyStateCnt>
  );
};

export default NumberWidgetEmptyState;
