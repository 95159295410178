import React, { FC, useEffect } from 'react';
import { useLoggedInUser } from 'customhooks/index';

const AppcuesCmp: FC = () => {
  const user = useLoggedInUser();

  useEffect(() => {
    if (user?.id) {
      window?.Appcues?.identify?.(user.id as string, {
        email: user.emailId as string,
        displayName: user.fullName as string,
        createdAt: user.createdAt,
        isVerified: user.isVerified,
        title: user.title,
        workspaceId: user.HotelId,
        workspaceCount: user?.UserHotels?.length,
        workspaceName: user?.Hotel?.name,
        industryId: user.Hotel?.Industry?.id,
        industryName: user.Hotel?.Industry?.name,
        subscriptionId: user.Hotel?.Subscription?.id,
        subscriptionProductId: user.Hotel?.Subscription?.ProductId,
        subscriptionStatus: user.Hotel?.Subscription?.status,
      });
    }
  }, [user]);
  return null;
};

export default AppcuesCmp;
