import React from 'react';

// Custom components
import VirtualList from 'components/VirtualList';

import InfiniteLoader from 'react-window-infinite-loader';

interface InfiniteVirtualListPropTypes {
  isNextPageLoading?: boolean;
  rows: any[];
  height?: number;
  rowGap?: number;
  handleLoadNextPage?: (offset: number) => Promise<void> | void;
  totalCount: number;
  overrideRowCount?: number;
}

const InfiniteVirtualList = ({
  isNextPageLoading,
  rows,
  height = 500,
  rowGap = 12,
  totalCount,
  handleLoadNextPage,
  overrideRowCount,
}: InfiniteVirtualListPropTypes) => {
  const currentRowCount = overrideRowCount ?? rows.length;
  const hasNextPage = currentRowCount < totalCount;
  const rowCount = hasNextPage ? currentRowCount + 1 : currentRowCount;

  const loadNextPageHelper = async (startIndex: number, stopIndex: number) => {
    if (startIndex !== 0 && stopIndex !== 0) {
      handleLoadNextPage?.(currentRowCount);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPageHelper;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < currentRowCount;

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={rowCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered }) => (
        <VirtualList
          onItemsRendered={onItemsRendered}
          height={height}
          rows={rows}
          rowGap={rowGap}
        />
      )}
    </InfiniteLoader>
  );
};

export default InfiniteVirtualList;
