import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import TaskIcon from 'components/Icons/taskIcon';
import CustomButton from 'components/Button/CustomButton';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import selectors from 'store/selectors';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import RenderAttachments from './attachments';
import {
  Wrapper,
  TemplateInfo,
  TaskDetails,
  TaskTitle,
  TaskInfo,
  Heading,
  Value,
  SelectedChecklist,
  PriorityContent,
} from './style';

const Preview = ({ template }) => {
  const checklistList = useSelector(selectors.getChecklistCompleteDataOnly);
  const categoryList = useSelector(selectors.getServiceAdmin);

  const [attachedChecklist, setAttachedChecklist] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const { addNewAssignees } = useAssigneesUtils();

  useEffect(() => {
    const selectedChecklist = checklistList?.find(
      (c) => c.id == template?.ChecklistId,
    );
    setAttachedChecklist(selectedChecklist);
  }, [template?.ChecklistId]);

  useEffect(() => {
    const category = categoryList?.find((c) => c.id == template?.ServiceTypeId);
    setSelectedCategory(category);
  }, [template?.ServiceTypeId]);

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  const assignees = useMemo(() => {
    if (template?.assignees) {
      const assigneesList = addNewAssignees(template?.assignees);
      return assigneesList;
    }
    return [];
  }, [template?.assignees]);

  return (
    <Wrapper>
      <TemplateInfo>
        <div className="innerCnt">
          <div className="details">
            <div className="templateIcon">
              <TaskIcon />
            </div>
            <div className="templateContent">
              <Typography className="templateTitle">
                {template?.templateTitle}
              </Typography>
              <Typography className="templateDescription">
                {template?.templateDescription}
              </Typography>
              <Typography className="templateCreatedBy">
                Created by: {template?.Creator?.fullName}
              </Typography>
            </div>
          </div>
          {/* Will be used later */}
          {/* <CustomButton variant="contained">Edit Template</CustomButton> */}
        </div>
      </TemplateInfo>

      <TaskDetails>
        <TaskTitle
          style={{
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            paddingBottom: 13,
          }}
        >
          {template?.title}
        </TaskTitle>

        <TaskInfo>
          <Heading>Description</Heading>
          <Value style={{ fontWeight: 500 }}>
            {template?.description || '-'}
          </Value>
        </TaskInfo>

        <TaskInfo
          style={{
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            paddingBottom: 20,
          }}
        >
          <Heading>Template</Heading>
          {attachedChecklist ? (
            <SelectedChecklist>
              <div className="leftCnt">
                <span
                  className="checklistIcon"
                  style={{ background: attachedChecklist?.icon?.color }}
                >
                  <MuiIcon
                    name={attachedChecklist?.icon?.icon}
                    sx={{ fontSize: '28px' }}
                  />
                </span>

                <div className="checklistNameCnt">
                  <span className="checklistName">
                    {attachedChecklist?.name}
                  </span>
                  <span className="checklistItemCount">
                    {`${attachedChecklist?.itemsCount} items`}
                  </span>
                </div>
              </div>
            </SelectedChecklist>
          ) : (
            <Value>-</Value>
          )}
        </TaskInfo>

        <TaskInfo>
          <Heading>Assigned To</Heading>
          {(assignees?.length === 0 || !assignees) && <Value>-</Value>}
          {assignees?.length > 0 && (
            <Box style={{ marginTop: 10 }}>
              <AssigneesView
                type={assignees?.length === 1 ? 'single' : 'avatars'}
                data={assignees}
                assigneesProps={{
                  popperProps: {
                    sx: {
                      zIndex: 1700,
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white !important',
                        boxShadow:
                          'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                        minWidth: 300,
                        maxHeight: 300,
                        overflowY: 'auto',
                        borderRadius: '15px',
                      },
                    },
                  },
                }}
              />
            </Box>
          )}
        </TaskInfo>

        <TaskInfo
          style={{
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            paddingBottom: 20,
          }}
        >
          <Heading>Due Date / Time</Heading>
          {template?.dueAfterCreationTime?.value &&
          template?.dueAfterCreationTime?.unit ? (
            <Value>
              {template?.dueAfterCreationTime?.value}{' '}
              {template?.dueAfterCreationTime?.unit} after the task is created
            </Value>
          ) : (
            <Value>-</Value>
          )}
        </TaskInfo>

        <TaskInfo>
          <Heading>Priority</Heading>
          <PriorityContent>
            {template?.priority && (
              <div
                className={`icon-priority-${template?.priority?.toLowerCase()}`}
                style={{ marginRight: 10 }}
              />
            )}
            <Value>{template?.priority || '-'}</Value>
          </PriorityContent>
        </TaskInfo>

        <TaskInfo>
          <Heading>Category</Heading>
          <Value>{selectedCategory?.type || '-'}</Value>
        </TaskInfo>

        <TaskInfo>
          <Heading>Location</Heading>
          <Value>{template?.Location?.name || '-'}</Value>
        </TaskInfo>

        <TaskInfo
          style={{
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            paddingBottom: 20,
          }}
        >
          <Heading>Asset</Heading>
          <Value>{template?.Asset?.name || '-'}</Value>
        </TaskInfo>

        <TaskInfo>
          <Heading>Attachments</Heading>
          {template?.attachment?.length > 0 ? (
            <RenderAttachments attachments={template?.attachment || []} />
          ) : (
            <Value>-</Value>
          )}
        </TaskInfo>
      </TaskDetails>
    </Wrapper>
  );
};

export default Preview;
