import { createSvgIcon } from '@mui/material';

const SignatureIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M3.76637 18.5515C3.02752 18.769 2.6581 18.8777 2.5056 18.7931C2.37415 18.7202 2.29217 18.5821 2.29106 18.4318C2.28978 18.2574 2.56209 17.9851 3.1067 17.4405L8.73524 11.812C8.87292 11.8488 9.01764 11.8684 9.16694 11.8684C10.0874 11.8684 10.8336 11.1223 10.8336 10.2018C10.8336 9.2813 10.0874 8.53511 9.16694 8.53511C8.24646 8.53511 7.50027 9.2813 7.50027 10.2018C7.50027 10.3511 7.5199 10.4958 7.55672 10.6335L1.92819 16.262C1.38358 16.8066 1.11127 17.0789 0.936889 17.0777C0.786573 17.0765 0.648505 16.9946 0.575588 16.8631C0.490998 16.7106 0.599749 16.3412 0.817251 15.6023L3.5889 6.18716C3.6436 5.95659 3.71233 5.66689 3.87274 5.4209C4.01128 5.20841 4.1965 5.03036 4.41428 4.9003C4.66641 4.74973 4.9586 4.69247 5.19114 4.64691L9.52999 3.77931C9.49244 3.52593 9.51364 3.26599 9.59356 3.02C9.67201 2.77857 9.80498 2.59366 9.92868 2.44796C10.0413 2.31528 10.1862 2.17046 10.3349 2.02175L10.9869 1.3698C11.1356 1.22106 11.2804 1.0762 11.4131 0.963564C11.5588 0.839869 11.7437 0.706894 11.9851 0.628448C12.3199 0.519684 12.6804 0.519684 13.0152 0.628448C13.2566 0.706894 13.4415 0.839869 13.5872 0.963564C13.7199 1.0762 13.8647 1.22105 14.0134 1.36979L17.9987 5.3551C18.1475 5.5038 18.2923 5.64861 18.4049 5.78129C18.5286 5.927 18.6616 6.11191 18.7401 6.35334C18.8488 6.68808 18.8488 7.04865 18.7401 7.38339C18.6616 7.62482 18.5286 7.80973 18.4049 7.95544C18.2923 8.08811 18.1475 8.23292 17.9987 8.38161L17.3468 9.0336C17.1981 9.18226 17.0532 9.32725 16.9206 9.43983C16.7748 9.56353 16.5899 9.6965 16.3485 9.77495C16.1026 9.85485 15.8427 9.87605 15.5894 9.83856L14.7218 14.1776C14.6762 14.4101 14.619 14.7023 14.4684 14.9544C14.3384 15.1722 14.1603 15.3574 13.9478 15.496C13.7018 15.6564 13.4121 15.7251 13.1816 15.7798L3.76637 18.5515Z"
        fill="#EF5350"
      />
    </>,
    'SignatureIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default SignatureIcon;
