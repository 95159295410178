// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// MUI
import { Stack, Typography } from '@mui/material';

// Styled
import { TalkToSalesButton } from './styled';

// Icons
import LockIconRounded from 'components/Icons/LockIconRounded';

// Constants
import { TALK_TO_SALES_URL } from 'utils/constants';

interface EmptyStateProps {
  onClickAddMembers: () => void;
}

const EmptyState = () => {
  const dispatch = useDispatch();

  const handleClickTalkToSales = () => {
    dispatch(
      actions.setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <Stack
      height="calc(100vh - 223px)"
      alignItems={'center'}
      padding={'60px 0px'}
    >
      <Stack
        width={'80px'}
        height={'80px'}
        borderRadius={'80px'}
        bgcolor={'rgba(179, 229, 252, 1)'}
        padding={'10px'}
        alignItems={'center'}
        justifyContent={'center'}
        marginBottom={'16px'}
      >
        <LockIconRounded style={{ fontSize: '40px' }} />
      </Stack>
      <Typography
        fontSize={'28px'}
        fontWeight={'700'}
        color="rgba(0, 0, 0, 0.87)"
        marginBottom={'16px'}
      >
        You’ve discovered a Paid Feature!
      </Typography>
      <Typography
        component={'span'}
        fontSize={'18px'}
        fontWeight={'700'}
        color="#424242"
        width={'500px'}
        textAlign={'center'}
        marginBottom={'32px'}
      >
        Location Members{' '}
        <Typography component={'span'} fontWeight={'500'}>
          aren’t available on your current plan. Upgrade Now to access this
          feature.
        </Typography>
      </Typography>
      <TalkToSalesButton onClick={handleClickTalkToSales} variant="contained">
        Talk to Sales
      </TalkToSalesButton>
    </Stack>
  );
};

export default EmptyState;
