import { Stack } from '@mui/material';
import styled from '@mui/system/styled';

import CustomButton from 'components/Button/CustomButton';

export const TaskContentCnt = styled('div')({
  padding: '12px 0 15px 0',
  '& .taskDashboardHeader': {
    padding: '0px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
});

export const TaskListWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flex: 1,
}));

export const Header = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  paddingBottom: '8px',
}));

export const FilterButton = styled(CustomButton, {
  shouldForwardProp: (prop) => !['isfilterapplied'].includes(prop),
})(({ isfilterapplied }) => ({
  height: 30,
  marginRight: isfilterapplied ? 10 : 0,
  padding: '3px 16px',
  color: isfilterapplied ? '#fff' : 'rgba(0, 0, 0, 0.87)',
  background: isfilterapplied ? 'rgba(104, 104, 254, 1)' : 'transparent',
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 22,
  letterSpacing: '0.46px',
  ':hover': {
    background: isfilterapplied
      ? 'rgba(104, 104, 254, 1)'
      : 'rgba(104,104,254,0.04);',
  },
  '& .MuiButton-startIcon': {
    marginRight: 2,
    height: 18,
    width: 18,
    alignItems: 'center',
    '& svg': {
      color: isfilterapplied ? '#fff' : 'rgba(117, 117, 117, 1)',
    },
  },
}));

export const VisibilityButton = styled(CustomButton, {
  shouldForwardProp: (prop) => !['isfilterapplied'].includes(prop),
})(() => ({
  height: 30,
  padding: '6px 8px',
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 22,
  letterSpacing: '0.46px',
  marginRight: 8,
  '& .MuiButton-startIcon': {
    marginRight: 4,
    height: 18,
    width: 18,
    alignItems: 'center',
    '& svg': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 18,
    },
  },
}));

export const IntroBannerCnt = styled(Stack)(() => ({
  borderRadius: 12,
  background: 'url(/assets/img/onboarding-logo.png) #EBEAFF no-repeat 100% 1px',
  backgroundSize: 'contain',
  gap: 5,
  padding: '14px 20px 14px 20px',
  margin: '0px 20px 10px 20px',
  position: 'relative',

  '& .crossCnt': {
    borderRadius: '50%',
    position: 'absolute',
    right: -5,
    top: -6,
    backgroundColor: '#78909C',
    height: 20,
    width: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      height: 15,
      '& path': {
        stroke: 'white',
      },
    },
  },

  '& img': {
    height: 36,
    width: 36,
  },

  '& .statement': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20.4px',
    letterSpacing: '0.15px',
    color: '#000000',
  },
}));
