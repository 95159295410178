import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { Outlet } from 'react-router';
import Box from '@mui/material/Box';
import Header from 'components/layout/header/header';
import Sidebar from 'components/layout/sidebar/sidebar';
import styled from '@mui/system/styled';
import { useInitialDataLoad } from 'utils/CustomHooks/useInitialDataLoad';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import AllDialogs from 'components/AllDialogs';
import { PAGE_TITLES } from 'utils/globalVariable';
import { useReactRouter } from 'controller/useReactRouter';

const drawerWidth = 250;
const Main = styled('main', {
  shouldForwardProp: (prop) =>
    !['open', 'supresstopmargin', 'topBar'].includes(prop),
})(({ theme, open, supresstopmargin, topBar }) => ({
  marginTop: supresstopmargin ? 0 : 48,
  ...(topBar && !supresstopmargin && { marginTop: 95 }),
  flexGrow: 1,
  zIndex: 999,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
  },
}));

export default function Layout() {
  const { handleGetPathName } = useReactRouter();
  const { workspaceId } = useWorkspaceHook();
  const orgHotelName: any = useSelector(selectors.getPropertyName);
  const appState = useSelector(selectors.getAppState);
  const open = useSelector(selectors.getMenuState);
  const isChecklistRoute = appState?.viewTitle === PAGE_TITLES.newChecklist;
  const isNotificationRoute = handleGetPathName().includes('/my-notifications');
  const { loadData, isLoading } = useInitialDataLoad(workspaceId as string);
  const topAlertState = useSelector(selectors.getTopAlertState);

  useEffect(() => {
    if (orgHotelName && orgHotelName != '') {
      document.title = `Xenia - ${orgHotelName}`;
    }
  }, [orgHotelName]);

  useEffect(() => {
    workspaceId && loadData();
  }, [workspaceId]);

  useEffect(() => {
    const appcuesChecklist = document.getElementsByClassName('apcl-left')?.[0];
    if (appcuesChecklist) {
      appcuesChecklist['style'].left = open ? 220 : 85;
    }
  }, [open]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AllDialogs />
      {globalThis?.platform !== 'mobile' && <Header open={open} />}
      {globalThis?.platform !== 'mobile' && <Sidebar open={open} />}
      {!isLoading && (
        <Main
          open={open}
          supresstopmargin={
            globalThis?.platform === 'mobile' ||
            isChecklistRoute ||
            isNotificationRoute
          }
          topBar={topAlertState.show}
        >
          <Outlet />
        </Main>
      )}
    </Box>
  );
}
