import {
  AllUserAndTeamLabel,
  MainCnt,
  Value,
} from 'pages/Templates/TemplateListView/list.style';

import UserAvatar from 'components/Avatar';
import moment from 'moment-timezone';
import TemplateActions from './renderers/actions/actions';
import TemplateAdmins from './renderers/admins/admins';
import UserOrTeamAvatarGroup from 'components/Avatar/UserOrTeamAvatarGroup';
import Name from './renderers/name/name';

export const rowHeight = 62;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};

// Columns listing

export const columns: any = (props) => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      colId: 'name',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 300,
      cellRenderer: cellRenderer.name,
    },
    {
      field: 'Creator',
      headerName: 'Created by',
      colId: 'Creator',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 250,
      valueGetter: valueGetters.createdBy,
      cellRenderer: cellRenderer.createdBy,
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      colId: 'createdAt',
      minWidth: 170,
      valueGetter: valueGetters.createdOn,
      cellRenderer: cellRenderer.createdOn,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Last updated',
      colId: 'updatedAt',
      width: 170,
      valueGetter: valueGetters.lastUpdated,
      cellRenderer: cellRenderer.lastUpdated,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: 'itemsCount',
      headerName: 'No. of items',
      colId: 'itemsCount',
      width: 170,
      valueGetter: valueGetters.itemsCount,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      cellRenderer: cellRenderer.itemsCount,
    },

    {
      field: 'logsCount',
      headerName: 'No. of Submissions',
      colId: 'logsCount',
      width: 200,
      suppressMovable: true,
      suppressMenu: true,
      valueGetter: valueGetters.submissionsCount,
      sortable: true,
      cellRenderer: cellRenderer.submissionsCount,
    },

    {
      field: 'templateAdmins',
      headerName: 'Template Admins',
      colId: 'templateAdmins',
      suppressMovable: true,
      suppressMenu: true,
      sortable: true,
      width: 200,
      valueGetter: valueGetters.templateAdmins,
      cellRenderer: cellRenderer.templateAdmins,
    },
    {
      field: 'templateSubmitters',
      headerName: 'Template Submitters',
      colId: 'templateSubmitters',
      cellStyle: { display: 'flex', alignItems: 'center' },
      suppressMovable: true,
      suppressMenu: true,
      sortable: false,
      width: 200,
      cellRenderer: cellRenderer.templateSubmitters,
    },

    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      width: 270,
      suppressMenu: true,
      cellClass: 'disableRowClick',
      pinned: 'right',
      cellRenderer: (params) => cellRenderer.actionColumn(params, props),
    },
  ];
};

// Cell Renderer
export const cellRenderer = {
  name: (params) => {
    if (!params?.data) return;
    return <Name template={params?.data} />;
  },
  createdBy: (params) => {
    if (!params?.data) return;
    const { Creator } = params.data;
    return (
      <MainCnt>
        <UserAvatar
          width={24}
          height={24}
          firstName={Creator?.firstName}
          profilePic={Creator?.photo}
          userId={Creator?.id}
        />
        <Value style={{ marginLeft: 10 }}>
          {Creator?.firstName} {Creator?.lastName}
        </Value>
      </MainCnt>
    );
  },
  itemsCount: (params) => {
    if (!params?.data) return;
    const { itemsCount } = params.data;
    return (
      <MainCnt>
        <Value>{itemsCount}</Value>
      </MainCnt>
    );
  },
  submissionsCount: (params) => {
    if (!params?.data) return;
    const { logsCount } = params.data;
    return (
      <MainCnt>
        <Value>{logsCount}</Value>
      </MainCnt>
    );
  },
  templateAdmins: (params) => {
    if (!params?.data) return;
    return <TemplateAdmins template={params?.data} />;
  },
  templateSubmitters: (params) => {
    if (!params?.data) return;
    const { ChecklistAccess } = params.data;
    const templateSubmitters = ChecklistAccess?.filter(
      (access) => access.role === 'submitter',
    );
    return templateSubmitters?.length ? (
      <UserOrTeamAvatarGroup
        members={templateSubmitters.map((access) => access?.AccessorId)}
      />
    ) : (
      <AllUserAndTeamLabel>All users & teams</AllUserAndTeamLabel>
    );
  },

  createdOn: (params) => {
    if (!params?.data) return;
    const { createdAt } = params.data;
    return (
      <MainCnt>
        <Value>{moment(createdAt).format('MM/DD/YY')}</Value>
      </MainCnt>
    );
  },

  lastUpdated: (params) => {
    if (!params?.data) return;
    const { updatedAt } = params.data;
    return (
      <MainCnt>
        <Value>{moment(updatedAt).format('MM/DD/YY')}</Value>
      </MainCnt>
    );
  },
  actionColumn: (params, props) => {
    if (!params?.data) return;
    return (
      <TemplateActions
        template={params?.data}
        folderId={props.selectedFolder}
      />
    );
  },
};

// Value Getters

export const valueGetters = {
  name: (params) => {
    const { name } = params.data;
    return name || '-';
  },
  itemsCount: (params) => {
    const { itemsCount } = params.data;
    return itemsCount;
  },
  submissionsCount: (params) => {
    const { logsCount } = params.data;
    return logsCount || '-';
  },
  templateAdmins: (params) => {
    const { templateAdmins } = params.data;
    return templateAdmins || '-';
  },
  createdBy: (params) => {
    const { Creator } = params.data;
    return Creator?.firstName || '-';
  },
  createdOn: (params) => {
    const { createdAt } = params.data;
    return createdAt || '-';
  },
  lastUpdated: (params) => {
    const { updatedAt } = params.data;
    return updatedAt || '-';
  },
};
