import {
  useMemo,
  useState,
  Fragment,
  useCallback,
  useContext,
  useEffect,
} from 'react';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import BulkSelection from './BulkSelection';
import LogDrawer from 'pages/Widgets/SubmissionsListing/LogDrawer';

// Utils
import { getColumns, ss_defaultColDef } from './utils';
import { DragStoppedEvent } from 'ag-grid-community';
import { TData } from 'memfs/lib/volume';

// Styles
import './styles.css';

// Context
import { SpreadsheetContext } from '../context';

import FullWidthCell from './CellRenderers/FullWidthCell';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

const Table = ({ height }) => {
  const context = useContext(SpreadsheetContext);
  const flaggedCategories = useSelector(selectors.getFlaggedItemsCategories);
  const [gridApi, setGridApi] = useState<any>(null);

  const {
    selectedSubmission,
    showDownloadOptions,
    template,
    selectedRows,
    columnsConfig,
    rows,
    setSelectedRows,
    setSelectedSubmission,
    setShowDownloadOptions,
    handleArchiveLogs,
    handleClickOpenLog,
    editColumnConfig,
    setColumnsConfig,
    loadNextPage,
    handleClickStartButton,
  } = context;

  const formattedRows = useMemo(() => {
    return [
      // {
      //   custom: true,
      //   id: 'pinnedData',
      // },
      ...(rows ?? []),
    ];
  }, [rows]);

  const locationsMap = useLocationsMap();

  // runs when ag grid is ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    const columnToFocus = params.columnApi.getAllDisplayedColumns()[1];
    params.api.ensureColumnVisible(columnToFocus);
    params.api.setFocusedCell(0, columnToFocus);
  };
  const getRowHeight = (params) => {
    return 40;
  };
  // runs on row click of ag grid
  const handleRowClick = (row) => {
    console.log(row);
  };

  // runs when open is clicked in name cell
  const onClickOpenCallback = useCallback((logId: string) => {
    handleClickOpenLog?.(logId);
  }, []);

  const tableColumns = useMemo(
    () =>
      getColumns({
        onClickOpenCallback,
        columnsConfig,
        handleArchiveLogs,
        handleClickStartButton,
        template,
        locationsMap,
        flaggedCategories: flaggedCategories?.list ?? [],
      }),
    [columnsConfig],
  );

  const onSelectionChanged = () => {
    if (gridApi) {
      const selected = gridApi.getSelectedRows();
      setSelectedRows?.(selected.map((s) => s.id));
    }
  };

  const handleClearSelection = () => {
    setSelectedRows?.([]);
    gridApi.deselectAll();
  };

  const onDragStopped = async (event: DragStoppedEvent<TData>) => {
    let currentColumns = event.columnApi.getColumnState();
    currentColumns = currentColumns.slice(3);
    const newConfig = currentColumns.map((col) => ({
      id: col.colId,
      description: columnsConfig.find((conf) => conf.id === col.colId)
        .description,
      type: columnsConfig.find((conf) => conf.id === col.colId).type,
      visible: true,
    }));
    const updatedConfig = await editColumnConfig?.(newConfig);
    setColumnsConfig?.(updatedConfig);
  };
  return (
    <Fragment>
      {handleArchiveLogs && !!selectedSubmission && (
        <LogDrawer
          open={!!selectedSubmission}
          onClose={() => setSelectedSubmission?.(null)}
          log={selectedSubmission}
          handleArchiveLog={(logs) =>
            handleArchiveLogs(logs.map((log) => log.id))
          }
        />
      )}
      <CustomListView
        rootProps={{
          className: 'spread-sheet',
          style: {
            height,
            boxShadow: 'none',
          },
        }}
        onRowClick={handleRowClick}
        gridProps={{
          ref: (ref) => {
            globalThis.spreadSheet = ref;
          },
          getRowHeight: getRowHeight,
          rowData: formattedRows,
          suppressDragLeaveHidesColumns: true,
          tooltipShowDelay: 500,
          columnDefs: tableColumns,
          rowSelection: 'multiple',
          rowMultiSelectWithClick: true,
          suppressCellFocus: false,
          suppressRowClickSelection: true,
          defaultColDef: ss_defaultColDef,
          getRowId: (params) => params?.data?.id,
          onSelectionChanged,
          onGridReady,
          onDragStopped,
          onBodyScrollEnd: (params) => {
            const { direction } = params;
            if (direction === 'vertical') {
              loadNextPage?.();
            }
          },
          // isRowSelectable: (params) => {
          //   return !params.data.custom;
          // },
          pinnedTopRowData: [{ custom: true, id: 'pinnedData' }],
          // isFullWidthRow: (params) => {
          //   return params.rowNode?.data?.custom;
          // },
          // fullWidthCellRenderer: () => (
          //   <FullWidthCell handleClickStartButton={handleClickStartButton} />
          // ),
        }}
      />
      <BulkSelection
        templateId={template?.id}
        selected={selectedRows}
        onClearSelection={handleClearSelection}
        handleArchiveLogs={handleArchiveLogs}
        showDownloadOptions={showDownloadOptions}
        setShowDownloadOptions={setShowDownloadOptions}
      />
    </Fragment>
  );
};

export default Table;
