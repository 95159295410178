import React, { FC, useState, useEffect, useMemo } from 'react';
import { find, groupBy, keys, map } from 'lodash';
import { Form } from 'react-bootstrap';
import { validateName } from 'utils/globalFunction';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import selectors from 'store/selectors';
import CustomButton from 'components/Button/CustomButton';
import WorkspaceAvatar from 'components/Avatar/WorkspaceAvatar/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import xeniaApi from 'api/index';
import WSColorsDropdown from 'pages/CreateWorkspace/WSColorsDropdown';
import EditIcon2 from 'components/Icons/editIcon2';
import { HasPermission } from 'components/HasPermission';

import DeleteWSAlertDialog from 'pages/organization-management/DeleteWSAlert';
import {
  SettingsHeader,
  SettingsMain,
  SettingsTitle,
} from 'components/common/styledComponents/settings.style';
import { colors } from 'components/theme/constants';
import { useCurrency } from 'customhooks/index';
import WorkingHours from 'pages/organization-management/WorkingHours/WorkingHours';

const OrganizationManagement: FC = () => {
  const timezones = useSelector(selectors.getTimezones);
  const { workspace, deleteWorkspace } = useWorkspaceHook();
  const { options: currencyOptions, currency } = useCurrency();
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const [orgName, setOrgName] = useState('');
  const [color, setColor] = useState('');
  const [photo, setPhoto] = useState(null);
  const [colorPanelOpen, setColorPanelOpen] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedTz, setSelectedTimezone] = useState<any>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<any>(null);

  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(actions.loadTimezones());
  }, []);

  const resetForm = () => {
    const name = workspace?.Hotel?.name;
    setOrgName(name as string);
    setColor(workspace?.Hotel?.avatar?.color as string);
    setPhoto(workspace?.Hotel?.avatar?.photo as any);
    const timezone = workspace?.Hotel?.timezone;
    setSelectedTimezone({ id: timezone, value: timezone, label: timezone });
  };

  useEffect(() => {
    resetForm();
  }, [workspace]);

  const toggleColorOpen = (e = null as any) => {
    setColorPanelOpen(colorPanelOpen ? null : e?.currentTarget);
  };

  const setWSColor = (color) => {
    setColor(color);
    toggleColorOpen();
  };

  const setWSPhoto = (photo) => {
    setPhoto(photo);
    toggleColorOpen();
  };

  const timezoneOptions = useMemo(() => {
    let options: any = timezones.map((t) => {
      const continent = t.tzCode.split('/')[0];
      return {
        label: t.label,
        value: t.tzCode,
        id: t.tzCode,
        continent,
      };
    });
    options = groupBy(options, 'continent');
    return map(keys(options), (label) => ({
      label,
      options: options[label],
    }));
  }, [timezones]);

  const selectedTimezone = useMemo(() => {
    const tz = find(timezones, (tz) => tz.tzCode === selectedTz?.value);
    if (tz) {
      return {
        label: tz.label,
        value: tz.tzCode,
        id: tz.tzCode,
      };
    }
    return selectedTz;
  }, [timezones, selectedTz]);

  useEffect(() => {
    setSelectedCurrency(currency);
  }, [currency]);

  const isChange = useMemo(() => {
    if (orgName !== workspace?.Hotel?.name) {
      return true;
    }
    if (color !== workspace?.Hotel?.avatar?.color) {
      return true;
    }
    if (photo !== workspace?.Hotel?.avatar?.photo) {
      return true;
    }
    if (selectedTimezone.id !== workspace?.Hotel?.timezone) {
      return true;
    }
    if (selectedCurrency.id !== workspace?.Hotel?.currency) {
      return true;
    }
    return false;
  }, [workspace, orgName, color, photo, selectedTimezone, selectedCurrency]);
  const updateWS = async () => {
    if (orgName.length > 0) {
      if (validateName(orgName.replace(/  +/g, ' '))) {
        const resp = await xeniaApi.editWorkspace({
          name: orgName,
          color,
          photo,
          timezone: selectedTimezone?.id || null,
          currency: selectedCurrency?.id || null,
          hotelId: workspace?.Hotel?.id,
        });
        if (resp) {
          dispatch(actions.reloadUserContext());
        }
        setError(false);
        return;
      }
      setError(true);
    } else {
      setError(true);
    }
  };

  const hasDeletePermission = hasPermission(PERMISSIONS.CAN_MANAGE_WORKSPACES);
  const canChangeWorkspaceConfig = hasPermission(
    PERMISSIONS.CAN_MANAGE_WORKSPACE_CONFIG,
  );

  return (
    <SettingsMain>
      <SettingsHeader style={{ display: 'flex', alignItems: 'center' }}>
        <SettingsTitle style={{ marginRight: 5 }}>Workspace</SettingsTitle>
      </SettingsHeader>
      <div className="layout-wrap hotal-management-layout">
        <div
          className="hotel-manage-content-wrap common-content-wrap"
          style={{ backgroundColor: colors.white }}
        >
          <Form className="organization-manage-form">
            <div className="row" style={{ marginLeft: 0 }}>
              <div>
                <div style={{ height: 20 }}></div>
                <div style={{ position: 'relative' }}>
                  <WorkspaceAvatar
                    width={48}
                    height={48}
                    workspaceId={workspace?.Hotel.id}
                    editColor={color}
                    editPhoto={photo}
                    photoChanged={photo !== workspace?.Hotel?.avatar?.photo}
                  />
                  {canChangeWorkspaceConfig && (
                    <div
                      onClick={toggleColorOpen}
                      style={{
                        position: 'absolute',
                        right: '-8px',
                        top: '28px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '5px',
                        boxShadow: '0px 3px 8px rgb(0 0 0 / 25%)',
                        height: '25px',
                        width: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <EditIcon2 width="17" />
                    </div>
                  )}
                </div>
                <WSColorsDropdown
                  setColor={setWSColor}
                  setPhoto={setWSPhoto}
                  color={color}
                  photo={photo}
                  hideButton={true}
                  canSetLogo={true}
                  popperProps={{
                    open: Boolean(colorPanelOpen),
                    anchorEl: colorPanelOpen,
                  }}
                />
              </div>
              <div className="col-md-4 col-sm-5 col-xs-12 col-12">
                <div className="form-group" style={{ marginBottom: '0px' }}>
                  <CustomInput
                    id="taskSearchInput"
                    suppressErrorSpace={true}
                    fieldProps={{
                      onChange: (e) => {
                        setOrgName(e.target.value);
                      },
                      placeholder: 'Search Tasks & Work Orders',
                      value: orgName,
                      disabled: !canChangeWorkspaceConfig,
                    }}
                    label="Workspace Name"
                    muiLabel={false}
                    topLabelInput={true}
                  />
                  <span className="error">
                    {error
                      ? 'Organization name cannot be empty or contain special characters.'
                      : ''}
                  </span>
                </div>
                <div className="form-group" style={{ marginBottom: '0px' }}>
                  <CustomSelect
                    label={'Timezone'}
                    placeholder="Select Timezone"
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    selectedOption={selectedTimezone}
                    options={timezoneOptions}
                    onChange={(e) => {
                      setSelectedTimezone(e);
                    }}
                    style={{
                      ...(!canChangeWorkspaceConfig && {
                        pointerEvents: 'none',
                      }),
                    }}
                  />
                </div>
                <div className="form-group">
                  <CustomSelect
                    label={'Currency'}
                    placeholder="Select Currency"
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    selectedOption={selectedCurrency}
                    options={currencyOptions}
                    onChange={(currency) => {
                      setSelectedCurrency(currency);
                    }}
                    style={{
                      ...(!canChangeWorkspaceConfig && {
                        pointerEvents: 'none',
                      }),
                    }}
                  />
                </div>
                <div className="row">
                  {(hasDeletePermission && (
                    <div
                      className="form-group"
                      style={{ display: 'flex', marginLeft: 17 }}
                    >
                      <CustomButton
                        variant={'outlined'}
                        color="error"
                        onClick={() => setOpenDeleteConfirm(true)}
                        buttonLoader={isLoading}
                      >
                        Delete Workspace
                      </CustomButton>
                      <DeleteWSAlertDialog
                        open={openDeleteConfirm}
                        handleClose={() => setOpenDeleteConfirm(false)}
                        success={() => deleteWorkspace(workspace?.Hotel?.id)}
                      />
                    </div>
                  )) ||
                    null}
                </div>
                {(isChange && (
                  <div className="form-group" style={{ display: 'flex' }}>
                    <CustomButton
                      variant={'contained'}
                      onClick={updateWS}
                      buttonLoader={isLoading}
                      sx={{ width: 120, borderRadius: '8px' }}
                    >
                      Save
                    </CustomButton>
                  </div>
                )) ||
                  null}
              </div>
            </div>
            <div
              className="row"
              style={{
                borderTop: '1px solid rgba(0, 0, 0, 12%)',
              }}
            >
              <div className="form-group"> </div>
            </div>
          </Form>
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_WORKSPACES]}>
            <div>
              <WorkingHours />
            </div>
          </HasPermission>
        </div>
      </div>
    </SettingsMain>
  );
};

export default OrganizationManagement;
