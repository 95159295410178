import { Stack, Typography } from '@mui/material';
import ImgRequestedTask from 'public/assets/img/ImgRequestedTask.svg';

function RequestedEmptyState() {
  return (
    <Stack alignItems={'center'} justifyContent={'center'} width="100%">
      <img
        style={{ maxWidth: 'none' }}
        src={ImgRequestedTask}
        width={'180px'}
        height={'auto'}
      />
      <Typography
        color="rgba(37, 35, 80, 1)"
        fontSize={'20px'}
        fontWeight={'800'}
        mb="10px"
        mt="-30px"
      >
        No Requested Tasks Yet
      </Typography>
      <Typography
        textAlign={'center'}
        color="#000"
        fontSize={'14px'}
        lineHeight={'22px'}
        fontWeight={'500'}
        width={'570px'}
      >
        Tasks will appear here once they are created from the Work Request
        Portal and approved. This space will help you track and manage requested
        tasks to ensure they are completed efficiently and on time.
      </Typography>
    </Stack>
  );
}

export default RequestedEmptyState;
