import styled from '@mui/system/styled';

export const AssetTile = styled('div')({
  padding: '10px 14px',
  border: '1px solid #0000001F',
  boxShadow: '0px 4px 4px 0px #0000000A',
  height: 44,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8,
  '.assetTitleLeftCnt': {
    display: 'flex',
    flexDirection: 'column',
    '.parentAssetName': {
      fontSize: 12,
      fontWeight: 500,
      color: '#6F6F6FDE',
      lineHeight: 'normal',
    },
  },
  '.assetTitle': {
    fontWeight: 500,
    fontSize: 15,
    color: '#000000DE',
    marginRight: 8,
    lineHeight: 'normal',
  },
  '&:hover': {
    cursor: 'pointer',
    border: '1px solid #6868FE',
    button: {
      display: 'inline-flex',
    },
  },
  button: {
    display: 'none',
  },
});
