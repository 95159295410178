import { useMemo, useCallback, useState } from 'react';
import { Box, Stack } from '@mui/material';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';

// Constants
import { columns, defaultColDef } from './constants';

// Styles
import './table.style.css';

interface IProps {
  height?: number | string;
  rowData: any;
  onRowClick?: (rowId: string) => void;
  onColumnChange?: (columns: any) => void;
  onBulkSelection?: (selected: any[]) => void;
  updateColumnStateCb?: (columnState: any) => void;
  removeScheduleHandler?: (scheduleId?: string) => void;
  pauseScheduleHandler?: (scheduleId?: string) => void;
  columnState?: any;
  project?: any;
  columnConfig?: {
    assignees?: boolean;
    location?: boolean;
    nextDue?: boolean;
  };
}

const defaultColumnConfig = {
  assignees: true,
  location: true,
  asset: true,
  nextDue: true,
  actions: true,
};

const ProjectsChildsTableView = ({
  rowData,
  height = 400,
  columnConfig,
  columnState,
  onRowClick,
  project,
  updateColumnStateCb,
  removeScheduleHandler,
  pauseScheduleHandler,
}: IProps) => {
  const [resultsFound, setResultsFound] = useState(true);

  const getRowHeight = useCallback(() => {
    return 50;
  }, []);

  const onColumnResized = (event) => {
    if (event?.finished && event.source !== 'api') {
      updateColumnStateCb?.(event.columnApi.getColumnState());
    }
  };

  const handleUpdateColumnState = (event) => {
    if (event.type === 'dragStopped') {
      updateColumnStateCb?.(event.columnApi.getColumnState());
    }
  };

  const gridColumns = useMemo(() => {
    const staticColumns = columns({
      onRowClick,
      project,
      removeScheduleHandler,
      pauseScheduleHandler,
      columnConfig: {
        ...defaultColumnConfig,
        ...columnConfig,
      },
    });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = columnState?.find((c) => c.colId === col.colId);
      const index = columnState?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [columnState, onRowClick, columnConfig, rowData]);

  const checkIfSearchResultIsEmpty = () => {
    const api = globalThis?.projectSchedules?.api;
    const allRows = api?.getDisplayedRowCount();
    if (allRows === 0) {
      setResultsFound(false);
    } else {
      setResultsFound(true);
    }
  };

  return (
    <Box style={{ position: 'relative' }}>
      {!resultsFound && (
        <Stack
          alignItems={'center'}
          style={{
            position: 'absolute',
            zIndex: '10',
            top: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <NoRecordsFound />
        </Stack>
      )}

      <CustomListView
        rootProps={{
          className: 'projects-schedules-table',
          style: {
            height,
            boxShadow: 'none',
            flex: 1,
          },
        }}
        onRowClick={onRowClick}
        gridProps={{
          ref: (ref) => {
            globalThis.projectSchedules = ref;
          },
          onGridReady: (params) => {
            params.api.sizeColumnsToFit();
          },
          headerHeight: 30,
          onSortChanged: handleUpdateColumnState,
          onDragStopped: handleUpdateColumnState,
          onColumnResized: onColumnResized,
          onColumnVisible: handleUpdateColumnState,
          onColumnPinned: handleUpdateColumnState,
          onColumnMoved: handleUpdateColumnState,
          onFilterChanged: checkIfSearchResultIsEmpty,
          rowData,
          suppressChangeDetection: true,
          defaultColDef: defaultColDef,
          columnDefs: gridColumns,
          suppressCellFocus: true,
          getRowHeight: getRowHeight,
          suppressRowClickSelection: true,
          getRowId: (params) => params.data.id,
        }}
      />
    </Box>
  );
};

export default ProjectsChildsTableView;
