// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Icons
import TimelineDotIcon from 'components/Icons/timelineDotIcon';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const DateChip = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 8px',
  borderRadius: '32px',
  border: '1px solid rgba(204, 203, 255, 1)',
  background: 'rgba(235, 234, 255, 1)',
  width: 'fit-content',
}));

export const DateText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '16px',
  letterSpacing: '0.15px',
  color: 'rgba(104, 104, 254, 1)',
}));

export const TimelineBordered = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '24px',
  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  marginLeft: '10px',
}));

export const TimelineItem = styled(Box)(() => ({
  position: 'relative',
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  padding: '10px 8px 10px 0px',
  width: '100%',
}));

export const TimelineDate = styled(Box)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '0.15px',
  color: 'rgba(158, 158, 158, 1)',
  marginLeft: 'auto',
}));

export const DotIcon = styled(TimelineDotIcon)(() => ({
  position: 'absolute',
  left: '-30px',
  top: '50%',
  transform: 'translate(0, -50%)',
  fill: 'none',
  fontSize: '12px',
}));
