import { statusDropdownData } from 'helper/task/dropdownData';
import { Assignee, Location, Schedule } from './renderers/renderers';
import { CheckListCnt, Container, StatusCell, Title } from './style';
import { Typography } from '@mui/material';
import ChecklistIcon from 'components/ChecklistIcon';

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};
export const cellRenderer = {
  title: (params) => {
    if (!params?.data) return;
    const { title } = params.data;
    return (
      <Container>
        <Title>{title}</Title>
      </Container>
    );
  },
  status: (params) => {
    const { taskStatus } = params.data;
    const selectedStatus = statusDropdownData.find(
      (s) => s.label === taskStatus,
    );
    return (
      <StatusCell
        color={selectedStatus?.color}
        bg={selectedStatus?.backgroundColor}
      >
        <span className={'statusText'}>{taskStatus}</span>
      </StatusCell>
    );
  },
  location: (params) => {
    if (!params?.data) return;
    const { Location: _location } = params.data;
    return _location ? <Location location={_location} /> : '-';
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { recurringByEvery } = params.data;
    return <Schedule recurringByEvery={recurringByEvery} />;
  },
  assignee: (row) => {
    if (!row?.data) return;
    const { TaskAssignees } = row.data;
    return <Assignee assignees={TaskAssignees} />;
  },
  templateAttached: (params, props) => {
    if (!params?.data) return;
    const { Checklist } = params.data;
    const { icon, name } = Checklist ?? {};

    return (
      <CheckListCnt>
        {icon && name ? (
          <div className="templateAttached">
            <ChecklistIcon icon={icon} style={{ minWidth: 24 }} />
            <Typography
              fontWeight={'400'}
              fontSize={'14px'}
              color={'rgba(0, 0, 0, 0.87)'}
              letterSpacing={'0.17px'}
            >
              {name}
            </Typography>
          </div>
        ) : (
          <span>-</span>
        )}
      </CheckListCnt>
    );
  },
};

export const valueGetters = {
  title: (params) => {
    if (!params?.data) return;
    const { title } = params.data;
    return title;
  },
  status: (params) => {
    if (!params?.data) return;
    const { status } = params.data;
    return status;
  },
  location: (params) => {
    if (!params?.data) return;
    const { location } = params.data;
    return location;
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { schedule } = params.data;
    return schedule;
  },
  assignee: (params) => {
    if (!params?.data) return;
    const { assignee } = params.data;
    return assignee;
  },
  templateAttached: (params) => {
    if (!params?.data) return;
    const { Checklist } = params.data;
    return Checklist?.name;
  },
};

export const columns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      sortable: true,
      valueGetter: valueGetters.title,
      cellRenderer: cellRenderer.title,
    },
    {
      field: 'status',
      headerName: 'Status',
      colId: 'status',
      sortable: true,
      cellRenderer: cellRenderer.status,
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
      width: 150,
    },
    {
      field: 'location',
      headerName: 'Location',
      colId: 'location',
      sortable: true,
      valueGetter: valueGetters.location,
      cellRenderer: cellRenderer.location,
      resizable: true,
      width: 150,
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      colId: 'schedule',
      sortable: true,
      valueGetter: valueGetters.schedule,
      cellRenderer: cellRenderer.schedule,
      resizable: true,
      width: 150,
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      colId: 'assignee',
      valueGetter: valueGetters.assignee,
      sortable: true,
      cellRenderer: cellRenderer.assignee,
    },
    {
      field: 'template',
      headerName: 'Template Attached',
      colId: 'template',
      sortable: true,
      cellRenderer: cellRenderer.templateAttached,
      valueGetter: valueGetters.templateAttached,
      width: 250,
    },
  ];
  return tableCells.filter((cell) => columnConfig[cell.field]);
};
