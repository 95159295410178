/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import { useEffect } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { NextButton, OrganizationInfoDiv } from './organizationInfo.style';
import { useOnboardState } from '../onboarding-provider';
import { useState } from 'react';
import xeniaApi from 'api/index';
import moment from 'moment';
import useUserLogin from 'utils/CustomHooks/useUserLogin';
import {
  getItem,
  isAndroidOrIOS,
  navigateToStore,
  removeLoginStorage,
  setItem,
} from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { Stack } from '@mui/material';

const OrganizationReason = (props) => {
  const { template, templateGroup } = props;
  const { setDeviceToken } = useUserLogin();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const {
    fullName,
    email,
    phone,
    phoneCode,
    password,
    organizationName,
    goal,
    setGoal,
    industry,
    jobTitle,
    invalidOrganizationName,
    teamSize,
    setOrganizationName,
    setInvalidOrganizationName,
    setHotelId,
    usecaseDetail,
    setUsecaseDetail,
  } = useOnboardState();

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [deviceType, setDeviceType] = useState('');

  const isMobileApp = globalThis?.platform === 'mobile';

  // Fetch Industries and Goals Data on Initial Load.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResp = await xeniaApi.getCategories(
          'hotel/checklist-categories',
        );

        if (categoryResp.category) {
          const sortedGoals = sortGoals(categoryResp.category);
          let goalsDropdown = sortedGoals.map((item) => {
            return {
              label: item.name,
              subTitle: item.description,
              value: item.id,
              id: item.id,
            };
          });
          setCategoryTypes(goalsDropdown);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();

    // Check Is IOS or Android
    setDeviceType(isAndroidOrIOS());
  }, []);

  // Sort Goals
  const sortGoals = (data) => {
    const goalsOrder = [
      'Operations',
      'Maintenance',
      'Quality & Safety',
      'Team Communication',
    ];

    return data.sort(function (a, b) {
      return goalsOrder.indexOf(a.name) - goalsOrder.indexOf(b.name);
    });
  };

  const goalChangeHandler = (g) => {
    if (goal?.some((item) => item.id === g.id)) {
      setGoal(goal.filter((item) => item.id !== g.id));
    } else {
      setGoal([...goal, g]);
    }
  };

  // Validations
  const validateCompanyName = () => {
    if (organizationName.length > 0) {
      setInvalidOrganizationName('');
      return true;
    }
    setInvalidOrganizationName('Organization Name is Required*');
    return false;
  };

  const validateGoal = () => {
    if (goal) {
      return true;
    }
    return false;
  };

  const deviceBasedRedirect = (hotelId, userData) => {
    if (isMobileApp) {
      removeLoginStorage();
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          action: 'loginWithUserData',
          data: userData,
        }),
      );
      return;
    }
    if (!navigateToStore()) {
      navigateWithWorkspaceUrl('/dashboard', hotelId, {
        replace: true,
      });
    }
  };

  // Set Meta data for Industries and Goals
  const setMetaInformation = async (hotelId, userData) => {
    if (validateGoal()) {
      try {
        const data = {
          ChecklistIndustryId: industry?.id,
          ChecklistCategoryIds: goal?.length ? goal.map((g) => g.id) : [],
          useCaseDetail: usecaseDetail,
        };

        setIsLoading(true);
        const resp = await xeniaApi.addIndustryAndGoals(
          `hotel/hotels/${hotelId}/metadata`,
          data,
        );
        if (resp.user) {
          deviceBasedRedirect(hotelId, userData);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      deviceBasedRedirect(hotelId, userData);
    }
  };

  // Handle Next Click
  const handleNextClick = async () => {
    if (validateCompanyName()) {
      try {
        const templateId = getItem('template-id');
        const templateGroupId = getItem('template-group-id');

        const data = {
          hotelName: organizationName,
          jobTitle,
          hotelTimezone: moment.tz.guess(),
          hotelMinUsers: teamSize.min,
          hotelMaxUsers: teamSize.max,
          adminFullName: fullName,
          adminEmailId: email,
          adminPhoneNo: phone ? phoneCode + phone : null,
          adminPassword: password,
          templateId: templateId || null,
          templateGroupId: templateGroupId || null,
        };
        setIsLoading(true);
        await setDeviceToken();
        const resp = await xeniaApi.createNewHotel(
          'hotel/accounts/claim',
          data,
        );

        if (resp.user) {
          const { HotelId, id, verificationToken } = resp.user;

          await setHotelId(HotelId);
          setItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN, verificationToken);
          setItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID, id);

          if (!isMobileApp) {
            dispatch(actions.setLoggedInUser(resp.user));

            window?.ServiceBell?.('identify', id, {
              Name: fullName,
              Email: email,
              Title: jobTitle,
              Team_Size: `${teamSize.min} - ${teamSize.max}`,
              Company: organizationName,
              Industry: industry?.label,
              Goal: goal?.label,
              Template: template?.name,
            });

            window?.ServiceBell?.('alert', {
              title: 'A New User Signup',
              body: `
            Name: ${fullName}
            Email: ${email}
            Title: ${jobTitle || 'N/A'}
            Phone: ${phone ? phoneCode + phone : 'N/A'}
            Team_Size: ${teamSize?.min} - ${teamSize?.max}
            Company: ${organizationName}
            Industry: ${industry?.label}
            Goal: ${goal?.label}
            Template: ${template?.name || 'N/A'}
            `,
            });

            setTimeout(async () => {
              await window?.ServiceBell?.('startJourney', {
                journeyId: 366,
              });
            }, 1000);
          }

          // If Success save Meta Information
          await setMetaInformation(HotelId, resp.user);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <OrganizationInfoDiv>
      <Stack>
        <p className="title">What are you trying to solve with Xenia?</p>
        <p className="subTitle">
          It helps us further personalize your experience
        </p>
        <Stack maxHeight={'580px'} overflow="auto" gap="16px">
          <CustomInput
            value={usecaseDetail}
            onChange={(e) => setUsecaseDetail(e.target.value)}
            suppressErrorSpace
            fieldProps={{
              multiline: true,
              rows: 6,
              placeholder: 'Enter here what you would like to do with Xenia',
              sx: {
                p: '12px 16px',
                '& .MuiOutlinedInput-input': {
                  p: '0px !important',
                  color: '#616161',
                  fontSize: '16px !important',
                  fontWeight: '500',
                },
              },
            }}
          />
        </Stack>
        <NextButton
          variant="contained"
          onClick={handleNextClick}
          buttonLoader={isLoading}
          style={{ width: '100%', margin: '30px 0px 10px 0px', height: 47 }}
        >
          {!deviceType ? 'Next' : 'Submit & Go to App'}
        </NextButton>
      </Stack>
    </OrganizationInfoDiv>
  );
};

export default OrganizationReason;
