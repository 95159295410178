import { FC } from 'react';
import CTypography from 'components/CTypography';
import AppleStore from 'public/assets/img/appleStore.svg';
import GoogleStore from 'public/assets/img/googleStore.svg';
import { DownloadCnt } from './style';
import { useDispatch } from 'react-redux';
import DIALOGS from 'utils/dialogIds';
import actions from 'store/actions';

const GetStarted: FC = () => {
  const dispatch = useDispatch();

  const handleShowDownloadMobileAppDialog = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG,
        open: true,
      }),
    );
  };
  return (
    <DownloadCnt>
      <CTypography>Download Xenia app on</CTypography>
      <div className="actionsCnt">
        <img
          src={AppleStore}
          style={{ marginRight: 10, cursor: 'pointer' }}
          onClick={handleShowDownloadMobileAppDialog}
        />
        <img
          src={GoogleStore}
          onClick={handleShowDownloadMobileAppDialog}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </DownloadCnt>
  );
};

export default GetStarted;
