/* eslint-disable prettier/prettier */
import styled from '@mui/system/styled';

export const OtpDiv = styled('div')({
  display: 'flex',
  marginBottom: 22,
  '& .margin-top--small': {
    marginTop: '1rem',
  },
  '& .inputStyle': {
    width: '48px',
    height: '48px',
    margin: '0 1rem',
    fontSize: '2rem',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  '& .btn-row': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
