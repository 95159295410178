import styled from '@mui/system/styled';

export const Parent = styled('div')({
  height: '100%',
});

export const Container = styled('div')({
  background: '#FEEBEE',
  borderRadius: 8,
  marginBottom: 12,
  '.overdueGroupTitleCnt': {
    background: '#FEEBEE',
    padding: 10,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 44,
    gap: 10,
    '&:hover': {
      cursor: 'pointer',
    },
    '.overdueGroupTitleLeftCnt': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    '.overdueScheduleTitle': {
      fontSize: 14,
      lineHeight: '19px',
      color: '#C62828',
      fontWeight: 700,
    },
    '& .warningIcon': {
      fontSize: 24,
      color: '#C62828',
    },
    '& .arrowIcon': {
      fontSize: 30,
      color: '#C62828',
    },
  },
  '& .overdueTaskCnt': {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    padding: '0px 12px 12px 12px',
  },
});

export const OtherTasks = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  marginBottom: 12,
});

export const Date = styled('span')({
  fontSize: 15,
  fontWeight: 900,
  color: '#757575',
  lineHeight: '20.8px',
  marginBottom: 8,
  marginTop: 14,
  display: 'block',
});
