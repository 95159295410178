import { Stack, Typography } from '@mui/material';
import RolesActionDropdown from './renderers/actions';
import moment from 'moment';
import {
  CreatedByRenderer,
  ReportsToRenderer,
  UsersRenderer,
} from './renderers/renderers';

export const defaultColDef = {
  sortable: true,
  filter: true,
  suppressMenu: true,
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  lockVisible: true,
};

export const generateColumns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      valueGetter: valueGetters.title,
      cellRenderer: cellRenderer.title,
    },
    {
      field: 'reportsTo',
      headerName: 'Reports To',
      colId: 'reportsTo',
      valueGetter: valueGetters.reportsTo,
      cellRenderer: cellRenderer.reportsTo,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      colId: 'createdBy',
      valueGetter: valueGetters.createdBy,
      cellRenderer: cellRenderer.createdBy,
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      colId: 'lastUpdated',
      valueGetter: valueGetters.lastUpdated,
      cellRenderer: cellRenderer.lastUpdated,
    },
    {
      field: 'users',
      headerName: 'Users',
      colId: 'users',
      valueGetter: valueGetters.users,
      cellRenderer: cellRenderer.users,
    },
    {
      field: 'actionCol',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: 'disableRowClick',
      cellRenderer: (params) => cellRenderer.actions(params, props),
    },
  ];

  return tableCells.filter((cell) => columnConfig[cell.field]);
};
const valueGetters = {
  title: (params) => {
    if (!params.data) return null;
    const { title } = params?.data || {};
    return title;
  },
  reportsTo: (params) => {
    if (!params.data) return null;
    const { ReportTo } = params?.data || {};
    return ReportTo;
  },
  createdBy: (params) => {
    if (!params.data) return null;
    const { CreatedBy } = params?.data || {};
    return CreatedBy;
  },
  lastUpdated: (params) => {
    if (!params.data) return null;
    const { updatedAt } = params?.data || {};
    return updatedAt;
  },
  users: (params) => {
    if (!params.data) return null;
    const { HotelUsers } = params?.data || {};
    return HotelUsers;
  },
};
const cellRenderer = {
  title: (params) => {
    if (!params?.data) return;
    const { title } = params?.data || {};
    return (
      <Stack justifyContent="center" style={{ height: '100%' }}>
        <Typography
          fontWeight="700"
          fontSize="14px"
          lineHeight="19.12px"
          color="#212121"
        >
          {title || '-'}
        </Typography>
      </Stack>
    );
  },
  reportsTo: (params) => {
    if (!params?.data) return;
    const { ReportTo } = params?.data || {};
    return ReportTo ? <ReportsToRenderer reportTo={ReportTo} /> : '-';
  },
  createdBy: (params) => {
    if (!params?.data) return;
    const { CreatedBy } = params?.data || {};
    return CreatedBy ? <CreatedByRenderer createdBy={CreatedBy} /> : '-';
  },
  lastUpdated: (params) => {
    if (!params?.data) return;
    const { updatedAt } = params?.data || {};
    return (
      <Stack justifyContent="center" style={{ height: '100%' }}>
        <Typography
          fontWeight="500"
          fontSize="14px"
          lineHeight="19.12px"
          color="#212121"
        >
          {updatedAt ? moment(updatedAt).format('MMM DD, YYYY') : '-'}
        </Typography>
      </Stack>
    );
  },
  users: (params) => {
    if (!params?.data) return;
    const { HotelUsers } = params?.data || {};
    return <UsersRenderer users={HotelUsers} />;
  },
  actions: (params, props) => {
    if (!params?.data) return;
    const { rolesActionDropdownProps } = props;
    return (
      <Stack justifyContent="center" style={{ height: '100%' }}>
        <RolesActionDropdown
          data={params?.data}
          rolesActionDropdownProps={rolesActionDropdownProps}
        />
      </Stack>
    );
  },
};
