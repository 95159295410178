import DowngradeModal from 'components/AllDialogs/billing/DowngradeModal';
import ConfirmationDialog from 'components/ConfirmationDialog';
import UpSellModal from 'components/UpSellModal';
import PlanExpired from 'pages/PlanExpired/RenewPlan';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialog';
import DIALOGS from 'utils/dialogIds';

const PricingStandalonePage: FC<any> = () => {
  const dialogState = useSelector(getDialogState);
  return (
    <>
      <DowngradeModal />
      <ConfirmationDialog dialogId={DIALOGS.CONFIRMATION} />
      {dialogState?.[DIALOGS.UPSELL_MODAL]?.open && <UpSellModal />}
      <PlanExpired />
    </>
  );
};

export default PricingStandalonePage;
