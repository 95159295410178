// React
import { useState, useContext, useMemo } from 'react';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import AdvancedFilterBuilder from 'components/TemplateDashboard/SubmissionListWithFilters/AdvancedFilterBuilder';
import CustomButton from 'components/Button/CustomButton';

// Icons
import FiltersIcon from 'components/Icons/filtersIcon';
import CancelIcon from '@mui/icons-material/Cancel';

// Context
import { SubmissionContext } from 'pages/TemplateDashboard/Tabs/Submissions/context';

const FiltersDropdown = () => {
  const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const ctx = useContext(SubmissionContext);

  const handleCallback = (elem: any) => {
    setIsActive(!!elem);
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const handleSetFiltersCb = (filters: any[]) =>
    ctx?.handleSetAdvancedFilters(filters);

  const deleteAllFilters = (e) => {
    e.stopPropagation();
    ctx?.handleSetAdvancedFilters([]);
  };

  const hasFilters = useMemo(() => {
    return ctx?.advancedFilters && ctx?.advancedFilters?.length > 0;
  }, [ctx?.advancedFilters]);

  const handleClickFilterDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <CustomDropdown
      popperProps={{
        content: (
          <AdvancedFilterBuilder
            handleSetFiltersCb={handleSetFiltersCb}
            handleClose={handleClose}
            filters={ctx?.advancedFilters?.length ? ctx?.advancedFilters : null}
            hideFilters="locations completedAt completedBy template duration flagCategories"
          />
        ),
        placement: 'bottom-start',
        open: Boolean(anchorEl),
        anchorEl,
      }}
      paperProps={{
        sx: {
          padding: '20px 24px',
        },
      }}
      handleCallback={handleCallback}
      onClickawayCallback={() => handleCallback(null)}
      iconButton={false}
      buttonRenderer={
        <CustomButton
          onClick={handleClickFilterDropdown}
          variant="outlined"
          startIcon={<FiltersIcon />}
          endIcon={<CancelIcon onClick={deleteAllFilters} />}
          sx={{
            color: 'rgba(97, 97, 97, 1)',
            border: '1px solid rgba(224, 224, 224, 1)',
            height: '40px',
            borderRadius: '8px',
            background: isActive ? 'rgba(104, 104, 254, 0.12)' : '',
            '&:hover': {
              border: '1px solid rgba(224, 224, 224, 1)',
              background: 'rgba(104, 104, 254, 0.12)',
            },
            ...(isActive && {
              '& svg': {
                color: 'rgba(104, 104, 254, 1)',
              },
            }),
            ...((isActive || hasFilters) && {
              '& .MuiButton-endIcon svg': {
                color: '#EF5350',
              },
              '& svg': {
                color: 'rgba(104, 104, 254, 1)',
              },
            }),
            '& .MuiButton-endIcon': {
              position: 'absolute',
              zIndex: 50,
              right: '-2px',
              top: '-6px',
              color: '#EF5350',
              background: 'white',
              borderRadius: '20px',
              '& svg': {
                fontSize: '16px !important',
              },
              ...(!hasFilters && { display: 'none' }),
            },
          }}
        >
          Filters
        </CustomButton>
      }
    />
  );
};

export default FiltersDropdown;
