import { useSelector, useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CTypography from 'components/CTypography';
import { IconButton, Stack } from '@mui/material';
import CloseIcon from 'components/Icons/closeIcon';
import { useEffect, useMemo, useState } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import useCustomizeLocationsHierarcy from 'utils/CustomHooks/useCustomizeLocationsHierarcy';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from 'components/Icons/addIcon';

import ChildItemIcon from 'components/Icons/childItemIcon';
import useResponsive from 'utils/CustomHooks/useResponsive';

import selectors from 'store/selectors';
import actions from 'store/actions';

import DIALOGS from 'utils/dialogIds';

import { cloneDeep } from 'lodash';

import { getLocationsLevelsState } from 'store/selectors/locationsLevels';

import {
  AddNewLocationLevel,
  ContentWrapper,
  CustomModalWrapper,
  FooterWrapper,
  HeaderWrapper,
  InfoWrapper,
  InfoWrapperCnt,
} from './style';

const CustomizeLocationHierarcy = () => {
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.CUSTOMIZE_LOCATION_HIERARCY];

  const { saveLocationsHierarcyInfo } = useCustomizeLocationsHierarcy();

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.CUSTOMIZE_LOCATION_HIERARCY));
  };
  const locationsLevels = useSelector(getLocationsLevelsState);
  const [locationsHierarcyData, setLocationsHierarcyData] = useState<any>([
    {
      title: '',
      order: 1,
    },
  ]);

  useEffect(() => {
    if (locationsLevels?.length > 0) {
      setLocationsHierarcyData(locationsLevels);
    }
  }, [locationsLevels]);

  const [isSaving, setIsSaving] = useState(false);

  // Data Change Handler
  const changeHandler = (event, index) => {
    if (event?.target?.value?.length > 100) return;
    const clonedData = cloneDeep(locationsHierarcyData);
    clonedData[index].title = event?.target?.value;
    setLocationsHierarcyData(clonedData);
  };

  const transformRequest = () => {
    return locationsHierarcyData?.map((item) => {
      return {
        ...(item?.id && { id: item?.id }),
        ...(item?.title && { title: item?.title }),
        ...(item?.order && { order: item?.order }),
      };
    });
  };

  // Save Handler for the locations Hierarcy
  const saveHandler = async () => {
    setIsSaving(true);
    const savedData = await saveLocationsHierarcyInfo({
      levels: transformRequest(),
    });

    setLocationsHierarcyData(savedData);
    dispatch(actions.setLocationsLevels(savedData));
    setIsSaving(false);
  };

  // Add New Location in UI
  const addNewHandler = () => {
    setLocationsHierarcyData([
      ...locationsHierarcyData,
      {
        title: '',
        order: locationsHierarcyData?.length + 1,
      },
    ]);
  };

  // Is Disable Check for the Save Button
  const isDisabledSaveBtn = useMemo(() => {
    const isEmpty = locationsHierarcyData?.some((item) => item?.title === '');
    return isEmpty;
  }, [locationsHierarcyData]);

  return (
    <CustomModalWrapper
      smallScreen={isMobileDeviceSm}
      handleClose={handleClose}
      open={modalState?.open}
      fullScreen={isMobileDeviceSm}
    >
      <HeaderWrapper
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <CTypography className="title">
          Customize Location Hierarchy
        </CTypography>

        <IconButton className={'closeIcon'} onClick={handleClose}>
          <CloseIcon sx={{ fontSize: '13px' }} />
        </IconButton>
      </HeaderWrapper>

      <ContentWrapper gap="20px">
        {locationsHierarcyData?.map((item, index) => {
          return (
            <Stack direction="row" alignItems="start">
              {index === 0 ? (
                <ArrowDownIcon className="parentIcon" />
              ) : (
                <ChildItemIcon className="childIcon" />
              )}
              <CustomInput
                muiLabel={false}
                style={{
                  marginBottom: 0,
                }}
                fieldProps={{
                  value: item?.title,
                  placeholder: 'Please provide level name',
                  onChange: (event) => {
                    changeHandler(event, index);
                  },
                }}
              />
            </Stack>
          );
        })}
      </ContentWrapper>

      <AddNewLocationLevel
        variant={'text'}
        color={'primary'}
        onClick={addNewHandler}
        startIcon={<AddIcon />}
      >
        Add Another Location Level
      </AddNewLocationLevel>
      <InfoWrapperCnt>
        <InfoWrapper direction="row" alignItems="center">
          <InfoIcon className="infoIcon" />
          <CTypography className="infoText">
            Location levels will be updated everywhere in your team.
          </CTypography>
        </InfoWrapper>
      </InfoWrapperCnt>

      <FooterWrapper direction="row" alignItems="center" justifyContent="end">
        <CustomButton
          variant={'text'}
          color={'primary'}
          onClick={handleClose}
          className="cancelBtn"
        >
          Cancel
        </CustomButton>

        <CustomButton
          variant={'contained'}
          disabled={isDisabledSaveBtn}
          color={'primary'}
          onClick={saveHandler}
          buttonLoader={isSaving}
          className="saveBtn"
        >
          Save Changes
        </CustomButton>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default CustomizeLocationHierarcy;
