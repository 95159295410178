// React
import { useContext, useMemo, useEffect } from 'react';

// Custom components
import SubmissionAccordions from 'components/TemplateDashboard/SubmissionListWithFilters/SubmissionList/SubmissionAccordions';

// Styled
import { Column } from './styled';

// Context
import { SubmissionContext } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { useSearchParams } from 'react-router-dom';

const SubmissionList = () => {
  const ctx = useContext(SubmissionContext);
  const [queryParams, setQueryParams] = useSearchParams();

  const logId = queryParams.get('logId');

  const isCheckedHash = useMemo(() => {
    return ctx?.selected?.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: true }),
      {},
    );
  }, [ctx?.selected]);

  const groupedLogs = useMemo(() => {
    return ctx?.submissionsLog
      ? ctx.submissionsLog.reduce((acc, cur) => {
          const { status } = cur;
          if (!acc[status]) {
            acc[status] = { status, data: [] };
          }
          acc[status].data.push(cur);
          return acc;
        }, {})
      : null;
  }, [ctx?.submissionsLog]);

  const sortByStatus = (array: any[]) => {
    const ordering = {};
    const sortOrder = ['In Progress', 'Submitted'];
    for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;

    return [...array].sort(function (a, b) {
      return (
        ordering[a.status] - ordering[b.status] ||
        a.status.localeCompare(b.status)
      );
    });
  };

  const groupedData = useMemo(
    () => (groupedLogs ? sortByStatus(Object.values(groupedLogs)) : null),
    [groupedLogs],
  );

  useEffect(() => {
    const submission = ctx?.submissionsLog?.find((log) => log.id === logId);
    if (submission) {
      ctx?.setActiveSubmission(submission);
      queryParams.delete('logId');
      setQueryParams(queryParams);
    } else if (groupedData) ctx?.setActiveSubmission(groupedData[0].data[0]);
  }, [groupedData]);

  return (
    <Column>
      {ctx && groupedData && (
        <SubmissionAccordions
          data={groupedData}
          activeSubmission={ctx?.activeSubmission}
          isCheckedHash={isCheckedHash}
          bulkGroupSelected={ctx?.bulkGroupSelected}
          setActiveSubmission={ctx?.setActiveSubmission}
          bulkCheckGroup={ctx?.bulkCheckGroup}
          handleClickSubmission={ctx.handleClickSubmission}
        />
      )}
    </Column>
  );
};

export default SubmissionList;
