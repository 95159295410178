import { Box, Typography, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const MainCnt = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 275px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: 600,
  margin: '0 auto',

  [theme.breakpoints.down('sm')]: {
    width: 'unset',
    padding: '0px 5px',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: 20,
  lineHeight: '27.32px',
  color: '#252350',
  marginBottom: 10,
  marginTop: -5,

  [theme.breakpoints.down('sm')]: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 'unset',
  },
}));

export const Statement = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '22px',
  color: '#000000',
  letterSpacing: '1%',
  marginBottom: 20,
  textAlign: 'center',

  [theme.breakpoints.down('sm')]: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 'unset',
  },
}));
