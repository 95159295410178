// React
import { useMemo } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Styled
import {
  Wrapper,
  Dot,
  TextWrapper,
  SecondaryText,
  PrimaryText,
} from './styled';

// Context
import { TemplateType } from 'pages/TemplateDashboard/context';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

interface FooterPropTypes {
  template: TemplateType | undefined;
}

const Footer = ({ template }: FooterPropTypes) => {
  const { getUtcToTzTime, getUtcToTz } = useDateTime();

  const hashedUserList = useSelector(selectors.getHashedUserList);

  const updatedBy: { id: string; firstName: string; lastName: string } =
    useMemo(() => {
      if (template && hashedUserList) {
        return hashedUserList[template.UpdatedBy];
      }
    }, [hashedUserList, template]);

  return (
    <Wrapper>
      <TextWrapper>
        <SecondaryText>Created by:</SecondaryText>
        <PrimaryText>
          {`${template?.Creator?.firstName} ${template?.Creator?.lastName}`} on{' '}
          {`${getUtcToTz(
            template?.createdAt ?? '',
            'MMM DD YY',
          )}, ${getUtcToTzTime(template?.createdAt ?? '')}`}
        </PrimaryText>
      </TextWrapper>
      <Dot />
      <TextWrapper>
        <SecondaryText>Last Updated by:</SecondaryText>
        <PrimaryText>
          {`${updatedBy?.firstName} ${updatedBy?.lastName}`} on{' '}
          {`${getUtcToTz(
            template?.updatedAt ?? '',
            'MMM DD YY',
          )}, ${getUtcToTzTime(template?.updatedAt ?? '')}`}
        </PrimaryText>
      </TextWrapper>
    </Wrapper>
  );
};

export default Footer;
