import LinearProgress from '@mui/material/LinearProgress';
import React, { useMemo } from 'react';
import { FC } from 'react';
import useBannerData from './../Banner/useBannerData';
import { UsageDetailsContainerStyled } from './UsageDetails.styled';
import UsersUsageIcon from './../../../components/Icons/usersUsageIcon';
import TemplateSubmissionsUsageIcon from './../../../components/Icons/templateSubmissionsUsageIcon';
import OperationsTemplateUsageIcon from './../../../components/Icons/operationsTemplateUsageIcon';
import TeamsUsageIcon from './../../../components/Icons/teamsUsageIcon';
import WorkOrderUsageIcon from './../../../components/Icons/workOrderUsageIcon';
import RecurringTaskUsageIcon from './../../../components/Icons/recurringTaskUsageIcon';
import AssetsUsageIcon from './../../../components/Icons/assetsUsageIcon';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { isEmpty } from 'lodash';
import useBilling from 'controller/useBilling';
import { StyledProgressBar } from 'components/UserCounterProgress/styled';

const UsageDetails: FC<any> = () => {
  const { isTrial } = useBannerData();
  const { billingData } = useBilling();

  const progressData = useMemo(() => {
    const { billingInfo } = billingData;
    const availableSlots = Math.max(
      billingInfo.availableSlots,
      billingInfo.portalSetSlots,
    );
    return {
      progress: billingInfo.slotsUsed / availableSlots,
      slotsUsed: billingInfo.slotsUsed,
      availableSlots,
    };
  }, [billingData]);

  const { PAYWALL_LIMITS, PAYWALL_LIMITS_NAMES } = useCustomPermission();
  const paywallUsage: any = useSelector(selectors.getPaywallUsage);
  const { Product } = useSelector(selectors.getBillingInfo) || {};

  const IconMap = {
    [PAYWALL_LIMITS.USERS]: <UsersUsageIcon className="icon" />,
    [PAYWALL_LIMITS.ONE_OFF_TASKS]: <WorkOrderUsageIcon className="icon" />,
    [PAYWALL_LIMITS.RECURRING_TASKS]: (
      <RecurringTaskUsageIcon className="icon" />
    ),
    [PAYWALL_LIMITS.TEMPLATE_SUBMISSIONS]: (
      <TemplateSubmissionsUsageIcon className="icon" />
    ),
    [PAYWALL_LIMITS.OPERATIONS_TEMPLATES]: (
      <OperationsTemplateUsageIcon className="icon" />
    ),
    [PAYWALL_LIMITS.TEAMS_WALL]: <TeamsUsageIcon className="icon" />,
    [PAYWALL_LIMITS.ASSETS]: <AssetsUsageIcon className="icon" />,
  };

  return (
    <>
      {isTrial || isEmpty(Product?.Paywall?.usageLimit) || (
        <UsageDetailsContainerStyled>
          <div className="title">Usage Details</div>
          <div className="usage-section">
            {Object.keys(Product?.Paywall?.usageLimit || [])
              .filter((key) => key !== PAYWALL_LIMITS.UPLOAD_LIMIT)
              .map((key, index) => {
                const total = Product?.Paywall?.usageLimit[key] || 0;
                const used = total - (paywallUsage[key] || 0);
                const progress = Math.round(
                  ((used > total ? total : used) / total) * 100,
                );

                return (
                  <>
                    <div key={index} className="usage-item">
                      <div className="usage-type">
                        {IconMap[key]}
                        <p className="title">{key}</p>
                      </div>
                      <div className="numbers">
                        <Box sx={{ width: '110px' }}>
                          <StyledProgressBar
                            variant="determinate"
                            value={progress}
                            color={'rgba(76, 175, 80, 1)'}
                          />
                        </Box>
                        <p className="description">
                          {used}/{total} used
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </UsageDetailsContainerStyled>
      )}
    </>
  );
};

export default UsageDetails;
