import { Stack } from '@mui/material';
import ChecklistIcon from 'components/Icons/checklistIcon';
import LoginIcon from 'components/Icons/loginIcon';
import LogoutIcon from 'components/Icons/logoutIcon';
import TaskIcon from 'components/Icons/taskIcon';

export const TaskIconHOC = () => {
  return (
    <Stack
      style={{
        backgroundColor: '#E0F2F1',
        height: 32,
        width: 32,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      sx={{
        '& svg': {
          height: 21,
          width: 21,
          left: 0,
          top: 0,
          margin: 0,
        },
      }}
    >
      <TaskIcon style={{ fill: 'none' }} primary={'#009688'} />
    </Stack>
  );
};

export const SubmissionIconHOC = () => {
  return (
    <Stack
      style={{
        backgroundColor: '#F3E5F5',
        height: 32,
        width: 32,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ChecklistIcon
        style={{
          fill: 'none',
          height: 20,
          width: 20,
          left: 0,
          top: 0,
          margin: 0,
        }}
        primary={'#9C27B0'}
        secondary={'#F3E5F5'}
      />
    </Stack>
  );
};

export const LoginIconHOC = () => {
  return (
    <Stack
      style={{
        backgroundColor: '#E3F2FD',
        height: 32,
        width: 32,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginIcon
        style={{
          fill: 'none',
          height: 20,
          width: 20,
          left: 0,
          top: 0,
          margin: 0,
        }}
      />
    </Stack>
  );
};

export const LogoutIconHOC = () => {
  return (
    <Stack
      style={{
        backgroundColor: '#F3E5F5',
        height: 32,
        width: 32,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LogoutIcon
        style={{
          fill: 'none',
          height: 20,
          width: 20,
          left: 0,
          top: 0,
          margin: 0,
        }}
      />
    </Stack>
  );
};
