// React
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import useResponsive from 'utils/CustomHooks/useResponsive';
import DIALOGS from 'utils/dialogIds';

interface AddProjectBtnPropTypes {
  createProjectCallback?: () => void;
}
function AddProjectBtn({ createProjectCallback }: AddProjectBtnPropTypes) {
  const { isMobileDeviceMd } = useResponsive();
  const dispatch = useDispatch();

  const handleAddProject = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_PROJECT,
        data: {
          createProjectCallback,
        },
      }),
    );
  };

  return (
    <Fragment>
      {isMobileDeviceMd ? (
        <Fab
          onClick={handleAddProject}
          sx={{
            position: 'fixed',
            bottom: '24px',
            right: '24px',
          }}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      ) : (
        <CustomButton
          variant={'contained'}
          startIcon={<AddIcon />}
          onClick={handleAddProject}
          style={{
            padding: '7px 16px',
            borderRadius: '8px',
            fontWeight: 600,
          }}
        >
          Add Project
        </CustomButton>
      )}
    </Fragment>
  );
}
export default AddProjectBtn;
