import Typography from '@mui/material/Typography';
import { BreadCrumbsCnt } from 'pages/Templates/FoldersDetailView/BreadCrumbs/breadCrumbs.style';
import { getSingleFolderCall } from 'api/checklistV2Api';
import { useContext, useEffect, useState } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useLocation } from 'react-router-dom';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';

function BreadCrumbs() {
  const { state = {}, updateState } = useContext(MyTemplatesContext);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [folder, setFolder] = useState({} as any);
  const location = useLocation();
  const { lastFolderId } = useCurrentFolderId();

  const getSingleFolder = async () => {
    const folderResponse = await getSingleFolderCall(lastFolderId);
    setFolder(folderResponse?.data);
  };

  const backToChecklistHome = () => {
    navigateWithWorkspaceUrl('/checklist');
  };
  useEffect(() => {
    getSingleFolder();
  }, []);

  const handleClickFolderLink = (id: string) => {
    //Filter all paths whose index is less than the index of object with the id passed above
    const folderIndex = folder?.path?.findIndex((f) => f.id === id);
    const path = folder?.path?.filter((f, index) => index <= folderIndex);
    const urlToNavigate =
      '/checklist/folder/' + path.map((f) => f.id).join('/');
    navigateWithWorkspaceUrl(urlToNavigate);
  };
  return (
    <BreadCrumbsCnt>
      <Typography
        variant={'h1'}
        className={'heading'}
        onClick={backToChecklistHome}
      >
        Folders
      </Typography>

      {folder?.path?.map((f, index) => {
        return (
          <div
            key={index}
            className={'folderNameCnt'}
            onClick={() => handleClickFolderLink(f.id)}
          >
            <ArrowRightIcon className={'arrowIcon'} />
            <TemplateFolderIcon
              primaryColor={f?.meta?.primaryColor}
              secondaryColor={f?.meta?.secondaryColor}
            />
            <Typography variant={'h1'} className={'folderName'}>
              {f?.title}
            </Typography>
          </div>
        );
      })}
      <div className={'folderNameCnt'}>
        <ArrowRightIcon className={'arrowIcon'} />
        <TemplateFolderIcon
        // primaryColor={f?.meta?.primaryColor}
        // secondaryColor={f?.meta?.secondaryColor}
        />
        <Typography variant={'h1'} className={'folderName'}>
          {folder?.title}
        </Typography>
      </div>
      {/*// }*/}
      {/*// <ArrowRightIcon className={'arrowIcon'} />*/}
      {/*// <div className={'folderNameCnt'}>*/}
      {/*//   <TemplateFolderIcon*/}
      {/*//     primaryColor={folder?.meta?.primaryColor}*/}
      {/*//     secondaryColor={folder?.meta?.secondaryColor}*/}
      {/*//   />*/}
      {/*//   <Typography variant={'h1'} className={'folderName'}>*/}
      {/*//     {folder?.title}*/}
      {/*//   </Typography>*/}
      {/*// </div>*/}
    </BreadCrumbsCnt>
  );
}

export default BreadCrumbs;
