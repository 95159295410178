import { createSvgIcon } from '@mui/material';

const ProcedureIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8655 1.66667H5.13463C4.69535 1.66666 4.31636 1.66665 4.00423 1.69215C3.67477 1.71907 3.34709 1.7785 3.03177 1.93916C2.56137 2.17884 2.17892 2.56129 1.93923 3.0317C1.77857 3.34701 1.71914 3.6747 1.69222 4.00415C1.66672 4.31629 1.66673 4.69524 1.66675 5.13452V14.8655C1.66673 15.3047 1.66672 15.6837 1.69222 15.9959C1.71914 16.3253 1.77857 16.653 1.93923 16.9683C2.17892 17.4387 2.56137 17.8212 3.03177 18.0609C3.34709 18.2215 3.67477 18.2809 4.00423 18.3079C4.31637 18.3334 4.69533 18.3334 5.13461 18.3333H14.8656C15.3048 18.3334 15.6838 18.3334 15.9959 18.3079C16.3254 18.2809 16.6531 18.2215 16.9684 18.0609C17.4388 17.8212 17.8212 17.4387 18.0609 16.9683C18.2216 16.653 18.281 16.3253 18.3079 15.9959C18.3334 15.6837 18.3334 15.3048 18.3334 14.8655V5.13454C18.3334 4.69525 18.3334 4.31629 18.3079 4.00415C18.281 3.6747 18.2216 3.34701 18.0609 3.0317C17.8212 2.56129 17.4388 2.17884 16.9684 1.93916C16.6531 1.7785 16.3254 1.71907 15.9959 1.69215C15.6838 1.66665 15.3048 1.66666 14.8655 1.66667ZM14.3393 8.08926C14.6648 7.76382 14.6648 7.23619 14.3393 6.91075C14.0139 6.58531 13.4863 6.58531 13.1608 6.91075L8.75008 11.3215L6.83934 9.41075C6.5139 9.08531 5.98626 9.08531 5.66083 9.41075C5.33539 9.73619 5.33539 10.2638 5.66083 10.5893L8.16083 13.0893C8.48626 13.4147 9.0139 13.4147 9.33934 13.0893L14.3393 8.08926Z"
        fill="#4CAF50"
      />
    </>,
    'ProcedureIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default ProcedureIcon;
