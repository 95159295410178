import { createSvgIcon } from '@mui/material';

const ColumnsIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect
        x="3.4"
        y="3.4"
        width="3.2"
        height="12.2"
        rx="1.6"
        stroke="currentColor"
        stroke-width="0.8"
      />
      <rect
        x="8.9"
        y="3.4"
        width="3.2"
        height="12.2"
        rx="1.6"
        stroke="currentColor"
        stroke-width="0.8"
      />
      <rect
        x="14.4"
        y="3.4"
        width="3.2"
        height="6.2"
        rx="1.6"
        stroke="currentColor"
        stroke-width="0.8"
      />
    </>,
    'ColumnsIcon',
  );
  return <IconCmp viewBox={'0 0 20 20'} {...props} />;
};
export default ColumnsIcon;
