import { useRef } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { WidgetCnt, WidgetHeading, WidgetSubHeading } from './style';
import useTasksFiltersConditions from 'utils/CustomHooks/useTasksFiltersConditions';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import EmptyState from './emptyState';

function OnTimeVsLateCompletionByLocation(props) {
  const { title, data, globalFilters, loading, error, reload } = props;
  const {
    recurringByLocationCompletedOnTime,
    recurringByLocationsCompletedOverdue,
  } = useTasksFiltersConditions();

  const dispatch = useDispatch();

  const chartRef = useRef(null);

  const clickHandler = (item, type) => {
    let advancedFilters: any = [];

    if (type === 'onTime') {
      advancedFilters = recurringByLocationCompletedOnTime([item?.id]);
    }

    if (type === 'overdue') {
      advancedFilters = recurringByLocationsCompletedOverdue([item?.id]);
    }

    if (globalFilters?.fromDate && globalFilters?.toDate) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'dueDate',
        value: [globalFilters?.fromDate, globalFilters?.toDate],
      });
    }
    if (globalFilters?.locations?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'locations',
        value: globalFilters.locations,
      });
    }

    if (globalFilters?.projects?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'projects',
        value: globalFilters.projects,
      });
    }

    if (globalFilters?.schedules?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'schedules',
        value: globalFilters.schedules,
      });
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TASK_TABLE_DIALOG,
        data: {
          filters: advancedFilters,
        },
      }),
    );
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;
    let truncatedLabel = payload.value;
    if (truncatedLabel.length > 12) {
      truncatedLabel = truncatedLabel.substring(0, 12) + '...';
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
        >
          <tspan
            x="0"
            dy="4"
            style={{
              fontWeight: 700,
              fontSize: 13,
              letterSpacing: '0.17px',
              fill: '#000000',
            }}
          >
            {truncatedLabel}
          </tspan>
        </text>
      </g>
    );
  };

  return (
    <WidgetCnt ref={chartRef}>
      <WidgetHeading>{title}</WidgetHeading>{' '}
      <WithLoader isLoading={loading}>
        <WithErrorState type={'bar'} error={error} reload={reload}>
          {data?.chart?.length > 0 && (
            <WidgetSubHeading>{data?.summary?.total}%</WidgetSubHeading>
          )}
          {data?.chart?.length > 0 ? (
            <ResponsiveContainer
              minWidth={data?.chart?.length * 40}
              height="100%"
            >
              <BarChart
                style={{ fontSize: 12 }}
                data={data?.chart}
                barGap={0}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 50,
                }}
              >
                <CartesianGrid
                  stroke="#f0f0f0"
                  strokeDasharray="0"
                  vertical={false}
                />
                <XAxis
                  dataKey="name"
                  angle={-45}
                  interval={0}
                  textAnchor="end"
                  tick={<CustomizedAxisTick />}
                  tickLine={false}
                  axisLine={{ stroke: '#f0f0f0' }}
                />
                <YAxis
                  type="number"
                  domain={[0, 100]}
                  ticks={[0, 50, 75, 100]}
                  tick={{
                    fontWeight: 700,
                    fontSize: 13,
                    letterSpacing: '0.17px',
                    fill: '#000000',
                  }}
                  tickLine={{ stroke: '#f0f0f0' }}
                  axisLine={{ stroke: '#f0f0f0' }}
                />
                <Tooltip />
                <Bar dataKey="onTime" fill="#73C076" minPointSize={1}>
                  {data?.chart?.map((item, index) => (
                    <Cell
                      key={`onTime-${index}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => clickHandler(item, 'onTime')}
                    />
                  ))}
                </Bar>
                <Bar dataKey="overdue" fill="#ED7C7C" minPointSize={1}>
                  {data?.chart?.map((item, index) => (
                    <Cell
                      key={`overdue-${index}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => clickHandler(item, 'overdue')}
                    />
                  ))}
                </Bar>
                <Legend
                  iconSize={10}
                  iconType="circle"
                  align="right"
                  verticalAlign="top"
                  formatter={(value, entry) => {
                    return (
                      <span
                        style={{
                          color: '#000000',
                          fontWeight: 700,
                          fontSize: 13,
                          lineHeight: '17.76px',
                        }}
                      >
                        {value === 'onTime' ? 'On-time' : 'Overdue'}
                      </span>
                    );
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <EmptyState />
          )}
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
}

export default OnTimeVsLateCompletionByLocation;
