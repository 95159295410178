/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import { useEffect } from 'react';
import { NextButton, OrganizationInfoDiv } from './organizationInfo.style';
import { useOnboardState } from '../onboarding-provider';
import { useState } from 'react';
import xeniaApi from 'api/index';
import { isAndroidOrIOS } from 'utils/globalFunction';
import { Stack } from '@mui/material';
import Goal from './Goal';

const OrganizationGoal = (props) => {
  const { nextStep } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { goal, setGoal } = useOnboardState();

  const [categoryTypes, setCategoryTypes] = useState([]);
  const [deviceType, setDeviceType] = useState('');

  const isMobileApp = globalThis?.platform === 'mobile';

  // Fetch Industries and Goals Data on Initial Load.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResp = await xeniaApi.getCategories(
          'hotel/checklist-categories',
        );

        if (categoryResp.category) {
          const sortedGoals = sortGoals(categoryResp.category);
          let goalsDropdown = sortedGoals.map((item) => {
            return {
              label: item.name,
              subTitle: item.description,
              value: item.id,
              id: item.id,
            };
          });
          setCategoryTypes(goalsDropdown);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();

    // Check Is IOS or Android
    setDeviceType(isAndroidOrIOS());
  }, []);

  // Sort Goals
  const sortGoals = (data) => {
    const goalsOrder = [
      'Operational Execution & Management',
      'Environmental, Health & Safety',
      'Quality Assurance / Quality Control',
      'Maintenance Management',
      'Multi-Site/Unit Operations',
    ];

    return data.sort(function (a, b) {
      return goalsOrder.indexOf(a.name) - goalsOrder.indexOf(b.name);
    });
  };

  const goalChangeHandler = (g) => {
    if (goal?.some((item) => item.id === g.id)) {
      setGoal(goal.filter((item) => item.id !== g.id));
    } else {
      setGoal([...goal, g]);
    }
  };

  // Handle Next Click
  const handleNextClick = async () => {
    nextStep();
  };

  return (
    <OrganizationInfoDiv>
      <Stack>
        <p className="title">What is your main purpose of using Xenia</p>
        <p className="subTitle">
          Tell us a bit about yourself so we can help you get started.
        </p>
        <Stack maxHeight={'440px'} overflow="auto" gap="16px">
          {categoryTypes.map((g) => (
            <Goal
              goal={g}
              checked={goal?.some((item) => item.id === g.id)}
              onCheck={goalChangeHandler}
            />
          ))}
        </Stack>
        <NextButton
          variant="contained"
          onClick={handleNextClick}
          buttonLoader={isLoading}
          style={{ width: '100%', margin: '30px 0px 10px 0px', height: 47 }}
        >
          {!deviceType ? 'Next' : 'Submit & Go to App'}
        </NextButton>
      </Stack>
    </OrganizationInfoDiv>
  );
};

export default OrganizationGoal;
