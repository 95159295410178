// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}));

export const SettingsIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: '1px solid rgba(189, 189, 189, 1)',
  borderRadius: 6,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 8,
  cursor: 'pointer',
  width: 36,
  height: 36,
  '&:hover': {
    background: 'rgba(189, 189, 189, 0.3)',
    transition:
      'background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms',
  },
  transition:
    'background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 6,
  height: 36,
  color: '#212121',
  border: '1px solid rgba(189, 189, 189, 1)',
  '&:hover': {
    background: 'rgba(189, 189, 189, 0.3)',
    border: '1px solid rgba(189, 189, 189, 1)',
  },
}));

export const StartButton = styled(CustomButton)(() => ({
  borderRadius: 6,
  height: 36,
  color: 'white',
  '& svg': {
    '& path': {
      stroke: 'white',
      strokeOpacity: 1,
    },
  },
}));
