// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Hooks
import useLocationDetail from '../useLocationDetail';
import useDateTime from 'utils/CustomHooks/useDateTime';
import useResponsive from 'utils/CustomHooks/useResponsive';

// Styles
import { FooterWrapper } from './styled';

function LocationDetailFooter() {
  const { selectedLocation } = useLocationDetail();
  const userList = useSelector(selectors.getUserAdmin);
  const { getUtcToTz } = useDateTime();

  const { isMobileDeviceSm } = useResponsive();

  const { locationNumber, createdAt, updatedAT, CreatedBy, UpdatedBy } =
    selectedLocation;
  const createdAtFormated = getUtcToTz(createdAt, 'MMM DD YYYY');
  const updatedAtFormated = getUtcToTz(updatedAT, 'MMM DD YYYY');
  const createdByUser = userList?.find((u) => u?.id == CreatedBy);
  const updatedByUser = userList?.find((u) => u?.id == UpdatedBy);
  const createdByFullName = `${createdByUser?.firstName} ${createdByUser?.lastName}`;
  const updatedByFullName = `${updatedByUser?.firstName} ${updatedByUser?.lastName}`;
  return (
    <FooterWrapper>
      <div className="historyUpdateDetailsItem">
        <label className="label">Location ID:</label>
        <span className="value">&nbsp;{locationNumber}</span>
      </div>

      <div className="historyUpdateDetailsItem">
        <label className="label">
          {!isMobileDeviceSm && <span>•</span>}
          <span>Created by:</span>
        </label>
        <span className="value">
          {createdByFullName} on {createdAtFormated}
        </span>
      </div>
      <div className="historyUpdateDetailsItem">
        <label className="label">
          {!isMobileDeviceSm && <span>•</span>}
          <span>Last Updated by:</span>
        </label>
        <span className="value">
          {updatedByFullName} on {updatedAtFormated}
        </span>
      </div>
    </FooterWrapper>
  );
}

export default LocationDetailFooter;
