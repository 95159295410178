// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  letterSpacing: '0.15px',
  color: '#212121',
}));
