import { createSvgIcon } from '@mui/material';

const EnvelopeIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M16.125 13.8999L11.1429 9.3999M6.85714 9.3999L1.87503 13.8999M1.5 5.6499L7.62369 9.93648C8.11957 10.2836 8.36751 10.4572 8.6372 10.5244C8.87542 10.5838 9.12458 10.5838 9.3628 10.5244C9.63249 10.4572 9.88043 10.2836 10.3763 9.93649L16.5 5.6499M5.1 15.3999H12.9C14.1601 15.3999 14.7902 15.3999 15.2715 15.1547C15.6948 14.939 16.039 14.5947 16.2548 14.1714C16.5 13.6901 16.5 13.06 16.5 11.7999V6.9999C16.5 5.73978 16.5 5.10973 16.2548 4.62842C16.039 4.20506 15.6948 3.86085 15.2715 3.64514C14.7902 3.3999 14.1601 3.3999 12.9 3.3999H5.1C3.83988 3.3999 3.20982 3.3999 2.72852 3.64514C2.30516 3.86085 1.96095 4.20506 1.74524 4.62842C1.5 5.10973 1.5 5.73978 1.5 6.9999V11.7999C1.5 13.06 1.5 13.6901 1.74524 14.1714C1.96095 14.5947 2.30516 14.939 2.72852 15.1547C3.20982 15.3999 3.83988 15.3999 5.1 15.3999Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'EnvelopeIcon',
  );
  return <IconCmp viewBox={'0 0 18 19'} style={{ fill: 'none' }} {...props} />;
};
export default EnvelopeIcon;
