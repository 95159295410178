import { Stack, Typography, styled } from '@mui/material';
import { colors } from 'components/theme/constants';

export const Container = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'center',
}));

export const Value = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#212121',
}));

export const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#212121',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const StatusCell = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'bg',
})(({ color, bg }) => ({
  color: color,
  background: bg,
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: 12,
  letterSpacing: '0.4px',
  paddingLeft: '16px',
  height: '100%',
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    color: color,
  },
}));

export const CheckListCnt = styled('div')({
  height: '100%',
  '& .templateAttached': {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    '& p': {
      marginLeft: 8,
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '17.76px',
      color: '#212121',
    },
  },
});
