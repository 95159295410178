import { styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const DateDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  width: '100%',
  justifyContent: 'space-between',
  padding: '6px 10px 6px 10px',
  height: 40,
  backgroundColor: 'transparent',
  color: 'rgb(104, 104, 254)',
  border: '1px solid rgba(104, 104, 254, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(104, 104, 254, 0.04)',
  },
}));
