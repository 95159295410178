// React
import { Fragment } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Custom components
import SubItem from './SubItem';

// Icons
import AllTemplatesIcon from 'components/Icons/allTemplatesIcon';
import SubmissionReportPieCharIcon from 'components/Icons/submissionReportPieCharIcon';
import TablesIcon from 'components/Icons/tablesIcon';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface SubItemsListPropTypes {
  submissionReportsId: any;
  onClickItemCallback?: () => void;
}

const SubItemsList = ({
  submissionReportsId,
  onClickItemCallback,
}: SubItemsListPropTypes) => {
  const currentPath = useLocation().pathname;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const handleClickSubItem = (
    itemType: 'allTemplates' | 'submissionReports' | 'tables',
  ) => {
    switch (itemType) {
      case 'allTemplates':
        navigateWithWorkspaceUrl('/checklist?view=grid');
        break;
      case 'submissionReports':
        submissionReportsId &&
          navigateWithWorkspaceUrl(
            `/submission-reports/${submissionReportsId}`,
          );
        break;
      case 'tables':
        navigateWithWorkspaceUrl('/templates-table-view');
        break;

      default:
        break;
    }
    onClickItemCallback?.();
  };

  return (
    <Fragment>
      <SubItem
        title="All Templates"
        isSelected={currentPath.includes('/checklist')}
        icon={<AllTemplatesIcon filled={currentPath.includes('/checklist')} />}
        onClick={() => handleClickSubItem('allTemplates')}
      />

      <SubItem
        title="Submission Reports"
        isSelected={currentPath.includes('/submission-reports')}
        icon={
          <SubmissionReportPieCharIcon
            filled={currentPath.includes('/submission-reports')}
          />
        }
        onClick={() => handleClickSubItem('submissionReports')}
      />

      <SubItem
        title="Tables"
        isSelected={currentPath.includes('/templates-table-view')}
        icon={
          <TablesIcon filled={currentPath.includes('/templates-table-view')} />
        }
        onClick={() => handleClickSubItem('tables')}
      />
    </Fragment>
  );
};

export default SubItemsList;
