import React, { useContext } from 'react';
import CustomButton from 'components/Button/CustomButton';
import {
  HeaderHeading,
  RequestsHeaderCnt,
} from 'pages/Requests/RequestsHeader/requestsHeader.style';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { RequestsContext } from 'pages/Requests/context/context';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';
import DIALOGS from 'utils/dialogIds';
import useRequests from 'pages/Requests/useRequests';
import { PERMISSIONS } from 'utils/constants';
import { HasPermission } from 'components/HasPermission';

const RequestsHeader = () => {
  const { updateRequestsState, requestsState } = useContext(RequestsContext);
  const { searchQuery } = requestsState;
  const { navigateToRequest } = useRequests();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    updateRequestsState({ searchQuery: e.target.value });
  };

  const handleOpenCreateRequest = () => {
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          navigateTo: 'CreateRequest',
        }),
      );
      return;
    }
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_REQUEST,
        data: {
          onSuccessCallBack: (newRequest) => {
            updateRequestsState({
              requests: [newRequest, ...requestsState.requests],
            });
            navigateToRequest(newRequest.id);
          },
        },
      }),
    );
  };
  return (
    <RequestsHeaderCnt>
      <CustomInput
        id="requestSearchInput"
        className="requestSearchInput"
        grayInput={true}
        suppressErrorSpace={true}
        fieldProps={{
          onChange: handleChange,
          startAdornment: <SearchIcon />,
          placeholder: 'Search',
        }}
        label=""
      />
      <HasPermission permissions={[PERMISSIONS.CAN_REQUEST_TASK]}>
        <CustomButton
          variant={'contained'}
          color={'primary'}
          startIcon={<AddIcon />}
          value={searchQuery}
          onClick={handleOpenCreateRequest}
        >
          Add Request
        </CustomButton>
      </HasPermission>
    </RequestsHeaderCnt>
  );
};

export default RequestsHeader;
