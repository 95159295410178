import SearchIcon from '@mui/icons-material/Search';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { debounce } from 'lodash';
import { LocationsSearchInputCnt } from 'pages/Locations/Header/LocationsSearchInput/locationsSearchInput.style';

interface Props {
  onSearch?: (value: string) => void;
  hideIcon?: boolean;
}

function LocationsSearchInput({ onSearch, hideIcon = false }: Props) {
  const handleInputChange = debounce(async (e) => {
    const value = e.target.value.trim();
    onSearch?.(value || '');
    globalThis?.LocationsGrid?.api?.setQuickFilter?.(value);
  }, 300);
  return (
    <LocationsSearchInputCnt>
      <CustomInput
        id="locationsSearchInput"
        suppressErrorSpace={true}
        grayInput={true}
        fieldProps={{
          onChange: handleInputChange,
          ...(!hideIcon && { startAdornment: <SearchIcon /> }),
          placeholder: 'Search',
        }}
        label=""
      />
    </LocationsSearchInputCnt>
  );
}

export default LocationsSearchInput;
