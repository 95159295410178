import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from '@mui/material';
import TextEditorHeader from 'pages/documents/builder/header';
import SOPEditor from 'components/SOPEditor';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { debounce } from 'lodash';
import useMeasure from 'react-use-measure';
import {
  EditorCnt,
  MainCnt,
  HeaderCnt,
  TitleCnt,
} from 'pages/documents/builder/style';
import CTypography from 'components/CTypography';

const SOPBuilder = (props) => {
  const { document, metaInfo } = props;
  const initialValue: any = useRef(<p></p>);
  const [data, setData] = useState<any>(document);
  const [ref, bounds] = useMeasure();
  const [editorHeight, setEditorHeight] = useState('');
  const { updateDocument } = useDocumentsFolders();

  useEffect(() => {
    if (data?.DocumentContent) {
      initialValue.current = metaInfo?.canEdit
        ? data?.DocumentContent?.draftContent || data?.DocumentContent?.content
        : data?.DocumentContent?.content;
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [metaInfo]);

  const updateSize = () => {
    if (bounds?.height) {
      const height = `calc(100vh - ${Math.ceil(bounds?.height || 0) + 20}px) `;
      setEditorHeight(height);
    }
  };

  const updateDocumentHandler = async (newData) => {
    const response = await updateDocument(newData, data?.id);
    setData(response);
  };

  const debouncedUpdateHandler = useCallback(
    debounce(updateDocumentHandler, 1000),
    [],
  );

  const handleContentChange = (value) => {
    debouncedUpdateHandler({
      draftContent: value || '<p></p>',
    });
  };

  const handleTitleChange = (e) => {
    const val = e?.target?.value;
    if (val?.length > 50) return;
    setData({
      ...data,
      title: e?.target?.value,
    });

    debouncedUpdateHandler({
      title: e?.target?.value || '-',
    });
  };

  useEffect(() => {
    updateSize();
  }, [bounds]);

  const updateDocumentCallback = (data) => {
    setData(data);
  };

  return (
    <Modal open={true}>
      <MainCnt tabIndex="">
        {globalThis?.platform !== 'mobile' && (
          <HeaderCnt ref={ref}>
            <TextEditorHeader
              document={data}
              metaInfo={metaInfo}
              updateDocumentCallback={updateDocumentCallback}
            />

            <TitleCnt>
              {metaInfo?.canEdit === true && (
                <CustomInput
                  className="titlefield"
                  underlineInput={true}
                  suppressErrorSpace={true}
                  fieldProps={{
                    placeholder: 'Untitled',
                    autoFocus: true,
                    style: {
                      borderBottom: 'unset',
                    },
                    inputProps: {
                      style: {
                        fontSize: 30,
                        fontWeight: 700,
                        color: '#212121',
                        padding: '5px 0',
                      },
                    },
                    onChange: handleTitleChange,
                    value: ['-', 'New Document']?.includes(data?.title)
                      ? ''
                      : data?.title,
                  }}
                />
              )}

              {metaInfo?.canEdit === false &&
                !['-', 'New Document']?.includes(data?.title) && (
                  <CTypography className="titleReadOnly">
                    {data?.title}
                  </CTypography>
                )}
            </TitleCnt>
          </HeaderCnt>
        )}
        {(globalThis?.platform === 'mobile' || editorHeight) && (
          <EditorCnt>
            <SOPEditor
              onChangeCallback={handleContentChange}
              initialValue={
                typeof initialValue.current === 'string'
                  ? initialValue.current
                  : ''
              }
              doc={document}
              editorHeight={
                globalThis?.platform === 'mobile'
                  ? 'calc(100vh - 10px)'
                  : editorHeight
              }
              metaInfo={metaInfo}
            />
          </EditorCnt>
        )}
      </MainCnt>
    </Modal>
  );
};

export default SOPBuilder;
