import styled from '@mui/system/styled';
import { IconButton } from '@mui/material';

export const AssigneeIconBtn = styled(IconButton)({
  borderRadius: 10,
  padding: 8,
  marginRight: 5,
  border: '1px dashed #BDBDBD',
  '& svg': {
    fontSize: 16,
    pointerEvents: 'none',
  },
});

export const RemoveIconBtn = styled(IconButton)({
  borderRadius: '50%',
  padding: 0,
  marginLeft: 8,
  '& svg': {
    fontSize: 20,
    color: '#EF5350',
  },
});

export const SelectedAssigneeChip = styled('div')({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '16px',
  padding: '3px 6px 3px 3px',
  height: 32,
  border: '1px solid rgba(0, 0, 0, 0.26)',
  '& .selectedAssigneeLabel': {
    marginLeft: 5,
    fontSize: 14,
  },
});
