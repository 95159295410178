import { createSvgIcon } from '@mui/material';

const BarChartIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.6771 1.60969e-06H8.32292C7.26865 -3.26046e-05 6.35908 -6.21192e-05 5.60995 0.0611439C4.81926 0.125746 4.03282 0.268378 3.27606 0.653966C2.14709 1.22921 1.22921 2.14709 0.653966 3.27606C0.268378 4.03282 0.125746 4.81926 0.0611439 5.60995C-6.21192e-05 6.35908 -3.26046e-05 7.26858 1.60969e-06 8.32284V31.6771C-3.26046e-05 32.7314 -6.21192e-05 33.6409 0.0611439 34.3901C0.125746 35.1808 0.268378 35.9672 0.653966 36.7239C1.22921 37.8529 2.14709 38.7708 3.27606 39.346C4.03282 39.7316 4.81926 39.8743 5.60995 39.9389C6.35908 40.0001 7.26859 40 8.32287 40H31.6771C32.7314 40 33.6409 40.0001 34.3901 39.9389C35.1807 39.8743 35.9672 39.7316 36.7239 39.346C37.8529 38.7708 38.7708 37.8529 39.346 36.7239C39.7316 35.9672 39.8743 35.1808 39.9389 34.3901C40.0001 33.6409 40 32.7314 40 31.6771V8.32287C40 7.26859 40.0001 6.35908 39.9389 5.60995C39.8743 4.81926 39.7316 4.03282 39.346 3.27606C38.7708 2.14709 37.8529 1.22921 36.7239 0.653966C35.9672 0.268378 35.1807 0.125746 34.3901 0.0611439C33.6409 -6.21192e-05 32.7314 -3.26046e-05 31.6771 1.60969e-06ZM22 10C22 8.89543 21.1046 8 20 8C18.8954 8 18 8.89543 18 10V30C18 31.1046 18.8954 32 20 32C21.1046 32 22 31.1046 22 30V10ZM28 16C29.1046 16 30 16.8954 30 18V30C30 31.1046 29.1046 32 28 32C26.8954 32 26 31.1046 26 30V18C26 16.8954 26.8954 16 28 16ZM12 20C13.1046 20 14 20.8954 14 22V30C14 31.1046 13.1046 32 12 32C10.8954 32 10 31.1046 10 30V22C10 20.8954 10.8954 20 12 20Z"
        fill="currentColor"
      />
    </>,
    'BarChartIcon',
  );
  return <IconCmp viewBox="0 0 40 40" fill="none" {...props} />;
};
export default BarChartIcon;
