import styled from '@mui/system/styled';
export const FolderItemCnt = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 16px',
  gap: 12,
  borderRadius: '12px',
  border: '1px solid #EEEEEE',
  background: '#FFF',
  boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
  transition: 'ease border 0.2s',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.08)',
    border: '1px solid #6868FE',
    transition: 'ease border 0.2s',
  },
  '.folderInfoCnt': {
    flex: 1,
    overflow: 'hidden',
  },
  '.folderName': {
    color: '#212121',
    fontSize: 13,
    fontWeight: '700',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '99%',
  },
  '.templateCount': {
    color: '#424242',
    fontSize: 12,
    fontWeight: '400',
  },
});
