import CTypography from 'components/CTypography';
import TemplateDefaultIcon from 'components/Icons/templateDefaultIcon';
import { PreviewWrapper } from './styled';

const OnboardingTemplateGroupPreview = ({ templateGroup, wrapper = {} }) => {
  return (
    <PreviewWrapper {...wrapper}>
      {templateGroup?.image ? (
        <img src={templateGroup?.image} />
      ) : (
        <div className="defaultIcon">
          <TemplateDefaultIcon
            primaryColor="white"
            style={{ height: 32, width: 32, fill: 'none' }}
          />
        </div>
      )}
      <div>
        <CTypography className="templateTitle">
          {templateGroup?.title}
        </CTypography>
        <CTypography className="templateSubTitle">by Xenia Team</CTypography>
      </div>
    </PreviewWrapper>
  );
};

export default OnboardingTemplateGroupPreview;
