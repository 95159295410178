import { ReactNode } from 'react';

// Icons
import PlayIconRounded from 'components/Icons/playIconRounded';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import GearIcon from 'components/Icons/gearIcon';
import DuplicateIconRounded from 'components/Icons/duplicateIconRounded';
import TrashIcon from 'components/Icons/trashIcon';
import { Schedule } from '@mui/icons-material';
import GlobeIcon from 'components/Icons/globeIcon';
import DisabledGlobeIconIcon from 'components/Icons/disabledGlobeIcon';
import TemplateShareIcon from 'components/Icons/templateShareIcon';

export interface DropdownOptionType {
  id:
    | 'start'
    | 'schedule'
    | 'edit'
    | 'settings'
    | 'duplicate'
    | 'delete'
    | 'unpublish'
    | 'publish'
    | 'shareTemplate';
  label: string;
  iconAlign: string;
  icon: ReactNode;
  sx?: any;
}

export const dropdownOptions = (template) => {
  return [
    ...(template?.HotelChecklist?.isEditable
      ? [
          {
            id: 'start',
            label: 'Start',
            iconAlign: 'start',
            icon: <PlayIconRounded />,
          },
        ]
      : []),
    {
      id: 'schedule',
      label: 'Assign',
      iconAlign: 'start',
      icon: <Schedule sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    },
    ...(template?.HotelChecklist?.isEditable
      ? [
          {
            id: 'edit',
            label: 'Edit Template',
            iconAlign: 'start',
            icon: <EditIconPencilBottomLine />,
          },
        ]
      : []),
    ...(template?.HotelChecklist?.isEditable
      ? [
          {
            id: 'settings',
            label: 'Settings',
            iconAlign: 'start',
            icon: <GearIcon sx={{ fill: 'none' }} />,
          },
        ]
      : []),
    {
      id: 'shareTemplate',
      label: 'Share this template',
      iconAlign: 'start',
      icon: <TemplateShareIcon style={{ color: '#000' }} />,
    },
    {
      id: 'duplicate',
      label: 'Duplicate',
      iconAlign: 'start',
      icon: <DuplicateIconRounded />,
    },
    ...(template?.HotelChecklist?.isEditable
      ? [
          {
            id: 'publish',
            label: 'Publish',
            iconAlign: 'start',
            icon: <GlobeIcon />,
          },
        ]
      : []),
    ...(template?.HotelChecklist?.isEditable
      ? [
          {
            id: 'unpublish',
            label: 'Unpublish',
            iconAlign: 'start',
            icon: <DisabledGlobeIconIcon />,
          },
        ]
      : []),
    ...(template?.HotelChecklist?.isEditable
      ? [
          {
            id: 'delete',
            label: 'Delete',
            iconAlign: 'start',
            icon: <TrashIcon />,
            sx: {
              color: 'rgba(239, 83, 80, 1)',
            },
          },
        ]
      : []),
  ];
};
