import { useMemo, useCallback } from 'react';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';

// Constants
import { columns, getRowStyles, defaultColDef } from './constants';

const ListView = ({ onRowClick, tasks }) => {
  const colConfig = useMemo(() => columns(), []);

  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);

  return (
    <CustomListView
      rootProps={{
        style: {
          height: 'calc(100vh - 205px)',
          boxShadow: 'none',
          flex: 1,
        },
      }}
      onRowClick={onRowClick}
      gridProps={{
        ref: (ref) => {
          globalThis.taskSummaryListGrid = ref;
        },
        headerHeight: 30,
        // onSortChanged: onSortChange,
        rowData: tasks,
        suppressChangeDetection: true,
        defaultColDef: defaultColDef,
        columnDefs: colConfig,
        suppressCellFocus: true,
        getRowHeight: getRowHeight,
        getRowStyle: getRowStyles,
        suppressRowClickSelection: true,
        getRowId: (params) => params.data.id,
      }}
    />
  );
};

export default ListView;
