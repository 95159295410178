import { createSvgIcon } from '@mui/material';

const RoundedAddIcon = (props) => {
  const { primary = '#E8F5E9', secondary = '#2E7D32' } = props;
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_588_18963)">
        <path
          d="M13.8755 26.9365C20.9233 26.9365 26.6367 21.2231 26.6367 14.1753C26.6367 7.12747 20.9233 1.41408 13.8755 1.41408C6.82765 1.41408 1.11426 7.12747 1.11426 14.1753C1.11426 21.2231 6.82765 26.9365 13.8755 26.9365Z"
          fill={primary}
        />
        <path
          d="M13.8755 27.6753C6.43103 27.6753 0.375488 21.6197 0.375488 14.1753C0.375488 6.73084 6.43103 0.675293 13.8755 0.675293C21.3199 0.675293 27.3755 6.73084 27.3755 14.1753C27.3755 21.6197 21.3199 27.6753 13.8755 27.6753ZM13.8755 2.15286C7.24616 2.15286 1.85305 7.54596 1.85305 14.1753C1.85305 20.8046 7.24616 26.1977 13.8755 26.1977C20.5048 26.1977 25.8979 20.8046 25.8979 14.1753C25.8979 7.54596 20.5048 2.15286 13.8755 2.15286Z"
          fill={secondary}
        />
        <path
          d="M13.8755 19.4699C13.4667 19.4699 13.1367 19.1399 13.1367 18.7311V9.62194C13.1367 9.21314 13.4667 8.88316 13.8755 8.88316C14.2843 8.88316 14.6143 9.21314 14.6143 9.62194V18.7311C14.6143 19.1399 14.2843 19.4699 13.8755 19.4699Z"
          fill={secondary}
        />
        <path
          d="M18.4312 14.9141H9.32203C8.91324 14.9141 8.58325 14.5841 8.58325 14.1753C8.58325 13.7665 8.91324 13.4365 9.32203 13.4365H18.4312C18.84 13.4365 19.17 13.7665 19.17 14.1753C19.17 14.5841 18.84 14.9141 18.4312 14.9141Z"
          fill={secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_588_18963">
          <rect
            width="27"
            height="27"
            fill="white"
            transform="translate(0.375488 0.675293)"
          />
        </clipPath>
      </defs>
    </>,
    'RoundedAddIcon',
  );
  return (
    <IconCmp
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    />
  );
};
export default RoundedAddIcon;
