export const THRESHOLD = {
  minW: 5,
  maxW: 12,
  minH: 8,
  maxH: 15,
};

export const INITIAL_LAYOUT_DASHBOARD = [
  {
    x: 0,
    y: 0,
    w: 6,
    h: 8,
    i: '.$usersReport',
    ...THRESHOLD,
  },
  {
    x: 6,
    y: 0,
    w: 6,
    h: 8,
    i: '.$checkListReport',
    ...THRESHOLD,
  },
];

export const INITIAL_LAYOUT_TASK_SUMMARY_REPORT = [
  {
    x: 0,
    y: 0,
    w: 12,
    h: 12,
    i: '.$taskListSummary',
    ...THRESHOLD,
  },
  {
    x: 0,
    y: 0,
    w: 6,
    h: 12,
    i: '.$tasksByAssignee',
    ...THRESHOLD,
  },
  {
    x: 6,
    y: 0,
    w: 6,
    h: 12,
    i: '.$tasksByCategory',
    ...THRESHOLD,
  },
];
