import { useCallback, useMemo, useRef } from 'react';

import {
  WidgetCnt,
  WidgetHeading,
  WidgetContentWrapper,
} from 'pages/Dashboards/Widgets/widgets.style';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { stringToHslColor } from 'helper/stringToColor';
import TasksByAssigneeEmptyState from 'pages/Widgets/TasksByAssignee/EmptyRecords/TaskListWidgetEmptyState.cmp';
import WidgetActions from 'pages/Dashboards/Widgets/widgetActions';
import { IWidgetData } from 'pages/Dashboards/interfaces';
import { exportToPng } from 'utils/globalFunction';
import { getRenderValue } from 'utils/widget';
import { useLoadWidget } from 'pages/Dashboards/Widgets/useLoadWidget';
import WidgetAdvanceFilter from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import {
  useViewFlaggedItems,
  useViewSubmissions,
  useViewTasks,
} from 'pages/Dashboards/hooks/useWidgets';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import { Stack, Typography } from '@mui/material';

const STATUS_COLORS = {
  'In Progress': '#F58500',
  'On Hold': '#64636D',
  Missed: '#EF5350',
  Open: '#3B8EF0',
  Completed: '#04B86C',
};

interface IBarChartWidgetProps {
  widgetId: string;
  editable?: boolean;
  globalFilters?: any;
}
function BarChartWidget({
  widgetId,
  editable,
  globalFilters,
}: IBarChartWidgetProps) {
  const chartRef: any = useRef(null);
  const { parsedData, getWidgetQuery, widget, summary } = useLoadWidget(
    widgetId,
    globalFilters,
  );
  const exportChartToImage = () => {
    exportToPng(chartRef.current, widget?.title);
  };
  const loading = getWidgetQuery.isFetching || false;
  const error = getWidgetQuery.error;
  const { handleViewTasks } = useViewTasks();
  const { handleViewSubmissions } = useViewSubmissions();
  const { handleViewFlaggedItemsChartWidgets } = useViewFlaggedItems();

  const handleBarClick = (e: any) => {
    if (widget?.entity === 'task') {
      handleViewTasks(
        widget,
        e.metadata,
        getWidgetQuery.refetch,
        globalFilters,
      );
    }

    if (widget?.entity === 'scheduledTask') {
      handleViewTasks(
        widget,
        e.metadata,
        getWidgetQuery.refetch,
        globalFilters,
        true,
      );
    }

    if (widget?.entity == 'submission') {
      handleViewSubmissions(
        widget,
        e.metadata,
        getWidgetQuery.refetch,
        globalFilters,
      );
    }

    if (
      widget?.entity === DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS &&
      widget?.type !== WIDGET_TYPE_IDS.CUSTOM_TABLE
    ) {
      handleViewFlaggedItemsChartWidgets(
        widget,
        e.metadata,
        getWidgetQuery.refetch,
        globalFilters,
      );
    }
  };
  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    const labelStyle = {
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '10px',
      letterSpacing: '0.17px',
    };

    const truncatedValue =
      payload.value.length > 12
        ? payload.value.substring(0, 12) + '...'
        : payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="black"
          transform="rotate(-45)"
          style={labelStyle}
        >
          {truncatedValue}
        </text>
      </g>
    );
  };

  const isPercentage = useMemo(() => {
    const pattern = /percentage|averageScore/;
    return pattern.test(widget?.config?.variable);
  }, [widget]);
  const isDuration = useMemo(() => {
    const pattern = /totalDuration|averageDuration/;
    return pattern.test(widget?.config?.variable);
  }, [widget]);

  const renderCustomizedLabel = ({ x, y, value, isPercentage, isDuration }) => {
    const labelStyle = {
      fontWeight: 700,
      fontSize: 10,
      lineHeight: '16.39px',
    };

    const labelWidth = 40;
    const borderRadius = 7;
    const finalValue = getRenderValue(value, isPercentage, isDuration);
    return (
      <g>
        <rect
          x={x}
          y={y - 22}
          width={labelWidth}
          height={18}
          rx={borderRadius}
          ry={borderRadius}
          fill="transparent"
          stroke="#E0E0E0"
          strokeWidth={1}
        />
        <text
          x={x + 20}
          y={y - 13}
          fill="black"
          textAnchor="middle"
          dominantBaseline="central"
          style={labelStyle}
        >
          {finalValue}
        </text>
      </g>
    );
  };

  const colorPicker = useCallback(
    (item) => {
      return (
        STATUS_COLORS[item?.valueX] ||
        stringToHslColor(item?.valueX, '66', '67')
      );
    },
    [stringToHslColor, STATUS_COLORS],
  );

  return (
    <WidgetCnt ref={chartRef}>
      <WidgetHeading>
        <Stack
          direction="column"
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Typography className="title" title={widget?.title}>
            {widget?.title}
          </Typography>
          {summary?.total >= 0 && (
            <Typography className="count">{summary?.total}</Typography>
          )}
        </Stack>
        <div className={'widgetActionsCnt'}>
          <WidgetAdvanceFilter
            widgetId={widgetId}
            globalFilters={globalFilters}
          />{' '}
          <WidgetActions
            exportChartToImage={exportChartToImage}
            widgetId={widgetId}
            editable={editable}
          />
        </div>
      </WidgetHeading>
      <WithLoader isLoading={loading}>
        <WithErrorState
          type={'bar'}
          error={error}
          reload={() => getWidgetQuery.refetch()}
        >
          {parsedData?.length ? (
            <WidgetContentWrapper>
              <ResponsiveContainer
                minWidth={parsedData.length * 40}
                height="100%"
              >
                <BarChart
                  style={{ fontSize: 12 }}
                  data={parsedData}
                  margin={{
                    top: 20,
                    right: 0,
                    left: -20,
                    bottom: 70,
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="0"
                    vertical={false}
                    stroke={'#EEEEEE'}
                  />
                  <XAxis
                    dataKey="valueX"
                    tickLine={false}
                    axisLine={false}
                    color={'red'}
                    angle={-45}
                    tick={(props) => <CustomizedAxisTick {...props} />}
                    interval={0}
                  />
                  <YAxis
                    type={'number'}
                    domain={[0, 'auto']}
                    tick={{
                      fill: '#000000',
                      fontSize: 13,
                      fontWeight: 700,
                      letterSpacing: '0.17px',
                    }}
                    tickCount={5}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(tick) =>
                      `${getRenderValue(tick, isPercentage, isDuration)}`
                    }
                  />
                  <Tooltip
                    formatter={(value) => [
                      `${getRenderValue(value, isPercentage, isDuration)}`,
                    ]}
                  />
                  <Bar
                    onClick={(e) => handleBarClick(e)}
                    dataKey="valueY"
                    fill="#8884d8"
                    label={({ x, y, value }) =>
                      renderCustomizedLabel({
                        x,
                        y,
                        value,
                        isPercentage,
                        isDuration,
                      })
                    }
                    barSize={40}
                    maxBarSize={40}
                    radius={[5, 5, 0, 0]}
                  >
                    {parsedData?.map((entry: IWidgetData, index: number) => (
                      <Cell key={`cell-${index}`} fill={colorPicker(entry)} />
                    ))}
                  </Bar>
                  {/*<Bar dataKey="uv" fill="#82ca9d" />*/}
                </BarChart>
              </ResponsiveContainer>
            </WidgetContentWrapper>
          ) : (
            <TasksByAssigneeEmptyState />
          )}
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
}

export default BarChartWidget;
