import { useState, useEffect, useMemo } from 'react';
import { LocationsList } from 'pages/Locations/LocationsList/locationsList.view';
import { LocationsMainCnt, StyledFab } from 'pages/Locations/locations.styles';
import LocationsHeader from 'pages/Locations/Header/LocationsHeader';
import LocationDetailView from 'pages/Locations/LocationDetail/LocationDetail.view';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getLocationsState,
  getLocationsFetchedState,
} from 'store/selectors/locations';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import { LocationsGridView } from './LocationsGrid/LocationsGridView';
import { useUserConfig } from 'controller/useUserConfig';
import useResponsive from 'utils/CustomHooks/useResponsive';
import LocationsMobileHeader from './Header/LocationsMobileHeader';
import AddRounded from '@mui/icons-material/AddRounded';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { Box, CircularProgress, Stack } from '@mui/material';
import NoAddedRecords from './EmptyRecords/NoAddedRecords.cmp';
import { isMobileApp } from 'utils/constants';
import { postToMobileApp } from 'utils/globalFunction';
import CTabs from 'components/CTabs';
import LocationsConfig from 'components/LocationsConfig/LocationsConfig';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function LocationsTabs() {
  const [activeTab, setActiveTab] = useState<string>('locations');
  const [showTabs, setShowTabs] = useState(false);
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileDeviceSm } = useResponsive();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_LOCATIONS)) {
      navigateWithWorkspaceUrl('/dashboard');
    }
  }, []);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [searchParams]);

  const tabs = useMemo(() => {
    const tabsArray = [
      {
        label: 'Locations',
        content: () => (activeTab !== 'config' ? <LocationsView /> : <></>),
      },
      {
        label: 'Configurations',
        content: () => (activeTab === 'config' ? <LocationsConfig /> : <></>),
      },
    ];

    return tabsArray;
  }, [activeTab]);

  useEffect(() => {
    if (
      location?.pathname?.endsWith('/locations') ||
      location?.pathname?.endsWith('/locations/')
    ) {
      setShowTabs(true);
    } else {
      setShowTabs(false);
    }
  }, [location?.pathname]);

  const onTabChangeCb = (activeTab: number) => {
    const tabName = activeTab === 0 ? 'locations' : 'config';
    setActiveTab(tabName);
    navigate(`${location.pathname}?tab=${tabName}`);
  };

  return globalThis?.platform !== 'mobile' &&
    !isMobileDeviceSm &&
    hasPermission(PERMISSIONS.CAN_MANAGE_WORKSPACE_CONFIG) ? (
    <CTabs
      data={tabs}
      onTabChangeCb={onTabChangeCb}
      activeTab={activeTab !== 'config' ? 0 : 1}
      variant="scrollable"
      rootSx={{ padding: '0px 20px', ...(!showTabs && { display: 'none' }) }}
    />
  ) : (
    <Box style={{ marginTop: 10 }}>
      <LocationsView />
    </Box>
  );
}

export default LocationsTabs;

function LocationsView() {
  const dispatch = useDispatch();
  const locationsList = useSelector(getLocationsState);
  const locationFetchState = useSelector(getLocationsFetchedState);

  const [selectedView, setSelectedView] = useState<ViewType | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [configKey, setConfigKey] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const { isMobileDeviceSm } = useResponsive();

  useEffect(() => {
    if (selectedView !== null && locationFetchState.locations) {
      setIsLoading(false);
    }
  }, [selectedView, locationFetchState]);

  const setLocationsViewType = async () => {
    const userConfig = await getUserConfig(configKey);
    if (userConfig?.locationsViewType) {
      setSelectedView(userConfig.locationsViewType);
    } else {
      setSelectedView(isMobileDeviceSm ? 'grid' : 'list');
    }
  };

  const setToggleStateConfig = async (value: ViewType) => {
    const prevView = selectedView;
    setSelectedView(value);
    const config = await editUserConfig(configKey, {
      locationsViewType: value,
    });
    if (!config) {
      setSelectedView(prevView);
    }
  };

  const onClickAdd = (data = {}) => {
    if (isMobileApp) {
      postToMobileApp('navigateTo', 'CreateLocation');
    } else {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createLocation',
          data,
        }),
      );
    }
  };

  useEffect(() => {
    if (isMobileDeviceSm) {
      setSelectedView('grid');
    }
  }, [isMobileDeviceSm, locationsList]);

  useEffect(() => {
    if (configKey) {
      setLocationsViewType();
    }
  }, [configKey]);

  useEffect(() => {
    if (userId) {
      setConfigKey(`locationsHomePageViewType::${userId}`);
    }
  }, [userId]);

  return (
    <LocationsMainCnt>
      <Routes>
        {locationsList?.length > 0 && (
          <Route path="/:locationId" element={<LocationDetailView />} />
        )}
        {locationsList?.length > 0 && (
          <Route
            path="/:locationId/sl/:subLocationId"
            element={<LocationDetailView />}
          />
        )}
        <Route
          path="/"
          element={
            <>
              {!isMobileDeviceSm && selectedView && (
                <LocationsHeader
                  selectedView={selectedView}
                  setSelectedView={setToggleStateConfig}
                  onSearch={(value) => setSearchTerm(value)}
                  onClickAdd={onClickAdd}
                />
              )}
              {isMobileDeviceSm && (
                <LocationsMobileHeader
                  onSearch={(value) => setSearchTerm(value)}
                />
              )}
              {isLoading && (
                <Stack width="100%" pt="200px" alignItems={'center'}>
                  <CircularProgress />
                </Stack>
              )}

              {!isLoading && locationsList?.length === 0 && (
                <Stack width="100%" alignItems={'center'}>
                  <NoAddedRecords />
                </Stack>
              )}
              {selectedView === 'list' &&
                !isLoading &&
                locationsList?.length > 0 && (
                  <LocationsList searchTerm={searchTerm} />
                )}
              {selectedView === 'grid' &&
                !isLoading &&
                locationsList?.length > 0 && (
                  <LocationsGridView searchTerm={searchTerm} />
                )}
              {isMobileDeviceSm && (
                <StyledFab onClick={onClickAdd} color="primary">
                  <AddRounded />
                </StyledFab>
              )}
            </>
          }
        />
      </Routes>
    </LocationsMainCnt>
  );
}
