import ListGridToggle from 'components/ListGridToggle';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { Stack } from '@mui/material';

// Icons
import SearchIcon from '@mui/icons-material/Search';

interface SearchAndToggleHeaderPropTypes {
  selectedView: ViewType;
  setSelectedView: (view: ViewType) => void;
  handleSearchInput: (value: string) => void;
}

const SearchAndToggleHeader = ({
  selectedView,
  setSelectedView,
  handleSearchInput,
}: SearchAndToggleHeaderPropTypes) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <CustomInput
        id="locationsSearchInput"
        suppressErrorSpace={true}
        grayInput={true}
        style={{ width: '200px', borderRadius: '8px' }}
        fieldProps={{
          onChange: (e) => handleSearchInput(e.target.value || ''),
          startAdornment: <SearchIcon />,
          placeholder: 'Search',
        }}
        label=""
      />
      <ListGridToggle
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      />
    </Stack>
  );
};

export default SearchAndToggleHeader;
