import { FC, useCallback, useRef, useEffect } from 'react';
import { DndContext, useDroppable } from '@dnd-kit/core';
import {
  verticalListSortingStrategy,
  SortableContext,
  arrayMove,
} from '@dnd-kit/sortable';

import ChecklistItemWrapper from 'pages/checklistV2/Builder/ChecklistItemWrapper';
import { Grid } from '@mui/material';
import { _colors } from 'components/ColorPicker';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import Stack from '@mui/material/Stack';
import VirtualList from 'components/VirtualList';
import useMeasure from 'react-use-measure';
import { groupBy } from 'lodash';
import { STEP_TYPES } from 'utils/templates';

interface IProps {
  checklist: any;
  checklistItems: any;
  originalChecklistItems: any;
  defaultLocationStep: string | null;
  setDefaultLocationStep: (stepId: string | null) => void;
  parentId?: string | null;
  relatedAutomationId?: string | null;
  autoFocusIndex: number;
  automations: any;
  virtualList?: boolean;
  fetchAndSetChecklistAutomations: () => void;
  setAutoFocusIndex(index: number | null): void;
  handleUpdateChecklistItems: (
    parentId: null | undefined | string,
    newItems: any[],
  ) => void;
  // updateChecklistItemById: (itemId: string, itemData: any) => void;
  deleteChecklistItemById: (itemId: string) => void;
  handleAddFollowUpItem: (item: any, prevItemData?: any) => void;
  handleAddChecklistItem: (
    itemType: string,
    itemId: string,
    itemData?: any,
    prevDescription?: string,
  ) => void;
  addingItem: boolean;
  sectionSteps: any;
  sectionVisibility: any;
  toggleSectionCollapse: (sectionId: string) => void;
  sectionEditingId: string | null;
  setSectionEditingId: (sectionId: string | null) => void;
  followupVisibility: any;
  handleSetFollowupVisibility: (
    checklistItemId: string,
    automationId: string,
  ) => void;
  itemCollapseState: any;
  handleItemCollapse: (itemId: string, unCollapse?: boolean) => void;
  autoFocusCursorPosition: number | null;
  setAutofocusCursorPosition: (index: number | null) => void;
  getSectionId?: (checklistItemIndex: number) => string | null;
  handleCollapseAllItems: (unCollapse?: boolean) => void;
  uploadingItemId: string | null;
  setUploadingItemId: (itemId: string | null) => void;
  checklistItemsHashRef: any;
  updateChecklistItemsHash: (checklistItemId: string, itemData: any) => void;
  uploadAttachmentsToStep: (itemId: string, files: any) => void;
  updateActiveChecklistItemOnScroll?: (removeFocus?: boolean) => void;
}

const ChecklistItems: FC<IProps> = (props) => {
  const {
    checklist,
    checklistItems,
    originalChecklistItems,
    parentId,
    relatedAutomationId,
    autoFocusIndex,
    virtualList = true,
    addingItem,
    sectionSteps,
    sectionVisibility,
    sectionEditingId,
    followupVisibility,
    itemCollapseState,
    setSectionEditingId,
    setAutoFocusIndex,
    handleUpdateChecklistItems,
    handleAddFollowUpItem,
    deleteChecklistItemById,
    handleAddChecklistItem,
    toggleSectionCollapse,
    handleSetFollowupVisibility,
    handleItemCollapse,
    autoFocusCursorPosition,
    setAutofocusCursorPosition,
    getSectionId,
    handleCollapseAllItems,
    uploadingItemId,
    setUploadingItemId,
    checklistItemsHashRef,
    updateChecklistItemsHash,
    uploadAttachmentsToStep,
    updateActiveChecklistItemOnScroll,
  } = props;

  const [ref, { height }] = useMeasure();
  const dragging = useRef<any>(false);
  const addingItemRef = useRef<any>(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      addingItemRef.current = false;
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [checklistItems]);

  const moveChunk = (
    array: any[],
    start: number,
    end: number,
    newIndex: number,
  ) => {
    if (
      start < 0 ||
      start >= array.length ||
      end < 0 ||
      end >= array.length ||
      newIndex < 0 ||
      newIndex >= array.length
    ) {
      return array.slice();
    }

    const newArray = array.slice();
    const chunk = newArray.splice(start, end - start + 1);
    newArray.splice(newIndex, 0, ...chunk);

    return newArray;
  };

  const handleDragEnd = ({ active, over, delta }) => {
    if (!over) {
      dragging.current = false;
      return;
    }
    if (active.id !== over.id) {
      const direction = delta.y > 0 ? 'down' : 'up';
      // const draggedActiveIndex = active?.data?.current?.sortable?.index || 0;
      const activeIndex = (originalChecklistItems as any[]).findIndex(
        (cItem) => cItem.id === active.id,
      );
      const activeItem = checklistItems[activeIndex];
      let overIndex = over?.data?.current?.sortable?.index || 0;

      // if dragging and dropping for follow up items
      if (parentId) {
        const activeIndex = active?.data?.current?.sortable?.index;
        const overIndex = over?.data?.current?.sortable?.index || 0;
        const newChecklistItems = arrayMove(
          checklistItems,
          activeIndex,
          overIndex,
        ).map((item: any, index) => {
          return {
            ...item,
            order: index,
          };
        });
        handleUpdateChecklistItems(parentId, newChecklistItems);
      } else {
        // if (overIndex === 0) {
        //   return;
        // }

        const newChecklistItemsCollapsed: any[] = arrayMove(
          checklistItems,
          activeIndex,
          overIndex,
        );

        const newPrevItem = newChecklistItemsCollapsed[overIndex - 1];
        const newPrevItemIndex = (originalChecklistItems as any[]).findIndex(
          (cItem) => cItem.id === newPrevItem?.id,
        );

        // TODO: Add this feature later on
        if (activeItem.type === STEP_TYPES.HEADER) {
          // let start = null;
          // let end = null;
          // let newIndex = null;
          // if (newPrevItem.type === STEP_TYPES.HEADER) {
          //   if (sectionVisibility[newPrevItem.id] === false) {
          //     start =
          //   } else {
          //     // cant move a section inside section
          //     return;
          //   }
          // } else {
          //   // check if the item is last in section
          // }
        } else {
          if (overIndex > 0) {
            if (
              newPrevItem?.type === STEP_TYPES.HEADER &&
              sectionVisibility[newPrevItem?.id] === false
            ) {
              overIndex =
                newPrevItemIndex +
                sectionSteps[newPrevItem.id] +
                (direction === 'down' ? 0 : 1);
              if (overIndex > originalChecklistItems.length - 1) {
                overIndex = originalChecklistItems.length - 1;
              }
            } else {
              overIndex = newPrevItemIndex + (direction === 'down' ? 0 : 1);
              if (overIndex > originalChecklistItems.length - 1) {
                overIndex = originalChecklistItems.length - 1;
              }
            }
          }

          const finalChecklistItems = arrayMove(
            originalChecklistItems,
            activeIndex,
            overIndex,
          ).map((item: any, index) => {
            const refItem = checklistItemsHashRef[item.id];
            const finalItem = refItem ?? item;
            return {
              ...finalItem,
              order: index,
            };
          });
          handleUpdateChecklistItems(parentId, finalChecklistItems);
        }
      }
    }
    dragging.current = false;
  };

  const onAddItem = useCallback(
    async (itemType: string, itemId: string, prevDescription?: string) => {
      addingItemRef.current = true;
      handleAddChecklistItem(
        itemType,
        itemId,
        {
          ParentId: parentId,
          RelatedAutomationId: relatedAutomationId,
        },
        prevDescription,
      );
      setSectionEditingId(null);
    },
    [checklist, checklistItems],
  );

  const { setNodeRef } = useDroppable({
    id: 'checklist-droppable',
  });

  const getIsItemVisible = (checklistItemIndex: number) => {
    let sectionId = null;
    const header = checklistItems?.filter(
      (item, cItemIdx) =>
        item.type === STEP_TYPES.HEADER && cItemIdx < checklistItemIndex,
    );
    if (header?.length) {
      sectionId = header[header.length - 1].id;
    }

    if (sectionId) {
      return (
        sectionVisibility[sectionId] === undefined ||
        sectionVisibility[sectionId] === true
      );
    }
    return true;
  };

  const handleDragStart = ({ active }) => {
    dragging.current = true;
    const activeItem = checklistItems.find((item) => item.id === active.id);
    if (activeItem?.ParentId) {
      return;
    }
    handleCollapseAllItems();
  };

  const getChildItems = (checklistItem: any) => {
    const itemAutomations = props.automations?.[checklistItem.id];
    if (itemAutomations?.length) {
      const automationIds = itemAutomations.map((auto) => auto.id);
      const childItems = checklistItem.ChildItems.filter((child) =>
        automationIds.includes(child.RelatedAutomationId),
      );
      return Object.entries(groupBy(childItems, 'RelatedAutomationId'));
    }
    return null;
  };

  return (
    <Grid ref={ref} height={'100%'} spacing={2}>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        onDragStart={(event) => handleDragStart(event)}
        autoScroll={!virtualList ? false : true}
      >
        <SortableContext
          items={checklistItems || []}
          strategy={verticalListSortingStrategy}
          disabled={!!uploadingItemId}
        >
          <Stack height="100%" ref={setNodeRef} width="100%">
            {!virtualList &&
              checklistItems?.map((checklistItem: any, index: number) => {
                if (
                  !getIsItemVisible(index) &&
                  checklistItem.type !== STEP_TYPES.HEADER
                ) {
                  return null;
                }
                return (
                  <ChecklistItemWrapper
                    key={checklistItem.id}
                    checklist={checklist}
                    checklistId={checklist.id}
                    checklistItems={checklistItems}
                    childItems={
                      checklistItem.ChildItems
                        ? getChildItems(checklistItem)
                        : null
                    }
                    defaultLocationStep={props.defaultLocationStep}
                    setDefaultLocationStep={props.setDefaultLocationStep}
                    automations={props.automations}
                    checklistItemIndex={index}
                    checklistItemData={checklistItem}
                    autoFocusItemId={autoFocusIndex}
                    addingItem={addingItem}
                    deleteChecklistItemById={deleteChecklistItemById}
                    addChecklistItem={onAddItem}
                    setAutoFocusItemId={setAutoFocusIndex}
                    handleUpdateChecklistItems={handleUpdateChecklistItems}
                    handleAddFollowUpItem={handleAddFollowUpItem}
                    fetchAndSetChecklistAutomations={
                      props.fetchAndSetChecklistAutomations
                    }
                    handleAddChecklistItem={handleAddChecklistItem}
                    sectionSteps={sectionSteps}
                    sectionVisibility={sectionVisibility}
                    toggleSectionCollapse={toggleSectionCollapse}
                    sectionEditingId={sectionEditingId}
                    setSectionEditingId={setSectionEditingId}
                    originalChecklistItems={originalChecklistItems}
                    handleSetFollowupVisibility={handleSetFollowupVisibility}
                    followupVisibility={followupVisibility}
                    itemCollapseState={itemCollapseState}
                    handleItemCollapse={handleItemCollapse}
                    autoFocusCursorPosition={autoFocusCursorPosition}
                    setAutofocusCursorPosition={setAutofocusCursorPosition}
                    getSectionId={getSectionId}
                    handleCollapseAllItems={handleCollapseAllItems}
                    uploadingItemId={uploadingItemId}
                    setUploadingItemId={setUploadingItemId}
                    checklistItemsHashRef={checklistItemsHashRef}
                    updateChecklistItemsHash={updateChecklistItemsHash}
                    uploadAttachmentsToStep={uploadAttachmentsToStep}
                  />
                );
              })}
            {virtualList && (
              <VirtualList
                height={height}
                rows={checklistItems?.map(
                  (checklistItem: any, index: number) => {
                    if (
                      !getIsItemVisible(index) &&
                      checklistItem.type !== STEP_TYPES.HEADER
                    ) {
                      return <></>;
                    }
                    return (
                      <ChecklistItemWrapper
                        key={checklistItem.id}
                        checklist={checklist}
                        checklistId={checklist.id}
                        checklistItems={checklistItems}
                        checklistItemIndex={index}
                        checklistItemData={checklistItem}
                        defaultLocationStep={props.defaultLocationStep}
                        setDefaultLocationStep={props.setDefaultLocationStep}
                        addingItem={addingItem}
                        addChecklistItem={onAddItem}
                        autoFocusItemId={autoFocusIndex}
                        setAutoFocusItemId={setAutoFocusIndex}
                        handleUpdateChecklistItems={handleUpdateChecklistItems}
                        handleAddFollowUpItem={handleAddFollowUpItem}
                        deleteChecklistItemById={deleteChecklistItemById}
                        automations={props.automations}
                        fetchAndSetChecklistAutomations={
                          props.fetchAndSetChecklistAutomations
                        }
                        sectionSteps={sectionSteps}
                        sectionVisibility={sectionVisibility}
                        handleAddChecklistItem={handleAddChecklistItem}
                        childItems={
                          checklistItem.ChildItems
                            ? getChildItems(checklistItem)
                            : null
                        }
                        toggleSectionCollapse={toggleSectionCollapse}
                        sectionEditingId={sectionEditingId}
                        setSectionEditingId={setSectionEditingId}
                        originalChecklistItems={originalChecklistItems}
                        handleSetFollowupVisibility={
                          handleSetFollowupVisibility
                        }
                        followupVisibility={followupVisibility}
                        itemCollapseState={itemCollapseState}
                        handleItemCollapse={handleItemCollapse}
                        autoFocusCursorPosition={autoFocusCursorPosition}
                        setAutofocusCursorPosition={setAutofocusCursorPosition}
                        getSectionId={getSectionId}
                        handleCollapseAllItems={handleCollapseAllItems}
                        uploadingItemId={uploadingItemId}
                        setUploadingItemId={setUploadingItemId}
                        checklistItemsHashRef={checklistItemsHashRef}
                        updateChecklistItemsHash={updateChecklistItemsHash}
                        uploadAttachmentsToStep={uploadAttachmentsToStep}
                      />
                    );
                  },
                )}
                onListScroll={() => {
                  updateActiveChecklistItemOnScroll?.(!addingItemRef.current);
                }}
              />
            )}
          </Stack>
        </SortableContext>
      </DndContext>
    </Grid>
  );
};

export default ChecklistItems;
