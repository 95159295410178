import { createSvgIcon } from '@mui/material';

const EmptyMailboxIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <ellipse
        cx="74.5749"
        cy="75.2049"
        rx="74.4792"
        ry="74.344"
        fill="#F4F4F4"
      />
      <path
        d="M108.636 34.8257H62.7598L63.39 79.7574H126.362V52.5293C126.361 47.8335 124.493 43.3306 121.169 40.0106C117.844 36.6907 113.336 34.8257 108.636 34.8257Z"
        fill="#716CC4"
      />
      <path
        d="M62.7596 34.8257C67.4609 34.8257 71.9697 36.6914 75.2941 40.0124C78.6184 43.3335 80.486 47.8377 80.486 52.5344V79.7574H45.0332V52.5293C45.0346 47.8335 46.9027 43.3306 50.2269 40.0106C53.5511 36.6907 58.0592 34.8257 62.7596 34.8257Z"
        fill="#252350"
      />
      <path
        d="M62.7589 124.689C58.0576 124.689 53.5488 122.823 50.2245 119.502C46.9001 116.181 45.0325 111.677 45.0325 106.98L45.0325 79.7572H80.4854V106.98C80.4854 111.677 78.6177 116.181 75.2934 119.502C71.9691 122.823 67.4603 124.689 62.7589 124.689Z"
        fill="#49449C"
      />
      <path
        opacity="0.09"
        d="M62.7589 124.689C58.0576 124.689 53.5488 122.823 50.2245 119.502C46.9001 116.181 45.0325 111.677 45.0325 106.98L45.0325 79.7572H80.4854V106.98C80.4854 111.677 78.6177 116.181 75.2934 119.502C71.9691 122.823 67.4603 124.689 62.7589 124.689Z"
        fill="black"
      />
      <path
        d="M95.8434 14.9136C96.113 14.9136 96.3715 15.0206 96.5621 15.211C96.7528 15.4014 96.8598 15.6597 96.8598 15.929V53.3213H94.7812V15.9493C94.7812 15.68 94.8883 15.4217 95.079 15.2313C95.2696 15.0409 95.5281 14.9339 95.7977 14.9339L95.8434 14.9136Z"
        fill="#49449C"
      />
      <path
        opacity="0.44"
        d="M95.8434 14.9136C96.113 14.9136 96.3715 15.0206 96.5621 15.211C96.7528 15.4014 96.8598 15.6597 96.8598 15.929V53.3213H94.7812V15.9493C94.7812 15.68 94.8883 15.4217 95.079 15.2313C95.2696 15.0409 95.5281 14.9339 95.7977 14.9339L95.8434 14.9136Z"
        fill="white"
      />
      <path
        d="M95.9051 57.2915C98.0887 57.2915 99.8589 55.523 99.8589 53.3415C99.8589 51.16 98.0887 49.3916 95.9051 49.3916C93.7214 49.3916 91.9512 51.16 91.9512 53.3415C91.9512 55.523 93.7214 57.2915 95.9051 57.2915Z"
        fill="#FF8444"
      />
      <path
        d="M96.8809 15.9492H106.613V19.7722L96.8809 22.2346V15.9492Z"
        fill="#FF8444"
      />
      <path
        d="M104.321 79.7573H87.1943V85.9767H104.321V79.7573Z"
        fill="#24285B"
      />
      <path
        d="M99.8532 85.9766H91.5186V135.467H99.8532V85.9766Z"
        fill="#49449C"
      />
      <path
        opacity="0.44"
        d="M99.8532 85.9766H91.5186V135.467H99.8532V85.9766Z"
        fill="white"
      />
      <path
        opacity="0.09"
        d="M91.5186 85.9766V102.563L99.8532 97.2577V85.9766H91.5186Z"
        fill="black"
      />
      <path
        d="M26.153 31.6674L28.4958 21.0361L30.5287 29.0934C30.729 29.8705 31.137 30.5786 31.709 31.142C32.281 31.7054 32.9955 32.1028 33.7761 32.2919L39.7527 33.7389L34.2132 35.3584C33.3339 35.6212 32.5352 36.1014 31.8911 36.7546C31.2471 37.4078 30.7784 38.2129 30.5287 39.0951L28.4348 46.3807L26.5748 38.5976C26.4102 37.9122 26.0585 37.2859 25.5587 36.7884C25.0589 36.2909 24.4307 35.9418 23.7441 35.7798L18.3672 34.5207L26.153 31.6674Z"
        fill="#FF8444"
      />
      <path
        d="M79.7079 46.7716L81.5222 38.5112L83.1281 44.7712C83.2854 45.3769 83.6051 45.9282 84.0529 46.3657C84.5007 46.8032 85.0595 47.1103 85.6692 47.2539L90.3142 48.381L86.0046 49.6401C85.3176 49.8405 84.6925 50.2115 84.1878 50.7184C83.683 51.2253 83.315 51.8517 83.118 52.5391L81.4917 58.2L80.0382 52.1532C79.9118 51.6194 79.639 51.1313 79.2502 50.7439C78.8615 50.3564 78.3723 50.0849 77.8377 49.9599L73.6602 48.9801L79.7079 46.7716Z"
        fill="#FF8444"
      />
      <path
        d="M23.3326 86.1135L24.5828 80.4272L25.6856 84.7326C25.7928 85.1489 26.0111 85.5284 26.3172 85.8306C26.6232 86.1328 27.0057 86.3464 27.4237 86.4486L30.6203 87.2203L27.6574 88.0885C27.1828 88.2257 26.7508 88.4813 26.4023 88.8312C26.0538 89.1812 25.8002 89.6139 25.6653 90.0888L24.5472 93.9829L23.5613 89.8198C23.4757 89.4486 23.2873 89.109 23.0177 88.8396C22.7481 88.5703 22.4082 88.3821 22.0366 88.2966L19.1602 87.6214L23.3326 86.1135Z"
        fill="#FF8444"
      />
      <path
        d="M53.7337 67.0746L54.6688 62.8149L55.4972 66.0439C55.5763 66.3562 55.7393 66.6409 55.9686 66.8673C56.1979 67.0936 56.4848 67.2531 56.7982 67.3284L59.197 67.9072L56.9558 68.5571C56.601 68.6606 56.2784 68.8522 56.018 69.1142C55.7576 69.3761 55.5679 69.6997 55.4667 70.0548L54.6434 72.969L53.9065 69.8568C53.841 69.5809 53.6998 69.3288 53.4987 69.1288C53.2975 68.9288 53.0445 68.7888 52.7681 68.7246L50.6133 68.2169L53.7337 67.0746Z"
        fill="#FF8444"
      />
    </>,
    'EmptyMailboxIcon',
  );
  return (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 150 150'} {...props} />
  );
};
export default EmptyMailboxIcon;
