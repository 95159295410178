import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import EditIcon from 'components/Icons/editIcon';
import ReOpenIcon from '@mui/icons-material/CachedOutlined';
import AddIcon from '@mui/icons-material/Add';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import NewFolderIcon from 'components/Icons/newFolderIcon';
import NewTemplateIcon from 'components/Icons/newTemplateIcon';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';

const folderActionDropdownOptions = [
  {
    label: 'New Template',
    id: 'new_template',
    icon: <NewTemplateIcon style={{ color: '#000000' }} />,
    iconAlign: 'start',
  },
  {
    label: 'New Folder',
    id: 'new_folder',
    icon: <NewFolderIcon style={{ color: '#000000' }} />,
    iconAlign: 'start',
  },
];

function AddTemplateFolderDropdown(props) {
  const { state, updateState } = useContext(MyTemplatesContext);
  const addFolder = (folder) => {
    //Only update state if user is standing inside the folder in which new folder is created
    //Or if he is created at root.
    if (folder?.ParentId == lastFolderId || !folder?.ParentId) {
      updateState({ data: [folder, ...state.data] });
    }
  };
  const { lastFolderId } = useCurrentFolderId();
  const dispatch = useDispatch();
  //Handler for option select of action dropdown
  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'new_folder':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.CREATE_FOLDER_DIALOG,
            data: {
              onSuccessCallback: addFolder,
              folderId: lastFolderId,
            },
          }),
        );
        break;
      case 'new_template':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.CREATE_TEMPLATE_DIALOG,
            data: { folderId: lastFolderId },
          }),
        );
        break;
    }
  };
  return (
    <ActionDropdown
      rootProps={{
        id: 'folders-action-dropdown',
        style: {
          position: 'absolute',
          top: -60,
          right: 0,
          zIndex: 1,
        },
      }}
      options={folderActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      iconButton={false}
      buttonProps={{
        startIcon: <AddIcon />,
        variant: 'contained',
        style: { padding: '4px 10px' },
      }}
      popperProps={{
        placement: 'bottom-start',
        sx: {
          zIndex: 1301,
        },
      }}
    >
      Add New
    </ActionDropdown>
  );
}

export default AddTemplateFolderDropdown;
