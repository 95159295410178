import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

const useCategory = (postBody: any) => {
  const dispatch = useDispatch();
  const serviceList: any = useSelector(selectors.getServiceAdmin);

  const getCategories = useCallback(() => {
    dispatch(actions.serviceListApiCall(postBody));
  }, [dispatch, postBody]);

  useEffect(() => {
    if (postBody) {
      getCategories();
    }
  }, []);

  const addHandler = (data) => {
    dispatch(actions.serviceAddApiCall(data));
  };

  const removeHandler = (data) => {
    dispatch(actions.serviceRemoveApiCall(data));
  };

  const updateHandler = (data, id) => {
    dispatch(
      actions.updateService({
        id: id,
        ...data,
        inProgress: true,
      }),
    );

    dispatch(actions.serviceEditApiCall(data, id));
  };

  return { addHandler, updateHandler, removeHandler };
};

export default useCategory;
