import { createSvgIcon } from '@mui/material';

const ThreeUsersAvatarIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_3955_32826)">
        <path
          d="M89.9689 94.8447C78.2631 97.5922 66.3551 99.3931 54.3602 100.23C51.3168 100.416 48.2236 100.528 45.1428 100.528C42.0621 100.528 38.9689 100.416 35.9317 100.23C23.9348 99.3927 12.0248 97.5919 0.316759 94.8447C0.316759 94.8447 -0.136658 79.8199 9.42235 69.5652C18.9814 59.3105 35.354 58.4161 45.1428 58.4161C54.9317 58.4161 71.3105 59.3292 80.8696 69.5652C90.4286 79.8012 89.9689 94.8447 89.9689 94.8447Z"
          fill="#6868FE"
        />
        <path
          d="M54.3602 63.4286V100.23C51.3167 100.416 48.2236 100.528 45.1428 100.528C42.0621 100.528 38.9689 100.416 35.9316 100.23V63.4286H54.3602Z"
          fill="#231F20"
        />
        <path
          d="M45.3789 50.472C54.6374 50.472 62.1429 41.8709 62.1429 31.2609C62.1429 20.6508 54.6374 12.0497 45.3789 12.0497C36.1204 12.0497 28.6149 20.6508 28.6149 31.2609C28.6149 41.8709 36.1204 50.472 45.3789 50.472Z"
          fill="white"
        />
        <path
          d="M45.3789 50.795C35.9628 50.795 28.2982 42.031 28.2982 31.2608C28.2982 19.8199 29.5404 11.7267 45.3789 11.7267C61.2174 11.7267 62.4659 19.8012 62.4659 31.2608C62.4659 42.031 54.8013 50.795 45.3789 50.795ZM45.3789 12.3664C30.0808 12.3664 28.9379 19.8199 28.9379 31.2608C28.9379 41.677 36.3168 50.1552 45.3789 50.1552C54.441 50.1552 61.8261 41.677 61.8261 31.2608C61.8261 19.8758 60.677 12.3664 45.3789 12.3664Z"
          fill="#231F20"
        />
        <path
          d="M55.9628 37.8881C56.7826 32.7701 65.2795 31.5093 64.8572 37.5403C64.7935 39.6369 63.9905 41.6434 62.5901 43.2049C61.1367 44.6832 58.7889 45.385 56.8944 44.5341"
          fill="white"
        />
        <path
          d="M58.6708 45.1988C58.0143 45.2025 57.3644 45.067 56.764 44.8013C56.7257 44.7842 56.6911 44.7596 56.6622 44.7291C56.6333 44.6986 56.6108 44.6628 56.5958 44.6235C56.5808 44.5843 56.5737 44.5425 56.5748 44.5006C56.576 44.4586 56.5854 44.4173 56.6025 44.3789C56.6196 44.3406 56.6441 44.306 56.6746 44.2771C56.7051 44.2483 56.741 44.2257 56.7802 44.2107C56.8195 44.1957 56.8612 44.1886 56.9032 44.1897C56.9452 44.1909 56.9865 44.2003 57.0249 44.2174C57.9377 44.5657 58.9338 44.6335 59.8854 44.4122C60.8371 44.1909 61.701 43.6905 62.3665 42.9752C63.7068 41.4658 64.4741 39.5333 64.5342 37.5156C64.7454 34.5218 62.7081 33.9628 61.8261 33.8572C59.559 33.6025 56.7205 35.2112 56.2795 37.9379C56.2821 37.9863 56.2738 38.0347 56.2552 38.0795C56.2366 38.1242 56.2082 38.1642 56.172 38.1965C56.1358 38.2287 56.0928 38.2524 56.0462 38.2658C55.9997 38.2792 55.9507 38.282 55.9029 38.2738C55.8551 38.2657 55.8097 38.2469 55.7702 38.2189C55.7307 38.1909 55.6979 38.1543 55.6745 38.1119C55.651 38.0695 55.6373 38.0223 55.6346 37.9739C55.6318 37.9256 55.6399 37.8772 55.6584 37.8323C56.1553 34.7268 59.3478 32.9317 61.9068 33.2236C63.6273 33.4162 65.3913 34.6646 65.1863 37.5715C65.0683 39.2733 64.5652 41.6708 62.8323 43.4348C62.2886 43.9879 61.6412 44.4284 60.9272 44.7311C60.2131 45.0337 59.4464 45.1927 58.6708 45.1988Z"
          fill="#231F20"
        />
        <path
          d="M34.7826 37.8881C33.9627 32.7701 25.4659 31.5093 25.8882 37.5403C25.9528 39.6355 26.7532 41.641 28.1491 43.2049C29.6087 44.6832 31.9503 45.385 33.8509 44.5341"
          fill="white"
        />
        <path
          d="M32.0932 45.1987C30.5289 45.1733 29.0387 44.5276 27.9503 43.4037C26.4999 41.7868 25.6644 39.7112 25.5901 37.5404C25.3913 34.6397 27.1553 33.3913 28.8696 33.1925C31.4348 32.9006 34.6273 34.7205 35.1242 37.8012C35.1369 37.8859 35.1161 37.9721 35.0662 38.0417C35.0164 38.1113 34.9414 38.1587 34.8572 38.1739C34.7732 38.1864 34.6877 38.1655 34.6192 38.1155C34.5506 38.0655 34.5044 37.9906 34.4907 37.9068C34.0497 35.1801 31.2174 33.559 28.9441 33.8261C28.0621 33.9317 26.0249 34.4907 26.2298 37.4845C26.3416 39.0807 26.8075 41.323 28.4037 42.9441C29.8758 44.441 32.0683 44.9627 33.7391 44.1863C33.7779 44.1687 33.8197 44.1588 33.8622 44.1574C33.9048 44.156 33.9472 44.163 33.987 44.178C34.0268 44.193 34.0633 44.2157 34.0943 44.2449C34.1253 44.274 34.1503 44.309 34.1677 44.3478C34.2014 44.4253 34.2034 44.513 34.1732 44.592C34.143 44.6709 34.0831 44.7349 34.0062 44.7702C33.4063 45.0475 32.7541 45.1936 32.0932 45.1987Z"
          fill="#231F20"
        />
        <path
          d="M35.9316 63.4285C35.9316 63.4285 38.2298 68.4347 44.9316 68.4347C46.8018 68.4861 48.6534 68.0519 50.3059 67.1746C51.9583 66.2972 53.3552 65.0065 54.3602 63.4285L53.4596 43.7701H36.8261L35.9316 63.4285Z"
          fill="white"
        />
        <path
          d="M44.9317 68.7577C38.0994 68.7577 35.6646 63.6148 35.6149 63.5651C35.6033 63.5161 35.6033 63.4651 35.6149 63.4161L36.5093 43.7515C36.5141 43.6691 36.5502 43.5917 36.6103 43.5351C36.6703 43.4785 36.7498 43.447 36.8323 43.4471H53.4659C53.5484 43.447 53.6278 43.4785 53.6879 43.5351C53.748 43.5917 53.7841 43.6691 53.7888 43.7515L54.6584 63.4161C54.6617 63.4744 54.6488 63.5324 54.6211 63.5838C53.5826 65.2021 52.1467 66.5273 50.4505 67.4331C48.7542 68.3388 46.8542 68.7949 44.9317 68.7577ZM36.236 63.3602C36.5404 63.9813 38.8509 68.1179 44.9317 68.1179C46.7285 68.1592 48.5065 67.7437 50.099 66.9105C51.6915 66.0773 53.0466 64.8536 54.0373 63.354L53.1615 44.0993H37.1304L36.236 63.3602Z"
          fill="#231F20"
        />
        <path
          d="M36.8261 52.1739C38.11 54.0373 39.8367 55.5526 41.8509 56.5839C45.1491 58.4037 50.0745 60.4534 54.0745 59.8447L53.4161 51.5528C50.4888 51.9191 47.5285 51.9399 44.5963 51.6149C42.0124 51.3851 39.3478 51.1367 36.9814 50.1367C36.5776 49.9379 36.3168 51.3789 36.8261 52.1739Z"
          fill="#231F20"
        />
        <path
          d="M52.5528 60.2485C48.5217 60.2485 44.1304 58.1801 41.7018 56.8323C39.6353 55.7886 37.8678 54.237 36.5652 52.323C36.3529 51.9673 36.2378 51.562 36.2313 51.1479C36.2247 50.7337 36.327 50.325 36.5279 49.9628C36.5955 49.8774 36.6892 49.8165 36.7947 49.7896C36.9002 49.7627 37.0116 49.7711 37.1118 49.8137C39.472 50.8075 42.2111 51.0559 44.6211 51.2609C47.5302 51.5895 50.468 51.5708 53.3726 51.205C53.4145 51.2001 53.457 51.2036 53.4975 51.2153C53.5381 51.2271 53.5758 51.2468 53.6087 51.2733C53.6427 51.299 53.6709 51.3317 53.6913 51.3692C53.7116 51.4067 53.7237 51.4481 53.7267 51.4907L54.3851 59.8013C54.3905 59.8798 54.366 59.9574 54.3164 60.0185C54.2668 60.0796 54.1959 60.1196 54.118 60.1304C53.6001 60.2106 53.0768 60.25 52.5528 60.2485ZM36.9565 50.4472C36.8706 50.7043 36.8379 50.9762 36.8603 51.2463C36.8827 51.5165 36.9598 51.7792 37.0869 52.0186C38.3394 53.842 40.0313 55.3202 42.0062 56.3168C44.6211 57.764 49.5776 60.0808 53.7453 59.5839L53.1242 51.8882C50.276 52.2077 47.401 52.2077 44.5528 51.8882C42.1428 51.6895 39.3975 51.4472 36.9565 50.4472Z"
          fill="#231F20"
        />
        <path
          d="M44.7205 53.4969H46.087C49.1884 53.4837 52.1584 52.2424 54.3469 50.0447C56.5353 47.8469 57.764 44.8717 57.764 41.7702V23.1987C57.764 23.1987 53.6894 24.3726 45.3416 24.3726C36.9938 24.3726 32.9193 23.1987 32.9193 23.1987V41.7702C32.92 43.3162 33.2264 44.8468 33.8206 46.2741C34.4149 47.7013 35.2854 48.9971 36.3821 50.0868C37.4787 51.1765 38.78 52.0388 40.211 52.624C41.6419 53.2092 43.1745 53.5059 44.7205 53.4969Z"
          fill="white"
        />
        <path
          d="M57.764 23.1987V41.7701C57.764 44.8803 56.5285 47.863 54.3293 50.0622C52.1301 52.2614 49.1474 53.4969 46.0373 53.4969H44.7205C41.6109 53.4952 38.6291 52.2592 36.4303 50.0604C34.2315 47.8615 32.9954 44.8798 32.9938 41.7701V23.1987C32.9938 23.1987 37.0745 24.3726 45.4162 24.3726C53.7578 24.3726 57.8385 23.1987 57.8385 23.1987M57.8385 22.5776C57.7809 22.5685 57.7222 22.5685 57.6646 22.5776C57.6273 22.5776 53.5528 23.7267 45.4286 23.7267C37.3044 23.7267 33.236 22.59 33.1988 22.5776C33.139 22.5689 33.0784 22.5689 33.0186 22.5776C32.8539 22.5776 32.6959 22.643 32.5794 22.7595C32.463 22.876 32.3975 23.034 32.3975 23.1987V41.7701C32.4008 45.0414 33.6987 48.1784 36.0078 50.4956C38.3169 52.8128 41.4493 54.1218 44.7205 54.1366H46.087C49.3663 54.1333 52.5105 52.8296 54.8299 50.5113C57.1494 48.193 58.4547 45.0495 58.4596 41.7701V23.1987C58.4596 23.034 58.3942 22.876 58.2777 22.7595C58.1612 22.643 58.0032 22.5776 57.8385 22.5776Z"
          fill="#231F20"
        />
        <path
          d="M19.646 97.472C19.5661 97.4722 19.4891 97.4423 19.4303 97.3882C19.3716 97.334 19.3355 97.2597 19.3292 97.1801L16.7764 72.3354C16.7677 72.2506 16.7928 72.1658 16.8464 72.0995C16.8999 72.0332 16.9774 71.9907 17.0621 71.9813C17.1462 71.9757 17.2294 72.002 17.295 72.055C17.3606 72.1079 17.4039 72.1836 17.4162 72.267L19.9627 97.1118C19.967 97.1534 19.963 97.1955 19.951 97.2355C19.9389 97.2756 19.919 97.3129 19.8924 97.3452C19.8659 97.3776 19.8331 97.4043 19.7962 97.424C19.7592 97.4436 19.7187 97.4557 19.677 97.4596L19.646 97.472Z"
          fill="#231F20"
        />
        <path
          d="M70.6459 97.472H70.6087C70.567 97.4681 70.5265 97.456 70.4895 97.4364C70.4526 97.4168 70.4199 97.39 70.3933 97.3577C70.3667 97.3253 70.3468 97.288 70.3348 97.248C70.3227 97.2079 70.3187 97.1658 70.323 97.1242L72.8757 72.2795C72.9013 72.2128 72.9482 72.1565 73.0092 72.1193C73.0701 72.0821 73.1417 72.0662 73.2127 72.074C73.2837 72.0818 73.35 72.1129 73.4015 72.1624C73.4529 72.212 73.4864 72.2771 73.4969 72.3478L70.9441 97.1925C70.9342 97.2663 70.8992 97.3345 70.8448 97.3854C70.7905 97.4363 70.7202 97.4669 70.6459 97.472Z"
          fill="#231F20"
        />
        <path
          d="M171.994 31.2919C171.994 41.9192 198.323 83.5465 155.224 83.5465C112.124 83.5465 138.453 41.9316 138.453 31.2919C138.453 20.6521 139.162 12.0372 155.224 12.0372C171.286 12.0372 171.994 20.6583 171.994 31.2919Z"
          fill="white"
        />
        <path
          d="M155.205 83.8509C144.41 83.8509 137.019 81.1925 133.242 75.944C126.876 67.0931 131.708 52.6583 135.236 42.1179C136.77 37.5465 138.093 33.5962 138.093 31.2794C138.093 19.8198 139.335 11.7142 155.205 11.7142C171.075 11.7142 172.317 19.8198 172.317 31.2794C172.317 33.5962 173.634 37.5465 175.168 42.1179C178.696 52.677 183.534 67.0931 177.162 75.944C173.385 81.2049 165.994 83.8509 155.205 83.8509ZM155.205 12.354C139.876 12.354 138.733 19.8447 138.733 31.2794C138.733 33.7018 137.398 37.6956 135.845 42.3229C132.36 52.7266 127.584 66.9813 133.764 75.5651C137.416 80.6397 144.627 83.2111 155.205 83.2111C165.783 83.2111 172.994 80.6397 176.64 75.5651C182.82 66.9813 178.044 52.7266 174.559 42.3229C173.012 37.6956 171.671 33.7018 171.671 31.2794C171.671 19.8757 170.528 12.3664 155.205 12.3664V12.354Z"
          fill="#231F20"
        />
        <path
          d="M199.683 94.8446C187.977 97.5921 176.069 99.393 164.074 100.23C161.031 100.416 157.938 100.528 154.857 100.528C151.776 100.528 148.683 100.416 145.646 100.23C133.649 99.3927 121.739 97.5918 110.031 94.8446C110.031 94.8446 109.578 79.8198 119.137 69.5838C128.696 59.3477 145.068 58.4347 154.857 58.4347C164.646 58.4347 181.031 59.3477 190.584 69.5838C200.137 79.8198 199.683 94.8446 199.683 94.8446Z"
          fill="#6868FE"
        />
        <path
          d="M164.074 63.4286V100.23C161.031 100.416 157.938 100.528 154.857 100.528C151.776 100.528 148.683 100.416 145.646 100.23V63.4286H164.074Z"
          fill="#231F20"
        />
        <path
          d="M129.36 97.472C129.28 97.4722 129.203 97.4423 129.145 97.3882C129.086 97.334 129.05 97.2597 129.044 97.1801L126.491 72.3354C126.482 72.2506 126.507 72.1658 126.561 72.0995C126.614 72.0332 126.692 71.9907 126.776 71.9813C126.861 71.9757 126.944 72.002 127.009 72.055C127.075 72.1079 127.118 72.1836 127.13 72.267L129.677 97.1118C129.682 97.1535 129.679 97.1959 129.667 97.2364C129.655 97.2768 129.636 97.3144 129.609 97.347C129.582 97.3795 129.549 97.4063 129.512 97.4256C129.474 97.445 129.433 97.4565 129.391 97.4596L129.36 97.472Z"
          fill="#231F20"
        />
        <path
          d="M180.36 97.4721H180.323C180.281 97.4682 180.241 97.4561 180.204 97.4365C180.167 97.4169 180.134 97.3901 180.108 97.3577C180.081 97.3254 180.061 97.2881 180.049 97.248C180.037 97.208 180.033 97.1659 180.037 97.1243L182.59 72.2795C182.601 72.1955 182.644 72.1189 182.71 72.0657C182.776 72.0125 182.86 71.9867 182.944 71.9938C183.029 72.0032 183.106 72.0457 183.16 72.112C183.213 72.1783 183.239 72.2631 183.23 72.3479L180.677 97.1926C180.667 97.2694 180.629 97.34 180.571 97.3913C180.512 97.4426 180.438 97.4713 180.36 97.4721Z"
          fill="#231F20"
        />
        <path
          d="M165.801 37.8882C166.621 32.7639 175.118 31.5031 174.708 37.5465C174.645 39.6451 173.842 41.6539 172.441 43.2173C170.988 44.7018 168.634 45.4037 166.733 44.5527"
          fill="white"
        />
        <path
          d="M168.509 45.2484C167.853 45.2537 167.204 45.1203 166.603 44.8571C166.559 44.8428 166.52 44.8197 166.486 44.7891C166.453 44.7585 166.426 44.7212 166.408 44.6796C166.39 44.6379 166.38 44.5929 166.381 44.5475C166.381 44.5021 166.391 44.4573 166.41 44.416C166.429 44.3747 166.456 44.3379 166.49 44.3079C166.525 44.2779 166.565 44.2554 166.608 44.2419C166.651 44.2285 166.697 44.2243 166.742 44.2297C166.787 44.2351 166.831 44.2499 166.87 44.2732C168.54 45.0248 170.739 44.503 172.211 43.031C173.808 41.4037 174.28 39.1552 174.391 37.5589C174.596 34.5589 172.559 33.9937 171.671 33.8943C169.385 33.6397 166.559 35.2484 166.118 37.9813C166.09 38.049 166.04 38.1053 165.976 38.1408C165.912 38.1763 165.838 38.1889 165.765 38.1766C165.693 38.1644 165.627 38.1279 165.578 38.0732C165.53 38.0185 165.501 37.9489 165.497 37.8757C165.994 34.7701 169.193 32.9627 171.758 33.2546C173.478 33.4534 175.242 34.7018 175.044 37.6024C174.926 39.3105 174.422 41.7142 172.683 43.4782C171.581 44.5977 170.08 45.2343 168.509 45.2484Z"
          fill="#231F20"
        />
        <path
          d="M144.602 37.8882C143.783 32.7639 135.286 31.5031 135.696 37.5465C135.761 39.6446 136.564 41.6525 137.963 43.2173C139.422 44.7018 141.77 45.4037 143.671 44.5527"
          fill="white"
        />
        <path
          d="M141.894 45.2484C140.325 45.2232 138.829 44.5779 137.733 43.4534C136 41.6894 135.497 39.2857 135.379 37.5776C135.174 34.677 136.938 33.4286 138.658 33.2298C141.224 32.9379 144.429 34.764 144.919 37.8509C144.927 37.8927 144.927 37.9356 144.917 37.9771C144.908 38.0186 144.89 38.0577 144.865 38.0921C144.84 38.1264 144.808 38.1553 144.772 38.1768C144.735 38.1984 144.694 38.2122 144.652 38.2174C144.569 38.2301 144.484 38.2099 144.416 38.1612C144.348 38.1125 144.301 38.0391 144.286 37.9565C143.851 35.2236 141 33.6087 138.733 33.8696C137.845 33.9689 135.807 34.5342 136.019 37.5342C136.079 39.5567 136.849 41.4936 138.193 43.0062C138.859 43.7214 139.724 44.2215 140.677 44.4428C141.63 44.664 142.626 44.5964 143.54 44.2484C143.618 44.2138 143.706 44.2114 143.785 44.2417C143.864 44.272 143.928 44.3325 143.963 44.4099C143.997 44.4874 144 44.5753 143.969 44.6546C143.939 44.7338 143.879 44.7977 143.801 44.8323C143.202 45.1046 142.552 45.2465 141.894 45.2484Z"
          fill="#231F20"
        />
        <path
          d="M145.739 63.5155C145.739 63.5155 148.037 68.5279 154.758 68.5279C160.112 68.5279 164.193 63.5155 164.193 63.5155L163.298 43.8198H146.634L145.739 63.5155Z"
          fill="white"
        />
        <path
          d="M154.758 68.8446C147.925 68.8446 145.441 63.6956 145.441 63.6459C145.418 63.5996 145.408 63.5483 145.41 63.4968L146.311 43.8012C146.315 43.7198 146.351 43.6433 146.409 43.5869C146.468 43.5304 146.546 43.4983 146.627 43.4968H163.292C163.373 43.4983 163.451 43.5304 163.51 43.5869C163.569 43.6433 163.604 43.7198 163.609 43.8012L164.509 63.4968C164.51 63.5757 164.484 63.6525 164.435 63.7142C164.273 63.9254 160.205 68.8446 154.758 68.8446ZM146.062 63.4471C146.366 64.0682 148.677 68.2049 154.758 68.2049C159.503 68.2049 163.286 64.0745 163.87 63.4037L162.988 44.149H146.938L146.062 63.4471Z"
          fill="#231F20"
        />
        <path
          d="M146.634 52.2484C147.921 54.1133 149.652 55.6288 151.671 56.6584C154.969 58.4844 159.907 60.5403 163.913 59.9254L163.255 51.6025C160.321 51.9665 157.354 51.9853 154.416 51.6584C151.826 51.4285 149.161 51.1801 146.789 50.1801C146.385 50 146.124 51.441 146.634 52.2484Z"
          fill="#231F20"
        />
        <path
          d="M162.385 60.3478C158.354 60.3478 153.95 58.2795 151.522 56.9317C149.45 55.8846 147.678 54.3288 146.373 52.4099C146.16 52.0542 146.045 51.649 146.039 51.2348C146.032 50.8206 146.134 50.4119 146.335 50.0497C146.403 49.9643 146.497 49.9035 146.602 49.8765C146.708 49.8496 146.819 49.8581 146.919 49.9006C149.286 50.9006 152.025 51.1428 154.441 51.3478C157.356 51.6795 160.3 51.6608 163.211 51.2919C163.296 51.2834 163.38 51.3078 163.447 51.3602C163.48 51.387 163.506 51.4201 163.525 51.4575C163.545 51.4948 163.556 51.5357 163.559 51.5776L164.217 59.9006C164.225 59.9792 164.202 60.0576 164.153 60.1199C164.105 60.1822 164.034 60.2237 163.956 60.236C163.436 60.311 162.911 60.3483 162.385 60.3478ZM146.764 50.5093C146.678 50.7664 146.645 51.0383 146.668 51.3084C146.69 51.5785 146.767 51.8413 146.894 52.0807C148.151 53.9045 149.844 55.3843 151.82 56.3851C154.441 57.8323 159.41 60.1552 163.578 59.6521L162.956 51.9441C160.104 52.264 157.225 52.264 154.373 51.9441C151.956 51.7515 149.205 51.5093 146.764 50.5093Z"
          fill="#231F20"
        />
        <path
          d="M167.634 28.3043V41.8198C167.634 44.9322 166.398 47.9171 164.197 50.1184C161.997 52.3198 159.013 53.5573 155.901 53.5589H154.534C152.992 53.5598 151.464 53.2568 150.039 52.6673C148.614 52.0778 147.318 51.2133 146.227 50.1232C145.136 49.0331 144.27 47.7388 143.679 46.3141C143.088 44.8894 142.783 43.3622 142.783 41.8198V28.9999L150.174 28.3788L151.025 22.5155L152.888 29.149L158.13 28.4534C158.671 26.1117 158.13 21.0496 158.056 20.8198L161.814 27.9503L167.634 28.3043Z"
          fill="white"
        />
        <path
          d="M155.901 53.882H154.534C151.334 53.8803 148.266 52.6094 146.002 50.348C143.739 48.0866 142.465 45.0196 142.46 41.8199V29C142.459 28.9191 142.49 28.8412 142.546 28.7823C142.601 28.7234 142.677 28.688 142.758 28.6832L149.894 28.0621L150.708 22.472C150.718 22.3999 150.752 22.3333 150.805 22.2835C150.859 22.2337 150.927 22.2038 151 22.1987C151.074 22.1894 151.15 22.2085 151.211 22.2523C151.272 22.296 151.314 22.3612 151.329 22.4348L153.112 28.8012L157.851 28.1801C158.292 25.8261 157.783 21.1428 157.733 20.9006C157.718 20.8598 157.712 20.8166 157.714 20.7733C157.716 20.73 157.726 20.6875 157.745 20.6483C157.763 20.609 157.789 20.5738 157.821 20.5447C157.853 20.5155 157.891 20.4929 157.932 20.4783C157.972 20.4636 158.016 20.4571 158.059 20.4591C158.102 20.4611 158.145 20.4717 158.184 20.4902C158.223 20.5086 158.258 20.5346 158.288 20.5667C158.317 20.5987 158.339 20.6362 158.354 20.677L162.05 27.6211L167.689 27.9938C167.77 27.9985 167.846 28.034 167.901 28.0929C167.957 28.1518 167.988 28.2297 167.988 28.3105V41.8261C167.983 45.0274 166.707 48.0957 164.44 50.3564C162.173 52.6171 159.102 53.8853 155.901 53.882ZM143.106 29.2981V41.8199C143.109 44.8493 144.314 47.7537 146.457 49.8952C148.6 52.0368 151.505 53.2406 154.534 53.2422H155.901C158.929 53.2389 161.832 52.0344 163.974 49.8931C166.115 47.7517 167.32 44.8482 167.323 41.8199V28.6087L161.801 28.2422C161.747 28.2379 161.695 28.2203 161.65 28.191C161.604 28.1618 161.567 28.1218 161.54 28.0745L158.528 22.4099C158.671 24.1863 158.795 26.9503 158.435 28.5341C158.421 28.5982 158.387 28.6562 158.337 28.6997C158.288 28.7432 158.227 28.7701 158.161 28.7764L152.919 29.472C152.842 29.4826 152.764 29.4652 152.699 29.423C152.634 29.3808 152.587 29.3166 152.565 29.2422L151.124 24.0683L150.503 28.4161C150.493 28.4883 150.459 28.5549 150.406 28.6047C150.353 28.6544 150.284 28.6844 150.211 28.6894L143.106 29.2981Z"
          fill="#231F20"
        />
        <path
          d="M145.155 73.3291C133.075 60.385 112.379 59.236 100 59.236C87.6211 59.236 66.9255 60.385 54.8447 73.3291C42.764 86.2732 43.3416 105.255 43.3416 105.255C43.3416 105.255 72.1056 112.422 100 112.422C127.894 112.422 156.658 105.236 156.658 105.236C156.658 105.236 157.23 86.2732 145.155 73.3291Z"
          fill="#6868FE"
        />
        <path
          d="M111.646 65.5465L103.397 94.0496L100 105.795L96.5962 94.031L88.354 65.5465C93.9379 63.8881 106.062 63.8881 111.646 65.5465Z"
          fill="#231F20"
        />
        <path
          d="M100.298 49.174C111.999 49.174 121.484 38.3009 121.484 24.8883C121.484 11.4756 111.999 0.602539 100.298 0.602539C88.5973 0.602539 79.1118 11.4756 79.1118 24.8883C79.1118 38.3009 88.5973 49.174 100.298 49.174Z"
          fill="white"
        />
        <path
          d="M100.298 49.4907C88.441 49.4907 78.7888 38.4534 78.7888 24.8882C78.7888 10.4783 80.3354 0.279541 100.298 0.279541C120.261 0.279541 121.807 10.4783 121.807 24.8882C121.807 38.4534 112.161 49.4907 100.298 49.4907ZM100.298 0.919291C80.882 0.919291 79.4286 10.41 79.4286 24.8882C79.4286 38.0994 88.795 48.8509 100.298 48.8509C111.801 48.8509 121.168 38.0994 121.168 24.8882C121.168 10.41 119.714 0.919291 100.298 0.919291Z"
          fill="#231F20"
        />
        <path
          d="M113.665 33.2298C114.696 26.764 125.435 25.1553 124.901 32.7951C124.721 35.41 123.882 38.0808 122.043 39.9503C120.205 41.8199 117.236 42.7081 114.839 41.6398"
          fill="white"
        />
        <path
          d="M117.093 42.4224C116.277 42.4271 115.468 42.2599 114.72 41.9317C114.643 41.8952 114.583 41.8301 114.553 41.7501C114.523 41.6701 114.525 41.5816 114.559 41.5031C114.594 41.4263 114.658 41.3664 114.737 41.3362C114.816 41.306 114.904 41.3079 114.981 41.3417C117.13 42.3106 119.95 41.6398 121.814 39.7267C123.845 37.6584 124.441 34.8013 124.584 32.7702C124.77 30.0932 123.46 28.3292 121.081 28.0559C118.168 27.7205 114.54 29.7889 113.981 33.2795C113.966 33.3638 113.919 33.4388 113.849 33.4886C113.78 33.5385 113.693 33.5593 113.609 33.5466C113.567 33.5407 113.527 33.5263 113.491 33.5044C113.455 33.4825 113.424 33.4536 113.4 33.4193C113.375 33.385 113.358 33.3462 113.349 33.3051C113.34 33.264 113.34 33.2215 113.348 33.1802C113.969 29.3292 117.969 27.0622 121.149 27.4224C123.286 27.6646 125.497 29.2112 125.224 32.8137C125.074 34.9566 124.441 37.969 122.273 40.174C120.911 41.5778 119.049 42.386 117.093 42.4224Z"
          fill="#231F20"
        />
        <path
          d="M86.9565 33.2298C85.9192 26.764 75.1863 25.1553 75.7143 32.7951C75.9006 35.41 76.7391 38.0808 78.5776 39.9503C80.4161 41.8199 83.3851 42.7081 85.7764 41.6398"
          fill="white"
        />
        <path
          d="M83.5031 42.4224C81.5447 42.3897 79.6793 41.581 78.3167 40.174C76.149 37.969 75.5155 34.9566 75.3664 32.8137C75.1118 29.2112 77.3043 27.6646 79.4348 27.4224C82.6273 27.0622 86.6211 29.3292 87.2422 33.1802C87.2606 33.2252 87.2686 33.2738 87.2656 33.3224C87.2626 33.371 87.2487 33.4182 87.2248 33.4606C87.201 33.5031 87.1679 33.5396 87.128 33.5674C87.088 33.5952 87.0423 33.6137 86.9943 33.6213C86.9462 33.629 86.8971 33.6258 86.8505 33.6118C86.8039 33.5978 86.761 33.5735 86.7251 33.5406C86.6892 33.5077 86.6612 33.4672 86.6432 33.422C86.6252 33.3768 86.6177 33.3281 86.6211 33.2795C86.0621 29.7889 82.4348 27.6895 79.5217 28.0559C77.1366 28.3292 75.8323 30.0932 76.0186 32.7702C76.1553 34.8013 76.7515 37.6584 78.7888 39.7267C80.6522 41.6398 83.4845 42.3044 85.6211 41.3417C85.6594 41.3241 85.7008 41.3144 85.7428 41.313C85.7849 41.3117 85.8268 41.3187 85.8662 41.3338C85.9055 41.3488 85.9414 41.3715 85.9719 41.4006C86.0023 41.4297 86.0266 41.4645 86.0435 41.5031C86.0611 41.5419 86.0709 41.5837 86.0724 41.6262C86.0738 41.6688 86.0668 41.7112 86.0518 41.751C86.0368 41.7908 86.0141 41.8273 85.9849 41.8583C85.9558 41.8893 85.9208 41.9143 85.882 41.9317C85.1323 42.2608 84.3217 42.428 83.5031 42.4224Z"
          fill="#231F20"
        />
        <path
          d="M88.354 65.5466C88.354 65.5466 91.2608 71.8758 99.7329 71.8758C108.205 71.8758 111.646 65.5466 111.646 65.5466L110.515 40.7019H89.4844L88.354 65.5466Z"
          fill="white"
        />
        <path
          d="M99.7329 72.1988C91.1553 72.1988 88.0932 65.7454 88.0621 65.6833C88.0503 65.6343 88.0503 65.5832 88.0621 65.5342L89.1926 40.6895C89.1958 40.6076 89.2306 40.5302 89.2896 40.4735C89.3487 40.4167 89.4274 40.3851 89.5093 40.3851H110.54C110.622 40.3851 110.701 40.4167 110.76 40.4735C110.819 40.5302 110.854 40.6076 110.857 40.6895L111.988 65.5342C111.994 65.5927 111.981 65.6516 111.95 65.7019C110.643 67.7424 108.832 69.4119 106.692 70.5498C104.552 71.6877 102.156 72.2556 99.7329 72.1988ZM88.677 65.4783C89.0186 66.1429 92.0373 71.5528 99.7329 71.5528C102.022 71.6037 104.287 71.0718 106.315 70.0071C108.342 68.9423 110.065 67.3796 111.323 65.4659L110.205 40.9938H89.795L88.677 65.4783Z"
          fill="#231F20"
        />
        <path
          d="M89.4845 51.3354C90.9441 53.6832 93.3665 55.5279 95.8385 56.9254C100.006 59.2298 106.236 61.8198 111.286 61.0496L110.46 50.5403C106.749 51.0029 102.996 51.028 99.2795 50.6149C96.0187 50.3292 92.6522 49.9938 89.6584 48.7515C89.1677 48.4969 88.8199 50.3105 89.4845 51.3354Z"
          fill="#231F20"
        />
        <path
          d="M109.36 61.4906C104.292 61.4906 98.7515 58.8819 95.6956 57.1925C92.6397 55.503 90.5466 53.6273 89.2298 51.5155C88.677 50.6397 88.7329 49.149 89.1801 48.6273C89.2515 48.5358 89.3511 48.4705 89.4633 48.4413C89.5756 48.4121 89.6943 48.4207 89.8012 48.4658C92.8012 49.708 96.2609 50.031 99.323 50.2981C103.006 50.7139 106.726 50.691 110.404 50.2297C110.487 50.2188 110.572 50.2411 110.64 50.2919C110.703 50.3477 110.743 50.4253 110.752 50.5093L111.584 61.0186C111.591 61.0964 111.568 61.1741 111.519 61.2354C111.471 61.2967 111.4 61.3369 111.323 61.3478C110.673 61.445 110.017 61.4927 109.36 61.4906ZM89.6211 49.0683C89.4822 49.4103 89.4213 49.779 89.4428 50.1475C89.4643 50.516 89.5677 50.8751 89.7453 51.1987C90.9876 53.2235 93.1056 55.062 95.9938 56.6645C99.3292 58.5279 105.652 61.4596 110.957 60.7887L110.174 50.9005C106.554 51.3115 102.899 51.3115 99.2795 50.9005C96.1925 50.6273 92.7018 50.3105 89.6211 49.0683Z"
          fill="#231F20"
        />
        <path
          d="M99.4348 52.9938H101.162C105.094 52.9938 108.865 51.4318 111.645 48.6514C114.426 45.8709 115.988 42.0999 115.988 38.1677V14.7019C115.988 14.7019 110.832 16.1864 100.298 16.1864C89.764 16.1864 84.6149 14.7019 84.6149 14.7019V38.1677C84.6149 42.0988 86.1761 45.8689 88.9552 48.6492C91.7343 51.4294 95.5038 52.9922 99.4348 52.9938Z"
          fill="white"
        />
        <path
          d="M115.988 14.7019V38.1677C115.988 42.0998 114.426 45.8709 111.645 48.6513C108.865 51.4317 105.094 52.9938 101.161 52.9938H99.4348C95.5037 52.9921 91.7342 51.4294 88.9551 48.6491C86.1761 45.8689 84.6149 42.0987 84.6149 38.1677V14.7019C84.6149 14.7019 89.7702 16.1863 100.298 16.1863C110.826 16.1863 115.988 14.7019 115.988 14.7019ZM84.6149 14.0807C84.4767 14.0811 84.3421 14.1245 84.2298 14.205C84.1489 14.264 84.0836 14.3417 84.0392 14.4315C83.9949 14.5212 83.9729 14.6204 83.9751 14.7205V38.1863C83.9784 42.286 85.6081 46.217 88.5064 49.1165C91.4048 52.016 95.335 53.6472 99.4348 53.6521H101.161C105.262 53.6472 109.193 52.0162 112.092 49.1169C114.991 46.2175 116.622 42.2866 116.627 38.1863V14.7019C116.63 14.6018 116.608 14.5026 116.563 14.4128C116.519 14.3231 116.453 14.2454 116.373 14.1863C116.26 14.1059 116.126 14.0625 115.988 14.0621C115.928 14.0534 115.867 14.0534 115.807 14.0621C115.758 14.0621 110.596 15.5155 100.279 15.5155C89.9627 15.5155 84.8199 14.0745 84.7515 14.0621C84.6939 14.053 84.6352 14.053 84.5776 14.0621L84.6149 14.0807Z"
          fill="#231F20"
        />
        <path
          d="M100 78.4348L96.0062 75.3541L100 72.2733L103.994 75.3541L100 78.4348Z"
          fill="white"
        />
        <path
          d="M103.398 94.0497L100 105.795L96.6025 94.031L98.6522 76.7639L98.6956 76.4037H101.304L101.342 76.7764L103.398 94.0497Z"
          fill="white"
        />
        <path
          d="M67.7702 108.491C67.6913 108.491 67.6151 108.462 67.5565 108.409C67.4979 108.356 67.4612 108.283 67.4534 108.205L64.2298 76.795C64.2247 76.7529 64.228 76.7102 64.2397 76.6694C64.2513 76.6286 64.2711 76.5906 64.2977 76.5575C64.3244 76.5245 64.3574 76.4972 64.3948 76.4772C64.4322 76.4571 64.4733 76.4449 64.5155 76.441C64.599 76.4356 64.6813 76.4621 64.7459 76.5151C64.8105 76.5682 64.8525 76.6438 64.8634 76.7267L68.087 108.137C68.0957 108.221 68.0705 108.306 68.017 108.373C67.9635 108.439 67.886 108.481 67.8013 108.491H67.7702Z"
          fill="#231F20"
        />
        <path
          d="M132.23 108.491C132.145 108.481 132.068 108.439 132.014 108.373C131.961 108.306 131.935 108.221 131.944 108.137L135.168 76.7267C135.179 76.6438 135.221 76.5682 135.285 76.5151C135.35 76.4621 135.432 76.4356 135.516 76.441C135.558 76.4449 135.599 76.4571 135.636 76.4772C135.674 76.4972 135.707 76.5245 135.733 76.5575C135.76 76.5906 135.78 76.6286 135.791 76.6694C135.803 76.7102 135.806 76.7529 135.801 76.795L132.578 108.205C132.574 108.247 132.562 108.287 132.542 108.324C132.522 108.361 132.496 108.394 132.463 108.42C132.431 108.447 132.394 108.467 132.354 108.479C132.314 108.491 132.271 108.495 132.23 108.491Z"
          fill="#231F20"
        />
        <path
          d="M43.3727 105.068C43.3305 105.067 43.2891 105.057 43.2508 105.039C43.2126 105.021 43.1783 104.996 43.1499 104.965C43.1216 104.933 43.0998 104.897 43.0857 104.857C43.0717 104.817 43.0658 104.775 43.0683 104.733C43.0683 104.491 44.3106 80.1677 57.5155 70.3665C69.5093 61.4473 88.1491 59.41 88.3354 59.3914C88.4057 59.4027 88.4702 59.437 88.519 59.4889C88.5678 59.5407 88.5981 59.6072 88.6052 59.6781C88.6122 59.7489 88.5957 59.8201 88.5582 59.8805C88.5206 59.941 88.4641 59.9874 88.3975 60.0125C88.2174 60.0125 69.764 62.0497 57.8944 70.8634C44.9627 80.4845 43.7143 104.503 43.7081 104.745C43.7081 104.789 43.6994 104.832 43.6823 104.872C43.6653 104.912 43.6404 104.948 43.6091 104.978C43.5778 105.008 43.5407 105.032 43.5001 105.047C43.4594 105.063 43.4161 105.07 43.3727 105.068Z"
          fill="#231F20"
        />
        <path
          d="M156.665 105.068C156.582 105.068 156.503 105.037 156.443 104.98C156.382 104.924 156.346 104.846 156.342 104.764C156.342 104.522 155.099 80.5031 142.149 70.882C130.298 62.0683 111.832 60.0497 111.646 60.031C111.597 60.0383 111.547 60.0342 111.5 60.0191C111.453 60.004 111.41 59.9784 111.375 59.9441C111.339 59.9099 111.312 59.8679 111.295 59.8214C111.278 59.775 111.272 59.7253 111.278 59.6762C111.283 59.6271 111.3 59.5799 111.326 59.5382C111.353 59.4966 111.388 59.4615 111.431 59.4358C111.473 59.4101 111.52 59.3944 111.569 59.3899C111.619 59.3854 111.668 59.3923 111.714 59.4099C111.901 59.4099 130.54 61.4658 142.534 70.3851C155.714 80.1863 156.969 104.509 156.981 104.752C156.983 104.836 156.953 104.917 156.896 104.98C156.839 105.042 156.761 105.081 156.677 105.087L156.665 105.068Z"
          fill="#231F20"
        />
      </g>
      <defs>
        <clipPath id="clip0_3955_32826">
          <rect width="200" height="112.422" fill="white" />
        </clipPath>
      </defs>
    </>,
    'ThreeUsersAvatarIcon',
  );
  return (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 200 113'} {...props} />
  );
};
export default ThreeUsersAvatarIcon;
