import { createSvgIcon } from '@mui/material';

const PhotoAnnotateIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M14.3867 1.83971C15.4396 0.86483 17.0746 0.896265 18.0892 1.91088C19.1038 2.9255 19.1353 4.56057 18.1604 5.61343L12.835 11.365C12.5067 11.7195 12.3426 11.8968 12.1492 11.9654C11.9793 12.0257 11.7943 12.0293 11.6221 11.9755C11.4263 11.9144 11.2555 11.7436 10.9138 11.4019L8.59819 9.0863C8.25654 8.74465 8.08571 8.57382 8.02457 8.37799C7.97082 8.20582 7.97437 8.02086 8.0347 7.85088C8.10333 7.65755 8.2806 7.49341 8.63513 7.16514L14.3867 1.83971Z"
        fill="#AB47BC"
      />
      <path
        d="M7.30181 10.1469C6.95975 9.80488 6.78872 9.63385 6.58446 9.57395C6.41979 9.52567 6.19885 9.53633 6.0396 9.60024C5.84205 9.67952 5.71859 9.82943 5.47167 10.1292C5.39026 10.2281 5.31557 10.3316 5.25059 10.4417C5.02505 10.8236 4.90904 11.2511 4.90341 11.6803C3.84004 11.5897 2.74541 11.9513 1.9317 12.765C1.07239 13.6243 1.12063 14.8335 1.15352 15.6578L1.15772 15.7628C1.19488 16.6892 1.22233 17.3733 0.925112 17.9532C0.7927 18.2116 0.80427 18.5202 0.955653 18.7679C1.10704 19.0156 1.37641 19.1667 1.66671 19.1667C1.89366 19.1667 2.15253 19.1734 2.4293 19.1806C3.0555 19.1969 3.77341 19.2155 4.42024 19.164C5.37401 19.0881 6.45154 18.8518 7.235 18.0683C8.04869 17.2546 8.41027 16.16 8.31976 15.0967C8.74894 15.0911 9.17652 14.9751 9.55844 14.7495C9.66847 14.6845 9.77205 14.6099 9.87089 14.5284C10.1707 14.2815 10.3206 14.1581 10.3999 13.9605C10.4638 13.8013 10.4744 13.5803 10.4262 13.4157C10.3663 13.2114 10.1952 13.0404 9.85317 12.6983L7.30181 10.1469Z"
        fill="#AB47BC"
      />
    </>,
    'PhotoAnnotateIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default PhotoAnnotateIcon;
