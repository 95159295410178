/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useProfileChannel } from 'controller/useProfileChannel';
import { LeftMenuChannelActions } from './LeftMenuChannelActions';
import DateTime from 'utils/DateTime';
import { Badge, List, ListItem } from '@mui/material';
import { ListSingleChatCard } from './ListSingleChat.style';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { UserManagementHotelAdminModel } from 'models/userModel';
import { isMutedChannel } from 'services/getStreamServices';
import VolumeImg from 'public/assets/img/volume-x.svg';
import moment from 'moment-timezone';
import UserAvatar from 'components/Avatar';
import { ConstantChannelTypeString } from 'utils/globalVariable';
import useDateTime from 'utils/CustomHooks/useDateTime';

export const ListSingleChat: FC<any> = ({
  singleData,
  handleSetChannel,
  openChannelActionModal,
  tz,
  selectedChannelId,
}: any) => {
  const { getUtcToTz } = useDateTime();
  const channel: any = singleData;
  const isMute = (channel && isMutedChannel(channel)) || false;
  const { memberObj }: any = useProfileChannel(channel);
  const userList = useSelector(selectors.getUserAdmin);
  const user: UserManagementHotelAdminModel = userList?.find(
    (u) => u.id === memberObj.userId,
  ) as UserManagementHotelAdminModel;
  const { GROUP } = ConstantChannelTypeString;
  const messages = channel?.state?.messages;
  const isGroup = channel?.data?.channelType === GROUP;
  let latestMessage: any = null;
  if (messages && messages.length) {
    const index = messages.length - 1;
    latestMessage = messages[index];
  }

  const renderLastMessageDay = (ch) => {
    const lastUpdate = ch?.state?.last_message_at || ch?.data?.created_at;

    return getUtcToTz(lastUpdate).calendar(null, {
      sameDay: '[]',
      lastDay: '[Yesterday]',
      lastWeek: 'MMM DD YY',
      sameElse: 'MMM DD YY',
    });
  };

  const renderLastMessageTime = (ch) => {
    const lastUpdate = ch?.state?.last_message_at || ch?.data?.created_at;
    const isTodayOrYesterday = moment().diff(lastUpdate, 'days') <= 1;

    return `${!isTodayOrYesterday ? 'at' : ''} ${getUtcToTz(lastUpdate, 'LT')}`;
  };

  const style: any = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    minWidth: '60px',
  };

  if (!channel) return null;
  return (
    <React.Fragment key={`list_single_chat_${channel?.id}`}>
      <List sx={{ padding: 0 }}>
        <ListItem
          sx={{ minHeight: '60px' }}
          className={`click ${selectedChannelId === channel?.id && 'selected'}`}
          onClick={() => {
            handleSetChannel(channel);
          }}
        >
          <ListSingleChatCard>
            <div className="avatarContainer">
              {channel?.data?.name && !isGroup ? (
                <Badge
                  color="secondary"
                  badgeContent={parseInt(channel?.state?.unreadCount)}
                >
                  <UserAvatar
                    width={32}
                    height={32}
                    userId={channel?.data?.id}
                    firstName={channel?.data?.name}
                  />
                </Badge>
              ) : (
                <Badge
                  color="secondary"
                  badgeContent={parseInt(channel?.state?.unreadCount)}
                >
                  <UserAvatar
                    width={32}
                    height={32}
                    userId={memberObj?.userId}
                    firstName={memberObj?.name}
                    profilePic={
                      !isGroup ? memberObj?.profileImage : '@@#6868FE'
                    }
                  />
                </Badge>
              )}
            </div>
            <div className="contentBox">
              <div className="nameBox" style={{ maxWidth: 125 }}>
                <div>
                  <h5 className="elEllipses">
                    {channel?.data?.name || memberObj.name}
                    {isMute && (
                      <span className="mutedChannel">
                        <img src={VolumeImg} />
                      </span>
                    )}
                  </h5>
                </div>
                <span
                  className={`elEllipses lastMessage ${
                    parseInt(channel?.state?.unreadCount) && latestMessage?.text
                      ? 'lastMessageBold'
                      : ''
                  }`}
                >
                  {latestMessage ? latestMessage.text : ''}
                </span>
              </div>
              <div style={style} className="leftMenuChannelHeader">
                <div className="timeMessage">
                  {renderLastMessageDay(channel)}
                </div>
                <div className="timeMessage">
                  {renderLastMessageTime(channel)}
                </div>
              </div>
              <LeftMenuChannelActions
                channel={channel}
                openChannelActionModal={openChannelActionModal}
                absolutePositioning={true}
              />
            </div>
          </ListSingleChatCard>
        </ListItem>
      </List>
    </React.Fragment>
  );
};
