import TemplateSubmissionTile from 'components/TemplateSubmissionTile';
import { Stack } from '@mui/material';

interface IProps {
  submissions: any[];
}

const GridView = ({ submissions }: IProps) => {
  return (
    <Stack direction={'row'} gap="16px" flexWrap="wrap">
      {submissions.map((sub) => (
        <TemplateSubmissionTile submission={sub} />
      ))}
    </Stack>
  );
};

export default GridView;
