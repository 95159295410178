import React from 'react';
import {
  AddTaskTextCnt,
  DateCellCnt,
} from 'pages/task/TaskListView/TaskCalendar/Renderers/renderer.style';
import AddCircleIcon from '@mui/icons-material/AddCircle';
const DateCell = (event: any) => {
  const { children } = event;
  return (
    <div className={'rbc-day-bg'}>
      {children}
      {/*<DateCellCnt>*/}
      <AddTaskTextCnt className={'addTaskTextCnt'}>
        <AddCircleIcon />
        Add Task
      </AddTaskTextCnt>
      {/*</DateCellCnt>*/}
    </div>
  );
};

export default DateCell;
