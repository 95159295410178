import {
  TaskStatusChip,
  TimelineDate,
  WorkOrderHistoryCnt,
  WorkOrderHistoryTile,
  WorkOrderTitle,
  TaskCategoryChip,
} from 'pages/Locations/LocationDetail/LocationHistory/locationHistory.style';
import UserAvatar from 'components/Avatar';
import { AssigneeCnt } from 'pages/Locations/LocationDetail/LocationHistory/locationHistory.style';
import xeniaApi from 'api/index';
import useLocationDetail from 'pages/Locations/LocationDetail/useLocationDetail';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState, useMemo } from 'react';
import { setLocationTaskHistory } from 'store/actions/locationActions';
import { statusDropdownData } from 'helper/task/dropdownData';
import selectors from 'store/selectors';
import { COLORS_LIST } from 'utils/constants';
import moment from 'moment';
import { groupBy } from 'lodash';

import CustomToolTip from 'components/Tooltip';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';

import { setDialog } from 'store/actions/dialogActions';
import useResponsive from 'utils/CustomHooks/useResponsive';
import TaskAndWorkOrderTable from 'components/DataTables/TaskAndWorkOrderTable';
import { Stack, CircularProgress } from '@mui/material';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import EmptyState from './EmptyState';
import SearchAndToggleHeader from '../common/SearchAndToggleHeader';
import ChildItemIcon from 'components/Icons/childItemIcon';
import { useUserConfig } from 'controller/useUserConfig';

function LocationHistory() {
  const dispatch = useDispatch();
  const { selectedLocation } = useLocationDetail();
  const { isMobileDeviceSm } = useResponsive();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();

  const [selectedView, setSelectedView] = useState<ViewType | null>(null);
  const [searchText, setSearchText] = useState('');
  const [fetching, setFetching] = useState(false);
  const [configKey, setConfigKey] = useState<any>(null);
  const [tableConfig, setTableConfig] = useState<any>(null);

  const serviceList = useSelector(selectors.getServiceAdmin);
  const taskHistory = useSelector(selectors.locationTaskHistorySelector);
  const topAlertState = useSelector(selectors.getTopAlertState);
  const commentChannelsHash = useSelector(selectors.getTaskCommentChannelsHash);
  const servicesHash = useSelector(selectors.getServicesHash);

  const taskHistoryWithComments = useMemo(() => {
    if (taskHistory) {
      return taskHistory.map((task) => {
        const channelId = `task_comments_${task.id}`;
        const channel = commentChannelsHash[channelId];
        const commentsCount = channel?.state;
        return {
          ...task,
          commentsCount: commentsCount,
        };
      });
    }
    return null;
  }, [taskHistory, commentChannelsHash]);

  const fetchTableConfig = async () => {
    const config = await getUserConfig(`locationHistoryTableConfig:${userId}`);
    if (config) {
      const colConfig = config;
      setTableConfig(colConfig);
    }
  };

  const updateColumns = async (columnState: any[]) => {
    const previousTableConfig = tableConfig || [];
    setTableConfig(columnState);
    const updatedConfig = await editUserConfig(
      `locationHistoryTableConfig:${userId}`,
      columnState,
    );
    if (!updatedConfig) {
      setTableConfig(previousTableConfig);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchTableConfig();
    }
  }, [userId]);

  useEffect(() => {
    fetchLocationsTaskHistory();
  }, []);

  useEffect(() => {
    if (userId) {
      setConfigKey(`locationTaskHistoryViewType::${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (configKey) {
      setLocationsViewType();
    }
  }, [configKey]);

  const setLocationsViewType = async () => {
    const userConfig = await getUserConfig(configKey);
    if (userConfig?.locationTaskHistoryViewType) {
      setSelectedView(userConfig.locationTaskHistoryViewType);
    } else {
      setSelectedView('list');
    }
  };

  const setToggleStateConfig = async (value: ViewType) => {
    const prevView = selectedView;
    setSelectedView(value);
    const config = await editUserConfig(configKey, {
      locationTaskHistoryViewType: value,
    });
    if (!config) {
      setSelectedView(prevView);
    }
  };

  const fetchLocationsTaskHistory = async (showLoader = true) => {
    showLoader && setFetching(true);
    const taskHistoryList = await xeniaApi.getLocationTaskHistory(
      selectedLocation?.id,
    );
    dispatch(setLocationTaskHistory(taskHistoryList));
    showLoader && setFetching(false);
  };

  const handleSearchInput = (value: string) => {
    setSearchText(value);
  };

  const filteredData = useMemo(() => {
    return searchText
      ? taskHistoryWithComments?.filter((obj) =>
          obj?.title?.includes(searchText),
        )
      : taskHistoryWithComments;
  }, [searchText, taskHistoryWithComments]);

  const formatDate = (item) =>
    moment(item.createdAt).format('ddd, MMM DD, YYYY');

  const taskHistoryGroupedData: any = useMemo(() => {
    return filteredData && filteredData?.length > 0
      ? groupBy(filteredData, formatDate)
      : {};
  }, [searchText, taskHistory]);

  const handleTaskClick = (taskId: string) => {
    const task = taskHistory.find((t) => t.id === taskId);
    // If on mobile app then send a message to RN WebView //
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          navigateTo: 'TaskDetail',
          data: task,
        }),
      );
      return;
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: fetchLocationsTaskHistory,
          taskId: task.id,
        },
      }),
    );
  };

  const groupedDataKeys = Object.keys(taskHistoryGroupedData);

  return (
    <>
      {fetching && (
        <Stack height="calc(100vh - 223px)" alignItems={'center'} pt="100px">
          <CircularProgress />
        </Stack>
      )}
      {taskHistoryWithComments &&
        taskHistoryWithComments?.length === 0 &&
        !fetching && <EmptyState />}
      {taskHistoryWithComments &&
        taskHistoryWithComments?.length > 0 &&
        !fetching &&
        selectedView && (
          <WorkOrderHistoryCnt>
            <SearchAndToggleHeader
              selectedView={selectedView}
              setSelectedView={setToggleStateConfig}
              handleSearchInput={handleSearchInput}
            />
            <Stack
              sx={{
                height: isMobileDeviceSm
                  ? 'calc(100vh - 180px)'
                  : topAlertState?.show
                  ? 'calc(100vh - 346px)'
                  : 'calc(100vh - 281px)',
                overflow: 'auto',
              }}
            >
              {selectedView === 'list' ? (
                <TaskAndWorkOrderTable
                  onRowClick={handleTaskClick}
                  rowData={filteredData}
                  onTaskActionSuccess={() => fetchLocationsTaskHistory(false)}
                  columnState={tableConfig}
                  updateColumnStateCb={updateColumns}
                  columnConfig={{
                    asset: false,
                    recurringByEvery: false,
                    createdAt: false,
                    dueTime: false,
                    timeSpent: false,
                    startTime: false,
                    endTime: false,
                    CompletedBy: false,
                    description: false,
                  }}
                />
              ) : (
                <Stack>
                  {groupedDataKeys?.map((key) => {
                    const currentTaskHistory = taskHistoryGroupedData[key];
                    return (
                      <>
                        <TimelineDate>{key}</TimelineDate>
                        {currentTaskHistory.map((t) => {
                          const statusObj = statusDropdownData.find(
                            (s) => s.id == t.taskStatus,
                          );
                          const selectedCategory = serviceList?.find(
                            (c) => c.id == t?.ServiceTypeId,
                          );
                          const categoryColors = COLORS_LIST?.find(
                            (c) => c.color == selectedCategory?.color,
                          );
                          return (
                            <WorkOrderHistoryTile
                              key={t.id}
                              onClick={() => handleTaskClick(t.id)}
                            >
                              <div className={'tileLeft'}>
                                <WorkOrderTitle className={'workOrderTitle'}>
                                  {t.title}
                                </WorkOrderTitle>
                                <div className={'tileLeftBottomCnt'}>
                                  {t?.ServiceTypeId ? (
                                    <TaskCategoryChip
                                      color={categoryColors?.textColor}
                                      background={categoryColors?.color}
                                    >
                                      {servicesHash?.[t?.ServiceTypeId]?.type}
                                    </TaskCategoryChip>
                                  ) : null}
                                  <span className={'locationName'}>
                                    {selectedLocation?.ParentId && (
                                      <ChildItemIcon className="subLocationIcon" />
                                    )}
                                    {selectedLocation?.name}
                                  </span>
                                </div>
                              </div>
                              <div className={'tileRight'}>
                                {t?.Assigned ? (
                                  <AssigneeCnt>
                                    <label>Assignee: </label>
                                    <CustomToolTip
                                      title={`${t?.Assigned?.firstName} ${t?.Assigned?.lastName}`}
                                      placement={'top'}
                                    >
                                      <span>
                                        <UserAvatar
                                          userId={t?.Assigned?.id}
                                          width={28}
                                          height={28}
                                        />
                                      </span>
                                    </CustomToolTip>
                                  </AssigneeCnt>
                                ) : null}
                                {t?.Team ? (
                                  <AssigneeCnt>
                                    <label>Assignee: </label>
                                    <CustomToolTip
                                      title={`Team: ${t?.Team?.name}`}
                                      placement={'top'}
                                    >
                                      <span>
                                        <TeamAvatar
                                          width={28}
                                          height={28}
                                          teamId={t?.Team?.id}
                                        />
                                      </span>
                                    </CustomToolTip>
                                  </AssigneeCnt>
                                ) : null}
                                <TaskStatusChip
                                  color={statusObj?.color}
                                  background={statusObj?.backgroundColor}
                                >
                                  {t.taskStatus}
                                </TaskStatusChip>
                              </div>
                            </WorkOrderHistoryTile>
                          );
                        })}
                      </>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </WorkOrderHistoryCnt>
        )}
    </>
  );
}

export default LocationHistory;
