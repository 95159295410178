const checklistV2ActionType = {
  GET_CHECKLIST_LIST: 'GET_CHECKLIST_LIST',
  SET_CHECKLIST_LIST: 'SET_CHECKLIST_LIST',
  GET_CHECKLIST_COMPLETE_LIST: 'GET_CHECKLIST_COMPLETE_LIST',
  SET_CHECKLIST_COMPLETE_LIST: 'SET_CHECKLIST_COMPLETE_LIST',
  CREATE_CHECKLIST: 'CREATE_CHECKLIST',
  UPDATE_CHECKLIST: 'UPDATE_CHECKLIST',
  SET_CHECKLIST: 'SET_CHECKLIST',
  GET_CHECKLIST: 'GET_CHECKLIST',
  DUPLICATE_CHECKLIST: 'DUPLICATE_CHECKLIST',
  ARCHIVE_CHECKLIST: 'ARCHIVE_CHECKLIST',
  DELETE_CHECKLIST: 'DELETE_CHECKLIST',
  UPLOAD_CHECKLIST_ATTACHMENTS: 'UPLOAD_CHECKLIST_ATTACHMENTS',
  SET_CHECKLIST_ATTACHMENTS: 'SET_CHECKLIST_ATTACHMENTS',
  SET_CHECKLIST_ITEM: 'SET_CHECKLIST_ITEM',
  GET_CHECKLIST_ITEMS: 'GET_CHECKLIST_ITEMS',
  SET_CHECKLIST_ITEMS: 'SET_CHECKLIST_ITEMS',
  MODIFY_CHECKLIST_ITEM: 'MODIFY_CHECKLIST_ITEM',
  DELETE_CHECKLIST_ITEM: 'DELETE_CHECKLIST_ITEM',
  INITIALIZE_CHECKLIST: 'INITIALIZE_CHECKLIST',
  SPLICE_CHECKLIST_ITEMS: 'SPLICE_CHECKLIST_ITEMS',
  UPLOAD_CHECKLIST_ITEM_ATTACHMENTS: 'UPLOAD_CHECKLIST_ITEM_ATTACHMENTS',
  SET_CHECKLIST_ITEM_ATTACHMENTS: 'SET_CHECKLIST_ITEM_ATTACHMENTS',
  DELETE_CHECKLIST_ITEM_ATTACHMENT: 'DELETE_CHECKLIST_ITEM_ATTACHMENT',
  GET_PUBLIC_CHECKLISTS: 'GET_PUBLIC_CHECKLISTS',
  SET_PUBLIC_CHECKLISTS: 'SET_PUBLIC_CHECKLISTS',
  PUBLISH_CHECKLIST: 'PUBLISH_CHECKLIST',
  GET_CHECKLIST_CATEGORIES: 'GET_CHECKLIST_CATEGORIES',
  GET_CHECKLIST_INDUSTRIES: 'GET_CHECKLIST_INDUSTRIES',
  SET_CHECKLIST_CATEGORIES: 'SET_CHECKLIST_CATEGORIES',
  SET_CHECKLIST_INDUSTRIES: 'SET_CHECKLIST_INDUSTRIES',
  GET_CHECKLIST_TYPES: 'GET_CHECKLIST_TYPES',
  SET_CHECKLIST_TYPES: 'SET_CHECKLIST_TYPES',
  GET_CHECKLIST_LOGS: 'GET_CHECKLIST_LOGS',
  GET_CHECKLIST_LOG: 'GET_CHECKLIST_LOG',
  SUBMIT_CHECKLIST_LOG: 'SUBMIT_CHECKLIST_LOG',
  SET_CHECKLIST_LOGS: 'SET_CHECKLIST_LOGS',
  CREATE_CHECKLIST_LOG: 'CREATE_CHECKLIST_LOG',
  SET_CHECKLIST_LOG: 'SET_CHECKLIST_LOG',
  CHECKLIST_LOG_SUBMITTED: 'CHECKLIST_LOG_SUBMITTED',
  SET_PREVIEW_CHECKLIST_DATA: 'SET_PREVIEW_CHECKLIST_DATA',
  SET_PREVIEW_CHECKLIST_MODAL_CONFIG: 'SET_PREVIEW_CHECKLIST_MODAL_CONFIG',
  CLOSE_PREVIEW_CHECKLIST_MODAL: 'CLOSE_PREVIEW_CHECKLIST_MODAL',
  SET_CHECKLIST_VIEW_TYPE: 'SET_CHECKLIST_VIEW_TYPE',
  SET_CHECKLIST_FILTERS: 'SET_CHECKLIST_FILTERS',
  FETCH_CHECKLIST_ITEMS_AUTOMATIONS: 'FETCH_CHECKLIST_ITEMS_AUTOMATIONS',
  SET_CHECKLIST_ITEMS_AUTOMATIONS: 'SET_CHECKLIST_ITEMS_AUTOMATIONS',
};
export default checklistV2ActionType;
