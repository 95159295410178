import { Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const MainCnt = styled(Stack)({
  margin: '5px 0px 5px 0px',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .assignUsersBtn': {
    height: 36,
    borderRadius: 6,
  },
});

export const SearchAssignedUsers = styled('div')(({ theme }) => ({
  width: 310,
  marginRight: 24,
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginRight: 0,
    maxWidth: '295px',
  },
  '& input': {
    padding: '8.5px 14px 8.5px 7px',
    '&::-webkit-input-placeholder': {
      fontSize: 14,
    },
  },
  '& svg': {
    color: '#0000008A',
  },

  '& legend': {
    width: 0,
  },
}));
