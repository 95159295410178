import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import TimeDuration from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeDuration';
import IosSwitch from 'components/IosSwitch';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import CrossIcon from 'components/Icons/crossIcon';
import { useUserConfig } from 'controller/useUserConfig';

import { closeDialog, setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { KIOSK_DEFAULT_CONFIG, WSCONFIG } from 'utils/globalVariable';

import { REDIRECT_TO } from './constants';
import debounce from 'lodash/debounce';
import KioskModeIcon from '../../Icons/kioskModeIcon';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import {
  BoxContainer,
  DeleteText,
  IntroKioskModeCnt,
  MainContainer,
  PinCodeBanner,
  RedirectToCnt,
  RedirectToItem,
  SelectedRedirectToItem,
  TimeDurationCnt,
} from './style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import FeatureLockIcon from 'components/Icons/featureLockIcon';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { TALK_TO_SALES_URL } from 'utils/constants';

// Kiosk Mode Config
const KioskModeConfigComponent = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasFeature, hasPermission, FEATURES } = useCustomPermission();
  const dispatch = useDispatch();
  const [duration, setDuration] = useState(null);

  const { config: globalConfig, updateConfig } = useWorkspaceConfig();
  const { kioskMode: kioskConfig } = globalConfig || {};

  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const [generalConfigKey, setGeneralConfigKey] = useState<any>(null);
  const [hideMainBanner, setHideMainBanner] = useState<any>(true);
  const [hidePinCodeInfoBanner, setHidePinCodeInfoBanner] = useState<any>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [config, setConfig] = useState<any>();

  const isFeatureEnabled = useMemo(() => {
    return hasFeature(FEATURES.KIOSK_MODE);
  }, []);
  useEffect(() => {
    setConfig(kioskConfig ?? KIOSK_DEFAULT_CONFIG);
  }, [kioskConfig]);

  useEffect(() => {
    if (userId) {
      setGeneralConfigKey(`kioskConfig::${userId}`);
    }
  }, [userId]);

  const getGeneralConfig = async () => {
    const userConfig = await getUserConfig(generalConfigKey);
    setHideMainBanner(userConfig?.hideKioskModeWelcomeBanner);
    setHidePinCodeInfoBanner(userConfig?.hideKioskModePinCodeBanner);
  };

  useEffect(() => {
    if (generalConfigKey) {
      getGeneralConfig();
    }
  }, [generalConfigKey]);

  const DeleteConfirmationTemplate = () => {
    return (
      <>
        <DeleteText style={{ marginTop: -10 }}>
          Disabling Kiosk Mode will deactivate it on all your devices where it
          is currently set up.
        </DeleteText>

        <DeleteText style={{ marginBottom: 10 }}>
          However, the PIN codes of the users will not be deleted or removed.
        </DeleteText>

        <DeleteText>Are you sure you want to disable Kiosk Mode?</DeleteText>
      </>
    );
  };

  // KIOSK Configurations Handlers
  const enableKioskMode = () => {
    if (!kioskConfig) {
      // If First Time Enable
      dispatch(
        setDialog({
          dialogId: DIALOGS.KIOSK_MODE_CONFIGURATIONS,
          open: true,
        }),
      );
      return;
    }

    if (kioskConfig?.isEnable) {
      dispatch(
        setDialog({
          dialogId: 'confirmationDialog',
          open: true,
          data: {
            title: 'Disable Kiosk Mode',
            ContentTemplate: DeleteConfirmationTemplate,
            onConfirmCallback: () => {
              setConfig({
                ...config,
                isEnable: !kioskConfig?.isEnable,
              });
              updateConfig(WSCONFIG.KIOSK_MODE_CONFIG, {
                ...kioskConfig,
                isEnable: !kioskConfig?.isEnable,
              });
            },
            confirmButtonProps: { color: 'error' },
            confirmationText: 'Yes, Disable Kiosk Mode',
          },
        }),
      );
      return;
    }

    setConfig({
      ...config,
      isEnable: !kioskConfig?.isEnable,
    });

    updateConfig(WSCONFIG.KIOSK_MODE_CONFIG, {
      ...kioskConfig,
      isEnable: !kioskConfig?.isEnable,
    });
  };

  const updateRedirection = (redirectTo) => {
    updateConfig(WSCONFIG.KIOSK_MODE_CONFIG, {
      ...kioskConfig,
      redirectTo,
    });
  };

  const updateRequireSelfie = () => {
    updateConfig(WSCONFIG.KIOSK_MODE_CONFIG, {
      ...kioskConfig,
      takeSelfie: !kioskConfig?.takeSelfie,
    });
  };

  const updateKioskLogout = () => {
    updateConfig(WSCONFIG.KIOSK_MODE_CONFIG, {
      ...kioskConfig,
      logoutInterval: {
        ...kioskConfig?.logoutInterval,
        enable: !kioskConfig?.logoutInterval?.enable,
      },
    });
  };

  const handleDuration = (_, duration) => {
    if (duration?.length > 4) return;
    setDuration(duration);
    debouncedUpdateLogOutInterval(duration);
  };

  const debouncedUpdateLogOutInterval = useCallback(
    debounce((duration) => {
      if (Number(duration) < 1) {
        return;
      }

      updateConfig(WSCONFIG.KIOSK_MODE_CONFIG, {
        ...kioskConfig,
        logoutInterval: {
          ...kioskConfig?.logoutInterval,
          duration,
        },
      });
    }, 2000),
    [kioskConfig],
  );

  // Close Welcome Banner when clicked cross icon
  const closeWelcomeBanner = async () => {
    const config = await editUserConfig(generalConfigKey, {
      hideKioskModeWelcomeBanner: true,
      ...(hidePinCodeInfoBanner && {
        hideKioskModePinCodeBanner: true,
      }),
    });

    if (config) {
      setHideMainBanner(true);
    }
  };

  const closePinCodeBanner = async () => {
    const config = await editUserConfig(generalConfigKey, {
      ...(hideMainBanner && {
        hideKioskModeWelcomeBanner: true,
      }),
      hideKioskModePinCodeBanner: true,
    });

    if (config) {
      setHidePinCodeInfoBanner(true);
    }
  };

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const selectedRedirectItem = useMemo(() => {
    return REDIRECT_TO?.find((item) => item?.id === config?.redirectTo);
  }, [config?.redirectTo]);

  const SelectedIconComponent = useMemo(() => {
    return selectedRedirectItem?.icon;
  }, [selectedRedirectItem]);

  useEffect(() => {
    setDuration(config?.logoutInterval?.duration);
  }, [config?.logoutInterval?.duration]);

  const redirectToUsersAndTeams = () => {
    navigateWithWorkspaceUrl('/users-and-teams?tab=users');
  };
  const upSellModalForFeature = () => {
    const dialogId = DIALOGS.UPSELL_MODAL;
    dispatch(
      setDialog({
        dialogId,
        open: true,
        data: {
          isEscapable: true,
          icon: <FeatureLockIcon />,
          title: 'You’ve discovered a Paid Feature!',
          description: (
            <>
              <b>{FEATURES.KIOSK_MODE}</b> isn’t available on your current plan.
              Upgrade Now to access this feature.
            </>
          ),
          primaryButtonText: 'Talk to sales',
          primaryButtonClickCB: () => {
            dispatch(closeDialog(dialogId));
            dispatch(
              setCalendlyInfo({
                url: TALK_TO_SALES_URL,
                open: true,
              }),
            );
          },
          buttonDisplayDirectionCol: true,
        },
      }),
    );
  };

  return (
    <MainContainer>
      {!hideMainBanner && (
        <IntroKioskModeCnt>
          <div className="crossCnt" onClick={closeWelcomeBanner}>
            <CrossIcon className="icon" />
          </div>
          <KioskModeIcon
            style={{ fill: 'none', color: '#01579B', width: 40, height: 35 }}
          />
          <Typography className="title">Introducing Kiosk Mode</Typography>
          <Typography className="statement">
            Kiosk Mode transforms your mobile device into a secure, dedicated
            station for accessing our app. Once enabled, users must enter a
            unique PIN code to access the app, ensuring privacy and security on
            shared devices.
          </Typography>
        </IntroKioskModeCnt>
      )}

      <BoxContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(!isFeatureEnabled && {
            cursor: 'pointer',
          }),
        }}
        onClick={() => {
          if (!hasFeature(FEATURES.KIOSK_MODE)) {
            upSellModalForFeature();
          }
        }}
      >
        <Stack gap={'4px'} width="100%">
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              style={{
                fontWeight: 700,
                fontSize: 14,
                lineHeight: '19.12px',
                color: '#212121',
                marginBottom: 5,
              }}
            >
              Enable Kiosk Mode
            </Typography>
            {isFeatureEnabled && (
              <IosSwitch
                height={20}
                width={32}
                checked={config?.isEnable || false}
                onChange={enableKioskMode}
                thumbSize={16}
              />
            )}
          </Stack>

          <Typography
            style={{
              fontWeight: 400,
              fontSize: 13,
              lineHeight: '17.76px',
              color: '#212121',
            }}
          >
            When enabled, owners/administrators can configure Kiosk Mode on a
            mobile device by accessing the Xenia mobile app.
          </Typography>
        </Stack>
        {!isFeatureEnabled && (
          <FeatureLockIcon style={{ height: 36, width: 36 }} />
        )}
      </BoxContainer>
      {isFeatureEnabled && (
        <>
          {kioskConfig?.isEnable && !hidePinCodeInfoBanner && (
            <PinCodeBanner
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Stack alignItems="center" flexDirection="row" gap="7px">
                <InfoIcon className="infoIcon" />
                <Typography className="content">
                  Admins can set user's PIN code manually by going to{' '}
                  <span className="pinLink" onClick={redirectToUsersAndTeams}>
                    Users & Teams
                  </span>
                  .
                </Typography>
              </Stack>

              <CrossIcon className="crossIcon" onClick={closePinCodeBanner} />
            </PinCodeBanner>
          )}

          {kioskConfig?.isEnable && (
            <>
              <BoxContainer>
                <Stack flexDirection="row" alignItems="center" gap="10px">
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '19.12px',
                      color: '#000000',
                    }}
                  >
                    Redirect Kiosk users to
                  </Typography>
                  <CustomDropdown
                    onClickawayCallback={() => setAnchorEl(null)}
                    buttonRenderer={
                      <div onClick={handleDropDownOpen}>
                        <SelectedRedirectToItem
                          flexDirection="row"
                          alignItems="center"
                          gap="8px"
                        >
                          {SelectedIconComponent && <SelectedIconComponent />}
                          <Typography className="title">
                            {selectedRedirectItem?.title}
                          </Typography>
                          <ArrowDropDownIcon />
                        </SelectedRedirectToItem>
                      </div>
                    }
                    popperProps={{
                      open: Boolean(anchorEl),
                      anchorEl: anchorEl,
                      sx: {
                        width: 90,
                      },
                      placement: 'bottom-start',
                      content: (
                        <RedirectToCnt flexDirection="column" gap="15px">
                          {REDIRECT_TO?.map((item) => {
                            const IconComponent = item.icon;
                            return (
                              <RedirectToItem
                                flexDirection="row"
                                alignItems="center"
                                gap="10px"
                                onClick={() => updateRedirection(item?.id)}
                              >
                                <IconComponent />
                                <Typography className="title">
                                  {item?.title}
                                </Typography>
                              </RedirectToItem>
                            );
                          })}
                        </RedirectToCnt>
                      ),
                    }}
                  />
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '19.12px',
                      color: '#000000',
                    }}
                  >
                    module after they logged in to the app
                  </Typography>
                </Stack>
              </BoxContainer>

              <BoxContainer>
                <Stack
                  gap="5px"
                  flexDirection="row"
                  alignItems="center"
                  style={{ marginBottom: 20 }}
                >
                  <IosSwitch
                    height={20}
                    width={32}
                    checked={config?.takeSelfie}
                    onChange={updateRequireSelfie}
                    thumbSize={16}
                  />
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '19.12px',
                      color: '#000000',
                    }}
                  >
                    Require Kiosk users to take selfie when logging in to the
                    app
                  </Typography>
                </Stack>

                <Stack gap="5px" flexDirection="row" alignItems="center">
                  <IosSwitch
                    height={20}
                    width={32}
                    checked={config?.logoutInterval?.enable}
                    onChange={updateKioskLogout}
                    thumbSize={16}
                  />
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '19.12px',
                      color: '#000000',
                    }}
                  >
                    Log out Kiosk users if they are inactive for more than
                  </Typography>

                  <TimeDurationCnt>
                    <TimeDuration
                      value={duration}
                      handleUpdateSchedule={handleDuration}
                      triggerHandlerOnValue={false}
                      fieldProps={{
                        placeholder: 'E.g. 15',
                      }}
                    />
                  </TimeDurationCnt>

                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '19.12px',
                      color: '#000000',
                    }}
                  >
                    minutes
                  </Typography>
                </Stack>
              </BoxContainer>
            </>
          )}
        </>
      )}
    </MainContainer>
  );
};

export default KioskModeConfigComponent;
