import { createSvgIcon } from '@mui/material';

const RequestsIcon = (props) => {
  const { primary = 'rgba(0,0,0,0.54)', secondary = 'rgb(204, 203, 255)' } =
    props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M13.7807 3.66663H8.21939C7.23226 3.66663 6.73869 3.66663 6.30294 3.81693C5.9176 3.94984 5.56662 4.16676 5.27541 4.45198C4.9461 4.77451 4.72537 5.21597 4.28391 6.09889L1.83337 11H5.39184C6.01995 11 6.59414 11.3548 6.87504 11.9166C7.15594 12.4784 7.73013 12.8333 8.35824 12.8333H13.6418C14.2699 12.8333 14.8441 12.4784 15.125 11.9166C15.4059 11.3548 15.9801 11 16.6082 11H20.1667L17.7162 6.09889C17.2747 5.21598 17.054 4.77451 16.7247 4.45198C16.4335 4.16676 16.0825 3.94984 15.6971 3.81693C15.2614 3.66663 14.7678 3.66663 13.7807 3.66663Z"
        fill={primary}
      />
      <path
        d="M13.7807 3.66663H8.21939C7.23226 3.66663 6.73869 3.66663 6.30294 3.81693C5.9176 3.94984 5.56662 4.16676 5.27541 4.45198C4.9461 4.77451 4.72537 5.21597 4.28391 6.09889L2.29789 10.0709C2.12465 10.4174 2.03803 10.5906 1.97694 10.7722C1.92269 10.9335 1.88352 11.0994 1.85993 11.2679C1.83337 11.4576 1.83337 11.6513 1.83337 12.0387V13.9333C1.83337 15.4734 1.83337 16.2435 2.13311 16.8318C2.39676 17.3492 2.81745 17.7699 3.3349 18.0336C3.92316 18.3333 4.69323 18.3333 6.23337 18.3333H15.7667C17.3069 18.3333 18.0769 18.3333 18.6652 18.0336C19.1826 17.7699 19.6033 17.3492 19.867 16.8318C20.1667 16.2435 20.1667 15.4734 20.1667 13.9333V12.0387C20.1667 11.6513 20.1667 11.4576 20.1401 11.2679C20.1166 11.0994 20.0774 10.9335 20.0231 10.7722C19.9621 10.5906 19.8754 10.4174 19.7022 10.0709L17.7162 6.09889C17.2747 5.21598 17.054 4.77451 16.7247 4.45198C16.4335 4.16676 16.0825 3.94984 15.6971 3.81693C15.2614 3.66663 14.7678 3.66663 13.7807 3.66663Z"
        fill={secondary}
        fillOpacity="0.5"
      />
      <path
        d="M2.29171 11H5.39184C6.01995 11 6.59414 11.3548 6.87504 11.9166C7.15594 12.4784 7.73013 12.8333 8.35824 12.8333H13.6418C14.2699 12.8333 14.8441 12.4784 15.125 11.9166C15.4059 11.3548 15.9801 11 16.6082 11H19.7084M8.21939 3.66663H13.7807C14.7678 3.66663 15.2614 3.66663 15.6971 3.81693C16.0825 3.94984 16.4335 4.16676 16.7247 4.45198C17.054 4.77451 17.2747 5.21598 17.7162 6.09889L19.7022 10.0709C19.8754 10.4174 19.9621 10.5906 20.0231 10.7722C20.0774 10.9335 20.1166 11.0994 20.1401 11.2679C20.1667 11.4576 20.1667 11.6513 20.1667 12.0387V13.9333C20.1667 15.4734 20.1667 16.2435 19.867 16.8318C19.6033 17.3492 19.1826 17.7699 18.6652 18.0336C18.0769 18.3333 17.3069 18.3333 15.7667 18.3333H6.23337C4.69323 18.3333 3.92316 18.3333 3.3349 18.0336C2.81745 17.7699 2.39676 17.3492 2.13311 16.8318C1.83337 16.2435 1.83337 15.4734 1.83337 13.9333V12.0387C1.83337 11.6513 1.83337 11.4576 1.85993 11.2679C1.88352 11.0994 1.92269 10.9335 1.97694 10.7722C2.03803 10.5906 2.12465 10.4174 2.29789 10.0709L4.28391 6.09889C4.72537 5.21597 4.9461 4.77451 5.27541 4.45198C5.56662 4.16676 5.9176 3.94984 6.30294 3.81693C6.73869 3.66663 7.23226 3.66663 8.21939 3.66663Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>,
    'RequestsIcon',
  );
  return <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />;
};
export default RequestsIcon;
