import DeleteIcon3 from 'components/Icons/deleteIcon3';
import React from 'react';
import DuplicateIcon from 'components/Icons/duplicateIcon';

export const options = [
  // {
  //   label: 'Duplicate',
  //   id: 'duplicate',
  //   iconAlign: 'start',
  //   icon: <DuplicateIcon />,
  //   style: {
  //     padding: '6px 16px',
  //   },
  // },
  {
    label: 'Delete',
    id: 'delete',
    iconAlign: 'start',
    icon: (
      <DeleteIcon3
        sx={{ fill: 'none', fontSize: 20 }}
        style={{ color: 'rgba(211, 47, 47, 1)' }}
      />
    ),
    style: {
      padding: '6px 16px',
      color: 'rgba(211, 47, 47, 1)',
    },
  },
];
