import RequestEmptyStateImg from 'public/assets/img/requestsEmptyStateImage.svg';
import { RequestsEmptyStateCnt } from 'pages/Requests/requests.style';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { RequestsContext } from 'pages/Requests/context/context';
import useRequests from 'pages/Requests/useRequests';
import { HasPermission } from 'components/HasPermission';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

function RequestsEmptyState() {
  const dispatch = useDispatch();
  const { updateRequestsState, requestsState } = useContext(RequestsContext);
  const { navigateToRequest } = useRequests();
  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const handleOpenCreateRequest = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_REQUEST,
        data: {
          onSuccessCallBack: (newRequest) => {
            updateRequestsState({
              requests: [newRequest, ...requestsState.requests],
            });
            navigateToRequest(newRequest.id);
          },
        },
      }),
    );
  };
  return (
    <RequestsEmptyStateCnt>
      <img
        src={RequestEmptyStateImg}
        alt="Empty State"
        width={164}
        height={164}
      />
      <h3>No Requests yet</h3>
      {hasPermission(PERMISSIONS.CAN_REQUEST_TASK) ? (
        <p>
          All requests will appear here. Click the ‘+’ button
          <br /> to create a new request.
        </p>
      ) : (
        <p>All requests will appear here.</p>
      )}
      <HasPermission permissions={[PERMISSIONS.CAN_REQUEST_TASK]}>
        <IconButton className={'addIconBtn'} onClick={handleOpenCreateRequest}>
          <AddIcon />
        </IconButton>
      </HasPermission>
    </RequestsEmptyStateCnt>
  );
}

export default RequestsEmptyState;
