import { FC, useState, useEffect } from 'react';
import { useUserConfig } from 'controller/useUserConfig';
import SavedTaskTemplates from 'components/DataTables/SavedTaskTemplates';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';

interface IProps {
  templates: Array<any>;
  actions: any;
}

export const TaskTemplatesList: FC<IProps> = (props) => {
  const { templates = [], actions } = props;
  const [loading, setLoading] = useState(true);
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const [configKey, setConfigKey] = useState<any>(null);
  const [tableConfig, setTableConfig] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      setConfigKey(`savedTaskTemplatesTableConfig:${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (configKey) {
      fetchTableConfig();
    }
  }, [configKey]);

  const fetchTableConfig = async () => {
    const config = await getUserConfig(configKey);

    if (config) {
      setTableConfig(config.savedTaskTemplatesTableConfig);
    }
    setLoading(false);
  };

  const updateColumns = async (columnState) => {
    if (!loading) {
      const previousTableConfig = tableConfig || [];
      setTableConfig(columnState);
      const updatedConfig = await editUserConfig(configKey, {
        savedTaskTemplatesTableConfig: columnState,
      });
      if (!updatedConfig) {
        setTableConfig(previousTableConfig);
      }
    }
  };

  const showTemplateDetails = (templateId) => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.TASK_TEMPLATE_DETAILS,
        open: true,
        data: {
          templateId,
          actions,
        },
      }),
    );
  };

  return (
    <SavedTaskTemplates
      rowData={templates}
      onRowClick={showTemplateDetails}
      updateColumnStateCb={updateColumns}
      columnState={tableConfig}
      isSizeColumnFit={!tableConfig}
      actions={actions}
      height={'calc(100vh - 120px)'}
    />
  );
};
