// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

export const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const FirstRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid #E0E0E0',
  position: 'relative',
  justifyContent: 'center',
  padding: '12px 24px',
}));

export const GoBackWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  left: 24,
  gap: 4,
  cursor: 'pointer',
  '& svg': {
    fontSize: 20,
  },
}));

export const BackText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '700',
  letterSpacing: '0.15px',
  lineHeight: '24px',
  color: 'rgba(66, 66, 66, 1)',
}));

export const ToggleBarCnt = styled('div')({
  display: 'flex',
  border: '0px solid #E0E0E0',
  justifyContent: 'center',
  '& svg': {
    color: 'rgba(117, 117, 117, 1)',
  },
  '& button': {
    height: 40,
    fontWeight: 700,
    fontSize: 14,
    color: 'rgba(66, 66, 66, 1)',
  },
  '& .selected': {
    backgroundColor: '#EBEAFF',
    border: '1px solid #6868FE !important',
    color: '#6868FE',
    '& svg': {
      color: '#6868FE',
    },
  },
});

export const SecondRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid #E0E0E0',
  background: '#F5F6FB',
  position: 'relative',
  justifyContent: 'center',
  padding: '8px 24px',
}));

export const TemplateNameText = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: '700',
  letterSpacing: '0.15px',
  lineHeight: '38.5px',
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: '600px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
}));

export const LastModifiedTextWrapper = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  left: 24,
  top: '50%',
  transform: 'translate(0px, -50%)',

  fontSize: '12px',
  fontWeight: '400',
  letterSpacing: '0.4px',
  lineHeight: '19.92px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const SecondRowButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  gap: 16,
  right: 24,
}));

export const StyledButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'bg',
})(({ bg }) => ({
  borderRadius: 6,
  padding: '6px 12px',

  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.4px',
  lineHeight: '24px',

  '& .MuiButton-startIcon': {
    marginRight: 2,
  },
  ...(bg && { background: bg }),
}));

export const FirstRowButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  gap: 12,
  right: 24,
}));

export const ShareButton = styled(CustomButton)(({ bg }) => ({
  padding: '3px 14px',
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.4px',
  borderRadius: '6px',
  lineHeight: '24px',
  '& svg': {
    fontSize: 14,
  },
}));
