import React, { useEffect, useState } from 'react';
import AddAssigneeIcon from 'components/Icons/addAssigneeIcon';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';

import { AssigneeIconBtn } from './style';
import useUsers from 'utils/CustomHooks/useUsers';
import { PERMISSIONS } from 'utils/constants';

function CreateEditTaskAssigneeDropdown(props) {
  const { getBacklistUsersByPermission } = useUsers();
  const { config } = useWorkspaceConfig();
  const { handleChangeCallback, assignees, task, autoTagLocation, hideAction } =
    props;

  const { updateAssignees, addNewAssignees, splitAssigneesForTasks } =
    useAssigneesUtils();

  const [taskAssignees, setTaskAssignees] = useState([]);

  const [dropdownConfig, setDropdownConfig] = useState({
    people: true,
    teams: true,
    locations: false,
  });

  useEffect(() => {
    setDropdownConfig({
      ...dropdownConfig,
      locations: config?.advancedLocationBasedAssignment ?? false,
    });
  }, [config]);

  useEffect(() => {
    setTaskAssignees(task?.TaskAssignees ?? []);

    const splitedData = splitAssigneesForTasks(
      task?.TaskAssignees ?? [],
      dropdownConfig,
    );

    setSelected(splitedData);
  }, [task?.TaskAssignees, dropdownConfig]);

  const [selected, setSelected] = useState({
    ...(dropdownConfig?.people && { people: [] }),
    ...(dropdownConfig?.teams && { teams: [] }),
    ...(dropdownConfig?.locations && { locations: [] }),
  });

  const handleSelect = (data: any) => {
    const mergedArray = [
      ...(data?.selectedList?.people ?? []),
      ...(data?.selectedList?.teams ?? []),
      ...(data?.selectedList?.locations ?? []),
    ];

    const updatedAssignees = addNewAssignees(mergedArray);

    setTaskAssignees(updatedAssignees);

    setSelected(data?.selectedList);
    handleChangeCallback?.(mergedArray);
  };

  // Remove selected
  const handleClearOptions = (event: React.MouseEvent<HTMLElement>, id) => {
    event.stopPropagation();

    const filteredAssigneeIds = assignees?.filter((item) => item != id);

    const updatedAssignees = updateAssignees(taskAssignees, id);
    setTaskAssignees(updatedAssignees);

    const tempSelected = {
      ...(dropdownConfig?.people && {
        people: selected?.people?.filter((item) => item != id),
      }),
      ...(dropdownConfig?.teams && {
        teams: selected?.teams?.filter((item) => item != id),
      }),
      ...(dropdownConfig?.locations && {
        locations: selected?.locations?.filter((item) => item != id),
      }),
    };

    setSelected(tempSelected);

    handleChangeCallback?.(filteredAssigneeIds, id, false);
  };

  return (
    <>
      <AssigneeDropdown
        buttonRenderer={
          hideAction ? (
            <></>
          ) : (
            <AssigneeIconBtn>
              <AddAssigneeIcon />
            </AssigneeIconBtn>
          )
        }
        handleChangeCallback={handleSelect}
        showTabs={true}
        listProps={{
          all: {
            showInlineSelection: false,
            isSearchable: true,
            isMulti: true,
            hideActions: false,
            placeholder: 'Type to Search',
            showMeOnTop: true,
            partition: true,
            selected: [
              ...(selected?.people ?? []),
              ...(selected?.teams ?? []),
              ...(selected?.locations ?? []),
            ],
          },
          people: {
            showMeOnTop: true,
            showInlineSelection: false,
            isSearchable: true,
            isMulti: true,
            hideActions: false,
            partition: true,
            selected: selected?.people ?? [],
          },
          teams: {
            showInlineSelection: false,
            isSearchable: true,
            isMulti: true,
            hideActions: false,
            selected: selected?.teams ?? [],
          },
          ...(dropdownConfig?.locations && {
            locations: {
              autoTagLocation: autoTagLocation,
              showInlineSelection: false,
              isSearchable: true,
              isMulti: true,
              hideActions: false,
              selected: selected?.locations ?? [],
              addLocationByTag: true,
              selectOnly: true,
            },
          }),
        }}
        displayOptions={{
          all: true,
          ...dropdownConfig,
        }}
        blacklistedIds={getBacklistUsersByPermission(
          PERMISSIONS.CAN_CHANGE_TASK_STATUS,
          true,
        )}
        popperProps={{ disablePortal: false }}
        allSelected={false}
      />

      {taskAssignees?.length > 2 && (
        <AssigneesView type="avatars" data={taskAssignees} />
      )}

      {taskAssignees?.length <= 2 && (
        <AssigneesView
          type="chips"
          assigneesProps={{
            removeHandler: handleClearOptions,
            clearOption: !hideAction,
          }}
          data={taskAssignees}
        />
      )}
    </>
  );
}

export default CreateEditTaskAssigneeDropdown;
