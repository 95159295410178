import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import { groupBy, isEqual, omit } from 'lodash';
import { useWindowResize } from 'controller/useWindowResize';
import InfiniteVirtualList from 'components/InfiniteVirtualList/InfiniteVirtualList';
import useDateTime from 'utils/CustomHooks/useDateTime';
import ActivityLogItem from '../logItem';
import { Container, Date, ListingCnt } from './style';

import 'react-vertical-timeline-component/style.min.css';
interface IProps {
  isNextPageLoading?: boolean;
  logs?: any;
  callbackActions?: any;
  handleLoadNextPage(data: any): void;
  totalCount?: any;
}

function InifiniteActivityLogs(props: IProps) {
  const { isNextPageLoading, handleLoadNextPage, logs, totalCount } = props;

  const parentRef = useRef<HTMLDivElement | null>(null);
  const size = useWindowResize();
  const [parentHeight, setParentHeight] = useState<any>(null);

  useEffect(() => {
    if (parentRef?.current?.clientHeight) {
      setParentHeight(parentRef.current.clientHeight);
    }
  }, [parentRef?.current?.clientHeight, size]);

  const { getUtcToTz } = useDateTime();

  const formatDate = (item) =>
    getUtcToTz(item?.createdAt, 'dddd, MMM DD, YYYY');

  const logsGroupedData: any = useMemo(() => {
    return logs && logs?.length > 0 ? groupBy(logs, formatDate) : {};
  }, [logs]);

  const groupedDataKeys = Object.keys(logsGroupedData);

  const logsWithDatesRows = useMemo(() => {
    const groupedRows = groupedDataKeys?.map((key) => {
      const date = <Date>{key}</Date>;
      const currentLogs = logsGroupedData[key].map((log) => (
        <ActivityLogItem timelineElement={log} />
      ));
      return [date, ...currentLogs];
    });
    const finalRows: any[] = [];
    groupedRows.forEach((row) => finalRows.push(...row));
    return finalRows.length ? finalRows : [];
  }, [logs]);

  const finalRows: any[] = useMemo(() => {
    let rows: any = [];

    rows = [...rows, ...logsWithDatesRows];
    return rows;
  }, [logsWithDatesRows]);

  return (
    <ListingCnt
      ref={parentRef}
      style={{
        height: 'calc(100vh - 200px)',
        overflowY: 'hidden',
      }}
    >
      <Container>
        <VerticalTimeline layout="1-column" animate={false}>
          <InfiniteVirtualList
            handleLoadNextPage={handleLoadNextPage}
            rows={finalRows}
            height={parentHeight ?? 500}
            totalCount={totalCount}
            isNextPageLoading={isNextPageLoading}
            overrideRowCount={logs?.length}
          />
        </VerticalTimeline>
      </Container>
    </ListingCnt>
  );
}

const arePropsEqual = (prevProps, nextProps) => {
  return isEqual(
    omit(prevProps, ['handleLoadNextPage']),
    omit(nextProps, ['handleLoadNextPage']),
  );
};

export default memo(InifiniteActivityLogs, arePropsEqual);
