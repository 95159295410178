import { createSvgIcon } from '@mui/material';

const TableAlternateIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g opacity="0.12">
        <path
          d="M32 8H20.8C16.3196 8 14.0794 8 12.3681 8.87195C10.8628 9.63893 9.63893 10.8628 8.87195 12.3681C8 14.0794 8 16.3196 8 20.8V24H32V8Z"
          fill="#6868FE"
        />
        <path d="M56 24H32V40H56V24Z" fill="#6868FE" />
        <path
          d="M32 40H8V43.2C8 47.6804 8 49.9206 8.87195 51.6319C9.63893 53.1372 10.8628 54.3611 12.3681 55.1281C14.0794 56 16.3196 56 20.8 56H32V40Z"
          fill="#6868FE"
        />
      </g>
      <path
        d="M8 24H56M8 40H56M32 8V56M20.8 8H43.2C47.6804 8 49.9206 8 51.6319 8.87195C53.1372 9.63893 54.3611 10.8628 55.1281 12.3681C56 14.0794 56 16.3196 56 20.8V43.2C56 47.6804 56 49.9206 55.1281 51.6319C54.3611 53.1372 53.1372 54.3611 51.6319 55.1281C49.9206 56 47.6804 56 43.2 56H20.8C16.3196 56 14.0794 56 12.3681 55.1281C10.8628 54.3611 9.63893 53.1372 8.87195 51.6319C8 49.9206 8 47.6804 8 43.2V20.8C8 16.3196 8 14.0794 8.87195 12.3681C9.63893 10.8628 10.8628 9.63893 12.3681 8.87195C14.0794 8 16.3196 8 20.8 8Z"
        stroke="#6868FE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TableAlternateIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 64 64'} {...props} />;
};
export default TableAlternateIcon;
