import { createSvgIcon } from '@mui/material';

const EmptySpreadsheetIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.18"
        d="M139.632 5.97108V98.6727C139.632 99.457 139.478 100.234 139.178 100.958C138.878 101.683 138.439 102.342 137.884 102.896C137.329 103.451 136.671 103.891 135.946 104.191C135.222 104.491 134.445 104.646 133.661 104.645H8.25331C6.66953 104.645 5.15077 104.015 4.03102 102.895C2.91127 101.775 2.28223 100.256 2.28223 98.6727V5.97108C2.28223 4.38745 2.91132 2.86869 4.03112 1.74889C5.15091 0.629094 6.66968 8.88667e-07 8.25331 8.88666e-07H133.661C134.445 -0.000427081 135.222 0.153727 135.946 0.453651C136.671 0.753574 137.329 1.19338 137.884 1.74794C138.438 2.30249 138.878 2.9609 139.178 3.68554C139.478 4.41018 139.632 5.18683 139.632 5.97108Z"
        fill="#6868FE"
      />
      <path
        d="M137.724 5.97109V98.6727C137.724 99.457 137.57 100.234 137.27 100.958C136.971 101.683 136.531 102.342 135.976 102.896C135.422 103.451 134.763 103.891 134.039 104.191C133.314 104.491 132.537 104.646 131.753 104.645H6.339C4.75522 104.645 3.23646 104.015 2.11671 102.895C0.996961 101.775 0.36792 100.256 0.36792 98.6727V5.97109C0.36792 4.38746 0.997013 2.86869 2.11681 1.7489C3.2366 0.629101 4.75537 7.99512e-06 6.339 7.99512e-06H131.746C132.531 -0.00127658 133.309 0.152245 134.034 0.451785C134.759 0.751325 135.419 1.191 135.974 1.74564C136.529 2.30027 136.969 2.95898 137.27 3.68404C137.57 4.40911 137.724 5.18629 137.724 5.97109Z"
        fill="white"
      />
      <path
        opacity="0.18"
        d="M115.233 15.4409H70.3101C69.0254 15.4409 67.9839 16.4824 67.9839 17.7672V49.0877C67.9839 50.3724 69.0254 51.4139 70.3101 51.4139H115.233C116.518 51.4139 117.56 50.3724 117.56 49.0877V17.7672C117.56 16.4824 116.518 15.4409 115.233 15.4409Z"
        fill="#6868FE"
      />
      <path
        d="M70.2773 30.491L78.1791 23.1378L89.081 36.2732L98.6188 27.932L102.571 31.3277L111.427 19.4849L115.925 24.9073V48.6891H70.2773V30.491Z"
        fill="white"
      />
      <path
        d="M137.724 5.97109V6.13388H0.374512V5.97109C0.374511 4.38859 1.00271 2.87081 2.1211 1.7512C3.23949 0.631589 4.75658 0.00173373 6.33908 7.99512e-06H131.747C132.531 -0.00127658 133.309 0.152245 134.034 0.451785C134.759 0.751325 135.419 1.191 135.974 1.74564C136.529 2.30027 136.969 2.95898 137.27 3.68404C137.57 4.40911 137.725 5.18629 137.724 5.97109Z"
        fill="#6868FE"
      />
      <path
        d="M28.7175 61.5449H46.704V119.997H28.7175V61.5449Z"
        fill="#3100BC"
      />
      <path
        d="M46.7041 73.1094H64.6906V119.997H46.7041V73.1094Z"
        fill="#3100BC"
      />
      <path
        d="M64.6907 84.6748H82.6772V119.998H64.6907V84.6748Z"
        fill="#3100BC"
      />
      <path d="M82.677 96.2393H100.664V120H82.677V96.2393Z" fill="#3100BC" />
      <path
        d="M100.664 107.802H118.65V119.998H100.664V107.802Z"
        fill="#3100BC"
      />
      <path
        d="M18.9046 6.13379V104.645H6.339C4.75522 104.645 3.23646 104.015 2.11671 102.895C0.996961 101.775 0.36792 100.256 0.36792 98.6726V6.13379H18.9046Z"
        fill="#D7D9E1"
      />
      <path
        d="M89.0534 36.6176L78.158 23.4887L70.4418 30.6677L70.1113 30.3144L78.2019 22.7855L89.1088 35.9258L98.6172 27.6105L102.532 30.9754L111.417 19.0918L116.11 24.752L115.738 25.0613L111.437 19.8732L102.61 31.6802L98.6205 28.2519L89.0534 36.6176Z"
        fill="#6868FE"
      />
      <path
        d="M78.1093 24.1463C78.6541 24.1463 79.0958 23.7047 79.0958 23.1598C79.0958 22.615 78.6541 22.1733 78.1093 22.1733C77.5645 22.1733 77.1228 22.615 77.1228 23.1598C77.1228 23.7047 77.5645 24.1463 78.1093 24.1463Z"
        fill="#6868FE"
      />
      <path
        d="M70.2778 31.2347C70.8226 31.2347 71.2643 30.793 71.2643 30.2482C71.2643 29.7034 70.8226 29.2617 70.2778 29.2617C69.7329 29.2617 69.2913 29.7034 69.2913 30.2482C69.2913 30.793 69.7329 31.2347 70.2778 31.2347Z"
        fill="#6868FE"
      />
      <path
        d="M89.2455 37.1927C89.7904 37.1927 90.232 36.7511 90.232 36.2062C90.232 35.6614 89.7904 35.2197 89.2455 35.2197C88.7007 35.2197 88.259 35.6614 88.259 36.2062C88.259 36.7511 88.7007 37.1927 89.2455 37.1927Z"
        fill="#6868FE"
      />
      <path
        d="M98.6191 28.9183C99.1639 28.9183 99.6056 28.4766 99.6056 27.9318C99.6056 27.387 99.1639 26.9453 98.6191 26.9453C98.0742 26.9453 97.6326 27.387 97.6326 27.9318C97.6326 28.4766 98.0742 28.9183 98.6191 28.9183Z"
        fill="#6868FE"
      />
      <path
        d="M102.571 32.3153C103.116 32.3153 103.558 31.8736 103.558 31.3288C103.558 30.784 103.116 30.3423 102.571 30.3423C102.027 30.3423 101.585 30.784 101.585 31.3288C101.585 31.8736 102.027 32.3153 102.571 32.3153Z"
        fill="#6868FE"
      />
      <path
        d="M111.427 20.4691C111.972 20.4691 112.414 20.0274 112.414 19.4826C112.414 18.9378 111.972 18.4961 111.427 18.4961C110.882 18.4961 110.441 18.9378 110.441 19.4826C110.441 20.0274 110.882 20.4691 111.427 20.4691Z"
        fill="#6868FE"
      />
      <path
        d="M115.925 25.8934C116.47 25.8934 116.911 25.4517 116.911 24.9069C116.911 24.3621 116.47 23.9204 115.925 23.9204C115.38 23.9204 114.938 24.3621 114.938 24.9069C114.938 25.4517 115.38 25.8934 115.925 25.8934Z"
        fill="#6868FE"
      />
      <path d="M31.1692 16.937H45.589V31.3568H31.1692V16.937Z" fill="#6868FE" />
      <path
        d="M31.1692 38.8726H45.589V53.2924H31.1692V38.8726Z"
        fill="#6868FE"
      />
      <path
        d="M33.1455 19.0918H35.3252V29.5656H33.1455V19.0918Z"
        fill="white"
      />
      <path
        d="M36.4841 22.4678H38.6639V29.5654H36.4841V22.4678Z"
        fill="white"
      />
      <path
        d="M39.8899 24.9058H42.0696V29.5648H39.8899V24.9058Z"
        fill="white"
      />
      <path
        d="M37.9836 51.4149C40.6556 51.4149 42.8217 49.2488 42.8217 46.5768C42.8217 43.9049 40.6556 41.7388 37.9836 41.7388C35.3116 41.7388 33.1455 43.9049 33.1455 46.5768C33.1455 49.2488 35.3116 51.4149 37.9836 51.4149Z"
        stroke="white"
        stroke-width="0.8193"
        stroke-miterlimit="10"
      />
      <path
        d="M37.9834 41.7388V43.0557"
        stroke="white"
        stroke-width="0.8193"
        stroke-miterlimit="10"
      />
      <path
        d="M37.9834 50.0967V51.4136"
        stroke="white"
        stroke-width="0.8193"
        stroke-miterlimit="10"
      />
      <path
        d="M42.8199 46.5771H41.5029"
        stroke="white"
        stroke-width="0.8193"
        stroke-miterlimit="10"
      />
      <path
        d="M34.4625 46.5771H33.1455"
        stroke="white"
        stroke-width="0.8193"
        stroke-miterlimit="10"
      />
      <path
        d="M37.9834 44.2832V46.8715L40.7085 48.099"
        stroke="white"
        stroke-width="0.8193"
        stroke-miterlimit="10"
      />
      <path
        d="M6.36987 31.3564H16.0444V32.3136H6.36987V31.3564Z"
        fill="white"
      />
      <path
        d="M4.12183 28.5239H16.0445V29.7416H4.12183V28.5239Z"
        fill="white"
      />
      <path d="M6.36987 42.6768H16.0444V43.634H6.36987V42.6768Z" fill="white" />
      <path
        d="M4.12183 39.8442H16.0445V41.0619H4.12183V39.8442Z"
        fill="white"
      />
      <path
        d="M6.36987 53.9971H16.0444V54.9543H6.36987V53.9971Z"
        fill="white"
      />
      <path
        d="M4.12183 51.1646H16.0445V52.3822H4.12183V51.1646Z"
        fill="white"
      />
      <path
        d="M6.36987 65.3174H16.0444V66.2746H6.36987V65.3174Z"
        fill="white"
      />
      <path
        d="M4.12183 62.4849H16.0445V63.7025H4.12183V62.4849Z"
        fill="white"
      />
    </>,
    'EmptySpreadsheetIcon',
  );
  return (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 140 120'} {...props} />
  );
};
export default EmptySpreadsheetIcon;
