import { createSvgIcon } from '@mui/material';

const ComposeMessageIcon = () => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9.16675 3.33344H5.66675C4.26662 3.33344 3.56655 3.33344 3.03177 3.60593C2.56137 3.84561 2.17892 4.22806 1.93923 4.69847C1.66675 5.23324 1.66675 5.93331 1.66675 7.33344V14.3334C1.66675 15.7336 1.66675 16.4336 1.93923 16.9684C2.17892 17.4388 2.56137 17.8213 3.03177 18.061C3.56655 18.3334 4.26662 18.3334 5.66675 18.3334H12.6667C14.0669 18.3334 14.7669 18.3334 15.3017 18.061C15.7721 17.8213 16.1546 17.4388 16.3943 16.9684C16.6667 16.4336 16.6667 15.7336 16.6667 14.3334V10.8334M6.66673 13.3334H8.06218C8.46983 13.3334 8.67366 13.3334 8.86547 13.2874C9.03553 13.2466 9.1981 13.1792 9.34722 13.0878C9.51542 12.9848 9.65954 12.8406 9.9478 12.5524L17.9167 4.58344C18.6071 3.89309 18.6071 2.7738 17.9167 2.08344C17.2264 1.39309 16.1071 1.39308 15.4167 2.08344L7.44778 10.0524C7.15952 10.3406 7.0154 10.4848 6.91233 10.653C6.82094 10.8021 6.7536 10.9647 6.71278 11.1347C6.66673 11.3265 6.66673 11.5304 6.66673 11.938V13.3334Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'composeMessageIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox="0 0 20 20" />;
};

export default ComposeMessageIcon;
