import { useCallback, useContext, useMemo } from 'react';
import { ListCnt, LoaderCnt } from 'pages/Templates/FoldersListView/list.style';
import CustomListView from 'components/ListView/Listview.cmp';
import { columns, defaultColDef, rowHeight } from './constants';
import { MyTemplatesContext } from '../MyTemplates/myTemplatesContext.tsx/context';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useParams } from 'react-router-dom';

const ROW_HEIGHT = 48;
const NO_DATA_HEIGHT = 320;
const TABLE_HEIGHT = 235;
const HEADER_HEIGHT = 36;
const BREAK_POINT = 5;

function Listing({ data }) {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { state = {}, updateState } = useContext(MyTemplatesContext);
  const { searchTerm, templatesHeadHeight } = state;
  const params = useParams();
  const templatesList = useSelector(selectors.getChecklistListDataOnly);

  const colConfig = useMemo(() => columns({}), [state.data]);
  const handleFolderSelect = (folder) => {
    navigateWithWorkspaceUrl(
      params?.['*']
        ? `/checklist/folder/${params?.['*'] + '/' + folder?.id}`
        : `/checklist/folder/${folder?.id}`,
    );
  };
  const onRowClick = (id) => {
    handleFolderSelect({ id });
  };

  const getRowHeight = useCallback((params) => {
    return rowHeight;
  }, []);

  const onGridResize = (event) => {
    event.api.sizeColumnsToFit();
  };

  const withTemplatesCalculations = () => {
    if (data?.length === 0) {
      return NO_DATA_HEIGHT;
    }
    if (data?.length < BREAK_POINT) {
      return HEADER_HEIGHT + data?.length * ROW_HEIGHT;
    }
    return TABLE_HEIGHT;
  };

  const withoutTemplatesCalculations = () => {
    const appBarHeight =
      document.getElementsByClassName('main-header')?.[0]?.clientHeight || 0;
    const tabsHeight =
      document.getElementsByClassName('MuiTabs-root')?.[0]?.clientHeight;

    const templatesMenuHeight: any = document.getElementById(
      'opertion-templates-menu',
    )?.clientHeight;

    const spacing = 100;

    const totalHeightDeduction =
      appBarHeight +
      templatesMenuHeight +
      templatesHeadHeight +
      tabsHeight +
      spacing;

    return `calc(100vh - ${Math.ceil(totalHeightDeduction)}px)`;
  };

  const calculatedHeight = useMemo(() => {
    if (templatesList?.length === 0) {
      return withoutTemplatesCalculations();
    } else {
      return withTemplatesCalculations();
    }
  }, [data, searchTerm, templatesHeadHeight, templatesList]);

  return (
    <ListCnt>
      {state?.folderLoading ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : (
        <CustomListView
          rootProps={{
            id: 'folderListView',
            style: {
              height: calculatedHeight,
              boxShadow: 'none',
              border: '1px solid #d3d3d382',
              flex: 1,
              borderRadius: 8,
              overflow: 'hidden',
            },
          }}
          onRowClick={onRowClick}
          gridProps={{
            rowData: data,
            headerHeight: 36,
            suppressChangeDetection: true,
            defaultColDef: defaultColDef,
            columnDefs: colConfig,
            suppressCellFocus: true,
            getRowHeight: getRowHeight,
            onGridSizeChanged: onGridResize,
            suppressRowClickSelection: true,
            getRowId: (params) => params.data.id,
          }}
        />
      )}
    </ListCnt>
  );
}

export default Listing;
