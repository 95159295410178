import React from 'react';
import { createContext, FunctionComponent, useState } from 'react';
import { UserResponse } from 'stream-chat/dist/types/types';

const DEFAULT_VALUE: {
  readers: UserResponse[];
  setReaders: (reader) => void;
  message: string;
  setMessage: (message) => void;
} = {
  readers: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setReaders: (readers) => {},
  message: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMessage: (message) => {},
};

export const ReadContext = createContext(DEFAULT_VALUE); //exporting context object

const ReadReceiptContextProvider: FunctionComponent<any> = (props) => {
  const [readers, setReaders] = useState<[]>([]);
  const [message, setMessage] = useState<string>('');
  return (
    <>
      <ReadContext.Provider
        value={{ readers, setReaders, message, setMessage }}
      >
        {props.children}
      </ReadContext.Provider>
    </>
  );
};

export default ReadReceiptContextProvider;
