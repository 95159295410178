// React
import { useCallback, useEffect, useMemo, Fragment } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import EmptyState from '../EmptyState';

// Constants
import { columns, defaultColDef, rowHeight } from './constants';

// Styles
import {
  ListCnt,
  LoaderCnt,
} from 'pages/Templates/TemplateListView/list.style';
import { CircularProgress } from '@mui/material';
import './table.css';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useTemplateAccess } from 'controller/useTemplateAccess';
import useLogActions from 'pages/TemplateDashboard/Tabs/Submissions/hooks/useLogActions';

function TemplateTable({ searchTerm }) {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { isTemplateAdmin } = useTemplateAccess();
  const _templatesList = useSelector(selectors.getChecklistListDataOnly);
  const { downloading, handleDownloadReportApi } = useLogActions();

  const templatesList: any = useMemo(() => {
    return _templatesList?.filter((t) => isTemplateAdmin(t));
  }, [_templatesList]);

  const filteredData = useMemo(() => {
    if (!searchTerm) {
      return templatesList;
    }
    return templatesList?.filter((f) => {
      return f?.name?.toLowerCase()?.includes(searchTerm.toLowerCase());
    });
  }, [searchTerm, templatesList]);

  const colConfig = useMemo(
    () => columns({ handleDownloadReportApi, downloading }),
    [downloading],
  );

  const onRowClick = (id) => {
    navigateWithWorkspaceUrl(
      `/templates/spreadsheet/${id}?prev=/templates-table-view`,
    );
  };

  const getRowHeight = useCallback(() => {
    return rowHeight;
  }, []);

  useEffect(() => {
    return () => {
      globalThis.templateTableView = null;
    };
  }, []);

  return (
    <ListCnt>
      {!templatesList ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : (
        <Fragment>
          {templatesList?.length === 0 ? (
            <EmptyState />
          ) : (
            <CustomListView
              rootProps={{
                className: 'template-table',
                style: {
                  height: 'calc(100vh - 140px)',
                  boxShadow: 'none',
                  border: '1px solid #d3d3d382',
                  flex: 1,
                  borderRadius: 8,
                  overflow: 'hidden',
                  padding: '0px 10px 12px 10px',
                  background: '#F5F5F5',
                },
              }}
              onRowClick={onRowClick}
              gridProps={{
                onGridReady: (params) => {
                  params.api.sizeColumnsToFit();
                },
                rowData: filteredData,
                ref: (ref) => {
                  globalThis.globalTableView = ref;
                },
                headerHeight: 30,
                suppressChangeDetection: true,
                defaultColDef: defaultColDef,
                columnDefs: colConfig,
                suppressCellFocus: true,

                getRowHeight: getRowHeight,
                suppressRowClickSelection: true,
                getRowId: (params) => params.data.id,
              }}
            />
          )}
        </Fragment>
      )}
    </ListCnt>
  );
}

export default TemplateTable;
