import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import LogDrawer from '../LogDrawer';

// AG Grid
import {
  defaultColDef,
  getRowStyles,
  columns,
} from 'pages/Widgets/SubmissionsListing/SubmissionListView/constants';

// Utils
import xeniaApi from 'api/index';
import { WidgetHeading } from 'pages/Widgets/widgets.style';
import 'pages/Widgets/SubmissionsListing/SubmissionListView/styles.css';
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getUserConfig, editUserConfig } from 'api/userConfig';
import { useLoadWidget } from 'pages/Dashboards/Widgets/useLoadWidget';
import { exportToPng } from 'utils/globalFunction';
import WidgetActions from 'pages/Dashboards/Widgets/widgetActions';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import { WidgetCnt } from 'pages/Dashboards/Widgets/widgets.style';
import { useMutation } from 'react-query';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import WidgetAdvanceFilter from '../../WidgetAdvanceFilter/widgetAdvanceFilter';
import { cloneDeep } from 'lodash';
interface ISubmissionTableWidgetProps {
  widgetId: string;
  editable?: boolean;
  globalFilters?: any;
}
// Submissions List.
const SubmissionListSummary = ({
  globalFilters,
  editable,
  widgetId,
}: ISubmissionTableWidgetProps) => {
  const datesFilters = ['completedAt'];
  const [data, setData] = useState<any>([]);
  const [activeLog, setActiveLog] = useState<SubmissionReportType>();
  const [tableConfig, setTableConfig] = useState<any>(null);
  const userId = useSelector(selectors.getUserId);
  const { workspaceId } = useWorkspaceHook();
  const getSubmissionsQuery = useMutation({
    mutationKey: 'getSubmissionList',
    mutationFn: xeniaApi.getAllChecklistLogs,
  });

  const { widget } = useLoadWidget(widgetId, globalFilters);
  const chartRef: any = useRef(null);

  const transformDuration = (values) => {
    const MinToSeconds = 60;
    const HourToSeconds = 60 * 60;

    const timeRanges: any = [];

    values.forEach((value) => {
      switch (value) {
        case '0 - 30 mins':
          timeRanges.push({ start: 0, end: 30 * MinToSeconds });
          break;
        case '30 mins - 1 hour':
          timeRanges.push({ start: 30 * MinToSeconds, end: HourToSeconds });
          break;
        case '1 hour - 3 hours':
          timeRanges.push({ start: HourToSeconds, end: 3 * HourToSeconds });
          break;
        case '3 hours - 6 hours':
          timeRanges.push({ start: 3 * HourToSeconds, end: 6 * HourToSeconds });
          break;
        case '6 hours - 12 hours':
          timeRanges.push({
            start: 6 * HourToSeconds,
            end: 12 * HourToSeconds,
          });
          break;
        case '12 hours - 24 hours':
          timeRanges.push({
            start: 12 * HourToSeconds,
            end: 24 * HourToSeconds,
          });
          break;
        case 'More than 24 hours':
          timeRanges.push({ start: 24 * HourToSeconds, end: null });
          break;
        default:
          timeRanges.push({ start: null, end: null });
      }
    });
    return timeRanges;
  };

  const fetchSubmissionsList = async () => {
    let localFilters = cloneDeep(widget?.filters?.filters);
    const localItemFilters = cloneDeep(
      widget?.additionalFilters?.submissionFilters?.filters,
    );

    if (localFilters?.length > 0) {
      localFilters = localFilters?.map((filter) => {
        if (datesFilters?.includes(filter?.filterName)) {
          return {
            ...filter,
            value: [filter?.value?.from, filter?.value?.to],
          };
        }

        return filter;
      });
    }

    const globalAdvanceFilters = {
      condition: 'AND',
      filters: [
        ...(globalFilters &&
        globalFilters.dateRange &&
        globalFilters.dateRange[0] &&
        globalFilters.dateRange[1]
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'date',
                value: [globalFilters.dateRange[0], globalFilters.dateRange[1]],
              },
            ]
          : []),
        ...(globalFilters &&
        globalFilters.location &&
        globalFilters.location.length > 0
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'locations',
                value: globalFilters.location,
              },
            ]
          : []),
      ],
    };

    localFilters = localFilters?.map((filter) => {
      if (filter?.filterName === 'duration') {
        return {
          ...filter,
          value: transformDuration(filter?.value),
        };
      }

      return filter;
    });

    localFilters = localFilters?.filter(
      (filter) =>
        !(filter?.filterName === 'user' && filter?.value?.[0] === 'all'),
    );

    // payload
    const requestPayload = {
      globalFilters: globalAdvanceFilters,
      ...(localFilters?.length > 0
        ? {
            advanceFilters: {
              condition: localFilters?.[0]?.conditional?.toUpperCase(),
              filters: localFilters,
            },
          }
        : {
            advanceFilters: {
              condition: 'AND',
              filters: [
                {
                  filterName: 'status',
                  comparator: 'is',
                  value: ['In Progress', 'Submitted'],
                  conditional: 'and',
                },
              ],
            },
          }),
      ...(localItemFilters?.length > 0 && {
        advanceItemFilters: {
          condition: 'AND',
          filters: localItemFilters,
        },
      }),
    };

    getSubmissionsQuery.mutate(requestPayload, {
      onSuccess: (data) => {
        setData(data?.data);
      },
      onError: (error) => {
        console.log('error', error);
      },
    });
  };

  const fetchTableConfig = async () => {
    const config = await getUserConfig(`dashtemplateSubmissionList:${userId}`);
    if (config?.config?.config) {
      const colConfig = config.config.config;
      setTableConfig(colConfig);
    }
  };

  useEffect(() => {
    if (widget?.id) {
      fetchSubmissionsList();
    }
  }, [globalFilters, widget]);

  useEffect(() => {
    if (userId) {
      fetchTableConfig();
    }
  }, [userId]);

  const onRowClick = async (id: string) => {
    const log = await xeniaApi.getChecklistLog({
      logId: id,
      sectioned: true,
      hotelId: workspaceId,
      filtered: true,
    });
    if (log?.data) {
      setActiveLog(log.data);
    } else {
      setActiveLog(undefined);
    }
  };

  //Hiding last 2 options for columns menu
  const getMainMenuItems = (params) => {
    const newOptions = params.defaultItems.slice(
      0,
      params.defaultItems.length - 3,
    );
    return newOptions;
  };

  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);
  const exportChartToImage = () => {
    exportToPng(chartRef.current, widget?.title);
  };
  const handleArchiveApi = async (logs: any[]) => {
    const logIds = logs.map((log) => log.id);
    await xeniaApi.deleteChecklistLogs({
      checklistId: activeLog?.ChecklistId,
      hotelId: workspaceId,
      postData: {
        logIds,
      },
    });
    setData((prevData) => prevData.filter((log) => !logIds.includes(log.id)));
    setActiveLog(undefined);
  };

  const gridColumns = useMemo(() => {
    const staticColumns = columns({ fetchSubmissionsList });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = tableConfig?.find((c) => c.colId === col.colId);
      const index = tableConfig?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [fetchSubmissionsList, tableConfig]);

  const updateColumns = async (columnState: any[]) => {
    const previousTableConfig = tableConfig || [];
    setTableConfig(columnState);
    const updatedConfig = await editUserConfig({
      type: `dashtemplateSubmissionList:${userId}`,
      config: columnState,
    });
    if (!updatedConfig?.config?.config) {
      setTableConfig(previousTableConfig);
    }
  };

  const onColumnResized = (event) => {
    if (event?.finished && event.source !== 'api') {
      updateColumns(event.columnApi.getColumnState());
    }
  };

  const handleUpdateColumnState = (event) => {
    updateColumns(event.columnApi.getColumnState());
  };

  return (
    <WidgetCnt ref={chartRef}>
      <LogDrawer
        open={!!activeLog}
        onClose={() => setActiveLog(undefined)}
        log={activeLog as SubmissionReportType}
        handleArchiveLog={handleArchiveApi}
      />
      <WidgetHeading style={{ marginBottom: 15 }}>
        {widget?.title || 'Submissions'}
        <div className={'widgetActionsCnt'}>
          <WidgetAdvanceFilter
            widgetId={widgetId}
            globalFilters={globalFilters}
          />
          <WidgetActions
            widgetId={widgetId}
            exportChartToImage={exportChartToImage}
            editable={editable}
          />
        </div>
      </WidgetHeading>
      <WithLoader
        isLoading={
          getSubmissionsQuery?.status === 'idle' ||
          getSubmissionsQuery?.isLoading
        }
      >
        <WithErrorState
          type={'table'}
          error={getSubmissionsQuery?.error}
          reload={fetchSubmissionsList}
        >
          <CustomListView
            rootProps={{
              style: {
                height: 400,
                boxShadow: 'none',
                flex: 1,
              },
            }}
            onRowClick={onRowClick}
            gridProps={{
              ref: (ref) => {
                globalThis.taskSummaryListGrid = ref;
              },
              onSortChanged: handleUpdateColumnState,
              onDragStopped: handleUpdateColumnState,
              onColumnResized: onColumnResized,
              onColumnVisible: handleUpdateColumnState,
              onColumnPinned: handleUpdateColumnState,
              onColumnMoved: handleUpdateColumnState,
              columnDefs: gridColumns,
              headerHeight: 30,
              rowData: data,
              suppressChangeDetection: true,
              defaultColDef: defaultColDef,
              suppressCellFocus: true,
              getMainMenuItems: getMainMenuItems,
              getRowHeight: getRowHeight,
              getRowStyle: getRowStyles,
              suppressRowClickSelection: true,
              getRowId: (params) => params.data.id,
            }}
          />
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
};

export default SubmissionListSummary;
