import { FC, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BoostImg,
  Header,
  InviteandBoost,
  MainCnt,
  Main,
  Content,
  EmptySubmissionsTitle,
  EmptySubmissionsStatement,
  TemplateGrid,
} from './style';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import BoostDashboard from 'public/assets/img/boostdashboard.png';

import ExploreTemplate from 'public/assets/img/explore-template.svg';
import FileSearchIcon from 'components/Icons/fileSearchIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import { getSubmissionList } from 'api/reporting';
import { CircularProgress } from '@mui/material';
import { Stack } from '@mui/material';

import ChecklistEmpty from 'public/assets/img/checklist-two-colors.svg';
import Tile from './components/Tile';
import { FixedSizeGrid as Grid } from 'react-window';
import useMeasure from 'react-use-measure';
import { getUserProfile } from 'store/selectors/auth';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const InProgressSubmissions = (props?: any) => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [loading, setLoading] = useState(true);
  const [submissions, setSubmissions] = useState<any>(null);
  const [ref, { width, height }] = useMeasure();
  const userProfile: any = useSelector(getUserProfile);
  const { id } = userProfile;

  useEffect(() => {
    fetchSubmissions();
  }, []);

  const fetchSubmissions = async () => {
    setLoading(true);
    const data = await getSubmissionList(
      {
        statuses: ['In Progress'],
        lastItemUpdatedBy: id,
      },
      false,
    );
    if (data) {
      setSubmissions(data);
    } else {
      setSubmissions([]);
    }
    setLoading(false);
  };

  const onClickContinue = (checklistId: string, logId: string) => {
    navigateWithWorkspaceUrl(
      `/in-progress-checklist/${checklistId}/?logId=${logId}&prevUrl=${`/dashboard`}`,
    );
  };

  const numCols = useMemo(() => {
    if (width < 450) {
      return 1;
    } else if (width < 720) {
      return 2;
    } else if (width < 1000) {
      return 3;
    } else {
      if (width <= 1200) {
        return 4;
      }
      return Math.floor(width / 300);
    }
  }, [width]);

  return (
    <Main>
      {!submissions && !loading && (
        <InviteandBoost>
          <div className="box">
            <CTypography className="title">
              Get started fast with a ready-made operations template
            </CTypography>
            <hr />
            <CTypography className="subtitle">
              Set up your team for success in record time with templates for
              operations, submissions, inspections, SOPs and more.
            </CTypography>
            <BoostImg src={BoostDashboard} />
            <CustomButton
              variant="contained"
              type="submit"
              startIcon={<img src={ExploreTemplate} />}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
              onClick={() => {
                dispatch(
                  setDialog({
                    dialogId: DIALOGS.TEMPLATE_LIBRARY,
                    open: true,
                  }),
                );
              }}
            >
              {'Explore templates'}
            </CustomButton>
          </div>
        </InviteandBoost>
      )}

      <MainCnt>
        <Header>
          <CTypography
            sx={{
              maxWidth: '50%',
              whiteSpace: 'pre',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            className="title"
          >{`In Progress Submissions ${
            submissions?.length ? `(${submissions.length})` : ''
          }`}</CTypography>
          <CustomButton
            variant="contained"
            startIcon={<FileSearchIcon style={{ fill: 'none' }} />}
            onClick={() => navigateWithWorkspaceUrl('/checklist')}
            onMouseDown={stopPropagation}
            onTouchStart={stopPropagation}
          >
            Go to My Templates
          </CustomButton>
        </Header>
        <hr />

        {/* Rows Content */}

        <Content ref={ref}>
          {loading && (
            <Stack
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Stack>
          )}
          {!loading && (!submissions || submissions?.length == 0) && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <img src={ChecklistEmpty} width="125px" />
              <EmptySubmissionsTitle>
                No Active Submissions
              </EmptySubmissionsTitle>
              <EmptySubmissionsStatement>
                Great job! There are currently no submissions in progress.
              </EmptySubmissionsStatement>
              <EmptySubmissionsStatement>
                Feel free to start a new submission.
              </EmptySubmissionsStatement>
            </Stack>
          )}
          {submissions?.length > 0 && (
            <Grid
              width={width || 0}
              height={height || 0}
              columnCount={numCols}
              columnWidth={width ? width / numCols : 4}
              rowHeight={98}
              rowCount={Math.ceil(submissions.length / numCols)}
              itemData={submissions}
            >
              {(props) => (
                <Tile
                  {...props}
                  row={
                    submissions[props.rowIndex * numCols + props.columnIndex]
                  }
                  onClickContinue={onClickContinue}
                  numCols={numCols}
                  numRows={Math.ceil(submissions.length / numCols)}
                />
              )}
            </Grid>
          )}
        </Content>
      </MainCnt>
    </Main>
  );
};

export default InProgressSubmissions;
