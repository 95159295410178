import { WidgetActionsCnt } from 'pages/Dashboards/Widgets/widgetActions/style';
import ExportWidgetDropdown from 'pages/Dashboards/Widgets/widgetActions/exportDropdown/exportDropdown';
import WidgetActionDropdown from 'pages/Dashboards/Widgets/widgetActions/actionDropdown';
import EditButton from 'pages/Dashboards/Widgets/widgetActions/editButton/edit';
import WithDashboardPermission from 'pages/Dashboards/withDashboardPermission';
interface IWidgetActionsProps {
  exportChartToImage: () => void;
  widgetId: string;
  editable?: boolean;
  isCompactAction?: boolean;
}
function WidgetActions({
  exportChartToImage,
  widgetId,
  editable,
  isCompactAction,
}: IWidgetActionsProps) {
  return (
    <WidgetActionsCnt>
      {!isCompactAction && (
        <ExportWidgetDropdown exportChartToImage={exportChartToImage} />
      )}
      <WithDashboardPermission>
        {editable && !isCompactAction && <EditButton widgetId={widgetId} />}
        {editable && (
          <WidgetActionDropdown
            options={
              isCompactAction ? ['download', 'edit', 'delete'] : ['delete']
            }
            widgetId={widgetId}
            exportChartToImage={exportChartToImage}
          />
        )}
      </WithDashboardPermission>
      {!editable && isCompactAction && (
        <WidgetActionDropdown
          widgetId={widgetId}
          exportChartToImage={exportChartToImage}
          options={['download']}
        />
      )}
    </WidgetActionsCnt>
  );
}

export default WidgetActions;
