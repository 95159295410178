import { createSvgIcon } from '@mui/material';

const PlayIconRounded = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_8273_149800)">
        <path
          d="M8 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8 1.33325C4.3181 1.33325 1.33333 4.31802 1.33333 7.99992C1.33333 11.6818 4.3181 14.6666 8 14.6666Z"
          stroke="black"
          stroke-opacity="0.54"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.33333 5.97681C6.33333 5.65862 6.33333 5.49952 6.39982 5.4107C6.45777 5.3333 6.54647 5.28488 6.64291 5.27799C6.75358 5.27008 6.88741 5.35611 7.15507 5.52818L10.3021 7.55129C10.5344 7.7006 10.6505 7.77525 10.6906 7.87018C10.7257 7.95312 10.7257 8.04672 10.6906 8.12966C10.6505 8.22459 10.5344 8.29924 10.3021 8.44855L7.15507 10.4717C6.88741 10.6437 6.75358 10.7298 6.64291 10.7219C6.54647 10.715 6.45777 10.6665 6.39982 10.5891C6.33333 10.5003 6.33333 10.3412 6.33333 10.023V5.97681Z"
          stroke="black"
          stroke-opacity="0.54"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8273_149800">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </>,
    'PlayIconRounded',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default PlayIconRounded;
