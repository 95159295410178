import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { CheckListIconBox, MainCnt, Value } from './style';

export const Name = (props) => {
  const { template } = props;
  const { name, icon } = template || {};

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  return (
    <MainCnt>
      <CheckListIconBox
        style={{
          backgroundColor: icon?.color,
          borderRadius: 4,
          marginRight: 10,
        }}
      >
        <MuiIcon name={icon?.icon} style={{ height: 15, width: 15 }} />
      </CheckListIconBox>
      <Value>{name}</Value>
    </MainCnt>
  );
};
