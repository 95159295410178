// React
import { ReactNode } from 'react';

// MUI
import { Stack, Typography, IconButton } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';

// Icons
import FeatureLockIcon from 'components/Icons/featureLockIcon';

interface PropTypes {
  configName: string;
  description: string | ReactNode;
  featureLocked?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}

const ConfigSummary = ({
  configName,
  description,
  featureLocked,
  icon,
  onClick,
}: PropTypes) => {
  return (
    <Stack
      direction={'row'}
      gap={'12px'}
      borderRadius={'8px'}
      border="1px solid #E0E0E0"
      padding={'12px 16px'}
      sx={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {icon}
      <Stack flex={1} direction={'column'} gap={'4px'}>
        <Typography fontWeight={600} fontSize={16} color={'#212121'}>
          {configName}
        </Typography>
        <Typography fontWeight={400} fontSize={13} color={'#424242'}>
          {description}
        </Typography>
      </Stack>
      <Stack justifyContent={'center'}>
        <IconButton sx={{ p: '6px', '& svg': { fontSize: '1.8rem' } }}>
          {featureLocked ? (
            <FeatureLockIcon style={{ height: 36, width: 36 }} />
          ) : (
            <ChevronRightRounded />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ConfigSummary;
