import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';

export const MainCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .icon': {
    height: 20,
    width: 20,
    marginRight: 10,
  },
});

export const Value = styled(CTypography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  letterSpacing: '-0.1px',
  color: '#212121',
});
