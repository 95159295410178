// Custom components
import AvatarPopover from 'components/AvatarPopover';

import {
  Container,
  TitleText,
  GreyText,
  ScheduleCnt,
  AssigneeCnt,
  LocationNameCnt,
} from './styled';
import { Stack } from '@mui/material';

// Icons
import RecurringIcon from 'components/Icons/recurringIcon';

// Constants
import { weeks } from 'components/RepeatTask/Weekly/constants';
import { months } from 'components/RepeatTask/Monthly/constants';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { useSelector } from 'react-redux';
import { getAuthLocationsHash } from 'store/selectors/locations';
import { useMemo } from 'react';

export const Title = ({ title }: { title: string }) => {
  return (
    <Container>
      <TitleText className="schedule-title">{title}</TitleText>
    </Container>
  );
};

export const Location = ({ location }) => {
  const authLocationsHash = useSelector(getAuthLocationsHash);

  const parentLocation = useMemo(() => {
    if (location?.ParentId) {
      const parentNode = authLocationsHash?.[location?.ParentId];
      return parentNode?.authorized ? parentNode : null;
    }

    return null;
  }, [location?.ParentId]);

  return (
    <LocationNameCnt isParent={parentLocation?.name}>
      <div className="content">
        <span className={'locationName'}> {location?.name}</span>
        {parentLocation?.name && (
          <span className="parentLocationName"> {parentLocation?.name}</span>
        )}
      </div>
    </LocationNameCnt>
  );
};

export const Schedule = ({ recurringByEvery }: { recurringByEvery: any }) => {
  const scheduleList = [...weeks, ...months];
  const schedule = scheduleList?.find(
    (item) => item.id === recurringByEvery,
  )?.displayTitle;
  if (!schedule) return <>-</>;
  return (
    <ScheduleCnt>
      <div className="innerCnt">
        <RecurringIcon />
        <GreyText>{schedule}</GreyText>
      </div>
    </ScheduleCnt>
  );
};

export const CreatedBy = ({ creator }: { creator: any }) => {
  if (!creator) return <>-</>;
  return (
    <Container>
      <Stack direction={'row'} alignItems={'center'}>
        {!creator && <span>{'-'}</span>}
        {creator && (
          <AvatarPopover
            nameStyle={{
              color: '#616161',
              fontSize: 14,
              fontWeight: 400,
            }}
            userId={creator.id}
          />
        )}
      </Stack>
    </Container>
  );
};

export const Assignee = ({ assignees }: { assignees: Array<any> }) => {
  return (
    <AssigneeCnt data-attribute="cell-click">
      {assignees?.length == 1 && (
        <AssigneesView type="single" data={assignees} />
      )}

      {assignees?.length > 1 && (
        <AssigneesView
          type="avatars"
          data={assignees}
          assigneesProps={{ isMenuToShow: true }}
        />
      )}

      {(assignees?.length == 0 || !assignees) && '-'}
    </AssigneeCnt>
  );
};

export const NextDue = ({ nextDue }: { nextDue: string }) => {
  return (
    <Container>
      <GreyText>{nextDue}</GreyText>
    </Container>
  );
};
