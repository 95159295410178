import React from 'react';

// Custom components
import UserAvatar from 'components/Avatar';
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';

import {
  Wrapper,
  AvatarAndNameWrapper,
  UserName,
  Date,
  NoteText,
  AttachmentsWrapper,
} from './styled';

// Types
import { TemplateNoteType } from 'pages/TemplateDashboard/context';

// Utils
import moment from 'moment-timezone';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

interface NotePropTypes {
  note: TemplateNoteType;
}

const Note = ({ note }: NotePropTypes) => {
  const { getUtcToTzTime } = useDateTime();

  return (
    <Wrapper>
      <AvatarAndNameWrapper>
        <UserAvatar userId={note.Creator.id} height={28} width={28} />
        <UserName>{`${note.Creator.firstName} ${note.Creator.lastName}`}</UserName>
        <Date>
          {moment(note.updatedAt).format('MMM DD YYYY')}{' '}
          {getUtcToTzTime(note.updatedAt)}
        </Date>
      </AvatarAndNameWrapper>
      <NoteText>{note.text}</NoteText>
      <AttachmentsWrapper>
        <RenderAttachments attachments={note.attachments} />
      </AttachmentsWrapper>
    </Wrapper>
  );
};

export default Note;
