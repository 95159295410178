import { Box, Stack, styled } from '@mui/material';

export const MainContainer = styled(Stack)(() => ({
  width: '100%',
  padding: 20,
}));

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});
