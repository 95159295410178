import { Box, Stack, Typography } from '@mui/material';
import styled from '@mui/system/styled';

export const Container = styled(Box)({
  width: '100%',
  minHeight: 350,
  borderRadius: 10,
  padding: 20,
  gap: 12,
  backgroundColor: '#EEEDFB',
});

export const Title = styled(Typography)({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '24.59px',
  color: '#000000',
  marginBottom: 12,
});

export const ActionBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isCompleted',
})(({ isCompleted }) => ({
  flexDirection: 'column',
  justifyContent: 'start',
  backgroundColor: isCompleted ? '#ECF8ED' : 'white',
  borderRadius: 8,
  border: isCompleted ? '1px solid #C8E6C9' : '1px solid #E0E0E0',
  boxShadow: '0px 2px 1px 0px #0000000D',
  padding: 16,
  height: 180,
  position: 'relative',

  '&:hover': {
    border: isCompleted ? '1px solid #C8E6C9' : '1px solid #6868FE',
    transition: '0.3s all',
  },

  '& .iconCnt': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    border: '1px solid #E0E0E0',
    height: 36,
    width: 36,
    marginBottom: 13,

    '& svg': {
      width: 22,
    },
  },

  '& .checkIconCnt': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    height: 36,
    width: 36,
    backgroundColor: '#4CAF50',
    marginBottom: 13,
    '& svg': {
      color: 'white',
    },
  },

  '& .title': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#212121',
    marginBottom: 3,
  },

  '& .description': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#424242',
  },

  '& .actionBtn': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#4E48FA',
    width: 'fit-content',

    position: 'absolute',
    bottom: 10,
    left: 8,

    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));

export const Footer = styled(Stack)(() => ({
  marginTop: 15,
  borderRadius: 8,
  padding: 16,
  gap: 16,
  backgroundColor: 'white',
  boxShadow: '0px 2px 1px 0px #0000000D',

  '& .main': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20.49px',
    color: '#212121',

    '& .industry': {
      fontWeight: 800,
      color: '#4E48FA',
    },
  },

  '& .sub': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#424242',
  },

  '& .bookBtn': {
    height: 43,
    minWidth: 147,
    borderRadius: 6,
    gap: 8,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '19.12px',
  },
}));
