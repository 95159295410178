import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import actions from 'store/actions';

import PageHeader from './header/PageHeader';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getItem, removeItem } from 'utils/globalFunction';
import Templates from 'pages/Templates';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';
const headerState = getItem('hideChecklistHeader');
const headerStateValueParsed = headerState != 'true';
const Checklist: FC = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [showPageHeader, setShowPageHeader] = useState(headerStateValueParsed);
  const { lastFolderId } = useCurrentFolderId();
  const { hasPermission } = useCustomPermission();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION)) {
      navigateWithWorkspaceUrl('/dashboard');
      return;
    }
  }, []);

  useEffect(() => {
    dispatch(actions.initializeNewChecklist());
    if (!lastFolderId) {
      dispatch(
        actions.getChecklistListApiCall({
          folderId: lastFolderId || 'root',
        }),
      );
    }
    dispatch(actions.getPublicChecklists({ detailed: true }));

    // close the modal in case user goes back/forward in browser
    return () => {
      dispatch(actions.closePreviewChecklistModal());
    };
  }, []);

  const duplicateChecklist = (
    checklistId: any,
    saveToLibrary = false,
    disableToast = false,
    onboarding = false,
  ) => {
    dispatch(
      actions.duplicateChecklistApiCall(
        checklistId,
        saveToLibrary,
        disableToast,
        onboarding,
      ),
    );
  };

  useEffect(() => {
    const templateId = searchParams.get('template-id');
    if (templateId) {
      duplicateChecklist(templateId, true, true, true);
      searchParams.delete('template-id');
      setSearchParams(searchParams);
      removeItem('template-id');
    }
  }, []);

  return hasPermission(PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION) ? (
    <Box>
      <div id="opertion-templates-menu">
        {showPageHeader && <PageHeader setShowPageHeader={setShowPageHeader} />}
      </div>
      <Templates />
    </Box>
  ) : null;
};

export default Checklist;
