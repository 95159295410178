import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { setDialog } from 'store/actions/dialogActions';
import CalendarInputIcon from 'components/Icons/calendarInputIcon';
import ReminderIcon from 'components/Icons/reminderIcon';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import DIALOGS from 'utils/dialogIds';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import useResponsive from 'utils/CustomHooks/useResponsive';
import moment from 'moment';

import {
  CompletedCnt,
  MainCnt,
  MainTitle,
  MarkCompleteBtn,
  ReminderCnt,
  ReminderInfo,
  ServiceDate,
  ServiceDateCnt,
  BreadCrumbMain,
  BreadCrumbSub,
  TagsCnt,
} from './style';

interface IProps {
  markCompleteServiceSuccessCallback(data?: any): void;
  deleteServiceSuccessCallback(data?: any): void;
  editServiceSuccessCallback(data?: any): void;
  service?: any;
}

function ServiceInfoCard(props: IProps) {
  const {
    editServiceSuccessCallback,
    deleteServiceSuccessCallback,
    markCompleteServiceSuccessCallback,
    service,
  } = props;
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const { addNewAssignees } = useAssigneesUtils();
  const { getUtcToTz } = useDateTime();

  const openDetailView = (id) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.ASSET_SERVICE_DETAIL,
        data: {
          id,
          editSuccessCallback: editServiceSuccessCallback,
          deleteSuccessCallback: deleteServiceSuccessCallback,
          markCompleteSuccessCallback: markCompleteServiceSuccessCallback,
        },
      }),
    );
  };

  const serviceDateTime = useMemo(() => {
    if (service?.date) {
      const TZDateTime = getUtcToTz(
        service?.date,
        service?.createdInPast ? 'MMM DD, YYYY' : 'MMM DD, YYYY [at] h:mm A',
      );
      return TZDateTime;
    }
  }, [service?.date]);

  const notifiers = useMemo(() => {
    if (service?.reminder?.recipients?.length > 0) {
      return addNewAssignees(service?.reminder?.recipients);
    }
  }, [service?.reminder?.recipients]);

  const markCompleteHandler = (event) => {
    event?.stopPropagation();
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.ASSET_SERVICE_MARK_COMPLETE,
        data: {
          service,
          onSuccessCallback: markCompleteServiceSuccessCallback,
        },
      }),
    );
  };

  const isReminderExists = useMemo(() => {
    const { reminder } = service || {};
    return (
      reminder?.time?.value &&
      reminder?.time?.unit &&
      reminder?.recipients?.length > 0
    );
  }, [service?.reminder]);

  const completedDateTime = useMemo(() => {
    if (service?.completedAt) {
      const TZDateTime = getUtcToTz(
        service?.createdAt,
        'MMM DD, YYYY [at] h:mm A',
      );
      return TZDateTime;
    }
  }, [service?.completedAt]);

  const isOverDueService = useMemo(() => {
    if (service?.createdInPast) {
      return false;
    }

    const givenDateTime = moment(service?.date);
    const currentDateTime = moment();
    const isBeforeCurrentDateTime = givenDateTime.isBefore(currentDateTime);

    if (isBeforeCurrentDateTime && !service?.completedAt) {
      return true;
    }

    if (isBeforeCurrentDateTime && service?.completedAt) {
      const completedDateTime = moment(service?.completedAt);
      const isBeforeCompletedDateTime =
        givenDateTime.isBefore(completedDateTime);

      return isBeforeCompletedDateTime;
    }
  }, [service]);

  return (
    <MainCnt onClick={() => openDetailView(service?.id)}>
      <Stack direction="row" justifyContent={'space-between'}>
        <Stack direction="column">
          {service?.Asset?.ParentId ? (
            <>
              <BreadCrumbMain>
                {service?.Asset?.ParentAsset?.name}
              </BreadCrumbMain>
              <BreadCrumbSub>{service?.Asset?.name}</BreadCrumbSub>
            </>
          ) : (
            <BreadCrumbMain>{service?.Asset?.name}</BreadCrumbMain>
          )}

          {service?.title && <MainTitle>{service?.title}</MainTitle>}
        </Stack>

        {!isMobileDeviceSm && !service?.completedAt && (
          <MarkCompleteBtn
            variant="contained"
            onClick={markCompleteHandler}
            startIcon={<DoneIcon />}
          >
            Mark Complete
          </MarkCompleteBtn>
        )}
      </Stack>

      <TagsCnt>
        <ServiceDateCnt
          style={{
            ...(isOverDueService && {
              backgroundColor: '#FBECEC',
              borderColor: '#FBECEC',
            }),
          }}
        >
          {isOverDueService ? (
            <AccessTimeRoundedIcon sx={{ fontSize: 16, color: '#D32F2F' }} />
          ) : (
            <CalendarInputIcon
              style={{ height: 14, width: 15, fill: 'none' }}
            />
          )}

          <ServiceDate>Service Date: {serviceDateTime}</ServiceDate>
        </ServiceDateCnt>

        {service?.completedAt && (
          <CompletedCnt>
            <CheckCircleRoundedIcon className="completedIcon" />
            <Typography className="completedBy">
              {service?.Completer?.firstName} {service?.Completer?.lastName}
            </Typography>
            <span>•</span>
            <Typography className="dateTime">{completedDateTime}</Typography>
          </CompletedCnt>
        )}

        {!service?.completedAt && isReminderExists && (
          <ReminderCnt>
            <ReminderIcon
              style={{ height: 17, width: 17, fill: 'none' }}
              sx={{
                '& path': {
                  stroke: '#6868FE',
                },
              }}
            />
            <ReminderInfo>
              {service?.reminder?.time?.value} {service?.reminder?.time?.unit}s
              before service date
            </ReminderInfo>
            <AssigneesView
              type="avatars"
              data={notifiers}
              assigneesProps={{
                height: 22,
                width: 22,
                popperProps: {
                  sx: {
                    zIndex: 1700,
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'white !important',
                      boxShadow:
                        'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                      minWidth: 300,
                      maxHeight: 300,
                      overflowY: 'auto',
                      borderRadius: '15px',
                    },
                  },
                },
              }}
            />
          </ReminderCnt>
        )}
        {isMobileDeviceSm && !service?.completedAt && (
          <Stack alignItems="end">
            <MarkCompleteBtn
              variant="contained"
              onClick={markCompleteHandler}
              startIcon={<DoneIcon />}
              style={{
                width: 170,
                marginTop: 10,
              }}
            >
              Mark Complete
            </MarkCompleteBtn>
          </Stack>
        )}
      </TagsCnt>
    </MainCnt>
  );
}

export default ServiceInfoCard;
