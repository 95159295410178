import { createSvgIcon } from '@mui/material';

const MultiSiteIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect y="0.548828" width="48" height="48" rx="8" fill="#CCCBFF" />
      <g opacity="0.12">
        <path
          d="M13.5 25.2485C13.5 24.5951 13.5 24.2684 13.6272 24.0188C13.739 23.7993 13.9175 23.6208 14.137 23.509C14.3866 23.3818 14.7133 23.3818 15.3667 23.3818H18.75V35.0485H13.5V25.2485Z"
          fill="#4032E1"
        />
        <path
          d="M29.25 23.3818H32.6333C33.2867 23.3818 33.6134 23.3818 33.863 23.509C34.0825 23.6208 34.261 23.7993 34.3728 24.0188C34.5 24.2684 34.5 24.5951 34.5 25.2485V35.0485H29.25V23.3818Z"
          fill="#4032E1"
        />
      </g>
      <path
        d="M15.3668 23.3822H18.7502V17.7822C18.7502 16.4754 18.7502 15.822 19.0045 15.3229C19.2282 14.8838 19.5851 14.5269 20.0242 14.3031C20.5233 14.0488 21.1767 14.0488 22.4835 14.0488H25.5168C26.8236 14.0488 27.477 14.0488 27.9761 14.3031C28.4152 14.5269 28.7721 14.8838 28.9958 15.3229C29.2502 15.822 29.2502 16.4754 29.2502 17.7822V23.3822H32.6335C33.2869 23.3822 33.6136 23.3822 33.8632 23.5093C34.0827 23.6212 34.2612 23.7996 34.373 24.0192C34.5002 24.2687 34.5002 24.5954 34.5002 25.2488V35.0488H29.2502H18.7502H13.5002V25.2488C13.5002 24.5954 13.5002 24.2687 13.6273 24.0192C13.7392 23.7996 13.9177 23.6212 14.1372 23.5093C14.3867 23.3822 14.7134 23.3822 15.3668 23.3822Z"
        fill="#EBEAFF"
      />
      <path
        d="M18.7502 23.3822H15.3668C14.7134 23.3822 14.3867 23.3822 14.1372 23.5093C13.9177 23.6212 13.7392 23.7996 13.6273 24.0192C13.5002 24.2687 13.5002 24.5954 13.5002 25.2488V35.0488M29.2502 23.3822H32.6335C33.2869 23.3822 33.6136 23.3822 33.8632 23.5093C34.0827 23.6212 34.2612 23.7996 34.373 24.0192C34.5002 24.2687 34.5002 24.5954 34.5002 25.2488V35.0488M29.2502 35.0488V17.7822C29.2502 16.4754 29.2502 15.822 28.9958 15.3229C28.7721 14.8838 28.4152 14.5269 27.9761 14.3031C27.477 14.0488 26.8236 14.0488 25.5168 14.0488H22.4835C21.1767 14.0488 20.5233 14.0488 20.0242 14.3031C19.5851 14.5269 19.2282 14.8838 19.0045 15.3229C18.7502 15.822 18.7502 16.4754 18.7502 17.7822V35.0488M35.6668 35.0488H12.3335M22.8335 18.7155H25.1668M22.8335 23.3822H25.1668M22.8335 28.0488H25.1668"
        stroke="#4032E1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'MultiSiteIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 48 49'} {...props} />;
};
export default MultiSiteIcon;
