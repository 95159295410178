import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const GetStartedCnt = styled('div')({
  marginBottom: 35,
  '& .label': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28.8px',
    letterSpacing: '0.15px',
    marginBottom: 10,
    color: colors.black,
  },
  '& .actionsCnt': {
    width: '100%',
    margin: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '10px',
    '& .box': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
      borderRadius: 14,
      padding: '26px 10px 26px 10px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      height: 150.52,
      color: '#212121',
      cursor: 'pointer',
      border: '1px solid transparent',
      transition: '0.3s all',
      '&:hover': {
        transition: '0.3s all',
        border: '1px solid #6868FE',
      },
      '& .outerCnt': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 10,
      },
      '& .iconCnt': {
        width: 44.35,
        height: 44.35,
        borderRadius: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 8,
      },
      '& .workOrders': {
        background: '#66BB6A',
      },
      '& .checkList': {
        background: '#9575CD',
      },
      '& .templates': {
        background: '#EF5350',
      },
      '& .phone': {
        background: '#4FC3F7',
      },
      '& p': {
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '20.49px',
        letterSpacing: '-0.1px',
        color: '#212121',
      },
    },
  },
});
