import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import 'pages/Widgets/TaskListing/TaskListView/taskListSummary.style.css';
// AG Grid
import {
  defaultColDef,
  columns,
  getRowStyles,
} from 'pages/Widgets/TaskListing/TaskListView/constants';
import { SortChangedEvent } from 'ag-grid-community';

// Utils
import { useUpdateColumn } from 'utils/CustomHooks/useUpdateColumn';
import xeniaApi from 'api/index';
import { TData } from 'memfs/lib/volume';
import { TasksListSummaryCnt } from 'pages/Widgets/TaskListing/TaskListView/tasksListSummary.style';
import { WidgetHeading, WidgetLoaderCnt } from 'pages/Widgets/widgets.style';
import CircularProgress from '@mui/material/CircularProgress';
import TaskListWidgetEmptyState from 'pages/Widgets/TaskListing/TaskListView/EmptyRecords/TaskListWidgetEmptyState.cmp';
import { setDialog } from 'store/actions/dialogActions';
const TasksListSummary = ({ filters, reload, setReload }) => {
  const { updateColumns } = useUpdateColumn(null);
  const categoryList = useSelector(selectors.getServiceAdmin);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { taskColumns } = useSelector((state: any) => {
    return {
      taskColumns: state?.taskHotelData?.taskColumns,
    };
  });

  const fetchTaskList = async () => {
    // Fetch Report data
    setLoading(true);
    const requestPayload = {
      ...filters,
    };
    const data = await xeniaApi.getTasksList(requestPayload);
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchTaskList();
  }, [filters]);

  useEffect(() => {
    if (reload) {
      fetchTaskList();
    }
  }, [reload]);

  const onRowClick = (id) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: setReload ? () => setReload(true) : null,
          taskId: id,
        },
      }),
    );
  };

  //Event will be called when a sort will be changed
  const onSortChange = (event: SortChangedEvent<TData>) => {
    updateColumns(event.columnApi.getColumnState());
  };

  const colConfig = useMemo(
    () =>
      columns({
        categoryList,
      }),
    [taskColumns],
  );

  //Hiding last 2 options for columns menu
  const getMainMenuItems = (params) => {
    const newOptions = params.defaultItems.slice(
      0,
      params.defaultItems.length - 3,
    );
    return newOptions;
  };

  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);

  return (
    <TasksListSummaryCnt className={'taskListSummaryCnt'}>
      <WidgetHeading>Tasks & Work Orders</WidgetHeading>

      {loading && (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      )}
      {!loading && data?.length === 0 && <TaskListWidgetEmptyState />}
      {!loading && data?.length > 0 && (
        <CustomListView
          rootProps={{
            style: {
              height: 400,
              boxShadow: 'none',
              flex: 1,
            },
          }}
          onRowClick={onRowClick}
          gridProps={{
            ref: (ref) => {
              globalThis.taskSummaryListGrid = ref;
            },
            headerHeight: 30,
            onSortChanged: onSortChange,
            rowData: data,
            suppressChangeDetection: true,
            defaultColDef: defaultColDef,
            columnDefs: colConfig,
            suppressCellFocus: true,
            getMainMenuItems: getMainMenuItems,
            getRowHeight: getRowHeight,
            getRowStyle: getRowStyles,
            suppressRowClickSelection: true,
            getRowId: (params) => params.data.id,
          }}
        />
      )}
    </TasksListSummaryCnt>
  );
};

export default TasksListSummary;
