import Typography from '@mui/material/Typography';
import {
  DocumentItemCnt,
  Dot,
  ChecklistTileCnt,
} from 'pages/documents/listing/gridView/document/style';

import { Grid } from '@mui/material';
import DocumentActions from 'pages/documents/actionDropdowns/documentActionDropdown/documentActionDropdown';
import ArchivedDocumentActions from 'pages/documents/actionDropdowns/documentActionDropdown/archiveDocumentActionDropdown';
import { useMemo, useState } from 'react';

import sopIcon from 'components/Icons/sopIcon';
import excelIcon from 'components/Icons/excelIcon';

import pptIcon from 'components/Icons/pptIcon';
import wordIcon from 'components/Icons/wordIcon';
import pdfIcon from 'components/Icons/pdfIcon';
import { ActionItemIcon } from 'pages/documents/mainHeader/styles';

import moment from 'moment-timezone';
import { formatBytes } from 'utils/globalFunction';
import mime from 'mime';
import VideoUploadedIcon from 'components/Icons/videoUploadedIcon';
import { WORKSPACE_ACCESS } from 'components/DocumentAccess/constants';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getFileTypeFromExt } from 'xeniautils';
import { DraftChip } from 'components/common/jsxrender/checklistV2/checklistGrid/styled';
import { EditOutlined } from '@mui/icons-material';

const fileTypes = {
  pdf: {
    icon: pdfIcon,
    styles: {
      width: 36,
      height: 36,
      color: '#E53935',
    },
    bgColor: '#FAD7D7',
  },
  sop: {
    icon: sopIcon,
    styles: {
      width: 33,
      height: 33,
      color: '#6868FE',
    },
    bgColor: '#E1E1FF',
  },

  word: {
    icon: wordIcon,
    styles: {
      width: 36,
      height: 36,
      color: '#1E88E5',
    },
    bgColor: '#D2E7FA',
  },
  excel: {
    icon: excelIcon,
    styles: {
      width: 31,
      height: 31,
      color: '#43A047',
    },
    bgColor: '#D9ECDA',
  },
  ppt: {
    icon: pptIcon,
    styles: {
      width: 36,
      height: 36,
      color: '#F4511E',
    },
    bgColor: '#FDDCD2',
  },

  video: {
    icon: VideoUploadedIcon,
    styles: {
      width: 35,
      height: 26,
      color: '#D81B60',
    },
    bgColor: '#F7D1DF',
  },
};

function DocumentItem(props: any) {
  const {
    file,
    isArchive,
    documentDropdownProps,
    archiveDocumentDropdownProps,
    selectedFolder,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [type, setType] = useState<any>(null);
  const currentUserId: any = useSelector(selectors.getUserId);
  const isDropdownOpen = Boolean(anchorEl);

  const createdAtMoment = moment(file?.createdAt);
  const formattedDate = createdAtMoment.format('MMM D, YYYY h:mm A');

  const isSop = file?.fileType === 'sop';

  const openFileHandler = () => {
    if (isArchive) return;

    const queryParams = isSop
      ? !file?.FolderId
        ? '?source=list'
        : `?source=list&folderId=${file?.FolderId}`
      : '';
    const urlTab = `${window.location.origin}/document-detail/${file?.id}${queryParams}`;
    window.open(urlTab, isSop ? '_self' : '_blank');
  };

  const fileTypeData = useMemo(() => {
    if (Object?.keys(file?.meta)?.length > 0) {
      const ext = mime
        .getExtension(file?.meta?.headInfo?.['content-type'])
        ?.toString();

      const type = getFileTypeFromExt(ext);
      setType(type);
      return type ? fileTypes[type] : null;
    } else {
      setType('sop');
      return fileTypes['sop'];
    }
  }, [file]);

  const canEdit = useMemo(() => {
    const user = file?.DocumentAccess?.find(
      (user) => user?.AccessorId === currentUserId,
    );

    if (
      user?.canEdit ||
      file?.workspaceAccess === WORKSPACE_ACCESS.workspaceEdit
    ) {
      return true;
    }
    return false;
  }, [file]);

  return (
    <Grid item xs={12} sm={6} lg={3}>
      <DocumentItemCnt onClick={openFileHandler}>
        <div
          className="thumbnail"
          style={{
            backgroundColor: fileTypeData?.bgColor,
          }}
        >
          {type === 'image' && (
            <img src={file?.path} style={{ width: '100%' }} />
          )}
          {fileTypeData && (
            <ActionItemIcon svgdimensions={fileTypeData?.styles ?? null}>
              {<fileTypeData.icon />}
            </ActionItemIcon>
          )}
        </div>
        <div className="content">
          <div className="titleCnt">
            <Typography className="title">
              {['-', 'New Document']?.includes(file?.title)
                ? 'Untitled'
                : file?.title}
            </Typography>
            <ChecklistTileCnt isdropdownopen={isDropdownOpen ? 'true' : null}>
              {isArchive && canEdit && (
                <ArchivedDocumentActions
                  document={file}
                  {...archiveDocumentDropdownProps}
                />
              )}

              {!isArchive && canEdit && (
                <DocumentActions
                  setAnchorEl={setAnchorEl}
                  document={file}
                  selectedFolder={selectedFolder}
                  {...documentDropdownProps}
                />
              )}
            </ChecklistTileCnt>
          </div>
          <div className="subCnt">
            {file?.meta?.size && (
              <>
                <Typography className="subTitle">
                  {formatBytes(file?.meta?.size)}
                </Typography>
                <Dot>•</Dot>
              </>
            )}
            <Typography className="subTitle">{formattedDate}</Typography>
            {isSop && file?.isDraft && (
              <DraftChip
                icon={<EditOutlined />}
                size="small"
                label="Draft"
                color="success"
              />
            )}
          </div>
        </div>
      </DocumentItemCnt>
    </Grid>
  );
}

export default DocumentItem;
