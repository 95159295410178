import styled from '@mui/system/styled';

export const WidgetActionsCnt = styled('div')({
  borderRadius: 8,
  border: '1px solid #E0E0E0',
  background: '#fff',
  boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.06)',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
});
