import { Stack, styled } from '@mui/material';

export const MainCnt = styled(Stack)(() => ({
  '& .title': {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '27.32px',
    alignItems: 'center',
    color: '#252350',
    marginTop: -25,
  },
  '& .subTitle': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
    alignItems: 'center',
    color: '#000000',
    marginTop: -5,
  },
  '& .createBtn': {
    marginTop: 10,
    height: 40,
    borderRadius: 6,
  },
}));
