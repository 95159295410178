import React, { ReactNode, useContext } from 'react';

import { FieldWrapper, FieldName } from './styled';
import Stack from '@mui/material/Stack';
import CompletedInfo from 'components/TemplateSubmissionPreview/CompletedInfo';
import { DashboardContext } from 'pages/TemplateDashboard/context';
import CreateCorrectiveTask from '../CreateCorrectiveTask';
import { Divider, Typography } from '@mui/material';
import EditIconPencilBox from 'components/Icons/editIconPencilBox';
import CameraAltOutlined from '@mui/icons-material/CameraAltOutlined';
import TaskIconWithPlus from 'components/Icons/taskIconWithPlus';
import { DiscriptionCtn } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/styled';

interface FieldNameRendererPropTypes {
  fieldName: string;
  item: any;
  isAnswered?: boolean;
  children: ReactNode;
  templateLog?: any;
  preview?: boolean;
  hideCorrectiveTask?: boolean;
  isEmulatorView?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  template?: any;
}

const IconAndText = ({ icon, text }) => (
  <Stack
    sx={{
      cursor: 'pointer',
    }}
    direction={'row'}
    alignItems={'center'}
    gap="6px"
  >
    {icon}
    <Typography
      color="rgba(102, 101, 132, 1)"
      fontSize="10px"
      fontWeight={'500'}
    >
      {text}
    </Typography>
  </Stack>
);

const FieldNameRenderer = ({
  isAnswered = true,
  fieldName,
  children,
  item,
  templateLog,
  preview,
  hideCorrectiveTask,
  isEmulatorView,
  onCorrectiveTaskCreationCb,
  template,
}: FieldNameRendererPropTypes) => {
  const { template: _template } = useContext(DashboardContext) || {};

  const finalTemplate = template || _template;

  return (
    <FieldWrapper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // mb={isEmulatorView ? 0 : 1}
      >
        <FieldName
          emulator={isEmulatorView ? 'true' : ''}
          hideCorrectiveTask={hideCorrectiveTask || preview ? 'true' : ''}
        >
          {fieldName}
        </FieldName>
        {finalTemplate?.isShowTimestamp && item.completedAt && isAnswered && (
          <CompletedInfo user={item.Updater} time={item.completedAt} />
        )}
        {!preview && !hideCorrectiveTask && (
          <CreateCorrectiveTask
            item={item}
            answer={item?.answers?.value}
            templateLog={templateLog}
            visible={!(finalTemplate?.isShowTimestamp && item.completedAt)}
            onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
          />
        )}
      </Stack>
      {!isEmulatorView && item.info?.optionDescription && (
        <DiscriptionCtn sx={{ marginTop: '10px' }}>
          <p>
            <b>Instructions:</b>
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: item.info?.optionDescription,
            }}
          />
        </DiscriptionCtn>
      )}
      {children}
      {preview && isEmulatorView && (
        <Stack mt="8px" gap="8px">
          <Divider />
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <IconAndText
              icon={
                <EditIconPencilBox
                  sx={{ color: 'rgba(117, 113, 197, 1)', fontSize: '13px' }}
                />
              }
              text="Add Note"
            />
            <IconAndText
              icon={
                <CameraAltOutlined
                  sx={{ color: 'rgba(117, 113, 197, 1)', fontSize: '13px' }}
                />
              }
              text="Add Photo"
            />
            <IconAndText
              icon={
                <TaskIconWithPlus
                  sx={{
                    color: 'rgba(117, 113, 197, 1)',
                    fontSize: '13px',
                    fill: 'none',
                  }}
                />
              }
              text="Create Task"
            />
          </Stack>
        </Stack>
      )}
    </FieldWrapper>
  );
};

export default FieldNameRenderer;
