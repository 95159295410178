import { createSvgIcon } from '@mui/material';

const EyeBallIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        fill="currentColor"
      />
      <path
        d="M1.61293 8.47549C1.52214 8.33173 1.47674 8.25985 1.45133 8.14898C1.43224 8.06571 1.43224 7.93437 1.45133 7.8511C1.47674 7.74023 1.52214 7.66835 1.61293 7.52459C2.3632 6.3366 4.59644 3.33337 7.99978 3.33337C11.4031 3.33337 13.6364 6.3366 14.3866 7.52459C14.4774 7.66835 14.5228 7.74023 14.5482 7.8511C14.5673 7.93437 14.5673 8.06571 14.5482 8.14898C14.5228 8.25985 14.4774 8.33173 14.3866 8.47549C13.6364 9.66348 11.4031 12.6667 7.99978 12.6667C4.59645 12.6667 2.3632 9.66348 1.61293 8.47549Z"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99978 10C9.10435 10 9.99978 9.10461 9.99978 8.00004C9.99978 6.89547 9.10435 6.00004 7.99978 6.00004C6.89521 6.00004 5.99978 6.89547 5.99978 8.00004C5.99978 9.10461 6.89521 10 7.99978 10Z"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'EyeBallIcon',
  );
  return <IconCmp viewBox={'0 0 16 16'} {...props} />;
};

export default EyeBallIcon;
