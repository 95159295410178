// React
import { useState, useMemo } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// MUI Components
import Stack from '@mui/material/Stack';

// Custom Components
import ChecklistGridActionDropdown from './ChecklistGridActionDropdown';
import ChecklistIcon from './ChecklistIcon';
import UserAvatar from 'components/Avatar';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

// Icons
import { EditOutlined } from '@mui/icons-material';

// Styled Components
import {
  ChecklistTileCnt,
  Overlay,
  OverlayButton,
  ChecklistTitle,
  FirstRow,
  CreatedBy,
  EditedTime,
  ChipContainer,
  AvatarAndTextWrapper,
  StyledChip,
  DraftChip,
  ChipsRow,
  StyledPublicChip,
  ArchiveDropdownCnt,
} from './styled';

// Utilities
import DateTime from 'utils/DateTime';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { setDialog } from 'store/actions/dialogActions';
import xeniaApi from 'api/index';

// Hooks
import { useTemplateAccess } from 'controller/useTemplateAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';
import DIALOGS from 'utils/dialogIds';
import { flattenTemplateObject } from 'components/TemplateLibrary/utils';
import ArchiveTemplateActionDropdown from 'pages/Templates/actionDropdowns/templatsActionDropdown/archiveTemplateActionDropdown';

interface ChecklistTileCmpPropTypes {
  checklist: TemplateType;
  checklistScope: 'myTemplates' | 'publicTemplates';
  onStartButtonClick?: (checklistId: string) => void;
  checklists?: any;
  actionDropdownProps?: any;
  folder: any;
  isArchive?: any;
}

const ChecklistTileCmp = ({
  checklist,
  checklistScope,
  checklists,
  actionDropdownProps,
  folder,
  isArchive = false,
}: ChecklistTileCmpPropTypes) => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { PAYWALL_LIMITS } = useCustomPermission();
  const { isTemplateAdmin } = useTemplateAccess();

  const userProfile: any = useSelector(selectors.getUserProfile);
  const { id } = userProfile;

  const hotelId = useSelector(selectors.getUserHotelId);
  const timezone: string =
    useSelector(selectors.getUserHotelTz) || DEFAULT_TIMEZONE;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDropdownOpen = Boolean(anchorEl);

  const CHIPS = useMemo(
    () => [
      `${checklist?.totalLogsCount} Submissions`,
      `${checklist?.itemsCount} Steps`,
    ],
    [checklist],
  );

  const setModalState = (tab) => {
    dispatch(
      actions.setPreviewChecklistModalConfig({
        open: true,
        activeTab: tab,
        checklistScope,
      }),
    );
  };

  const togglePublishState = async () => {
    await xeniaApi.toggleChecklistPublishState(checklist.id);
    dispatch(
      actions.getChecklistListApiCall({
        detailed: true,
        folderId: folder || 'root',
      }),
    );
  };

  const handleStartChecklist = () => {
    if (checklist.isPublished) {
      navigateWithWorkspaceUrl(
        `/in-progress-checklist/${checklist?.id}?prevUrl=${`${
          folder ? `/checklist?folderId=${folder}` : '/checklist'
        }`}`,
      );
    } else {
      showStartConfirmation();
    }
  };

  const handleClickEditOrStartButton = () => {
    if (isTemplateAdmin(checklist)) {
      navigateWithWorkspaceUrl(`/checklist/${checklist?.id}`);
    } else {
      if (checklist.isPublished) {
        handleStartChecklist();
      } else {
        showStartConfirmation();
      }
    }
  };

  const handleClickPreviewButton = () => {
    if (isTemplateAdmin(checklist)) {
      navigateWithWorkspaceUrl(`/templates/${checklist?.id}`);
    } else {
      dispatch(actions.setPreviewChecklistData(checklist));
      setModalState('preview');
    }
  };

  //open template preview modal
  const handleOpenTemplatePreviewModal = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TEMPLATE_PREVIEW,
        open: true,
        data: {
          templates: flattenTemplateObject(checklists),
          selectedTemplate: checklist,
        },
      }),
    );
  };

  const handleEdit = () => {
    navigateWithWorkspaceUrl(`/checklist/${checklist?.id}`);
    dispatch(actions.closePreviewChecklistModal());
  };

  const handleArchive = () => {
    dispatch(actions.archiveChecklistApiCall(checklist.id, folder));
  };

  const showStartConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Publish Template',
          description:
            'In order to Start or Assign/Schedule this template you must Publish it.',
          confirmationText: 'Go to Builder',
          onConfirmCallback: () => {
            navigateWithWorkspaceUrl(`/checklist/${checklist?.id}`);
          },
        },
      }),
    );
  };

  const handleClickAssignButton = () => {
    if (checklist.isPublished) {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createEditTask',
          data: {
            taskData: {
              checklistId: checklist?.id,
            },
          },
        }),
      );
      dispatch(actions.closePreviewChecklistModal());
    } else {
      showStartConfirmation();
    }
  };

  const handleClickDropdownIcon = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleDuplicate = () => {
    dispatch(actions.duplicateChecklistApiCall(checklist?.id, false));
  };

  return (
    <ChecklistTileCnt isdropdownopen={isDropdownOpen ? 'true' : null}>
      {isArchive ? (
        <ArchiveDropdownCnt>
          <ArchiveTemplateActionDropdown
            template={checklist}
            deleteTemplateCallback={actionDropdownProps.deleteTemplateCallback}
          />
        </ArchiveDropdownCnt>
      ) : (
        <Overlay
          isdropdownopen={isDropdownOpen ? 'true' : null}
          className="checklist-preview-modal-overlay"
        >
          <VerifyFeatureAccess
            paywallUsageId={PAYWALL_LIMITS.OPERATIONS_TEMPLATES}
          >
            <div className={'overlayBtnsCnt'}>
              <OverlayButton
                onClick={handleClickPreviewButton}
                variant="contained"
              >
                View
              </OverlayButton>
              {checklist?.HotelChecklist?.isEditable && (
                <OverlayButton
                  onClick={handleClickEditOrStartButton}
                  variant={'outlined'}
                  buttonType={'white'}
                >
                  {isTemplateAdmin(checklist) ? 'Edit' : 'Start'}
                </OverlayButton>
              )}
            </div>
          </VerifyFeatureAccess>

          {isTemplateAdmin(checklist) && (
            <ChecklistGridActionDropdown
              popperProps={{
                open: isDropdownOpen,
                anchorEl: anchorEl,
              }}
              buttonProps={{
                onClick: handleClickDropdownIcon,
              }}
              folder={folder}
              setAnchorEl={setAnchorEl}
              editChecklist={handleEdit}
              startChecklist={handleStartChecklist}
              assignChecklist={handleClickAssignButton}
              archiveChecklist={handleArchive}
              duplicateChecklist={handleDuplicate}
              togglePublishState={togglePublishState}
              {...actionDropdownProps}
              checklist={checklist}
            />
          )}
        </Overlay>
      )}

      <FirstRow>
        <ChecklistIcon icon={checklist.icon} sx={{ fontSize: '1rem' }} />
      </FirstRow>
      <ChecklistTitle>{checklist.name}</ChecklistTitle>
      <ChipContainer>
        {CHIPS.map((chip, index) => (
          <StyledChip
            size="small"
            key={`${checklist.id}-${index}`}
            label={chip}
          />
        ))}
      </ChipContainer>

      <AvatarAndTextWrapper>
        <UserAvatar
          userId={checklist.CreatedBy}
          firstName={checklist.Creator?.firstName || ''}
          width={20}
          height={20}
        />

        <Stack>
          <CreatedBy>
            {'Created by '}
            {checklist?.Creator?.id === id
              ? 'you'
              : checklist?.Creator?.firstName || ''}
          </CreatedBy>
          <EditedTime>
            Created{' '}
            {DateTime.fromUTCString(checklist.createdAt).fromNow(timezone)}
          </EditedTime>
        </Stack>
        {!checklist?.isPublished && (
          <DraftChip
            icon={<EditOutlined />}
            size="small"
            label="Draft"
            color="success"
          />
        )}
      </AvatarAndTextWrapper>
    </ChecklistTileCnt>
  );
};

export default ChecklistTileCmp;
