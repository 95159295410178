// React
import { useEffect, useState, useCallback } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Constants
import { formatFilters } from 'utils/Task/filters';
import { getProjects } from 'api/projectsApi';
import { cloneDeep } from 'lodash';

export const useProjects = (pageSize = 100, customPayload = {}) => {
  const limit = pageSize || 100;
  const dispatch = useDispatch();

  const [data, setData] = useState<any>(null);
  const [completeData, setCompleteData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [advanceFilters, setAdvanceFilters] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    fetchData();
  }, [advanceFilters]);

  const searchTermApiCall = (searchedTerm) => {
    const searchedData = completeData?.filter((project) =>
      project?.title?.toLowerCase()?.includes(searchedTerm?.toLowerCase()),
    );
    setData(searchedData);
    setNoResults(searchedTerm ? searchedData?.length === 0 : false);
  };

  const handleChangeSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    searchTermApiCall(value);
  };

  const removeProject = (projectId) => {
    const updatedCompleteList = completeData?.filter(
      (item) => item?.id !== projectId,
    );
    setCompleteData(updatedCompleteList);

    const updatedData = data?.filter((item) => item?.id !== projectId);
    setData(updatedData);
  };

  const updateProject = (updatedProject) => {
    let clonedCompleteData = cloneDeep(completeData);
    let clonedData = cloneDeep(data);

    clonedCompleteData = clonedCompleteData?.map((item) => {
      if (item?.id === updatedProject?.id) {
        return updatedProject;
      }

      return item;
    });

    clonedData = clonedData?.map((item) => {
      if (item?.id === updatedProject?.id) {
        return updatedProject;
      }

      return item;
    });

    setCompleteData(clonedCompleteData);
    setData(clonedData);
  };

  // load more
  const handleLoadNextPage = async (offset: number) => {
    const paginationData = {
      offset,
      limit,
    };
    setIsNextPageLoading(true);
    let result: any = null;
    const postData = {
      advanceFilters: advanceFilters ? formatFilters(advanceFilters) : {},
      ...(searchTerm && { searchText: searchTerm }),
      ...paginationData,
      ...customPayload,
    };

    result = await getProjects(postData);
    const prevData = data ?? [];
    const newRows = result ?? [];
    setData([...prevData, ...newRows]);
    setTotalCount(result?.count);
    setIsNextPageLoading(false);
  };

  const fetchData = async (searchText = '') => {
    const paginationData = {
      offset: 0,
      limit,
    };
    setLoading(true);
    let result: any = null;

    const postData = {
      advanceFilters: advanceFilters ? formatFilters(advanceFilters) : {},
      ...(searchText && {
        searchText: searchText,
      }),
      ...paginationData,
      ...customPayload,
    };

    result = await getProjects(postData);

    const newRows = result ?? [];
    setData(newRows);
    setCompleteData(newRows);
    setTotalCount(result?.count);
    setLoading(false);
    setNoResults(searchText || advanceFilters ? result?.count === 0 : false);
  };

  const handleClickScheduleItem = (task) => {
    // If on mobile app then send a message to RN WebView //
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          navigateTo: 'TaskDetail',
          data: task,
        }),
      );
      return;
    }

    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: fetchData,
          taskId: task.id,
        },
      }),
    );
  };

  return {
    data,
    advanceFilters,
    searchTerm,
    totalCount,
    isNextPageLoading,
    loading,
    noResults,
    setSearchTerm,
    handleLoadNextPage,
    fetchData,
    setAdvanceFilters,
    handleChangeSearch,
    handleClickScheduleItem,
    removeProject,
    updateProject,
  };
};
