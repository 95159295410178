import { useEffect, useReducer, useState } from 'react';
import DocumentsTabs from 'pages/documents/tabs';
import PageHeader from 'pages/documents/mainHeader';
import { DocumentsContext } from 'pages/documents/context/context';
import { getItem } from 'utils/globalFunction';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import useMeasure from 'react-use-measure';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { PERMISSIONS } from 'utils/constants';

// Hide / Show Top Menu
const headerState = getItem('hideDocumentsHeader');
const headerStateValueParsed = headerState != 'true';

// Documents State Interface
interface IMyDocumentsState {
  loading: boolean;
  data: any;
  selectedFolder: any;
  searchTerm: string;
  selectedView: any;
  isArchive: any;
  sortBy: string;
  order: string;
}

// Initial State
const initialState = {
  loading: true,
  selectedView: 'grid',
  data: {
    documents: [],
    folders: [],
  },
  selectedFolder: '',
  searchTerm: '',
  isArchive: false,
  sortBy: 'createdAt',
  order: 'desc',
};

const Documents = () => {
  const [state, updateState] = useReducer(
    (prevState: IMyDocumentsState, value): IMyDocumentsState => {
      return { ...prevState, ...value };
    },
    initialState,
  );
  const [showPageHeader, setShowPageHeader] = useState(headerStateValueParsed);
  const { getFoldersDocuments } = useDocumentsFolders();
  const [ref, bounds] = useMeasure();

  const { hasPermission } = useCustomPermission();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_VIEW_SOP_AND_FILES)) {
      navigateWithWorkspaceUrl('/dashboard');
    }
  }, []);

  useEffect(() => {
    updateState({
      mainHeadHeight: bounds?.height,
    });
  }, [bounds]);

  // Add Folder in the List
  const addFolder = (folder) => {
    const updatedFolders = [folder, ...state.data.folders];
    updateState({
      data: {
        ...state.data,
        folders: updatedFolders,
      },
    });
  };

  // Add Document in the List
  const addDocument = (document) => {
    const updatedDocuments = [document, ...state.data.documents];
    updateState({
      data: {
        ...state.data,
        documents: updatedDocuments,
      },
    });
  };

  // Update Folder Content
  const updateFolder = (newData) => {
    const updatedFolders = state?.data?.folders?.map((item) => {
      if (item.id === newData?.id) {
        return {
          ...item,
          ...newData,
        };
      }
      return item;
    });

    updateState({
      data: {
        ...state.data,
        folders: updatedFolders,
      },
    });
  };

  // Delete Folder By ID
  const deleteFolder = (id) => {
    const updatedFolders = state?.data?.folders?.filter(
      (item) => item.id != id,
    );

    updateState({
      data: {
        ...state.data,
        folders: updatedFolders,
      },
    });
  };

  // Delete Document By ID
  const deleteDocument = (id) => {
    const updatedDocuments = state?.data?.documents?.filter(
      (item) => item.id != id,
    );

    updateState({
      data: {
        ...state.data,
        documents: updatedDocuments,
      },
    });
  };

  // Update Document Content
  const updateDocument = (newData) => {
    const updatedDocuments = state?.data?.documents?.map((item) => {
      if (item.id === newData?.id) {
        return {
          ...item,
          ...newData,
        };
      }
      return item;
    });

    updateState({
      data: {
        ...state.data,
        documents: updatedDocuments,
      },
    });
  };

  // Add Document To Folder
  const onAddDocumentToFolder = (folderId, documentId) => {
    const updatedDocuments = state?.data?.documents?.filter(
      (item) => item.id !== documentId,
    );

    const updatedFolders = state?.data?.folders?.map((item) => {
      if (item.id === folderId) {
        return {
          ...item,
          itemsCount: item.itemsCount + 1,
        };
      }
      return item;
    });

    updateState({
      data: {
        ...state.data,
        folders: updatedFolders,
        documents: updatedDocuments,
      },
    });
  };

  const getFolderAndDocuments = async (newData = {}, loading = true) => {
    {
      loading &&
        updateState({
          loading: true,
        });
    }

    const data = await getFoldersDocuments({
      folderId: state?.selectedFolder?.id || null,
      archived: state?.isArchive ? state?.isArchive : null,
      sortBy: state?.sortBy,
      order: state?.order,
      ...newData,
    });

    const transformedFolders = data?.folders?.map((item) => {
      return {
        ...item,
        isFolder: true,
      };
    });

    updateState({
      loading: false,
      data: {
        ...data,
        folders: transformedFolders,
      },
    });
  };

  return hasPermission(PERMISSIONS.CAN_VIEW_SOP_AND_FILES) ? (
    <DocumentsContext.Provider
      value={{
        state,
        updateState,
        addFolder,
        addDocument,
        updateFolder,
        deleteFolder,
        updateDocument,
        deleteDocument,
        onAddDocumentToFolder,
        getFolderAndDocuments,
      }}
    >
      <div ref={ref}>
        {showPageHeader && <PageHeader setShowPageHeader={setShowPageHeader} />}
      </div>
      <DocumentsTabs />
    </DocumentsContext.Provider>
  ) : null;
};

export default Documents;
