/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

export interface AnchorProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  datatestid?: string;
  id?: string;
  style?: Record<string, string | number>;
  title?: string;
  href?: string;
}

export interface ButtonProps {
  type: Btntype;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  datatestid?: string;
  id?: string;
  style?: Record<string, string | number>;
  datatoggle?: string;
  datatarget?: string;
  datadismiss?: string;
}

export enum Btntype {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export interface ImagesProps {
  className?: string;
  src?: string;
  alt?: string;
  datatestid?: string;
  id?: string;
  datatip?: string;
  style?: Record<string, string | number>;
  title?: string;
  width?: string;
  height?: string;
  onClick?: any;
}

export interface UserStatusProps {
  className?: string;
  datatestid?: string;
  id?: string;
  style?: Record<string, string | number>;
  title?: string;
}

export type UserStatusCountProps = UserStatusProps;
export type TaskStatustProps = UserStatusProps;

export enum Inputtype {
  TEXT = 'text',
  FILE = 'file',
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export interface InputProps {
  type: Inputtype;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  name?: string;
  datatestid?: string;
  autoComplete?: string;
  id?: string;
  placeholder?: string;
  value?: string;
  style?: Record<string, string | number>;
  defaultValue?: string;
  checked?: boolean;
}

export interface TextAreaProps {
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  name?: string;
  datatestid?: string;
  id?: string;
  placeholder?: string;
  value?: string;
  style?: Record<string, string | number>;
  autoFocus?: boolean;
}

export interface SelectProps {
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
  name?: string;
  datatestid?: string;
  id?: string;
  style?: Record<string, string | number>;
  data: any | undefined;
  valueKey: string;
  displayKey: string;
  placeholder?: string;
  defaultValue?: string;
  extraOption?: Record<string, string | number> | null;
}

export interface BootstrapModelProps {
  className?: string;
  datatestid?: string;
  id?: string;
  handleClose;
  isShow;
  animation;
  arialabelledby;
  centered;
  style?: Record<string, string | number>;
  headerData?: string | ReactElement<any, any>;
  bodyData?: string | ReactElement<any, any>;
  headerHtml?: string | ReactElement<any, any>;
  backdrop?: any;
  // closeButton?: boolean;
  showCloseBtn?: boolean;
  keyboard?: any;
  footer?: any;
  size?: any;
  container?: any;
  contentClassname?: string;
}

export interface LoaderProps {
  count: any;
  height: any;
  loop?: any;
}

export interface FlatFileProps {
  embedType: string;
}

export interface BathroomCounterTypeProps {
  className?: string;
}

export interface SlideArrowCounterTypeProps {
  className?: string;
}

export interface RoomWrapTypeProps {
  className?: string;
  key?: any;
}
