import React, { FC } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { ActionsItemsCnt, ItemContainer } from './style';
import ActionDropdown from 'components/Dropdown/ActionDropdown';

interface IProps {
  tabs: string[];
  currentTab?: any;
  editingTab?: any;
  newLabel?: any;
  onTabClick?: any;
  onEditChange?: any;
  onKeyPress?: any;
  onBlur?: any;
  onOptionSelect?: any;
  actionDropdownOptions?: any;
}

const MoreItemsContent: FC<IProps> = ({
  tabs = [],
  currentTab,
  editingTab,
  newLabel,
  onTabClick,
  onEditChange,
  onKeyPress,
  onBlur,
  onOptionSelect,
  actionDropdownOptions,
}) => {
  return (
    <ActionsItemsCnt>
      <Grid container direction="row" alignItems="center" gap="8px">
        {tabs?.map((tab) => (
          <React.Fragment key={tab}>
            {editingTab === tab ? (
              <CustomInput
                label=""
                variant="outlined"
                muiLabel={false}
                fieldProps={{
                  placeholder: 'Enter view name',
                  value: newLabel,
                  autoFocus: true,
                  onChange: onEditChange,
                  onKeyPress: onKeyPress,
                  style: { height: 33 },
                  onBlur: onBlur,
                }}
              />
            ) : (
              <ItemContainer
                onClick={() => onTabClick(tab)}
                style={{
                  ...(currentTab === tab && {
                    borderBottom: '2px solid #6868FE',
                  }),
                }}
              >
                <Box className="iconAndContent">
                  <ListIcon
                    style={{ fontSize: 18 }}
                    sx={{
                      '& path': {
                        fill: '#616161ab',
                        ...(currentTab === tab && {
                          fill: '#000000ab',
                        }),
                      },
                    }}
                  />
                  <Typography
                    className="label"
                    style={{
                      ...(currentTab === tab && {
                        fontWeight: 700,
                        textDecoration: 'none',
                      }),
                    }}
                  >
                    {tab?.length > 14 ? tab?.slice(0, 14) + '...' : tab}
                  </Typography>
                </Box>
                <ActionDropdown
                  options={actionDropdownOptions}
                  handleOptionSelect={(option) => onOptionSelect(option, tab)}
                  iconButton={false}
                  buttonProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      padding: 0,
                      minWidth: 'unset',
                    },
                    sx: {
                      '& svg': {
                        color: '#424242b5',
                      },
                    },
                  }}
                  popperProps={{
                    placement: 'bottom-start',
                    sx: {
                      zIndex: 1301,
                    },
                  }}
                  paperProps={{
                    style: {
                      minWidth: 120,
                    },
                  }}
                >
                  <MoreHorizIcon />
                </ActionDropdown>
              </ItemContainer>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </ActionsItemsCnt>
  );
};

export default MoreItemsContent;
