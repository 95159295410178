import styled from '@mui/system/styled';

export const AssetsHeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 20,
  },
}));
