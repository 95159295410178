import { CheckListIconBox, MainCnt, Value, ValueDraft } from './styled';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import moment from 'moment-timezone';
import TemplateActions from './renderers/actions/actions';
import { DraftChip } from 'components/common/jsxrender/checklistV2/checklistGrid/styled';
import { EditOutlined } from '@mui/icons-material';

export const rowHeight = 52;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};

const MuiIcon = ({ name, ...rest }) => {
  const IconComponent = iconToComponentMapping[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};

// Columns listing

export const columns: any = (props) => {
  return [
    {
      field: 'name',
      headerName: 'Template Name',
      colId: 'name',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      // minWidth: 400,
      flex: 3,
      cellRenderer: cellRenderer.name,
    },
    {
      field: 'logsCount',
      headerName: 'Number of Submissions',
      colId: 'logsCount',
      // minWidth: 150,
      // width: 265,
      flex: 1,
      suppressMovable: true,
      suppressMenu: true,
      valueGetter: valueGetters.submissionsCount,
      sortable: true,
      cellRenderer: cellRenderer.submissionsCount,
    },
    {
      field: 'lastSubmittedDate',
      headerName: 'Last Submitted',
      colId: 'lastSubmittedDate',
      // minWidth: 150,
      // width: 265,
      flex: 1,
      valueGetter: valueGetters.lastSubmittedDate,
      cellRenderer: cellRenderer.lastSubmittedDate,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },

    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      width: 140,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: 'disableRowClick',
      cellRenderer: (params) => cellRenderer.actionColumn(params, props),
    },
  ];
};

// Cell Renderer
export const cellRenderer = {
  name: (params) => {
    if (!params?.data) return;
    const { name, icon, isPublished } = params.data;
    return (
      <MainCnt>
        <CheckListIconBox
          style={{
            backgroundColor: icon?.color,
            borderRadius: 4,
            marginRight: 10,
          }}
        >
          <MuiIcon name={icon?.icon} style={{ height: 15, width: 15 }} />
        </CheckListIconBox>
        <ValueDraft>
          <Value className="template-name" style={{ fontWeight: 700 }}>
            {name}
          </Value>
          {!isPublished && (
            <DraftChip
              icon={<EditOutlined style={{ fontSize: 12 }} />}
              size="small"
              label="Draft saved"
              color="success"
              sx={{ padding: '1px 3px' }}
            />
          )}
        </ValueDraft>
      </MainCnt>
    );
  },
  itemsCount: (params) => {
    if (!params?.data) return;
    const { itemsCount } = params.data;
    return (
      <MainCnt>
        <Value>{itemsCount}</Value>
      </MainCnt>
    );
  },
  submissionsCount: (params) => {
    if (!params?.data) return;
    const { logsCount } = params.data;
    return (
      <MainCnt>
        <Value>{logsCount}</Value>
      </MainCnt>
    );
  },

  lastSubmittedDate: (params) => {
    if (!params?.data) return;
    const { lastSubmittedDate } = params.data;
    const startOfToday = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    function isToday(momentDate) {
      return momentDate.isSame(startOfToday, 'd');
    }
    function isYesterday(momentDate) {
      return momentDate.isSame(yesterday, 'd');
    }

    const humanizedDate = isToday(moment(lastSubmittedDate))
      ? 'Today'
      : isYesterday(moment(lastSubmittedDate))
      ? 'Yesterday'
      : moment(lastSubmittedDate).format('MMMM DD, YYYY');

    return (
      <MainCnt>
        <Value>{lastSubmittedDate ? `${humanizedDate}` : `-`}</Value>
      </MainCnt>
    );
  },
  actionColumn: (params, props) => {
    if (!params?.data) return;
    return (
      <TemplateActions
        disabled={props.downloading}
        handleDownloadReportApi={props.handleDownloadReportApi}
        template={params?.data}
      />
    );
  },
};

// Value Getters

export const valueGetters = {
  name: (params) => {
    const { name } = params.data;
    return name || '-';
  },
  itemsCount: (params) => {
    const { itemsCount } = params.data;
    return itemsCount;
  },
  submissionsCount: (params) => {
    const { logsCount } = params.data;
    return logsCount || 0;
  },
  templateAdmins: (params) => {
    const { templateAdmins } = params.data;
    return templateAdmins || '-';
  },
  createdBy: (params) => {
    const { Creator } = params.data;
    return Creator?.firstName || '-';
  },
  createdOn: (params) => {
    const { createdAt } = params.data;
    return createdAt || '-';
  },
  lastSubmittedDate: (params) => {
    const { lastSubmittedDate } = params.data;
    return lastSubmittedDate || '-';
  },
};
