import { useCallback, useMemo, useEffect } from 'react';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';

// AG Grid
import { defaultColDef, columns, getRowStyles } from './constants';

// Styles
import './taskListSummary.style.css';

const defaultColumnConfig = {
  taskNumber: true,
  title: true,
  assignee: true,
  taskStatus: true,
  location: true,
  asset: true,
  recurringByEvery: true,
  createdAt: true,
  dueTime: true,
  ServiceType: true,
  Checklist: true,
  priority: true,
  timeSpent: true,
  description: true,
  cost: true,
  startTime: true,
  endTime: true,
  CompletedBy: true,
  actionCol: true,
};

interface IProps {
  height?: number | string;
  rowData: any;
  tableId?: string;
  onRowClick?: (taskId: string) => void;
  onColumnChange?: (columns: any) => void;
  onBulkSelection?: (selected: any[]) => void;
  onTaskActionSuccess?: () => void;
  updateColumnStateCb?: (columnState: any) => void;
  columnState?: any;
  isSizeColumnFit?: boolean;
  columnConfig?: {
    taskNumber?: boolean;
    title?: boolean;
    assignee?: boolean;
    taskStatus?: boolean;
    location?: boolean;
    asset?: boolean;
    recurringByEvery?: boolean;
    createdAt?: boolean;
    dueTime?: boolean;
    ServiceType?: boolean;
    cost?: boolean;
    Checklist?: boolean;
    priority?: boolean;
    timeSpent?: boolean;
    description?: boolean;
    startTime?: boolean;
    endTime?: boolean;
    CompletedBy?: boolean;
    actionCol?: boolean;
  };
}

const TaskAndWorkOrderTable = ({
  height = 400,
  rowData,
  tableId,
  onRowClick,
  onBulkSelection,
  onTaskActionSuccess,
  updateColumnStateCb,
  columnConfig,
  columnState,
  isSizeColumnFit,
}: IProps) => {
  const getMainMenuItems = (params) => {
    const newOptions = params.defaultItems.slice(
      0,
      params.defaultItems.length - 3,
    );
    return newOptions;
  };

  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);

  const onSelectionChanged = (event) => {
    const selected = event.api.getSelectedNodes();
    onBulkSelection?.(selected);
  };

  const onColumnResized = (event) => {
    if (event?.finished && event.source !== 'api') {
      updateColumnStateCb?.(event.columnApi.getColumnState());
    }
  };

  const handleUpdateColumnState = (event) => {
    if (
      event.type === 'dragStopped' ||
      event.type === 'sortChanged' ||
      event.type === 'columnVisible'
    ) {
      updateColumnStateCb?.(event.columnApi.getColumnState());
    }
  };

  const gridColumns = useMemo(() => {
    const staticColumns = columns({
      onRowClick,
      onTaskActionSuccess,
      columnConfig: {
        ...defaultColumnConfig,
        ...columnConfig,
      },
    });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = columnState?.find((c) => c.colId === col.colId);
      const index = columnState?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [columnState, onRowClick, onTaskActionSuccess, columnConfig, rowData]);

  useEffect(() => {
    if (tableId) {
      globalThis?.[tableId]?.current?.refreshCells();
      return;
    }

    globalThis?.taskAndWorkOrdersTable?.current?.refreshCells();
  }, [rowData]);

  return (
    <CustomListView
      rootProps={{
        className: 'task-and-work-orders-table',
        style: {
          height,
          boxShadow: 'none',
          flex: 1,
        },
      }}
      onRowClick={onRowClick}
      gridProps={{
        ref: (ref) => {
          if (tableId) {
            globalThis[tableId] = ref;
            return;
          }
          globalThis.taskAndWorkOrdersTable = ref;
        },
        onGridReady: (params) => {
          isSizeColumnFit && params.api.sizeColumnsToFit();
        },
        headerHeight: 30,
        onSortChanged: handleUpdateColumnState,
        onDragStopped: handleUpdateColumnState,
        onColumnResized: onColumnResized,
        onColumnVisible: handleUpdateColumnState,
        onColumnPinned: handleUpdateColumnState,
        onColumnMoved: handleUpdateColumnState,
        rowData,
        suppressChangeDetection: true,
        defaultColDef: defaultColDef,
        columnDefs: gridColumns,
        suppressCellFocus: true,
        getMainMenuItems: getMainMenuItems,
        getRowHeight: getRowHeight,
        getRowStyle: getRowStyles,
        suppressRowClickSelection: true,
        getRowId: (params) => params.data.id,
        rowSelection: 'multiple',
        onSelectionChanged,
        checkboxSelection: true,
      }}
    />
  );
};

export default TaskAndWorkOrderTable;
