import React from 'react';

import { Stack, Typography } from '@mui/material';

interface LayoutPropTypes {
  children: React.ReactNode;
  leftText: {
    heading: string;
    description: string;
  };
  margin: string;
  supressBorder?: boolean;
}

const Layout = ({
  children,
  leftText: { heading, description },
  margin,
  supressBorder,
}: LayoutPropTypes) => {
  return (
    <Stack
      borderBottom={supressBorder ? '' : '1px solid rgba(238, 238, 238, 1)'}
      paddingBottom={'20px'}
      margin={margin}
      direction={'row'}
      gap={'50px'}
    >
      <Stack gap={'2px'} width={'300px'}>
        <Typography fontSize={14} fontWeight={700} color={'#212121'}>
          {heading}
        </Typography>
        <Typography fontSize={12} fontWeight={400} color={'#616161'}>
          {description}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default Layout;
