import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LocationNameCnt } from './style';
import { getAuthLocationsHash } from 'store/selectors/locations';

const LocationAttached = ({ location }) => {
  const authLocationsHash = useSelector(getAuthLocationsHash);

  const parentLocation = useMemo(() => {
    if (location?.ParentId) {
      const parentNode = authLocationsHash?.[location?.ParentId];
      return parentNode?.authorized ? parentNode : null;
    }

    return null;
  }, [location?.ParentId]);

  return (
    <LocationNameCnt isParent={parentLocation?.name}>
      <div className="content">
        <span className={'locationName'}> {location?.name}</span>
        {parentLocation?.name && (
          <span className="parentLocationName"> {parentLocation?.name}</span>
        )}
      </div>
    </LocationNameCnt>
  );
};

export default LocationAttached;
