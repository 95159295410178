/* eslint-disable prettier/prettier */
import OnBoardingContainer from './OnBoardingContainer';
import { OnboardingStateProvider } from './onboarding-provider';

const OnBoardingWrapper = () => {
  return (
    <OnboardingStateProvider>
      <OnBoardingContainer />
    </OnboardingStateProvider>
  );
};
export default OnBoardingWrapper;
