import React, { useState, useMemo, useContext } from 'react';

// Custom components
import SearchBar from '../../SearchBar';
import CustomButton from 'components/Button/CustomButton';

// Third party
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Icons
import ColumnsIcon from 'components/Icons/columnsIcon';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';

// Styled
import {
  Column,
  Header,
  SearchAndShowAll,
  AllCheckbox,
  StyledCheckbox,
  List,
  ListItem,
  Footer,
} from './styled';

// Context
import { SpreadsheetContext } from 'pages/TemplateDashboard/Tabs/Spreadsheet/context';

const DropdownContent = () => {
  const context = useContext(SpreadsheetContext);
  const { template, columnsConfig, setColumnsConfig, editColumnConfig } =
    context;
  const [searchTerm, setSearchTerm] = useState('');

  const showAll = useMemo(
    () => (columnsConfig ?? []).every((col) => col.visible),
    [columnsConfig],
  );

  const currentColumns = useMemo(
    () =>
      searchTerm
        ? columnsConfig.filter((conf) =>
            conf.description.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : columnsConfig,
    [columnsConfig, searchTerm],
  );

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(columnsConfig ?? []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newConfig = await editColumnConfig?.(items);
    setColumnsConfig?.(newConfig);
  };

  const handleSearch = (term: string) => {
    if (!term) {
      setSearchTerm('');
    } else {
      setSearchTerm(term);
    }
  };

  const handleClickShowAll = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let newColumns = [...(columnsConfig ?? [])];
    const visible = event.target.checked;
    newColumns = newColumns.map((col) => ({ ...col, visible }));
    const newConfig = await editColumnConfig?.(newColumns);
    setColumnsConfig?.(newConfig);
  };

  const handleToggleColumnCheckbox = async (
    event: React.ChangeEvent<HTMLInputElement>,
    colId: string,
  ) => {
    let newColumns = [...(columnsConfig ?? [])];
    const visible = event.target.checked;
    newColumns = newColumns.map((col) => {
      if (col.id === colId) {
        return {
          ...col,
          visible,
        };
      }
      return col;
    });
    const newConfig = await editColumnConfig?.(newColumns);
    setColumnsConfig?.(newConfig);
  };

  const handleResetColumns = async () => {
    const newConfig = template?.ChecklistItems?.filter(
      (item) => item.isActive,
    ).map((item) => ({
      id: item.id,
      description: item.description,
      type: item.type,
      visible: true,
    }));
    const result = await editColumnConfig?.(newConfig);
    setColumnsConfig?.(result);
  };

  return (
    <Column>
      <Header>
        <ColumnsIcon
          sx={{
            color: 'transparent',
            '& rect': {
              stroke: 'rgba(89, 89, 89, 1) !important',
              strokeWidth: '1.1px',
            },
          }}
        />
        <p className="heading">Show Hide Columns</p>
      </Header>
      <SearchAndShowAll>
        <SearchBar width="100%" onSearch={handleSearch} />
        <div className="showAll">
          <AllCheckbox checked={showAll} onChange={handleClickShowAll} />
          <p className="showAllText">Show All</p>
        </div>
      </SearchAndShowAll>
      <List>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="spreadsheet-columns">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {currentColumns.map((column, index) => (
                  <Draggable
                    key={column.id}
                    draggableId={column.id}
                    index={index}
                  >
                    {(provided) => (
                      <ListItem
                        active={''}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DragIndicatorRoundedIcon className="icon" />
                        <div className="columnNameWrapper">
                          <StyledCheckbox
                            checked={column.visible}
                            onChange={(e) =>
                              handleToggleColumnCheckbox(e, column.id)
                            }
                          />
                          <span className="columnNameText">
                            {column.description}
                          </span>
                        </div>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
      <Footer>
        <CustomButton
          fullWidth
          sx={{ borderRadius: '8px', height: '30px' }}
          variant="contained"
          startIcon={
            <AutorenewRoundedIcon sx={{ fontSize: '18px !important' }} />
          }
          onClick={handleResetColumns}
        >
          Reset Columns
        </CustomButton>
      </Footer>
    </Column>
  );
};

export default DropdownContent;
