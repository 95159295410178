// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const PageWrapper = styled(Box)(() => ({
  marginTop: 0,
  padding: '0px 10px 0px 50px',
}));

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});
