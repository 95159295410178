import React from 'react';
import MyProfileForm from 'pages/my-settings/myProfileForm';
import ChangePasswordForm from 'pages/my-settings/changePasswordForm';

const MyProfileTab = ({ userProfileData, picCdnPath, setPicCdnPath }) => {
  return (
    <>
      <MyProfileForm
        userProfileData={userProfileData}
        picCdnPath={picCdnPath}
        setPicCdnPath={setPicCdnPath}
      />
      <ChangePasswordForm userProfileData={userProfileData} />
    </>
  );
};

export default MyProfileTab;
