import { FunctionComponent } from 'react';
import { ReadContext } from './readReceiptContextProvider';
import CloseIcon from '@mui/icons-material/Close';
import { ReadReceiptSidebarContainer } from './readReceiptSidebar.style';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import UserAvatar from 'components/Avatar';
import { useSelector } from 'react-redux';
import { getUserHotelTz } from 'store/selectors/auth';
import useDateTime from 'utils/CustomHooks/useDateTime';

const ReadReceiptSidebar: FunctionComponent = () => {
  const tz = useSelector(getUserHotelTz);
  const { getUtcToTz } = useDateTime();

  return (
    <ReadReceiptSidebarContainer>
      <ReadContext.Consumer>
        {({ readers, setReaders, message, setMessage }) =>
          readers.length > 0 && (
            <div className="readReceiptBox">
              <CloseIcon
                className="closeIcon"
                onClick={() => {
                  setReaders([]);
                  setMessage('');
                }}
              ></CloseIcon>
              <div
                style={{
                  paddingInline: 24,
                  paddingTop: 60,
                  paddingBottom: 32,
                  wordBreak: 'break-word',
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: message }}></p>
              </div>
              <hr />
              <div className="readBox">
                <span className="readLabel">Read</span>
                <CheckIcon className="checkIcon"></CheckIcon>
              </div>
              {readers.map((reader) => (
                <Grid
                  container
                  className="readerCard"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item className="nameContainer">
                    <UserAvatar
                      width={24}
                      height={24}
                      userId={reader?.id}
                      firstName={reader?.name}
                      profilePic={reader?.profileImage}
                    />
                    <span className="name">{reader.name}</span>
                  </Grid>
                  <Grid item>
                    <span className="date">
                      {getUtcToTz(reader.updated_at, 'hh:mm a')}
                    </span>
                    <span className="date">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                    <span className="date">
                      {getUtcToTz(reader.updated_at, 'MMM DD yyyy')}
                    </span>
                  </Grid>
                </Grid>
              ))}
            </div>
          )
        }
      </ReadContext.Consumer>
    </ReadReceiptSidebarContainer>
  );
};

export default ReadReceiptSidebar;
