import { useSelector } from 'react-redux';
import { CategoryChip } from './style';
import { COLORS_LIST } from 'utils/constants';
import selectors from 'store/selectors';

interface IProps {
  category: number;
}

const TaskCategory = ({ category }: IProps) => {
  const categoryList = useSelector(selectors.getServiceAdmin);
  const selectedCategory = categoryList?.find((c) => c?.id == category);

  const selectedColor = COLORS_LIST.find(
    (item) => item.color === selectedCategory?.color,
  );

  return selectedCategory ? (
    <CategoryChip
      className="serviceTypeCnt"
      style={{
        backgroundColor: selectedColor?.color,
        color: selectedColor?.textColor,
      }}
    >
      {selectedCategory?.type?.charAt(0)?.toUpperCase() +
        selectedCategory?.type?.slice(1)}
    </CategoryChip>
  ) : (
    <span>-</span>
  );
};

export default TaskCategory;
