import { Box, styled } from '@mui/material';
import { colors } from 'components/theme/constants';
import { theme } from 'components/theme/theme';

export const MessagesChannelInnerStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',

  '.str-chat__li': {
    position: 'unset',
  },

  '.msgWrap': {
    height: 'auto',
    flexGrow: 1,
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    '.msgOuter': {
      maxHeight: '100%',
      height: '100%',
    },
    '& .str-chat__virtual-list': {
      height: '100%',
      '& .messagesSection': {
        height: 'inherit',
      },
    },
    '& .str-chat__empty-channel': {
      marginTop: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    '.avatarWrapper': {
      display: 'flex',
      alignItems: 'flex-start',
      paddingBottom: 10,

      width: '100%',
      '&:hover': {
        '.actionBtnWrap': {
          display: 'block',
        },
      },
      '.imgbox': {
        width: 24,
        height: 24,
        cursor: 'pointer',
      },
      '.nameTimeWrap': {
        h6: {
          marginRight: 10,
          fontWeight: 'normal',
          fontSize: theme.typography.pxToRem(16),
          lineHeight: '24px',
          color: 'rgba(37, 35, 80, 0.7)',
          cursor: 'pointer',
        },
        span: {
          fontWeight: 'normal',
          fontSize: theme.typography.pxToRem(12),
          lineHeight: '16px',
          color: 'rgba(37, 35, 80, 0.5)',
        },
      },
      p: {
        fontWeight: 'normal',
        fontSize: theme.typography.pxToRem(15),
        lineHeight: '12px',
        display: 'inline-block',
        color: theme.palette.text.primary,
      },
      '.infoIcon': {
        color: 'rgba(0, 0, 0, 0.38)',
      },
      '.actionBtnWrap': {
        display: 'none',
        height: 18,
        div: {
          display: 'flex',
        },
        button: {
          padding: 0,
        },
        '.dropdown-menu': {
          '.dropdown-item': {
            padding: '5px 10px',
            lineHeight: '18px',
            fontSize: theme.typography.pxToRem(12),
          },
        },
      },
      '&.sender': {
        flexDirection: 'row-reverse',
        '.imgbox': {
          marginRight: 0,
          marginLeft: 10,
          display: 'flex',
          alignItems: 'center',
        },
        '.nameTimeWrap': {
          flexDirection: 'row-reverse',
          h6: {
            marginRight: 0,
            marginLeft: 10,
          },
        },
        p: {
          textAlign: 'right',
        },
        '.userMessage': {
          background: colors.primary.p50,
          borderRadius: '12px 0px 12px 12px',
          border: `1px solid ${colors.primary.p100}`,
          padding: '8px',
          maxWidth: '70%',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          marginTop: '10px',
          '& p': {
            lineHeight: '21px',
            fontWeight: 400,
            letterSpacing: '0.5px',
            '& .message-user-tag': {
              backgroundColor: '#ffffff',
              color: colors.primary.main,
              borderRadius: 90,
              paddingInline: 4,
              fontSize: 12,
              lineHeight: '12px',
              height: 20,
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
          '& img': {
            borderRadius: '20px',
          },
        },
        '.namebox': {
          '& span': {
            marginRight: '4px',
          },
          '.username': {
            marginLeft: 8,
            marginRight: 0,
            color: colors.light.text.primary,
            fontSize: 14,
            fontWeight: 600,
          },
          width: 'calc(100% - 60px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          '.elEllipses': {
            display: '-webkit-box !important',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
        '.dropdownToggle': {
          '& span': {
            marginTop: '10px',
          },
        },
      },
      '&.receiver': {
        '.imgbox': {
          borderRadius: 50,
          flexShrink: 0,
          height: 30,
          marginRight: 10,
          overflow: 'hidden',
        },
        '& .userMessage': {
          wordBreak: 'break-word',
          marginTop: 10,
          borderRadius: '0px 12px 12px 12px',
          background: colors.primary.p50,
          border: `1px solid ${colors.primary.p200}`,
          padding: 8,
          maxWidth: '70%',
          overflowWrap: 'break-word',

          '& img': {
            borderRadius: 20,
          },
          '& p': {
            lineHeight: '21px',
            fontWeight: 400,
            letterSpacing: '0.5px',
            '& .message-user-tag': {
              marginRight: 2,
              color: '#ffffff',
              backgroundColor: colors.primary.main,
              borderRadius: 90,
              paddingInline: 4,
              fontSize: 12,
              lineHeight: '12px',
              height: 20,
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        },
        '& .namebox': {
          '& span': {
            marginRight: 2,
          },
          '.username': {
            color: colors.light.text.primary,
            fontSize: 14,
            fontWeight: 600,
            marginRight: 8,
          },
          width: 'calc(100% - 60px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        },
      },
      '.messageAttachments': {
        display: 'inline-block',
        width: '100%',

        '& .str-chat__modal': {
          left: 65,
        },
        '& .image-gallery-image': {
          width: 500,
        },
        '& .fullscreen': {
          color: 'red',
          '& img': {
            height: 'auto',
            width: '100%',
          },
        },

        '.str-chat__message-attachment--file': {
          width: '180px',
          background: '#f6f5ff',
          border: '1px solid #dbe2f0',
          borderRadius: '4px',
          margin: '5px',
          cssFloat: 'left',
          '.str-chat__message-attachment-file--item': {
            display: 'flex',
            flexDirection: 'row',
            padding: '8px',
            height: 'unset',
            '.rfu-file-icon--small': {
              display: 'block',
              height: '46px',
              marginRight: '10px',
            },
            '.str-chat__message-attachment-file--item-text': {
              maxWidth: '120px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
              'a,\n            span': {
                fontFamily: "'Manrope'",
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
              },
              a: { fontSize: '14px', color: '#151339' },
              span: {
                fontSize: '13px',
                color: '#423f79',
                display: 'block !important',
                textAlign: 'left',
              },
            },
          },
        },
      },
      '.messageUserTag': {
        borderRadius: 90,
        background: colors.primary.main,
        padding: '0px 4px',
        color: theme.palette.common.white,
        fontSize: 12,
        lineHeight: '12px',
      },
    },
    '.alertWrap': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 30,
    },
    '.badge': {
      width: 'auto',
      borderRadius: 4,
      padding: '2px 15px',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
    },
    '.noMemberWrap': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      zIndex: 1,
      padding: '10px 15px',
      '&::after': {
        content: '',
        width: 'calc(100% + 30px)',
        borderRadius: 4,
        height: '100%',
        position: 'absolute',
        left: -15,
        backgroundColor: '#f7f9fd',
        zIndex: -1,
      },
      '.btn': {
        textTransform: 'capitalize',
        span: {
          margin: '0 0 0 10px',
        },
      },
    },
  },
  '.typeMsgInputWrap': {
    padding: '25px',
    position: 'relative',
    width: '100%',

    '.formGroup': {
      marginBottom: 0,
      '.uploadFileWrap': {
        label: {
          cursor: 'pointer',
          position: 'relative',
          zIndex: 11,
          marginRight: '35px',
          display: 'flex',
          alignItems: 'center',
          span: {
            fontSize: '16px',
          },
        },
        input: {
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: 0,
          zIndex: -1,
          width: '20px',
        },
      },
      '.btnbox': {
        position: 'absolute',
        top: '12px',
        right: '15px',
      },
      '.sendBtn': {
        span: {
          fontSize: '16px',
          fontWeight: 'bold',
          '&::before': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
  },
  '.timebox': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 20,
    color: colors.light.text.disabled,
  },
}));
