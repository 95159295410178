import { useContext, useMemo } from 'react';
import { TaskSummaryContext } from 'pages/ReportingV2/Reports/TaskOverview/context/taskSummaryContext';
import { useSelector } from 'react-redux';
import { getUserHotelTz } from 'store/selectors/auth';
import selectors from 'store/selectors';
import { RootState } from '../../../store';
import {
  generateAssigneeDropdownData,
  generateCategoryDropdownData,
  generateLocationDropdownData,
  generateTeamsFilterDropdownData,
  statusDropdownData,
} from 'helper/task/dropdownData';
import { overdueDropdownData } from 'helper/reports/dropdownData';
import { cloneDeep, isEmpty } from 'lodash';
import { getLocationsState } from 'store/selectors/locations';

function useTaskReportFilters(context) {
  const { state, updateState, clearAllFilters } = useContext(context);
  const categoriesList = useSelector(selectors.getServiceAdmin);
  const userList = useSelector(selectors.getUserAdmin);
  const teams = useSelector(selectors.getTeams);
  const locationsList = useSelector(getLocationsState);

  //Selected categories in filters objects
  const categoryDropdownData = useMemo(() => {
    return generateCategoryDropdownData(categoriesList);
  }, [categoriesList]);

  const assigneeDropdownData = useMemo(() => {
    return generateAssigneeDropdownData(userList);
  }, [userList]);

  // Generating Teams data for dropdown
  const teamsDropdownData = useMemo(() => {
    return generateTeamsFilterDropdownData(teams);
  }, [teams]);
  // Generating locations data for dropdown
  const locationsDropdownData = useMemo(() => {
    return generateLocationDropdownData(locationsList);
  }, [teams]);

  const taskAssigneesData = [
    { label: 'Unassigned', id: 'unassigned' },
    ...(teamsDropdownData?.length
      ? [
          {
            label: 'Teams',
            id: 'teams',
            child: teamsDropdownData,
          },
        ]
      : []),
    { label: 'People', id: 'people', child: assigneeDropdownData },
    { label: 'Locations', id: 'locations', child: locationsDropdownData },
  ];
  //Selected status in filters objects
  const selectedStatus = useMemo(() => {
    return statusDropdownData?.filter((s) =>
      state?.filters['taskStatuses']?.includes(s.id),
    );
  }, [state?.filters]);

  //Selected categories in filters objects
  const selectTaskCategories = useMemo(() => {
    return categoryDropdownData?.filter((s) =>
      state?.filters['categories']?.includes(s.id),
    );
  }, [state.filters, categoriesList]);

  const selectedLocations = useMemo(() => {
    return state?.filters['locations'];
  }, [state.filters]);

  const selectedProjectsList = useMemo(() => {
    return state?.filters['projects'];
  }, [state.filters]);

  const selectedSchedulesList = useMemo(() => {
    return state?.filters['schedules'];
  }, [state.filters]);

  const selectedTaskAssignees = useMemo(() => {
    return taskAssigneesData?.reduce((pre: any, curr) => {
      if (state?.filters['assignees']?.includes(curr.id)) pre.push(curr);
      let assignee = curr.child?.filter((x) =>
        state?.filters['assignees']?.includes(x.id),
      );
      if (assignee?.length) pre = pre.concat(assignee);

      assignee = null;
      for (let index = 0; index <= curr?.child?.length - 1; index++) {
        assignee = curr?.child?.[index]?.child?.filter((x) =>
          state?.filters['assignees']?.includes(x.id),
        );

        if (assignee?.length) pre = pre.concat(assignee);
      }

      return pre;
    }, []);
  }, [state?.filters, taskAssigneesData]);

  //Selected overdue filters
  const selectedOverdueFilter = useMemo(() => {
    const overDueData = overdueDropdownData;
    return overDueData?.filter((s) =>
      Object.keys?.(state?.filters?.dueFilter || {})?.includes(s.id),
    );
  }, [state?.filters]);
  const handleDateChange = (date): any => {
    updateState({
      selectedDateLabel: date?.selectedDateOption,
      filters: {
        ...state?.filters,
        fromDate: date?.from,
        toDate: date?.to,
      },
    });
  };
  const handleOverdueFilterChange = (option) => {
    updateState({
      filters: { ...state.filters, dueFilter: { [option[0].id]: true } },
    });
  };
  const handleFilterChange = (values, type) => {
    const postData = values.map((v) => v.id);

    if (type === 'projects') {
      updateState({
        filters: { ...state.filters, [type]: postData, schedules: [] },
      });

      return;
    }

    if (type === 'schedules') {
      updateState({
        filters: { ...state.filters, [type]: postData, projects: [] },
      });

      return;
    }

    updateState({ filters: { ...state.filters, [type]: postData } });
  };

  const handleClearFilter = (type) => {
    const filtersCopy = cloneDeep(state.filters);
    delete filtersCopy[type];
    updateState({ filters: filtersCopy });
  };
  const handleClearDateFilter = (date) => {
    handleDateChange(date);
  };
  const isFilterApplied = useMemo(() => {
    const { selectedDateLabel, filters } = state;
    const { fromDate, toDate, ...rest } = filters;
    const isFilterApplied =
      selectedDateLabel !== 'Last 30 Days' || !isEmpty(rest);
    return isFilterApplied;
  }, [state?.filters]);

  return {
    isFilterApplied,
    handleClearDateFilter,
    handleClearFilter,
    handleFilterChange,
    handleOverdueFilterChange,
    handleDateChange,
    selectedOverdueFilter,
    selectedTaskAssignees,
    selectTaskCategories,
    selectedLocations,
    selectedProjectsList,
    selectedSchedulesList,
    selectedStatus,
    clearAllFilters,
    state,
    updateState,
  };
}

export default useTaskReportFilters;
