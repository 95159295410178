import React from 'react';

import CustomModal from 'components/CustomModal';
import DialogWrapper from './DialogWrapper';

import {
  IconContainer,
  Title,
  Hr,
  ButtonsWrapper,
  RedButtonFilled,
  RedButtonOutlined,
} from './styled';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DeleteIcon from 'components/Icons/deleteIcon';

const DeleteDialog = ({ open, handleClose, onClickDelete, onClickHide }) => {
  return (
    <CustomModal handleClose={handleClose} open={open}>
      <DialogWrapper handleClose={handleClose}>
        <IconContainer>
          <DeleteIcon sx={{ color: '#D32F2F' }} />
        </IconContainer>
        <Title>Are you sure you want to Delete this Step?</Title>
        <Hr />
        <ButtonsWrapper>
          <RedButtonFilled onClick={onClickDelete}>Yes, Delete</RedButtonFilled>
          <RedButtonOutlined
            onClick={onClickHide}
            startIcon={<VisibilityOffOutlinedIcon />}
          >
            No, Hide Step Instead
          </RedButtonOutlined>
        </ButtonsWrapper>
      </DialogWrapper>
    </CustomModal>
  );
};

export default DeleteDialog;
