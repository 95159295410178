import { Box, Grid } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import IntroVideoThumbnail from 'public/assets/img/tasks/intro-video.svg';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { setDialog } from 'store/actions/dialogActions';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import DIALOGS from 'utils/dialogIds';
import { CreateTaskInfoStyled } from './createTaskInfo.styled';
import { HasPermission } from 'components/HasPermission';

// Create Task Information Dialog Box
function CreateTaskInfo(props) {
  const dispatch = useDispatch();
  const { PERMISSIONS } = useCustomPermission();
  const handleCreateTask = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
      }),
    );
  };

  const handleVideoIntroClick = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.PLAY_VIDEO,
        open: true,
        data: {
          videoUrl:
            'https://cdn.xeniaplatform.io/xenia-videos/work-order-empty-state-video-v-3.mp4',
        },
      }),
    );
  };

  return (
    <CreateTaskInfoStyled>
      <Grid
        className="container"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box className="infoContainer">
          <p className="title">
            Efficiently Manage Your Team's Tasks with Work Orders
          </p>
          <p className="description">
            <span className="subtitle">Assign and Track:</span> Easily assign
            tasks and monitor progress all in one place.
          </p>
          <p className="description">
            <span className="subtitle">Record Details:</span> Attach checklists,
            procedures, and more for comprehensive task management.
          </p>
          <p className="description">
            <span className="subtitle">Advanced Filtering:</span> Utilize
            powerful filters with AND/OR logic to drill down and analyze
            operations data precisely.
          </p>
          <p className="description">
            <span className="subtitle">Broad Applications:</span> Perfect for
            preventive maintenance, inspections, cleaning, and more.
          </p>

          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
            <Box className="buttonsContainer">
              <CustomButton variant="contained" onClick={handleCreateTask}>
                Create Work Order
              </CustomButton>
            </Box>
          </HasPermission>
        </Box>
        <Box className="thumbnailContainer" onClick={handleVideoIntroClick}>
          <img src={IntroVideoThumbnail} />
        </Box>
      </Grid>
    </CreateTaskInfoStyled>
  );
}

export default CreateTaskInfo;
