import { useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TasksByLocationsTable from 'components/DataTables/Reports/TasksByLocationsTable';
import {
  WidgetCnt,
  WidgetHeading,
  WidgetLoaderCnt,
  WidgetSubHeading,
} from './style';
import useTasksFiltersConditions from 'utils/CustomHooks/useTasksFiltersConditions';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';

function ScheduledCompletionByLocation(props) {
  const { title, loading, data, globalFilters } = props;
  const { recurringByLocations } = useTasksFiltersConditions();
  const dispatch = useDispatch();

  const clickHandler = (id) => {
    let advancedFilters: any = [];

    if (id) {
      advancedFilters = recurringByLocations([id]);
    }

    if (globalFilters?.fromDate && globalFilters?.toDate) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'dueDate',
        value: [globalFilters?.fromDate, globalFilters?.toDate],
      });
    }
    if (globalFilters?.locations?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'locations',
        value: globalFilters.locations,
      });
    }

    if (globalFilters?.projects?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'projects',
        value: globalFilters.projects,
      });
    }

    if (globalFilters?.schedules?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'schedules',
        value: globalFilters.schedules,
      });
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TASK_TABLE_DIALOG,
        data: {
          filters: advancedFilters,
        },
      }),
    );
  };

  return (
    <WidgetCnt>
      <WidgetHeading>{title}</WidgetHeading>
      <WidgetSubHeading>{data?.summary?.total}%</WidgetSubHeading>
      {loading ? (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      ) : (
        <Stack height={'400px'}>
          <TasksByLocationsTable
            rowData={data?.table}
            onRowClick={clickHandler}
          />
        </Stack>
      )}
    </WidgetCnt>
  );
}

export default ScheduledCompletionByLocation;
