import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ReOpenIcon from '@mui/icons-material/CachedOutlined';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { unArchiveTemplateCall } from 'api/checklistV2Api';
import { useState } from 'react';
// import DeleteIcon from 'components/Icons/deleteIcon';
const archiveTemplateActionDropdowns = [
  { label: 'Restore', id: 'restore', icon: <ReOpenIcon />, iconAlign: 'start' },
  // {
  //   label: 'Delete',
  //   id: 'delete',
  //   icon: <DeleteIcon sx={{ color: 'red' }} />,
  //   iconAlign: 'start',
  //   sx: {
  //     color: 'red',
  //   },
  // },
];

function ArchiveTemplateActionDropdown(props) {
  const { template, deleteTemplateCallback } = props;
  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const dispatch = useDispatch();
  const deleteTemplate = async () => {
    setDeleteButtonLoader(true);
    // await deleteFolderCall(folder.id);
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.CONFIRMATION,
      }),
    );
    setDeleteButtonLoader(false);
    // deleteFolderCallback(folder.id);
  };

  // UnArchive Template
  const handleRestoreTemplate = async () => {
    await unArchiveTemplateCall(template.id);
    deleteTemplateCallback(template.id);
  };
  //Handler for option select of action dropdown
  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'delete':
        dispatch(
          actions.setDialog({
            dialogId: DIALOGS.CONFIRMATION,
            open: true,
            data: {
              title: 'Delete Template',
              description: 'Are you sure you want to delete this template?',
              onConfirmCallback: deleteTemplate,
              confirmButtonProps: {
                buttonLoader: deleteButtonLoader,
              },
            },
          }),
        );
        break;
      case 'restore':
        handleRestoreTemplate();
        break;
    }
  };

  return (
    <ActionDropdown
      rootProps={{
        id: 'template-action-dropdown',
      }}
      options={archiveTemplateActionDropdowns}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { padding: 2 },
      }}
      popperProps={{
        sx: {
          zIndex: 1301,
        },
      }}
      paperProps={{
        style: {
          minWidth: 145,
        },
      }}
    >
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
}

export default ArchiveTemplateActionDropdown;
