/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';

import React, { FC } from 'react';

import { ArchiveCard } from './ArchiveCard';
import { ListSingleChat } from './ListSingleChat';

import selectors from 'store/selectors';
import { ChatSidebarStyled } from './chatSidebar.styled';

export const ListChat: FC<any> = ({
  isArchivedList = false,
  handleSetChannel,
  channelList,
  getArchivedChatList,
  setIsArchiveShow,
  openChannelActionModal,
  selectedChannelId,
  tz,
}: any) => {
  const channelsHash = useSelector(selectors.getStreamChannelHash);

  return (
    <>
      <ChatSidebarStyled>
        <div id="groupNav" className="collapse show">
          <div>
            {channelList?.map((singleData, index) => {
              const channel = isArchivedList
                ? singleData
                : channelsHash[singleData.id] || singleData;
              return (
                <ListSingleChat
                  singleData={channel}
                  index={index}
                  handleSetChannel={handleSetChannel}
                  openChannelActionModal={openChannelActionModal}
                  tz={tz}
                  selectedChannelId={selectedChannelId}
                />
              );
            })}
          </div>
        </div>
        <ArchiveCard
          getArchivedChatList={getArchivedChatList}
          setIsArchiveShow={setIsArchiveShow}
        />
      </ChatSidebarStyled>
    </>
  );
};
