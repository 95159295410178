import { useMemo, useRef } from 'react';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import './style.css';

// Utils
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import WidgetAdvanceFilter from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter';
import WidgetActions from 'pages/Dashboards/Widgets/widgetActions';
import {
  WidgetCnt,
  WidgetHeading,
} from 'pages/Dashboards/Widgets/widgets.style';
import { useLoadWidget } from 'pages/Dashboards/Widgets/useLoadWidget';
import { exportToPng } from 'utils/globalFunction';
import { defaultColDef, columns } from './constants';
import { useViewFlaggedItems } from 'pages/Dashboards/hooks/useWidgets';
import { Stack, Typography } from '@mui/material';

interface ITaskTableWidgetProps {
  widgetId: string;
  editable?: boolean;
  globalFilters?: any;
}
const FlaggedItemsTableByQuestions = ({
  widgetId,
  editable,
  globalFilters,
}: ITaskTableWidgetProps) => {
  const { handleViewFlaggedItems } = useViewFlaggedItems();

  const chartRef: any = useRef(null);

  const { parsedData, getWidgetQuery, widget, summary } = useLoadWidget(
    widgetId,
    globalFilters,
  );

  const exportChartToImage = () => {
    exportToPng(chartRef.current, widget?.title);
  };
  const loading = getWidgetQuery.isFetching || false;
  const error = getWidgetQuery.error;

  const transformedData = useMemo(() => {
    return (
      parsedData?.map((item) => {
        return {
          id: item?.question?.id,
          template: item?.template,
          flaggedResponses: item?.count,
          question: item?.question?.description,
        };
      }) ?? []
    );
  }, [parsedData]);

  const colConfig = useMemo(() => columns(), [parsedData]);

  const handleRowClick = (id) => {
    handleViewFlaggedItems(widget, id, getWidgetQuery.refetch, globalFilters);
  };

  return (
    <WidgetCnt ref={chartRef}>
      <WidgetHeading>
        <Stack
          direction="column"
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Typography className="title" title={widget?.title}>
            {widget?.title}
          </Typography>

          {summary?.total >= 0 && (
            <Typography className="count">{summary?.total}</Typography>
          )}
        </Stack>
        <div className={'widgetActionsCnt'}>
          <WidgetAdvanceFilter
            widgetId={widgetId}
            globalFilters={globalFilters}
            hideFilters={'status duration completedAt completedBy'}
          />{' '}
          <WidgetActions
            exportChartToImage={exportChartToImage}
            widgetId={widgetId}
            editable={editable}
          />
        </div>
      </WidgetHeading>

      <WithLoader isLoading={loading}>
        <WithErrorState
          type={'table'}
          error={error}
          reload={() => getWidgetQuery.refetch()}
        >
          <CustomListView
            rootProps={{
              className: 'templateBasedFlaggedItemsTable',
              style: {
                height: 400,
                boxShadow: 'none',
                flex: 1,
              },
            }}
            onRowClick={handleRowClick}
            gridProps={{
              ref: (ref) => {
                globalThis.templateBasedFlaggedItemsTable = ref;
              },
              onGridReady: (params) => {
                params.api.sizeColumnsToFit();
              },
              headerHeight: 30,
              rowData: transformedData,
              suppressChangeDetection: true,
              defaultColDef: defaultColDef,
              columnDefs: colConfig,
              suppressCellFocus: true,
              suppressRowClickSelection: true,
              getRowId: (params) => params.data.id,
            }}
          />
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
};

export default FlaggedItemsTableByQuestions;
