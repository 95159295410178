import styled from '@mui/system/styled';

export const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(4, 1fr)`,
  gap: '16px',

  [theme.breakpoints.down('1100')]: {
    gridTemplateColumns: `repeat(3, 1fr)`,
    gap: '12px',
  },

  [theme.breakpoints.down('800')]: {
    gridTemplateColumns: `repeat(2, 1fr)`,
    gap: '12px',
  },

  [theme.breakpoints.down('391')]: {
    gridTemplateColumns: `repeat(1, 1fr)`,
    gap: '12px',
  },
}));
