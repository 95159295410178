import styled from '@mui/system/styled';

export const ReportingListItemCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px 9px',
  marginBottom: 20,
  transition: 'ease all 0.3s',
  borderRadius: 12,
  boxShadow:
    'rgb(9 30 66 / 25%) 0px 4px 8px -4px, rgb(9 30 66 / 8%) 0px 0px 0px 1px',
  '&:hover': {
    background: '#EBEAFF',
    cursor: 'pointer',
    transition: 'ease all 0.3s',
  },
  '.arrowIcon': {
    fontSize: 17,
  },
  '& .reportingListItemLeft': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 16,
    '.reportIconCnt': {
      svg: {
        fontSize: 26,
      },
    },
    '& .reportTitleCnt': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      '.reportName': {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px',
        color: '#212121',
        marginBottom: 2,
      },
      '.reportDescription': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19px',
        color: '#424242',
      },
    },
  },
});
