import produce from 'immer';
import { Reducer } from 'redux';
import _, { cloneDeep } from 'lodash';

import actionTypes from 'store/actionTypes';

export const checklistV2Reducer: Reducer<any> = (
  checklistData = {},
  action,
) => {
  if (!action) {
    return checklistData;
  }

  return produce(checklistData, (draft: any) => {
    switch (action.type) {
      case actionTypes.SET_CHECKLIST_LIST:
        // eslint-disable-next-line no-case-declarations
        const checklistListData: any = action.payload;
        draft.checklistListData = checklistListData;
        break;

      case actionTypes.SET_CHECKLIST_COMPLETE_LIST:
        // eslint-disable-next-line no-case-declarations
        const checklistListCompleteData: any = action.payload;
        draft.checklistListCompleteData = checklistListCompleteData;
        break;
      case actionTypes.DELETE_CHECKLIST:
        // eslint-disable-next-line no-case-declarations
        const checklistId: any = action.payload.checklistId;
        // eslint-disable-next-line no-case-declarations
        const selectedIndex: any = draft?.checklistListData?.data?.findIndex(
          (checklist: any) => checklist.id === checklistId,
        );
        draft.checklistListData?.data?.splice(selectedIndex, 1);
        break;
      case actionTypes.SET_PUBLIC_CHECKLISTS:
        // eslint-disable-next-line no-case-declarations
        const publicChecklists: any = action.payload;
        draft.publicChecklists = publicChecklists;
        break;
      case actionTypes.SET_CHECKLIST_CATEGORIES:
        // eslint-disable-next-line no-case-declarations
        const checklistCategories: any = action.payload;
        draft.checklistCategories = checklistCategories;
        break;
      case actionTypes.SET_CHECKLIST_INDUSTRIES:
        // eslint-disable-next-line no-case-declarations
        const checklistIndustries: any = action.payload;
        draft.checklistIndustries = checklistIndustries;
        break;
      case actionTypes.SET_CHECKLIST_TYPES:
        draft.checklistTypes = action.payload;
        break;
      case actionTypes.SET_CHECKLIST:
        // eslint-disable-next-line no-case-declarations
        const checklistData: any = action.payload;
        draft.checklistData = checklistData;
        break;
      case actionTypes.SET_CHECKLIST_ATTACHMENTS:
        // eslint-disable-next-line no-case-declarations
        const checklistDataWithAttachments = _.cloneDeep(draft.checklistData);
        checklistDataWithAttachments.data.attachments = [
          ...checklistDataWithAttachments.data.attachments,
          ...action.payload.filePath.map((attachment: any) => {
            return action.payload.baseUrl + attachment;
          }),
        ];
        draft.checklistData = checklistDataWithAttachments;
        break;
      case actionTypes.SET_CHECKLIST_ITEM:
        // eslint-disable-next-line no-case-declarations
        const checklistForUpdate: any = action.payload.data;

        // eslint-disable-next-line no-case-declarations
        const cloned = cloneDeep(draft?.checklistListCompleteData?.data || {});

        // eslint-disable-next-line no-case-declarations
        const selectedIndexForUpdate: any =
          draft?.checklistListCompleteData?.data?.findIndex(
            (checklist: any) => checklist.id === checklistForUpdate?.id,
          );

        if (cloned && selectedIndexForUpdate >= 0) {
          cloned[selectedIndexForUpdate] = action.payload.data;
        }

        if (draft?.checklistListCompleteData?.data) {
          draft.checklistListCompleteData.data = cloned;
        }
        break;
      case actionTypes.SPLICE_CHECKLIST_ITEMS:
        // eslint-disable-next-line no-case-declarations
        const splicedChecklistItemData = _.cloneDeep(draft.checklistItemsData);
        splicedChecklistItemData.data.splice(
          action.payload.checklistItemIndex,
          1,
        );
        draft.checklistItemsData = splicedChecklistItemData;
        break;
      case actionTypes.SET_CHECKLIST_ITEMS:
        // eslint-disable-next-line no-case-declarations
        draft.checklistItemsData = action.payload;
        break;
      case actionTypes.SET_CHECKLIST_LOGS:
        // eslint-disable-next-line no-case-declarations
        draft.checklistLogs = action.payload;
        break;
      case actionTypes.SET_CHECKLIST_LOG:
        // eslint-disable-next-line no-case-declarations
        draft.checklistLog = action.payload;
        break;
      case actionTypes.CHECKLIST_LOG_SUBMITTED:
        // eslint-disable-next-line no-case-declarations
        draft.checklistLogSubmitted = action.payload;
        break;
      case actionTypes.SET_CHECKLIST_ITEM_ATTACHMENTS:
        // eslint-disable-next-line no-case-declarations
        const checklistItemDataWithAttachments = _.cloneDeep(
          draft.checklistItemsData,
        );
        checklistItemDataWithAttachments.data[
          action.payload.checklistItemIndex
        ].attachments = [
          ...checklistItemDataWithAttachments.data[
            action.payload.checklistItemIndex
          ].attachments,
          ...action.payload.attachments.filePath.map((attachment: any) => {
            return action.payload.attachments.baseUrl + attachment;
          }),
        ];
        draft.checklistItemsData = checklistItemDataWithAttachments;
        break;
      case actionTypes.DELETE_CHECKLIST_ITEM_ATTACHMENT:
        // eslint-disable-next-line no-case-declarations
        const checklistItemDataWithUpdatedAttachments = _.cloneDeep(
          draft.checklistItemsData,
        );
        checklistItemDataWithUpdatedAttachments.data[
          action.payload.checklistItemIndex
        ].attachments.splice(action.payload.attachmentIndex, 1);
        draft.checklistItemsData = checklistItemDataWithUpdatedAttachments;
        break;
      case actionTypes.INITIALIZE_CHECKLIST:
        // eslint-disable-next-line no-case-declarations
        draft.checklistData = {};
        draft.checklistItemData = {};
        draft.checklistItemsData = [];
        break;
      case actionTypes.SET_PREVIEW_CHECKLIST_DATA:
        draft.previewChecklistData = action.payload;
        break;
      case actionTypes.SET_PREVIEW_CHECKLIST_MODAL_CONFIG:
        // eslint-disable-next-line no-case-declarations
        const prevConfig = draft.previewChecklistModalConfig
          ? { ...draft.previewChecklistModalConfig }
          : {};
        draft.previewChecklistModalConfig = {
          ...prevConfig,
          ...action.payload,
        };
        break;
      case actionTypes.CLOSE_PREVIEW_CHECKLIST_MODAL:
        draft.previewChecklistModalConfig = {
          open: false,
          activeTab: 'preview',
          checklistScope: 'myTemplates',
        };
        break;
      case actionTypes.SET_CHECKLIST_VIEW_TYPE:
        draft.checklistViewType = action.payload;
        break;
      case actionTypes.SET_CHECKLIST_FILTERS:
        draft.checklistFilters = action.payload;
        break;
      case actionTypes.SET_CHECKLIST_ITEMS_AUTOMATIONS:
        draft.itemsAutomations = action.payload;
        break;
      default:
        return draft;
    }
  });
};
