import { createSvgIcon } from '@mui/material';

const LongDescriptionIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.5 3.75H17.5"
        stroke="#FF7043"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 7.91669H10.3917"
        stroke="#FF7043"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 12.0833H17.5"
        stroke="#FF7043"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 16.25H10.3917"
        stroke="#FF7043"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LongDescriptionIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default LongDescriptionIcon;
