import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  StatusDropdownButton,
  StyledEndIconCnt,
} from 'pages/Widgets/TaskFilters/StatusFilter/statusFilter.style';
import { RemoveIconBtn } from 'pages/Widgets/TaskFilters/CategoryFilter/categoryFilter.style';
import CancelIcon from '@mui/icons-material/Cancel';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';

function LocationFilterDropdown(props) {
  const { selectedIds = null, onChangeCallback, onClearCallback } = props;

  const locationsHash = useSelector(selectors.getLocationsHash);
  const [selectedOption, setSelectedOption] = useState<any[]>(
    selectedIds ?? [],
  );

  useEffect(() => {
    if (!selectedIds) return;
    const selectedLocations = selectedIds.map((id) => locationsHash[id]);
    setSelectedOption(selectedLocations);
  }, [selectedIds]);

  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
  };

  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return `Location: ${selectedOption[0].name}`;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption.length} Locations`;
    } else {
      return 'Locations: All';
    }
  }, [selectedOption]);

  const handleChangeOptions = (locationIds: string[]) => {
    const selectedLocations = locationIds.map((id) => locationsHash[id]);
    onChangeCallback(selectedLocations);
    setSelectedOption(selectedLocations);
  };

  return (
    <AssigneeDropdown
      buttonRenderer={
        <StatusDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          isSelected={selectedOption.length > 0}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          {selectedText}
          {selectedOption?.length ? (
            <RemoveIconBtn
              className="remove-assignee"
              onClick={handleClearOptions}
            >
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}
        </StatusDropdownButton>
      }
      handleChangeCallback={(data) => {
        const { selectedList } = data;
        handleChangeOptions(selectedList?.locations ?? []);
      }}
      showTabs={false}
      listProps={{
        locations: {
          showInlineSelection: true,
          isSearchable: true,
          isMulti: true,
          hideActions: true,
          selected: selectedIds ?? [],
        },
      }}
      displayOptions={{
        all: false,
        people: false,
        teams: false,
        locations: true,
      }}
      allSelected={false}
    />
  );
}

export default LocationFilterDropdown;
