import { createSvgIcon } from '@mui/material';

const AddToFolderIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M7.83366 3.08333L7.18293 1.78187C6.99564 1.4073 6.902 1.22001 6.76229 1.08318C6.63875 0.962176 6.48985 0.870151 6.32637 0.813764C6.1415 0.75 5.93211 0.75 5.51333 0.75H3.28366C2.63026 0.75 2.30357 0.75 2.054 0.877159C1.83448 0.989011 1.656 1.16749 1.54415 1.38701C1.41699 1.63657 1.41699 1.96327 1.41699 2.61667V3.08333M1.41699 3.08333H10.2837C11.2638 3.08333 11.7538 3.08333 12.1281 3.27407C12.4574 3.44185 12.7251 3.70957 12.8929 4.03885C13.0837 4.4132 13.0837 4.90324 13.0837 5.88333V8.45C13.0837 9.43009 13.0837 9.92014 12.8929 10.2945C12.7251 10.6238 12.4574 10.8915 12.1281 11.0593C11.7538 11.25 11.2638 11.25 10.2837 11.25H4.21699C3.2369 11.25 2.74685 11.25 2.37251 11.0593C2.04323 10.8915 1.77551 10.6238 1.60773 10.2945C1.41699 9.92014 1.41699 9.43009 1.41699 8.45V3.08333ZM7.25033 8.91667V5.41667M5.50033 7.16667H9.00033"
        stroke="black"
        stroke-opacity="0.54"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AddToFolderIcon',
  );
  return (
    <IconCmp
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      {...props}
    />
  );
};
export default AddToFolderIcon;
