import React, { ReactNode } from 'react';
import { StyledItem } from 'pages/Dashboards/DashboardListing/GridView/dashboardsGridItem/dashboardsGridItem.style';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { DashboardTemplateItemCnt } from 'pages/Dashboards/DashboardTemplates/dashboardTemplateItem/templateItem.style';

interface ITemplateGridLoaderProps {
  isLoading: boolean;
  children: ReactNode;
}

const WithTemplateLoader: React.FC<ITemplateGridLoaderProps> = ({
  isLoading,
  children,
}) => {
  if (isLoading) {
    return (
      <Grid container spacing={3}>
        {Array.from({ length: 4 }).map((_, index) => (
          <DashboardTemplateItemCnt
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
          >
            <StyledItem>
              <div className={'imageCnt'}>
                <Skeleton variant="rectangular" width={40} height={40} />
              </div>
              <div className={'contentCnt'}>
                <Skeleton variant="text" width="100%" />
                <Stack spacing={1} direction="row">
                  <Skeleton variant="text" width="40%" />
                </Stack>
              </div>
            </StyledItem>
          </DashboardTemplateItemCnt>
        ))}
      </Grid>
    );
  }

  return <>{children}</>;
};

export default WithTemplateLoader;
