import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';
import { colors } from 'components/theme/constants';

export const SettingsMain = styled('div', {
  shouldForwardProp: (prop) => !['nopadding'].includes(prop),
})(({ nopadding }) => ({
  padding: nopadding ? '' : '0 24px 20px 24px',
  width: '100%',
}));

export const SettingsHeader = styled('div')({
  padding: '42px 0px 24px 0px',
  backgroundColor: colors.white,
});

export const SettingsTitle = styled(CTypography)({
  fontSize: 24,
  fontWeight: 700,
  color: '#000000DE',
});

export const SettingsHeaderWithActions = styled('div')({
  padding: '42px 0px 24px 0px',
  backgroundColor: colors.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const SettingsContentWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['padding'].includes(prop) || prop !== 'topAlertState',
})(({ padding, topAlertState }) => ({
  maxHeight: topAlertState ? 'calc(100vh - 222px)' : 'calc(100vh - 210px)',
  overflowY: 'auto',
  padding,
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
    width: 0,
  },
}));
