import styled from '@mui/system/styled';

export const FooterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
  '.label': {
    fontWeight: 400,
    fontSize: 12,
    span: {
      marginLeft: 3,
    },
  },
  '.value': {
    fontWeight: 700,
    fontSize: 12,
    marginLeft: 3,
  },
}));
