import { useState, useEffect, useCallback, useMemo } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import Header from './Header';
import Table from './Table';
import EmptyData from './EmptyStates/EmptyData';
import EmptySearch from './EmptyStates/EmptySearch';

// MUI
import { CircularProgress } from '@mui/material';

// Styles
import { ViewWrapper, LoaderWrapper, TotalRow } from './styled';

// Context
import { SpreadsheetContext } from './context';

// Utils
import {
  getSpreadsheetSubmissions,
  deleteChecklistLogs,
  getChecklistLog,
} from 'api/checklistV2Api';
import { editUserConfig, getUserConfig } from 'api/userConfig';
import { debounce, isArray } from 'lodash';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface SpreadsheetPropTypes {
  viewMode: 'collapsed' | 'fullScreen';
  template: any;
  tableHeight?: string;
  setShouldUpdate?: undefined | any;
  shouldUpdate?: undefined | any;
}

const Spreadsheet = ({
  viewMode = 'collapsed',
  template,
  tableHeight = '100%',
  setShouldUpdate = undefined,
  shouldUpdate = undefined,
}: SpreadsheetPropTypes) => {
  const PAGE_SIZE = 100;

  const dispatch = useDispatch();
  const { workspaceId, navigateWithWorkspaceUrl } = useWorkspaceHook();

  // check current log for updating local logs
  const currentLog = useSelector(selectors.getChecklistLog);

  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [fetching, setFetching] = useState(true);
  const [columnsConfig, setColumnsConfig] = useState<any>(null);
  const [advancedFilters, setAdvancedFilters] = useState<any[] | undefined>([]);
  const [advancedItemFilters, setAdvancedItemFilters] = useState<
    any[] | undefined
  >([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [offset, setOffset] = useState<number>(PAGE_SIZE);
  const [loadingMore, setLoadingMore] = useState(false);

  // fetch data with these deps
  useEffect(() => {
    fetchSpreadsheetData(false, searchTerm);
  }, [shouldUpdate, currentLog]);

  // fetch data on advanced filters change
  useEffect(() => {
    if (validateAllAdvancedFilters({ advancedFilters, advancedItemFilters })) {
      getAndSetSpreadsheetData(searchTerm);
      setOffset(PAGE_SIZE);
    }
  }, [advancedFilters, advancedItemFilters]);

  // fetch data on search term change with debounced api call
  useEffect(() => {
    setFetching(true);
    handleSearchApiCall(searchTerm);
  }, [searchTerm]);

  const handleSearchApiCall = useCallback(
    debounce(async (term: any) => {
      await getAndSetSpreadsheetData(term);
      setOffset(PAGE_SIZE);
      setFetching(false);
    }, 500),
    [],
  );

  // format rows for AG GRID
  const formatRows = (rows: any[]) => {
    return rows.map((item) => ({
      id: item.id,
      name: {
        type: 'name',
        answers: {
          value: item?.creator?.fullName || item?.submitterName,
        },
      },
      submissionDate: {
        type: 'date',
        answers: {
          value: item.status === 'Submitted' ? item.updatedAt : null,
        },
      },
      status: {
        type: 'status',
        answers: {
          value: item.status,
        },
      },
      duration: {
        type: 'duration',
        answers: {
          value: {
            createdAt: item.createdAt,
            lastItemUpdatedAt: item.lastItemUpdatedAt,
          },
        },
      },
      startDate: {
        type: 'date',
        answers: {
          value: item.createdAt,
        },
      },
      ...item.items,
    }));
  };

  const validateAdvancedFilter = (advancedFilters: any[] | undefined) => {
    if (advancedFilters === undefined) return true;
    return (
      advancedFilters.every(
        (f) =>
          !!f.filterName &&
          !!f.comparator &&
          !!f.conditional &&
          !!f.value.length,
      ) && advancedFilters.length !== 0
    );
  };

  const validateAdvancedItemFilter = (
    advancedItemFilters: any[] | undefined,
  ) => {
    if (advancedItemFilters === undefined) return true;
    return (
      advancedItemFilters.every(
        (f) =>
          !!f.item &&
          !!f.comparator &&
          (isArray(f.value)
            ? f.value.length > 0 && f.value.every((v) => !!v)
            : !!f.value),
      ) && advancedItemFilters.length !== 0
    );
  };

  const validateAllAdvancedFilters = ({
    advancedFilters,
    advancedItemFilters,
  }) => {
    return (
      validateAdvancedFilter(advancedFilters) ||
      validateAdvancedItemFilter(advancedItemFilters)
    );
  };

  const formatAdvancedFiltersForApi = (advancedFilters: any[] | undefined) => {
    const formatDateFilters = (filters: any[]) => {
      return filters.map((f) =>
        f.filterName === 'date'
          ? { ...f, value: [f.value[0].startDate, f.value[0].endDate] }
          : f,
      );
    };

    const formatUserFilters = (filters: any[]) => {
      return filters.filter((f) => {
        if (f.filterName === 'user' && f.value.includes('all')) {
          return false;
        }
        return true;
      });
    };

    const condition = advancedFilters?.[0]?.conditional?.toUpperCase();

    if (advancedFilters === undefined)
      return {
        condition,
        filters: [],
      };

    return {
      condition,
      filters: formatDateFilters(formatUserFilters(advancedFilters)),
    };
  };

  const getAndSetSpreadsheetData = async (term = '', offset?: number) => {
    const { logs, logsCount } = await getSpreadsheetDataApiCall(term, offset);

    setRows(formatRows(logs));
    setTotalCount(logsCount);
    return { logs, logsCount };
  };

  const getSpreadsheetDataApiCall = async (term = '', offset?: number) => {
    const submissions = await getSpreadsheetSubmissions({
      templateId: template?.id,
      postData: {
        advanceFilters: validateAdvancedFilter(advancedFilters)
          ? formatAdvancedFiltersForApi(advancedFilters)
          : [],
        advanceItemFilters: validateAdvancedItemFilter(advancedItemFilters)
          ? {
              condition: advancedFilters?.length
                ? advancedFilters[0].conditional.toUpperCase()
                : 'AND',
              filters: advancedItemFilters,
            }
          : [],
        limit: PAGE_SIZE,
        ...(term && { searchString: term }),
        ...(offset && { offset }),
      },
    });
    return {
      logs: submissions?.data?.logs ?? [],
      itemLabels: submissions?.data?.itemLabels ?? [],
      logsCount: submissions?.data?.logsCount ?? 0,
    };
  };

  const fetchSpreadsheetData = async (loading = true, term = '') => {
    if (loading) {
      setFetching(true);
    }
    const { itemLabels, logs, logsCount } = await getSpreadsheetDataApiCall(
      term,
    );

    setTotalCount(logsCount);
    setRows(formatRows(logs));
    setOffset(logs.length);
    const columns = itemLabels;
    const config = await getColumnConfig();
    let syncedConfig = syncColumns({
      columns,
      columnsConfig: config,
    });
    const isSync = compareColumns({ columns, columnsConfig: config });
    // if no config present or columns are out of sync
    if (!config || !isSync) {
      syncedConfig = await editColumnConfig(syncedConfig);
    }
    setColumnsConfig(syncedConfig);
    setColumns(itemLabels);
    setFetching(false);
  };

  const getColumnConfig = async () => {
    const columnsConfig = await getUserConfig(
      `spreadsheetConfig:${workspaceId}:${template?.id}`,
    );
    return columnsConfig?.config?.config?.columns ?? null;
  };

  const handleArchiveApi = async (logIds: string[]) => {
    await deleteChecklistLogs({
      checklistId: template?.id,
      hotelId: workspaceId,
      postData: {
        logIds,
      },
    });
  };

  const handleArchiveLogs = async (logIds: string[]) => {
    await handleArchiveApi(logIds);
    setSelectedSubmission(null);
    setShouldUpdate();
    setSelectedRows([]);
  };

  const handleClickOpenLog = async (logId: string) => {
    const log = await getChecklistLog({
      logId,
      hotelId: workspaceId,
      filtered: true,
      sectioned: true,
    });
    setSelectedSubmission(log?.data);
  };

  const editColumnConfig = async (columns) => {
    const configObj = {
      type: `spreadsheetConfig:${workspaceId}:${template?.id}`,
      config: {
        columns,
      },
    };
    const columnsConfig = await editUserConfig(configObj);
    return columnsConfig?.config?.config?.columns ?? null;
  };

  const compareColumns = ({ columnsConfig, columns }) => {
    return (
      columns.every((col) =>
        columnsConfig?.some(
          (conf) => conf.id === col.id && conf.description === col.description,
        ),
      ) &&
      !columnsConfig?.some((conf) => !columns.find((col) => col.id === conf.id))
    );
  };

  const syncColumns = ({ columnsConfig, columns }) => {
    // compare columnsConfig and columns to see if columnsConfig needs to be updated
    if (columnsConfig) {
      const isSync = compareColumns({
        columnsConfig,
        columns,
      });
      if (!isSync) {
        const newConfig = getSyncedConfig({ columns, columnsConfig });
        return newConfig;
      }
      return columnsConfig;
    }
    // if there is no columnsConfig then just create a new columnsConfig
    else if (columns) {
      return columns.map((c) => ({
        ...c,
        visible: true,
      }));
    }
  };

  const getSyncedConfig = ({ columnsConfig, columns }) => {
    const columnConfigSet = new Set();

    columnsConfig?.forEach((c) => columnConfigSet.add(c.id));
    columns?.forEach((c) => columnConfigSet.add(c.id));

    const finalConfig: any[] = [];
    const newCols: any[] = [];
    const setArray = Array.from(columnConfigSet);
    setArray.forEach((c) => {
      // check if exists in columnsConfig
      const foundInConfig = columnsConfig.find((config) => config.id === c);
      const foundInColumns = columns.find((col) => col.id === c);

      if (foundInConfig && foundInColumns) {
        finalConfig.push({
          description: foundInColumns?.description,
          id: foundInConfig?.id,
          visible: foundInConfig?.visible,
        });
      } else if (foundInColumns) {
        newCols.push({
          ...foundInColumns,
          visible: true,
        });
      }
    });

    return [...finalConfig, ...newCols];
  };

  const loadNextPage = async () => {
    const hasNext = rows.length < totalCount;
    const newOffset = offset + PAGE_SIZE;
    if (hasNext && !loadingMore) {
      setLoadingMore(true);
      setOffset(newOffset);
      const { logs, logsCount } = await getSpreadsheetDataApiCall(
        searchTerm,
        offset,
      );
      setRows([...rows, ...formatRows(logs)]);
      setTotalCount(logsCount);
      setLoadingMore(false);
    }
  };

  const handleDeleteAllAdvancedFilters = () => {
    setAdvancedFilters(undefined);
    setAdvancedItemFilters(undefined);
  };

  const showStartConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Publish Template',
          description:
            'In order to Start or Assign/Schedule this template you must Publish it.',
          confirmationText: 'Go to Builder',
          onConfirmCallback: () => {
            navigateWithWorkspaceUrl(`/checklist/${template?.id}`);
          },
        },
      }),
    );
  };

  const handleClickStartButton = () => {
    if (template?.isPublished) {
      navigateWithWorkspaceUrl(
        `/in-progress-checklist/${
          template.id
        }?prevUrl=${`/templates/${template.id}?tab=2`}`,
      );
    } else {
      showStartConfirmation();
    }
  };

  const areAllAdvancedFiltersEmpty = useMemo(
    () =>
      (advancedFilters === undefined || advancedFilters?.length === 0) &&
      (advancedItemFilters === undefined || advancedItemFilters?.length === 0),
    [advancedFilters, advancedItemFilters],
  );

  const isEmptySearch = useMemo(
    () => !areAllAdvancedFiltersEmpty || !!searchTerm,
    [areAllAdvancedFiltersEmpty, searchTerm],
  );

  const isEmptyData = useMemo(
    () => areAllAdvancedFiltersEmpty && !searchTerm,
    [areAllAdvancedFiltersEmpty, searchTerm],
  );

  const context = {
    template,
    showDownloadOptions,
    selectedSubmission,
    selectedRows,
    columns,
    rows,
    columnsConfig,
    advancedFilters,
    advancedItemFilters,
    searchTerm,
    setShowDownloadOptions,
    setSelectedSubmission,
    setSelectedRows,
    handleArchiveLogs,
    handleClickOpenLog,
    editColumnConfig,
    setColumnsConfig,
    setAdvancedFilters,
    setAdvancedItemFilters,
    setSearchTerm,
    loadNextPage,
    handleDeleteAllAdvancedFilters,
    handleClickStartButton,
  };

  return (
    <SpreadsheetContext.Provider value={context}>
      <ViewWrapper>
        <Header viewMode={viewMode} />
        {rows.length === 0 && !fetching && isEmptySearch && <EmptySearch />}
        {rows.length === 0 && !fetching && isEmptyData && <EmptyData />}
        {rows.length > 0 && !fetching && <Table height={tableHeight} />}
        {fetching && (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}
      </ViewWrapper>
      {selectedRows?.length > 0 && (
        <TotalRow>
          <span className="normal">Total:</span>
          <span className="bold">{selectedRows.length}</span>
        </TotalRow>
      )}
    </SpreadsheetContext.Provider>
  );
};

export default Spreadsheet;
