import styled from '@mui/system/styled';

export const FiltersCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '20px 0px',
  gap: 15,

  '& button': {
    backgroundColor: 'unset',
    border: '1px solid #E0E0E0',
    borderRadius: 6,
  },
});
