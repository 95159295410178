import { FolderItemCnt } from 'pages/Templates/Folders/foldersItem/folderItem.style';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import Typography from '@mui/material/Typography';
import FolderActionDropdown from 'pages/Templates/actionDropdowns/folderActionDropdown/folderActionDropdown';
import React from 'react';
import { Grid } from '@mui/material';
import ArchiveFolderActionDropdown from 'pages/Templates/actionDropdowns/folderActionDropdown/archiveFolderActionDropdown';
import { PERMISSIONS } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

interface IFolderActionDropdownProps {
  updateFolderCallback: (folder: any) => void;
  deleteFolderCallback: (folderId: string) => void;
}
interface IFolderItemProps {
  folder: any;
  actionDropdownProps: IFolderActionDropdownProps;
  isArchive: boolean;
  onClick: (folder: any) => void;
}
function FolderItem(props: IFolderItemProps) {
  const { folder, actionDropdownProps, isArchive, onClick } = props;
  const { hasPermission } = useCustomPermission();
  const checklistPermission = hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST);
  return (
    <Grid item xs={12} md={6} lg={3} onClick={() => onClick(folder)}>
      <FolderItemCnt>
        <TemplateFolderIcon
          primaryColor={folder?.meta?.primaryColor}
          secondaryColor={folder?.meta?.secondaryColor}
          sx={{ fontSize: 34 }}
        />
        <div className={'folderInfoCnt'}>
          <Typography variant={'h4'} className={'folderName'}>
            {folder?.title}
          </Typography>
          <Typography variant={'subtitle2'} className={'templateCount'}>
            {folder?.itemsCount || 0} items
          </Typography>
        </div>
        {checklistPermission ? (
          isArchive ? (
            <ArchiveFolderActionDropdown
              folder={folder}
              {...actionDropdownProps}
            />
          ) : (
            <FolderActionDropdown
              folder={folder}
              {...actionDropdownProps}
              isArchive={isArchive}
            />
          )
        ) : null}
      </FolderItemCnt>
    </Grid>
  );
}

export default FolderItem;
