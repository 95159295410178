import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DocumentsCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0px 0 0px',
}));

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  marginTop: 100,
});

export const TabsCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 24px 0 24px',
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '8px 12px 0 12px',
  },
}));
