import styled from '@mui/system/styled';

export const ReportingHeaderCnt = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 30px',
  '& button .requestReportIcon': {
    fontSize: '20px',
    color: '#fff',
  },
});

export const HeaderHeading = styled('div')({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '32px',
});
