import { useMemo } from 'react';
import { Typography } from '@mui/material';
import useDateTime from 'utils/CustomHooks/useDateTime';

import { UpcomingServiceBannerCnt } from './assetDetailTab.style';
import CustomButton from 'components/Button/CustomButton';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import ServiceIcon from 'components/Icons/serviceIcon';

interface IProps {
  nextService?: any;
}

const UpcomingServiceBanner = (props: IProps) => {
  const { nextService } = props;
  const { getUtcToTz } = useDateTime();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const nextServiceDateTime = useMemo(() => {
    if (nextService?.date) {
      const TZDateTime = getUtcToTz(nextService?.date, 'MMM DD, YYYY  h:mm A');
      return TZDateTime;
    }
  }, [nextService?.date]);

  const viewDetailHandler = () => {
    navigateWithWorkspaceUrl(
      `/assets/${nextService?.AssetId}?tab=3&service=${nextService?.id}`,
    );
  };

  return (
    <UpcomingServiceBannerCnt direction="row" alignItems="center">
      <ServiceIcon className="icon" />
      <Typography className="info">
        Upcoming Service on {nextServiceDateTime}
      </Typography>

      <CustomButton
        variant="text"
        className="viewDetails"
        onClick={viewDetailHandler}
      >
        View Details
      </CustomButton>
    </UpcomingServiceBannerCnt>
  );
};

export default UpcomingServiceBanner;
