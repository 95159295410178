const useTasksFiltersConditions = () => {
  const recurringCompletedOnTime = () => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['Completed'],
      },
      {
        comparator: 'isNot',
        conditional: 'and',
        filterName: 'isOverDue',
        value: ['Yes'],
      },
    ];

    return filters;
  };

  const recurringCompletedLate = () => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['Completed'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'isOverDue',
        value: ['Yes'],
      },
    ];

    return filters;
  };

  const recurringInComplete = () => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'isNot',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['Completed'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'isOverDue',
        value: ['Yes'],
      },
    ];

    return filters;
  };

  const recurringOpen = () => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['Open'],
      },
    ];

    return filters;
  };

  const recurringCompleted = () => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['Completed'],
      },
    ];

    return filters;
  };

  const recurringInProgress = () => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['In Progress'],
      },
    ];

    return filters;
  };

  const recurringOnHold = () => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['On Hold'],
      },
    ];

    return filters;
  };

  const recurringMissed = () => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['Missed'],
      },
    ];

    return filters;
  };

  const recurringByLocations = (locations) => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'locations',
        value: locations,
      },
    ];

    return filters;
  };

  const recurringByLocationCompletedOnTime = (locations) => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'locations',
        value: locations,
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['Completed'],
      },
      {
        comparator: 'isNot',
        conditional: 'and',
        filterName: 'isOverDue',
        value: ['Yes'],
      },
    ];

    return filters;
  };

  const recurringByLocationsCompletedOverdue = (locations) => {
    const filters = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'locations',
        value: locations,
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskStatuses',
        value: ['Completed'],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'isOverDue',
        value: ['Yes'],
      },
    ];

    return filters;
  };

  return {
    recurringCompletedOnTime,
    recurringCompletedLate,
    recurringInComplete,
    recurringOpen,
    recurringCompleted,
    recurringInProgress,
    recurringOnHold,
    recurringMissed,
    recurringByLocations,
    recurringByLocationCompletedOnTime,
    recurringByLocationsCompletedOverdue,
  };
};

export default useTasksFiltersConditions;
