// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Chip } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const NameAndBack = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  padding: 16,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid rgba(224, 224, 224, 1)`,
}));

export const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  left: 16,
  fontWeight: 700,
}));

export const TemplateNameText = styled(Typography)({
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginRight: 16,
  maxWidth: '800px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  cursor: 'default',
});
