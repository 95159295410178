// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 151px)',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
  padding: '0 16px',
}));

export const Heading = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: '700',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginBottom: 2,
}));

export const DetailText = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: '0.17px',
  color: 'rgba(0, 0, 0, 0.6)',
  maxWidth: 199,
  textAlign: 'center',
}));
