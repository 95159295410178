import { Stack } from '@mui/material';
import styled from '@mui/system/styled';

import { statusDropdownData } from 'helper/task/dropdownData';

export const ProjectTypeCnt = styled(Stack)({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '5px',

  '& .assetIcon': {
    height: 15,
    width: 15,
    path: {
      stroke: '#4E48FA',
    },
  },

  '& .userIcon': {
    height: 15,
    width: 15,
    path: {
      stroke: '#0900ff',
    },
  },

  '& .projectName': {
    fontWeight: 700,
    fontSize: 11,
    lineHeight: '15.03px',
    color: '#212121',
  },
});

export const ProjectItemCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clickable',
})(({ theme, clickable }) => ({
  border: '1px solid #E0E0E0',
  borderRadius: 8,
  padding: '12px 16px',
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.08)',
  background: '#FFFFFF',
  position: 'relative',
  overflow: 'hidden',
  cursor: clickable ? 'pointer' : '',
  transition: '0.2s',
  ':hover': {
    boxShadow: clickable ? '0px 16px 10px -20px rgba(0,0,0,0.63)' : '',
    transition: '0.2s',
  },
  '.content': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 18,
    },
    '.column': {
      display: 'flex',
      gap: 4,
      flexDirection: 'column',
      '.title': {
        color: '#000000',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '19.12px',
      },
    },
    '& .right': {
      height: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  '& .value': {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16.39px',
    color: '#000000',
  },
}));

export const InfoChip = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  '.infoChipIcon': {
    display: 'flex',
  },
  '.infoChipText': {
    color: '#424242',
    fontSize: 12,
    fontWeight: 400,
  },
});
