// React
import { useState, useEffect, useMemo } from 'react';

// React Router
import { useSearchParams } from 'react-router-dom';

// Components
import PortalSettings from './PortalSettings';
import ConfigSummary from './components/ConfigSummary';
import RequestsIcon from 'components/Icons/requestsIcon';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

// MUI
import { Box, Stack } from '@mui/material';
import { CircularProgress } from '@mui/material';

// Utils
import xeniaApi from 'api/index';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// Context
import { RequestConfigContext, RequestConfigType } from './context';

const PublicRequestConfig = () => {
  const { hasFeature, FEATURES } = useCustomPermission();
  const [config, setConfig] = useState<RequestConfigType | null>(null);
  const [localConfig, setLocalConfig] = useState<RequestConfigType | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const configId = useMemo(
    () => searchParams.get('configId') ?? undefined,
    [searchParams],
  );

  const fetchConfig = async () => {
    try {
      const data = await xeniaApi.getPublicRequestConfig(undefined, configId);
      if (data) {
        setConfig(data.config);
        setLocalConfig(data.config);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // update any key in the config helper
  const updateConfig = async (key: string, data: any) => {
    try {
      const updatedData = {
        ...(localConfig ?? {}),
        [key]: data,
      };
      setLocalConfig(updatedData as any);
      const updatedConfig = await xeniaApi.patchPublicRequestConfig({
        configId: config?.id as string,
        patchData: {
          [key]: data,
        },
      });
      setConfig(updatedConfig);
    } catch (error) {
      setLocalConfig(config);
    }
  };

  // update visibility for form fields and map to database schema
  const handleUpdateFieldVisibility = async (
    field: string,
    visibility: any,
  ) => {
    const visibilityMap = {
      Required: {
        required: true,
        show: true,
      },
      Optional: {
        required: false,
        show: true,
      },
      Hidden: {
        required: false,
        show: false,
      },
    };
    await updateConfig('fields', {
      ...localConfig?.fields,
      [field]: visibilityMap[visibility],
    });
  };

  useEffect(() => {
    if (hasFeature(FEATURES.PUBLIC_WORK_ORDER_REQUEST)) {
      fetchConfig();
    } else {
      setLoading(false);
    }
  }, []);

  const updateConfigState = (data) => {
    setLocalConfig(data);
    setConfig(data);
  };

  const contextValue = {
    config: localConfig,
    updateConfig,
    handleUpdateFieldVisibility,
    updateConfigState,
  };

  return (
    <RequestConfigContext.Provider value={contextValue}>
      <Box sx={{ pt: '4px' }}>
        {loading && (
          <Stack
            width={'100%'}
            height={'300px'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <CircularProgress />
          </Stack>
        )}

        {!loading && !hasFeature(FEATURES.PUBLIC_WORK_ORDER_REQUEST) && (
          <VerifyFeatureAccess featureId={FEATURES.PUBLIC_WORK_ORDER_REQUEST}>
            <Box style={{ margin: 'auto 50px' }}>
              <ConfigSummary
                icon={<RequestsIcon sx={{ '& path': { fill: 'none' } }} />}
                featureLocked={!hasFeature(FEATURES.PUBLIC_WORK_ORDER_REQUEST)}
                configName="Public Work Request Portal"
                description={
                  <span>
                    Configure your publicly shareable request portal and
                    customize fields available within the work request form.
                    <a
                      style={{
                        fontWeight: 700,
                        fontSize: 13,
                        marginLeft: '2px',
                      }}
                      href={
                        'https://help.xenia.team/hc/en-us/categories/15486235456667-Work-Requests'
                      }
                      target="_blank"
                    >
                      Learn More
                    </a>
                  </span>
                }
              />
            </Box>
          </VerifyFeatureAccess>
        )}

        {!loading && hasFeature(FEATURES.PUBLIC_WORK_ORDER_REQUEST) && (
          <PortalSettings />
        )}
      </Box>
    </RequestConfigContext.Provider>
  );
};

export default PublicRequestConfig;
