// React
import { useState, useMemo, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Utilities
import { Utils } from './utils';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

export const useMetaDetail = ({
  handleGoNextMetadata,
  isXeniaAdmin,
  isEditing,
  handleCloseModal,
  checklist,
}) => {
  const adminMetadataType = Utils.adminMetadataTypes;
  const userMetadataType = Utils.adminMetadataTypes;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  // Selectors
  const types: any = useSelector(selectors.getChecklistTypes);
  const industries: any = useSelector(selectors.getChecklistIndustries);

  // States
  const Util = new Utils({ types, industries, isXeniaAdmin });
  const [metadata, setMetadata] = useState<
    typeof adminMetadataType | typeof userMetadataType
  >(isXeniaAdmin ? Util.adminMetadata : Util.userMetaData);
  const [isIconPickerOpen, setIsIconPickerOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // upload image
  const handleUpload = (acceptedFiles: any, key: 'image' | 'attachments') => {
    if (acceptedFiles && acceptedFiles.length) {
      const oldFiles = metadata?.attachments?.length
        ? metadata.attachments
        : [];
      const uploadedFiles = Util.handleUploadImage(
        acceptedFiles,
        key === 'attachments' ? true : false,
      );

      setMetadata({
        ...metadata,
        [key]:
          key === 'attachments'
            ? [...oldFiles, ...uploadedFiles]
            : uploadedFiles,
      });
    }
  };

  // check if user can go next
  const nextDisabled = useMemo(() => {
    return Util.isNextDisabled(metadata);
  }, [metadata]);

  // set local state
  const handleChangeMetadata = (key, value) => {
    setMetadata({
      ...metadata,
      [key]: value,
    });
  };

  // handle go next button
  const handleClickNext = async (folderId) => {
    setIsLoading(true);
    const data = await Util.goNext(metadata);
    setIsLoading(false);
    handleGoNextMetadata(data, !isEditing);
  };

  // handle click cancel button
  const handleClickCancel = () => {
    if (isEditing) {
      handleCloseModal();
    } else {
      navigateWithWorkspaceUrl('/checklist');
    }
  };

  // remove image
  const handleRemoveImage = () => {
    setMetadata({
      ...metadata,
      image: null,
    });
  };

  // handle delete checklist attachment
  const handleDeleteChecklistAttachment = (index) => {
    setMetadata({
      ...metadata,
      attachments: Util.handleDeleteChecklistAttachment(metadata, index),
    });
  };

  // handle navigation of checklist preview
  const navigateAttachmentPreview = (index) => {
    setSelectedAttachment(index);
  };

  // hide attachment preview
  const hideAttachmentPreview = () => {
    setSelectedAttachment(null);
  };

  // set local state if checklist is in editing state
  useEffect(() => {
    setMetadata(Util.setDefaultChecklistState(checklist));
  }, [checklist]);

  return {
    metadata,
    setMetadata,
    nextDisabled,
    isIconPickerOpen,
    selectedAttachment,
    industries,
    types,
    isLoading,
    setIsIconPickerOpen,
    handleUpload,
    handleRemoveImage,
    handleChangeMetadata,
    handleClickNext,
    handleClickCancel,
    handleDeleteChecklistAttachment,
    navigateAttachmentPreview,
    hideAttachmentPreview,
  };
};
