import { Stack, Typography } from '@mui/material';
import ProjectSchedulesSearch from '../search';
import CustomButton from 'components/Button/CustomButton';
import { addScheduleInProject, togglePause } from 'api/projectsApi';
import PauseIcon from '@mui/icons-material/Pause';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AddIcon from 'components/Icons/addIcon';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useMemo, useState } from 'react';
import { AssetsCnt, MainCnt } from './style';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { PROJECT_TYPES } from 'utils/globalVariable';
import AssetsMultiSelect from 'components/AssetsDropdown/MultipleSelection';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import useUsers from 'utils/CustomHooks/useUsers';
import { PERMISSIONS } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const SchedulesTableHeader = (props) => {
  const {
    project,
    toggleUpdateHandlerCallback,
    getProjectSchedules,
    getSchedulesStats,
    getProjectDetails,
  } = props;
  const dispatch = useDispatch();
  const { getBacklistUsersByPermission } = useUsers();
  const { config } = useWorkspaceConfig();
  const [selected, setSelected] = useState<any>([]);
  const [addingSchedule, setAddingSchedule] = useState(false);

  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const canPauseResumeProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_PAUSE_RESUME_PROJECT);
  }, [PERMISSIONS]);

  const canEditProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_EDIT_PROJECT);
  }, [PERMISSIONS]);

  const PauseAllTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to pause all schedules in this project?
      </Typography>
    );
  };

  const togglePauseHandler = async (isPaused) => {
    if (isPaused) {
      dispatch(
        setDialog({
          dialogId: 'confirmationDialog',
          open: true,
          data: {
            title: 'Pause All Schedules',
            ContentTemplate: PauseAllTemplate,
            onConfirmCallback: async () => {
              await togglePause(project?.id, project?.title);
              toggleUpdateHandlerCallback(isPaused);
            },
            confirmationText: 'Pause All',
          },
        }),
      );

      return;
    }
    await togglePause(project?.id, project?.title);

    toggleUpdateHandlerCallback(isPaused);
  };

  const handleUpdateMultipleAsset = async (options) => {
    if (options?.length > 0) {
      const payload = {
        entityId: options[options?.length - 1]?.id,
      };

      setAddingSchedule(true);

      const response = await addScheduleInProject(project?.id, payload);
      if (response) {
        await getProjectDetails(false);
        await getProjectSchedules(false);
        await getSchedulesStats();
      }

      setAddingSchedule(false);
    }
  };

  const addScheduleBasedOnUser = async (selectedData) => {
    const payload = {
      entityId: selectedData?.currentSelected,
    };

    setSelected(selectedData?.selectedList);
    setAddingSchedule(true);
    const response = await addScheduleInProject(project?.id, payload);
    if (response) {
      await getProjectDetails(false);
      await getProjectSchedules(false);
      await getSchedulesStats();

      setSelected({
        ...selected,
        locations: [],
      });
    }

    setAddingSchedule(false);
  };

  const usersBlacklist = useMemo(() => {
    return (
      getBacklistUsersByPermission(PERMISSIONS.CAN_CHANGE_TASK_STATUS, true) ??
      []
    );
  }, []);

  return (
    <MainCnt>
      <ProjectSchedulesSearch />

      <Stack direction="row" alignItems="center" gap="10px">
        {canPauseResumeProject ? (
          !project?.isPaused ? (
            <CustomButton
              startIcon={<PauseIcon />}
              variant="outlined"
              onClick={() => togglePauseHandler(true)}
              style={{
                padding: '6px 10px',
                borderRadius: '8px',
                fontWeight: 600,
              }}
            >
              Pause All
            </CustomButton>
          ) : (
            <CustomButton
              startIcon={<PlayArrowIcon />}
              variant="contained"
              onClick={() => togglePauseHandler(false)}
              sx={{
                backgroundColor: '#4CAF50',
                '&:hover': {
                  backgroundColor: '#4CAF50',
                },
              }}
              style={{
                padding: '7px 16px',
                borderRadius: '8px',
                fontWeight: 600,
              }}
            >
              Resume All
            </CustomButton>
          )
        ) : (
          <></>
        )}

        {canEditProject && project?.type === PROJECT_TYPES.userBasedProject && (
          <AssigneeDropdown
            buttonRenderer={
              <CustomButton
                startIcon={<AddIcon />}
                variant="contained"
                style={{
                  padding: '7px 16px',
                  borderRadius: '8px',
                  fontWeight: 600,
                }}
              >
                Add Assignee
              </CustomButton>
            }
            blacklistedIds={[...(project?.entityIds || []), ...usersBlacklist]}
            handleChangeCallback={addScheduleBasedOnUser}
            showTabs={true}
            listProps={{
              all: {
                showInlineSelection: true,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                placeholder: 'Type to Search',
                partition: true,
                selected: [
                  ...(selected?.people ?? []),
                  ...(selected?.teams ?? []),
                  ...(selected?.locations ?? []),
                ],
              },
              people: {
                showInlineSelection: true,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                partition: true,
                selected: selected?.people ?? [],
              },
              teams: {
                showInlineSelection: true,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                selected: selected?.teams ?? [],
              },
              ...(config?.advancedLocationBasedAssignment && {
                locations: {
                  disableUnAuth: true,
                  autoTagLocation: false,
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  selected: selected?.locations ?? [],
                  addLocationByTag: true,
                  isSelectAll: false,
                },
              }),
            }}
            displayOptions={{
              all: true,
              people: true,
              teams: true,
              locations: config?.advancedLocationBasedAssignment,
            }}
            popperProps={{
              disablePortal: false,
              sx: {
                '& .MuiList-root': {
                  ...(addingSchedule && {
                    pointerEvents: 'none',
                  }),
                },
                '& .MuiBox-root': {
                  ...(addingSchedule && {
                    pointerEvents: 'none',
                  }),
                },
              },
              style: { zIndex: 1500 },
            }}
            allSelected={false}
          />
        )}

        {canEditProject && project?.type === PROJECT_TYPES.assetBasedProject && (
          <AssetsCnt
            sx={{
              '& button': {
                borderRadius: '8px',
                fontWeight: 600,
              },
            }}
          >
            <AssetsMultiSelect
              onChangeCallback={(options) => handleUpdateMultipleAsset(options)}
              selected={project?.entityIds}
              dropdownProps={{
                blacklistedIds: project?.entityIds,
              }}
              definedSelectedText={'Add Assets'}
              startIcon={<AddIcon />}
              onClick={(e) => e.stopPropagation()}
              popperPropsStylesOnly={{
                sx: {
                  '& .MuiList-root': {
                    ...(addingSchedule && {
                      pointerEvents: 'none',
                    }),
                  },
                },
              }}
            />
          </AssetsCnt>
        )}
      </Stack>
    </MainCnt>
  );
};

export default SchedulesTableHeader;
