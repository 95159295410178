import { IconButton } from '@mui/material';
import styled from '@mui/system/styled';

export const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: 14,
  top: 20,
  cursor: 'pointer',
  height: 26,
  width: 32,
  paddingLeft: 5,
});
