// MUI components
import { styled } from '@mui/material/styles';

import { Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const WrapperDiv = styled('div')({
  display: 'flex',
  height: 'calc(100vh - 245px)',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
});

export const IconWrapper = styled(Typography)({
  width: 120,
  height: 120,
  borderRadius: 120,
  background: 'rgba(235, 234, 255, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    fontSize: 64,
  },
});

export const NoResultsTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '25px',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  margin: '8px 0px',
});

export const NoResultsDescriptionWrapper = styled('div')({
  display: 'flex',
  width: 494,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: 0.17,
  marginBottom: 20,
});

export const NoResultsDescription = styled(Typography)({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21.86px',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.6)',
});

export const Button = styled(CustomButton)({
  borderRadius: 6,
});
