// React
import React, { useContext, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import DownloadOptions from 'components/AllDialogs/logs/DownloadOptions';

// Icons
import { MoreHoriz } from '@mui/icons-material';
import ArchiveIcon from 'components/Icons/archiveIcon';
import DownloadIcon from 'components/Icons/downloadIcon';

// Context
import { SubmissionContext } from 'pages/TemplateDashboard/Tabs/Submissions/context';

// Constants
import DIALOGS from 'utils/dialogIds';

// Hooks
import useLogActions from 'pages/TemplateDashboard/Tabs/Submissions/hooks/useLogActions';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import LinkIcon from '@mui/icons-material/Link';

const OptionsDropdown = ({ log }: { log: SubmissionReportType }) => {
  const dispatch = useDispatch();
  const ctx = useContext(SubmissionContext);

  const { downloading, handleDownloadReportApi } = useLogActions();

  const [showDownloadConfirmation, setShowDownloadConfirmation] =
    useState(false);

  const handleOpenConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.CONFIRMATION,
        open: true,
        data: {
          title: 'Archive Submission',
          description:
            'Are you sure you want to archive the selected submission?',
          confirmButtonProps: {
            color: 'error',
          },
          onConfirmCallback: () => ctx?.handleArchiveLogs([log]),
        },
      }),
    );
  };

  const handleCopyLink = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.SUBMISSION_SHARE_DIALOG,
        open: true,
        data: {
          shareUrl: `${window.location.origin}/ws/${log.HotelId}/${log?.ChecklistId}/${log?.id}`,
          templateName: log?.name,
        },
      }),
    );
  };

  const handleOptionSelect = (option, e) => {
    switch (option.id) {
      case 'download':
        setShowDownloadConfirmation(true);
        return;
      case 'copyLink':
        handleCopyLink();
        break;
      case 'archive':
        handleOpenConfirmation();
        return;

      default:
        break;
    }
  };

  const handleDownloadReport = async (option: any) => {
    await handleDownloadReportApi({
      logIds: [log?.id],
      option: option.id,
      status: [],
    });
    setShowDownloadConfirmation(false);
  };

  const getOptions = () => {
    const opts = [
      {
        label: 'Download',
        id: 'download',
        sx: {
          color: 'rgba(0, 0, 0, 0.87)',
          '& svg': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        iconAlign: 'start',
        icon: <DownloadIcon />,
      },
      {
        label: 'Archive',
        id: 'archive',
        sx: {
          color: 'rgba(239, 83, 80, 1)',
          '& svg': {
            color: 'rgba(239, 83, 80, 1)',
          },
        },
        iconAlign: 'start',
        icon: <ArchiveIcon />,
      },
    ];

    if (log?.status === 'In Progress')
      opts.splice(1, 0, {
        label: 'Copy Link',
        id: 'copyLink',
        sx: {
          color: 'rgba(0, 0, 0, 0.87)',
          '& svg': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        iconAlign: 'start',
        icon: <LinkIcon />,
      });

    return opts;
  };

  return (
    <React.Fragment>
      {showDownloadConfirmation && (
        <DownloadOptions
          multiple={false}
          open={showDownloadConfirmation}
          handleClose={() => setShowDownloadConfirmation(false)}
          onSelect={handleDownloadReport}
          loading={downloading}
        />
      )}
      <ActionDropdown
        buttonProps={{
          sx: {
            width: 24,
            height: 24,
          },
        }}
        options={getOptions()}
        handleOptionSelect={handleOptionSelect}
      >
        <MoreHoriz />
      </ActionDropdown>
    </React.Fragment>
  );
};

export default OptionsDropdown;
