import { Stack, Typography } from '@mui/material';

import NoRecordsFound from 'components/Icons/noRecordFoundIcon';

const GlobalEmptyResult = () => {
  return (
    <Stack alignItems={'center'}>
      <NoRecordsFound style={{ width: 307, height: 231 }} />
      <Typography
        fontSize={'18px'}
        fontWeight={'700'}
        lineHeight={'25px'}
        textAlign={'center'}
        color={'rgba(0, 0, 0, 0.87)'}
        margin={'8px 0px'}
      >
        We couldn’t find what you’re looking for.
      </Typography>
      <Typography
        fontSize={'16px'}
        fontWeight={'400'}
        lineHeight={'21.86px'}
        textAlign={'center'}
        color={'rgba(0, 0, 0, 0.6)'}
      >
        We couldn’t find what you’re looking for. Please try different criteria.
      </Typography>
    </Stack>
  );
};

export default GlobalEmptyResult;
