import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, CircularProgress } from '@mui/material';
import ScheduleDetailHeader from './Header';
import ScheduleDetailView from './Details';
import ScheduleTasks from './Tasks';
import { LoaderCnt, MainContainer } from './style';
import { getParentSchedules } from 'api/schedulesApi';

const ScheduleDetails = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState({});

  const [value, setValue] = useState('Details');
  const handleChange = (_, newValue: any) => {
    setValue(newValue);
  };

  const getScheduleDetails = async (load = true) => {
    if (load) {
      setLoading(true);
    }

    const scheduleData = await getParentSchedules({
      advanceFilters: {
        condition: 'AND',
        filters: [
          {
            comparator: 'is',
            filterName: 'schedules',
            value: [params?.scheduleId],
          },
        ],
      },
      offset: 0,
      limit: 9999999999,
    });

    setSchedule(scheduleData?.rows?.[0] ?? {});

    if (load) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getScheduleDetails();
  }, []);

  return (
    <MainContainer>
      {loading ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : (
        <>
          <ScheduleDetailHeader
            schedule={schedule}
            getScheduleDetails={getScheduleDetails}
          />
          <Tabs
            sx={{
              padding: '0px 24px',
              borderBottom: '1px solid #E0E0E0',
              width: '100%',
              '& button.MuiTab-root': {
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '14px',
                p: '11px 6px',
              },
            }}
            value={value}
            onChange={handleChange}
          >
            <Tab value={'Details'} label="Details" />
            <Tab value={'Tasks'} label="Tasks" />
          </Tabs>
          {value === 'Details' && <ScheduleDetailView data={schedule} />}
          {value === 'Tasks' && <ScheduleTasks schedule={schedule} />}
        </>
      )}
    </MainContainer>
  );
};

export default ScheduleDetails;
