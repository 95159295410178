import { createSvgIcon } from '@mui/material';

const MagicLinkIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M13 3V1M13 15V13M6 8H8M18 8H20M15.8 10.8L17 12M15.8 5.2L17 4M1 20L10 11M10.2 5.2L9 4"
        stroke="#4E48FA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'MagicLinkIcon',
  );
  return (
    <IconCmp
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    />
  );
};
export default MagicLinkIcon;
