import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
const emptyStateCntMixing = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: 'calc(100vh - 198px)',
  width: '100%',
  '& h3': {
    fontSize: 19,
    fontWeight: 700,
    lineHeight: '25.95px',
    marginBottom: 9,
  },
  '& p': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21.86px',
    marginBottom: 24,
    color: '#00000099',
    textAlign: 'center',
  },
  '.addIconBtn': {
    width: 60,
    height: 60,
    borderRadius: '50%',
    background: '#6868FE',
    '& .MuiSvgIcon-root': {
      color: '#fff',
      fontSize: 30,
    },
  },
};

export const RequestContentCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '0 24px',
  height: 'calc(100vh - 128px)',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: 0,
    height: 'calc(100vh - 73px)',
  },
}));
export const RequestsLoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});

export const CreateRequestInfoStyled = styled(Box)(({ theme, color }) => ({
  width: '50%',
  position: 'absolute',
  top: '35%',
  left: 74,
  transform: 'translate(0, -35%)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    left: 'unset',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  '.container': {
    gap: 70,
    '.infoContainer': {
      flex: 1,
      '.title': {
        fontWeight: 800,
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.87)',
        textAlign: 'left',
        lineHeight: '160%',
        [theme.breakpoints.down('lg')]: {
          fontSize: 20,
        },
      },
      '.description': {
        textAlign: 'left',
        marginTop: 13,
        fontWeight: 500,
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.6)',
        [theme.breakpoints.down('lg')]: {
          fontSize: 16,
        },
      },
      '.taskToSalesText': {
        textAlign: 'left',
        marginTop: 16,
        fontWeight: 700,
        fontSize: 18,
        color: '#5555FF',
        [theme.breakpoints.down('lg')]: {
          fontSize: 16,
        },
      },
      '.buttonsContainer': {
        marginTop: 28,
      },
    },
    '.thumbnailContainer': {
      width: 400,
      cursor: 'pointer',
    },
  },
}));

export const RequestsNoAccessEmptyStateCnt = styled(Box)(({ theme, color }) => {
  return {
    background: 'url(/assets/img/requestsEmptyState.svg) no-repeat 100% 1px',
    backgroundSize: 'contain',
    ...emptyStateCntMixing,
    [theme.breakpoints.down('md')]: {
      background: 'none',
    },
  };
});
export const RequestsEmptyStateCnt = styled(Box)(() => emptyStateCntMixing);
