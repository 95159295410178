// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Button } from '@mui/material';

export const NotificationCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  padding: '16px 0px 8px 0px',
  background: '#fff',
  borderRadius: 24,
  boxShadow:
    '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)',
  width: 700,
  height: '100%',
  position: 'relative',
}));

export const MarkAllAsReadButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  position: 'absolute',
  right: 16,
  top: 24,
  '& .MuiButton-startIcon': {
    marginRight: 6,
    marginLeft: 0,
  },
  '&:disabled': {
    '& svg path': {
      fill: `rgba(0,0,0,0.26)`,
    },
  },
}));
