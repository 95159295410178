// MUI Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';

// Custom Icons
import EditIcon from 'components/Icons/editIcon';
import ArchiveIcon from 'components/Icons/archiveIcon';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import AddToFolderIcon from 'components/Icons/addToFolderIcon';
import TemplateShareIcon from 'components/Icons/templateShareIcon';
import DuplicateIcon from 'components/Icons/duplicateIcon';
import LinkExternalIcon from 'components/Icons/linkExternal';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GlobeIcon from 'components/Icons/globeIcon';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import DisabledGlobeIcon from 'components/Icons/disabledGlobeIcon';
import { useSnackbar } from 'notistack';

const DocumentActions = ({
  addDocumentCallback,
  updateDocumentCallback,
  deleteDocumentCallback,
  onAddDocumentToFolder,
  selectedFolder = '',
  document = {},
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    updateDocument,
    archiveDocument,
    publishDocument,
    unpublishDocument,
    createDocumentDuplicate,
  } = useDocumentsFolders();

  const handleMoveToFolder = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.DOCUMENT_MOVE_TO_FOLDER_DIALOG,
        data: {
          document,
          onSuccessCallback: deleteDocumentCallback,
        },
      }),
    );
  };

  const handleAddToFolder = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.DOCUMENT_ADD_TO_FOLDER_DIALOG,
        data: {
          document: document,
          onSuccessCallback: onAddDocumentToFolder,
        },
      }),
    );
  };

  const handleDocumentAccess = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.ACCESS_DOCUMENT_DIALOG,
        data: {
          document: document,
          onSuccessCallback: updateDocumentCallback,
        },
      }),
    );
  };

  const handleRenameFile = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.RENAME_DOCUMENT_DIALOG,
        data: {
          document: document,
          onSuccessCallback: updateDocumentCallback,
        },
      }),
    );
  };

  const handleCopyLink = () => {
    // If on mobile app then send a message to RN WebView //
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          writeToClipboard: true,
        }),
      );
      return;
    }

    navigator?.clipboard?.writeText(
      `${window.location.origin}/document-detail/${document?.id}`,
    );

    enqueueSnackbar('Document link copied', {
      variant: 'success',
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
  };

  const handleDuplicateCreate = async () => {
    const response = await createDocumentDuplicate(document?.id);
    addDocumentCallback(response);
  };

  const handleArchive = async () => {
    await archiveDocument(document?.id);
    deleteDocumentCallback(document?.id);
  };

  const handlePublish = async () => {
    const response = await publishDocument(document?.id);
    updateDocumentCallback(response);
    if (globalThis?.documentsListView) {
      globalThis?.documentsListView?.api?.redrawRows();
    }
  };

  const handleUnPublish = async () => {
    const response = await unpublishDocument(document?.id);
    updateDocumentCallback(response);
    if (globalThis?.documentsListView) {
      globalThis?.documentsListView?.api?.redrawRows();
    }
  };

  const handleEditFile = async () => {
    const queryParams = !document?.FolderId
      ? '?source=list'
      : `?source=list&folderId=${document?.FolderId}`;

    const urlTab = `${window.location.origin}/document-detail/${document?.id}${queryParams}`;
    window.open(urlTab, '_self');
  };

  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'edit':
        handleEditFile();
        break;
      case 'rename':
        handleRenameFile();
        break;
      case 'addToFolder':
        handleAddToFolder();
        break;

      case 'moveToFolder':
        handleMoveToFolder();
        break;

      case 'publish':
        handlePublish();
        break;

      case 'republish':
        handlePublish();
        break;

      case 'Unpublish':
        handleUnPublish();
        break;

      case 'documentAccess':
        handleDocumentAccess();
        break;
      case 'copyLink':
        handleCopyLink();
        break;
      case 'duplicate':
        handleDuplicateCreate();
        break;
      case 'archive':
        handleArchive();
        break;
      default:
        return;
    }
  };

  const iconStyles = {
    sx: { fill: 'none', fontSize: 14 },
    style: { color: 'rgba(0, 0, 0, 0.54)' },
  };

  return (
    <ActionDropdown
      rootProps={{
        id: 'documents-action-dropdown',
        className: 'documents-action-dropdown',
      }}
      popperProps={{
        style: { width: 180 },
        placement: 'bottom-start',
      }}
      options={[
        ...(document?.fileType !== 'sop'
          ? [
              {
                label: 'Rename',
                id: 'rename',
                iconAlign: 'start',
                icon: <EditIcon {...iconStyles} />,
                style: { padding: '6px 16px' },
              },
            ]
          : []),
        ...(document?.fileType === 'sop'
          ? [
              {
                label: 'Edit',
                id: 'edit',
                iconAlign: 'start',
                icon: <EditIcon {...iconStyles} />,
                style: { padding: '6px 16px' },
              },
            ]
          : []),

        ...(!selectedFolder
          ? [
              {
                label: 'Add to Folder',
                id: 'addToFolder',
                iconAlign: 'start',
                icon: <AddToFolderIcon {...iconStyles} />,
                style: { padding: '6px 16px' },
              },
            ]
          : []),
        ...(selectedFolder
          ? [
              {
                label: 'Move to Folder',
                id: 'moveToFolder',
                iconAlign: 'start',
                icon: <FolderOpenIcon />,
                style: { padding: '6px 16px' },
              },
            ]
          : []),

        ...(document?.fileType === 'sop' && document?.isDraft
          ? [
              {
                label: 'Publish',
                id: 'publish',
                iconAlign: 'start',
                icon: <GlobeIcon {...iconStyles} />,
                style: { padding: '6px 16px' },
              },
            ]
          : []),
        ...(document?.fileType === 'sop' &&
        !document?.isDraft &&
        !document?.draftContent
          ? [
              {
                label: 'Unpublish',
                id: 'Unpublish',
                iconAlign: 'start',
                icon: (
                  <DisabledGlobeIcon style={{ fontSize: 18, marginLeft: -1 }} />
                ),
                style: { padding: '6px 16px' },
              },
            ]
          : []),

        ...(document?.fileType === 'sop' &&
        !document?.isDraft &&
        document?.draftContent
          ? [
              {
                label: 'republish',
                id: 'republish',
                iconAlign: 'start',
                icon: <GlobeIcon {...iconStyles} />,
                style: { padding: '6px 16px' },
              },
            ]
          : []),
        {
          label: 'Document Access',
          id: 'documentAccess',
          iconAlign: 'start',
          icon: <TemplateShareIcon style={{ color: '#000' }} />,
          style: { padding: '6px 16px' },
        },
        {
          label: 'Copy link',
          id: 'copyLink',
          iconAlign: 'start',
          icon: <LinkExternalIcon style={{ color: '#757575' }} />,
          style: { padding: '6px 16px' },
        },
        {
          label: 'Duplicate',
          id: 'duplicate',
          iconAlign: 'start',
          icon: <DuplicateIcon {...iconStyles} />,
          style: { padding: '6px 16px' },
        },
        {
          label: 'Archive',
          id: 'archive',
          iconAlign: 'start',
          icon: (
            <ArchiveIcon
              style={{ color: 'rgba(211, 47, 47, 1)' }}
              sx={{ fill: 'none', fontSize: 14 }}
            />
          ),
          style: { padding: '6px 16px', color: 'rgba(211, 47, 47, 1)' },
        },
      ]}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: {
          padding: 2,
        },
      }}
    >
      <MoreHorizIcon />
    </ActionDropdown>
  );
};

export default DocumentActions;
