import React, { useState, useEffect } from 'react';

// Components
import Banner from '../../components/Banner';

// Styled
import { ComponentWrapper, BannerContainer } from './BannerWrapper.styled';

// Utils
import { getItem, setItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import useMeasure from 'react-use-measure';

interface BannerWrapperPropTypes {
  children: React.ReactElement;
  activeTab: number;
}

const BannerWrapper = ({ children, activeTab }: BannerWrapperPropTypes) => {
  const [ref, bounds] = useMeasure();
  const [height, setHeight] = useState<any>(0);
  const [showBanner, setShowBanner] = useState<boolean>(false);

  const handleClickClose = () => {
    setShowBanner(false);
    setItem(LOCALSTORAGE_KEYS.NOTIFICATION_BANNER_VISIBILITY, 'false');
  };

  const getBannerVisibility = () => {
    const isBannerVisible = getItem(
      LOCALSTORAGE_KEYS.NOTIFICATION_BANNER_VISIBILITY,
    );
    return isBannerVisible === 'true' || !isBannerVisible;
  };

  const handleUpdateVisibility = () => {
    const isBannerVisible = getBannerVisibility();
    setShowBanner(isBannerVisible);
  };

  const setChildrenHeight = () => {
    const isBannerVisible = getBannerVisibility();
    setHeight(isBannerVisible ? bounds.height - 98 : bounds.height - 22);
  };

  useEffect(() => {
    handleUpdateVisibility();
    window.addEventListener('storage', handleUpdateVisibility);
    return () => window.removeEventListener('storage', handleUpdateVisibility);
  }, [activeTab]);

  useEffect(() => {
    setChildrenHeight();
  }, [bounds, window.innerHeight]);

  return (
    <ComponentWrapper ref={ref} bannervisible={showBanner ? 'true' : ''}>
      {showBanner && (
        <BannerContainer>
          <Banner handleClickClose={handleClickClose} />
        </BannerContainer>
      )}
      {React.cloneElement(children, { height })}
    </ComponentWrapper>
  );
};

export default BannerWrapper;
