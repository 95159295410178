import { useMemo, useState } from 'react';
import CustomButton from 'components/Button/CustomButton';
import { Header, MainCnt } from './style';

import DownloadIcon2 from 'components/Icons/downloadIcon2';
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import NotPreviewable from './notPreviewable';
import Previewable from './previewable';
import mime from 'mime';
import { isPreviewable } from 'xeniautils';
import { downloadFile } from 'utils/globalFunction';

const DownloadPreview = (props) => {
  const { document, metaInfo } = props;
  const [loading, setLoading] = useState(false);

  const downloadHandler = () => {
    setLoading(true);
    downloadFile(document?.path, document?.title, () => {
      setLoading(false);
    });
  };

  const canPreview = useMemo(() => {
    const ext = mime
      .getExtension(document?.meta?.headInfo?.['content-type'])
      ?.toString();

    if (ext === 'qt') {
      return null;
    }

    return isPreviewable(ext);
  }, [document]);

  return (
    <MainCnt>
      <Header>
        <img
          src={XeniaLogo}
          width={110}
          height={22}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.location.href = '/';
          }}
        />
        {canPreview && metaInfo?.canEdit && (
          <CustomButton
            variant="contained"
            className="downloadBtn"
            sx={{ padding: '2px 16px' }}
            startIcon={<DownloadIcon2 style={{ color: 'white' }} />}
            onClick={downloadHandler}
            buttonLoader={loading}
            disabled={loading}
          >
            Download File
          </CustomButton>
        )}
      </Header>
      {canPreview ? (
        <Previewable document={document} />
      ) : (
        <NotPreviewable document={document} />
      )}
    </MainCnt>
  );
};

export default DownloadPreview;
