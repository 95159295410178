import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import EditIcon from 'components/Icons/editIcon';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import ColorPickerIcon from 'components/Icons/colorPickerIcon';
import ArchiveIcon from 'components/Icons/archiveIcon';
import {
  getChecklistListApiCall,
  initializeNewChecklist,
} from 'store/actions/checklistV2Actions';
const folderActionDropdownOptions = [
  { label: 'Rename', id: 'rename', icon: <EditIcon />, iconAlign: 'start' },
  {
    label: 'Change Color',
    id: 'change_color',
    icon: (
      <ColorPickerIcon
        sx={{
          fill: 'none',
        }}
      />
    ),
    iconAlign: 'start',
  },
  {
    label: 'Archive',
    id: 'archive',
    icon: <ArchiveIcon />,
    iconAlign: 'start',
  },
];
interface IFolderActionDropdownProps {
  updateFolderCallback: (folder: any) => void;
  deleteFolderCallback: (folderId: string) => void;
  folder: any;
  isArchive: boolean;
}
function FolderActionDropdown(props: IFolderActionDropdownProps) {
  const { updateFolderCallback, deleteFolderCallback, folder, isArchive } =
    props;
  const dispatch = useDispatch();
  const loadTemplates = async () => {
    dispatch(initializeNewChecklist());
    dispatch(
      getChecklistListApiCall({
        detailed: true,
        folderId: 'root',
        archived: isArchive,
      }),
    );
  };
  //Handler for option select of action dropdown
  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'rename':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.RENAME_FOLDER_DIALOG,
            data: { folder, onSuccessCallback: updateFolderCallback },
          }),
        );
        break;
      case 'change_color':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.FOLDER_COLOR_DIALOG,
            data: { folder, onSuccessCallback: updateFolderCallback },
          }),
        );
        break;
      case 'archive':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.ARCHIVE_FOLDER_DIALOG,
            data: {
              folder,
              onSuccessCallback: (folderId) => {
                deleteFolderCallback(folderId);
                loadTemplates();
              },
            },
          }),
        );
        break;
    }
  };

  return (
    <ActionDropdown
      rootProps={{
        id: 'folders-action-dropdown',
      }}
      options={folderActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { padding: 2, transform: 'rotate(90deg)' },
      }}
      popperProps={{
        sx: {
          zIndex: 1301,
        },
      }}
    >
      {' '}
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
}

export default FolderActionDropdown;
