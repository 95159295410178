import { createSvgIcon } from '@mui/material';

const QRCodeIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M22.0701 4.72411L24.1666 4.61377L24.277 6.71032L22.1804 6.82067L22.0701 4.72411Z"
        fill="black"
      />
      <path
        d="M28.6885 4.72411L30.785 4.61377L30.8954 6.71032L28.7988 6.82067L28.6885 4.72411Z"
        fill="black"
      />
      <path
        d="M35.3113 4.9448L37.1871 4.61377L37.5182 6.48963L35.6423 6.82067L35.3113 4.9448Z"
        fill="black"
      />
      <path
        d="M39.7253 4.72436L41.8219 4.61401L41.9322 6.71057L39.8357 6.82091L39.7253 4.72436Z"
        fill="black"
      />
      <path
        d="M22.2455 6.90894L22.1572 8.851L24.0993 8.93928L24.1876 6.99721L22.2455 6.90894Z"
        fill="black"
      />
      <path
        d="M24.7599 6.82056L24.2744 8.54194L25.9958 9.02745L26.4813 7.30607L24.7599 6.82056Z"
        fill="black"
      />
      <path
        d="M28.9754 6.8208L28.6885 8.7408L30.6085 9.0277L30.8954 7.1077L28.9754 6.8208Z"
        fill="black"
      />
      <path
        d="M31.3837 6.8208L30.8982 8.54218L32.6196 9.0277L33.1051 7.30632L31.3837 6.8208Z"
        fill="black"
      />
      <path
        d="M39.9005 6.90894L39.8123 8.851L41.7543 8.93928L41.8426 6.99721L39.9005 6.90894Z"
        fill="black"
      />
      <path
        d="M22.357 9.02759L22.0701 10.9476L23.9901 11.2345L24.277 9.31448L22.357 9.02759Z"
        fill="black"
      />
      <path
        d="M24.2744 9.40276L26.1061 9.02759L26.4813 10.8593L24.6496 11.2345L24.2744 9.40276Z"
        fill="black"
      />
      <path
        d="M31.0736 9.11572L30.9854 11.0578L32.9274 11.1461L33.0157 9.204L31.0736 9.11572Z"
        fill="black"
      />
      <path
        d="M33.5881 9.02759L33.1025 10.749L34.8239 11.2345L35.3094 9.51311L33.5881 9.02759Z"
        fill="black"
      />
      <path
        d="M37.626 9.57932L39.1708 9.13794L39.6122 10.6828L38.0674 11.1241L37.626 9.57932Z"
        fill="black"
      />
      <path
        d="M39.9005 9.11572L39.8123 11.0578L41.7543 11.1461L41.8426 9.204L39.9005 9.11572Z"
        fill="black"
      />
      <path
        d="M26.5935 11.7864L28.1383 11.345L28.5797 12.8898L27.0349 13.3312L26.5935 11.7864Z"
        fill="black"
      />
      <path
        d="M28.8683 11.323L28.78 13.2651L30.7221 13.3533L30.8104 11.4113L28.8683 11.323Z"
        fill="black"
      />
      <path
        d="M31.0736 11.3228L30.9854 13.2648L32.9274 13.3531L33.0157 11.411L31.0736 11.3228Z"
        fill="black"
      />
      <path
        d="M33.1025 11.6095L34.9343 11.2344L35.3094 13.0661L33.4777 13.4413L33.1025 11.6095Z"
        fill="black"
      />
      <path
        d="M39.9005 11.3228L39.8123 13.2648L41.7543 13.3531L41.8426 11.411L39.9005 11.3228Z"
        fill="black"
      />
      <path
        d="M22.0701 13.7724L23.9459 13.4414L24.277 15.3173L22.4011 15.6483L22.0701 13.7724Z"
        fill="black"
      />
      <path
        d="M31.0736 13.5295L30.9854 15.4716L32.9274 15.5599L33.0157 13.6178L31.0736 13.5295Z"
        fill="black"
      />
      <path
        d="M39.7253 13.6623L41.7115 13.4417L41.9322 15.4279L39.946 15.6485L39.7253 13.6623Z"
        fill="black"
      />
      <path
        d="M24.2744 15.9792L26.1503 15.6482L26.4813 17.5241L24.6054 17.8551L24.2744 15.9792Z"
        fill="black"
      />
      <path
        d="M28.7979 16.1999L30.3427 15.7585L30.7841 17.3034L29.2392 17.7448L28.7979 16.1999Z"
        fill="black"
      />
      <path
        d="M31.0736 15.7366L30.9854 17.6786L32.9274 17.7669L33.0157 15.8248L31.0736 15.7366Z"
        fill="black"
      />
      <path
        d="M35.3113 15.7585L37.4078 15.6482L37.5182 17.7447L35.4216 17.8551L35.3113 15.7585Z"
        fill="black"
      />
      <path
        d="M22.0701 18.1863L23.9459 17.8552L24.277 19.7311L22.4011 20.0621L22.0701 18.1863Z"
        fill="black"
      />
      <path
        d="M26.4832 17.9656L28.5797 17.8552L28.6901 19.9518L26.5935 20.0621L26.4832 17.9656Z"
        fill="black"
      />
      <path
        d="M30.8982 18.0759L32.8844 17.8552L33.1051 19.8414L31.1189 20.0621L30.8982 18.0759Z"
        fill="black"
      />
      <path
        d="M35.4865 17.9436L35.3982 19.8857L37.3403 19.9739L37.4285 18.0319L35.4865 17.9436Z"
        fill="black"
      />
      <path
        d="M39.7253 18.1863L41.6012 17.8552L41.9322 19.7311L40.0564 20.0621L39.7253 18.1863Z"
        fill="black"
      />
      <path
        d="M24.2744 20.3268L26.2165 20.062L26.4813 22.0041L24.5392 22.2689L24.2744 20.3268Z"
        fill="black"
      />
      <path
        d="M26.6596 20.1504L26.5713 22.0925L28.5134 22.1807L28.6016 20.2387L26.6596 20.1504Z"
        fill="black"
      />
      <path
        d="M29.174 20.062L28.6885 21.7834L30.4099 22.2689L30.8954 20.5475L29.174 20.062Z"
        fill="black"
      />
      <path
        d="M33.2119 20.614L34.7567 20.1726L35.1981 21.7174L33.6533 22.1588L33.2119 20.614Z"
        fill="black"
      />
      <path
        d="M35.4865 20.1504L35.3982 22.0925L37.3403 22.1807L37.4285 20.2387L35.4865 20.1504Z"
        fill="black"
      />
      <path
        d="M4.41382 22.3791L6.51037 22.2688L6.62071 24.3654L4.52416 24.4757L4.41382 22.3791Z"
        fill="black"
      />
      <path
        d="M11.1482 22.8205L12.693 22.3792L13.1344 23.924L11.5896 24.3654L11.1482 22.8205Z"
        fill="black"
      />
      <path
        d="M13.4191 22.3572L13.3308 24.2992L15.2729 24.3875L15.3612 22.4455L13.4191 22.3572Z"
        fill="black"
      />
      <path
        d="M15.6261 22.3572L15.5378 24.2992L17.4799 24.3875L17.5682 22.4455L15.6261 22.3572Z"
        fill="black"
      />
      <path
        d="M17.8314 22.3572L17.7432 24.2992L19.6852 24.3875L19.7735 22.4455L17.8314 22.3572Z"
        fill="black"
      />
      <path
        d="M20.0402 22.3572L19.9519 24.2992L21.894 24.3875L21.9822 22.4455L20.0402 22.3572Z"
        fill="black"
      />
      <path
        d="M22.2455 22.3572L22.1572 24.2992L24.0993 24.3875L24.1876 22.4455L22.2455 22.3572Z"
        fill="black"
      />
      <path
        d="M24.4542 22.3572L24.366 24.2992L26.308 24.3875L26.3963 22.4455L24.4542 22.3572Z"
        fill="black"
      />
      <path
        d="M26.6596 22.3572L26.5713 24.2992L28.5134 24.3875L28.6016 22.4455L26.6596 22.3572Z"
        fill="black"
      />
      <path
        d="M28.8683 22.3572L28.78 24.2992L30.7221 24.3875L30.8104 22.4455L28.8683 22.3572Z"
        fill="black"
      />
      <path
        d="M35.4865 22.3572L35.3982 24.2992L37.3403 24.3875L37.4285 22.4455L35.4865 22.3572Z"
        fill="black"
      />
      <path
        d="M39.8347 22.8205L41.3795 22.3792L41.8209 23.924L40.2761 24.3654L39.8347 22.8205Z"
        fill="black"
      />
      <path
        d="M42.4162 22.2688L41.9307 23.9902L43.652 24.4757L44.1376 22.7543L42.4162 22.2688Z"
        fill="black"
      />
      <path
        d="M48.5535 22.3791L50.65 22.2688L50.7604 24.3654L48.6638 24.4757L48.5535 22.3791Z"
        fill="black"
      />
      <path
        d="M52.9685 22.5336L54.9106 22.2688L55.1754 24.2109L53.2333 24.4757L52.9685 22.5336Z"
        fill="black"
      />
      <path
        d="M55.3488 22.3572L55.2605 24.2992L57.2026 24.3875L57.2908 22.4455L55.3488 22.3572Z"
        fill="black"
      />
      <path
        d="M57.7117 22.3792L57.491 24.1447L59.2565 24.3654L59.4772 22.5998L57.7117 22.3792Z"
        fill="black"
      />
      <path
        d="M4.41382 24.6965L6.40003 24.4758L6.62071 26.462L4.63451 26.6827L4.41382 24.6965Z"
        fill="black"
      />
      <path
        d="M13.4191 24.5642L13.3308 26.5063L15.2729 26.5946L15.3612 24.6525L13.4191 24.5642Z"
        fill="black"
      />
      <path
        d="M22.357 24.4758L22.0701 26.3958L23.9901 26.6827L24.277 24.7627L22.357 24.4758Z"
        fill="black"
      />
      <path
        d="M24.4542 24.5642L24.366 26.5063L26.308 26.5946L26.3963 24.6525L24.4542 24.5642Z"
        fill="black"
      />
      <path
        d="M26.6596 24.5642L26.5713 26.5063L28.5134 26.5946L28.6016 24.6525L26.6596 24.5642Z"
        fill="black"
      />
      <path
        d="M28.8683 24.5642L28.78 26.5063L30.7221 26.5946L30.8104 24.6525L28.8683 24.5642Z"
        fill="black"
      />
      <path
        d="M31.3837 24.4758L30.8982 26.1972L32.6196 26.6827L33.1051 24.9613L31.3837 24.4758Z"
        fill="black"
      />
      <path
        d="M35.5982 24.4758L35.3113 26.3958L37.2313 26.6827L37.5182 24.7627L35.5982 24.4758Z"
        fill="black"
      />
      <path
        d="M38.0021 24.4758L37.5166 26.1972L39.238 26.6827L39.7235 24.9613L38.0021 24.4758Z"
        fill="black"
      />
      <path
        d="M42.1105 24.5642L42.0222 26.5063L43.9643 26.5946L44.0526 24.6525L42.1105 24.5642Z"
        fill="black"
      />
      <path
        d="M46.3447 24.7407L48.2868 24.4758L48.5516 26.4179L46.6096 26.6827L46.3447 24.7407Z"
        fill="black"
      />
      <path
        d="M48.7287 24.5642L48.6404 26.5063L50.5824 26.5946L50.6707 24.6525L48.7287 24.5642Z"
        fill="black"
      />
      <path
        d="M50.9347 24.5642L50.8464 26.5063L52.7885 26.5946L52.8768 24.6525L50.9347 24.5642Z"
        fill="black"
      />
      <path
        d="M53.1427 24.5642L53.0544 26.5063L54.9965 26.5946L55.0848 24.6525L53.1427 24.5642Z"
        fill="black"
      />
      <path
        d="M55.1729 24.851L57.0046 24.4758L57.3797 26.3076L55.548 26.6827L55.1729 24.851Z"
        fill="black"
      />
      <path
        d="M6.61816 26.9477L8.56023 26.6829L8.82506 28.6249L6.88299 28.8898L6.61816 26.9477Z"
        fill="black"
      />
      <path
        d="M9.00502 26.771L8.91675 28.7131L10.8588 28.8013L10.9471 26.8593L9.00502 26.771Z"
        fill="black"
      />
      <path
        d="M11.2121 26.771L11.1238 28.7131L13.0658 28.8013L13.1541 26.8593L11.2121 26.771Z"
        fill="black"
      />
      <path
        d="M13.4191 26.771L13.3308 28.7131L15.2729 28.8013L15.3612 26.8593L13.4191 26.771Z"
        fill="black"
      />
      <path
        d="M15.6261 26.771L15.5378 28.7131L17.4799 28.8013L17.5682 26.8593L15.6261 26.771Z"
        fill="black"
      />
      <path
        d="M17.8314 26.771L17.7432 28.7131L19.6852 28.8013L19.7735 26.8593L17.8314 26.771Z"
        fill="black"
      />
      <path
        d="M20.197 26.7932L19.9763 28.5587L21.7418 28.7794L21.9625 27.0139L20.197 26.7932Z"
        fill="black"
      />
      <path
        d="M24.5613 26.6829L24.2744 28.6029L26.1944 28.8898L26.4813 26.9698L24.5613 26.6829Z"
        fill="black"
      />
      <path
        d="M26.6596 26.771L26.5713 28.7131L28.5134 28.8013L28.6016 26.8593L26.6596 26.771Z"
        fill="black"
      />
      <path
        d="M28.8683 26.7712L28.78 28.7133L30.7221 28.8016L30.8104 26.8595L28.8683 26.7712Z"
        fill="black"
      />
      <path
        d="M31.0736 26.771L30.9854 28.7131L32.9274 28.8013L33.0157 26.8593L31.0736 26.771Z"
        fill="black"
      />
      <path
        d="M37.6964 26.7712L37.6082 28.7133L39.5502 28.8016L39.6385 26.8595L37.6964 26.7712Z"
        fill="black"
      />
      <path
        d="M42.1105 26.7712L42.0222 28.7133L43.9643 28.8016L44.0526 26.8595L42.1105 26.7712Z"
        fill="black"
      />
      <path
        d="M44.3146 26.771L44.2263 28.7131L46.1684 28.8013L46.2567 26.8593L44.3146 26.771Z"
        fill="black"
      />
      <path
        d="M46.5206 26.7712L46.4324 28.7133L48.3744 28.8016L48.4627 26.8595L46.5206 26.7712Z"
        fill="black"
      />
      <path
        d="M48.7287 26.771L48.6404 28.7131L50.5824 28.8013L50.6707 26.8593L48.7287 26.771Z"
        fill="black"
      />
      <path
        d="M50.9347 26.771L50.8464 28.7131L52.7885 28.8013L52.8768 26.8593L50.9347 26.771Z"
        fill="black"
      />
      <path
        d="M57.3826 26.7932L59.4791 26.6829L59.5895 28.7794L57.4929 28.8898L57.3826 26.7932Z"
        fill="black"
      />
      <path
        d="M4.41382 29.1545L6.35589 28.8896L6.62071 30.8317L4.67865 31.0965L4.41382 29.1545Z"
        fill="black"
      />
      <path
        d="M6.61816 29.2651L8.44989 28.8899L8.82506 30.7216L6.99334 31.0968L6.61816 29.2651Z"
        fill="black"
      />
      <path
        d="M11.2121 28.9778L11.1238 30.9199L13.0658 31.0081L13.1541 29.0661L11.2121 28.9778Z"
        fill="black"
      />
      <path
        d="M13.4191 28.9778L13.3308 30.9199L15.2729 31.0081L15.3612 29.0661L13.4191 28.9778Z"
        fill="black"
      />
      <path
        d="M15.4463 29.2651L17.278 28.8899L17.6532 30.7216L15.8215 31.0968L15.4463 29.2651Z"
        fill="black"
      />
      <path
        d="M31.0736 28.9778L30.9854 30.9199L32.9274 31.0081L33.0157 29.0661L31.0736 28.9778Z"
        fill="black"
      />
      <path
        d="M35.4216 29.4414L36.9665 29L37.4078 30.5448L35.863 30.9862L35.4216 29.4414Z"
        fill="black"
      />
      <path
        d="M37.5166 29.2648L39.3483 28.8896L39.7235 30.7214L37.8918 31.0965L37.5166 29.2648Z"
        fill="black"
      />
      <path
        d="M42.2176 28.8896L41.9307 30.8096L43.8507 31.0965L44.1376 29.1765L42.2176 28.8896Z"
        fill="black"
      />
      <path
        d="M44.3146 28.9778L44.2263 30.9199L46.1684 31.0081L46.2567 29.0661L44.3146 28.9778Z"
        fill="black"
      />
      <path
        d="M51.0457 28.8896L50.7588 30.8096L52.6788 31.0965L52.9657 29.1765L51.0457 28.8896Z"
        fill="black"
      />
      <path
        d="M53.1427 28.9778L53.0544 30.9199L54.9965 31.0081L55.0848 29.0661L53.1427 28.9778Z"
        fill="black"
      />
      <path
        d="M55.3488 28.9778L55.2605 30.9199L57.2026 31.0081L57.2908 29.0661L55.3488 28.9778Z"
        fill="black"
      />
      <path
        d="M57.5568 28.9778L57.4685 30.9199L59.4106 31.0081L59.4989 29.0661L57.5568 28.9778Z"
        fill="black"
      />
      <path
        d="M4.58925 31.1848L4.50098 33.1269L6.44305 33.2152L6.53132 31.2731L4.58925 31.1848Z"
        fill="black"
      />
      <path
        d="M8.82788 31.3613L10.7699 31.0964L11.0348 33.0385L9.09271 33.3033L8.82788 31.3613Z"
        fill="black"
      />
      <path
        d="M11.2121 31.1848L11.1238 33.1269L13.0658 33.2152L13.1541 31.2731L11.2121 31.1848Z"
        fill="black"
      />
      <path
        d="M13.2419 31.4716L15.0737 31.0964L15.4488 32.9282L13.6171 33.3033L13.2419 31.4716Z"
        fill="black"
      />
      <path
        d="M17.7654 31.6482L19.3102 31.2068L19.7516 32.7516L18.2068 33.193L17.7654 31.6482Z"
        fill="black"
      />
      <path
        d="M20.3459 31.0964L19.8604 32.8178L21.5817 33.3033L22.0672 31.582L20.3459 31.0964Z"
        fill="black"
      />
      <path
        d="M26.5935 31.6482L28.1383 31.2068L28.5797 32.7516L27.0349 33.193L26.5935 31.6482Z"
        fill="black"
      />
      <path
        d="M28.8683 31.1848L28.78 33.1269L30.7221 33.2152L30.8104 31.2731L28.8683 31.1848Z"
        fill="black"
      />
      <path
        d="M30.8982 31.4716L32.7299 31.0964L33.1051 32.9282L31.2734 33.3033L30.8982 31.4716Z"
        fill="black"
      />
      <path
        d="M39.7253 31.2068L41.8219 31.0964L41.9322 33.193L39.8357 33.3033L39.7253 31.2068Z"
        fill="black"
      />
      <path
        d="M44.4263 31.0964L44.1394 33.0164L46.0594 33.3033L46.3463 31.3833L44.4263 31.0964Z"
        fill="black"
      />
      <path
        d="M46.6748 31.2068L46.4541 32.9723L48.2196 33.193L48.4403 31.4275L46.6748 31.2068Z"
        fill="black"
      />
      <path
        d="M57.3826 31.3171L59.3688 31.0964L59.5895 33.0826L57.6033 33.3033L57.3826 31.3171Z"
        fill="black"
      />
      <path
        d="M4.58925 33.3916L4.50098 35.3337L6.44305 35.4219L6.53132 33.4799L4.58925 33.3916Z"
        fill="black"
      />
      <path
        d="M6.79897 33.3916L6.71069 35.3337L8.65276 35.4219L8.74104 33.4799L6.79897 33.3916Z"
        fill="black"
      />
      <path
        d="M8.82788 33.6786L10.6596 33.3035L11.0348 35.1352L9.20305 35.5104L8.82788 33.6786Z"
        fill="black"
      />
      <path
        d="M19.8604 33.5242L21.8466 33.3035L22.0672 35.2897L20.081 35.5104L19.8604 33.5242Z"
        fill="black"
      />
      <path
        d="M24.2744 33.4138L26.371 33.3035L26.4813 35.4L24.3848 35.5104L24.2744 33.4138Z"
        fill="black"
      />
      <path
        d="M33.1025 33.5683L35.0446 33.3035L35.3094 35.2455L33.3674 35.5104L33.1025 33.5683Z"
        fill="black"
      />
      <path
        d="M35.4865 33.3918L35.3982 35.3339L37.3403 35.4222L37.4285 33.4801L35.4865 33.3918Z"
        fill="black"
      />
      <path
        d="M37.6964 33.3918L37.6082 35.3339L39.5502 35.4222L39.6385 33.4801L37.6964 33.3918Z"
        fill="black"
      />
      <path
        d="M39.9005 33.3918L39.8123 35.3339L41.7543 35.4222L41.8426 33.4801L39.9005 33.3918Z"
        fill="black"
      />
      <path
        d="M42.2607 33.4138L42.04 35.1793L43.8056 35.4L44.0262 33.6345L42.2607 33.4138Z"
        fill="black"
      />
      <path
        d="M48.5535 33.4138L50.65 33.3035L50.7604 35.4L48.6638 35.5104L48.5535 33.4138Z"
        fill="black"
      />
      <path
        d="M55.1729 33.4138L57.2694 33.3035L57.3797 35.4L55.2832 35.5104L55.1729 33.4138Z"
        fill="black"
      />
      <path
        d="M4.58925 35.5986L4.50098 37.5407L6.44305 37.629L6.53132 35.6869L4.58925 35.5986Z"
        fill="black"
      />
      <path
        d="M6.61816 35.8857L8.44989 35.5105L8.82506 37.3422L6.99334 37.7174L6.61816 35.8857Z"
        fill="black"
      />
      <path
        d="M13.2419 35.8413L15.1178 35.5103L15.4488 37.3861L13.573 37.7172L13.2419 35.8413Z"
        fill="black"
      />
      <path
        d="M17.656 35.8413L19.5319 35.5103L19.8629 37.3861L17.987 37.7172L17.656 35.8413Z"
        fill="black"
      />
      <path
        d="M22.1794 36.062L23.7243 35.6206L24.1657 37.1654L22.6208 37.6068L22.1794 36.062Z"
        fill="black"
      />
      <path
        d="M24.4542 35.5989L24.366 37.5409L26.308 37.6292L26.3963 35.6872L24.4542 35.5989Z"
        fill="black"
      />
      <path
        d="M26.6596 35.5986L26.5713 37.5407L28.5134 37.629L28.6016 35.6869L26.6596 35.5986Z"
        fill="black"
      />
      <path
        d="M28.8683 35.5989L28.78 37.5409L30.7221 37.6292L30.8104 35.6872L28.8683 35.5989Z"
        fill="black"
      />
      <path
        d="M31.0736 35.5986L30.9854 37.5407L32.9274 37.629L33.0157 35.6869L31.0736 35.5986Z"
        fill="black"
      />
      <path
        d="M33.1025 35.8857L34.9343 35.5105L35.3094 37.3422L33.4777 37.7174L33.1025 35.8857Z"
        fill="black"
      />
      <path
        d="M37.5166 35.7312L39.5028 35.5105L39.7235 37.4967L37.7373 37.7174L37.5166 35.7312Z"
        fill="black"
      />
      <path
        d="M44.1394 35.6208L46.236 35.5105L46.3463 37.607L44.2497 37.7174L44.1394 35.6208Z"
        fill="black"
      />
      <path
        d="M48.8404 35.5105L48.5535 37.4305L50.4735 37.7174L50.7604 35.7974L48.8404 35.5105Z"
        fill="black"
      />
      <path
        d="M50.9347 35.5986L50.8464 37.5407L52.7885 37.629L52.8768 35.6869L50.9347 35.5986Z"
        fill="black"
      />
      <path
        d="M53.1427 35.5986L53.0544 37.5407L54.9965 37.629L55.0848 35.6869L53.1427 35.5986Z"
        fill="black"
      />
      <path
        d="M55.3488 35.5986L55.2605 37.5407L57.2026 37.629L57.2908 35.6869L55.3488 35.5986Z"
        fill="black"
      />
      <path
        d="M57.8681 35.5105L57.3826 37.2319L59.1039 37.7174L59.5895 35.996L57.8681 35.5105Z"
        fill="black"
      />
      <path
        d="M4.58925 37.8054L4.50098 39.7475L6.44305 39.8358L6.53132 37.8937L4.58925 37.8054Z"
        fill="black"
      />
      <path
        d="M8.82788 37.8274L10.9244 37.717L11.0348 39.8136L8.93823 39.9239L8.82788 37.8274Z"
        fill="black"
      />
      <path
        d="M26.7701 37.717L26.4832 39.637L28.4032 39.9239L28.6901 38.0039L26.7701 37.717Z"
        fill="black"
      />
      <path
        d="M28.6885 38.0925L30.5202 37.7173L30.8954 39.549L29.0636 39.9242L28.6885 38.0925Z"
        fill="black"
      />
      <path
        d="M44.3146 37.8054L44.2263 39.7475L46.1684 39.8358L46.2567 37.8937L44.3146 37.8054Z"
        fill="black"
      />
      <path
        d="M46.8302 37.717L46.3447 39.4384L48.0661 39.9239L48.5516 38.2026L46.8302 37.717Z"
        fill="black"
      />
      <path
        d="M51.0457 37.717L50.7588 39.637L52.6788 39.9239L52.9657 38.0039L51.0457 37.717Z"
        fill="black"
      />
      <path
        d="M53.1427 37.8054L53.0544 39.7475L54.9965 39.8358L55.0848 37.8937L53.1427 37.8054Z"
        fill="black"
      />
      <path
        d="M57.3826 37.9377L59.3688 37.717L59.5895 39.7032L57.6033 39.9239L57.3826 37.9377Z"
        fill="black"
      />
      <path
        d="M4.41382 40.1448L6.40003 39.9241L6.62071 41.9103L4.63451 42.131L4.41382 40.1448Z"
        fill="black"
      />
      <path
        d="M8.82788 40.1448L10.8141 39.9241L11.0348 41.9103L9.04857 42.131L8.82788 40.1448Z"
        fill="black"
      />
      <path
        d="M13.2419 40.2551L15.1178 39.9241L15.4488 41.7999L13.573 42.131L13.2419 40.2551Z"
        fill="black"
      />
      <path
        d="M17.656 40.2551L19.5319 39.9241L19.8629 41.7999L17.987 42.131L17.656 40.2551Z"
        fill="black"
      />
      <path
        d="M33.1025 40.0344L35.1991 39.9241L35.3094 42.0206L33.2129 42.131L33.1025 40.0344Z"
        fill="black"
      />
      <path
        d="M37.626 40.4758L39.1708 40.0344L39.6122 41.5793L38.0674 42.0206L37.626 40.4758Z"
        fill="black"
      />
      <path
        d="M39.9005 40.0125L39.8123 41.9545L41.7543 42.0428L41.8426 40.1007L39.9005 40.0125Z"
        fill="black"
      />
      <path
        d="M42.1105 40.0125L42.0222 41.9545L43.9643 42.0428L44.0526 40.1007L42.1105 40.0125Z"
        fill="black"
      />
      <path
        d="M44.3146 40.0125L44.2263 41.9545L46.1684 42.0428L46.2567 40.1007L44.3146 40.0125Z"
        fill="black"
      />
      <path
        d="M46.5206 40.0125L46.4324 41.9545L48.3744 42.0428L48.4627 40.1007L46.5206 40.0125Z"
        fill="black"
      />
      <path
        d="M49.039 39.9241L48.5535 41.6455L50.2748 42.131L50.7604 40.4096L49.039 39.9241Z"
        fill="black"
      />
      <path
        d="M53.1427 40.0125L53.0544 41.9545L54.9965 42.0428L55.0848 40.1007L53.1427 40.0125Z"
        fill="black"
      />
      <path
        d="M55.6584 39.9241L55.1729 41.6455L56.8942 42.131L57.3797 40.4096L55.6584 39.9241Z"
        fill="black"
      />
      <path
        d="M22.0701 42.3959L24.0121 42.1311L24.277 44.0732L22.3349 44.338L22.0701 42.3959Z"
        fill="black"
      />
      <path
        d="M24.4542 42.2195L24.366 44.1616L26.308 44.2498L26.3963 42.3078L24.4542 42.2195Z"
        fill="black"
      />
      <path
        d="M26.6596 42.2192L26.5713 44.1613L28.5134 44.2496L28.6016 42.3075L26.6596 42.2192Z"
        fill="black"
      />
      <path
        d="M28.8683 42.2195L28.78 44.1616L30.7221 44.2498L30.8104 42.3078L28.8683 42.2195Z"
        fill="black"
      />
      <path
        d="M31.0736 42.2192L30.9854 44.1613L32.9274 44.2496L33.0157 42.3075L31.0736 42.2192Z"
        fill="black"
      />
      <path
        d="M33.2824 42.2195L33.1941 44.1616L35.1362 44.2498L35.2244 42.3078L33.2824 42.2195Z"
        fill="black"
      />
      <path
        d="M39.9005 42.2192L39.8123 44.1613L41.7543 44.2496L41.8426 42.3075L39.9005 42.2192Z"
        fill="black"
      />
      <path
        d="M48.7287 42.2192L48.6404 44.1613L50.5824 44.2496L50.6707 42.3075L48.7287 42.2192Z"
        fill="black"
      />
      <path
        d="M53.2554 42.1311L52.9685 44.0511L54.8885 44.338L55.1754 42.418L53.2554 42.1311Z"
        fill="black"
      />
      <path
        d="M55.1729 42.5063L57.0046 42.1311L57.3797 43.9628L55.548 44.338L55.1729 42.5063Z"
        fill="black"
      />
      <path
        d="M22.2455 44.426L22.1572 46.3681L24.0993 46.4564L24.1876 44.5143L22.2455 44.426Z"
        fill="black"
      />
      <path
        d="M28.6885 44.5586L30.6747 44.3379L30.8954 46.3241L28.9092 46.5448L28.6885 44.5586Z"
        fill="black"
      />
      <path
        d="M33.2824 44.426L33.1941 46.3681L35.1362 46.4564L35.2244 44.5143L33.2824 44.426Z"
        fill="black"
      />
      <path
        d="M39.9005 44.426L39.8123 46.3681L41.7543 46.4564L41.8426 44.5143L39.9005 44.426Z"
        fill="black"
      />
      <path
        d="M44.1394 44.6692L46.0153 44.3381L46.3463 46.214L44.4704 46.545L44.1394 44.6692Z"
        fill="black"
      />
      <path
        d="M48.7287 44.426L48.6404 46.3681L50.5824 46.4564L50.6707 44.5143L48.7287 44.426Z"
        fill="black"
      />
      <path
        d="M57.3826 44.6692L59.2584 44.3381L59.5895 46.214L57.7136 46.545L57.3826 44.6692Z"
        fill="black"
      />
      <path
        d="M22.2455 46.6331L22.1572 48.5751L24.0993 48.6634L24.1876 46.7213L22.2455 46.6331Z"
        fill="black"
      />
      <path
        d="M26.4832 46.8757L28.359 46.5447L28.6901 48.4205L26.8142 48.7516L26.4832 46.8757Z"
        fill="black"
      />
      <path
        d="M30.8982 46.8095L32.8403 46.5447L33.1051 48.4867L31.163 48.7516L30.8982 46.8095Z"
        fill="black"
      />
      <path
        d="M33.1025 46.9199L34.9343 46.5447L35.3094 48.3764L33.4777 48.7516L33.1025 46.9199Z"
        fill="black"
      />
      <path
        d="M37.5166 46.8097L39.4587 46.5449L39.7235 48.487L37.7814 48.7518L37.5166 46.8097Z"
        fill="black"
      />
      <path
        d="M39.9005 46.6331L39.8123 48.5751L41.7543 48.6634L41.8426 46.7213L39.9005 46.6331Z"
        fill="black"
      />
      <path
        d="M48.7287 46.6331L48.6404 48.5751L50.5824 48.6634L50.6707 46.7213L48.7287 46.6331Z"
        fill="black"
      />
      <path
        d="M55.1729 46.6553L57.2694 46.5449L57.3797 48.6415L55.2832 48.7518L55.1729 46.6553Z"
        fill="black"
      />
      <path
        d="M22.2455 48.8401L22.1572 50.7822L24.0993 50.8704L24.1876 48.9284L22.2455 48.8401Z"
        fill="black"
      />
      <path
        d="M30.8982 48.9724L32.8844 48.7517L33.1051 50.7379L31.1189 50.9586L30.8982 48.9724Z"
        fill="black"
      />
      <path
        d="M37.8035 48.7517L37.5166 50.6717L39.4366 50.9586L39.7235 49.0386L37.8035 48.7517Z"
        fill="black"
      />
      <path
        d="M39.9005 48.8401L39.8123 50.7822L41.7543 50.8704L41.8426 48.9284L39.9005 48.8401Z"
        fill="black"
      />
      <path
        d="M42.1105 48.8401L42.0222 50.7822L43.9643 50.8704L44.0526 48.9284L42.1105 48.8401Z"
        fill="black"
      />
      <path
        d="M44.3146 48.8401L44.2263 50.7822L46.1684 50.8704L46.2567 48.9284L44.3146 48.8401Z"
        fill="black"
      />
      <path
        d="M46.5206 48.8401L46.4324 50.7822L48.3744 50.8704L48.4627 48.9284L46.5206 48.8401Z"
        fill="black"
      />
      <path
        d="M48.5535 49.1269L50.3852 48.7517L50.7604 50.5834L48.9286 50.9586L48.5535 49.1269Z"
        fill="black"
      />
      <path
        d="M55.3488 48.8401L55.2605 50.7822L57.2026 50.8704L57.2908 48.9284L55.3488 48.8401Z"
        fill="black"
      />
      <path
        d="M57.8681 48.7517L57.3826 50.4731L59.1039 50.9586L59.5895 49.2372L57.8681 48.7517Z"
        fill="black"
      />
      <path
        d="M22.0701 51.1792L24.0563 50.9585L24.277 52.9447L22.2908 53.1654L22.0701 51.1792Z"
        fill="black"
      />
      <path
        d="M26.4832 51.0691L28.5797 50.9587L28.6901 53.0553L26.5935 53.1656L26.4832 51.0691Z"
        fill="black"
      />
      <path
        d="M33.1025 51.2236L35.0446 50.9587L35.3094 52.9008L33.3674 53.1656L33.1025 51.2236Z"
        fill="black"
      />
      <path
        d="M35.6423 51.0688L35.4216 52.8344L37.1871 53.0551L37.4078 51.2895L35.6423 51.0688Z"
        fill="black"
      />
      <path
        d="M39.9005 51.0469L39.8123 52.9889L41.7543 53.0772L41.8426 51.1352L39.9005 51.0469Z"
        fill="black"
      />
      <path
        d="M44.1394 51.1794L46.1256 50.9587L46.3463 52.9449L44.3601 53.1656L44.1394 51.1794Z"
        fill="black"
      />
      <path
        d="M55.3488 51.0469L55.2605 52.9889L57.2026 53.0772L57.2908 51.1352L55.3488 51.0469Z"
        fill="black"
      />
      <path
        d="M57.5568 51.0469L57.4685 52.9889L59.4106 53.0772L59.4989 51.1352L57.5568 51.0469Z"
        fill="black"
      />
      <path
        d="M24.2744 53.4304L26.2165 53.1655L26.4813 55.1076L24.5392 55.3724L24.2744 53.4304Z"
        fill="black"
      />
      <path
        d="M26.4832 53.5407L28.3149 53.1655L28.6901 54.9973L26.8583 55.3724L26.4832 53.5407Z"
        fill="black"
      />
      <path
        d="M31.0076 53.717L32.5524 53.2756L32.9938 54.8205L31.4489 55.2618L31.0076 53.717Z"
        fill="black"
      />
      <path
        d="M33.1025 53.5407L34.9343 53.1655L35.3094 54.9973L33.4777 55.3724L33.1025 53.5407Z"
        fill="black"
      />
      <path
        d="M37.5166 53.4304L39.4587 53.1655L39.7235 55.1076L37.7814 55.3724L37.5166 53.4304Z"
        fill="black"
      />
      <path
        d="M39.9005 53.2537L39.8123 55.1957L41.7543 55.284L41.8426 53.3419L39.9005 53.2537Z"
        fill="black"
      />
      <path
        d="M42.2607 53.2756L42.04 55.0412L43.8056 55.2618L44.0262 53.4963L42.2607 53.2756Z"
        fill="black"
      />
      <path
        d="M48.5535 53.4306L50.4955 53.1658L50.7604 55.1078L48.8183 55.3727L48.5535 53.4306Z"
        fill="black"
      />
      <path
        d="M50.9347 53.2537L50.8464 55.1957L52.7885 55.284L52.8768 53.3419L50.9347 53.2537Z"
        fill="black"
      />
      <path
        d="M53.1427 53.2537L53.0544 55.1957L54.9965 55.284L55.0848 53.3419L53.1427 53.2537Z"
        fill="black"
      />
      <path
        d="M55.3488 53.2537L55.2605 55.1957L57.2026 55.284L57.2908 53.3419L55.3488 53.2537Z"
        fill="black"
      />
      <path
        d="M57.5568 53.2537L57.4685 55.1957L59.4106 55.284L59.4989 53.3419L57.5568 53.2537Z"
        fill="black"
      />
      <path
        d="M24.4542 55.4607L24.366 57.4028L26.308 57.491L26.3963 55.549L24.4542 55.4607Z"
        fill="black"
      />
      <path
        d="M35.3113 55.6371L37.2533 55.3723L37.5182 57.3144L35.5761 57.5792L35.3113 55.6371Z"
        fill="black"
      />
      <path
        d="M37.5166 55.7475L39.3483 55.3723L39.7235 57.204L37.8918 57.5792L37.5166 55.7475Z"
        fill="black"
      />
      <path
        d="M46.4541 55.9243L47.9989 55.4829L48.4403 57.0277L46.8955 57.4691L46.4541 55.9243Z"
        fill="black"
      />
      <path
        d="M48.5535 55.7475L50.3852 55.3723L50.7604 57.204L48.9286 57.5792L48.5535 55.7475Z"
        fill="black"
      />
      <path
        d="M53.2554 55.3723L52.9685 57.2923L54.8885 57.5792L55.1754 55.6592L53.2554 55.3723Z"
        fill="black"
      />
      <path
        d="M55.3488 55.4607L55.2605 57.4028L57.2026 57.491L57.2908 55.549L55.3488 55.4607Z"
        fill="black"
      />
      <path
        d="M57.5568 55.4607L57.4685 57.4028L59.4106 57.491L59.4989 55.549L57.5568 55.4607Z"
        fill="black"
      />
      <path
        d="M22.1794 58.1311L23.7243 57.6897L24.1657 59.2345L22.6208 59.6759L22.1794 58.1311Z"
        fill="black"
      />
      <path
        d="M24.2744 57.9545L26.1061 57.5793L26.4813 59.4111L24.6496 59.7862L24.2744 57.9545Z"
        fill="black"
      />
      <path
        d="M31.0076 58.1311L32.5524 57.6897L32.9938 59.2345L31.4489 59.6759L31.0076 58.1311Z"
        fill="black"
      />
      <path
        d="M33.2824 57.6677L33.1941 59.6098L35.1362 59.6981L35.2244 57.756L33.2824 57.6677Z"
        fill="black"
      />
      <path
        d="M35.3113 57.9545L37.143 57.5793L37.5182 59.4111L35.6865 59.7862L35.3113 57.9545Z"
        fill="black"
      />
      <path
        d="M39.8347 58.1311L41.3795 57.6897L41.8209 59.2345L40.2761 59.6759L39.8347 58.1311Z"
        fill="black"
      />
      <path
        d="M42.2607 57.6897L42.04 59.4552L43.8056 59.6759L44.0262 57.9104L42.2607 57.6897Z"
        fill="black"
      />
      <path
        d="M50.7588 57.9104L52.6347 57.5793L52.9657 59.4552L51.0898 59.7862L50.7588 57.9104Z"
        fill="black"
      />
      <path
        d="M57.3826 57.8L59.3688 57.5793L59.5895 59.5656L57.6033 59.7862L57.3826 57.8Z"
        fill="black"
      />
      <path
        d="M4.41378 14.8438V9.82756C4.41378 6.95279 6.78046 4.61377 9.68798 4.61377H14.588C17.4954 4.61377 19.8621 6.95279 19.8621 9.82756V20.062L9.68798 20.059C6.78046 20.059 4.41378 17.7201 4.41378 14.8438ZM6.73103 14.8438C6.73103 16.4417 8.05742 17.7419 9.6886 17.7419L17.5448 17.7442V9.82756C17.5448 8.23037 16.2184 6.93101 14.588 6.93101H9.68798C8.05742 6.93101 6.73103 8.23037 6.73103 9.82756V14.8438Z"
        fill="black"
      />
      <path
        d="M59.5877 14.844V9.82781C59.5877 6.95304 57.221 4.61401 54.3135 4.61401H49.4134C46.5061 4.61401 44.1394 6.95304 44.1394 9.82781V20.0623L54.3135 20.0592C57.221 20.0592 59.5877 17.7203 59.5877 14.844ZM57.2704 14.844C57.2704 16.442 55.944 17.7421 54.3129 17.7421L46.4566 17.7444V9.82781C46.4566 8.23061 47.783 6.93125 49.4134 6.93125H54.3135C55.944 6.93125 57.2704 8.23061 57.2704 9.82781V14.844Z"
        fill="black"
      />
      <path
        d="M4.41354 49.5561V54.5723C4.41354 57.4471 6.78022 59.7861 9.68774 59.7861H14.5878C17.4951 59.7861 19.8618 57.4471 19.8618 54.5723V44.3379L9.68774 44.3409C6.78022 44.3409 4.41354 46.6798 4.41354 49.5561ZM6.73078 49.5561C6.73078 47.9582 8.05717 46.658 9.68835 46.658L17.5446 46.6557V54.5723C17.5446 56.1695 16.2182 57.4689 14.5878 57.4689H9.68774C8.05717 57.4689 6.73078 56.1695 6.73078 54.5723V49.5561Z"
        fill="black"
      />
      <path
        d="M8.82853 13.8461V10.8281C8.82853 9.83551 9.64923 9.02759 10.6578 9.02759H13.6199C14.6285 9.02759 15.4492 9.83551 15.4492 10.8281V15.6483L10.6578 15.6455C9.64923 15.6455 8.82853 14.8386 8.82853 13.8461Z"
        fill="black"
      />
      <path
        d="M55.1742 13.8461V10.8281C55.1742 9.83551 54.3535 9.02759 53.3449 9.02759H50.3828C49.3742 9.02759 48.5535 9.83551 48.5535 10.8281V15.6483L53.3449 15.6455C54.3535 15.6455 55.1742 14.8386 55.1742 13.8461Z"
        fill="black"
      />
      <path
        d="M8.82853 50.5538V53.5718C8.82853 54.5644 9.64923 55.3723 10.6578 55.3723H13.6199C14.6285 55.3723 15.4492 54.5644 15.4492 53.5718V48.7516L10.6578 48.7544C9.64923 48.7544 8.82853 49.5613 8.82853 50.5538Z"
        fill="black"
      />
    </>,
    'QRCodeIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 64 65'} {...props} />;
};
export default QRCodeIcon;
