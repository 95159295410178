import { useCallback, useEffect } from 'react';
import { TaskSummaryContext } from 'pages/ReportingV2/Reports/TaskOverview/context/taskSummaryContext';
import {
  INTIAL_LAYOUT_SCHEDULED_WORK_REPORT,
  SCHEDULED_REPORTS_THRESHOLD,
} from 'components/CustomTilesGrid/utils';

import CustomDragTilesGrid from 'components/CustomDragTilesGrid';

import useReports from 'pages/ReportingV2/Reports/useReports';
import { REPORTING_CONFIG_CONSTANTS } from 'pages/ReportingV2/Reports/constants';

import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { transformLayouts } from 'utils/globalFunction';
import ScheduledWorkHeader from './header/header';
import CompletionOverview from './widgets/completionOverview';
import WorkByStatus from './widgets/workByStatus';
import CorrectiveActions from './widgets/correctiveActions';
import ScheduledTasks from './widgets/schedules';
import CompletionByLocations from './widgets/completionByLocation';
import OnTimeVsLateByLocation from './widgets/onTimeVsLateCompletionbyLocation';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';

function ScheduledWork() {
  const { workspaceId } = useWorkspaceHook();
  const {
    defaultProps,
    state,
    updateState,
    clearAllFilters,
    updateGridSettings,
  } = useReports(REPORTING_CONFIG_CONSTANTS.SCHEDULED_WORK_REPORT, false);

  const transformedLayouts = useCallback(() => {
    return state?.gridSettings
      ? transformLayouts(
          state?.gridSettings,
          INTIAL_LAYOUT_SCHEDULED_WORK_REPORT,
          SCHEDULED_REPORTS_THRESHOLD,
        )
      : null;
  }, [state?.gridSettings]);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasPermission } = useCustomPermission();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_VIEW_REPORTING)) {
      navigateWithWorkspaceUrl('/dashboard');
    }
  }, []);

  return (
    <TaskSummaryContext.Provider
      value={{ state, updateState, clearAllFilters }}
    >
      <div>
        <ScheduledWorkHeader />
        {state?.isLoading ? null : (
          <div style={{ margin: '20px 30px' }}>
            <CustomDragTilesGrid
              key={workspaceId}
              customLayout={INTIAL_LAYOUT_SCHEDULED_WORK_REPORT}
              onChangeCB={updateGridSettings}
              isDroppable={false}
              isResizable={false}
              isDraggable={false}
            >
              <CompletionOverview
                key="scheduleCompletionOverview"
                {...defaultProps}
              />
              <WorkByStatus key="scheduledWorkByStatus" {...defaultProps} />
              <CompletionByLocations
                key="scheduleCompletionByLocation"
                {...defaultProps}
              />

              <OnTimeVsLateByLocation
                key="onTimeVsLateCompletionByLocation"
                {...defaultProps}
              />

              <ScheduledTasks
                key="schedulesCompletionStats"
                {...defaultProps}
              />

              <CorrectiveActions
                key="correctiveScheduledTasks"
                {...defaultProps}
              />
            </CustomDragTilesGrid>
          </div>
        )}
      </div>
    </TaskSummaryContext.Provider>
  );
}

export default ScheduledWork;
