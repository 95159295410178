import { useEffect, useState, Fragment } from 'react';

import { Stack, Typography, CircularProgress, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import ImageIcon from 'components/Icons/imageIcon';

import xeniaApi from 'api/index';

interface PropTypes {
  logo: string | null;
  handleUpload: (filePath: string) => void;
  handleRemove: () => void;
}

const LogoUpload = ({ logo, handleUpload, handleRemove }: PropTypes) => {
  const [uploading, setUploading] = useState(false);

  const uploadProps = {
    maxFiles: 1,
    accept: {
      image: ['png', 'webp', 'jpg', 'jpeg', 'avif'],
    },
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    ...uploadProps,
    disabled: uploading || !!logo,
  });

  const {
    getRootProps: _getRootProps,
    getInputProps: _getInputProps,
    acceptedFiles: _acceptedFiles,
  } = useDropzone({
    ...uploadProps,
    disabled: uploading,
  });

  const uploadLogo = async () => {
    if (!acceptedFiles.length && !_acceptedFiles.length) return;
    const acceptedFilesFinal = _acceptedFiles.length
      ? _acceptedFiles
      : acceptedFiles;
    setUploading(true);
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(
      acceptedFilesFinal,
    );
    if (response) {
      handleUpload(`${response.baseUrl}${response.filePath[0]}`);
    }
    setUploading(false);
  };

  useEffect(() => {
    uploadLogo();
  }, [acceptedFiles, _acceptedFiles]);

  return (
    <Stack direction={'row'} gap={'16px'}>
      <Stack
        borderRadius={'8px'}
        border={'1px dashed #BDBDBD'}
        padding={'18px 26px'}
        width={'250px'}
        gap={'8px'}
        alignItems={'center'}
        height={'150px'}
        justifyContent={'center'}
        sx={{
          cursor: logo ? '' : 'pointer',
        }}
        {...getRootProps()}
      >
        {logo ? (
          <Box
            width={'100%'}
            height={'100%'}
            position={'relative'}
            overflow={'hidden'}
            sx={{
              '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              },
            }}
          >
            <img src={logo} />
          </Box>
        ) : (
          <Fragment>
            <input {...getInputProps()} disabled={!!logo} />
            {uploading && <CircularProgress />}
            {!uploading && <ImageIcon sx={{ fontSize: '32px' }} />}
            <Typography
              textAlign={'center'}
              fontSize={13}
              fontWeight={400}
              color={'#424242'}
            >
              Drag and drop your logo here or click here to upload
            </Typography>
          </Fragment>
        )}
      </Stack>
      {logo && (
        <Stack
          height={'30px'}
          fontSize={13}
          fontWeight={500}
          direction={'row'}
          gap={'8px'}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Stack
            borderRadius={'6px'}
            border={'1px solid rgba(189, 189, 189, 1)'}
            padding={'4px 10px'}
            color={'#212121'}
            direction={'row'}
            gap={'3px'}
            alignItems={'center'}
            {..._getRootProps()}
          >
            <input {..._getInputProps()} />
            {uploading && <CircularProgress size={15} />}
            Change Logo
          </Stack>
          <Box
            borderRadius={'6px'}
            border={'1px solid rgba(239, 83, 80, 1)'}
            padding={'4px 10px'}
            color={'rgba(239, 83, 80, 1)'}
            onClick={uploading ? undefined : handleRemove}
          >
            Remove
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default LogoUpload;
