import React, { useState } from 'react';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import DropdownContent from './DropdownContent';

const ColumnsDropdown = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const ButtonRenderer = React.cloneElement(children, {
    onClick: handleClickButton,
  });

  return (
    <CustomDropdown
      popperProps={{
        content: <DropdownContent />,
        style: { width: 340 },
        anchorEl,
        open: Boolean(anchorEl),
        placement: 'bottom-end',
      }}
      buttonRenderer={ButtonRenderer}
      onClickawayCallback={handleClickButton}
    />
  );
};

export default ColumnsDropdown;
