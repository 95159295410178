import React, { FC, useState } from 'react';
import CustomButton from 'components/Button/CustomButton';

import {
  AbstractBackgroundCnt,
  WSCreateContainer,
} from 'pages/CreateWorkspace/style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import CreateWorkspaceLeftNav from './LeftNav';
import BulkInviteByEmailPhoneForm from 'components/BulkInvite/bulkInviteByEmailPhoneForm';

const InviteWorkspaceUsers: FC = () => {
  const [buttonIsSubmitting, setButtonIsSubmitting] = useState(false);
  const { workspaceId, navigateToOtherWorkspace } = useWorkspaceHook();
  const skipButton = (
    <CustomButton
      variant="text"
      type="button"
      fullWidth
      onClick={() => {
        navigateToOtherWorkspace({
          id: workspaceId,
          params: { replace: true },
        });
      }}
    >
      {'Skip this step'}
    </CustomButton>
  );
  const inviteForm = (
    <div
      style={{
        width: 'calc(100% - 297px)',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <div style={{ paddingLeft: '15%', paddingTop: '145px', width: '80%' }}>
        <div className="mainTitle">Invite Members</div>
        <div className="subtitle">
          Seamlessly invite team members to join your workspace
        </div>
        <div style={{ height: '40px' }} />

        <BulkInviteByEmailPhoneForm
          dialogId="BulkInviteUsers"
          setButtonIsSubmitting={setButtonIsSubmitting}
          showSubmitButton
          hotelId={workspaceId}
          skipButton={skipButton}
          hideElements={{
            locationMembership: true,
          }}
          onSuccess={() =>
            navigateToOtherWorkspace({
              id: workspaceId,
              params: { replace: true },
            })
          }
        />
        <div style={{ height: '55px' }} />
      </div>
    </div>
  );
  return (
    <AbstractBackgroundCnt>
      <WSCreateContainer>
        <CreateWorkspaceLeftNav step={2} />
        {inviteForm}
      </WSCreateContainer>
    </AbstractBackgroundCnt>
  );
};

export default InviteWorkspaceUsers;
