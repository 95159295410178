// React
import { Fragment, useEffect, useMemo, useState } from 'react';

// Components
import EmptyStateNotification from '../EmptyStateNotification';
import NotificationBox from './components/NotificationBox';
import VirtualList from 'components/VirtualList';

// Styled
import {
  Parent,
  GroupWrapper,
  LoadingWrapper,
  GroupHeading,
  NotificationListWrapper,
} from './NotificationsList.styled';
import CircularProgress from '@mui/material/CircularProgress';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

// Utils
import { Dictionary, groupBy } from 'lodash';
import { NotificationResponseModel } from 'models/taskModel';

interface PropTypes {
  isNotificationLoading: boolean;
  notificationListing: NotificationResponseModel[] | null;
  height?: number;
  activeTab: number;
}

const NotificationsList = ({
  isNotificationLoading,
  notificationListing,
  height,
  activeTab,
}: PropTypes) => {
  const { getUtcToTz } = useDateTime();

  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    if (notificationListing === null || notificationListing.length === 0) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  }, [notificationListing]);

  const groupNotificationData: Dictionary<NotificationResponseModel[]> =
    useMemo(() => {
      return groupBy(notificationListing, function (notification) {
        return getUtcToTz(notification.createdAt)
          .startOf('day')
          .calendar(null, {
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            lastWeek: 'ddd MMM DD YYYY',
            sameElse: 'ddd MMM DD YYYY',
          });
      });
    }, [notificationListing]);

  return (
    <Parent>
      {isNotificationLoading ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <>
          {empty ? (
            <EmptyStateNotification activeTab={activeTab} />
          ) : (
            <>
              <VirtualList
                rows={Object.keys(groupNotificationData).map((dateGroup) => (
                  <Fragment>
                    <GroupWrapper>
                      <GroupHeading>{dateGroup}</GroupHeading>
                    </GroupWrapper>
                    <NotificationListWrapper>
                      {groupNotificationData[dateGroup].map((notification) => (
                        <NotificationBox notification={notification} />
                      ))}
                    </NotificationListWrapper>
                  </Fragment>
                ))}
                height={height}
                rowGap={8}
              />
            </>
          )}
        </>
      )}
    </Parent>
  );
};

export default NotificationsList;
