import { useLocation } from 'react-router-dom';

export const useCurrentFolderId = () => {
  const location = useLocation();
  function getFolderIdsFromUrl() {
    const pathSegments = location.pathname.split('/');

    // Assuming the IDs are after the '/folders/' segment
    const foldersIndex = pathSegments.findIndex(
      (segment) => segment === 'folder',
    );

    // If '/folders/' is not found in the path, return an empty array
    if (foldersIndex === -1) {
      return [];
    }

    // Get the segments after '/folders/', and filter out any empty strings (which can occur if there's a trailing slash)
    return pathSegments.slice(foldersIndex + 1).filter(Boolean);
  }

  const folderIds = getFolderIdsFromUrl();
  const lastFolderId = folderIds[folderIds.length - 1];

  return { lastFolderId, getFolderIdsFromUrl };
};
