import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import React from 'react';
import DeleteIcon3 from 'components/Icons/deleteIcon3';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';
import actions from 'store/actions';
import { useDeleteWidget } from 'pages/Dashboards/hooks/useWidgets';
import { useParams } from 'react-router-dom';
import {
  useDashboard,
  useDeleteDashboard,
} from 'pages/Dashboards/hooks/useDashboard';
import { showMessageNotification } from 'utils/globalFunction';
import { options } from 'pages/Dashboards/dashboardActionDropdown/constants';

interface IDashboardActionDropdown {
  dashboardId: string;
  onDeleteCallback?: (dashboardId: string) => void;
}
const DashboardActionDropdown: React.FC<IDashboardActionDropdown> = ({
  dashboardId,
  onDeleteCallback,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { deleteDashboardQuery } = useDeleteDashboard(dashboardId);
  const handleWidgetDelete = () => {
    deleteDashboardQuery.mutate(dashboardId, {
      onSuccess: () => {
        showMessageNotification('Dashboard deleted Successfully', 'success');
        onDeleteCallback?.(dashboardId);
      },
    });
  };
  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'delete':
        dispatch(
          actions.setDialog({
            dialogId: DIALOGS.CONFIRMATION,
            open: true,
            data: {
              title: 'Delete Dashboard',
              description: 'Are you sure you want to delete this dashboard?',
              onConfirmCallback: handleWidgetDelete,
              confirmButtonProps: {
                buttonLoader: deleteDashboardQuery?.isLoading,
              },
            },
          }),
        );
        break;
      default:
        return;
    }
  };

  const iconStyles = {
    sx: { fill: 'none', fontSize: 14 },
    style: { color: 'rgba(0, 0, 0, 0.54)' },
  };

  return (
    <ActionDropdown
      iconButton={true}
      rootProps={{
        id: 'export-action-dropdown',
        className: 'documents-action-dropdown',
      }}
      popperProps={{
        style: { width: 180 },
        placement: 'bottom-start',
      }}
      options={options}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: {
          borderRadius: '50%',
          width: 40,
          height: 40,
        },
      }}
    >
      <MoreHorizIcon />
    </ActionDropdown>
  );
};

export default DashboardActionDropdown;
