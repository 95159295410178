import React, { useMemo } from 'react';

import CSVIcon from './icons/CSVIcon';
import ExcelIcon from './icons/ExcelIcon';
import GenericFileIcon from './icons/GenericFileIcon';
import ImageIcon from './icons/ImageIcon';
import PDFIcon from './icons/PDFIcon';
import PPTIcon from './icons/PPTIcon';
import SVGIcon from './icons/SVGIcon';
import TXTIcon from './icons/TXTIcon';
import VideoIcon from './icons/VideoIcon';
import WordIcon from './icons/WordIcon';
import ZipIcon from './icons/ZipIcon';

interface IProps {
  url: string;
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const FileIcon: React.FC<IProps> = (props) => {
  const { url, fillColor, bgColor, ...rest } = props;

  const fileExtension = useMemo(() => {
    const parts = url.split('.');
    const lastPart = parts[parts.length - 1];
    return lastPart.split('?')[0];
  }, [url]);

  const iconProps = {
    fillColor,
    bgColor,
    ...rest,
  };

  switch (fileExtension) {
    case 'csv':
      return <CSVIcon {...iconProps} />;
    case 'doc':
    case 'docx':
      return <WordIcon {...iconProps} />;
    case 'xls':
    case 'xlsx':
      return <ExcelIcon {...iconProps} />;
    case 'pdf':
      return <PDFIcon {...iconProps} />;
    case 'ppt':
    case 'pptx':
      return <PPTIcon {...iconProps} />;
    case 'svg':
      return <SVGIcon {...iconProps} />;
    case 'txt':
      return <TXTIcon {...iconProps} />;
    case 'zip':
      return <ZipIcon {...iconProps} />;
    case 'jpg':
    case 'jpeg':
    case 'png':
      return <ImageIcon {...iconProps} />;
    case 'mp4':
    case 'mov':
      return <VideoIcon {...iconProps} />;
    default:
      return <GenericFileIcon {...iconProps} />;
  }
};

export default FileIcon;
