import { setDialog } from 'store/actions/dialogActions';
import CustomButton from 'components/Button/CustomButton';
import {
  LocationDetailHeaderCnt,
  BreadcrumbHeadingCnt,
  LocationName,
  LocationsHeading,
  Dots,
  DottedContainer,
  DottedText,
} from 'pages/Locations/LocationDetail/LocationDetailHeader/locationDetailHeader.style';
import { useMemo } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import CustomToolTip from 'components/Tooltip';
import useLocationDetail from 'pages/Locations/LocationDetail/useLocationDetail';
import { useDispatch, useSelector } from 'react-redux';
import xeniaApi from 'api/index';
import { setLocationTaskHistory } from 'store/actions/locationActions';
import { getLocationsState } from 'store/selectors/locations';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { HasPermission } from 'components/HasPermission';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import selectors from 'store/selectors';
import ChildItemIcon from 'components/Icons/childItemIcon';

interface LocationDetailHeaderProps {
  onAssignWorkCb: () => void;
}

function LocationDetailHeader({ onAssignWorkCb }: LocationDetailHeaderProps) {
  const { getParentsNodes } = useLocationsUtils();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { selectedLocation } = useLocationDetail();
  const locationsList = useSelector(getLocationsState);
  const locationsHash = useSelector(selectors.getLocationsHash);

  const dispatch = useDispatch();
  const { PAYWALL_LIMITS, PERMISSIONS } = useCustomPermission();
  const handleBackClick = () => {
    const prevUrl = selectedLocation?.ParentId
      ? `/locations/${selectedLocation?.ParentId}`
      : '/locations';
    navigateWithWorkspaceUrl(prevUrl);
  };
  const handleNavigateToParentLocation = (location) => {
    const path = location.ParentId
      ? `/locations/${location?.ParentId}/sl/${location?.id}`
      : `/locations/${location?.id}`;

    navigateWithWorkspaceUrl(path);
  };
  const fetchLocationsTaskHistory = async () => {
    const taskHistoryList = await xeniaApi.getLocationTaskHistory(
      selectedLocation?.id,
    );
    dispatch(setLocationTaskHistory(taskHistoryList));
  };
  const onTaskCreationCb = async () => {
    onAssignWorkCb?.();
    await fetchLocationsTaskHistory();
  };
  //Open create task dialog
  const handleCreateTask = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          onSuccessCallBack: onTaskCreationCb,
          dialogState: {
            viewLess: false,
          },
          taskData: {
            locationIds: [selectedLocation.id],
          },
        },
      }),
    );
  };

  const parentLocation = useMemo(() => {
    return locationsList.find((l) => selectedLocation?.ParentId == l?.id);
  }, [selectedLocation]);

  const allNodes = useMemo(() => {
    return getParentsNodes(locationsHash, selectedLocation);
  }, [selectedLocation, locationsHash]);

  return (
    <LocationDetailHeaderCnt>
      <BreadcrumbHeadingCnt>
        <CustomToolTip title={'Back'}>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
        </CustomToolTip>
        <LocationsHeading
          className={'heading'}
          clickable={Boolean(parentLocation)}
          {...(selectedLocation && {
            onClick: () => navigateWithWorkspaceUrl('/locations'),
          })}
        >
          Locations
        </LocationsHeading>
        <ChevronRightIcon className={'rightArrowIcon'} />

        {allNodes?.length > 2 && (
          <>
            <Tooltip
              arrow
              PopperProps={{
                sx: {
                  zIndex: 1300,
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'white !important',
                    boxShadow:
                      'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                    minWidth: 100,
                    maxHeight: 300,
                    overflowY: 'auto',
                    borderRadius: '15px',
                  },
                },
              }}
              title={
                <DottedContainer>
                  {allNodes?.map((item, index) => {
                    if (
                      index === allNodes?.length - 1 ||
                      index === allNodes?.length - 2
                    ) {
                      return null;
                    }

                    if (index === 0) {
                      return (
                        <DottedText
                          onClick={() => handleNavigateToParentLocation(item)}
                        >
                          {item?.name}
                        </DottedText>
                      );
                    }

                    return (
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="10px"
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={() => handleNavigateToParentLocation(item)}
                      >
                        <ChildItemIcon
                          sx={{
                            fontSize: 16,
                            color: '#71777ac9',
                            marginBottom: 1,
                          }}
                        />
                        <DottedText>{item?.name}</DottedText>
                      </Stack>
                    );
                  })}
                </DottedContainer>
              }
            >
              <Dots>...</Dots>
            </Tooltip>
            <ChevronRightIcon className={'rightArrowIcon'} />
          </>
        )}

        {allNodes?.length > 1 && (
          <>
            <LocationName
              onClick={() =>
                handleNavigateToParentLocation(allNodes?.[allNodes?.length - 2])
              }
            >
              {allNodes?.[allNodes?.length - 2]?.name}
            </LocationName>
            <ChevronRightIcon className={'rightArrowIcon'} />
          </>
        )}

        {allNodes?.[allNodes?.length - 1] && (
          <LocationName selected={true}>
            {allNodes?.[allNodes?.length - 1]?.name}
          </LocationName>
        )}
      </BreadcrumbHeadingCnt>
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
        <VerifyFeatureAccess paywallUsageId={PAYWALL_LIMITS.ONE_OFF_TASKS}>
          <CustomButton
            variant="contained"
            startIcon={
              <QueryBuilderRoundedIcon
                style={{ color: 'white', fontSize: 22 }}
              />
            }
            style={{ padding: '7px 16px', borderRadius: '6px' }}
            onClick={handleCreateTask}
            className="createWO"
          >
            Assign Work
          </CustomButton>
        </VerifyFeatureAccess>
      </HasPermission>
    </LocationDetailHeaderCnt>
  );
}

export default LocationDetailHeader;
