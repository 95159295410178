import { useContext } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import { MainCnt, Value } from './style';

function FolderName(props) {
  const { isArchive } = useContext(MyTemplatesContext);
  const { folder } = props;
  const { title, meta } = folder;

  return (
    <MainCnt>
      <TemplateFolderIcon
        primaryColor={isArchive ? '#E4E4E4' : meta?.primaryColor}
        secondaryColor={isArchive ? '#989898' : meta?.secondaryColor}
        className="icon"
      />
      <Value style={{ fontWeight: 700 }}>{title}</Value>
    </MainCnt>
  );
}

export default FolderName;
