// MUI components
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .area': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

export const SubmissionListWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3, 2, 0),
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  width: '458px',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
}));

export const SubmissionPreviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 0, 0, 3),
  height: '100%',
  overflow: 'auto',
  flex: 1,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 6,
  height: 36,
  color: 'rgba(66, 66, 66, 1)',
  border: '1px solid rgba(189, 189, 189, 1)',
  '&:hover': {
    background: 'rgba(189, 189, 189, 0.3)',
    border: '1px solid rgba(189, 189, 189, 1)',
  },
}));

export const IconButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: '1px solid #e0e0e0',
  borderRadius: 8,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 6,
  cursor: 'pointer',
  height: 40,
  '& .content': {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    padding: '4px',
    '& span': {
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.17px',
      lineHeight: 'normal',
    },
  },
  '&:hover': {
    background: 'rgba(189, 189, 189, 0.3)',
    transition:
      'background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms',
  },
  transition:
    'background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms',
}));
