import { createSvgIcon } from '@mui/material';

interface IProps {
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const WordIcon = (props: IProps) => {
  const { bgColor = '#1E88E5', fillColor = 'white', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <rect width="32" height="32" rx="6" fill={bgColor} />
      <path
        d="M17.5842 6.82584L7.29847 8.77737C7.12484 8.81037 7 8.96822 7 9.14472V22.8513C7 23.0278 7.12484 23.1857 7.29847 23.2187L17.5842 25.1702C17.6071 25.1745 17.6301 25.1817 17.6531 25.1817C17.7377 25.1817 17.8166 25.1559 17.8827 25.1013C17.9673 25.031 18.0204 24.9234 18.0204 24.8144V7.18171C18.0204 7.07265 17.9673 6.96503 17.8827 6.89472C17.798 6.8244 17.6918 6.80575 17.5842 6.82584ZM18.7551 9.01844V11.5899H23.1633V12.3246H18.7551V14.1613H23.1633V14.896H18.7551V16.7327H23.1633V17.4674H18.7551V19.6715H23.1633V20.4062H18.7551V22.9776H24.2653C24.67 22.9776 25 22.6476 25 22.2429V9.75313C25 9.34848 24.67 9.01844 24.2653 9.01844H18.7551ZM8.69898 12.5656H10.0077L10.7538 17.1345C10.7868 17.3282 10.8084 17.598 10.8227 17.9266H10.8457C10.8557 17.6812 10.8988 17.4143 10.9605 17.123L11.9247 12.5656H13.2105L14.0829 17.1804C14.1145 17.3498 14.1389 17.5923 14.1633 17.9151H14.1747C14.1848 17.6626 14.2121 17.4043 14.2551 17.146L14.9898 12.5656H16.2066L14.7946 19.4304H13.463L12.5791 15.0337C12.5317 14.8042 12.5088 14.5373 12.4987 14.2531H12.4872C12.4643 14.566 12.4255 14.8314 12.3839 15.0337L11.477 19.4304H10.0995L8.69898 12.5656Z"
        fill={fillColor}
      />
    </>,
    'WordIcon',
  );
  return <IconCmp viewBox={'0 0 32 32'} {...rest} />;
};
export default WordIcon;
