import React from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.min.css';
import 'ag-grid-enterprise';
import { useDispatch } from 'react-redux';

import { isEqual } from 'lodash';
import {
  LocationContainer,
  TeamsTileInnerCnt,
} from 'pages/teams/teamsList/teamsGridView.style';

import { Grid, Typography } from '@mui/material';
import TeamsActionDropdown from 'pages/teams/teamsList/TeamsActionDropdown/teamsActionDropdown';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import UsersDropdownCmp from 'components/Dropdown/UsersDropdown/UsersDropdown.cmp';
import xeniaApi from 'api/index';
import { editTeam } from 'store/actions/teamActions';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import LocationIcon from 'components/Icons/locationIcon';

const TeamsGridView = ({ taskActionDropdownProps, toggleDrawer, data }) => {
  const dispatch = useDispatch();

  const handleSelectMember = async (member, team) => {
    const membersIdsArr = team?.Members?.map((m) => m.MemberId);
    const isMemberAlreadyExist = membersIdsArr.some((m) => m == member.id);
    const finalMemberArr = isMemberAlreadyExist
      ? membersIdsArr.filter((m) => m !== member.id)
      : [...membersIdsArr, member.id];
    const postData = {
      id: team?.id,
      Members: finalMemberArr,
      name: team?.name,
      isActive: true,
    };
    const newTeamObj = await xeniaApi.editTeam(postData);
    dispatch(editTeam(newTeamObj?.data, team));
  };
  return (
    <Grid container spacing={2}>
      {data.map((t) => {
        return (
          <Grid
            item
            xs={6}
            md={4}
            lg={4}
            xl={3}
            onClick={() => taskActionDropdownProps.handleEditTeam(t)}
          >
            <TeamsTileInnerCnt>
              <div className="teamsTitleHeaderCnt">
                <div className="teamTitleCnt">
                  <TeamAvatar teamId={t.id} width={32} height={32} />
                  <span className="teamsTitle" title={t.name}>
                    {t.name}
                  </span>
                </div>
                <TeamsActionDropdown data={t} {...taskActionDropdownProps} />
              </div>
              <div className="gridItemFooter">
                <UsersDropdownCmp
                  handleChangeCallback={(member) =>
                    handleSelectMember(member, t)
                  }
                  members={t.Members.map((m) => m.MemberId)}
                />

                <AssigneeDropdown
                  handleChangeCallback={(data: any) => {
                    taskActionDropdownProps?.handleUpdateCall?.({
                      id: t?.id,
                      name: t?.name,
                      Members: t?.Members?.map((item) => item?.MemberId) ?? [],
                      isActive: true,
                      LocationId: data?.currentSelected,
                    });
                  }}
                  buttonRendererProps={{
                    width: '100%',
                  }}
                  buttonRenderer={
                    <LocationContainer addLocation={!t?.Location?.name}>
                      <LocationIcon
                        className="icon"
                        sx={{
                          width: '16px',
                          '& path': {
                            stroke: t?.Location?.name ? '#212121' : '#1d1dd9',
                          },
                        }}
                      />
                      <Typography
                        className="name"
                        style={{
                          color: t?.Location?.name ? '#212121' : '#6868FE',
                        }}
                      >
                        {t?.Location?.name || 'Add to Location'}
                      </Typography>
                    </LocationContainer>
                  }
                  showTabs={false}
                  listProps={{
                    locations: {
                      showInlineSelection: false,
                      isSearchable: true,
                      isMulti: false,
                      hideActions: false,
                      selected: t?.LocationId ? [t?.LocationId] : [],
                      addLocationByTag: false,
                    },
                  }}
                  displayOptions={{
                    locations: true,
                  }}
                  popperProps={{
                    disablePortal: true,
                    onClick: (e) => e?.stopPropagation(),
                  }}
                  allSelected={false}
                />
              </div>
            </TeamsTileInnerCnt>
          </Grid>
        );
      })}
    </Grid>
  );
};
function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.data, nextProps.data);
  /*
  return true if passing nextProps
   to render
   would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}
export default React.memo(TeamsGridView, areEqual);
