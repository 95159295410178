import { Box } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const Container = styled(Box)({
  height: 'calc(100vh - 100px)',
  width: '100%',
  padding: '0px 20px',
});

export const CreateNewBtn = styled(CustomButton)({
  borderRadius: '8px',
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 500,
  marginBottom: 15,
  height: 36,
});
