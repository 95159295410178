import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ChecklistItem from 'components/common/jsxrender/checklistV2/builder/checklistItem';

const ChecklistItemWrapper = (props: {
  checklist: any;
  checklistId: string;
  checklistItems: any;
  childItems?: any[] | null;
  checklistItemIndex: number;
  checklistItemData: any;
  defaultLocationStep: string | null;
  setDefaultLocationStep: (stepId: string | null) => void;
  automations?: any;
  autoFocusItemId: any;
  setAutoFocusItemId: any;
  addingItem: boolean;
  sectionSteps: any;
  sectionVisibility: any;
  addChecklistItem: (itemType: string, index, prevDescription?: string) => void;
  handleUpdateChecklistItems: (
    parentId: null | undefined | string,
    newItems: any[],
    checklistItems: any[],
  ) => void;
  handleAddFollowUpItem: (item: any, prevItemData?: any) => void;
  fetchAndSetChecklistAutomations?: () => void;
  handleAddChecklistItem: (
    itemType: string,
    itemId: string,
    itemData?: any,
  ) => void;
  deleteChecklistItemById: (itemId: string) => void;
  toggleSectionCollapse: (sectionId: string) => void;
  sectionEditingId: string | null;
  setSectionEditingId: (sectionId: string) => void;
  originalChecklistItems: any;
  handleSetFollowupVisibility: (
    checklistItemId: string,
    automationId: string,
  ) => void;
  followupVisibility: any;
  itemCollapseState: any;
  handleItemCollapse: (itemId: string, unCollapse?: boolean) => void;
  autoFocusCursorPosition: number | null;
  setAutofocusCursorPosition: (index: number | null) => void;
  getSectionId?: (checklistItemIndex: number) => string | null;
  handleCollapseAllItems: (collapse: boolean) => void;
  uploadingItemId: string | null;
  setUploadingItemId: (itemId: string | null) => void;
  checklistItemsHashRef: any;
  updateChecklistItemsHash: (checklistItemId: string, itemData: any) => void;
  uploadAttachmentsToStep: (itemId: string, files: any) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.checklistItemData.id });
  const style: any = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    userSelect: 'none',
    cursor: 'default',
    padding: '0px',
  };

  return (
    <div id={props.checklistItemData.id} ref={setNodeRef} style={style}>
      <ChecklistItem
        id={props.checklistItemData.id}
        key={props.checklistItemData.id}
        checklist={props.checklist}
        checklistId={props.checklistId}
        checklistItems={props.checklistItems}
        checklistItemIndex={props.checklistItemIndex}
        checklistItemData={props.checklistItemData}
        defaultLocationStep={props.defaultLocationStep}
        setDefaultLocationStep={props.setDefaultLocationStep}
        addChecklistItem={props.addChecklistItem}
        autoFocusItemId={props.autoFocusItemId}
        setAutoFocusItemId={props.setAutoFocusItemId}
        handleUpdateChecklistItems={props.handleUpdateChecklistItems}
        fetchAndSetChecklistAutomations={props.fetchAndSetChecklistAutomations}
        handleAddFollowUpItem={props.handleAddFollowUpItem}
        automations={props.automations}
        deleteChecklistItemById={props.deleteChecklistItemById}
        handleAddChecklistItem={props.handleAddChecklistItem}
        childItems={props.childItems}
        sortableProps={{
          attributes,
          listeners,
          setNodeRef,
          transform,
          transition,
        }}
        addingItem={props.addingItem}
        sectionSteps={props.sectionSteps}
        sectionVisibility={props.sectionVisibility}
        toggleSectionCollapse={props.toggleSectionCollapse}
        sectionEditingId={props.sectionEditingId}
        setSectionEditingId={props.setSectionEditingId}
        originalChecklistItems={props.originalChecklistItems}
        handleSetFollowupVisibility={props.handleSetFollowupVisibility}
        followupVisibility={props.followupVisibility}
        itemCollapseState={props.itemCollapseState}
        handleItemCollapse={props.handleItemCollapse}
        autoFocusCursorPosition={props.autoFocusCursorPosition}
        setAutofocusCursorPosition={props.setAutofocusCursorPosition}
        getSectionId={props.getSectionId}
        handleCollapseAllItems={props.handleCollapseAllItems}
        uploadingItemId={props.uploadingItemId}
        setUploadingItemId={props.setUploadingItemId}
        checklistItemsHashRef={props.checklistItemsHashRef}
        updateChecklistItemsHash={props.updateChecklistItemsHash}
        uploadAttachmentsToStep={props.uploadAttachmentsToStep}
      />
    </div>
  );
};

export default ChecklistItemWrapper;
