import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  ColorsDropdown,
  ColorContainer,
  ColorSpan,
  UploadDiv,
  UploadLink,
  UploadText,
  RemoveImageLink,
} from './style';
import CustomButton from 'components/Button/CustomButton';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import UploadImage from 'components/Icons/UploadImage';

import { userProfileUploadApi } from 'api/userApi';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const colors = [
  '#C7ADFE',
  '#FFA681',
  '#F8D488',
  '#F88989',
  '#91BFF8',
  '#89CFC2',
];

const WSColorsDropdown = ({
  setColor,
  setPhoto = (filePath) => {
    //
  },
  color,
  photo = null,
  hideButton = false,
  popperProps = {},
  canSetLogo = false,
}) => {
  const { hasFeature, FEATURES } = useCustomPermission();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: {
      'image/*': [],
    },
    multiple: false,
    maxFiles: 1,
    maxSize: 26214400, // MAX 25MB size
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      const upload = async () => {
        const response = await userProfileUploadApi(acceptedFiles[0]);
        const cdnPath = `${response.baseUrl}${response.filePath}`;
        setPhoto(cdnPath);
      };
      upload();
    }
  }, [acceptedFiles]);

  const colorButton = () => {
    if (hideButton) {
      return null;
    }
    return <ColorSpan color={color} />;
  };

  const renderLogoUploadUI = () => {
    if (!canSetLogo) return null;
    if (!hasFeature(FEATURES.LOGO_UPLOAD)) return null;
    const renderRemove = () => {
      if (!photo) return;
      return (
        <RemoveImageLink
          onClick={() => {
            setPhoto(null);
          }}
        >
          Remove
        </RemoveImageLink>
      );
    };

    return (
      <UploadDiv>
        <UploadLink {...getRootProps()}>
          <input
            {...getInputProps()}
            className={'p-0'}
            accept="image/png,image/jpeg,image/jpg,application/pdf"
          />
          <UploadImage /> <UploadText>Upload Image</UploadText>
        </UploadLink>
        {renderRemove()}
      </UploadDiv>
    );
  };

  return (
    <CustomDropdown
      popperProps={{
        placement: 'bottom-start',
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        },
        content: (
          <ColorsDropdown>
            <div className="mainTitle">Workspace Color</div>
            <div style={{ height: '15px' }} />
            <div className="subtitle">Select Color</div>
            <div style={{ height: '15px' }} />
            <div style={{ display: 'flex' }}>
              {colors.map((c) => (
                <ColorContainer color={c}>
                  <CustomButton
                    disableElevation
                    onClick={() => {
                      setColor(c);
                    }}
                  >
                    <ColorSpan color={c} />
                  </CustomButton>
                </ColorContainer>
              ))}
            </div>
            {renderLogoUploadUI()}
          </ColorsDropdown>
        ),
        ...popperProps,
      }}
      iconButton={true}
    >
      {colorButton()}
    </CustomDropdown>
  );
};

export default WSColorsDropdown;
