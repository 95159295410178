import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const Container = styled(Box)(({ isactive }) => ({
  display: 'flex',
}));

export const StyledIconButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isactive',
})(({ isactive }) => ({
  color: isactive ? 'rgba(104, 104, 254, 1)' : 'rgba(0, 0, 0, 0.6)',
  fontSize: '12px',
  fontWeight: 900,
  lineHeight: '20px',
  backgroundColor: isactive ? 'rgba(104, 104, 254, 0.1)' : null,
  '& .MuiButton-startIcon > svg': {
    color: isactive ? 'rgba(104, 104, 254, 1)' : 'rgba(0, 0, 0, 0.6)',
  },
}));
