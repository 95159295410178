import CTabs from 'components/CTabs';
import { useCallback, useEffect, useMemo } from 'react';
import ManageSchedules from 'pages/Schedules/ManageSchedules/ManageSchedules';
import Overdue from 'pages/Schedules/Overdue';
import Completed from 'pages/Schedules/Completed';
import { useParams } from 'react-router-dom';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { Container } from 'pages/Schedules/style';

function ScheduledWork() {
  const { tab } = useParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const tabs = useMemo(
    () => [
      {
        label: 'Schedules',
        content: () =>
          tab === 'manage-schedules' ? (
            <ManageSchedules persistKey="schedulesView" />
          ) : (
            <></>
          ),
        path: 'manage-schedules',
      },
      {
        label: 'Overdue',
        content: () => (tab === 'overdue' ? <Overdue /> : <></>),
        path: 'overdue',
      },
      {
        label: 'Completed',
        content: () => (tab === 'completed' ? <Completed /> : <></>),
        path: 'completed',
      },
    ],
    [tab],
  );

  useEffect(() => {
    if (!tab) {
      navigateWithWorkspaceUrl(`/scheduled-work/manage-schedules`);
    }
  }, []);

  //Return object of tab based on the url path
  const getActiveTab = useCallback(
    (activeTab) => tabs.findIndex((t) => t.path === activeTab),
    [tab],
  );

  //Callback function to change the tab based on the url path
  const onTabChangeCb = (activeTab: number) => {
    const path = tabs[activeTab].path;
    navigateWithWorkspaceUrl(`/scheduled-work/${path}`);
  };

  return (
    <Container>
      <CTabs
        data={tabs}
        onTabChangeCb={onTabChangeCb}
        activeTab={getActiveTab(tab)}
        variant="scrollable"
      />
    </Container>
  );
}

export default ScheduledWork;
