import { Box, Chip, Skeleton, Stack } from '@mui/material';
import styled from '@mui/system/styled';
import { CHIP_COLORS } from 'components/TemplateLibrary/TemplateTile/constants';

export const Container = styled(Box)({
  height: 218,
  borderRadius: 10,
  padding: 20,
  gap: 12,
  backgroundColor: '#ECF5FC',
  marginTop: 15,
});

export const Header = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .title': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24.59px',
    color: '#000000',
  },
  '& .industry': {
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '24.59px',
    color: '#4E48FA',
  },

  '& .viewAll': {
    height: 30,
    width: 68,
    borderRadius: 4,
    padding: 0,
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '22px',
  },
  '& .closeIconBtn': {
    color: '#616161',
    cursor: 'pointer',
    height: 19,
    width: 19,
  },
});

export const Content = styled(Box)({
  marginTop: 12,
});

export const SkeletonBox = styled(Skeleton)({
  borderRadius: 12,
  height: 136,
  width: '100%',
  marginBottom: 15,
});

export const ChecklistBox = styled(Box)({
  border: '1px solid #E0E0E0',
  padding: '12px 16px 16px 16px',
  borderRadius: 12,
  gap: 8,
  height: 136,
  backgroundColor: 'white',
  boxShadow: '0px 2px 1px 0px #0000000D',
  marginBottom: 15,
  cursor: 'pointer',
  '&:hover': {
    border: '1px solid #6868FE',
  },

  '& .title': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '19.12px',
    letterSpacing: '-0.1px',
    color: '#212121',

    display: 'inline-block',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '& .description': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '-0.1px',
    color: '#424242',
  },
});

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => !['type', 'chipcolors'].includes(prop),
})(({ type, typelabel }: { typelabel: string; type: 'industry' | 'type' }) => ({
  padding: '7px 6px 4px 6px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: CHIP_COLORS[type]?.[typelabel]?.bg,
  height: '24px',
  width: 'fit-content',
  marginBottom: 10,
  '& > span': {
    color: CHIP_COLORS[type]?.[typelabel]?.text,
    padding: 0,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16.39px',
    letterSpacing: '-0.1px',
  },
}));
