import { getAuthorizedLocations } from 'api/locations';
import { find, flatMap, map } from 'lodash';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { createHashFromLocations } from 'utils/locations';

export default function useLocationsUtils() {
  const dispatch = useDispatch();

  const flattenLocations = (location) => {
    const flattenedLocation = { ...location };

    if (location.Sublocations && location.Sublocations.length > 0) {
      const subLocationsWithPath = location.Sublocations.flatMap(
        (subLocation) => flattenLocations(subLocation),
      );
      return [flattenedLocation, ...subLocationsWithPath];
    }

    return [flattenedLocation];
  };

  // Get Node and Its Children till leaf node based on id
  const getAllSubLocationsIdsById = (nodeId, data) => {
    function getNodeAndChildrenIdsById(id, nodes) {
      for (const node of nodes) {
        if (node.id === id) {
          const ids = [node.id];
          if (node.Sublocations && node.Sublocations.length > 0) {
            for (const child of node.Sublocations) {
              const childIds = getNodeAndChildrenIdsById(
                child.id,
                node.Sublocations,
              );
              ids.push(...childIds);
            }
          }
          return ids;
        } else if (node.Sublocations && node.Sublocations.length > 0) {
          const foundInChildren = getNodeAndChildrenIdsById(
            id,
            node.Sublocations,
          );
          if (foundInChildren.length > 0) {
            return foundInChildren;
          }
        }
      }

      return []; // If the node with the given id is not found
    }

    const result = getNodeAndChildrenIdsById(nodeId, data);
    return result;
  };

  // Get Specific Node
  const getSublocationsById = (id, data) => {
    const foundObject = find(data, { id });

    if (foundObject) {
      return foundObject.Sublocations || [];
    }

    const sublocations = flatMap(data, 'Sublocations');
    if (sublocations.length > 0) {
      return getSublocationsById(id, sublocations);
    }

    return [];
  };

  // Check is All selected

  const isAllSelected = (selectedIds, data) => {
    function checkAllIdsIncluded(selectedIds, data) {
      for (const node of data) {
        if (!selectedIds.includes(node.id)) {
          return false;
        }

        if (node.Sublocations && node.Sublocations.length > 0) {
          const areChildrenIncluded = checkAllIdsIncluded(
            selectedIds,
            node.Sublocations,
          );
          if (!areChildrenIncluded) {
            return false;
          }
        }
      }

      return true;
    }

    return checkAllIdsIncluded(selectedIds, data);
  };

  const getSublocationIdsById = (id, data) => {
    const foundObject = find(data, { id });

    if (foundObject) {
      return map(foundObject.Sublocations, 'id');
    }

    const sublocationIds = flatMap(data, (obj) =>
      obj.sublocations ? getSublocationIdsById(id, obj.Sublocations) : [],
    );

    return sublocationIds;
  };

  const searchObjectsByName = (name, nodes) => {
    const result: any = [];
    for (const node of nodes) {
      if (
        node.name &&
        node.name?.toLowerCase()?.includes(name?.toLowerCase())
      ) {
        result.push(node);
      }

      if (node.Sublocations && node.Sublocations.length > 0) {
        const matchingSublocations = searchObjectsByName(
          name,
          node.Sublocations,
        );
        result.push(...matchingSublocations);
      }
    }
    return result;
  };

  const getParentsNodes = (data, selectedLocation, isAuth = false) => {
    const allNodes: Array<any> = [];

    const location = data?.[selectedLocation?.id];

    if (location && isAuth && location?.authorized) {
      allNodes?.unshift(location);
    }

    if (location && !isAuth) {
      allNodes?.unshift(location);
    }

    let parentId = location?.ParentId;

    while (parentId) {
      const parent = data?.[parentId];

      if (parent && isAuth && parent?.authorized) {
        allNodes?.unshift(parent);
      }

      if (parent && !isAuth) {
        allNodes?.unshift(parent);
      }

      parentId = parent?.ParentId;
    }

    return allNodes;
  };

  const fetchAuthLocations = async () => {
    const authLocationsRes = await getAuthorizedLocations();

    const authLocationsHash = (authLocationsRes || []).reduce(
      (acc, location) => {
        const subLocations = location?.Sublocations ?? [];
        const subLocationsHash = createHashFromLocations(subLocations);
        return {
          ...acc,
          [location.id]: { ...location, Sublocations: subLocationsHash },
          ...subLocationsHash,
        };
      },
      {},
    );

    dispatch(actions?.setAuthorizedLocations(authLocationsRes ?? []));
    dispatch(actions?.setAuthLocationsHash(authLocationsHash));
  };

  return {
    searchObjectsByName,
    flattenLocations,
    getAllSubLocationsIdsById,
    getSublocationIdsById,
    getSublocationsById,
    isAllSelected,
    getParentsNodes,
    fetchAuthLocations,
  };
}
