import styled from '@mui/system/styled';

export const TeamsTileInnerCnt = styled('div')({
  boxShadow: '0 6px 24px 0 rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 8%)',
  borderRadius: 8,
  padding: 16,
  '& .gridItemFooter': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      fontSize: 13,
      marginRight: 10,
      fontWeight: 400,
    },
  },
  '& .teamsTitleHeaderCnt': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 38,
  },
  '& .teamTitleCnt': {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    flexBasis: '100%',
    '& .teamsTitle': {
      fontSize: 16,
      marginLeft: 8,
      fontWeight: 600,
      width: '95%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

export const LocationContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'addLocation',
})(({ addLocation }) => ({
  padding: '4px 8px 4px 8px',
  gap: '5px',
  border: '1px solid #EEEEEE',
  borderRadius: '60px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: 145,
  textOverflow: 'ellipsis',

  '&:hover': {
    backgroundColor: addLocation ? '#F5F5FF' : 'unset',
  },

  '& .icon': {
    height: 16,
    color: '#757575',
  },

  '& .name': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16.39px',
    letterSpacing: '0.37px',
    color: '#212121',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
