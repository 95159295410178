import { createSvgIcon } from '@mui/material';

const MCQIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66675 10C6.66675 9.53977 7.03984 9.16668 7.50008 9.16668L17.5001 9.16668C17.9603 9.16668 18.3334 9.53977 18.3334 10C18.3334 10.4602 17.9603 10.8333 17.5001 10.8333L7.50008 10.8333C7.03984 10.8333 6.66675 10.4602 6.66675 10Z"
        fill="#26C6DA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66675 5.00001C6.66675 4.53977 7.03984 4.16668 7.50008 4.16668L17.5001 4.16668C17.9603 4.16668 18.3334 4.53977 18.3334 5.00001C18.3334 5.46025 17.9603 5.83334 17.5001 5.83334L7.50008 5.83334C7.03984 5.83334 6.66675 5.46025 6.66675 5.00001Z"
        fill="#26C6DA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66675 15C6.66675 14.5398 7.03984 14.1667 7.50008 14.1667L17.5001 14.1667C17.9603 14.1667 18.3334 14.5398 18.3334 15C18.3334 15.4602 17.9603 15.8333 17.5001 15.8333L7.50008 15.8333C7.03984 15.8333 6.66675 15.4602 6.66675 15Z"
        fill="#26C6DA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66675 10C1.66675 9.07954 2.41294 8.33334 3.33341 8.33334C4.25389 8.33334 5.00008 9.07954 5.00008 10C5.00008 10.9205 4.25389 11.6667 3.33341 11.6667C2.41294 11.6667 1.66675 10.9205 1.66675 10Z"
        fill="#26C6DA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66675 5.00001C1.66675 4.07954 2.41294 3.33334 3.33341 3.33334C4.25389 3.33334 5.00008 4.07954 5.00008 5.00001C5.00008 5.92048 4.25389 6.66668 3.33341 6.66668C2.41294 6.66668 1.66675 5.92048 1.66675 5.00001Z"
        fill="#26C6DA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66675 15C1.66675 14.0795 2.41294 13.3333 3.33341 13.3333C4.25389 13.3333 5.00008 14.0795 5.00008 15C5.00008 15.9205 4.25389 16.6667 3.33341 16.6667C2.41294 16.6667 1.66675 15.9205 1.66675 15Z"
        fill="#26C6DA"
      />
    </>,
    'MCQIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default MCQIcon;
