import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ReOpenIcon from '@mui/icons-material/CachedOutlined';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { useState } from 'react';
import DeleteIcon from 'components/Icons/deleteIcon';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
const archiveFolderActionDropdownOptions = [
  {
    label: 'Restore',
    id: 'restore',
    icon: <ReOpenIcon />,
    iconAlign: 'start',
  },
  {
    label: 'Delete',
    id: 'delete',
    icon: <DeleteIcon style={{ color: 'red' }} />,
    iconAlign: 'start',
    sx: {
      color: 'red',
    },
  },
];
interface IArchiveFolderActionDropdownProps {
  deleteFolderCallback: (folder: string) => void;
  folder: any;
}
function ArchiveFolderActionDropdown(props: IArchiveFolderActionDropdownProps) {
  const { deleteFolderCallback, folder } = props;
  const { unarchiveFolder, deleteFolderCall } = useDocumentsFolders();
  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const dispatch = useDispatch();
  const deleteFolder = async () => {
    setDeleteButtonLoader(true);
    const response = await deleteFolderCall(folder.id);
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.CONFIRMATION,
      }),
    );
    setDeleteButtonLoader(false);

    if (response) {
      deleteFolderCallback(folder.id);
    }
  };
  const handleRestoreFolder = async () => {
    await unarchiveFolder(folder?.id);
    deleteFolderCallback(folder?.id);
  };
  //Handler for option select of action dropdown
  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'delete':
        dispatch(
          actions.setDialog({
            dialogId: DIALOGS.CONFIRMATION,
            open: true,
            data: {
              title: 'Delete Folder',
              description:
                'Are you sure you want to delete this folder and all its contents?',
              onConfirmCallback: deleteFolder,
              confirmButtonProps: {
                buttonLoader: deleteButtonLoader,
              },
              // onConfirmCallbackParams: values,
              // onCancelCallback: () => setConfirmationData(null),
            },
          }),
        );
        break;
      case 'restore':
        handleRestoreFolder();
        break;
    }
  };

  return (
    <ActionDropdown
      rootProps={{
        id: 'folders-action-dropdown',
      }}
      options={archiveFolderActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { padding: 2, transform: 'rotate(90deg)' },
      }}
      popperProps={{
        placement: 'bottom-start',
        sx: {
          zIndex: 1301,
        },
      }}
      paperProps={{
        style: {
          minWidth: 145,
        },
      }}
    >
      {' '}
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
}

export default ArchiveFolderActionDropdown;
