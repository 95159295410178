import React, { useCallback, useEffect, useState } from 'react';
import { TasksByCategoryCnt } from 'pages/Widgets/TaskByCategory/tasksByCategory.style';
import { WidgetHeading, WidgetLoaderCnt } from 'pages/Widgets/widgets.style';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import xeniaApi from 'api/index';
import CircularProgress from '@mui/material/CircularProgress';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import TasksByCategoryEmptyState from './EmptyRecords/TaskByCategoryEmptyState.cmp';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

function TasksByCategory({ filters, reload, setReload }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const transformDataForChart = useCallback(
    (countsData) => {
      const transformedData = countsData
        ?.map((d) => {
          return {
            name: d?.category,
            value: d?.count,
            id: d?.id,
          };
        })
        .filter((d) => d?.name !== 'No Category');
      return transformedData;
    },
    [filters, data],
  );

  //Fetch Report data
  const fetchTasksByCategories = async () => {
    setLoading(true);
    const requestPayload = {
      ...filters,
    };
    const data = await xeniaApi.getTaskCountsByCategories(requestPayload);
    if (data) {
      const transformedData = transformDataForChart(data);
      setData(transformedData);
    }
    setLoading(false);
  };

  //Load data on mount and when filters change
  useEffect(() => {
    fetchTasksByCategories();
  }, [filters]);

  useEffect(() => {
    if (reload) {
      fetchTasksByCategories();
    }
  }, [reload]);

  const CustomLabel = ({
    cx,
    cy,
    value,
    innerRadius,
    outerRadius,
    midAngle,
    stroke,
    name,
    percent,
    ...rest
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) + 25;
    const cos = Math.cos(-RADIAN * midAngle);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentage = `${(percent * 100).toFixed(2)}%`;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <text
        cx={cx}
        cy={cy}
        stroke="none"
        x={x}
        y={y}
        alignment-baseline="middle"
        className="recharts-text recharts-pie-label-text"
        text-anchor={textAnchor}
        fill={'#212121'}
      >
        <tspan x={x} dy="5px" fontSize={12}>
          {name}&nbsp;
          <tspan fontSize={12} fontWeight={600}>
            {value}
          </tspan>
          <tspan fontSize={12} fill={'#616161'}>
            &nbsp;({percentage})
          </tspan>
        </tspan>
      </text>
    );
  };
  const handleViewTasks = async (id) => {
    const selectiveTaskList = await xeniaApi.getTasksList({
      categories: [id],
      ...filters,
    });
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.SHOW_MORE_TASKS,
        data: {
          successCB: setReload ? () => setReload(true) : null,
          tasks: selectiveTaskList,
          hideDate: true,
          getRequest: {
            categories: [id],
            ...filters,
          },
        },
      }),
    );
  };
  return (
    <TasksByCategoryCnt>
      <WidgetHeading>By Category</WidgetHeading>
      {loading && (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      )}
      {!loading && data?.length === 0 && <TasksByCategoryEmptyState />}
      {!loading && data?.length > 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart
            width={400}
            height={500}
            margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
          >
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={120}
              fill="#8884d8"
              label={CustomLabel}
              minAngle={15}
              onClick={(e) => handleViewTasks(e?.payload?.id)}
              // label
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{ background: 'black', color: '#fff' }}
              itemStyle={{ color: '#fff' }}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </TasksByCategoryCnt>
  );
}

export default TasksByCategory;
