import styled from '@mui/system/styled';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '32px',
  textAlign: 'center',

  '& .content': {
    padding: '32px 82px',
  },

  '& .qr-code': {
    width: '164px',
    margin: '12px 0',
  },

  '& h3': {
    fontWeight: '700',
    paddingBottom: '8px',
    fontSize: '24px',
  },
  '& .sales-btn': {
    marginTop: '12px',
    marginBottom: '16px',
    marginLeft: '12px',
  },
});
