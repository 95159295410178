import { useState } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { validateEmail } from 'utils/globalFunction';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { useReactRouter } from 'controller/useReactRouter';
import xeniaApi from 'api/index';
import XeniaLogoSymbol from 'public/assets/img/xenia-logo-symbol.svg';
import {
  DividerLine,
  EmailContainer,
  LabelTitle,
  MainTitle,
  NextButton,
  SignUpContent,
  SubTitle,
} from './style';

function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordResetSent, setResetPasswordSent] = useState<boolean>(false);
  const [resetPasswordBtnLoader, setResetPasswordBtnLoader] =
    useState<boolean>(false);

  const { handleRedirectToRoute } = useReactRouter();

  //Handle Email Input Change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmailError('');
    setEmail(value);
  };

  //Validate Email Input
  const validateEmailInput = () => {
    if (!email) {
      setEmailError(VALIDATION_ERROR_MESSAGES.REQUIRED_EMAIL_ID);
      return false;
    }
    if (!validateEmail(email)) {
      {
        setEmailError(VALIDATION_ERROR_MESSAGES.REQUIRED_VALID_EMAIL_ID);
        return false;
      }
    }
    return true;
  };

  const handleResetPassword = async () => {
    setResetPasswordBtnLoader(true);
    if (!validateEmailInput()) {
      setResetPasswordBtnLoader(false);
      return;
    }
    await xeniaApi.sendLoginMagicLink({
      emailId: email,
      forgotPassword: true,
    });
    setResetPasswordSent(true);
    setResetPasswordBtnLoader(false);
  };
  //When user press enter key
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode == 13) {
      handleResetPassword();
      return;
    }
  };

  const navigateToRegister = () => {
    handleRedirectToRoute('/register');
  };

  return (
    <>
      <img
        src={XeniaLogoSymbol}
        className="logo"
        height={40}
        width={70}
        style={{ marginBottom: 20 }}
      />
      {passwordResetSent ? (
        <>
          <MainTitle>Password reset link sent</MainTitle>
          <SubTitle>
            <b>{email}</b>
          </SubTitle>
          <SubTitle style={{ textAign: 'center' }}>
            If you don’t see an email, please check your spam folder.
          </SubTitle>
          <SubTitle>
            Need help? Contact us at{' '}
            <a style={{ fontSize: 18 }} href="mailto:support@xenia.team">
              support@xenia.team
            </a>
          </SubTitle>
        </>
      ) : (
        <>
          <MainTitle>Forgot Password</MainTitle>
          <SubTitle>Don’t worry it happens to the best of us.</SubTitle>
          <SubTitle>
            Verify your email address to receive a link to reset your password.
          </SubTitle>
        </>
      )}
      <EmailContainer>
        <LabelTitle>Email</LabelTitle>
        <CustomInput
          error={emailError}
          muiLabel={true}
          fieldProps={{
            autoFocus: true,
            onKeyDown: handleKeyDown,
            onChange: handleEmailChange,
            name: 'email',
            type: 'email',
            value: email,
            placeholder: 'Enter your email',
            disabled: passwordResetSent,
            style: {
              borderRadius: 8,
              padding: 2,
              height: 47,
            },
          }}
        />
      </EmailContainer>

      {!passwordResetSent && (
        <>
          <NextButton
            variant="contained"
            onClick={handleResetPassword}
            buttonLoader={resetPasswordBtnLoader}
          >
            Send password Reset Link
          </NextButton>
        </>
      )}

      {!passwordResetSent && <DividerLine />}

      <NextButton
        variant="outlined"
        onClick={() => handleRedirectToRoute('/sign-in')}
      >
        Back to Login
      </NextButton>

      <SignUpContent>
        Don't have an account?{' '}
        <span className="link" onClick={navigateToRegister}>
          Sign Up
        </span>
      </SignUpContent>
    </>
  );
}
export default ForgotPassword;
