import { useEffect, useState } from 'react';

import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import GlobeIcon from 'components/Icons/globeIcon';
import GearIcon from 'components/Icons/gearIcon';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import moment from 'moment-timezone';
import CTypography from 'components/CTypography';
import DisabledGlobeIcon from 'components/Icons/disabledGlobeIcon';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import DocumentAccess from 'components/DocumentAccess';

import {
  BackText,
  FirstRow,
  GoBackWrapper,
  LastUpdatedCnt,
  RightCnt,
  RowWrapper,
  SettingsButton,
  StyledButton,
  HeaderMainCnt,
} from 'pages/documents/builder/style';
import { useReactRouter } from 'controller/useReactRouter';
import CustomButton from 'components/Button/CustomButton';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import LinkIconRotated from 'components/Icons/LinkIconRotated';

const TextEditorHeader = ({ document, metaInfo, updateDocumentCallback }) => {
  const { publishDocument, unpublishDocument } = useDocumentsFolders();
  const { isMobileDeviceSm } = useResponsive();

  const [publishLoader, setPublishLoader] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);
  const { handleGetQueryParam } = useReactRouter();
  const [backLink, setBackLink] = useState(false);
  const userAuth = useSelector(selectors.getLoggedInUser);
  const queryParams = handleGetQueryParam();

  useEffect(() => {
    const haveSource = queryParams.get('source');
    if (haveSource) {
      setBackLink(window.history.length > 0);
    }
  }, []);

  const goBackHandler = () => {
    const folderId = queryParams.get('folderId');
    let redirectURL = `/ws/${document?.HotelId}/documents/my-documents`;

    if (folderId) {
      redirectURL += `?folderId=${folderId}`;
    }

    if (!userAuth) {
      redirectURL = '/';
    }
    window.open(redirectURL, '_self');
  };

  const handleSettingsOpen = () => {
    setOpenAccess(true);
  };

  const closeHandler = () => {
    setOpenAccess(false);
  };

  const publishHandler = async () => {
    setPublishLoader(true);
    let response = null;
    if (
      document?.isDraft ||
      (!document?.isDraft && document?.DocumentContent?.draftContent)
    ) {
      response = await publishDocument(document?.id);
    } else {
      response = await unpublishDocument(document?.id);
    }

    if (response) {
      updateDocumentCallback(response);
    }

    setPublishLoader(false);
  };

  const updatedAt = document?.DocumentContent?.updatedAt;
  const lastUpdated = updatedAt && moment(updatedAt).format('hh:mm A');

  return (
    <HeaderMainCnt>
      {openAccess && (
        <DocumentAccess
          open={openAccess}
          document={document}
          closeDialog={closeHandler}
          updateCallback={updateDocumentCallback}
        />
      )}
      <RowWrapper>
        <FirstRow>
          <GoBackWrapper onClick={goBackHandler}>
            <ArrowBackRoundedIcon />
            <BackText>Back</BackText>
          </GoBackWrapper>
          {!!metaInfo?.canEdit && (
            <RightCnt>
              {!isMobileDeviceSm && lastUpdated && (
                <LastUpdatedCnt>
                  <CloudDoneOutlinedIcon className="icon" />
                  <CTypography>Last Saved at {lastUpdated}</CTypography>
                </LastUpdatedCnt>
              )}

              <StyledButton
                endIcon={
                  !document?.isDraft ? (
                    document?.DocumentContent?.draftContent ? (
                      <GlobeIcon />
                    ) : (
                      <DisabledGlobeIcon style={{ fontSize: 22 }} />
                    )
                  ) : (
                    <GlobeIcon />
                  )
                }
                disabled={publishLoader}
                buttonLoader={publishLoader}
                variant="outlined"
                onClick={publishHandler}
              >
                {!document?.isDraft &&
                  !document?.DocumentContent?.draftContent &&
                  'Unpublish'}
                {!document?.isDraft &&
                  document?.DocumentContent?.draftContent &&
                  'Republish'}
                {document?.isDraft && 'Publish'}
              </StyledButton>

              <SettingsButton
                variant="contained"
                onClick={handleSettingsOpen}
                startIcon={<LinkIconRotated />}
              >
                Share
              </SettingsButton>
            </RightCnt>
          )}
        </FirstRow>
      </RowWrapper>
    </HeaderMainCnt>
  );
};

export default TextEditorHeader;
