import { useMemo } from 'react';
import { Typography } from '@mui/material';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { LastServiceDateCnt } from './assetDetailTab.style';

interface IProps {
  lastService?: any;
}

const LastServiceDate = (props: IProps) => {
  const { lastService } = props;
  const { getUtcToTz } = useDateTime();

  const lastServiceDateTime = useMemo(() => {
    if (lastService?.date) {
      const TZDateTime = getUtcToTz(
        lastService?.date,
        lastService?.createdInPast ? 'MMM DD, YYYY' : 'MMM DD, YYYY  h:mm A',
      );
      return TZDateTime;
    }
  }, [lastService?.date]);

  return (
    <LastServiceDateCnt>
      <div className="label">Last Service Date</div>
      <div className="innerCnt">
        <Typography className="dateTime">
          {lastServiceDateTime || '-'}
        </Typography>
      </div>
    </LastServiceDateCnt>
  );
};

export default LastServiceDate;
