import { Box, Stack, styled } from '@mui/material';

export const MainCnt = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));

export const AssetsCnt = styled(Box)(() => ({
  '& button': {
    backgroundColor: 'rgba(104,104,254,1)',
    width: 150,
    height: 37,
    '&:hover': {
      backgroundColor: 'rgba(104,104,254,1)',
    },
  },
  '& span': {
    color: 'white',
  },

  '& .dropdownIcon': {
    color: 'white',
  },
}));
