import { createSvgIcon } from '@mui/material';

const ServiceIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.74993 6C5.74993 3.10051 8.10043 0.75 10.9999 0.75C11.6176 0.75 12.212 0.856999 12.7644 1.0541C13.0066 1.14051 13.1877 1.34497 13.2441 1.59583C13.3006 1.8467 13.2246 2.10899 13.0427 2.29082L11.3601 3.99133C11.1214 4.2325 11.0021 4.35308 10.9459 4.48669C10.867 4.67407 10.8676 4.88544 10.9475 5.07237C11.0045 5.20566 11.1245 5.32556 11.3645 5.56538C11.605 5.80564 11.7252 5.92577 11.8587 5.98265C12.046 6.06243 12.2577 6.06265 12.4452 5.98325C12.5788 5.92664 12.6993 5.80675 12.9402 5.56698L14.6683 3.84728C14.8476 3.66792 15.1055 3.59137 15.3536 3.64381C15.6018 3.69624 15.8066 3.87055 15.8981 4.10712C16.1256 4.69535 16.2499 5.33404 16.2499 6C16.2499 8.89949 13.8994 11.25 10.9999 11.25C10.5714 11.25 10.1539 11.1985 9.7536 11.101C9.5638 11.0548 9.44378 11.0257 9.35608 11.008C9.33985 11.0048 9.32658 11.0023 9.31594 11.0005C9.31314 11.0029 9.31007 11.0057 9.30673 11.0087C9.26013 11.0508 9.19898 11.1116 9.09112 11.2195L4.40526 15.9053C3.49104 16.8195 2.00881 16.8195 1.0946 15.9053C0.180382 14.9911 0.180382 13.5089 1.0946 12.5947L5.78046 7.90881C5.88832 7.80095 5.94914 7.7398 5.99123 7.6932C6.00295 7.68022 5.99628 7.6657 5.99188 7.64385C5.97425 7.55614 5.94517 7.43613 5.89893 7.24633C5.80143 6.84608 5.74993 6.42855 5.74993 6Z"
        fill="#0288D1"
      />
    </>,
    'ServiceIcon',
  );
  return (
    <IconCmp
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      {...props}
    />
  );
};
export default ServiceIcon;
