/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { Attachment } from 'stream-chat-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  CustomMessageRenderProps,
  GetPromiseApiRequestType,
  MessageUserProfileResponse,
} from 'models/index.m';
import { useMessagingContainer } from 'controller/useMessagingContainer';
import { useMessageActionHandler } from 'controller/useMessageActionHandler';
import BootstrapModelComponent from 'components/common/htmlTags/bootstrapModel';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import { getFullName } from 'utils/globalFunction';
import { useAddEmployeeDirectChat } from 'controller/useAddEmployeeDirectChat';
import selectors from 'store/selectors';
import UserAvatar from 'components/Avatar';
import DateTime from 'utils/DateTime';
import { getUserHotelTz } from 'store/selectors/auth';
import CustomButton from 'components/Button/CustomButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditIcon from 'components/Icons/editIcon';
import { Grid } from '@mui/material';
import { ReadContext } from './readReceiptContextProvider';
import CustomModal from 'components/CustomModal';
import { EditMessageModal } from './EditMessageModal.style';
import CloseIcon from '@mui/icons-material/Close';
import useDateTime from 'utils/CustomHooks/useDateTime';

export const CustomMessageRender: FC<CustomMessageRenderProps> = ({
  isGroupChat,
  message,
  readBy,
  handleSetChannel,
}: CustomMessageRenderProps) => {
  const dispatch = useDispatch();
  const { getUtcToTz } = useDateTime();
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);
  const tz = useSelector(getUserHotelTz);
  const { handleDeleteMessageSubmit, handleEditMessageSubmit } =
    useMessageActionHandler();
  const { getMszContent } = useMessagingContainer();
  const [showUserProfile, setShowUserProfile] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [userProfileData, setUserProfileData] =
    useState<MessageUserProfileResponse | null>(null);
  const [showEditMszData, setShowEditMszData] = useState<any>({
    setShow: false,
    content: '',
    messageId: '',
  });
  const currentUserId: any = useSelector(selectors.getUserId);

  const { handleAddEmployeeDirectChatSubmit } = useAddEmployeeDirectChat();

  useEffect(() => {
    if (showUserProfile && selectedUserId !== '') {
      new Promise((resolve, reject) => {
        const messagesUserProfileGetData: GetPromiseApiRequestType = {
          callbackResolve: resolve,
          callbackReject: reject,
          id: selectedUserId,
        };
        dispatch(
          actions.messagesUserProfileApiCall(messagesUserProfileGetData),
        );
      }).then((res: any) => {
        setUserProfileData({ ...res, name: getFullName(res) });
      });
    } else {
      setUserProfileData(null);
    }
  }, [showUserProfile, selectedUserId]);

  const handleClose = () => {
    setShowEditMszData({
      ...showEditMszData,
      setShow: false,
    });
  };

  const handleAvatarClick = () => {
    setShowUserProfile(true);
    setSelectedUserId(message?.user?.id);
  };

  const getMessageIsRead = (readby) => {
    if (readby?.length > 1) {
      return <span className="timebox">Read •</span>;
    }
    return null;
  };

  if (!activeStreamChannel) return null;

  const messageHtml = getMszContent(message);

  const isUserMessage = currentUserId === message.user.id;
  const viewerClass = isUserMessage ? 'sender' : 'receiver';
  const isEdited =
    new Date(message.created_at).valueOf() !==
    new Date(message.updated_at).valueOf();

  const actionDropdownOptions: any = [
    {
      label: 'Edit',
      id: 'edit',
      icon: <EditIcon />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
    },
    {
      label: 'Remove',
      id: 'remove',
      icon: <DeleteIcon sx={{ color: '#D32F2F' }} />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      sx: {
        color: '#D32F2F',
        borderTop: '#0000001F 1px solid',
      },
    },
  ];

  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'edit':
        setShowEditMszData({
          ...showEditMszData,
          setShow: true,
          content: message?.text,
          messageId: message?.id,
        });
        break;
      case 'remove':
        handleDeleteMessageSubmit(message?.id);
        break;
      default:
        return;
    }
  };

  return (
    <React.Fragment key={`message_${message.id}`}>
      <div
        id={`message_${message.id}`}
        className={`avatarWrapper msgAvatar ${viewerClass}`}
      >
        <div
          className="imgbox"
          data-toggle="modal"
          data-target="#profileCardModal"
        >
          {/* <AvatarPopover
              avatarProps={{ width: 32, height: 32 }}
              showAvatarOnly
              data={{
                id: user?.id,
                emailId: user?.emailId,
                firstName: user?.firstName,
                lastName: user?.lastName,
                photo: user?.image,
                phoneNo: 'asd',
                title: '',
                status: 'null',
                UserHotels: [],
              }}
            /> */}
          <UserAvatar
            width={24}
            height={24}
            userId={message.user?.id}
            profilePic={message.user?.profileImage}
            firstName={message.user?.name}
            onClick={handleAvatarClick}
          />
          {isUserMessage ? (
            <div className="actionBtnWrap">
              <ActionDropdown
                options={actionDropdownOptions}
                handleOptionSelect={handleOptionSelect}
                popperProps={{
                  placement: 'bottom-end',
                }}
                buttonProps={{
                  disableRipple: true,
                }}
              >
                <MoreVertOutlinedIcon style={{ fontSize: 18 }} />
              </ActionDropdown>
            </div>
          ) : null}
        </div>
        <div className={`namebox ${viewerClass}`}>
          {(!isUserMessage ? (
            <>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <div className="username">{message.user?.name}</div>
                <span>
                  <div className="timebox">
                    {getUtcToTz(message.updated_at, 'hh:mm a')}
                    <br />
                  </div>
                </span>
                <ReadContext.Consumer>
                  {(context) =>
                    readBy?.length > 0 && (
                      <InfoOutlinedIcon
                        className="info-icon"
                        sx={{ fontSize: 16 }}
                        onClick={() => {
                          context.setReaders(readBy);
                          context.setMessage(messageHtml);
                        }}
                      />
                    )
                  }
                </ReadContext.Consumer>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {!isGroupChat && getMessageIsRead(readBy)}
                <span>
                  <div className="timebox">
                    {getUtcToTz(message.updated_at, 'hh:mm a')}
                    <br />
                  </div>
                </span>
                {isGroupChat && readBy?.length > 0 && (
                  <ReadContext.Consumer>
                    {(context) => (
                      <InfoOutlinedIcon
                        className="infoIcon"
                        sx={{ fontSize: 16 }}
                        onClick={() => {
                          context.setReaders(readBy);
                          context.setMessage(messageHtml);
                        }}
                      />
                    )}
                  </ReadContext.Consumer>
                )}
                <div className="username">You</div>
              </Grid>
            </>
          )) || null}
          <p className={`userMessage ${viewerClass}`}>
            <div dangerouslySetInnerHTML={{ __html: messageHtml }} />
            {(message?.attachments?.length && (
              <div className="messageAttachments">
                {<Attachment attachments={message?.attachments} />}
              </div>
            )) ||
              null}
          </p>

          <p className="isEdited">{isEdited ? '(edited)' : ''}</p>
        </div>
      </div>

      <CustomModal open={showEditMszData.setShow} handleClose={handleClose}>
        <EditMessageModal>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid className="title" item>
              Edit Message
            </Grid>
            <Grid className="closeIcon" item>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <form className="addEditDepartmentForm">
            <textarea
              placeholder="Type new message"
              rows={5}
              onChange={(e) => {
                setShowEditMszData({
                  ...showEditMszData,
                  content: e.target.value,
                });
              }}
            >
              {showEditMszData.content}
            </textarea>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              mt="3"
            >
              <CustomButton
                variant={'text'}
                onClick={() => {
                  setShowEditMszData({
                    ...showEditMszData,
                    setShow: false,
                  });
                }}
                sx={{ mr: '10px' }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleEditMessageSubmit(showEditMszData);
                  setShowEditMszData({
                    ...showEditMszData,
                    setShow: false,
                    content: message?.text,
                    messageId: message?.id,
                  });
                }}
                variant={'contained'}
              >
                Update
              </CustomButton>
            </Grid>
          </form>
        </EditMessageModal>
      </CustomModal>
      <BootstrapModelComponent
        className="common-modal profile-card-modal"
        isShow={showUserProfile}
        handleClose={() => {
          setShowUserProfile(false);
        }}
        animation={false}
        arialabelledby="contained-modal-title-vcenter"
        centered={true}
        headerData={''}
        bodyData={
          <>
            <button
              type="button"
              className="close btn-close"
              onClick={() => {
                setShowUserProfile(false);
                setSelectedUserId('');
              }}
            >
              <span className="icon-close"></span>
            </button>
            {userProfileData ? (
              <>
                <div className="profile-imgbox">
                  <UserAvatar
                    width={498}
                    height={390}
                    userId={userProfileData?.id}
                    firstName={userProfileData?.name}
                    profilePic={userProfileData?.photo}
                    variant="rounded"
                  />
                  <div className="overlay">
                    <h3>{userProfileData.name}</h3>
                  </div>
                </div>
                <div className="profile-info">
                  <ul className="list">
                    <li>
                      <a
                        href={void 0}
                        onClick={async () => {
                          const currentClient =
                            await handleAddEmployeeDirectChatSubmit([
                              userProfileData,
                            ]);
                          handleSetChannel(currentClient);
                        }}
                        className={`link ${isUserMessage ? 'disabled' : ''}`}
                      >
                        <span className="icon-chat2"></span> Message
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:${userProfileData?.emailId} `}
                        className="link"
                      >
                        <span className="icon-mail"></span> Email
                      </a>
                    </li>
                  </ul>
                  <div className="info-item">
                    <label>Phone</label>
                    <span>{userProfileData?.phoneNo}</span>
                  </div>
                  <div className="info-item">
                    <label>Email</label>
                    <span>{userProfileData?.emailId}</span>
                  </div>
                  <div className="info-item">
                    <label>Position</label>
                    <span>{userProfileData?.title}</span>
                  </div>
                </div>
              </>
            ) : (
              <div className="mt-5 text-center mb-5">
                Loading....Please Wait
              </div>
            )}
          </>
        }
        showCloseBtn={false}
      />
    </React.Fragment>
  );
};
