import React, { createContext, useContext, useState } from 'react';

const OnboardingStateContext = createContext(null);

export function useOnboardState() {
  const state = useContext(OnboardingStateContext);

  if (!state) {
    throw new Error(
      'useOnboardState must be used within OnboardingStateProvider',
    );
  }

  return state;
}

export function OnboardingStateProvider(props) {
  const { children } = props;
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [emailInvite, setEmailInvite] = useState(false);
  const [phone, setPhone] = useState(null);
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneInvite, setPhoneInvite] = useState(false);
  const [password, setPassword] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [invalidOrganizationName, setInvalidOrganizationName] = useState('');
  const [teamSize, setTeamSize] = useState({});
  const [industry, setIndustry] = useState('');
  const [goal, setGoal] = useState([]);
  const [hotelId, setHotelId] = useState('');
  const [isAdmin, setIsAdmin] = useState('');
  const [status, setStatus] = useState('');
  const [title, setTitle] = useState('');
  const [inviteStatus, setInviteStatus] = useState(false);
  const [inviteToken, setInviteToken] = useState(null);
  const [checkTerm, setCheckTerm] = useState(false);
  const [usecaseDetail, setUsecaseDetail] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  const providerValue = {
    fullName,
    email,
    phone,
    password,
    organizationName,
    invalidOrganizationName,
    teamSize,
    phoneCode,
    industry,
    goal,
    hotelId,
    isAdmin,
    status,
    title,
    emailInvite,
    phoneInvite,
    inviteStatus,
    inviteToken,
    checkTerm,
    usecaseDetail,
    jobTitle,
    setJobTitle,
    setUsecaseDetail,
    setFullName,
    setEmail,
    setPhone,
    setPassword,
    setOrganizationName,
    setInvalidOrganizationName,
    setTeamSize,
    setIndustry,
    setGoal,
    setHotelId,
    setIsAdmin,
    setStatus,
    setTitle,
    setEmailInvite,
    setPhoneInvite,
    setPhoneCode,
    setInviteStatus,
    setInviteToken,
    setCheckTerm,
  };
  return (
    <OnboardingStateContext.Provider value={providerValue}>
      {children}
    </OnboardingStateContext.Provider>
  );
}
