// React
import { Fragment } from 'react';

import CustomButton from 'components/Button/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';

// MUI
import { Fab } from '@mui/material';

// Hooks
import useResponsive from 'utils/CustomHooks/useResponsive';
import { weeklyInitState } from 'components/AllDialogs/tasks/createTask/RepeatTaskView.cmp';

interface AddScheduleBtnPropTypes {
  createTaskCallback?: () => void;
}
function AddScheduleBtn({ createTaskCallback }: AddScheduleBtnPropTypes) {
  const { isMobileDeviceMd } = useResponsive();
  const dispatch = useDispatch();

  const handleAddSchedule = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          onSuccessCallBack: createTaskCallback,
          taskData: {
            ...weeklyInitState,
          },
          dialogState: {
            viewLess: false,
            showRepeatTask: true,
          },
        },
      }),
    );
  };

  return (
    <Fragment>
      {isMobileDeviceMd ? (
        <Fab
          onClick={handleAddSchedule}
          sx={{
            position: 'fixed',
            bottom: '24px',
            right: '24px',
          }}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      ) : (
        <CustomButton
          variant={'contained'}
          startIcon={<AddIcon />}
          onClick={handleAddSchedule}
          sx={{
            transform: 'translateY(-63px)',
          }}
          style={{
            padding: '7px 16px',
            borderRadius: '8px',
            fontWeight: 600,
          }}
        >
          Add Schedule
        </CustomButton>
      )}
    </Fragment>
  );
}
export default AddScheduleBtn;
