import CustomButton from 'components/Button/CustomButton';
import { MainCnt } from './style';

import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import { Paper } from '@mui/material';
import CTypography from 'components/CTypography';

import iconRestrictedDocument from 'public/assets/img/iconRestrictedDocument.svg';

const RestrictedView = () => {
  const goToHome = () => {
    window.location.href = '/';
  };
  return (
    <MainCnt>
      <Paper className="contentCnt">
        <img
          src={XeniaLogo}
          width={110}
          height={22}
          style={{ marginBottom: 50 }}
        />
        <div className="innerCnt">
          <img src={iconRestrictedDocument} />
          <CTypography className="title">
            Hmm...looks like this document is restricted
          </CTypography>
          <CTypography className="statement">
            To access it, you’ll require permissions. Please contact your
            administrator for assistance.
          </CTypography>

          <CustomButton
            variant="contained"
            className="goToHome"
            sx={{ padding: '2px 16px' }}
            onClick={goToHome}
          >
            Go to Xenia Home
          </CustomButton>
        </div>
      </Paper>
    </MainCnt>
  );
};

export default RestrictedView;
