import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CustomButton from 'components/Button/CustomButton';
import EditIcon from 'components/Icons/editIcon';
import DeleteIcon from 'components/Icons/deleteIcon';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { getHashedUserList } from 'store/selectors/users';
import { getRoles } from 'store/selectors/roles';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  ActionsCnt,
  BackBox,
  MainCnt,
  SummaryBox,
  SummaryTitle,
  SummaryValue,
} from './style';
import { PERMISSIONS } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { getUserProfile } from 'store/selectors/auth';

function RoleDetailsHeader(props) {
  const { details, getCustomRoleDetails } = props;
  const { hasPermission, hasFeature, FEATURES } = useCustomPermission();
  const hashedUsers = useSelector(getHashedUserList);
  const { fetchCustomRoles } = useCustomRoles();
  const userProfileData: any = useSelector(getUserProfile);
  const { workspaceId } = useWorkspaceHook();
  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();

  const onEditHandler = () => {
    getCustomRoleDetails();
  };

  const PermissionsChangedTemplate = () => {
    return (
      <>
        <Typography fontSize="16px" fontWeight="500">
          Seems like your role / permissions has been updated, Please click on
          reload to apply it accordingly!
        </Typography>
      </>
    );
  };

  const onDeleteHandler = (data) => {
    const usersIds = data?.HotelUsers?.map((item) => item?.UserId);
    const isLoggedInUser = usersIds?.includes(userProfileData?.id);

    if (isLoggedInUser) {
      dispatch(
        setDialog({
          dialogId: DIALOGS.CONFIRMATION,
          open: true,
          data: {
            confirmButtonProps: {
              style: {
                backgroundColor: '#D32F2F',
              },
            },
            hideCrossIcon: true,
            removeClickAwayClose: true,
            hideCancelBtn: true,
            ContentTemplate: PermissionsChangedTemplate,
            confirmationText: 'Reload',
            title: 'Your Role Changed',
            onConfirmCallback: () => {
              window.location.href = `/ws/${workspaceId}/users-and-teams?tab=roles`;
            },
          },
        }),
      );
    } else {
      fetchCustomRoles({ payload: {}, forceLoad: true });
      navigateWithWorkspaceUrl(`/users-and-teams?tab=roles`);
    }
  };

  const editDetailsHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CUSTOM_ROLES_SET_DETAILS,
        data: {
          role: details,
          isEdit: true,
          onSuccessCB: onEditHandler,
        },
      }),
    );
  };

  useEffect(() => {
    fetchCustomRoles({});
  }, []);

  const goBackHandler = () => {
    navigateWithWorkspaceUrl(`/users-and-teams?tab=roles`);
  };

  const createdBy = useMemo(() => {
    const user = hashedUsers?.[details?.CreatedBy];
    const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
    return fullName;
  }, [hashedUsers, details]);

  const reportToRole = useMemo(() => {
    return rolesList?.find((role) => role?.id === details?.ReportTo);
  }, [rolesList, details]);

  const createdOn = useMemo(() => {
    return moment(details?.createdAt).format('MMM DD, YYYY');
  }, [details]);

  const actionsDropdownList = useMemo(
    () => [
      {
        label: 'Delete',
        id: 'delete',
        icon: <DeleteIcon sx={{ color: '#D32F2F' }} />,
        iconAlign: 'start',
        style: {
          padding: '6px 16px',
          color: '#D32F2F',
        },
      },
    ],
    [],
  );

  const handleOptionSelect = useCallback(
    (option) => {
      switch (option.id) {
        case 'delete':
          {
            dispatch(
              setDialog({
                open: true,
                dialogId: DIALOGS.CUSTOM_ROLES_DELETE_ROLE,
                data: {
                  role: details,
                  onSuccessCB: () => onDeleteHandler(details),
                },
              }),
            );
          }
          break;

        default:
          return;
      }
    },
    [details],
  );

  return (
    <MainCnt>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <BackBox direction="row" alignItems="center" gap="5px">
          <ArrowBackIcon className="icon" onClick={goBackHandler} />
          <Typography className="roleName">{details?.title}</Typography>
        </BackBox>
        <Stack direction="row" alignItems="center" gap="5px">
          {details?.editable &&
            hasPermission(PERMISSIONS.CAN_MANAGE_ROLES) &&
            hasFeature(FEATURES.CUSTOM_ROLES) && (
              <>
                <CustomButton
                  className="editPermissionsBtn"
                  variant="contained"
                  onClick={editDetailsHandler}
                  startIcon={
                    <EditIcon
                      sx={{
                        '& path': {
                          stroke: 'white',
                          opacity: 0.8,
                        },
                      }}
                    />
                  }
                >
                  Edit Details
                </CustomButton>
                <ActionDropdown
                  popperProps={{
                    style: {
                      width: 168,
                      zIndex: 13000,
                    },
                    placement: 'bottom-end',
                  }}
                  options={actionsDropdownList}
                  handleOptionSelect={handleOptionSelect}
                  listProps={{
                    sx: {
                      padding: '0px',
                    },
                  }}
                  buttonProps={{
                    sx: {
                      '&:hover': {
                        backgroundColor: 'unset',
                      },
                    },
                  }}
                >
                  <ActionsCnt>
                    <MoreHorizIcon />
                  </ActionsCnt>
                </ActionDropdown>{' '}
              </>
            )}
        </Stack>
      </Stack>

      <SummaryBox container>
        <Grid
          item
          xs={12}
          sx={{
            borderBottom: '1px solid #E0E0E0',
            padding: '5px 15px 15px 15px',
          }}
        >
          <Stack direction="column">
            <Typography
              fontWeight="700"
              fontSize="13px"
              lineHeight="17.76px"
              color="#212121"
            >
              Description
            </Typography>
            <Typography
              fontWeight="500"
              fontSize="13px"
              lineHeight="17.76px"
              color="#000000"
            >
              <LinesEllipsis
                className="description"
                text={details?.description || '-'}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="column" className="content">
            <SummaryTitle>Created On</SummaryTitle>
            <SummaryValue>{createdOn || '-'}</SummaryValue>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="column" className="content">
            <SummaryTitle>Created By</SummaryTitle>
            <SummaryValue>{createdBy || '-'}</SummaryValue>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="column" className="content">
            <SummaryTitle>Report To</SummaryTitle>
            <SummaryValue>{reportToRole?.title || '-'}</SummaryValue>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack direction="column" className="content">
            <SummaryTitle>Permissions</SummaryTitle>
            <SummaryValue>{details?.RolePermissions?.length || 0}</SummaryValue>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction="column"
            className="content"
            style={{ borderRight: 'unset' }}
          >
            <SummaryTitle>Assigned Users</SummaryTitle>
            <SummaryValue>{details?.HotelUsers?.length || 0}</SummaryValue>
          </Stack>
        </Grid>
      </SummaryBox>
    </MainCnt>
  );
}

export default RoleDetailsHeader;
