import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAssetsState } from 'store/selectors/assets';
import useLocationDetail from 'pages/Locations/LocationDetail/useLocationDetail';
import { AssetTile } from 'pages/Locations/LocationDetail/LocationAssets/LocationAssets.style';
import OpenBookIcon from 'components/Icons/openBookIcon';
import CustomButton from 'components/Button/CustomButton';
import React, { useMemo } from 'react';
import { appRoutes } from 'utils/globalVariable';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import selectors from 'store/selectors';
import { getAssetById } from 'utils/globalFunction';
import useResponsive from 'utils/CustomHooks/useResponsive';
import EmptyState from './EmptyState';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { getLocationsState } from 'store/selectors/locations';

function LocationAssets() {
  const { selectedLocation, getLocationAssetsIncludingSub } =
    useLocationDetail();
  const { getAllSubLocationsIdsById } = useLocationsUtils();
  const locationsList = useSelector(getLocationsState);
  const { isMobileDeviceSm } = useResponsive();

  const assetsList = useSelector(getAssetsState);
  const topAlertState = useSelector(selectors.getTopAlertState);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const navigateToAssetDetail = (asset) => {
    const urlToNavigate = asset?.ParentId
      ? `/${asset?.ParentId}/sa/${asset?.id}`
      : `/${asset?.id}`;
    navigateWithWorkspaceUrl(appRoutes.assets + urlToNavigate);
  };

  const assets = useMemo(() => {
    let assetsList: any = [];
    if (selectedLocation?.id) {
      const locationsIds = getAllSubLocationsIdsById(
        selectedLocation?.id,
        locationsList,
      );

      for (let index = 0; index < locationsIds?.length; index++) {
        const asset = getLocationAssetsIncludingSub(locationsIds?.[index]);
        assetsList = [...assetsList, ...(asset || [])];
      }
    }

    return assetsList;
  }, [selectedLocation?.id]);

  return (
    <div
      style={{
        height: isMobileDeviceSm
          ? 400
          : topAlertState?.show
          ? 'calc(100vh - 295px)'
          : 'calc(100vh - 223px)',
        marginBottom: 16,
      }}
    >
      <Grid container spacing={2}>
        {assets?.map((a) => {
          const parentAsset = getAssetById(a?.ParentId, assetsList);
          const parentAssetName = parentAsset?.name;
          return (
            <Grid item xs={12} md={4} lg={3}>
              <AssetTile>
                <div className={'assetTitleLeftCnt'}>
                  <span className={'assetTitle'}>{a?.name}</span>
                  {parentAssetName ? (
                    <span className={'parentAssetName'}>
                      Parent: {parentAssetName}
                    </span>
                  ) : null}
                </div>
                <CustomButton
                  startIcon={<OpenBookIcon sx={{ color: '#757575' }} />}
                  onClick={() => navigateToAssetDetail(a)}
                  variant={'outlined'}
                  buttonType={'grayWhite'}
                  sx={{ borderRadius: '30px', padding: '3px 13px' }}
                >
                  Open
                </CustomButton>
              </AssetTile>
            </Grid>
          );
        })}
      </Grid>
      {assets && assets.length === 0 && <EmptyState />}
    </div>
  );
}

export default LocationAssets;
