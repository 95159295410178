import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Form } from 'react-bootstrap';
import { default as Select, components } from 'react-select';

import { useQueryUsersList } from 'controller/useQueryUsersList';
import { useAddGroupChannel } from 'controller/useAddGroupChannel';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import {
  Chat,
  Channel,
  MessageInput,
  useMessageContext,
  VirtualizedMessageList,
} from 'stream-chat-react';
import { CustomMessageRender } from './customMessage';
import UserAvatar from 'components/Avatar';

import selectors from 'store/selectors';

import {
  streamChatType,
  ConstantChannelTypeString,
} from 'utils/globalVariable';

import {
  getOrCreateDummyChannel,
  streamClient,
} from 'services/getStreamServices';

import { find, uniq } from 'lodash';
import CustomButton from 'components/Button/CustomButton';
import { CustomMessageInput } from './customMessageInput';

import { MessagesChannelInnerStyled } from './messagesChannelInner.styled';
import { CustomDateSeparator } from './CustomDateSeparator';

import '@stream-io/stream-chat-css/dist/css/index.css';

const Option = (props) => {
  const checkboxIcon =
    (props.isSelected && 'checkbox-orange-checked.png') ||
    'checkbox-unchecked.png';

  return (
    <components.Option {...props}>
      <div className="react-select-add-to-group-option align-items-center">
        <div className="react-select-add-to-group-option-checkbox">
          <img height={16} width={16} src={`/assets/img/${checkboxIcon}`} />
        </div>
        <div className="react-select-add-to-group-option-img align-items-center">
          <UserAvatar width={20} height={20} userId={props.data.value} />
        </div>
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
};

export const CreateMessageGroup: FC<any> = ({
  channelList,
  setShowAddGroup,
  handleSetChannel,
}: any) => {
  const currentUserId = useSelector(selectors.getUserId);
  const { openWSChannel, workspaceId } = useWorkspaceHook();
  const hotelId = workspaceId;
  // eslint-disable-next-line prefer-const
  const [focusedOnce, setFocusedOnce] = useState(false);

  const { queryUsersObj }: any = useQueryUsersList(true);
  const [dummyChannel, setDummyChannel]: any = useState(null);
  const [defaultChannel, setDefaultChannel]: any = useState(null);
  const [groupName, setGroupName] = useState('');
  const [shouldNameGroup, setShouldNameGroup] = useState(false);
  const [selectedUsers, setSelectedUsers]: any = useState([]);
  const defaultErrorObj = {
    groupNameError: '',
    selectedMembersError: '',
  };
  const [addGroupChannelErrorObj, setAddGroupChannelErrorObj] =
    useState(defaultErrorObj);

  const { handleAddGroupChannelSubmit } = useAddGroupChannel();
  const { GROUP, DIRECT_MESSAGE, FEED_STREAM } = ConstantChannelTypeString;

  const { users } = queryUsersObj;
  const userList = () => {
    if (!users || !users.length) return [];
    return users.map((user) => {
      return {
        value: user.id,
        label: user.name,
        profileImage: user.profileImage,
      };
    });
  };

  const sendMessage = async ({ message, channel }) => {
    if (message) {
      try {
        const { text, attachments } = message;
        const data = {
          text,
          attachments,
        };
        await channel.sendMessage(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const submitAddGroup = async (message: any = null) => {
    const error = defaultErrorObj;
    if (shouldNameGroup && groupName === '') {
      error.groupNameError = `Please enter name`;
    } else {
      error.groupNameError = '';
    }
    if (selectedUsers.length === 0) {
      error.selectedMembersError = 'Please select at lease 1 member';
    } else {
      error.selectedMembersError = '';
    }
    setAddGroupChannelErrorObj(error);

    if (error.groupNameError === '' && error.selectedMembersError === '') {
      const selectedMembers = selectedUsers.map(({ value }) => value);
      const name = (shouldNameGroup && groupName) || '';
      let channel;
      let { channelType } = defaultChannel.data;
      channelType = Array.isArray(channelType) ? channelType[0] : channelType;
      if (name === '' && channelType !== streamChatType.DUMMY) {
        channel = defaultChannel;
      } else {
        channel = await handleAddGroupChannelSubmit(
          selectedMembers,
          name,
          null,
          true,
        );
      }

      setAddGroupChannelErrorObj(defaultErrorObj);
      if (channel) {
        if (message) {
          await sendMessage({ channel, message });
        }
        openWSChannel({ channel });
        setShowAddGroup(false);
        setFocusedOnce(false);
      }
    }
  };

  const overrideSubmitHandler = async (message) => {
    await submitAddGroup(message);
  };

  const focusSelect = (ref) => {
    if (!focusedOnce && ref && ref.select && ref.select.focus) {
      ref.select.focus();
      setFocusedOnce(true);
    }
  };

  useEffect(() => {
    if (!defaultChannel) {
      getOrCreateDummyChannel({ userId: currentUserId, hotelId }).then((ch) => {
        setDummyChannel(ch);
        setDefaultChannel(ch);
        return null;
      });
    }
  }, []);

  useEffect(() => {
    if (shouldNameGroup && selectedUsers.length > 1) {
      setDefaultChannel(dummyChannel);
      return;
    }
    let selectedUserIds: any = selectedUsers.map(({ value }) => value);
    selectedUserIds.push(currentUserId);
    selectedUserIds = uniq(selectedUserIds);
    selectedUserIds = selectedUserIds.sort().join(',');
    const matchedChannel = find(channelList, (ch) => {
      const { channelType, name } = ch.data;
      if (name && name.trim() !== '' && channelType !== DIRECT_MESSAGE)
        return false;
      if (![GROUP, DIRECT_MESSAGE].includes(channelType)) return false;
      const { members } = ch.state;
      let memberIds: any = Object.keys(members);
      memberIds = uniq(memberIds);
      memberIds = memberIds.sort().join(',');
      return selectedUserIds === memberIds;
    });
    const channel = matchedChannel || dummyChannel;
    setDefaultChannel(channel);
  }, [selectedUsers, shouldNameGroup]);

  const CustomMessage = () => {
    const { message, readBy } = useMessageContext();
    return (
      <CustomMessageRender
        isGroupChat={
          defaultChannel.data.channelType === GROUP ||
          defaultChannel.data.channelType === FEED_STREAM
        }
        message={message}
        readBy={readBy}
        handleSetChannel={handleSetChannel}
      />
    );
  };

  return (
    <Form className="create-group-form">
      <div
        className="form-header compose-group"
        style={{ position: 'relative' }}
      >
        <div>
          <div className="form-head-section">
            <div className="list-title">
              <h6>New Message</h6>
            </div>
            <Form.Group>
              <div className="candidate-group-members">
                <div className="candidate-group-members-to-label">To:</div>
                <div>
                  <Select
                    ref={(ref) => {
                      focusSelect(ref);
                    }}
                    className="select-wrap"
                    placeholder={'Select Members'}
                    classNamePrefix="select-box"
                    options={userList()}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected) => {
                      setSelectedUsers(selected);
                    }}
                    components={{ Option }}
                    value={selectedUsers}
                    openMenuOnFocus={true}
                  />
                </div>
              </div>
            </Form.Group>
          </div>

          {selectedUsers.length > 1 ? (
            <div className="create-group-naming">
              <div className="row">
                <div className="create-group-naming-label">
                  Name this group?
                </div>
                <div className="create-group-naming-check">
                  <Form.Check
                    type="switch"
                    onChange={({ target }) => {
                      setShouldNameGroup(target.checked);
                    }}
                    checked={shouldNameGroup}
                    id="create-group-naming-switch"
                    label=""
                  />
                </div>
              </div>

              {shouldNameGroup ? (
                <div className="row">
                  <div className="group-name-input-div">
                    <Form.Control
                      type="text"
                      value={groupName}
                      placeholder={'Group Name'}
                      onChange={(e) => {
                        setGroupName(e.target.value);
                      }}
                    />
                    <p className="error">
                      {addGroupChannelErrorObj?.groupNameError}
                    </p>
                  </div>
                  <CustomButton
                    variant={'contained'}
                    onClick={() => {
                      submitAddGroup();
                    }}
                  >
                    Create
                  </CustomButton>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        {(dummyChannel && (
          <Chat client={streamClient}>
            <Channel
              channel={defaultChannel}
              DateSeparator={CustomDateSeparator}
              Input={CustomMessageInput}
            >
              <MessagesChannelInnerStyled>
                <div className="msgWrap">
                  <div id="message-list" className="msgOuter w-100">
                    <VirtualizedMessageList
                      messageLimit={30}
                      disableDateSeparator={false}
                      Message={CustomMessage}
                      additionalVirtuosoProps={{
                        className: 'messagesSection',
                        alignToBottom: true,
                        followOutput: true,
                        initialTopMostItemIndex: 0,
                        topItemCount: 0,
                      }}
                    />
                  </div>
                </div>
                <MessageInput
                  overrideSubmitHandler={overrideSubmitHandler}
                  mentionAllAppUsers={true}
                  additionalTextareaProps={{
                    placeholder: 'Start Typing',
                  }}
                />
              </MessagesChannelInnerStyled>
            </Channel>
          </Chat>
        )) ||
          null}
      </div>
    </Form>
  );
};
