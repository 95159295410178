// React Router
import { useSearchParams } from 'react-router-dom';

// Custom components
import Tooltip from 'components/Tooltip';

// Icons
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

// Styled
import { Column, NameAndBack, BackButton, TemplateNameText } from './styled';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Context
import { TemplateType } from 'pages/TemplateDashboard/context';

const PageHeader = ({ template }: { template: TemplateType }) => {
  const [query] = useSearchParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const handleClickBack = () => {
    const prev = query.get('prev');
    navigateWithWorkspaceUrl(prev ?? `/templates/${template.id}?tab=2`);
  };

  return (
    <Column>
      <NameAndBack>
        <BackButton
          onClick={handleClickBack}
          startIcon={<ArrowBackRoundedIcon />}
        >
          Back
        </BackButton>
        <Tooltip title={template?.name}>
          <TemplateNameText>{template?.name}</TemplateNameText>
        </Tooltip>
      </NameAndBack>
    </Column>
  );
};

export default PageHeader;
