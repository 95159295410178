import React, { ReactNode } from 'react';
import { useDashboard } from 'pages/Dashboards/hooks/useDashboard';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { IDashboardAccessor } from 'pages/Dashboards/interfaces';
interface IWithDashboardPermissionProps {
  children: ReactNode;
}
const WithDashboardPermission: React.FC<IWithDashboardPermissionProps> = ({
  children,
}) => {
  const { selectedDashboard } = useDashboard();
  const userAuth = useSelector(selectors.getLoggedInUser);
  const currentUserPermission: IDashboardAccessor | undefined =
    selectedDashboard?.DashboardAccess?.find(
      (u: IDashboardAccessor) => u.AccessorId === userAuth?.id,
    );
  const isRoleEditor = currentUserPermission?.role === 'editor';
  const isNoPermissionSet = selectedDashboard?.DashboardAccess?.length;

  if (!isNoPermissionSet) {
    return <>{children}</>;
  }
  if (!isRoleEditor) {
    return null;
  }
  return <>{children}</>;
};

export default WithDashboardPermission;
