import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import React, { useMemo, useState } from 'react';
import CustomListItem from 'components/List/ListItem/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddOutlined';
import {
  SearchInputCnt,
  StyledList,
  StyledSearchField,
  AssigneeDropdownContentCnt,
  UserStatusChip,
  AddIconButton,
  DropdownBtnCnt,
} from 'components/Dropdown/UsersDropdown/userDropdown.style';
import DoneIcon from '@mui/icons-material/Done';

import { generateAssigneeDropdownData } from '../../../helper/task/dropdownData';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { userStatusColors } from '../../../helper/user/userStatus';
import { find } from 'lodash';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomAvatarGroup from 'components/Avatar/AvatarGroup/AvatarGroup.cmp';
import useUsers from 'utils/CustomHooks/useUsers';
function UsersDropdownCmp(props) {
  const {
    selectedOptions,
    listProps = {},
    handleChangeCallback,
    members,
  } = props;
  const [selected, setSelected] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userList = useSelector(selectors.getUserAdmin);
  const { taskAssignees } = useUsers();

  const teams = useSelector(selectors.getTeams);
  //Toggle Check/Uncheck of options list for single select dropdown
  const handleSingleSelect = (event, value) => {
    event.stopPropagation;
    //If option has a child just expand the list
    const isAlreadyExist = value?.id == selected?.id;
    //Single Select
    if (selected && isAlreadyExist) {
      // handleChangeCallback();
      setSelected(null);
      handleChangeCallback(value);
    } else {
      handleChangeCallback(value);
      setSelected(value);
    }
  };

  //Generating Members data for dropdown
  const membersDropdownData = useMemo(() => {
    return generateAssigneeDropdownData(taskAssignees())?.filter(
      (o) => o.id != 'unassigned',
    );
  }, [userList]);

  //handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  //Filtering options based on serach query
  const filteredOptions = () => {
    const optionsAfterSort = membersDropdownData?.sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    if (!searchQuery) {
      return optionsAfterSort;
    }
    const optionsList = optionsAfterSort.reduce((r, cv) => {
      r.push(cv);
      return r;
    }, []);
    const optionsAfterFilter = optionsList.filter((obj) => {
      return obj.label.toLowerCase().includes(searchQuery.toLowerCase());
    });
    return optionsAfterFilter;
  };
  const StatusChip = ({ user }) => {
    const availabilityStatus = getAvailabilityStatus(
      user.UserAvailabilityStatuses,
      user.HotelId,
    );
    return (
      <UserStatusChip
        style={{
          background:
            userStatusColors?.[availabilityStatus?.currentStatus]?.background,
        }}
      >
        {availabilityStatus?.currentStatus}
      </UserStatusChip>
    );
  };
  const getAvailabilityStatus = (
    UserAvailabilityStatuses = [],
    hotelId = null,
  ) => {
    let availabilityStatus: any = find(UserAvailabilityStatuses, {
      HotelId: hotelId,
    });
    availabilityStatus = availabilityStatus || { currentStatus: 'offline' };
    return availabilityStatus;
  };
  //Open popper on button click
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const ListItems = () => {
    return (
      <AssigneeDropdownContentCnt>
        <SearchInputCnt>
          <StyledSearchField
            id="SearchInput"
            autoFocus={true}
            startAdornment={<SearchIcon />}
            onChange={handleSearch}
            placeholder={'Type to search assignee'}
            value={searchQuery}
            label={''}
            fullWidth={true}
            autoComplete="off"
          />
        </SearchInputCnt>
        <StyledList
          sx={{ width: '100%' }}
          dense={true}
          disablePadding
          {...listProps}
        >
          {filteredOptions().map((option, i) => {
            const { endIcon, startIcon, isTeam } = option;
            const endIconRenderer: any =
              option.isTeam && endIcon ? endIcon : <StatusChip user={option} />;
            return (
              <>
                <CustomListItem
                  checkbox={false}
                  key={option.id}
                  id={option.id}
                  selected={selected?.id == option?.id}
                  onClick={(event) => handleSingleSelect(event, option)}
                  startIcon={startIcon}
                  endIcon={members?.includes(option.id) ? <DoneIcon /> : null}
                >
                  {option.label}
                </CustomListItem>
              </>
            );
          })}
        </StyledList>
      </AssigneeDropdownContentCnt>
    );
  };

  return (
    <ClickAwayListener
      onClickAway={handleCloseDropdown}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <DropdownBtnCnt>
        <CustomAvatarGroup members={members} />
        <AddIconButton onClick={handleClick}>
          <AddIcon />
        </AddIconButton>
        <CustomDropdown
          popperProps={{
            content: <ListItems />,
            disablePortal: false,
            onClick: (e) => e.stopPropagation(),
            open: open,
            anchorEl: anchorEl,
            placement: 'bottom-start',
            style: {
              zIndex: 1204,
              width: 280,
            },
          }}
        />
      </DropdownBtnCnt>
    </ClickAwayListener>
  );
}

export default UsersDropdownCmp;
