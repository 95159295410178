import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { Stack } from '@mui/material';

export const Container = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'center',
}));

export const RemoveButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isdisabled',
})(({ isdisabled }) => ({
  fontWeight: 500,
  color: isdisabled ? 'rgba(0,0,0,0.26)' : 'rgba(211, 47, 47, 1)',
  '& .download-icon': {
    fontSize: 18,
    '& path': {
      stroke: isdisabled ? 'rgba(0,0,0,0.26)' : 'rgba(211, 47, 47, 1)',
      strokeOpacity: 1,
      fillOpacity: 1,
    },
  },
}));
