// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100vh',
}));

export const NotificationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 49px)',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '25px 0px',
}));
