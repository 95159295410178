import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import CustomButton from 'components/Button/CustomButton';

import { navigateToStore, validateEmail } from 'utils/globalFunction';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { useReactRouter } from 'controller/useReactRouter';
import xeniaApi from 'api/index';
import XeniaLogoSymbol from 'public/assets/img/xenia-logo-symbol.svg';
import { getPublicChecklist, getTemplatesGroup } from 'api/checklistV2Api';
import OnboardingPreview from 'components/TemplateLibrary/TemplatePreview/Onboarding/OnboardingPreview';
import OnboardingTemplateGroupPreview from 'components/TemplateLibrary/TemplatePreview/Onboarding/OnboardingTemplateGroupPreview';
import AuthScreenInfo from 'components/BuilderUI/AuthScreen';
import useResponsive from 'utils/CustomHooks/useResponsive';

import {
  EmailTitle,
  FormCnt,
  GetStartedBtn,
  InfoCnt,
  LoginCnt,
  SubTitle,
  TermsAndConditions,
  Title,
} from './style';

function SignUpWithEmail() {
  const { isMobileDeviceMd } = useResponsive();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState(null);
  const [templateGroup, setTemplateGroup] = useState(null);
  const { handleRedirectToRoute, getSearchParamKey } = useReactRouter();

  const getTemplate = async () => {
    const templateId = getSearchParamKey('template-id');
    if (templateId) {
      (async () => {
        try {
          const res = await getPublicChecklist(templateId);
          if (res?.data) {
            setTemplate(res?.data);
          }
        } catch (e) {
          console.log('error:', e);
        }
      })();
    }
  };

  const getTemplateGroup = async () => {
    const templateGroupId = getSearchParamKey('template-group-id');
    if (templateGroupId) {
      (async () => {
        try {
          const res = await getTemplatesGroup(templateGroupId);
          if (res?.data) {
            setTemplateGroup(res?.data);
          }
        } catch (e) {
          console.log('error:', e);
        }
      })();
    }
  };

  useEffect(() => {
    getTemplate();
    getTemplateGroup();
  }, []);

  //Handle Email Input Change
  const handleEmailChange = (e) => {
    const value = e.target.value?.toLowerCase();
    setEmailError('');
    setEmail(value);
  };

  const navigateToOnaboarding = () => {
    handleRedirectToRoute(`/on-boarding?email=${email}`);
  };
  //Validate Email Input
  const validateEmailInput = () => {
    if (!email) {
      setEmailError(VALIDATION_ERROR_MESSAGES.REQUIRED_EMAIL_ID);
      return false;
    }
    if (!validateEmail(email)) {
      {
        setEmailError(VALIDATION_ERROR_MESSAGES.REQUIRED_VALID_EMAIL_ID);
        return false;
      }
    }
    return true;
  };
  //Entering email and click on next to show password screen
  const handleSignup = async () => {
    const isEmailValid = validateEmailInput();
    if (isEmailValid) {
      setIsLoading(true);
      const resp = await xeniaApi.emailOrPhoneAuth(
        'hotel/credentials/claim-test',
        { credential: email },
      );
      setIsLoading(false);
      if (resp.user) {
        navigateToOnaboarding();
      } else {
        setEmailError(resp.message);
      }
    }
  };

  const navigateToLogin = () => {
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          action: 'navigate',
          data: 'LoginWithEmail',
        }),
      );
      return;
    }

    if (!navigateToStore()) {
      const templateId = getSearchParamKey('template-id');
      const templateGroupId = getSearchParamKey('template-group-id');
      if (templateId) {
        handleRedirectToRoute(`/sign-in?template-id=${templateId}`);
      } else if (templateGroupId) {
        handleRedirectToRoute(`/sign-in?template-group-id=${templateGroupId}`);
      } else {
        handleRedirectToRoute('/sign-in');
      }
    }
  };

  //When user press enter key
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode == 13) {
      handleSignup();
    }
  };
  return (
    <Grid container style={{ height: '100vh' }}>
      {!isMobileDeviceMd && (
        <Grid item xs={12} sm={6}>
          <InfoCnt>
            <AuthScreenInfo />
          </InfoCnt>
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <FormCnt justifyContent={'center'}>
          <img src={XeniaLogoSymbol} className="logo" />
          <Title>Create your free Xenia account</Title>
          <SubTitle>
            Start your 14 Day Free-Trial today! No credit card required.
          </SubTitle>

          {template && (
            <OnboardingPreview
              template={template}
              wrapper={{
                style: {
                  margin: '0px 0px 10px 0px',
                  maxWidth: 'unset',
                },
              }}
            />
          )}
          {templateGroup && (
            <OnboardingTemplateGroupPreview
              templateGroup={templateGroup}
              wrapper={{
                style: {
                  margin: '0px 0px 10px 0px',
                  maxWidth: 'unset',
                },
              }}
            />
          )}
          <EmailTitle>Work Email</EmailTitle>
          <CustomInput
            error={emailError}
            muiLabel={true}
            fieldProps={{
              onKeyDown: handleKeyDown,
              onChange: handleEmailChange,
              placeholder: 'Enter your work email',
              autoComplete: 'off',
              name: 'email',
              type: 'email',
              value: email,
              style: {
                borderRadius: 8,
                height: 47,
              },
            }}
          />

          <GetStartedBtn
            variant="contained"
            buttonLoader={isLoading}
            onClick={handleSignup}
          >
            Get started
          </GetStartedBtn>

          <LoginCnt>
            <Typography className="alreadyLoggedIn">
              Already have an account?
            </Typography>
            <CustomButton
              variant={'text'}
              className="link"
              onClick={navigateToLogin}
              disableRipple
            >
              Log In
            </CustomButton>
          </LoginCnt>

          <TermsAndConditions>
            <Typography className="content">
              By clicking “Get Started” or signing up, you agree to Xenia's{' '}
              <a
                className="link"
                href={'https://www.xenia.team/terms-of-service'}
                target="_blank"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                className="link"
                href="https://www.xenia.team/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
              . You also agree to receive updates and offers from Xenia via
              email and phone.
            </Typography>
          </TermsAndConditions>
        </FormCnt>
      </Grid>
    </Grid>
  );
}
export default SignUpWithEmail;
