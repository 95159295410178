//Create New DashboardTemplatesHeader functional component with a heading on left and a CustomButton on right

import React from 'react';
import styled from 'styled-components';
import CustomButton from 'components/Button/CustomButton';
import {
  HeaderHeading,
  ReportingHeaderCnt,
} from 'pages/ReportingV2/ReportingHeader/reportListingHeader.style';
import PlaneFlyingIcon from 'components/Icons/PlaneFlyingIcon';

const ReportingHeader = () => {
  return (
    <ReportingHeaderCnt>
      <HeaderHeading>Reports</HeaderHeading>
      <CustomButton
        variant={'contained'}
        color={'primary'}
        startIcon={<PlaneFlyingIcon className={'requestReportIcon'} />}
        onClick={() => {
          window.open('mailto:support@xenia.team', '_blank');
        }}
      >
        Request a Custom Report
      </CustomButton>
    </ReportingHeaderCnt>
  );
};

export default ReportingHeader;
