import styled from '@mui/system/styled';

export const EventWrapperStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isScheduled' || prop !== 'color',
})(({ theme, isScheduled, color }) => ({
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  background: color,
  boxShadow:
    'inset rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
  ...(!isScheduled && { border: 'none' }),
  ...(isScheduled && { background: '#F5F5F5', border: '1px solid #d1d1d1' }),
}));
export const DateCellCnt = styled('div')({
  height: '100%',
  // '&:hover': {
  //   '.addTaskTextCnt': {
  //     background: '#F5F5F5',
  //     display: 'flex',
  //   },
  // },
});
export const AddTaskTextCnt = styled('div')({
  height: '100%',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  background: '#e3e3ff',
  color: 'rgb(104 104 255)',
  svg: {
    color: 'rgb(104 104 255)',
    fontSize: 18,
    marginRight: 5,
  },
});
export const StatusIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  svg: {
    color,
    fontSize: 10,
  },
}));
export const WeekCustomHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isToday',
})(({ theme, isToday }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: 8,
  '.day': {
    fontSize: 16,
    fontWeight: 400,
    color: '#00000099',
    ...(isToday && { color: '#6868fe' }),
  },
  '.dayNumber': {
    fontSize: 20,
    fontWeight: 700,
    color: '#00000099',
    ...(isToday && { color: '#6868fe' }),
  },
}));
export const CustomEventStyled = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  padding: 0,
  borderRadius: 5,
  width: '100%',
  '.taskTitle': {
    width: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    fontWeight: 600,
    fontSize: 12,
  },
  '& .actualTask': {
    display: 'flex',
    alignItems: 'center',
    height: 25,
  },
  '.scheduledTask': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 25,
    '.taskTitle': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));
