import { createSvgIcon } from '@mui/material';

interface IProps {
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const WordIcon = (props: IProps) => {
  const { bgColor = '#AB47BC', fillColor = 'white', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <rect width="32" height="32" rx="6" fill={bgColor} />
      <path
        d="M12.4391 27.1C12.3641 27.55 12.6641 28 13.1891 28H18.8141C19.2641 28 19.6391 27.55 19.5641 27.1L18.3641 21.85C18.2891 21.475 17.9891 21.25 17.6141 21.25H14.3141C13.9391 21.25 13.6391 21.475 13.5641 21.85L12.4391 27.1ZM15.9641 23.5C16.7891 23.5 17.4641 24.175 17.4641 25C17.4641 25.825 16.7891 26.5 15.9641 26.5C15.1391 26.5 14.4641 25.825 14.4641 25C14.4641 24.175 15.1391 23.5 15.9641 23.5Z"
        fill={fillColor}
      />
      <path d="M18.2129 18.25H13.7129V19.75H18.2129V18.25Z" fill={fillColor} />
      <path d="M18.2129 16H15.9629V17.5H18.2129V16Z" fill={fillColor} />
      <path d="M15.9629 14.5H13.7129V16H15.9629V14.5Z" fill={fillColor} />
      <path d="M18.2129 13H15.9629V14.5H18.2129V13Z" fill={fillColor} />
      <path d="M15.9629 11.5H13.7129V13H15.9629V11.5Z" fill={fillColor} />
      <path d="M18.2129 10H15.9629V11.5H18.2129V10Z" fill={fillColor} />
      <path d="M15.9629 8.5H13.7129V10H15.9629V8.5Z" fill={fillColor} />
      <path d="M18.2129 7H15.9629V8.5H18.2129V7Z" fill={fillColor} />
      <path d="M15.9629 5.5H13.7129V7H15.9629V5.5Z" fill={fillColor} />
      <path d="M18.2129 4H15.9629V5.5H18.2129V4Z" fill={fillColor} />
    </>,
    'WordIcon',
  );
  return <IconCmp viewBox={'0 0 32 32'} {...rest} />;
};
export default WordIcon;
