import { Stack, Typography } from '@mui/material';
import UserAvatar from '../AvatarsList/userAvatar';
import ChildItemIcon from 'components/Icons/childItemIcon';
import LocationIcon from 'components/Icons/locationIcon';
import TeamAvatar from '../AvatarsList/teamAvatar';
import { LocationIconCnt } from './style';

interface IProps {
  data?: any;
  assigneesProps?: any;
}

const defaultProps = {
  width: 28,
  height: 28,
  textStyles: {},
  locationIconStyles: {
    main: {},
    container: {},
  },
};

const SingleInstance = (props: IProps) => {
  const { data, assigneesProps = {} } = props;
  const customAssigneesProps = { ...defaultProps, ...assigneesProps };

  return (
    <>
      {/* User */}
      {data?.Assigned && (
        <Stack direction="row" alignItems="center" gap="8px">
          <UserAvatar
            data={data?.Assigned}
            height={customAssigneesProps?.height}
            width={customAssigneesProps?.width}
          />
          <Typography
            fontSize="14px"
            fontWeight="500"
            color="rgba(0,0,0)"
            sx={customAssigneesProps?.textStyles}
          >
            {data?.Assigned?.firstName} {data?.Assigned?.lastName ?? ''}
          </Typography>
        </Stack>
      )}

      {/* Team  */}
      {data?.Team && (
        <Stack direction="row" alignItems="center" gap="8px">
          <TeamAvatar
            data={data?.Team}
            height={customAssigneesProps?.height}
            width={customAssigneesProps?.width}
          />
          <Typography
            fontSize="14px"
            fontWeight="500"
            color="rgba(0,0,0)"
            sx={customAssigneesProps?.textStyles}
          >
            Team:
            {data?.Team?.name}
          </Typography>
        </Stack>
      )}

      {/* Location */}
      {data?.Location && (
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          sx={customAssigneesProps?.locationIconStyles?.main}
        >
          {data?.Location?.ParentId ? (
            <LocationIconCnt
              sx={customAssigneesProps?.locationIconStyles?.container}
            >
              <ChildItemIcon
                sx={
                  customAssigneesProps?.locationIconStyles?.childIcon || {
                    fontSize: 20,
                    color: '#71777ac9',
                  }
                }
              />
            </LocationIconCnt>
          ) : (
            <LocationIconCnt
              sx={customAssigneesProps?.locationIconStyles?.container}
            >
              <LocationIcon
                sx={
                  customAssigneesProps?.locationIconStyles?.parentIcon || {
                    fontSize: 20,
                    color: '#78909C',
                  }
                }
              />
            </LocationIconCnt>
          )}
          <Typography
            fontSize="14px"
            fontWeight="500"
            color="rgba(0,0,0)"
            sx={customAssigneesProps?.textStyles}
          >
            {data?.Location?.name}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default SingleInstance;
