import CustomButton from 'components/Button/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import ListGridToggle from 'components/ListGridToggle';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import { Stack, Divider } from '@mui/material';

import LocationFilterDropdown from 'pages/Widgets/TaskFilters/LocationFilter/locationFilters';
import { AssetsHeaderCnt } from './assetsHeader.style';
import AssetsSearchInput from './assetsSearchInput/assetsSearchInput';

interface Props {
  selectedView: ViewType;
  setSelectedView: any;
  onSearch?: (value: string) => void;
  onClickAdd: () => void;
  handleChangeLocation: (locations: any) => void;
  handleClearLocationFilter: () => void;
}

function AssetsHeader({
  selectedView,
  setSelectedView,
  onSearch,
  onClickAdd,
  handleChangeLocation,
  handleClearLocationFilter,
}: Props) {
  return (
    <AssetsHeaderCnt>
      <Stack direction="row" alignItems={'center'} gap={'12px'}>
        <AssetsSearchInput onSearch={onSearch} />
        <LocationFilterDropdown
          onChangeCallback={(option) => handleChangeLocation(option)}
          onClearCallback={() => handleClearLocationFilter()}
        />
      </Stack>

      <Stack direction="row" alignItems={'center'} gap={'12px'}>
        <ListGridToggle
          selectedView={selectedView}
          setSelectedView={(view) => setSelectedView(view)}
        />
        <Divider orientation="vertical" sx={{ height: '30px' }} />
        <CustomButton
          variant="contained"
          startIcon={<AddIcon style={{ color: 'white', fontSize: 22 }} />}
          style={{ padding: '7px 16px', borderRadius: '8px', fontWeight: 600 }}
          onClick={onClickAdd}
        >
          Add Asset
        </CustomButton>
      </Stack>
    </AssetsHeaderCnt>
  );
}

export default AssetsHeader;
