import { createSvgIcon } from '@mui/material';
import React from 'react';

const RequestPendingIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99179 6.99998L9.77901 6.21276C10.9878 5.00401 11.6668 3.36459 11.6668 1.65515C11.6668 1.06319 11.1869 0.583313 10.595 0.583313H3.40534C2.81338 0.583313 2.3335 1.06319 2.3335 1.65515C2.3335 3.36459 3.01257 5.00401 4.22132 6.21276L5.00854 6.99998L4.22132 7.7872C3.01257 8.99595 2.3335 10.6354 2.3335 12.3448C2.3335 12.9368 2.81338 13.4166 3.40534 13.4166H10.595C11.1869 13.4166 11.6668 12.9368 11.6668 12.3448C11.6668 10.6354 10.9878 8.99595 9.77901 7.7872L8.99179 6.99998ZM7.58348 6.41607C7.68596 6.41612 7.73719 6.41614 7.78967 6.40648C7.86788 6.39209 7.94941 6.35835 8.01491 6.31325C8.05886 6.28299 8.09485 6.247 8.16683 6.17502L8.95405 5.3878C9.69563 4.64622 10.1938 3.70672 10.3971 2.69345C10.4507 2.42625 10.4775 2.29265 10.4257 2.14283C10.385 2.02504 10.2771 1.89326 10.1696 1.8302C10.0329 1.74998 9.8716 1.74998 9.54908 1.74998H4.45125C4.12872 1.74998 3.96746 1.74998 3.83075 1.8302C3.72327 1.89326 3.6153 2.02504 3.57461 2.14283C3.52285 2.29265 3.54965 2.42625 3.60326 2.69345C3.80655 3.70672 4.3047 4.64622 5.04628 5.3878L5.8335 6.17502C5.90468 6.24621 5.94027 6.2818 5.98352 6.31174C6.04966 6.35753 6.13179 6.39157 6.21094 6.406C6.26268 6.41543 6.31333 6.41546 6.41463 6.41551L7.58348 6.41607Z"
        fill="currentColor"
      />
    </>,
    'RequestPendingIcon',
  );
  return <IconCmp viewBox={'0 0 14 14'} fill="none" {...props} />;
};

export default RequestPendingIcon;
