import { useReducer, useEffect } from 'react';
import {
  EmulatorWrapper,
  TemplateNameEmulator,
  OpenOnMobileButton,
} from '../style';

import TemplateBody from 'components/TemplatePreview/TemplateBody';

import { Stack } from '@mui/material';
import MobileIcon from 'components/Icons/mobileIcon';

const Emulator = ({
  checklist,
  checklistItems,
  handleClickMobile,
  checklistItemsHashRef,
}) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const intervalId = setInterval(forceUpdate, 1000);
    return () => clearInterval(intervalId);
  }, [checklist, checklistItems, checklistItemsHashRef]);

  return (
    <EmulatorWrapper>
      <Stack width="320px" alignItems={'center'} position={'relative'}>
        <img
          width={'310px'}
          height={'auto'}
          src={'/assets/img/iphoneblack.svg'}
          style={{
            maxWidth: 'none',
          }}
        />
        <Stack
          width="268px"
          position={'absolute'}
          zIndex={2}
          top="44px"
          left="31px"
          height={'545px'}
          overflow={'scroll'}
          pr="10px"
          gap="8px"
        >
          <Stack>
            <TemplateNameEmulator>{checklist?.name}</TemplateNameEmulator>
          </Stack>
          {!!checklist && checklistItems?.length && (
            <>
              <TemplateBody
                isEmulatorView={true}
                checklistItems={checklistItems.map((item) => {
                  return checklistItemsHashRef?.[item.id] ?? item;
                })}
              />
            </>
          )}
        </Stack>
      </Stack>
      <OpenOnMobileButton
        startIcon={<MobileIcon />}
        variant="outlined"
        buttonType="grayWhite"
        onClick={handleClickMobile}
      >
        Open On Mobile
      </OpenOnMobileButton>
    </EmulatorWrapper>
  );
};

export default Emulator;
