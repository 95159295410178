import { createSvgIcon } from '@mui/material';

interface IProps {
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const ExcelIcon = (props: IProps) => {
  const { bgColor = '#009688', fillColor = 'white', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <rect width="32" height="32" rx="6" fill={bgColor} />
      <g clip-path="url(#clip0_1399_34415)">
        <path
          d="M22.7612 7.31572C22.9728 7.3141 23.1826 7.35458 23.3784 7.43481C23.5742 7.51503 23.7521 7.6334 23.9017 7.78302C24.0513 7.93265 24.1697 8.11053 24.2499 8.30634C24.3301 8.50214 24.3706 8.71194 24.369 8.92353V22.7604C24.3706 22.972 24.3301 23.1818 24.2499 23.3776C24.1697 23.5734 24.0513 23.7513 23.9017 23.901C23.7521 24.0506 23.5742 24.1689 23.3784 24.2492C23.1826 24.3294 22.9728 24.3699 22.7612 24.3683H8.92426C8.71267 24.3699 8.50287 24.3294 8.30707 24.2492C8.11127 24.1689 7.93338 24.0506 7.78376 23.901C7.63413 23.7513 7.51576 23.5734 7.43554 23.3776C7.35531 23.1818 7.31483 22.972 7.31645 22.7604V8.92353C7.31483 8.71194 7.35531 8.50214 7.43554 8.30634C7.51576 8.11053 7.63413 7.93265 7.78376 7.78302C7.93338 7.6334 8.11127 7.51503 8.30707 7.43481C8.50287 7.35458 8.71267 7.3141 8.92426 7.31572H22.7612ZM11.5796 20.367H8.92426V22.7604H11.5796V20.367ZM22.7551 20.367H13.1813V22.7604H22.7612L22.7551 20.367ZM11.5796 16.3718H8.92426V18.7714H11.5796V16.3718ZM22.7551 16.3718H13.1813V18.7714H22.7612L22.7551 16.3718ZM11.5796 12.3828H8.92426V14.7762H11.5796V12.3828ZM22.7551 12.3828H13.1813V14.7762H22.7612L22.7551 12.3828Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1399_34415">
          <rect
            width="18"
            height="18"
            fill={fillColor}
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </>,
    'ExcelIcon',
  );
  return <IconCmp viewBox={'0 0 32 32'} {...rest} />;
};
export default ExcelIcon;
