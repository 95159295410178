// Styled
import { Container, Heading, DetailText } from './styled';

// Icons
import CalendarWithBlobIcon from 'components/Icons/calendarWithBlob';

const EmptyState = () => {
  return (
    <Container>
      <CalendarWithBlobIcon
        style={{ height: '221px', width: '250px', marginBottom: '40px' }}
      />
      <Heading>No Overdue Schedules</Heading>
      <DetailText>No scheduled work has been overdue.</DetailText>
    </Container>
  );
};

export default EmptyState;
