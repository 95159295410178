import { createSvgIcon } from '@mui/material';

const LogoutIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M13.5 4.5L16.5 7.5M16.5 7.5L13.5 10.5M16.5 7.5H6.75M11.25 1.65303C10.2939 1.07871 9.18393 0.75 8 0.75C4.41015 0.75 1.5 3.77208 1.5 7.5C1.5 11.2279 4.41015 14.25 8 14.25C9.18393 14.25 10.2939 13.9213 11.25 13.347"
        stroke="#E53935"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LogoutIcon',
  );
  return (
    <IconCmp
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      {...props}
    />
  );
};
export default LogoutIcon;
