/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { FC } from 'react';
import XeniaLogo from 'components/common/xenia-logo';

export const Copyrights: FC<any> = ({ classexits = '' }: any) => {
  return (
    <>
      <p className={`text-center uppercase copyright-reserved ${classexits}`}>
        © 2022 All Rights Reserved.
      </p>
    </>
  );
};

/*******************
  @Purpose : Return Splash Screen
  @Parameter : key
  @Author : INIC
  ******************/
export const returnSplashScreen = () => {
  return (
    <div className="w-screen h-screen bg-white flex items-center justify-center">
      <XeniaLogo
        fill="#252350"
        className="animate-pulse transition-all"
        width="400"
      />
    </div>
  );
};
