import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import EditIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteIcon from 'components/Icons/deleteIcon';
import { deleteTeam } from 'store/actions/teamActions';
import xeniaApi from 'api/index';
import { useDispatch } from 'react-redux';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CustomButton from 'components/Button/CustomButton';

function AlertDialog({ open, handleClose, success }) {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Team</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this team?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton variant={'text'} onClick={handleClose}>
            Cancel
          </CustomButton>
          <CustomButton
            variant={'contained'}
            color={'primary'}
            onClick={success}
          >
            Yes, Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const teamsActionDropdownOptions = [
  { label: 'Edit', id: 'edit', icon: <EditIcon />, iconAlign: 'start' },
  {
    label: 'Delete',
    id: 'delete',
    icon: <DeleteIcon sx={{ color: '#D32F2F' }} />,
    sx: {
      color: '#D32F2F',
      borderTop: '#0000001F 1px solid',
    },
    iconAlign: 'start',
  },
];
function TeamsActionDropdown(props) {
  const { data, handleEditTeam } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Handler for option select of action dropdown
  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'edit':
        {
          handleEditTeam?.(data);
        }
        break;

      case 'delete':
        {
          handleDialogOpen?.();
        }
        break;
      default: {
        return;
      }
    }
  };
  const handleDeleteTeam = async () => {
    handleClose();
    const deletedTeam = await xeniaApi.deleteTeam(data?.id);
    if (deletedTeam) {
      dispatch(deleteTeam(data?.id));
    }
  };
  return (
    <>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        success={handleDeleteTeam}
      />
      <ActionDropdown
        popperProps={{ style: { width: 180 }, placement: 'bottom-end' }}
        options={teamsActionDropdownOptions}
        handleOptionSelect={handleOptionSelect}
        buttonProps={{
          style: { padding: 2, transform: 'rotate(90deg)' },
        }}
      >
        {' '}
        <MoreVertOutlinedIcon style={{ fontSize: 22 }} />
      </ActionDropdown>
    </>
  );
}

export default TeamsActionDropdown;
