// Custom components
import ChecklistAttachments from 'pages/checklistV2/Builder/ChecklistAttachments';
import CustomButton from 'components/Button/CustomButton';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import IconPicker from 'pages/checklistV2/Builder/IconPicker';
import UsersOrTeamsSelectDropdown from 'components/Dropdown/PeopleOrTeamsSelectDropdown';

// Custom icons
import DeleteIcon from 'components/Icons/deleteIcon';

// Utilities
import {
  StyledCustomModal,
  ModalWrapper,
  HeaderWrapper,
  TextWrapper,
  Text,
  Row,
  IconPickerWrapper,
  ButtonsRow,
  AutocompleteWrapper,
  ImageRow,
  ImageAndRemoveWrapper,
  ImageContainer,
  Image,
  RemoveWrapper,
  RemoveText,
} from './styled';
import { Utils } from './utils';

// Custom hook
import { useMetaDetail } from './useMetaDetail';
import { getTemplateFolders } from 'api/checklistV2Api';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const propTypes = Utils.propTypes;

const MetaDetailModal = ({
  open,
  handleGoNextMetadata,
  isXeniaAdmin,
  isEditing,
  handleCloseModal,
  checklist,
  isNextLoading,
  folderId,
}: typeof propTypes) => {
  const {
    metadata,
    setMetadata,
    nextDisabled,
    isIconPickerOpen,
    selectedAttachment,
    industries,
    types,
    isLoading,
    setIsIconPickerOpen,
    handleUpload,
    handleRemoveImage,
    handleChangeMetadata,
    handleClickNext,
    handleClickCancel,
    handleDeleteChecklistAttachment,
    navigateAttachmentPreview,
    hideAttachmentPreview,
  } = useMetaDetail({
    handleGoNextMetadata,
    isXeniaAdmin,
    isEditing,
    handleCloseModal,
    checklist,
  }); // hook for utility functions

  const [foldersList, setFoldersList] = useState([]);
  const params = useParams();
  const getFolders = async () => {
    const foldersList = await getTemplateFolders({});
    setFoldersList(foldersList?.data);
  };
  useEffect(() => {
    getFolders();
  }, []);
  //Generating data for select options
  const targetFolderOptions = useMemo(() => {
    return foldersList?.map((f: any) => ({
      label: f?.title,
      value: f?.id,
      obj: f,
    }));
  }, [foldersList]);
  useEffect(() => {
    setMetadata((prevState) => ({
      ...prevState,
      FolderId: folderId,
    }));
  }, [folderId, foldersList]);
  return (
    <StyledCustomModal open={open}>
      <ModalWrapper>
        <HeaderWrapper>
          <TextWrapper>
            <Text type="heading">
              {isXeniaAdmin ? 'Template Information' : 'Template Name'}
            </Text>
            <Text>Set your template name and icon to begin.</Text>
          </TextWrapper>
        </HeaderWrapper>
        <Row>
          <IconPickerWrapper>
            <IconPicker
              setIconPickerOpen={setIsIconPickerOpen}
              iconPickerOpen={isIconPickerOpen}
              handlePickerSelect={(icon) => {
                handleChangeMetadata('icon', icon);
                setIsIconPickerOpen(false);
              }}
              icon={metadata?.icon}
            />
          </IconPickerWrapper>
          <CustomInput
            sx={{ flex: 1, '& .MuiOutlinedInput-input': { fontSize: 14 } }}
            suppressErrorSpace={true}
            muiLabel={false}
            fieldProps={{
              placeholder: 'Enter template name',
              onChange: (e) => handleChangeMetadata('name', e.target.value),
              fullWidth: true,
              value: metadata.name ?? '',
              autoFocus: true,
            }}
          />
        </Row>

        {/* Folder Selection */}
        {targetFolderOptions?.length > 0 && (
          <CustomSelect
            placeholder="Select Folder"
            isSearchable={true}
            closeMenuOnSelect={true}
            isDisabled={!!folderId}
            selectedOption={targetFolderOptions?.find(
              (item) => item?.value === metadata?.FolderId,
            )}
            options={targetFolderOptions}
            onChange={(e) => handleChangeMetadata('FolderId', e?.value)}
            isClearable={true}
          />
        )}

        {isXeniaAdmin ? (
          <>
            <CustomInput
              sx={{ mb: 3, '& .MuiOutlinedInput-input': { fontSize: 14 } }}
              suppressErrorSpace={true}
              muiLabel={false}
              fieldProps={{
                placeholder: 'Add a description',
                onChange: (e) =>
                  handleChangeMetadata('description', e.target.value),
                multiline: true,
                rows: 5,
                value: metadata.description ?? '',
              }}
            />
            <Row>
              <AutocompleteWrapper>
                <CustomSelect
                  selectedOption={
                    metadata.ChecklistIndustry?.value
                      ? metadata.ChecklistIndustry
                      : null
                  }
                  options={industries?.map((val) => ({
                    value: val.id,
                    label: val.name,
                  }))}
                  onChange={(val) =>
                    handleChangeMetadata('ChecklistIndustry', val)
                  }
                  placeholder="Industry"
                />
              </AutocompleteWrapper>
              <AutocompleteWrapper>
                <CustomSelect
                  selectedOption={
                    metadata.ChecklistType?.value
                      ? metadata.ChecklistType
                      : null
                  }
                  options={types?.map((val) => ({
                    value: val.id,
                    label: val.name,
                  }))}
                  onChange={(val) => handleChangeMetadata('ChecklistType', val)}
                  placeholder="Type"
                />
              </AutocompleteWrapper>
            </Row>
            {metadata.image?.preview ? (
              <ImageRow>
                <ImageAndRemoveWrapper>
                  <ImageContainer>
                    <Image src={metadata.image.preview} />
                  </ImageContainer>
                  <RemoveWrapper onClick={handleRemoveImage}>
                    <DeleteIcon
                      style={{
                        width: 20,
                        height: 20,
                        color: 'rgba(239, 83, 80, 1)',
                        fill: 'none',
                        cursor: 'pointer',
                      }}
                    />
                    <RemoveText>Remove</RemoveText>
                  </RemoveWrapper>
                </ImageAndRemoveWrapper>
              </ImageRow>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <ButtonsRow>
          <CustomButton
            sx={{ color: 'rgba(104,104,254,1)' }}
            variant="text"
            size="small"
            onClick={handleClickCancel}
          >
            Cancel
          </CustomButton>
          <CustomButton
            disabled={nextDisabled || isLoading || isNextLoading}
            variant="contained"
            size="small"
            onClick={handleClickNext}
          >
            {isEditing ? 'Update' : 'Next'}
          </CustomButton>
        </ButtonsRow>
      </ModalWrapper>
    </StyledCustomModal>
  );
};

export default MetaDetailModal;
