import { useSelector } from 'react-redux';
import { getUserHotelTz } from 'store/selectors/auth';
import { useParams } from 'react-router-dom';
import DateTime from 'utils/DateTime';
import { useEffect, useMemo, useReducer, useState } from 'react';
import xeniaApi from 'api/index';
import { editUserConfig, getUserConfig } from 'api/userConfig';
import { isEmpty } from 'lodash';

function useReports(reportName, fetchReport = true) {
  const tz = useSelector(getUserHotelTz);
  const [reload, setReload] = useState(false);

  const params = useParams();
  const { reportId } = params;
  const initialState = {
    gridSettings: null,
    selectedDateLabel: 'Last 30 Days',
    filters: {
      fromDate: new DateTime()
        .toTz(tz)
        .transform({ subtract: [29, 'day'], startOf: 'day' })
        .toJSDate(),
      toDate: new DateTime()
        .toTz(tz)
        .transform({ subtract: [0, 'day'], endOf: 'day' })
        .toJSDate(),
    },
    isLoading: false,
  };

  const [state, updateState] = useReducer((prevState, value) => {
    return { ...prevState, ...value };
  }, initialState);

  const defaultProps = {
    filters: state.filters,
    selectedDateLabel: state.selectedDateLabel,
    reload,
    setReload,
  };

  const fetchSingleReport = async () => {
    const data = await xeniaApi.getReport(reportId);
    if (data) {
      updateState({ report: data });
    }
  };

  const clearAllFilters = () => {
    updateState(initialState);
  };

  const getGridSettings = async () => {
    const savedGridSettings = await getUserConfig(reportName);
    if (savedGridSettings) {
      updateState({
        gridSettings: savedGridSettings?.config?.config?.gridLayout,
      });
    }
    updateState({ isLoading: false });
  };

  useEffect(() => {
    updateState({ isLoading: true });
    getGridSettings();
    if (fetchReport) {
      fetchSingleReport();
    }
  }, []);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  const updateGridSettings = async (settings) => {
    updateState({ gridSettings: settings?.gridsLayouts });
    const configObj = {
      type: reportName,
      config: { gridLayout: settings.gridsLayouts },
    };
    await editUserConfig(configObj);
  };

  const isFilterApplied = useMemo(() => {
    const { selectedDateLabel, filters } = state;
    const { fromDate, toDate, ...rest } = filters;
    const isFilterApplied =
      selectedDateLabel !== 'Last 30 Days' || !isEmpty(rest);
    return isFilterApplied;
  }, [state?.filters]);

  return {
    isFilterApplied,
    state,
    updateState,
    defaultProps,
    updateGridSettings,
    clearAllFilters,
  };
}

export default useReports;
