import { useRef, useState } from 'react';
import SegmentedControl from 'components/Button/SegmentedButtons/segmentedButton';

const CalendarViewSwitch = (props) => {
  const { handleViewSwitch } = props;

  return (
    <div>
      <SegmentedControl
        name="calendarViewSwitch"
        callback={(val) => handleViewSwitch(val)}
        controlRef={useRef()}
        segments={[
          {
            label: 'Month',
            value: 'month',
            ref: useRef(),
          },
          {
            label: 'Week',
            value: 'week',
            ref: useRef(),
          },
          {
            label: 'Day',
            value: 'day',
            ref: useRef(),
          },
        ]}
      />
    </div>
  );
};

export default CalendarViewSwitch;
