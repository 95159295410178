// MUI
import { Box } from '@mui/material';

// Icons
import DeleteIcon from 'components/Icons/deleteIcon';
import CloseIcon from 'components/Icons/closeIcon';

// Styled
import {
  BulkActionSelection,
  BulkActionCnt,
  BulkActionItem,
  BulkActionCancelItem,
} from 'components/common/styledComponents/bulkAction.style';

interface MembersBulkActionsPropTypes {
  selected: any[];
  handleRemove: () => void;
  onClearSelection: () => void;
}

const MembersBulkActions = ({
  selected,
  handleRemove,
  onClearSelection,
}: MembersBulkActionsPropTypes) => {
  return (
    <BulkActionCnt>
      <BulkActionSelection>
        <span className={'count'}>{selected.length}</span>
        <span className={'selectedText'}>
          Member{selected?.length > 1 ? 's' : ''}
        </span>
      </BulkActionSelection>
      <BulkActionItem onClick={handleRemove} noborder={'true'}>
        <DeleteIcon />
        <span className="actionText">Remove</span>
      </BulkActionItem>
      <BulkActionCancelItem onClick={onClearSelection}>
        {' '}
        <Box
          sx={{
            path: {
              fill: 'rgba(0,0,0,0.6)',
            },
          }}
        >
          <CloseIcon className={'actionIcon'} />
        </Box>
      </BulkActionCancelItem>
    </BulkActionCnt>
  );
};

export default MembersBulkActions;
