import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const TaskListSkeleton = () => {
  return (
    <Stack spacing={3} style={{ width: '100%', margin: '0px 30px' }}>
      <Skeleton variant="rectangular" height={20} />

      {[...Array(8)].map(() => (
        <Skeleton variant="rectangular" height={50} />
      ))}
    </Stack>
  );
};

export default TaskListSkeleton;
