// React
import { useState, useMemo } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

import WorkspaceAvatar from 'components/Avatar/WorkspaceAvatar';

// Styled
import {
  NotificationTextAndSeeMoreWrapper,
  NotificationText,
  NotificationAndWorkspaceWrapper,
  WorkspaceAvatarAndNameWrapper,
  WorkspaceText,
  SeeMore,
} from './NotificationMessage.styled';

interface Proptypes {
  title: string;
  workspaceId: string;
}

const MAX_LENGTH = 150;

const NotificationMessage = ({ title, workspaceId }: Proptypes) => {
  const userHotels: any = useSelector(selectors.getUserHotels);
  const selectedWorkspace = userHotels?.find((h) => h.Hotel.id == workspaceId);
  const workspaceName = selectedWorkspace?.Hotel?.name;

  const [seeMore, setSeeMore] = useState(false);

  const hasSeeMore = useMemo(() => title.length > MAX_LENGTH, [title]);
  const formattedTitle = useMemo(() => {
    if (!hasSeeMore) return title;
    if (seeMore) return title;
    if (title.length > MAX_LENGTH) {
      return `${title.slice(0, MAX_LENGTH)} ...`;
    }
    return title;
  }, [title, seeMore]);

  const toggleSeeMore = (e) => {
    e.stopPropagation();
    setSeeMore(!seeMore);
  };

  return (
    <NotificationAndWorkspaceWrapper>
      {/* change logic here for formatting the notification text */}
      <NotificationTextAndSeeMoreWrapper>
        <NotificationText>{formattedTitle}</NotificationText>
        {hasSeeMore && !seeMore && (
          <SeeMore onClick={toggleSeeMore}>See more</SeeMore>
        )}
        {hasSeeMore && seeMore && (
          <SeeMore onClick={toggleSeeMore}>See less</SeeMore>
        )}
      </NotificationTextAndSeeMoreWrapper>
      <WorkspaceAvatarAndNameWrapper>
        <WorkspaceAvatar workspaceId={workspaceId} width={18} height={18} />
        <WorkspaceText>{workspaceName}</WorkspaceText>
      </WorkspaceAvatarAndNameWrapper>
    </NotificationAndWorkspaceWrapper>
  );
};

export default NotificationMessage;
