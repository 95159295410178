// React
import { useMemo, Fragment } from 'react';

// Custom components
import IosSwitch from 'components/IosSwitch';
import LocationConfigBoxLayout from './components/LocationConfigBoxLayout';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import FeatureLockIcon from 'components/Icons/featureLockIcon';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// MUI
import { Stack } from '@mui/material';
import { CircularProgress } from '@mui/material';

// Styled
import { BoldText, GreyText } from './styled';

// Hooks
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import LocationsLevels from './levels';

const LocationsConfig = () => {
  const { config, updateConfig, localConfig } = useWorkspaceConfig();
  const { hasFeature, FEATURES } = useCustomPermission();
  const memberAndLocationOption = useMemo(
    () => (localConfig?.membersCanViewSelectAllLocations ? 1 : 2),
    [localConfig],
  );

  const usersViewOption = useMemo(
    () => (localConfig?.userCanViewAllUsers ? 1 : 2),
    [localConfig],
  );

  return (
    <Stack padding={'8px 40px'} gap={'16px'}>
      {!config ? (
        <Stack
          width={'100%'}
          height={'300px'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <VerifyFeatureAccess
            featureId={FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              border={'1px solid #E0E0E0'}
              borderRadius={'8px'}
              padding={'12px 16px'}
              style={{
                ...(!hasFeature(
                  FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT,
                ) && {
                  cursor: 'pointer',
                }),
              }}
            >
              <Stack gap={'4px'} width="100%">
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <BoldText>Advanced Location Based Assignment</BoldText>
                  {hasFeature(FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT) && (
                    <IosSwitch
                      height={20}
                      width={32}
                      checked={localConfig?.advancedLocationBasedAssignment}
                      onChange={(e) =>
                        updateConfig(
                          'advancedLocationBasedAssignment',
                          !localConfig?.advancedLocationBasedAssignment,
                        )
                      }
                      thumbSize={16}
                    />
                  )}
                </Stack>
                <GreyText>
                  If this option is checked, it will enable the ability to
                  assign users to the locations.
                </GreyText>
              </Stack>
              {!hasFeature(FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT) && (
                <FeatureLockIcon style={{ height: 36, width: 36 }} />
              )}
            </Stack>
          </VerifyFeatureAccess>

          {localConfig?.advancedLocationBasedAssignment && (
            <Stack border={'1px solid #E0E0E0'} borderRadius={'8px'}>
              <Stack padding={'12px 16px'} borderBottom={'1px solid #E0E0E0'}>
                <LocationConfigBoxLayout
                  heading="What location can team members view and select"
                  footerNote={`“Admins” will be able to select from all locations.`}
                  options={[
                    {
                      id: 1,
                      label: 'Members can view and select from all locations',
                    },
                    {
                      id: 2,
                      label: `Members can only view and select locations they're a member of`,
                    },
                  ]}
                  selectedOptionId={memberAndLocationOption}
                  onClickOption={(option) => {
                    updateConfig(
                      'membersCanViewSelectAllLocations',
                      option.id === 1,
                    );
                  }}
                />
              </Stack>
              <Stack padding={'12px 16px'}>
                <LocationConfigBoxLayout
                  heading="What users can other users see"
                  footerNote={`“Admins” can see all users.`}
                  options={[
                    {
                      id: 1,
                      label:
                        'Users can see all users regardless of location membership',
                    },
                    {
                      id: 2,
                      label: `Users can only see other users and teams in locations they are a member of`,
                    },
                  ]}
                  selectedOptionId={usersViewOption}
                  onClickOption={(option) => {
                    updateConfig('userCanViewAllUsers', option.id === 1);
                  }}
                />
              </Stack>
            </Stack>
          )}

          {/* Locations Levels */}
          <LocationsLevels />
        </Fragment>
      )}
    </Stack>
  );
};

export default LocationsConfig;
