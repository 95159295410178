import actionTypes from 'store/actionTypes';
import { initialDialogStateData } from 'store/initialStateData';
import { cloneDeep } from 'lodash';

export const dialogReducer = (state: any = initialDialogStateData, action) => {
  switch (action.type) {
    case actionTypes.SET_DIALOG: {
      const updatedState = {
        ...state,
        [action.payload.dialogId]: action.payload,
      };

      return updatedState;
    }
    case actionTypes.CLOSE_DIALOG: {
      const dialogId = action.payload;
      const cloneState = cloneDeep(state);
      delete cloneState[dialogId];

      return cloneState;
    }
    default:
      return state;
  }
};
