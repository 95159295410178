import styled from '@mui/system/styled';

export const DashboardsListingContainer = styled('div')({
  marginTop: 5,
});

export const LoaderCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});
