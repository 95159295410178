import TemplatesEmpty from 'public/assets/img/templatesEmpty.svg';
import CTypography from 'components/CTypography';
import { EmptyCnt } from 'pages/Templates/MyTemplates/emptyStates/myTemplates/styles';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import { useContext } from 'react';
import { DocumentsContext } from 'pages/documents/context/context';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function FolderDocumentsEmpty() {
  const dispatch = useDispatch();
  const { state, addDocument, updateState } = useContext(DocumentsContext);
  const { createDocument } = useDocumentsFolders();
  const { selectedFolder, isArchive } = state;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const uploadDocumentHandler = async (data) => {
    if (selectedFolder) {
      data = {
        ...data,
        FolderId: selectedFolder?.id,
      };
    }

    const response = await createDocument(data);
    if (isArchive) {
      updateState({
        isArchive: false,
      });
      navigateWithWorkspaceUrl(`/documents/my-documents`);
    } else {
      addDocument(response);
    }

    manageLoader(false);

    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
      }),
    );
  };

  const sopHandler = async () => {
    let obj: any = {
      title: 'New Document',
    };
    if (selectedFolder) {
      obj = {
        ...obj,
        FolderId: selectedFolder?.id,
      };
    }

    const response = await createDocument(obj, false);

    if (response?.id) {
      addDocument(response);

      dispatch(
        setDialog({
          open: false,
          dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
        }),
      );

      const urlTab = `${window.location.origin}/document-detail/${response?.id}?source=list`;
      window.open(urlTab, '_self');
    }
  };

  const manageLoader = (loading) => {
    updateState({ loading });
  };

  const addNew = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
        data: {
          onUploadFileSuccessCallback: uploadDocumentHandler,
          onSOPSuccessCallback: sopHandler,
          manageLoader: manageLoader,
          FolderId: selectedFolder?.id,
        },
      }),
    );
  };

  return (
    <EmptyCnt>
      <div className="content">
        <img alt="templates" src={TemplatesEmpty} />
        <CTypography className="title">No documents</CTypography>
        <CTypography className="subtitle">
          There are no documents in this folder. To add a document, click on the
          <CustomButton
            variant="text"
            sx={{ padding: '2px 16px' }}
            onClick={addNew}
          >
            “+ Add New”
          </CustomButton>
          button above.
        </CTypography>
      </div>
    </EmptyCnt>
  );
}

export default FolderDocumentsEmpty;
