import React, { useMemo } from 'react';
import {
  AssetDetailsCnt,
  LocationNameValueCnt,
} from 'pages/Assets/AssetDetail/AssetDetailTab/assetDetailTab.style';
import {
  InputWithLabel,
  LocationNameWithLabelCnt,
} from 'components/AllDialogs/assets/createAsset/createAsset.style';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useSelector } from 'react-redux';
import { getLocationsState } from 'store/selectors/locations';
import AssetsPhotosPreview from './assetPhotosPreview.cmp';
import AssetsFilesPreview from './assetFilesPreview.cmp';
import AssetQRCode from 'pages/Assets/AssetDetail/AssetDetailTab/assetQRCode.cmp';
import NextService from './nextService.cmp';
import LastServiceDate from './lastServiceDate.cmp';
import LastServiceAgo from './lastService.cmp';

function AssetFormDetails(props) {
  const { asset, onEdit, updateAssetState } = props;
  const { getUtcToTz } = useDateTime();
  const locationsList = useSelector(getLocationsState);
  const parentLocation = useMemo(() => {
    const parentLocationObj = locationsList?.find((l) =>
      l?.Sublocations?.some((sl) => sl?.id == asset?.LocationId),
    );
    return parentLocationObj;
  }, [locationsList, asset]);
  // const childLocation = useMemo(() => {
  //   const childLocationObj = locationsList?.find(
  //     (location) => location.id === asset?.LocationId,
  //   );
  //   return childLocationObj;
  // }
  const formattedDate = useMemo(() => {
    if (asset.purchaseDate) {
      return getUtcToTz(asset.purchaseDate, 'MMM DD YYYY');
    }
    return '-';
  }, [asset]);

  const handleAddQR = () => {
    onEdit();
    updateAssetState({ isQREnable: true });
  };

  return (
    <AssetDetailsCnt>
      <InputWithLabel style={{ marginTop: 4 }}>
        <label className="title">Asset Name</label>
        <label className={'heading'}>{asset.name || '-'}</label>
      </InputWithLabel>
      <LocationNameWithLabelCnt
        className="locationCnt"
        style={{ marginTop: 4 }}
      >
        <label className="title">Location</label>
        <LocationNameValueCnt>
          <label className={'locationName'}>
            {asset?.Location?.name || '-'}
          </label>
          {parentLocation ? (
            <label className={'parentLocationName'}>
              Parent: {parentLocation?.name}
            </label>
          ) : null}
        </LocationNameValueCnt>
      </LocationNameWithLabelCnt>
      <AssetsPhotosPreview asset={asset} />

      <InputWithLabel style={{ marginTop: 4 }}>
        <label className="title">Description</label>

        <label className={'value'}>{asset?.description || '-'}</label>
      </InputWithLabel>
      <NextService nextService={asset?.NextService} />
      <LastServiceDate lastService={asset?.LastService} />
      <LastServiceAgo lastService={asset?.LastService} />

      <InputWithLabel style={{ marginTop: 4 }}>
        <label className="title">Model</label>

        <label className={'value'}>{asset?.model || '-'}</label>
      </InputWithLabel>
      <InputWithLabel style={{ marginTop: 4 }}>
        <label className="title">Serial Number</label>

        <label className={'value'}>{asset.serialNumber || '-'}</label>
      </InputWithLabel>
      <InputWithLabel style={{ marginTop: 4 }}>
        <label className="title">Purchase Date</label>
        <label className={'value'}>{formattedDate}</label>
      </InputWithLabel>
      <AssetsFilesPreview asset={asset} />
      <AssetQRCode asset={asset} handleAddQR={handleAddQR} />
    </AssetDetailsCnt>
  );
}

export default AssetFormDetails;
