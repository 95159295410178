import { Box, Stack, Typography } from '@mui/material';
import { IDashboardTemplateItem } from 'pages/Dashboards/interfaces';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { Content } from './templateItem.style';
import { dashboardImages } from './reportsImages';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface IDashboardTemplateItemProps {
  item: IDashboardTemplateItem;
}
function DashboardTemplateItem(props: IDashboardTemplateItemProps) {
  const { item } = props;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const handleReportClick = (_, report) => {
    navigateWithWorkspaceUrl(`/dashboards/template/${report.id}`);
  };
  return (
    <Box
      onClick={(e) => handleReportClick(e, item)}
      sx={{ marginBottom: '15px', cursor: 'pointer' }}
    >
      <Content direction="row" justifyContent="space-between">
        <Stack direction="row" gap="10px">
          {dashboardImages?.[item?.slug]}
          <Stack direction="column" gap="7px">
            <Typography className="heading">{item?.name}</Typography>
            <Typography className="statement">{item.description}</Typography>
          </Stack>
        </Stack>
        <KeyboardArrowRightIcon sx={{ color: '#757575' }} />
      </Content>
    </Box>
  );
}

export default DashboardTemplateItem;
