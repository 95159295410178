// React
import { useContext } from 'react';

// Components
import CustomInput from 'components/Form/TextField/TextField.cmp';

// Icons
import SearchIcon from '@mui/icons-material/Search';

// Context
import { SubmissionContext } from 'pages/TemplateDashboard/Tabs/Submissions/context';

const SearchBar = () => {
  const ctx = useContext(SubmissionContext);

  const handleChange = (e) => {
    ctx?.handleSearch(e.target.value);
  };

  return (
    <CustomInput
      id="locationsSearchInput"
      suppressErrorSpace={true}
      grayInput={true}
      fieldProps={{
        onChange: handleChange,
        startAdornment: <SearchIcon />,
        placeholder: 'Search',
      }}
      label=""
    />
  );
};

export default SearchBar;
