import IconButton from '@mui/material/IconButton';
import CTypography from 'components/CTypography';
import UserAvatar from 'components/Avatar';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';
import DeleteIcon from 'components/Icons/deleteIcon';
import CellEditor from './cellEditor';
import EditIcon from 'components/Icons/editIcon';
import {
  TitleCnt,
  CreatedByCnt,
} from 'pages/service-management/categories/categories.style';
import { isError } from './utils';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

export const Columns = (props) => {
  return [
    {
      headerName: 'Title',
      field: 'type',
      colId: 'type',
      headerClass: 'header-category',
      suppressMovable: true,
      suppressMenu: true,
      cellRenderer: (params) => cellRenderer.title(params, props),
      editable: true,
      cellEditorSelector: cellEditorSelector,
      valueSetter: (params) => ValueSetter.title(params, props),
    },
    {
      headerName: 'Created By',
      colId: 'createdBy',
      suppressMovable: true,
      headerClass: 'header-category',
      suppressMenu: true,
      cellRenderer: cellRenderer.createdBy,
      valueGetter: valueGetters.createdBy,
    },
    {
      field: '',
      maxWidth: 80,
      headerName: '',
      colId: 'actionCol',
      suppressMovable: true,
      suppressMenu: true,
      cellRenderer: (params) => cellRenderer.actionColumn(params, props),
    },
  ];
};

const valueGetters = {
  createdBy: (params) => {
    const { User } = params.data;
    return `${User?.firstName} ${User?.lastName}`;
  },
};

const cellRenderer = {
  title: (params, props) => {
    const { id, type, color, inProgress } = params.data;
    const { updateHandler } = props;
    const { onBtStartEditing } = props;
    return (
      <TitleCnt inProgress={inProgress}>
        <ColorsDropdown
          selected={color}
          changeHandler={(color) => {
            updateHandler({ type: type, color: color }, id);
          }}
        />
        <CTypography className="rowContent">{type}</CTypography>
        <IconButton
          style={{
            marginLeft: 10,
            display: 'none',
            backgroundColor: 'transparent',
            padding: 0,
            marginBottom: 4,
          }}
          className="editIcon"
          onClick={() => {
            onBtStartEditing(params);
          }}
        >
          <EditIcon style={{ fontSize: 15, color: '#8e8484' }} />
        </IconButton>
      </TitleCnt>
    );
  },
  createdBy: (params) => {
    const { User, UserId } = params.data;
    return User?.firstName || User?.lastName ? (
      <CreatedByCnt>
        <UserAvatar
          width={24}
          height={24}
          userId={UserId}
          className="userIcon"
        />
        <CTypography className="rowContent">
          {User?.firstName} {User?.lastName}
        </CTypography>
      </CreatedByCnt>
    ) : (
      <span>---</span>
    );
  },
  actionColumn: (params, props) => {
    const { handleShowRemove } = props;
    const { FEATURES } = useCustomPermission();
    return (
      <div className="actions" style={{ display: 'none' }}>
        <IconButton onClick={() => handleShowRemove(params.data)}>
          <DeleteIcon style={{ color: 'red' }} />
        </IconButton>
      </div>
    );
  },
};

const cellEditorSelector = () => {
  return {
    component: CellEditor,
  };
};

const ValueSetter = {
  title: async (params, props) => {
    const { updateHandler, serviceList } = props;
    const data = params.data;
    if (
      data.type !== params.newValue &&
      !isError(params.newValue, serviceList, null)
    ) {
      updateHandler({ type: params.newValue }, data.id);
      return true;
    } else {
      return false;
    }
  },
};
