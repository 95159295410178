/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAddGroupChannel } from 'controller/useAddGroupChannel';
import { Inputtype } from 'interfaces/htmlTags';
import selectors from 'store/selectors';
import { ConstantChannelTypeString } from 'utils/globalVariable';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from 'components/Button/CustomButton';

import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { Grid } from '@mui/material';
import { AddFeedBox } from './AddFeed.style';

export const AddFeed: FC<any> = ({
  showAddPublicFeeds,
  setShowAddPublicFeeds,
}: any) => {
  const currentUserId: any = useSelector(selectors.getUserId);
  const { openWSChannel } = useWorkspaceHook();

  const { handleAddGroupChannelSubmit } = useAddGroupChannel();
  const [feedName, setFeedName] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [feedNameError, setFeedNameError] = useState('');
  const addFeedSubmit = async () => {
    setButtonDisabled(true);
    if (feedName !== '') {
      const channel = await handleAddGroupChannelSubmit(
        [currentUserId],
        feedName,
        ConstantChannelTypeString.FEED_STREAM,
      );
      setSubmitFeed();
      setFeedNameError('');
      setButtonDisabled(false);
      openWSChannel({ channel });
    } else {
      setFeedNameError('Feed name is required.');
      setButtonDisabled(false);
    }
  };

  const setSubmitFeed = () => {
    setShowAddPublicFeeds(false);
    setFeedName('');
    setFeedNameError('');
  };
  return (
    showAddPublicFeeds && (
      <AddFeedBox>
        <CustomInput
          grayInput={true}
          type={Inputtype.TEXT}
          value={feedName}
          autoComplete="off"
          autoFocus={true}
          error={feedNameError}
          fieldProps={{
            placeholder: 'Feed Name',
          }}
          onChange={(e) => {
            setFeedName(e.target.value);
          }}
        ></CustomInput>
        <Grid display="flex" gap="10">
          <CustomButton
            variant={'contained'}
            disabled={buttonDisabled}
            onClick={() => {
              addFeedSubmit();
            }}
          >
            Save
          </CustomButton>
          <CustomButton
            variant={'text'}
            onClick={() => {
              setSubmitFeed();
            }}
          >
            Cancel
          </CustomButton>
        </Grid>
      </AddFeedBox>
    )
  );
};
