import { Box, styled } from '@mui/material';

export const Header = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  height: 60,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 30px',

  '& .signup': {
    width: 85,
    padding: 5,
  },

  '& .login': {
    width: 85,
    padding: 5,
  },
}));

export const MainCnt = styled(Box)(({ theme }) => ({
  height: '100vh',
  '& .contentCnt': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#F5F6FB',
    height: '100%',
  },

  '& .innerCnt': {
    backgroundColor: 'white',
    height: 300,
    width: 500,
    borderRadius: '24px',
    padding: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0px 0px 3px -2px rgba(0,0,0,1)',

    [theme.breakpoints.down('sm')]: {
      height: 'unset',
      width: 'unset',
      borderRadius: 'unset',
    },
  },

  '& .title': {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '27.33px',
    color: '#212121',
    marginBottom: 10,
  },

  '& .statement': {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.15px',
    lineHeight: '24px',
    color: '#616161',
    marginBottom: 15,
  },
  '& .goToHome': {
    padding: 7,
    borderRadius: 8,
    width: 175,

    fontSize: 15,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    fontWeight: 500,
  },
}));
