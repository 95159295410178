import { getFileTypeFromExt } from 'xeniautils';
import { useMemo } from 'react';
import mime from 'mime';

const Previewable = (props) => {
  const { document } = props;

  const fileType: any = useMemo(() => {
    const ext = mime
      .getExtension(document?.meta?.headInfo?.['content-type'])
      ?.toString();

    return getFileTypeFromExt(ext);
  }, [document]);

  return (
    <div className="contentCnt">
      {fileType === 'image' && (
        <img style={{ height: 'calc(100vh - 100px)' }} src={document?.path} />
      )}

      {['pdf', 'word', 'video']?.includes(fileType) && (
        <iframe
          src={document?.path}
          height="100%"
          width="100%"
          allowFullScreen
        />
      )}
    </div>
  );
};

export default Previewable;
