import ListGridToggle from 'components/ListGridToggle';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { Stack } from '@mui/material';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface SearchAndToggleHeaderPropTypes {
  selectedView: ViewType;
  setSelectedView: (view: ViewType) => void;
  handleSearchInput: (event: any) => void;
}

const SearchAndToggleHeader = ({
  selectedView,
  setSelectedView,
  handleSearchInput,
}: SearchAndToggleHeaderPropTypes) => {
  const { isMobileDeviceSm } = useResponsive();
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <CustomInput
        id="assetsSearchInput"
        suppressErrorSpace={true}
        grayInput={true}
        style={{
          width: isMobileDeviceSm ? 'unset' : '200px',
          borderRadius: '8px',
        }}
        fieldProps={{
          onChange: (e) => handleSearchInput(e.target.value || ''),
          startAdornment: <SearchIcon />,
          placeholder: 'Search',
        }}
        label=""
      />

      {!isMobileDeviceSm && (
        <ListGridToggle
          selectedView={selectedView}
          setSelectedView={setSelectedView}
        />
      )}
    </Stack>
  );
};

export default SearchAndToggleHeader;
