import { useMutation } from 'react-query';

import ScheduleCompletionOverview from 'pages/Widgets/TemplateLibrary/ScheduledTasks/ScheduleCompletionOverview';
import { getScheduleCompletionOverview } from 'api/scheduledReportsApi';
import { useEffect, useState } from 'react';

function CompletionOverview(props) {
  const { filters } = props;

  const [data, setData] = useState({});

  const getQuery = useMutation({
    mutationKey: 'getScheduledCompletionOverview',
    mutationFn: getScheduleCompletionOverview,
  });

  const error = getQuery.error;
  const loading = getQuery.isLoading;

  const getData = async () => {
    const requestPayload = {
      filters: {
        location: filters.locations,
        schedules: filters.schedules,
        projects: filters.projects,
        ...((filters?.fromDate || filters?.toDate) && {
          dateRange: [filters?.fromDate, filters?.toDate],
        }),
      },
    };

    getQuery.mutate(requestPayload, {
      onSuccess: (data) => {
        const chartData = data?.charts || [];

        const order = ['Completed on Time', 'Completed Late', 'Incomplete'];

        const sortedData = chartData.sort((a, b) => {
          return order.indexOf(a?.valueX) - order.indexOf(b.valueX);
        });

        setData({
          chart: sortedData || [],
          summary: {
            completedOnTime: data?.summary?.completedOnTime || 0,
          },
        });
      },
    });
  };

  useEffect(() => {
    if (
      (filters?.fromDate && filters?.toDate) ||
      (!filters?.fromDate && !filters?.toDate)
    ) {
      getData();
    }
  }, [filters]);

  return (
    <ScheduleCompletionOverview
      title="Schedule Completion Overview"
      data={data}
      loading={loading ?? true}
      error={error}
      reload={getData}
      globalFilters={filters}
    />
  );
}

export default CompletionOverview;
