// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 10,
  border: '1px solid rgba(224, 224, 224, 1)',
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: theme.spacing(1, 2),
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const WidgetHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '700',
  letterSpacing: '0.15px',
  lineHeight: '21.86px',
  color: 'rgba(33, 33, 33, 1)',
}));

export const ActionButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  height: '250px',
  width: '100%',
  overflow: 'scroll',
  padding: '12px 16px',
}));
