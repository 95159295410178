import { createSvgIcon } from '@mui/material';

const CasesIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M13.3333 14.1667H2C1.63333 14.1667 1.31956 14.0363 1.05867 13.7754C0.797334 13.5141 0.666667 13.2001 0.666667 12.8334V5.50008H2V12.8334H13.3333V14.1667ZM4.66667 11.5001C4.3 11.5001 3.98622 11.3696 3.72533 11.1087C3.464 10.8474 3.33333 10.5334 3.33333 10.1667V2.83341H6.66667V1.50008C6.66667 1.13341 6.79733 0.819415 7.05867 0.558081C7.31956 0.297192 7.63333 0.166748 8 0.166748H10.6667C11.0333 0.166748 11.3473 0.297192 11.6087 0.558081C11.8696 0.819415 12 1.13341 12 1.50008V2.83341H15.3333V10.1667C15.3333 10.5334 15.2029 10.8474 14.942 11.1087C14.6807 11.3696 14.3667 11.5001 14 11.5001H4.66667ZM8 2.83341H10.6667V1.50008H8V2.83341ZM4.66667 10.1667H14V4.16675H4.66667V10.1667Z"
        fill="black"
        fill-opacity="0.6"
      />
    </>,
    'CasesIcon',
  );
  return <IconCmp viewBox={'0 0 16 15'} {...props} />;
};
export default CasesIcon;
