import React, { useState, useMemo } from 'react';

import { Stack, Typography } from '@mui/material';

import CustomDropdown from 'components/Dropdown/CustomDropdown';
import VisibilityDropdown from './VisibilityDropdown';

// Icons
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { KeyboardArrowUpRounded } from '@mui/icons-material';

type FieldWithActionsPropType = {
  icon: React.ReactNode;
  label: string;
  visibility: {
    required: boolean;
    show: boolean;
  };
  disabled?: boolean;
  onVisibilityChange: (visibility: string) => void;
};

const FieldWithActions = ({
  icon,
  label,
  visibility,
  disabled,
  onVisibilityChange,
}: FieldWithActionsPropType) => {
  const [ref, setRef] = useState(null);

  const handleClickDropdownButton = (event: any) => {
    if (disabled) return;
    setRef(ref ? null : event.currentTarget);
  };

  const formattedVisibility = useMemo(
    () =>
      visibility?.required
        ? 'Required'
        : visibility?.show
        ? 'Optional'
        : 'Hidden',
    [visibility],
  );

  const handleChangeVisibility = (visibility: string) => {
    onVisibilityChange(visibility);
    setRef(null);
  };

  return (
    <Stack
      sx={{
        '& svg': {
          width: '18px',
          height: '18px',
          color: '#616161',
          '& path': {
            strokeWidth: 1,
          },
        },
      }}
      width={'100%'}
      direction={'row'}
      gap={'8px'}
      alignItems={'center'}
    >
      {icon}
      <Typography flex={1} fontSize={14} fontWeight={400} color={'#212121'}>
        {label}
      </Typography>
      <CustomDropdown
        onClickawayCallback={() => setRef(null)}
        popperProps={{
          open: Boolean(ref),
          anchorEl: ref,
          content: (
            <VisibilityDropdown
              onVisibilityChange={handleChangeVisibility}
              visibility={formattedVisibility}
            />
          ),
        }}
        buttonRenderer={
          <Stack
            padding={'8px 12px'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            onClick={handleClickDropdownButton}
            border={'1px solid #E0E0E0'}
            borderRadius={'6px'}
            gap={'4px'}
            sx={{
              '& svg': {
                width: '18px',
                height: '18px',
              },
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
            width={'130px'}
            bgcolor={disabled ? '#F5F5F5' : ''}
          >
            <Typography fontSize={13} fontWeight={400} color={'#212121'}>
              {formattedVisibility}
            </Typography>
            {ref ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
          </Stack>
        }
      />
    </Stack>
  );
};

export default FieldWithActions;
