import styled from '@mui/system/styled';
import { Box } from '@mui/material';

export const DashboardCmpDraftHeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 20px',
  width: '100%',
  borderBottom: '1px solid #E0E0E0',
  '& .dashboardHeaderRightCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  '.dashboardTitleInput': {
    marginRight: 100,
    input: {
      fontSize: 22,
      fontWeight: 700,
      color: '#000000',
      padding: '5.5px 14px',
      '&:hover': {
        backgroundColor: '#f5f5f5',
        borderRadius: 8,
      },
    },
  },
}));

export const GoBackCnt = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    position: 'unset',
    left: 'unset',
    gap: 'unset',
  },
  '& svg': {
    fontSize: 24,
    color: '#212121',
  },
}));
