// React
import { ReactNode } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getUserHotelTz } from 'store/selectors/auth';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';

// Icons
import { xeniaLogoBase64 } from 'pages/task/TaskListView/ExportDropdown/xeniaLogoBase64';
import { SaveAltOutlined } from '@mui/icons-material';

// Utils
import printDoc from 'pages/task/TaskListView/pdfExport/printDoc';
import DateTime from 'utils/DateTime';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';

// Types
import { ShouldRowBeSkippedParams } from 'ag-grid-community';

const ExportDropdownOptions = [
  { label: 'Export To Excel', id: 'exportToExcel' },
  { label: 'Export To PDF', id: 'exportToPdf' },
];
const printParams = {
  PDF_HEADER_COLOR: '#5555ff',
  PDF_LOGO:
    'https://assets.website-files.com/62168f6065c8d5f1a5d782ee/6216953bcfa3e2224fff4e16_Xenia%20Logo.svg',
  PDF_INNER_BORDER_COLOR: '#dde2eb',
  PDF_OUTER_BORDER_COLOR: '#babfc7',
  PDF_PAGE_ORITENTATION: 'landscape',
  PDF_WITH_HEADER_IMAGE: true,
  PDF_WITH_FOOTER_PAGE_COUNT: true,
  PDF_HEADER_HEIGHT: 15,
  PDF_ROW_HEIGHT: 15,
  PDF_ODD_BKG_COLOR: '#fcfcfc',
  PDF_EVEN_BKG_COLOR: '#fff',
  PDF_WITH_CELL_FORMATTING: true,
  PDF_WITH_COLUMNS_AS_LINKS: true,
  PDF_SELECTED_ROWS_ONLY: true,
};

interface ExportDropdownProps {
  customRenderer?: ReactNode;
  popperProps?: any;
  buttonProps?: any;
  onlySelected?: boolean;
}

function ExportDropdown({
  customRenderer,
  popperProps = {},
  buttonProps,
  onlySelected = false,
}: ExportDropdownProps) {
  const userProfile: any = useSelector(selectors.getUserProfile);
  const orgHotelName = useSelector(selectors.getPropertyName);
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;
  const getParams = () => {
    return {
      onlySelected: onlySelected,
      fileName: `${orgHotelName}_Work_Order_Report_${DateTime.fromTodayInTz(
        tz,
      ).toTzString(tz, 'MM-DD-YY')}`,
      columnKeys: globalThis.taskListGrid.columnApi
        .getColumnState()
        .filter(
          (c) => !['task_checkbox', 'actionCol'].includes(c.colId) && !c.hide,
        )
        .map((c) => c.colId),
      rowHeight: (params) =>
        params.rowIndex === 1 ? 40 : params.rowIndex < 6 ? 30 : 24,
      addImageToCell: (rowIndex, col, value) => {
        if (rowIndex !== 1) {
          return;
        }
        return {
          image: {
            id: 'logo',
            base64: value,
            imageType: 'png',
            width: 139,
            height: 28,
            position: {
              colSpan: 2,
              offsetY: 5,
              offsetX: 5,
            },
          },
        };
      },
      shouldRowBeSkipped: (params: ShouldRowBeSkippedParams) => {
        return (
          !!params.node.group ||
          params.node.data?.addTask ||
          !params.node.data?.id
        );
      },
      prependContent: [
        {
          cells: [
            {
              data: {
                type: 'String',
                value: xeniaLogoBase64.Logo,
              },
              mergeAcross: 10,
            },
          ],
        },
        {
          cells: [
            {
              data: {
                value: `Organization: ${userProfile?.Hotel?.name}`,
                type: 'String',
              },
              mergeAcross: 10,
              styleId: 'orgName',
            },
          ],
        },
        {
          cells: [
            {
              data: {
                value: 'Task & Work Orders Data Export',
                type: 'String',
              },
              mergeAcross: 10,
            },
          ],
        },
      ],
    };
  };
  const handleExport = (option) => {
    switch (option.id) {
      case 'exportToExcel': {
        globalThis.taskListGrid.api.exportDataAsExcel(getParams());
        break;
      }
      case 'exportToPdf': {
        printDoc(
          printParams,
          globalThis?.taskListGrid?.api,
          globalThis?.taskListGrid?.columnApi,
          orgHotelName,
          tz,
        );
      }
    }
  };
  return (
    <ActionDropdown
      options={ExportDropdownOptions}
      handleOptionSelect={handleExport}
      buttonProps={
        buttonProps ?? {
          id: 'columnSelectionDropdown',
          variant: 'text',
          style: {
            padding: '6px 8px',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '22px',
            letterSpacing: '0.14px',
            height: 30,
            marginRight: '-5px',
          },
          sx: {
            '& .MuiButton-startIcon': {
              marginRight: '2px',
              height: 18,
              width: 18,
              alignItems: 'center',
            },
          },
          startIcon: (
            <SaveAltOutlined
              sx={{
                color: 'rgba(117, 117, 117, 1) !important',
                width: 16,
                height: 16,
              }}
            />
          ),
        }
      }
      iconButton={false}
      popperProps={popperProps}
    >
      {customRenderer ?? 'Export'}
    </ActionDropdown>
  );
}

export default ExportDropdown;
