import styled from '@mui/system/styled';
import { display } from '@mui/system';

export const HeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 0 12px 0',
  position: 'relative',
  '.lefCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  '.rightCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
}));
