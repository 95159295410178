// MUI components
import { styled } from '@mui/material/styles';
import { Autocomplete, Typography } from '@mui/material';

import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    margin: 0,
    width: '680px',
    position: 'absolute',
    top: '5%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const ModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'flex-start',
  marginBottom: '26px',
  justifyContent: 'space-between',
});

export const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ type }) => ({
  fontSize: type === 'heading' ? '20px' : '14px',
  fontWeight: type === 'heading' ? 700 : 400,
  lineHeight: type === 'heading' ? '32px' : '24px',
  letterSpacing: type === 'heading' ? '-0.25px' : '0.15px',
  textAlign: 'left',
  color: type === 'heading' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.38)',
}));

export const Row = styled('div')({
  display: 'flex',
  gap: '14px',
  marginBottom: '24px',
  height: '40px',
});

export const IconPickerWrapper = styled('div')({
  //   display: 'flex',
  width: '40px',
  height: '40px',
});

export const ButtonsRow = styled('div')({
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',
});

export const AutocompleteWrapper = styled('div')({
  flex: 1,
});

export const ImageRow = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

export const ImageAndRemoveWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '218px',
  height: '164px',
  padding: '16px',
  borderRadius: '18px',
  border: '1px dashed rgba(0, 0, 0, 0.54)',
  marginBottom: '32px',
  gap: '8px',
});

export const ImageContainer = styled('div')({
  justifyContent: 'center',
  height: '104px',
  width: '186px',
  borderRadius: '8px',
  overflow: 'hidden',
});

export const Image = styled('img')({
  objectFit: 'cover',
});

export const RemoveWrapper = styled('div')({
  display: 'flex',
  borderRadius: '8px',
  gap: '8px',
  width: '100%',
});

export const RemoveText = styled(Typography)({
  color: 'rgba(239, 83, 80, 1)',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  cursor: 'pointer',
});
