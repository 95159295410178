import pdfIcon from 'public/assets/img/file-types/pdf.svg';
import sopIcon from 'public/assets/img/file-types/sop.svg';
import wordIcon from 'public/assets/img/file-types/word.svg';
import excelIcon from 'public/assets/img/file-types/excel.svg';
import pptIcon from 'public/assets/img/file-types/ppt.svg';
import videoIcon from 'public/assets/img/file-types/video.svg';
import imageIcon from 'public/assets/img/file-types/image.svg';
import { styled } from '@mui/material';

export const StyledFileTypeIcon = styled('img')({
  width: 20,
  height: 20,
});

export const fileTypesData = [
  {
    label: 'Show All',
    value: 'all',
    id: 'all',
    listItemProps: {
      sx: {
        borderBottom: '1px solid #00000014',
      },
    },
  },
  {
    label: 'PDF',
    value: 'pdf',
    id: 'pdf',
    startIcon: <StyledFileTypeIcon src={pdfIcon} />,
  },
  {
    label: 'Xenia SOP Document',
    value: 'sop',
    id: 'sop',
    startIcon: <StyledFileTypeIcon src={sopIcon} />,
  },
  {
    label: 'Word',
    value: 'word',
    id: 'word',
    startIcon: <StyledFileTypeIcon src={wordIcon} />,
  },
  {
    label: 'Excel',
    value: 'excel',
    id: 'excel',
    startIcon: <StyledFileTypeIcon src={excelIcon} />,
  },
  {
    label: 'PPT',
    value: 'ppt',
    id: 'ppt',
    startIcon: <StyledFileTypeIcon src={pptIcon} />,
  },
  {
    label: 'Video',
    value: 'video',
    id: 'video',
    startIcon: <StyledFileTypeIcon src={videoIcon} />,
  },
  {
    label: 'Image',
    value: 'image',
    id: 'image',
    startIcon: <StyledFileTypeIcon src={imageIcon} />,
  },
];
