import { Box, styled } from '@mui/material';

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(100vh - 275px)',
  height: '100%',
  width: '100%',
});

export const Date = styled('span')({
  fontSize: 13,
  fontWeight: 700,
  color: '#000000',
  lineHeight: '17.76px',
  padding: '12px 0px 16px 0px',
  display: 'block',
  backgroundColor: 'white',
  position: 'relative',
  '::before': {
    content: "''",
    position: 'absolute',
    right: 0,
    top: 19,
    width: 'calc(100% - 175px)',
    height: '1px',
    background:
      'linear-gradient(90deg, #E0E0E0 61.5%, rgba(224, 224, 224, 0) 100%)',
  },
});

export const Container = styled('div')(() => ({
  '& .vertical-timeline-element-icon': {
    borderRadius: 0,
    boxShadow: 'unset',

    '& div': {
      borderBottom: '1px solid white',
      borderTop: '1px solid white',
    },
  },

  '& .vertical-timeline-element--work': {
    margin: 0,
  },

  '& .vertical-timeline-element-content': {
    padding: 0,
    boxShadow: 'unset',
  },
  '& .vertical-timeline': {
    padding: 0,
    width: '100%',
    maxWidth: 'unset',
    '&:before': {
      background: '#E0E0E0',
      left: 15,
      top: 50,
      width: 1,
    },
  },

  '& .time': {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16.39px',
    color: '#424242',
    marginTop: 0,
  },

  '& .content': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: 'black',
    marginTop: 1,
  },
}));

export const ListingCnt = styled(Box)({ height: 'calc(100vh - 275px)' });
