import React from 'react';

import { DialogCnt, Header, AlertWrapper, AlertText } from './styled';

import CloseRounded from '@mui/icons-material/CloseRounded';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const DialogWrapper = ({ children, handleClose }) => {
  return (
    <DialogCnt>
      <Header>
        <CloseRounded onClick={handleClose} />
      </Header>
      {children}
      <AlertWrapper>
        <ReportProblemOutlinedIcon />
        <AlertText>
          Hidden steps will not appear in future submissions and are hidden in
          this Template’s Spreadsheet View
        </AlertText>
      </AlertWrapper>
    </DialogCnt>
  );
};

export default DialogWrapper;
