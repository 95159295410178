import moment from 'moment/moment';
import { CustomToolbarCnt } from 'pages/task/TaskListView/TaskCalendar/taskCalendar.style';
import { IconButton } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import CalendarViewSwitch from 'pages/task/TaskListView/TaskCalendar/viewSwitch/viewSwitch';
import React from 'react';
import DateTime from 'utils/DateTime';
import { useSelector } from 'react-redux';
import { getUserHotelTz } from 'store/selectors/auth';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import ArrowLeft from 'components/Icons/arrowLeft';
import ArrowRight from 'components/Icons/arrowRight';
import useDateTime from 'utils/CustomHooks/useDateTime';

const CustomToolbar = (toolbar, currentView, handleViewSwitch) => {
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;
  const { isSameDay, getUtcToTz } = useDateTime();
  const goToBack = () => {
    const mDate = toolbar.date;
    let newDate;
    switch (currentView) {
      case 'month':
        {
          newDate = DateTime.fromTzString(mDate, tz)
            .toTz(tz)
            .transform({ subtract: [1, 'month'], startOf: 'day' })
            .toTzString(tz);
        }
        break;
      case 'day':
        {
          newDate = DateTime.fromTzString(mDate, tz)
            .toTz(tz)
            .transform({ subtract: [1, 'day'], startOf: 'day' })
            .toTzString(tz);
        }
        break;
      case 'agenda':
        {
          newDate = DateTime.fromTzString(mDate, tz)
            .toTz(tz)
            .transform({ subtract: [1, 'day'], startOf: 'day' })
            .toTzString(tz);
        }
        break;
      case 'week':
        {
          newDate = DateTime.fromTzString(mDate, tz)
            .toTz(tz)
            .transform({ subtract: [1, 'week'], startOf: 'day' })
            .toTzString(tz);
        }
        break;
      default:
        return false;
    }
    toolbar.onNavigate('prev', newDate);
  };

  const goToNext = () => {
    const mDate = toolbar.date;
    let newDate;
    switch (currentView) {
      case 'month':
        {
          newDate = DateTime.fromTzString(mDate, tz)
            .toTz(tz)
            .transform({ add: [1, 'month'], startOf: 'day' })
            .toTzString(tz);
        }
        break;
      case 'day':
        {
          newDate = DateTime.fromTzString(mDate, tz)
            .toTz(tz)
            .transform({ add: [1, 'day'], startOf: 'day' })
            .toTzString(tz);
        }
        break;
      case 'agenda':
        {
          newDate = DateTime.fromTzString(mDate, tz)
            .toTz(tz)
            .transform({ add: [1, 'day'], startOf: 'day' })
            .toTzString(tz);
        }
        break;
      case 'week':
        {
          newDate = DateTime.fromTzString(mDate, tz)
            .toTz(tz)
            .transform({ add: [1, 'week'], startOf: 'day' })
            .toTzString(tz);
        }
        break;
      default:
        return false;
    }
    toolbar.onNavigate('prev', newDate);
  };

  const goToCurrent = () => {
    const now = new DateTime()
      .toTz(tz)
      .transform({ startOf: 'day' })
      .toTzString(tz);
    toolbar.onNavigate('current', now);
  };
  const day = getUtcToTz(toolbar.date).format('ddd');
  const dayNumber = getUtcToTz(toolbar.date, 'DD');
  return (
    <CustomToolbarCnt>
      {currentView !== 'day' ? (
        <div className="calendarNavigateCnt">
          <span className="toolbarDate">{toolbar.label}</span>
          <IconButton className={'arrowBtn'} onClick={goToBack}>
            <ArrowLeft sx={{ fontSize: 10, color: '#6868FE' }} />
          </IconButton>
          <IconButton className={'arrowBtn'} onClick={goToNext}>
            <ArrowRight sx={{ fontSize: 10, color: '#6868FE' }} />
          </IconButton>
        </div>
      ) : (
        <div className="calendarDayNavigateCnt">
          <div className={'dateCnt'}>
            <span className="day">{day}</span>
            <span className="dayNumber">{dayNumber}</span>
          </div>
          <div className={'arrowsCnt'}>
            <IconButton className={'arrowBackBtn'} onClick={goToBack}>
              <ArrowLeft sx={{ fontSize: 10, color: '#6868FE' }} />
            </IconButton>
            <IconButton className={'arrowNextBtn'} onClick={goToNext}>
              <ArrowRight sx={{ fontSize: 10, color: '#6868FE' }} />
            </IconButton>
          </div>
        </div>
      )}
      <div className="CalendarSettingCnt">
        <CalendarViewSwitch handleViewSwitch={handleViewSwitch} />
      </div>
    </CustomToolbarCnt>
  );
};

export default CustomToolbar;
