import { MainCnt, MainText, Details } from './noRecordsFound.style';

const NoRecordsFound = () => {
  return (
    <MainCnt>
      <img src={'/assets/img/create-assign.png'} />{' '}
      <MainText>No Categories</MainText>
      <Details>
        Categories are used to organize tasks and work orders, classify common
        work orders and deliver insights for reporting. You can create unlimited
        categories, customize their colors, and filter your task boards on them.
      </Details>
    </MainCnt>
  );
};

export default NoRecordsFound;
