// React
import { useState, useEffect, useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Context
import AttachChecklistContext from 'components/AttachChecklistModal/context';

// Components
import Header from 'components/AttachChecklistModal/Header';
import Preview from 'components/AttachChecklistModal/Preview';
import NoChecklist from 'components/AttachChecklistModal/NoChecklist';

// Utils
import DIALOGS from 'utils/dialogIds';

// Styled
import {
  StyledCustomModal,
  ModalWrapper,
  Body,
  MainLoaderCnt,
  NoTemplateSelected,
} from './styled';
import { getChecklistListing, getTemplateFolders } from 'api/checklistV2Api';
import FoldersList from 'components/AttachChecklistModal/FoldersList';
import { cloneDeep, groupBy } from 'lodash';
import { CircularProgress } from '@mui/material';
import NoTemplateSelectedImg from 'public/assets/img/no-template-selected.svg';
import CTypography from 'components/CTypography';
import TemplatesList from './TemplateList';
import { getFoldersCall } from 'api/documents';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import { BreadCrumbsCnt } from 'components/AttachChecklistModal/TemplateList/style';

const AttachChecklistModal = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const [folders, setFolders] = useState<any>({
    isLoading: true,
    data: null,
  });
  const [templates, setTemplates] = useState<any>({
    isLoading: true,
    data: null,
  });

  const dialogId = DIALOGS.ATTACH_CHECKLIST;
  const modalState = dialogState?.[dialogId];

  const [activeChecklist, setActiveChecklist] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedFolderTemplates, setSelectedFolderTemplates] =
    useState<any>(null);
  const [foldersHeight, setFoldersHeight] = useState(0);

  // Get Folders and Templates Data
  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    getFolders();
    getTemplates();
  };

  const getFolders = async () => {
    const foldersList = await getTemplateFolders({});
    const foldersWithChecklists = foldersList?.data?.filter(
      (item) => item.itemsCount != 0,
    );
    setFolders({ ...folders, data: foldersWithChecklists });
  };
  const getTemplates = async () => {
    const response = await getChecklistListing({
      detailed: true,
      archived: false,
    });

    const groupedTemplates = groupBy(response?.data, 'HotelChecklist.FolderId');
    setTemplates({ ...templates, data: groupedTemplates });
  };

  useEffect(() => {
    const isDataReady =
      folders?.data &&
      templates?.data &&
      templates?.isLoading &&
      folders?.isLoading;

    if (isDataReady) {
      const foldersList = cloneDeep(folders.data);
      const foldersWithChecklists = foldersList.map((folder) => {
        folder.checklists = templates?.data?.[folder?.id];
        return folder;
      });

      setFolders({
        isLoading: false,
        data: foldersWithChecklists,
      });

      setTemplates({
        isLoading: false,
        data: templates?.data?.['null'] || [],
      });
    }
  }, [folders, templates]);

  const folderClickHandler = (folder) => {
    setSelectedFolderTemplates(folder);
    setActiveChecklist(null);
  };

  const backToChecklistHome = () => {
    setSelectedFolderTemplates(null);
    setActiveChecklist(null);
  };

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  const handleClickAttach = (checklist) => {
    modalState?.data?.onClickAttach(checklist);
    handleClose();
  };

  // By Default Preview Selection
  useEffect(() => {
    if (modalState?.data?.searchTerm) {
      setSearchTerm(modalState?.data?.searchTerm);
    }
  }, [selectedFolderTemplates, templates, modalState?.data]);

  useEffect(() => {
    !templates?.isLoading && setActiveChecklist(templates?.data?.[0]);
  }, [templates]);

  // Root Templates Filtering
  const filteredTemplates = useMemo(() => {
    if (templates?.isLoading) {
      return [];
    }
    const filtered = templates?.data?.filter((checklist) =>
      checklist?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );

    return filtered?.length ? filtered : templates?.data;
  }, [templates, searchTerm, selectedFolderTemplates]);

  // Folders Filtering
  const filteredFolders = useMemo(() => {
    if (folders?.isLoading) {
      return [];
    }

    const filtered = folders?.data?.filter((folder) =>
      folder?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );

    return filtered?.length ? filtered : folders?.data;
  }, [folders, searchTerm, selectedFolderTemplates]);

  // Templates Inside Folders Filtering
  const filteredFolderTemplates = useMemo(() => {
    if (!selectedFolderTemplates) {
      return null;
    }

    const filtered = selectedFolderTemplates?.checklists?.filter((checklist) =>
      checklist?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );

    return filtered?.length
      ? {
          ...selectedFolderTemplates,
          checklists: filtered,
        }
      : selectedFolderTemplates;
  }, [selectedFolderTemplates, searchTerm]);

  // Calculate Height
  const reCalculateHeight = (foldersHeight) => {
    setFoldersHeight(foldersHeight);
  };
  const foldersData = selectedFolderTemplates
    ? filteredFolders.filter(
        (folder) => folder.ParentId == selectedFolderTemplates.id,
      )
    : filteredFolders.filter((folder) => !folder.ParentId);
  const templatesData = selectedFolderTemplates
    ? filteredTemplates.filter(
        (temp) => temp.parentId == selectedFolderTemplates.id,
      )
    : filteredTemplates.filter((temp) => !temp.parentId);
  const state = {
    activeChecklist,
    searchTerm,
    setSearchTerm,
    setActiveChecklist,

    folders: foldersData,
    rootTemplates: templatesData,
    selectedFolderTemplates,
  };

  const loading = folders?.isLoading || templates?.isLoading;
  const haveData = folders?.data?.length > 0 || templates?.data?.length > 0;

  return (
    <AttachChecklistContext.Provider value={state}>
      <StyledCustomModal handleClose={handleClose} open={modalState?.open}>
        <ModalWrapper>
          <Header />
          <Body>
            {loading && (
              <MainLoaderCnt>
                <CircularProgress />
              </MainLoaderCnt>
            )}

            {!loading && !haveData && <NoChecklist />}

            {!loading && haveData && (
              <>
                <div>
                  {selectedFolderTemplates ? (
                    <div style={{ display: 'flex', padding: '16px' }}>
                      <BreadCrumbsCnt
                        selectedFolder={selectedFolderTemplates ?? true}
                      >
                        <CTypography
                          variant={'h1'}
                          className={'heading'}
                          onClick={backToChecklistHome}
                        >
                          Folders
                        </CTypography>
                        {selectedFolderTemplates?.path?.map((f, index) => {
                          return (
                            <>
                              <ArrowRightIcon
                                style={{
                                  color: '#757575',
                                  margin: '0px 6px',
                                  fontSize: 11,
                                }}
                              />
                              <div className={'folderNameCnt'}>
                                <TemplateFolderIcon
                                  primaryColor={f?.meta?.primaryColor}
                                  secondaryColor={f?.meta?.secondaryColor}
                                />
                                <CTypography
                                  variant={'h1'}
                                  className={'folderName'}
                                  onClick={() => folderClickHandler(f)}
                                >
                                  {f?.title}
                                </CTypography>
                              </div>
                            </>
                          );
                        })}
                      </BreadCrumbsCnt>
                      <BreadCrumbsCnt
                        selectedFolder={selectedFolderTemplates ?? true}
                      >
                        <ArrowRightIcon
                          style={{
                            color: '#757575',
                            margin: '0px 6px',
                            fontSize: 11,
                          }}
                        />
                        <div className={'folderNameCnt'}>
                          <TemplateFolderIcon
                            primaryColor={
                              selectedFolderTemplates?.meta?.primaryColor
                            }
                            secondaryColor={
                              selectedFolderTemplates?.meta?.secondaryColor
                            }
                          />
                          <CTypography variant={'h1'} className={'folderName'}>
                            {selectedFolderTemplates?.title}
                          </CTypography>
                        </div>
                      </BreadCrumbsCnt>
                    </div>
                  ) : null}
                  {foldersData?.length > 0 ? (
                    <>
                      <FoldersList
                        folderClickHandler={folderClickHandler}
                        reCalculateHeight={reCalculateHeight}
                      />
                    </>
                  ) : null}
                  {selectedFolderTemplates ? (
                    <TemplatesList
                      onClickItem={(checklist) => setActiveChecklist(checklist)}
                      onClickAttach={handleClickAttach}
                      activeChecklist={activeChecklist}
                      selectedFolderTemplates={filteredFolderTemplates}
                      reCalculateHeight={reCalculateHeight}
                      id="foldersTemplates"
                      backToChecklistHome={backToChecklistHome}
                    />
                  ) : null}

                  {templatesData?.length > 0 && (
                    <TemplatesList
                      onClickItem={(checklist) => setActiveChecklist(checklist)}
                      onClickAttach={handleClickAttach}
                      isLoading={templates?.isLoading}
                      activeChecklist={activeChecklist}
                      rootTemplates={filteredTemplates}
                      foldersHeight={foldersHeight}
                      reCalculateHeight={reCalculateHeight}
                      id="templates"
                    />
                  )}
                </div>

                {!loading &&
                  !selectedFolderTemplates &&
                  templatesData?.length === 0 && (
                    <NoTemplateSelected>
                      <img src={NoTemplateSelectedImg} alt="No Template" />
                      <CTypography className="text">
                        Select an operation template to preview here
                      </CTypography>
                    </NoTemplateSelected>
                  )}
                {!loading && activeChecklist && (
                  <Preview
                    checklist={activeChecklist}
                    onClickAttach={handleClickAttach}
                  />
                )}
              </>
            )}
          </Body>
        </ModalWrapper>
      </StyledCustomModal>
    </AttachChecklistContext.Provider>
  );
};

export default AttachChecklistModal;
