// React
import { useContext } from 'react';

// Custom components
import SearchInput from 'components/Form/TextField/SearchField.cmp';
import FiltersDropdown from 'components/TaskAdvanceFilters/FiltersDropdown';
import ListGridToggle from 'components/ListGridToggle';

// Styles
import { HeaderCnt, ToggleContainer } from './header.style';

// Context
import { ManageSchedulesCtx } from 'pages/Schedules/ManageSchedules/context';

// Utils
import AddScheduleBtn from 'pages/Schedules/common/Header/addScheduleBtn';
import useHeader from 'pages/Schedules/common/useHeader';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { HasPermission } from 'components/HasPermission';
import { PERMISSIONS } from 'utils/constants';

interface MySchedulesHeaderPropTypes {
  createTaskCallback?: () => void;
}

function ManageSchedulesHeader({
  createTaskCallback,
}: MySchedulesHeaderPropTypes) {
  const { isMobileDeviceSm } = useResponsive();
  const {
    advanceFilters,
    setAdvanceFilters,
    searchTerm,
    handleChangeSearch,
    selectedView,
    setSelectedView,
  } = useContext(ManageSchedulesCtx);
  const { handleSetAdvanceFilters } = useHeader();

  return (
    <HeaderCnt>
      <div className={'lefCnt'}>
        <SearchInput
          fieldProps={{
            value: searchTerm,
            onChange: (e) => handleChangeSearch(e),
          }}
          sx={{ maxWidth: { sm: 'calc(100vw - 140px)', md: 250 } }}
        />
        <FiltersDropdown
          selectedFilters={advanceFilters}
          onChange={(filters) =>
            handleSetAdvanceFilters(filters, setAdvanceFilters)
          }
          hideFilters={
            'taskStatuses futureStartDate futureDueDate completedBy taskTemplates priority createdBy scheduleFrequency projects'
          }
          handleClearFilters={() =>
            handleSetAdvanceFilters(undefined, setAdvanceFilters)
          }
        />
      </div>
      {!isMobileDeviceSm && (
        <ToggleContainer>
          <ListGridToggle
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
        </ToggleContainer>
      )}{' '}
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
        <AddScheduleBtn createTaskCallback={createTaskCallback} />
      </HasPermission>
    </HeaderCnt>
  );
}

export default ManageSchedulesHeader;
