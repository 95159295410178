import { Box, Stack, styled } from '@mui/material';

export const HeaderCnt = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '10px 20px 0px 20px',
}));

export const ProjectAccessControl = styled(Box)(() => ({
  borderRadius: 6,
  border: '1px solid #BDBDBD',
  padding: '6px 0px 6px 0px',
  height: 36,
  width: 36,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  cursor: 'pointer',
}));
