// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const PreviewTabWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 'calc(100vh - 155px)',
}));

export const TemplatePreviewContainer = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  width: '800px',
  height: '100%',
  overflow: 'auto',
  paddingTop: theme.spacing(2),
}));

export const WidgetContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 0, 2, 2),
  height: '100%',
  overflow: 'auto',
  gap: theme.spacing(2),
  flex: 1,
}));
