import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import EditIcon from 'components/Icons/editIcon';
import CustomToolTip from 'components/Tooltip/tooltip';
import DeleteIcon from 'components/Icons/deleteIcon';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import { colors } from 'components/theme/constants';
import selectors from 'store/selectors';
import UserAvatar from 'components/Avatar';
import DIALOGS from 'utils/dialogIds';
import useTasksTemplates from 'utils/CustomHooks/useTasksTemplates';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { setDialog } from 'store/actions/dialogActions';
import TaskIcon from '../../../Icons/sidebarIcons/taskIcon';

import {
  CreatorName,
  DescriptionContent,
  DueDateContent,
  ItemWrapper,
  Title,
  TaskIconCnt,
} from './style';
import {
  DottedContainer,
  DottedText,
} from 'components/Dropdown/Task/Location/taskLocationDropdown.style';
import { cloneDeep } from 'lodash';

export const TitleRenderer = ({ title }) => {
  return (
    <CustomToolTip
      PopperProps={{
        sx: {
          pointerEvents: 'none',
          zIndex: 1300,
          '& .MuiTooltip-tooltip': {
            boxShadow:
              'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
            minWidth: 100,
            maxHeight: 300,
            overflowY: 'auto',
            borderRadius: '15px',
          },
        },
      }}
      title={
        <DottedContainer>
          <DottedText>{title}</DottedText>
        </DottedContainer>
      }
    >
      <ItemWrapper>
        <TaskIconCnt>
          <TaskIcon />
        </TaskIconCnt>
        <Title>{title}</Title>
      </ItemWrapper>
    </CustomToolTip>
  );
};

const CreatedBy = ({ userId }: { userId: string }) => {
  const hashedUsers = useSelector(selectors.getHashedUserList);
  const user = hashedUsers?.[userId];
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
  return (
    <>
      <UserAvatar width={24} height={24} userId={userId} />
      <CreatorName style={{ marginLeft: 10 }}>{fullName}</CreatorName>
    </>
  );
};

export const CreatedByRenderer = ({ createdBy }) => {
  return (
    <ItemWrapper>
      <CreatedBy userId={createdBy?.id} />
    </ItemWrapper>
  );
};

export const DueDateRenderer = ({ dueDate }) => {
  return (
    <ItemWrapper>
      {dueDate?.value && dueDate?.unit ? (
        <DueDateContent>
          {`${dueDate?.value} ${dueDate?.unit}${
            dueDate?.value > 1 ? 's' : ''
          } after task is created`}
        </DueDateContent>
      ) : (
        <DueDateContent>-</DueDateContent>
      )}
    </ItemWrapper>
  );
};

export const DescriptionRenderer = ({ description }) => {
  return (
    <ItemWrapper>
      <DescriptionContent>{description || '-'}</DescriptionContent>
    </ItemWrapper>
  );
};

export const ActionsRender = (props) => {
  const { addNewAssignees } = useAssigneesUtils();
  const categoryList = useSelector(selectors.getServiceAdmin);

  const templatesActionDropdownOptions = [
    {
      label: 'Edit Template',
      id: 'edit',
      icon: <EditIcon style={{ height: 20, width: 20 }} />,
      iconAlign: 'start',
    },
    {
      label: 'Delete Template',
      id: 'delete',
      icon: (
        <DeleteIcon
          style={{ color: colors.red, fill: 'none', height: 18, width: 18 }}
        />
      ),
      sx: {
        color: `${colors.red}`,
      },
      iconAlign: 'start',
    },
  ];

  const { template, actions } = props;
  const { editSuccessHandler, deleteSuccessHandler } = actions;

  const formattedTemplate = useMemo(() => {
    const copiedTemplate = cloneDeep(template);
    if (copiedTemplate) {
      delete copiedTemplate?.templateTitle;
      delete copiedTemplate?.templateDescription;
      delete copiedTemplate?.dueAfterCreationTime;

      const assignees = copiedTemplate?.Assignees?.map(
        (item) => item?.AssigneeId,
      );

      const ServiceType = categoryList?.find(
        (c) => c.id == copiedTemplate?.ServiceTypeId,
      );

      return {
        ...copiedTemplate,
        TaskAssignees: addNewAssignees(assignees),
        ServiceType,
      };
    }
  }, [template]);

  const { deleteTasksTemplate } = useTasksTemplates();
  const dispatch = useDispatch();

  const deleteConfirmCallback = async () => {
    await deleteTasksTemplate(template?.id);
    deleteSuccessHandler?.(template?.id);
  };

  const nextHandler = (data, templateInfo) => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'editTask',
      }),
    );

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG,
        data: {
          taskTemplate: {
            metaData: data,
            templateInfo: templateInfo,
          },
          isEdit: true,
          onSuccessCallBack: editSuccessHandler,
        },
      }),
    );
  };

  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'edit':
        {
          dispatch(
            setDialog({
              open: true,
              dialogId: 'editTask',
              data: {
                dialogState: {
                  taskTemplate: {
                    isEdit: true,
                  },
                  viewLess: false,
                  hideElements: {
                    dueDate: true,
                    schedule: true,
                    isRequiredChecklistCheckbox: true,
                  },
                  removeSubmitValidation: true,
                },
                actionBtn: {
                  text: 'Next',
                  clickHandler: nextHandler,
                },
                taskData: formattedTemplate,
                taskTemplateInfo: {
                  ...(template?.dueAfterCreationTime && {
                    dueAfterCreationTime: template?.dueAfterCreationTime,
                  }),
                  ...(template?.templateTitle && {
                    templateTitle: template?.templateTitle,
                  }),
                  ...(template?.templateDescription && {
                    templateDescription: template?.templateDescription,
                  }),
                  ...(template?.config && {
                    config: template?.config,
                  }),
                },
              },
            }),
          );
        }
        break;

      case 'delete':
        {
          dispatch(
            setDialog({
              dialogId: DIALOGS.CONFIRMATION,
              open: true,
              data: {
                confirmButtonProps: {
                  style: {
                    backgroundColor: '#D32F2F',
                  },
                },
                confirmationText: 'Delete',
                title: 'Delete Task Template',
                description:
                  'It will also delete the automations linked with this template in all checklists. So are you sure you want to delete this task template?',
                onConfirmCallback: deleteConfirmCallback,
              },
            }),
          );
        }
        break;
    }
  };

  return (
    <ActionDropdown
      options={templatesActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { transform: 'rotate(90deg)' },
      }}
      popperProps={{
        sx: {
          zIndex: 1301,
        },
        placement: 'bottom-start',
      }}
      paperProps={{
        style: {
          minWidth: 140,
        },
      }}
    >
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
};
