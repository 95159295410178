import styled from '@mui/system/styled';
import { Dialog } from '@mui/material';

export const CustomDrawerInnerCnt = styled('div')({
  position: 'relative',
  width: 600,
  height: '100%',
  '& .btnActionCnt': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '12px 24px',
    marginTop: 20,
  },
  '& h2': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '28px',
  },
  '& .headerCnt': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px 16px 24px',
    marginBottom: 8,
  },
  '& .teamsDrawerContentCnt': {
    padding: '0 24px',
    '& input': {
      '&::placeholder': {
        color: '#858585 !important',
        opacity: 1,
      },
    },

    '& label': {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '17.76px',
      color: '#424242',
      marginBottom: 6,
    },
    '& .title': {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '17.76px',
      color: '#424242',
      marginBottom: 6,
    },
  },
});
export const TeamsDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    overflowY: 'visible',
  },
}));

export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
});
