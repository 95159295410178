import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';
import { colors } from 'components/theme/constants';

export const Main = styled('div')({
  height: '100%',
});

export const Title = styled('div')({
  display: 'flex',
  gap: 10,
  marginBottom: 15,
  '& svg': {
    color: '#CCCBFF',
  },
  '& p': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28.8px',
    letterSpacing: '0.15px',
    color: colors.black,
  },
});

export const InviteandBoost = styled('div')({
  height: '100%',
  '& .MuiGrid-item': {
    padding: '0px 10px',
  },
  '& .box': {
    background: colors.white,
    border: '1px solid #E0E0E0',
    borderRadius: 10,
    height: 'inherit',
    padding: 20,
    position: 'relative',

    '& .title': {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '160%',
      letterSpacing: '0.15px',
      color: colors.black,
    },
    '& .subtitle': {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '160%',
      letterSpacing: '0.15px',
      color: colors.black,
      marginBottom: 10,
      maxWidth: 300,
    },
    '& button': {
      position: 'absolute',
      bottom: 90,
    },
  },
});

export const TemplateGrid = styled('div', {
  shouldForwardProp: (prop) => prop !== 'gap',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  // gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '16px',
  // justifyContent: 'space-evenly',
  // [theme.breakpoints.down('lg')]: {
  //   gridTemplateColumns: 'repeat(3, 1fr)',
  // },
  // [theme.breakpoints.down('md')]: {
  //   gridTemplateColumns: 'repeat(2, 1fr)',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   gridTemplateColumns: 'repeat(1, 1fr)',
  // },
}));

export const TemplateRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 38,
  padding: 4,
  borderRadius: 6,
  '&:hover': {
    backgroundColor: '#EBEAFF',
    cursor: 'pointer',
    '& .secondColumn': {
      '& .logs': {
        display: 'none',
      },
      '& .openTemplate': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
      },
    },
  },
  '& .firstColumn': {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 140px)',
    '& p': {
      marginLeft: 8,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20.02px',
      letterSpacing: '0.17px',
      color: colors.black,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  '& .secondColumn': {
    '& .logs': {
      border: '1px solid #E0E0E0',
      borderRadius: 62,
      padding: 10,
      minWidth: 105,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '17.16px',
        letterSpacing: '0.17px',
        color: colors.black,
      },
    },
    '& .openTemplate': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '17.16px',
      letterSpacing: '0.17px',
      display: 'none',
      color: '#6868FE',
      border: '1px solid #6868FE',
      borderRadius: 4,
      padding: '4px 12px 4px 12px',
    },
  },
});

export const MainCnt = styled('div')({
  padding: 20,
  height: '100%',
  border: '1px solid #E0E0E0',
  borderRadius: 10,
  overflow: 'hidden',
});

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .title': {
    marginLeft: 7,
    color: colors.black,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28.8px',
    letterSpacing: '0.15px',
  },
  '& btn': {
    height: 37,
    borderRadius: 8,
  },
});

export const Content = styled('div')({
  height: 'calc(100% - 75px)',
  overflowY: 'auto',
  '& .customRow': {
    display: 'flex',
    justfiyContent: 'space-between',
    alignItems: 'center',
    '& .label': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20.02px',
      letterSpacing: '0.17px',
    },
  },
});

export const EmptySubmissionsTitle = styled(CTypography)({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '24.5px',
  textAlign: 'center',
  color: '#000000DE',
  margin: '5px 0px 10px 0px',
});

export const EmptySubmissionsStatement = styled(CTypography)({
  fontWeight: 400,
  fontSize: 15,
  lineHeight: '24px',
  color: '#000000',
  textAlign: 'center',
});

export const BoostImg = styled('img')({
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: 230,
});
