// MUI components
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: 80,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  marginBottom: 18,
  '& .empty-icon': {
    fontSize: 150,
  },
}));

export const BoldText = styled(Typography)(() => ({
  fontWeight: 600,
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: 20,
  letterSpacing: 0.15,
  marginBottom: 4,
}));

export const GreyText = styled(Typography)(() => ({
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: 16,
  letterSpacing: 0.15,
  width: 380,
  textAlign: 'center',
}));
