import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

const DashboardSkeleton = () => {
  return (
    <Stack spacing={3}>
      <div style={{ padding: '30px 70px' }}>
        <Skeleton
          variant="rectangular"
          height={40}
          style={{ marginBottom: 10 }}
        />

        <Skeleton
          variant="rectangular"
          height={130}
          style={{ marginBottom: 35 }}
        />

        <Skeleton
          variant="rectangular"
          height={20}
          width={200}
          style={{ marginBottom: 15 }}
        />
        <Grid container style={{ marginBottom: 35 }}>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangular"
              height={150}
              style={{ marginRight: 15 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangular"
              height={150}
              style={{ marginRight: 15 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangular"
              height={150}
              style={{ marginRight: 15 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangular"
              height={150}
              style={{ marginRight: 15 }}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 35 }}>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              height={300}
              style={{ marginRight: 15 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              height={300}
              style={{ marginRight: 15 }}
            />
          </Grid>
        </Grid>

        <Skeleton
          variant="rectangular"
          height={20}
          width={200}
          style={{ marginBottom: 15 }}
        />

        <Grid container style={{ marginBottom: 35 }}>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangular"
              height={50}
              style={{ marginRight: 15 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangular"
              height={50}
              style={{ marginRight: 15 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangular"
              height={50}
              style={{ marginRight: 15 }}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              variant="rectangular"
              height={50}
              style={{ marginRight: 15 }}
            />
          </Grid>
        </Grid>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div>
            <Skeleton
              variant="rectangular"
              height={20}
              width={200}
              style={{ marginBottom: 15 }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Skeleton
              variant="rectangular"
              height={40}
              width={100}
              style={{ marginRight: 10 }}
            />
            <Skeleton variant="rectangular" height={40} width={100} />
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default DashboardSkeleton;
