import { Box, Stack, Typography } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const InfoCnt = styled(Box)({
  padding: 20,
});

export const FormCnt = styled(Stack)(({ theme }) => ({
  padding: 20,
  width: 600,
  height: '100%',
  '& input': {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    '&::placeholder': {
      fontSize: 15,
      color: '#616161',
      lineHeight: '21.86px',
      fontWeight: 500,
      opacity: 0.7,
    },
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'unset',
    padding: 10,
    marginTop: 50,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: 10,
  },

  '& .logo': {
    width: 62,
    height: 38,
    marginBottom: 25,
  },

  '& .MuiFormHelperText-root': {
    marginBottom: 10,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: 32,
  lineHeight: '43.71px',
  color: '#000000',
  marginBottom: 5,

  [theme.breakpoints.down('sm')]: {
    fontWeight: 700,
    fontSize: 26,
    lineHeight: 1.3,
  },
}));

export const SubTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '24.58px',
  color: '#616161',
  marginBottom: 45,
});

export const EmailTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#212121',
  marginBottom: 5,
});

export const GetStartedBtn = styled(CustomButton)({
  width: '100%',
  borderRadius: '8px',
  height: 47,
  marginBottom: 20,
  fontSize: 16,
  fontWeight: 700,
});

export const LoginCnt = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 30,
  '& .alreadyLoggedIn': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '26px',
    color: '#000000',
  },

  '& .link': {
    fontWeight: 600,
    fontSize: 16,
    paddingLeft: 0,
    lineHeight: '26px',
    color: '#6868FE',
  },
});

export const TermsAndConditions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& .content': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '22px',
    color: '#616161',
  },

  '& .link': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '22px',
    color: '#4E48FA',
    textDecoration: 'underline',
  },
});
