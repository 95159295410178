import { find } from 'lodash';
import { UserStatusChip } from './style';
import { userStatusColors } from 'helper/user/userStatus';

export const getAvailabilityStatus = (
  UserAvailabilityStatuses = [],
  hotelId = null,
) => {
  let availabilityStatus: any = find(UserAvailabilityStatuses, {
    HotelId: hotelId,
  });
  availabilityStatus = availabilityStatus || { currentStatus: 'offline' };
  if (availabilityStatus.currentStatus === 'busy') {
    return { currentStatus: 'work in progress' };
  }
  return availabilityStatus;
};

export const sortUsers = (data) => {
  const sortedData = data?.sort((value1, value2) => {
    const user1Status = getAvailabilityStatus(
      value1?.obj?.UserAvailabilityStatuses,
      value1?.obj?.UserHotel?.HotelId,
    );
    const user2Status = getAvailabilityStatus(
      value2?.obj?.UserAvailabilityStatuses,
      value2?.obj?.UserHotel?.HotelId,
    );
    const indexes = ['available', 'online', 'team', 'busy', 'offline'];
    const index1: any = value1.isTeam
      ? indexes.indexOf('team')
      : indexes?.indexOf(user1Status?.currentStatus);
    const index2: any = value2.isTeam
      ? indexes?.indexOf('team')
      : indexes?.indexOf(user2Status?.currentStatus);
    return index1 - index2;
  });
  return sortedData ?? [];
};

export const filteredOptions = (data, searchQuery) => {
  const tabOptions = data;
  if (!searchQuery) {
    return tabOptions;
  }
  const optionsList = tabOptions.reduce((r, cv) => {
    r.push(cv);
    return r;
  }, []);
  const optionsAfterFilter = optionsList.filter((obj) => {
    return obj.label.toLowerCase().includes(searchQuery.toLowerCase());
  });
  return optionsAfterFilter;
};

export const filteredLocationsOptions = (data, searchQuery) => {
  const tabOptions = data;
  if (!searchQuery) {
    return tabOptions;
  }
  const optionsAfterFilter = tabOptions?.reduce((r, cv) => {
    const filteredChild =
      cv?.child?.filter((sl) =>
        sl?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
      ) || [];
    const doesChildMatch = !!filteredChild?.length || false;
    const parentMatch = cv?.label
      ?.toLowerCase()
      ?.includes(searchQuery?.toLowerCase());
    if (doesChildMatch || parentMatch) {
      r.push({ ...cv, child: filteredChild });
    }
    return r;
  }, []);

  return optionsAfterFilter;
};

export const StatusChip = ({ user }) => {
  const availabilityStatus = getAvailabilityStatus(
    user.obj.UserAvailabilityStatuses,
    user.obj?.UserHotel?.HotelId,
  );
  return (
    <UserStatusChip
      style={{
        background:
          userStatusColors?.[availabilityStatus?.currentStatus]?.background,
      }}
    >
      {availabilityStatus?.currentStatus}
    </UserStatusChip>
  );
};

export const sortTeams = (data) => {
  return data?.sort((team1, team2) => {
    return team1?.label?.toLowerCase() - team2?.label?.toLowerCase();
  });
};

export const sortLocations = (data) => {
  return data?.sort((loc1, loc2) => {
    return loc1?.label?.toLowerCase() - loc2?.label?.toLowerCase();
  });
};

export const getSelected = (data, selected) => {
  return data?.filter((item) => selected?.includes(item?.id));
};

export const getUnSelected = (data, selected) => {
  return data?.filter((item) => !selected?.includes(item?.id));
};

export const getSelectedLocations = (data, selected) => {
  return data.filter(
    (item) =>
      selected?.includes(item?.id) ||
      item?.child?.find((childItem) => selected?.includes(childItem?.id)),
  );
};
