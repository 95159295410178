import { createSvgIcon } from '@mui/material';

const VideoOutlinedIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M11 20.1668C16.0626 20.1668 20.1667 16.0628 20.1667 11.0002C20.1667 5.93755 16.0626 1.8335 11 1.8335C5.93739 1.8335 1.83333 5.93755 1.83333 11.0002C1.83333 16.0628 5.93739 20.1668 11 20.1668Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.70833 8.21838C8.70833 7.78087 8.70833 7.56212 8.79976 7.43999C8.87944 7.33356 9.0014 7.26698 9.13401 7.25751C9.28618 7.24664 9.4702 7.36493 9.83822 7.60152L14.1654 10.3833C14.4848 10.5886 14.6445 10.6912 14.6996 10.8218C14.7478 10.9358 14.7478 11.0645 14.6996 11.1786C14.6445 11.3091 14.4848 11.4117 14.1654 11.617L9.83822 14.3988C9.4702 14.6354 9.28618 14.7537 9.13401 14.7428C9.0014 14.7333 8.87944 14.6668 8.79976 14.5603C8.70833 14.4382 8.70833 14.2195 8.70833 13.7819V8.21838Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'VideoOutlinedIcon',
  );

  return <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />;
};
export default VideoOutlinedIcon;
