import { Grid, Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const DashboardTemplateItemCnt = styled(Grid)({});

export const Content = styled(Stack)({
  borderRadius: 12,
  border: '1px solid #E0E0E0',
  height: 76,
  width: '100%',
  alignItems: 'center',
  padding: 20,

  '& .heading': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20.49px',
    color: '#000000',
    marginBottom: -3,
  },

  '& .statement': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#424242',
  },
});

export const ImgBox = styled(Stack)({
  height: 42,
  width: 42,
  borderRadius: 100,
  alignItems: 'center',
  justifyContent: 'center',
});
