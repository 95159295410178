// React
import React, { useMemo } from 'react';

// Custom components
import CustomToolTip from 'components/Tooltip/tooltip';
import UserAvatar from 'components/Avatar';
import OptionsDropdown from 'components/TemplateDashboard/SubmissionListWithFilters/SubmissionList/SubmissionEntry/OptionsDropdown';

// Styled
import {
  Entry,
  FirstRow,
  SecondRow,
  StyledCheckbox,
  AvatarAndNameWrapper,
  NameText,
  OptionsWrapper,
  DateAndDotWrapper,
  DateText,
  Dot,
  AnonymousAvatar,
  AnonymousNameAndEmail,
  AnonymousEmail,
  StatusWrapper,
} from './styled';

// Icons
import GlobeIcon from 'components/Icons/globeIcon';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';

// Constants
import { statusColorMap } from './constants';

// Utils
import { isEqual } from 'lodash';

interface SubmissionEntryPropTypes {
  submission: SubmissionReportType;
  active: boolean;
  isPreview: boolean;
  onClickEntry: (log: SubmissionReportType) => void;
  handleClickEntry: () => void;
}

const SubmissionEntry = ({
  submission,
  active,
  isPreview,
  onClickEntry,
  handleClickEntry,
}: SubmissionEntryPropTypes) => {
  const { getUtcToTzTime, getDateInTz } = useDateTime();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClickEntry(submission);
  };

  const getSubmitterName = (log: SubmissionReportType) => {
    if (log.submitterName) return log.submitterName;
    if (!log.LastItemUpdater || !log.Updater) return 'Anonymous';
    else {
      const updater = log.LastItemUpdater ?? log.Updater;
      return `${updater?.firstName} ${updater?.lastName}`;
    }
  };

  const submitter = useMemo(() => {
    return {
      name: getSubmitterName(submission),
      id: submission?.LastItemUpdater?.id ?? submission?.Updater?.id,
    };
  }, [submission]);

  return (
    <Entry
      onClick={handleClickEntry}
      active={active ? 'true' : ''}
      ispreview={isPreview ? 'true' : ''}
    >
      <FirstRow>
        <StyledCheckbox onClick={handleClick} checked={active} />
        <AvatarAndNameWrapper>
          {submission?.anonymousId ? (
            <CustomToolTip title="Submitted via Public Link">
              <AnonymousAvatar>
                <GlobeIcon style={{ color: '#fff', fontSize: '16px' }} />
              </AnonymousAvatar>
            </CustomToolTip>
          ) : (
            <UserAvatar width={24} height={24} userId={submitter.id} />
          )}
          {submission?.anonymousId ? (
            <AnonymousNameAndEmail>
              <NameText>{submitter?.name}</NameText>
              <AnonymousEmail>{submission?.submitterEmail}</AnonymousEmail>
            </AnonymousNameAndEmail>
          ) : (
            <NameText>{submitter?.name}</NameText>
          )}
        </AvatarAndNameWrapper>
        <OptionsWrapper>
          <OptionsDropdown log={submission} />
        </OptionsWrapper>
      </FirstRow>
      <SecondRow>
        <DateAndDotWrapper>
          <DateText>
            {getDateInTz(submission?.lastItemUpdatedAt, 'MMM DD YYYY')}
          </DateText>
          <Dot />
          <DateText>
            {getUtcToTzTime(
              submission?.status === 'Submitted'
                ? submission?.updatedAt
                : submission?.lastItemUpdatedAt,
            )}
          </DateText>
        </DateAndDotWrapper>
        <StatusWrapper color={statusColorMap[submission?.status]}>
          <div className="dot" />
          <p className="status">{submission?.status}</p>
        </StatusWrapper>
      </SecondRow>
    </Entry>
  );
};

const arePropsEqual = (
  prevProps: SubmissionEntryPropTypes,
  nextProps: SubmissionEntryPropTypes,
) => isEqual(prevProps, nextProps);

export default React.memo(SubmissionEntry, arePropsEqual);
