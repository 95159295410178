/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { DateSeparator, DateSeparatorProps } from 'stream-chat-react';
import DateTime from 'utils/DateTime';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import { getUserHotelTz } from 'store/selectors/auth';
import { useSelector } from 'react-redux';

export const CustomDateSeparator = (props: DateSeparatorProps) => {
  const { date } = props;
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;

  function formatDate(d: any) {
    return `${DateTime.fromJSDate(d).toTz(tz).raw().calendar(null, {
      sameDay: '[TODAY]',
      lastDay: '[YESTERDAY] ',
      lastWeek: '[Last] dddd [at] hh:mm a',
      sameElse: 'MMM DD YYYY',
    })}`;
  }

  return <DateSeparator formatDate={formatDate} date={date} />;
};
