// React
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import Header from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/Header';
import FilterRow from './FilterRow';

// Icons
import { AddRounded } from '@mui/icons-material';

// Styled
import {
  Wrapper,
  FilterRowsWrapper,
  AddFilterButton,
  BottomRowWrapper,
} from './styled';

// Utils
import { isEqual } from 'lodash';

export type FilterType = {
  filterName:
    | 'status'
    | 'startDate'
    | 'dueDate'
    | 'template'
    | 'assignee'
    | 'category'
    | 'location'
    | 'asset'
    | 'completedAt'
    | 'archived'
    | 'taskTypes'
    | 'isOverDue'
    | 'taskTemplates'
    | 'projects'
    | 'schedules'
    | 'priority'
    | 'createdBy'
    | '';
  comparator: 'is' | 'isNot' | '';
  value: any[];
  conditional: 'and' | 'or' | '';
};

interface AdvancedFilterBuilderPropTypes {
  renderCloseIcon?: boolean;
  hideFilters?: string;
  filters: any[];
  handleClose?: () => void;
  handleSetFiltersCb: (filters: FilterType[]) => void;
  handleClearFilters?: () => void | undefined;
  dropdownWidth?: DropdownWidth | null;
}

interface DropdownWidth {
  mainDropdownWidth?: string | null;
  isDropdownWidth?: string | null;
}

const defaultFilterState: FilterType[] = [
  {
    filterName: '',
    comparator: 'is',
    value: [],
    conditional: 'and',
  },
];

const AdvancedFilterBuilder = ({
  renderCloseIcon = true,
  filters,
  handleClose,
  handleSetFiltersCb,
  hideFilters = '',
  dropdownWidth = null,
  handleClearFilters,
}: AdvancedFilterBuilderPropTypes) => {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState<FilterType[]>(
    filters?.length ? filters : defaultFilterState,
  );

  const handleDeleteRow = (idx: number) => {
    if (
      filterData.length === 1 &&
      filterData[0].filterName &&
      filterData[0].value.length
    ) {
      handleClearFilters?.();
    }
    if (filterData.length === 1) {
      setFilterData(defaultFilterState);
      handleSetFiltersCb(defaultFilterState);
      return;
    }
    const filters = [...filterData];
    filters.splice(idx, 1);

    setFilterData(filters);
    handleSetFiltersCb(filters);
  };

  const setConditionForFilters = (filters: FilterType[]) => {
    const condition =
      filters.length >= 2 ? filters[1].conditional : filters[0].conditional;
    return filters.map((f) => ({ ...f, conditional: condition }));
  };

  const handleSetFilters = (
    idx: number,
    newFilters: {
      filterName?:
        | 'status'
        | 'startDate'
        | 'dueDate'
        | 'template'
        | 'assignee'
        | 'category'
        | 'location'
        | 'asset'
        | 'completedAt'
        | 'archived'
        | 'taskTypes'
        | 'isOverDue'
        | 'taskTemplates'
        | 'projects'
        | 'schedules'
        | 'priority'
        | 'createdBy'
        | '';
      comparator?: 'is' | 'isNot';
      value?: any[];
      conditional?: 'and' | 'or';
    },
  ) => {
    const filters = [...filterData];
    filters[idx] = { ...filters[idx], ...newFilters };
    const newFilterState = setConditionForFilters(filters);
    setFilterData(newFilterState);
    handleSetFiltersCb(newFilterState);
  };

  const handleAddFilter = () => {
    const filters = [
      ...filterData,
      {
        ...defaultFilterState[0],
        conditional: filterData[filterData.length - 1].conditional,
      },
    ];
    setFilterData(filters);
    handleSetFiltersCb(filters);
  };

  return (
    <Wrapper>
      <Header renderCloseIcon={renderCloseIcon} handleClose={handleClose} />
      <FilterRowsWrapper>
        {filterData.map((f, idx) => (
          <FilterRow
            handleDeleteRow={handleDeleteRow}
            key={idx}
            index={idx}
            handleSetFilters={handleSetFilters}
            filter={filterData[idx]}
            hideFilters={hideFilters}
            dropdownWidth={dropdownWidth}
          />
        ))}
      </FilterRowsWrapper>
      <BottomRowWrapper>
        <AddFilterButton
          onClick={handleAddFilter}
          fullWidth={false}
          startIcon={<AddRounded />}
        >
          Add Filter
        </AddFilterButton>
      </BottomRowWrapper>
    </Wrapper>
  );
};

const arePropsEqual = (
  prevProps: AdvancedFilterBuilderPropTypes,
  nextProps: AdvancedFilterBuilderPropTypes,
) =>
  isEqual(
    {
      filters: prevProps.filters,
    },
    {
      filters: nextProps.filters,
    },
  );

export default React.memo(AdvancedFilterBuilder, arePropsEqual);
