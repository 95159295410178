import { createSvgIcon } from '@mui/material';

const HomeIcon = (props) => {
  const { primary = 'black', secondary = '#D9D9D9' } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M2.75 9.68463C2.75 9.15814 2.75 8.89489 2.81786 8.65246C2.87797 8.43772 2.97675 8.23574 3.10936 8.05645C3.25906 7.85405 3.46685 7.69243 3.88244 7.3692L10.0996 2.53367C10.4216 2.28318 10.5826 2.15794 10.7604 2.1098C10.9173 2.06732 11.0827 2.06732 11.2396 2.1098C11.4174 2.15794 11.5784 2.28318 11.9004 2.53367L18.1176 7.3692C18.5331 7.69243 18.7409 7.85405 18.8906 8.05645C19.0232 8.23574 19.122 8.43772 19.1821 8.65246C19.25 8.89489 19.25 9.15814 19.25 9.68463V16.3167C19.25 17.3434 19.25 17.8568 19.0502 18.249C18.8744 18.5939 18.5939 18.8744 18.249 19.0502C17.8568 19.25 17.3434 19.25 16.3167 19.25H5.68333C4.65657 19.25 4.14319 19.25 3.75102 19.0502C3.40605 18.8744 3.12559 18.5939 2.94982 18.249C2.75 17.8568 2.75 17.3434 2.75 16.3167V9.68463Z"
        fill={primary}
        fill-opacity="0.54"
      />
      <path
        d="M7.44885 12.8333C7.85589 14.4148 9.29148 15.5833 11 15.5833C12.7085 15.5833 14.1441 14.4148 14.5512 12.8333M10.0996 2.53367L3.88244 7.3692C3.46685 7.69243 3.25906 7.85405 3.10936 8.05645C2.97675 8.23574 2.87797 8.43772 2.81786 8.65246C2.75 8.89489 2.75 9.15814 2.75 9.68463V16.3167C2.75 17.3434 2.75 17.8568 2.94982 18.249C3.12559 18.5939 3.40605 18.8744 3.75102 19.0502C4.14319 19.25 4.65657 19.25 5.68333 19.25H16.3167C17.3434 19.25 17.8568 19.25 18.249 19.0502C18.5939 18.8744 18.8744 18.5939 19.0502 18.249C19.25 17.8568 19.25 17.3434 19.25 16.3167V9.68463C19.25 9.15814 19.25 8.89489 19.1821 8.65246C19.122 8.43772 19.0232 8.23574 18.8906 8.05645C18.7409 7.85405 18.5331 7.69243 18.1176 7.3692L11.9004 2.53367C11.5784 2.28318 11.4174 2.15794 11.2396 2.1098C11.0827 2.06732 10.9173 2.06732 10.7604 2.1098C10.5826 2.15794 10.4216 2.28318 10.0996 2.53367Z"
        stroke={primary}
        stroke-opacity="0.54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>,
    'HomeIcon',
  );
  return (
    <IconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    />
  );
};
export default HomeIcon;
