import { createSvgIcon } from '@mui/material';
import React from 'react';

const TeamsUsageIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="34" height="34" rx="8" fill="#CFD8DC" />
      <path
        opacity="0.12"
        d="M14.9165 17C16.9876 17 18.6665 15.3211 18.6665 13.25C18.6665 11.1789 16.9876 9.5 14.9165 9.5C12.8454 9.5 11.1665 11.1789 11.1665 13.25C11.1665 15.3211 12.8454 17 14.9165 17Z"
        fill="#455A64"
      />
      <path
        d="M14.9165 17C16.9876 17 18.6665 15.3211 18.6665 13.25C18.6665 11.1789 16.9876 9.5 14.9165 9.5C12.8454 9.5 11.1665 11.1789 11.1665 13.25C11.1665 15.3211 12.8454 17 14.9165 17Z"
        fill="#E1F5FE"
      />
      <path
        d="M20.3332 9.8898C21.5679 10.5034 22.4165 11.7776 22.4165 13.25C22.4165 14.7224 21.5679 15.9966 20.3332 16.6102M21.9998 20.972C23.2594 21.5419 24.3936 22.4708 25.3332 23.6667M8.6665 23.6667C10.2886 21.6021 12.4908 20.3333 14.9165 20.3333C17.3422 20.3333 19.5444 21.6021 21.1665 23.6667M18.6665 13.25C18.6665 15.3211 16.9876 17 14.9165 17C12.8454 17 11.1665 15.3211 11.1665 13.25C11.1665 11.1789 12.8454 9.5 14.9165 9.5C16.9876 9.5 18.6665 11.1789 18.6665 13.25Z"
        stroke="#455A64"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TeamsUsageIcon',
  );
  return <IconCmp viewBox={'0 0 34 34'} fill="none" {...props} />;
};

export default TeamsUsageIcon;
