import { NoAddedCnt, TextCnt, MainText, SubText } from './noAddedRecords.style';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import CustomButton from 'components/Button/CustomButton';

const NoAddedRecords = () => {
  const dispatch = useDispatch();

  return (
    <NoAddedCnt>
      <img src={'/assets/img/globe_location_empty_state.svg'} />{' '}
      <TextCnt>
        <MainText>
          Effortlessly assign and track work across multiple locations
        </MainText>
        <SubText>
          Link each location to tasks and assets for full operational clarity
          and control
        </SubText>
      </TextCnt>
      <CustomButton
        className="addBtn"
        variant="contained"
        onClick={() => {
          dispatch(
            setDialog({
              open: true,
              dialogId: 'createLocation',
            }),
          );
        }}
      >
        Add Location
      </CustomButton>
    </NoAddedCnt>
  );
};

export default NoAddedRecords;
