import AddIcon from '@mui/icons-material/Add';
import CustomButton from 'components/Button/CustomButton';
import { useCreateDashboard } from 'pages/Dashboards/hooks/useDashboard';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
const createDashboardPayload = {
  title: 'Untitled Dashboard',
};
function AddDashboardButton() {
  const { createDashboardQuery } = useCreateDashboard();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const handleAddDashboard = () => {
    createDashboardQuery.mutate(createDashboardPayload, {
      onSuccess: (data) => {
        navigateWithWorkspaceUrl(`/dashboards/${data.id}`);
      },
    });
  };
  return (
    <CustomButton
      variant={'contained'}
      color={'primary'}
      startIcon={<AddIcon />}
      onClick={handleAddDashboard}
      buttonLoader={createDashboardQuery.isLoading}
      sx={{ borderRadius: '8px' }}
    >
      {createDashboardQuery.isLoading ? 'Creating...' : 'Create Dashboard'}
    </CustomButton>
  );
}

export default AddDashboardButton;
