import { createSvgIcon } from '@mui/material';
import React from 'react';

const RequestDeclinedIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M8.00016 1.33331C4.3135 1.33331 1.3335 4.31331 1.3335 7.99998C1.3335 11.6866 4.3135 14.6666 8.00016 14.6666C11.6868 14.6666 14.6668 11.6866 14.6668 7.99998C14.6668 4.31331 11.6868 1.33331 8.00016 1.33331ZM11.3335 10.3933L10.3935 11.3333L8.00016 8.93998L5.60683 11.3333L4.66683 10.3933L7.06016 7.99998L4.66683 5.60665L5.60683 4.66665L8.00016 7.05998L10.3935 4.66665L11.3335 5.60665L8.94016 7.99998L11.3335 10.3933Z"
        fill="currentColor"
      />
    </>,
    'RequestDeclinedIcon',
  );
  return <IconCmp viewBox={'0 0 16 16'} fill="none" {...props} />;
};

export default RequestDeclinedIcon;
