import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const NoAddedCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  zIndex: 1,
  width: 810,
  [theme.breakpoints.down('lg')]: {
    width: 650,
  },
  [theme.breakpoints.down('md')]: {
    width: 550,
  },
  [theme.breakpoints.down('sm')]: {
    width: 350,
  },
  marginTop: 50,
  '& .addBtn': {
    cursor: 'pointer',
    width: 182,
    height: 45,
    borderRadius: 8,
  },
  '& img': {
    width: 180,
  },
}));

export const TextCnt = styled('div')({
  margin: '10px 0px',
  textAlign: 'center',
});

export const MainText = styled('p')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '41.99px',
  letterSpacing: '0.25px',
  color: colors.black,
  opacity: 0.87,
  margin: '15px 0px 5px 0px',

  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
    lineHeight: '31.99px',
  },
}));

export const SubText = styled('p')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '30px',
  letterSpacing: '0.15px',
  color: '#00000099',
  opacity: 0.6,
  marginBottom: 20,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));
