import { BootstrapModelProps } from 'interfaces/htmlTags';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

const BootstrapModelComponent: FC<BootstrapModelProps> = ({
  className,
  datatestid,
  id = '',
  handleClose,
  showCloseBtn = true,
  isShow,
  animation,
  arialabelledby,
  centered,
  headerData,
  headerHtml,
  bodyData,
  style = {},
  backdrop = true,
  keyboard = false,
  footer = null,
  size = undefined,
  container,
  contentClassname,
}: BootstrapModelProps) => {
  /*******************
      @purpose : Render Common Anchor Component
      @Author : INIC
      ******************/

  return (
    <Modal
      size={size}
      className={className}
      show={isShow}
      onHide={handleClose}
      animation={animation}
      aria-labelledby={arialabelledby}
      centered={centered}
      id={id}
      data-testid={datatestid}
      style={style}
      backdrop={backdrop}
      keyboard={keyboard}
      container={container}
      contentClassName={contentClassname}
    >
      {headerData !== '' && headerHtml !== '' ? (
        <Modal.Header closeButton={!!showCloseBtn}>
          <Modal.Title>{headerData}</Modal.Title>
          {headerHtml}
        </Modal.Header>
      ) : null}
      <Modal.Body>{bodyData}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default BootstrapModelComponent;
