import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';

export const ListSingleChatCard = styled(Container)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  h5: {
    fontSize: '14px',
    fontWeight: 600,
  },
  '.contentBox': {
    justifyContent: 'space-between',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'flex-row',
    alignItems: 'center',
  },
  '.nameBox': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    '.elEllipses': {
      display: '-webkit-box !important',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  '.avatarContainer': {
    marginRight: '12px',
    alignSelf: 'center',
    img: {
      width: '32px',
      height: '32px',
      maxWidth: 'fit-content',
    },
  },
  '.timeMessage': {
    fontSize: '10px',
  },
  '.lastMessage': {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '.lastMessageBold': {
    fontWeight: 'bold',
  },
  '.leftMenuChannelHeader': {
    marginRight: '10px',
  },
  '.nameContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  '.mutedChannel': {
    marginLeft: 10,
  },
}));
