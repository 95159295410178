// Styled
import {
  Container,
  ContentContainer,
  Heading,
  SubHeading,
  Statement,
  Content,
  Row,
  Text,
  Dot,
  GetStarted,
} from 'pages/documents/emptyStates/main/style';

// Icons
import DocumentEmptyInfo from 'public/assets/img/documents-empty-info.svg';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';

const EmptyState = () => {
  const { createDocument } = useDocumentsFolders();
  return (
    <Container>
      <ContentContainer>
        <Heading>Create Your Company's Knowledge Hub</Heading>
        <SubHeading>Create, manage and share all information.</SubHeading>

        <Statement>
          Crafting Standard Operating Procedures (SOPs) has never been this
          easy. Begin your journey towards:
        </Statement>

        <Content>
          <Row>
            <Dot>•</Dot>
            <Text>
              <span className="title">Standardization:</span>
              <span>Lay down consistent guidelines for everyone.</span>
            </Text>
          </Row>
          <Row>
            <Dot>•</Dot>
            <Text>
              <span className="title">Training:</span>
              <span>
                Empower new and existing team members with the right knowledge.
              </span>
            </Text>
          </Row>
          <Row>
            <Dot>•</Dot>
            <Text>
              <span className="title">Accountability:</span>
              <span>Ensure clarity in roles, tasks and operations.</span>
            </Text>
          </Row>
          <Row>
            <Dot>•</Dot>
            <Text>
              <span className="title">Knowledge Sharing:</span>
              <span>
                Share information across your teams easily with just a few
                clicks.
              </span>
            </Text>
          </Row>
        </Content>
        <GetStarted
          variant="contained"
          style={{ marginBottom: 30 }}
          onClick={async () => {
            const response = await createDocument(
              {
                title: 'New Document',
              },
              false,
            );

            const urlTab = `${window.location.origin}/document-detail/${response?.id}?source=list`;
            window.open(urlTab, '_self');
          }}
        >
          Write SOP
        </GetStarted>
      </ContentContainer>
      <img src={DocumentEmptyInfo} className="useCases" />
    </Container>
  );
};

export default EmptyState;
