// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Checkbox } from '@mui/material';

export const Entry = styled(Box, {
  shouldForwardProp: (prop) => !['active', 'ispreview'].includes(prop),
})(({ theme, active, ispreview }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  padding: theme.spacing(2, 1),
  width: '100%',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  cursor: 'pointer',
  ...(active && {
    borderLeft: '3px solid rgba(104, 104, 254, 1)',
    background: 'rgba(245, 244, 255, 1)',
    paddingLeft: '5px',
  }),
  ...(ispreview && {
    background: 'rgba(245, 244, 255, 1)',
  }),
  '&:hover': {
    background: 'rgba(245, 244, 255, 1)',
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  marginRight: 15,
  marginLeft: 9,
}));

export const FirstRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const SecondRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: 44,
}));

export const AvatarAndNameWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  marginRight: 20,
}));

export const NameText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '19.12px',
  color: 'rgba(0, 0, 0, 0.87)',
  letterSpacing: '0.15px',
  maxWidth: '300px',
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const OptionsWrapper = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
}));

export const DateAndDotWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  marginRight: 4,
}));

export const DateText = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '20.41px',
  color: 'rgba(97, 97, 97, 1)',
  letterSpacing: '0.1px',
}));

export const Dot = styled(Box)(({ theme }) => ({
  height: 4,
  width: 4,
  borderRadius: 4,
  background: 'rgba(117, 117, 117, 1)',
}));

export const AnonymousAvatar = styled(Box)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 24,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const AnonymousNameAndEmail = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const AnonymousEmail = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '17.16px',
  color: 'rgba(66, 66, 66, 1)',
  maxWidth: '300px',
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StatusWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,

  '& .dot': {
    display: 'flex',
    width: 7,
    height: 7,
    borderRadius: 7,
    background: color,
  },

  '& .status': {
    fontSize: 14,
    fontWeight: '700',
    lineHeight: '21.98px',
    color: 'rgba(97, 97, 97, 1)',
    letterSpacing: '0.1px',
  },
}));

export const StatusDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  display: 'flex',
  width: 7,
  height: 7,
  borderRadius: 7,
  background: color,
  marginRight: 4,
}));
