import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';
import { Box } from '@mui/material';

export const CreateTaskInfoStyled = styled(Box)({
  width: '100%',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',

  '.container': {
    gap: 70,
    background: colors.white,
    '.infoContainer': {
      marginTop: 25,
      flex: 1,
      '.title': {
        fontWeight: 800,
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.87)',
        lineHeight: '160%',
      },
      '.subtitle': {
        fontWeight: 600,
        fontSize: 17,
        color: 'rgba(0, 0, 0, 0.87)',
        lineHeight: '160%',
      },
      '.description': {
        marginTop: 13,
        fontWeight: 500,
        fontSize: 17,
        color: 'rgba(0, 0, 0, 0.6)',
      },
      '.buttonsContainer': {
        marginTop: 28,
      },
    },
    '.thumbnailContainer': {
      width: 400,
      cursor: 'pointer',
    },
  },
});
