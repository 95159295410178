// React
import { useMemo, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Custom components
import CustomListItem from 'components/List/ListItem/ListItem';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';

// Icons
import DoneIcon from '@mui/icons-material/Done';

// Styles
import { StyledList } from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';
import { useUpdateColumn } from 'utils/CustomHooks/useUpdateColumn';
import {
  GroupByBtn,
  IconCnt,
} from 'pages/task/TaskListView/GroupByDropdown/taskGroupByDropdown.style';
import GroupByIcon from 'components/Icons/groupByIcon';
import { isEmpty } from 'lodash';
import LocationIcon from 'components/Icons/locationIcon';
import AssetsIcon from 'components/Icons/assetsIcon';
import PriorityFlagIcon from 'components/Icons/PriorityFlagIcon';
import AddAssigneeIcon from 'components/Icons/addAssigneeIcon';
import ChecklistIcon from 'components/Icons/checklistIconWithoutBg';
import { getTaskListFilters } from 'store/selectors/task';

const groupByOptions = [
  {
    title: 'Status',
    id: 'status',
    icon: <ChecklistIcon className={'actionIcon'} />,
  },
  { title: 'Assigned To', id: 'assigned_to', icon: <AddAssigneeIcon /> },
  { title: 'Asset', id: 'asset', icon: <AssetsIcon /> },
  { title: 'Location', id: 'location', icon: <LocationIcon /> },
  { title: 'Priority', id: 'priority', icon: <PriorityFlagIcon /> },
];
function TaskGroupByDropdown(props) {
  const { listProps = {}, currentTabInTasksView } = props;
  const { updateColumnsForTasksListing } = useUpdateColumn(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const taskListFilters: any = useSelector(getTaskListFilters);

  const taskColumns = useMemo(() => {
    return taskListFilters?.tabs?.[currentTabInTasksView]?.columns ?? [];
  }, [taskListFilters?.tabs?.[currentTabInTasksView]?.columns]);

  const toggleColumnRowGroup = (colId) => {
    //ag-grid update column definition
    const columnDefs = globalThis?.taskListGrid?.api?.getColumnDefs();
    const newColDefs = [...columnDefs];
    const colIndex = newColDefs.findIndex((col) => col.colId === colId);
    const prevColIndex = newColDefs.findIndex((col) => col.rowGroup);
    const col = newColDefs[colIndex];

    if (prevColIndex > -1) {
      //if there is a previous column with rowGroup set to false
      const prevCol = newColDefs[prevColIndex];
      const newPrevCol = {
        ...prevCol,
        rowGroup: false,
        sort: null,
        sortIndex: null,
      };
      newColDefs[prevColIndex] = newPrevCol;
    }
    const newCol = {
      //set the new column with rowGroup set to true/false
      ...col,
      rowGroup: !col.rowGroup,
      sort: null,
      sortable: false,
      sortIndex: null,
    };
    newColDefs[colIndex] = newCol;
    globalThis?.taskListGrid?.api?.setColumnDefs(newColDefs);
    updateColumnsForTasksListing(
      globalThis?.taskListGrid?.columnApi.getColumnState(),
      currentTabInTasksView,
    );
  };
  const handleDropdownOpen = (event) => {
    //open dropdown
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    //close dropdown
    setAnchorEl(null);
  };

  const ListItems = () =>
    useMemo(() => {
      return (
        <>
          <StyledList
            sx={{ width: '100%' }}
            dense={true}
            disablePadding
            {...listProps}
          >
            {groupByOptions.map((c, i) => {
              const isSelected = globalThis?.taskListGrid?.columnApi
                ?.getRowGroupColumns()
                ?.some((col) => col.colId == c.id && col.colDef.rowGroup);
              return (
                <CustomListItem
                  key={c.title}
                  id={c.title}
                  selected={isSelected}
                  checkbox={false}
                  onClick={() => toggleColumnRowGroup(c.id)}
                  endIcon={isSelected && <DoneIcon />}
                  startIcon={<IconCnt>{c.icon}</IconCnt>}
                >
                  {c.title}
                </CustomListItem>
              );
            })}
          </StyledList>
        </>
      );
    }, [taskColumns]);

  const selectedRowGroup = globalThis?.taskListGrid?.columnApi
    ?.getRowGroupColumns()
    ?.find((col) => col?.colDef?.rowGroup);
  const selectedRowGroupTitle = groupByOptions.find(
    (o) => o.id === selectedRowGroup?.colId,
  )?.title;
  return (
    <>
      <GroupByBtn
        onClick={handleDropdownOpen}
        startIcon={<GroupByIcon />}
        selected={!isEmpty(selectedRowGroup)}
      >
        Group By
        {`${selectedRowGroupTitle ? `: ${selectedRowGroupTitle}` : ''}`}
      </GroupByBtn>
      <CustomDropdown
        closeAction={handleDropdownClose}
        popperProps={{
          content: <ListItems />,
          id: 'columnSelectionDropdown',
          style: { width: 240 },
          open: !!anchorEl,
          placement: 'bottom-start',
          anchorEl,
        }}
      ></CustomDropdown>
    </>
  );
}

export default TaskGroupByDropdown;
