import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';
import AddIcon from 'components/Icons/addIcon';
import SendIcon from 'components/Icons/sendIcon';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import CreateTeamDrawer from 'pages/teams/createTeamDrawer/createTeam';
import InviteDashboard from 'public/assets/img/invitedashboard.png';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import DIALOGS from 'utils/dialogIds';
import {
  InviteandBoost,
  InviteImg,
  MainText,
  NoAddedCnt,
  SubText,
  TextCnt,
} from './style';

export const TeamEmptyState = (props) => {
  const { PAYWALL_LIMITS, FEATURES } = useCustomPermission();
  const [teamOpen, setTeamOpen] = useState(false);

  const handleCreateTeam = () => {
    setTeamOpen(true);
  };

  return (
    <NoAddedCnt>
      <CreateTeamDrawer
        heading="Add Team"
        open={teamOpen}
        onClose={() => {
          setTeamOpen(false);
        }}
      />
      <TextCnt>
        <MainText>You have no teams yet</MainText>
        <SubText>
          To get started, click on the{' '}
          <span className="inner-text">Create Team</span> button to set up your
          first team
        </SubText>
      </TextCnt>
      <VerifyFeatureAccess paywallUsageId={PAYWALL_LIMITS.TEAMS_WALL}>
        <CustomButton
          className="addBtn"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreateTeam}
        >
          Create Team
        </CustomButton>
      </VerifyFeatureAccess>
    </NoAddedCnt>
  );
};

export const UsersEmptyState = (props) => {
  const dispatch = useDispatch();
  const { FEATURES } = useCustomPermission();
  return (
    <InviteandBoost>
      <div className="box">
        <CTypography className="title">Invite your team to Xenia</CTypography>
        <hr />
        <CTypography className="subtitle">
          Take teamwork to the next level with Xenia. Invite your team to join
          your account via email or phone number and achieve more together.
        </CTypography>
        <InviteImg src={InviteDashboard} />
        <CustomButton
          variant="contained"
          type="submit"
          startIcon={<SendIcon primary={'#FFFFFF'} />}
          className="sendInvite"
          onClick={() => {
            dispatch(
              setDialog({
                dialogId: DIALOGS.INVITE_USERS,
                open: true,
              }),
            );
          }}
        >
          Invite Team Members
        </CustomButton>
      </div>
    </InviteandBoost>
  );
};
