import { useCallback } from 'react';
import TaskSummaryHeader from 'pages/ReportingV2/Reports/TaskOverview/TaskSummaryHeader/TaskSummaryHeader';
import { TaskSummaryContext } from 'pages/ReportingV2/Reports/TaskOverview/context/taskSummaryContext';
import TaskStatusCounts from 'pages/Widgets/TaskStatusCounts/taskStatusCounts';
import TasksListSummary from 'pages/Widgets/TaskListing/TaskListView/tasksListSummary';
import TasksByAssignee from 'pages/Widgets/TasksByAssignee/tasksByAssignee';
import TasksByCategory from 'pages/Widgets/TaskByCategory/tasksByCategory';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { INITIAL_LAYOUT_TASK_SUMMARY_REPORT } from 'components/CustomTilesGrid/utils';
import CustomDragTilesGrid from 'components/CustomDragTilesGrid';
import useReports from 'pages/ReportingV2/Reports/useReports';
import { REPORTING_CONFIG_CONSTANTS } from 'pages/ReportingV2/Reports/constants';
import { transformLayouts } from 'utils/globalFunction';
import { THRESHOLD } from 'components/CustomDragTilesGrid/utils';

function TaskSummary() {
  const { workspaceId } = useWorkspaceHook();
  const {
    defaultProps,
    state,
    updateState,
    clearAllFilters,
    updateGridSettings,
    isFilterApplied,
  } = useReports(REPORTING_CONFIG_CONSTANTS.TASK_SUMMARY_REPORT);

  const transformedLayouts = useCallback(() => {
    return state?.gridSettings
      ? transformLayouts(
          state?.gridSettings,
          INITIAL_LAYOUT_TASK_SUMMARY_REPORT,
          THRESHOLD,
        )
      : null;
  }, [state?.gridSettings]);

  return (
    <TaskSummaryContext.Provider
      value={{ state, updateState, clearAllFilters }}
    >
      <div>
        <TaskSummaryHeader />
        <TaskStatusCounts {...defaultProps} isFilterApplied={isFilterApplied} />

        {state?.isLoading ? null : (
          <div style={{ margin: '20px 30px' }}>
            <CustomDragTilesGrid
              key={workspaceId}
              customLayout={
                transformedLayouts() || INITIAL_LAYOUT_TASK_SUMMARY_REPORT
              }
              onChangeCB={updateGridSettings}
            >
              <TasksListSummary key="taskListSummary" {...defaultProps} />
              <TasksByAssignee key="tasksByAssignee" {...defaultProps} />
              <TasksByCategory key="tasksByCategory" {...defaultProps} />
            </CustomDragTilesGrid>
          </div>
        )}
      </div>
    </TaskSummaryContext.Provider>
  );
}

export default TaskSummary;
