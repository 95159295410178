import { FC } from 'react';
import CommentIcon from 'components/Icons/commentIcon';
import AddCommentIcon from 'components/Icons/addCommentIcon';
import { StyledAction, StyledBadge } from './styled';

const CommentAction: FC<any> = (props) => {
  const { variant, clickHandler, count, ...rest } = props;
  return (
    <StyledAction {...rest}>
      {variant === 'add' && <AddCommentIcon />}

      {(variant === 'unread' || variant === 'read') && (
        <StyledBadge
          isUnRead={variant === 'unread'}
          badgeContent={count}
          color="primary"
        >
          <CommentIcon />
        </StyledBadge>
      )}
    </StyledAction>
  );
};

export default CommentAction;
