import { createSvgIcon } from '@mui/material';

interface IProps {
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const PPTIcon = (props: IProps) => {
  const { bgColor = '#F4511E', fillColor = 'white', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <rect width="32" height="32" rx="6" fill={bgColor} />
      <g clip-path="url(#clip0_1399_34409)">
        <path
          d="M17.3725 7.01129L7.2925 8.92379C7.12234 8.95614 7 9.11083 7 9.28379V22.7163C7 22.8893 7.12234 23.044 7.2925 23.0763L17.3725 24.9888C17.395 24.993 17.4175 25 17.44 25C17.523 25 17.6003 24.9747 17.665 24.9213C17.748 24.8524 17.8 24.7469 17.8 24.64V7.36004C17.8 7.25317 17.748 7.1477 17.665 7.07879C17.582 7.00989 17.478 6.99161 17.3725 7.01129ZM18.52 9.16004V12.4225C18.6381 12.4057 18.7577 12.4 18.88 12.4V14.92H21.4C21.4 16.3122 20.2722 17.44 18.88 17.44C18.7577 17.44 18.6381 17.4344 18.52 17.4175V19.6H21.4V20.32H18.52V22.84H23.92C24.3166 22.84 24.64 22.5166 24.64 22.12V9.88004C24.64 9.48348 24.3166 9.16004 23.92 9.16004H18.52ZM19.96 11.32C21.3522 11.32 22.48 12.4479 22.48 13.84H19.96V11.32ZM9.93625 12.6363H12.31C14.0073 12.6363 14.8638 13.3535 14.8638 14.785C14.8638 15.4615 14.6191 16.0099 14.1325 16.4275C13.6459 16.8452 12.9892 17.0575 12.175 17.0575H11.455V19.375H9.93625V12.6363ZM11.455 13.8063V15.8988H12.0513C12.8584 15.8988 13.2663 15.5486 13.2663 14.8413C13.2663 14.1494 12.8584 13.8063 12.0513 13.8063H11.455Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1399_34409">
          <rect
            width="18"
            height="18"
            fill={fillColor}
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </>,
    'PPTIcon',
  );
  return <IconCmp viewBox={'0 0 32 32'} {...rest} />;
};
export default PPTIcon;
