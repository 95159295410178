// Custom components
import { Box } from '@mui/material';
import {
  TitleRenderer,
  CreatedByRenderer,
  DueDateRenderer,
  DescriptionRenderer,
  ActionsRender,
} from './renderers/renderers';

// Constants
import { statusDropdownData } from 'helper/task/dropdownData';

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};

export const getRowStyles = (params) => {
  const rowGroupId =
    globalThis?.taskSummaryListGrid?.columnApi?.getRowGroupColumns()?.[0]
      ?.colId;
  if (params?.node?.group && rowGroupId == 'status') {
    const selectedStatus = statusDropdownData.find(
      (s) => s.value === params?.node?.key,
    );
    return {
      background: selectedStatus?.backgroundColor,
      color: selectedStatus?.color,
      boxShadow: 'none',
      borderTop: '25px solid #fff',
    };
  }
  if (params?.node?.group) {
    return {
      background: '#ECEEF6',
      borderTop: '25px solid #fff',
    };
  }
};

export const cellRenderer = {
  title: (params, _) => {
    if (!params?.data) return;
    const { templateTitle } = params.data;
    return <TitleRenderer title={templateTitle} />;
  },
  createdBy: (params) => {
    if (!params?.data) return;
    const { Creator } = params.data;
    return <CreatedByRenderer createdBy={Creator} />;
  },
  dueDate: (params) => {
    if (!params?.data) return;
    const { dueAfterCreationTime } = params.data;
    return <DueDateRenderer dueDate={dueAfterCreationTime} />;
  },
  description: (params) => {
    if (!params?.data) return;
    const { templateDescription } = params.data;
    return <DescriptionRenderer description={templateDescription} />;
  },
  actions: (params, props) => {
    if (!params?.data) return;
    const { actions } = props;

    return (
      <Box data-attribute={'cell-click'}>
        <ActionsRender template={params?.data} actions={actions} />
      </Box>
    );
  },
};

export const valueGetters = {
  title: (params) => {
    if (!params?.data) return;
    const { templateTitle } = params.data;
    return templateTitle || '-';
  },
  createdBy: (params) => {
    if (!params?.data) return;
    const { Creator } = params.data;
    return Creator?.fullName || '-';
  },
  dueDate: (params) => {
    if (!params?.data) return;
    const { dueAfterCreationTime } = params.data;
    return dueAfterCreationTime?.value + '' + dueAfterCreationTime?.unit;
  },
  description: (params) => {
    if (!params?.data) return;
    const { templateDescription } = params.data;
    return templateDescription || '-';
  },
};

export const columns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      sortable: true,
      cellRenderer: (params) => cellRenderer.title(params, props),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      colId: 'createdBy',
      sortable: true,
      valueGetter: valueGetters.dueDate,
      cellRenderer: cellRenderer.createdBy,
      resizable: true,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      colId: 'dueDate',
      sortable: true,
      valueGetter: valueGetters.dueDate,
      cellRenderer: cellRenderer.dueDate,
      resizable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      colId: 'description',
      valueGetter: valueGetters.description,
      sortable: true,
      cellRenderer: cellRenderer.description,
    },
    {
      field: 'actionCol',
      headerName: '',
      colId: 'actionCol',
      width: 100,
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: 'disableRowClick',
      cellRenderer: (params) => cellRenderer.actions(params, props),
      cellStyle: { paddingLeft: '7px', paddingRight: '7px' },
    },
  ];

  return tableCells.filter((cell) => columnConfig[cell.field]);
};
