import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing('0px', 2, 2, 2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const StepTypesGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: '16px',
});

export const StepTypesItem = styled('div')({
  background: '#fff',
  borderRadius: '16px',
  padding: '16px 12px',
  cursor: 'pointer',
  border: '1px solid transparent',
  '&:hover': {
    border: '1px solid #6868FE',
  },
});
