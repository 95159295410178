/* eslint-disable @typescript-eslint/no-explicit-any */
import ArchiveIcon from 'components/Icons/archiveIcon';
import React, { FC } from 'react';
import { AchieveCard } from './ArchiveCard.style';
export const ArchiveCard: FC<any> = ({ setIsArchiveShow }: any) => {
  return (
    <AchieveCard>
      <div
        className="archivedChat"
        onClick={() => {
          setIsArchiveShow(true);
        }}
      >
        <div className="clickableArea">
          <ArchiveIcon className="archiveIcon" />
          <a className="archiveLink">Archive</a>
        </div>
        {/* <div id="archivechatNav">
          <Card.Body>
            <ListGroup as="ul">{getArchivedChatList()}</ListGroup> 
          </Card.Body>
        </div> */}
      </div>
    </AchieveCard>
  );
};
