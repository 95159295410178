import { FC, useMemo, useCallback } from 'react';
import CustomListView from 'components/ListView/Listview.cmp';
import FullWidthCellRenderer from './createFixedRow';
import { Columns } from './constants';

import { LoaderCnt, TableCnt } from './style';
import FlagCategoriesEmptyState from './emptyState/emptyState';
import { CircularProgress } from '@mui/material';

const FlaggedCategoriesTable: FC<any> = ({
  categoriesList,
  actionsProps,
  hideMainBanner,
  isLoading,
}: any) => {
  const pinnedTopRowData = useMemo(() => {
    return [
      {
        fullWidth: true,
      },
    ];
  }, []);

  //Fit columns to span to full width of the grid automatically
  const onGridReady = (event) => {
    setTimeout(() => {
      event.api.sizeColumnsToFit();
    }, 1000);
  };

  const onGridResize = (event) => {
    event.api.sizeColumnsToFit();
  };

  const isFullWidthRow = useCallback((params) => {
    return params.rowNode.data.fullWidth;
  }, []);

  const fullWidthCellRenderer = useMemo(() => {
    return FullWidthCellRenderer;
  }, []);

  const onRowStartEditing = useCallback((params) => {
    const { api } = globalThis?.FlaggedCategoriesGrid || {};

    api?.stopEditing();
    api?.startEditingCell({
      rowIndex: params?.node?.rowIndex,
      colKey: params?.columnApi?.getAllColumns()?.[0].colId,
    });
  }, []);

  const onModelUpdated = () => {
    const { api } = globalThis.FlaggedCategoriesGrid;
    const rowsCount = api.getDisplayedRowCount();

    if (rowsCount === 0) {
      api.showNoRowsOverlay();
    } else {
      api.hideOverlay();
    }
  };

  const tableHeight = useMemo(() => {
    if (categoriesList?.length === 0 && !hideMainBanner) {
      return 'calc(100vh - 300px)';
    }

    if (categoriesList?.length === 0 && hideMainBanner) {
      return 'calc(100vh - 300px)';
    }

    if (categoriesList?.length > 0 && hideMainBanner) {
      return 'calc(100vh - 330px)';
    }

    if (categoriesList?.length > 0 && !hideMainBanner) {
      return 'calc(100vh - 440px)';
    }
  }, [hideMainBanner, categoriesList]);

  return isLoading ? (
    <LoaderCnt>
      <CircularProgress />
    </LoaderCnt>
  ) : (
    <TableCnt
      sx={{
        height: tableHeight,
      }}
    >
      <CustomListView
        rootProps={{
          className: 'flagged-categories-table-view',
          style: {
            height: 'inherit',
            boxShadow: 'none',
            flex: 1,
          },
        }}
        onRowClick={() => {
          console.log('row clicked');
        }}
        gridProps={{
          ref: (ref) => {
            globalThis.FlaggedCategoriesGrid = ref;
          },
          columnDefs: Columns({
            actionsProps: {
              ...actionsProps,
              onRowStartEditing,
            },
          }),
          rowData: categoriesList,
          pinnedTopRowData: pinnedTopRowData,
          isFullWidthRow: isFullWidthRow,
          fullWidthCellRenderer: fullWidthCellRenderer,
          onGridReady: onGridReady,
          onGridSizeChanged: onGridResize,
          suppressClickEdit: true,
          frameworkComponents: {
            customNoRowsOverlay: FlagCategoriesEmptyState,
          },
          onModelUpdated: onModelUpdated,
        }}
      />
    </TableCnt>
  );
};

export default FlaggedCategoriesTable;
