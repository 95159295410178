import React, { useContext, useEffect, useMemo } from 'react';
import RequestListingItem from 'pages/Requests/RequestListing/RequestListingItem';
import {
  RequestListingList,
  StatusGroupAccordion,
} from 'pages/Requests/RequestListing/requestListing.style';
import useRequests from 'pages/Requests/useRequests';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RequestsContext } from 'pages/Requests/context/context';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isEmpty } from 'lodash';
import EmptyState from 'components/AttachChecklistModal/EmptyFilter';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';

function RequestListing() {
  const {
    selectedRequestId,
    selectedRequest,
    scrollToRequest,
    navigateToRequest,
  } = useRequests();
  const { requestsState, updateRequestsState } = useContext(RequestsContext);
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const { searchQuery, requests, collapsed, updateRequest } = requestsState;

  useEffect(() => {
    if (globalThis.platform === 'mobile') {
      window.addEventListener('message', (e: any) => {
        const dataObj = JSON.parse(e?.data);
        if (dataObj.type == 'request') {
          updateRequest(dataObj);
        }
      });
    }
    if (requestsState?.requests?.length) {
      if (!selectedRequestId && !isMediumDown) {
        navigateToRequest(requestsState?.requests?.[0]?.id);
      }
      if (selectedRequest()) {
        scrollToRequest();
      }
    }
  }, []);

  const filteredListing = useMemo(() => {
    // Split the search query into an array of individual words
    const searchWords = searchQuery.split(' ');
    // Filter the array for items that include any of the search words
    return requests.filter((ele) => {
      // Check if the lowercase item includes any of the search words
      return searchWords.some(
        (w) =>
          ele?.title?.toLowerCase()?.includes(w.toLowerCase()) ||
          ele?.status?.toLowerCase()?.includes(w.toLowerCase()) ||
          ele?.user?.name?.toLowerCase()?.includes(w.toLowerCase()),
      );
    });
  }, [searchQuery, requests]);
  const groupedRequestDataByStatus = useMemo(() => {
    return filteredListing.reduce((acc, cur) => {
      const { status } = cur;
      if (!acc[status]) {
        acc[status] = { status, data: [] };
      }
      acc[status].data.push(cur);
      return acc;
    }, {});
  }, [searchQuery, requests]);
  const groupedData: any = Object.values(groupedRequestDataByStatus);

  const handleSetCollapsed = (e, status) => {
    if (collapsed?.includes(status)) {
      updateRequestsState({ collapsed: collapsed.filter((s) => s !== status) });
    } else {
      updateRequestsState({ collapsed: [...collapsed, status] });
    }
  };
  return (
    <>
      <RequestListingList>
        {!isEmpty(groupedData) ? (
          groupedData?.map((r) => {
            const isExpanded = !collapsed.includes(r?.status);
            return (
              <>
                <StatusGroupAccordion
                  onClick={(e) => handleSetCollapsed(e, r?.status)}
                >
                  <span className={'statusLabel'}>
                    {r?.status}&nbsp;({r?.data?.length})
                  </span>
                  {isExpanded ? (
                    <ArrowUpIcon className={'arrowIcon'} />
                  ) : (
                    <ArrowDownIcon className={'arrowIcon'} />
                  )}
                </StatusGroupAccordion>
                {isExpanded &&
                  r?.data?.map((item) => {
                    return (
                      <RequestListingItem
                        selected={selectedRequestId == item?.id}
                        request={item}
                        key={item.id}
                      />
                    );
                  })}
              </>
            );
          })
        ) : (
          <EmptyState />
        )}
      </RequestListingList>
    </>
  );
}

export default RequestListing;
