import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import DeleteIcon3 from 'components/Icons/deleteIcon3';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';
import actions from 'store/actions';
import { useDeleteWidget, useWidget } from 'pages/Dashboards/hooks/useWidgets';
import { useParams } from 'react-router-dom';
import { useDashboard } from 'pages/Dashboards/hooks/useDashboard';
import { showMessageNotification } from 'utils/globalFunction';
import DeleteIcon from 'components/Icons/deleteIcon';
import DownloadIcon2 from 'components/Icons/downloadIcon2';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import { DashboardsContext } from 'pages/Dashboards/context/context';
interface IWidgetDropdownProps {
  exportChartToImage: () => void;
  widgetId: any;
  options?: any;
}
const WidgetActionDropdown = ({ exportChartToImage, widgetId, options }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    dashboardsState: { currentGridLayout },
    updateDashboardsState,
  } = useContext(DashboardsContext);
  const { deleteWidgetInDashboard } = useDashboard();
  const dispatch = useDispatch();
  const params = useParams();
  const { dashboardId } = params;
  const { deleteWidgetQuery } = useDeleteWidget(widgetId);

  const { queryWidgetById } = useWidget({
    apiParams: { dashboardId, widgetId: widgetId },
  });
  const data = queryWidgetById()?.widget;
  const handleWidgetDelete = () => {
    deleteWidgetQuery.mutate(
      { id: widgetId, dashboardId },
      {
        onSuccess: () => {
          deleteWidgetInDashboard(widgetId, currentGridLayout);
          const gridLayout =
            currentGridLayout?.filter((i) => i?.i?.substring(2) !== widgetId) ||
            null;
          updateDashboardsState({
            currentGridLayout: gridLayout,
          });
          showMessageNotification('Widget deleted Successfully', 'success');
        },
      },
    );
  };
  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'download':
        exportChartToImage();
        break;
      case 'edit':
        dispatch(
          setDialog({
            dialogId: DIALOGS.EDIT_WIDGET_DIALOG,
            open: true,
            data: {
              ...data,
              dashboardId,
            },
          }),
        );
        break;
      case 'delete':
        dispatch(
          actions.setDialog({
            dialogId: DIALOGS.CONFIRMATION,
            open: true,
            data: {
              title: 'Delete Widget',
              description: 'Are you sure you want to delete this widget?',
              onConfirmCallback: handleWidgetDelete,
              confirmButtonProps: {
                buttonLoader: deleteWidgetQuery?.isLoading,
              },
              // onConfirmCallbackParams: values,
              // onCancelCallback: () => setConfirmationData(null),
            },
          }),
        );
        break;
      default:
        return;
    }
  };

  const iconStyles = {
    sx: { fill: 'none', fontSize: 14 },
    style: { color: 'rgba(0, 0, 0, 0.54)' },
  };

  return (
    <ActionDropdown
      iconButton={true}
      rootProps={{
        id: 'export-action-dropdown',
        className: 'documents-action-dropdown',
      }}
      popperProps={{
        style: { width: 180 },
        placement: 'bottom-start',
      }}
      options={[
        ...(options.includes('download')
          ? [
              {
                label: 'Download',
                id: 'download',
                iconAlign: 'start',
                icon: (
                  <DownloadIcon2
                    sx={{ fill: 'none' }}
                    style={{ color: 'rgba(211, 47, 47, 1)', fontSize: 18 }}
                  />
                ),
                style: {
                  padding: '6px 16px',
                },
              },
            ]
          : []),
        ...(options.includes('edit')
          ? [
              {
                label: 'Edit',
                id: 'edit',
                iconAlign: 'start',
                icon: (
                  <EditIconPencilBottomLine
                    sx={{ fill: 'none' }}
                    style={{ color: 'rgba(211, 47, 47, 1)', fontSize: 18 }}
                  />
                ),
                style: {
                  padding: '6px 16px',
                },
              },
            ]
          : []),
        ...(options.includes('delete')
          ? [
              {
                label: 'Delete',
                id: 'delete',
                iconAlign: 'start',
                icon: (
                  <DeleteIcon
                    sx={{ fill: 'none' }}
                    style={{ color: 'rgba(211, 47, 47, 1)', fontSize: 18 }}
                  />
                ),
                style: {
                  padding: '6px 16px',
                  color: 'rgba(211, 47, 47, 1)',
                },
              },
            ]
          : []),
      ]}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: {
          padding: '9px 12px',
          borderRadius: 0,
        },
      }}
    >
      <MoreHorizIcon />
    </ActionDropdown>
  );
};

export default WidgetActionDropdown;
