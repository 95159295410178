import LockIconRounded from 'components/Icons/LockIconRounded';
import { useDispatch } from 'react-redux';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { TALK_TO_SALES_URL } from 'utils/constants';
import { LockCnt, MainCnt, Statement, TalkToSalesBtn, Title } from './style';

function AssetServicesPaidFeature() {
  const dispatch = useDispatch();
  const talkToSalesAction = () => {
    dispatch(
      setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <MainCnt>
      <LockCnt>
        <LockIconRounded style={{ fontSize: '40px' }} />
      </LockCnt>

      <Title>You’ve discovered a Paid Feature!</Title>

      <Statement>
        <span className="servicelog">Service Log</span> isn’t available on your
        current plan.
      </Statement>
      <Statement> Upgrade Now to access this feature.</Statement>

      <TalkToSalesBtn variant="contained" onClick={talkToSalesAction}>
        Talk to Sales
      </TalkToSalesBtn>
    </MainCnt>
  );
}

export default AssetServicesPaidFeature;
