import { useEffect, useState, Fragment } from 'react';

// React Router
import { useParams } from 'react-router-dom';

// Custom components
import PageHeader from './PageHeader';
import Spreadsheet from 'pages/TemplateDashboard/Tabs/Spreadsheet';

// MUI
import { CircularProgress } from '@mui/material';

// Styled
import { StyledCustomModal, SpreadsheetWrapper, LoaderWrapper } from './styled';

// Utils
import xeniaApi from 'api/index';

// Hooks
import { useTemplateAccess } from 'controller/useTemplateAccess';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';

const FullScreenSpreadsheet = () => {
  const { templateId } = useParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState<TemplateType | undefined>(undefined);

  const { isTemplateAdmin } = useTemplateAccess();

  useEffect(() => {
    fetchTemplate();
  }, []);

  // fetch data initially
  const fetchTemplate = async () => {
    const template = await xeniaApi.getChecklist(templateId);
    if (template?.data) {
      if (!template.data.name || !isTemplateAdmin(template.data)) {
        navigateWithWorkspaceUrl('/checklist');
      }
      setTemplate(template?.data);
      setLoading(false);
    } else {
      navigateWithWorkspaceUrl('/checklist');
    }
  };

  return (
    <StyledCustomModal
      fullScreen
      open={true}
      handleClose={() => console.log('')}
    >
      {loading && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
      {template && (
        <Fragment>
          <PageHeader template={template} />
          {template && (
            <SpreadsheetWrapper>
              <Spreadsheet viewMode="fullScreen" template={template} />
            </SpreadsheetWrapper>
          )}
        </Fragment>
      )}
    </StyledCustomModal>
  );
};

export default FullScreenSpreadsheet;
