import styled from '@mui/system/styled';
import Paper from '@mui/material/Paper';
import { Box, Grid, Stack } from '@mui/material';

export const SignInContainer = styled(Grid)({
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FormCnt = styled(Stack)(({ theme }) => ({
  padding: 20,
  width: 600,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: 10,
  },

  '& .headingCnt': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h4': {},
    '& h6': {},
  },
}));

export const InfoCnt = styled(Box)({
  padding: 20,
});

export const AbstractBackgroundCnt = styled('div')({
  height: '100vh',
  backgroundRepeat: 'no-repeat',
  backgroundPosition:
    '23% 53%, calc(100% - 450px) 100%, 0 100%, calc(100% - 40px) calc(100% - 20px), 79% 41%, 64px 0, 33% 232px, calc(100% - 64px) 0, 0 50%',
});

export const BackBtn = styled(Stack)({
  cursor: 'pointer',
  backgroundColor: 'white',
  position: 'absolute',
  left: 25,
  top: 25,
  borderRadius: 100,
  border: '1px solid #A9A9FF',
  gap: 2,
  padding: '7px 14px 7px 8px',
  flexDirection: 'row',

  '& svg': {
    height: '14px !important',
    '& path': {
      color: '#4E48FA',
    },
  },

  '& .backText': {
    fontWeight: 600,
    marginLeft: -5,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#4E48FA',
  },
});

export const OnBoardingBackground = styled('div')(({ theme }) => ({
  height: '100%',
  background: 'url(/assets/img/tasklist-blur.png)',
  backgroundSize: 'cover !important',
  [theme.breakpoints.down('md')]: {
    minHeight: 'unset',
  },
}));

export const LoginCnt = styled(Paper)({
  marginTop: 79,
  padding: '32px 40px',
  borderRadius: 15,
  boxShadow:
    '0px 6px 24px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
  '& .headingCnt': {
    marginBottom: 32,
  },
  '& h4': {
    fontSize: 24,
    fontWeight: 700,
  },
  '& h6': {
    fontSize: 14,
    color: '#00000099',
    marginTop: 10,
  },
  '& .signupLink': {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'rgba(104,104,254,1)',
  },
});
export const LoginOuterCnt = styled('div')({
  width: 558,
  margin: '0 auto 0 auto',
  paddingTop: 110,
  textAlign: 'center',
});
export const DividerWithText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: 320,
  margin: '16px auto',
  '& .line': {
    height: 1,
    flex: 1,
    background: 'rgba(0,0,0,0.23)',
  },
  '& .text': {
    fontSize: 13,
    color: 'rgba(0,0,0,0.6)',
    display: 'inline-block',
    margin: '0 5px',
  },
});

// calc(100% - 40px) calc(100% - 20px)
