import { useDispatch } from 'react-redux';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import EditIcon from 'components/Icons/editIcon';
import ColorPickerIcon from 'components/Icons/colorPickerIcon';
import ArchiveIcon from 'components/Icons/archiveIcon';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';

const folderActionDropdownOptions = [
  {
    label: 'Rename',
    id: 'rename',
    icon: <EditIcon style={{ fontSize: 18 }} />,
    iconAlign: 'start',
  },
  {
    label: 'Change Color',
    id: 'change_color',
    icon: (
      <ColorPickerIcon
        sx={{
          fill: 'none',
        }}
      />
    ),
    iconAlign: 'start',
  },
  {
    label: 'Archive',
    id: 'archive',
    icon: <ArchiveIcon />,
    iconAlign: 'start',
  },
];

interface IFolderActionDropdownProps {
  updateFolderCallback: (folder: any) => void;
  archiveFolderCallback: () => void;
  folder: any;
}
function FolderActionDropdown(props: IFolderActionDropdownProps) {
  const { updateFolderCallback, archiveFolderCallback, folder } = props;
  const dispatch = useDispatch();

  const updateColorHandler = (data) => {
    updateFolderCallback(data);
    if (globalThis?.documentsListView) {
      globalThis?.documentsListView?.api?.redrawRows();
    }
  };

  //Handler for option select of action dropdown
  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'rename':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.RENAME_DOCUMENT_FOLDER_DIALOG,
            data: { folder, onSuccessCallback: updateFolderCallback },
          }),
        );
        break;
      case 'change_color':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.DOCUMENT_FOLDER_COLOR_DIALOG,
            data: { folder, onSuccessCallback: updateColorHandler },
          }),
        );
        break;
      case 'archive':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.ARCHIVE_DOCUMENT_FOLDER_DIALOG,
            data: {
              folder,
              onSuccessCallback: (folderId) => {
                archiveFolderCallback();
              },
            },
          }),
        );
        break;
    }
  };

  return (
    <ActionDropdown
      rootProps={{
        id: 'folders-action-dropdown',
      }}
      options={folderActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { padding: 2, transform: 'rotate(90deg)' },
      }}
      popperProps={{
        placement: 'bottom-start',
        sx: {
          zIndex: 1301,
        },
      }}
    >
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
}

export default FolderActionDropdown;
