import {
  EmptyStateCnt,
  EmptyStateText,
} from './taskByCategoryEmptyState.style';
import React from 'react';
import PieChartIcon from 'components/Icons/pieChartIcon';

const TasksByCategoryEmptyState = (props) => {
  return (
    <EmptyStateCnt>
      <PieChartIcon />
      <EmptyStateText>No record available</EmptyStateText>
    </EmptyStateCnt>
  );
};

export default TasksByCategoryEmptyState;
