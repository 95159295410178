import { FC, useState } from 'react';
import {
  DialogContent,
  DialogActions,
  Divider,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import CustomButton from 'components/Button/CustomButton';
import RichTextEditor from 'components/RichTextEditor';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { StyledDialog, StyledCustomInput } from './styled';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';

interface IProps {
  checklistItem: any;
  handleSave: (description: string) => void;
  open: boolean;
  handleClose: () => void;
}

const StepDescriptionDialog: FC<IProps> = (props) => {
  const { checklistItem, handleSave, open, handleClose } = props;

  if (!open) return <></>;

  const { info } = checklistItem;

  const [description, setDescription] = useState<string>(
    info?.optionDescription || '',
  );

  return (
    <StyledDialog disableEnforceFocus={true} open={open}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pl={2}
        py={2}
        spacing={1}
      >
        <FormatAlignLeftIcon />
        <Typography
          sx={{
            color: '#000',
            fontSize: '20px',
            fontWeight: '800',
          }}
        >
          Add Instructions
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <Divider />
      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            padding: '0px',
          },
        }}
      >
        <RichTextEditor
          value={description}
          onChange={setDescription}
          placeholder={'Enter instructions....'}
          toolbar={
            'bold italic underline strikethrough |  numlist bullist checklist'
          }
        />
      </DialogContent>
      <Divider sx={{ mb: 1 }} />

      <DialogActions>
        <CustomButton variant="outlined" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={() => {
            handleSave(description);
            handleClose();
          }}
        >
          Save
        </CustomButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default StepDescriptionDialog;
