import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import { WidgetCnt, WidgetHeading, WidgetSubHeading } from './style';
import useTasksFiltersConditions from 'utils/CustomHooks/useTasksFiltersConditions';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';

function ScheduleCompletionOverview(props) {
  const { title, data, error = '', reload, loading, globalFilters } = props;
  const dispatch = useDispatch();

  const {
    recurringInComplete,
    recurringCompletedLate,
    recurringCompletedOnTime,
  } = useTasksFiltersConditions();

  const COLORS = {
    'Completed on Time': '#4CAF50',
    'Completed Late': '#EF5350',
    Incomplete: '#FB8C00',
  };

  const formatLegendText = (_, entry) => {
    const [percentage] = entry.value.split(' ');

    const index = entry.value.indexOf(' ');
    const status = entry.value.substring(index + 1);
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            fontWeight: '700',
            fontSize: 18,
            lineHeight: '24.59px',
            color: '#212121',
            marginLeft: 10,
          }}
        >
          {percentage}
        </span>
        <span
          style={{
            marginLeft: 5,
            fontWeight: '500',
            fontSize: 14,
            lineHeight: '19.12px',
            color: '#424242',
          }}
        >
          {status}
        </span>
      </div>
    );
  };

  const clickHandler = (item) => {
    let advancedFilters: any = [];

    if (item?.valueX === 'Incomplete') {
      advancedFilters = recurringInComplete();
    }

    if (item?.valueX === 'Completed Late') {
      advancedFilters = recurringCompletedLate();
    }

    if (item?.valueX === 'Completed on Time') {
      advancedFilters = recurringCompletedOnTime();
    }

    if (globalFilters?.fromDate && globalFilters?.toDate) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'dueDate',
        value: [globalFilters?.fromDate, globalFilters?.toDate],
      });
    }
    if (globalFilters?.locations?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'locations',
        value: globalFilters.locations,
      });
    }

    if (globalFilters?.projects?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'projects',
        value: globalFilters.projects,
      });
    }

    if (globalFilters?.schedules?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'schedules',
        value: globalFilters.schedules,
      });
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TASK_TABLE_DIALOG,
        data: {
          filters: advancedFilters,
        },
      }),
    );
  };

  return (
    <WidgetCnt>
      <WidgetHeading style={{ padding: '18px 24px 0px 24px' }}>
        {title}
      </WidgetHeading>
      <WithLoader isLoading={loading}>
        <WithErrorState type={'bar'} error={error} reload={reload}>
          {!loading && (
            <WidgetSubHeading style={{ padding: '0px 24px' }}>
              {data?.summary?.completedOnTime}%
            </WidgetSubHeading>
          )}
          <ResponsiveContainer>
            <PieChart>
              <Pie
                dataKey="valueY"
                data={data?.chart}
                cx="50%"
                cy="50%"
                innerRadius={90}
                outerRadius={110}
                fill="#8884d8"
                labelLine={false}
              >
                {data?.chart?.map((item, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[item?.valueX]}
                    onClick={() => clickHandler(item)}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
              </Pie>

              <text
                x="50%"
                y="50%"
                dy={16}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={24}
                style={{
                  transform: 'translate(-98px, -20px)',
                  fontWeight: 700,
                  fontSize: 32,
                  lineHeight: '43.71px',
                  color: '#000000',
                }}
              >
                {data?.summary?.completedOnTime}%
              </text>
              <text
                x="50%"
                y="60%"
                width="100"
                height="100"
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                  transform: 'translate(-100px, -8px)',
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: '19.12px',
                  color: '#000000',
                }}
              >
                Completed on Time
              </text>
              <Legend
                iconSize={10}
                align="right"
                verticalAlign="middle"
                layout="vertical"
                formatter={formatLegendText}
                payload={data?.chart?.map((item) => ({
                  value: `${item.valueY}% ${item.valueX}`,
                  color: COLORS[item?.valueX],
                }))}
                wrapperStyle={{ margin: '0', padding: '0' }}
              />
            </PieChart>
          </ResponsiveContainer>
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
}

export default ScheduleCompletionOverview;
