import { FC } from 'react';

import { ConstantChannelTypeString } from 'utils/globalVariable';
import { isMutedChannel } from 'services/getStreamServices';
import VolumeXIcon from 'components/Icons/volume2Icon';
import Volume2Icon from 'components/Icons/volume2Icon';
import EyeOffIcon from 'components/Icons/eyeoffIcon';
import ArchiveIcon from 'components/Icons/archiveIcon';
import { colors } from 'components/theme/constants';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';

export const LeftMenuChannelActions: FC<any> = ({
  absolutePositioning = false,
  channel,
  openChannelActionModal,
}: any) => {
  const isMute = (channel && isMutedChannel(channel)) || false;
  const { isArchived, channelType } = channel?.data || {
    isArchived: false,
    channelType: 'Group',
  };
  const isDM = channelType === ConstantChannelTypeString.DIRECT_MESSAGE;

  const actionDropdownOptions: any = [
    {
      label: isMute ? 'Unmute' : 'Mute',
      id: 'mute',
      modal: 'mute',
      icon: isMute ? <VolumeXIcon /> : <Volume2Icon />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      hide: isArchived,
    },
    {
      label: 'Hide',
      id: 'hide',
      modal: 'hide',
      icon: <EyeOffIcon />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      hide: !(isDM && !isArchived),
    },
    {
      label: isArchived ? 'Unarchive' : 'Archive',
      id: 'archive',
      modal: 'archive',
      icon: <ArchiveIcon />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      hide: isDM,
      sx: {
        color: `${colors.red}`,
        borderTop: '#0000001F 1px solid',
      },
    },
  ];

  return (
    <div className={`actionBtnWrap ${absolutePositioning ? 'absolute' : ''}`}>
      <ActionDropdown
        options={actionDropdownOptions}
        handleOptionSelect={(option) => {
          openChannelActionModal({ modal: option.modal, channel: channel });
        }}
        buttonProps={{ style: { padding: '6px' } }}
      >
        <MoreVertOutlinedIcon style={{ fontSize: 20 }} />
      </ActionDropdown>
    </div>
  );
};
