import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from 'components/Icons/addIcon';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import useCategory from 'controller/useCategory';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import ColorIcon from 'components/Icons/colorIcon';
import selectors from 'store/selectors';
import ErrorIcon from '@mui/icons-material/Error';
import ToolTip from 'components/Tooltip/tooltip';
import { AddContentCnt, CreateCategory } from './categories.style';
import { COLORS_LIST } from 'utils/constants';
import { colors } from 'components/theme/constants';
import { isError } from './utils';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

export default () => {
  const [isCreate, setIsCreate] = useState(false);
  const [category, setCategory]: any = useState({
    type: '',
    color: '',
  });

  const [error, setError] = useState('');

  const serviceList: any = useSelector(selectors.getServiceAdmin);
  const { FEATURES } = useCustomPermission();

  useEffect(() => {
    let currentColor = serviceList?.[0]?.color;
    let newColor = COLORS_LIST[0];

    if (currentColor) {
      currentColor = COLORS_LIST.find((item) => item.color === currentColor);
      const currentIndex = COLORS_LIST.indexOf(currentColor);
      let newIndex = 0;
      if (currentIndex !== COLORS_LIST.length - 1) {
        newIndex = currentIndex + 1;
      }
      newColor = COLORS_LIST[newIndex];
    }

    setCategory({
      ...category,
      color: newColor?.color,
    });
  }, [serviceList, isCreate]);

  const { addHandler } = useCategory(null);

  const handleNewCategory = (e) => {
    setError('');
    setCategory({
      ...category,
      type: e.target.value,
    });
  };

  const clearAdd = () => {
    setCategory({
      type: '',
      color: '',
    });
    setError('');
  };

  let defaultColor: any = COLORS_LIST[0];

  if (category?.color) {
    defaultColor = COLORS_LIST.find((item) => item.color === category.color);
  }

  const addCategory = () => {
    if (!isError(category?.type, serviceList, setError)) {
      addHandler(category);
      clearAdd();
    }
  };

  const keyDownHandler = (e) => {
    if (e.keyCode === 13) {
      addCategory();
    }
  };

  return isCreate ? (
    <>
      <CreateCategory>
        <ColorIcon
          primary={defaultColor?.color}
          secondary={defaultColor?.borderColor}
          style={{ width: '24px', height: '24px', color: category.color }}
        />
        <CustomInput
          sx={{ mb: 0 }}
          fieldProps={{
            placeholder: 'Enter category title',
            value: category?.type,
            autoFocus: true,
            onClick: (e) => e.stopPropagation(),
            onChange: handleNewCategory,
            onKeyDown: keyDownHandler,
            className: 'inputCnt',
            endAdornment: error ? (
              <ToolTip placement="top" arrow title={error}>
                <ErrorIcon className="error" />
              </ToolTip>
            ) : null,
          }}
          suppressBorder={true}
        />
        <CustomButton
          variant={'text'}
          className="cancelBtn"
          disableElevation
          onClick={() => {
            setIsCreate(false);
            clearAdd();
          }}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          disableElevation
          className="addBtn"
          onClick={addCategory}
        >
          Add
        </CustomButton>
      </CreateCategory>
      {error && <span className="error">{error}</span>}
    </>
  ) : (
    <AddContentCnt
      onClick={() => {
        setIsCreate(true);
      }}
    >
      <AddIcon style={{ color: colors.white }} />
      <CTypography className="content">Add New Category</CTypography>
    </AddContentCnt>
  );
};
