import {
  CreateRequestInfoStyled,
  RequestsNoAccessEmptyStateCnt,
} from 'pages/Requests/requests.style';
import { Box, Grid } from '@mui/material';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';
import CustomButton from 'components/Button/CustomButton';
import { TALK_TO_SALES_URL } from 'utils/constants';

function RequestsFeatureEmptyState() {
  const dispatch = useDispatch();
  const handleTalkToSales = () => {
    dispatch(
      actions.setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <RequestsNoAccessEmptyStateCnt>
      <CreateRequestInfoStyled>
        <Grid
          className="container"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="infoContainer">
            <p className="title">Manage and Track Requests</p>
            <p className="description">
              <b>Xenia’s Requests</b> streamlines maintenance and service
              request management. View inbound requests, approve and assign to
              the appropriate team in seconds!
            </p>
            <p className={'taskToSalesText'}>
              Talk to sales to get access to this feature
            </p>
            <Box className="buttonsContainer">
              <CustomButton
                variant="contained"
                style={{ marginRight: 16 }}
                onClick={handleTalkToSales}
              >
                Talk to Sales
              </CustomButton>
              <CustomButton
                variant="outlined"
                onClick={() => {
                  window?.open(
                    'https://help.xenia.team/hc/en-us/articles/15486208241947-Requests-Xenia-Feature-Overview',
                    '_blank',
                  );
                }}
              >
                Learn about this feature
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      </CreateRequestInfoStyled>
    </RequestsNoAccessEmptyStateCnt>
  );
}

export default RequestsFeatureEmptyState;
