import { useMemo, useEffect, useCallback } from 'react';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';

// Constants
import { columns, defaultColDef } from './constants';

// Styles
import './tasksByLocationsTable.style.css';

interface IProps {
  height?: number | string;
  rowData: any;
  onRowClick?: (rowId: string) => void;
  onColumnChange?: (columns: any) => void;
  onBulkSelection?: (selected: any[]) => void;
  onTaskActionSuccess?: () => void;
  updateColumnStateCb?: (columnState: any) => void;
  isSizeColumnFit?: boolean;
  columnState?: any;
  columnConfig?: {
    title?: boolean;
    open?: boolean;
    inProgress?: boolean;
    onHold?: boolean;
    completed?: boolean;
    missed?: boolean;
    completedOnTime?: boolean;
    completedLate?: boolean;
    incomplete?: boolean;
  };
}

const defaultColumnConfig = {
  title: true,
  open: true,
  inProgress: true,
  onHold: true,
  completed: true,
  missed: true,
  completedOnTime: true,
  completedLate: true,
  incomplete: true,
};

const ListView = ({
  rowData,
  height = 400,
  columnConfig,
  columnState,
  onRowClick,
  updateColumnStateCb,
  isSizeColumnFit,
}: IProps) => {
  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);

  const onColumnResized = (event) => {
    if (event?.finished && event.source !== 'api') {
      updateColumnStateCb?.(event.columnApi.getColumnState());
    }
  };

  const handleUpdateColumnState = (event) => {
    if (event.type === 'dragStopped') {
      updateColumnStateCb?.(event.columnApi.getColumnState());
    }
  };

  const gridColumns = useMemo(() => {
    const staticColumns = columns({
      onRowClick,
      columnConfig: {
        ...defaultColumnConfig,
        ...columnConfig,
      },
    });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = columnState?.find((c) => c.colId === col.colId);
      const index = columnState?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [columnState, onRowClick, columnConfig, rowData]);

  useEffect(() => {
    globalThis?.tasksByLocations?.current?.refreshCells();
  }, [rowData]);

  return (
    <CustomListView
      rootProps={{
        className: 'tasks-by-locations-completion',
        style: {
          height,
          boxShadow: 'none',
          flex: 1,
        },
      }}
      onRowClick={onRowClick}
      gridProps={{
        ref: (ref) => {
          globalThis.tasksByLocations = ref;
        },
        onGridReady: (params) => {
          isSizeColumnFit && params.api.sizeColumnsToFit();
        },
        headerHeight: 30,
        onSortChanged: handleUpdateColumnState,
        onDragStopped: handleUpdateColumnState,
        onColumnResized: onColumnResized,
        onColumnVisible: handleUpdateColumnState,
        onColumnPinned: handleUpdateColumnState,
        onColumnMoved: handleUpdateColumnState,
        rowData,
        suppressChangeDetection: true,
        defaultColDef: defaultColDef,
        columnDefs: gridColumns,
        suppressCellFocus: true,
        getRowHeight: getRowHeight,
        suppressRowClickSelection: true,
        getRowId: (params) => params.data.id,
      }}
    />
  );
};

export default ListView;
