import xeniaApi from 'api/index';
import {
  deleteAssets,
  setAuthAssets,
  updateAssets,
} from 'store/actions/assetActions';
import { useDispatch } from 'react-redux';
import { createAsset } from 'api/assets';
import actions from 'store/actions';

function useUpdateAsset() {
  const dispatch = useDispatch();
  //Updates assset on the backend and dispatch into the store
  const updateAsset = async (data) => {
    const {
      name,
      id,
      description,
      avatar,
      serialNumber,
      isQREnable,
      model,
      LocationId,
      purchaseDate,
      attachments,
    } = data;
    const payload = {
      name,
      id,
      description,
      avatar,
      serialNumber,
      isQREnable,
      model,
      LocationId,
      purchaseDate,
      attachments,
    };
    dispatch(updateAssets(data));
    const updatedAsset = await xeniaApi.updateAsset(payload);
    if (updatedAsset) {
      dispatch(updateAssets(updatedAsset));
      const authAssets = await xeniaApi.getAuthAssets();
      dispatch(setAuthAssets(authAssets));
    }
  };
  const addSubAsset = async (data) => {
    createAsset(data).then((res) => {
      dispatch(actions.addSubAsset(res));
    });
  };
  //delete locations on the backend and updates in the store
  const deleteAsset = async (data) => {
    dispatch(deleteAssets(data));
    const deleteAsset = await xeniaApi.deleteAssets(data);

    const authAssets = await xeniaApi.getAuthAssets();
    dispatch(setAuthAssets(authAssets));

    return deleteAsset;
  };
  return { updateAsset, deleteAsset, addSubAsset };
}

export default useUpdateAsset;
