import { useCallback, useMemo, useRef } from 'react';

import {
  WidgetCnt,
  WidgetContentWrapper,
  WidgetHeading,
} from 'pages/Dashboards/Widgets/widgets.style';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import TasksByAssigneeEmptyState from 'pages/Widgets/TasksByAssignee/EmptyRecords/TaskListWidgetEmptyState.cmp';
import WidgetActions from 'pages/Dashboards/Widgets/widgetActions';
import { useLoadWidget } from 'pages/Dashboards/Widgets/useLoadWidget';
import { exportToPng } from 'utils/globalFunction';
import { getRenderValue } from 'utils/widget';
import { IWidgetData } from 'pages/Dashboards/interfaces';
import WidgetAdvanceFilter from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter';
import WithErrorState from '../errorStateCmp/errorState';
import WithLoader from '../withLoader';
import {
  useViewFlaggedItems,
  useViewSubmissions,
  useViewTasks,
} from 'pages/Dashboards/hooks/useWidgets';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import { Stack, Typography } from '@mui/material';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const STATUS_COLORS = {
  'In Progress': '#F58500',
  'On Hold': '#64636D',
  Missed: '#EF5350',
  Open: '#3B8EF0',
  Completed: '#04B86C',
};

interface IPieChartWidgetProps {
  widgetId: string;
  editable?: boolean;
  globalFilters?: any;
}

function PieChartWidget({
  widgetId,
  editable,
  globalFilters,
}: IPieChartWidgetProps) {
  const chartRef: any = useRef(null);
  const { parsedData, getWidgetQuery, widget, summary } = useLoadWidget(
    widgetId,
    globalFilters,
  );

  const { handleViewTasks } = useViewTasks();
  const { handleViewSubmissions } = useViewSubmissions();
  const { handleViewFlaggedItemsChartWidgets } = useViewFlaggedItems();

  const exportChartToImage = () => {
    exportToPng(chartRef.current, widget?.title);
  };
  const error = getWidgetQuery.error;
  const loading = getWidgetQuery.isFetching;

  const colorPicker = useCallback(
    (item, index) => {
      return STATUS_COLORS[item?.valueX] || COLORS[index % COLORS.length];
    },
    [COLORS, STATUS_COLORS],
  );

  const CustomLabel = ({
    cx,
    cy,
    value,
    innerRadius,
    outerRadius,
    midAngle,
    percent,
    valueX,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) + 25;
    const cos = Math.cos(-RADIAN * midAngle);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentage = `${(percent * 100).toFixed(1)}%`;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <text
        cx={cx}
        cy={cy}
        stroke="none"
        x={x}
        y={y}
        alignmentBaseline="middle"
        className="recharts-text recharts-pie-label-text"
        textAnchor={textAnchor}
        fill={'#212121'}
      >
        <tspan
          x={x}
          dy="5px"
          fontSize={13}
          fontWeight={800}
          letterSpacing={'0.17px'}
        >
          {valueX}&nbsp;
          <tspan>{value}</tspan>
          <tspan>&nbsp;({percentage})</tspan>
        </tspan>
      </text>
    );
  };

  const handleBarClick = (e: any) => {
    if (widget?.entity === 'task') {
      handleViewTasks(
        widget,
        e.metadata,
        getWidgetQuery.refetch,
        globalFilters,
      );
    }

    if (widget?.entity === 'scheduledTask') {
      handleViewTasks(
        widget,
        e.metadata,
        getWidgetQuery.refetch,
        globalFilters,
        true,
      );
    }

    if (widget?.entity == 'submission') {
      handleViewSubmissions(
        widget,
        e.metadata,
        getWidgetQuery.refetch,
        globalFilters,
      );
    }

    if (
      widget?.entity === DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS &&
      widget?.type !== WIDGET_TYPE_IDS.CUSTOM_TABLE
    ) {
      handleViewFlaggedItemsChartWidgets(
        widget,
        e?.metadata,
        getWidgetQuery.refetch,
        globalFilters,
      );
    }
  };

  const isPercentage = useMemo(() => {
    const pattern = /percentage|averageScore/;
    return pattern.test(widget?.config?.variable);
  }, [widget]);
  const isDuration = useMemo(() => {
    const pattern = /totalDuration|averageDuration/;
    return pattern.test(widget?.config?.variable);
  }, [widget]);
  return (
    <WidgetCnt ref={chartRef}>
      <WidgetHeading>
        <Stack
          direction="column"
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Typography className="title" title={widget?.title}>
            {widget?.title}
          </Typography>
          {summary?.total >= 0 && (
            <Typography className="count">{summary?.total}</Typography>
          )}
        </Stack>
        <div className={'widgetActionsCnt'}>
          <WidgetAdvanceFilter
            widgetId={widgetId}
            globalFilters={globalFilters}
          />{' '}
          <WidgetActions
            exportChartToImage={exportChartToImage}
            widgetId={widgetId}
            editable={editable}
          />
        </div>
      </WidgetHeading>
      <WithLoader isLoading={loading}>
        <WithErrorState
          type={'pie'}
          error={error}
          reload={() => getWidgetQuery.refetch()}
        >
          {parsedData?.length ? (
            <WidgetContentWrapper>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart
                  width={400}
                  height={500}
                  margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
                >
                  <Pie
                    dataKey="valueY"
                    onClick={handleBarClick}
                    isAnimationActive={false}
                    data={parsedData}
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label={CustomLabel}
                    minAngle={15}
                  >
                    {parsedData?.map((entry: IWidgetData, index: number) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colorPicker(entry, index)}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    contentStyle={{ background: 'black', color: '#fff' }}
                    itemStyle={{ color: '#fff' }}
                    formatter={(value) => [
                      `${getRenderValue(value, isPercentage, isDuration)}`,
                    ]}
                  />
                </PieChart>
              </ResponsiveContainer>
            </WidgetContentWrapper>
          ) : (
            <TasksByAssigneeEmptyState />
          )}
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
}

export default PieChartWidget;
