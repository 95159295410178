import React, { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { DropdownWithLabelCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';

const TaskLocationDropdown = () => {
  const { updateTask, newTask, setNewTaskObj, project } =
    useContext(CreateTaskContext);

  const dispatch = useDispatch();
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'editTask',
      }),
    );
  };
  const handleUpdateLocationCreate = (values) => {
    const { AssetId, ...rest } = newTask;
    setNewTaskObj({ ...rest, locationIds: [values?.[0]?.id] });
  };

  const handleUpdateLocationEdit = (values) => {
    const { ...rest } = newTask;
    setNewTaskObj({
      ...rest,
      LocationId: values?.[0]?.id || null,
      AssetId: null,
      Asset: null,
      Location: null,
    });
  };

  return (
    <DropdownWithLabelCnt>
      <label>Location</label>
      <LocationsDropdown
        onAddLocationCallback={closeAction}
        onChangeCallback={handleUpdateLocationEdit}
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        dropdownBtnProps={{
          disabled: project?.id,
        }}
        selected={
          newTask?.locationIds ||
          newTask?.Location?.id ||
          newTask?.LocationId ||
          newTask?.Asset?.Location?.id
        }
        isMulti={false}
        viewType={'select'}
      />
    </DropdownWithLabelCnt>
  );
};

export default TaskLocationDropdown;
