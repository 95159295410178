import { createSvgIcon } from '@mui/material';

const AssetsUsageIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="34" height="34" rx="8" fill="#4DB6AC" />
      <path
        opacity="0.12"
        d="M20.7503 18.2497C23.2816 18.2497 25.3337 17.5035 25.3337 16.583V22.833C25.3337 23.7535 23.2816 24.4997 20.7503 24.4997C18.219 24.4997 16.167 23.7535 16.167 22.833V16.583C16.167 17.5035 18.219 18.2497 20.7503 18.2497Z"
        fill="#00695C"
      />
      <path
        d="M13.2503 12.8333C15.7816 12.8333 17.8337 12.0871 17.8337 11.1667C17.8337 10.2462 15.7816 9.5 13.2503 9.5C10.719 9.5 8.66699 10.2462 8.66699 11.1667C8.66699 12.0871 10.719 12.8333 13.2503 12.8333Z"
        fill="#B2DFDB"
      />
      <path
        d="M20.7503 18.25C23.2816 18.25 25.3337 17.5038 25.3337 16.5833C25.3337 15.6629 23.2816 14.9167 20.7503 14.9167C18.219 14.9167 16.167 15.6629 16.167 16.5833C16.167 17.5038 18.219 18.25 20.7503 18.25Z"
        fill="#B2DFDB"
      />
      <path
        d="M16.167 16.5833C16.167 15.6629 18.219 14.9167 20.7503 14.9167C23.2816 14.9167 25.3337 15.6629 25.3337 16.5833V22.8333C25.3337 23.7538 23.2816 24.5 20.7503 24.5C18.219 24.5 16.167 23.7538 16.167 22.8333V16.5833Z"
        fill="#B2DFDB"
      />
      <path
        d="M17.8337 11.1667C17.8337 12.0871 15.7816 12.8333 13.2503 12.8333C10.719 12.8333 8.66699 12.0871 8.66699 11.1667M17.8337 11.1667C17.8337 10.2462 15.7816 9.5 13.2503 9.5C10.719 9.5 8.66699 10.2462 8.66699 11.1667M17.8337 11.1667V12.4167M8.66699 11.1667V21.1667C8.66699 22.0871 10.719 22.8333 13.2503 22.8333M13.2503 16.1667C13.1099 16.1667 12.9709 16.1644 12.8337 16.1599C10.4976 16.0833 8.66699 15.3694 8.66699 14.5M13.2503 19.5C10.719 19.5 8.66699 18.7538 8.66699 17.8333M25.3337 16.5833C25.3337 17.5038 23.2816 18.25 20.7503 18.25C18.219 18.25 16.167 17.5038 16.167 16.5833M25.3337 16.5833C25.3337 15.6629 23.2816 14.9167 20.7503 14.9167C18.219 14.9167 16.167 15.6629 16.167 16.5833M25.3337 16.5833V22.8333C25.3337 23.7538 23.2816 24.5 20.7503 24.5C18.219 24.5 16.167 23.7538 16.167 22.8333V16.5833M25.3337 19.7083C25.3337 20.6288 23.2816 21.375 20.7503 21.375C18.219 21.375 16.167 20.6288 16.167 19.7083"
        stroke="#00695C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AssetsUsageIcon',
  );
  return <IconCmp viewBox={'0 0 34 34'} fill="none" {...props} />;
};

export default AssetsUsageIcon;
