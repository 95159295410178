import { IconButton, Tooltip } from '@mui/material';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import React from 'react';
import { EditIconButton } from 'pages/Dashboards/Widgets/widgetActions/editButton/edit.style';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWidget } from 'pages/Dashboards/hooks/useWidgets';
interface IEditButtonProps {
  widgetId: string;
}
function EditButton({ widgetId }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { dashboardId } = params;
  const { queryWidgetById } = useWidget({
    apiParams: { dashboardId, widgetId: widgetId },
  });
  const data = queryWidgetById()?.widget;
  const handleEditdWidget = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.EDIT_WIDGET_DIALOG,
        open: true,
        data: {
          ...data,
          dashboardId,
        },
      }),
    );
  };
  return (
    <Tooltip placement="top" arrow title="Edit">
      <EditIconButton onClick={handleEditdWidget}>
        <EditIconPencilBottomLine
          sx={{
            fontSize: 16,
            '& path': {
              stroke: '#424242',
              strokeOpacity: 1,
              strokeWidth: 1,
            },
          }}
        />
      </EditIconButton>
    </Tooltip>
  );
}

export default EditButton;
