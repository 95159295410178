import { Box, Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const MainPermission = styled(Stack)({
  backgroundColor: '#F5F6FA',
  border: '1px solid #EEEEEE',
  padding: '10px 30px 10px 30px',

  '& .content': {
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#212121',
  },
});

export const SubPermission = styled(Stack)({
  borderTop: '1px solid #EEEEEE',
  padding: '12px 10px 12px 10px',
  marginLeft: 18,
  '& .title': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
  '& .description': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',
  },
});

export const PermissionsListingCnt = styled(Box)({
  marginTop: 5,
  height: 'calc(100vh - 400px)',
  overflowY: 'auto',
});

export const EmptyCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: 300,
});

export const SearchPermissions = styled('div')(({ theme }) => ({
  width: 310,
  marginRight: 24,
  marginTop: 10,
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginRight: 0,
    maxWidth: '295px',
  },
  '& input': {
    padding: '8.5px 14px 8.5px 7px',
    '&::-webkit-input-placeholder': {
      fontSize: 14,
    },
  },
  '& svg': {
    color: '#0000008A',
  },

  '& legend': {
    width: 0,
  },
}));

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});
