import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { setDialog } from 'store/actions/dialogActions';
import { getDialogState } from 'store/selectors/dialogSelectors';
import FlaggedItemsDetails from 'components/DataTables/Reports/FlaggedItems/FlaggedItemsDetails';
import { useSubmissionsUtils } from 'utils/CustomHooks/useSubmissionsUtils';
import DIALOGS from 'utils/dialogIds';
import xeniaApi from 'api/index';
import { ContentCnt, LoaderCnt } from './style';

function FlaggedItemsTableDialog() {
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { transformaToFlaggedItems, transformaToFlaggedItemsQuestions } =
    useSubmissionsUtils();
  const dialogState = useSelector(getDialogState);
  const state = dialogState?.[DIALOGS.FLAGGED_ITEMS_REPORT_DIALOG];

  const { open, data } = state;
  const { filters = null, title, type, itemId } = data;

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.FLAGGED_ITEMS_REPORT_DIALOG,
      }),
    );
  };

  const fetchTaskList = async () => {
    const requestPayload = {
      ...filters,
    };
    const response = await xeniaApi.getAllChecklistLogs(requestPayload, true);

    let transformedData: any = [];

    if (type === 'question') {
      transformedData = transformaToFlaggedItemsQuestions(
        response?.data ?? [],
        itemId,
      );
    } else {
      transformedData = transformaToFlaggedItems(response?.data ?? []);
    }

    setTableData(transformedData ?? []);
    setLoading(false);
  };

  useEffect(() => {
    fetchTaskList();
  }, [filters]);

  return (
    <CustomDialog
      hideActions={true}
      header={
        <CustomDialogHeader onClose={handleClose}>
          <Typography
            fontWeight="700"
            fontSize="18px"
            lineHeight="24.59px"
            color="#000000"
          >
            {title}
          </Typography>
          {!loading && (
            <Typography
              fontWeight="800"
              fontSize="26px"
              lineHeight="35.52px"
              color="#000000"
            >
              {tableData?.length || 0}
            </Typography>
          )}
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        {loading ? (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        ) : (
          <FlaggedItemsDetails rowData={tableData} />
        )}
      </ContentCnt>
    </CustomDialog>
  );
}

export default FlaggedItemsTableDialog;
