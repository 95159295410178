import { useCallback } from 'react';
import actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import useTaskList from 'utils/CustomHooks/useTasksList';
import {
  checkEmptyFilters,
  validateAdvancedFilter,
} from 'utils/CustomHooks/useTaskFilters/utils';
import { formatFilters } from 'utils/Task/filters';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useUserConfig } from 'controller/useUserConfig';
import useTasksConfig from '../useTasksTabs';
import { getTaskListFilters } from 'store/selectors/task';

type OptionsType = {
  skipFilterSave?: boolean;
};
function useTaskFilters() {
  const dispatch = useDispatch();
  const { handleClearFilter, getTasksCommon } = useTaskList();
  const { workspaceId } = useWorkspaceHook();
  const { getUserConfig } = useUserConfig();
  const { updateTabConfigState, updateTasksConfig } = useTasksConfig();
  const allTabsfilterData: any = useSelector(getTaskListFilters);
  const userId = useSelector((state: any) => state?.authData?.user?.id);
  const configKey = `tasksViewsConfig::${workspaceId}-${userId}`;

  const fetchAdvanceFilters = useCallback(async () => {
    const data = await getUserConfig(configKey);
    if (data) {
      return data;
    }
    return null;
  }, []);

  const setAdvanceFiltersDb = useCallback(
    async (advanceFilters: any, currentTabInTasksView: any) => {
      const updatedData = updateTabConfigState(
        allTabsfilterData,
        currentTabInTasksView,
        {
          filters: advanceFilters,
        },
      );

      updateTasksConfig(updatedData);
    },
    [allTabsfilterData],
  );

  const handleSetAdvanceFiltersCommon = useCallback(
    (
      filters,
      forcedAdvanceFilters: any = null,
      options?: OptionsType,
      currentTabInTasksView?: any,
    ) => {
      const currFilters = filters?.filters || [];
      const myFilters = {
        condition: filters?.condition || 'AND',
        filters: forcedAdvanceFilters
          ? [...forcedAdvanceFilters, ...currFilters]
          : currFilters,
      };
      // formatted filters without forced filters
      const formattedCurrentFilters = formatFilters(filters);
      const isCurrentFilterValid = validateAdvancedFilter(
        formattedCurrentFilters.filters ?? [],
      );
      let filtersForStore: any = undefined;
      if (isCurrentFilterValid) {
        filtersForStore = filters;
        !options?.skipFilterSave &&
          setAdvanceFiltersDb(filters, currentTabInTasksView);
        getTasksCommon(myFilters);
      } else if (
        !isCurrentFilterValid &&
        checkEmptyFilters(formattedCurrentFilters.filters)
      ) {
        handleClearFilter();
        setAdvanceFiltersDb(null, currentTabInTasksView);
      }
    },
    [allTabsfilterData, setAdvanceFiltersDb],
  );

  const syncAdvanceFilters = useCallback((filters: any[]) => {
    dispatch(actions.setTaskListFilters(filters));
  }, []);

  return {
    syncAdvanceFilters,
    fetchAdvanceFilters,
    handleSetAdvanceFiltersCommon,
  };
}

export default useTaskFilters;
