import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from 'components/Icons/addIcon';
import ImgCustomRolesUsers from 'public/assets/img/assignedUsersRolesEmpty.svg';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';
import { MainCnt } from './style';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';

const UsersAssignedEmptyState = (props) => {
  const { getCustomRoleDetails, details } = props;
  const dispatch = useDispatch();
  const { fetchCustomRoles } = useCustomRoles();

  const editAssignUsersHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CUSTOM_ROLES_SET_EDIT_ASSIGN_ROLE,
        data: {
          role: details,
          blacklistUsers: [],
          onSuccessCB: () => {
            getCustomRoleDetails?.();
            fetchCustomRoles({ payload: {}, forceLoad: true });
          },
        },
      }),
    );
  };

  return (
    <MainCnt alignItems="center" justifyContent="center" gap="15px">
      <img src={ImgCustomRolesUsers} />
      <Typography className="title">Assign Roles to Users</Typography>
      <Typography className="subTitle">
        Manage user permissions by assigning role. Start by adding users and
        assigning this role to them.
      </Typography>
      <CustomButton
        variant="contained"
        className="createBtn"
        onClick={editAssignUsersHandler}
        startIcon={<AddIcon />}
      >
        Assign Role
      </CustomButton>
    </MainCnt>
  );
};

export default UsersAssignedEmptyState;
