// React
import { useState } from 'react';

// Icons
import CalendarIconRounded from 'components/Icons/calendarIconRounded';

// Utils
import { Moment } from 'moment-timezone';

import useResponsive from 'utils/CustomHooks/useResponsive';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
import { DateDropdownButton } from './style';
import DateRangePickerDropdown from 'components/DateRangePicker/Dropdown';

interface DateRangeDropdownPropTypes {
  handleDateChange: (range: { from: null | Moment; to: null | Moment }) => void;
}

interface DateRangeType {
  from: null | Moment;
  to: null | Moment;
}

const DateRangeDropdown = ({
  handleDateChange,
}: DateRangeDropdownPropTypes) => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    from: null,
    to: null,
  });
  const { isMobileDeviceSm } = useResponsive();
  const [selectedDateLabel, setSelectedDateLabel] = useState<string>('Any');

  const handleSetDateRange = (data: {
    from: null | Moment;
    to: null | Moment;
    selectedDateOption: string;
  }) => {
    setDateRange(data);
    setSelectedDateLabel(data.selectedDateOption);
    handleDateChange(data);
  };

  return (
    <DateRangePickerDropdown
      setRange={handleSetDateRange}
      dateRange={dateRange}
      selectedDateLabel={selectedDateLabel}
      contentProps={{
        displaySideOptions: !isMobileDeviceSm,
        allowInputs: !isMobileDeviceSm,
        numberOfMonths: isMobileDeviceSm ? 1 : 2,
        sideMenu: dateRangePickerSideMenuCommon,
      }}
      buttonRenderer={
        <DateDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          startIcon={<CalendarIconRounded />}
          sx={{
            height: '30px',
            padding: '4px 10px',
          }}
        >
          Date
        </DateDropdownButton>
      }
    />
  );
};

export default DateRangeDropdown;
