import { createSvgIcon } from '@mui/material';

const NewTemplateIcon = (props) => {
  const { style, ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M11.1535 4.15423L8.67858 1.67935C8.52894 1.52971 8.35499 1.40997 8.16602 1.32397V3.73346C8.16602 4.06015 8.16602 4.2235 8.2296 4.34828C8.28552 4.45805 8.37476 4.54728 8.48452 4.60321C8.6093 4.66679 8.77265 4.66679 9.09935 4.66679H11.5088C11.4228 4.47781 11.3031 4.30387 11.1535 4.15423Z"
        fill="currentColor"
      />
      <path
        d="M8.16732 1.32397V3.73346C8.16732 4.06015 8.16732 4.2235 8.2309 4.34828C8.28682 4.45805 8.37606 4.54728 8.48582 4.60321C8.61061 4.66679 8.77395 4.66679 9.10065 4.66679H11.5101M7.00065 10.5001V7.00008M5.25065 8.75008H8.75065M8.16732 1.16675H5.13398C4.15389 1.16675 3.66385 1.16675 3.2895 1.35749C2.96022 1.52527 2.6925 1.79298 2.52472 2.12226C2.33398 2.49661 2.33398 2.98666 2.33398 3.96675V10.0334C2.33398 11.0135 2.33398 11.5036 2.52472 11.8779C2.6925 12.2072 2.96022 12.4749 3.2895 12.6427C3.66385 12.8334 4.15389 12.8334 5.13398 12.8334H8.86732C9.84741 12.8334 10.3375 12.8334 10.7118 12.6427C11.0411 12.4749 11.3088 12.2072 11.4766 11.8779C11.6673 11.5036 11.6673 11.0135 11.6673 10.0334V4.66675L8.16732 1.16675Z"
        stroke="currentColor"
        strokeOpacity="0.54"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
    </>,
    'NewTemplateIcon',
  );
  return (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 14 14'}
      {...rest}
    />
  );
};
export default NewTemplateIcon;
