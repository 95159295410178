import React, { useMemo, useRef } from 'react';

import {
  WidgetCnt,
  WidgetContentWrapper,
  WidgetHeading,
} from 'pages/Dashboards/Widgets/widgets.style';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import WidgetActions from 'pages/Dashboards/Widgets/widgetActions';
import { exportToPng } from 'utils/globalFunction';
import { getRenderValue } from 'utils/widget';
import { useLoadWidget } from 'pages/Dashboards/Widgets/useLoadWidget';
import WidgetAdvanceFilter from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import TasksByAssigneeEmptyState from 'pages/Widgets/TasksByAssignee/EmptyRecords/TaskListWidgetEmptyState.cmp';
import {
  useViewFlaggedItems,
  useViewSubmissions,
  useViewTasks,
} from 'pages/Dashboards/hooks/useWidgets';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import { Stack, Typography } from '@mui/material';

interface ILineChartWidgetProps {
  widgetId: string;
  editable?: boolean;
  globalFilters?: any;
}

function LineChartWidget({
  widgetId,
  editable,
  globalFilters,
}: ILineChartWidgetProps) {
  const chartRef: any = useRef(null);
  const { parsedData, getWidgetQuery, widget, summary } = useLoadWidget(
    widgetId,
    globalFilters,
  );
  const { handleViewFlaggedItemsChartWidgets } = useViewFlaggedItems();
  const { handleViewTasks } = useViewTasks();
  const { handleViewSubmissions } = useViewSubmissions();

  const handleClick = (e: any) => {
    if (
      widget?.entity === DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS &&
      widget?.type !== WIDGET_TYPE_IDS.CUSTOM_TABLE &&
      e?.activePayload?.[0]?.payload?.metadata
    ) {
      handleViewFlaggedItemsChartWidgets(
        widget,
        e?.activePayload?.[0]?.payload?.metadata,
        getWidgetQuery.refetch,
        globalFilters,
      );
    }
  };
  const exportChartToImage = () => {
    exportToPng(chartRef.current, widget?.title);
  };
  const loading = getWidgetQuery.isFetching;
  const error = getWidgetQuery.error;
  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    const labelStyle = {
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '10px',
      letterSpacing: '0.17px',
    };
    const truncatedValue =
      payload.value.length > 10
        ? payload.value.substring(0, 10) + '...'
        : payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#000"
          transform="rotate(-35)"
          style={labelStyle}
        >
          {truncatedValue}
        </text>
      </g>
    );
  };

  const isPercentage = useMemo(() => {
    const pattern = /percentage|averageScore/;
    return pattern.test(widget?.config?.variable);
  }, [widget]);
  const isDuration = useMemo(() => {
    const pattern = /totalDuration|averageDuration/;
    return pattern.test(widget?.config?.variable);
  }, [widget]);

  return (
    <WidgetCnt ref={chartRef}>
      <WidgetHeading>
        <Stack
          direction="column"
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Typography className="title" title={widget?.title}>
            {widget?.title}
          </Typography>
          {summary?.total >= 0 && (
            <Typography className="count">{summary?.total}</Typography>
          )}
        </Stack>
        <div className={'widgetActionsCnt'}>
          <WidgetAdvanceFilter
            widgetId={widgetId}
            globalFilters={globalFilters}
          />{' '}
          <WidgetActions
            exportChartToImage={exportChartToImage}
            widgetId={widgetId}
            editable={editable}
          />
        </div>
      </WidgetHeading>{' '}
      <WithLoader isLoading={loading}>
        <WithErrorState
          type={'line'}
          error={error}
          reload={() => getWidgetQuery.refetch()}
        >
          {parsedData?.length ? (
            <WidgetContentWrapper>
              <ResponsiveContainer
                minWidth={parsedData.length * 40}
                height="100%"
              >
                <AreaChart
                  // width={500}
                  // height={400}
                  onClick={handleClick}
                  style={{ fontSize: 12 }}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 0,
                    bottom: 70,
                  }}
                  data={parsedData}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke={'#EEE'}
                  />
                  <XAxis
                    dataKey="valueX"
                    tick={(props) => <CustomizedAxisTick {...props} />}
                    axisLine={false}
                    // label={ will be used later
                    //   widget?.config?.metric?.charAt(0).toUpperCase() +
                    //   widget?.config?.metric.slice(1)
                    // }
                  />
                  <YAxis
                    axisLine={false}
                    tick={{
                      fill: '#000000',
                      fontSize: 13,
                      fontWeight: 700,
                      letterSpacing: '0.17px',
                    }}
                    tickCount={2}
                    type={'number'}
                    // domain={[0, maxY + maxY / 2]}
                    allowDecimals={false}
                    tickFormatter={(tick) =>
                      `${getRenderValue(tick, isPercentage, isDuration)}`
                    }
                  />
                  <ReferenceDot r={5} fill="#fff" stroke="#6868FE" />
                  <Tooltip
                    isAnimationActive={false}
                    formatter={(value) => [
                      `${getRenderValue(value, isPercentage, isDuration)}`,
                    ]}
                  />

                  <Area
                    fill="#6868fe33"
                    type="monotone"
                    dataKey="valueY"
                    stroke="#6868FE"
                    strokeWidth={2}
                    dot={{
                      stroke: '#6868FE',
                      strokeWidth: 1,
                      fill: '#fff',
                      opacity: 1,
                      r: 5,
                    }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </WidgetContentWrapper>
          ) : (
            <TasksByAssigneeEmptyState />
          )}
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
}

export default LineChartWidget;
