import { createSvgIcon } from '@mui/material';

const CallIcon2 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M21 15V11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11V15M6.5 19C5.11929 19 4 17.8807 4 16.5V13.5C4 12.1193 5.11929 11 6.5 11C7.88071 11 9 12.1193 9 13.5V16.5C9 17.8807 7.88071 19 6.5 19ZM15.5 19C14.1193 19 13 17.8807 13 16.5V13.5C13 12.1193 14.1193 11 15.5 11C16.8807 11 18 12.1193 18 13.5V16.5C18 17.8807 16.8807 19 15.5 19Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CallIcon2',
  );
  return (
    <IconCmp
      width="22"
      height="20"
      viewBox="0 0 22 20"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default CallIcon2;
// #6868FE
