import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { TASK_STATUS } from 'utils/globalVariable';
import ArchiveIcon from 'components/Icons/archiveIcon';
import ReAssignIcon from 'components/Icons/reAssignIcon';
import MarkCompletedIcon from 'components/Icons/markCompletedIcon';
import EditIcon from 'components/Icons/editIcon';
import UserAssignIcon from 'components/Icons/userAssign';
import ReOpenIcon from '@mui/icons-material/CachedOutlined';
import { isEmpty } from 'lodash';
import { colors } from 'components/theme/constants';
import { useTaskEdit } from 'controller/useTaskEdit';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

const taskActionDropdownOptions = [
  { label: 'Edit', id: 'edit', icon: <EditIcon />, iconAlign: 'start' },
  { label: 'Re-Open', id: 're_open', icon: <ReOpenIcon />, iconAlign: 'start' },
  {
    label: 'Assign',
    id: 'assign',
    icon: <UserAssignIcon />,
    iconAlign: 'start',
  },
  {
    label: 'Re-Assign',
    id: 're_assign',
    icon: <ReAssignIcon />,
    iconAlign: 'start',
  },
  {
    label: 'Mark as Complete',
    id: 'mark_as_complete',
    icon: <MarkCompletedIcon />,
    iconAlign: 'start',
  },
  {
    label: 'Archive',
    id: 'archive',
    icon: <ArchiveIcon style={{ color: colors.red, fill: 'none' }} />,
    sx: {
      color: `${colors.red}`,
      borderTop: '#0000001F 1px solid',
    },
    iconAlign: 'start',
  },
  // {
  //   label: 'Un-archive',
  //   id: 'unarchive',
  //   icon: <ArchiveIcon style={{ color: colors.red, fill: 'none' }} />,
  //   sx: {
  //     color: `${colors.red}`,
  //     borderTop: '#0000001F 1px solid',
  //   },
  //   iconAlign: 'start',
  // },
];
function TaskActionDropdown(props) {
  const { task, successCB } = props;

  const {
    handleTaskArchiveCheckDialog,
    handleTaskUnarchiveCheckDialog,
    handleTaskCompleteClick,
    editTaskEnabler,
    handleTaskAssign,
    handleTaskReOpen,
  } = useTaskEdit();

  const taskList: any = useSelector(selectors.getTaskList);
  const selectedTask = taskList?.rows?.find((t) => t.id === task?.id);

  //Handler for option select of action dropdown
  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'edit':
        {
          editTaskEnabler(task, {}, successCB);
        }
        break;
      case 'mark_as_complete':
        {
          if (!task?.taskNumber) {
            return;
          }
          handleTaskCompleteClick(task, successCB);
        }
        break;
      case 'archive':
        {
          handleTaskArchiveCheckDialog(task, task.recurringTask, successCB);
        }
        break;
      case 'unarchive':
        {
          handleTaskUnarchiveCheckDialog(task, task.recurringTask);
        }
        break;
      case 're_assign':
        {
          handleTaskAssign(task, successCB);
        }
        break;
      case 're_open':
        {
          handleTaskReOpen(task?.id, successCB);
        }
        break;
      case 'assign':
        {
          handleTaskAssign(task, successCB);
        }
        break;
    }
  };

  const actionOptionsAfterPermission = taskActionDropdownOptions.filter(
    (a: any) => {
      if (
        (a.id == 'mark_as_complete' &&
          task?.taskStatus == TASK_STATUS.Completed) ||
        (a.id == 're_open' && task?.taskStatus !== TASK_STATUS.Completed) ||
        (a.id == 'mark_as_complete' && !task?.taskNumber) ||
        (a.id == 're_assign' && isEmpty(task?.AssignId)) ||
        (a.id == 'assign' && !isEmpty(task?.AssignId)) ||
        (a.id == 'archive' && task?.isArchive === 'Yes') ||
        (a.id == 'unarchive' && task?.isArchive === 'No') ||
        (a.id == 'edit' && task?.ProjectId)
      ) {
        return false;
      }
      return a;
    },
  );
  return (
    <ActionDropdown
      options={actionOptionsAfterPermission}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { marginRight: 16, padding: 2, transform: 'rotate(90deg)' },
      }}
      popperProps={{
        sx: {
          zIndex: 1301,
        },
      }}
    >
      {' '}
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
}

export default TaskActionDropdown;
