import { useDispatch } from 'react-redux';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import actions from 'store/actions';
import { useTemplateAccess } from 'controller/useTemplateAccess';
import { DraftChip } from 'components/common/jsxrender/checklistV2/checklistGrid/styled';
import { EditOutlined } from '@mui/icons-material';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';

import {
  CheckListIconBox,
  MainCnt,
  TemplateName,
  Value,
  ValueDraft,
} from '../../list.style';

function Name(props) {
  const { template } = props;
  const { name, icon, isPublished } = template;
  const dispatch = useDispatch();
  const { isTemplateAdmin } = useTemplateAccess();

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  const setModalState = (tab) => {
    dispatch(
      actions.setPreviewChecklistModalConfig({
        open: true,
        activeTab: tab,
        checklistScope: 'myTemplates',
      }),
    );
  };

  const handleClickPreviewButton = () => {
    if (isTemplateAdmin(template)) {
      navigateWithWorkspaceUrl(`/templates/${template?.id}`);
    } else {
      dispatch(actions.setPreviewChecklistData(template));
      setModalState('preview');
    }
  };

  return (
    <MainCnt>
      <CheckListIconBox
        style={{
          backgroundColor: icon?.color,
          borderRadius: 4,
          marginRight: 10,
        }}
      >
        <MuiIcon name={icon?.icon} style={{ height: 15, width: 15 }} />
      </CheckListIconBox>
      <ValueDraft>
        <Value style={{ fontWeight: 700 }}>
          <TemplateName onClick={handleClickPreviewButton}>{name}</TemplateName>
        </Value>
        {!isPublished && (
          <DraftChip
            icon={<EditOutlined style={{ fontSize: 12 }} />}
            size="small"
            label="Draft saved"
            color="success"
            sx={{ padding: '1px 3px' }}
          />
        )}
      </ValueDraft>
    </MainCnt>
  );
}

export default Name;
