// React
import { FC, Fragment, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { updateUserMetaInfo } from 'store/actions/userActions';

// Components
import TemplateFileUpload from 'components/BuilderUI/TemplateFileUpload';
import TemplateFileUploadSuccess from 'components/BuilderUI/TemplateFileUpload/successInfo';

// Utils
import xeniaApi from 'api/index';

const TemplateUpload: FC = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const userMetaInfo: any = useSelector(selectors.getUserMeta);

  const dispatch = useDispatch();

  const updateUserMeta = async (postData) => {
    await xeniaApi.updateUserMeta(postData);
    dispatch(updateUserMetaInfo(postData));
  };

  const templateUploadHandler = () => {
    setShowSuccess(true);
    hideTemplateFileBanner();
  };

  const closeSuccessModal = () => {
    setShowSuccess(false);
  };

  const hideTemplateFileBanner = () => {
    const postObj = {
      ...userMetaInfo,
      dashboard: { ...userMetaInfo?.dashboard, hideTemplateUploader: true },
    };

    updateUserMeta(postObj);
  };

  return (
    <Fragment>
      {!userMetaInfo?.dashboard?.hideTemplateUploader && (
        <TemplateFileUpload
          onSuccessCallback={templateUploadHandler}
          closeHandler={hideTemplateFileBanner}
        />
      )}
      {showSuccess && (
        <TemplateFileUploadSuccess
          isOpen={showSuccess}
          closeHandler={closeSuccessModal}
        />
      )}
    </Fragment>
  );
};

export default TemplateUpload;
