import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const NoAddedCnt = styled('div')({
  marginTop: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100% - 115px)',
  flexDirection: 'column',
  '& .addBtn': {
    cursor: 'pointer',
    marginTop: 10,
  },
});

export const TextCnt = styled('div')({
  margin: '10px 0px',
  textAlign: 'center',
});

export const MainText = styled('p')({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '28.8px',
  color: colors.black,
  letterSpacing: '0.15px',
  opacity: 0.8,
});

export const SubText = styled('p')({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  opacity: 0.87,
  '& .inner-text': {
    color: colors.black,
    opacity: 1,
  },
});

export const InviteandBoost = styled('div')({
  height: 'inherit',
  '& .MuiGrid-item': {
    padding: '0px 10px',
  },
  '& .sendInvite': {
    position: 'absolute',
    bottom: 90,
  },
  '& .box': {
    background: colors.white,
    border: '1px solid #E0E0E0',
    borderRadius: 10,
    height: 'inherit',
    padding: 20,
    position: 'relative',

    '& .title': {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '160%',
      letterSpacing: '0.15px',
      color: colors.black,
    },
    '& .subtitle': {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '160%',
      letterSpacing: '0.15px',
      color: colors.black,
      marginBottom: 10,
      maxWidth: 400,
    },
  },
});

export const InviteImg = styled('img')({
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: 230,
});
