import NoRecordsFound from 'components/Icons/noRecordFoundIcon';

import {
  WrapperDiv,
  NoResultsTitle,
  NoResultsDescriptionWrapper,
  NoResultsDescription,
} from './styled';

const EmptyResultsFilter = () => {
  return (
    <WrapperDiv>
      <NoRecordsFound style={{ width: 307, height: 231 }} />
      <NoResultsTitle>No results found</NoResultsTitle>
      <NoResultsDescriptionWrapper>
        <NoResultsDescription>
          We couldn’t find what you’re looking for. Please try different
          criteria.
        </NoResultsDescription>
      </NoResultsDescriptionWrapper>
    </WrapperDiv>
  );
};

export default EmptyResultsFilter;
