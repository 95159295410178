// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
}));

export const FilterAndSearchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

export const ListWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}));
