import { Box, Stack, styled } from '@mui/material';

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});

export const IntroBannerCnt = styled(Stack)(() => ({
  borderRadius: 12,
  background: 'url(/assets/img/onboarding-logo.png) #EBEAFF no-repeat 100% 1px',
  backgroundSize: 'contain',
  gap: 5,
  padding: '16px 20px 16px 20px',
  height: 80,
  marginBottom: 20,
  position: 'relative',

  '& .crossCnt': {
    borderRadius: '50%',
    position: 'absolute',
    right: -5,
    top: -6,
    backgroundColor: '#78909C',
    height: 20,
    width: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      height: 15,
      '& path': {
        stroke: 'white',
      },
    },
  },

  '& img': {
    height: 36,
    width: 36,
  },

  '& .statement': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20.4px',
    letterSpacing: '0.15px',
    color: '#000000',
    maxWidth: 600,
  },
}));
