import { createSvgIcon } from '@mui/material';

const TickInsideSquareIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g mask="url(#mask0_1214_7829)">
        <path
          d="M7.68333 11.3333L3.91667 7.56667L4.85 6.63333L7.68333 9.46667L13.8 3.35L14.75 4.26667L7.68333 11.3333ZM3.33333 14C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13044 2.65267 2.39133 2.39133C2.65267 2.13044 2.96667 2 3.33333 2H12.6667C12.7556 2 12.8389 2.00822 12.9167 2.02467C12.9944 2.04156 13.0722 2.06667 13.15 2.1L11.9167 3.33333H3.33333V12.6667H12.6667V8.23333L14 6.9V12.6667C14 13.0333 13.8696 13.3473 13.6087 13.6087C13.3473 13.8696 13.0333 14 12.6667 14H3.33333Z"
          fill="#6868FE"
        />
      </g>
    </>,
    'TickInsideSquareIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default TickInsideSquareIcon;
