// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  letterSpacing: '0.1px',
  lineHeight: '21.98px',
  color: 'rgba(33, 33, 33, 1)',
  marginTop: '12.69px',
}));

export const GreyText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: '0.17px',
  lineHeight: '20.02px',
  color: 'rgba(97, 97, 97, 1)',
}));
