import styled from '@mui/system/styled';
import { Typography } from '@mui/material';
import { colors } from 'components/theme/constants';

export const CustomDrawerInnerCnt = styled('div')({
  position: 'relative',
  height: '100%',
});
export const TaskDetailCnt = styled('div')({
  position: 'relative',
  height: '100%',
  '& .completed-alert': {
    background:
      'linear-gradient(270deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 52.6%, rgba(255, 255, 255, 0.3) 100%), #04B86C',
    color: '#ffff',
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px 0',
    textAlign: 'center',
  },
  '& .requestStatus': {
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '0 10px',
    borderRadius: 36,
    '& .statusText': {
      fontSize: '12px',
    },
    '& .statusIcon': {
      display: 'flex',
    },
    '& svg': {
      fontSize: '16px',
    },
  },
  '& .btnActionCnt': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '12px 24px',
    marginTop: 20,
  },
  '& h2': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '28px',
  },
  '& .headerCnt': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '20px',
    fontWeight: 600,
    color: '#252350',
    padding: '16px 0 0 40px',
  },
  '& .actionBtn': {
    padding: '16px 24px 0 0',
  },
  '& .frame': {
    padding: '0 24px 0 40px',
  },
  '& .description-text': {
    color: '#423F79',
  },
  '& .info-item': {
    display: 'flex',
  },
  '& .capsule': {
    padding: '1rem 0',
    width: '145px',
    fontSize: '14px',
    fontWeight: 700,
  },
  '& .item-value': {
    color: '#252350',
  },
  '& .titlebox': {
    width: '150px',
    color: '#212121',
    fontSize: '14px',
    fontWeight: 800,
    paddingRight: '20px',
    flexShrink: 0,
    '& label': {},
  },
  '& .mini-titlebox': {
    color: '#212121',
    fontSize: '14px',
    fontWeight: 800,
    flexShrink: 0,
    '& label': {},
  },
  '& .attachment-wrap': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& .imgbox': {
      borderRadius: '4px',
      marginRight: '8px',
      marginBottom: '8px',
      '& .img': {
        width: '72px',
        height: '72px',
        borderRadius: '4px',
        objectFit: 'cover',
        border: '1px solid #f0f4fe',
      },
    },
  },
});

export const RequestorInfoField = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const RequestorInfoFieldHeading = styled(Typography)({
  fontSize: '14px',
  fontWeight: 800,
  color: colors.grey900,
  width: 150,
});

export const RequestorInfoFieldValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'bold',
})(({ bold }) => ({
  fontSize: 14,
  fontWeight: bold ? 700 : 400,
  color: bold ? '#252350' : '#151339',
}));
