import ImgFlaggedCategoriesImg from 'public/assets/img/img-flag-categories.svg';
import { FlagCategoriesEmptyStateCnt } from './style';
import { Typography } from '@mui/material';

const FlagCategoriesEmptyState = () => {
  return (
    <FlagCategoriesEmptyStateCnt>
      <img src={ImgFlaggedCategoriesImg} className="img" />
      <Typography className="title">No Flag Categories</Typography>
      <Typography className="statement">
        Assign a category to each flagged response to gain detailed insights
        into recurring issues, enabling targeted improvements and strategic
        planning.
      </Typography>
    </FlagCategoriesEmptyStateCnt>
  );
};

export default FlagCategoriesEmptyState;
