import { Box, Stack, Typography, styled } from '@mui/material';

export const Heading = styled(Typography)({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '18px',
  color: '#616161',
});

export const Content = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '24px',
  color: '#000000',
});

export const MainCnt = styled(Stack)({
  gap: 20,
  borderRadius: 6,
  padding: 15,
});

export const DetailsCnt = styled(Stack)({
  padding: '10px 20px 18px 20px',
  '& hr': {
    marginBottom: 15,
    color: '#E0E0E0',
  },
});

export const DataSet = styled(Box)({
  gap: '10px',
  marginBottom: 15,
});

export const AvatarCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    fontSize: 14,
    fontWeight: '600 !important',
    lineHeight: '24px',
    color: '#000 !important',
  },
});

export const ByCnt = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

export const Dot = styled('span')({
  margin: '0px 6px',
  color: '#757575',
});

export const ValueText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customcolor',
})(({ customcolor }) => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '24px',
  color: customcolor ?? '#000',
}));

export const PriorityContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const CategoryCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& > div': {
    fontWeight: '600 !important',
  },
});

export const CategoryChip = styled(Box, {
  shouldForwardProp: (prop) => !['bg', 'color', 'bordercolor'].includes(prop),
})(({ bg, color, shouldDisplayFlex, bordercolor }) => ({
  display: shouldDisplayFlex ? 'flex' : 'inline',
  alignItems: 'center',
  gap: 8,
  color: color ?? 'rgba(0,0,0, 0.87)',
  fontWeight: '400 !important',
  padding: !!bg && '6px 12px',
  borderRadius: 4,
  background: bg,
  cursor: 'pointer',
  fontSize: '12px !important',
  ...(bordercolor && {
    border: `1px solid ${bordercolor}`,
  }),
  svg: {
    width: 22,
    height: 22,
  },
  '.placeholder': {
    fontSize: 14,
    margin: 0,
  },
}));

export const ValueCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
