import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { WidgetCnt, WidgetHeading, WidgetSubHeading } from './style';
import useTasksFiltersConditions from 'utils/CustomHooks/useTasksFiltersConditions';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import { useMemo } from 'react';
import EmptyState from './emptyState';

function ScheduledWorkByStatus(props) {
  const { title, data, reload, error, globalFilters, loading } = props;
  const {
    recurringOpen,
    recurringCompleted,
    recurringInProgress,
    recurringOnHold,
    recurringMissed,
  } = useTasksFiltersConditions();

  const dispatch = useDispatch();

  const COLORS = {
    'In Progress': '#F58500',
    'On Hold': '#64636D',
    Missed: '#EF5350',
    Open: '#3B8EF0',
    Completed: '#04B86C',
  };

  const CustomLabel = ({
    cx,
    cy,
    value,
    innerRadius,
    outerRadius,
    midAngle,
    percent,
    valueX,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) + 25;
    const cos = Math.cos(-RADIAN * midAngle);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentage = `${(percent * 100).toFixed(1)}%`;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <text
        cx={cx}
        cy={cy}
        stroke="none"
        x={x}
        y={y}
        alignmentBaseline="middle"
        className="recharts-text recharts-pie-label-text"
        textAnchor={textAnchor}
        fill={'#212121'}
      >
        <tspan
          x={x}
          dy="5px"
          fontSize={12}
          fontWeight={800}
          letterSpacing={'0.17px'}
        >
          {valueX}&nbsp;
          <tspan>{value}</tspan>
          <tspan>&nbsp;({percentage})</tspan>
        </tspan>
      </text>
    );
  };

  const clickHandler = (item) => {
    let advancedFilters: any = [];

    if (item?.valueX === 'Open') {
      advancedFilters = recurringOpen();
    }

    if (item?.valueX === 'Completed') {
      advancedFilters = recurringCompleted();
    }

    if (item?.valueX === 'Missed') {
      advancedFilters = recurringMissed();
    }

    if (item?.valueX === 'On Hold') {
      advancedFilters = recurringOnHold();
    }

    if (item?.valueX === 'In Progress') {
      advancedFilters = recurringInProgress();
    }

    if (globalFilters?.fromDate && globalFilters?.toDate) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'dueDate',
        value: [globalFilters?.fromDate, globalFilters?.toDate],
      });
    }
    if (globalFilters?.locations?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'locations',
        value: globalFilters.locations,
      });
    }

    if (globalFilters?.projects?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'projects',
        value: globalFilters.projects,
      });
    }

    if (globalFilters?.schedules?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'schedules',
        value: globalFilters.schedules,
      });
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TASK_TABLE_DIALOG,
        data: {
          filters: advancedFilters,
        },
      }),
    );
  };

  const transformedData = useMemo(() => {
    return data?.chart?.map((item) => {
      return {
        valueX: item?.valueX,
        valueY: Number(item?.valueY),
      };
    });
  }, [data]);

  return (
    <WidgetCnt>
      <WidgetHeading style={{ padding: '18px 16px 0px 16px' }}>
        {title}
      </WidgetHeading>
      <WithLoader isLoading={loading}>
        <WithErrorState type={'bar'} error={error} reload={reload}>
          {transformedData?.length > 0 && (
            <WidgetSubHeading style={{ padding: '0px 16px' }}>
              {data?.summary?.total}
            </WidgetSubHeading>
          )}

          {transformedData?.length > 0 ? (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  dataKey="valueY"
                  data={transformedData}
                  cx="50%"
                  cy="50%"
                  outerRadius={90}
                  fill="#8884d8"
                  label={CustomLabel}
                >
                  {transformedData?.map((item, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[item?.valueX]}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        clickHandler(item);
                      }}
                    />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{ background: 'black', color: '#fff' }}
                  itemStyle={{ color: '#fff' }}
                  formatter={(value, name, props) =>
                    `${props.payload.valueX}: ${value}`
                  }
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <EmptyState />
          )}
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
}

export default ScheduledWorkByStatus;
