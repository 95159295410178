// React
import { useContext, useEffect, useState } from 'react';

// Custom components
import ActiveSubmissionLog from './ActiveSubmissionLog';
import CheckListSkeleton from 'components/TaskChecklist/Skeleton/CheckListSkeleton';

// Context
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import {
  SubmissionContext,
  SubmissionReportType,
} from 'pages/TemplateDashboard/Tabs/Submissions/context';
import xeniaApi from 'api/index';

// Styled
import { Wrapper } from './styled';

const SubmissionTemplatePreview = () => {
  const ctx = useContext(SubmissionContext);
  const { workspaceId } = useWorkspaceHook();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SubmissionReportType | undefined>();
  const getChecklistLog = async (checklistLogId: string) => {
    setLoading(true);
    const logData = await xeniaApi.getChecklistLog({
      logId: checklistLogId,
      hotelId: workspaceId,
      filtered: true,
      sectioned: true,
    });
    if (logData?.data) {
      setLoading(false);
      setData(logData?.data);
    }
  };

  useEffect(() => {
    if (ctx?.activeSubmission) {
      getChecklistLog(ctx?.activeSubmission?.id);
    }
  }, [ctx?.activeSubmission]);
  return ctx?.activeSubmission && data ? (
    <Wrapper>
      {loading ? (
        <CheckListSkeleton />
      ) : (
        <ActiveSubmissionLog
          activeSubmission={data}
          template={ctx?.template}
          handleArchiveLogs={ctx?.handleArchiveLogs}
        />
      )}
    </Wrapper>
  ) : (
    <></>
  );
};

export default SubmissionTemplatePreview;
