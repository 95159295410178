import { useCallback, useEffect } from 'react';
import { isEmpty, isObject, isArray } from 'lodash';

function useSchedulesHeader() {
  const validateFilterValues = (filterValue) => {
    const testValue = (val) => val !== null && val !== undefined;

    if (isArray(filterValue)) {
      return filterValue.every((val) => testValue(val));
    } else if (isObject(filterValue)) {
      return Object.values(filterValue).every((val) => testValue(val));
    } else {
      return testValue(filterValue);
    }
  };

  const validateAdvancedFilter = useCallback((advancedFilters) => {
    return advancedFilters.every(
      (f) =>
        !!f.filterName &&
        !!f.comparator &&
        !!f.conditional &&
        !isEmpty(f.value) &&
        validateFilterValues(f.value),
    );
  }, []);

  const checkEmptyFilters = useCallback((filters) => {
    const f = filters[0];
    return (
      filters.length === 1 &&
      !!f.filterName &&
      !!f.comparator &&
      !!f.value.length
    );
  }, []);

  const handleSetAdvanceFiltersUpdated = useCallback(
    (filters, setAdvanceFilters) => {
      if (filters) {
        const isFilterValid = validateAdvancedFilter(filters.filters);
        let finalFilters = undefined;
        if (isFilterValid) {
          finalFilters = filters;
          setAdvanceFilters(finalFilters);
          return;
        }
      } else {
        setAdvanceFilters(undefined);
        return;
      }
      setAdvanceFilters(filters);
    },
    [validateAdvancedFilter, checkEmptyFilters],
  );

  const handleSetAdvanceFilters = useCallback(
    (filters, setAdvanceFilters) => {
      if (filters) {
        const isFilterValid = validateAdvancedFilter(filters.filters);
        let finalFilters = undefined;
        if (isFilterValid) {
          finalFilters = filters;
        } else if (!isFilterValid && checkEmptyFilters(filters.filters)) {
          finalFilters = undefined;
        }
        setAdvanceFilters(finalFilters);
      } else {
        setAdvanceFilters(undefined);
      }
    },
    [validateAdvancedFilter, checkEmptyFilters],
  );

  const handleChangeSearch = useCallback((e, setSearchTerm) => {
    const term = e.target.value || '';
    setSearchTerm(term);
  }, []);

  return {
    handleChangeSearch,
    handleSetAdvanceFilters,
    handleSetAdvanceFiltersUpdated,
  };
}

export default useSchedulesHeader;
