/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */

// React
import { FC, Fragment, useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions/index';
import selectors from 'store/selectors';
import { getUserHotelTz } from 'store/selectors/auth';

import { useSearchParams } from 'react-router-dom';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import ListIcon from 'components/Icons/listIcon';
import { MenuBtnsCnt } from 'components/layout/navbar/navBar.style';
import CategoriesSearchInput from 'components/layout/navbar/CategoriesSearchInput/categoriesSearchInput';
import TrialDurationLeft from 'components/Billing/TrialDurationLeft';

// Third party
import classNames from 'classnames';
import { Form } from 'react-bootstrap';

// Custom hooks.ts
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useReactRouter } from 'controller/useReactRouter';

// Icons
import EditIcon from 'public/assets/img/edit-icon.svg';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ChevronLeftRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';

// Services
import { useAddEmployeeDirectChat } from 'controller/useAddEmployeeDirectChat';
import {
  getStreamChannels,
  searchMessageInChannels,
} from 'services/getStreamServices';
import DateTime from 'utils/DateTime';
import useBilling from 'controller/useBilling';

// Utilities
import { getItem } from 'utils/globalFunction';

import {
  DEFAULT_TIMEZONE,
  LOCALSTORAGE_KEYS,
  PAGE_TITLES,
} from 'utils/globalVariable';

import { Box } from '@mui/material';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const TopBar: FC<any> = () => {
  const { handleGetPathName } = useReactRouter();
  const { workspaceId, navigateWithWorkspaceUrl, navigateBackWithWorkspace } =
    useWorkspaceHook();
  const loaders = useSelector(selectors.getLoaders);
  const [searchParams] = useSearchParams();

  const appState = useSelector(selectors.getAppState);
  const { billingData } = useBilling();
  const { viewTitle, childTitle, renderGoBackIcon, handleGoBack } = appState;
  const initialNotificationPostData = {
    page: 1,
    pageSize: 10,
    searchText: '',
    sort: {
      createdAt: 'DESC',
    },
  };

  const dispatch = useDispatch();
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;
  const currentUserId: any = useSelector(selectors.getUserId);
  const hotelId: string | number | null = useSelector(selectors.getUserHotelId);

  const [showNotification, SetShowNotification] = useState(false);
  const [notificationPostData, SetNotificationPostData] = useState<any>(
    initialNotificationPostData,
  );
  const [isSearchMessages, setIsSearchMessages] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string>('');
  const [searchUser, setSearchUsers] = useState<any>(null);

  const { handleAddEmployeeDirectChatSubmit } = useAddEmployeeDirectChat();

  // reset search on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.setSearchText(''));
    };
  }, []);

  useEffect(() => {
    if (currentUserId) {
      dispatch(actions.getNotificationUnreadCount());
    }
  }, [currentUserId, workspaceId]);

  useEffect(() => {
    if (showNotification) {
      handleShowNotification();
    }
  }, [showNotification, notificationPostData]);

  useEffect(() => {
    searchData(searchString);
  }, [searchString]);

  const handleShowNotification = () => {
    const postData = notificationPostData;
    const getNotificationData = {
      postData: postData,
    };
    dispatch(actions.taskNotificationListApiCall(getNotificationData));
  };

  useEffect(() => {
    dispatch(actions.taskNotificationListApiCallSuccess([]));
  }, [workspaceId]);

  const openChannel = async ({ id, channel }) => {
    const [channelInfo] = await getStreamChannels({
      id: channel.id,
      hotelId: channel.hotelId,
    });
    if (channelInfo) {
      dispatch(
        actions.scrollToChannelMessage({
          channel: channelInfo,
          message: { id },
        }),
      );
      setIsSearchMessages(false);
      setSearchString('');
    }
  };

  const getSearchHtmlJsx = () => {
    return (
      <Box className="search-info-wrap">
        {searchString.length > 0 ? (
          <span className="result-count-text">
            {searchUser && searchUser.length} matches found
          </span>
        ) : null}
        {searchUser &&
          searchUser.map((s, index) => {
            const isUserMessage = currentUserId === s.message?.user?.id;
            return (
              <Box className="searched-item" key={index}>
                <Box
                  className="info"
                  style={{ cursor: 'pointer' }}
                  title={'Open message channel'}
                  onClick={() => {
                    openChannel(s.message);
                  }}
                >
                  <p>{s.message.text}</p>
                  <span className="time">
                    {DateTime.fromUTCString(s.message?.created_at).toTzString(
                      tz,
                      'hh:mm a',
                    )}
                  </span>
                </Box>
                <Box
                  className="avtar-wraper msz-search-user"
                  title={isUserMessage ? '' : 'Open direct message'}
                  style={{ cursor: isUserMessage ? 'auto' : 'pointer' }}
                  onClick={async () => {
                    if (isUserMessage) {
                      await handleAddEmployeeDirectChatSubmit([
                        s.message?.user,
                      ]);
                      setSearchUsers(null);
                      setIsSearchMessages(false);
                      setSearchString('');
                      // handleSetChannel(currentClient);
                    }
                  }}
                >
                  <Box className="imgbox">
                    <img
                      src={s.message?.user?.profileImage}
                      alt={s.message?.user?.name}
                      width="30"
                      height="30"
                    />
                  </Box>
                  <Box className="namebox">
                    <h6>
                      {s.message?.user?.id ===
                      getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID)
                        ? 'You'
                        : s.message?.user?.name}
                    </h6>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    );
  };

  const searchData = async (text) => {
    if (text !== '') {
      const result = await searchMessageInChannels({
        hotelId,
        text,
        userId: currentUserId,
      });
      setSearchUsers(result?.results);
    }
  };
  const renderSearchBar = () => {
    if (viewTitle !== PAGE_TITLES.messages) return null;
    return (
      <Form.Group
        className={`input-with-icon title-searchbox searchbox chat-searchbox mb-0 position-relative ${
          isSearchMessages ? 'active' : ''
        }`}
        controlId="search"
      >
        <input
          className="form-control"
          type="text"
          placeholder="Search Chat"
          autoComplete="off"
          value={searchString}
          id="search"
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
        />
        <span
          className="icon-search input-left-icon"
          onClick={() => {
            setIsSearchMessages(true);
          }}
        ></span>
        {searchString ? (
          <span
            className="mr-2 icon-close"
            onClick={() => {
              setSearchUsers(null);
              setSearchString('');
              setIsSearchMessages(false);
            }}
          ></span>
        ) : null}
        {searchString ? getSearchHtmlJsx() : null}
      </Form.Group>
    );
  };
  const handleNavigate = (path) => {
    navigateWithWorkspaceUrl(path);
  };
  const renderLeftNav = () => {
    const { FEATURES } = useCustomPermission();
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minWidth: '310px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {renderGoBackIcon && (
            <IconButton
              onClick={
                handleGoBack
                  ? () => handleGoBack()
                  : () => navigateBackWithWorkspace()
              }
            >
              <ChevronLeftRounded />
            </IconButton>
          )}
          <Box className="page-title">{viewTitle}</Box>
          {childTitle && (
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                ml: '4px',
                alignItems: 'center',
              }}
            >
              <ChevronRightIcon
                sx={{
                  color: '#757575',
                }}
              />
              <Box className="page-title">{childTitle}</Box>
            </Box>
          )}
        </Box>
        {viewTitle === PAGE_TITLES.messages ? (
          <Box
            className="chat-round-button"
            role="button"
            onClick={() => dispatch(actions.showCreateGroup(true))}
          >
            <img className="m-11" src={EditIcon} width="20" height="20" />
          </Box>
        ) : null}
      </Box>
    );
  };

  const renderRightNav = () => {
    return (
      <Box className="d-flex align-items-center">
        {viewTitle === PAGE_TITLES.settings &&
          childTitle === PAGE_TITLES.serviceManagement && (
            <CategoriesSearchInput />
          )}
        {billingData.isTrial || billingData.isCanceledAndActive ? (
          <div className="mr-3">
            <TrialDurationLeft />
          </div>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  return (
    <Fragment>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {renderLeftNav()}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'inherit',
            alignItems: 'center',
          }}
        >
          {renderSearchBar()}
          {renderRightNav()}
        </Box>
      </Box>
    </Fragment>
  );
};

export default TopBar;
