// Styles
import { Container, RemoveButton } from './styled';

// Icons
import DeleteIcon from 'components/Icons/deleteIcon';

interface MembersTableActionsPropTypes {
  disabled: boolean;
  onClick: (memberId: string) => void;
  memberId: string;
}

function MembersTableActions(props: MembersTableActionsPropTypes) {
  const { disabled, onClick, memberId } = props;
  return (
    <Container className="actions-container">
      <RemoveButton
        isdisabled={disabled ? 'true' : ''}
        startIcon={<DeleteIcon className="download-icon" />}
        data-attribute={'cell-click'}
        disabled={disabled}
        onClick={() => onClick(memberId)}
      >
        Remove
      </RemoveButton>
    </Container>
  );
}

export default MembersTableActions;
