import { styled } from '@mui/system';
import { colors } from 'components/theme/constants';

export const AchieveCard = styled('div')(() => ({
  '.archivedChat': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 24,
    border: '1px solid rgba(0,0,0,.125)',
    backgroundColor: '#ffffff',
    width: 333,
    borderRight: 'none',
    borderLeft: 'none',
    position: 'fixed',
    bottom: 0,
    borderTop: '1px solid #dbe2f0',
    borderRadius: 0,
    height: 63,
  },
  '.clickableArea': {
    cursor: 'pointer',
    '& .archiveIcon': {
      color: colors.light.action.active,
    },
    '& .archiveLink': {
      marginLeft: 11,
    },
  },
  a: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14,
  },
  '.archiveIcon': {
    width: 20,
    height: 18,
  },
}));
