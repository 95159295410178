import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const MessageEmptyStateStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  '.title': {
    color: '#212121',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '28px',
    marginTop: 16,
  },
  '.description': {
    color: '#616161',
    fontWeight: 400,
    fontSize: 14,
    marginTop: 4,
    marginBottom: 24,
    textAlign: 'center',
  },
}));
