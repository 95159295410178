import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import DeleteIcon from 'components/Icons/deleteIcon';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import EyeBallIcon from 'components/Icons/eyeBallIcon';
import EditIcon from 'components/Icons/editIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';

const RolesActionDropdown = (props) => {
  const { data, rolesActionDropdownProps } = props;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { fetchCustomRoleDetails } = useCustomRoles();
  const dispatch = useDispatch();
  const { hasPermission, hasFeature, FEATURES } = useCustomPermission();

  const rolesActionDropdownOptions = [
    {
      label: 'View Details',
      id: 'view',
      icon: (
        <EyeBallIcon
          style={{
            color: 'rgba(0, 0, 0, 0.44)',
            fill: 'none',
            width: 17,
            height: 17,
          }}
        />
      ),
      iconAlign: 'start',
    },
    ...(data?.editable &&
    hasPermission(PERMISSIONS.CAN_MANAGE_ROLES) &&
    hasFeature(FEATURES.CUSTOM_ROLES)
      ? [
          {
            label: 'Edit Details',
            id: 'edit',
            icon: (
              <EditIcon
                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 16 }}
              />
            ),
            iconAlign: 'start',
          },
        ]
      : []),
    ...(data?.editable &&
    hasPermission(PERMISSIONS.CAN_MANAGE_ROLES) &&
    hasFeature(FEATURES.CUSTOM_ROLES)
      ? [
          {
            label: 'Delete',
            id: 'delete',
            icon: <DeleteIcon style={{ color: '#EF5350', fontSize: 16 }} />,
            iconAlign: 'start',
            sx: {
              color: '#D32F2F',
            },
          },
        ]
      : []),
  ];

  const handleViewAction = () => {
    navigateWithWorkspaceUrl(`/users-and-teams/roles/${data?.id}`);
  };

  const handleEditAction = () => {
    fetchCustomRoleDetails({}, data?.id, (res) => {
      dispatch(
        setDialog({
          open: true,
          dialogId: DIALOGS.CUSTOM_ROLES_SET_DETAILS,
          data: {
            role: res?.[0],
            isEdit: true,
            onSuccessCB: rolesActionDropdownProps?.onEditHandler,
          },
        }),
      );
    });
  };

  const handleDeleteAction = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CUSTOM_ROLES_DELETE_ROLE,
        data: {
          role: data,
          onSuccessCB: () => rolesActionDropdownProps?.onDeleteHandler(data),
        },
      }),
    );
  };

  //Handler for option select of action dropdown
  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'view':
        handleViewAction();
        break;

      case 'edit':
        handleEditAction();
        break;
      case 'delete':
        handleDeleteAction();
        break;
      default: {
        return;
      }
    }
  };

  return (
    <div data-attribute="cell-click">
      <ActionDropdown
        popperProps={{ style: { width: 180 }, placement: 'bottom-end' }}
        options={rolesActionDropdownOptions}
        handleOptionSelect={handleOptionSelect}
        buttonProps={{
          style: { padding: 2, transform: 'rotate(90deg)' },
        }}
      >
        <MoreVertOutlinedIcon style={{ fontSize: 22 }} />
      </ActionDropdown>
    </div>
  );
};

export default RolesActionDropdown;
