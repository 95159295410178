import FiltersDropdown from 'components/TaskAdvanceFilters/FiltersDropdown';
import { HeaderCnt } from 'pages/task/TaskListView/TaskListHeader/taskListHeader.style';
import { HasPermission } from 'components/HasPermission';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import useTaskFilters from 'utils/CustomHooks/useTaskFilters/useTaskFilters';
import { useMemo } from 'react';
import TaskActionsDropdown from './ActionDropdown';

interface TaskListHeaderPropTypes {
  disabledAdvanceFilters?: string[];
  forcedAdvanceFilters?: any[];
  isMainView: boolean;
  currentTabInTasksView?: any;
}

function TaskCalendarHeader(props: TaskListHeaderPropTypes) {
  const {
    disabledAdvanceFilters,
    forcedAdvanceFilters,
    isMainView,
    currentTabInTasksView,
  } = props;
  const { PERMISSIONS } = useCustomPermission();
  const allTabsfilterData: any = useSelector(selectors.getTaskListFilters);
  const { handleSetAdvanceFiltersCommon } = useTaskFilters();

  const filterData = useMemo(() => {
    return allTabsfilterData?.tabs?.[currentTabInTasksView]?.filters || {};
  }, [currentTabInTasksView, allTabsfilterData]);

  // Hide Filters
  const hiddenFilters = useMemo(() => {
    let hidden: any = '';
    if (disabledAdvanceFilters) {
      hidden = disabledAdvanceFilters.join(' ');
    }
    return hidden;
  }, [disabledAdvanceFilters]);

  const shouldShowAddButton = useMemo(() => {
    return (
      !forcedAdvanceFilters ||
      (forcedAdvanceFilters as any)?.filter(
        (f) => f.filterName === 'taskTypes' && f?.value?.includes('corrective'),
      )?.length === 0
    );
  }, [forcedAdvanceFilters]);

  return (
    <HeaderCnt>
      <div className={'lefCnt'}>
        <FiltersDropdown
          selectedFilters={filterData}
          onChange={(filters) =>
            handleSetAdvanceFiltersCommon(
              filters,
              forcedAdvanceFilters,
              {
                skipFilterSave: false,
              },
              currentTabInTasksView,
            )
          }
          handleClearFilters={() =>
            handleSetAdvanceFiltersCommon(
              null,
              forcedAdvanceFilters ? forcedAdvanceFilters : null,
              { skipFilterSave: !isMainView },
              currentTabInTasksView,
            )
          }
          hideFilters={hiddenFilters}
        />
      </div>
      <div className={'rightCnt'}>
        {shouldShowAddButton && (
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
            <TaskActionsDropdown />
          </HasPermission>
        )}
      </div>
    </HeaderCnt>
  );
}

export default TaskCalendarHeader;
