import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import xeniaApi from 'api/index';
import UsersDropdownCmp from 'components/Dropdown/UsersDropdown/UsersDropdown.cmp';
import { editTeam } from 'store/actions/teamActions';
import { MainCnt } from './style';

export const Members = (props) => {
  const dispatch = useDispatch();
  const { data } = props;
  const { Members } = data || {};

  const handleSelectMember = async (member, team) => {
    const membersIdsArr = team?.Members?.map((m) => m.MemberId);
    const isMemberAlreadyExist = membersIdsArr.some((m) => m == member.id);
    const finalMemberArr = isMemberAlreadyExist
      ? membersIdsArr.filter((m) => m !== member.id)
      : [...membersIdsArr, member.id];
    const postData = {
      id: team?.id,
      Members: finalMemberArr,
      name: team?.name,
      isActive: true,
    };
    const newTeamObj = await xeniaApi.editTeam(postData);
    dispatch(editTeam(newTeamObj?.data, team));
  };

  return (
    <MainCnt>
      <Box
        sx={{
          '& span': {
            height: 30,
            width: 30,
            marginTop: '1px',
          },
          '& svg': {
            marginBottom: 15,
          },
        }}
      >
        <UsersDropdownCmp
          handleChangeCallback={(member) => handleSelectMember(member, data)}
          members={Members.map((m) => m.MemberId)}
        />
      </Box>
    </MainCnt>
  );
};
