import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const BoldText = styled(Typography)(() => ({
  fontWeight: '600',
  color: '#212121',
  fontSize: '14px',
  marginBottom: 5,
}));

export const GreyText = styled(Typography)(() => ({
  fontWeight: '400',
  color: '#424242',
  fontSize: '13px',
}));
