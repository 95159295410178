/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CreateAccountDiv, NextButton } from './createAccount.style';
import { useOnboardState } from '../onboarding-provider';
import * as Yup from 'yup';
import xeniaApi from 'api/index';
import parsePhoneNumber from 'libphonenumber-js';
import PhoneEmailInput from 'components/Dropdown/PhoneCodeInput';
import {
  getCountryCode,
  getNumberWithCode,
  getNumberWithoutCode,
} from '../../../helper/phoneNumber';
import PasswordInput from 'components/Form/TextField/PasswordField.cmp';
import { useSnackbar } from 'notistack';
import { getItem, setItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import useUserLogin from 'utils/CustomHooks/useUserLogin';
import CTypography from 'components/CTypography';
import OnboardingPreview from 'components/TemplateLibrary/TemplatePreview/Onboarding/OnboardingPreview';
import OnboardingTemplateGroupPreview from 'components/TemplateLibrary/TemplatePreview/Onboarding/OnboardingTemplateGroupPreview';

const CreateAccount = (props) => {
  const { nextStep, template, templateGroup } = props;

  const {
    fullName,
    email,
    emailInvite,
    phone,
    phoneCode,
    phoneInvite,
    password,
    setFullName,
    setEmail,
    setPhone,
    setPassword,
    setPhoneCode,
    inviteStatus,
    inviteToken,
  } = useOnboardState();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setDeviceToken } = useUserLogin();

  let schema = Yup.object().shape({
    fullName: Yup.string().nullable().required('Please provide your full name'),
    email: Yup.string()
      .email('Please provide valid email address')
      .nullable()
      .test('require phone or email', function (email) {
        const phone = this.resolve(Yup.ref('phone'));
        if (!email && !phone) {
          return this.createError({
            message: `Please provide valid email address or phone`,
            path: 'email',
          });
        } else return true;
      }),
    phone: Yup.string('Please provide valid phone number').nullable(),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Please set your account password'),
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    switch (name) {
      case 'fullName':
        setFullName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
      default:
        break;
    }
  };
  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };
  const handlePhoneCodeSelect = (code) => {
    setPhoneCode(code?.obj?.dial_code);
  };
  const handleNextClick = () => {
    const phoneNumberWithCode =
      phone && !phoneInvite ? phoneCode + phone : phoneInvite ? phone : '';
    if (
      phoneNumberWithCode &&
      !parsePhoneNumber(phoneNumberWithCode).isValid()
    ) {
      setErrors({ ...errors, phone: 'Please enter a valid phone number' });
      return;
    }
    schema
      .validate(
        {
          fullName,
          email,
          phone,
          password,
        },
        {
          abortEarly: false,
        },
      )
      .then(async () => {
        if (phoneNumberWithCode && !inviteStatus) {
          setIsLoading(true);
          const resp = await xeniaApi.emailOrPhoneAuth(
            'hotel/credentials/claim-test',
            { credential: phoneNumberWithCode },
          );

          setIsLoading(false);

          if (resp.user) {
            await nextHandler();
          } else {
            setErrors({ ...errors, phone: resp.message });
          }
        } else {
          await nextHandler();
        }
      })
      .catch((err) => {
        const errors = err.inner.reduce((r, cv) => {
          r[cv.path] = cv.message;
          return r;
        }, {});
        setErrors(errors);
      });
  };
  const nextHandler = async () => {
    try {
      if (inviteStatus) {
        const numberWithoutCode = phoneInvite
          ? getNumberWithoutCode(phone)
          : phone;
        const phoneNumberWithCode =
          phone && !phoneInvite
            ? phoneCode + numberWithoutCode
            : phoneInvite
            ? phone
            : '';

        setIsLoading(true);
        const payload = {
          fullName,
          email,
          phoneNo: phoneNumberWithCode,
          password,
          inviteToken,
        };
        const res = await xeniaApi.createInvitedUserApi(payload);

        if (res.status === 200 && res?.data?.data) {
          await setDeviceToken();
          const credentials = {
            emailId: email.trim() || phoneNumberWithCode.trim(),
            password,
            device: 'WEB',
            accessType: 'WEB',
            deviceToken: getItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN),
          };

          const login = await xeniaApi.loginApi(credentials);

          const { id, verificationToken } = login.user;
          setItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN, verificationToken);
          setItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID, id);

          setIsLoading(false);

          nextStep();
        }
      } else {
        nextStep();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  const selectedPhoneCode = phone && getCountryCode(phoneCode + phone);
  const phoneNumberWithoutCode = phone ? getNumberWithCode(phone) : '';
  return (
    <CreateAccountDiv>
      <CTypography className="title">Create your Xenia account</CTypography>
      <CTypography className="subTitle">
        First things first, tell us a bit about yourself
      </CTypography>

      {template && (
        <OnboardingPreview
          template={template}
          wrapper={{
            style: {
              margin: '0px 0px 10px 0px',
              maxWidth: 'unset',
            },
          }}
        />
      )}
      {templateGroup && (
        <OnboardingTemplateGroupPreview
          templateGroup={templateGroup}
          wrapper={{
            style: {
              margin: '0px 0px 10px 0px',
              maxWidth: 'unset',
            },
          }}
        />
      )}

      <div>
        <CTypography className="labelText">Full Name</CTypography>
        <CustomInput
          id="fullNameInput"
          fieldProps={{
            onChange: handleInputChange,
            placeholder: 'Enter your full name',
            name: 'fullName',
            value: fullName,
            style: {
              borderRadius: 8,
              height: 47,
            },
          }}
          muiLabel={true}
          error={errors['fullName']}
          style={{ marginBottom: 20 }}
        />
        <CTypography className="labelText">Email</CTypography>
        <CustomInput
          id="emailInput"
          muiLabel={true}
          fieldProps={{
            onChange: handleInputChange,
            placeholder: 'Enter your email address',
            name: 'email',
            value: email,
            disabled: emailInvite,
            type: 'email',
            style: {
              borderRadius: 8,
              height: 47,
            },
          }}
          error={errors['email']}
          style={{ marginBottom: 20 }}
        />
        {emailInvite || (!phoneInvite && !emailInvite) ? (
          <>
            <CTypography className="labelText">Phone Number</CTypography>
            <PhoneEmailInput
              initValue={phone}
              suppressEmail={true}
              error={errors['phone']}
              selectValueCallback={handlePhoneInputChange}
              onPhoneCodeSelect={handlePhoneCodeSelect}
              selectedCode={selectedPhoneCode}
              formControlProps={{
                muiLabel: true,
                style: { marginBottom: 20 },
              }}
              fieldProps={{
                style: {
                  borderRadius: 8,
                  height: 47,
                },
              }}
            />
          </>
        ) : (
          <>
            <CTypography className="labelText">Phone Number</CTypography>
            <CustomInput
              id="phoneInput"
              muiLabel={true}
              fieldProps={{
                onChange: handleInputChange,
                placeholder: 'Enter your phone number',
                name: 'phone',
                value: phoneNumberWithoutCode,
                disabled: phoneInvite,
                type: 'tel',
                style: {
                  borderRadius: 8,
                  height: 47,
                },
              }}
              error={errors['phone']}
              style={{ marginBottom: 20 }}
            />
          </>
        )}{' '}
        <>
          <CTypography className="labelText">Password</CTypography>
          <PasswordInput
            id="passwordInput"
            muiLabel={true}
            fieldProps={{
              placeholder: 'Type your password',
              onChange: handleInputChange,
              value: password,
              style: {
                borderRadius: 8,
                height: 47,
              },
            }}
            error={errors['password']}
          />
        </>
        <NextButton
          variant="contained"
          onClick={handleNextClick}
          buttonLoader={isLoading}
          style={{
            width: '100%',
            marginTop: 10,
            height: 47,
          }}
        >
          {inviteStatus ? 'LETS GO' : 'Next'}
        </NextButton>
      </div>
    </CreateAccountDiv>
  );
};

export default CreateAccount;
