import SearchInput from 'components/Form/TextField/SearchField.cmp';
import ListGridToggle from 'components/ListGridToggle';
import { HeaderCnt } from 'pages/Dashboards/DashboardListing/Header/style';
import AddDashboardButton from 'pages/Dashboards/DashboardListing/Header/addDashboardButton';
import { DashboardsContext } from 'pages/Dashboards/context/context';
import { useContext } from 'react';
interface IDashboardListingHeaderProps {
  setSelectedView: (view) => void;
  selectedView: 'grid' | 'list';
}
function DashboardListingHeader({
  setSelectedView,
  selectedView,
}: IDashboardListingHeaderProps) {
  const { dashboardsState, updateDashboardsState } =
    useContext(DashboardsContext);
  const { searchQuery } = dashboardsState;

  const updateToggleHandler = (option) => {
    setSelectedView(option);
  };
  const handleChangeSearch = (e) => {
    const searchInputValue = e.target.value;
    updateDashboardsState({ searchQuery: searchInputValue });
  };

  return (
    <HeaderCnt>
      <div className={'lefCnt'}>
        <SearchInput
          fieldProps={{
            value: searchQuery,
            onChange: (e) => handleChangeSearch(e),
          }}
          sx={{
            maxWidth: { sm: 'calc(100vw - 140px)', md: 250 },
            width: { sm: '100%', md: 350 },
          }}
        />
      </div>
      <div className={'rightCnt'}>
        <ListGridToggle
          selectedView={selectedView}
          setSelectedView={updateToggleHandler}
        />
        <AddDashboardButton />
      </div>
    </HeaderCnt>
  );
}

export default DashboardListingHeader;
