// React
import { useMemo } from 'react';

// Custom components
import Tooltip from 'components/Tooltip';
import UserOrTeamAvatarGroup from 'components/Avatar/UserOrTeamAvatarGroup';
import RightNav from './RightNav';

// Icons
import EditIcon2 from 'components/Icons/editIcon2';
import GlobeIcon from 'components/Icons/globeIcon';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

// Styled
import {
  Column,
  NameAndBack,
  BackButton,
  MetaWrapper,
  TemplateNameText,
  TemplateStatusChip,
  TemplateOwnerWrapper,
  TemplateOwnerText,
  NavWrapper,
  AllUsersText,
} from './styled';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useHistory from 'utils/CustomHooks/useHistory';

// Context
import { TemplateType } from 'pages/TemplateDashboard/context';

const PageHeader = ({ template }: { template: TemplateType }) => {
  const { navigateBackWithWorkspace, navigateWithWorkspaceUrl } =
    useWorkspaceHook();
  const { lastLocation } = useHistory();

  const handleClickBack = () => {
    if (
      lastLocation?.pathname.includes('in-progress-checklist') ||
      lastLocation?.pathname.includes('templates/spreadsheet') ||
      !lastLocation
    ) {
      navigateWithWorkspaceUrl('/checklist');
    } else {
      navigateBackWithWorkspace();
    }
  };

  const { admins, submitters } = useMemo(() => {
    if (!template?.ChecklistAccess.length) {
      return {
        admins: [],
        submitters: [],
      };
    }
    const admins = template?.ChecklistAccess?.filter(
      (accessor) => accessor.role === 'admin',
    );
    const submitters = template?.ChecklistAccess?.filter(
      (accessor) => accessor.role === 'submitter',
    );
    return {
      admins: admins.map((access) => access.AccessorId),
      submitters: submitters.map((access) => access.AccessorId),
    };
  }, [template]);

  return (
    <Column>
      <NameAndBack>
        <BackButton
          onClick={handleClickBack}
          startIcon={<ArrowBackRoundedIcon />}
        >
          Back
        </BackButton>
        <Tooltip title={template?.name}>
          <TemplateNameText>{template?.name}</TemplateNameText>
        </Tooltip>
      </NameAndBack>
      <MetaWrapper>
        <TemplateStatusChip
          icon={template?.isPublished ? <GlobeIcon /> : <EditIcon2 />}
          size="small"
          label={template?.isPublished ? 'Published' : 'Draft'}
        />
        <TemplateOwnerWrapper sx={{ marginRight: '16px' }}>
          <TemplateOwnerText>Template Admins:</TemplateOwnerText>
          {admins.length ? (
            <UserOrTeamAvatarGroup
              max={3}
              members={admins}
              width={24}
              height={24}
              labelSx={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            />
          ) : (
            <AllUsersText>All users & teams</AllUsersText>
          )}
        </TemplateOwnerWrapper>
        <TemplateOwnerWrapper>
          <TemplateOwnerText>Template Submitters:</TemplateOwnerText>
          {submitters.length ? (
            <UserOrTeamAvatarGroup
              max={3}
              members={submitters}
              width={24}
              height={24}
              labelSx={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            />
          ) : (
            <AllUsersText>All users & teams</AllUsersText>
          )}
        </TemplateOwnerWrapper>
        <NavWrapper>
          <RightNav />
        </NavWrapper>
      </MetaWrapper>
    </Column>
  );
};

export default PageHeader;
