import { useDispatch } from 'react-redux';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Stack } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import RolesSearchInput from './search/search';
import { HasPermission } from 'components/HasPermission';
import { PERMISSIONS } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const RolesViewHeader = (props) => {
  const { onSearch } = props;
  const dispatch = useDispatch();
  const { hasFeature, FEATURES } = useCustomPermission();

  const openCustomRolesDetailForm = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CUSTOM_ROLES_SET_DETAILS,
      }),
    );
  };

  return (
    <Stack
      p="16px 0px"
      justifyContent="space-between"
      direction="row"
      alignItems="center"
    >
      <RolesSearchInput onSearch={onSearch} />

      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ROLES]}>
        {hasFeature(FEATURES.CUSTOM_ROLES) && (
          <Stack justifyContent="end" alignItems="center" direction="row">
            <CustomButton
              variant="contained"
              startIcon={<AddOutlinedIcon />}
              onClick={openCustomRolesDetailForm}
              sx={{ borderRadius: '8px' }}
            >
              Create New Role
            </CustomButton>
          </Stack>
        )}
      </HasPermission>
    </Stack>
  );
};

export default RolesViewHeader;
