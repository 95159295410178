//Create functional component names as Reporing Dashboard
import React from 'react';
import { ReportingDashboardCnt } from 'pages/ReportingV2/reportingV2.style';
import TaskOverview from 'pages/ReportingV2/Reports/TaskOverview';
import { Route, Routes } from 'react-router-dom';
import LocationDetailView from 'pages/Locations/LocationDetail/LocationDetail.view';
import LocationsHeader from 'pages/Locations/Header/LocationsHeader';
import { LocationsList } from 'pages/Locations/LocationsList/locationsList.view';
import ReportingHeader from 'pages/ReportingV2/ReportingHeader/ReportListingHeader';
import ReportingList from 'pages/ReportingV2/ReportListing/ReportingList';
import ReportDashboard from 'pages/ReportingV2/ReportDashboard/ReportDashboard';

const ReportingDashboard = () => {
  return (
    <ReportingDashboardCnt>
      {/*<DashboardTemplatesHeader />*/}
      {/*<ReportingList />*/}

      <Routes>
        <Route path="/:reportId" element={<ReportDashboard />} />

        <Route
          path="/"
          element={
            <>
              <ReportingHeader />
              <ReportingList />
            </>
          }
        />
      </Routes>
    </ReportingDashboardCnt>
  );
};

export default ReportingDashboard;
