import React, { FC, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { setItem, clearLocalStorage } from 'utils/globalFunction';
import { async } from '@firebase/util';

const LoginWithParams: FC = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const run = async () => {
      await clearLocalStorage();
      setItem(
        LOCALSTORAGE_KEYS.USER_PROFILE_ID,
        searchParams.get(LOCALSTORAGE_KEYS.USER_PROFILE_ID),
      );
      setItem(
        LOCALSTORAGE_KEYS.ACCESS_TOKEN,
        searchParams.get(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      );
      try {
        setItem(
          LOCALSTORAGE_KEYS.REMEMBER_EMAIL,
          searchParams.get(LOCALSTORAGE_KEYS.REMEMBER_EMAIL),
        );
      } catch (error) {
        console.log('error: REMEMBER_EMAIL', error);
      }
      try {
        setItem(
          LOCALSTORAGE_KEYS.REMEMBER_PASSWORD,
          searchParams.get(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD),
        );
      } catch (error) {
        console.log('error: REMEMBER_PASSWORD', error);
      }
      try {
        setItem(
          LOCALSTORAGE_KEYS.FORGOT_EMAIL,
          searchParams.get(LOCALSTORAGE_KEYS.FORGOT_EMAIL),
        );
      } catch (error) {
        console.log('error: FORGOT_EMAIL', error);
      }
      try {
        setItem(
          LOCALSTORAGE_KEYS.EXPIRES_IN,
          searchParams.get(LOCALSTORAGE_KEYS.EXPIRES_IN),
        );
      } catch (error) {
        console.log('error: EXPIRES_IN', error);
      }
      try {
        setItem(
          LOCALSTORAGE_KEYS.DEVICE_TOKEN,
          searchParams.get(LOCALSTORAGE_KEYS.DEVICE_TOKEN),
        );
      } catch (error) {
        console.log('error: DEVICE_TOKEN', error);
      }
      if (searchParams.get(LOCALSTORAGE_KEYS.MASTER_HOTEL_ID)) {
        const hid = searchParams.get(LOCALSTORAGE_KEYS.MASTER_HOTEL_ID);
        navigate(`/ws/${hid}/dashboard`, { replace: true });
      } else {
        navigate(`/`, { replace: true });
      }
    };
    run();
  }, []);

  return null;
};

export default LoginWithParams;
