import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import getDocDefinition from './docDefinition';
import DateTime from 'utils/DateTime';

function printDoc(printParams, gridApi, columnApi, orgHotelName, tz) {
  const fileName = `${orgHotelName}_Work_Order_Report_${DateTime.fromTodayInTz(
    tz,
  ).toTzString(tz, 'MMM DD YY')}`;
  const docDefinition = getDocDefinition(
    printParams,
    gridApi,
    columnApi,
    orgHotelName,
  );
  pdfMake.createPdf(docDefinition).download(fileName);
}

export default printDoc;
