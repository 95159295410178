import CustomButton from 'components/Button/CustomButton';
import { useCallback, useMemo, useState } from 'react';
import {
  ActionColumnRendererCnt,
  ArrowBtn,
  AssetNameCnt,
  AttachmentIcon,
  CreateServiceBtn,
  EmptyLoaderCnt,
  LastServiceCnt,
  LastServiceFormatterCnt,
  LocationNameCnt,
  MUILoader,
  MainCnt,
  NextServiceCnt,
  ReminderIconCnt,
} from 'pages/Assets/AssetsList/assetsList.styles';
import AddTaskWorkOrderIcon from 'components/Icons/addTaskWorkorderIcon';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowExpanded from '@mui/icons-material/KeyboardArrowDown';
import ArrowCollapsed from '@mui/icons-material/KeyboardArrowRight';
import ChildItemIcon from 'components/Icons/childItemIcon';
import { appRoutes } from 'utils/globalVariable';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';
import { isEmpty } from 'lodash';
import OpenBookIcon from 'components/Icons/openBookIcon';
import useUpdateAsset from 'utils/CustomHooks/useUpdateAsset';
import { HasPermission } from 'components/HasPermission';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import mime from 'mime';
import xeniaApi from 'api/index';
import actions from 'store/actions';
import { Badge, Box, Typography } from '@mui/material';
import ImageComponent from 'components/common/htmlTags/image';
import ImageFileUpload from 'components/ImageFileUpload';
import { getLocationsState } from 'store/selectors/locations';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import ReminderIcon from 'components/Icons/reminderIcon';
import AddIcon from 'components/Icons/addIcon';
import DIALOGS from 'utils/dialogIds';
import { getAssetById } from 'api/assets';
import { setAuthAssets, updateAssets } from 'store/actions/assetActions';
import CalendarInputIcon from 'components/Icons/calendarInputIcon';
import { formatDateDifference } from 'utils/globalFunction';
import useAssetsServices from 'utils/CustomHooks/useAssetsServices';

export function PhotosAttachment(props) {
  const { asset } = props;
  const attachments = asset?.attachments || [];
  const dispatch = useDispatch();
  const { updateAsset } = useUpdateAsset();

  const [loading, setLoading] = useState(false);
  const attachmentsData: any = useMemo(() => {
    return attachments
      ?.filter((url) => /\.(jpe?g|png|gif)$/i.test(url))
      ?.map((attachment: any) => {
        return {
          url: attachment,
          type: mime.getType(attachment),
        };
      })
      ?.reverse();
  }, [attachments]);

  const uploadAttachments = async (acceptedFiles: any) => {
    try {
      if (acceptedFiles.length) {
        setLoading(true);
        const response = await xeniaApi.CreateTaskAttchmentFilesApi(
          acceptedFiles,
        );
        if (response) {
          let attachedList = response.filePath.map(
            (item) => response.baseUrl + item,
          );

          if (asset?.attachments?.length > 0) {
            attachedList = [
              ...asset.attachments,
              ...response.filePath.map((item) => response.baseUrl + item),
            ];
          }

          const postData = { ...asset, attachments: attachedList };
          updateAsset({ ...postData });
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <MainCnt loading={loading} onHoverOnly={attachmentsData?.length > 0}>
      {attachmentsData?.length > 0 ? (
        <div
          className="photoCnt"
          onClick={() => {
            dispatch(
              actions.setAttachmentsPreviewData({
                index: 0,
                attachments: attachmentsData,
              } as any),
            );
          }}
        >
          {loading && <MUILoader disableShrink size={20} />}
          <Badge
            badgeContent={
              attachmentsData.length - 1 === 0
                ? 0
                : `+${attachmentsData.length - 1}`
            }
            color="success"
          >
            <div className="imgbox" role="button">
              <ImageComponent
                src={attachmentsData?.[0]?.url}
                className={'img'}
              />
            </div>
          </Badge>
        </div>
      ) : loading ? (
        <EmptyLoaderCnt>
          <MUILoader disableShrink size={20} />
        </EmptyLoaderCnt>
      ) : (
        <p>-</p>
      )}

      <div className="attachCnt">
        <ImageFileUpload
          onUploadCallback={uploadAttachments}
          options={{
            accept: {
              'image/*': [],
            },
          }}
        >
          <AttachmentIcon />
        </ImageFileUpload>
      </div>
    </MainCnt>
  );
}

export function ActionColumnRenderer({ params }) {
  const { PERMISSIONS } = useCustomPermission();
  const { id, LocationId } = params.data;
  const dispatch = useDispatch();
  const handleCreateTask = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          dialogState: {
            viewLess: false,
          },
          taskData: {
            ...(LocationId && { locationIds: [LocationId] }),
            AssetId: id,
          },
        },
      }),
    );
  };
  return (
    <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
      <ActionColumnRendererCnt
        className={'actions'}
        data-attribute={'cell-click'}
      >
        <CustomButton
          startIcon={<AddTaskWorkOrderIcon className={'addTaskIcon'} />}
          onClick={handleCreateTask}
          variant={'outlined'}
          buttonType={'grayWhite'}
        >
          Create Work Order
        </CustomButton>
      </ActionColumnRendererCnt>
    </HasPermission>
  );
}
export function OpenAssetBtnRenderer({ params }) {
  const { id, ParentId } = params.data;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const navigateToAssetDetail = () => {
    const urlToNavigate = ParentId ? `/${ParentId}/sa/${id}` : `/${id}`;
    navigateWithWorkspaceUrl(appRoutes.assets + urlToNavigate);
  };
  return (
    <div className={'actions'} style={{ display: 'none' }}>
      <CustomButton
        startIcon={<OpenBookIcon sx={{ color: '#757575' }} />}
        onClick={navigateToAssetDetail}
        variant={'outlined'}
        buttonType={'grayWhite'}
        sx={{ borderRadius: '30px', padding: '3px 13px' }}
      >
        Open
      </CustomButton>
    </div>
  );
}

export function AssetNameRenderer({ params }) {
  const { name, ParentId, avatar, SubAssets } = params.data;
  const { updateAsset } = useUpdateAsset();

  const handleUpdateAssetColor = (color) => {
    updateAsset({ ...params.data, avatar: { color } });
  };
  const haveSubAssets = !isEmpty(params?.data?.SubAssets);
  return (
    <AssetNameCnt>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        data-attribute={'cell-click'}
      >
        {!ParentId && params.node.expanded && haveSubAssets && (
          <ArrowBtn
            onClick={(e) => {
              e.stopPropagation();
              params.node.setExpanded(false);
              globalThis?.AssetsGrid.api.redrawRows();
            }}
          >
            <ArrowExpanded />{' '}
          </ArrowBtn>
        )}
        {!ParentId && !params.node.expanded && haveSubAssets && (
          <ArrowBtn
            onClick={(e) => {
              e.stopPropagation();
              params.node.setExpanded(true);
              globalThis?.AssetsGrid.api.redrawRows();
            }}
          >
            <ArrowCollapsed />
          </ArrowBtn>
        )}
        {ParentId ? <ChildItemIcon className="subAssetIcon" /> : null}
      </span>
      {!ParentId ? (
        <span
          data-attribute={'cell-click'}
          style={{ marginLeft: !haveSubAssets ? 30 : '' }}
        >
          <ColorsDropdown
            selected={avatar?.color}
            changeHandler={handleUpdateAssetColor}
            iconStyles={{
              width: 10,
              height: 10,
              borderRadius: '2px',
              border: 'none',
            }}
          />
        </span>
      ) : null}
      <span className={'assetName'}>{name}</span>

      {SubAssets?.length ? (
        <span className={'countsTag'}>{SubAssets?.length} Sub-Assets</span>
      ) : null}
    </AssetNameCnt>
  );
}

export function LocationName({ location }) {
  const locationsList = useSelector(getLocationsState);

  const getParentLocation = useCallback(
    (locationId) => {
      return locationsList.find((item) =>
        item?.Sublocations?.some((subLoc) => subLoc?.id === locationId),
      );
    },
    [location, locationsList],
  );

  const parentLocation = getParentLocation(location?.id);

  return (
    <LocationNameCnt isParent={parentLocation?.name}>
      <Box
        sx={{
          backgroundColor: location?.avatar?.color,
          minWidth: 10,
          minHeight: 10,
          borderRadius: '2px',
          border: 'none',
        }}
      />
      <div className="content">
        <span className={'locationName'}> {location.name}</span>
        {parentLocation?.name && (
          <span className="parentLocationName"> {parentLocation?.name}</span>
        )}
      </div>
    </LocationNameCnt>
  );
}

export function NextServiceDate({ asset }) {
  const { FEATURES, hasFeature } = useCustomPermission();
  const { getUtcToTz } = useDateTime();
  const { NextService: nextService } = asset || {};
  const TZDateTime = getUtcToTz(nextService?.date, 'MMM DD, YYYY h:mm A');
  const { addNewAssignees } = useAssigneesUtils();
  const { removeAssetService } = useAssetsServices();
  const dispatch = useDispatch();

  const assignees = useMemo(() => {
    return addNewAssignees(nextService?.reminder?.recipients ?? []);
  }, [asset?.nextService?.reminder?.recipients]);

  const createNewServiceHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_ASSET_SERVICE,
        data: {
          asset,
          onSuccessCallback: async () => {
            const updatedAsset = await getAssetById(asset?.id);
            dispatch(updateAssets(updatedAsset));

            const authAssets = await xeniaApi.getAuthAssets();
            dispatch(setAuthAssets(authAssets));
          },
        },
      }),
    );
  };

  const deleteConfirmCallback = async () => {
    const response = await removeAssetService(asset?.NextService?.id);
    if (response) {
      const updatedAsset = await getAssetById(asset?.id);
      dispatch(updateAssets(updatedAsset));
      const authAssets = await xeniaApi.getAuthAssets();
      dispatch(setAuthAssets(authAssets));
      globalThis?.AssetsGrid?.api?.redrawRows();
    }
  };

  const deleteService = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.CONFIRMATION,
        open: true,
        data: {
          confirmButtonProps: {
            style: {
              backgroundColor: '#D32F2F',
            },
          },
          confirmationText: 'Delete',
          title: 'Delete Asset Service',
          description: 'Are you sure you want to delete this asset service?',
          onConfirmCallback: deleteConfirmCallback,
        },
      }),
    );
  };

  const editAssetServiceHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_ASSET_SERVICE,
        data: {
          isEdit: true,
          service: { ...asset?.NextService, Asset: asset },
          onSuccessCallback: async () => {
            const updatedAsset = await getAssetById(asset?.id);
            dispatch(updateAssets(updatedAsset));
            const authAssets = await xeniaApi.getAuthAssets();
            dispatch(setAuthAssets(authAssets));
            globalThis?.AssetsGrid?.api?.redrawRows();
          },
          deleteHandler: deleteService,
        },
      }),
    );
  };

  const isReminderActive = useMemo(() => {
    return (
      nextService?.reminder?.recipients &&
      (nextService?.reminder?.email ||
        nextService?.reminder?.sms ||
        nextService?.reminder?.push)
    );
  }, []);

  const hasAssetsServicesFeature = useMemo(
    () => hasFeature(FEATURES.ASSET_SERVICE),
    [],
  );

  return (
    <div data-attribute="cell-click" style={{ height: '100%' }}>
      {!nextService ? (
        hasAssetsServicesFeature ? (
          <CreateServiceBtn
            variant="text"
            onClick={createNewServiceHandler}
            startIcon={<AddIcon />}
          >
            Create Service
          </CreateServiceBtn>
        ) : (
          '-'
        )
      ) : (
        <NextServiceCnt onClick={editAssetServiceHandler}>
          <ReminderIconCnt
            style={{
              ...(!isReminderActive && {
                backgroundColor: 'white',
                border: '1px solid #E0E0E0',
              }),
            }}
          >
            <ReminderIcon
              className="icon"
              sx={{
                ...(!isReminderActive && {
                  '& path': {
                    stroke: '#6868FE !important',
                  },
                }),
              }}
            />
          </ReminderIconCnt>
          <AssigneesView
            type="avatars"
            data={assignees}
            assigneesProps={{
              popperProps: {
                sx: {
                  zIndex: 1700,
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'white !important',
                    boxShadow:
                      'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                    minWidth: 300,
                    maxHeight: 300,
                    overflowY: 'auto',
                    borderRadius: '15px',
                  },
                },
              },
            }}
          />
          {isReminderActive && <span style={{ marginLeft: 5 }}>•</span>}
          <Typography
            className="dateTime"
            style={{
              ...(isReminderActive && {
                marginLeft: 5,
              }),
            }}
          >
            {TZDateTime}
          </Typography>
        </NextServiceCnt>
      )}
    </div>
  );
}

export function LastServiceDate({ lastService }) {
  const { getUtcToTz } = useDateTime();

  const TZDateTime = useMemo(() => {
    if (lastService?.date) {
      return getUtcToTz(
        lastService?.date,
        lastService?.createdInPast ? 'MMM DD, YYYY' : 'MMM DD, YYYY  h:mm A',
      );
    }
  }, [lastService?.date]);
  return (
    <LastServiceCnt>
      <CalendarInputIcon className="calendarIcon" />
      <Typography className="dateTime">{TZDateTime || '-'}</Typography>
    </LastServiceCnt>
  );
}

export function LastServiceFormater({ lastService }) {
  const { getUtcToTz } = useDateTime();
  const formattedAgo = useMemo(() => {
    if (lastService?.date) {
      const TZDateTime = getUtcToTz(lastService?.date);
      return formatDateDifference(TZDateTime);
    }
  }, [lastService?.date]);

  return (
    <LastServiceFormatterCnt>
      <Typography className="dateTime">{formattedAgo || '-'}</Typography>
    </LastServiceFormatterCnt>
  );
}
