import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useWidget } from 'pages/Dashboards/hooks/useWidgets';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import { groupBy, map, sumBy } from 'lodash';

export const useLoadWidget = (widgetId: string, globalFilters: any) => {
  const params = useParams();
  const { dashboardId } = params;
  const { getWidgetQuery } = useWidget({
    apiParams: {
      widgetId,
      dashboardId,
      data: { filters: globalFilters },
    },
  });

  const flagCategoryDataTransformation = (data) => {
    const grouped = groupBy(data, 'valueX');
    const result = map(grouped, (items, key) => {
      if (key === 'N/A') {
        return {
          valueX: 'N/A',
          valueY: sumBy(items, 'valueY'),
          metadata: {
            id: items
              ?.filter((item) => item?.valueX === 'N/A')
              ?.map?.((item) => item?.metadata?.id),
          },
        };
      } else {
        return items?.[0];
      }
    });

    return result;
  };

  useEffect(() => {
    getWidgetQuery.refetch();
  }, [globalFilters]);

  const data = getWidgetQuery?.data || {};
  const widgetData = data?.data || [];
  const summary = data?.summary;
  const widget = data?.widget || {};

  const parsedData = useMemo(() => {
    if (widget?.type === WIDGET_TYPE_IDS.CUSTOM_TABLE) {
      return widgetData;
    }
    let transformedData = widgetData?.map((d: any) => {
      const { valueX, valueY, ...rest } = d;
      return {
        valueX: valueX ?? 'N/A',
        valueY: Number(valueY),
        ...rest,
      };
    });

    if (widget?.config?.metric === 'flagCategory') {
      transformedData = flagCategoryDataTransformation(transformedData);
    }

    return transformedData;
  }, [data]);

  return { parsedData, getWidgetQuery, widget, summary };
};
