import AddIcon from '@mui/icons-material/Add';
import { setDialog } from 'store/actions/dialogActions';
import CustomButton from 'components/Button/CustomButton';
import {
  AssetDetailHeaderCnt,
  BreadcrumbHeadingCnt,
  AssetName,
  AssetsHeading,
} from 'pages/Assets/AssetDetail/AssetDetailHeader/assetDetailHeader.style';
import React, { useMemo } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '@mui/material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { appRoutes } from 'utils/globalVariable';
import CustomToolTip from 'components/Tooltip';
import useAssetDetail from 'pages/Assets/AssetDetail/useAssetDetail';
import { useDispatch, useSelector } from 'react-redux';
import xeniaApi from 'api/index';
import { setAssetTaskHistory } from 'store/actions/assetActions';
import { getAssetsState } from 'store/selectors/assets';
import { HasPermission } from 'components/HasPermission';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';

function AssetDetailHeader({ onAssignWorkCb }) {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { PERMISSIONS } = useCustomPermission();
  const { selectedAsset } = useAssetDetail();
  const assetsList = useSelector(getAssetsState);
  const dispatch = useDispatch();
  const handleBackClick = () => {
    const prevUrl = selectedAsset?.ParentId
      ? `${appRoutes?.assets}/${selectedAsset?.ParentId}`
      : appRoutes?.assets;
    navigateWithWorkspaceUrl(prevUrl);
  };
  const handleNavigateToParentAsset = () => {
    const navUrl = `${appRoutes?.assets}/${selectedAsset?.ParentId}`;
    navigateWithWorkspaceUrl(navUrl);
  };
  const fetchAssetTaskHistory = async () => {
    onAssignWorkCb?.();
    const taskHistoryList = await xeniaApi.getAssetTaskHistory(
      selectedAsset?.id,
    );
    dispatch(setAssetTaskHistory(taskHistoryList));
  };
  //Open create task dialog
  const handleCreateTask = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          onSuccessCallBack: fetchAssetTaskHistory,
          dialogState: {
            viewLess: false,
          },
          taskData: {
            locationIds: [selectedAsset?.LocationId],
            AssetId: selectedAsset?.id,
          },
        },
      }),
    );
  };
  const parentAsset = useMemo(() => {
    return assetsList.find((l) => selectedAsset?.ParentId == l?.id);
  }, [selectedAsset]);
  return (
    <AssetDetailHeaderCnt>
      <BreadcrumbHeadingCnt>
        <CustomToolTip title={'Back'}>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
        </CustomToolTip>
        <AssetsHeading
          className={'heading'}
          clickable={Boolean(parentAsset)}
          {...(selectedAsset && {
            onClick: () => navigateWithWorkspaceUrl(appRoutes?.assets),
          })}
        >
          Assets
        </AssetsHeading>
        <ChevronRightIcon className={'rightArrowIcon'} />

        {selectedAsset?.ParentId ? (
          <AssetName
            selected={!selectedAsset.ParentId}
            onClick={handleNavigateToParentAsset}
          >
            {parentAsset?.name}
          </AssetName>
        ) : (
          <AssetName selected={!selectedAsset.ParentId}>
            {selectedAsset?.name}
          </AssetName>
        )}
        {selectedAsset?.ParentId && (
          <>
            <ChevronRightIcon className={'rightArrowIcon'} />
            <AssetName selected={true}>{selectedAsset?.name}</AssetName>
          </>
        )}
      </BreadcrumbHeadingCnt>
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
        <CustomButton
          variant="contained"
          startIcon={
            <QueryBuilderRoundedIcon style={{ color: 'white', fontSize: 22 }} />
          }
          style={{ padding: '7px 16px', borderRadius: '6px' }}
          onClick={handleCreateTask}
          className="createWO"
        >
          Assign Work
        </CustomButton>
      </HasPermission>
    </AssetDetailHeaderCnt>
  );
}

export default AssetDetailHeader;
