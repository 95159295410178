import { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import {
  configureSSO,
  enableDisableSSO,
  getSSOConfigurations,
} from 'api/workspaceApi';
import {
  BoldText,
  GreyText,
  LoaderCnt,
  PageHeader,
  PageTitle,
  PageWrapper,
} from './style';
import FeatureLockIcon from 'components/Icons/featureLockIcon';
import IosSwitch from 'components/IosSwitch';
import CustomButton from 'components/Button/CustomButton';
import VerifyDomain from './VerifyDomain';
import ConfigureSso from './ConfigureSso';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import AssignRole from './AssignRole';

const SSOConfigrations = () => {
  const { hasFeature, hasPermission, FEATURES } = useCustomPermission();
  const [enableSSO, setEnableSSO] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [configurations, setConfigurations] = useState<any>(null);

  const isFeatureEnabled = useMemo(() => {
    return hasFeature(FEATURES.SSO);
  }, []);
  const getConfigurations = async () => {
    setIsLoading(true);
    const response: any = await getSSOConfigurations();
    setEnableSSO(response?.ssoEnabled || false);
    updateConfig(response);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isFeatureEnabled) {
      getConfigurations();
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleCreateSSOConfig = async () => {
    if (configurations === null) {
      setButtonLoading(true);
      const response: any = await configureSSO({});
      updateConfig(response);
      setButtonLoading(false);
    }
  };
  const handleEnableSSO = async () => {
    const payload = {
      ssoEnabled: !enableSSO,
    };
    const response: any = await enableDisableSSO(payload);
    updateConfig(response);
    setEnableSSO(!enableSSO);
  };

  const updateConfig = (data) => {
    setConfigurations(data);
  };
  return (
    hasPermission(PERMISSIONS.CAN_MANAGE_SSO) && (
      <PageWrapper>
        {isLoading ? (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        ) : (
          <>
            <PageHeader style={{ display: 'flex', alignItems: 'center' }}>
              <PageTitle style={{ marginRight: 5 }}>
                Single Sign-On (SSO)
              </PageTitle>
            </PageHeader>
            <VerifyFeatureAccess featureId={FEATURES.SSO}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                border={'1px solid #E0E0E0'}
                borderRadius={'8px'}
                padding={'12px 16px'}
                mb={2}
                sx={{
                  ...(!isFeatureEnabled && {
                    cursor: 'pointer',
                  }),
                }}
              >
                <Stack gap={'4px'} width="100%">
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <BoldText>Enable SSO</BoldText>
                    {/* enable swicth */}
                    {isFeatureEnabled && (
                      <>
                        {configurations?.ssoConfigured &&
                          configurations.ssoDomainVerified &&
                          configurations.ssoRoleId && (
                            <IosSwitch
                              height={20}
                              width={32}
                              checked={enableSSO}
                              onChange={handleEnableSSO}
                              thumbSize={16}
                            />
                          )}
                        {configurations === null && (
                          <CustomButton
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleCreateSSOConfig}
                            buttonLoader={buttonLoading}
                            sx={{ borderRadius: '8px' }}
                          >
                            Create configurations
                          </CustomButton>
                        )}
                      </>
                    )}
                  </Stack>
                  <GreyText>
                    When enabled, users can streamline their login experience,
                    reducing the need to remember multiple usernames and
                    passwords.
                  </GreyText>
                </Stack>
                {!isFeatureEnabled && (
                  <FeatureLockIcon style={{ height: 36, width: 36 }} />
                )}
              </Stack>
            </VerifyFeatureAccess>
            {configurations && (
              <>
                <VerifyDomain
                  isDone={configurations?.ssoDomainVerified || false}
                  disabled={configurations?.ssoDomainVerified || false}
                />
                <ConfigureSso
                  isDone={configurations?.ssoConfigured}
                  disabled={
                    !configurations?.ssoDomainVerified ||
                    configurations?.ssoConfigured
                  }
                />
                <AssignRole
                  isDone={configurations?.ssoRoleId}
                  disabled={!configurations?.ssoConfigured}
                  ssoId={configurations.id}
                  ssoRoleId={configurations.ssoRoleId}
                  updateConfig={updateConfig}
                />
              </>
            )}
          </>
        )}
      </PageWrapper>
    )
  );
};

export default SSOConfigrations;
