// MUI components
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const CopyLinkBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 1)',
  borderRadius: 4,
  padding: '4px 10px',
  gap: 8,
  border: '1px solid rgba(132, 134, 255, 1)',
  cursor: 'pointer',
  width: 108,
});

export const CopyLinkText = styled(Typography)({
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '22px',
  color: 'rgba(104, 104, 254, 1)',
});
