// React
import { useEffect, useState } from 'react';

// React Router
import { useParams, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import Tabs from 'pages/TemplateDashboard/TabsHeader';
import PageHeader from 'pages/TemplateDashboard/PageHeader';

// Mui
import { CircularProgress } from '@mui/material';

// Styled
import { StyledCustomModal, Content, LoaderWrapper } from './styled';

// Utils
import xeniaApi from 'api/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Context
import {
  DashboardContext,
  TemplateType,
} from 'pages/TemplateDashboard/context';

// Hooks
import { useTemplateAccess } from 'controller/useTemplateAccess';
import useFlaggedCategoriesConfig from 'utils/CustomHooks/useFlaggedCategoriesConfig';

const TemplateDashboard = () => {
  const { templateId } = useParams();
  const [query] = useSearchParams();
  const { getFlaggedCategories } = useFlaggedCategoriesConfig();

  const logId = query.get('logId');

  const { workspaceId, navigateWithWorkspaceUrl } = useWorkspaceHook();

  const { isTemplateAdmin } = useTemplateAccess();

  const currentLog = useSelector(selectors.getChecklistLog);

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<number>(logId ? 1 : 0);
  const [template, setTemplate] = useState<TemplateType | undefined>(undefined);
  const [submissionCount, setSubmissionCount] = useState<number | undefined>(
    undefined,
  );
  const [shouldUpdate, setShouldUpdate] = useState(0);

  useEffect(() => {
    let tab: any = searchParams.get('tab');
    if (tab !== undefined) {
      tab = parseInt(tab);
      if (tab >= 0 && tab <= 2) {
        setActiveTab(tab);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    fetchSubmissionCount();
  }, [shouldUpdate]);

  useEffect(() => {
    fetchTemplate();
  }, [templateId]);

  useEffect(() => {
    if (currentLog) {
      setShouldUpdate((prev) => prev + 1);
    }
  }, [currentLog]);

  // fetch data initially
  const fetchTemplate = async () => {
    const template = await xeniaApi.getChecklist(templateId);
    if (template?.data) {
      if (!template.data.name || !isTemplateAdmin(template?.data)) {
        navigateWithWorkspaceUrl('/checklist');
      }
      setTemplate(template?.data);
      setLoading(false);
    } else {
      navigateWithWorkspaceUrl('/checklist');
    }
  };

  const fetchSubmissionCount = async () => {
    const submissionCount = await xeniaApi.getTemplateSubmissionCount({
      workspaceId: workspaceId as string,
      templateId: templateId as string,
    });
    if (submissionCount?.data) {
      setSubmissionCount(submissionCount.data.count);
    }
  };

  useEffect(() => {
    getFlaggedCategories();
  }, []);

  const context = {
    templateId,
    template,
    activeTab,
    submissionCount,
    shouldUpdate,
    setActiveTab,
    setTemplate,
    setShouldUpdate: () => setShouldUpdate((prev) => prev + 1),
  };

  return (
    <StyledCustomModal
      fullScreen
      open={true}
      transitionDuration={0}
      handleClose={() => console.log('')}
    >
      {!template || loading || template === undefined ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <DashboardContext.Provider value={context}>
          <PageHeader template={template} />
          <Content>
            <Tabs />
          </Content>
        </DashboardContext.Provider>
      )}
    </StyledCustomModal>
  );
};

export default TemplateDashboard;
