import styled from '@mui/system/styled';
import { Typography } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  height: '100%',
  paddingTop: '100px',
});

export const IconWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 120,
  height: 120,
  borderRadius: 120,
  alignItems: 'center',
  background: 'rgba(235, 234, 255, 1)',
  justifyContent: 'center',
  marginBottom: 16,
  '& svg': {
    fontSize: 60,
  },
});

export const BoldText = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: 0.15,
  color: 'rgba(33,33,33,1)',
  marginBottom: 4,
});

export const NormalText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  letterSpacing: 0.17,
  color: '#616161',
  width: 494,
  textAlign: 'center',
  marginBottom: 20,
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));
