/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import 'rc-time-picker/assets/index.css';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

import { useReactRouter } from 'controller/useReactRouter';
import {
  IntroBannerCnt,
  TaskContentCnt,
  TaskListWrapper,
} from 'pages/taskDashboard.style';
import TaskListView from 'pages/task/TaskListView/TaskListview.cmp';
import TaskCalendar from 'pages/task/TaskListView/TaskCalendar/TaskCalendar.view';
import { Route, Routes } from 'react-router-dom';
import withTitleHoc from 'components/Routes/withTitleHoc';
import { PAGE_TITLES } from 'utils/globalVariable';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import SavedTaskTemplates from './savedtasktemplates';

const TaskCalendarCmp = withTitleHoc(TaskCalendar);
const TaskListViewCmp = withTitleHoc(TaskListView);
const SavedTaskTemplatesCmp = withTitleHoc(SavedTaskTemplates);

import TaskListHeader from 'pages/task/TaskListView/TaskListHeader/TaskListHeader';
import TaskCalendarHeader from 'pages/task/TaskListView/TaskCalendarHeader/TaskCalendarHeader';
import { useTaskEdit } from 'controller/useTaskEdit';
import xeniaApi from 'api/index';
import { setDialog } from 'store/actions/dialogActions';
import useTaskFilters from 'utils/CustomHooks/useTaskFilters/useTaskFilters';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
import { getUserHotelTz } from 'store/selectors/auth';
import TasksViewTabsList from './task/tabs/list';
import { Stack, Typography } from '@mui/material';
import CrossIcon from 'components/Icons/crossIcon';
import { useUserConfig } from 'controller/useUserConfig';

export const filtersKeys = [
  'taskStatus',
  'priority',
  'taskLocations',
  'taskAssets',
  'taskCategories',
  'taskAssignees',
  'taskDueFilter',
  'taskType',
];
const TaskHome: FC = () => {
  const dispatch = useDispatch();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const [hideMainBanner, setHideMainBanner] = useState<any>(true);
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const [searchParams] = useSearchParams();

  const [currentTabInTasksView, setCurrentTabInTasksView] = useState('List');

  const tz = useSelector(getUserHotelTz);

  const defaultLast7DaysFilter = useMemo(() => {
    if (tz) {
      const range = dateRangePickerSideMenuCommon(tz)[4];
      return {
        from: range?.range?.from,
        to: range?.range?.to,
        selectedDateOption: range?.label,
      };
    }
    return {};
  }, [tz]);

  const [forcedAdvanceFilters, setForcedAdvanceFilters] = useState<any>(null);
  const [dateFilter, setDateFilter] = useState<any>(defaultLast7DaysFilter);

  const {
    handleSetAdvanceFiltersCommon,
    fetchAdvanceFilters,
    syncAdvanceFilters,
  } = useTaskFilters();

  const disabledAdvanceFilters = useMemo(() => {
    if (forcedAdvanceFilters) {
      const disabledFilters = forcedAdvanceFilters.map((f) => f.filterName);
      return disabledFilters;
    }
    return null;
  }, [forcedAdvanceFilters, searchParams]);

  const formatDateFilter = (dateFilter) => {
    return {
      comparator: 'is',
      conditional: 'and',
      filterName: 'createdDate',
      value: {
        from: dateFilter.from,
        to: dateFilter.to,
      },
    };
  };

  const getTasksListing = async (currentTab = currentTabInTasksView) => {
    dispatch(actions.setLoader({ type: 'tasksList', value: true }));
    dispatch(actions.setLoader({ type: 'tasksCalendar', value: true }));

    // Getting Saved Configurations through API
    const dbFilters = await fetchAdvanceFilters();
    syncAdvanceFilters(dbFilters); // Dispatch to save in redux store

    // Get Advance Filters
    const advanceFilters = dbFilters?.tabs?.[currentTab]?.filters;
    const dateFilter =
      dbFilters?.tabs?.[currentTab]?.dateRange ?? defaultLast7DaysFilter;

    setDateFilter(dateFilter);

    setForcedAdvanceFilters(null);
    handleSetAdvanceFiltersCommon(
      advanceFilters,
      [formatDateFilter(dateFilter)],
      {
        skipFilterSave: true,
      },
      currentTabInTasksView,
    );
  };

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_VIEW_TASKS)) {
      setTimeout(() => {
        navigateWithWorkspaceUrl('/dashboard');
      }, 1000);
    }

    getGeneralConfig();

    // Gettings List of Tasks
    getTasksListing();
  }, []);

  const { handleEditDetailTask } = useTaskEdit();

  const getTaskDetailIdFromNotification: any = useSelector(
    selectors.getTaskDetailIdFromNotification,
  );

  const loaders = useSelector(selectors.getLoaders);

  const getSearchText: any = useSelector(selectors.getSearchTextFromStore);
  const taskListFilters: any = useSelector(selectors.getTaskListFilters);

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const { handleGetQueryParam } = useReactRouter();
  const { handleGetPathName } = useReactRouter();

  const path = handleGetPathName();

  useEffect(() => {
    const params = handleGetQueryParam();
    const channelId = params.get('channelId');
    const isCommentChannel =
      channelId && channelId.startsWith('task_comments_');
    if (channelId && !isCommentChannel) {
      navigateWithWorkspaceUrl(`/messages/${channelId}`);
      return;
    }
    const taskId =
      (isCommentChannel && channelId.substr(14)) || params.get('taskId');
    if (taskId) {
      const isCommentParam =
        params.get('comments') && params.get('comments') === '1';
      const section =
        isCommentChannel || isCommentParam ? 'comments' : 'detail';
      handleEditDetailTask(taskId, section);
      navigateWithWorkspaceUrl('/tasks/list');
    }
  }, [handleGetQueryParam()]);

  useEffect(() => {
    if (getTaskDetailIdFromNotification !== '') {
      handleEditDetailTask(getTaskDetailIdFromNotification, 'detail');
    }
  }, [getTaskDetailIdFromNotification]);

  const fetchTask = async (id, isRecur?: any) => {
    if (!isRecur) {
      const taskData = await xeniaApi.taskDetailSingleListing({
        id,
      });
      dispatch(
        actions.editSingleTask({
          taskId: id,
          task: { ...taskData?.data },
        }),
      );
    } else {
      closeTaskDetail();
    }
  };

  const closeTaskDetail = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'taskDetail',
      }),
    );
  };

  const getGeneralConfig = async () => {
    const userConfig = await getUserConfig(
      `taskAndWorkOrdersBanner::${userId}`,
    );
    setHideMainBanner(userConfig?.hideTaskAndWorkordersBanner);
  };

  const closeBanner = async () => {
    const config = await editUserConfig(`taskAndWorkOrdersBanner::${userId}`, {
      hideTaskAndWorkordersBanner: true,
    });

    if (config) {
      setHideMainBanner(true);
    }
  };

  const showTasksListFilters = !loaders?.filters?.isLoading;
  return hasPermission(PERMISSIONS.CAN_VIEW_TASKS) ? (
    <TaskContentCnt>
      {showTasksListFilters && (
        <>
          {path?.includes('/tasks/list') && (
            <>
              {!hideMainBanner && (
                <IntroBannerCnt
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div className="crossCnt" onClick={closeBanner}>
                    <CrossIcon className="icon" />
                  </div>

                  <Stack direction="row" alignItems="center" gap="10px">
                    <WysiwygIcon style={{ color: '#0277BD' }} />

                    <Typography className="statement">
                      <b>Custom Views:</b> Create multiple task views with
                      unique filters. Tip: Recreate Corrective Actions and
                      Requested Tasks views using the Task Type filter.
                    </Typography>
                  </Stack>
                </IntroBannerCnt>
              )}
              <TasksViewTabsList
                currentTabInTasksView={currentTabInTasksView}
                setCurrentTabInTasksView={setCurrentTabInTasksView}
                getTasksListing={getTasksListing}
              />
              {currentTabInTasksView === 'Calendar' ? (
                <TaskCalendarHeader
                  forcedAdvanceFilters={forcedAdvanceFilters}
                  disabledAdvanceFilters={disabledAdvanceFilters}
                  isMainView={!searchParams.get('filters')}
                  currentTabInTasksView={currentTabInTasksView}
                />
              ) : (
                <TaskListHeader
                  forcedAdvanceFilters={forcedAdvanceFilters}
                  disabledAdvanceFilters={disabledAdvanceFilters}
                  isMainView={!searchParams.get('filters')}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  formatDateFilter={formatDateFilter}
                  currentTabInTasksView={currentTabInTasksView}
                />
              )}
            </>
          )}{' '}
        </>
      )}

      <div style={{ position: 'relative', display: 'flex' }}>
        <Routes>
          {hasPermission(PERMISSIONS.CAN_MANAGE_TASKS) && (
            <Route
              path="savedtasktemplates"
              element={
                <SavedTaskTemplatesCmp
                  title={PAGE_TITLES.task}
                  childTitle={PAGE_TITLES.taskTemplates}
                />
              }
            />
          )}

          <Route
            path="list"
            element={
              <TaskListWrapper>
                {currentTabInTasksView === 'Calendar' ? (
                  <TaskCalendarCmp
                    handleEditDetailTask={handleEditDetailTask}
                    title={PAGE_TITLES.task}
                    childTitle={currentTabInTasksView}
                    forcedAdvanceFilters={forcedAdvanceFilters}
                  />
                ) : (
                  <TaskListViewCmp
                    title={PAGE_TITLES.task}
                    childTitle={currentTabInTasksView}
                    taskListFilters={taskListFilters}
                    getSearchText={getSearchText}
                    onRowClick={handleEditDetailTask}
                    taskActionDropdownProps={{
                      successCB: fetchTask,
                    }}
                    forcedAdvanceFilters={forcedAdvanceFilters}
                    isMainView={!searchParams.get('filters')}
                    currentTabInTasksView={currentTabInTasksView}
                    hideMainBanner={hideMainBanner}
                  />
                )}
              </TaskListWrapper>
            }
          />
        </Routes>
      </div>
    </TaskContentCnt>
  ) : null;
};

export default TaskHome;
