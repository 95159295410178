import styled from '@mui/system/styled';

export const MySchedulesContainer = styled('div')(({ theme }) => ({
  padding: '0 24px',
  height: 'calc(100vh - 220px)',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 50px)',
  },
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 50px)',
  },
}));

export const LoadingContainer = styled('div')(({ theme }) => ({
  padding: '24px',
  height: 'calc(100vh - 220px)',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 50px)',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
