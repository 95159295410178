import { useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import { sortByOptions } from 'pages/documents/subHeader/constants';
import {
  SortByDropdownButton,
  StyledEndIconCnt,
  MainCnt,
} from 'pages/documents/subHeader/SortByDropdown/sortByDropdown.style';

interface initialSortInterface {
  value: string;
  label: string;
}

const initialSort = sortByOptions.filter(
  (option) => option.value === 'createdAt',
) || [{ value: '', label: '' }];

function SortByList(props) {
  const { closeAction, selectedOption, setSelectedOption, onChangeCallback } =
    props;

  return (
    <SelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={true}
      canSearch={false}
      checkbox={false}
      isMulti={false}
      options={sortByOptions}
      minOneSelected={true}
      handleChangeCallback={(values) => {
        setSelectedOption(values);
        onChangeCallback(values?.[0]);
        closeAction();
      }}
    />
  );
}

function SortByDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected,
    children,
    onAddCategoryCallback,
    onChangeCallback,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] =
    useState<initialSortInterface[]>(initialSort);

  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };
  const handleChangeOption = async (values) => {
    setSelectedOption(values);
  };

  const selectedText = selectedOption?.length ? selectedOption[0]?.label : '';

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <MainCnt>
        <SortByDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption?.length > 0}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <span className="selectedSortText">Sort: {selectedText}</span>
        </SortByDropdownButton>
        <CustomDropdown
          paperProps={{
            sx: {
              overflow: 'hidden',
              paddingBottom: '0px',
            },
          }}
          popperProps={{
            ...popperProps,
            content: (
              <SortByList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                onChangeCallback={onChangeCallback}
                setSelectedOption={handleChangeOption}
                selected={selected}
                onAddCategoryCallback={onAddCategoryCallback}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999 },
            open: !!anchorEl,
            placement: 'bottom-start',
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </MainCnt>
    </ClickAwayListener>
  );
}

export default SortByDropdown;
