import XeniaLogo from 'components/common/xenia-logo';
import React, { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import xeniaApi from 'api/index';
import { useReactRouter } from 'controller/useReactRouter';

const Verify: FC = () => {
  const [searchParams] = useSearchParams();
  const { handleRouterReplace } = useReactRouter();

  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) return;

    const verify = async () => {
      const res = await xeniaApi.verifyEmail({ token });

      if (res.status === 200) {
        handleRouterReplace('/');
      }
    };
    void verify();
  }, [token]);
  return <XeniaLogo />;
};

export default Verify;
