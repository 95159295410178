import { Box, Stack, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const CreateNewServiceBtn = styled(CustomButton)({
  borderRadius: 8,
  height: 36,
  padding: '6px 16px 6px 16px',
});

export const FilterAction = styled(Box)({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  borderRadius: 100,
  padding: '0px 16px 0px 16px',
  gap: '8px',
  border: '1px solid #E0E0E0',
  height: 36,

  fontSize: 13,
  fontWeight: 600,
  lineHeight: '17.76px',
  letterSpacing: '0.14px',
  color: '#000000',

  '& .overdueCnt': {
    height: 18,
    width: 18,
    padding: '0px 6px 0px 6px',
    backgroundColor: '#EF5350',
    borderRadius: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .count': {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '14px',
      color: 'white',
    },
  },
});

export const HeaderCnt = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: 20,
  },
}));

export const FloatingBtn = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 15,
  right: 15,
  zIndex: 1500,
}));
