import { createSvgIcon } from '@mui/material';

const EyeOffIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1299_2904)">
        <path
          d="M7.425 3.18C7.94125 3.05916 8.4698 2.99876 9 3C14.25 3 17.25 9 17.25 9C16.7947 9.85171 16.2518 10.6536 15.63 11.3925M10.59 10.59C10.384 10.8111 10.1356 10.9884 9.85961 11.1113C9.58362 11.2343 9.28568 11.3004 8.98357 11.3058C8.68146 11.3111 8.38137 11.2555 8.10121 11.1424C7.82104 11.0292 7.56654 10.8608 7.35288 10.6471C7.13923 10.4335 6.97079 10.179 6.85763 9.8988C6.74447 9.61863 6.68889 9.31854 6.69423 9.01644C6.69956 8.71433 6.76568 8.41639 6.88866 8.14039C7.01163 7.86439 7.18894 7.61599 7.41 7.41M13.455 13.455C12.1729 14.4323 10.6118 14.9737 9 15C3.75 15 0.75 9 0.75 9C1.68292 7.26143 2.97685 5.74246 4.545 4.545L13.455 13.455Z"
          stroke="#7571C5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.75 0.75L17.25 17.25"
          stroke="#7571C5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1299_2904">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>,
    'EyeOffIcon',
  );
  return (
    <IconCmp
      style={{ fill: 'none', height: '18px', width: '18px' }}
      viewBox="0 0 18 18"
      {...props}
    />
  );
};
export default EyeOffIcon;
