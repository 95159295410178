import { uploadPublicDocumentCall } from 'api/documents';
import config from 'config/index';
import { getItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const uploadImageHandler = (blobInfo, document) =>
  new Promise<string>((resolve, reject) => {
    if (blobInfo.blob().size > 26214400) {
      return reject({
        message: 'File is too big! Max size 25 MB',
        remove: true,
      });
    }
    uploadPublicDocumentCall([blobInfo.blob()], document?.id).then(
      (response) => {
        if (response) {
          resolve(response?.baseUrl + response?.filePath?.[0]);
        } else {
          reject('Unable to upload image');
        }
      },
    );
  });

export const aiRequestHandler = (request, respondWith, document) => {
  const transformedPrompt =
    request?.system.join('\n') +
    '\n\nContext:""' +
    '\n\nQuestion: """' +
    request?.query +
    '"""' +
    '\n\nAnswer:';

  respondWith.stream(async (_, streamMessage) => {
    const postData = {
      prompt: transformedPrompt,
    };

    const token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || '';

    const customHeaders = {
      'Content-Type': 'application/json',
      Authorization: token,
      platform: 'webapp',
      ...(!token && { anonymousId: getItem(LOCALSTORAGE_KEYS.ANON_ID) || '' }),
    };
    const response: any = await fetch(
      `${config.REACT_APP_BASE_URL}hotel/documents/${document?.id}/ask`,
      {
        method: 'POST',
        headers: customHeaders,
        body: JSON.stringify(postData),
      },
    );

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();

    const read = true;

    while (read) {
      const { value, done } = await reader.read();
      let transformedValue = value;
      if (
        transformedValue?.includes('.\n') ||
        transformedValue?.includes(':\n')
      ) {
        transformedValue = transformedValue?.replace(/\n/g, '<br>');
      }

      if (done) break;
      streamMessage(transformedValue);
    }
  });
};
