import SettingsNavigation from 'pages/settings/settingsNav/settingsNav.view';
import { SettingsCnt } from 'pages/settings/settings.style';
import { Outlet } from 'react-router';
import React from 'react';

function Settings() {
  return (
    <SettingsCnt>
      <SettingsNavigation />
      <div className="contentCnt">
        <Outlet />
      </div>
    </SettingsCnt>
  );
}

export default Settings;
