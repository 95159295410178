import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import AssetsDropdown from 'components/Dropdown/Task/Asset/TaskAssetDropdown';
import { DropdownWithLabelCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';
import AssetsMultiSelect from 'components/AssetsDropdown/MultipleSelection';
import useDropdownData from 'utils/CustomHooks/useDropdownData';
import { PROJECT_TYPES } from 'utils/globalVariable';

const TaskAssetDropdown = () => {
  const { updateTask, newTask, isProject, projectTab } =
    useContext(CreateTaskContext);
  const { selectTaskAsset } = useDropdownData();
  const dispatch = useDispatch();
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createEditTask',
      }),
    );
  };

  const handleUpdateMultipleAsset = (values) => {
    updateTask({
      assets: values?.map((item) => item?.id),
    });
  };

  const handleUpdateAsset = (values) => {
    const option = values?.[0];
    const assetObj = {
      id: option?.id,
      ...(!isProject && { LocationId: option?.obj?.LocationId }),
      name: option?.label,
    };
    updateTask({
      AssetId: option?.id || null,
      Asset: assetObj,
      Location: null,
      ...(!isProject && option?.obj?.LocationId
        ? { locationIds: [option?.obj?.LocationId] }
        : { locationIds: null }),
    });
  };

  return (
    <DropdownWithLabelCnt>
      <label>Asset</label>

      {isProject && projectTab === PROJECT_TYPES.assetBasedProject ? (
        <AssetsMultiSelect
          onChangeCallback={(options) => handleUpdateMultipleAsset(options)}
          selected={selectTaskAsset(newTask?.assets ?? [])}
          onAddAssetCallback={closeAction}
        />
      ) : (
        <AssetsDropdown
          dropdownBtnProps={{
            disabled: newTask?.isAutoTagLocation || isProject,
          }}
          onAddAssetCallback={closeAction}
          onChangeCallback={handleUpdateAsset}
          popperProps={{ disablePortal: false }}
          dropdownProps={{ iconButton: true }}
          selected={newTask?.AssetId}
          isMulti={false}
          location={
            newTask?.locationIds?.[0] ||
            newTask?.LocationId ||
            newTask?.Location?.id
          }
          viewType={'select'}
        />
      )}
    </DropdownWithLabelCnt>
  );
};

export default TaskAssetDropdown;
