import React, { useCallback, useRef, useEffect } from 'react';
import { VariableSizeList as List } from 'react-window';
import { useWindowResize } from 'controller/useWindowResize';
import { ListOnItemsRenderedProps } from 'react-window';

interface VirtualListPropTypes {
  height?: number;
  rows: any[];
  rowGap?: number;
  onItemsRendered?: (props: ListOnItemsRenderedProps) => void;
  overscanCount?: number;
  onListScroll?: any;
}

const VirtualList = ({
  height = 500,
  rows,
  rowGap,
  onItemsRendered,
  overscanCount = 1,
  onListScroll,
}: VirtualListPropTypes) => {
  const listRef: any = useRef();
  const sizeMap = useRef({});
  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex?.(index);
  }, []);
  const [windowWidth] = useWindowResize();

  const getSize = (index: number) => sizeMap.current[index] || 50;

  return (
    <List
      ref={listRef}
      height={height}
      width="100%"
      itemCount={rows?.length}
      itemSize={getSize}
      itemData={rows}
      onItemsRendered={onItemsRendered}
      overscanCount={overscanCount}
      onScroll={onListScroll}
    >
      {({ data, index, style }) => (
        <div style={style}>
          <Row
            data={data}
            index={index}
            setSize={setSize}
            windowWidth={windowWidth}
            rowGap={rowGap}
          />
        </div>
      )}
    </List>
  );
};

const Row = ({ data, index, setSize, windowWidth, rowGap }) => {
  const rowRef: any = useRef();

  React.useEffect(() => {
    setSize(index, rowRef.current.getBoundingClientRect().height);
  }, [setSize, index, windowWidth]);

  return (
    <div style={{ paddingBottom: `${rowGap ?? 0}px` }} ref={rowRef}>
      {data[index]}
    </div>
  );
};

export default VirtualList;
