import { createSvgIcon } from '@mui/material';

const TextFieldIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.333 3.33333L12.083 3.33332C11.6228 3.33332 11.2497 2.96023 11.2497 2.49999C11.2497 2.03975 11.6228 1.66666 12.083 1.66666L14.1637 1.66666L14.1663 1.66666L14.169 1.66666L16.2497 1.66666C16.7099 1.66667 17.083 2.03976 17.083 2.5C17.083 2.96024 16.7099 3.33333 16.2497 3.33333L14.9997 3.33333V4.99999L15.6985 4.99999C16.1378 4.99998 16.5167 4.99996 16.8289 5.02547C17.1583 5.05238 17.486 5.11181 17.8013 5.27248C18.2717 5.51216 18.6542 5.89461 18.8939 6.36502C19.0545 6.68033 19.114 7.00801 19.1409 7.33747C19.1664 7.64961 19.1664 8.02858 19.1663 8.46787V11.5321C19.1664 11.9714 19.1664 12.3504 19.1409 12.6625C19.114 12.992 19.0545 13.3197 18.8939 13.635C18.6542 14.1054 18.2717 14.4878 17.8013 14.7275C17.486 14.8882 17.1583 14.9476 16.8289 14.9745C16.5168 15 16.1378 15 15.6986 15H14.9997V16.6667L16.2497 16.6667C16.7099 16.6667 17.083 17.0398 17.083 17.5C17.083 17.9602 16.7099 18.3333 16.2497 18.3333L12.083 18.3333C11.6228 18.3333 11.2497 17.9602 11.2497 17.5C11.2497 17.0398 11.6228 16.6667 12.083 16.6667L13.333 16.6667L13.333 3.33333Z"
        fill="#607D8B"
      />
      <path
        d="M4.30089 4.99999H10.833C11.2932 4.99999 11.6663 5.37309 11.6663 5.83332V14.1667C11.6663 14.6269 11.2932 15 10.833 15H4.30091C3.86168 15 3.48259 15 3.17049 14.9745C2.84103 14.9476 2.51335 14.8882 2.19803 14.7275C1.72763 14.4878 1.34518 14.1054 1.10549 13.635C0.944833 13.3197 0.885403 12.992 0.858485 12.6625C0.832983 12.3504 0.832995 11.9714 0.833008 11.5321V8.46787C0.832995 8.02861 0.832983 7.6496 0.858485 7.33747C0.885403 7.00801 0.944833 6.68033 1.10549 6.36502C1.34518 5.89461 1.72763 5.51216 2.19803 5.27248C2.51335 5.11181 2.84103 5.05238 3.17049 5.02547C3.48262 4.99996 3.86162 4.99998 4.30089 4.99999Z"
        fill="#607D8B"
      />
    </>,
    'TextFieldIcon',
  );
  return <IconCmp viewBox={'0 0 20 20'} {...props} />;
};
export default TextFieldIcon;
