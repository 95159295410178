import NoRecordsFound from 'components/Icons/noRecordFoundIcon';

import {
  WrapperDiv,
  NoResultsTitle,
  NoResultsDescriptionWrapper,
  NoResultsDescription,
} from './styled';

const AssetsWorkOrderEmptyState = () => {
  return (
    <WrapperDiv>
      <NoRecordsFound style={{ width: 307, height: 231 }} />
      <NoResultsTitle>No work orders found</NoResultsTitle>
      <NoResultsDescriptionWrapper>
        <NoResultsDescription>
          No work orders are currently linked to this asset. Create new work
          orders and assign them to this asset.
        </NoResultsDescription>
      </NoResultsDescriptionWrapper>
    </WrapperDiv>
  );
};

export default AssetsWorkOrderEmptyState;
