import { useContext, useEffect, useMemo, useState } from 'react';
// import DateFilter from 'pages/Widgets/TaskFilters/DateFilter';
import { DashboardCmpFiltersCnt } from 'pages/Dashboards/DashboardCmp/DashboardCmpContent/DashboardCmpFilters/dashboardCmpFilters.style';
import LocationFilterDropdown from 'pages/Widgets/TaskFilters/LocationFilter/locationFilters';
import { generateLocationDropdownData } from 'helper/task/dropdownData';
import { useSelector } from 'react-redux';
import { getLocationsState } from 'store/selectors/locations';
import { cloneDeep, isEqual } from 'lodash';
import { DashboardsContext } from 'pages/Dashboards/context/context';
import CustomButton from 'components/Button/CustomButton';
import DateTime from 'utils/DateTime';
import { getUserHotelTz } from 'store/selectors/auth';
import useResponsive from 'utils/CustomHooks/useResponsive';
import {
  dateRangePickerSideMenuCommon,
  reportingInitialRange,
} from 'utils/datePicker';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import { useUserConfig } from 'controller/useUserConfig';

function DashboardsCmpFilters(props) {
  const { selectedDashboard } = props;
  const { getUserConfig, editUserConfig } = useUserConfig();
  const tz = useSelector(getUserHotelTz) || 'America/Chicago';
  const initState = {
    dateRange: {
      selectedDateLabel: 'Last 30 Days',
      filters: {
        fromDate: new DateTime()
          .toTz(tz)
          .transform({ subtract: [29, 'day'], startOf: 'day' })
          .toJSDate(),
        toDate: new DateTime()
          .toTz(tz)
          .transform({ subtract: [0, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    location: null,
  };
  const { isMobileDeviceSm } = useResponsive();
  const { dashboardsState, updateDashboardsState } =
    useContext(DashboardsContext);
  const [state, updateState] = useState<any>(initState);
  const locationsList = useSelector(getLocationsState);

  const getConfigDetails = async () => {
    if (selectedDashboard?.id) {
      const persistantState = await getUserConfig(
        `CUSTOM_DASHBOARD_${selectedDashboard?.id}_GLOBAL_FILTERS`,
      );

      if (persistantState) {
        updateState(persistantState);
        updateDashboardsState({
          filters: {
            location: persistantState.location,
            dateRange: [
              persistantState.dateRange?.filters?.fromDate,
              persistantState.dateRange?.filters?.toDate,
            ],
          },
        });
      } else {
        updateDashboardsState({
          filters: {
            location: initState.location,
            dateRange: [
              initState.dateRange?.filters?.fromDate,
              initState.dateRange?.filters?.toDate,
            ],
          },
        });
      }
    }
  };

  useEffect(() => {
    getConfigDetails();
  }, [selectedDashboard]);
  const handleDateChange = (date): any => {
    const copyFilterState = cloneDeep(state);
    copyFilterState.dateRange = {
      selectedDateLabel: date?.selectedDateOption,
      filters: {
        ...state?.dateRange?.filters,
        fromDate: date?.from,
        toDate: date?.to,
      },
    };
    updateState(copyFilterState);
  };
  const handleLocationFilterChange = (values, type) => {
    const postData = values.map((v) => v.id);
    updateState({ ...state, location: postData });
  };
  const handleClearDateFilter = (date) => {
    handleDateChange(date);
  };
  const handleClearLocationFilter = (values) => {
    updateState({ ...state, location: values });
  };

  const handleApplyFilter = () => {
    editUserConfig(
      `CUSTOM_DASHBOARD_${selectedDashboard?.id}_GLOBAL_FILTERS`,
      state,
    );
    updateDashboardsState({
      filters: {
        location: state.location,
        dateRange: [
          state.dateRange?.filters?.fromDate,
          state.dateRange?.filters?.toDate,
        ],
      },
    });
  };
  const isFilterDisabled = () => {
    const localObj = {
      location: state.location,
      dateRange: [
        state.dateRange?.filters?.fromDate,
        state.dateRange?.filters?.toDate,
      ],
    };
    return isEqual(localObj, dashboardsState.filters);
  };
  return (
    <DashboardCmpFiltersCnt>
      <DateRangePickerCommon
        handleDateChange={handleDateChange}
        selectedDateLabel={state?.dateRange?.selectedDateLabel}
        buttonProps={{
          hideSelected: state?.dateRange?.selectedDateLabel === 'Last 30 Days',
        }}
        selectedDateRange={{
          from: state?.dateRange?.filters?.fromDate,
          to: state?.dateRange?.filters?.toDate,
        }}
        clearProps={{
          dateLabel: 'Last 30 Days',
          onClick: handleClearDateFilter,
          initialRange: reportingInitialRange,
          showClearBtn: state?.dateRange?.selectedDateLabel != 'Last 30 Days',
        }}
        contentProps={{
          displaySideOptions: !isMobileDeviceSm,
          allowInputs: !isMobileDeviceSm,
          numberOfMonths: isMobileDeviceSm ? 1 : 2,
          sideMenu: dateRangePickerSideMenuCommon,
        }}
      />
      <LocationFilterDropdown
        selected={state.location}
        onChangeCallback={(option) =>
          handleLocationFilterChange(option, 'locations')
        }
        onClearCallback={handleClearLocationFilter}
      />

      <CustomButton
        variant={'outlined'}
        onClick={handleApplyFilter}
        disabled={isFilterDisabled()}
      >
        Save Filters
      </CustomButton>
    </DashboardCmpFiltersCnt>
  );
}

export default DashboardsCmpFilters;
