import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { MainCnt, Value } from './style';

export const Name = (props) => {
  const { data } = props;
  const { id, name } = data || {};
  return (
    <MainCnt>
      <TeamAvatar teamId={id} width={26} height={26} />
      <Value style={{ marginLeft: 5 }}>{name}</Value>
    </MainCnt>
  );
};
