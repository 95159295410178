import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import CustomDashboardImg from 'public/assets/img/custom-dashboard.svg';
import {
  CustomDashboardEmptyCnt,
  MainTitle,
  SubTitle,
} from 'pages/Dashboards/EmptyState/dashboardMainEmptyState.style';
import CustomButton from 'components/Button/CustomButton';
import WithDashboardPermission from 'pages/Dashboards/withDashboardPermission';
import { useCreateDashboard } from 'pages/Dashboards/hooks/useDashboard';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const createDashboardPayload = {
  title: 'Untitled Dashboard',
};

const DashboardMainEmptyState = () => {
  const { createDashboardQuery } = useCreateDashboard();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const handleAddDashboard = () => {
    createDashboardQuery.mutate(createDashboardPayload, {
      onSuccess: (data) => {
        navigateWithWorkspaceUrl(`/dashboards/${data.id}`);
      },
    });
  };

  return (
    <CustomDashboardEmptyCnt>
      <img src={CustomDashboardImg} style={{ margin: '40px 0px 20px 0px' }} />
      <Stack direction="column">
        <MainTitle>Welcome to Custom Dashboards</MainTitle>
        <SubTitle>
          Create your personalized reports with custom chart widgets and
          filters. Start building your unique dashboard to gain insights that
          matter most to you.
        </SubTitle>
      </Stack>
      <WithDashboardPermission>
        <CustomButton
          variant="contained"
          style={{ borderRadius: '6px', width: 250, height: 40 }}
          startIcon={<AddIcon />}
          onClick={handleAddDashboard}
        >
          Create Your First Dashboard
        </CustomButton>
      </WithDashboardPermission>
    </CustomDashboardEmptyCnt>
  );
};

export default DashboardMainEmptyState;
