// Styled
import {
  Wrapper,
  IconWrapper,
  BoldText,
  GreyText,
} from './EmptyStateNotifications.styled';

// Icons
import EmptyMailboxIcon from 'components/Icons/emptyMailboxIcon';

interface PropTypes {
  activeTab: number;
}

const EmptyStateNotifications = ({ activeTab }: PropTypes) => {
  const TEXT_MAP = {
    0: {
      bold: 'No Notifications',
      grey: 'When you receive any new notifications, they will appear here.',
    },
    1: {
      bold: 'All Caught Up!',
      grey: `You're on top of things. No unread messages here.`,
    },
    2: {
      bold: 'No Alerts/Triggers',
      grey: `When you receive any new alert, trigger or flagged item notifications, they will appear here.`,
    },
  };

  const { bold, grey } = TEXT_MAP[activeTab];

  return (
    <Wrapper>
      <IconWrapper>
        <EmptyMailboxIcon className="empty-icon" />
      </IconWrapper>
      <BoldText>{bold}</BoldText>
      <GreyText>{grey}</GreyText>
    </Wrapper>
  );
};

export default EmptyStateNotifications;
