import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const NoAddedCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  zIndex: 1,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '& .addBtn': {
    cursor: 'pointer',
    '& svg': {
      height: 50,
      width: 50,
      backgroundColor: colors.primary.main,
      borderRadius: '50%',
      color: colors.white,
      padding: 10,
    },
  },
});

export const TextCnt = styled('div')({
  margin: '10px 0px',
  textAlign: 'center',
});

export const MainText = styled('p')({
  fontWeight: 800,
  fontSize: 16,
  color: colors.black,
});

export const SubText = styled('p')({
  fontSize: 14,
  '& .inner-text': {
    color: colors.primary.main,
  },
});
