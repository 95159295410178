import { Grid } from '@mui/material';

import React, { useContext } from 'react';
import { DocumentsContext } from 'pages/documents/context/context';
import FolderItem from 'pages/documents/listing/gridView/folder';
import DocumentItem from 'pages/documents/listing/gridView/document';
import { Route, Routes, useParams } from 'react-router-dom';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function GridView(props) {
  const {
    state = {},
    updateState,
    updateFolder,
    deleteFolder,
    addDocument,
    updateDocument,
    onAddDocumentToFolder,
    deleteDocument,
    getFolderAndDocuments,
  } = useContext(DocumentsContext);

  const { isArchive, selectedFolder } = state;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const params = useParams();
  const { folders, documents } = props;

  const folderDropdownProps = {
    updateFolderCallback: updateFolder,
    archiveFolderCallback: () => getFolderAndDocuments({}, false),
  };

  const archivedFoldersDropdownProps = {
    deleteFolderCallback: deleteFolder,
  };

  const documentDropdownProps = {
    updateDocumentCallback: updateDocument,
    onAddDocumentToFolder: onAddDocumentToFolder,
    deleteDocumentCallback: deleteDocument,
    addDocumentCallback: addDocument,
  };

  const archiveDocumentDropdownProps = {
    deleteDocumentCallback: deleteDocument,
  };

  const handleFolderSelect = (folder) => {
    updateState({ loading: true, selectedFolder: folder });
    if (isArchive) {
      navigateWithWorkspaceUrl(
        params?.['*']
          ? `/documents/archive/folder/${params?.['*'] + '/' + folder?.id}`
          : `/documents/archive/folder/${folder?.id}`,
      );
    } else {
      navigateWithWorkspaceUrl(
        params?.['*']
          ? `/documents/my-documents/folder/${params?.['*'] + '/' + folder?.id}`
          : `/documents/my-documents/folder/${folder?.id}`,
      );
    }
  };

  return (
    <>
      {/*{!selectedFolder && (*/}
      <div>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {folders?.map((f) => {
            return (
              <FolderItem
                folder={f}
                folderDropdownProps={folderDropdownProps}
                archivedFoldersDropdownProps={archivedFoldersDropdownProps}
                onClick={handleFolderSelect}
                isArchive={isArchive}
              />
            );
          })}
        </Grid>
      </div>
      {/*// )}*/}

      {/* Documents Listing */}
      <div>
        <Grid container spacing={2}>
          {documents?.map((f) => {
            return (
              <DocumentItem
                file={f}
                documentDropdownProps={documentDropdownProps}
                archiveDocumentDropdownProps={archiveDocumentDropdownProps}
                selectedFolder={selectedFolder}
                isArchive={isArchive}
              />
            );
          })}
        </Grid>
      </div>
    </>
  );
}

export default GridView;
