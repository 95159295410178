import { useMemo } from 'react';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { LastServiceDateCnt } from './assetDetailTab.style';
import { Typography } from '@mui/material';
import { formatDateDifference } from 'utils/globalFunction';

interface IProps {
  lastService?: any;
}

const LastServiceAgo = (props: IProps) => {
  const { lastService } = props;
  const { getUtcToTz } = useDateTime();

  const lastServiceAgo = useMemo(() => {
    if (lastService?.date) {
      const TZDateTime = getUtcToTz(lastService?.date);
      return formatDateDifference(TZDateTime);
    }
  }, [lastService?.date]);

  return (
    <LastServiceDateCnt>
      <div className="label">Last Service</div>
      <div className="innerCnt">
        <Typography className="dateTime">{lastServiceAgo || '-'}</Typography>
      </div>
    </LastServiceDateCnt>
  );
};

export default LastServiceAgo;
