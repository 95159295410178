import { Grid } from '@mui/material';
import ChecklistTileCmp from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistTileCmp';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import { useContext } from 'react';

function TemplateItem(props) {
  const { template, templates, actionDropdownProps, folder } = props;
  const { isArchive } = useContext(MyTemplatesContext);
  return (
    <Grid key={template?.id} item xs={6} md={4} lg={3} xl={3}>
      <ChecklistTileCmp
        checklist={template}
        checklists={templates}
        checklistScope={'myTemplates'}
        actionDropdownProps={actionDropdownProps}
        folder={folder}
        isArchive={isArchive}
      />
    </Grid>
  );
}

export default TemplateItem;
