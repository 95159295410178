import { createSvgIcon } from '@mui/material';

const LocationIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 8.33331C2.5 4.19118 5.85786 0.833313 10 0.833313C14.1421 0.833313 17.5 4.19118 17.5 8.33331C17.5 10.4385 16.5443 12.2835 15.2436 13.9725C14.1566 15.3839 12.7653 16.7631 11.3755 18.1408L11.3746 18.1418C11.1121 18.402 10.8496 18.6622 10.5893 18.9226C10.2638 19.248 9.73618 19.248 9.41074 18.9226C9.15022 18.662 8.887 18.4011 8.6243 18.1407C7.23455 16.763 5.84337 15.3839 4.75643 13.9725C3.45569 12.2835 2.5 10.4385 2.5 8.33331ZM12.5 8.33331C12.5 9.71402 11.3807 10.8333 10 10.8333C8.61929 10.8333 7.5 9.71402 7.5 8.33331C7.5 6.9526 8.61929 5.83331 10 5.83331C11.3807 5.83331 12.5 6.9526 12.5 8.33331Z"
        fill="#26A69A"
      />
    </>,
    'LocationIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default LocationIcon;
