import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import {
  BoostImg,
  Header,
  InviteandBoost,
  MainCnt,
  Main,
  TemplateRow,
  Content,
} from './style';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import BoostDashboard from 'public/assets/img/boostdashboard.png';

import ChecklistIconTemplate from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistIcon';
import ExploreTemplate from 'public/assets/img/explore-template.svg';
import FileSearchIcon from 'components/Icons/fileSearchIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const CheckListReport: FC = () => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const allTemplates = useSelector(selectors.getChecklistCompleteDataOnly);

  const activeTemplates = allTemplates?.filter(
    (item) => !item?.HotelChecklist?.archivedAt,
  );

  const isContent = activeTemplates?.length > 0 ? true : false;

  const onClickOpenTemplate = (templateId: string) => {
    navigateWithWorkspaceUrl(`/templates/${templateId}`);
  };

  return (
    <Main>
      {!isContent && (
        <InviteandBoost>
          <div className="box">
            <CTypography className="title">
              Get started fast with a ready-made operations template
            </CTypography>
            <hr />
            <CTypography className="subtitle">
              Set up your team for success in record time with templates for
              operations, submissions, inspections, SOPs and more.
            </CTypography>
            <BoostImg src={BoostDashboard} />
            <CustomButton
              variant="contained"
              type="submit"
              startIcon={<img src={ExploreTemplate} />}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
              onClick={() => {
                dispatch(
                  setDialog({
                    dialogId: DIALOGS.TEMPLATE_LIBRARY,
                    open: true,
                  }),
                );
              }}
            >
              {'Explore templates'}
            </CustomButton>
          </div>
        </InviteandBoost>
      )}

      {isContent && (
        <MainCnt>
          <Header>
            <CTypography className="title">My Templates</CTypography>
            <CustomButton
              variant="contained"
              startIcon={<FileSearchIcon style={{ fill: 'none' }} />}
              onClick={() => navigateWithWorkspaceUrl('/checklist')}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
            >
              Go to My Templates
            </CustomButton>
          </Header>
          <hr />

          {/* Rows Content */}
          <Content>
            {activeTemplates?.map((row: any) => (
              <TemplateRow
                key={row?.id}
                onMouseDown={stopPropagation}
                onTouchStart={stopPropagation}
                onClick={() => onClickOpenTemplate(row.id)}
              >
                <div className="firstColumn">
                  <ChecklistIconTemplate
                    icon={row.icon}
                    style={{ minWidth: 24 }}
                  />
                  <CTypography className="label">{row.name}</CTypography>
                </div>
                <div className="secondColumn">
                  <div className="logs">
                    <CTypography>
                      {row.logsCount} submission{row.logsCount != 1 && 's'}
                    </CTypography>
                  </div>
                  <div
                    onClick={() => onClickOpenTemplate(row.id)}
                    className="openTemplate"
                  >
                    Open Template
                  </div>
                </div>
              </TemplateRow>
            ))}
          </Content>
        </MainCnt>
      )}
    </Main>
  );
};

export default CheckListReport;
