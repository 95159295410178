import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CustomButton from 'components/Button/CustomButton';

export const NextServiceCnt = styled(Stack)({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '2px',
  height: '100%',

  '& .dateTime': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '-0.1px',
    color: '#212121',
  },
});

export const LastServiceCnt = styled(Stack)({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '2px',
  height: '100%',

  '& .calendarIcon': {
    height: 17,
    width: 17,
    fill: 'none',
    marginRight: 5,
  },

  '& .dateTime': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '-0.1px',
    color: '#212121',
  },
});

export const LastServiceFormatterCnt = styled(Stack)({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '2px',
  height: '100%',

  '& .dateTime': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '-0.1px',
    color: '#212121',
  },
});

export const CreateServiceBtn = styled(CustomButton)({
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '17.76px',
  letterSpacing: '-0.1px',
});

export const ReminderIconCnt = styled(Box)({
  width: 28,
  height: 28,
  borderRadius: '100px',
  backgroundColor: '#6868FE',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .icon': {
    height: 17,
    width: 17,
    fill: 'none',
    '& path': {
      stroke: 'white',
    },
  },
});

export const MainCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'loading' && prop != 'onHoverOnly',
})(({ theme, loading, onHoverOnly }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '& .attachCnt': {
    display: onHoverOnly ? 'none' : 'block',
  },
  '&:hover': {
    '& .attachCnt': {
      display: 'block',
    },
  },
  '& .photoCnt': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    '& .imgbox': {
      borderRadius: '4px',
      opacity: loading ? 0.5 : 1,
      display: 'flex',
      '& .img': {
        width: '60px',
        height: '38px',
        borderRadius: '4px',
        objectFit: 'cover',
        border: '1px solid #f0f4fe',
      },
    },
    '& .MuiBadge-badge': {
      top: 7,
      fontSize: 11,
      fontWeight: 800,
      padding: 2,
      color: colors.white,
      backgroundColor: colors.primary.main,
    },
  },
}));

export const AttachmentIcon = styled(AttachFileOutlinedIcon)({
  color: 'gray',
  height: 17,
  transform: 'rotate(45deg)',
  marginLeft: 10,
  cursor: 'pointer',
  '&:hover': {
    color: 'black',
  },
});

export const CellContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: 'max-content',
});

export const EmptyLoaderCnt = styled('div')({
  position: 'relative',
  width: '60px',
  height: '38px',
  borderRadius: '4px',
  objectFit: 'cover',
  border: '1px solid #f0f4fe',
  backgroundColor: 'lightgray',
});

export const MUILoader = styled(CircularProgress)({
  position: 'absolute',
  left: 19,
  top: 8,
  opacity: 0.6,
  '& svg': {
    color: '#3c3737',
  },
});

export const AssetNameCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& .subAssetIcon': {
    color: '#757575',
    fontSize: 16,
    marginRight: 12,
  },
  '.countsTag': {
    padding: '5px 8px 5px 8px',
    height: 29,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 60,
    background: '#F5F5F5',
    color: '#212121',
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 13,
  },
  '& .assetName': {
    fontSize: 14,
    fontWeight: 600,
    color: '#212121',
    marginLeft: 10,
  },
});
export const ActionColumnRendererCnt = styled('div')({
  display: 'none',
  textAlign: 'right',
  '& button': {
    borderRadius: 30,
    paddingLeft: 15,
  },
  '& .addTaskIcon': {
    color: '#757575',
  },
});

export const TitleCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'inProgress',
})(({ theme, inProgress }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  opacity: inProgress ? 0.5 : 1,
  '& .rowContent': {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 10,
    color: colors.black,
  },
}));
export const ArrowBtn = styled(IconButton)({
  padding: 0,
  marginRight: 8,
  '& svg': {
    fontSize: 22,

    color: '#9E9E9E',
  },
});

export const LocationNameCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isParent',
})(({ isParent }) => ({
  display: 'flex',
  lineHeight: isParent ? 'normal' : 'unset',
  alignItems: 'center',
  height: '100%',
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .parentLocationName': {
    color: 'rgba(111, 111, 111, 0.87)',
    fontSize: 12,
    marginLeft: 12,
    fontWeight: 500,
  },

  '& .locationName': {
    color: '#212121',
    fontSize: 14,
    marginLeft: 12,
    fontWeight: 500,
  },
  '& .colorIcon': {
    fontSize: 14,
    borderRadius: '2px',
  },
}));

export const NoAddedCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  zIndex: 1,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 810,
  marginTop: 50,
  [theme.breakpoints.down('lg')]: {
    width: 650,
  },
  [theme.breakpoints.down('md')]: {
    width: 550,
  },
  [theme.breakpoints.down('sm')]: {
    width: 350,
  },
  '& .addBtn': {
    cursor: 'pointer',
    width: 182,
    height: 45,
    borderRadius: 8,
  },
  '& img': {
    width: 180,
  },
}));
