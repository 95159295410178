import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { isEqual } from 'lodash';
import selectors from 'store/selectors';
import {
  AddLocation,
  Container,
  LocationsActionBtn,
  LocationsActionWrapper,
  SelectedText,
} from './style';

export const TeamLocation = (props) => {
  const { data, handleUpdateCall } = props;
  const { id, Location } = data || {};
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const userProfileData: any = useSelector(selectors.getUserProfile);

  const [selected, setSelected] = useState<any>(null);

  useEffect(() => {
    if (!Location?.id) {
      setSelected(null);
      return;
    }

    setSelected(Location?.id);
  }, [Location]);

  const locationsUpdateHandler = async (locData) => {
    setSelected(locData?.currentSelected);

    handleUpdateCall?.({
      id,
      name: data?.name,
      Members: data?.Members?.map((item) => item?.MemberId) ?? [],
      isActive: true,
      LocationId: locData?.currentSelected,
    });
  };

  return (
    <Container>
      <AssigneeDropdown
        handleChangeCallback={(data) => locationsUpdateHandler(data)}
        buttonRendererProps={{
          width: '100%',

          style: {
            ...(!userProfileData?.isAdmin &&
              userProfileData?.id !== data?.id && { pointerEvents: 'none' }),
          },
        }}
        buttonRenderer={
          Location ? (
            <Stack direction="row" alignItems="center" gap="20px">
              <SelectedText>{Location?.name}</SelectedText>

              <ArrowDropDownIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            </Stack>
          ) : (
            <AddLocation>
              <Typography className="text">Add to Location</Typography>
              <ArrowDropDownIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            </AddLocation>
          )
        }
        showTabs={false}
        listProps={{
          locations: {
            showInlineSelection: true,
            isSearchable: true,
            isMulti: false,
            isSelectAll: false,
            hideActions: false,
            selected: selected ? [selected] : [],
            footer: (
              <LocationsActionWrapper>
                <Stack
                  direction="row"
                  alignItems="center"
                  onClick={() => {
                    navigateWithWorkspaceUrl('/locations');
                  }}
                >
                  <LocationsActionBtn>Go to Locations</LocationsActionBtn>
                  <ArrowForwardIosIcon
                    sx={{
                      color: '#6868FE',
                      cursor: 'pointer',
                      height: 15,
                      width: 15,
                    }}
                  />
                </Stack>
              </LocationsActionWrapper>
            ),
          },
        }}
        displayOptions={{
          locations: true,
        }}
        popperProps={{ disablePortal: false }}
        allSelected={false}
      />
    </Container>
  );
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.data, nextProps.data);
}
export default memo(TeamLocation, areEqual);
