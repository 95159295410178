import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const SortByDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  backgroundColor: 'white',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  width: 191,
  justifyContent: 'space-around',
  padding: '6px 10px 6px 10px',
  height: 40,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    fontSize: 14,
    color: '#212121',
  },
});

export const MainCnt = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',

    '& button': {
      padding: '0px 8px',
    },
    '& .selectedSortText': {
      fontSize: 12,
    },
  },
}));
