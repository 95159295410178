// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const CustomizeTemplateBtn = styled(CustomButton)(() => ({
  borderRadius: 6,
  height: 36,
  color: 'white',
  '& svg': {
    '& path': {
      stroke: 'white',
      strokeOpacity: 1,
    },
  },
}));

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '10px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  flexDirection: 'column',
  gap: 4,
}));

export const TemplateNameAndAttachmentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 12,
  width: '100%',
}));

export const TemplateName = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '800',
  lineHeight: '38px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
}));

export const AttachmentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 8px',
  height: 36,
  borderRadius: 6,
  background: 'rgba(224, 224, 224, 1)',
  border: '1px solid rgba(189, 189, 189, 1)',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.4px',
  cursor: 'pointer',
  color: 'rgba(66, 66, 66, 1)',
  '& svg': {
    fontSize: 16,
  },
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 6,
  padding: '6px 16px',
  color: '#212121',
}));
