import React from 'react';

import { Typography, CircularProgress, Box, Stack } from '@mui/material';

interface StatBoxProps {
  title: string;
  count: number;
  percentage: number;
  color: string;
}

const StatBox: React.FC<StatBoxProps> = (props) => {
  const { title, count, percentage, color } = props;
  return (
    <Stack
      direction={'row'}
      p="12px 16px"
      border={'1px solid #E0E0E0'}
      borderRadius="12px"
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Stack gap="2px">
        <Typography fontSize={'14px'} color={color} fontWeight={'700'}>
          {title}
        </Typography>
        <Typography fontSize={'24px'} color={'#212121'} fontWeight={'800'}>
          {count}
        </Typography>
      </Stack>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <Box
          sx={{
            position: 'relative',
            height: 'inherit',
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              color: '#DBE4E8',
            }}
            size={60}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            sx={{
              color: color,
              position: 'absolute',
              left: 0,
            }}
            size={60}
            value={percentage}
          />
        </Box>
        <Box
          sx={{
            top: -3,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            fontSize={'12px'}
            color={'#212121'}
            fontWeight={'700'}
          >{`${percentage}%`}</Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default StatBox;
