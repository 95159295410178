import { useContext } from 'react';
import { DocumentsContext } from 'pages/documents/context/context';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import { MainCnt, Value } from './style';

function FolderName(props) {
  const { updateState } = useContext(DocumentsContext);
  const { folder } = props;
  const { title, meta } = folder;

  return (
    <MainCnt>
      <TemplateFolderIcon
        primaryColor={meta?.primaryColor}
        secondaryColor={meta?.secondaryColor}
        className="icon"
      />
      <Value style={{ fontWeight: 700 }}>{title}</Value>
    </MainCnt>
  );
}

export default FolderName;
