// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

export const Header = styled(Box)(({ theme }) => ({
  padding: '10px 16px',
  width: '100%',
  height: 49,
  zIndex: 10000,
  borderBottom: `1px solid rgba(224, 224, 224, 1)`,
  display: 'flex',
  alignItems: 'center',
}));

export const BackButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  padding: '6px 8px',
}));

export const Heading = styled(Typography)({
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: '800px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  cursor: 'default',
  margin: '0px auto',
});
