import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { NameInputWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import LevelLocationsDropdown from 'components/LevelLocationsDropdown';
import LocationLevelsDropdown from 'components/LocationLevelsDropdown';

const LocationLevel = ({ editable, updateAction, location }) => {
  const [parentLevelSelected, setParentLevelSelected] = useState<any>(null);
  const [isLocLevels, setIsLocLevels] = useState();
  const [isInitial, setIsInitial] = useState(true);

  const updateLocLevels = (data) => {
    setIsLocLevels(data);
  };
  const onLevelChangeHandler = (data) => {
    updateAction({
      LevelId: data?.level?.id,
      Level: data?.level,
      ParentId: isInitial ? location?.ParentId : null,
    });
    setParentLevelSelected(data?.parentLevel);
    setIsInitial(false);
  };

  const parentChangeHandler = (data) => {
    updateAction({
      ParentId: data?.id,
    });
  };

  useEffect(() => {
    if (editable) {
      setIsInitial(true);
    }
  }, [editable, location]);

  return (
    <NameInputWithLabel style={{ marginTop: 4 }}>
      {editable ? (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: 10, display: isLocLevels ? 'flex' : 'none' }}
        >
          <Grid item xs={12} sm={6}>
            <label>Level</label>
            <LocationLevelsDropdown
              selected={location?.Level?.id}
              onSelectionCallback={onLevelChangeHandler}
              updateLocLevelsCallback={updateLocLevels}
            />
          </Grid>

          {parentLevelSelected && (
            <Grid item xs={12} sm={6}>
              <label>{parentLevelSelected?.title}</label>
              <LevelLocationsDropdown
                selected={location?.ParentId}
                selectedLevel={parentLevelSelected}
                onSelectionCallback={parentChangeHandler}
                blacklistIds={[location.id]}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <>
          <label>Level</label>
          <label className={'heading'}>{location?.Level?.title || '-'}</label>
        </>
      )}
    </NameInputWithLabel>
  );
};

export default LocationLevel;
