import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { groupBy, isEqual, omit } from 'lodash';
import { useWindowResize } from 'controller/useWindowResize';
import InfiniteVirtualList from 'components/InfiniteVirtualList/InfiniteVirtualList';
import useDateTime from 'utils/CustomHooks/useDateTime';
import ServiceInfoCard from './infoCard/infoCard';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { Date, ListingCnt } from './style';
interface IProps {
  isNextPageLoading?: boolean;
  services?: any;
  callbackActions?: any;
  handleLoadNextPage(data: any): void;
  servicesMeta?: any;
}

function ServicesLogGrid(props: IProps) {
  const {
    isNextPageLoading,
    handleLoadNextPage,
    services,
    callbackActions,
    servicesMeta,
  } = props;

  const { isMobileDeviceMd } = useResponsive();

  const parentRef = useRef<HTMLDivElement | null>(null);
  const size = useWindowResize();
  const [parentHeight, setParentHeight] = useState<any>(null);

  useEffect(() => {
    if (parentRef?.current?.clientHeight) {
      setParentHeight(parentRef.current.clientHeight);
    }
  }, [parentRef?.current?.clientHeight, size]);

  const { getUtcToTz } = useDateTime();

  const formatDate = (item) => getUtcToTz(item.date, 'ddd, MMM DD, YYYY');

  const servicesGroupedData: any = useMemo(() => {
    return services && services?.length > 0
      ? groupBy(services, formatDate)
      : {};
  }, [services]);

  const groupedDataKeys = Object.keys(servicesGroupedData);

  const servicesWithDatesRows = useMemo(() => {
    const groupedRows = groupedDataKeys?.map((key) => {
      const date = <Date>{key}</Date>;
      const currentService = servicesGroupedData[key].map((service) => (
        <ServiceInfoCard
          key={service?.id}
          service={service}
          editServiceSuccessCallback={callbackActions?.editSuccessCallback}
          deleteServiceSuccessCallback={callbackActions?.deleteSuccessCallback}
          markCompleteServiceSuccessCallback={
            callbackActions?.markCompleteSuccessCallback
          }
        />
      ));
      return [date, ...currentService];
    });
    const finalRows: any[] = [];
    groupedRows.forEach((row) => finalRows.push(...row));
    return finalRows.length ? finalRows : [];
  }, [services]);

  const finalRows: any[] = useMemo(() => {
    let rows: any = [];

    rows = [...rows, ...servicesWithDatesRows];
    return rows;
  }, [servicesWithDatesRows]);

  return (
    <ListingCnt
      ref={parentRef}
      style={{
        ...(isMobileDeviceMd && {
          height: 'calc(100vh - 330px)',
        }),
      }}
    >
      <InfiniteVirtualList
        handleLoadNextPage={handleLoadNextPage}
        rows={finalRows}
        height={parentHeight ?? 500}
        totalCount={servicesMeta?.total}
        isNextPageLoading={isNextPageLoading}
        overrideRowCount={services?.length}
      />
    </ListingCnt>
  );
}

const arePropsEqual = (prevProps, nextProps) => {
  return isEqual(
    omit(prevProps, ['handleLoadNextPage']),
    omit(nextProps, ['handleLoadNextPage']),
  );
};

export default memo(ServicesLogGrid, arePropsEqual);
