import React from 'react';

import ChecklistWithHandsAndTicks from 'components/Icons/checklistWithHandsAndTicks';

import { Wrapper, BoldText, GreyText } from './styled';

const EmptyState = () => {
  return (
    <Wrapper>
      <ChecklistWithHandsAndTicks sx={{ width: 118, height: 85 }} />
      <BoldText>Nothing to see here...yet</BoldText>
      <GreyText>
        All ‘In Progress’ and ‘Completed’ submissions will appear here
      </GreyText>
    </Wrapper>
  );
};

export default EmptyState;
