// MUI
import { Stack, Typography } from '@mui/material';

// Styled
import { AddMembersButton } from './styled';

// Icons
import ThreeUsersAvatarIcon from 'components/Icons/threeUsersAvatarIcon';
import AddRounded from '@mui/icons-material/AddRounded';

interface EmptyStateProps {
  onClickAddMembers: () => void;
}

const EmptyState = ({ onClickAddMembers }: EmptyStateProps) => {
  return (
    <Stack
      height="calc(100vh - 223px)"
      alignItems={'center'}
      justifyContent={'center'}
    >
      <ThreeUsersAvatarIcon style={{ width: '200px', height: 'auto' }} />
      <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={18}
        fontWeight={700}
        color="#212121"
      >
        Add Location Members
      </Typography>
      <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={14}
        fontWeight={400}
        color="#616161"
        textAlign={'center'}
        marginBottom={'26px'}
        width={'500px'}
      >
        Add users to locations to personalize their experience based on the work
        they do. Location membership makes it easier for users to select the
        right location, and find information relevant to them.
      </Typography>
      <AddMembersButton
        onClick={onClickAddMembers}
        variant="contained"
        startIcon={<AddRounded />}
      >
        Add Members
      </AddMembersButton>
      {/* <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={14}
        fontWeight={500}
        color="#6868FE"
        sx={{ cursor: 'pointer' }}
      >
        Learn more about location members
      </Typography> */}
    </Stack>
  );
};

export default EmptyState;
