import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const LocationDetailTabCnt = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingRight: 5,
  overflowY: 'auto',
  '.locationDetailTabInnerCnt': {
    width: 630,
    [theme.breakpoints.down('md')]: {
      width: 'unset',
    },

    display: 'flex',
    flexDirection: 'column',
    '.saveButton': {
      marginTop: 10,
      alignSelf: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '.editButton': {
    position: 'absolute',
    right: 5,
    top: 0,
    '.editIcon': {
      fontSize: 18,
      color: '#6868FE',
    },
  },
}));

export const PhotosCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: 20,
  gap: 10,
  '& .imgCnt': {
    position: 'relative',
    '& .imgView': {
      width: 150,
      height: 100,
      objectFit: 'cover',
      borderRadius: 8,
      cursor: 'pointer',
    },
    '& .download': {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      right: 0,
      boxShadow: '0px -1px 5px rgb(0 0 0 / 10%)',

      '& svg': {
        fontSize: 17,
        '& path': {
          fill: colors.black,
          fillOpacity: 1,
        },
        '&:hover': {
          transform: 'scale(1.1)',
          '& path': {
            fill: colors.white,
          },
        },
      },
    },
  },
});

export const FilesCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 10,
  '& .fileInnerCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    width: 157,
    height: 40,
    border: '1px solid #EEEEEE',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    padding: '4px 8px',
    position: 'relative',
    '& .download': {
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      right: 5,
      transform: 'translateY(-50%)',
      '& svg': {
        fontSize: 14,
        '& path': {
          fill: colors.black,
          fillOpacity: 0.6,
        },
      },
    },
    '& .fileDetailsCnt': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
      marginLeft: 10,
      '& .fileName': {
        lineHeight: '20.02px',
        fontSize: 14,
        color: '#424242',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 80,
        whiteSpace: 'nowrap',
        display: 'block',
      },
    },
  },
});

export const FileIconCnt = styled('div')({
  width: 18,
});
