// MUI components
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const Parent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const GroupWrapper = styled(Box)(({ theme }) => ({
  padding: '10px 16px',
  borderRadius: 8,
  background: 'rgba(245, 246, 251, 1)',
  display: 'flex',
  margin: '0px 16px',
  marginBottom: 8,
}));

export const LoadingWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 80,
}));

export const GroupHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: 'rgba(97, 97, 97, 1)',
  fontSize: 13,
  letterSpacing: 0.13,
}));

export const NotificationListWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));
