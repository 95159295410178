// React
import { useContext } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Mui
import { Fab, Tooltip } from '@mui/material';

// Icons
import EmptySpreadsheetIcon from 'components/Icons/emptySpreadsheetIcon';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// Styled
import { EmptyStateWrapper, CenterColumn, BoldText, GreyText } from './styled';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Context
import { SpreadsheetContext } from '../context';

const EmptyData = () => {
  const context = useContext(SpreadsheetContext);

  const { handleClickStartButton } = context;

  return (
    <EmptyStateWrapper>
      <EmptySpreadsheetIcon sx={{ fontSize: '7.5rem' }} />
      <CenterColumn>
        <BoldText>No submissions yet</BoldText>
        <GreyText>All submissions for this template will show here</GreyText>
        <Tooltip title="Add Submission" arrow>
          <Fab onClick={handleClickStartButton} color="primary">
            <AddRoundedIcon />
          </Fab>
        </Tooltip>
      </CenterColumn>
    </EmptyStateWrapper>
  );
};

export default EmptyData;
