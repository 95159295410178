import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from 'components/Icons/addIcon';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import AssignedUsersSearch from './search';
import { MainCnt } from './style';
import { PERMISSIONS } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { Typography } from '@mui/material';
import { getUserProfile } from 'store/selectors/auth';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const AssignedUsersHeader = (props) => {
  const { details, getCustomRoleDetails, onSearch } = props;
  const { hasPermission } = useCustomPermission();
  const { fetchCustomRoles } = useCustomRoles();
  const userProfileData: any = useSelector(getUserProfile);
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();

  const blacklistUsers = useMemo(() => {
    return details?.HotelUsers?.map((user) => user?.UserId);
  }, [details]);

  const PermissionsChangedTemplate = () => {
    return (
      <>
        <Typography fontSize="16px" fontWeight="500">
          Seems like your role / permissions has been updated, Please click on
          reload to apply it accordingly!
        </Typography>
      </>
    );
  };

  const editAssignUsersHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CUSTOM_ROLES_SET_EDIT_ASSIGN_ROLE,
        data: {
          role: details,
          blacklistUsers,
          onSuccessCB: (selected: any = []) => {
            const isLoggedInUser = selected?.includes(userProfileData.id);

            if (isLoggedInUser) {
              dispatch(
                setDialog({
                  dialogId: DIALOGS.CONFIRMATION,
                  open: true,
                  data: {
                    confirmButtonProps: {
                      style: {
                        backgroundColor: '#D32F2F',
                      },
                    },
                    hideCrossIcon: true,
                    removeClickAwayClose: true,
                    hideCancelBtn: true,
                    ContentTemplate: PermissionsChangedTemplate,
                    confirmationText: 'Reload',
                    title: 'Your Role Changed',
                    onConfirmCallback: () => {
                      window.location.href = `/ws/${workspaceId}/users-and-teams?tab=roles`;
                    },
                  },
                }),
              );
            } else {
              getCustomRoleDetails?.();
              fetchCustomRoles({ payload: {}, forceLoad: true });
            }
          },
        },
      }),
    );
  };

  return (
    <MainCnt direction="row">
      <AssignedUsersSearch onSearch={onSearch} />
      {hasPermission(PERMISSIONS.CAN_MANAGE_ROLES) && (
        <CustomButton
          className="assignUsersBtn"
          variant="contained"
          onClick={editAssignUsersHandler}
          startIcon={<AddIcon />}
        >
          Assign Users
        </CustomButton>
      )}
    </MainCnt>
  );
};

export default AssignedUsersHeader;
