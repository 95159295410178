import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getReports } from 'api/reporting';
import TaskSummary from 'pages/ReportingV2/Reports/TaskOverview';
import EmployeeProductivity from 'pages/ReportingV2/Reports/EmployeeProductivity';
import CompletedWork from 'pages/ReportingV2/Reports/CompletedWork';
import TemplateSubmissions from 'pages/ReportingV2/Reports/TemplateSubmissions';
import ScheduledWork from 'pages/Dashboards/Reports/ScheduledWork';

const reports = {
  'task-summary': <TaskSummary />,
  'schedule-report': <ScheduledWork />,
  'employee-productivity': <EmployeeProductivity />,
  'completed-work-report': <CompletedWork />,
  'template-submissions': <TemplateSubmissions />,
};
function ReportDashboard() {
  const [data, setData] = useState<any>({});
  const params = useParams();
  const { reportId } = params;

  //Fetching reports list data from api
  const getReportData = async () => {
    const reportData = await getReports();
    const currentReportData = reportData.find((r) => r.id === reportId);
    setData(currentReportData);
  };
  useEffect(() => {
    getReportData();
  }, [reportId]);
  const reportType = data?.slug;
  return <div>{reports?.[reportType]}</div>;
}

export default ReportDashboard;
