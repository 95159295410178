import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAssetsState } from 'store/selectors/assets';
import { useEffect, useMemo } from 'react';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function useAssetDetail() {
  const params = useParams();
  const { assetId, subAssetId } = params;
  const assetList = useSelector(getAssetsState);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const selectedAsset = useMemo(() => {
    const filterAsset = assetList.find((l) => {
      return l.id == assetId;
    });
    const subAsset = filterAsset?.SubAssets?.find((l) => l.id == subAssetId);
    return subAsset ? subAsset : filterAsset;
  }, [assetId, subAssetId, assetList]);

  useEffect(() => {
    if (!selectedAsset) {
      navigateWithWorkspaceUrl('/assets');
    }
  }, []);
  return { selectedAsset };
}

export default useAssetDetail;
