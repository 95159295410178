import BarChartIcon from 'components/Icons/barChartIcon';
import { EmptyStateCnt, EmptyStateText } from './emptystate.style';

const EmptyState = () => {
  return (
    <EmptyStateCnt>
      <BarChartIcon />
      <EmptyStateText>No record available</EmptyStateText>
    </EmptyStateCnt>
  );
};

export default EmptyState;
