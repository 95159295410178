import MyTemplatesHeader from 'pages/Templates/MyTemplates/Header/Header';
import Folders from 'pages/Templates/Folders/folders';
import TemplatesListing from 'pages/Templates/TemplatesListing/templatesListing';
import { LoaderCnt, TabContentCnt } from 'pages/Templates/templates.style';
import React, { useEffect, useReducer } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import BreadCrumbs from 'pages/Templates/FoldersDetailView/BreadCrumbs/breadCrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { getTemplateFolders } from 'api/checklistV2Api';
import selectors from 'store/selectors';
import AchiveEmpty from './emptyStates/archive/archive';
import FolderTemplatesEmpty from './emptyStates/folderTemplates/folderTemplates';
import EmptyChecklist from 'pages/checklistV2/emptyChecklist/EmptyChecklist';
import useMeasure from 'react-use-measure';
import xeniaApi from 'api/index';
import actions from 'store/actions';
import { initializeNewChecklist } from 'store/actions/checklistV2Actions';
import {
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useFolders from 'utils/CustomHooks/useFolders';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';

interface IMyTemplatesState {
  folderLoading: boolean;
  data: any[];
  templatesLoading: boolean;
  searchTerm: string;
}
const initialState = {
  folderLoading: true,
  templatesLoading: false,
  data: [],
  selectedView: 'grid',
  searchTerm: '',
};
function MyTemplates(props) {
  const { isArchive = false } = props;
  const dispatch = useDispatch();
  const myTemplatesData = useSelector(selectors.getChecklistListDataOnly);
  const [templatesHeadRef, templatesHeadBounds] = useMeasure();
  const { moveTemplateOutOfFolder } = useFolders();
  const { lastFolderId, getFolderIdsFromUrl } = useCurrentFolderId();
  const params = useParams();
  const [state, updateState] = useReducer(
    (prevState: IMyTemplatesState, value): IMyTemplatesState => {
      return { ...prevState, ...value };
    },
    initialState,
  );

  useEffect(() => {
    updateState({
      templatesHeadHeight: templatesHeadBounds?.height,
    });
  }, [templatesHeadBounds]);

  useEffect(() => {
    getFolders();
  }, [lastFolderId]);

  const getChecklistsFolderTemplates = async () => {
    updateState({ templatesLoading: true });
    const root = isArchive ? null : 'root';
    const postObj = {
      folderId: lastFolderId || root,
      archived: isArchive,
    };
    const checklistListApiResponse = await xeniaApi.getChecklistListing(
      postObj,
    );
    if (checklistListApiResponse) {
      dispatch(actions.setChecklistList(checklistListApiResponse));
    }
    updateState({ templatesLoading: false });
  };

  useEffect(() => {
    dispatch(initializeNewChecklist());
    getChecklistsFolderTemplates();
  }, [lastFolderId]);

  const addToFolder = (folderId) => {
    const folderAfterUpdatedCount = state.data.map((f) => {
      const count = f.itemsCount > 0 ? f.itemsCount + 1 : 1;
      if (f.id === folderId) {
        return { ...f, itemsCount: count };
      }
      return f;
    });
    updateState({ data: folderAfterUpdatedCount });
  };
  const onMoveFolder = (fromId, toId) => {
    const folderAfterUpdatedCount = state.data.map((f) => {
      if (f.id === toId) {
        return { ...f, itemsCount: f.itemsCount + 1 };
      }
      if (f.id === fromId) {
        return { ...f, itemsCount: f.itemsCount - 1 };
      }
      return f;
    });

    updateState({ data: folderAfterUpdatedCount });
  };

  const onMoveOutOfFolder = async (folderId: string, template: any) => {
    const result = await moveTemplateOutOfFolder({
      folderId,
      checklistIds: [template.id],
    });
    if (result) {
      const folderAfterUpdatedCount = state.data.map((f) => {
        if (f.id === folderId) {
          return { ...f, itemsCount: f.itemsCount - 1 };
        }
        return f;
      });
      updateState({ data: folderAfterUpdatedCount });
      dispatch(actions.removeChecklist(template.id));
    }
  };

  const getFolders = async () => {
    updateState({ folderLoading: true });
    const root = isArchive ? null : 'root';
    const foldersList = await getTemplateFolders({
      archived: isArchive,
      parentId: lastFolderId || root,
    });
    updateState({ data: foldersList?.data, folderLoading: false });
  };

  const isLoading = state?.folderLoading || !myTemplatesData;
  const showEmptyState =
    !isLoading && state?.data?.length === 0 && myTemplatesData?.length === 0;

  const showData =
    !isLoading && (state?.data?.length > 0 || myTemplatesData?.length > 0);

  return (
    <MyTemplatesContext.Provider
      value={{
        state,
        updateState,
        addToFolder,
        onMoveFolder,
        onMoveOutOfFolder,
        isArchive,
        isLoading,
      }}
    >
      <TabContentCnt>
        <Routes>
          <Route
            path={'/folder/*'}
            element={
              <>
                {lastFolderId &&
                  state?.templatesLoading == false &&
                  isLoading == false && (
                    <>
                      <div ref={templatesHeadRef}>
                        <MyTemplatesHeader />
                        <BreadCrumbs />
                      </div>
                      {state?.data?.length > 0 && <Folders />}
                      {myTemplatesData?.length > 0 ? (
                        <>
                          <TemplatesListing />
                        </>
                      ) : (
                        <FolderTemplatesEmpty />
                      )}
                    </>
                  )}
              </>
            }
          />

          <Route
            path={'/'}
            element={
              <>
                {!lastFolderId ? (
                  <>
                    <div ref={templatesHeadRef}>
                      <MyTemplatesHeader />
                    </div>
                    {isLoading && (
                      <LoaderCnt>
                        <CircularProgress />
                      </LoaderCnt>
                    )}
                    {showEmptyState && !isArchive && <EmptyChecklist />}
                    {showEmptyState && isArchive && <AchiveEmpty />}
                    {showData && (
                      <>
                        {state?.data?.length > 0 && <Folders />}
                        {myTemplatesData?.length > 0 && <TemplatesListing />}
                      </>
                    )}
                  </>
                ) : null}
              </>
            }
          />
        </Routes>
        {/*)}*/}
        {lastFolderId && state?.templatesLoading == true && (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        )}
      </TabContentCnt>
    </MyTemplatesContext.Provider>
  );
}

export default MyTemplates;
