import styled from '@mui/system/styled';
import { Box, Grid, Stack, Typography } from '@mui/material';

export const BackBox = styled(Stack)({
  marginTop: 5,
  '& .icon': {
    color: '#424242',
    cursor: 'pointer',
  },
  '& .roleName': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    color: '#000000DE',
  },
});

export const MainCnt = styled(Box)({
  '& .editDetailsBtn': {
    height: 36,
    borderRadius: 6,
  },
  '& .editPermissionsBtn': {
    height: 36,
    borderRadius: 6,
  },
});

export const SummaryBox = styled(Grid)({
  border: '1px solid #E0E0E0',
  borderRadius: '12px',
  padding: '12px 0px',
  marginTop: 15,

  '& .content': {
    marginTop: 10,
    alignItems: 'center',
    borderRight: '1px solid #0000001F',
    padding: 5,
  },
});

export const SummaryTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16.39px',
  color: '#424242',
  marginBottom: 3,
});

export const SummaryValue = styled(Typography)({
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#000000',
});

export const ActionsCnt = styled(Box)({
  border: '1px solid #BDBDBD',
  borderRadius: 6,
  height: 36,
  width: 36,

  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});
