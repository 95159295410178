/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MessagesHeaderRenderProps } from 'models/index.m';
import { ConstantChannelTypeString } from 'utils/globalVariable';
import { useProfileChannel } from 'controller/useProfileChannel';
import { AddMemberRender } from './addMemberBtn';
import { updateChannelPartially } from 'services/getStreamServices';
import actions from 'store/actions';
import { trim } from 'lodash';
import UserAvatar from 'components/Avatar';

import selectors from 'store/selectors/index';
import { Grid, Modal } from '@mui/material';
import GroupDetailsModal from './groupDetailsModal';
import AddMembersModal from './addMembersModal';
import { MessageHeaderStyled } from './messagesHeader.styled';

export const MessagesHeaderRender: FC<MessagesHeaderRenderProps> = () => {
  const dispatch = useDispatch();
  const defaultState = {
    showGroupDetailModel: false,
    showDetailDescription: false,
    showAddMember: false,
  };
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);
  const [messageHeaderState, setMessageHeaderState] = useState(defaultState);
  const [channelName, setChannelName] = useState(activeStreamChannel.data.name);

  const { data, state } = activeStreamChannel;
  const { member_count, serviceType, description, taskType } = data;
  const { members } = state;
  const { memberObj }: any = useProfileChannel(activeStreamChannel);

  const { GROUP, DIRECT_MESSAGE, FEED_STREAM, TASK } =
    ConstantChannelTypeString;

  const getChannelType = () => {
    return data.channelType;
  };

  const isGroup = getChannelType() === GROUP;

  const getTypeTitle = () => {
    const type = getChannelType();

    const titles = {
      [GROUP]: 'Group Details',
      [DIRECT_MESSAGE]: 'Direct Message Details',
      [FEED_STREAM]: 'Feed Details',
    };
    return titles[type];
  };

  const isGroupChat = () => {
    let is = false;
    if (getChannelType() === GROUP) {
      is = true;
    }
    return is;
  };

  const isFeedChat = () => {
    let is = false;
    if (getChannelType() === FEED_STREAM) {
      is = true;
    }
    return is;
  };

  const isChannelTasktype = () => {
    let is = false;
    if (getChannelType() === TASK) {
      is = true;
    }
    return is;
  };

  const handleClose = () => {
    setMessageHeaderState(defaultState);
  };

  const handleAddMembersModalClose = () => {
    setMessageHeaderState({
      ...messageHeaderState,
      showAddMember: false,
    });
  };

  const getHeaderName = () => {
    return memberObj.name;
  };

  const getProfileImage = () => {
    let imageUrl;
    if (
      activeStreamChannel?.data?.name &&
      trim(activeStreamChannel?.data?.name) !== ''
    ) {
      imageUrl = '/assets/img/Image.png';
    }
    return (
      <div className="avatar">
        <div
          style={{ marginRight: '8px' }}
          data-toggle="modal"
          data-target="#profileCardModal"
        >
          {imageUrl?.length && !isGroup ? (
            <img
              src={imageUrl}
              style={{ maxWidth: 'unset' }}
              alt={getHeaderName()}
              width="32"
              height="32"
            />
          ) : (
            <UserAvatar
              width={32}
              height={32}
              userId={memberObj?.userId}
              firstName={memberObj?.name}
              profilePic={!isGroup ? memberObj?.profileImage : '@@#6868FE'}
            />
          )}
        </div>
      </div>
    );
  };

  const getHideShowDetailText = () => {
    const { showDetailDescription } = messageHeaderState;
    return (
      <>
        {!showDetailDescription ? 'View More' : 'Hide'}
        Details
      </>
    );
  };

  const changeChannelName = async () => {
    const dataForUpdate = { name: channelName };
    if (activeStreamChannel?.data.name === channelName) {
      return;
    }
    await updateChannelPartially({
      channel: activeStreamChannel,
      dataForUpdate,
    });
    dispatch(actions.setIsRefreshing(true));
  };

  useEffect(() => {
    const channelName =
      (messageHeaderState.showGroupDetailModel &&
        activeStreamChannel?.data?.name) ||
      '';
    setChannelName(channelName);
  }, [messageHeaderState.showGroupDetailModel]);

  useEffect(() => {
    if (messageHeaderState.showGroupDetailModel) {
      changeChannelName();
    }
  }, [channelName]);

  return (
    <>
      <MessageHeaderStyled>
        <h3 className="title">
          {getProfileImage()}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <div className="chatTitle">{getHeaderName()}</div>
            <div className="addedMembers">
              {isGroupChat() && (
                <>
                  <span>{member_count} members</span>
                </>
              )}
              {(isFeedChat() && <span>All Members</span>) || ''}
            </div>
          </Grid>
        </h3>
      </MessageHeaderStyled>
      {(isGroupChat() || isFeedChat()) && (
        <AddMemberRender
          messageHeaderState={messageHeaderState}
          setMessageHeaderState={setMessageHeaderState}
        />
      )}
      <Modal
        open={messageHeaderState.showAddMember}
        onClose={handleAddMembersModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddMembersModal
          messageHeaderState={messageHeaderState}
          setMessageHeaderState={setMessageHeaderState}
          handleClose={handleAddMembersModalClose}
        ></AddMembersModal>
      </Modal>
      <Modal
        open={messageHeaderState.showGroupDetailModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <GroupDetailsModal
          title={getTypeTitle()}
          handleClose={handleClose}
          members={members}
          messageHeaderState={messageHeaderState}
          setMessageHeaderState={setMessageHeaderState}
          isChannelTasktype={isChannelTasktype}
          serviceType={serviceType}
          description={description}
          channelName={channelName}
          setChannelName={setChannelName}
          taskType={taskType}
          getHideShowDetailText={getHideShowDetailText}
        ></GroupDetailsModal>
      </Modal>
    </>
  );
};
