import { Typography } from '@mui/material';
import {
  DashboardTemplateItemCnt,
  TemplateImage,
  StyledItem,
} from 'pages/Dashboards/DashboardListing/GridView/dashboardsGridItem/dashboardsGridItem.style';
import {
  AvatarAndTextWrapper,
  CreatedBy,
  DraftChip,
  EditedTime,
} from 'components/common/jsxrender/checklistV2/checklistGrid/styled';
import UserAvatar from 'components/Avatar';
import Stack from '@mui/material/Stack';
import DateTime from 'utils/DateTime';
import { EditOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import BarChartIcon2 from 'components/Icons/barChartIcon2';
import { IDashboardItem } from 'pages/Dashboards/interfaces';

interface IDashboardGridItemProps {
  item: IDashboardItem;
  onClick?: () => void;
}

function DashboardsGridItem(props: IDashboardGridItemProps) {
  const { item, onClick } = props;
  const hashedUsers = useSelector(selectors.getHashedUserList);
  const userObj = hashedUsers?.[item?.CreatedBy];
  const timezone: string =
    useSelector(selectors.getUserHotelTz) || DEFAULT_TIMEZONE;
  return (
    <DashboardTemplateItemCnt
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={3}
      onClick={onClick}
    >
      <StyledItem>
        <div className={'imageCnt'}>
          <BarChartIcon2 className={'chartIcon'} />
        </div>
        <div className={'contentCnt'}>
          <Typography
            variant="h6"
            component="div"
            className={'heading'}
            title={item?.title}
          >
            {item?.title}
          </Typography>
          <AvatarAndTextWrapper>
            <UserAvatar userId={item?.CreatedBy} width={25} height={25} />

            <Stack>
              <CreatedBy>
                {'Created by '}
                {/*{checklist?.Creator?.id === id*/}
                {/*  ? 'you'*/}
                {/*  : checklist?.Creator?.firstName || ''}*/}
                {`${userObj?.firstName || ''} ${userObj?.lastName || ''}`}
              </CreatedBy>
              <EditedTime>
                Created{' '}
                {DateTime.fromUTCString(item.createdAt).fromNow(timezone)}
              </EditedTime>
            </Stack>
            {!item?.publish && (
              <DraftChip
                icon={<EditOutlined />}
                size="small"
                label="Draft"
                color="success"
              />
            )}
          </AvatarAndTextWrapper>
        </div>
      </StyledItem>
    </DashboardTemplateItemCnt>
  );
}

export default DashboardsGridItem;
