import { createSvgIcon } from '@mui/material';

const CalendarWithBlobIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_2_58247)">
        <path
          d="M168.731 10.3093C151.199 -3.01544 119.62 -3.60823 99.4266 13.4665C77.1264 32.3261 75.7153 66.7139 53.1831 83.8016C30.2771 101.198 3.56968 92.7835 0.74107 135.593C-1.43032 168.583 24.0272 168.647 45.3351 172.751C83.6019 180.116 153.75 216.211 188.074 162.777C227.165 101.927 208.035 40.1869 168.731 10.3093Z"
          fill="#6868FE"
        />
        <path
          opacity="0.9"
          d="M168.731 10.3093C151.199 -3.01544 119.62 -3.60823 99.4266 13.4665C77.1264 32.3261 75.7153 66.7139 53.1831 83.8016C30.2771 101.198 3.56968 92.7835 0.74107 135.593C-1.43032 168.583 24.0272 168.647 45.3351 172.751C83.6019 180.116 153.75 216.211 188.074 162.777C227.165 101.927 208.035 40.1869 168.731 10.3093Z"
          fill="white"
        />
        <path
          d="M125 220.09C193.996 220.09 249.929 216.824 249.929 212.796C249.929 208.768 193.996 205.502 125 205.502C56.0035 205.502 0.0708008 208.768 0.0708008 212.796C0.0708008 216.824 56.0035 220.09 125 220.09Z"
          fill="#F5F5F5"
        />
        <path
          d="M222.561 156.192H207.097V155.548H222.561C224.38 155.51 226.156 154.992 227.71 154.046C229.263 153.1 230.539 151.76 231.407 150.161L231.974 150.47C231.051 152.171 229.693 153.596 228.039 154.601C226.386 155.606 224.495 156.155 222.561 156.192Z"
          fill="#6868FE"
        />
        <path
          d="M204.036 155.548H197.703V156.192H204.036V155.548Z"
          fill="#6868FE"
        />
        <path
          d="M222.561 153.937H41.633C40.641 153.956 39.6563 153.764 38.7443 153.374C37.8323 152.983 37.0138 152.403 36.3434 151.671C35.673 150.94 35.1659 150.074 34.8559 149.132C34.5459 148.189 34.44 147.192 34.5454 146.205L44.3199 34.0915C44.5384 32.0051 45.5091 30.0696 47.0506 28.6467C48.5921 27.2238 50.5989 26.4108 52.6962 26.3596H233.624C234.616 26.3413 235.6 26.534 236.511 26.925C237.423 27.316 238.241 27.8963 238.911 28.6275C239.581 29.3586 240.088 30.2239 240.399 31.1659C240.709 32.1079 240.816 33.1052 240.712 34.0915L230.995 146.205C230.775 148.301 229.796 150.244 228.243 151.669C226.689 153.093 224.668 153.9 222.561 153.937Z"
          fill="#6868FE"
        />
        <path
          d="M218.791 151.585H42.3739C41.5292 151.598 40.6916 151.43 39.9166 151.094C39.1416 150.758 38.447 150.261 37.879 149.635C37.311 149.01 36.8826 148.271 36.6222 147.467C36.3618 146.664 36.2754 145.814 36.3687 144.974L45.2734 42.8415C45.3266 42.3297 45.5648 41.8548 45.9432 41.5061C46.3216 41.1573 46.8143 40.9586 47.3288 40.9472H233.134C233.377 40.943 233.617 40.9905 233.84 41.0865C234.063 41.1826 234.262 41.3249 234.426 41.5042C234.589 41.6834 234.712 41.8953 234.787 42.1259C234.863 42.3566 234.888 42.6005 234.861 42.8415L225.95 144.974C225.769 146.76 224.942 148.418 223.623 149.636C222.305 150.854 220.586 151.547 218.791 151.585Z"
          fill="white"
        />
        <path
          d="M64.8612 35.8634C63.6441 35.8842 62.4366 35.6458 61.3187 35.1642C60.2008 34.6825 59.1982 33.9685 58.3773 33.0697C57.5565 32.1709 56.9362 31.1077 56.5578 29.9508C56.1793 28.7939 56.0512 27.5697 56.1821 26.3596C56.4469 23.7911 57.6407 21.4079 59.5392 19.6578C61.4376 17.9076 63.9099 16.9112 66.4913 16.8557C67.7082 16.8348 68.9155 17.0732 70.0331 17.5549C71.1507 18.0366 72.153 18.7507 72.9733 19.6496C73.7937 20.5486 74.4133 21.6119 74.791 22.7688C75.1687 23.9257 75.2959 25.1497 75.164 26.3596H71.298C71.3759 25.6411 71.2999 24.9143 71.0751 24.2275C70.8503 23.5407 70.4818 22.9096 69.9942 22.3763C69.5066 21.8429 68.911 21.4195 68.247 21.1343C67.583 20.849 66.8659 20.7083 66.1434 20.7217C64.6087 20.7498 63.1375 21.3388 62.0074 22.3775C60.8773 23.4162 60.1666 24.8327 60.0094 26.3596C59.9315 27.078 60.0075 27.8048 60.2323 28.4916C60.4571 29.1784 60.8256 29.8095 61.3132 30.3428C61.8009 30.8762 62.3965 31.2996 63.0604 31.5849C63.7244 31.8702 64.4415 32.0108 65.164 31.9974L64.8612 35.8634Z"
          fill="#263238"
        />
        <path
          d="M103.102 35.8634C101.883 35.8879 100.672 35.6523 99.5515 35.1722C98.4305 34.6922 97.4246 33.9788 96.601 33.0795C95.7773 32.1803 95.1547 31.1158 94.7747 29.9571C94.3947 28.7984 94.266 27.5719 94.3972 26.3596C94.662 23.7911 95.8559 21.4079 97.7543 19.6578C99.6527 17.9076 102.125 16.9112 104.706 16.8557C105.923 16.8348 107.131 17.0732 108.248 17.5549C109.366 18.0366 110.368 18.7507 111.188 19.6496C112.009 20.5486 112.628 21.6119 113.006 22.7688C113.384 23.9257 113.511 25.1497 113.379 26.3596H109.513C109.591 25.6411 109.515 24.9143 109.29 24.2275C109.065 23.5407 108.697 22.9096 108.209 22.3763C107.722 21.8429 107.126 21.4195 106.462 21.1343C105.798 20.849 105.081 20.7083 104.359 20.7217C102.824 20.7498 101.353 21.3388 100.223 22.3775C99.0924 23.4162 98.3817 24.8327 98.2245 26.3596C98.1477 27.0778 98.2244 27.8042 98.4497 28.4905C98.6749 29.1769 99.0434 29.8075 99.5308 30.3406C100.018 30.8737 100.613 31.2971 101.277 31.5828C101.94 31.8684 102.657 32.0098 103.379 31.9974L103.102 35.8634Z"
          fill="#263238"
        />
        <path
          d="M141.343 35.8634C140.126 35.8833 138.92 35.6442 137.803 35.1621C136.685 34.6801 135.684 33.966 134.863 33.0673C134.043 32.1685 133.424 31.1057 133.046 29.9493C132.667 28.7928 132.54 27.5692 132.67 26.3596C132.932 23.7901 134.126 21.4052 136.025 19.6546C137.924 17.9039 140.397 16.9084 142.98 16.8557C144.196 16.8348 145.404 17.0732 146.521 17.5549C147.639 18.0366 148.641 18.7507 149.462 19.6496C150.282 20.5486 150.902 21.6119 151.279 22.7688C151.657 23.9257 151.784 25.1497 151.652 26.3596H147.786C147.865 25.6409 147.79 24.9137 147.566 24.2264C147.341 23.5391 146.973 22.9076 146.485 22.3741C145.997 21.8405 145.401 21.4171 144.737 21.1321C144.072 20.8472 143.355 20.7073 142.632 20.7217C141.098 20.75 139.627 21.3392 138.498 22.378C137.369 23.4168 136.66 24.8333 136.504 26.3596C136.426 27.078 136.502 27.8048 136.727 28.4916C136.952 29.1784 137.32 29.8095 137.808 30.3428C138.296 30.8762 138.891 31.2996 139.555 31.5849C140.219 31.8702 140.936 32.0108 141.659 31.9974L141.343 35.8634Z"
          fill="#263238"
        />
        <path
          d="M179.584 35.8634C178.367 35.8832 177.161 35.6442 176.044 35.1621C174.926 34.6801 173.925 33.966 173.104 33.0672C172.284 32.1685 171.665 31.1057 171.287 29.9492C170.908 28.7928 170.781 27.5692 170.911 26.3595C171.175 23.7906 172.368 21.4065 174.267 19.6562C176.166 17.9058 178.639 16.9098 181.221 16.8557C182.438 16.8349 183.645 17.0733 184.763 17.5549C185.881 18.0366 186.884 18.7506 187.704 19.6494C188.525 20.5482 189.146 21.6114 189.524 22.7683C189.902 23.9252 190.031 25.1494 189.9 26.3595H186.034C186.112 25.6411 186.036 24.9143 185.811 24.2275C185.586 23.5407 185.218 22.9096 184.73 22.3763C184.242 21.8429 183.647 21.4195 182.983 21.1342C182.319 20.8489 181.602 20.7083 180.879 20.7217C179.345 20.75 177.875 21.3392 176.746 22.378C175.617 23.4168 174.907 24.8332 174.751 26.3595C174.674 27.078 174.75 27.8048 174.974 28.4916C175.199 29.1784 175.568 29.8095 176.055 30.3428C176.543 30.8762 177.139 31.2996 177.803 31.5848C178.467 31.8701 179.184 32.0108 179.906 31.9974L179.584 35.8634Z"
          fill="#263238"
        />
        <path
          d="M217.825 35.8634C216.608 35.8832 215.402 35.6442 214.284 35.1621C213.167 34.6801 212.166 33.966 211.345 33.0672C210.525 32.1685 209.906 31.1057 209.527 29.9492C209.149 28.7928 209.021 27.5692 209.152 26.3595C209.416 23.7906 210.609 21.4065 212.508 19.6562C214.407 17.9058 216.88 16.9098 219.462 16.8557C220.679 16.8349 221.886 17.0733 223.004 17.5549C224.122 18.0366 225.125 18.7506 225.945 19.6494C226.766 20.5482 227.386 21.6114 227.765 22.7683C228.143 23.9252 228.272 25.1494 228.141 26.3595H224.275C224.353 25.6411 224.277 24.9143 224.052 24.2275C223.827 23.5407 223.458 22.9096 222.971 22.3763C222.483 21.8429 221.888 21.4195 221.224 21.1342C220.56 20.8489 219.843 20.7083 219.12 20.7217C217.587 20.7514 216.117 21.3411 214.988 22.3796C213.859 23.4182 213.149 24.8338 212.992 26.3595C212.915 27.078 212.991 27.8048 213.215 28.4916C213.44 29.1784 213.809 29.8095 214.296 30.3428C214.784 30.8762 215.38 31.2996 216.044 31.5848C216.707 31.8701 217.425 32.0108 218.147 31.9974L217.825 35.8634Z"
          fill="#263238"
        />
        <path
          d="M218.328 146.952H43.9269C43.8856 146.954 43.8446 146.946 43.8066 146.93C43.7686 146.914 43.7347 146.889 43.7071 146.859C43.6794 146.828 43.6589 146.792 43.6468 146.752C43.6346 146.713 43.6313 146.671 43.6369 146.63L52.329 46.9781C52.3369 46.8909 52.3769 46.8097 52.4411 46.7503C52.5053 46.6908 52.5893 46.6572 52.6769 46.6559H227.071C227.113 46.6548 227.154 46.6625 227.192 46.6786C227.23 46.6948 227.265 46.7189 227.293 46.7495C227.321 46.78 227.342 46.8162 227.355 46.8557C227.368 46.8951 227.372 46.9369 227.368 46.9781L218.676 146.63C218.668 146.717 218.628 146.799 218.563 146.858C218.499 146.917 218.415 146.951 218.328 146.952ZM44.307 146.308H218.057L226.698 47.3003H52.9475L44.307 146.308Z"
          fill="#E0E0E0"
        />
        <path
          d="M220.042 127.023H45.6665C45.6256 127.023 45.585 127.015 45.5476 126.999C45.5101 126.982 45.4766 126.958 45.4492 126.927C45.4218 126.897 45.4012 126.861 45.3886 126.822C45.3761 126.783 45.372 126.742 45.3766 126.701C45.3831 126.613 45.4226 126.531 45.4871 126.472C45.5517 126.412 45.6365 126.379 45.7245 126.379H220.119C220.16 126.378 220.202 126.385 220.24 126.402C220.278 126.418 220.312 126.442 220.34 126.472C220.369 126.503 220.39 126.539 220.403 126.579C220.416 126.618 220.42 126.66 220.415 126.701C220.404 126.792 220.36 126.875 220.291 126.934C220.221 126.994 220.133 127.026 220.042 127.023Z"
          fill="#E0E0E0"
        />
        <path
          d="M221.8 107.094H47.4062C47.365 107.094 47.3242 107.086 47.2865 107.069C47.2487 107.053 47.2148 107.029 47.1869 106.998C47.159 106.968 47.1378 106.932 47.1245 106.893C47.1112 106.854 47.1062 106.813 47.1098 106.772C47.1192 106.684 47.1605 106.603 47.2258 106.544C47.2911 106.484 47.3759 106.451 47.4641 106.45H221.858C221.9 106.449 221.941 106.456 221.979 106.472C222.018 106.489 222.052 106.513 222.08 106.543C222.108 106.574 222.129 106.61 222.142 106.649C222.155 106.689 222.159 106.731 222.155 106.772C222.144 106.859 222.102 106.94 222.037 106.999C221.972 107.058 221.888 107.092 221.8 107.094Z"
          fill="#E0E0E0"
        />
        <path
          d="M223.54 87.1585H49.1459C49.1044 87.1597 49.0631 87.1519 49.0249 87.1358C48.9866 87.1197 48.9523 87.0955 48.9242 87.065C48.8961 87.0344 48.8749 86.9982 48.862 86.9588C48.8491 86.9193 48.8448 86.8776 48.8495 86.8363C48.8602 86.7491 48.9019 86.6686 48.9669 86.6095C49.0319 86.5504 49.116 86.5166 49.2038 86.5142H223.598C223.639 86.5139 223.68 86.5223 223.717 86.5389C223.755 86.5554 223.788 86.5797 223.815 86.6101C223.843 86.6406 223.864 86.6765 223.876 86.7155C223.889 86.7544 223.893 86.7956 223.888 86.8363C223.882 86.9241 223.842 87.0061 223.778 87.0659C223.713 87.1256 223.628 87.1587 223.54 87.1585Z"
          fill="#E0E0E0"
        />
        <path
          d="M225.28 67.2294H50.8856C50.8441 67.2306 50.8029 67.2228 50.7646 67.2067C50.7264 67.1906 50.6921 67.1664 50.664 67.1359C50.6359 67.1053 50.6147 67.0691 50.6018 67.0297C50.5889 66.9902 50.5846 66.9485 50.5892 66.9072C50.5972 66.82 50.6371 66.7388 50.7014 66.6794C50.7656 66.6199 50.8496 66.5863 50.9372 66.585H225.338C225.379 66.5837 225.42 66.5914 225.458 66.6076C225.496 66.6238 225.53 66.6481 225.558 66.6788C225.585 66.7094 225.606 66.7458 225.618 66.7852C225.63 66.8247 225.634 66.8663 225.628 66.9072C225.621 66.995 225.582 67.077 225.517 67.1367C225.453 67.1965 225.368 67.2296 225.28 67.2294Z"
          fill="#E0E0E0"
        />
        <path
          d="M196.524 146.952C196.482 146.954 196.441 146.946 196.403 146.93C196.365 146.914 196.331 146.889 196.304 146.859C196.276 146.828 196.256 146.792 196.243 146.752C196.231 146.713 196.228 146.671 196.234 146.63L204.926 46.9781C204.934 46.8909 204.974 46.8097 205.038 46.7503C205.102 46.6908 205.186 46.6572 205.274 46.6559C205.315 46.6548 205.356 46.6625 205.395 46.6786C205.433 46.6948 205.467 46.7189 205.495 46.7495C205.523 46.78 205.545 46.8162 205.557 46.8557C205.57 46.8951 205.575 46.9369 205.57 46.9781L196.878 146.63C196.869 146.718 196.827 146.799 196.762 146.859C196.697 146.918 196.612 146.951 196.524 146.952Z"
          fill="#E0E0E0"
        />
        <path
          d="M174.726 146.952C174.684 146.954 174.643 146.946 174.605 146.93C174.566 146.914 174.532 146.889 174.504 146.859C174.476 146.828 174.455 146.792 174.442 146.753C174.429 146.713 174.425 146.671 174.429 146.63L183.121 46.9781C183.131 46.8904 183.172 46.8091 183.237 46.7498C183.303 46.6904 183.387 46.657 183.476 46.656C183.517 46.6546 183.558 46.6623 183.596 46.6785C183.634 46.6947 183.668 46.719 183.696 46.7497C183.723 46.7804 183.744 46.8167 183.756 46.8562C183.768 46.8956 183.771 46.9372 183.766 46.9781L175.074 146.63C175.067 146.718 175.028 146.8 174.963 146.86C174.898 146.92 174.814 146.953 174.726 146.952Z"
          fill="#E0E0E0"
        />
        <path
          d="M152.928 146.952C152.887 146.954 152.845 146.946 152.807 146.93C152.769 146.914 152.735 146.889 152.706 146.859C152.678 146.828 152.657 146.792 152.644 146.753C152.631 146.713 152.627 146.671 152.632 146.63L161.324 46.9781C161.333 46.8904 161.374 46.8091 161.44 46.7498C161.505 46.6904 161.59 46.657 161.678 46.656C161.719 46.6546 161.76 46.6623 161.798 46.6785C161.836 46.6947 161.87 46.719 161.898 46.7497C161.926 46.7804 161.946 46.8167 161.958 46.8562C161.97 46.8956 161.974 46.9372 161.968 46.9781L153.276 146.63C153.268 146.717 153.228 146.799 153.164 146.858C153.1 146.918 153.016 146.951 152.928 146.952Z"
          fill="#E0E0E0"
        />
        <path
          d="M131.124 146.952C131.082 146.954 131.041 146.946 131.003 146.93C130.965 146.914 130.93 146.889 130.902 146.859C130.874 146.828 130.853 146.792 130.84 146.753C130.827 146.713 130.823 146.671 130.827 146.63L139.5 46.9781C139.508 46.8909 139.548 46.8097 139.612 46.7503C139.677 46.6908 139.761 46.6572 139.848 46.6559C139.89 46.6548 139.931 46.6625 139.969 46.6786C140.007 46.6948 140.042 46.7189 140.07 46.7495C140.098 46.78 140.119 46.8162 140.132 46.8557C140.145 46.8951 140.149 46.9369 140.144 46.9781L131.478 146.63C131.47 146.718 131.429 146.801 131.364 146.86C131.298 146.92 131.213 146.953 131.124 146.952Z"
          fill="#E0E0E0"
        />
        <path
          d="M109.326 146.952C109.285 146.954 109.244 146.946 109.206 146.93C109.168 146.914 109.134 146.889 109.106 146.859C109.079 146.828 109.058 146.792 109.046 146.752C109.034 146.713 109.031 146.671 109.036 146.63L117.728 46.9781C117.735 46.8904 117.774 46.8084 117.839 46.7486C117.904 46.6888 117.988 46.6557 118.076 46.6559C118.118 46.6548 118.159 46.6625 118.197 46.6786C118.236 46.6948 118.27 46.7189 118.298 46.7495C118.326 46.78 118.347 46.8162 118.36 46.8557C118.373 46.8951 118.377 46.9369 118.373 46.9781L109.681 146.63C109.671 146.718 109.63 146.799 109.565 146.859C109.499 146.918 109.415 146.951 109.326 146.952Z"
          fill="#E0E0E0"
        />
        <path
          d="M87.5287 146.952C87.4872 146.954 87.4459 146.946 87.4077 146.93C87.3695 146.914 87.3351 146.889 87.307 146.859C87.2789 146.828 87.2577 146.792 87.2448 146.753C87.2319 146.713 87.2276 146.671 87.2323 146.63L95.9243 46.9781C95.9337 46.8904 95.975 46.8091 96.0403 46.7498C96.1056 46.6904 96.1904 46.657 96.2787 46.656C96.3199 46.6546 96.361 46.6623 96.3989 46.6785C96.4369 46.6947 96.4709 46.719 96.4985 46.7497C96.5261 46.7804 96.5467 46.8167 96.5588 46.8562C96.5709 46.8956 96.5743 46.9372 96.5686 46.9781L87.8766 146.63C87.8701 146.718 87.8306 146.8 87.766 146.86C87.7015 146.92 87.6166 146.953 87.5287 146.952Z"
          fill="#E0E0E0"
        />
        <path
          d="M65.7311 146.952C65.6896 146.954 65.6483 146.946 65.6101 146.93C65.5719 146.914 65.5375 146.889 65.5094 146.859C65.4813 146.828 65.4601 146.792 65.4472 146.753C65.4343 146.713 65.43 146.671 65.4347 146.63L74.1267 46.9781C74.1361 46.8904 74.1774 46.8091 74.2427 46.7498C74.308 46.6904 74.3928 46.657 74.4811 46.656C74.5223 46.6546 74.5634 46.6623 74.6013 46.6785C74.6393 46.6947 74.6733 46.719 74.7009 46.7497C74.7285 46.7804 74.7491 46.8167 74.7612 46.8562C74.7733 46.8956 74.7767 46.9372 74.771 46.9781L66.079 146.63C66.071 146.717 66.0311 146.799 65.9669 146.858C65.9026 146.918 65.8186 146.951 65.7311 146.952Z"
          fill="#E0E0E0"
        />
        <path
          d="M154.107 84.8454H140.789C140.525 84.8498 140.264 84.7979 140.022 84.6932C139.78 84.5886 139.563 84.4335 139.385 84.2384C139.208 84.0433 139.074 83.8127 138.993 83.5618C138.911 83.3109 138.885 83.0456 138.914 82.7835L139.945 70.9601C140 70.4021 140.258 69.8838 140.67 69.5037C141.083 69.1236 141.62 68.9081 142.181 68.8982H155.499C155.763 68.8929 156.025 68.9441 156.268 69.0483C156.511 69.1526 156.729 69.3075 156.907 69.5027C157.085 69.6979 157.219 69.9289 157.301 70.1802C157.383 70.4316 157.41 70.6974 157.38 70.9601L156.349 82.7835C156.294 83.3426 156.035 83.8617 155.621 84.242C155.208 84.6222 154.669 84.837 154.107 84.8454Z"
          fill="#6868FE"
        />
        <path
          d="M247.033 84.8454H233.031L234.417 68.8982H248.424L243.16 76.875L247.033 84.8454Z"
          fill="white"
        />
        <path
          d="M247.554 85.1675H232.677L234.127 68.576H249.036L243.566 76.875L247.554 85.1675ZM233.379 84.5232H246.504L242.786 76.875L247.838 69.2204H234.713L233.379 84.5232Z"
          fill="#6868FE"
        />
        <path
          d="M195.84 104.775H182.522C182.259 104.78 181.997 104.729 181.755 104.625C181.512 104.521 181.295 104.367 181.117 104.172C180.939 103.978 180.804 103.747 180.722 103.497C180.64 103.246 180.612 102.981 180.641 102.719L181.672 90.8892C181.73 90.3318 181.99 89.815 182.403 89.4364C182.816 89.0577 183.354 88.8434 183.914 88.8338H197.232C197.496 88.8296 197.757 88.8814 197.999 88.9856C198.24 89.0898 198.457 89.2441 198.635 89.4384C198.813 89.6326 198.948 89.8624 199.03 90.1125C199.113 90.3626 199.141 90.6273 199.114 90.8892L198.083 102.719C198.025 103.276 197.764 103.793 197.351 104.172C196.938 104.551 196.401 104.765 195.84 104.775Z"
          fill="#6868FE"
        />
        <path
          d="M130.84 104.775H117.522C117.259 104.78 116.997 104.729 116.755 104.625C116.512 104.521 116.295 104.367 116.117 104.172C115.939 103.978 115.804 103.747 115.722 103.497C115.64 103.246 115.612 102.981 115.641 102.719L116.672 90.8892C116.73 90.3318 116.99 89.815 117.403 89.4364C117.816 89.0577 118.354 88.8434 118.914 88.8338H132.232C132.496 88.8296 132.757 88.8814 132.999 88.9856C133.24 89.0898 133.457 89.2441 133.635 89.4384C133.813 89.6326 133.948 89.8624 134.03 90.1125C134.113 90.3626 134.141 90.6273 134.114 90.8892L133.083 102.719C133.025 103.276 132.764 103.793 132.351 104.172C131.938 104.551 131.401 104.765 130.84 104.775Z"
          fill="#6868FE"
        />
        <path
          d="M108.84 104.775H95.5221C95.2585 104.78 94.9969 104.729 94.7545 104.625C94.5122 104.521 94.2948 104.367 94.1167 104.172C93.9387 103.978 93.8042 103.747 93.7221 103.497C93.64 103.246 93.6123 102.981 93.6407 102.719L94.6716 90.8892C94.7298 90.3318 94.99 89.815 95.4031 89.4364C95.8162 89.0577 96.3536 88.8434 96.9139 88.8338H110.232C110.496 88.8296 110.757 88.8814 110.999 88.9856C111.24 89.0898 111.457 89.2441 111.635 89.4384C111.813 89.6326 111.948 89.8624 112.03 90.1125C112.113 90.3626 112.141 90.6273 112.114 90.8892L111.083 102.719C111.025 103.276 110.764 103.793 110.351 104.172C109.938 104.551 109.401 104.765 108.84 104.775Z"
          fill="#6868FE"
        />
        <path
          d="M173.84 104.775H160.522C160.259 104.78 159.997 104.729 159.755 104.625C159.512 104.521 159.295 104.367 159.117 104.172C158.939 103.978 158.804 103.747 158.722 103.497C158.64 103.246 158.612 102.981 158.641 102.719L159.672 90.8892C159.73 90.3318 159.99 89.815 160.403 89.4364C160.816 89.0577 161.354 88.8434 161.914 88.8338H175.232C175.496 88.8296 175.757 88.8814 175.999 88.9856C176.24 89.0898 176.457 89.2441 176.635 89.4384C176.813 89.6326 176.948 89.8624 177.03 90.1125C177.113 90.3626 177.141 90.6273 177.114 90.8892L176.083 102.719C176.025 103.276 175.764 103.793 175.351 104.172C174.938 104.551 174.401 104.765 173.84 104.775Z"
          fill="#6868FE"
        />
        <path
          d="M151.84 104.775H138.522C138.259 104.78 137.997 104.729 137.755 104.625C137.512 104.521 137.295 104.367 137.117 104.172C136.939 103.978 136.804 103.747 136.722 103.497C136.64 103.246 136.612 102.981 136.641 102.719L137.672 90.8892C137.73 90.3318 137.99 89.815 138.403 89.4364C138.816 89.0577 139.354 88.8434 139.914 88.8338H153.232C153.496 88.8296 153.757 88.8814 153.999 88.9856C154.24 89.0898 154.457 89.2441 154.635 89.4384C154.813 89.6326 154.948 89.8624 155.03 90.1125C155.113 90.3626 155.141 90.6273 155.114 90.8892L154.083 102.719C154.025 103.276 153.764 103.793 153.351 104.172C152.938 104.551 152.401 104.765 151.84 104.775Z"
          fill="#6868FE"
        />
        <path
          opacity="0.4"
          d="M130.84 104.775H117.522C117.259 104.78 116.997 104.729 116.755 104.625C116.512 104.521 116.295 104.367 116.117 104.172C115.939 103.978 115.804 103.747 115.722 103.497C115.64 103.246 115.612 102.981 115.641 102.719L116.672 90.8892C116.73 90.3318 116.99 89.815 117.403 89.4364C117.816 89.0577 118.354 88.8434 118.914 88.8338H132.232C132.496 88.8296 132.757 88.8814 132.999 88.9856C133.24 89.0898 133.457 89.2441 133.635 89.4384C133.813 89.6326 133.948 89.8624 134.03 90.1125C134.113 90.3626 134.141 90.6273 134.114 90.8892L133.083 102.719C133.025 103.276 132.764 103.793 132.351 104.172C131.938 104.551 131.401 104.765 130.84 104.775Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M108.84 104.775H95.5221C95.2585 104.78 94.9969 104.729 94.7545 104.625C94.5122 104.521 94.2948 104.367 94.1167 104.172C93.9387 103.978 93.8042 103.747 93.7221 103.497C93.64 103.246 93.6123 102.981 93.6407 102.719L94.6716 90.8892C94.7298 90.3318 94.99 89.815 95.4031 89.4364C95.8162 89.0577 96.3536 88.8434 96.9139 88.8338H110.232C110.496 88.8296 110.757 88.8814 110.999 88.9856C111.24 89.0898 111.457 89.2441 111.635 89.4384C111.813 89.6326 111.948 89.8624 112.03 90.1125C112.113 90.3626 112.141 90.6273 112.114 90.8892L111.083 102.719C111.025 103.276 110.764 103.793 110.351 104.172C109.938 104.551 109.401 104.765 108.84 104.775Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M173.84 104.775H160.522C160.259 104.78 159.997 104.729 159.755 104.625C159.512 104.521 159.295 104.367 159.117 104.172C158.939 103.978 158.804 103.747 158.722 103.497C158.64 103.246 158.612 102.981 158.641 102.719L159.672 90.8892C159.73 90.3318 159.99 89.815 160.403 89.4364C160.816 89.0577 161.354 88.8434 161.914 88.8338H175.232C175.496 88.8296 175.757 88.8814 175.999 88.9856C176.24 89.0898 176.457 89.2441 176.635 89.4384C176.813 89.6326 176.948 89.8624 177.03 90.1125C177.113 90.3626 177.141 90.6273 177.114 90.8892L176.083 102.719C176.025 103.276 175.764 103.793 175.351 104.172C174.938 104.551 174.401 104.765 173.84 104.775Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M151.84 104.775H138.522C138.259 104.78 137.997 104.729 137.755 104.625C137.512 104.521 137.295 104.367 137.117 104.172C136.939 103.978 136.804 103.747 136.722 103.497C136.64 103.246 136.612 102.981 136.641 102.719L137.672 90.8892C137.73 90.3318 137.99 89.815 138.403 89.4364C138.816 89.0577 139.354 88.8434 139.914 88.8338H153.232C153.496 88.8296 153.757 88.8814 153.999 88.9856C154.24 89.0898 154.457 89.2441 154.635 89.4384C154.813 89.6326 154.948 89.8624 155.03 90.1125C155.113 90.3626 155.141 90.6273 155.114 90.8892L154.083 102.719C154.025 103.276 153.764 103.793 153.351 104.172C152.938 104.551 152.401 104.765 151.84 104.775Z"
          fill="white"
        />
        <path
          d="M221.362 64.9163H186.002C185.737 64.9216 185.475 64.8704 185.232 64.7662C184.989 64.6619 184.772 64.507 184.594 64.3118C184.415 64.1166 184.281 63.8856 184.199 63.6343C184.118 63.3829 184.091 63.117 184.12 62.8544L185.151 51.031C185.206 50.473 185.465 49.9547 185.877 49.5746C186.289 49.1945 186.826 48.979 187.387 48.9691H222.754C223.018 48.9648 223.28 49.0167 223.522 49.1213C223.765 49.2259 223.982 49.3809 224.16 49.5758C224.338 49.7708 224.472 50.0014 224.554 50.2522C224.636 50.5031 224.664 50.7686 224.636 51.031L223.605 62.8544C223.549 63.4135 223.29 63.9326 222.877 64.3129C222.463 64.6932 221.924 64.9079 221.362 64.9163Z"
          fill="#6868FE"
        />
        <path
          d="M187.825 101.224H187.773C187.673 101.217 187.575 101.184 187.492 101.127C187.408 101.071 187.341 100.993 187.297 100.902C186.633 99.3828 185.72 97.9848 184.597 96.7655C184.47 96.6399 184.399 96.4692 184.398 96.291C184.398 96.1127 184.468 95.9415 184.594 95.8151C184.719 95.6886 184.89 95.6173 185.068 95.6166C185.246 95.616 185.418 95.6863 185.544 95.8119C186.577 96.8919 187.432 98.1295 188.076 99.4781C190.29 97.2044 192.689 95.1182 195.248 93.241C195.381 93.1376 195.549 93.0905 195.717 93.1098C195.884 93.1291 196.037 93.2132 196.143 93.3441C196.193 93.4151 196.227 93.4953 196.245 93.5799C196.262 93.6645 196.263 93.7518 196.246 93.8366C196.229 93.9213 196.195 94.0019 196.147 94.0733C196.098 94.1448 196.036 94.2058 195.963 94.2526C193.218 96.2595 190.666 98.518 188.34 100.999C188.275 101.07 188.195 101.127 188.106 101.165C188.018 101.204 187.922 101.224 187.825 101.224Z"
          fill="white"
        />
        <path
          d="M165.825 101.224H165.773C165.673 101.217 165.575 101.184 165.492 101.127C165.408 101.071 165.341 100.993 165.297 100.902C164.633 99.3828 163.72 97.9848 162.597 96.7655C162.47 96.6399 162.399 96.4692 162.398 96.291C162.398 96.1127 162.468 95.9415 162.594 95.8151C162.719 95.6886 162.89 95.6173 163.068 95.6166C163.246 95.616 163.418 95.6863 163.544 95.8119C164.577 96.8919 165.432 98.1295 166.076 99.4781C168.29 97.2044 170.689 95.1182 173.248 93.241C173.381 93.1376 173.549 93.0905 173.717 93.1098C173.884 93.1291 174.037 93.2132 174.143 93.3441C174.193 93.4151 174.227 93.4953 174.245 93.5799C174.262 93.6645 174.263 93.7518 174.246 93.8366C174.229 93.9213 174.195 94.0019 174.147 94.0733C174.098 94.1448 174.036 94.2058 173.963 94.2526C171.218 96.2595 168.666 98.518 166.34 100.999C166.275 101.07 166.195 101.127 166.106 101.165C166.018 101.204 165.922 101.224 165.825 101.224Z"
          fill="white"
        />
        <path
          d="M187.825 101.224H187.773C187.673 101.217 187.575 101.184 187.492 101.127C187.408 101.071 187.341 100.993 187.297 100.902C186.633 99.3828 185.72 97.9848 184.597 96.7655C184.47 96.6399 184.399 96.4692 184.398 96.291C184.398 96.1127 184.468 95.9415 184.594 95.8151C184.719 95.6886 184.89 95.6173 185.068 95.6166C185.246 95.616 185.418 95.6863 185.544 95.8119C186.577 96.8919 187.432 98.1295 188.076 99.4781C190.29 97.2044 192.689 95.1182 195.248 93.241C195.381 93.1376 195.549 93.0905 195.717 93.1098C195.884 93.1291 196.037 93.2132 196.143 93.3441C196.193 93.4151 196.227 93.4953 196.245 93.5799C196.262 93.6645 196.263 93.7518 196.246 93.8366C196.229 93.9213 196.195 94.0019 196.147 94.0733C196.098 94.1448 196.036 94.2058 195.963 94.2526C193.218 96.2595 190.666 98.518 188.34 100.999C188.275 101.07 188.195 101.127 188.106 101.165C188.018 101.204 187.922 101.224 187.825 101.224Z"
          fill="white"
        />
        <path
          d="M122.825 101.224H122.773C122.673 101.217 122.575 101.184 122.492 101.127C122.408 101.071 122.341 100.993 122.297 100.902C121.633 99.3828 120.72 97.9848 119.597 96.7655C119.47 96.6399 119.399 96.4692 119.398 96.291C119.398 96.1127 119.468 95.9415 119.594 95.8151C119.719 95.6886 119.89 95.6173 120.068 95.6166C120.246 95.616 120.418 95.6863 120.544 95.8119C121.577 96.8919 122.432 98.1295 123.076 99.4781C125.29 97.2044 127.689 95.1182 130.248 93.241C130.381 93.1376 130.549 93.0905 130.717 93.1098C130.884 93.1291 131.037 93.2132 131.143 93.3441C131.193 93.4151 131.227 93.4953 131.245 93.5799C131.262 93.6645 131.263 93.7518 131.246 93.8366C131.229 93.9213 131.195 94.0019 131.147 94.0733C131.098 94.1448 131.036 94.2058 130.963 94.2526C128.218 96.2595 125.666 98.518 123.34 100.999C123.275 101.07 123.195 101.127 123.106 101.165C123.018 101.204 122.922 101.224 122.825 101.224Z"
          fill="white"
        />
        <path
          d="M100.825 101.224H100.773C100.673 101.217 100.575 101.184 100.492 101.127C100.408 101.071 100.341 100.993 100.297 100.902C99.6328 99.3828 98.7203 97.9848 97.5969 96.7655C97.4704 96.6399 97.399 96.4692 97.3984 96.291C97.3978 96.1127 97.4681 95.9415 97.5937 95.8151C97.7193 95.6886 97.89 95.6173 98.0682 95.6166C98.2464 95.616 98.4176 95.6863 98.544 95.8119C99.5772 96.8919 100.432 98.1295 101.076 99.4781C103.29 97.2044 105.689 95.1182 108.248 93.241C108.381 93.1376 108.549 93.0905 108.717 93.1098C108.884 93.1291 109.037 93.2132 109.143 93.3441C109.193 93.4151 109.227 93.4953 109.245 93.5799C109.262 93.6645 109.263 93.7518 109.246 93.8366C109.229 93.9213 109.195 94.0019 109.147 94.0733C109.098 94.1448 109.036 94.2058 108.963 94.2526C106.218 96.2595 103.666 98.518 101.34 100.999C101.275 101.07 101.195 101.127 101.106 101.165C101.018 101.204 100.922 101.224 100.825 101.224Z"
          fill="white"
        />
        <path
          d="M143.825 101.224H143.773C143.673 101.217 143.575 101.184 143.492 101.127C143.408 101.071 143.341 100.993 143.297 100.902C142.633 99.3828 141.72 97.9848 140.597 96.7655C140.47 96.6399 140.399 96.4692 140.398 96.291C140.398 96.1127 140.468 95.9415 140.594 95.8151C140.719 95.6886 140.89 95.6173 141.068 95.6166C141.246 95.616 141.418 95.6863 141.544 95.8119C142.577 96.8919 143.432 98.1295 144.076 99.4781C146.29 97.2044 148.689 95.1182 151.248 93.241C151.381 93.1376 151.549 93.0905 151.717 93.1098C151.884 93.1291 152.037 93.2132 152.143 93.3441C152.193 93.4151 152.227 93.4953 152.245 93.5799C152.262 93.6645 152.263 93.7518 152.246 93.8366C152.229 93.9213 152.195 94.0019 152.147 94.0733C152.098 94.1448 152.036 94.2058 151.963 94.2526C149.218 96.2595 146.666 98.518 144.34 100.999C144.275 101.07 144.195 101.127 144.106 101.165C144.018 101.204 143.922 101.224 143.825 101.224Z"
          fill="white"
        />
        <path
          d="M164.094 143.898C163.168 143.914 162.249 143.732 161.398 143.365C160.548 142.998 159.785 142.454 159.16 141.77C158.535 141.086 158.064 140.277 157.776 139.396C157.488 138.515 157.39 137.584 157.49 136.662C157.691 134.704 158.603 132.887 160.052 131.555C161.502 130.223 163.389 129.468 165.357 129.433C166.283 129.417 167.202 129.598 168.053 129.964C168.904 130.33 169.667 130.873 170.292 131.557C170.916 132.241 171.388 133.049 171.676 133.93C171.964 134.81 172.062 135.742 171.962 136.662C171.761 138.621 170.849 140.439 169.4 141.772C167.951 143.105 166.063 143.861 164.094 143.898ZM165.274 130.722C163.66 130.757 162.115 131.38 160.929 132.474C159.743 133.568 158.996 135.057 158.83 136.662C158.748 137.419 158.828 138.185 159.064 138.909C159.3 139.633 159.688 140.298 160.201 140.86C160.714 141.423 161.341 141.87 162.04 142.171C162.739 142.473 163.494 142.622 164.256 142.61C165.869 142.573 167.414 141.948 168.601 140.853C169.787 139.758 170.533 138.268 170.699 136.662C170.779 135.906 170.698 135.142 170.461 134.419C170.224 133.697 169.836 133.033 169.323 132.471C168.81 131.91 168.184 131.463 167.486 131.162C166.788 130.86 166.034 130.71 165.274 130.722Z"
          fill="#6868FE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_58247">
          <rect width="250" height="221" fill="white" />
        </clipPath>
      </defs>
    </>,
    'CalendarWithBlobIcon',
  );
  return (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 250 221'} {...props} />
  );
};
export default CalendarWithBlobIcon;
