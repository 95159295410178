// React
import React from 'react';

// Custom components
import ScheduleGridItem from 'pages/Schedules/common/ScheduleGridItem/ScheduleGridItem';
import InfiniteVirtualList from 'components/InfiniteVirtualList/InfiniteVirtualList';

// Utilities
import { isEqual, omit } from 'lodash';
interface GridViewPropTypes {
  tasks: any[];
  totalCount: number;
  isNextPageLoading?: boolean;
  hideLeftBar?: boolean;
  height: number;
  onClickTask?: (taskId: string) => void;
  handleLoadNextPage?: (offset: number) => Promise<void>;
  fetchDataCallback?: () => void;
  showActions?: boolean;
}

const GridView = ({
  tasks,
  totalCount,
  isNextPageLoading = false,
  hideLeftBar = false,
  height,
  onClickTask,
  handleLoadNextPage,
  fetchDataCallback,
  showActions,
}: GridViewPropTypes) => {
  return (
    <InfiniteVirtualList
      handleLoadNextPage={handleLoadNextPage}
      rows={tasks?.map((task) => (
        <ScheduleGridItem
          fetchDataCallback={fetchDataCallback}
          hideLeftBar={hideLeftBar}
          onClick={onClickTask}
          key={task.id}
          task={task}
          showActions={showActions}
        />
      ))}
      height={height}
      totalCount={totalCount}
      isNextPageLoading={isNextPageLoading}
    />
  );
};

const arePropsEqual = (
  prevProps: GridViewPropTypes,
  nextProps: GridViewPropTypes,
) => {
  return isEqual(
    omit(prevProps, ['handleLoadNextPage', 'onClickTask']),
    omit(nextProps, ['handleLoadNextPage', 'onClickTask']),
  );
};

export default React.memo(GridView, arePropsEqual);
