import { useState, Fragment, useEffect } from 'react';

import { Stack, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseRounded from '@mui/icons-material/CloseRounded';
import LocationsSearchInput from '../LocationsSearchInput/locationsSearchInput';

interface Props {
  onSearch?: (value: string) => void;
}

const LocationsMobileHeader = ({ onSearch }: Props) => {
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    onSearch?.('');
  }, []);

  const handleCloseSearch = () => {
    setShowInput(false);
    onSearch?.('');
  };

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      padding={'16px 0px'}
    >
      {showInput && (
        <Stack
          direction={'row'}
          gap={'16px'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <LocationsSearchInput onSearch={onSearch} hideIcon />
          <CloseRounded onClick={handleCloseSearch} />
        </Stack>
      )}
      {!showInput && (
        <Fragment>
          <SearchIcon sx={{ opacity: '0' }} />
          <Typography
            color="#212121"
            fontWeight={'700'}
            fontSize={'20px'}
            letterSpacing={'0.15px'}
          >
            Locations
          </Typography>
          <SearchIcon
            sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
            onClick={() => setShowInput(true)}
          />
        </Fragment>
      )}
    </Stack>
  );
};

export default LocationsMobileHeader;
