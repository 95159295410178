import { useDropzone } from 'react-dropzone';
import { MainCnt } from './style';
import { useSnackbar } from 'notistack';
import { showMessageNotification } from 'utils/globalFunction';
import { uploadFile } from '@uploadcare/upload-client';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import TemplatesFileUploadSuccess from 'components/BuilderUI/TemplateFileUpload/successInfo';
import { useState } from 'react';
import { uploadCarePublicKey } from 'utils/constants';

function UploadTemplate(props) {
  const { workspace } = useWorkspaceHook();
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const { enqueueSnackbar } = useSnackbar();
  const { manageLoader, children } = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const MAX_UPLOAD_SIZE = 100;

  // Handle Upload Function
  const handleUpload = async (files) => {
    manageLoader?.(true);
    const selectedFile = files?.[0];

    const result = await uploadFile(selectedFile, {
      publicKey: uploadCarePublicKey,
      store: 'auto',
      metadata: {
        workspaceId: workspace?.Hotel?.id,
        workspaceName: workspace?.Hotel?.name,
        uploadedBy: userProfileData?.fullName,
        role: workspace?.title,
      },
    });

    if (result?.cdnUrl) {
      manageLoader?.(false);
      setShowSuccess(true);
    } else {
      showMessageNotification('Uploading Failed - Please try again', 'error');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleUpload,
    maxFiles: 3,
    multiple: true,
    maxSize: MAX_UPLOAD_SIZE * 1048576,
    accept: {
      'application/pdf': ['.pdf'],

      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],

      'application/vnd.ms-excel': ['xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        'xlsx',
      ],
      'text/csv': ['.csv'],
    },
    onDropRejected: (rejectedFiles) => {
      const errorMessage = rejectedFiles?.[0]?.errors?.[0]?.code;
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  const closeSuccessModal = () => {
    setShowSuccess(false);
  };

  return (
    <MainCnt>
      {showSuccess && (
        <TemplatesFileUploadSuccess
          isOpen={showSuccess}
          closeHandler={closeSuccessModal}
        />
      )}
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {children}
      </div>
    </MainCnt>
  );
}

export default UploadTemplate;
