import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const FileIconCnt = styled('div')({
  '& .icon': {
    height: 36,
    width: 36,
  },
});

export const DocumentItemCnt = styled('div')({
  borderRadius: '12px',
  border: '1px solid #EEEEEE',
  background: '#FFF',
  boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
  transition: 'ease border 0.2s',
  minHeight: 158,

  '.thumbnail': {
    height: 80,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },

  '.documents-action-dropdown': {
    visibility: 'hidden',
  },

  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.08)',
    border: '1px solid #6868FE',
    transition: 'ease border 0.2s',

    '.documents-action-dropdown': {
      visibility: 'visible',
    },
  },

  '.content': {
    flex: 1,
    padding: '15px 15px 10px 15px',
    overflow: 'hidden',
  },
  '.title': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '250px',
    display: 'block',
    textAlign: 'left',
    marginBottom: 7,
  },
  '.subTitle': {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14.52px',
    color: '#616161',
  },

  '.subCnt': {
    display: 'flex',
    alignItems: 'center',
    marginTop: -3,
  },
  '.titleCnt': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const Dot = styled('span')({
  margin: '0px 5px',
});

export const ChecklistTileCnt = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isdropdownopen',
})(({ isdropdownopen }) => ({
  position: 'relative',
  ...(isdropdownopen && {
    display: 'flex',
  }),
}));

export const ActionItemIcon = styled(Box, {
  shouldForwardProp: (prop) => !['bgcolor', 'svgdimensions'].includes(prop),
})(({ bgcolor, svgdimensions = {} }) => ({
  padding: '10px',
  backgroundColor: bgcolor,
  height: '40px',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  '& > svg': {
    fill: 'none',
    color: 'white',
    height: '20px',
    width: '20px',
    strokeWidth: '1px',
    ...svgdimensions,
  },
}));
