import DeleteIcon from 'components/Icons/deleteIcon';
import {
  BulkActionSelection,
  BulkActionCnt,
  BulkActionItem,
  BulkActionCancelItem,
} from 'pages/Assets/BulkActions/assetsBulkAction.style';
import CloseIcon from '@mui/icons-material/Close';
import useUpdateAsset from 'utils/CustomHooks/useUpdateAsset';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';

function AssetsBulkAction({ selected, onClearSelection }) {
  const dispatch = useDispatch();
  const { deleteAsset } = useUpdateAsset();
  const handleDeleteAssets = () => {
    const assetIds = selected?.map((l) => l?.data?.id);
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Delete Asset',
          description:
            assetIds?.length == 1
              ? 'Are you sure you want to delete this Assets?'
              : 'Are you sure you want to delete the selected Assets?',
          onConfirmCallback: () =>
            deleteAsset(assetIds).then(() => {
              onClearSelection();
            }),
          confirmButtonProps: { color: 'error' },
          confirmationText: 'Delete',
        },
      }),
    );
  };

  return (
    <BulkActionCnt>
      <BulkActionSelection>
        <span className={'count'}>{selected.length}</span>
        <span className={'selectedText'}>selected</span>
      </BulkActionSelection>
      <BulkActionItem onClick={handleDeleteAssets}>
        <DeleteIcon className={'deleteIcon actionIcon'} />
        <label className={'actionText'}>Delete</label>
      </BulkActionItem>
      <BulkActionCancelItem onClick={onClearSelection}>
        {' '}
        <CloseIcon className={'deleteIcon actionIcon'} />
      </BulkActionCancelItem>
    </BulkActionCnt>
  );
}

export default AssetsBulkAction;
