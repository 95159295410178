// Custom components
import FilterTerm from './FilterTerm';
import IsOrNot from './IsOrNot';
import AndOr from './AndOr';
import StatusDropdown from './FilterDropdowns/StatusDropdown';
import DateDropdown from './FilterDropdowns/DateDropdown';
import PersonDropdown from './FilterDropdowns/PersonDropdown';
import LocationDropdown from './FilterDropdowns/LocationDropdown';

// Styled
import { RowWrapper, DeleteIcon, WhereText } from './styled';

interface FilterRowPropTypes {
  index: number;
  filter: {
    filterName: 'status' | 'date' | 'user' | 'locations' | '';
    comparator: 'is' | 'isNot' | '';
    value: any[];
    conditional: 'and' | 'or' | '';
  };
  handleDeleteRow: (idx: number) => void;
  handleSetFilters: (
    idx: number,
    filterData: {
      filterName?: 'status' | 'date' | 'user';
      comparator?: 'is' | 'isNot';
      value?: any[];
      conditional?: 'and' | 'or';
    },
  ) => void;
}

const FilterRow = ({
  index,
  filter,
  handleDeleteRow,
  handleSetFilters,
}: FilterRowPropTypes) => {
  const options = [
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'user',
      label: 'Person',
    },
    {
      id: 'date',
      label: 'Date',
    },
    {
      id: 'locations',
      label: 'Location',
    },
  ];

  const handleChangeFilter = (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    value: string | string[],
  ) => {
    handleSetFilters(index, {
      [filterType]: value,
      ...(filterType === 'filterName' && { value: [] }),
    });
  };

  return (
    <RowWrapper>
      {index === 0 && <WhereText>Where</WhereText>}
      {index > 0 && (
        <AndOr
          disabled={index > 1}
          value={filter.conditional}
          onSelect={handleChangeFilter}
        />
      )}
      <FilterTerm
        value={filter.filterName}
        options={options}
        onSelect={handleChangeFilter}
      />
      <IsOrNot value={filter.comparator} onSelect={handleChangeFilter} />
      {(filter.filterName === 'status' || filter.filterName === '') && (
        <StatusDropdown onSelect={handleChangeFilter} value={filter.value} />
      )}
      {filter.filterName === 'date' && (
        <DateDropdown onSelect={handleChangeFilter} value={filter.value} />
      )}
      {filter.filterName === 'user' && (
        <PersonDropdown
          showAuthorizedAssignees
          onSelect={handleChangeFilter}
          value={filter.value}
        />
      )}
      {filter.filterName === 'locations' && (
        <LocationDropdown
          onChange={(locations) => handleChangeFilter('value', locations)}
          selectedLocations={filter.value}
        />
      )}
      <DeleteIcon
        onClick={() => handleDeleteRow(index)}
        sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
      />
    </RowWrapper>
  );
};

export default FilterRow;
