/* eslint-disable prettier/prettier */
import { values, flatten } from 'lodash';
import { CreateMessageGroup } from 'components/common/jsxrender/messages/CreateMessageGroup';
import { ArchiveChatListRender } from 'components/common/jsxrender/messages/archiveChatListRender';
import { ChannelActionModelRender } from 'components/common/jsxrender/messages/channelActionModelRender';
import { CustomDateSeparator } from 'components/common/jsxrender/messages/CustomDateSeparator';
import { ListChat } from 'components/common/jsxrender/messages/ListChat';
import { MessageChannelInner } from 'components/common/jsxrender/messages/messageChannelInner';
import { MessagesHeaderRender } from 'components/common/jsxrender/messages/messagesHeaderRender';
import { SectionChatList } from 'components/common/jsxrender/messages/sectionChatList';
import SuggestionItem from 'components/common/jsxrender/messages/suggestionItem';
import { streamClient } from 'services/getStreamServices';
import actions from 'store/actions';
import selectors from 'store/selectors/index';
import { getAllChannelList } from 'utils/globalFunction';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';

import { Modal, Tab, Tabs } from '@mui/material';
import { CustomMessageInput } from 'components/common/jsxrender/messages/customMessageInput';
import JoinGroupModal from 'components/common/jsxrender/messages/JoinGroupModal';
import ReadReceiptContextProvider, {
  ReadContext,
} from 'components/common/jsxrender/messages/readReceiptContextProvider';
import ReadReceiptSidebar from 'components/common/jsxrender/messages/readReceiptSidebar';
import { useArchiveChannel } from 'controller/useArchiveChannel';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserHotelTz } from 'store/selectors/auth';
import { Channel, Chat } from 'stream-chat-react';
import { useGetStreamHook } from 'utils/CustomHooks/useGetStreamHook';
import {
  ArchiveSidebarTitle,
  ChatBox,
  CommunicationContentWrap,
  CommunicationLayoutWrapCnt,
  CommunicationLeftSideBar,
} from './Messages.style';

export enum MESSAGES_TABS {
  sections = 'sections',
  list = 'list',
}
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { MessageEmptyState } from 'components/common/jsxrender/messages/MessageEmptyState';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';

const Messages: FC = () => {
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const params = useParams();
  const { openWSChannel, workspaceId, navigateWithWorkspaceUrl } =
    useWorkspaceHook();
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const userList = useSelector(selectors.getUserAdmin);
  const [channelListArchived, setChannelListArchived] = useState<any>();
  const isRefreshing: boolean | string = useSelector(selectors.getisRefreshing);
  const channelMessageToScrollTo: any = useSelector(
    selectors.getScrollToChannelMessage,
  );
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;
  const isShowGroupTrigger: boolean = useSelector(selectors.isShowGroupTrigger);
  const [showAddGroup, setShowAddGroup] = useState<boolean>(false);
  const [isArchiveShow, setIsArchiveShow] = useState<boolean>(false);
  const [showJoinChannelModel, setShowJoinChannelModel] =
    useState<boolean>(false);
  const [channelActionModalToShow, setChannelActionModalToShow] =
    useState<string>('');
  const [channelActionModalChannel, setChannelActionModalChannel] =
    useState<any>(null);
  const channelList = useSelector(selectors.getStreamChannelList);
  const channelsSortedList = useSelector(selectors.getStreamChannelsSortedList);
  const channelsSectionList = useSelector(
    selectors.getStreamChannelsSectionList,
  );
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);

  const setStreamChannel = (channel) => {
    dispatch(actions.setActiveStreamChannel(channel));
  };

  const activeWorkspace: any = useSelector(selectors.getActiveWorkspace);

  const getJoinedChannelData: any = useSelector(selectors.getJoinedChannelData);

  const { handleArchiveChannelSubmit } = useArchiveChannel();

  const openChannelActionModal = ({ modal = '', channel = null }) => {
    if (modal === '' || !channel) {
      setChannelActionModalToShow('');
      setChannelActionModalChannel(null);
    } else {
      setChannelActionModalToShow(modal);
      setChannelActionModalChannel(channel);
    }
  };

  const [value, setValue] = useState<string>(MESSAGES_TABS.sections);

  const { hasPermission } = useCustomPermission();

  useEffect(() => {
    if (!params?.channelId) {
      const [channel] = flatten(values(channelsSectionList));
      openWSChannel({ channel });
      return;
    }
    openWSChannel({ wsId: workspaceId, channelId: params?.channelId });
  }, [params?.channelId, channelList?.length]);

  useEffect(() => {
    if (getJoinedChannelData) {
      setShowJoinChannelModel(true);
    } else {
      setShowJoinChannelModel(false);
    }
  }, [getJoinedChannelData]);

  useEffect(() => {
    if (channelMessageToScrollTo) {
      const { channel } = channelMessageToScrollTo;
      openWSChannel({ channel });
    }
  }, [channelMessageToScrollTo]);

  useEffect(() => {
    if (isShowGroupTrigger) {
      setShowAddGroup(true);
      dispatch(actions.showCreateGroup(false));
    }
  }, [isShowGroupTrigger]);

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_USE_CHAT)) {
      navigateWithWorkspaceUrl('/dashboard');
    }

    window?.ServiceBell?.('hide');
    return () => {
      window?.ServiceBell?.('show');
    };
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSetChannel = (channel) => {
    openWSChannel({ channel });
    setShowAddGroup(false);
  };

  const loadArchivedChannel = async () => {
    try {
      const hotelUsersIds = userList?.map((u) => u.id);
      const channelListArch = await getAllChannelList(
        {
          id: userProfileData.id,
          HotelId: activeWorkspace?.Hotel?.id,
          hotelUsersIds,
        },
        true,
      );
      setChannelListArchived(channelListArch);
    } catch (error) {
      console.log(error);
    }
  };

  const getChannelListPromise = async ({
    isFirst = false,
    channelId = '' as boolean | string,
  }) => {
    loadArchivedChannel();

    dispatch(
      actions.setChannelsRefreshRequest({
        timestamp: new Date().valueOf(),
        isFirst,
        channelId,
      }),
    );
  };

  useEffect(() => {
    if (isArchiveShow) {
      loadArchivedChannel();
    }
  }, [isArchiveShow, isRefreshing]);

  const getArchivedChatList = () => {
    return channelListArchived?.map((singleArchivedData, index) => {
      return (
        // eslint-disable-next-line react/jsx-key
        <ArchiveChatListRender
          index={index}
          singleData={singleArchivedData}
          handleSetChannel={handleSetChannel}
          setIsArchiveShow={setIsArchiveShow}
          openChannelActionModal={openChannelActionModal}
          selectedChannelId={channelId}
        />
      );
    });
  };

  return (
    <ReadReceiptContextProvider>
      <ReadContext.Consumer>
        {({ setReaders, setMessage }) => (
          <>
            {/* className="communication-layout-wrap-cnt" */}
            <CommunicationLayoutWrapCnt>
              <div className="layoutWrap communicationLayoutWrap">
                <CommunicationLeftSideBar>
                  <form>
                    {isArchiveShow ? (
                      <>
                        <ArchiveSidebarTitle>
                          <span
                            className="iconLeftAngle icon-left-angle"
                            onClick={() => {
                              setIsArchiveShow(false);
                            }}
                          ></span>
                          Archived Messages
                        </ArchiveSidebarTitle>
                        <ListChat
                          isArchivedList={true}
                          handleSetChannel={(e) => {
                            setReaders([]);
                            setMessage('');
                            handleSetChannel(e);
                          }}
                          channelList={channelListArchived}
                          getArchivedChatList={getArchivedChatList}
                          setIsArchiveShow={setIsArchiveShow}
                          openChannelActionModal={openChannelActionModal}
                          selectedChannelId={channelId}
                          tz={tz}
                        />
                      </>
                    ) : (
                      <>
                        <Tabs
                          onChange={handleTabChange}
                          value={value}
                          id="noanim-tab-example"
                          variant="fullWidth"
                        >
                          <Tab
                            id="full-width-tab-1"
                            value={MESSAGES_TABS.sections}
                            label="Sections"
                            aria-controls="full-width-tabpanel-1"
                            sx={{ textTransform: 'capitalize' }}
                          />
                          <Tab
                            id="full-width-tab-2"
                            value={MESSAGES_TABS.list}
                            label="List"
                            aria-controls="full-width-tabpanel-2"
                            sx={{ textTransform: 'capitalize' }}
                          />
                        </Tabs>
                        {value === MESSAGES_TABS.sections && (
                          <SectionChatList
                            setShowAddGroup={setShowAddGroup}
                            channelsSectionList={channelsSectionList}
                            getArchivedChatList={getArchivedChatList}
                            getChannelListPromise={getChannelListPromise}
                            setIsArchiveShow={setIsArchiveShow}
                            selectedChannelId={channelId}
                            handleSetChannel={(e) => {
                              setReaders([]);
                              setMessage('');
                              handleSetChannel(e);
                            }}
                            openChannelActionModal={openChannelActionModal}
                          />
                        )}
                        {value === MESSAGES_TABS.list && (
                          <ListChat
                            handleSetChannel={(e) => {
                              setReaders([]);
                              setMessage('');
                              handleSetChannel(e);
                            }}
                            channelList={channelsSortedList}
                            getArchivedChatList={getArchivedChatList}
                            setIsArchiveShow={setIsArchiveShow}
                            openChannelActionModal={openChannelActionModal}
                            selectedChannelId={channelId}
                            tz={tz}
                          />
                        )}
                      </>
                    )}
                  </form>
                </CommunicationLeftSideBar>

                <CommunicationContentWrap>
                  {activeStreamChannel?.data?.isArchived && (
                    <span className="archiveChatMsz">
                      This chat has been archived. To send messages, please
                      <span
                        className="unarchiveBtn ml-1 mr-1"
                        onClick={() => {
                          handleArchiveChannelSubmit(activeStreamChannel);
                        }}
                      >
                        unarchive
                      </span>
                      the chat
                    </span>
                  )}
                  {(showAddGroup && (
                    <CreateMessageGroup
                      channelList={channelList}
                      setStreamChannel={setStreamChannel}
                      setShowAddGroup={setShowAddGroup}
                      refreshChannels={getChannelListPromise}
                      handleSetChannel={(e) => {
                        setReaders([]);
                        setMessage('');
                        handleSetChannel(e);
                      }}
                    />
                  )) ||
                    (channelsSortedList.length === 0 && (
                      <MessageEmptyState
                        onBtnClick={() => {
                          dispatch(actions.showCreateGroup(true));
                        }}
                      />
                    )) || (
                      <div>
                        <div className="contentTitleWrap">
                          {activeStreamChannel && (
                            <MessagesHeaderRender
                              handleSetChannel={(e) => {
                                setReaders([]);
                                setMessage('');
                                handleSetChannel(e);
                              }}
                            />
                          )}
                        </div>
                        <ChatBox>
                          <Chat client={streamClient}>
                            {activeStreamChannel ? (
                              <Channel
                                channel={activeStreamChannel}
                                DateSeparator={CustomDateSeparator}
                                AutocompleteSuggestionItem={
                                  SuggestionItem as any
                                }
                                Input={CustomMessageInput}
                              >
                                <MessageChannelInner
                                  handleSetChannel={(e) => {
                                    setReaders([]);
                                    setMessage('');
                                    handleSetChannel(e);
                                  }}
                                />
                              </Channel>
                            ) : null}
                          </Chat>
                        </ChatBox>
                      </div>
                    )}
                </CommunicationContentWrap>
                <ReadReceiptSidebar />
              </div>
            </CommunicationLayoutWrapCnt>

            <Modal
              open={showJoinChannelModel}
              onClose={() => {
                setShowJoinChannelModel(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <JoinGroupModal title={'Join Channel'} />
            </Modal>

            <ChannelActionModelRender
              modalToShow={channelActionModalToShow}
              channel={channelActionModalChannel}
              handleClose={() => openChannelActionModal({ modal: '' })}
            />
          </>
        )}
      </ReadContext.Consumer>
    </ReadReceiptContextProvider>
  );
};

export default Messages;
