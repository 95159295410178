import TasksListSummary from 'pages/Widgets/TaskListing/TaskListView/tasksListSummary';
import { useSelector, useDispatch } from 'react-redux';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import { getDialogState } from 'store/selectors/dialogSelectors';
import TasksListingTable from 'components/AllDialogs/Dashboards/taskTable/TaskListView/taskListingTable';
function TaskTableDialog() {
  const dispatch = useDispatch();
  const dialogState = useSelector(getDialogState);
  const state = dialogState?.[DIALOGS.TASK_TABLE_DIALOG];
  const { open, data } = state;
  const { filters = null } = data;
  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.TASK_TABLE_DIALOG,
      }),
    );
  };

  return (
    <CustomDialog
      hideActions={true}
      header={
        <CustomDialogHeader onClose={handleClose}>Tasks</CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <TasksListingTable filters={filters} />
      </ContentCnt>
    </CustomDialog>
  );
}

export default TaskTableDialog;
