import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors, elevations } from '../../../theme/constants';

export const AddMembersModalContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 497,
  background: 'white',
  border: '1px solid #fff',
  boxShadow: elevations.elevation5,
  borderRadius: '16px',
  padding: '16px 24px',
  '.title': {
    fontWeight: 700,
    fontSize: '20px',
    color: colors.light.text.primary,
  },
  '.closeIcon,.backIcon': {
    fontSize: 20,
    color: colors.light.action.active,
    cursor: 'pointer',
  },
  '.inputContainer': {
    marginTop: 32,
    marginBottom: 16,
    padding: 0,
  },
  '.membersContainer': {
    padding: 0,
    maxHeight: 300,
    overflow: 'scroll',
    '.title': {
      fontWeight: 700,
      fontSize: '14px',
      color: colors.primary.p75,
      textTransform: 'upperCase',
    },
    '.memberGrid': {
      marginTop: 8,
      '&.disabled': {
        pointerEvents: 'none',
      },
      cursor: 'pointer',
    },
    '.active': {
      background: colors.primary.p50,
      borderRadius: '8px',
      '.checkIcon': {
        marginRight: 8,
        color: colors.primary.main,
        fontSize: 24,
      },
    },
    '.member': {
      marginLeft: 4,
      display: 'flex',
      alignItems: 'center',
      height: 48,
      '.name': {
        marginLeft: 8,
        fontWeight: 600,
        fontSize: 14,
        color: colors.primary.p100,
      },
    },
  },
  '.footer': {
    marginTop: 32,
    '.cancelBtn': {
      marginRight: 8,
      color: colors.primary.main,
    },
  },
}));
