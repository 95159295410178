import { createSvgIcon } from '@mui/material';

const HelpInChatIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M4.625 12.35C4.625 9.68975 4.625 8.35963 5.12909 7.34355C5.57251 6.44978 6.28004 5.72312 7.15029 5.26772C8.13964 4.75 9.43476 4.75 12.025 4.75H24.975C27.5652 4.75 28.8604 4.75 29.8497 5.26772C30.72 5.72312 31.4275 6.44978 31.8709 7.34355C32.375 8.35963 32.375 9.68975 32.375 12.35V20.9C32.375 23.5602 32.375 24.8904 31.8709 25.9065C31.4275 26.8002 30.72 27.5269 29.8497 27.9823C28.8604 28.5 27.5652 28.5 24.975 28.5H21.0958C20.1337 28.5 19.6527 28.5 19.1925 28.597C18.7843 28.683 18.3893 28.8253 18.0182 29.0201C17.5999 29.2395 17.2243 29.5481 16.473 30.1654L12.7955 33.187C12.154 33.714 11.8333 33.9775 11.5633 33.9778C11.3286 33.9781 11.1065 33.8685 10.9601 33.6801C10.7917 33.4634 10.7917 33.0416 10.7917 32.1979V28.5C9.35796 28.5 8.64111 28.5 8.05296 28.3381C6.45691 27.8989 5.21025 26.6186 4.78259 24.9794C4.625 24.3753 4.625 23.6391 4.625 22.1667V12.35Z"
        fill="#6868FE"
      />
      <path
        d="M15.4167 12.6702C15.6883 11.8772 16.2245 11.2084 16.9302 10.7825C17.6359 10.3565 18.4656 10.2008 19.2724 10.3429C20.0792 10.485 20.811 10.9158 21.3381 11.559C21.8653 12.2021 22.1538 13.0161 22.1526 13.8568C22.1526 16.2301 18.6864 17.4167 18.6864 17.4167M18.7311 22.1667H18.7465M10.7917 28.5V32.1979C10.7917 33.0416 10.7917 33.4634 10.9601 33.6801C11.1065 33.8685 11.3286 33.9781 11.5633 33.9778C11.8333 33.9775 12.154 33.714 12.7955 33.187L16.473 30.1654C17.2243 29.5481 17.5999 29.2395 18.0182 29.02C18.3893 28.8253 18.7843 28.683 19.1925 28.597C19.6527 28.5 20.1337 28.5 21.0958 28.5H24.975C27.5652 28.5 28.8604 28.5 29.8497 27.9823C30.72 27.5269 31.4275 26.8002 31.8709 25.9065C32.375 24.8904 32.375 23.5602 32.375 20.9V12.35C32.375 9.68975 32.375 8.35963 31.8709 7.34355C31.4275 6.44978 30.72 5.72312 29.8497 5.26772C28.8604 4.75 27.5652 4.75 24.975 4.75H12.025C9.43476 4.75 8.13964 4.75 7.15029 5.26772C6.28004 5.72312 5.57251 6.44978 5.12909 7.34355C4.625 8.35963 4.625 9.68975 4.625 12.35V22.1667C4.625 23.6391 4.625 24.3753 4.78259 24.9794C5.21025 26.6186 6.45691 27.8989 8.05296 28.3381C8.64111 28.5 9.35796 28.5 10.7917 28.5Z"
        stroke="#6868FE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'HelpInChatIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 37 38'} {...props} />;
};
export default HelpInChatIcon;
