import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import UserAvatar from 'components/Avatar';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import CTabs from 'components/CTabs';
import { useDropzone } from 'react-dropzone';
import { getItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { useSnackbar } from 'notistack';
import selectors from 'store/selectors';
import { userProfileUploadApi } from 'api/userApi';
import MyProfileTab from 'pages/my-settings/myProfileTab';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import {
  AvatarContainer,
  ChipStyled,
  ChipWrap,
  IconButtonStyled,
  StackItem,
} from 'pages/my-settings/styles';
import {
  SettingsMain,
  SettingsHeader,
} from 'components/common/styledComponents/settings.style';
import MailIcon from 'components/Icons/mailIcon';
import PhoneIcon from 'components/Icons/PhoneIcon';
import CasesIcon from 'components/Icons/casesIcon';

const MySettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const { workspaceId } = useWorkspaceHook();
  const { fullName, emailId, phoneNo, UserHotels } = userProfileData;
  const currentUserHotel = find(
    UserHotels,
    (userHotel) => userHotel.Hotel.id === workspaceId,
  );
  const { title } = currentUserHotel || {};
  const [file, setFile] = useState<any>(null);
  const [picCdnPath, setPicCdnPath] = useState<string>('');
  const userId: string = getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID) as string;
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/*': [],
    },
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxFiles: 1,
    maxSize: 26214400, // MAX 25MB size
    onDropAccepted: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      await onClickLoadFile(file);
    },
    onDropRejected: (rejectedFiles) => {
      enqueueSnackbar(`${rejectedFiles[0]?.errors[0]?.message}`, {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      file !== null && URL.revokeObjectURL(file.preview);
    };
  }, []);

  const onClickLoadFile = async (file) => {
    const response = await userProfileUploadApi(file);
    const cdnPath = `${response.baseUrl}${response.filePath}`;
    setPicCdnPath(cdnPath);
  };

  const userProfile = useMemo(
    () => find(UserHotels, (userHotel) => userHotel.Hotel.id === workspaceId),
    [workspaceId, UserHotels],
  );

  const mySettingsTabs = [
    {
      label: 'My Profile',
      content: (
        <MyProfileTab
          userProfileData={userProfileData}
          picCdnPath={picCdnPath}
          setPicCdnPath={setPicCdnPath}
        />
      ),
    },
  ];

  return (
    <SettingsMain>
      <SettingsHeader style={{ display: 'flex' }}>
        <AvatarContainer>
          <UserAvatar
            width={80}
            height={80}
            userId={userId}
            placeholder={file === null ? null : file.preview}
          />

          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <IconButtonStyled aria-label="edit" size="small" onClick={open}>
              <EditIcon fontSize="small" />
            </IconButtonStyled>
          </div>
        </AvatarContainer>

        <div style={{ flex: 1 }}>
          <ChipWrap>
            <span style={{ display: 'inline-block', paddingRight: '10px' }}>
              {fullName}
            </span>
            <ChipStyled label={userProfile?.Role?.title ?? ''} size="small" />
          </ChipWrap>
          <div>
            <Stack direction="row" spacing={2} sx={{ color: '#00000061' }}>
              {title && (
                <StackItem>
                  <CasesIcon sx={{ color: '#00000099', fontSize: '18px' }} />
                  <span>{title}</span>
                </StackItem>
              )}

              {phoneNo && (
                <StackItem>
                  <PhoneIcon sx={{ color: '#00000099', fontSize: '17px' }} />
                  <span>{phoneNo}</span>
                </StackItem>
              )}

              {emailId && (
                <StackItem>
                  <MailIcon style={{ color: '#00000099', fontSize: '18px' }} />
                  <span>{emailId}</span>
                </StackItem>
              )}
            </Stack>
          </div>
        </div>
      </SettingsHeader>

      <CTabs data={mySettingsTabs} />
    </SettingsMain>
  );
};

export default MySettings;
