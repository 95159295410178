import { useParams } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { RequestsContext } from 'pages/Requests/context/context';
import { initialStateType } from 'pages/Requests/Requests';
import xeniaApi from 'api/index';
import { getSingleRequest } from 'api/requests';
function useRequests() {
  const params = useParams();
  const { requestId } = params;
  const selectedRequestId = requestId || params?.['*'];
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { requestsState, updateRequestsState, collapsed } =
    useContext(RequestsContext) || {};
  const { requests = [] } = requestsState || {};
  //Get selected request from url params
  const selectedRequest = useCallback(() => {
    const selectedRequestItem = requests?.find(
      (r) => r.id == selectedRequestId,
    );
    return selectedRequestItem;
  }, [selectedRequestId, requestsState]);

  const navigateToRequest = useCallback(
    (requestId: string) => {
      //Navigate to request detail page
      navigateWithWorkspaceUrl(`/requests/${requestId}`);
    },
    [selectedRequestId],
  );
  const getSingleRequest = useCallback(async (requestId) => {
    const fetchSingleRequest = await xeniaApi.getSingleRequest(requestId);
    if (fetchSingleRequest) {
      return fetchSingleRequest;
    }
  }, []);

  const scrollToRequest = useCallback(() => {
    const selectedRequestItem = document.getElementById(`${selectedRequestId}`);
    const scrollOffset = 200; // Number of pixels to subtract from the scroll position

    if (selectedRequestItem && selectedRequestItem.parentElement) {
      const parentElement = selectedRequestItem.parentElement;
      const elementPosition = selectedRequestItem.offsetTop;
      const offsetPosition = elementPosition - scrollOffset;
      parentElement.scrollTop = offsetPosition;
    }
  }, [selectedRequestId]);

  const updateRequest = (newRequest) => {
    const acceptedStatus = 'Accepted';
    updateRequestsState({
      requests: requestsState?.requests?.map((request) => {
        if (request.id === selectedRequestId) {
          return {
            ...request,
            status: acceptedStatus,
            TaskId: newRequest?.id,
          };
        }
        return request;
      }),
      ...(collapsed?.includes(acceptedStatus) && {
        collapsed: collapsed?.filter((item) => item !== acceptedStatus),
      }),
    });
  };
  return {
    selectedRequest,
    navigateToRequest,
    getSingleRequest,
    scrollToRequest,
    selectedRequestId,
    updateRequest,
  };
}

export default useRequests;
