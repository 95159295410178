import { FC, useRef, useState, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { useSortable } from '@dnd-kit/sortable';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import ColorPicker from 'components/ColorPicker';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ColorDot, FlaggedCheckText } from './styled';
import { Box, IconButton } from '@mui/material';
import { showMessageNotification } from 'utils/globalFunction';
import { TextField, InputAdornment } from '@mui/material';
import { DebouncedFunc } from 'lodash';
import CategoryDropdown from 'components/Dropdown/TemplateCategoryDropdown/TemplateCategoryDropdown';
import { CircleIcon } from 'components/common/jsxrender/checklistV2/builder/checklistItem.style';
import CustomToolTip from 'components/Tooltip/tooltip';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

export interface IMultipleChoiceOption {
  id: string;
  label: string;
  color: string;
  flag: boolean;
  order: number;
  score: number;
  flagCategory?: string;
}

interface IProps {
  isScoring: boolean;
  option: IMultipleChoiceOption;
  index: number;
  onChange: DebouncedFunc<(index: number, key: string, value: any) => void>;
  optionChangeFunc(index: number, key: string, value: any): void;
  onRemove(): void;
  onClick?: (id?: string) => void;
  autoFocusItemId?: any;
  setOptionsRef(index: number, key: string, value: any): void;
  popup: boolean;
  itemId: string;
  isUploading: boolean;
}

const Option: FC<IProps> = (props) => {
  const {
    option,
    onChange,
    onRemove,
    index,
    isScoring,
    onClick,
    autoFocusItemId,
    setOptionsRef,
    optionChangeFunc,
    itemId,
    popup,
    isUploading,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [colorOpen, setColorOpen] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: option.id });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const onLabelChange = useCallback(
    (event) => {
      optionChangeFunc(index, 'label', event.target.value);
    },
    [index, onChange],
  );

  const onScoreChange = useCallback(
    (event) => {
      if (
        Number.isFinite(Number(event.target.value)) ||
        event.target.value === null
      ) {
        optionChangeFunc(index, 'score', Number(event.target.value));
      } else if (event.target.value === '/') {
        optionChangeFunc(index, 'score', null);
      } else {
        showMessageNotification('Score must be a number', 'error');
      }
    },
    [index, onChange],
  );

  const nullScoring = () => {
    optionChangeFunc(index, 'score', null);
  };
  return (
    <Stack
      id={option.id}
      direction="row"
      gap={'12px'}
      style={style}
      alignItems={'center'}
    >
      <Stack direction={'row'} gap="6px" alignItems={'center'}>
        <IconButton
          sx={{ padding: '4px' }}
          onClick={(e) => {
            e.stopPropagation();
            if (isUploading) return;
            onChange.cancel();
            onRemove();
          }}
        >
          <RemoveCircleOutlineIcon
            sx={{
              fontSize: '18px',
              color: isUploading
                ? 'rgba(117, 117, 117, 1)'
                : 'rgba(211, 47, 47, 1)',
            }}
          />
        </IconButton>
        <IconButton
          sx={{ padding: '4px' }}
          ref={setNodeRef}
          {...listeners}
          {...attributes}
        >
          <DragIndicatorIcon
            sx={{ color: 'rgba(158, 158, 158, 1)', fontSize: '18px' }}
          />
        </IconButton>
      </Stack>
      <Stack flex={1}>
        <CustomInput
          sx={{
            marginBottom: '0 !important',
            marginLeft: '0px !important',
            '& .MuiInputBase-root': {
              borderRadius: '8px',
            },
            '& input': {
              fontSize: '14px',
              padding: '10.66px 14px !important',
            },
            '& fieldset': {
              borderColor: '#E0E0E0',
            },
          }}
          fieldProps={{
            disabled: isUploading ? true : false,
            defaultValue: option.label,
            placeholder: 'Enter option',
            onChange: onLabelChange,
            onClick: (e) => {
              e.stopPropagation();
              onClick && onClick(`${itemId}-${option.id}`);
            },
            autoFocus: popup
              ? false
              : autoFocusItemId === `${itemId}-${option.id}`,
            startAdornment: (
              <div ref={ref}>
                <ColorDot
                  sx={{ backgroundColor: option.color }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isUploading) return;
                    onChange.cancel();
                    setColorOpen(true);
                  }}
                />

                {ref?.current && (
                  <ColorPicker
                    anchorEl={ref.current}
                    open={colorOpen}
                    value={option.color}
                    onClose={() => setColorOpen(false)}
                    onSelect={(color) => {
                      onChange(index, 'color', color);
                      setColorOpen(false);
                    }}
                  />
                )}
              </div>
            ),
          }}
        />
      </Stack>
      <Stack
        direction="row"
        borderRadius={'8px'}
        padding="0px 12px"
        alignItems={'center'}
        border="1px solid #E0E0E0"
        gap="8px"
        height="40px"
        justifyContent={'space-between'}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack direction="row">
          <FlaggedCheckText>Flag:</FlaggedCheckText>
          <CustomCheckbox
            disabled={isUploading}
            disableRipple
            checked={option.flag}
            onChange={(e) => {
              optionChangeFunc(index, 'flag', e.target.checked);
            }}
            sx={{
              padding: '0px',
              minWidth: '0px',
            }}
          />
        </Stack>

        <Box
          sx={{
            flex: '0 1 0',
          }}
        >
          <CategoryDropdown
            selected={option?.flag && option.flagCategory}
            onChangeCallback={(value) => {
              optionChangeFunc(index, 'flagCategory', value.id);
            }}
            placeholder="Enter Category Name"
          />
        </Box>
      </Stack>
      {isScoring && (
        <TextField
          sx={{
            width: '100px',
            '& .MuiInputBase-root': {
              paddingLeft: '12px',
              height: '40px',
            },
            '& .Mui-focused': {
              '& .cancelIcon': {
                opacity: 1,
              },
            },
            '& :hover': {
              '& .cancelIcon': {
                opacity: 1,
              },
            },
            '& input': {
              color: '#000',
              fontWeight: '600',
              fontSize: '14px',
              paddingRight: '12px',
              textAlign: 'right',
            },
            '& fieldset': {
              borderRadius: '8px',
              borderColor: '#E0E0E0',
              top: '0px',
              '& legend': {
                display: 'none',
              },
            },
          }}
          disabled={isUploading}
          variant="outlined"
          onChange={onScoreChange}
          size="small"
          value={option.score === null ? '/' : option.score ?? 1}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  '& .MuiTypography-root': {
                    color: '#616161',
                    fontWeight: '400',
                    fontSize: '14px',
                  },
                }}
                position="start"
              >
                Score:
              </InputAdornment>
            ),
            endAdornment: isUploading ? (
              ''
            ) : (
              <CustomToolTip
                placement={'top'}
                title={
                  <>
                    This will exclude the question's score from the overall
                    <br />
                    submission score, effectively nullifying its impact.
                  </>
                }
              >
                <CircleIcon className={'cancelIcon'} onClick={nullScoring}>
                  <DoNotDisturbIcon />
                </CircleIcon>
              </CustomToolTip>
            ),
          }}
          autoFocus={
            popup ? false : autoFocusItemId === `${itemId}-${option.id}-score`
          }
          onClick={(e) => {
            e.stopPropagation();
            onClick && !popup && onClick(`${itemId}-${option.id}-score`);
          }}
        />
      )}
    </Stack>
  );
};

export default Option;
