import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { Dialog } from '@mui/material';

import DialogHeader from './dialogHeader';
import DialogContent from './dialogContent';
import { setDialog } from 'store/actions/dialogActions';

interface IProps {
  dialogId: string;
}

const NotificationAutomationDialog: FC<IProps> = (props) => {
  const { dialogId } = props;
  const dispatch = useDispatch();

  const dialogSlice = useSelector(selectors.getDialogState);
  const dialogState = dialogSlice?.[dialogId];
  const dialogData = dialogState?.data;

  const { field, automation, onSave } = dialogData || {};

  const handleClose = useCallback(() => {
    dispatch(setDialog({ dialogId, open: false, data: null }));
  }, [dialogId]);

  return (
    <Dialog open={dialogState?.open}>
      <DialogHeader onClose={handleClose} />
      <DialogContent
        automation={automation}
        field={field}
        onClose={handleClose}
        onSave={onSave}
      />
    </Dialog>
  );
};

export default NotificationAutomationDialog;
