import AddIcon from 'components/Icons/addIcon';
import TelescopeIcon from 'components/Icons/telescopeIcon';
import UploadIcon from 'components/Icons/uploadIcon';

import { ActionItemDescription } from './styled';

export const getHeaderItems = (clickHandlers) => {
  return [
    {
      icon: {
        component: AddIcon,
        iconBackgroundColor: 'rgba(3, 169, 244, 1)',
      },
      heading: 'Create New Template',
      description:
        'Customize your template from scratch with our advanced template builder.',
      bgColor: 'rgba(225, 245, 254, 1)',
      borderColor: 'rgba(3, 169, 244, 1)',
      onClick: clickHandlers[0],
    },
    {
      icon: {
        component: TelescopeIcon,
        iconBackgroundColor: 'rgba(149, 117, 205, 1)',
        styles: { width: 22, height: 22 },
      },
      heading: 'Explore Free Template Library',
      description:
        'Dont build from scratch. Select from our community of templates.',
      bgColor: 'rgba(237, 231, 246, 1)',
      borderColor: 'rgba(126, 87, 194, 1)',
      onClick: clickHandlers[1],
    },
    {
      icon: {
        component: UploadIcon,
        iconBackgroundColor: 'rgba(139, 195, 74, 1)',
      },
      heading: 'Upload Your Form',
      descriptionRenderer: (
        <>
          <ActionItemDescription component="span">
            {`Have a long form? Send it to us and we’ll digitize it! .xlsx, .csv, .pdf, .docx. Limit of 3 forms.`}{' '}
          </ActionItemDescription>
        </>
      ),
      description: `Have a long form? Send it to us and we’ll digitize it! .xlsx, .csv, .pdf, .docx. Limit of 3 forms.`,
      bgColor: 'rgba(241, 248, 233, 1)',
      borderColor: 'rgba(139, 195, 74, 1)',
      onClick: clickHandlers[2],
    },
  ];
};
