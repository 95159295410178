import AddIcon from 'components/Icons/addIcon';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import useAssetDetail from '../../useAssetDetail';
import AssetServicesEmptyIcon from 'public/assets/img/asset-services-emptystate.svg';
import AssetServicesOverdueEmptyIcon from 'public/assets/img/asset-services-overdue-emptystate.svg';
import AssetServicesCompletedEmptyIcon from 'public/assets/img/asset-services-completed-emptystate.svg';
import { CreateServiceBtn, MainCnt, Statement, Title } from './style';
import useResponsive from 'utils/CustomHooks/useResponsive';
const CONTENT = {
  all: {
    imgSrc: AssetServicesEmptyIcon,
    title: 'No Asset Services Found',
    statement:
      "Start managing your asset services efficiently with our Asset Servicing feature. Keep track of your assets' maintenance schedules, set reminders, and mark services as completed to ensure they are well-maintained and performing optimally.",
  },
  upcoming: {
    imgSrc: AssetServicesEmptyIcon,
    title: 'No Upcoming Asset Services',
    statement:
      'There are currently no upcoming asset services scheduled. Stay proactive by creating new services and setting reminders for the next service date.',
  },
  overdue: {
    imgSrc: AssetServicesOverdueEmptyIcon,
    title: 'No Overdue Asset Services',
    statement:
      'There are currently no overdue asset services. Ensure timely maintenance of your assets by creating new services and setting reminders for upcoming service dates.',
  },
  completed: {
    imgSrc: AssetServicesCompletedEmptyIcon,
    title: 'No Completed Asset Services',
    statement:
      'Review your completed asset services here. Mark services as completed to keep track of your assets maintenance history.',
  },
};

interface IProps {
  type: string;
  addSuccessCallback(data?: any): void;
}

function AssetServicesEmptyState(props: IProps) {
  const { addSuccessCallback, type } = props;
  const { selectedAsset } = useAssetDetail();
  const { isMobileDeviceMd } = useResponsive();

  const dispatch = useDispatch();

  const createNewServiceHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_ASSET_SERVICE,
        data: {
          asset: selectedAsset,
          onSuccessCallback: addSuccessCallback,
        },
      }),
    );
  };

  return (
    <MainCnt
      style={{
        ...(isMobileDeviceMd && {
          height: 'calc(100vh - 330px)',
        }),
      }}
    >
      <img src={CONTENT[type]?.imgSrc} />
      <Title>{CONTENT[type]?.title}</Title>

      <Statement>{CONTENT[type]?.statement}</Statement>

      <CreateServiceBtn
        variant="contained"
        onClick={createNewServiceHandler}
        startIcon={<AddIcon />}
      >
        Create Service
      </CreateServiceBtn>
    </MainCnt>
  );
}

export default AssetServicesEmptyState;
