import React from 'react';

import CustomModal from 'components/CustomModal';
import DialogWrapper from './DialogWrapper';

import {
  IconContainer,
  Title,
  Hr,
  ButtonsWrapper,
  RedButtonFilled,
  RedButtonOutlined,
} from './styled';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const HideDialog = ({
  open,
  handleClose,
  onClickCancel,
  onClickHide,
  isSection = false,
}) => {
  return (
    <CustomModal handleClose={handleClose} open={open}>
      <DialogWrapper handleClose={handleClose}>
        <IconContainer>
          <VisibilityOffOutlinedIcon />
        </IconContainer>
        <Title>
          Are you sure you want to Hide this {isSection ? 'Section' : 'Step'}?
        </Title>
        <Hr />
        <ButtonsWrapper>
          <RedButtonOutlined onClick={onClickCancel}>
            No, Cancel
          </RedButtonOutlined>
          <RedButtonFilled
            onClick={onClickHide}
            startIcon={<VisibilityOffOutlinedIcon />}
          >
            Yes, Hide {isSection ? 'Section' : 'Step'}
          </RedButtonFilled>
        </ButtonsWrapper>
      </DialogWrapper>
    </CustomModal>
  );
};

export default HideDialog;
