import React, { useEffect, useState } from 'react';
import { ReportingListCnt } from 'pages/ReportingV2/ReportListing/reportListing.style';
import ReportListingItem from 'pages/ReportingV2/ReportListing/ReportListingItem/ReportListingItem';
import { getReports } from 'api/reporting';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function ReportingList() {
  const [data, setData] = useState<any>([]);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const getReportData = async () => {
    const reportData = await getReports();
    setData(reportData);
  };
  useEffect(() => {
    getReportData();
  }, []);

  const handleReportClick = (e, report) => {
    navigateWithWorkspaceUrl(`/reporting/${report.id}`);
  };
  return (
    <ReportingListCnt>
      {data?.map((r) => (
        <ReportListingItem key={r.id} report={r} onClick={handleReportClick} />
      ))}
    </ReportingListCnt>
  );
}

export default ReportingList;
