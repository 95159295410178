// Utils
import moment from 'moment';

// Styles
import {
  Description,
  ToolsDescription,
  ToolsText,
  DescriptionBold,
} from './styled';
import ChecklistForm from 'public/assets/img/subscriptionPlans/checklist_form.svg';
import WorkOrderManagement from 'public/assets/img/subscriptionPlans/workorder_management.svg';
import PrecentiveMaintenance from 'public/assets/img/subscriptionPlans/preventive_maintenance.svg';
import AssetsManagement from 'public/assets/img/subscriptionPlans/assets_managements.svg';
import SafetyCompliance from 'public/assets/img/subscriptionPlans/safety_compliance.svg';
import OperationsReports from 'public/assets/img/subscriptionPlans/operations_reports.svg';

import QualityAssurance from 'public/assets/img/subscriptionPlans/quality_assurance.svg';
import LogBooks from 'public/assets/img/subscriptionPlans/log_books.svg';
import TeamDirectory from 'public/assets/img/subscriptionPlans/team_directory.svg';

const imagesToShow = [
  ChecklistForm,
  WorkOrderManagement,
  PrecentiveMaintenance,
  AssetsManagement,
  SafetyCompliance,
  OperationsReports,
  QualityAssurance,
  LogBooks,
  TeamDirectory,
];

export const getToolsDescription = () => {
  return (
    <>
      <ToolsDescription>
        All the tools you need to run a highly connected & accountable
        operation.
      </ToolsDescription>
      <div style={{ display: 'flex' }}>
        {imagesToShow?.map((image, index) => (
          <img src={image} />
        ))}
      </div>
    </>
  );
};

export const getDescriptionJSX = (
  billingInfo,
  canManageBilling,
  isTrialAndActive,
) => {
  if (isTrialAndActive) {
    return getToolsDescription();
  }

  switch (billingInfo?.status) {
    case 'Unpaid':
      return (
        <Description>
          We failed to charge your card for your current
          <DescriptionBold component="span"> Xenia</DescriptionBold>{' '}
          subscription. Please update your card as soon as possible to continue
          with your subscription.
        </Description>
      );
    case 'Trial':
      if (!canManageBilling) {
        return (
          <Description>
            Please contact your admin to upgrade to
            <DescriptionBold component="span">
              {' '}
              Xenia Premium
            </DescriptionBold>{' '}
            to enjoy exclusive features.
          </Description>
        );
      }
      return getToolsDescription();
    default:
      if (!canManageBilling) {
        return (
          <Description>
            No worries, your data is safe and secure. Please contact your admin
            to upgrade to
            <DescriptionBold component="span">
              {' '}
              Xenia Premium
            </DescriptionBold>{' '}
            to enjoy exclusive features.
          </Description>
        );
      }
      return getToolsDescription();
  }
};

export const getTitleText = (
  billingInfo,
  onBoardingGoal = 'Operations & Maintenance',
) => {
  switch (billingInfo?.status) {
    case 'Trial':
      return (
        <ToolsText>{`Streamline your ${onBoardingGoal} via one powerful platform`}</ToolsText>
      );
    case 'Canceled':
      if (moment(billingInfo.endDate).isBefore(moment())) {
        return `Your subscription was expired on ${moment(
          billingInfo.endDate,
        ).format('MMM DD YYYY')}`;
      } else {
        return `Your subscription was cancelled, but don't worry, you can use it until ${moment(
          billingInfo.endDate,
        ).format('MMM DD YYYY')}`;
      }
    case 'Unpaid':
      return 'Credit Card Declined';
    default:
      return (
        <ToolsText>{`Streamline your ${onBoardingGoal} via one powerful platform`}</ToolsText>
      );
  }
};
