// React
import React, { useContext } from 'react';

// Custom components
import SubmissionListWithFilters from 'components/TemplateDashboard/SubmissionListWithFilters';
import BulkActions from 'components/TemplateDashboard/SubmissionListWithFilters/BulkActions';
import SubmissionTemplatePreview from 'components/TemplateDashboard/SubmissionTemplatePreview';
import EmptyState from 'pages/TemplateDashboard/Tabs/Submissions/EmptyState';

import {
  SubmissionTabWrapper,
  SubmissionListWrapper,
  SubmissionPreviewContainer,
} from './styled';

// Types
import { SubmissionContext } from './context';

const Submissions = () => {
  const ctx = useContext(SubmissionContext);

  return (
    <React.Fragment>
      {ctx?.empty && <EmptyState />}
      {!ctx?.empty && (
        <SubmissionTabWrapper>
          <SubmissionListWrapper>
            <SubmissionListWithFilters />
            {ctx?.selected && (
              <BulkActions
                selected={ctx?.selected}
                onClearSelection={() => ctx?.handleClearSelection()}
              />
            )}
          </SubmissionListWrapper>
          <SubmissionPreviewContainer>
            {!!(ctx?.submissionsLog && ctx?.submissionsLog.length) && (
              <SubmissionTemplatePreview key={ctx?.shouldUpdate} />
            )}
          </SubmissionPreviewContainer>
        </SubmissionTabWrapper>
      )}
    </React.Fragment>
  );
};

export default Submissions;
