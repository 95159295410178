// MUI
import { Stack, Typography } from '@mui/material';

// Icons
import NoRecordsFound from 'components/Icons/noRecordFoundIcon';

const EmptyState = () => {
  return (
    <Stack height="calc(100vh - 223px)" alignItems={'center'} pt={'100px'}>
      <NoRecordsFound style={{ width: '200px', height: 'auto' }} />
      <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={18}
        fontWeight={700}
        color="#212121"
      >
        No work orders found
      </Typography>
      <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={14}
        fontWeight={400}
        color="#616161"
        textAlign={'center'}
        marginBottom={'26px'}
        width={'365px'}
      >
        No work orders are currently linked to this location. Create new work
        orders and assign them to this location.
      </Typography>
    </Stack>
  );
};

export default EmptyState;
