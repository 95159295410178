// Notifications Settings
const NOTIFICATION_OPTIONS = [
  {
    value: 'SMS_EMAIL_PUSH_WHATSAPP',
    id: 'SMS_EMAIL_PUSH_WHATSAPP',
    label: 'SMS, Email, Push & WhatsApp',
  },
  {
    value: 'SMS_EMAIL_PUSH',
    id: 'SMS_EMAIL_PUSH',
    label: 'SMS, Email & Push',
  },
  {
    value: 'SMS_EMAIL_WHATSAPP',
    id: 'SMS_EMAIL_WHATSAPP',
    label: 'SMS, Email & WhatsApp',
  },
  {
    value: 'SMS_PUSH_WHATSAPP',
    id: 'SMS_PUSH_WHATSAPP',
    label: 'SMS, Push & WhatsApp',
  },
  {
    value: 'EMAIL_PUSH_WHATSAPP',
    id: 'EMAIL_PUSH_WHATSAPP',
    label: 'Email, Push & WhatsApp',
  },
  {
    value: 'EMAIL_PUSH',
    id: 'EMAIL_PUSH',
    label: 'Email & Push',
  },
  {
    value: 'EMAIL_WHATSAPP',
    id: 'EMAIL_WHATSAPP',
    label: 'Email & WhatsApp',
  },
  {
    value: 'SMS_EMAIL',
    id: 'SMS_EMAIL',
    label: 'SMS & Email',
  },
  {
    value: 'SMS_PUSH',
    id: 'SMS_PUSH',
    label: 'SMS & Push',
  },
  {
    value: 'SMS_WHATSAPP',
    id: 'SMS_WHATSAPP',
    label: 'SMS & WhatsApp',
  },
  {
    value: 'PUSH_WHATSAPP',
    id: 'PUSH_WHATSAPP',
    label: 'Push & WhatsApp',
  },
  {
    value: 'EMAIL',
    id: 'EMAIL',
    label: 'Email (only)',
  },
  {
    value: 'PUSH',
    id: 'PUSH',
    label: 'Push (only)',
  },
  {
    value: 'SMS',
    id: 'SMS',
    label: 'SMS (only)',
  },
  {
    value: 'WHATSAPP',
    id: 'WHATSAPP',
    label: 'WhatsApp (only)',
  },
  {
    value: 'NONE',
    id: 'NONE',
    label: 'None',
  },
];

export { NOTIFICATION_OPTIONS };
