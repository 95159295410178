import styled from '@mui/system/styled';

export const Main = styled('div')({
  padding: 0,
});

export const WSList = styled('div')({
  display: 'block',
});

export const WSContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ isSelected }) => ({
  width: '240px',
  height: '210px',
  padding: '18px',
  border: isSelected ? '1px solid #6868FE' : '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: isSelected ? '0px 8px 24px rgba(149, 157, 165, 0.2)' : 'none',
  filter: isSelected
    ? ''
    : 'drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.2))',
  backgroundColor: '#FFFFFF',
  borderRadius: '16px',
  marginRight: '16px',
  marginBottom: '16px',
  float: 'left',
}));

export const WSActionButtons = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const WSInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .ws-navigator:hover': {
    opacity: '0.8',
  },
});

export const WSName = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ isSelected }) => ({
  fontSize: '14px',
  fontWeight: isSelected ? '600' : '400',
  color: isSelected ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)',
  paddingTop: '24px',
}));

export const WorkspaceAdd = styled('div')({
  height: '80px',
  width: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '30px',
  fontWeight: '300',
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='rgba(0, 0, 0, 0.38)' stroke-width='1' stroke-dasharray='7 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`,
});

export const WSInviteContainer = styled('div')({
  width: '240px',
  height: '210px',
  padding: '18px',
  border: '1px solid #EEEEEE',
  boxShadow: 'none',
  filter: 'drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.2))',
  backgroundColor: '#EBEAFF',
  borderRadius: '16px',
  marginRight: '16px',
  marginBottom: '16px',
  float: 'left',
});

export const InvitedInWSName = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  color: '#424242',
  lineHeight: '20px',
  paddingTop: '12px',
});

export const InviteDetails = styled('div')({
  fontSize: '11px',
  fontWeight: '500',
  color: '#757575',
  lineHeight: '15px',
  textAlign: 'center',
  paddingTop: '6px',
});

export const InviteActionButtons = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  borderTop: '1px solid rgba(0, 0, 0, 0.06)',
  marginTop: '14px',
  marginLeft: '-18px',
  marginRight: '-18px',
  padding: '10px 18px',
});
