import { DashboardsListingContainer } from 'pages/Dashboards/DashboardListing/dashboardsListing.style';
import DashboardListingHeader from 'pages/Dashboards/DashboardListing/Header/Header';
import DashboardsListView from 'pages/Dashboards/DashboardListing/listView';
import DashboardsGridListing from 'pages/Dashboards/DashboardListing/GridView/GridViewListing';
import {
  GRID_VIEW,
  LIST_VIEW,
} from 'pages/Dashboards/DashboardListing/constants';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import { getAllDashboards } from 'api/dashboards';
import WithGridLoader from 'pages/Dashboards/DashboardListing/GridView/withGridLoader';
import WithTemplateLoader from 'pages/Dashboards/DashboardTemplates/withTemplateLoader';

function DashboardsListing() {
  const [selectedView, setSelectedView] = useState<'grid' | 'list'>(GRID_VIEW);
  const dashboards = useQuery({
    queryKey: [QUERY_KEYS.GET_DASHBOARDS],
    queryFn: getAllDashboards,
    enabled: false,
  });
  useEffect(() => {
    dashboards.refetch();
  }, []);
  return (
    <DashboardsListingContainer>
      <DashboardListingHeader
        setSelectedView={setSelectedView}
        selectedView={selectedView}
      />
      {
        <>
          <>
            {' '}
            {selectedView == GRID_VIEW && (
              <WithGridLoader isLoading={dashboards?.isLoading}>
                <DashboardsGridListing />
              </WithGridLoader>
            )}
            {selectedView == LIST_VIEW && (
              <WithTemplateLoader isLoading={dashboards?.isFetching}>
                {' '}
                <DashboardsListView />
              </WithTemplateLoader>
            )}
          </>
        </>
      }
    </DashboardsListingContainer>
  );
}

export default DashboardsListing;
