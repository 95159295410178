import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { AddMembersModalContainer } from './addMembersModalContainer.style';
import UserAvatar from 'components/Avatar';
import { useSelectedExistMember } from 'controller/useSelectedExistMember';
import { useAddNewMember } from 'controller/useAddMembers';
import { useQueryUsersList } from 'controller/useQueryUsersList';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { filter, isEqual } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

import {
  SearchInputCnt,
  StyledSearchField,
} from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';

const AddMembersModal: FunctionComponent<any> = ({
  handleClose,
  messageHeaderState,
  setMessageHeaderState,
}) => {
  const dispatch = useDispatch();
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);
  const { queryUsersObj, searchStr, handleTextChange }: any = useQueryUsersList(
    messageHeaderState.showAddMember,
  );
  const { handleAddMemberSubmit } = useAddNewMember(activeStreamChannel);

  const existProps = useSelectedExistMember(activeStreamChannel, 'addmember');

  const { selectedMembers, setSelectedMember, existMember } = existProps;
  const { users } = queryUsersObj;

  useEffect(() => {
    dispatch(actions.setIsRefreshing(true));
  }, [setMessageHeaderState]);

  const addMemberHandler = useCallback(
    (singleUser) => {
      const selectedUsers = selectedMembers;
      const isExists = filter(selectedUsers, (object) => {
        return isEqual(object, singleUser.id);
      });
      if (isExists && Array.isArray(isExists) && isExists.length > 0) {
        const exist = selectedUsers.indexOf(singleUser?.id);
        if (exist > -1) {
          selectedUsers.splice(exist, 1);
        } else {
          selectedUsers.push(singleUser.id);
        }
      } else {
        selectedUsers.push(singleUser.id);
      }
      setSelectedMember([...selectedUsers]);
    },
    [selectedMembers, setSelectedMember],
  );

  return (
    <>
      <AddMembersModalContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid className="title" item>
            <span className="backIcon">
              <ChevronLeftIcon
                onClick={() => {
                  handleAddMemberSubmit(selectedMembers);
                  setMessageHeaderState({
                    ...messageHeaderState,
                    showGroupDetailModel: true,
                  });
                }}
              />
            </span>
            Add Members
          </Grid>
          <Grid className="closeIcon" item>
            <CloseIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <div className="inputContainer">
          <SearchInputCnt>
            <StyledSearchField
              id="SearchInput"
              startAdornment={<SearchIcon />}
              onChange={handleTextChange}
              placeholder={'Search Employees'}
              value={searchStr}
              label={''}
              autoFocus={true}
              fullWidth={true}
            />
          </SearchInputCnt>
        </div>
        <div className="membersContainer">
          <p className="title">Members</p>
          {users?.map((user, i) => {
            const isSelectionDisabled = !!existMember?.find(
              (s) => s === user?.id,
            );
            const isSelected = !!selectedMembers?.find((s) => s === user?.id);
            return (
              <Grid
                container
                className={`memberGrid ${isSelectionDisabled && 'disabled'} ${
                  isSelected && 'active'
                }`}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={i}
                onClick={() => {
                  addMemberHandler(user);
                }}
              >
                <Grid item className="member">
                  <UserAvatar
                    width={32}
                    height={32}
                    userId={user?.id}
                    firstName={user?.name}
                    profilePic={user?.profileImage}
                  />
                  <span className="name">{user?.name}</span>
                </Grid>

                {isSelected && (
                  <Grid item>
                    <CheckIcon className="checkIcon"></CheckIcon>
                  </Grid>
                )}
              </Grid>
            );
          })}
        </div>
      </AddMembersModalContainer>
    </>
  );
};

export default AddMembersModal;
