// React
import e from 'express';
import { useMemo } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Constants
import { COLORS_LIST } from 'utils/constants';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';
import { TASK_STATUS } from 'utils/globalVariable';

export const useSchedule = (task: any) => {
  const categoryList = useSelector(selectors.getServiceAdmin);
  const templates = useSelector(selectors.getChecklistCompleteDataOnly);
  const users = useSelector(selectors.getUserAdmin);

  const { getUtcToTzTime, getUtcToTz } = useDateTime();

  const taskCategory = useMemo(
    () => categoryList?.find((cat) => cat.id === task?.ServiceId),
    [task, categoryList],
  );

  const taskInterval = useMemo(() => {
    if (task.requestThisTask) {
      if (
        task.intervalWeek?.length === 7 &&
        task.requestThisTask === 'Weekly'
      ) {
        return 'Daily';
      }
      return task.requestThisTask;
    }
    return null;
  }, [task]);

  const taskCategoryColor = useMemo(() => {
    if (taskCategory) {
      const color = COLORS_LIST.find((c) => c.color === taskCategory.color);
      return color;
    }
    return COLORS_LIST[0];
  }, [task, taskCategory]);

  const taskTemplate = useMemo(
    () => templates?.find((template) => template.id === task.ChecklistId),
    [task, templates],
  );

  const taskAssignee = useMemo(
    () => users?.find((user) => user.id === task.AssignId),
    [task, users],
  );

  const isTaskOverdue = useMemo(() => {
    return task.isOverDue;
  }, [task]);

  const isTaskMissed = useMemo(() => {
    return task.taskStatus === TASK_STATUS.Missed;
  }, [task]);

  const getFormattedDates = (format = 'MMM D YYYY') => {
    return {
      dueDate: task.dueDate
        ? `${getUtcToTz(task.dueDate, format)} • ${getUtcToTzTime(
            task.dueDate,
          )}`
        : undefined,
      completedOn: task.Finisher
        ? `${getUtcToTz(task.updatedAt, format)} • ${getUtcToTzTime(
            task.updatedAt,
          )}`
        : undefined,
      nextDue: task.NextDue
        ? `${getUtcToTz(task.NextDue.dueDate, format)} • ${getUtcToTzTime(
            task.NextDue.startDate,
          )} - ${getUtcToTzTime(task.NextDue.dueDate)}`
        : undefined,
    };
  };

  return {
    taskCategory,
    taskCategoryColor,
    taskTemplate,
    taskAssignee,
    isTaskOverdue,
    taskInterval,
    isTaskMissed,
    getFormattedDates,
  };
};
