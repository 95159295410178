import { useEffect, useMemo, useState } from 'react';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import { LoaderCnt } from 'pages/documents/detail/style';
import { CircularProgress } from '@mui/material';

import SOPBuilder from '../builder';
import DownloadPreview from '../downloadPreview';
import LinkUnavailable from './linkUnavailable';

import RestrictedView from './restrictedView';
import { getItem, setItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { v4 } from 'uuid';
import {
  PUBLIC_ACCESS,
  WORKSPACE_ACCESS,
} from 'components/DocumentAccess/constants';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DocumentDetail = () => {
  const [loading, setLoading] = useState(true);
  const { getDocument } = useDocumentsFolders();
  const [data, setData] = useState<any>({});
  const [metaInfo, setMetaInfo] = useState<any>({ canEdit: null });
  const [error, setError] = useState<any>(null);
  const userAuth = useSelector(selectors.getLoggedInUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      if (data?.publicAccess === PUBLIC_ACCESS?.publicEdit) {
        setMetaInfo({ canEdit: true });
        return;
      }

      if (userAuth) {
        const userInfo = userAuth?.UserHotels?.find(
          (item) => item?.Hotel?.id === userAuth?.HotelId,
        );
        const userAccessCheck = userInfo?.Role?.title === 'Basic User';
        if (userAccessCheck) {
          setMetaInfo({ canEdit: false });
          return;
        }
        if (data?.workspaceAccess === WORKSPACE_ACCESS.workspaceEdit) {
          const hasAccess = userAuth?.UserHotels?.find(
            (item) => item?.Hotel?.id === data?.HotelId,
          );

          if (hasAccess) {
            setMetaInfo({ canEdit: true });
            return;
          }
        }

        const user = data?.DocumentAccess?.find(
          (item) => item.AccessorId === userAuth?.id,
        );

        setMetaInfo({ canEdit: user?.canEdit || false });
      } else {
        if (data?.publicAccess === PUBLIC_ACCESS?.publicView) {
          setMetaInfo({ canEdit: false });
          return;
        }
      }
    }
  }, [userAuth, data]);

  useEffect(() => {
    if (metaInfo?.canEdit != null) {
      setLoading(false);
    }
  }, [metaInfo]);

  const getDocumentDetails = async () => {
    const urlSplited = window?.location?.href?.split('/');
    const documentId = urlSplited?.[urlSplited?.length - 1];
    const response = await getDocument(documentId);

    if (response?.errorCode) {
      if (response?.errorCode === 401) {
        sessionStorage.setItem('documentId', documentId);
        navigate('/sign-in', { replace: true });
      }
      setError(response?.errorCode);
      setLoading(false);
    }

    setData(response?.data?.data || {});
  };

  useEffect(() => {
    handleSetOrGetAnonymousId();
    getDocumentDetails();
  }, []);

  const handleSetOrGetAnonymousId = () => {
    const anonId = getItem(LOCALSTORAGE_KEYS.ANON_ID);
    if (!anonId) {
      const uuid = v4();
      setItem(LOCALSTORAGE_KEYS.ANON_ID, uuid);
      return uuid;
    }
    return anonId;
  };

  const isSOP: any = useMemo(() => {
    return Object?.keys(data?.meta || {})?.length === 0;
  }, [data]);

  const isData = Object.keys(data)?.length > 0;

  return (
    <div>
      {loading && (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      )}
      {!loading && isData && isSOP && (
        <SOPBuilder document={data} metaInfo={metaInfo} />
      )}
      {!loading && isData && !isSOP && (
        <DownloadPreview document={data} metaInfo={metaInfo} />
      )}
      {!loading && !isData && (error === 404 || error === 500) && (
        <LinkUnavailable />
      )}
      {!loading && !isData && error === 403 && <RestrictedView />}
    </div>
  );
};

export default DocumentDetail;
