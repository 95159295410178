/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux';
import { trim, forEach, uniq } from 'lodash';

import {
  createStreamChannel,
  getStreamChannel,
  getStreamChannels,
  queryStreamUsers,
} from 'services/getStreamServices';

import actions from 'store/actions';
import selectors from 'store/selectors';

import {
  getChannelUniqueId,
  showMessageNotification,
} from 'utils/globalFunction';

import {
  streamChatType,
  streamConstant,
  ConstantChannelTypeString,
} from 'utils/globalVariable';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

export const useAddGroupChannel = (): any => {
  const { workspaceId } = useWorkspaceHook();
  const currentUserId = useSelector(selectors.getUserId);
  const hotelId = workspaceId;
  const dispatch = useDispatch();
  const handleAddGroupChannelSubmit = async (
    membersList,
    groupName,
    _createType,
    shouldReload = true,
  ) => {
    let createType = _createType;
    membersList = membersList.filter((s) => s !== currentUserId);
    let type =
      createType && createType === ConstantChannelTypeString.FEED_STREAM
        ? streamChatType.FeedStream
        : streamChatType.TEAM;
    if (
      !createType ||
      createType === ConstantChannelTypeString.DIRECT_MESSAGE
    ) {
      if ((!groupName || trim(groupName) == '') && membersList.length === 1) {
        createType = ConstantChannelTypeString.DIRECT_MESSAGE;
        type = streamChatType.MESSAGING;
      } else {
        createType = ConstantChannelTypeString.GROUP;
      }
    }
    const channelIdUnique: string | null = getChannelUniqueId(
      currentUserId,
      createType,
    );

    const existChannels = await getStreamChannels({
      name: groupName,
      isArchived: false,
      hotelId: hotelId,
      type: [type],
      members: [],
      channelType: createType,
    });

    let shouldCreateChannel: boolean = existChannels.length === 0;
    const allMembers = [currentUserId, ...membersList];
    const allMembersSortedString = allMembers.sort().join();
    if (!groupName || trim(groupName) === '') {
      let matchedChannel: any = null;
      existChannels.forEach((ch) => {
        if (matchedChannel || (ch?.data?.name && trim(ch.data.name) !== ''))
          return;
        const { members } = ch.state;
        const exitingMemberIds: string[] = [];
        forEach(members, (v, k) => {
          exitingMemberIds.push(k);
        });
        const exitingMemberIdsString = exitingMemberIds.sort().join();
        if (allMembersSortedString === exitingMemberIdsString) {
          matchedChannel = ch;
        }
      });

      if (matchedChannel) {
        return matchedChannel;
      }
      shouldCreateChannel = true;
    }

    if (shouldCreateChannel) {
      let members = [currentUserId, ...membersList];
      if (createType === ConstantChannelTypeString.FEED_STREAM) {
        const teamMembers = await queryStreamUsers({
          userid: currentUserId,
          hotelId,
        });
        members = teamMembers?.users.map((u) => u.id);
      }
      const channel = await getStreamChannel({
        type,
        name: groupName,
        members: uniq([...members, currentUserId]),
        channelType: createType,
        hotelId,
        uniqueId: channelIdUnique as any,
      });
      const channelCreate = await createStreamChannel(channel);
      if (channelCreate) {
        if (shouldReload) {
          dispatch(actions.setIsRefreshing(true));
        }
        const [channel] = await getStreamChannels({
          id: channelCreate.channel.id,
          hotelId,
        });
        return channel;
      }
    } else {
      showMessageNotification(
        `${createType} ${groupName} already exist.`,
        streamConstant.ERROR,
      );
    }
    return null;
  };

  return {
    handleAddGroupChannelSubmit,
  };
};
