import styled from '@mui/system/styled';
import { Fab } from '@mui/material';

export const LocationsMainCnt = styled('div')(({ theme }) => ({
  padding: '0px 25px 0 25px',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: '4px 16px',
  },
}));

export const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 30,
  right: 16,
  '& svg': {
    fontSize: 32,
  },
}));
