import { Paper } from '@mui/material';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';

import { getByType } from 'utils/fileTypes';
import DownloadIcon2 from 'components/Icons/downloadIcon2';
import { downloadFile, formatBytes } from 'utils/globalFunction';

import { getFileTypeFromExt } from 'xeniautils';

import mime from 'mime';
import { useMemo, useState } from 'react';

const NotPreviewable = (props) => {
  const { document } = props;
  const [loading, setLoading] = useState(false);

  const downloadHandler = () => {
    setLoading(true);
    downloadFile(document?.path, document?.title, () => {
      setLoading(false);
    });
  };

  const fileType = useMemo(() => {
    const ext = mime
      .getExtension(document?.meta?.headInfo?.['content-type'])
      ?.toString();

    const type = getFileTypeFromExt(ext);
    return getByType(type);
  }, [document]);

  return (
    <Paper className="contentCnt">
      <div className="innerCnt">
        <img
          src={fileType?.icon}
          style={{ height: 60, width: 60, marginBottom: 20 }}
        />

        <CTypography className="title">{document?.title}</CTypography>
        <CTypography className="size">
          File Size {formatBytes(document?.meta?.size)}
        </CTypography>
        <CTypography className="statement">
          This file is not available to preview. Try downloading instead
        </CTypography>

        <CustomButton
          variant="contained"
          className="downloadBtn"
          sx={{ padding: '2px 16px' }}
          startIcon={<DownloadIcon2 style={{ color: 'white' }} />}
          onClick={downloadHandler}
          buttonLoader={loading}
          disabled={loading}
        >
          Download File
        </CustomButton>
      </div>
    </Paper>
  );
};

export default NotPreviewable;
