import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomButton from 'components/Button/CustomButton';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { TeamsEmptyStateCnt } from 'pages/teams/emptyState/emptyState.style';
import NoTeamsImg from 'public/assets/img/no-teams.svg';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
function TeamsEmptyState(props) {
  const { PAYWALL_LIMITS, FEATURES } = useCustomPermission();
  const { toggleDrawer } = props;
  return (
    <TeamsEmptyStateCnt>
      <div className="teamsEmptyStateInnerCnt">
        <img src={NoTeamsImg} alt="No Teams" />
        <h2>You have no teams yet</h2>
        <p></p>
        <p style={{ marginBottom: 24 }}>
          To get started, click on the <b>Create Team</b> button to set up your
          first team
        </p>
        <VerifyFeatureAccess
          paywallUsageId={PAYWALL_LIMITS.TEAMS_WALL}
          // If we want to add lock
          // lockIconProps={{
          //   sx: {
          //     display: 'inline-flex',
          //   },
          // }}
        >
          <CustomButton
            variant="contained"
            startIcon={<AddOutlinedIcon />}
            onClick={toggleDrawer}
          >
            Create Team
          </CustomButton>
        </VerifyFeatureAccess>
      </div>
    </TeamsEmptyStateCnt>
  );
}

export default TeamsEmptyState;
