// MUI
import { Stack, Typography } from '@mui/material';

// Icons
import TableAlternateIcon from 'components/Icons/tableAlternateIcon';
import ChecklistWithHandsAndTicks from 'components/Icons/checklistWithHandsAndTicks';

const EmptyState = () => {
  return (
    <Stack height="calc(100vh - 223px)" alignItems={'center'} pt={'100px'}>
      <ChecklistWithHandsAndTicks style={{ width: '160px', height: 'auto' }} />
      <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={18}
        fontWeight={700}
        color="#212121"
      >
        No submission found
      </Typography>
      <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={14}
        fontWeight={400}
        color="#616161"
        textAlign={'center'}
      >
        Template Submissions linked to this location or it’s sub-location(s)
        will appear here.
      </Typography>
    </Stack>
  );
};

export default EmptyState;
