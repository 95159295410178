import { useMemo, useRef } from 'react';

import {
  WidgetCnt,
  WidgetHeading,
} from 'pages/Dashboards/Widgets/widgets.style';
import WidgetActions from 'pages/Dashboards/Widgets/widgetActions';
import WidgetAdvanceFilter from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import { Stack, Typography } from '@mui/material';
import { NUMBER_WIDGET_VALUES } from './constants';
import { exportToPng } from 'utils/globalFunction';
import { useLoadWidget } from 'pages/Dashboards/Widgets/useLoadWidget';
import NumberWidgetEmptyState from './EmptyRecords/TaskListWidgetEmptyState.cmp';
import { CountCnt } from './numberWidget.style';
import { getRenderValue } from 'utils/widget';

interface ILineChartWidgetProps {
  widgetId: string;
  editable?: boolean;
  globalFilters?: any;
}

function NumberWidget({
  widgetId,
  editable,
  globalFilters,
}: ILineChartWidgetProps) {
  const chartRef: any = useRef(null);
  const { parsedData, getWidgetQuery, widget, summary } = useLoadWidget(
    widgetId,
    globalFilters,
  );

  const exportChartToImage = () => {
    exportToPng(chartRef.current, widget?.title);
  };
  const loading = getWidgetQuery.isFetching;
  const error = getWidgetQuery.error;

  const isPercentage = useMemo(() => {
    const pattern = /percentage|averageScore/;
    return pattern.test(widget?.config?.variable);
  }, [widget]);
  const isDuration = useMemo(() => {
    const pattern = /totalDuration|averageDuration/;
    return pattern.test(widget?.config?.variable);
  }, [widget]);

  return (
    <WidgetCnt ref={chartRef}>
      <WidgetHeading>
        <Stack
          direction="column"
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Typography className="title" title={widget?.title}>
            {widget?.title}
          </Typography>
          {summary?.total >= 0 && (
            <Typography className="count">{summary?.total}</Typography>
          )}
        </Stack>
        <div className={'widgetActionsCnt'}>
          <WidgetAdvanceFilter
            isCompactAction={true}
            widgetId={widgetId}
            globalFilters={globalFilters}
          />{' '}
          <WidgetActions
            isCompactAction={true}
            exportChartToImage={exportChartToImage}
            widgetId={widgetId}
            editable={editable}
          />
        </div>
      </WidgetHeading>{' '}
      <WithLoader isLoading={loading}>
        <WithErrorState
          type={'line'}
          error={error}
          reload={() => getWidgetQuery.refetch()}
        >
          {parsedData?.length ? (
            <CountCnt>
              <label className={'countsText'}>
                {getRenderValue(parsedData[0].valueY, isPercentage, isDuration)}
              </label>
              {!isPercentage ? (
                <label className={'detailText'}>
                  {NUMBER_WIDGET_VALUES[widget.entity]}
                </label>
              ) : null}
            </CountCnt>
          ) : (
            <NumberWidgetEmptyState />
          )}
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
}

export default NumberWidget;
