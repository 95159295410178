// MUI components
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Checkbox,
  AvatarGroup,
  Avatar,
  Chip,
} from '@mui/material';
import { colors } from 'components/theme/constants';

export const CellWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  gap: '5px',
}));
export const PassFailCellWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flex: 1,
}));
export const FlagIconCnt = styled(Box)(() => ({
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
}));

export const Row = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'gap',
})(({ gap }) => ({
  display: 'flex',
  gap: gap ? `${gap}px` : '0px',
  alignItems: 'center',
}));
export const NameRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'gap',
})(({ gap }) => ({
  display: 'flex',
  gap: gap ? `${gap}px` : '0px',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const Text = styled(Typography, {
  shouldForwardProp: (prop) => !['bold', 'width'].includes(prop),
})(({ bold, width }) => ({
  fontSize: 12,
  fontWeight: bold ? 600 : 500,
  letterSpacing: 0.17,
  color: 'rgba(0, 0, 0, 0.87)',
  ...(width && {
    width,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  '& .MuiCheckbox-colorSuccess.Mui-checked': {
    color: 'rgba(76, 175, 80, 1)',
  },
}));

export const AttachmentsWrapper = styled(AvatarGroup)(({ theme }) => ({
  '& .MuiAvatar-root': {
    height: 32,
    width: 32,
    borderRadius: 8,

    background: 'rgba(235, 234, 255, 1)',
    color: 'rgba(78, 72, 250, 1)',
    fontSize: 14,
    fontWeight: 700,
  },
}));

export const Attachment = styled(Avatar)(({ theme }) => ({}));

export const OpenChip = styled(Chip)(() => ({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  cursor: 'pointer',
  background: 'white',
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: 0.16,
  color: 'rgba(57, 34, 214, 1)',
  borderRadius: 16,
  border: '1px solid rgba(204, 203, 255, 1)',
  padding: '4px 12px',
  '& .MuiSvgIcon-root': {
    fontSize: '14px',
    color: 'rgba(57, 34, 214, 1)',
    marginLeft: '0px',
    marginRight: '0px',
    strokeWidth: '1.3px',
  },
  '& .MuiChip-label': {
    padding: '0px',
  },
}));

export const MCQChip = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  border: `1px solid ${color}`,
  borderRadius: 100,
  padding: '4px 10px',
  height: 26,

  '& > p': {
    fontWeight: 500,
    fontSize: 13,
    color,
    lineHeight: '16px',
  },
}));

export const FullWidthCellWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  cursor: 'pointer',
  backgroundColor: '#F0F0FF',
  borderLeft: '1px solid #e0e0e0',
  paddingLeft: 24,
  '& svg': {
    marginRight: 10,
    color: colors.white,
    background: colors.primary.main,
    borderRadius: '50%',
    padding: 3,
  },
  '& .content': {
    fontSize: 14,
    fontWeight: 500,
    color: colors.primary.main,
  },
}));

export const AddText = styled(Box)(() => ({
  fontWeight: 700,
  fontSize: 14,
  color: 'rgba(78, 72, 250, 1)',
  letterSpacing: 0.17,
}));
