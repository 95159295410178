// React
import { useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Styled components
import {
  HeaderWrapper,
  HeaderText,
  ActionItemsRow,
  ActionItemWrapper,
  ActionItemIconWrapper,
  ActionItemIcon,
  ActionItemTextWrapper,
  ActionItemHeading,
  ActionItemDescription,
  CloseIconButton,
  LoaderCnt,
} from './styled';

// Utilities
import { getHeaderItems } from './constants';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import DIALOGS from 'utils/dialogIds';
import { PERMISSIONS } from 'utils/constants';
import { HasPermission } from 'components/HasPermission';
import CloseIcon from '@mui/icons-material/Close';
import { getItem, setItem } from 'utils/globalFunction';
import { setDialog } from 'store/actions/dialogActions';
import { Box, CircularProgress } from '@mui/material';
import UploadTemplate from '../uploadTemplate';

const PageHeader = ({ setShowPageHeader }) => {
  const dispatch = useDispatch();

  const [activeActionItem, setActiveActionItem] = useState<null | number>(null);
  const [fileUploading, setFileUploading] = useState(false);

  const actionItems = getHeaderItems([
    () => {
      dispatch(
        setDialog({
          open: true,
          dialogId: DIALOGS?.CREATE_TEMPLATE_DIALOG,
        }),
      );
      setActiveActionItem(0);
    },
    () => {
      dispatch(
        actions.setDialog({ open: true, dialogId: DIALOGS.TEMPLATE_LIBRARY }),
      );
      setActiveActionItem(1);
    },
    () => {
      setActiveActionItem(2);
    },
  ]);
  const handleHideShowHeader = () => {
    const headerState = getItem('hideChecklistHeader');
    const headerStateValueParsed = headerState != 'true';
    setShowPageHeader(!headerStateValueParsed);
    setItem('hideChecklistHeader', headerStateValueParsed);
  };

  const manageFileUploadLoader = (data) => {
    setFileUploading(data);
  };

  return (
    <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_CHECKLIST]}>
      <HeaderWrapper>
        <ActionItemsRow>
          {actionItems.map((item, index) => {
            return (
              <ClickAwayListener
                onClickAway={() => {
                  if (activeActionItem !== null) setActiveActionItem(null);
                }}
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
              >
                {index === 2 ? (
                  <ActionItemWrapper
                    bgcolor={item.bgColor}
                    bordercolor={activeActionItem === index && item.borderColor}
                    onClick={item.onClick}
                    key={index}
                  >
                    <>
                      {fileUploading && (
                        <LoaderCnt>
                          <CircularProgress />
                        </LoaderCnt>
                      )}
                      <Box
                        style={{
                          ...(fileUploading && {
                            display: 'none',
                          }),
                        }}
                      >
                        <UploadTemplate manageLoader={manageFileUploadLoader}>
                          <div style={{ display: 'flex', gap: 10 }}>
                            <>
                              <ActionItemIconWrapper bgcolor={item.borderColor}>
                                <ActionItemIcon
                                  svgdimensions={item.icon.styles ?? null}
                                  bgcolor={item.icon.iconBackgroundColor}
                                >
                                  {<item.icon.component />}
                                </ActionItemIcon>
                              </ActionItemIconWrapper>

                              <ActionItemTextWrapper>
                                <ActionItemHeading>
                                  {item.heading}
                                </ActionItemHeading>
                                {item.descriptionRenderer ?? (
                                  <ActionItemDescription>
                                    {item.description}
                                  </ActionItemDescription>
                                )}
                              </ActionItemTextWrapper>
                            </>
                          </div>
                        </UploadTemplate>
                      </Box>
                    </>
                  </ActionItemWrapper>
                ) : (
                  <ActionItemWrapper
                    bgcolor={item.bgColor}
                    bordercolor={activeActionItem === index && item.borderColor}
                    onClick={item.onClick}
                    key={index}
                  >
                    <ActionItemIconWrapper bgcolor={item.borderColor}>
                      <ActionItemIcon
                        svgdimensions={item.icon.styles ?? null}
                        bgcolor={item.icon.iconBackgroundColor}
                      >
                        {<item.icon.component />}
                      </ActionItemIcon>
                    </ActionItemIconWrapper>

                    <ActionItemTextWrapper>
                      <ActionItemHeading>{item.heading}</ActionItemHeading>
                      {item.descriptionRenderer ?? (
                        <ActionItemDescription>
                          {item.description}
                        </ActionItemDescription>
                      )}
                    </ActionItemTextWrapper>
                  </ActionItemWrapper>
                )}
              </ClickAwayListener>
            );
          })}
        </ActionItemsRow>
        <CloseIconButton onClick={handleHideShowHeader}>
          <CloseIcon />
        </CloseIconButton>
      </HeaderWrapper>
    </HasPermission>
  );
};

export default PageHeader;
