import { createSvgIcon } from '@mui/material';

const FileSearchIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_530_34026"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
      >
        <rect fill="inherit" />
      </mask>
      <g>
        <path
          d="M11.6667 9.16675H6.66671M8.33337 12.5001H6.66671M13.3334 5.83341H6.66671M16.6667 8.75008V5.66675C16.6667 4.26662 16.6667 3.56655 16.3942 3.03177C16.1545 2.56137 15.7721 2.17892 15.3017 1.93923C14.7669 1.66675 14.0668 1.66675 12.6667 1.66675H7.33337C5.93324 1.66675 5.23318 1.66675 4.6984 1.93923C4.22799 2.17892 3.84554 2.56137 3.60586 3.03177C3.33337 3.56655 3.33337 4.26662 3.33337 5.66675V14.3334C3.33337 15.7335 3.33337 16.4336 3.60586 16.9684C3.84554 17.4388 4.22799 17.8212 4.6984 18.0609C5.23318 18.3334 5.93324 18.3334 7.33337 18.3334H9.58337M18.3334 18.3334L17.0834 17.0834M17.9167 15.0001C17.9167 16.6109 16.6109 17.9167 15 17.9167C13.3892 17.9167 12.0834 16.6109 12.0834 15.0001C12.0834 13.3893 13.3892 12.0834 15 12.0834C16.6109 12.0834 17.9167 13.3893 17.9167 15.0001Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </>,
    'FileSearchIcon',
  );
  return <IconCmp viewBox={'0 0 20 20'} {...props} />;
};
export default FileSearchIcon;
