import { createSvgIcon } from '@mui/material';

const QualityIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect y="0.548828" width="48" height="48" rx="8" fill="#D1C4E9" />
      <path
        opacity="0.4"
        d="M19.1799 28.5853L18.1665 36.2154L23.5196 33.0035C23.6942 32.8988 23.7815 32.8464 23.8747 32.8259C23.9571 32.8078 24.0425 32.8078 24.125 32.8259C24.2181 32.8464 24.3054 32.8988 24.48 33.0035L29.8332 36.2154L28.8204 28.582"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.1635 15.5055C29.3437 15.9413 29.6896 16.2877 30.125 16.4685L31.6521 17.1011C32.0879 17.2816 32.4341 17.6278 32.6146 18.0636C32.7951 18.4994 32.7951 18.9891 32.6146 19.4249L31.9826 20.9509C31.802 21.3869 31.8017 21.8771 31.9831 22.3129L32.6141 23.8384C32.7036 24.0543 32.7497 24.2856 32.7497 24.5193C32.7497 24.753 32.7037 24.9843 32.6143 25.2002C32.5249 25.4161 32.3938 25.6122 32.2286 25.7774C32.0633 25.9426 31.8671 26.0737 31.6512 26.163L30.1253 26.7951C29.6896 26.9753 29.3432 27.3211 29.1623 27.7566L28.5298 29.2837C28.3493 29.7195 28.0031 30.0658 27.5673 30.2463C27.1315 30.4268 26.6419 30.4268 26.2061 30.2463L24.6801 29.6142C24.2443 29.4341 23.7548 29.4345 23.3193 29.6152L21.7923 30.2469C21.3567 30.427 20.8675 30.4268 20.4321 30.2464C19.9966 30.0661 19.6506 29.7202 19.47 29.2849L18.8373 27.7574C18.6571 27.3216 18.3112 26.9752 17.8757 26.7944L16.3487 26.1618C15.9131 25.9814 15.567 25.6354 15.3864 25.1998C15.2058 24.7642 15.2056 24.2748 15.3857 23.8391L16.0178 22.3131C16.1978 21.8772 16.1975 21.3878 16.0168 20.9522L15.3856 19.424C15.2961 19.2082 15.25 18.9768 15.25 18.7431C15.25 18.5095 15.296 18.2781 15.3854 18.0622C15.4748 17.8463 15.6059 17.6502 15.7711 17.485C15.9364 17.3198 16.1325 17.1888 16.3484 17.0994L17.8744 16.4673C18.3098 16.2873 18.6559 15.9419 18.8369 15.5069L19.4694 13.9798C19.6499 13.544 19.9962 13.1977 20.4319 13.0172C20.8677 12.8367 21.3574 12.8367 21.7932 13.0172L23.3191 13.6493C23.7549 13.8294 24.2444 13.829 24.6799 13.6483L26.2076 13.0182C26.6433 12.8378 27.1329 12.8378 27.5686 13.0183C28.0043 13.1988 28.3505 13.5449 28.531 13.9806L29.1637 15.5082L29.1635 15.5055Z"
        fill="#F3E5F5"
        stroke="#512DA8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'QualityIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 48 49'} {...props} />;
};
export default QualityIcon;
