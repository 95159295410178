import { BuilderComponent } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { showMessageNotification } from 'utils/globalFunction';

import { uploadFile } from '@uploadcare/upload-client';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { uploadCarePublicKey } from 'utils/constants';

function TemplatesFileUpload({ onSuccessCallback, closeHandler }) {
  const { workspace } = useWorkspaceHook();
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const [builderContentJson, setBuilderContentJson] = useState({});
  const [uploading, setUploading] = useState(false);
  const [isLinked, setIsLinked] = useState(false);

  const uploadSuccessHandler = () => {
    onSuccessCallback?.();
  };

  const handleButtonClick = () => {
    const fileInput: any = document.getElementById('fileInput');
    fileInput?.click();
  };

  const linkButtonHandler = () => {
    const fileInput: any = document.getElementById('fileInput');
    fileInput?.addEventListener('change', async (event) => {
      if (uploading) return;
      setUploading(true);
      const selectedFile = fileInput?.files?.[0];

      const result = await uploadFile(selectedFile, {
        publicKey: uploadCarePublicKey,
        store: 'auto',
        metadata: {
          workspaceId: workspace?.Hotel?.id,
          workspaceName: workspace?.Hotel?.name,
          uploadedBy: userProfileData?.fullName,
          role: workspace?.title,
        },
      });
      setUploading(false);
      if (result?.cdnUrl) {
        uploadSuccessHandler();
      } else {
        showMessageNotification('Uploading Failed - Please try again', 'error');
      }
    });
  };

  useEffect(() => {
    window?.builder
      .get('file-upload-template', {
        url: location.pathname,
      })
      .promise()
      .then(setBuilderContentJson);
  }, []);

  useEffect(() => {
    if (builderContentJson && !isLinked) {
      setIsLinked(true);
      setTimeout(() => {
        linkButtonHandler();
      }, 2000);
    }
  }, [builderContentJson]);

  // Builder IO File Upload Template Component
  return (
    <BuilderComponent
      model="file-upload-template"
      content={builderContentJson}
      context={{
        handleButtonClick: handleButtonClick,
        closeHandler: closeHandler,
      }}
    />
  );
}
export default TemplatesFileUpload;
