import { Badge } from '@mui/material';
import styled from '@mui/system/styled';

export const FloatingBtn = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 15,
  right: 15,
}));

export const InputIconCnt = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

export const FilterBadge = styled(Badge)(() => ({
  cursor: 'pointer',
  '.MuiBadge-badge': {
    top: 6,
    right: 22,
  },
}));
