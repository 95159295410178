import IconButton from '@mui/material/IconButton';
import CTypography from 'components/CTypography';
import EditIcon from 'components/Icons/editIcon';
import {
  CellContentWrapper,
  TitleCnt,
} from 'pages/Assets/AssetsList/assetsList.styles';

export const defaultColDef = {
  lockVisible: true,
};

export const getRowStyle = (params) => {
  if (params.node.data.ParentId) {
    return { background: '#FAFAFA' };
  }
};
import {
  ActionColumnRenderer,
  AssetNameRenderer,
  LastServiceDate,
  LastServiceFormater,
  LocationName,
  NextServiceDate,
  OpenAssetBtnRenderer,
  PhotosAttachment,
} from 'pages/Assets/AssetsList/columnRenderers';

export const Columns = () => {
  return [
    {
      field: '',
      headerName: '',
      colId: 'openAssetAction',
      width: 120,
      suppressMenu: true,
      resizable: true,
      cellRenderer: (params) => cellRenderer.openAssetAction(params),
    },
    {
      headerName: 'Location',
      field: 'Location.name',
      colId: 'location',
      minWidth: 120,
      resizable: true,
      suppressMenu: true,
      cellRenderer: (params) => cellRenderer.location(params),
    },
    {
      headerName: 'Description',
      field: 'description',
      colId: 'description',
      minWidth: 140,
      resizable: true,
      suppressMenu: true,
      valueGetter: valueGetters?.description,
    },
    {
      field: 'attachment',
      headerName: 'Photos/Attachments',
      colId: 'attachment',
      resizable: true,
      minWidth: 210,
      valueGetter: (params) => valueGetters?.attachment(params),
      cellRenderer: (params) => cellRenderer?.attachment(params),
    },
    {
      field: 'NextService',
      headerName: 'Next Service Date',
      colId: 'NextService',
      resizable: true,
      minWidth: 400,
      valueGetter: (params) => valueGetters?.nextServiceDate(params),
      cellRenderer: (params) => cellRenderer?.nextServiceDate(params),
    },
    {
      field: 'LastService',
      headerName: 'Last Service Date',
      colId: 'LastService',
      resizable: true,
      minWidth: 210,
      valueGetter: (params) => valueGetters?.lastServiceDate(params),
      cellRenderer: (params) => cellRenderer?.lastServiceDate(params),
    },
    {
      field: 'LastService',
      headerName: 'Last Service',
      colId: 'LastService',
      resizable: true,
      minWidth: 210,
      valueGetter: (params) => valueGetters?.lastService(params),
      cellRenderer: (params) => cellRenderer?.lastService(params),
    },
    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      resizable: true,
      suppressMenu: true,
      minWidth: 190,
      cellRenderer: (params) => cellRenderer.actionColumn(params),
    },
  ];
};

const valueGetters = {
  description: (params) => {
    const { description } = params.data;
    return description || '-';
  },
  nextServiceDate: (params) => {
    const { NextService } = params?.data || {};
    return NextService?.date || '-';
  },
  lastServiceDate: (params) => {
    const { LastService } = params?.data || {};
    return LastService?.date || '-';
  },
  lastService: (params) => {
    const { LastService } = params?.data || {};
    return LastService?.date || '-';
  },
  attachment: (params) => {
    if (!params?.data) return;
    return params?.data?.attachment?.filter((url) =>
      /\.(jpe?g|png|gif)$/i.test(url),
    )?.length;
  },
};

export const cellRenderer = {
  title: (params) => {
    const { id, type, color, inProgress } = params.data;
    return (
      <TitleCnt inProgress={inProgress}>
        <CTypography className="rowContent">{type}</CTypography>
        <IconButton
          style={{
            marginLeft: 10,
            display: 'none',
            backgroundColor: 'transparent',
            padding: 0,
            marginBottom: 4,
          }}
          className="editIcon"
          onClick={() => {
            // onBtStartEditing(params);
          }}
        >
          <EditIcon style={{ fontSize: 15, color: '#8e8484' }} />
        </IconButton>
      </TitleCnt>
    );
  },
  nextServiceDate: (params) => {
    return !params?.data?.ParentId ? (
      <NextServiceDate asset={params?.data} />
    ) : (
      '-'
    );
  },
  lastServiceDate: (params) => {
    const { LastService } = params?.data || {};
    return <LastServiceDate lastService={LastService} />;
  },
  lastService: (params) => {
    const { LastService } = params?.data || {};
    return <LastServiceFormater lastService={LastService} />;
  },
  attachment: (params) => {
    return (
      <CellContentWrapper data-attribute="cell-click" style={{ width: '100%' }}>
        <PhotosAttachment asset={params.data} />
      </CellContentWrapper>
    );
  },
  location: (params) => {
    const { Location } = params.data;
    return Location ? <LocationName location={Location} /> : '-';
  },
  name: (params) => {
    return <AssetNameRenderer params={params} />;
  },
  openAssetAction: (params) => {
    return <OpenAssetBtnRenderer params={params} />;
  },
  actionColumn: (params) => {
    return <ActionColumnRenderer params={params} />;
  },
};
export const AssetsNameColumn = {
  headerName: 'Name',
  field: 'name',
  checkboxSelection: true,
  headerCheckboxSelection: true,
  suppressMovable: true,
  suppressMenu: true,
  minWidth: 400,
  colId: 'name',
  sortable: true,
  sortChildren: true,
  comparator: (a, b) => {
    return a?.toLowerCase().localeCompare(b?.toLowerCase(), undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  },
  cellStyle: (params) => {
    return params.data.ParentId ? { marginLeft: 30 } : {};
  },
  cellRenderer: cellRenderer.name,
  cellRendererParams: {
    suppressCount: true,
  },
};
