// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ViewWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 0px 0px 24px',
});

export const BillingCardWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));
