import { useEffect, useState } from 'react';
import { BuilderComponent } from '@builder.io/react';

const AuthScreenInfo = () => {
  const [builderContentJson, setBuilderContentJson] = useState({});
  useEffect(() => {
    window?.builder
      .get('login-screen-section', {
        url: location.pathname,
      })
      .promise()
      .then(setBuilderContentJson);
  }, []);

  return (
    <BuilderComponent
      model="login-screen-section"
      content={builderContentJson}
    />
  );
};

export default AuthScreenInfo;
