import React from 'react';
import { Stack, Typography } from '@mui/material';

interface ChipProps {
  text: string;
}

const Chip: React.FC<ChipProps> = (props) => {
  const { text } = props;
  return (
    <Stack p="3px 8px" bgcolor={'#F5F5F5'} borderRadius={'62px'}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: 11,
          color: '#000000',
          whiteSpace: 'pre',
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default Chip;
