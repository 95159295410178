import TableAlternateIcon from 'components/Icons/tableAlternateIcon';

import {
  WrapperDiv,
  IconWrapper,
  NoResultsTitle,
  NoResultsDescriptionWrapper,
  NoResultsDescription,
  Button,
} from './styled';

import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const EmptyState = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  return (
    <WrapperDiv>
      <IconWrapper>
        <TableAlternateIcon />
      </IconWrapper>
      <NoResultsTitle>No submissions available yet</NoResultsTitle>
      <NoResultsDescriptionWrapper>
        <NoResultsDescription>
          Once submissions are completed, they can be accessed from this screen.
          From here, you can jump between different operation templates and
          access all submission records.
        </NoResultsDescription>
      </NoResultsDescriptionWrapper>
      <Button
        onClick={() => navigateWithWorkspaceUrl('/checklist?view=grid')}
        variant="contained"
      >
        Go to Operation Templates
      </Button>
    </WrapperDiv>
  );
};

export default EmptyState;
