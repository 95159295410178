import { useState, useEffect, useMemo, Fragment } from 'react';

import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import GridView from 'pages/Schedules/common/GridView';
import SearchAndToggleHeader from '../common/SearchAndToggleHeader';
import EmptyState from './EmptyState';
import SchedulesTable from 'components/DataTables/SchedulesTable';

// MUI
import { Stack } from '@mui/material';
import { CircularProgress } from '@mui/material';

// Utilities
import { ViewType } from 'components/ListGridToggle/ListGridToggle';

// Hooks
import { useUserConfig } from 'controller/useUserConfig';

// Utils
import { getParentSchedules } from 'api/schedulesApi';
import DIALOGS from 'utils/dialogIds';
import useAssetDetail from '../useAssetDetail';
import useResponsive from 'utils/CustomHooks/useResponsive';

const AssetsSchedules = ({
  fetchSchedulesCounter,
  loadTabsData,
  setLoadTabsData,
}) => {
  const { selectedAsset } = useAssetDetail();
  const dispatch = useDispatch();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();

  const [selectedView, setSelectedView] = useState<ViewType | null>(null);
  const [searchText, setSearchText] = useState('');
  const [fetching, setFetching] = useState(true);
  const [configKey, setConfigKey] = useState<any>(null);
  const [schedules, setSchedules] = useState<any>(null);
  const [tableConfig, setTableConfig] = useState<any>(null);

  const { isMobileDeviceSm } = useResponsive();

  useEffect(() => {
    if (loadTabsData?.scheduling) {
      fetchSchedules();
    } else {
      setLoadTabsData({
        ...loadTabsData,
        scheduling: true,
      });
    }
  }, [fetchSchedulesCounter, selectedAsset]);

  useEffect(() => {
    if (isMobileDeviceSm) {
      setSelectedView('grid');
    }
  }, [isMobileDeviceSm]);

  const filteredSchedules = useMemo(() => {
    if (!searchText) return schedules;
    return schedules.filter((s: any) =>
      s.title.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [schedules, searchText]);

  const setAssetSchedulesViewType = async () => {
    const userConfig = await getUserConfig(configKey);

    if (isMobileDeviceSm) {
      setSelectedView('grid');
      return;
    }

    if (userConfig?.assetSchedulesViewType) {
      setSelectedView(userConfig.assetSchedulesViewType);
    } else {
      setSelectedView('list');
    }
  };

  const setToggleStateConfig = async (value: ViewType) => {
    const prevView = selectedView;
    setSelectedView(value);
    const config = await editUserConfig(configKey, {
      assetSchedulesViewType: value,
    });
    if (!config) {
      setSelectedView(prevView);
    }
  };

  const handleClickTableRow = (taskId) => {
    const task = schedules.find((s: any) => s.id === taskId);
    handleClickTask(task);
  };

  const fetchTableConfig = async () => {
    const config = await getUserConfig(`assetSchedulesTableConfig:${userId}`);
    if (config) {
      const colConfig = config;
      setTableConfig(colConfig);
    }
  };

  const updateColumns = async (columnState: any[]) => {
    const previousTableConfig = tableConfig || [];
    setTableConfig(columnState);
    const updatedConfig = await editUserConfig(
      `assetSchedulesTableConfig:${userId}`,
      columnState,
    );
    if (!updatedConfig) {
      setTableConfig(previousTableConfig);
    }
  };

  const fetchSchedules = async () => {
    setFetching(true);
    const data = await getParentSchedules({
      advanceFilters: {
        condition: 'AND',
        filters: [
          {
            comparator: 'is',
            filterName: 'assets',
            value: [selectedAsset?.id],
          },
        ],
      },
      offset: 0,
      limit: 9999999999,
    });
    if (data) {
      setSchedules(data.rows);
    } else {
      setSchedules([]);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchTableConfig();
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      setConfigKey(`assetSchedulesViewType::${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    setAssetSchedulesViewType();
  }, [configKey]);

  const handleClickTask = (task: any) => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TASK_SCHEDULES_DRAWER,
        open: true,
        data: {
          task,
          onEditCallback: () => {
            fetchSchedules();
            dispatch(actions.closeDialog(DIALOGS.TASK_SCHEDULES_DRAWER));
          },
        },
      }),
    );
  };

  return (
    <Fragment>
      {fetching && (
        <Stack
          width={'100%'}
          height="calc(100vh - 223px)"
          alignItems={'center'}
          pt="100px"
        >
          <CircularProgress />
        </Stack>
      )}
      {!fetching && schedules?.length === 0 && <EmptyState />}

      {!fetching && schedules?.length > 0 && selectedView && (
        <Stack gap={'16px'}>
          <SearchAndToggleHeader
            selectedView={selectedView}
            setSelectedView={setToggleStateConfig}
            handleSearchInput={setSearchText}
          />
          {selectedView === 'grid' && filteredSchedules?.length > 0 && (
            <Stack height={'calc(100vh - 279px)'}>
              <GridView
                tasks={filteredSchedules}
                totalCount={filteredSchedules?.length}
                height={window.innerHeight - 279}
                onClickTask={handleClickTask}
              />
            </Stack>
          )}
          {selectedView === 'grid' && filteredSchedules?.length === 0 && (
            <EmptyState />
          )}
          {selectedView === 'list' && (
            <Stack height={'calc(100vh - 279px)'}>
              <SchedulesTable
                rowData={filteredSchedules}
                onRowClick={handleClickTableRow}
                updateColumnStateCb={updateColumns}
                columnState={tableConfig}
                isSizeColumnFit={!tableConfig}
                columnConfig={{
                  title: true,
                  Location: true,
                  recurringByEvery: true,
                  Creator: true,
                  assignedTo: true,
                  NextDue: true,
                }}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Fragment>
  );
};

export default AssetsSchedules;
