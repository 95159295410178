import Box from '@mui/material/Box';
import xeniaApi from 'api/index';
import UserAvatar from 'components/Avatar';
import Timeline from 'components/common/jsxrender/timeline';
import TimeLogIcon from 'components/Icons/timelogIcon';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import {
  ActivityInfoDescription,
  ActivityInfoText,
  ActivityPerformerName,
  AttachmentsContainer,
  Container,
  StatusColor,
} from './styled';
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';
import { taskActivityRequest } from 'api/requests';
import useDateTime from 'utils/CustomHooks/useDateTime';

interface ITimeline {
  date: string;
  dateProps?: any;
  data: ITimelineData[];
}

interface ITimelineData {
  customRenderer?: ReactNode;
  text?: string;
  time?: string;
}

interface IUser {
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  photo: string;
  name: string;
}

interface IActivity {
  action: ActivityActionEnum;
  by: IUser;
  on: string;
  type: ActivityTypeEnum;

  attribute?: string;
  data?: {
    attachments: string[];
    count: number;
    secondsSpent: number;
  };
  from?: string | IUser;
  to?: string | IUser;
}

enum ActivityActionEnum {
  Created = 'created',
  Added = 'added',
  Assigned = 'assigned',
  UnAssigned = 'un-assigned',
  Updated = 'updated',
  Removed = 'removed',
  ReAssigned = 're-assigned',
  Started = 'started',
  Paused = 'paused',
  Resumed = 'resumed',
  Finished = 'finished',
}

enum ActivityTypeEnum {
  NewTask = 'newTask',
  NewRequest = 'newRequest',
  GeneralChange = 'generalChange',
  AttachmentChange = 'attachmentChange',
  AssigneeChange = 'assigneeChange',
  PriorityChange = 'priorityChange',
  StatusChange = 'statusChange',
  TimeLog = 'timeLog',
  ScheduleChange = 'scheduleChange',
  MarkOverDue = 'markOverDue',
}

const RequestActivityTimeline = ({ currentRequest }) => {
  const [timelines, setTimelines] = useState<ITimeline[]>([]);
  const [activitiesRaw, setActivitiesRaw] = useState<IActivity[]>([]);
  const { getUtcToTz } = useDateTime();

  const getRequestsActivityData = async (id) => {
    const resp = await xeniaApi.taskActivityRequest(id);
    if (resp?.data)
      setActivitiesRaw(
        resp.data.sort((a, b) => {
          return new Date(b.on).getTime() - new Date(a.on).getTime();
        }),
      );
  };

  useEffect(() => {
    getRequestsActivityData(currentRequest?.id);
  }, [currentRequest]);

  useEffect(() => {
    const groupedData: { [key: string]: IActivity[] } = activitiesRaw.reduce(
      (acc, curr) => {
        const date = getUtcToTz(curr.on, 'MMM DD YYYY');
        if (!acc[date]) {
          acc[date] = [curr];
        } else {
          acc[date].push(curr);
        }
        return acc;
      },
      {},
    );

    const mappedTimelines: ITimeline[] = [];
    for (const key in groupedData) {
      mappedTimelines.push({
        date: key,
        data: groupedData[key].map((item) => generateNodeForEachCase(item)),
      });
    }

    setTimelines(mappedTimelines);
  }, [activitiesRaw]);

  const convertTime = (timeString) => {
    const date = getUtcToTz(timeString, 'hh:mm A');
    return date;
  };

  const secondsToTimeSpentString = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secs = duration.seconds();
    let result = '';

    result += hours ? `${hours}h ` : '';
    result += minutes ? `${minutes}m ` : '';
    result += `${secs}s`;

    return result.trim();
  };

  const generateNodeForEachCase = (activity: IActivity): ITimelineData => {
    let renderer: ReactNode = <></>;
    let additionalInfoContainer: ReactNode = <></>;
    switch (activity.type) {
      case ActivityTypeEnum.NewTask:
        renderer = <>{activity.action} this task</>;
        break;
      case ActivityTypeEnum.NewRequest:
        renderer = <>{activity.action} this request</>;
        break;
      case ActivityTypeEnum.GeneralChange:
        renderer = (
          <>
            {activity.action}
            <b>&nbsp;{activity?.attribute}</b>
          </>
        );
        break;
      case ActivityTypeEnum.AssigneeChange:
        renderer = (
          <>
            <ActivityInfoText>
              {activity.action} this task {activity?.to ? 'to' : 'from'}
            </ActivityInfoText>
            <UserAvatar
              sx={{ ml: 1 }}
              style={{ marginLeft: '8px', marginRight: '8px' }}
              width={28}
              height={28}
              userId={(activity[activity?.to ? 'to' : 'from'] as IUser)?.id}
              firstName={
                (activity[activity?.to ? 'to' : 'from'] as IUser)?.firstName ||
                (activity[activity?.to ? 'to' : 'from'] as IUser)?.name
              }
              profilePic={
                (activity[activity?.to ? 'to' : 'from'] as IUser)?.photo ||
                '@@#6868FE'
              }
            />
            <ActivityPerformerName sx={{ ml: 1 }}>
              {(activity[activity?.to ? 'to' : 'from'] as IUser)?.fullName ||
                (activity[activity?.to ? 'to' : 'from'] as IUser)?.name}
            </ActivityPerformerName>
          </>
        );
        break;
      case ActivityTypeEnum.AttachmentChange:
        renderer = (
          <>
            {activity.action} {activity?.data?.count} images
          </>
        );
        additionalInfoContainer = (
          <>
            <AttachmentsContainer>
              <RenderAttachments
                style={{ flexWrap: 'wrap' }}
                attachments={activity?.data?.attachments}
              />
            </AttachmentsContainer>
          </>
        );
        break;
      case ActivityTypeEnum.ScheduleChange:
        renderer = (
          <>
            edited the schedule from {activity.from} to {activity.to}
          </>
        );
        break;
      case ActivityTypeEnum.MarkOverDue:
        renderer = <>Work Order is marked overdue</>;
        break;
      case ActivityTypeEnum.StatusChange:
        renderer = <>{activity?.to} the status</>;
        break;
      case ActivityTypeEnum.PriorityChange:
        renderer = (
          <>
            <span>
              changed the status from <b>{activity?.from?.toString()}</b> to{' '}
              <b>{activity?.to?.toString()}</b>
            </span>
          </>
        );
        break;
      case ActivityTypeEnum.TimeLog:
        renderer = (
          <>
            {activity.action} timer <TimeLogIcon />{' '}
            {activity?.data?.secondsSpent && (
              <b>{secondsToTimeSpentString(activity.data.secondsSpent)}</b>
            )}
          </>
        );
        break;
      default:
        break;
    }

    return {
      customRenderer: (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              {activity?.by && (
                <UserAvatar
                  width={28}
                  height={28}
                  userId={activity.by?.id}
                  firstName={activity.by?.firstName}
                  profilePic={activity.by?.photo}
                />
              )}
              <ActivityInfoDescription>
                <ActivityPerformerName>
                  {activity?.by?.fullName}
                </ActivityPerformerName>
                {renderer}
              </ActivityInfoDescription>
            </Box>
            {additionalInfoContainer}
          </Box>
        </>
      ),
      time: convertTime(activity.on),
    };
  };

  return (
    <>
      <Container className={'activityContainer'}>
        {timelines.map((timeline, index) => (
          <>
            <Timeline key={index} timeline={timeline} />
          </>
        ))}
      </Container>
    </>
  );
};

export default RequestActivityTimeline;
