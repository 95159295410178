import { useEffect, useMemo } from 'react';
import LocationsSearchInput from 'pages/Locations/Header/LocationsSearchInput/locationsSearchInput';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { LocationsHeaderCnt } from 'pages/Locations/Header/locationHeader.style';
import ListGridToggle from 'components/ListGridToggle';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import { Stack, Divider } from '@mui/material';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import { useSelector } from 'react-redux';
import { getLocationsLevelsState } from 'store/selectors/locationsLevels';

interface Props {
  selectedView: ViewType;
  setSelectedView: any;
  onSearch?: (value: string) => void;
  onClickAdd: (data?: any) => void;
}

function LocationsHeader({
  selectedView,
  setSelectedView,
  onSearch,
  onClickAdd,
}: Props) {
  const locationsLevels = useSelector(getLocationsLevelsState);

  useEffect(() => {
    onSearch?.('');
  }, []);

  const handleOptionSelect = (option) => {
    onClickAdd({
      levelId: option?.id,
    });
  };

  const options = useMemo(() => {
    return (locationsLevels || []).map((level) => ({
      label: level?.title,
      id: level?.id,
    }));
  }, [locationsLevels]);

  return (
    <LocationsHeaderCnt>
      <LocationsSearchInput onSearch={onSearch} />
      <Stack direction="row" alignItems={'center'} gap={'12px'}>
        <ListGridToggle
          selectedView={selectedView}
          setSelectedView={(view) => setSelectedView(view)}
        />
        <Divider orientation="vertical" sx={{ height: '30px' }} />
        {options?.length > 0 ? (
          <ActionDropdown
            rootProps={{
              id: 'folders-action-dropdown',
              className: 'folders-action-dropdown',
            }}
            options={options}
            handleOptionSelect={handleOptionSelect}
            iconButton={false}
            buttonProps={{
              startIcon: <AddIcon style={{ color: 'white', fontSize: 22 }} />,
              variant: 'contained',
              style: {
                padding: '7px 16px',
                borderRadius: '8px',
                fontWeight: 600,
              },
            }}
            popperProps={{
              placement: 'bottom-start',
              sx: {
                zIndex: 1301,
              },
            }}
          >
            Add Location
          </ActionDropdown>
        ) : (
          <CustomButton
            variant="contained"
            startIcon={<AddIcon style={{ color: 'white', fontSize: 22 }} />}
            style={{
              padding: '7px 16px',
              borderRadius: '8px',
              fontWeight: 600,
            }}
            onClick={onClickAdd}
          >
            Add Location
          </CustomButton>
        )}
      </Stack>
    </LocationsHeaderCnt>
  );
}

export default LocationsHeader;
