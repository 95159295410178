import { TaskOverviewHeaderCnt } from 'pages/ReportingV2/Reports/CompletedWork/CompletedWorkHeader/completedWorkHeader.style';
import { CompletedWorkContext } from 'pages/ReportingV2/Reports/CompletedWork/context/completedWorkContext';
import CategoryFilterDropdown from 'pages/Widgets/TaskFilters/CategoryFilter/categoryFilter';
import OverdueFilterDropdown from 'pages/Widgets/TaskFilters/OverDueFilter/overdueFilter';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { IconButton } from '@mui/material';
import Tooltip from 'components/Tooltip';
import useTaskReportFilters from 'pages/ReportingV2/Reports/useTaskReportFilters';
import LocationFilterDropdown from 'pages/Widgets/TaskFilters/LocationFilter/locationFilters';
import useResponsive from 'utils/CustomHooks/useResponsive';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import {
  dateRangePickerSideMenuCommon,
  reportingInitialRange,
} from 'utils/datePicker';
function CompletedWorkHeader() {
  const {
    isFilterApplied,
    handleClearDateFilter,
    handleClearFilter,
    handleFilterChange,
    handleOverdueFilterChange,
    handleDateChange,
    selectedOverdueFilter,
    selectTaskCategories,
    selectedLocations,
    clearAllFilters,
    state,
  } = useTaskReportFilters(CompletedWorkContext);

  const { isMobileDeviceSm } = useResponsive();

  return (
    <TaskOverviewHeaderCnt>
      <div className="taskOverviewHeaderLeft">
        <h3 className={'headingText'}>Completed Work Report</h3>

        <DateRangePickerCommon
          clearProps={{
            dateLabel: 'Last 30 Days',
            onClick: handleClearDateFilter,
            initialRange: reportingInitialRange,
            showClearBtn: state?.selectedDateLabel != 'Last 30 Days',
          }}
          buttonProps={{
            hideSelected: true,
          }}
          handleDateChange={handleDateChange}
          selectedDateLabel={state?.selectedDateLabel}
          showLabelPrefix="Date Range: "
          selectedDateRange={{
            from: state?.filters?.fromDate,
            to: state?.filters?.toDate,
          }}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
          popperProps={{
            placement: 'bottom-start',
          }}
        />
      </div>
      <div className="taskOverviewHeaderRight">
        {/*<StatusFilterDropdown*/}
        {/*  selected={selectedStatus}*/}
        {/*  onChangeCallback={(option) =>*/}
        {/*    handleFilterChange(option, 'taskStatuses')*/}
        {/*  }*/}
        {/*  onClearCallback={() => handleClearFilter('taskStatuses')}*/}
        {/*/>*/}
        <CategoryFilterDropdown
          selected={selectTaskCategories}
          onChangeCallback={(option) =>
            handleFilterChange(option, 'categories')
          }
          onClearCallback={() => handleClearFilter('categories')}
        />
        <LocationFilterDropdown
          selected={selectedLocations}
          onChangeCallback={(option) => handleFilterChange(option, 'locations')}
          onClearCallback={() => handleClearFilter('locations')}
        />
        <OverdueFilterDropdown
          selected={selectedOverdueFilter}
          onChangeCallback={handleOverdueFilterChange}
          onClearCallback={() => handleClearFilter('dueFilter')}
        />
        {isFilterApplied ? (
          <Tooltip title="Clear All Filters">
            <IconButton onClick={clearAllFilters}>
              <FilterAltOffIcon sx={{ color: '#EF5350' }} />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </TaskOverviewHeaderCnt>
  );
}

export default CompletedWorkHeader;
