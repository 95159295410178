import { useState, useEffect, Children, useMemo } from 'react';
import { Responsive } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import { GRIDS_LAYOUTS } from 'utils/constants';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TileCnt } from './style';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './style.css';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { THRESHOLD } from './utils';

const CustomTilesGrid = (props) => {
  const {
    isDraggable,
    isResizeable,
    children,
    customLayout,
    onChangeCB,
    id,
    draggableHandle,
  } = props;

  const [layouts, setLayouts] = useState(customLayout);
  const [mounted, setMounted] = useState(false);
  const userMetaInfo: any = useSelector(selectors.getUserMeta);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setLayouts(customLayout);
  }, [customLayout]);

  const onLayoutChange = (layout, _) => {
    if (mounted) {
      const postObj = {
        ...userMetaInfo,
        gridsLayouts: {
          ...userMetaInfo?.gridsLayouts,
          [GRIDS_LAYOUTS['SUMMARY_LAYOUT']]: layout,
        },
      };
      onChangeCB(postObj);
    }
    setLayouts(layout);
  };

  const transformedLayouts = useMemo(() => {
    if (layouts) {
      return layouts?.map((item) => {
        return {
          ...item,
          ...THRESHOLD,
        };
      });
    }
    return [];
  }, [layouts]);

  return (
    <div className="mb-4">
      <Responsive
        {...props}
        layouts={transformedLayouts}
        measureBeforeMount={false}
        useCSSTransforms={mounted}
        onLayoutChange={onLayoutChange}
        isDroppable
        isResizable={isResizeable}
        isDraggable={isDraggable}
        width={props.size.width as number}
        resizeHandles={['e', 's', 'se']}
        draggableHandle={draggableHandle ? '.dragIcon' : undefined}
      >
        {Children.map(children, (child, index) => {
          if (!child?.key) return null;

          return (
            <TileCnt key={child?.key} data-grid={layouts?.[index]}>
              <DragIndicatorIcon className="dragIcon" />
              {child}
            </TileCnt>
          );
        })}
      </Responsive>
    </div>
  );
};

export default withSize({ refreshMode: 'debounce', refreshRate: 60 })(
  CustomTilesGrid,
);

CustomTilesGrid.defaultProps = {
  className: 'layout',
  rowHeight: 30,
  cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
  containerPadding: [0, 0],
  isDraggable: true,
  isResizeable: true,
};
