import React, { FC, useEffect, useState } from 'react';
import {
  Header,
  MainCnt,
  Main,
  TemplateRow,
  Content,
  MyRequestsEmptyStateCnt,
} from './style';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';
import FileSearchIcon from 'components/Icons/fileSearchIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getMyRequests, getRequests } from 'api/requests';
import { requestIcons } from 'pages/Requests/RequestListing/RequestListingItem';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import RequestEmptyStateImg from 'public/assets/img/requestsEmptyStateImage.svg';

const stopPropagation = (event) => {
  event.stopPropagation();
};

const MyRequestsReport: FC = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [submissionListing, setSubmissionListing] = useState([]);
  const userProfileData: any = useSelector(selectors.getUserProfile);

  const fetchMyRequestsList = async () => {
    const mySubmissionList = userProfileData?.isAdmin
      ? await getRequests()
      : await getMyRequests(userProfileData?.id);
    if (mySubmissionList) {
      setSubmissionListing(mySubmissionList);
    }
  };
  useEffect(() => {
    fetchMyRequestsList();
  }, []);
  const handleNavigateToRequest = (e, requestId) => {
    navigateWithWorkspaceUrl(`/requests/${requestId}`);
  };
  const isContent = submissionListing?.length > 0 ? true : false;

  return (
    <Main>
      {!isContent && (
        <MainCnt>
          <Header>
            <CTypography className="title">My Requests</CTypography>
            <CustomButton
              variant="contained"
              startIcon={<FileSearchIcon style={{ fill: 'none' }} />}
              onClick={() => navigateWithWorkspaceUrl('/requests')}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
            >
              Go to My Requests
            </CustomButton>
          </Header>
          <hr />
          <MyRequestsEmptyStateCnt>
            <img
              src={RequestEmptyStateImg}
              alt="Empty State"
              width={100}
              height={100}
            />
            <h3>No Requests yet</h3>
          </MyRequestsEmptyStateCnt>
        </MainCnt>
      )}
      {/*{!isContent && (*/}
      {/*  <InviteandBoost>*/}
      {/*    <div className="box">*/}
      {/*      <CTypography className="title">*/}
      {/*        Get started fast with a ready-made operations template*/}
      {/*      </CTypography>*/}
      {/*      <hr />*/}
      {/*      <CTypography className="subtitle">*/}
      {/*        Set up your team for success in record time with templates for*/}
      {/*        operations, submissions, inspections, SOPs and more.*/}
      {/*      </CTypography>*/}
      {/*      <BoostImg src={BoostDashboard} />*/}
      {/*      <CustomButton*/}
      {/*        variant="contained"*/}
      {/*        type="submit"*/}
      {/*        startIcon={<img src={ExploreTemplate} />}*/}
      {/*        onMouseDown={stopPropagation}*/}
      {/*        onTouchStart={stopPropagation}*/}
      {/*        onClick={() => {*/}
      {/*          dispatch(*/}
      {/*            setDialog({*/}
      {/*              dialogId: DIALOGS.TEMPLATE_LIBRARY,*/}
      {/*              open: true,*/}
      {/*            }),*/}
      {/*          );*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        {'Explore templates'}*/}
      {/*      </CustomButton>*/}
      {/*    </div>*/}
      {/*  </InviteandBoost>*/}
      {/*)}*/}

      {isContent && (
        <MainCnt>
          <Header>
            <CTypography className="title">My Requests</CTypography>
            <CustomButton
              variant="contained"
              startIcon={<FileSearchIcon style={{ fill: 'none' }} />}
              onClick={() => navigateWithWorkspaceUrl('/requests')}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
            >
              Go to My Requests
            </CustomButton>
          </Header>
          <hr />

          {/* Rows Content */}
          <Content>
            {submissionListing?.map((row: any) => {
              const { color, background, icon, border } =
                requestIcons[row?.status] || {};
              return (
                <TemplateRow
                  key={row?.id}
                  onMouseDown={stopPropagation}
                  onTouchStart={stopPropagation}
                  onClick={(event) => handleNavigateToRequest(event, row?.id)}
                >
                  <div className="firstColumn">
                    <CTypography className="label">{row.title}</CTypography>
                  </div>
                  <div className="secondColumn">
                    <div
                      className="requestStatus"
                      style={{
                        border: `1px solid ${border}`,
                        background: background,
                      }}
                    >
                      <span className={'statusIcon'} style={{ color }}>
                        {icon}
                      </span>
                      <span className="statusText" style={{ color }}>
                        {row?.status}
                      </span>
                    </div>
                  </div>
                </TemplateRow>
              );
            })}
          </Content>
        </MainCnt>
      )}
    </Main>
  );
};

export default MyRequestsReport;
