import {
  ErrorStateCnt,
  ErrorStateHeading,
  ErrorStateText,
} from './errorState.style';
import React from 'react';
import BarChartIconFilled from 'components/Icons/barChartIconFilled';
import LineChartIconFilled from 'components/Icons/lineChartIconFilled';
import PieChartIconFilled from 'components/Icons/pieChartIconFilled';
import TableWidgetIconFilled from 'components/Icons/tableWidgetIconFilled';
import CustomButton from 'components/Button/CustomButton';

const ChartIcons = {
  bar: BarChartIconFilled,
  line: LineChartIconFilled,
  pie: PieChartIconFilled,
  table: TableWidgetIconFilled,
};
interface IWithErrorProps {
  children: React.ReactNode;
  type: 'bar' | 'line' | 'pie' | 'table';
  error: any;
  reload: () => void;
}
const WithErrorState: React.FC<IWithErrorProps> = ({
  children,
  type,
  error,
  reload,
}) => {
  const IconComponent = ChartIcons[type];
  const handleReloadWidget = () => {
    reload();
  };
  if (error) {
    return (
      <ErrorStateCnt>
        <IconComponent />
        <ErrorStateHeading>Uh-Oh, Something went wrong</ErrorStateHeading>
        <ErrorStateText>
          We're having trouble loading the data for this chart.
          <br />
          Please click on reload button.
        </ErrorStateText>
        <CustomButton variant={'outlined'} onClick={handleReloadWidget}>
          Reload Chart
        </CustomButton>
      </ErrorStateCnt>
    );
  }
  return <>{children}</>;
};

export default WithErrorState;
