import styled from '@mui/system/styled';

export const AssetDetailHeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    display: 'unset',
    justifyContent: 'unset',
    alignItems: 'unset',
    '& .createWO': {
      marginTop: 10,
    },
  },

  [theme.breakpoints.down('sm')]: {
    display: 'unset',
    justifyContent: 'unset',
    alignItems: 'unset',
    '& .createWO': {
      marginTop: 0,
      width: '98%',
    },
  },
}));

export const AssetName = styled('span', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  fontSize: 20,
  color: '#000000DE',
  fontWeight: 400,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 400,
  display: 'block',
  textAlign: 'left',

  [theme.breakpoints.down('xl')]: {
    maxWidth: 250,
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 130,
  },

  ...(selected && { fontWeight: 700 }),
  ...(!selected && { cursor: 'pointer' }),
  ...(!selected && {
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
}));
export const AssetsHeading = styled('span', {
  shouldForwardProp: (prop) => prop !== 'clickable',
})(({ theme, clickable }) => ({
  fontSize: 20,
  fontWeight: 400,
  color: '#000000DE',

  ...(!clickable && {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
}));
export const BreadcrumbHeadingCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',

  [theme.breakpoints.down('sm')]: {
    marginBottom: 10,
  },
  '.rightArrowIcon': {
    margin: '0 4px',
    fontSize: 18,
  },
  '& button': {
    padding: 0,
    marginRight: 13,
    '& svg': {
      fontSize: 18,
    },
  },
}));
