import AddTaskWorkOrderIcon from 'components/Icons/addTaskWorkorderIcon';
import DeleteIcon from 'components/Icons/deleteIcon';
import {
  BulkActionSelection,
  BulkActionCnt,
  BulkActionItem,
  BulkActionCancelItem,
} from 'pages/Locations/LocationsList/BulkActions/locationsBulkAction.style';
import CloseIcon from '@mui/icons-material/Close';
import useUpdateLocation from 'utils/CustomHooks/useUpdateLocation';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
function LocationsBulkAction({ selected, onClearSelection }) {
  const dispatch = useDispatch();
  const { fetchAuthLocations } = useLocationsUtils();

  const { deleteLocation } = useUpdateLocation();
  const handleDeleteLocations = () => {
    const locationIds = selected?.map((l) => l?.data?.id);
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Delete Location',
          description:
            locationIds?.length == 1
              ? 'Are you sure you want to delete this Location?'
              : 'Are you sure you want to delete the selected Locations?',
          onConfirmCallback: () =>
            deleteLocation(locationIds).then(() => {
              onClearSelection();
              fetchAuthLocations();
            }),
          confirmButtonProps: { color: 'error' },
          confirmationText: 'Delete',
        },
      }),
    );
  };
  const handleCreateTask = () => {
    const selectedLocationIds = selected?.map((l) => l?.data?.id);
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          dialogState: {
            viewLess: false,
          },
          taskData: {
            locationIds: selectedLocationIds,
          },
        },
      }),
    );
  };
  return (
    <BulkActionCnt>
      <BulkActionSelection>
        <span className={'count'}>{selected.length}</span>
        <span className={'selectedText'}>selected</span>
      </BulkActionSelection>
      {/*For Later use*/}
      {/*<BulkActionItem className={'borderRight'} onClick={handleCreateTask}>*/}
      {/*  <AddTaskWorkOrderIcon className={'addTaskIcon actionIcon'} />*/}
      {/*  <label className={'actionText'}>Create Work Order</label>*/}
      {/*</BulkActionItem>*/}
      <BulkActionItem onClick={handleDeleteLocations}>
        <DeleteIcon className={'deleteIcon actionIcon'} />
        <label className={'actionText'}>Delete</label>
      </BulkActionItem>
      <BulkActionCancelItem onClick={onClearSelection}>
        {' '}
        <CloseIcon className={'deleteIcon actionIcon'} />
      </BulkActionCancelItem>
    </BulkActionCnt>
  );
}

export default LocationsBulkAction;
