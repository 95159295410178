import { createSvgIcon } from '@mui/material';

const QRCodeIcon2 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M4.875 5.07495H4.8825M13.125 5.07495H13.1325M4.875 13.325H4.8825M9.75 9.94995H9.7575M13.125 13.325H13.1325M12.75 15.95H15.75V12.95M10.5 12.575V15.95M15.75 10.7H12.375M11.7 7.69995H14.55C14.97 7.69995 15.1801 7.69995 15.3405 7.61821C15.4816 7.5463 15.5963 7.43157 15.6683 7.29044C15.75 7.13001 15.75 6.91999 15.75 6.49995V3.64995C15.75 3.22991 15.75 3.01989 15.6683 2.85946C15.5963 2.71834 15.4816 2.6036 15.3405 2.5317C15.1801 2.44995 14.97 2.44995 14.55 2.44995H11.7C11.28 2.44995 11.0699 2.44995 10.9095 2.5317C10.7684 2.6036 10.6537 2.71834 10.5817 2.85946C10.5 3.01989 10.5 3.22991 10.5 3.64995V6.49995C10.5 6.91999 10.5 7.13001 10.5817 7.29044C10.6537 7.43157 10.7684 7.5463 10.9095 7.61821C11.0699 7.69995 11.28 7.69995 11.7 7.69995ZM3.45 7.69995H6.3C6.72004 7.69995 6.93006 7.69995 7.09049 7.61821C7.23161 7.5463 7.34635 7.43157 7.41825 7.29044C7.5 7.13001 7.5 6.91999 7.5 6.49995V3.64995C7.5 3.22991 7.5 3.01989 7.41825 2.85946C7.34635 2.71834 7.23161 2.6036 7.09049 2.5317C6.93006 2.44995 6.72004 2.44995 6.3 2.44995H3.45C3.02996 2.44995 2.81994 2.44995 2.65951 2.5317C2.51839 2.6036 2.40365 2.71834 2.33175 2.85946C2.25 3.01989 2.25 3.22991 2.25 3.64995V6.49995C2.25 6.91999 2.25 7.13001 2.33175 7.29044C2.40365 7.43157 2.51839 7.5463 2.65951 7.61821C2.81994 7.69995 3.02996 7.69995 3.45 7.69995ZM3.45 15.95H6.3C6.72004 15.95 6.93006 15.95 7.09049 15.8682C7.23161 15.7963 7.34635 15.6816 7.41825 15.5404C7.5 15.38 7.5 15.17 7.5 14.75V11.9C7.5 11.4799 7.5 11.2699 7.41825 11.1095C7.34635 10.9683 7.23161 10.8536 7.09049 10.7817C6.93006 10.7 6.72004 10.7 6.3 10.7H3.45C3.02996 10.7 2.81994 10.7 2.65951 10.7817C2.51839 10.8536 2.40365 10.9683 2.33175 11.1095C2.25 11.2699 2.25 11.4799 2.25 11.9V14.75C2.25 15.17 2.25 15.38 2.33175 15.5404C2.40365 15.6816 2.51839 15.7963 2.65951 15.8682C2.81994 15.95 3.02996 15.95 3.45 15.95Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'QRCodeIcon2',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 19'} {...props} />;
};
export default QRCodeIcon2;
