export const overdueDropdownData = [
  {
    label: 'Is Overdue',
    value: 'Is Overdue',
    id: 'overDue',
  },
  {
    label: 'Is not Overdue',
    value: 'Is not Overdue',
    id: 'notOverDue',
  },
];
