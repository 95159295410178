import styled from '@mui/system/styled';

import { statusDropdownData } from 'helper/task/dropdownData';

export const ScheduleItemCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clickable',
})(({ theme, clickable }) => ({
  border: '1px solid #E0E0E0',
  borderRadius: 8,
  padding: '12px 16px',
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.08)',
  background: '#FFFFFF',
  position: 'relative',
  overflow: 'hidden',
  cursor: clickable ? 'pointer' : '',
  transition: '0.2s',
  ':hover': {
    boxShadow: clickable ? '0px 16px 10px -20px rgba(0,0,0,0.63)' : '',
    transition: '0.2s',
  },
  '.header': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 18,
    },
    '.column': {
      display: 'flex',
      gap: 4,
      flexDirection: 'column',
      '.title': {
        color: '#212121',
        fontSize: 13,
        fontWeight: 700,
        lineHeight: '19px',
      },
      '.overdue': {
        color: 'rgba(211, 47, 47, 1)',
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.17,
        display: 'flex',
        gap: 6,
        alignItems: 'center',
      },
      '.dueOrcompleted': {
        color: 'rgba(97, 97, 97, 1)',
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.4,
        display: 'flex',
        gap: 6,
        alignItems: 'center',
        '& svg': {
          fontSize: 16,
          '& path': {
            stroke: 'rgba(117, 117, 117, 1)',
          },
        },
      },
    },
  },
  '& .itemFooter': {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    margin: '12px 0 0 0',
    padding: '12px 0 0 0',
    borderTop: '1px solid #E0E0E0',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

export const InfoChip = styled('span')({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #E0E0E0',
  borderRadius: 100,
  minHeight: 28,
  padding: '0 8px',
  gap: 6,
  '.infoChipIcon': {
    display: 'flex',
  },
  '.infoChipText': {
    color: '#424242',
    fontSize: 12,
    fontWeight: 400,
  },
});

export const LeftBorder = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status',
})(({ status }) => ({
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  background:
    statusDropdownData.find((s) => s.id === status)?.color || 'transparent',
  width: 5,
}));

export const CreatedByWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '.createdBy': {
    fontWeight: '400',
    fontSize: 12,
    letterSpacing: 0.4,
    color: 'rgba(97,97,97,1)',
  },
  '.user': {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
});
