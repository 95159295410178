// Styled
import { Container, ContentContainer, Heading, DetailText } from './styled';

// Icons
import CalendarWithBlobIcon from 'components/Icons/calendarWithBlobIcon';
import SchedulesUseCases from 'components/Icons/schedulesUseCases';

const EmptyState = () => {
  return (
    <Container>
      <ContentContainer>
        <Heading>My Schedules</Heading>
        <DetailText>
          View your scheduled work, create new recurring assignments, and
          quickly identify incomplete & overdue work.
        </DetailText>
        <CalendarWithBlobIcon style={{ height: '168px', width: '242px' }} />
      </ContentContainer>
      <SchedulesUseCases className="useCases" />
    </Container>
  );
};

export default EmptyState;
