import AssigneesView from 'components/AssigneesView/AssigneesView';
import { AssigneeCnt } from './style';
import { Stack, Typography } from '@mui/material';
import useDateTime from 'utils/CustomHooks/useDateTime';
import IosSwitch from 'components/IosSwitch';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { togglePause } from 'api/schedulesApi';
import DeleteIcon from 'components/Icons/deleteIcon';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { archiveSeries } from 'api/tasksApi';
import { colors } from 'components/theme/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useMemo } from 'react';

export const Assignee = ({ assignees }: { assignees: Array<any> }) => {
  return (
    <AssigneeCnt data-attribute="cell-click">
      {assignees?.length == 1 && (
        <AssigneesView type="single" data={assignees} />
      )}

      {assignees?.length > 1 && (
        <AssigneesView
          type="avatars"
          data={assignees}
          assigneesProps={{ isMenuToShow: true }}
        />
      )}

      {(assignees?.length == 0 || !assignees) && '-'}
    </AssigneeCnt>
  );
};

export const NextDue = (props) => {
  const { dueDate } = props;
  const { getUtcToTz } = useDateTime();
  return (
    <Typography
      fontWeight="500"
      fontSize="14px"
      lineHeight="19.12px"
      color="#212121"
    >
      {getUtcToTz(dueDate, 'MMM DD, YYYY [at] h:mm A') || '-'}
    </Typography>
  );
};

export const ScheduleActions = (props) => {
  const { data, removeScheduleHandler, pauseScheduleHandler, project } = props;
  const dispatch = useDispatch();

  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const canPauseResumeProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_PAUSE_RESUME_PROJECT);
  }, [PERMISSIONS]);

  const canEditProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_EDIT_PROJECT);
  }, [PERMISSIONS]);

  const PauseScheduleTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to pause this schedule ?
      </Typography>
    );
  };

  const toggleSchedule = async () => {
    if (!data?.isPaused) {
      dispatch(
        setDialog({
          dialogId: 'confirmationDialog',
          open: true,
          data: {
            title: 'Pause Schedule',
            ContentTemplate: PauseScheduleTemplate,
            onConfirmCallback: async () => {
              const result = await togglePause(data?.id);
              if (result) {
                pauseScheduleHandler(data?.id);
              }
            },
            confirmationText: 'Pause Schedule',
          },
        }),
      );
      return;
    }

    await togglePause(data?.id);
    pauseScheduleHandler(data?.id);
  };

  const DeleteScheduleTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        {project?.entityIds?.length === 1
          ? 'Are you sure you want to delete this schedule ? This will also delete the project (as it is the last schedule which is going to be deleted).'
          : 'Are you sure you want to delete this schedule ?'}
      </Typography>
    );
  };

  const actionsDropdownHandler = async (options) => {
    switch (options?.id) {
      case 'delete':
        dispatch(
          setDialog({
            dialogId: 'confirmationDialog',
            open: true,
            data: {
              title: 'Delete Project Schedule',
              ContentTemplate: DeleteScheduleTemplate,
              onConfirmCallback: async () => {
                const result = await archiveSeries(data?.taskUniqueId);
                if (result) {
                  removeScheduleHandler(data?.id);
                }
              },
              confirmationText: 'Delete Schedule',
            },
          }),
        );
        break;

      default:
        return null;
    }
  };

  return (
    <Stack
      onClick={(e) => e.stopPropagation()}
      gap="16px"
      direction="row"
      alignItems="center"
      height="100%"
    >
      {canPauseResumeProject && (
        <IosSwitch
          height={19}
          width={32}
          thumbSize={15}
          disabled={project?.isPaused}
          onChange={toggleSchedule}
          checked={!data?.isPaused}
        />
      )}

      {canEditProject && (
        <ActionDropdown
          paperProps={{
            style: {
              minWidth: 145,
            },
          }}
          options={[
            {
              label: 'Delete',
              id: 'delete',
              icon: <DeleteIcon style={{ color: colors.red, fill: 'none' }} />,
              iconAlign: 'start',
              sx: {
                color: `${colors.red}`,
              },
            },
          ]}
          handleOptionSelect={actionsDropdownHandler}
        >
          <MoreHorizIcon />
        </ActionDropdown>
      )}
    </Stack>
  );
};
