// React
import { Fragment, useRef, useState, useEffect, useMemo } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';

// Custom components
import EmptyState from './EmptyState';
import GridView from 'pages/Schedules/common/GridView';
import Header from 'pages/Schedules/ManageSchedules/Header';
import ListView from './ListView';
import EmptyResultsFilter from '../common/EmptyResultsFilter';

// MUI
import { CircularProgress } from '@mui/material';

// Styled
import { Container, Content, LoadingContainer } from './styled';

// Context
import { ManageSchedulesCtx } from './context';

// Constants
import DIALOGS from 'utils/dialogIds';

// Hooks
import { useScheduleTab } from 'pages/Schedules/common/useScheduleTab';
import { useWindowResize } from 'controller/useWindowResize';
import useDateTime from 'utils/CustomHooks/useDateTime';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useUserConfig } from 'controller/useUserConfig';

const ManageSchedules = ({ customPayload = {}, persistKey }: any) => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const size = useWindowResize();
  const { isMobileDeviceSm } = useResponsive();

  const [height, setHeight] = useState<any>(0);
  const [selectedView, setSelectedView] = useState<'grid' | 'list'>('grid');
  const ref = useRef<any>(null);

  const { getDateInTz, getUtcToTzTime } = useDateTime();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const [configKey, setConfigKey] = useState<any>(null);
  const [initialConfigLoad, setInitialConfigLoad] = useState(false);

  useEffect(() => {
    if (persistKey && userId) {
      setConfigKey(`${persistKey}::${userId}`);
    }
  }, [userId]);

  const getSchedulesViewConfig = async () => {
    const userSchedulesViewConfig = await getUserConfig(configKey);
    setAdvanceFilters(userSchedulesViewConfig?.filters || null);

    setTimeout(() => {
      setInitialConfigLoad(true);
    }, 1000);
  };

  useEffect(() => {
    if (!persistKey && !initialConfigLoad) {
      setInitialConfigLoad(true);
      return;
    }

    if (persistKey && configKey) {
      getSchedulesViewConfig();
    }
  }, [configKey]);

  useEffect(() => {
    setHeight(
      isMobileDeviceSm
        ? ref?.current?.clientHeight + 40
        : ref?.current?.clientHeight - 30,
    );
  }, [size, ref?.current?.clientHeight]);

  const {
    data,
    advanceFilters,
    searchTerm,
    totalCount,
    isNextPageLoading,
    loading,
    noResults,
    setSearchTerm,
    setAdvanceFilters,
    handleLoadNextPage,
    fetchData,
    handleChangeSearch,
  } = useScheduleTab(2, 9999999999999, customPayload, initialConfigLoad);

  const updateFilters = async () => {
    await editUserConfig(`${persistKey}::${userId}`, {
      filters: advanceFilters,
    });
  };
  useEffect(() => {
    if (persistKey && initialConfigLoad) {
      updateFilters();
    }
  }, [advanceFilters]);

  const contextValue = {
    data,
    loading,
    advanceFilters,
    searchTerm,
    selectedView,
    setAdvanceFilters,
    setSearchTerm,
    handleChangeSearch,
    setSelectedView,
  };

  const handleClickTask = (task: any) => {
    navigateWithWorkspaceUrl(`/schedule-details/${task?.id}`);
  };

  const formattedSchedulesForList = useMemo(() => {
    if (data !== null) {
      return data.map((task) => ({
        ...task,
        NextDueFormatted: task.NextDue
          ? `${getDateInTz(
              task.NextDue.dueDate,
              'MMM D, YYYY',
            )} • ${getUtcToTzTime(task.NextDue.startDate)} - ${getUtcToTzTime(
              task.NextDue.dueDate,
            )}`
          : '-',
      }));
    }
    return [];
  }, [data]);

  const handleClickListRow = (taskId: string) => {
    const task = data.find((t) => t.id === taskId);
    handleClickTask(task);
  };

  useEffect(() => {
    if (isMobileDeviceSm) {
      setSelectedView('grid');
    }
  }, [isMobileDeviceSm]);

  return (
    <ManageSchedulesCtx.Provider value={contextValue}>
      <Content ref={ref}>
        <Header createTaskCallback={() => fetchData()} />
        <div>
          {loading && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
          {data !== null && !loading && (
            <Fragment>
              {data?.length === 0 ? (
                <Fragment>
                  {noResults ? <EmptyResultsFilter /> : <EmptyState />}
                </Fragment>
              ) : (
                <Container>
                  {selectedView === 'grid' ? (
                    <GridView
                      hideLeftBar
                      isNextPageLoading={isNextPageLoading}
                      handleLoadNextPage={handleLoadNextPage}
                      totalCount={totalCount}
                      onClickTask={handleClickTask}
                      tasks={data}
                      height={height}
                      fetchDataCallback={fetchData}
                      showActions={true}
                    />
                  ) : (
                    <ListView
                      tasks={formattedSchedulesForList}
                      onRowClick={handleClickListRow}
                    />
                  )}
                </Container>
              )}
            </Fragment>
          )}
        </div>
      </Content>
    </ManageSchedulesCtx.Provider>
  );
};

export default ManageSchedules;
