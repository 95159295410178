import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, CircularProgress } from '@mui/material';
import ProjectDetailHeader from './Header';
import ProjectSchedules from './Schedules';
import ProjectDetailView from './Details';
import ProjectTasks from './Tasks';
import { getProjectById } from 'api/projectsApi';
import { LoaderCnt, MainContainer } from './style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const ProjectDetails = () => {
  const params = useParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});

  const [value, setValue] = useState('Details');
  const handleChange = (_, newValue: any) => {
    setValue(newValue);
  };

  const getProjectDetails = async (load = true) => {
    if (load) {
      setLoading(true);
    }
    const response = await getProjectById(params?.projectId);

    if (!response) {
      navigateWithWorkspaceUrl(`/projects`);
    }

    setProject(response);

    if (load) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  const updateProjectCallback = (updatedProject) => {
    setProject(updatedProject);
  };

  return (
    <MainContainer>
      {loading ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : (
        <>
          <ProjectDetailHeader
            project={project}
            getProjectDetails={getProjectDetails}
          />
          <Tabs
            sx={{
              padding: '0px 24px',
              borderBottom: '1px solid #E0E0E0',
              width: '100%',
              '& button.MuiTab-root': {
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '14px',
                p: '11px 6px',
              },
            }}
            value={value}
            onChange={handleChange}
          >
            <Tab value={'Details'} label="Details" />
            <Tab value={'Schedules'} label="Schedules" />
            <Tab value={'Tasks'} label="Tasks" />
          </Tabs>
          {value === 'Details' && <ProjectDetailView data={project} />}
          {value === 'Schedules' && (
            <ProjectSchedules
              project={project}
              getProjectDetails={getProjectDetails}
              updateProjectCallback={updateProjectCallback}
            />
          )}
          {value === 'Tasks' && <ProjectTasks project={project} />}
        </>
      )}
    </MainContainer>
  );
};

export default ProjectDetails;
