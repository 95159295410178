import { Stack, Typography, styled } from '@mui/material';

export const Container = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'center',
}));

export const Value = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#212121',
}));

export const SearchInputCnt = styled('div')(({ theme }) => ({
  width: 310,
  marginRight: 24,
  marginBottom: 10,
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginRight: 0,
    maxWidth: '295px',
  },
  '& input': {
    padding: '8.5px 14px 8.5px 7px',
    '&::-webkit-input-placeholder': {
      fontSize: 14,
    },
  },
  '& svg': {
    color: '#0000008A',
  },

  '& legend': {
    width: 0,
  },
}));

export const AssigneeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});
