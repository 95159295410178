/* eslint-disable prettier/prettier */
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { colors } from 'components/theme/constants';

export const NextButton = styled(CustomButton)({
  width: '100%',
  borderRadius: '8px',
  height: 40,
  marginBottom: 20,
  fontSize: 16,
  fontWeight: 700,
});

export const CreateAccountDiv = styled('div')(({ theme }) => ({
  width: 480,
  margin: '0px auto',
  '& input': {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    '&::placeholder': {
      fontSize: 15,
      color: '#616161',
      lineHeight: '21.86px',
      fontWeight: 500,
      opacity: 0.7,
    },
  },
  [theme.breakpoints.down('md')]: {
    width: 'unset',
    maxWidth: 392,
  },
  '& .title': {
    fontWeight: 800,
    fontSize: 30,
    lineHeight: '43.71px',
    color: '#000000',
    textAlign: 'center',
    marginBottom: 3,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      fontWeight: 600,
    },
  },
  '& .subTitle': {
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'center',
    lineHeight: '24.59px',
    letterSpacing: '-0.15px',
    color: '#616161',
    marginBottom: 35,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      fontWeight: 500,
    },
  },
  '& .labelText': {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#212121',
    marginBottom: 5,
  },
  '& .conditional': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18px',
    color: '#424242',
  },
  '& .link': {
    color: colors.primary.main,
    fontSize: 13,
  },
}));
