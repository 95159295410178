import styled from '@mui/system/styled';

export const RequestsHeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 24px',
  gap: 20,
  '& button': {
    minWidth: 150,
    padding: '7px 16px',
  },
  '& button .requestReportIcon': {
    fontSize: '20px',
    color: '#fff',
  },
  '& .requestSearchInput': {
    width: 390,
  },
  [theme.breakpoints.down('md')]: {
    padding: '16px',
    '& .requestSearchInput': {
      width: '100%',
    },
  },
}));

export const HeaderHeading = styled('div')({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '32px',
});
