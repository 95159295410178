import { useContext } from 'react';
import { AssigneeDropdownCnt } from 'components/AllDialogs/tasks/editTask/createTask.style';
import CreateEditTaskAssigneeDropdown from 'components/common/jsxrender/task/CreateEdit/CreateEditAssigneeDropdown';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import { cloneDeep, union } from 'lodash';
import { PROJECT_TYPES } from 'utils/globalVariable';

const TaskAssigneeDropdown = () => {
  const { updateTask, newTask, project } = useContext(CreateTaskContext);

  const getNewOverdueWatchers = (assignees) => {
    const OldOverdueWatchers = cloneDeep(
      newTask?.notification?.overdue?.recipients || [],
    );

    const CurrentAssignees = cloneDeep(assignees || []);
    const finaldata = union(OldOverdueWatchers, CurrentAssignees);
    return finaldata || [];
  };

  const handleUpdateAssignee = async (assignees) => {
    updateTask({
      assignees,
      notification: {
        ...newTask?.notification,
        overdue: {
          recipients: getNewOverdueWatchers(assignees),
        },
      },
    });
  };
  return (
    <AssigneeDropdownCnt>
      <label>Assignee:</label>
      <CreateEditTaskAssigneeDropdown
        handleChangeCallback={handleUpdateAssignee}
        assignees={newTask?.assignees || []}
        task={newTask}
        hideAction={project?.type === PROJECT_TYPES.userBasedProject}
      />
    </AssigneeDropdownCnt>
  );
};

export default TaskAssigneeDropdown;
