// React
import { useMemo, useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Components
import CTabs from 'components/CTabs';
import BannerWrapper from '../BannerWrapper';
import NotificationsList from '../NotificationList/NotificationsList';

// Styled
import {
  NotificationCard,
  MarkAllAsReadButton,
} from './NotificationsCard.styled';

// Icons
import TickInsideSquareIcon from 'components/Icons/tickInsideSquareIcon';

// Hooks
import { useNotification } from 'components/NotificationV2/useNotification';

// Types
import { NotificationResponseModel } from 'models/taskModel';

const initialNotificationPostData = {
  page: 1,
  pageSize: 10,
  searchText: '',
  sort: {
    createdAt: 'DESC',
  },
  type: 'ALL',
};

const NotificationsCard = () => {
  const { setMarkAllAsRead, getAllNotification, getNotificationCount } =
    useNotification();
  const unreadCount: number = useSelector(selectors.getNotificationUnreadCount);
  const currentUserId: string | number | null = useSelector(
    selectors.getUserId,
  );

  const [activeTab, setActiveTab] = useState(0);
  const [markingAllAsRead, setMarkingAllAsRead] = useState(false);
  const [isNotificationLoading, setIsNotificationLoading] =
    useState<boolean>(false);
  const [notificationListing, setNotificationListing] = useState<any>(null);

  const getNotificationList = async () => {
    setIsNotificationLoading(true);
    const allNotifications = await getAllNotification(
      initialNotificationPostData,
    );
    setNotificationListing(allNotifications);
    setIsNotificationLoading(false);
  };

  const filteredNotifications = useMemo(() => {
    if (!notificationListing || notificationListing?.length === 0) return null;
    if (activeTab === 0) return notificationListing;
    const funcMap = {
      1: (n: NotificationResponseModel) => !n.isRead,
      2: (n: NotificationResponseModel) => n.type === 9,
    };
    return notificationListing.filter(funcMap[activeTab]);
  }, [notificationListing, activeTab]);

  useEffect(() => {
    getNotificationList();
    getNotificationCount();
  }, [unreadCount]);

  const defaultTabProps = {
    tabProps: {
      sx: {
        color: 'rgba(0,0,0,0.6)',
        textTransform: 'capitalize',
      },
    },
    panelProps: {
      sx: {
        p: 0,
      },
    },
  };

  const handleMarkAllAsRead = async () => {
    setMarkingAllAsRead(true);
    const postData: any = {
      currentUserId,
    };
    try {
      await setMarkAllAsRead(postData);
      await getNotificationList();
    } finally {
      setMarkingAllAsRead(false);
    }
  };

  const tabs = useMemo(
    () => [
      {
        label: 'All',
        content: () => (
          <BannerWrapper activeTab={activeTab}>
            <NotificationsList
              isNotificationLoading={isNotificationLoading || markingAllAsRead}
              notificationListing={filteredNotifications}
              activeTab={activeTab}
            />
          </BannerWrapper>
        ),
        ...defaultTabProps,
      },
      {
        label: 'Unread',
        content: (
          <BannerWrapper activeTab={activeTab}>
            <NotificationsList
              isNotificationLoading={isNotificationLoading || markingAllAsRead}
              notificationListing={filteredNotifications}
              activeTab={activeTab}
            />
          </BannerWrapper>
        ),
        ...defaultTabProps,
      },
      {
        label: 'Alerts/Triggers',
        content: (
          <BannerWrapper activeTab={activeTab}>
            <NotificationsList
              isNotificationLoading={isNotificationLoading || markingAllAsRead}
              notificationListing={filteredNotifications}
              activeTab={activeTab}
            />
          </BannerWrapper>
        ),
        ...defaultTabProps,
      },
    ],
    [activeTab, isNotificationLoading, markingAllAsRead],
  );

  return (
    <NotificationCard>
      <CTabs onTabChangeCb={(tab) => setActiveTab(tab)} data={tabs} />
      <MarkAllAsReadButton
        onClick={handleMarkAllAsRead}
        startIcon={<TickInsideSquareIcon />}
        disabled={markingAllAsRead || isNotificationLoading}
      >
        Mark all as read
      </MarkAllAsReadButton>
    </NotificationCard>
  );
};

export default NotificationsCard;
