/* eslint-disable prettier/prettier */
import styled from '@mui/system/styled';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CustomButton from 'components/Button/CustomButton';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';

export const NextButton = styled(CustomButton)({
  width: '100%',
  borderRadius: '8px',
  height: 40,
  marginBottom: 20,
  fontSize: 16,
  fontWeight: 700,
});

export const SelectWrapper = styled(CustomSelect)({
  '& .select-box__placeholder': {
    fontSize: 15,
    color: '#616161',
    lineHeight: '21.86px',
    fontWeight: 500,
    opacity: 0.7,
  },
  '& label': {
    fontSize: 15,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  '& .select-box__control': {
    padding: 2,
  },
});

export const OrganizationInfoDiv = styled('div')(({ theme }) => ({
  width: 480,
  margin: '0px auto',
  '& input': {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    '&::placeholder': {
      fontSize: 15,
      color: '#616161',
      lineHeight: '21.86px',
      fontWeight: 500,
      opacity: 0.7,
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  '& .orgName': {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-input-placeholder': {
        color: '#252350',
      },
    },
  },
  '& .title': {
    fontWeight: 800,
    fontSize: 28,
    lineHeight: '43.71px',
    color: '#000000',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      fontWeight: 600,
    },
  },
  '& .select-box__control': {
    borderRadius: 8,
  },
  '& .subTitle': {
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'center',
    lineHeight: '24.59px',
    letterSpacing: '-0.15px',
    color: '#616161',
    marginBottom: 25,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      fontWeight: 500,
    },
  },
  '& .companyLabel': {
    fontFamily: 'Manrope',
    fontStyle: 'Regular',
    fontSize: '20px',
    lineHeight: ['32px', '160%'],
    align: 'Center',
    verticalAlign: 'Top',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .companySizeCont': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    marginTop: '43px',
  },
  '& .labelText': {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#212121',
    marginBottom: 5,
  },
  '& .teamLabelTextError': {
    fontFamily: 'Manrope',
    fontStyle: 'Regular',
    fontSize: '14px',
    lineHeight: ['32px', '160%'],
    align: 'Center',
    verticalAlign: 'Top',
    letterSpacing: '0.15px',
    color: '#d32f2f',
  },
  '& .CompanySizeDiv': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },
  '& .expTitle': {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '21.86px',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  '& .CompanySizeLabelDiv': {
    display: 'flex',
    padding: '8px 20px',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    borderRadius: 20,
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '5px 15px',
    },
    '& h5': {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      color: '#424242',
    },
  },
  '& .selected': {
    backgroundColor: '#e3e3ff',
    border: '1px solid #6868fe',
    '& h5': {
      color: '#6868fe',
    },
  },
}));

export const StyledCheckIcon = styled(CheckSharpIcon)({
  fontSize: 18,
  color: '#6868fe',
});
