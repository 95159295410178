import { createSvgIcon } from '@mui/material';

interface IProps {
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const GenericFileIcon = (props: IProps) => {
  const { bgColor = '#BE134D', fillColor = 'white', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <rect width="32" height="32" rx="6" fill={bgColor} />
      <rect width="32" height="32" rx="6" fill="white" fill-opacity="0.3" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0719 18.1992C10.2931 18.2327 10.5195 18.25 10.75 18.25C13.2353 18.25 15.25 16.2353 15.25 13.75V9.625C15.25 9.32955 15.212 9.04296 15.1407 8.76986C15.0272 8.33517 14.9704 8.11779 15.0008 8.01689C15.0325 7.9118 15.0709 7.86205 15.1646 7.80489C15.2545 7.75 15.4381 7.75 15.8053 7.75H18.431C19.0347 7.74999 19.533 7.74998 19.9389 7.78315C20.3604 7.81759 20.748 7.89151 21.112 8.07698C21.6765 8.3646 22.1354 8.82354 22.423 9.38803C22.6085 9.75203 22.6824 10.1396 22.7169 10.5611C22.75 10.967 22.75 11.4653 22.75 12.069V19.931C22.75 20.5347 22.75 21.033 22.7169 21.4389C22.6824 21.8604 22.6085 22.248 22.423 22.612C22.1354 23.1765 21.6765 23.6354 21.112 23.923C20.748 24.1085 20.3604 24.1824 19.9389 24.2169C19.533 24.25 19.0347 24.25 18.431 24.25H13.569C12.9653 24.25 12.467 24.25 12.0611 24.2169C11.6396 24.1824 11.252 24.1085 10.888 23.923C10.3235 23.6354 9.8646 23.1765 9.57698 22.612C9.39151 22.248 9.31759 21.8604 9.28315 21.4389C9.24998 21.033 9.24999 20.5347 9.25 19.931V18.8424C9.25 18.5554 9.25 18.412 9.29887 18.3259C9.3466 18.2418 9.40042 18.1955 9.49071 18.1608C9.58315 18.1254 9.74608 18.15 10.0719 18.1992ZM16.375 14.5C16.375 14.0858 16.7108 13.75 17.125 13.75H19.75C20.1642 13.75 20.5 14.0858 20.5 14.5C20.5 14.9142 20.1642 15.25 19.75 15.25H17.125C16.7108 15.25 16.375 14.9142 16.375 14.5ZM15.625 17.5C15.625 17.0858 15.9608 16.75 16.375 16.75H19.75C20.1642 16.75 20.5 17.0858 20.5 17.5C20.5 17.9142 20.1642 18.25 19.75 18.25H16.375C15.9608 18.25 15.625 17.9142 15.625 17.5ZM13.75 19.75C13.3358 19.75 13 20.0858 13 20.5C13 20.9142 13.3358 21.25 13.75 21.25H19.75C20.1642 21.25 20.5 20.9142 20.5 20.5C20.5 20.0858 20.1642 19.75 19.75 19.75H13.75Z"
        fill={fillColor}
      />
      <path
        d="M11.5 9.625C11.5 9.41789 11.6679 9.25 11.875 9.25C12.0821 9.25 12.25 9.41789 12.25 9.625V13.75C12.25 14.5784 11.5784 15.25 10.75 15.25C9.92157 15.25 9.25 14.5784 9.25 13.75V10.75C9.25 10.3358 8.91421 10 8.5 10C8.08579 10 7.75 10.3358 7.75 10.75V13.75C7.75 15.4069 9.09315 16.75 10.75 16.75C12.4069 16.75 13.75 15.4069 13.75 13.75V9.625C13.75 8.58947 12.9105 7.75 11.875 7.75C10.8395 7.75 10 8.58947 10 9.625V13.75C10 14.1642 10.3358 14.5 10.75 14.5C11.1642 14.5 11.5 14.1642 11.5 13.75V9.625Z"
        fill={fillColor}
      />
    </>,
    'GenericFileIcon',
  );
  return <IconCmp viewBox={'0 0 32 32'} {...rest} />;
};
export default GenericFileIcon;
