// React
import { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

import { TemplatesCnt } from './templates.style';

import Search from './Search';
import TemplateTable from './TemplateTable';

const TemplateTableView = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(actions.getChecklistListApiCall({ detailed: true }));
  }, []);

  return (
    <TemplatesCnt>
      <Search
        searchTerm={searchTerm}
        handleChangeSearch={(term) => setSearchTerm(term ?? '')}
      />
      <TemplateTable searchTerm={searchTerm} />
    </TemplatesCnt>
  );
};

export default TemplateTableView;
