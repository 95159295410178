/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { each } from 'lodash';
import CreateAccount from './create-account';
import { MainDiv, LogoCnt } from './onBoarding.style';
import OrganizationInfo from './organization-info';
import OrganizationGoal from './organizationGoal';
import OrganizationSolution from './organizationSolution';
import { BackBtn, OnBoardingBackground } from 'pages/Login/style';
import { useReactRouter } from 'controller/useReactRouter';
import { useSearchParams } from 'react-router-dom';
import { useOnboardState } from './onboarding-provider';

import XeniaLogoSymbol from 'public/assets/img/xenia-logo-symbol.svg';
import selectors from 'store/selectors';
import useAppNavigation from 'utils/CustomHooks/useAppNavigation';
import { getPublicChecklist, getTemplatesGroup } from 'api/checklistV2Api';
import { getItem } from 'utils/globalFunction';
import { Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const OnBoardingContainer = () => {
  const { handleRedirectToRoute, handleRouterReplace } = useReactRouter();
  const userProfileData = useSelector(selectors.getUserProfile);
  const { navigateToHome } = useAppNavigation();
  const [activeStep, setActiveStep] = useState(0);
  const [template, setTemplate] = useState(null);
  const [templateGroup, setTemplateGroup] = useState(null);
  const {
    setEmail,
    setEmailInvite,
    setHotelId,
    setFullName,
    setIsAdmin,
    setPhone,
    setPhoneInvite,
    setStatus,
    setInviteToken,
    setTitle,
    inviteStatus,
    setInviteStatus,
    setPassword,
    hotelId,
  } = useOnboardState();

  const [searchParams] = useSearchParams();

  const getTemplate = async () => {
    const templateId = getItem('template-id');
    if (templateId) {
      (async () => {
        try {
          const res = await getPublicChecklist(templateId);
          if (res?.data) {
            setTemplate(res?.data);
          }
        } catch (e) {
          console.log('error:', e);
        }
      })();
    }
  };

  const getTemplateGroup = async () => {
    const templateGroupId = getItem('template-group-id');
    if (templateGroupId) {
      (async () => {
        try {
          const res = await getTemplatesGroup(templateGroupId);
          if (res?.data) {
            setTemplateGroup(res?.data);
          }
        } catch (e) {
          console.log('error:', e);
        }
      })();
    }
  };

  useEffect(() => {
    getTemplate();
    getTemplateGroup();
  }, []);

  useEffect(() => {
    let step = searchParams.get('step');
    if (step) {
      setActiveStep(Number(step));
    } else {
      setActiveStep(1);
    }
    let email = searchParams.get('email');
    if (email) {
      setEmailInvite(true);
      setEmail(email);
    }
    let phoneNo = searchParams.get('phoneNo')?.trim();
    if (phoneNo) {
      setPhoneInvite(true);
      setPhone('+' + phoneNo);
    }
    let status = searchParams.get('inviteStatus')?.trim();
    if (status) {
      setInviteStatus(true);
    }
    let inviteToken = searchParams.get('inviteToken')?.trim();
    if (inviteToken) {
      setInviteToken(inviteToken);
    }

    if (!step && userProfileData?.id && !status) {
      const firstWorkspaceId =
        userProfileData?.HotelId || userProfileData?.UserHotels?.[0].Hotel.id;

      navigateToHome(firstWorkspaceId);
    }

    setHotelId(searchParams.get('HotelId'));
    setFullName(searchParams.get('fullName')?.trim());
    setIsAdmin(searchParams.get('isAdmin'));
    setStatus(searchParams.get('status'));
    setTitle(searchParams.get('title'));
  }, [searchParams]);

  const nextStepHandler = (searchParameters, isReplace, prev) => {
    let step = prev ? activeStep - 1 : activeStep + 1;
    if (inviteStatus && step != 5) {
      navigateToHome(hotelId);
    }
    setActiveStep(step);
    if (searchParameters) {
      const params = [`step=${step}`];
      each(searchParameters, (v, k) => {
        params.push(`${k}=${v}`);
      });
      if (isReplace) {
        handleRouterReplace(`/on-boarding?${params.join('&')}`);
      } else {
        handleRedirectToRoute(`/on-boarding?${params.join('&')}`);
      }
    }
  };

  return (
    <OnBoardingBackground>
      <MainDiv>
        {(activeStep === 2 || activeStep === 3 || activeStep === 4) && (
          <BackBtn
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setActiveStep((prev) => prev - 1);
            }}
          >
            <ArrowBackIosIcon />{' '}
            <Typography className="backText">Back</Typography>
          </BackBtn>
        )}
        <div className="containerDiv">
          <div className={'onBoardingBox'}>
            {activeStep <= 4 ? (
              <>
                <div className={'onboardingContentsDiv'}>
                  <LogoCnt>
                    <img
                      src={XeniaLogoSymbol}
                      width={70}
                      height={40}
                      className="logo"
                    />
                  </LogoCnt>
                  {activeStep === 1 ? (
                    <CreateAccount
                      nextStep={nextStepHandler}
                      template={template}
                      templateGroup={templateGroup}
                    />
                  ) : activeStep === 2 ? (
                    <OrganizationInfo
                      nextStep={nextStepHandler}
                      template={template}
                      templateGroup={templateGroup}
                    />
                  ) : activeStep === 3 ? (
                    <OrganizationGoal
                      nextStep={nextStepHandler}
                      template={template}
                      templateGroup={templateGroup}
                    />
                  ) : activeStep === 4 ? (
                    <OrganizationSolution
                      nextStep={nextStepHandler}
                      template={template}
                      templateGroup={templateGroup}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <>
                {/* {activeStep === 3 && (
                  <div className={'inviteBox'}>
                    <InviteUser nextStep={nextStepHandler} />
                  </div>
                )} */}
                {/* {activeStep === 3 && (
                  <QuickActions nextStep={nextStepHandler} />
                )}
                {activeStep === 4 && <QuickTasks nextStep={nextStepHandler} />} */}
              </>
            )}
          </div>
        </div>
      </MainDiv>
    </OnBoardingBackground>
  );
};

export default OnBoardingContainer;
