import styled from '@mui/system/styled';
import { display } from '@mui/system';

export const HeaderCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 24px 12px 24px',
  '.lefCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
});
