import { FC, useState, useCallback, useEffect } from 'react';
import { Stack } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import LocationOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import GoogleMap from 'components/GoogleMap';
import axios from 'axios';
import { debounce } from 'lodash';

interface IProps {
  value?: any;
  handleSave?(value: any): void;
  preview?: boolean;
  disableLocateMe?: boolean;
}

const GeoStamp: FC<IProps> = (props) => {
  const {
    value: initialValue,
    handleSave,
    preview = false,
    disableLocateMe = false,
  } = props;

  const [currentValue, setCurrentValue] = useState(
    initialValue || { address: '' },
  );
  const [showMap, setShowMap] = useState(false);
  const [mapData, setMapData] = useState({
    address: '',
    lat: 40.71306965776129,
    lng: -74.0022139318488,
  });

  useEffect(() => {
    if (initialValue) {
      setCurrentValue(initialValue);
      setMapData(initialValue);
    }
  }, [initialValue]);

  const locateMe = () => {
    navigator?.geolocation?.getCurrentPosition(
      async ({ coords: { latitude: lat, longitude: lng } }) => {
        if (lat && lng) {
          const res = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBNqA6bKknZuU00R2sUeQh0lk94ESyLS1k`,
          );

          const address = res?.data?.results[0]?.formatted_address;
          setMapData({ address, lat, lng });
        }
      },
      (error) => {
        console.log('error', error);
      },
    );
  };

  const debouncedCallback = useCallback(
    debounce((value: any) => {
      handleSave && handleSave(value);
    }, 700),
    [handleSave],
  );

  const handleChange = (e) => {
    if (preview) return;
    if (handleSave) {
      let newValue: any = null;
      if (showMap) {
        newValue = { ...mapData, address: e.target.value };
        setMapData(newValue);
      } else {
        newValue = { ...currentValue, address: e.target.value };
        setCurrentValue(newValue);
      }
      debouncedCallback(newValue);
    }
  };

  const handleSaveLocation = () => {
    if (handleSave) {
      handleSave(mapData);
    }
  };

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction="row" alignItems="flex-start">
        <CustomInput
          suppressErrorSpace
          fieldProps={{
            value: showMap ? mapData.address : currentValue.address,
            placeholder: 'Location',
            onChange: handleChange,
          }}
        />
        <CustomButton
          disabled={disableLocateMe}
          startIcon={showMap ? <LocationOutlinedIcon /> : <MapOutlinedIcon />}
          sx={{ flex: '0 0 auto', fontSize: '15px' }}
          variant={showMap ? 'outlined' : 'contained'}
          onClick={() => {
            if (showMap) {
              locateMe();
            } else {
              setShowMap(true);
            }
          }}
        >
          {showMap ? 'Locate Me' : 'Map'}
        </CustomButton>
      </Stack>

      {showMap && (
        <div>
          <GoogleMap
            lat={mapData.lat}
            lng={mapData.lng}
            preview={preview}
            onChange={(v) => {
              setMapData(v);
            }}
          />
        </div>
      )}

      {showMap && (
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <CustomButton
            variant="outlined"
            onClick={() => {
              setShowMap(false);
            }}
          >
            Close Map
          </CustomButton>
          <CustomButton
            disabled={!(mapData.lat || mapData.lng)}
            variant="contained"
            onClick={() => {
              setShowMap(false);
              const { address, lat, lng } = mapData;
              setCurrentValue({
                address: `${address} (${lat}, ${lng})`,
                lat,
                lng,
              });
              handleSaveLocation();
            }}
          >
            Save Location
          </CustomButton>
        </Stack>
      )}
    </Stack>
  );
};

export default GeoStamp;
