import { FC, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

interface IProps {
  value: {
    address: string;
    lat?: number;
    lng?: number;
  };
}

const GeoStampItem: FC<IProps> = (props) => {
  const { value } = props;

  const mapUrl = useMemo(() => {
    if (!value?.lat && !value?.lng) return '';

    return `https://maps.googleapis.com/maps/api/staticmap?markers=color:red%7C${
      value.lat
    },${value.lng}&zoom=12&size=${
      innerWidth - 500
    }x250&key=AIzaSyBNqA6bKknZuU00R2sUeQh0lk94ESyLS1k`;
  }, [value]);

  return (
    <Stack spacing={1} width="100%">
      <Typography
        color="#212121"
        fontSize="13px"
        width="100%"
        textAlign="right"
        fontWeight={500}
      >
        {value?.address}
      </Typography>
      {value?.lat && value?.lng && mapUrl.length && (
        <img
          width="100%"
          height="250px"
          alt="staic map"
          style={{
            objectFit: 'cover',
          }}
          src={mapUrl}
        />
      )}
    </Stack>
  );
};

export default GeoStampItem;
