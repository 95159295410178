import { createSvgIcon } from '@mui/material';

const CheckboxRoundChecked = () => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_458_42354"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_458_42354)">
        <path
          d="M16 28C14.3333 28 12.7724 27.6836 11.3173 27.0507C9.86133 26.4169 8.59467 25.5609 7.51733 24.4827C6.43911 23.4053 5.58311 22.1387 4.94933 20.6827C4.31644 19.2276 4 17.6667 4 16C4 14.3333 4.31644 12.772 4.94933 11.316C5.58311 9.86089 6.43911 8.59422 7.51733 7.516C8.59467 6.43867 9.86133 5.58311 11.3173 4.94933C12.7724 4.31644 14.3333 4 16 4C17.3556 4 18.6444 4.21111 19.8667 4.63333C21.0889 5.05556 22.2 5.64444 23.2 6.4L22.2333 7.4C21.3667 6.73333 20.4058 6.22222 19.3507 5.86667C18.2947 5.51111 17.1778 5.33333 16 5.33333C13.0444 5.33333 10.528 6.372 8.45067 8.44933C6.37244 10.5276 5.33333 13.0444 5.33333 16C5.33333 18.9556 6.37244 21.4724 8.45067 23.5507C10.528 25.628 13.0444 26.6667 16 26.6667C18.9556 26.6667 21.4724 25.628 23.5507 23.5507C25.628 21.4724 26.6667 18.9556 26.6667 16C26.6667 15.4667 26.628 14.9387 26.5507 14.416C26.4724 13.8942 26.3556 13.3889 26.2 12.9L27.2667 11.8333C27.5111 12.4778 27.6947 13.1498 27.8173 13.8493C27.9391 14.5498 28 15.2667 28 16C28 17.6667 27.6836 19.2276 27.0507 20.6827C26.4169 22.1387 25.5613 23.4053 24.484 24.4827C23.4058 25.5609 22.1391 26.4169 20.684 27.0507C19.228 27.6836 17.6667 28 16 28ZM14.0667 21.2L9.33333 16.4667L10.2667 15.5333L14.0667 19.3333L27.0667 6.33333L28 7.3L14.0667 21.2Z"
          fill="white"
        />
      </g>
    </>,
    'CheckboxRoundChecked',
  );
  return <IconCmp viewBox="0 0 32 32" />;
};
export default CheckboxRoundChecked;
