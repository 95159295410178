import { MainCnt } from 'pages/Templates/FoldersListView/list.style';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useState } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import { ActionBtn } from '../../list.style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import ChecklistGridActionDropdown from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistGridActionDropdown';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { setDialog } from 'store/actions/dialogActions';
import xeniaApi from 'api/index';
import ArchiveTemplateActionDropdown from 'pages/Templates/actionDropdowns/templatsActionDropdown/archiveTemplateActionDropdown';
import { useTemplateAccess } from 'controller/useTemplateAccess';
import DIALOGS from 'utils/dialogIds';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';

function TemplateActions(props) {
  const { template, folderId } = props;
  const dispatch = useDispatch();
  const { isTemplateAdmin } = useTemplateAccess();
  const { lastFolderId } = useCurrentFolderId();
  const {
    addToFolder,
    onMoveFolder,
    isArchive,
    state = {},
    updateState,
    onMoveOutOfFolder,
  } = useContext(MyTemplatesContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDropdownOpen = Boolean(anchorEl);

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { FEATURES } = useCustomPermission();

  const handleClickPreviewButton = () => {
    if (isTemplateAdmin(template)) {
      navigateWithWorkspaceUrl(`/templates/${template?.id}`);
    } else {
      dispatch(actions.setPreviewChecklistData(template));
      setModalState('preview');
    }
  };

  const handleOptionSelect = async () => {
    const isAdmin = isTemplateAdmin(template);
    if (isAdmin) {
      navigateWithWorkspaceUrl(`/checklist/${template?.id}`);
    } else {
      navigateWithWorkspaceUrl(
        `/in-progress-checklist/${template?.id}?prevUrl=${`/checklist`}`,
      );
    }
  };

  const handleClickDropdownIcon = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleEdit = () => {
    navigateWithWorkspaceUrl(`/checklist/${template?.id}`);
    dispatch(actions.closePreviewChecklistModal());
  };

  const onPublishButtonClick = (checklistId) => {
    dispatch(actions.publishChecklist(checklistId));
  };

  const setModalState = (tab) => {
    dispatch(
      actions.setPreviewChecklistModalConfig({
        open: true,
        activeTab: tab,
        checklistScope: 'myTemplates',
      }),
    );
  };

  const showStartConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Publish Template',
          description:
            'In order to Start or Assign/Schedule this template you must Publish it.',
          confirmationText: 'Go to Builder',
          onConfirmCallback: () => {
            navigateWithWorkspaceUrl(`/checklist/${template?.id}`);
          },
        },
      }),
    );
  };

  const handleStartChecklist = () => {
    if (template.isPublished) {
      navigateWithWorkspaceUrl(
        `/in-progress-checklist/${template?.id}?prevUrl=${`${
          folderId ? `/checklist?folderId=${folderId}` : '/checklist'
        }`}`,
      );
    } else {
      showStartConfirmation();
    }
  };

  const handleClickAssignButton = () => {
    if (template.isPublished) {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createEditTask',
          data: {
            taskData: {
              checklistId: template?.id,
            },
          },
        }),
      );
      dispatch(actions.closePreviewChecklistModal());
    } else {
      showStartConfirmation();
    }
  };

  const handleArchive = () => {
    dispatch(actions.archiveChecklistApiCall(template.id, lastFolderId));
  };

  const handleDuplicate = () => {
    dispatch(actions.duplicateChecklistApiCall(template?.id, false));
  };

  const togglePublishState = async () => {
    await xeniaApi.toggleChecklistPublishState(template.id);
    dispatch(
      actions.getChecklistListApiCall({
        detailed: true,
        folderId: folderId || 'root',
      }),
    );
  };

  const deleteTemplateCallback = () => {
    dispatch(
      actions.getChecklistListApiCall({
        detailed: true,
        folderId: lastFolderId || 'root',
        archived: isArchive,
      }),
    );
  };

  return (
    <MainCnt data-attribute={'cell-click'}>
      <ActionBtn
        variant="contained"
        style={{ marginRight: 5 }}
        onClick={handleClickPreviewButton}
        disabled={isArchive}
        color="primary"
      >
        View
      </ActionBtn>
      {template?.HotelChecklist?.isEditable && (
        <>
          {' '}
          <ActionBtn
            variant="outlined"
            onClick={() => handleOptionSelect()}
            disabled={isArchive}
            color="primary"
          >
            {isTemplateAdmin(template) ? 'Edit' : 'Start'}
          </ActionBtn>
          <VerifyFeatureAccess
            featureId={FEATURES.PUBLISH_CHECKLISTS}
            hide={true}
          >
            <ActionBtn
              variant="outlined"
              style={{ marginLeft: 5 }}
              onClick={() => onPublishButtonClick(template?.id)}
              disabled={isArchive || !template?.isPublished}
              color="primary"
            >
              Publish
            </ActionBtn>
          </VerifyFeatureAccess>
        </>
      )}
      {isTemplateAdmin(template) ? (
        isArchive ? (
          <ArchiveTemplateActionDropdown
            template={template}
            deleteTemplateCallback={deleteTemplateCallback}
          />
        ) : (
          <ChecklistGridActionDropdown
            popperProps={{
              open: isDropdownOpen,
              anchorEl: anchorEl,
              disablePortal: false,
            }}
            actionRootProps={{
              rootProps: {
                style: { marginLeft: 10 },
                zIndex: 1000,
              },
            }}
            buttonProps={{
              onClick: handleClickDropdownIcon,
              sx: {
                '& svg': {
                  height: 24,
                  width: 24,
                  color: '#0000008A',
                },
              },
            }}
            folder={folderId}
            setAnchorEl={setAnchorEl}
            editChecklist={handleEdit}
            startChecklist={handleStartChecklist}
            assignChecklist={handleClickAssignButton}
            archiveChecklist={handleArchive}
            togglePublishState={togglePublishState}
            onAddTemplateToFolder={addToFolder}
            onMoveOutOfFolder={onMoveOutOfFolder}
            onMoveFolder={onMoveFolder}
            checklist={template}
            variant="vertical"
          />
        )
      ) : null}
    </MainCnt>
  );
}

export default TemplateActions;
