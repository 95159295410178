import { MainCnt } from 'pages/Templates/FoldersListView/list.style';
import CustomToolTip from 'components/Tooltip/tooltip';
import { IconButton } from '@mui/material';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import DIALOGS from 'utils/dialogIds';
import ColorPickerIcon from 'components/Icons/colorPickerIcon';
import EditIcon from 'components/Icons/editIconPencilBottomLine';
import { useContext, useState } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import ArchiveIcon from 'components/Icons/archiveIcon';
import { deleteFolderCall } from 'api/checklistV2Api';
import ArchiveFolderActionDropdown from 'pages/Templates/actionDropdowns/folderActionDropdown/archiveFolderActionDropdown';
import { ArchiveActionsCnt } from './style';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';

function FolderActions(props) {
  const { folder } = props;
  const dispatch = useDispatch();
  const { state = {}, updateState, isArchive } = useContext(MyTemplatesContext);
  const { hasPermission } = useCustomPermission();
  const checklistPermission = hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST);

  const data = state.data;
  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  // Edit Success Callback
  const updateFolderCallback = (folder) => {
    const folders = data.map((f) => {
      if (f.id === folder.id) {
        return folder;
      }
      return f;
    });
    updateState({ data: folders });
  };
  const deleteFolderCallback = () => {
    const folders = data.filter((f) => f.id !== folder?.id);
    updateState({ data: folders });
  };

  const deleteFolder = async () => {
    setDeleteButtonLoader(true);
    await deleteFolderCall(folder.id);
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.CONFIRMATION,
      }),
    );
    setDeleteButtonLoader(false);
    deleteFolderCallback();
  };

  const handleOptionSelect = async (option) => {
    switch (option) {
      case 'rename':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.RENAME_FOLDER_DIALOG,
            data: { folder, onSuccessCallback: updateFolderCallback },
          }),
        );
        break;
      case 'change_color':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.FOLDER_COLOR_DIALOG,
            data: { folder, onSuccessCallback: updateFolderCallback },
          }),
        );
        break;
      case 'archive':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.ARCHIVE_FOLDER_DIALOG,
            data: {
              folder,
              onSuccessCallback: deleteFolderCallback,
            },
          }),
        );
        break;
    }
  };

  return (
    <MainCnt data-attribute={'cell-click'} style={{ justifyContent: 'center' }}>
      {checklistPermission ? (
        !isArchive ? (
          <>
            <CustomToolTip title="Edit">
              <IconButton
                onClick={() => handleOptionSelect('rename')}
                className="actionBtn"
              >
                <EditIcon
                  className="editIcon"
                  sx={{
                    color: '#616161',
                    fill: 'none',
                    height: '15px',
                    width: '15px',
                  }}
                />
              </IconButton>
            </CustomToolTip>
            <CustomToolTip title="Color">
              <IconButton
                onClick={() => handleOptionSelect('change_color')}
                className="actionBtn"
              >
                <ColorPickerIcon
                  sx={{
                    color: '#616161',
                    fill: 'none',
                    height: '16.67px',
                    width: '16.67px',
                  }}
                />
              </IconButton>
            </CustomToolTip>

            <CustomToolTip title="Archive">
              <IconButton
                onClick={() => handleOptionSelect('archive')}
                className="actionBtn"
              >
                <ArchiveIcon
                  sx={{
                    color: '#616161',
                    fill: 'none',
                    height: '16.67px',
                    width: '15px',
                  }}
                />
              </IconButton>
            </CustomToolTip>
          </>
        ) : (
          <ArchiveActionsCnt>
            <ArchiveFolderActionDropdown
              folder={folder}
              deleteFolderCallback={deleteFolderCallback}
            />
          </ArchiveActionsCnt>
        )
      ) : null}
    </MainCnt>
  );
}

export default FolderActions;
