import { useState, useEffect, useMemo, Fragment } from 'react';

import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import SearchAndToggleHeader from '../common/SearchAndToggleHeader';
import TemplateSubmissionsTable from 'components/DataTables/TemplateSubmissionsTable';
import GridView from './GridView';
import EmptyState from './EmptyState';

// MUI
import { CircularProgress, Stack } from '@mui/material';

// Utilities
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import api from 'api';

// Hooks
import { useUserConfig } from 'controller/useUserConfig';
import useResponsive from 'utils/CustomHooks/useResponsive';
import useLocationDetail from '../useLocationDetail';

const LocationTemplateSubmissions = () => {
  const { isMobileDeviceSm } = useResponsive();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const { selectedLocation } = useLocationDetail();

  const topAlertState = useSelector(selectors.getTopAlertState);

  const [selectedView, setSelectedView] = useState<ViewType | null>(null);
  const [searchText, setSearchText] = useState('');
  const [fetching, setFetching] = useState(false);
  const [templateSubmissions, setTemplateSubmissions] = useState<any>([]);
  const [tableConfig, setTableConfig] = useState<any>(null);

  const filteredData = useMemo(() => {
    if (!searchText) return templateSubmissions;
    return templateSubmissions.filter((submission: any) =>
      submission.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [templateSubmissions, searchText]);

  const setLocationTemplateSubmissionsViewType = async () => {
    const userConfig = await getUserConfig(
      `locationTemplateSubmissionsViewType::${userId}`,
    );
    if (userConfig?.locationTemplateSubmissionsViewType) {
      setSelectedView(userConfig.locationTemplateSubmissionsViewType);
    } else {
      setSelectedView('list');
    }
  };

  const setToggleStateConfig = async (value: ViewType) => {
    const prevView = selectedView;
    setSelectedView(value);
    const config = await editUserConfig(
      `locationTemplateSubmissionsViewType::${userId}`,
      {
        locationTemplateSubmissionsViewType: value,
      },
    );
    if (!config) {
      setSelectedView(prevView);
    }
  };

  const fetchLocationTemplateSubmissions = async () => {
    setFetching(true);
    const response = await api.getSubmissionList({
      locations: [selectedLocation.id],
      statuses: ['Submitted'],
    });
    if (response) {
      setTemplateSubmissions(response);
    }
    setFetching(false);
  };

  const fetchTableConfig = async () => {
    const config = await getUserConfig(
      `locationSubmissionsTableConfig::${userId}`,
    );
    if (config) {
      const colConfig = config;
      setTableConfig(colConfig);
    }
  };

  useEffect(() => {
    setLocationTemplateSubmissionsViewType();
  }, []);

  useEffect(() => {
    fetchLocationTemplateSubmissions();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchTableConfig();
    }
  }, [userId]);

  const updateColumns = async (columnState: any[]) => {
    const previousTableConfig = tableConfig || [];
    setTableConfig(columnState);
    const updatedConfig = await editUserConfig(
      `locationSubmissionsTableConfig::${userId}`,
      columnState,
    );
    if (!updatedConfig) {
      setTableConfig(previousTableConfig);
    }
  };

  return (
    <Fragment>
      {fetching && (
        <Stack width="100%" alignItems={'center'} height="calc(100vh - 223px)">
          <CircularProgress />
        </Stack>
      )}

      {selectedView && !fetching && (
        <Stack gap={'12px'}>
          <SearchAndToggleHeader
            selectedView={selectedView}
            setSelectedView={setToggleStateConfig}
            handleSearchInput={setSearchText}
          />
          {!fetching && templateSubmissions.length === 0 && <EmptyState />}
          <Stack
            sx={{
              height: isMobileDeviceSm
                ? 'calc(100vh - 180px)'
                : topAlertState?.show
                ? 'calc(100vh - 346px)'
                : 'calc(100vh - 281px)',
              overflow: 'auto',
            }}
          >
            {selectedView === 'grid' ? (
              <GridView submissions={filteredData} />
            ) : (
              <TemplateSubmissionsTable
                columnState={tableConfig}
                rowData={filteredData}
                updateColumnStateCb={updateColumns}
              />
            )}
          </Stack>
        </Stack>
      )}
    </Fragment>
  );
};

export default LocationTemplateSubmissions;
