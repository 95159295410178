import { useContext, useMemo } from 'react';

// Custom components
import SearchBar from './SearchBar';
import CustomButton from 'components/Button/CustomButton';
import CustomToolTip from 'components/Tooltip/tooltip';
import AdvancedFiltersDropdown from './AdvancedFiltersDropdown';
import ColumnsDropdown from './ColumnsDropdown';

// Icons
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import ColumnsIcon from 'components/Icons/columnsIcon';
import DownloadIcon2 from 'components/Icons/downloadIcon2';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

// Styles
import { HeaderWrapper, IconButtonBox } from './styled';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Context
import { SpreadsheetContext } from '../context';
import useLogActions from 'pages/TemplateDashboard/Tabs/Submissions/hooks/useLogActions';

const Header = ({ viewMode }) => {
  const context = useContext(SpreadsheetContext);
  const { handleDownloadReportApi } = useLogActions();

  const {
    selectedRows,
    template,
    advancedFilters,
    advancedItemFilters,
    searchTerm,
    setAdvancedFilters,
    setAdvancedItemFilters,
    setShowDownloadOptions,
    setSearchTerm,
    handleDeleteAllAdvancedFilters,
  } = context;

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const handleClickScreenToggle = () => {
    viewMode === 'fullScreen'
      ? navigateWithWorkspaceUrl(`/templates/${template?.id}?tab=2`)
      : navigateWithWorkspaceUrl(`/templates/spreadsheet/${template?.id}`);
  };

  const handleClickDownload = async () => {
    await handleDownloadReportApi({
      logIds: selectedRows?.length ? selectedRows : [],
      option: 'csv',
      templateId: template?.id,
      status: null,
      advancedFilters,
      advancedItemFilters,
    });
  };

  return (
    <HeaderWrapper>
      <div className="area">
        <SearchBar
          onSearch={(term) =>
            term ? setSearchTerm?.(term) : setSearchTerm?.('')
          }
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <AdvancedFiltersDropdown
          deleteAllFilters={() => handleDeleteAllAdvancedFilters?.()}
          selectedFilters={advancedFilters ?? []}
          selectedItemFilters={advancedItemFilters ?? []}
          templateItems={template?.ChecklistItems}
          onChange={(filters, empty) => {
            if (empty) {
              setAdvancedFilters?.(undefined);
            } else {
              setAdvancedFilters?.(filters);
            }
          }}
          onItemFilterChange={(itemFilters, empty) => {
            if (empty) {
              setAdvancedItemFilters?.(undefined);
            } else {
              setAdvancedItemFilters?.(itemFilters);
            }
          }}
        />
      </div>
      <div className="area">
        <CustomToolTip
          placement="top"
          title={viewMode === 'fullScreen' ? 'Collapse' : 'Full Screen Mode'}
        >
          <IconButtonBox onClick={handleClickScreenToggle}>
            <div className="content">
              {viewMode === 'fullScreen' ? (
                <CloseFullscreenIcon
                  className="icon"
                  sx={{ color: 'rgba(97, 97, 97, 1)', fontSize: '18px' }}
                />
              ) : (
                <OpenInFullOutlinedIcon
                  className="icon"
                  sx={{ color: 'rgba(97, 97, 97, 1)', fontSize: '18px' }}
                />
              )}
            </div>
          </IconButtonBox>
        </CustomToolTip>
        <ColumnsDropdown>
          <IconButtonBox>
            <div className="content">
              <ColumnsIcon
                sx={{
                  color: 'transparent',
                  fontSize: '18px',
                  '& rect': {
                    stroke: 'rgba(97, 97, 97, 1) !important',
                  },
                }}
              />
              <span>Columns</span>
            </div>
          </IconButtonBox>
        </ColumnsDropdown>
        <CustomButton
          sx={{ borderRadius: '8px', height: '40px' }}
          variant="contained"
          endIcon={
            <DownloadIcon2
              sx={{ '& path': { fill: '#fff', fillOpacity: '1' } }}
            />
          }
          onClick={handleClickDownload}
        >
          Download
        </CustomButton>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
