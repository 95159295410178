import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Stack } from '@mui/material';
import AddIcon from 'components/Icons/addIcon';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';
import TasksTemplatesEmptyState from './emptyState/emptyState';
import { TaskTemplatesList } from './list';
import { Container, CreateNewBtn } from './style';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { getTaskTemplatesState } from 'store/selectors/taskTemplates';

const SavedTaskTemplates = () => {
  const { isMobileDeviceSm } = useResponsive();
  const [loading, setLoading] = useState(true);

  const [templates, setTemplates] = useState<Array<any>>([]);
  const dispatch = useDispatch();

  const taskTemplates = useSelector(getTaskTemplatesState);

  useEffect(() => {
    if (!taskTemplates?.loading) {
      setTemplates(taskTemplates?.list ?? []);
      setLoading(false);
    }
  }, [taskTemplates]);

  const addTemplateinState = (newTemplate) => {
    setTemplates([newTemplate, ...templates]);
  };

  const editTemplateinState = (editedTemplate) => {
    const updatedTemplates = templates?.map((item) => {
      if (editedTemplate?.id === item?.id) {
        return editedTemplate;
      }
      return item;
    });

    setTemplates(updatedTemplates);
    globalThis?.SavedTaskTemplates?.api?.redrawRows();
  };

  const deleteTemplateFromState = (deleteTemplateId) => {
    const filteredTemplates = templates?.filter(
      (item) => item?.id !== deleteTemplateId,
    );
    setTemplates(filteredTemplates);
  };

  const nextHandler = (metaData, templateInfo) => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createEditTask',
      }),
    );

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG,
        data: {
          taskTemplate: {
            metaData,
            templateInfo,
          },
          onSuccessCallBack: addTemplateinState,
        },
      }),
    );
  };

  const createTasksTemplate = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          hideElements: {
            dueDate: true,
            schedule: true,
            autoTagLocation: true,
            separateTasksCreation: true,
            isRequiredChecklistCheckbox: true,
          },
          actionBtn: {
            text: 'Next',
            clickHandler: nextHandler,
          },
        },
      }),
    );
  };

  return (
    <Container>
      {loading && (
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      )}

      {!loading && (
        <Stack
          direction="row"
          justifyContent={isMobileDeviceSm ? 'center' : 'end'}
        >
          <CreateNewBtn
            startIcon={<AddIcon />}
            variant="contained"
            onClick={createTasksTemplate}
          >
            Create New Template
          </CreateNewBtn>
        </Stack>
      )}

      {!loading && templates?.length === 0 && (
        <TasksTemplatesEmptyState createTasksTemplate={createTasksTemplate} />
      )}

      {!loading && templates?.length > 0 && (
        <TaskTemplatesList
          templates={templates}
          actions={{
            editSuccessHandler: editTemplateinState,
            deleteSuccessHandler: deleteTemplateFromState,
          }}
        />
      )}
    </Container>
  );
};

export default SavedTaskTemplates;
