import { statusDropdownData } from 'helper/task/dropdownData';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import React from 'react';
import {
  CustomEventStyled,
  StatusIcon,
} from 'pages/task/TaskListView/TaskCalendar/Renderers/renderer.style';
import RepeatScheduleIcon from 'components/Icons/repeatScheduleIcon';
import CustomToolTip from 'components/Tooltip';

const CustomEvent = (event) => {
  const { children } = event;
  const status = statusDropdownData.find(
    (s) => s.id === event.event.obj.taskStatus,
  );
  return (
    <CustomToolTip title={event.event.obj.title} placement="top" arrow>
      <CustomEventStyled data-attribute="event" color={status?.color}>
        {!event?.event?.obj?.taskNumber ? (
          <div className={'scheduledTask'}>
            <span className={'taskTitle'}>{event.event.obj.title}</span>
            <RepeatScheduleIcon style={{ fontSize: 12, color: '#9E9E9E' }} />
          </div>
        ) : null}

        {event?.event?.obj?.taskNumber ? (
          <div className={'actualTask'}>
            <StatusIcon className={'statusIcon'} color={status?.color}>
              {status?.startIcon}
            </StatusIcon>
            <span className={'taskTitle'}>{event.event.obj.title}</span>
          </div>
        ) : null}
      </CustomEventStyled>
    </CustomToolTip>
  );
};

export default CustomEvent;
