import {
  SettingsHeaderWithActions,
  SettingsTitle,
} from 'components/common/styledComponents/settings.style';

const Header = () => {
  return (
    <SettingsHeaderWithActions>
      <SettingsTitle>Billing</SettingsTitle>
    </SettingsHeaderWithActions>
  );
};

export default Header;
