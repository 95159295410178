import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import AddIcon from '@mui/icons-material/Add';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import NewFolderIcon from 'components/Icons/newFolderIcon';
import NewTemplateIcon from 'components/Icons/newTemplateIcon';
import { DocumentsContext } from 'pages/documents/context/context';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const folderActionDropdownOptions = [
  {
    label: 'New SOP / Document',
    id: 'new_sop_document',
    icon: <NewTemplateIcon style={{ color: '#000000' }} />,
    iconAlign: 'start',
  },
  {
    label: 'New Folder',
    id: 'new_folder',
    icon: <NewFolderIcon style={{ color: '#000000' }} />,
    iconAlign: 'start',
  },
];

function AddDocumentFolderDropdown() {
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const { state, updateState, addDocument, addFolder } =
    useContext(DocumentsContext);

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { createDocument } = useDocumentsFolders();

  const { selectedFolder, isArchive } = state;

  const uploadDocumentHandler = async (data) => {
    if (selectedFolder) {
      data = {
        ...data,
        FolderId: selectedFolder?.id,
      };
    }

    const response = await createDocument(data);
    if (isArchive) {
      updateState({
        isArchive: false,
      });
      navigateWithWorkspaceUrl(`/documents/my-documents`);
    } else {
      addDocument(response);
    }

    manageLoader(false);

    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
      }),
    );
  };

  const sopHandler = async () => {
    let obj: any = {
      title: 'New Document',
    };
    if (selectedFolder) {
      obj = {
        ...obj,
        FolderId: selectedFolder?.id,
      };
    }

    const response = await createDocument(obj, false);

    if (response?.id) {
      addDocument(response);

      dispatch(
        setDialog({
          open: false,
          dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
        }),
      );

      const urlTab = `${window.location.origin}/document-detail/${response?.id}?source=list`;
      window.open(urlTab, '_self');
    }
  };

  const manageLoader = (loading) => {
    updateState({ loading });
  };

  //Handler for option select of action dropdown
  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'new_sop_document':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
            data: {
              onUploadFileSuccessCallback: uploadDocumentHandler,
              onSOPSuccessCallback: sopHandler,
              manageLoader: manageLoader,
            },
          }),
        );
        break;
      case 'new_folder':
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS?.CREATE_DOCUMENT_FOLDER_DIALOG,
            data: {
              onSuccessCallback: addFolder,
              folderId: selectedFolder?.id,
            },
          }),
        );
        break;
    }
  };

  return (
    <ActionDropdown
      rootProps={{
        id: 'folders-action-dropdown',
        className: 'folders-action-dropdown',
      }}
      options={folderActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      iconButton={false}
      buttonProps={{
        startIcon: !isMobileDeviceSm && <AddIcon />,
        variant: 'contained',
      }}
      popperProps={{
        placement: 'bottom-start',
        sx: {
          zIndex: 1301,
        },
      }}
    >
      {isMobileDeviceSm ? <AddIcon /> : 'Add New'}
    </ActionDropdown>
  );
}

export default AddDocumentFolderDropdown;
