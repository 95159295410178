// React
import { FC, useCallback, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { getUserMetaInfo, updateUserMetaInfo } from 'store/actions/userActions';

// Custom components
import UsersReport from './reports/users';
import CheckListReport from './reports/checkLists';
import InProgressSubmissions from './reports/InProgressSubmissions';
import OnBoardingCall from './onBoardingCall';
import Resources from './resources';
import Download from './download';
import {
  INITIAL_LAYOUT_DASHBOARD,
  THRESHOLD,
} from 'components/CustomTilesGrid/utils';
import StarsIcon from 'components/Icons/starsIcon';
import CTypography from 'components/CTypography';
import CustomTilesGrid from 'components/CustomTilesGrid';
import DashboardSkeleton from './Skeleton.cmp';
import TemplateUpload from './templateUpload';

// Hooks
import { useReactRouter } from 'controller/useReactRouter';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Utils
import { GRIDS_LAYOUTS } from 'utils/constants';
import xeniaApi from 'api/index';
import { MainCnt, Title } from './style';
import DIALOGS from 'utils/dialogIds';
import { HasPermission } from 'components/HasPermission';
import MyRequestsReport from 'pages/dashboard/reports/myRequests';
import useBilling from 'controller/useBilling';
import { Grid } from '@mui/material';
import OverdueTasksListSummary from 'pages/Widgets/TaskListing/TaskListView/overdueTaskListSummary';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { transformLayouts } from 'utils/globalFunction';
import OnboardingChecklist from './onBoardingChecklist';
import BrowseSampleTemplates from './browseSampleTemplates';
import { useUserRole } from 'utils/CustomHooks/useUserRole';

const Dashboard: FC = () => {
  const { isBasicUser } = useUserRole();
  const userProfile: any = useSelector(selectors.getUserProfile);
  const userMetaInfo: any = useSelector(selectors.getUserMeta);
  const { workspaceId } = useWorkspaceHook();
  const { getSearchParamKey } = useReactRouter();
  const { billingData } = useBilling();

  const { hasFeature, hasPermission, FEATURES, PERMISSIONS } =
    useCustomPermission();

  const canManageChecklistsPermission = hasPermission(
    PERMISSIONS.CAN_MANAGE_CHECKLIST,
  );

  const canViewTasksRequests = hasPermission(
    PERMISSIONS.CAN_VIEW_TASK_REQUESTS,
  );

  const canManageUsers = hasPermission(PERMISSIONS.CAN_MANAGE_USERS);

  const hasTaskPermission = hasPermission(PERMISSIONS.CAN_MANAGE_TASKS);

  const canViewTasksPermission = hasPermission(PERMISSIONS.CAN_VIEW_TASKS);

  useEffect(() => {
    if (getSearchParamKey('payment_success') === 'true') {
      dispatch(
        actions.setDialog({
          dialogId: DIALOGS.BILLING.PAYMENT_SUCCESS,
          open: true,
        }),
      );
    }
  }, []);

  const getUserMetaInfoCall = async () => {
    dispatch(updateUserMetaInfo({ fetching: true }));
    const userMetaInfo = await xeniaApi.getUserMeta();
    dispatch(getUserMetaInfo({ fetching: false, ...userMetaInfo }));
  };

  useEffect(() => {
    getUserMetaInfoCall();
  }, []);
  const dispatch = useDispatch();

  const updateUserMeta = async (postData) => {
    await xeniaApi.updateUserMeta(postData);
    dispatch(updateUserMetaInfo(postData));
  };

  const transformedLayouts = useCallback(
    (acceptOnly?: any) => {
      let tempLayout = transformLayouts(
        userMetaInfo?.gridsLayouts?.[GRIDS_LAYOUTS?.SUMMARY_LAYOUT],
        INITIAL_LAYOUT_DASHBOARD,
        THRESHOLD,
      );
      if (acceptOnly) {
        tempLayout = tempLayout.filter((t) => acceptOnly.includes(t.i));
      }
      return tempLayout;
    },
    [userMetaInfo?.gridsLayouts?.[GRIDS_LAYOUTS?.SUMMARY_LAYOUT]],
  );

  return (
    <>
      {userMetaInfo.fetching ? (
        <DashboardSkeleton />
      ) : (
        <MainCnt>
          <Title>
            <StarsIcon />
            <CTypography>Welcome, {userProfile?.fullName}!</CTypography>
          </Title>

          {/* Show Template upload on trial only. */}
          {billingData?.isTrial && <TemplateUpload />}

          {userMetaInfo?.dashboard?.hideTemplateUploader &&
            billingData?.isTrial &&
            !userMetaInfo?.dashboard?.hideOnboardingCall && (
              <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_BILLING]}>
                <OnBoardingCall updateUserMeta={updateUserMeta} />
              </HasPermission>
            )}

          {<OnboardingChecklist />}
          {hasTaskPermission && <BrowseSampleTemplates />}

          {/*  BENTO LINKS */}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="bento-customize-xenia"></div>
            </Grid>
            <Grid item xs={12}>
              <div className="bento-free-template"></div>
            </Grid>
            <Grid item xs={12}>
              <div className="bento-maintenance"></div>
            </Grid>
          </Grid>

          <CustomTilesGrid
            key={workspaceId}
            customLayout={transformedLayouts() || INITIAL_LAYOUT_DASHBOARD}
            onChangeCB={updateUserMeta}
            draggableHandle={true}
          >
            {canManageUsers && <UsersReport key="usersReport" />}

            {canManageChecklistsPermission && (
              <CheckListReport key="checkListReport" />
            )}

            {canManageChecklistsPermission && (
              <InProgressSubmissions key="inProgressSubmissionsReport" />
            )}

            {canViewTasksPermission && (
              <OverdueTasksListSummary key="overDueTask" />
            )}

            {canViewTasksRequests && (
              <MyRequestsReport key="myRequestsReport" />
            )}
          </CustomTilesGrid>

          <Resources />
          <Download />
        </MainCnt>
      )}
    </>
  );
};

export default Dashboard;
