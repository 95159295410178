import {
  getDeviceToken,
  getItem,
  removeItem,
  setItem,
} from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useReactRouter } from 'controller/useReactRouter';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as queryString from 'querystring';

function useUserLogin() {
  const dispatch = useDispatch();
  const { handleRouterReplace } = useReactRouter();

  const location = useLocation();
  const navigate = useNavigate();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const askNotificationPermission = () => {
    function handlePermission(permission) {
      // set the button to shown or hidden, depending on what the user answers
      if (
        Notification.permission === 'denied' ||
        Notification.permission === 'default'
      ) {
        console.log('User was asked. New permission is: ' + permission);
      }
    }
    // some browser versions don't support promise versions of Notification.requestPermission()
    function checkNotificationPromise() {
      try {
        Notification.requestPermission().then();
      } catch (e) {
        return false;
      }
      return true;
    }
    // Check if the browser supports notifications
    if (!('Notification' in window)) {
      // handle non-notification env here
    } else {
      if (checkNotificationPromise()) {
        const permissionPromise = Notification.requestPermission();
        const timeout = new Promise((resolve) => setTimeout(resolve, 500)); // Set a timeout of 500 milliseconds
        return Promise.race([permissionPromise, timeout]) // Use Promise.race to resolve with the first settled promise
          .then((permission) => {
            if (permission === 'granted') {
              console.log('Notifications are allowed.');
            } else if (permission === 'denied' || !Notification.permission) {
              console.log('Notifications are blocked.');
            } else {
              console.log(
                'Notification permission has not been granted or denied yet.',
              );
            }
          })
          .catch((error) => {
            console.error('Failed to request notification permission:', error);
          });
      } else {
        Notification.requestPermission(function (permission) {
          handlePermission(permission);
        });
        return true;
      }
    }
  };
  const setDeviceToken = async () => {
    askNotificationPermission();
    const token: string = await getDeviceToken();
    setItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN, token);
  };

  const setLoggedInUser = (user) => {
    const {
      emailId,
      HotelId,
      UserHotels,
      fullName,
      phoneNo,
      title,
      loginRedirect,
    } = user.user;
    const firstWorkspaceId = HotelId || UserHotels?.[0].Hotel.id;
    const emailParam = emailId ? `email=${emailId}` : '';
    const fullNameParam = fullName ? `fullName=${fullName}` : '';
    const phoneNoParam = phoneNo ? `phoneNo=${phoneNo}` : '';
    const titleParam = title ? `email=${title}` : '';
    dispatch(actions.login(user));
    if (loginRedirect.toLowerCase() === 'profile') {
      const url = `/on-boarding?${emailParam}&inviteStatus=true&HotelId=${HotelId}&${fullNameParam}&${phoneNoParam}&${titleParam}`;
      handleRouterReplace(url);
    } else if (loginRedirect.toLowerCase() === 'homepage') {
      const documentId = sessionStorage.getItem('documentId');
      if (documentId) {
        sessionStorage.removeItem('documentId');

        const urlTab = `${window.location.origin}/document-detail/${documentId}`;
        window.open(urlTab, '_self');
        return;
      }

      const templateId = getItem('template-id');

      if (templateId) {
        navigateWithWorkspaceUrl(
          `/checklist?template-id=${templateId}`,
          firstWorkspaceId,
          {
            replace: true,
          },
        );
        removeItem('template-id');
      } else {
        const redirectURI: any = queryString.parse(location.search.slice(1)); // remove ? from query string and parse it using querystring
        if (redirectURI?.redirectURI) {
          // If there is redirect url in query string then redirect to that url
          navigate(decodeURIComponent(redirectURI.redirectURI), {
            replace: true,
          });
          return;
        }
        if (redirectURI?.featureRedirectURI) {
          const decodedFeatureURI = decodeURIComponent(
            redirectURI.featureRedirectURI,
          );
          //remove word from string
          const replaceFeatureString = decodedFeatureURI.replace('/fr', '');
          navigateWithWorkspaceUrl(replaceFeatureString, firstWorkspaceId);
          return;
        }
        navigateWithWorkspaceUrl('/dashboard', firstWorkspaceId);
      }
    } else if (loginRedirect.toLowerCase() === 'passwordreset') {
      handleRouterReplace('/set-password');
    } else {
      handleRouterReplace('/sign-in');
    }
  };
  return {
    askNotificationPermission,
    setLoggedInUser,
    setDeviceToken,
  };
}

export default useUserLogin;
