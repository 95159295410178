// React
import { FC, useMemo, useState, useEffect } from 'react';

// Redux
import { getLocationsState } from 'store/selectors/locations';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import LocationsBulkAction from 'pages/Locations/LocationsList/BulkActions/LocationsBulkAction';
import { cellRenderer } from 'pages/Locations/LocationsList/constants';

// Utils
import { appRoutes } from 'utils/globalVariable';
import { Columns, getRowStyle, defaultColDef } from './constants';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useUserConfig } from 'controller/useUserConfig';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';

// Styles
import './locationsList.css';

export const LocationsList: FC<any> = ({ searchTerm }) => {
  const locationsList = useSelector(getLocationsState);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const topAlertState = useSelector(selectors.getTopAlertState);
  const { isMobileDeviceSm } = useResponsive();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const { config } = useWorkspaceConfig();
  const [configKey, setConfigKey] = useState<any>(null);
  const [tableConfig, setTableConfig] = useState<any>(null);

  useEffect(() => {
    if (userId) {
      setConfigKey(`locationsHomeTableConfig:${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (configKey) {
      fetchTableConfig();
    }
  }, [configKey]);

  const fetchTableConfig = async () => {
    const config = await getUserConfig(configKey);
    if (config) {
      setTableConfig(config.locationsHomeTableConfig);
    }
  };

  //Fit columns to span to full width of the grid automatically
  const onGridReady = (event) => {
    setTimeout(() => {
      event.api.sizeColumnsToFit();
    }, 1000);
  };
  // navigate to location detail on click
  const handleRowClick = (id, viewType, data) => {
    const urlToNavigate = data.data.ParentId
      ? `/${data?.data?.ParentId}/sl/${data?.data?.id}`
      : `/${data?.data?.id}`;
    navigateWithWorkspaceUrl(appRoutes.locations + urlToNavigate);
  };
  const onGridResize = (event) => {
    event.api.sizeColumnsToFit();
  };

  const onModelUpdated = () => {
    const { api } = globalThis.LocationsGrid;
    const rowsCount = api.getDisplayedRowCount();

    if (
      rowsCount === 0 &&
      locationsList?.length > 0 &&
      api.isAnyFilterPresent()
    ) {
      api.showNoRowsOverlay();
    } else {
      api.hideOverlay();
    }
  };

  const onSelectionChanged = (event) => {
    const selected = event.api.getSelectedNodes();
    setSelectedLocations(selected);
  };
  //
  const resetSelection = () => {
    globalThis.LocationsGrid.api.deselectAll();
  };

  const flattenLocations = (location, parentPath = []) => {
    const path: any = [...parentPath, location.name];
    const flattenedLocation = { ...location, path };

    if (location.Sublocations && location.Sublocations.length > 0) {
      const subLocationsWithPath = location.Sublocations.flatMap(
        (subLocation) => flattenLocations(subLocation, path),
      );
      return [flattenedLocation, ...subLocationsWithPath];
    }

    return [flattenedLocation];
  };

  const formattedLocationList = useMemo(() => {
    let flatList = locationsList?.flatMap((location) =>
      flattenLocations(location, []),
    );

    if (searchTerm) {
      flatList = flatList
        ?.filter((item) =>
          item?.name?.toLowerCase()?.includes?.(searchTerm?.toLowerCase()),
        )
        ?.map((item) => {
          return {
            ...item,
            path: [item.name],
          };
        });
    }

    for (let index = 0; index < flatList?.length; index++) {
      const tagged = flatList?.[index]?.Sublocations?.filter(
        (sl) => sl.LevelId,
      );

      if (tagged?.length > 0) {
        flatList[index].taggedName = `${tagged?.[0]?.Level?.title}${
          tagged?.length > 1 ? 's' : ''
        }`;
        flatList[index].taggedCount = tagged?.length;
      }

      const untagged = flatList?.[index]?.Sublocations?.filter(
        (sl) => !sl.LevelId,
      );

      if (untagged?.length > 0) {
        flatList[index].untaggedName = 'Sub locations';
        flatList[index].untaggedCount = untagged?.length;
      }
    }

    return flatList;
  }, [locationsList, searchTerm]);

  const getDataPath = useMemo(() => {
    return (data) => {
      return data.path;
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    const dynamicDef = tableConfig ? tableConfig[0] : {};
    return {
      headerName: 'Name',
      field: 'name',
      checkboxSelection: locationsList?.length !== 0,
      headerCheckboxSelection: locationsList?.length !== 0,
      sortable: true,
      sortChildren: true,
      minWidth: 250,
      comparator: (a, b) => {
        return a?.toLowerCase().localeCompare(b?.toLowerCase(), undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      },
      suppressMovable: true,
      suppressMenu: true,
      resizable: true,
      flex: 1,
      colId: 'name',
      pinned: 'left',
      cellStyle: (params) => {
        return params.data.ParentId
          ? { marginLeft: (params?.data?.path?.length - 1) * 30 }
          : {};
      },
      cellRenderer: cellRenderer.name,
      cellRendererParams: {
        suppressCount: true,
        isSearching: searchTerm,
      },
      ...dynamicDef,
    };
  }, [locationsList, tableConfig, searchTerm]);

  const onColumnResized = (event) => {
    const prevConfig = tableConfig;
    const state = event.columnApi.getColumnState();
    if (event?.finished && event.source !== 'api') {
      setTableConfig(state);
      const newConfig = editUserConfig(configKey, {
        locationsHomeTableConfig: state,
      });
      if (!newConfig) {
        setTableConfig(prevConfig);
      }
    }
  };

  const handleUpdateColumnState = (event) => {
    const prevConfig = tableConfig;
    const state = event.columnApi.getColumnState();
    if (event.type === 'dragStopped') {
      setTableConfig(state);
      const newConfig = editUserConfig(configKey, {
        locationsHomeTableConfig: state,
      });
      if (!newConfig) {
        setTableConfig(prevConfig);
      }
    }
  };

  const gridColumns = useMemo(() => {
    const staticColumns = Columns({
      hideMembersCol: !config?.advancedLocationBasedAssignment,
    });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = tableConfig?.find((c) => c.colId === col.colId);
      const index = tableConfig?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [tableConfig, locationsList]);

  return (
    <>
      {selectedLocations?.length > 0 && (
        <LocationsBulkAction
          selected={selectedLocations}
          onClearSelection={resetSelection}
        />
      )}
      <CustomListView
        rootProps={{
          className: 'location-list',
          style: {
            height: isMobileDeviceSm
              ? 'calc(100vh - 134px)'
              : topAlertState?.show
              ? 'calc(100vh - 193px)'
              : 'calc(100vh - 112px)',
            boxShadow: 'none',
            border: '1px solid #d3d3d382',
            flex: 1,
            borderRadius: 8,
            overflow: 'hidden',
            padding: '0px 10px 12px 10px',
            background: '#F5F5F5',
          },
        }}
        onRowClick={handleRowClick}
        gridProps={{
          ref: (ref) => {
            globalThis.LocationsGrid = ref;
          },
          rowData: formattedLocationList,
          treeData: true,
          defaultColDef: defaultColDef,
          columnDefs: gridColumns,
          onGridReady: onGridReady,
          onGridSizeChanged: onGridResize,
          onSortChanged: handleUpdateColumnState,
          onDragStopped: handleUpdateColumnState,
          onColumnResized: onColumnResized,
          onColumnVisible: handleUpdateColumnState,
          onColumnPinned: handleUpdateColumnState,
          onColumnMoved: handleUpdateColumnState,
          suppressClickEdit: true,
          suppressCellFocus: true,
          getRowId: (params) => params.data.id,
          suppressRowClickSelection: true,
          groupSelectsChildren: false,
          rowSelection: 'multiple',
          headerHeight: 36,
          rowHeight: 56,
          autoGroupColumnDef: autoGroupColumnDef,
          onSelectionChanged: onSelectionChanged,
          getRowStyle: getRowStyle,

          getDataPath,
          onModelUpdated: onModelUpdated,
        }}
      />
    </>
  );
};
