import ArchiveEmpty from 'public/assets/img/archiveEmpty.svg';
import CTypography from 'components/CTypography';
import { EmptyCnt } from 'pages/documents/emptyStates/archive/styles';

function ArchiveEmptyState() {
  return (
    <EmptyCnt>
      <div className="content">
        <img alt="documents" src={ArchiveEmpty} />
        <CTypography className="title">Nothing here</CTypography>
        <CTypography className="subtitle">
          It seems like you haven't archived any documents yet. Once you do,
          they will appear here. Archiving helps keep your workspace clean and
          organized, while still retaining older documents for potential future
          use.
        </CTypography>
      </div>
    </EmptyCnt>
  );
}

export default ArchiveEmptyState;
