import { styled } from '@mui/material/styles';
import CustomButton from 'components/Button/CustomButton';
import { Box, Typography, Divider } from '@mui/material';

export const DialogCnt = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px 24px 24px',
  borderRadius: '24px',
  alignItems: 'center',
  width: 556,
}));

export const Header = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px',
  width: '100%',
  justifyContent: 'flex-end',
  '& svg': {
    color: 'rgba(97, 97, 97, 1)',
    cursor: 'pointer',
  },
}));

export const IconContainer = styled(Box)(() => ({
  width: '80px',
  height: '80px',
  borderRadius: '80px',
  background: '#EEE',
  padding: '24px',
  marginBottom: '14px',
  '& svg': {
    fontSize: '32px',
  },
}));

export const Title = styled(Typography)(() => ({
  tetAlign: 'center',
  fontSize: 24,
  fontWeight: 600,
  color: 'rgba(0,0,0,0.87)',
  marginBottom: '16px',
}));

export const Hr = styled(Divider)(() => ({
  marginBottom: '24px',
}));

export const ButtonsWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  marginBottom: 16,
}));

const buttonStyles = {
  borderRadius: 9,
  flex: 1,
  paddingTop: 8,
  paddingBottom: 8,
  fontWeight: 600,
};

export const RedButtonFilled = styled(CustomButton)(() => ({
  ...buttonStyles,
  background: 'rgba(239, 83, 80, 1)',
  '&:hover': {
    background: 'rgba(239, 83, 80, 1)',
  },
  color: '#fff',
  '& svg': {
    color: '#fff',
  },
}));

export const RedButtonOutlined = styled(CustomButton)(() => ({
  ...buttonStyles,
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
  },
  border: '1px solid rgba(239, 83, 80, 1)',
  color: 'rgba(239, 83, 80, 1)',
  '& svg': {
    color: 'rgba(239, 83, 80, 1)',
  },
}));

export const AlertWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  marginBottom: 16,
  padding: '4px 16px',
  border: '1px solid rgba(255, 224, 130, 1)',
  borderRadius: 8,
  background: 'rgba(255, 248, 225, 1)',
  '& svg': {
    color: '#FF6F00',
  },
}));

export const AlertText = styled(Typography)(() => ({
  tetAlign: 'center',
  fontSize: 14,
  fontWeight: 400,
  color: '#FF6F00',
  letterSpacing: 0.17,
}));
