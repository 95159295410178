// Styled
import { HeaderText } from './styled';

interface HeaderPropTypes {
  heading: string;
}

const Subheader = ({ heading }: HeaderPropTypes) => {
  return <HeaderText>{heading}</HeaderText>;
};

export default Subheader;
