// React
import { useState, useMemo } from 'react';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import AdvancedFilterBuilder from './AdvancedFilterBuilder';

// Icons
import FiltersIcon from 'components/Icons/filtersIcon';
import CancelIcon from '@mui/icons-material/Cancel';

const filterTypes = [
  'header',
  'signature',
  'takePhoto',
  'photoAnnotate',
  'instruction',
  'geoStamp',
  'dateTime',
];

const AdvancedFiltersDropdown = ({
  selectedFilters,
  selectedItemFilters,
  templateItems,
  onChange,
  deleteAllFilters,
  onItemFilterChange,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const hasFilters = useMemo(
    () => selectedFilters?.length > 0 || selectedItemFilters?.length > 0,
    [selectedFilters, selectedItemFilters],
  );

  const handleCallback = (elem: any) => {
    setIsActive(!!elem);
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const handleSetFiltersCb = (filters: any[], empty?: boolean) =>
    onChange(filters, empty);

  const handleSetItemFiltersCb = (filters: any[], empty?: boolean) =>
    onItemFilterChange(filters, empty);

  const handleDeleteAllFilters = (event: any) => {
    event.stopPropagation();
    deleteAllFilters();
  };

  return (
    <CustomDropdown
      popperProps={{
        content: (
          <AdvancedFilterBuilder
            handleSetFiltersCb={handleSetFiltersCb}
            handleSetItemFiltersCb={handleSetItemFiltersCb}
            handleClose={handleClose}
            filters={selectedFilters ?? []}
            itemFilters={selectedItemFilters ?? []}
            templateItems={
              templateItems?.filter(
                (item) =>
                  !filterTypes?.includes(item.type) && item?.description,
              ) ?? []
            }
          />
        ),
        placement: 'bottom-start',
        open: Boolean(anchorEl),
        anchorEl,
      }}
      paperProps={{
        sx: {
          padding: '20px 24px',
        },
      }}
      handleCallback={handleCallback}
      onClickawayCallback={() => handleCallback(null)}
      iconButton={false}
      buttonProps={{
        variant: 'outlined',
        startIcon: <FiltersIcon />,
        endIcon: <CancelIcon onClick={handleDeleteAllFilters} />,
        sx: {
          color: 'rgba(97, 97, 97, 1)',
          border: '1px solid rgba(224, 224, 224, 1)',
          height: '40px',
          borderRadius: '8px',
          background: isActive || hasFilters ? 'rgba(104, 104, 254, 0.12)' : '',
          '&:hover': {
            border: '1px solid rgba(224, 224, 224, 1)',
            background: 'rgba(104, 104, 254, 0.12)',
          },
          ...((isActive || hasFilters) && {
            '& .MuiButton-endIcon svg': {
              color: '#EF5350',
            },
            '& svg': {
              color: 'rgba(104, 104, 254, 1)',
            },
          }),
          '& .MuiButton-endIcon': {
            position: 'absolute',
            zIndex: 50,
            right: '-2px',
            top: '-6px',
            color: '#EF5350',
            background: 'white',
            borderRadius: '20px',
            '& svg': {
              fontSize: '16px !important',
            },
            ...(!hasFilters && { display: 'none' }),
          },
        },
      }}
    >
      Filters
    </CustomDropdown>
  );
};

export default AdvancedFiltersDropdown;
