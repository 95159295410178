import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';

import { Main } from './styles';
import { NOTIFICATION_OPTIONS } from './constants';
import {
  SettingsHeader,
  SettingsTitle,
} from 'components/common/styledComponents/settings.style';
import xeniaApi from 'api/index';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';

const NotificationSettings = () => {
  const [value, setValue] = useState(NOTIFICATION_OPTIONS[0]);
  const { hasPermission } = useCustomPermission();
  const canConfigWorkSpace = hasPermission(
    PERMISSIONS.CAN_MANAGE_WORKSPACE_CONFIG,
  );
  const dispatch = useDispatch();
  const notificationConfig = useSelector(selectors.getUserNotificationConfig);

  useEffect(() => {
    dispatch(actions.loadUserConfigs());
  }, []);

  useEffect(() => {
    const config = NOTIFICATION_OPTIONS.find(
      (op) => op.value === notificationConfig?.config?.key,
    );
    if (config) {
      setValue(config);
    }
  }, [notificationConfig]);

  return (
    <Main>
      <SettingsHeader style={{ display: 'flex', alignItems: 'center' }}>
        <SettingsTitle style={{ marginRight: 5 }}>Notifications</SettingsTitle>
      </SettingsHeader>
      <hr />

      <Grid container spacing={8}>
        <Grid item xs={8}>
          <p>
            Configure how you like to receive notifications for Work Orders,
            Operations Templates, Chats, and General Settings
          </p>
        </Grid>
        <Grid item xs={4}>
          <CustomSelect
            selectedOption={value}
            style={{
              ...(!canConfigWorkSpace && {
                pointerEvents: 'none',
              }),
            }}
            onChange={async (selected) => {
              setValue(selected);

              let config: any = null;

              switch (selected.value) {
                case 'SMS_EMAIL_PUSH':
                  config = {
                    email: true,
                    push: true,
                    sms: true,
                    whatsapp: false,
                    key: 'SMS_EMAIL_PUSH',
                  };
                  break;
                case 'EMAIL_PUSH':
                  config = {
                    email: true,
                    push: true,
                    sms: false,
                    whatsapp: false,
                    key: 'EMAIL_PUSH',
                  };
                  break;
                case 'SMS_PUSH':
                  config = {
                    email: false,
                    push: true,
                    sms: true,
                    whatsapp: false,
                    key: 'SMS_PUSH',
                  };
                  break;
                case 'SMS_EMAIL':
                  config = {
                    email: true,
                    push: false,
                    sms: true,
                    whatsapp: false,
                    key: 'SMS_EMAIL',
                  };
                  break;
                case 'EMAIL':
                  config = {
                    email: true,
                    push: false,
                    sms: false,
                    whatsapp: false,
                    key: 'EMAIL',
                  };
                  break;
                case 'PUSH':
                  config = {
                    email: false,
                    push: true,
                    sms: false,
                    whatsapp: false,
                    key: 'PUSH',
                  };
                  break;
                case 'SMS':
                  config = {
                    email: false,
                    push: false,
                    sms: true,
                    whatsapp: false,
                    key: 'SMS',
                  };
                  break;
                case 'NONE':
                  config = {
                    email: false,
                    push: false,
                    sms: false,
                    whatsapp: false,
                    key: 'NONE',
                  };
                  break;
                case 'SMS_EMAIL_PUSH_WHATSAPP':
                  config = {
                    email: true,
                    push: true,
                    sms: true,
                    whatsapp: true,
                    key: 'SMS_EMAIL_PUSH_WHATSAPP',
                  };
                  break;
                case 'SMS_EMAIL_WHATSAPP':
                  config = {
                    email: true,
                    push: false,
                    sms: true,
                    whatsapp: true,
                    key: 'SMS_EMAIL_WHATSAPP',
                  };
                  break;
                case 'SMS_PUSH_WHATSAPP':
                  config = {
                    email: false,
                    push: true,
                    sms: true,
                    whatsapp: true,
                    key: 'SMS_PUSH_WHATSAPP',
                  };
                  break;
                case 'SMS_WHATSAPP':
                  config = {
                    email: false,
                    push: false,
                    sms: true,
                    whatsapp: true,
                    key: 'SMS_WHATSAPP',
                  };
                  break;
                case 'EMAIL_PUSH_WHATSAPP':
                  config = {
                    email: true,
                    push: true,
                    sms: false,
                    whatsapp: true,
                    key: 'EMAIL_PUSH_WHATSAPP',
                  };
                  break;
                case 'EMAIL_WHATSAPP':
                  config = {
                    email: true,
                    push: false,
                    sms: false,
                    whatsapp: true,
                    key: 'EMAIL_WHATSAPP',
                  };
                  break;
                case 'PUSH_WHATSAPP':
                  config = {
                    email: false,
                    push: true,
                    sms: false,
                    whatsapp: true,
                    key: 'PUSH_WHATSAPP',
                  };
                  break;
                case 'WHATSAPP':
                  config = {
                    email: false,
                    push: false,
                    sms: false,
                    whatsapp: true,
                    key: 'WHATSAPP',
                  };
                  break;
              }

              await xeniaApi.updateUserConfig(
                config,
                'UserNotificationsConfig',
                true,
              );
            }}
            options={NOTIFICATION_OPTIONS}
            grayInput
          />
        </Grid>
      </Grid>
    </Main>
  );
};

export default NotificationSettings;
