import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Stack, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CustomButton from 'components/Button/CustomButton';
import EyeballIcon2 from 'components/Icons/eyeballIcon2';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';
import TaskIcon from 'components/Icons/sidebarIcons/taskIcon';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { TALK_TO_SALES_URL } from 'utils/constants';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DIALOGS from 'utils/dialogIds';
import BookMeetingIcon from 'public/assets/img/ImgBookCall.svg';
import { trackingEventsOnWorkspace } from 'api/workspaceApi';
import { ActionBox, Container, Footer, Title } from './style';

const OperationsChecklsit = (props) => {
  const { onboardingStatus, getOnboardingChecklistDetails } = props;
  const user: any = useSelector(selectors.getUserProfile);
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const createTemplateHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_TEMPLATE_DIALOG,
      }),
    );
  };

  const createTemplateThroughTaskInHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          onSuccessCallBack: (res) => {
            if (res) {
              getOnboardingChecklistDetails();
            }
          },
        },
      }),
    );
  };

  const viewScheduleHandler = () => {
    trackingEventsOnWorkspace({
      onboarding: {
        viewed: true,
      },
    });
    navigateWithWorkspaceUrl('/scheduled-work');
  };

  const bookCall = () => {
    dispatch(
      setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <Container>
      <Title>Get Started Checklist</Title>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <ActionBox isCompleted={onboardingStatus?.template}>
            {onboardingStatus?.template ? (
              <Box className="checkIconCnt">
                <CheckCircleOutlineOutlinedIcon />
              </Box>
            ) : (
              <Box className="iconCnt">
                <ChecklistIcon />
              </Box>
            )}

            <Typography className="title">Create Template</Typography>
            <Typography className="description">
              Build your custom template or choose from hundreds of pre-built
              templates.
            </Typography>

            {!onboardingStatus?.template && (
              <CustomButton
                variant="text"
                className="actionBtn"
                endIcon={<EastIcon />}
                onClick={createTemplateHandler}
              >
                Create Template
              </CustomButton>
            )}
          </ActionBox>
        </Grid>

        <Grid item xs={4}>
          <ActionBox isCompleted={onboardingStatus?.task}>
            {onboardingStatus?.task ? (
              <Box className="checkIconCnt">
                <CheckCircleOutlineOutlinedIcon />
              </Box>
            ) : (
              <Box className="iconCnt">
                <TaskIcon />
              </Box>
            )}

            <Typography className="title">
              Use Template in a task/schedule
            </Typography>
            <Typography className="description">
              Schedule your template as part of a task.
            </Typography>

            {!onboardingStatus?.task && (
              <CustomButton
                variant="text"
                className="actionBtn"
                endIcon={<EastIcon />}
                onClick={createTemplateThroughTaskInHandler}
              >
                Create Task
              </CustomButton>
            )}
          </ActionBox>
        </Grid>

        <Grid item xs={4}>
          <ActionBox isCompleted={onboardingStatus?.viewed}>
            {onboardingStatus?.viewed ? (
              <Box className="checkIconCnt">
                <CheckCircleOutlineOutlinedIcon />
              </Box>
            ) : (
              <Box className="iconCnt">
                <EyeballIcon2
                  sx={{
                    fill: 'none',
                    '& path': {
                      stroke: 'black',
                      opacity: 0.7,
                    },
                  }}
                />
              </Box>
            )}

            <Typography className="title">View Schedule</Typography>
            <Typography className="description">
              View your schedule to see what tasks are due and when.
            </Typography>

            {!onboardingStatus?.viewed && (
              <CustomButton
                variant="text"
                className="actionBtn"
                endIcon={<EastIcon />}
                onClick={viewScheduleHandler}
              >
                View Schedule
              </CustomButton>
            )}
          </ActionBox>
        </Grid>
      </Grid>

      <Footer
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" gap="10px" alignItems="center">
          <img src={BookMeetingIcon} />
          <Stack gap="4px">
            <Typography className="main">
              Discover how businesses{' '}
              {user.Hotel?.Industry?.name && (
                <span>
                  within the{' '}
                  <span className="industry">{user.Hotel?.Industry?.name}</span>{' '}
                  industry{' '}
                </span>
              )}
              leverage Xenia for their operations
            </Typography>

            <Typography className="sub">
              Book a complimentary call with our product specialist to explore
              how you can attain excellence in achieving your company goals.
            </Typography>
          </Stack>
        </Stack>

        <CustomButton
          variant="contained"
          className="bookBtn"
          onClick={bookCall}
        >
          Book a Free Call
        </CustomButton>
      </Footer>
    </Container>
  );
};

export default OperationsChecklsit;
