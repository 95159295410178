// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { Divider } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const FilterRowsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

export const BottomRowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const AddFilterButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '22px',
  width: 82,
  padding: '4px 0px',
  '& > span': {
    marginRight: 4,
  },
  '& > span > svg': {
    fontSize: '18px !important',
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({}));
