import { useContext, useEffect } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import RepeatIcon from 'components/Icons/repeatIcon';
import {
  RepeatTaskSwitch,
  RepeatTaskToggleCnt,
  RepeatTaskToggleLabel,
} from 'components/AllDialogs/tasks/editTask/createTask.style';
import FormControlLabel from '@mui/material/FormControlLabel';
import RepeatTaskView from 'components/AllDialogs/tasks/editTask/RepeatTaskView.cmp';
import { Stack } from '@mui/material';

const weeklyInitState = {
  recurringByEvery: 'Week1',
  recurringTask: true,
  requestThisTask: 'Weekly',
  intervalWeek: ['Mon'],
  editInstance: 'One',
};
const RepeatTaskToggle = (props) => {
  const state = useContext(CreateTaskContext);
  const {
    updateTask,
    newTask,
    showRepeatTask,
    setShowRepeatTask,
    setNewTaskObj,
    setTaskTime,
    project,
  } = useContext(CreateTaskContext);

  const handleShowRepeatTask = (e) => {
    const {
      recurringByEvery,
      recurringTask,
      requestThisTask,
      intervalWeek,
      editInstance,
      recurringOnDay,
      endDate,
      dueTime,
      startTime,
      ...rest
    } = newTask;
    setShowRepeatTask(!showRepeatTask);
    if (!showRepeatTask) {
      updateTask({ ...weeklyInitState, startTime: '', dueTime: '' });
      setTaskTime((prevState) => ({
        ...prevState,
        dueTime: '',
        startTime: '',
      }));
    } else {
      setNewTaskObj(rest);
      setTaskTime((prevState) => ({
        ...prevState,
        dueTime: '',
        startTime: '',
      }));
    }
  };

  useEffect(() => {
    setShowRepeatTask(newTask?.recurringTask);
  }, [newTask?.recurringTask]);
  return (
    <Stack
      borderRadius={'6px'}
      bgcolor={!showRepeatTask ? '#F5F5F5' : '#EBEAFF'}
      p={project?.id ? '10px 16px 0px 16px' : '0px 16px'}
      pb={showRepeatTask ? '16px' : '0px'}
      gap="8px"
    >
      <RepeatTaskToggleCnt
        style={{
          paddingTop: props.editSeriesType === 'All' ? '16px' : '0px',
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <RepeatIcon
            sx={{
              '& path': {
                fill: !showRepeatTask
                  ? 'rgba(97, 97, 97, 1)'
                  : 'rgba(104, 104, 254, 1)',
              },
            }}
          />
          <RepeatTaskToggleLabel
            style={{
              color: !showRepeatTask ? '#212121' : 'rgba(104, 104, 254, 1)',
            }}
          >
            Add schedule
          </RepeatTaskToggleLabel>
        </Stack>
        {!project?.id && props.editSeriesType !== 'All' && (
          <FormControlLabel
            checked={showRepeatTask}
            label={''}
            onChange={handleShowRepeatTask}
            control={<RepeatTaskSwitch sx={{ m: 1 }} />}
            sx={{
              margin: 0,
            }}
          />
        )}
      </RepeatTaskToggleCnt>
      <RepeatTaskView
        setTaskTimeChange={state.setTaskTimeChange}
        {...state}
        updateTask={updateTask}
        newTask={newTask}
        isDropdown={props.isDropdown}
        editSeriesType={props.editSeriesType}
      />
    </Stack>
  );
};

export default RepeatTaskToggle;
