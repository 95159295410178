import { useEffect } from 'react';

import actions from 'store/actions';
import { useDispatch } from 'react-redux';

import DIALOGS from 'utils/dialogIds';

const FullScreenTemplateLibrary = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TEMPLATE_LIBRARY,
        open: true,
      }),
    );
    return () => {
      dispatch(actions.closeDialog(DIALOGS.TEMPLATE_LIBRARY));
    };
  }, []);

  return <></>;
};

export default FullScreenTemplateLibrary;
