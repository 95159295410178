import { Stack, Typography } from '@mui/material';
import AddIcon from 'components/Icons/addIcon';
import TasksTemplateImg from 'public/assets/img/ImgTaskTemplates.svg';
import { CreateNewBtn } from './style';

const TasksTemplatesEmptyState = (props) => {
  const { createTasksTemplate } = props;
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      marginTop="100px"
    >
      <div style={{ width: 650 }}>
        <img src={TasksTemplateImg} />
        <Typography
          style={{
            fontWeight: 800,
            fontSize: '20px',
            lineHeight: '27.32px',
            marginTop: -15,
            alignItems: 'center',
            color: '#252350',
            marginBottom: 10,
          }}
        >
          Let’s create your first task template
        </Typography>
        <Typography
          style={{
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '22px',
            color: '#000000',
            marginBottom: 30,
          }}
        >
          Start building a streamlined task system! Create preset task templates
          with pre-filled fields to expedite task creation and enhance
          efficiency across your team. Once established, track usage patterns to
          optimize your workflow.{' '}
          {/* <a
            style={{
              fontWeight: 700,
              fontSize: 14,
              lineHeight: '22px',
              textDecoration: 'underline',
            }}
            href={
              'https://help.xenia.team/hc/en-us/categories/15486235456667-Work-Requests'
            }
            target="_blank"
          >
            Learn More
          </a> */}
        </Typography>
        <CreateNewBtn
          startIcon={<AddIcon />}
          variant="contained"
          onClick={createTasksTemplate}
        >
          Create Your First Task Template
        </CreateNewBtn>
      </div>
    </Stack>
  );
};

export default TasksTemplatesEmptyState;
