// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Custom components
import CustomModal from 'components/CustomModal/CustomModal';

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    borderRadius: 0,
  },
});

export const SpreadsheetWrapper = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 66px)',
  padding: '0 24px 24px 24px',
  overflow: 'auto',
}));

export const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));
