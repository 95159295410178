import { createContext } from 'react';

import { ContextBaseType } from 'pages/Schedules/common/types';

export const OverdueSchedulesCtx = createContext<ContextBaseType>({
  loading: true,
  advanceFilters: null,
  setAdvanceFilters: null,
  searchTerm: '',
  setSearchTerm: null,
  handleChangeSearch: null,
});
