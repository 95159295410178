export const metricsAndFiltersMap = {
  assignee: 'assignees',
  team: 'assignees',
  status: 'taskStatuses',
  priority: 'priorities',
  category: 'categories',
  location: 'locations',
  asset: 'assets',
  dateCreated: null,
  dateCompleted: 'completedDate',
};

export const submissionMetricsAndFilters = {
  fromDate: 'fromDate',
  toDate: 'toDate',
  template: 'template',
  location: 'locations',
  locations: 'locations',
  status: 'statuses',
  statuses: 'statuses',
  submitter: 'user',
  users: 'users',
  user: 'users',
  createdDate: 'createdDate',
};

export const flaggedItemsFiltersMap = {
  submitter: 'user',
  template: 'template',
  location: 'locations',
};
