import { Stack, Typography } from '@mui/material';
import styled from '@mui/system/styled';

export const MainTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 800,
  lineHeight: '27.32px',
  color: '#000000',
  marginBottom: 10,
  textAlign: 'center',
});

export const SubTitle = styled(Typography)({
  fontSize: 15,
  fontWeight: 500,
  lineHeight: '24px',
  color: '#424242',
  textAlign: 'center',
});

export const CustomDashboardEmptyCnt = styled(Stack)({
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
  width: 620,
  margin: '0 auto',
});
