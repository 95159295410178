/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { removeStreamUser } from 'services/getStreamServices';
import actions from 'store/actions';
import { streamConstant, streamMessages } from 'utils/globalVariable';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors/index';

export const useRemoveMembers = (): any => {
  const dispatch = useDispatch();
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);
  const handleRemoveMemberSubmit = async (membersRemoveId, removetype) => {
    if (membersRemoveId.length > 0) {
      let msz = streamMessages.REMOVE_MEMBER_SUCCESS;
      if (removetype === streamConstant.REMOVE_TYPE_LEAVE) {
        msz = streamMessages.YOU_LEAVE_CHANNEL_SUCCESS;
      }
      const isRemove = await removeStreamUser(
        activeStreamChannel,
        membersRemoveId,
      );
      dispatch(actions.setIsRefreshing(true));
      return isRemove;
    }
  };

  return {
    handleRemoveMemberSubmit,
  };
};
