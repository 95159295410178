import React, { useCallback } from 'react';
import { QRControlWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import QRCodeContainerView from 'components/QRCode/contianer';
import QrCodeIcon from '@mui/icons-material/QrCode2';
import CustomButton from 'components/Button/CustomButton';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import DeleteIcon from 'components/Icons/deleteIcon';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

const LocationQRcode = (props: any) => {
  const { location, updateAction, editable, handleAddQR } = props;
  const { isQREnable } = location;
  const { workspaceId } = useWorkspaceHook();
  const { FEATURES } = useCustomPermission();

  const dispatch = useDispatch();
  const url = location.ParentId
    ? `${window.location.origin}/ws/${workspaceId}/locations/${location.ParentId}/sl/${location.id}`
    : `${window.location.origin}/ws/${workspaceId}/locations/${location.id}`;

  const openQRDialog = useCallback(() => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.QR_CODE,
        open: true,
        data: {
          qrText: url,
          headerText: 'View Location from QR Code',
          subText:
            'Download / print this QR code to allow Users to scan and access details about this Location via the Mobile App.',
        },
      }),
    );
  }, [dispatch, url]);

  return (
    <QRControlWithLabel>
      <label>QR Code</label>

      {editable && isQREnable && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <span>
            <CheckCircleIcon sx={{ color: '#4CAF50' }} />
          </span>
          <Typography variant="subtitle2">QR Code Created</Typography>
          <span>-</span>
          <CustomButton onClick={openQRDialog}>View</CustomButton>
          <CustomButton
            onClick={() => updateAction({ isQREnable: false })}
            color="error"
            startIcon={<DeleteIcon />}
          >
            Remove
          </CustomButton>
        </Stack>
      )}

      {!editable && isQREnable && (
        <QRCodeContainerView
          headerText={location?.name}
          subText={'Created in Xenia'}
          qrText={url}
          handleView={openQRDialog}
        />
      )}

      {!isQREnable && (
        <VerifyFeatureAccess featureId={FEATURES.SCAN_QR_CODE}>
          <CustomButton
            variant="outlined"
            startIcon={<QrCodeIcon />}
            onClick={() => {
              handleAddQR();
              updateAction({ isQREnable: true });
            }}
          >
            Add QR Code
          </CustomButton>
        </VerifyFeatureAccess>
      )}
    </QRControlWithLabel>
  );
};

export default LocationQRcode;
