// React
import { useState, useEffect } from 'react';

// Custom components
import WidgetLayout from 'components/TemplateDashboard/WidgetLayout';
import UserAvatar from 'components/Avatar';
import EmptyState from 'components/TemplateDashboard/EmptyState';
import DateRangeDropdown from '../DateRangeDropdown/DateRangeDropdown';
import CustomToolTip from 'components/Tooltip/tooltip';

// Styled
import {
  Wrapper,
  ActionButton,
  SubmissionBox,
  AvatarWrapper,
  Text,
  Dot,
  ReportButton,
  AnonymousAvatar,
} from './styled';

// Utils
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { Moment } from 'moment-timezone';
import xeniaApi from 'api/index';
import moment from 'moment-timezone';

// Icons
import GlobeIcon from 'components/Icons/globeIcon';

// Context
import { SubmissionReportType } from 'pages/TemplateDashboard/context';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

const SubmissionReport = ({
  templateId,
  shouldUpdate,
  handleClickViewAll,
}: {
  templateId: string;
  shouldUpdate?: number;
  handleClickViewAll: () => void;
}) => {
  const [submissionsLog, setSubmissionsLog] = useState<
    SubmissionReportType[] | undefined
  >(undefined);
  const { workspaceId } = useWorkspaceHook();

  const { getUtcToTzTime } = useDateTime();

  useEffect(() => {
    handleFilterSubmissionReports();
  }, [shouldUpdate]);

  const handleClickReport = (templateId: string) => {
    window.open(
      `/ws/${workspaceId}/checklistlogs/${templateId}/report`,
      '_blank',
    );
  };

  // filter activity logs by date
  const handleFilterSubmissionReports = async (dateFilters?: {
    fromDate: string;
    toDate: string;
  }) => {
    const submissions = await xeniaApi.getChecklistLogs({
      checklistId: templateId,
      hotelId: workspaceId,
      postData: dateFilters ?? {},
      detailed: false,
    });
    if (submissions?.data)
      setSubmissionsLog(
        submissions.data
          .sort((a, b) => {
            const dateA = moment(a.lastItemUpdatedAt);
            const dateB = moment(b.lastItemUpdatedAt);
            return dateB.diff(dateA);
          })
          .filter((s) => s.status === 'Submitted'),
      );
  };

  const handleDateChange = (range: {
    from: Moment | null;
    to: Moment | null;
  }) => {
    if (range.from && range.to) {
      handleFilterSubmissionReports({
        fromDate: range.from.toString(),
        toDate: range.to.toString(),
      });
    } else {
      if (!range.from && !range.to) {
        handleFilterSubmissionReports();
      }
    }
  };

  const getSubmitterName = (log: SubmissionReportType) => {
    if (log.submitterName) return log.submitterName;
    if (!log.LastItemUpdater || !log.Updater) return 'Anonymous';
    const updater = log.LastItemUpdater ?? log.Updater;
    return `${updater?.firstName} ${updater?.lastName}`;
  };

  return (
    <WidgetLayout
      widgetHeading="Submission Reports"
      actionButtons={
        <>
          <DateRangeDropdown handleDateChange={handleDateChange} />
          <ActionButton onClick={handleClickViewAll} variant="outlined">
            View All
          </ActionButton>
        </>
      }
    >
      <Wrapper>
        {submissionsLog && submissionsLog.length ? (
          submissionsLog.map((log) => (
            <SubmissionBox key={log.id}>
              <AvatarWrapper>
                {!log.LastItemUpdater && !log.Updater ? (
                  <CustomToolTip title="Submitted via Public Link">
                    <AnonymousAvatar>
                      <GlobeIcon style={{ color: '#fff', fontSize: '18px' }} />
                    </AnonymousAvatar>
                  </CustomToolTip>
                ) : (
                  <UserAvatar
                    width={28}
                    height={28}
                    userId={log?.LastItemUpdater?.id ?? log?.Updater?.id}
                  />
                )}
              </AvatarWrapper>
              <Text>{getSubmitterName(log)}</Text>
              <Dot />
              <Text>
                {moment(log.lastItemUpdatedAt).format('MMM DD YYYY')}{' '}
                {getUtcToTzTime(log.lastItemUpdatedAt)}
              </Text>
              <ReportButton
                onClick={() => handleClickReport(log.id)}
                variant="outlined"
              >
                View Report
              </ReportButton>
            </SubmissionBox>
          ))
        ) : (
          <EmptyState type="submission" />
        )}
      </Wrapper>
    </WidgetLayout>
  );
};

export default SubmissionReport;
