import TeamsActionDropdown from 'pages/teams/teamsList/TeamsActionDropdown/teamsActionDropdown';
import { TeamLocation } from './renderers/location';
import { Name } from './renderers/name';
import { Members } from './renderers/members';
import { Container } from './style';

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};
export const cellRenderer = {
  name: (params) => {
    if (!params?.data) return;
    return (
      <Container>
        <Name data={params?.data} />
      </Container>
    );
  },
  members: (params) => {
    if (!params?.data) return;
    return (
      <Container>
        <Members data={params?.data} />
      </Container>
    );
  },
  locations: (params, props) => {
    if (!params?.data) return;
    const { teamActionDropdownProps } = props;
    return (
      <Container>
        <TeamLocation
          data={params?.data}
          handleUpdateCall={teamActionDropdownProps?.handleUpdateCall}
        />
      </Container>
    );
  },
  actions: (params, props) => {
    if (!params?.data) return;
    const { teamActionDropdownProps } = props;
    return (
      <Container>
        <TeamsActionDropdown
          data={params?.data}
          handleEditTeam={teamActionDropdownProps?.handleEditTeam}
        />
      </Container>
    );
  },
};

export const valueGetters = {
  name: (params) => {
    if (!params?.data) return;
    const { name } = params.data;
    return name || '-';
  },
  members: (params) => {
    if (!params?.data) return;
    const { Members } = params.data;
    return Members || '-';
  },
  locations: (params) => {
    if (!params?.data) return;
    const { LocationId } = params.data;
    return LocationId || '-';
  },
  actions: (params) => {
    if (!params?.data) return;
    return params.data;
  },
};

export const columns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'name',
      headerName: 'Name',
      colId: 'name',
      sortable: true,
      valueGetter: valueGetters.name,
      cellRenderer: cellRenderer.name,
    },
    {
      field: 'members',
      headerName: 'Members',
      colId: 'members',
      sortable: true,
      valueGetter: valueGetters.members,
      cellRenderer: cellRenderer.members,
      resizable: true,
    },
    {
      field: 'locations',
      headerName: 'Locations',
      colId: 'locations',
      sortable: true,
      valueGetter: valueGetters.locations,
      cellRenderer: (params) => cellRenderer.locations(params, props),
      resizable: true,
    },
    {
      field: 'actionCol',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      width: 80,
      cellClass: 'disableRowClick',
      valueGetter: valueGetters.actions,
      cellRenderer: (params) => cellRenderer.actions(params, props),
    },
  ];
  return tableCells.filter((cell) => columnConfig[cell.field]);
};
