import { createSvgIcon } from '@mui/material';

interface IProps {
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const TXTIcon = (props: IProps) => {
  const { bgColor = '#00ACC1', fillColor = 'white', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <rect width="32" height="32" rx="6" fill={bgColor} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.59961 12.3999C7.59961 11.9028 8.00255 11.4999 8.49961 11.4999H23.7996C24.2967 11.4999 24.6996 11.9028 24.6996 12.3999C24.6996 12.897 24.2967 13.2999 23.7996 13.2999H8.49961C8.00255 13.2999 7.59961 12.897 7.59961 12.3999Z"
        fill={fillColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.59961 8.7999C7.59961 8.30285 8.00255 7.8999 8.49961 7.8999H23.7996C24.2967 7.8999 24.6996 8.30285 24.6996 8.7999C24.6996 9.29696 24.2967 9.6999 23.7996 9.6999H8.49961C8.00255 9.6999 7.59961 9.29696 7.59961 8.7999Z"
        fill={fillColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.59961 15.9999C7.59961 15.5028 8.00255 15.0999 8.49961 15.0999H23.7996C24.2967 15.0999 24.6996 15.5028 24.6996 15.9999C24.6996 16.497 24.2967 16.8999 23.7996 16.8999H8.49961C8.00255 16.8999 7.59961 16.497 7.59961 15.9999Z"
        fill={fillColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.59961 19.5999C7.59961 19.1028 8.00255 18.6999 8.49961 18.6999H23.7996C24.2967 18.6999 24.6996 19.1028 24.6996 19.5999C24.6996 20.097 24.2967 20.4999 23.7996 20.4999H8.49961C8.00255 20.4999 7.59961 20.097 7.59961 19.5999Z"
        fill={fillColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.59961 23.2C7.59961 22.703 8.00255 22.3 8.49961 22.3H18.9996C19.4967 22.3 19.8996 22.703 19.8996 23.2C19.8996 23.6971 19.4967 24.1 18.9996 24.1H8.49961C8.00255 24.1 7.59961 23.6971 7.59961 23.2Z"
        fill={fillColor}
      />
    </>,
    'TXTIcon',
  );
  return <IconCmp viewBox={'0 0 32 32'} {...rest} />;
};
export default TXTIcon;
