import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CTabs from 'components/CTabs';
import { TemplatesCnt } from 'pages/Templates/templates.style';
import MyTemplates from 'pages/Templates/MyTemplates/MyTemplates';
import PublicChecklist from 'components/common/jsxrender/checklistV2/PublicChecklist';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import TemplateConfigurations from './Configurations';
import { getUserProfile } from 'store/selectors/auth';

function Templates() {
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const [activeTab, setActiveTab] = useState<number>(0);
  const checklistPermission = hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const userProfileData: any = useSelector(getUserProfile);

  const tabs = useMemo(() => {
    const baseTabs = [
      {
        label: 'My Templates',
        content: () => (activeTab === 0 ? <MyTemplates /> : <></>),
        path: 'my-templates',
      },
      ...(checklistPermission
        ? [
            {
              label: 'Public Templates',
              content: () =>
                activeTab == 1 ? (
                  <PublicChecklist
                    view={'list'}
                    checklistScope={'public-template'}
                  />
                ) : (
                  <></>
                ),
              path: 'public-template',
            },
          ]
        : []),
      ...(userProfileData?.isAdmin
        ? [
            {
              label: 'Configurations',
              content: () =>
                activeTab == 2 ? <TemplateConfigurations /> : <></>,
              path: 'configurations',
            },
          ]
        : []),
      {
        label: 'Archive',
        content: () =>
          activeTab === 3 ? <MyTemplates isArchive={true} /> : <></>,
        path: 'overdue',
      },
    ];

    return baseTabs;
  }, [activeTab, checklistPermission]);

  //Callback function to change the tab based on the url path
  const onTabChangeCb = (activeTab: number) => {
    setActiveTab(activeTab);
    navigateWithWorkspaceUrl('/checklist');
  };

  return (
    <TemplatesCnt>
      <CTabs
        data={tabs}
        onTabChangeCb={onTabChangeCb}
        activeTab={activeTab}
        variant="scrollable"
      />
    </TemplatesCnt>
  );
}

export default Templates;
