import { ReactNode } from 'react';
import {
  Container,
  DateChip,
  DateText,
  TimelineBordered,
  TimelineItem,
  DotIcon,
  TimelineDate,
} from './styled';

interface TimelinePropTypes {
  timeline: {
    date: string;
    dateProps?: any;
    data: {
      customRenderer?: ReactNode;
      text?: string;
      time?: string;
    }[];
  };
}

const Timeline = ({ timeline }: TimelinePropTypes) => {
  return (
    <Container>
      <DateChip {...timeline.dateProps}>
        <DateText>{timeline.date}</DateText>
      </DateChip>
      <TimelineBordered>
        {timeline.data.map((timelineItem, idx) => (
          <TimelineItem key={idx}>
            <DotIcon />
            {timelineItem.customRenderer ?? timelineItem.text}
            {!!timelineItem.time && (
              <TimelineDate>{timelineItem.time}</TimelineDate>
            )}
          </TimelineItem>
        ))}
      </TimelineBordered>
    </Container>
  );
};

export default Timeline;
