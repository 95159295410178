const TaskDetailTabs = ({
  selectedTab,
  setSelectedTab,
  currentCommentChannel,
  currentRequest,
}) => {
  return (
    <>
      <ul className="nav nav-pills tab-navbar" id="pills-tab">
        <li className="nav-item">
          <a
            className={`nav-link ${selectedTab === 'detail' ? 'active' : ''}`}
            href={void 0}
            onClick={() => setSelectedTab('detail')}
          >
            Work Order
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${selectedTab === 'activity' ? 'active' : ''}`}
            href={void 0}
            onClick={() => setSelectedTab('activity')}
          >
            Activity Log
          </a>
        </li>
      </ul>
    </>
  );
};

export default TaskDetailTabs;
