import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import xeniaApi from 'api/index';
import ChecklistPreviewModal from 'components/common/jsxrender/checklistV2/checklistPreviewModal';
import CustomButton from 'components/Button/CustomButton';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface IProps {
  children?: React.ReactNode;
}

const InternalSubmissionShare: FC<IProps> = (props) => {
  const { logId, templateId, workspaceId } = useParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const [state, setState] = useState('loading');

  const fetchTemplate = async () => {
    const template = await xeniaApi.getChecklist(templateId);
    const log = await xeniaApi.getChecklistLog({ logId, hotelId: workspaceId });

    if (
      template?.data?.templateAccess?.isAdmin ||
      template?.data?.templateAccess?.isSubmitter
    ) {
      navigateWithWorkspaceUrl(
        `/in-progress-checklist/${template?.data?.id}/?logId=${log?.data?.id}`,
      );
      setState('allowed');
    } else {
      setState('notAllowed');
    }
  };

  useEffect(() => {
    void fetchTemplate();
  }, []);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#F5F6FB"
      spacing={4}
    >
      <img className={'xeniaLogo'} src={XeniaLogo} alt="xenia logo" />
      {state === 'loading' ? (
        <CircularProgress />
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          width="500px"
          spacing={2}
          bgcolor="#fff"
          py="32px"
          px="16px"
          borderRadius="24px"
          boxShadow="box-shadow: 0px 2.50798px 3.91578px 0px rgba(157, 153, 153, 0.02), 0px 6.21408px 9.9033px 0px rgba(157, 153, 153, 0.03), 0px 12.43566px 20.20179px 0px rgba(157, 153, 153, 0.04), 0px 25.10625px 41.61188px 0px rgba(157, 153, 153, 0.05), 0px 67px 114px 0px rgba(157, 153, 153, 0.07);"
        >
          {state === 'notAllowed' && (
            <>
              <svg
                width="94"
                height="79"
                viewBox="0 0 94 79"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.12"
                  d="M34.9997 64.1668C51.108 64.1668 64.1663 51.1085 64.1663 35.0002C64.1663 18.8919 51.108 5.8335 34.9997 5.8335C18.8914 5.8335 5.83301 18.8919 5.83301 35.0002C5.83301 51.1085 18.8914 64.1668 34.9997 64.1668Z"
                  fill="#757575"
                />
                <path
                  d="M64.1664 32.3335V35.0169C64.1628 41.3064 62.1261 47.4263 58.3602 52.4638C54.5943 57.5014 49.3009 61.1866 43.2695 62.9699C37.238 64.7532 30.7917 64.5391 24.8919 62.3594C18.9921 60.1797 13.9549 56.1513 10.5316 50.875C7.10837 45.5986 5.4824 39.357 5.89623 33.0811C6.31006 26.8052 8.74153 20.8311 12.828 16.05C16.9145 11.2688 22.437 7.93672 28.5719 6.55062C34.7068 5.16451 41.1255 5.79867 46.8705 8.35853M64.1663 11.6668L34.9997 40.8627L26.2497 32.1127"
                  stroke="#757575"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="48"
                  y="33"
                  width="44"
                  height="44"
                  rx="22"
                  fill="#EF5350"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M64 51C64 47.6863 66.6863 45 70 45C73.3137 45 76 47.6863 76 51V52.1503C76.2826 52.2123 76.5539 52.3024 76.816 52.436C77.5686 52.8195 78.1805 53.4314 78.564 54.184C78.8113 54.6694 78.9099 55.1861 78.9558 55.7482C79 56.2894 79 56.9537 79 57.7587V59.2413C79 60.0463 79 60.7106 78.9558 61.2518C78.9099 61.8139 78.8113 62.3306 78.564 62.816C78.1805 63.5686 77.5686 64.1805 76.816 64.564C76.3306 64.8113 75.8139 64.9099 75.2518 64.9558C74.7106 65 74.0463 65 73.2413 65H66.7587C65.9537 65 65.2894 65 64.7482 64.9558C64.1861 64.9099 63.6694 64.8113 63.184 64.564C62.4314 64.1805 61.8195 63.5686 61.436 62.816C61.1887 62.3306 61.0901 61.8139 61.0442 61.2518C61 60.7106 61 60.0463 61 59.2413V57.7587C61 56.9537 61 56.2894 61.0442 55.7482C61.0901 55.1861 61.1887 54.6694 61.436 54.184C61.8195 53.4314 62.4314 52.8195 63.184 52.436C63.4461 52.3024 63.7174 52.2123 64 52.1503V51ZM74 51V52.0016H66V51C66 48.7909 67.7909 47 70 47C72.2091 47 74 48.7909 74 51ZM71 57.5C71 56.9477 70.5523 56.5 70 56.5C69.4477 56.5 69 56.9477 69 57.5V59.5C69 60.0523 69.4477 60.5 70 60.5C70.5523 60.5 71 60.0523 71 59.5V57.5Z"
                  fill="white"
                />
                <rect
                  x="48"
                  y="33"
                  width="44"
                  height="44"
                  rx="22"
                  stroke="white"
                  stroke-width="4"
                />
              </svg>

              <Typography
                fontSize="24px"
                fontWeight={600}
                color="#000000DE"
                textAlign="center"
              >
                Hmm...looks like this submission is restricted
              </Typography>
              <Typography fontSize="16px" color="#616161" textAlign="center">
                To access it, you’ll require certain permissions. Please contact
                your administrator for assistance.
              </Typography>
            </>
          )}
          <CustomButton
            variant="contained"
            onClick={() => navigateWithWorkspaceUrl('/dashboard')}
          >
            Go to Xenia Home
          </CustomButton>
        </Stack>
      )}
      <ChecklistPreviewModal />
    </Stack>
  );
};

export default InternalSubmissionShare;
