import { Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRoles } from 'store/selectors/roles';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';

const RoleRenderer = (props) => {
  const { member } = props;

  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;

  const { fetchCustomRoles } = useCustomRoles();

  const selectedRole = useMemo(() => {
    return rolesList?.find((item) => item?.id === member?.RoleId);
  }, [rolesList, member?.RoleId]);

  useEffect(() => {
    fetchCustomRoles();
  }, []);

  return (
    <Typography
      fontWeight="500"
      fontSize="13px"
      lineHeight="17.76px"
      color="#212121"
    >
      {selectedRole?.title || 'N/A'}
    </Typography>
  );
};

export default RoleRenderer;
