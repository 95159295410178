// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Chip = styled(Box)(() => ({
  borderRadius: '16px',
  backgroundColor: 'rgba(245, 245, 245, 1)',
  padding: '2px 8px',

  fontSize: '10px',
  fontWeight: 600,
  color: '#212121',
  lineHeight: '14px',
}));

export const AnonymousAvatar = styled(Box)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 24,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
