import { useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CorrectiveTasksTable from 'components/DataTables/Reports/CorrectiveTasksTable';
import { setDialog } from 'store/actions/dialogActions';
import {
  WidgetCnt,
  WidgetHeading,
  WidgetLoaderCnt,
  WidgetSubHeading,
} from './style';

function CorrectiveScheduledTasks(props) {
  const { data, title, loading } = props;
  const dispatch = useDispatch();

  const handleTaskClick = (taskId: string) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          taskId: taskId,
        },
      }),
    );
  };

  return (
    <WidgetCnt>
      <WidgetHeading>{title}</WidgetHeading>
      <WidgetSubHeading>{data?.summary?.total}</WidgetSubHeading>
      {loading ? (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      ) : (
        <Stack height={'400px'}>
          <CorrectiveTasksTable
            rowData={data?.table}
            onRowClick={handleTaskClick}
          />
        </Stack>
      )}
    </WidgetCnt>
  );
}

export default CorrectiveScheduledTasks;
