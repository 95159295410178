import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import { NameInputWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import { CreateLocationContext } from 'components/AllDialogs/locations/createLocation/context/context';

const LocationNameInput = ({
  editable,
  updateAction,
  location,
  setLocationNameError,
  locationNameError,
}) => {
  const handleInputChange = (e) => {
    setLocationNameError('');
    const value = e.target.value;
    updateAction({ name: value });
  };
  return (
    <NameInputWithLabel style={{ marginTop: 4 }}>
      <label>Location Name</label>
      {editable ? (
        <CustomInput
          muiLabel={false}
          grayInput={true}
          error={locationNameError}
          fieldProps={{
            placeholder: 'Enter location name',
            onChange: handleInputChange,
            value: location.name,
          }}
        />
      ) : (
        <label className={'heading'}>{location.name || '-'}</label>
      )}
    </NameInputWithLabel>
  );
};

export default LocationNameInput;
