// Icons
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

// Styled
import { FullWidthCellWrapper } from './styled';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import AddIcon from 'components/Icons/addIcon';
import { colors } from 'components/theme/constants';
import CTypography from 'components/CTypography';

const FullWidthCell = ({ handleClickStartButton }) => {
  return (
    <CustomButton
      onClick={handleClickStartButton}
      sx={{ fontWeight: 700 }}
      startIcon={
        <AddCircleOutlineRoundedIcon
          sx={{
            color: 'rgba(78, 72, 250, 1)',
            fontSize: '22px',
            marginRight: '-4px',
          }}
        />
      }
    >
      ADD
    </CustomButton>
    // <FullWidthCellWrapper onClick={handleClickStartButton}>
    //   <AddIcon style={{ color: colors.white, fontSize: 17 }} />
    //   <CTypography className="content">Add</CTypography>
    // </FullWidthCellWrapper>
  );
};

export default FullWidthCell;
