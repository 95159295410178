import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AssetsIcon from 'components/Icons/assetsIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import IosSwitch from 'components/IosSwitch';
import RepeatIconMultiColor from 'components/Icons/repeatIconMultiColor';
import CalendarInputIcon from 'components/Icons/calendarInputIcon';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import { colors } from 'components/theme/constants';
import DeleteIcon from 'components/Icons/deleteIcon';

import { PROJECT_TYPES } from 'utils/globalVariable';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { setDialog } from 'store/actions/dialogActions';
import { deleteProject, togglePause } from 'api/projectsApi';
import { InfoChip, ProjectItemCnt, ProjectTypeCnt } from './style';
import ReplayIcon from '@mui/icons-material/Replay';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

interface ProjectItemPropTypes {
  project: any;
  deleteProjectHandler?: (projectId: string) => void;
  updateProjectHandler?: (project: any) => void;
  createProjectCallback?: () => void;
}

function ProjectGridItem({
  project,
  deleteProjectHandler,
  updateProjectHandler,
  createProjectCallback,
}: ProjectItemPropTypes) {
  const dispatch = useDispatch();
  const { getUtcToTz } = useDateTime();

  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const canPauseResumeProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_PAUSE_RESUME_PROJECT);
  }, [PERMISSIONS]);

  const canDeleteProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_DELETE_PROJECT);
  }, [PERMISSIONS]);

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const taskInterval = useMemo(() => {
    if (project?.NextTask?.[0]?.requestThisTask) {
      if (
        project?.NextTask?.[0]?.intervalWeek?.length === 7 &&
        project?.NextTask?.[0]?.requestThisTask === 'Weekly'
      ) {
        return 'Daily';
      }
      return project?.NextTask?.[0]?.requestThisTask;
    }
    return null;
  }, [project]);

  const PauseAllTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to pause all schedules in this project?
      </Typography>
    );
  };

  const DeleteProjectTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to delete this project ?
      </Typography>
    );
  };

  const projectPauseHandler = async (event) => {
    event?.stopPropagation();

    if (!project?.isPaused) {
      dispatch(
        setDialog({
          dialogId: 'confirmationDialog',
          open: true,
          data: {
            title: 'Pause All Schedules',
            ContentTemplate: PauseAllTemplate,
            onConfirmCallback: async () => {
              const response = await togglePause(project?.id, project?.title);
              if (response) {
                createProjectCallback?.();
              }
            },
            confirmationText: 'Pause All',
          },
        }),
      );

      return;
    }
    const response = await togglePause(project?.id, project?.title);
    if (response) {
      createProjectCallback?.();
    }
  };

  const NextDue = useMemo(() => {
    if (project?.NextTask?.[0]?.dueDate) {
      const TZDateTime = getUtcToTz(
        project?.NextTask?.[0]?.dueDate,
        'MMM DD, YYYY [at] h:mm A',
      );
      return TZDateTime;
    }
  }, [project]);

  const actionsDropdownHandler = async (options) => {
    switch (options?.id) {
      case 'delete':
        dispatch(
          setDialog({
            dialogId: 'confirmationDialog',
            open: true,
            data: {
              title: 'Delete Project',
              ContentTemplate: DeleteProjectTemplate,
              onConfirmCallback: async () => {
                await deleteProject(project?.id);
                deleteProjectHandler?.(project.id);
              },
              confirmationText: 'Delete Project',
            },
          }),
        );

        break;

      default:
        return null;
    }
  };

  return (
    <ProjectItemCnt
      clickable={'true'}
      onClick={() => {
        if (project?.state !== 'creating')
          navigateWithWorkspaceUrl(`/projects/${project?.id}`);
      }}
    >
      <div className={'content'}>
        <div className={'column'}>
          <ProjectTypeCnt>
            {project?.type === PROJECT_TYPES.userBasedProject ? (
              <UsersAndTeamsIcon className="userIcon" />
            ) : (
              <AssetsIcon className="assetIcon" />
            )}
            <Typography className="projectName">Project</Typography>
          </ProjectTypeCnt>

          <Typography className="title">{project?.title}</Typography>

          <Stack
            direction="row"
            gap="7px"
            alignItems="center"
            style={{ marginTop: 2 }}
          >
            <>
              {taskInterval && (
                <InfoChip>
                  <span className={'infoChipIcon'}>
                    <RepeatIconMultiColor
                      style={{ fontSize: 14, fill: 'none' }}
                    />
                  </span>
                  <Typography className="value">{taskInterval}</Typography>
                </InfoChip>
              )}
              {taskInterval && <span style={{ color: 'gray' }}>•</span>}
              {NextDue && (
                <Stack direction="row" alignItems="center" gap="5px">
                  <CalendarInputIcon
                    style={{
                      height: 14,
                      width: 15,
                      fill: 'none',
                    }}
                  />
                  <Typography className="value" style={{ paddingTop: 3 }}>
                    Next Due on {NextDue}
                  </Typography>
                </Stack>
              )}
            </>
          </Stack>
        </div>
        {project?.state === 'creating' ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={'center'}
            gap="5px"
            onClick={(e) => {
              e?.stopPropagation();
              createProjectCallback?.();
            }}
            style={{
              borderRadius: 42,
              width: 110,
              height: 42,
              backgroundColor: '#EBEAFF',
            }}
          >
            <ReplayIcon style={{ color: '#4E48FA', height: 20, width: 20 }} />
            <Stack direction="column">
              <Typography
                fontWeight="600"
                fontSize="12px"
                lineHeight="16.39px"
                color="#4E48FA"
              >
                Saving
              </Typography>
              <Typography
                fontWeight="600"
                fontSize="12px"
                lineHeight="16.39px"
                color="#4E48FA"
              >
                Reload it
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            className={'right'}
            style={{ borderLeft: '1px solid lightGray' }}
          >
            {canPauseResumeProject && (
              <IosSwitch
                height={20}
                width={32}
                checked={!project?.isPaused}
                onChange={projectPauseHandler}
                onClick={(e) => e.stopPropagation()}
                thumbSize={16}
                sx={{ marginLeft: 2 }}
              />
            )}

            {canDeleteProject && (
              <ActionDropdown
                paperProps={{
                  style: {
                    minWidth: 145,
                  },
                }}
                options={[
                  {
                    label: 'Delete',
                    id: 'delete',
                    icon: (
                      <DeleteIcon style={{ color: colors.red, fill: 'none' }} />
                    ),
                    iconAlign: 'start',
                    sx: {
                      color: `${colors.red}`,
                    },
                  },
                ]}
                handleOptionSelect={actionsDropdownHandler}
              >
                <MoreHorizIcon />
              </ActionDropdown>
            )}
          </Stack>
        )}
      </div>
    </ProjectItemCnt>
  );
}

export default ProjectGridItem;
