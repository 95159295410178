// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import CustomModal from 'components/CustomModal/CustomModal';

export const HeaderMainCnt = styled('div')({
  backgroundColor: 'white',
});

export const TitleCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const HeaderCnt = styled('div')({
  '& .titlefield': {
    padding: '5px 20px 0px 20px',
    width: '100%',

    '& input': {
      textAlign: 'center',
      '&::placeholder': {
        fontSize: 30,
        color: '#8A919B',
        lineHeight: '40.98px',
        fontWeight: 700,
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: 'unset',
    },
  },
  '& .titleReadOnly': {
    fontSize: 30,
    width: 500,
    fontWeight: 700,
    color: '#212121',
    padding: '5px 0',
    margin: '5px 20px 0px 20px',
    textAlign: 'center',
  },
});

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    borderRadius: 0,
  },
});

export const MainCnt = styled('div')({
  backgroundColor: '#EDF1F3',
  width: '100%',
  height: '100%',
});

export const EditorCnt = styled('div')(({ theme }) => ({
  margin: '5px 130px 50px 130px',
  [theme.breakpoints.down('md')]: {
    margin: '5px 40px 50px 40px',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '5px 5px 50px 5px',
  },
}));

export const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const FirstRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid #E0E0E0',
  position: 'relative',
  justifyContent: 'end',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
  },
  padding: '12px 24px',
  minHeight: 60,
}));

export const RightCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const GoBackWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  left: 24,
  gap: 4,
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    position: 'unset',
    left: 'unset',
    gap: 'unset',
  },
  '& svg': {
    fontSize: 20,
  },
}));

export const BackText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '700',
  letterSpacing: '0.15px',
  lineHeight: '24px',
  color: 'rgba(66, 66, 66, 1)',
}));

export const ToggleBarCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  border: '0px solid #E0E0E0',
  justifyContent: 'center',

  '& svg': {
    color: 'rgba(117, 117, 117, 1)',
  },
  '& button': {
    height: 40,
    fontWeight: 700,
    fontSize: 14,
    color: 'rgba(66, 66, 66, 1)',
  },
  '& .selected': {
    backgroundColor: '#EBEAFF',
    border: '1px solid #6868FE !important',
    color: '#6868FE',
    '& svg': {
      color: '#6868FE',
    },
  },
}));

export const SecondRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid #E0E0E0',
  background: '#F5F6FB',
  position: 'relative',
  justifyContent: 'space-between',
  padding: 26,
}));

export const TemplateNameText = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: '700',
  letterSpacing: '0.15px',
  lineHeight: '38.5px',
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: '600px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
}));

export const LastUpdatedCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: 15,

  borderRight: '1px solid lightgray',
  paddingRight: 10,
  height: 35,
  '& .icon': {
    marginRight: 5,
    color: '#1C1B1F',
    height: 19,
  },
  '& p': {
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '0.4px',
    lineHeight: '19.92px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

export const SecondRowButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  gap: 16,
  right: 24,
}));

export const SettingsButton = styled(CustomButton)(() => ({
  '&:hover': {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.04), 0px 1px 0px 0px rgba(0,0,0,0.02)',
  },
}));

export const StyledButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'bg',
})(({ bg }) => ({
  padding: '6px 12px',
  marginRight: 10,
  fontSize: '14px',
  fontWeight: 600,
  letterSpacing: '0.4px',
  lineHeight: '24px',

  '& .MuiButton-startIcon': {
    marginRight: 2,
  },

  ...(bg && { background: bg }),
}));

export const FirstRowButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  gap: 12,
  right: 24,
}));

export const ShareButton = styled(CustomButton)(({ bg }) => ({
  padding: '3px 14px',
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0.4px',
  borderRadius: '6px',
  lineHeight: '24px',
  '& svg': {
    fontSize: 14,
  },
}));
