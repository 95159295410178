import { useContext, useEffect } from 'react';
import ListGridToggle from 'components/ListGridToggle';
import { DocumentsContext } from 'pages/documents/context/context';
import { getItem, setItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import FileTypesDropdown from 'pages/documents/subHeader/fileTypesDropdown';
import SortByDropdown from 'pages/documents/subHeader/SortByDropdown/sortByDropdown';
import AddDocumentFolderDropdown from 'pages/documents/subHeader/addDocumentFolderDropdown/addDocumentFolderDropdown';
import SearchInput from 'components/Form/TextField/SearchField.cmp';
import { FlexCnt, HeaderCnt } from 'pages/documents/subHeader/style';
import useResponsive from 'utils/CustomHooks/useResponsive';

import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';

function MyDocumentsHeader(props) {
  const { getFolderAndDocuments, ref } = props;
  const { state = {}, updateState } = useContext(DocumentsContext);
  const { searchTerm, selectedView, isArchive } = state;
  const isData =
    state?.data?.folders?.length > 0 || state?.data?.documents?.length > 0;

  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const updateToggleHandler = (selectedView) => {
    setItem(LOCALSTORAGE_KEYS.DOCUMENTS_SELECTED_VIEW, selectedView);
    updateState({ ...state, selectedView });
  };

  const handleChangeSearch = (e) => {
    const searchTerm = e.target.value;
    updateState({ searchTerm });
  };

  useEffect(() => {
    const selected = getItem(LOCALSTORAGE_KEYS.DOCUMENTS_SELECTED_VIEW);
    if (selected) {
      updateState({ ...state, selectedView: selected });
    }
  }, []);

  const sortChangeHandler = (selected) => {
    updateState({ ...state, sortBy: selected?.value });

    const isData =
      state?.data?.folders?.length > 0 || state?.data?.documents?.length > 0;

    if (isData) {
      let req = {};

      if (['createdAt, updatedAt'].includes(selected?.value)) {
        req = { sortBy: selected?.value, order: 'desc' };
      }

      if (selected?.value === 'asc') {
        req = { sortBy: 'title', order: selected?.value };
      }

      if (selected?.value === 'desc') {
        req = { sortBy: 'title', order: selected?.value };
      }

      updateState(req);

      const isData =
        state?.data?.folders?.length > 0 || state?.data?.documents?.length > 0;

      if (isData) {
        getFolderAndDocuments(req);
      }
    }
  };

  const fileTypesChangeHandler = (selected) => {
    const filtered = selected?.filter((item) => item?.value != 'all');
    const transformedData = filtered.map((obj) => obj.value).join(',');
    updateState({ fileTypes: transformedData || null });

    const isData =
      state?.data?.folders?.length > 0 || state?.data?.documents?.length > 0;

    if (isData) {
      getFolderAndDocuments({ fileTypes: transformedData || null });
    }
  };
  const handleOpenSearch = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.SEARCH_DOCUMENTS,
      }),
    );
  };
  return (
    <HeaderCnt ref={ref}>
      {isData && (
        <div className={'lefCnt'}>
          <SearchInput
            onClick={handleOpenSearch}
            fieldProps={{
              value: searchTerm,
            }}
            sx={{
              maxWidth: { sm: 'calc(100vw - 140px)', md: 250 },
              width: { sm: '100%', md: 350 },
            }}
          />
          {!isMobileDeviceSm && (
            <>
              <SortByDropdown onChangeCallback={sortChangeHandler} />
              <FileTypesDropdown onChangeCallback={fileTypesChangeHandler} />
            </>
          )}

          {isMobileDeviceSm && (
            <FlexCnt>
              <SortByDropdown onChangeCallback={sortChangeHandler} />
              <FileTypesDropdown onChangeCallback={fileTypesChangeHandler} />
            </FlexCnt>
          )}
        </div>
      )}
      <div className={'rightCnt'}>
        {!isMobileDeviceSm && isData && (
          <ListGridToggle
            selectedView={selectedView}
            setSelectedView={updateToggleHandler}
          />
        )}

        {!isArchive && <AddDocumentFolderDropdown />}
      </div>
    </HeaderCnt>
  );
}

export default MyDocumentsHeader;
