import { createSvgIcon } from '@mui/material';

const MonitorIcon = (props) => {
  const { primary = '#EBEAFF', secondary = '#3922D6' } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M6.44 2.33325H17.55C21.11 2.33325 22 3.22325 22 6.77325V13.1033C22 16.6633 21.11 17.5433 17.56 17.5433H6.44C2.89 17.5533 2 16.6633 2 13.1133V6.77325C2 3.22325 2.89 2.33325 6.44 2.33325Z"
        fill={primary}
        stroke={secondary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 17.5533V22.3333"
        stroke={secondary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 13.3333H22"
        stroke={secondary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 22.3333H16.5"
        stroke={secondary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'MonitorIcon',
  );
  return (
    <IconCmp
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    />
  );
};
export default MonitorIcon;
