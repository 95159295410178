import { useEffect, useMemo } from 'react';
import DashboardTemplateItem from 'pages/Dashboards/DashboardTemplates/dashboardTemplateItem/dashboardTemplateItem';
import { IDashboardTemplateItem } from 'pages/Dashboards/interfaces';
import { getReports } from 'api/reporting';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import { sortBy } from 'lodash';

function DashboardTemplates() {
  const dashboardTemplatesQuery = useQuery(
    [QUERY_KEYS.GET_DASHBOARD_TEMPLATES],
    getReports,
    { enabled: false },
  );

  useEffect(() => {
    dashboardTemplatesQuery.refetch();
  }, []);

  // Sort Data
  const sortedData = useMemo(() => {
    if (dashboardTemplatesQuery?.data?.length > 0) {
      const customOrder = [
        'task-summary',
        'schedule-report',
        'employee-productivity',
        'completed-work-report',
        'template-submissions',
        'template-submission',
      ];
      const sorted = sortBy(dashboardTemplatesQuery.data, (item) =>
        customOrder.indexOf(item?.slug),
      );
      return sorted;
    }

    return [];
  }, [dashboardTemplatesQuery?.data]);

  return (
    <>
      <WithLoader isLoading={dashboardTemplatesQuery.isFetching}>
        {sortedData?.map((item: IDashboardTemplateItem, i) => {
          return <DashboardTemplateItem key={item.id} item={item} />;
        })}
      </WithLoader>
    </>
  );
}

export default DashboardTemplates;
