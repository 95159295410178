import { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { map, compact, includes } from 'lodash';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { DoneOutlined } from '@mui/icons-material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { useSelector, useDispatch } from 'react-redux';
import xeniaApi from 'api/index';
import selectors from 'store/selectors';
import actions from 'store/actions';
import UserAvatar from 'components/Avatar';
import CustomButton from 'components/Button/CustomButton';
import ChecklistGrid from 'components/common/jsxrender/checklistV2/checklistGrid';
import ChecklistFilters from './ChecklistFilters';
import { searchChecklist } from './utils';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import appConstants from 'utils/appConstants';
import DIALOGS from 'utils/dialogIds';
import { flattenTemplateObject } from 'components/TemplateLibrary/utils';

interface Data {
  id: string;
  icon: {
    icon: string;
    color: string;
  };
  ChecklistTypeId;
  ChecklistIndustryId;
  ChecklistType: {
    id: string;
    name: string;
  };
  ChecklistIndustry: {
    id: string;
    name: string;
  };
  HotelId: string;
  color: string;
  name: string;
  Creator: {
    id: string;
    firstName: string;
    lastName: string;
  };
  ChecklistItems: any;
  actions: string;
}

function descendingComparator(a: any, b: any, orderBy: any) {
  let comparatorFn;
  switch (orderBy) {
    case 'ChecklistType':
      {
        comparatorFn = () => {
          if (a?.ChecklistType == null && b?.ChecklistType == null) return 0;
          if (a?.ChecklistType == null) return -1;
          if (b?.ChecklistType == null) return 1;
          const nameA = a?.ChecklistType?.name
            ? a?.ChecklistType?.name.toLowerCase()
            : '-';
          const nameB = b?.ChecklistType?.name
            ? b?.ChecklistType?.name.toLowerCase()
            : '-';
          return nameA == nameB ? 0 : nameA > nameB ? 1 : -1;
        };
      }
      break;
    case 'Creator':
      {
        comparatorFn = () => {
          if (a?.Creator == null && b?.Creator == null) return 0;
          if (a?.Creator == null) return -1;
          if (b?.Creator == null) return 1;
          const fullNameA = a?.Creator?.firstName
            ? a?.Creator?.firstName.toLowerCase() +
              a?.Creator?.lastName.toLowerCase()
            : '-';
          const fullNameB = b?.Creator?.firstName
            ? b?.Creator?.firstName.toLowerCase() +
              b?.Creator?.lastName.toLowerCase()
            : '-';
          return fullNameA == fullNameB ? 0 : fullNameA > fullNameB ? 1 : -1;
        };
      }
      break;
    case 'ChecklistIndustry':
      {
        comparatorFn = () => {
          if (a?.ChecklistIndustry == null && b?.ChecklistIndustry == null)
            return 0;
          if (a?.ChecklistIndustry == null) return -1;
          if (b?.ChecklistIndustry == null) return 1;
          const nameA = a?.ChecklistIndustry?.name
            ? a?.ChecklistIndustry?.name.toLowerCase()
            : '-';
          const nameB = b?.ChecklistIndustry?.name
            ? b?.ChecklistIndustry?.name.toLowerCase()
            : '-';
          return nameA == nameB ? 0 : nameA > nameB ? 1 : -1;
        };
      }
      break;
    case 'name':
      {
        comparatorFn = () => {
          if (a?.name == null && b?.name == null) return 0;
          if (a?.name == null) return -1;
          if (b?.name == null) return 1;
          return a?.name.toLowerCase() == b?.name.toLowerCase()
            ? 0
            : a?.name.toLowerCase() > b?.name.toLowerCase()
            ? 1
            : -1;
        };
      }
      break;
    case 'ChecklistItems':
      {
        comparatorFn = () => {
          if (a?.ChecklistItems == null && b?.ChecklistItems == null) return 0;
          if (a?.ChecklistItems == null) return -1;
          if (b?.ChecklistItems == null) return 1;
          return a?.ChecklistItems == b?.ChecklistItems
            ? 0
            : a?.ChecklistItems > b?.ChecklistItems
            ? 1
            : -1;
        };
      }
      break;

    default:
      return;
  }
  return comparatorFn();
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'ChecklistType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'ChecklistIndustry',
    numeric: false,
    disablePadding: false,
    label: 'Industry',
  },
  {
    id: 'Creator',
    numeric: false,
    disablePadding: false,
    label: 'Created by',
  },
  {
    id: 'ChecklistItems',
    numeric: true,
    disablePadding: false,
    label: 'No. of items',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

interface SortableTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
}

function SortableTableHead(props: SortableTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              whiteSpace: 'nowrap',
              padding: '12px 16px',
              width: headCell.label === 'NAME' ? '40%' : 'auto',
            }}
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const MuiIcon = ({ name, ...rest }) => {
  const IconComponent = iconToComponentMapping[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};

export default function PublicChecklist({ view, checklistScope }) {
  const myTemplates = useSelector(selectors.getChecklistCompleteDataOnly);
  const checklistList = useSelector(selectors.getPublicChecklists);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('name');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [filteredChecklists, setFilteredChecklists] = useState<Data[]>([]);
  const selectedFilters = useSelector(selectors.getChecklistFilters);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const getSearchText: any = useSelector(selectors.getSearchTextFromStore);
  const hotelId = useSelector(selectors.getUserHotelId);
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const myTemplateParentIds = useMemo(
    () => compact(map(myTemplates, 'parentId')),
    [myTemplates?.length],
  );
  const archiveChecklist = (checklistId: any) => {
    dispatch(actions.archiveChecklistApiCall(checklistId));
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    dispatch(actions.getChecklistTypes());
    dispatch(actions.getChecklistIndustries());
  }, []);

  useEffect(() => {
    const filteredChecklists = searchChecklist({
      selectedFilters,
      checklistList,
      searchTerm: getSearchText,
    });

    setFilteredChecklists(filteredChecklists);
  }, [checklistList, selectedFilters, getSearchText]);

  const onSave = async (checklistId) => {
    setDisableSaveButton(true);
    const duplicateResponse = await xeniaApi.duplicateChecklist({
      checklistId: checklistId,
      disableToast: true,
      saveToLibrary: true,
    });
    setDisableSaveButton(false);
    dispatch(
      actions.getChecklistCompleteList({
        detailed: true,
        includeArchived: true,
      }),
    );
    navigateWithWorkspaceUrl(`/checklist/${duplicateResponse.data.id}`);
  };

  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'edit':
        navigateWithWorkspaceUrl(`/checklist/${option.rowId}`);
        break;
      case 'archive':
        archiveChecklist(option.rowId);
        break;
      default:
        return;
    }
  };

  // open template preview modal
  const handleOpenTemplatePreviewModal = (templateId) => {
    const template = checklistList.find(
      (checklist: any) => checklist.id === templateId,
    );
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TEMPLATE_PREVIEW,
        open: true,
        data: {
          templates: flattenTemplateObject(filteredChecklists),
          selectedTemplate: template,
        },
      }),
    );
  };
  return (
    <Box sx={{ width: '100%' }}>
      {view === 'list' && (
        <>
          <ChecklistFilters />
          <TableContainer>
            <Table className="bg-white" sx={{ minWidth: 750 }} size={'small'}>
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {filteredChecklists && !!filteredChecklists.length && (
                <TableBody>
                  {filteredChecklists
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const alreadySaved = includes(
                        myTemplateParentIds,
                        row.id,
                      );

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          sx={{ height: '45px' }}
                        >
                          <TableCell component="th" id={labelId} scope="row">
                            <div
                              className="d-flex align-items-center pointer-cursor"
                              onClick={() =>
                                handleOpenTemplatePreviewModal(row.id)
                              }
                            >
                              <Box
                                sx={{ backgroundColor: row.icon.color }}
                                className="checklist-icon-container"
                                style={{ paddingTop: 4, paddingBottom: 6 }}
                              >
                                <MuiIcon
                                  name={row.icon.icon}
                                  sx={{ fontSize: '1rem' }}
                                />
                              </Box>
                              <div
                                className="checklist-name"
                                style={{ marginLeft: 7 }}
                              >
                                {row.name}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Box>{row.ChecklistType?.name}</Box>
                          </TableCell>
                          <TableCell>
                            <Box>{row.ChecklistIndustry?.name}</Box>
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                            <div className="d-flex align-items-center">
                              {row.Creator ? (
                                <>
                                  <Box className="mr-2">
                                    <UserAvatar
                                      width={28}
                                      height={28}
                                      userId={row.Creator.id}
                                      firstName={row.Creator.firstName}
                                    />
                                  </Box>
                                  <div>{`${row.Creator.firstName || ''}
                                ${row.Creator.lastName || ''}`}</div>
                                </>
                              ) : (
                                <>-</>
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            {
                              row.ChecklistItems.filter(
                                (ChecklistItem: any) =>
                                  ChecklistItem.type !== 'header',
                              ).length
                            }
                          </TableCell>
                          <TableCell align="right">
                            <div className="d-flex align-items-center float-right min-width-135 justify-content-end">
                              {hotelId !== row.HotelId && (
                                <CustomButton
                                  variant={
                                    alreadySaved ? 'contained' : 'outlined'
                                  }
                                  endIcon={
                                    alreadySaved ? <DoneOutlined /> : null
                                  }
                                  className={`py-1 px-3 ${
                                    alreadySaved ? 'text-white' : ''
                                  }`}
                                  disabled={disableSaveButton || alreadySaved}
                                  onClick={() => onSave(row.id)}
                                >
                                  {alreadySaved ? 'Saved' : 'Save & Edit'}
                                </CustomButton>
                              )}
                              {hotelId === row.HotelId && (
                                <ActionDropdown
                                  popperProps={{
                                    style: { width: 180 },
                                    placement: 'bottom-end',
                                  }}
                                  options={[
                                    {
                                      label: 'Edit',
                                      id: 'edit',
                                      icon: <EditOutlinedIcon />,
                                      iconAlign: 'start',
                                      rowId: row.id,
                                    },
                                    {
                                      label: 'Archive',
                                      id: 'archive',
                                      icon: <ArchiveOutlinedIcon />,
                                      iconAlign: 'start',
                                      rowId: row.id,
                                    },
                                  ]}
                                  handleOptionSelect={handleOptionSelect}
                                  buttonProps={{
                                    style: {
                                      padding: 2,
                                      color: '#7571C5',
                                    },
                                  }}
                                >
                                  {' '}
                                  <MoreHorizRoundedIcon />
                                </ActionDropdown>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
            {filteredChecklists && !filteredChecklists.length && (
              <Box className="d-flex justify-content-center mt-4">
                <i>
                  No public {appConstants.checklist.plural.lowercase} found!
                </i>
              </Box>
            )}
          </TableContainer>
        </>
      )}
      {view === 'grid' && <ChecklistGrid checklistScope={checklistScope} />}
    </Box>
  );
}
