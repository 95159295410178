import { useEffect } from 'react';

import actions from 'store/actions';
import { useDispatch } from 'react-redux';

import DIALOGS from 'utils/dialogIds';

const FileUploadTemplateMobile = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.FILE_UPLOAD_TEMPLATE_MOB,
        open: true,
      }),
    );
    return () => {
      dispatch(actions.closeDialog(DIALOGS.FILE_UPLOAD_TEMPLATE_MOB));
    };
  }, []);

  return <></>;
};

export default FileUploadTemplateMobile;
