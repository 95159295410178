import styled from '@mui/system/styled';

export const AbstractBackgroundCnt = styled('div')({
  background:
    'url(/assets/img/loginBackgroundImages/bg_bottom_center_right_triangles.svg), url(/assets/img/loginBackgroundImages/bg_bottom_left_half_ring.svg), url(/assets/img/loginBackgroundImages/bg_bottom_right_arrows.svg), url(/assets/img/loginBackgroundImages/bg_dots.svg), url(/assets/img/loginBackgroundImages/bg_top_left_half_circle.svg), url(/assets/img/loginBackgroundImages/bg_zigzag.svg), url(/assets/img/loginBackgroundImages/top_right_half_ring.svg), url(/assets/img/loginBackgroundImages/bg_left_vertical_center.svg)',
  height: '100vh',
  backgroundRepeat: 'no-repeat',
  backgroundPosition:
    'calc(100% - 450px) 100%, 0 100%, calc(100% - 40px) calc(100% - 20px), calc(100% - 100px) 41%, 64px 0, calc(100% - 200px) 82px, calc(100% - 64px) 0, 0 50%',
});

export const LeftNavCnt = styled('div')({
  width: '295px',
  backgroundColor: '#16140b !important',
  height: '100vh',
  justifyContent: 'center',
  display: 'flex',
  paddingTop: '200px',
  background:
    'linear-gradient(rgba(0,0,0,0.1),rgba(25,18,9,0.4)),url(/assets/img/loginBackgroundImages/xen_workspace_left_img.png);',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 0',
  backgroundSize: '295px',
});

export const WSCreateContainer = styled('div')({
  display: 'flex',
  '& .mainTitle': {
    fontSize: 34,
    fontWeight: '700',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .subtitle': {
    fontSize: 16,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

export const WSCreateHeader = styled('div')({
  height: '110px',
  backgroundColor: '#fff',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.01)',
  paddingLeft: '15%',
});
