// React
import { useContext, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import DownloadOptions from 'components/AllDialogs/logs/DownloadOptions';

// Styled
import {
  BulkActionSelection,
  BulkActionCnt,
  BulkActionItem,
  BulkActionCancelItem,
} from 'components/common/styledComponents/bulkAction.style';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';

// Icons
import ArchiveIcon from 'components/Icons/archiveIcon';
import DownloadIcon from 'components/Icons/downloadIcon';

// Context
import { SubmissionContext } from 'pages/TemplateDashboard/Tabs/Submissions/context';

// Constants
import DIALOGS from 'utils/dialogIds';

// Hooks
import useLogActions from 'pages/TemplateDashboard/Tabs/Submissions/hooks/useLogActions';

interface BulkActionPropTypes {
  selected: any[];
  onClearSelection: () => void;
}

const BulkActions = ({ selected, onClearSelection }: BulkActionPropTypes) => {
  const ctx = useContext(SubmissionContext);
  const dispatch = useDispatch();

  const { downloading, handleDownloadReportApi } = useLogActions();

  const [showDownloadConfirmation, setShowDownloadConfirmation] =
    useState(false);

  const handleBulkArchive = () => {
    ctx?.handleArchiveLogs(selected);
  };

  const handleOpenArchiveConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.CONFIRMATION,
        open: true,
        data: {
          title: 'Archive Submissions',
          description:
            'Are you sure you want to archive the selected submissions?',
          confirmButtonProps: {
            color: 'error',
          },
          onConfirmCallback: () => handleBulkArchive(),
        },
      }),
    );
  };

  const handleOpenDownloadConfirmation = () => {
    setShowDownloadConfirmation(true);
  };

  const handleBulkDownload = async (option: any) => {
    await handleDownloadReportApi({
      logIds: selected.map((submission) => submission.id),
      option: option.id,
    });
    setShowDownloadConfirmation(false);
  };

  return (
    <>
      {selected?.length ? (
        <BulkActionCnt>
          <BulkActionSelection>
            <span className={'count'}>{selected.length}</span>
            <span className={'selectedText'}>
              Submission{selected?.length > 1 ? 's' : ''}
            </span>
          </BulkActionSelection>
          <BulkActionItem
            onClick={handleOpenDownloadConfirmation}
            noborder={'true'}
          >
            <DownloadIcon />
            <span className="actionText">Download</span>
          </BulkActionItem>
          <BulkActionItem
            onClick={handleOpenArchiveConfirmation}
            noborder={'true'}
          >
            <ArchiveIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            <span className="actionText">Archive</span>
          </BulkActionItem>
          <BulkActionCancelItem onClick={onClearSelection}>
            {' '}
            <CloseIcon className={'actionIcon'} />
          </BulkActionCancelItem>
        </BulkActionCnt>
      ) : (
        <></>
      )}
      {showDownloadConfirmation && (
        <DownloadOptions
          loading={downloading}
          multiple
          open={showDownloadConfirmation}
          handleClose={() => {
            setShowDownloadConfirmation(false);
            onClearSelection();
          }}
          onSelect={(option) => handleBulkDownload(option)}
        />
      )}
    </>
  );
};

export default BulkActions;
