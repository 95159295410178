import {
  MainCnt,
  Value,
  CellContentWrapper,
  ActionBtn,
  ActionBtnCnt,
} from 'pages/Dashboards/DashboardListing/listView/list.style';
import UserAvatar from 'components/Avatar';
import moment from 'moment-timezone';
import ListActions from 'pages/Dashboards/DashboardListing/listView/renderers/actions/actions';
import DashboardActionDropdown from 'pages/Dashboards/dashboardActionDropdown';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { DraftChip } from 'components/common/jsxrender/checklistV2/checklistGrid/styled';
import { EditOutlined } from '@mui/icons-material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

export const rowHeight = 62;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};
const CreatedByRenderer = ({ userId }: { userId: string }) => {
  const hashedUsers = useSelector(selectors.getHashedUserList);
  const user = hashedUsers?.[userId];
  const fullName = `${user.firstName || ''} ${user.lastName || ''}`;
  return (
    <MainCnt>
      <UserAvatar width={24} height={24} userId={userId} />
      <Value style={{ marginLeft: 10 }}>{fullName}</Value>
    </MainCnt>
  );
};

const ViewActionButton = ({ id }: { id: string }) => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const handlePreviewButton = () => {
    navigateWithWorkspaceUrl(`/dashboards/${id}`);
  };
  return (
    <ActionBtn
      variant="contained"
      style={{ marginRight: 5 }}
      onClick={handlePreviewButton}
      color="primary"
    >
      View
    </ActionBtn>
  );
};
export const columns: any = ({ actionDropodwnProps }) => {
  return [
    {
      field: 'title',
      headerName: 'Name',
      colId: 'title',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      width: 250,
      cellRenderer: cellRenderer.title,
    },
    {
      field: 'CreatedBy',
      headerName: 'Created by',
      colId: 'CreatedBy',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 200,
      valueGetter: valueGetters.createdBy,
      cellRenderer: cellRenderer.createdBy,
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      colId: 'createdAt',
      minWidth: 100,
      valueGetter: valueGetters.createdOn,
      cellRenderer: cellRenderer.createdOn,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Last updated',
      colId: 'updatedAt',
      minWidth: 100,
      valueGetter: valueGetters.lastUpdated,
      cellRenderer: cellRenderer.lastUpdated,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      width: 110,
      suppressMenu: true,
      cellClass: 'disableRowClick',
      cellRenderer: (params) =>
        cellRenderer.actionColumn(params, actionDropodwnProps),
    },
  ];
};

// Cell Renderer
export const cellRenderer = {
  title: (params) => {
    if (!params?.data) return;

    return (
      <div>
        <CellContentWrapper data-attribute="cell-click">
          <span
            style={{
              fontWeight: 700,
              fontSize: 14,
              lineHeight: '19.12px',
              color: '#212121',
            }}
          >
            {params?.data?.title}
          </span>
          {!params?.data?.publish && (
            <DraftChip
              icon={<EditOutlined style={{ fontSize: 12 }} />}
              size="small"
              label="Draft"
              color="success"
              sx={{ padding: '1px 3px' }}
            />
          )}
        </CellContentWrapper>
      </div>
    );
  },
  createdBy: (params) => {
    if (!params?.data) return;
    const data: any = params.data;
    return <CreatedByRenderer userId={data?.CreatedBy} />;
  },

  createdOn: (params) => {
    if (!params?.data) return;
    const { createdAt } = params.data;
    return (
      <MainCnt>
        <Value>{moment(createdAt).format('MMM DD, YYYY')}</Value>
      </MainCnt>
    );
  },

  lastUpdated: (params) => {
    if (!params?.data) return;
    const { updatedAt } = params.data;
    return (
      <MainCnt>
        <Value>{moment(updatedAt).format('MMM DD, YYYY')}</Value>
      </MainCnt>
    );
  },
  actionColumn: (params, actionDropodwnProps) => {
    if (!params?.data) return;
    return (
      <ActionBtnCnt data-attribute="cell-click">
        <ViewActionButton id={params?.data?.id} />
        <DashboardActionDropdown
          dashboardId={params?.data?.id}
          {...actionDropodwnProps}
        />
      </ActionBtnCnt>
    );
  },
};

// Value Getters

export const valueGetters = {
  name: (params) => {
    const { name } = params.data;
    return name || '-';
  },
  createdBy: (params) => {
    const { Creator } = params.data;
    return Creator?.firstName || '-';
  },
  createdOn: (params) => {
    const { createdAt } = params.data;
    return createdAt || '-';
  },
  lastUpdated: (params) => {
    const { updatedAt } = params.data;
    return updatedAt || '-';
  },
};
