import React, { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { getItem, postToMobileApp } from 'utils/globalFunction';
import xeniaApi from 'api/index';
import useUserLogin from 'utils/CustomHooks/useUserLogin';
import { useReactRouter } from 'controller/useReactRouter';
import { CircularProgress, Stack } from '@mui/material';
import XeniaLogoSymbol from 'public/assets/img/xenia-logo-symbol.svg';
import { MainTitle, SubTitle } from '../loginWithEmail/style';
import { isMobileApp, mobileOS } from 'utils/constants';

const LoginWithSSO: FC = (props) => {
  const [searchParams] = useSearchParams();
  const { handleRouterReplace } = useReactRouter();
  const { setLoggedInUser, setDeviceToken } = useUserLogin();

  const loginWithSSO = async () => {
    await setDeviceToken();
    const code = searchParams.get('code');
    // Mobile app flow
    if (isMobileApp) {
      if (code) {
        const payload = {
          code: code,
          device: mobileOS,
          accessType: 'APP',
          deviceToken: getItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN),
        };
        xeniaApi
          .loginBySSO(payload)
          .then((res) => {
            if (res) {
              postToMobileApp('loginWithUserData', res);
            }
          })
          .catch((e) => {
            // Do nothing in case of mobile app
          });
      }
    } else {
      if (code) {
        const payload = {
          code: code,
          device: 'WEB',
          accessType: 'WEB',
          deviceToken: getItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN),
        };
        xeniaApi
          .loginBySSO(payload)
          .then((res) => {
            if (res) {
              setLoggedInUser(res);
            }
          })
          .catch((e) => {
            handleRouterReplace('/sign-in');
          });
      } else {
        handleRouterReplace('/sign-in');
      }
    }
  };
  useEffect(() => {
    loginWithSSO();
  }, []);

  return (
    <>
      <img
        src={XeniaLogoSymbol}
        className="logo"
        height={40}
        width={70}
        style={{ marginBottom: 20 }}
      />
      <MainTitle>Login to Xenia</MainTitle>
      <SubTitle>Please wait.</SubTitle>

      <Stack>
        <CircularProgress />
        loading...
      </Stack>
    </>
  );
};

export default LoginWithSSO;
