import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CTabs from 'components/CTabs';
import { TabsCnt } from 'pages/documents/style';
import DashboardTemplates from './DashboardTemplates/dashboardTemplates';
import DashboardsListing from './DashboardListing/dashboardsListing';
import { PERMISSIONS } from 'utils/constants';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

function DashboardTabs() {
  const [searchParams] = useSearchParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasPermission } = useCustomPermission();
  const navigate = useNavigate();
  const [tab, setTab] = useState('dashboard-templates');

  const tabs = useMemo(
    () => [
      {
        label: 'Dashboard Templates',
        content: () =>
          tab === 'dashboard-templates' ? <DashboardTemplates /> : <></>,
        path: 'dashboard-templates',
      },
      {
        label: 'Custom Dashboards',
        content: () =>
          tab === 'custom-dashboards' ? <DashboardsListing /> : <></>,
        path: 'custom-dashboards',
      },
    ],
    [tab],
  );

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_DASHBOARDS)) {
      navigateWithWorkspaceUrl('/dashboard');
      return;
    }
  }, []);

  useEffect(() => {
    const tab = searchParams.get('tab');

    if (tab) {
      setTab(tab);
    }
  }, [searchParams]);

  const getActiveTab = useCallback(
    (activeTab) => tabs.findIndex((t) => t.path === activeTab),
    [tab],
  );

  const onTabChangeCb = (activeTab: number) => {
    const path = tabs[activeTab].path;
    setTab(path);
    navigate(`${location.pathname}?tab=${path}`);
  };

  return hasPermission(PERMISSIONS.CAN_MANAGE_DASHBOARDS) ? (
    <TabsCnt>
      <CTabs
        data={tabs}
        onTabChangeCb={onTabChangeCb}
        activeTab={getActiveTab(tab)}
        variant="scrollable"
      />
    </TabsCnt>
  ) : null;
}

export default DashboardTabs;
