import { Box, styled } from '@mui/material';
import { colors } from 'components/theme/constants';

export const ChatSidebarStyled = styled(Box)(() => ({
  backgroundColor: colors.white,
  overflow: 'auto',
  height: 'calc(100vh - 180px)',
  paddingTop: 8,
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  marginBottom: 50,
  hr: {
    margin: 0,
  },
  '.headText': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '.gridBox': {
    height: 54,
    paddingLeft: 28,
    paddingRight: 10,
  },
  '.accordionLink': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: colors.light.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    span: {
      fontSize: 6,
      transform: 'rotateZ(180deg)',
      transition: 'all 0.3s ease',
      '&.icon-down-angle:before': {
        color: colors.light.text.secondary,
      },
    },
    '&.collapsed': {
      span: {
        transform: 'rotateZ(0deg)',
        transition: 'all 0.3s ease',
      },
    },
  },
  '.btnInviteUsersBox': {
    display: 'flex',
    margin: 15,
    '.btnInviteUsers': {
      border: '1px dashed rgba(0, 0, 0, 0.3)',
      color: colors.light.text.secondary,
      flexGrow: 1,
      height: 30,
      padding: 0,
      fontSize: 12,
      gap: 7,
      '&:hover, &:active, &:focus': {
        borderColor: colors.primary.main,
        color: colors.primary.main,
        '& svg': {
          path: {
            stroke: colors.primary.main,
            strokeOpacity: 1,
          },
        },
      },
      '&:active, &:focus': {
        background: colors.primary.p50,
      },
    },
  },
  '.btnAdd': {
    margin: 0,
    padding: 0,
    height: 32,
    width: 32,
    minWidth: 'unset',
    borderRadius: 360,
    border: '1px solid rgba(0, 0, 0, 0.08)',
    color: colors.light.text.secondary,
    '&:hover,&:focus': {
      borderColor: colors.primary.main,
      color: colors.primary.main,
    },
    '&:focus': {
      background: '#EBEAFF',
    },
  },
  ul: {
    '.selected': {
      background: '#f5f5f5',
    },
    li: {
      display: 'flex',
      position: 'relative',
      paddingRight: '10px',
      padding: '8px 10px 8px 25px',
      border: '1px solid white',
      transition: 'all 0.3s',
      cursor: 'pointer',
      '&:hover': {
        background: colors.primary.p50,
        '& .actionBtnWrap': {
          display: 'flex',
        },
        '& .timeMessage': {
          display: 'none',
        },
      },
      '&:active, &.active': {
        background: colors.primary.p50,
      },
      '.leftMenuChannelHeader': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '.channel-unread-mute-status': {
          display: 'flex',
          justifyContent: 'space-between',
          span: {
            marginLeft: '12px',
          },
        },
      },
      '&:hover .channel-unread-mute-status': {
        display: 'none !important',
      },
      '.link': {
        width: '100%',
        '.unreadCount': {
          background: '#6868fe',
          borderRadius: '4px',
          width: '24px',
          height: '24px',
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px',
          fontFamily: "'Manrope'",
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
        },
        h5: {
          fontFamily: "'Manrope' !important",
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '24px',
          color: '#BDBDBD',
        },
        span: {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16px',
          color: colors.light.text.secondary,
          display: 'block',
        },
      },
      '.actionBtnWrap': {
        '&.absolute': {
          position: 'absolute',
        },
        right: '3px',
        top: '13px',
        display: 'none',
        '&::after': {
          display: 'none',
        },
        '.dropdownMenu': {
          borderRadius: 8,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.22)',
          '& a': {
            fontFamily: '"Manrope"',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '24px',
          },
        },
      },
      '.avatarContainer': {
        maxWidth: 32,
      },
      '.userIcon': {
        maxHeight: `32px !important`,
        minWidth: `32px !important`,
        borderRadius: '25px',
      },
      '.dropdownMenu': {
        '.actionArchiveText': {
          color: colors.light.text.secondary,
        },
        '.actionMuteText': {
          color: colors.light.text.primary + ' !important',
        },
      },
    },
  },
}));
