import { useContext, useMemo } from 'react';
import { MainCnt } from 'pages/documents/listing/listView/list.style';
import DocumentActions from 'pages/documents/actionDropdowns/documentActionDropdown/documentActionDropdown';
import ArchiveDocumentActionDropdown from 'pages/documents/actionDropdowns/documentActionDropdown/archiveDocumentActionDropdown';
import { DocumentsContext } from 'pages/documents/context/context';
import FolderActionDropdown from 'pages/documents/actionDropdowns/folderActionDropdown/folderActionDropdown';
import ArchiveFolderActionDropdown from 'pages/documents/actionDropdowns/folderActionDropdown/archiveFolderActionDropdown';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { WORKSPACE_ACCESS } from 'components/DocumentAccess/constants';

function ListActions(props) {
  const { row } = props;

  const {
    state = {},
    updateFolder,
    deleteFolder,
    onAddDocumentToFolder,
    addDocument,
    updateDocument,
    deleteDocument,
    getFolderAndDocuments,
  } = useContext(DocumentsContext);

  const currentUserId: any = useSelector(selectors.getUserId);
  const { isArchive, selectedFolder } = state;

  const archivedFoldersDropdownProps = {
    deleteFolderCallback: deleteFolder,
  };

  const foldersDropdownProps = {
    updateFolderCallback: updateFolder,
    archiveFolderCallback: () => getFolderAndDocuments({}, false),
  };

  const documentDropdownProps = {
    updateDocumentCallback: updateDocument,
    deleteDocumentCallback: deleteDocument,
    onAddDocumentToFolder: onAddDocumentToFolder,
    addDocumentCallback: addDocument,
  };

  const archiveDocumentDropdownProps = {
    deleteDocumentCallback: deleteDocument,
  };

  const canEdit = useMemo(() => {
    const user = row?.DocumentAccess?.find(
      (user) => user?.AccessorId === currentUserId,
    );

    if (
      user?.canEdit ||
      row?.workspaceAccess === WORKSPACE_ACCESS.workspaceEdit
    ) {
      return true;
    }
    return false;
  }, [row]);

  return (
    <MainCnt data-attribute={'cell-click'} style={{ justifyContent: 'end' }}>
      {!row?.fileType && !isArchive && (
        <FolderActionDropdown folder={row} {...foldersDropdownProps} />
      )}

      {!row?.fileType && isArchive && (
        <ArchiveFolderActionDropdown
          folder={row}
          {...archivedFoldersDropdownProps}
        />
      )}

      {row?.fileType && isArchive && canEdit && (
        <ArchiveDocumentActionDropdown
          document={row}
          {...archiveDocumentDropdownProps}
        />
      )}

      {row?.fileType && !isArchive && canEdit && (
        <DocumentActions
          document={row}
          selectedFolder={selectedFolder}
          {...documentDropdownProps}
        />
      )}
    </MainCnt>
  );
}

export default ListActions;
