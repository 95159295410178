import { createSvgIcon } from '@mui/material';

const BookCallIcon = (props) => {
  const { primary = '#EBEAFF', secondary = '#3922D6' } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M7.61073 10.4954C8.42273 12.1867 9.52965 13.7717 10.9315 15.1736C12.3333 16.5754 13.9184 17.6824 15.6096 18.4943C15.7551 18.5642 15.8278 18.5991 15.9199 18.6259C16.247 18.7213 16.6486 18.6528 16.9256 18.4545C17.0035 18.3986 17.0702 18.332 17.2036 18.1986C17.6115 17.7907 17.8154 17.5868 18.0205 17.4535C18.7938 16.9506 19.7909 16.9506 20.5642 17.4535C20.7693 17.5868 20.9732 17.7907 21.3811 18.1986L21.6085 18.4259C22.2285 19.046 22.5385 19.356 22.7069 19.6889C23.0418 20.3511 23.0418 21.133 22.7069 21.7952C22.5385 22.1281 22.2285 22.4382 21.6085 23.0582L21.4246 23.2421C20.8067 23.86 20.4977 24.1689 20.0777 24.4049C19.6116 24.6667 18.8877 24.8549 18.3531 24.8534C17.8713 24.8519 17.5421 24.7585 16.8835 24.5716C13.3446 23.5671 10.0052 21.6719 7.21918 18.8859C4.4332 16.0999 2.53798 12.7605 1.53352 9.22154C1.34661 8.56302 1.25315 8.23376 1.25172 7.75199C1.25013 7.2174 1.43838 6.49349 1.70021 6.0274C1.93616 5.60736 2.24511 5.29841 2.863 4.68052L3.04691 4.49661C3.66693 3.8766 3.97693 3.56659 4.30988 3.39819C4.97203 3.06327 5.75401 3.06327 6.41616 3.39819C6.74911 3.56659 7.05912 3.8766 7.67913 4.49661L7.90648 4.72396C8.31436 5.13184 8.51829 5.33577 8.65163 5.54085C9.15445 6.31422 9.15445 7.31123 8.65162 8.0846C8.51829 8.28968 8.31435 8.49361 7.90648 8.90149C7.77312 9.03485 7.70643 9.10153 7.65062 9.17948C7.45227 9.45648 7.38379 9.85812 7.47913 10.1852C7.50596 10.2772 7.54088 10.35 7.61073 10.4954Z"
        fill={primary}
      />
      <path
        d="M15.3917 6.00001C16.5312 6.22234 17.5785 6.77964 18.3994 7.6006C19.2204 8.42156 19.7777 9.46882 20 10.6083M15.3917 1.33334C17.7592 1.59635 19.9669 2.65655 21.6523 4.33985C23.3377 6.02316 24.4007 8.22952 24.6667 10.5967M10.9315 15.1736C9.52965 13.7717 8.42273 12.1867 7.61073 10.4954C7.54088 10.35 7.50596 10.2772 7.47913 10.1852C7.38379 9.85812 7.45227 9.45648 7.65062 9.17948C7.70643 9.10153 7.77312 9.03485 7.90648 8.90149C8.31435 8.49361 8.51829 8.28968 8.65162 8.0846C9.15445 7.31123 9.15445 6.31422 8.65163 5.54085C8.51829 5.33577 8.31436 5.13184 7.90648 4.72396L7.67913 4.49661C7.05912 3.8766 6.74911 3.56659 6.41616 3.39819C5.75401 3.06327 4.97203 3.06327 4.30988 3.39819C3.97693 3.56659 3.66693 3.8766 3.04691 4.49661L2.863 4.68052C2.24511 5.29841 1.93616 5.60736 1.70021 6.0274C1.43838 6.49349 1.25013 7.2174 1.25172 7.75199C1.25315 8.23376 1.34661 8.56302 1.53352 9.22154C2.53798 12.7605 4.4332 16.0999 7.21918 18.8859C10.0052 21.6719 13.3446 23.5671 16.8835 24.5716C17.5421 24.7585 17.8713 24.8519 18.3531 24.8534C18.8877 24.8549 19.6116 24.6667 20.0777 24.4049C20.4977 24.1689 20.8067 23.86 21.4246 23.2421L21.6085 23.0582C22.2285 22.4381 22.5385 22.1281 22.7069 21.7952C23.0418 21.133 23.0418 20.3511 22.7069 19.6889C22.5385 19.356 22.2285 19.046 21.6085 18.4259L21.3811 18.1986C20.9732 17.7907 20.7693 17.5868 20.5642 17.4535C19.7909 16.9506 18.7938 16.9506 18.0205 17.4535C17.8154 17.5868 17.6115 17.7907 17.2036 18.1986C17.0702 18.332 17.0035 18.3986 16.9256 18.4545C16.6486 18.6528 16.247 18.7213 15.9199 18.6259C15.8278 18.5991 15.7551 18.5642 15.6096 18.4943C13.9184 17.6824 12.3333 16.5754 10.9315 15.1736Z"
        stroke={secondary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={primary}
      />
    </>,
    'BookCallIcon',
  );
  return (
    <IconCmp
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    />
  );
};
export default BookCallIcon;
