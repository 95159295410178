// Redux
import { getUserHotelTz } from 'store/selectors/auth';
import { useSelector } from 'react-redux';

// MUI
import { Box, Grid } from '@mui/material';

// Custom components
import UserAvatar from 'components/Avatar';
import ChecklistLogReportAttachments from 'components/ChecklistReport/ChecklistLogReportAttachments';

// Utils
import DateTime from 'utils/DateTime';

import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import { NotesValuesCnt } from 'components/ChecklistReport/checklistReport.style';

const ChecklistLogItemNote = function ({ logItemNote }) {
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;
  return (
    <>
      <NotesValuesCnt>
        <Box className={'notesValuesInnerCnt'}>
          <Box
            sx={{
              color: 'rgba(33, 33, 33, 1)',
              fontWeight: 400,
              fontSize: '14px',
              letterSpacing: '0.15px',
            }}
          >
            {logItemNote.text}
          </Box>
          <Box
            sx={{
              color: 'rgba(97, 97, 97, 1)',
              fontWeight: 400,
              fontSize: '11px',
              letterSpacing: '0.15px',
            }}
          >
            {DateTime.fromUTCString(logItemNote.createdAt).toTzString(
              tz,
              'MMM DD YYYY',
            )}{' '}
            -{' '}
            {DateTime.fromUTCString(logItemNote.createdAt).toTzString(
              tz,
              'h:mm A',
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '4px',
            color: 'rgba(33, 33, 33, 1)',
            fontWeight: 500,
            fontSize: '12px',
            letterSpacing: '0.15px',
            alignItems: 'center',
          }}
        >
          <UserAvatar
            width={20}
            height={20}
            userId={logItemNote.Creator.id}
            firstName={logItemNote.Creator.firstName}
            profilePic={logItemNote?.Creator?.photo}
          />
          <Box>
            {`${logItemNote.Creator.firstName || ''}
                ${logItemNote.Creator.lastName || ''}`}
          </Box>
        </Box>
      </NotesValuesCnt>
      {!!logItemNote.attachments.length && (
        <Box className="mt-2">
          <Grid item xs={12} className="d-flex" sx={{ overflowX: 'scroll' }}>
            <ChecklistLogReportAttachments
              attachments={logItemNote.attachments}
            />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ChecklistLogItemNote;
