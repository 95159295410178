import { createSvgIcon } from '@mui/material';
import React from 'react';

const TemplateSubmissionsUsageIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="34" height="34" rx="8" fill="#9575CD" />
      <path
        opacity="0.12"
        d="M17.0002 26.1663C22.0628 26.1663 26.1668 22.0623 26.1668 16.9997C26.1668 11.9371 22.0628 7.83301 17.0002 7.83301C11.9376 7.83301 7.8335 11.9371 7.8335 16.9997C7.8335 22.0623 11.9376 26.1663 17.0002 26.1663Z"
        fill="#512DA8"
      />
      <path
        d="M26.1668 16.1616V17.0049C26.1657 18.9816 25.5256 20.905 24.3421 22.4883C23.1585 24.0715 21.4948 25.2297 19.5992 25.7902C17.7036 26.3506 15.6777 26.2833 13.8234 25.5983C11.9692 24.9132 10.3861 23.6472 9.31021 21.9889C8.23432 20.3306 7.7233 18.369 7.85337 16.3965C7.98343 14.4241 8.7476 12.5466 10.0319 11.0439C11.3162 9.54126 13.0519 8.49402 14.98 8.05839C16.9081 7.62276 18.9254 7.82206 20.731 8.62659"
        fill="#F3E5F5"
      />
      <path
        d="M26.1668 9.66634L17.0002 18.8422L14.2502 16.0922"
        fill="#F3E5F5"
      />
      <path
        d="M26.1668 16.1616V17.0049C26.1657 18.9816 25.5256 20.905 24.3421 22.4883C23.1585 24.0715 21.4948 25.2297 19.5992 25.7902C17.7036 26.3506 15.6777 26.2833 13.8234 25.5983C11.9692 24.9132 10.3861 23.6472 9.31021 21.9889C8.23432 20.3306 7.7233 18.369 7.85337 16.3965C7.98343 14.4241 8.7476 12.5466 10.0319 11.0439C11.3162 9.54126 13.0519 8.49402 14.98 8.05839C16.9081 7.62276 18.9254 7.82206 20.731 8.62659M26.1668 9.66634L17.0002 18.8422L14.2502 16.0922"
        stroke="#512DA8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TemplateSubmissionsUsageIcon',
  );
  return <IconCmp viewBox={'0 0 34 34'} fill="none" {...props} />;
};

export default TemplateSubmissionsUsageIcon;
