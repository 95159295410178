import { createSvgIcon } from '@mui/material';

const Volume2Icon = (props: any) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M8.25 3.75L4.5 6.75H1.5V11.25H4.5L8.25 14.25V3.75Z"
        stroke="#7571C5"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.3025 3.69751C15.7086 5.10397 16.4984 7.01128 16.4984 9.00001C16.4984 10.9887 15.7086 12.8961 14.3025 14.3025M11.655 6.34501C12.358 7.04824 12.753 8.00189 12.753 8.99626C12.753 9.99063 12.358 10.9443 11.655 11.6475"
        stroke="#7571C5"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'VolumeXIcon',
  );
  return (
    <IconCmp
      style={{ width: '18', height: '18', fill: 'none' }}
      viewBox="0 0 18 18"
    />
  );
};
export default Volume2Icon;
