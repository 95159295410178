import React, { useCallback, useEffect, useState } from 'react';
import { WidgetCnt } from 'pages/Widgets/TasksCompletedByEmployee/tasksCompletedByEmployee.style';
import { WidgetHeading, WidgetLoaderCnt } from 'pages/Widgets/widgets.style';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Cell,
} from 'recharts';
import xeniaApi from 'api/index';
import CircularProgress from '@mui/material/CircularProgress';
import { stringToHslColor } from 'helper/stringToColor';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import TasksByEmployeeEmptyState from 'pages/Widgets/TasksCompletedByEmployee/EmptyRecords/TasksByEmployeeWidgetEmptyState.cmp';
import EmployeeFilterDropdown from 'pages/Widgets/TaskFilters/EmployeeFilter/EmployeeFilter';

function TasksCompletedByEmployee({ filters, reload, setReload }) {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState<any>([]);
  const dispatch = useDispatch();
  const transformDataForChart = useCallback(
    (countsData) => {
      const transformedData = countsData?.map((d) => {
        return {
          name: d?.Finisher?.fullName,
          value: d?.count,
          id: d?.Finisher?.id,
        };
      });
      return transformedData;
    },
    [filters, data],
  );

  //Fetch Report data
  const fetchCompletedTasksByEmployee = async (customFilters = {}) => {
    setLoading(true);
    const requestPayload = {
      ...filters,
      ...customFilters,
    };
    const data = await xeniaApi.getCompletedTaskByEmployee(requestPayload);
    if (data) {
      const transformedData = transformDataForChart(data);
      setData(transformedData);
    }
    setLoading(false);
  };
  const handleEmployeeSelect = (option) => {
    setSelectedEmployee(option);
    const employeeIds = option.map((t) => t.id);
    const updatedFilters = {
      ...filters,
      ...(employeeIds.length > 0 && {
        completedBy: employeeIds,
      }),
    };
    fetchCompletedTasksByEmployee(updatedFilters);
  };
  //Load data on mount and when filters change
  useEffect(() => {
    fetchCompletedTasksByEmployee();
  }, [filters]);

  useEffect(() => {
    if (reload) {
      fetchCompletedTasksByEmployee();
    }
  }, [reload]);

  const handleViewTasks = async (id) => {
    const selectiveTaskList = await xeniaApi.getTasksList({
      completedBy: [id],
      dateKeys: ['endTime'],
      taskStatuses: ['Completed'],
      ...filters,
    });
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.SHOW_MORE_TASKS,
        data: {
          successCB: setReload ? () => setReload(true) : null,
          getRequest: {
            completedBy: [id],
            dateKeys: ['endTime'],
            taskStatuses: ['Completed'],
            ...filters,
          },
          tasks: selectiveTaskList,
          hideDate: true,
        },
      }),
    );
  };
  const handleClearFilter = () => {
    setSelectedEmployee([]);
    fetchCompletedTasksByEmployee();
  };
  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    const extractFirstWord = payload.value.split(' ')[0];
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  };
  return (
    <WidgetCnt>
      <WidgetHeading>
        Employee Completed Task Count{' '}
        <EmployeeFilterDropdown
          selected={selectedEmployee}
          onChangeCallback={(option) => handleEmployeeSelect(option)}
          onClearCallback={handleClearFilter}
        />
      </WidgetHeading>
      {loading && (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      )}
      {!loading && data?.length === 0 && <TasksByEmployeeEmptyState />}

      {!loading && data?.length > 0 && (
        <ResponsiveContainer minWidth={data.length * 40} height="100%">
          <BarChart
            style={{ fontSize: 12 }}
            width={400}
            height={400}
            data={data}
            margin={{
              top: 20,
              right: 0,
              left: -20,
              bottom: 50,
            }}
          >
            <CartesianGrid
              strokeDasharray="0"
              vertical={false}
              stroke={'#EEEEEE'}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              color={'red'}
              angle={-45}
              tick={(props) => <CustomizedAxisTick {...props} />}
              interval={0}
            />
            <YAxis
              type={'number'}
              domain={[0, 'auto']}
              tickCount={5}
              tickLine={false}
              axisLine={false}
            />
            <Tooltip />
            <Bar
              onClick={(e) => handleViewTasks(e?.payload?.id)}
              dataKey="value"
              fill="#8884d8"
              label={{ position: 'top' }}
              barSize={40}
              maxBarSize={40}
              radius={[5, 5, 0, 0]}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={stringToHslColor(entry?.name, '66', '67')}
                />
              ))}
            </Bar>
            {/*<Bar dataKey="uv" fill="#82ca9d" />*/}
          </BarChart>
        </ResponsiveContainer>
      )}
    </WidgetCnt>
  );
}

export default TasksCompletedByEmployee;
