/* eslint-disable @typescript-eslint/no-explicit-any */
import { updateChannelPartially } from 'services/getStreamServices';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';

export const useArchiveChannel = (): any => {
  const dispatch = useDispatch();

  const handleArchiveChannelSubmit = async (channel) => {
    const dataForUpdate = { isArchived: !channel.data.isArchived };
    await updateChannelPartially({
      channel,
      dataForUpdate,
    });

    dispatch(actions.setIsLoadFirst(true));
    dispatch(actions.setIsRefreshing(true));
  };

  return {
    handleArchiveChannelSubmit,
  };
};
