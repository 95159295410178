import React, { FC, useEffect, useState } from 'react';
import ButtonComponent from 'components/common/form/buttons';
import { Btntype } from 'interfaces/htmlTags';
import ImageComponent from 'components/common/htmlTags/image';
import { Copyrights } from 'components/common/jsxrender/copyrights';
import { getItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { ForgotPasswordInterFace } from 'models/index.m';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { useReactRouter } from 'controller/useReactRouter';
import GreenTick from 'public/assets/img/green-tick.svg';

const EmailConfirmation: FC = () => {
  const dispatch = useDispatch();
  const { handleRouterReplace } = useReactRouter();
  const [resendButtonEnabled, setResetButtonEnabled] = useState(true);

  useEffect(() => {
    if (!getItem(LOCALSTORAGE_KEYS.FORGOT_EMAIL)) {
      handleRouterReplace('/reset-password');
    }
  }, []);
  const resendForgotEmail = async () => {
    if (resendButtonEnabled) {
      setResetButtonEnabled(false);
      const resetPasswordData: ForgotPasswordInterFace = {
        emailId: getItem(LOCALSTORAGE_KEYS.FORGOT_EMAIL) as string,
      };
      dispatch(actions.forgotPassword(resetPasswordData));
      setTimeout(() => {
        setResetButtonEnabled(true);
      }, 3000);
    }
  };

  const goToLoginpage = () => {
    handleRouterReplace('/sign-in');
  };

  return (
    <div className="main-layout-outer d-flex align-items-center">
      <div className="layout-left" />
      <div className="login-layout">
        <div className="password-set-lauout email-confirmation-layout mt-3">
          <div className="password-set-form email-confirmation-form">
            <div className="iconbox">
              <ImageComponent src={GreenTick} alt="" width="36" height="24" />
            </div>
            <h2 className="mb-3" style={{ fontWeight: 100 }}>
              Email Sent!
            </h2>
            <p className="instruction">
              An email with instructions to change your
              <br />
              password has been sent to <br />
              <span className="changeLoginType">
                {getItem(LOCALSTORAGE_KEYS.FORGOT_EMAIL) as string}.
              </span>
            </p>
            <p className="sub-text mt-3 instruction">
              Please follow the steps to continue.
            </p>
            <div className="resend-info">
              <div className="form-group">
                <ButtonComponent
                  type={Btntype.BUTTON}
                  onClick={goToLoginpage}
                  className="btn btn-primary btnCenter w-100"
                >
                  <>
                    <span className="icon-left-angle"></span> Back To Home
                  </>
                </ButtonComponent>
              </div>
            </div>
            <div className="resendEmail">
              <h5 style={{ fontWeight: 100 }}> Didn’t receive email?</h5>
              <span
                onClick={resendForgotEmail}
                className={`font-weight-700 v2-orange-text ml-3 ${
                  !resendButtonEnabled && 'grey-text'
                }`}
                role="button"
              >
                Resend
              </span>
            </div>
            <Copyrights classexits="top20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
