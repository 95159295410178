import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from 'components/Icons/addIcon';
import ImgCustomRoles from 'public/assets/img/ImgCustomRoles.svg';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';
import { MainCnt } from './style';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const RolesEmptyState = () => {
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const dispatch = useDispatch();
  const createRoleHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CUSTOM_ROLES_SET_DETAILS,
      }),
    );
  };
  return hasPermission(PERMISSIONS.CAN_MANAGE_ROLES) ? (
    <MainCnt alignItems="center" justifyContent="center" gap="15px">
      <img src={ImgCustomRoles} />
      <Typography className="title">Create Your First Custom Role</Typography>
      <Typography className="subTitle">
        Define unique roles for different locations. Assign permissions to
        control access.
      </Typography>

      <CustomButton
        variant="contained"
        className="createBtn"
        onClick={createRoleHandler}
        startIcon={<AddIcon />}
      >
        Create New Role
      </CustomButton>
    </MainCnt>
  ) : (
    <MainCnt alignItems="center" justifyContent="center" gap="15px">
      <img src={ImgCustomRoles} />
      <Typography className="title">No Custom Role Created Yet</Typography>
      <Typography className="subTitle">
        Define unique roles for different locations. Assign permissions to
        control access.
      </Typography>
    </MainCnt>
  );
};

export default RolesEmptyState;
