import { useCallback } from 'react';
import CompletedWorkHeader from 'pages/ReportingV2/Reports/CompletedWork/CompletedWorkHeader/CompletedWorkHeader';
import { CompletedWorkContext } from 'pages/ReportingV2/Reports/CompletedWork/context/completedWorkContext';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { INITIAL_LAYOUT_TASK_COMPLETED_BY_REPORT } from 'components/CustomTilesGrid/utils';
import CustomDragTilesGrid from 'components/CustomDragTilesGrid';
import useReports from 'pages/ReportingV2/Reports/useReports';
import { REPORTING_CONFIG_CONSTANTS } from 'pages/ReportingV2/Reports/constants';
import TasksCompletedByEmployee from 'pages/Widgets/TasksCompletedByEmployee/tasksCompletedByEmployee';
import TasksCompletedByTeamMember from 'pages/Widgets/TasksCompletedByTeamMember/TasksCompletedByTeamMember';
import { transformLayouts } from 'utils/globalFunction';
import { THRESHOLD } from 'components/CustomDragTilesGrid/utils';

function CompletedWork() {
  const { workspaceId } = useWorkspaceHook();
  const {
    defaultProps,
    state,
    updateState,
    clearAllFilters,
    updateGridSettings,
  } = useReports(REPORTING_CONFIG_CONSTANTS.COMPLETED_WORK_REPORT);

  const transformedLayouts = useCallback(() => {
    return state?.gridSettings
      ? transformLayouts(
          state?.gridSettings,
          INITIAL_LAYOUT_TASK_COMPLETED_BY_REPORT,
          THRESHOLD,
        )
      : null;
  }, [state?.gridSettings]);

  return (
    <CompletedWorkContext.Provider
      value={{ state, updateState, clearAllFilters }}
    >
      <div>
        <CompletedWorkHeader />
        {/*<TaskStatusCounts {...defaultProps} isFilterApplied={isFilterApplied} />*/}

        {state?.isLoading ? null : (
          <div style={{ margin: '20px 30px' }}>
            <CustomDragTilesGrid
              key={workspaceId}
              customLayout={
                transformedLayouts() || INITIAL_LAYOUT_TASK_COMPLETED_BY_REPORT
              }
              onChangeCB={updateGridSettings}
              draggableHandle
            >
              <TasksCompletedByTeamMember
                key="tasksCompletedByTeamMember"
                {...defaultProps}
              />
              <TasksCompletedByEmployee
                key="tasksCompletedByEmployee"
                {...defaultProps}
              />
            </CustomDragTilesGrid>
          </div>
        )}
      </div>
    </CompletedWorkContext.Provider>
  );
}

export default CompletedWork;
