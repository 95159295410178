// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Chip } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

export const BannerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '14px 16px 14px 64px',
  background:
    'linear-gradient(270deg, #A4A3FF -3.06%, #C99FFF 50.43%, #8D8DFF 96.66%)',
  borderRadius: 12,
  boxShadow:
    '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)',
  width: '100%',
  position: 'relative',
  '& .mobile-in-hand-icon': {
    position: 'absolute',
    width: 70,
    left: 0,
    top: '-14px',
  },
}));

export const Headline = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '26.56px',
  color: 'rgba(255, 255, 255, 1)',
}));

export const ClickHereButton = styled(Button)(({ theme }) => ({
  padding: '8px 24px',
  marginLeft: 'auto',
  background:
    'linear-gradient(98deg, rgba(255, 255, 255, 0.16) -59.86%, rgba(96, 96, 96, 0.00) 95.96%)',
  color: 'rgba(255, 255, 255, 1)',
  borderRadius: 120,
  border: '1px solid #FFF',
  fontSize: '13px',
  fontWeight: '800',
  lineHeight: '22px',
  transition: '0.2s all',
  '&:hover': {
    background: '#fff',
    color: 'rgba(104, 104, 254, 1)',
    border: '1px solid #6868FE',
  },
}));

export const CrossButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: -8,
  top: -8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 20,
  width: 20,
  borderRadius: 120,
  background: 'rgba(158, 158, 158, 1)',
  color: 'rgba(255, 255, 255, 1)',
  border: '2px solid #FFF',
  cursor: 'pointer',
  '& svg': {
    color: '#fff',
    fontSize: 12,
  },
}));
