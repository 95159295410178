import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import CTypography from 'components/CTypography';
import { BookCall, VideoThumbnail } from './style';
import CrossIcon from 'public/assets/img/crossIcon.png';
import CustomButton from 'components/Button/CustomButton';
import CallIcon from 'components/Icons/callIcon2';
import selectors from 'store/selectors';
import actions from 'store/actions';
import YoutubeIcon from 'public/assets/img/youtube.svg';
import DIALOGS from 'utils/dialogIds';
import { TALK_TO_SALES_URL } from 'utils/constants';

const OnBoardingCall: FC<any> = (props: any) => {
  const { updateUserMeta } = props;
  const dispatch = useDispatch();
  const userMetaInfo: any = useSelector(selectors.getUserMeta);

  return (
    <BookCall>
      <IconButton
        className={'removeIconBtn'}
        onClick={() => {
          const postObj = {
            ...userMetaInfo,
            dashboard: { ...userMetaInfo?.dashboard, hideOnboardingCall: true },
          };
          updateUserMeta(postObj);
        }}
      >
        <img src={CrossIcon} className="cross" />
      </IconButton>

      <div className="content">
        <CTypography className="label">
          Managing your operation just got easier
        </CTypography>

        <CTypography className="subText">
          Whether it’s managing maintenance, daily team operations or quality
          assurance - Xenia has the capabilities to supercharge your workflow.
        </CTypography>

        {/* <CustomButton
          variant={'contained'}
          className={'startedGuideBtn'}
          color={'primary'}
          onClick={() => {
            window.open(
              'https://help.xenia.team/hc/en-us/articles/15317348296859',
              '_blank',
            );
          }}
        >
          Getting Started Guide
        </CustomButton> */}

        <CustomButton
          variant={'contained'}
          className={'bookCallBtn'}
          color={'primary'}
          endIcon={<CallIcon />}
          onClick={() => {
            dispatch(
              actions.setCalendlyInfo({
                url: TALK_TO_SALES_URL,
                open: true,
              }),
            );
          }}
        >
          Schedule Call With Product Specialist
        </CustomButton>
      </div>

      <VideoThumbnail
        onClick={() => {
          dispatch(
            actions.setDialog({
              dialogId: DIALOGS.PLAY_VIDEO,
              open: true,
              data: {
                videoUrl: 'https://www.youtube.com/watch?v=UXsc-k6c8Ow',
                styles: {
                  width: '800px',
                  height: '600px',
                },
              },
            }),
          );
        }}
      >
        <img
          src="https://img.youtube.com/vi/UXsc-k6c8Ow/maxresdefault.jpg"
          className="guideThumbnail"
          alt="xenia_guide"
        />
        <img src={YoutubeIcon} className="youtubeIcon" />
      </VideoThumbnail>
    </BookCall>
  );
};

export default OnBoardingCall;
