/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { ChannelMessagesRenderProps } from 'models/index.m';
import { LeftMenuChannelActions } from './LeftMenuChannelActions';
import { ChannelUnreadMuteStatus } from './ChannelUnreadMuteStatus';
import UserPlaceholderIcon from 'public/assets/img/Image.png';
import { isMutedChannel } from 'services/getStreamServices';
import { ListSingleChatCard } from './ListSingleChat.style';
import { Badge, List, ListItem } from '@mui/material';
import VolumeImg from 'public/assets/img/volume-x.svg';
import UserAvatar from 'components/Avatar';

export const ChannelMessagesRender: FC<ChannelMessagesRenderProps> = ({
  index,
  singleData,
  handleSetChannel,
  setIsArchiveShow,
  openChannelActionModal,
  selectedChannelId,
}: ChannelMessagesRenderProps) => {
  const channel: any = singleData;
  const isMute = (channel && isMutedChannel(channel)) || false;
  return (
    <React.Fragment key={channel.id}>
      <List style={{ minHeight: '52px', padding: 0 }}>
        <ListItem
          className={`click  ${
            selectedChannelId === channel?.id && 'selected'
          }`}
          onClick={() => {
            setIsArchiveShow(false);
            handleSetChannel(channel);
          }}
        >
          <ListSingleChatCard>
            <div className="avatarContainer">
              <Badge
                color="error"
                badgeContent={parseInt(channel?.state?.unreadCount)}
              >
                <UserAvatar
                  width={32}
                  height={32}
                  userId={channel?.data?.id}
                  firstName={channel?.data?.name}
                />
              </Badge>
            </div>
            <div className="nameContainer">
              <div
                className="leftMenuChannelHeader nameBox"
                style={{ marginRight: '-22px' }}
              >
                <h5>{channel?.data?.name}</h5>
                {isMute && (
                  <span className="mutedChannel">
                    <img src={VolumeImg} />
                  </span>
                )}
              </div>
            </div>
          </ListSingleChatCard>
          <LeftMenuChannelActions
            channel={channel}
            openChannelActionModal={openChannelActionModal}
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
};
