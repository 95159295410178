import CTabs from 'components/CTabs';
import AllSchedules from 'pages/Schedules/AllSchedules/AllSchedules';
import { Container } from 'pages/Schedules/style';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function AgendaView() {
  const { tab } = useParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const tabs = useMemo(
    () => [
      {
        label: 'Schedules',
        content: () => (tab === 'schedules' ? <AllSchedules /> : <></>),
        path: 'schedules',
      },
    ],
    [tab],
  );

  useEffect(() => {
    if (!tab) {
      navigateWithWorkspaceUrl(`/agenda-view/schedules`);
    }
  }, []);

  //Return object of tab based on the url path
  const getActiveTab = useCallback(
    (activeTab) => tabs.findIndex((t) => t.path === activeTab),
    [tab],
  );

  //Callback function to change the tab based on the url path
  const onTabChangeCb = (activeTab: number) => {
    const path = tabs[activeTab].path;
    navigateWithWorkspaceUrl(`/agenda-view/${path}`);
  };

  return (
    <Container>
      <CTabs
        data={tabs}
        onTabChangeCb={onTabChangeCb}
        activeTab={getActiveTab(tab)}
        variant="scrollable"
      />
    </Container>
  );
}

export default AgendaView;
