// React
import { useState, useEffect } from 'react';

// Redux
import store from '../../../store';

// MUI components
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import CustomButton from 'components/Button/CustomButton';

// Custom icons
import { StyledEndIconCnt } from 'components/Dropdown/DatePicker/datePicker.style';
import CalendarIcon from 'components/Icons/calendarIcon';

// Styled components
import {
  DateCnt,
  DigitalClock,
} from 'components/Dropdown/DatePicker/datePicker.style';

// Utilities
import { DayPicker } from 'react-day-picker-latest';
import 'components/Dropdown/DatePicker/style.css';
import DateTime from 'utils/DateTime';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import moment from 'moment/moment';
import useDateTime, {
  dateFormatUTC,
  tzDateFormat,
} from 'utils/CustomHooks/useDateTime';
import Stack from '@mui/material/Stack';
import { Divider, Typography } from '@mui/material';
import { TimeView } from '@mui/x-date-pickers';

export function DatePicker({ setSelectedDay, selectedDay, datePickerProps }) {
  const { disabled } = datePickerProps;
  return (
    <div className="customDatePicker">
      <DayPicker
        mode="single"
        selected={selectedDay}
        onSelect={setSelectedDay}
        disabled={disabled}
        fromYear={new Date().getFullYear() - 100}
        toYear={new Date().getFullYear() + 100}
        captionLayout="dropdown-buttons"
      />
    </div>
  );
}

export function DatePickerDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    children,
    onDateSelect,
    viewType = 'taskView',
    selected,
    datePickerProps = {},
    placeholder,
    mode = 'date',
    disabled = false,
    onTimeSelect = undefined,
    shouldDisableTime = undefined,
    dateFormatter = null,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDay, setSelectedDay] = useState<any>();
  const [selectedTime, setSelectedTime] = useState<any>(
    moment().startOf('day'),
  );
  const { getUtcToTz, getDateInUtc, getDateInTz } = useDateTime();

  useEffect(() => {
    if (selected) {
      if (mode === 'date') {
        const date = selected
          ? moment(getUtcToTz(selected, tzDateFormat)).toDate()
          : null;

        setSelectedDay(date || null);
      } else if (mode === 'dateTime') {
        const date = selected
          ? moment(getUtcToTz(selected, tzDateFormat))
          : null;

        const time = selected ? getUtcToTz(selected) : null;

        setSelectedDay(date?.toDate() || null);
        setSelectedTime(time || null);
      }
    } else {
      setSelectedDay(null);
    }
  }, [selected]);

  const handleDropdownOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };
  const tz =
    store.getState()?.authData?.user?.Hotel?.timezone || DEFAULT_TIMEZONE;

  const handleDateSelect = (date) => {
    const dateInTz = getDateInTz(date).format();
    const utcDate = getDateInUtc(dateInTz, dateFormatUTC);
    setSelectedDay(date);
    if (mode === 'date') {
      onDateSelect(utcDate);
      setAnchorEl(null);
    } else if (mode === 'dateTime' && selectedTime) {
      const dateInTz = getDateInTz(date, 'MMM DD YYYY');
      const timeInTz = getDateInTz(selectedTime, 'HH:mm');

      const dateTime = moment(dateInTz + ' ' + timeInTz).format(
        'YYYY-MM-DDTHH:mm:ss',
      );

      const dateTimeInUtc = getDateInUtc(dateTime);

      onDateSelect(dateTimeInUtc);
    }
  };

  const handleTimeSelect = (value) => {
    setSelectedTime(value);

    if (mode === 'dateTime' && selectedDay) {
      const dateInTz = getDateInTz(selectedDay, 'MMM DD YYYY');
      const timeInTz = getDateInTz(value, 'hh:mm A');

      const dateTime = moment(dateInTz + ' ' + timeInTz).format(
        'YYYY-MM-DDTHH:mm:ss',
      );

      const dateTimeInUtc = getDateInUtc(dateTime);

      onDateSelect(dateTimeInUtc);
      onTimeSelect?.(timeInTz);
    }
  };

  const handleShouldDisableTime = (value: moment.Moment, view: TimeView) => {
    return shouldDisableTime ? shouldDisableTime(value, view) : false;
  };

  const { buttonSx, buttonStyle, ...restButtonProps } = buttonProps;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ClickAwayListener
        onClickAway={handleDropdownClose}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <div>
          {viewType === 'taskView' && (
            <CustomButton
              disabled={disabled}
              variant="outlined"
              buttonType="grayWhite"
              onClick={handleDropdownOpen}
              endIcon={
                <StyledEndIconCnt>
                  {/*{selected && (*/}
                  {/*  <IconButton onClick={handleClearOptions}>*/}
                  {/*    <CloseIcon />*/}
                  {/*  </IconButton>*/}
                  {/*)}*/}
                  <CalendarIcon style={{ fontSize: 22 }} />
                </StyledEndIconCnt>
              }
              style={{
                width: '100%',
                justifyContent: 'space-between',
                padding: '6px 17px 6px 10px',
                height: 42,
                color: !selectedDay && '#9E9E9E',
                ...(buttonStyle || {}),
              }}
              sx={buttonSx || {}}
              {...restButtonProps}
            >
              {selectedDay
                ? dateFormatter
                  ? dateFormatter(selectedDay)
                  : `${moment(selectedDay).format('MM/DD/YYYY')} ${
                      mode === 'dateTime'
                        ? moment(selectedTime).format('hh:mm A')
                        : ''
                    }`
                : placeholder}
            </CustomButton>
          )}
          {viewType === 'tableView' && (
            <DateCnt onClick={handleDropdownOpen}>
              <CalendarIcon />
              <span>
                {selected
                  ? dateFormatter
                    ? dateFormatter(selected)
                    : DateTime.fromUTCString(selected).toTzString(
                        tz,
                        'MMM DD YYYY',
                      ) ?? '-'
                  : 'Select'}
              </span>
            </DateCnt>
          )}

          <CustomDropdown
            popperProps={{
              ...popperProps,
              content: (
                <Stack>
                  <Stack direction="row">
                    <DatePicker
                      setSelectedDay={handleDateSelect}
                      selectedDay={selectedDay}
                      datePickerProps={datePickerProps}
                    />
                    {mode === 'dateTime' && (
                      <Stack spacing={1} borderLeft="1px solid #0000001F">
                        <Typography
                          textAlign="center"
                          mt="8px"
                          fontSize="12px"
                          color="#000000DE"
                          fontWeight={700}
                        >
                          Time
                        </Typography>
                        <Divider />
                        <DigitalClock
                          value={selectedTime}
                          onChange={handleTimeSelect}
                          shouldDisableTime={handleShouldDisableTime}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              ),
              style: {
                ...popperProps.style,
                zIndex: 9999,
              },
              open: !!anchorEl,
              placement: 'bottom-start',
              anchorEl,
            }}
            clickaway={false}
          >
            {children}
          </CustomDropdown>
        </div>
      </ClickAwayListener>
    </LocalizationProvider>
  );
}
