/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import { useEffect } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import {
  NextButton,
  OrganizationInfoDiv,
  SelectWrapper,
} from './organizationInfo.style';
import { useOnboardState } from '../onboarding-provider';
import { useState } from 'react';
import xeniaApi from 'api/index';
import useUserLogin from 'utils/CustomHooks/useUserLogin';
import { isAndroidOrIOS } from 'utils/globalFunction';
import { useDispatch } from 'react-redux';
import CTypography from 'components/CTypography';
import { getIndustryIcon } from './utils';
import OnboardingPreview from 'components/TemplateLibrary/TemplatePreview/Onboarding/OnboardingPreview';
import OnboardingTemplateGroupPreview from 'components/TemplateLibrary/TemplatePreview/Onboarding/OnboardingTemplateGroupPreview';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const OrganizationInfo = (props) => {
  const { template, templateGroup, nextStep } = props;

  const [isLoading, setIsLoading] = useState(false);

  const {
    fullName,
    industry,
    setIndustry,
    organizationName,
    invalidOrganizationName,
    setOrganizationName,
    setInvalidOrganizationName,
    jobTitle,
    setJobTitle,
    hotelId,
  } = useOnboardState();

  const [industryTypes, setIndustryTypes] = useState([]);
  const [deviceType, setDeviceType] = useState('');

  // Fetch Industries on initial Load.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const industryResp = await xeniaApi.getIndustries(
          'hotel/checklist-industries',
        );

        if (industryResp.industries) {
          const sortedIndustries = sortIndustries(industryResp.industries);

          let industriesDropdown = sortedIndustries.map((item) => {
            return {
              label: item.name,
              value: item.id,
              id: item.id,
              startIcon: <img src={getIndustryIcon(item.name)} />,
            };
          });

          setIndustryTypes(industriesDropdown);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();

    // Check Is IOS or Android
    setDeviceType(isAndroidOrIOS());
  }, []);

  // Sort Industries
  const sortIndustries = (data) => {
    const order = [
      'Hotel',
      'Restaurant',
      'Retail',
      'Real Estate',
      'Construction',
      'Education',
      'Facility Management',
      'Vacation Rental',
      'Cleaning Services',
      'Senior Living',
      'Other',
    ];

    // Create a map to store the index of each category
    const orderMap = new Map();
    order.forEach((category, index) => {
      orderMap.set(category, index);
    });

    // Custom sorting function
    data.sort((a, b) => {
      const categoryA = a.name;
      const categoryB = b.name;

      // If both categories are in the order array
      if (orderMap.has(categoryA) && orderMap.has(categoryB)) {
        return orderMap.get(categoryA) - orderMap.get(categoryB);
      }
      // If only one category is in the order array
      else if (orderMap.has(categoryA)) {
        return -1;
      } else if (orderMap.has(categoryB)) {
        return 1;
      }
      // If neither category is in the order array, maintain original order
      else {
        return 0;
      }
    });

    return data;
  };

  // Handle Input Change - Organization Name
  const handleNameChange = (e) => {
    setInvalidOrganizationName('');
    setOrganizationName(e.target.value);
  };

  // Handle Input Change - Title
  const handleJobTitleChange = (e) => {
    setJobTitle(e.target.value);
  };

  // For Meta Info Change Handlers
  const industryChangeHandler = (e) => {
    setIndustry(e);
  };

  const validateIndustry = () => {
    if (industry) {
      return true;
    }
    return false;
  };

  // Handle Next Click
  const handleNextClick = async () => {
    nextStep();
  };

  return (
    <OrganizationInfoDiv>
      <p className="title">{`Welcome to Xenia, ${fullName}!`}</p>
      <p className="subTitle">
        Let us know more about you and your organization
      </p>
      {template && (
        <OnboardingPreview
          template={template}
          wrapper={{
            style: {
              margin: '0px 0px 10px 0px',
              maxWidth: 'unset',
            },
          }}
        />
      )}
      {templateGroup && (
        <OnboardingTemplateGroupPreview
          templateGroup={templateGroup}
          wrapper={{
            style: {
              margin: '0px 0px 10px 0px',
              maxWidth: 'unset',
            },
          }}
        />
      )}

      <div>
        <CTypography className="labelText">Organization</CTypography>
        <CustomInput
          id="companyNameInput"
          fieldProps={{
            onChange: handleNameChange,
            placeholder: 'Enter your organization name',
            value: organizationName,
            name: 'organizationName',
            className: 'orgName',
            style: {
              borderRadius: 8,
              height: 47,
            },
          }}
          error={invalidOrganizationName}
          style={{ marginBottom: 20 }}
        />
        <CTypography className="labelText">What is your title?</CTypography>
        <CustomInput
          id="jobTitleInput"
          fieldProps={{
            onChange: handleJobTitleChange,
            placeholder: 'Enter your title',
            value: jobTitle,
            name: 'jobTitle',
            className: 'orgName',
            style: {
              borderRadius: 8,
              height: 47,
            },
          }}
          style={{ marginBottom: 20 }}
        />

        <CTypography className="labelText">Industry</CTypography>

        <SelectWrapper
          selectedOption={industry}
          placeholder="What industry are you in?"
          options={industryTypes}
          styles={{ marginBottom: 20 }}
          onChange={industryChangeHandler}
        />

        <NextButton
          variant="contained"
          onClick={handleNextClick}
          buttonLoader={isLoading}
          style={{ width: '100%', margin: '5px 0px 10px 0px', height: 47 }}
        >
          {!deviceType ? 'Next' : 'Submit & Go to App'}
        </NextButton>
      </div>
    </OrganizationInfoDiv>
  );
};

export default OrganizationInfo;
