import { useState } from 'react';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import useResponsive from 'utils/CustomHooks/useResponsive';
import EmployeeFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/EmployeeFilter/EmployeeFilter';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
import LocationFilterDropdown from 'pages/Widgets/TaskFilters/LocationFilter/locationFilters';
import { FiltersCnt } from './style';

function ActivityLogsFilters(props) {
  const { isMobileDeviceSm } = useResponsive();
  const { filters, updateDateRangeCB, updateLocationCB, updateUsersCB } = props;
  const [selectedDateOption, setSelectedDateOption] = useState('Any');
  const [selectedUsersObjs, setSelectedUsersObjs] = useState([]);

  const handleDateChange = (date) => {
    setSelectedDateOption(date?.selectedDateOption);
    updateDateRangeCB(date);
  };

  const handleLocationsChange = (locations) => {
    const transfomedLocations = locations?.map((location) => location?.id);
    updateLocationCB(transfomedLocations);
  };

  const handleUsersChange = (users) => {
    setSelectedUsersObjs(users);
    const transformedUsers = users?.map((user) => user?.id);
    updateUsersCB(transformedUsers);
  };

  return (
    <FiltersCnt>
      <DateRangePickerCommon
        buttonProps={{
          hideSelected: true,
        }}
        handleDateChange={handleDateChange}
        selectedDateLabel={selectedDateOption}
        showLabelPrefix="Date Range: "
        selectedDateRange={filters?.date}
        contentProps={{
          displaySideOptions: !isMobileDeviceSm,
          allowInputs: !isMobileDeviceSm,
          numberOfMonths: isMobileDeviceSm ? 1 : 2,
          sideMenu: dateRangePickerSideMenuCommon,
        }}
        popperProps={{
          placement: 'bottom-start',
        }}
      />
      <LocationFilterDropdown
        selected={filters?.locations}
        onChangeCallback={(options) => handleLocationsChange(options)}
        hideRemoveIcon={true}
      />

      <EmployeeFilterDropdown
        onChangeCallback={(options) => handleUsersChange(options)}
        selected={selectedUsersObjs}
        labelText={'Users'}
      />
    </FiltersCnt>
  );
}

export default ActivityLogsFilters;
