import { createSvgIcon } from '@mui/material';

const NewFolderIcon = (props) => {
  const { style = {}, ...rest } = props;
  const IconCmp = createSvgIcon(
    <path
      d="M7.58268 4.08333L6.93195 2.78187C6.74467 2.4073 6.65102 2.22001 6.51131 2.08318C6.38777 1.96218 6.23887 1.87015 6.07539 1.81376C5.89053 1.75 5.68114 1.75 5.26235 1.75H3.03268C2.37929 1.75 2.05259 1.75 1.80303 1.87716C1.5835 1.98901 1.40503 2.16749 1.29317 2.38701C1.16602 2.63657 1.16602 2.96327 1.16602 3.61667V4.08333M1.16602 4.08333H10.0327C11.0128 4.08333 11.5028 4.08333 11.8772 4.27407C12.2064 4.44185 12.4742 4.70957 12.6419 5.03885C12.8327 5.4132 12.8327 5.90324 12.8327 6.88333V9.45C12.8327 10.4301 12.8327 10.9201 12.6419 11.2945C12.4742 11.6238 12.2064 11.8915 11.8772 12.0593C11.5028 12.25 11.0128 12.25 10.0327 12.25H3.96602C2.98592 12.25 2.49588 12.25 2.12153 12.0593C1.79225 11.8915 1.52453 11.6238 1.35675 11.2945C1.16602 10.9201 1.16602 10.4301 1.16602 9.45V4.08333ZM6.99935 9.91667V6.41667M5.24935 8.16667H8.74935"
      stroke="currentColor"
      strokeOpacity="0.54"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    'NewFolderIcon',
  );
  return (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 14 14'}
      {...rest}
    />
  );
};
export default NewFolderIcon;
