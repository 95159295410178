import styled from '@mui/system/styled';

export const FlagCategoriesEmptyStateCnt = styled('div')({
  width: 600,
  '& .img': {
    marginBottom: 5,
    height: 120,
    width: 120,
  },

  '& .title': {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '27.37px',
    textAlign: 'center',
    color: '#252350',
    marginBottom: 5,
  },

  '& .statement': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
    color: '#000000',
  },
});
