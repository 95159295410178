// MUI components
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from 'components/Button/CustomButton';

export const MainCnt = styled(Box)({
  padding: '20px 27px 10px 27px',
});

export const CreateNewBtn = styled(CustomButton)({
  borderRadius: '8px',
  fontSize: 14,
  height: 40,
  lineHeight: '19.12px',
  fontWeight: 500,
});

export const SectionCnt = styled(Box)({
  padding: '20px 24px 20px 24px',
  borderRadius: 12,
  border: '1px solid #E0E0E0',

  '& .title': {
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '24.59px',
    color: '#000000',
    marginBottom: 10,
  },

  '& .subTitle': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
    color: '#212121',
    marginBottom: 20,
  },
});
