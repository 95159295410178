// React
import { FC, useState, useEffect, useMemo, useRef } from 'react';

// React Router
import { useParams, useLocation } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// MUI components
import { Box, Dialog, CircularProgress, Typography } from '@mui/material';

// Custom components
import BootstrapModelComponent from 'components/common/htmlTags/bootstrapModel';
import ChecklistAttachmentPreview from 'components/common/jsxrender/checklistV2/checklistAttachmentPreview';
import Header from 'pages/checklistV2/Builder/Header';
import MetaDetailModal from 'pages/checklistV2/Builder/MetaDetailModal';
import StepTypes from 'pages/checklistV2/stepTypes';
import ChecklistItems from 'pages/checklistV2/Builder/checklistItems';
import Emulator from './Emulator/Emulator';

// Styled
import {
  StyledCustomModal,
  BodyWrapper,
  BuilderBody,
  LoaderWrapper,
} from './style';

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Utilities
import { compact, cloneDeepWith, map, sample, includes } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DIALOGS from 'utils/dialogIds';
import { _colors } from 'components/ColorPicker';
import IconButton from '@mui/material/IconButton';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { LearnMoreBtn } from 'components/common/jsxrender/checklistV2/builder/checklistItem.style';
import Stack from '@mui/material/Stack';
import xeniaApi from 'api/index';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { STEP_TYPES } from 'utils/templates';
import InstructionDialog from 'components/common/jsxrender/checklistV2/builder/InstructionDialog';
import useFlaggedCategoriesConfig from 'utils/CustomHooks/useFlaggedCategoriesConfig';
import { PERMISSIONS } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const ChecklistBuilder: FC = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const dispatch = useDispatch();
  const { checklistId: paramChecklistId, folderId = '' } = useParams();
  const location: any = useLocation();
  const { getUtcToTzTime } = useDateTime();

  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [showStepType, setShowStepType] = useState<boolean>(false);
  const [autoFocusItemId, setAutoFocusItemId]: any = useState(null);
  const [autofocusCursorPosition, setAutofocusCursorPosition] = useState<
    number | null
  >(0);
  const [isMetaModalOpen, setIsMetaModalOpen] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [viewToggle, setViewToggle] = useState('builder');
  const [isChecklistEditing, setIsChecklistEditing] = useState(false);
  const features = useSelector(selectors.getUserHotelFeatures);
  const [redirectTo, setRedirectTo] = useState<any>(null);
  const topAlertState = useSelector(selectors.getTopAlertState);

  const [checklist, setChecklist] = useState<any>(null);
  const [checklistItems, setChecklistItems] = useState<any>(null);
  const [defaultLocationStep, setDefaultLocationStep] = useState<string | null>(
    null,
  );
  const [automations, setAutomations] = useState<any>([]);
  const [addingItem, setAddingItem] = useState(false);
  const [sectionVisibility, setSectionVisibility] = useState<any>({});
  const [sectionEditingId, setSectionEditingId] = useState<any>(null);
  const [followupVisibility, setFollowupVisibility] = useState<any>({});
  const [itemCollapseState, setItemCollapseState] = useState({});
  const [lastSavedAt, setLastSavedAt] = useState<any>(null);
  const [uploadingItemId, setUploadingItemId] = useState<any>(null);
  const [instructionDialogOpen, setInstructionDialogOpen] = useState(false);
  const [currInstructionItemData, setCurrInstructionItemData] =
    useState<any>(null);
  const checklistItemsHashRef = useRef<any>(null);
  const checklistItemsArrayRef = useRef<any>(null);

  const { getFlaggedCategories } = useFlaggedCategoriesConfig();
  const { hasPermission } = useCustomPermission();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST)) {
      navigateWithWorkspaceUrl('/dashboard');
      return;
    }
  }, []);

  const sectionSteps = useMemo(() => {
    const result: any = {};

    let currentSectionId = null;
    let currentSectionCount = 0;

    checklistItems?.forEach((item) => {
      if (item.type === STEP_TYPES.HEADER) {
        // If a header is found, update the current section information
        if (currentSectionId !== null) {
          result[currentSectionId as any] = currentSectionCount;
        }

        currentSectionId = item.id;
        currentSectionCount = 0;
      } else {
        // Increment the count for the current section's children
        currentSectionCount += 1;
      }
    });

    // Add the count for the last section (if any)
    if (currentSectionId !== null) {
      result[currentSectionId] = currentSectionCount;
    }

    return result;
  }, [checklistItems]);

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST)) return;
    setLastSavedAt(getUtcToTzTime(new Date()));
    const newHash = getChecklistItemsHash(checklistItems);
    checklistItemsHashRef.current = newHash;
    checklistItemsArrayRef.current = getChecklistItemsArrayFromHashRef(
      checklistItems,
      newHash,
    );
  }, [checklistItems]);

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST)) return;
    getOrCreateAndSetChecklist();
    dispatch(actions.getChecklistCategories());
    dispatch(actions.getChecklistTypes());
    dispatch(actions.getChecklistIndustries());
    if (paramChecklistId) {
      setIsChecklistEditing(true);
    }

    if (location?.state?.redirectTo) {
      setRedirectTo(location?.state?.redirectTo);
    } else {
      setRedirectTo(`/checklist/new${folderId ? '/folder/' + folderId : ''}`);
    }
    fetchSavedOptions();
    return () => {
      resetChecklistBuilder();
      dispatch(actions.getChecklistCompleteList());
    };
  }, []);

  // this will call flagged catefories list on initial load
  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST)) return;
    getFlaggedCategories();
  }, []);

  const getChecklistItemsHash = (items: any[]) => {
    if (!items) return {};
    const hash: any = {};
    items.forEach((item) => {
      hash[item.id] = item;
      if (item.ChildItems) {
        Object.assign(hash, getChecklistItemsHash(item.ChildItems));
      }
    });
    return hash;
  };

  const getChecklistItemsArrayFromHashRef = (
    checklistItemsState: any[],
    hash: { [x: string]: any },
  ) => {
    const items: any[] = [];
    if (!checklistItemsState || !hash) return items;
    for (let index = 0; index < checklistItemsState.length; index++) {
      const element = checklistItemsState[index];
      const finalElement = hash[element.id];
      items.push({
        ...finalElement,
        ChildItems: finalElement?.ChildItems?.length
          ? getChecklistItemsArrayFromHashRef(finalElement.ChildItems, hash)
          : null,
      });
    }
    return items;
  };

  const getSectionId = (checklistItemIndex: number) => {
    let sectionId = null;
    const header = checklistItems?.filter(
      (item, cItemIdx) =>
        item.type === 'header' && cItemIdx <= checklistItemIndex,
    );
    if (header?.length) {
      sectionId = header[header.length - 1].id;
    }

    return sectionId;
  };

  const checklistItemsWithVisibility = useMemo(() => {
    const result: any = [];

    checklistItems?.forEach((item, index) => {
      if (item.type === STEP_TYPES.HEADER) {
        result.push(item);
      } else {
        const sectionId: any = getSectionId(index);
        const isVisible =
          sectionVisibility[sectionId] === undefined ||
          sectionVisibility[sectionId] === true;

        if (isVisible) {
          result.push({
            ...item,
            sectionId,
          });
        }
      }
    });

    return result;
  }, [checklistItems, sectionVisibility, sectionSteps]);

  // ----------------Data Related Functions----------------

  const fetchSavedOptions = async () => {
    const savedOptions = await xeniaApi.getSavedOptions();
    dispatch(actions.setSavedOptions(savedOptions));
  };

  const getOrCreateAndSetChecklist = async () => {
    const _createAndSetNewChecklist = () => {
      const newChecklist = getFreshChecklist();
      setChecklist(newChecklist);
      setChecklistItems(newChecklist.ChecklistItems);
      navigateWithWorkspaceUrl(
        `/checklist/${newChecklist.id}${folderId ? '/folder/' + folderId : ''}`,
        '',
        { replace: true },
      );
      setIsChecklistEditing(false);
      setIsMetaModalOpen(true);
    };

    if (paramChecklistId) {
      const checklist = await xeniaApi.getChecklist(paramChecklistId);
      const checklistItems = await xeniaApi.getChecklistItems(paramChecklistId);
      fetchAndSetChecklistAutomations(paramChecklistId);
      if (checklist?.data) {
        setChecklist(checklist.data);
        setChecklistItems(checklistItems?.data ?? []);
      } else {
        _createAndSetNewChecklist();
      }
    } else {
      _createAndSetNewChecklist();
    }
  };

  const fetchAndSetChecklistAutomations = async (checklistId?: string) => {
    const automations = await xeniaApi.getChecklistAutomations({
      checklistId: checklistId || checklist.id,
    });
    if (automations) {
      const groupedAutomations = automations.reduce((acc, automation) => {
        const { EntityId } = automation;
        if (!acc[EntityId]) {
          acc[EntityId] = [];
        }
        acc[EntityId].push({
          id: automation.id,
          conditions: automation.conditions,
          actions: automation.actions,
          EntityId: automation.EntityId,
          entityType: automation.entityType,
          operator: automation.operator,
        });
        return acc;
      }, {});
      setAutomations(groupedAutomations);
    }
  };

  const handleDeleteFollowupAutomationOnChildrenDelete = async (
    parentItem: any,
  ) => {
    const parentItemAutomations = automations[parentItem.id];
    const childAutomationIdsSet = new Set(
      parentItem.ChildItems.map((cItem) => cItem.RelatedAutomationId),
    );
    const automationIdsSet = new Set(
      parentItemAutomations
        .filter((a) =>
          a.actions.some((act) => act.type === 'FOLLOWUP_QUESTIONS'),
        )
        .map((automation) => automation.id),
    );

    const automationIdsToDelete: any = [];
    for (const automation of automationIdsSet) {
      if (!childAutomationIdsSet.has(automation)) {
        automationIdsToDelete.push(automation);
      }
    }

    await xeniaApi.deleteAutomation({
      automationIds: automationIdsToDelete,
    });
    fetchAndSetChecklistAutomations();
  };

  // ----------------Checklist Item CRUD----------------

  // updates complete checklist
  const updateChecklistHandler = async (checklistData: any) => {
    const previousChecklist = { ...checklist };
    const previousChecklistItems = [...checklistItems];
    setChecklist(checklistData);
    setChecklistItems(checklistData.checklistItems);
    const updatedChecklist = await xeniaApi.updateChecklist(
      checklist.id,
      false,
      checklistData,
    );
    if (!updatedChecklist?.data) {
      setChecklist(previousChecklist);
      setChecklistItems(previousChecklistItems);
    } else {
      setChecklist(updatedChecklist.data);
      setChecklistItems(updatedChecklist.data.ChecklistItems);
    }
  };

  const handleAddChecklistItem = async (
    itemType: string,
    itemId: string,
    itemData: any,
    prevDescription?: string,
  ) => {
    setAddingItem(true);
    const index = checklistItems.findIndex((cItem) => cItem.id === itemId);
    const order =
      checklistItems.find((cItem) => cItem.id === itemId)?.order ?? 0;
    const sectionIdForItem = getSectionId(index);
    const sectionItem = checklistItems.find(
      (cItem) => cItem.id === sectionIdForItem,
    );
    let isCurrentItemActive = true;
    if (sectionItem && itemType !== STEP_TYPES.HEADER) {
      isCurrentItemActive = sectionItem.isActive;
    }
    const newChecklistItem: any = {
      id: uuid(),
      description: '',
      order: order === 0 ? order : order + 1,
      score: 1,
      type: itemType,
      attachments: [],
      isImageOrNoteRequired: false,
      isActive: isCurrentItemActive,
      ...itemData,
    };

    if (itemType === STEP_TYPES.COST || itemType === STEP_TYPES.TEMPERATURE) {
      newChecklistItem.unit = itemType === STEP_TYPES.COST ? '$' : '°F';
    } else if (itemType === STEP_TYPES.NUMBER) {
      newChecklistItem.unit = '';
    } else if (itemType === STEP_TYPES.DATE_TIME) {
      newChecklistItem.unit = 'date';
    } else if (itemType === STEP_TYPES.DROPDOWN) {
      newChecklistItem.unit = 'single';
    }
    if (itemType === STEP_TYPES.PASS_FAIL) {
      newChecklistItem.options = [
        {
          label: 'Pass',
          value: 'pass',
          type: 'success',
        },
        {
          label: 'Fail',
          value: 'fail',
          type: 'error',
        },
        {
          label: 'N/A',
          value: 'na',
          type: 'info',
        },
      ];
    }

    if (
      itemType === STEP_TYPES.MULTIPLE_CHOICE ||
      itemType === STEP_TYPES.DROPDOWN
    ) {
      const lastStep = checklistItems[index];

      if (
        lastStep?.type === 'multipleChoice' ||
        lastStep?.type === 'dropdown'
      ) {
        newChecklistItem.options = cloneDeepWith(lastStep.options, (options) =>
          map(options, (option) => ({ ...option, id: uuid() })),
        );
      } else {
        newChecklistItem.options = [
          {
            id: uuid(),
            label: '',
            order: 0,
            score: 1,
            color: sample(_colors) as string,
            flag: false,
          },
        ];
      }
    }

    if (itemType === STEP_TYPES.INSTRUCTION) {
      newChecklistItem.options = {
        requireAcknowledgement: true,
        instruction: '',
      };
    }
    if (itemType === STEP_TYPES.PHOTO_ANNOTATE) {
      newChecklistItem.options = {
        annotation: [],
      };
    }

    let newItems = [...getSyncedChecklistItems(checklistItems)];
    newItems?.splice(index + 1, 0, newChecklistItem);
    newItems = newItems.map((item, index) => {
      return {
        ...item,
        order: index,
      };
    });

    if (itemType === STEP_TYPES.INSTRUCTION) {
      setCurrInstructionItemData(newChecklistItem);
      setInstructionDialogOpen(true);
    }

    handleUpdateChecklistItems(null, newItems);
    setTimeout(() => {
      isCurrentItemActive && handleItemCollapse(newChecklistItem.id);
    }, 300);
    setShowStepType(false);
    setAddingItem(false);
  };

  const flattenChecklist = (checklistItems: any[], mapRef = false) => {
    const items: any[] = [];
    for (let index = 0; index < checklistItems.length; index++) {
      const element = checklistItems[index];
      const finalElement = mapRef
        ? checklistItemsHashRef.current[element.id]
        : element;
      items.push(finalElement);
      if (finalElement.ChildItems) {
        items.push(...flattenChecklist(finalElement.ChildItems));
      }
    }
    return items;
  };

  const updateChecklistApiCall = async (
    checklistData: any,
    checklistItems: any[],
  ) => {
    const flattenedChecklist = flattenChecklist(checklistItems);
    await xeniaApi.updateChecklist(checklistData.id, false, {
      name: checklistData.name,
      checklistItems: flattenedChecklist.map((checklistItem) => ({
        id: checklistItem.id,
        description: checklistItem.description,
        order: checklistItem.order,
        type: checklistItem.type,
        attachments: checklistItem.attachments,
        isImageOrNoteRequired: checklistItem.isImageOrNoteRequired,
        unit: checklistItem.unit,
        options: checklistItem.options,
        isActive: checklistItem.isActive,
      })),
    });
  };

  const updateChecklistItemById = (
    checklistItemId: string,
    checklistItemData: any,
    stateChecklistItems?: any[],
  ) => {
    const getUpdatedItems = (
      checklistItemId: string,
      checklistItemData: any,
      checklistItems: any[],
    ) => {
      const updatedItems = checklistItems.map((item) => {
        if (item.id === checklistItemId) {
          return {
            ...item,
            ...checklistItemData,
          };
        } else if (item.ChildItems && item.ChildItems.length > 0) {
          return {
            ...item,
            ChildItems: getUpdatedItems(
              checklistItemId,
              checklistItemData,
              item.ChildItems,
            ),
          };
        }
        return item;
      });
      return updatedItems;
    };

    const updatedItems = getUpdatedItems(
      checklistItemId,
      checklistItemData,
      stateChecklistItems ?? checklistItems,
    );
    checklistItemsHashRef.current[checklistItemId] = checklistItemData;
    setChecklistItems(updatedItems);
    autoSaveChecklistItem(checklistItemData);
  };

  const deleteChecklistItemById = (checklistItemId, checklistItems) => {
    const parentId = checklistItemsHashRef.current[checklistItemId]?.ParentId;
    const getUpdatedItems = (checklistItemId, checklistItems) => {
      const updatedItems = checklistItems.reduce((acc, item) => {
        if (item.id === checklistItemId) {
          return acc;
        } else if (item.ChildItems && item.ChildItems.length > 0) {
          return [
            ...acc,
            {
              ...item,
              ChildItems: getUpdatedItems(checklistItemId, item.ChildItems),
            },
          ];
        } else {
          return [...acc, item];
        }
      }, []);
      return updatedItems;
    };

    const updatedItems = getUpdatedItems(checklistItemId, checklistItems);
    const updatedHash = getChecklistItemsHash(updatedItems);
    checklistItemsArrayRef.current = updatedItems;
    setChecklistItems(updatedItems);
    deleteChecklistItemApi(checklistItemId);
    if (parentId) {
      const parent = updatedHash[parentId];
      handleDeleteFollowupAutomationOnChildrenDelete(parent);
    }
    if (defaultLocationStep === checklistItemId) {
      setDefaultLocationStep('');
    }
  };

  const getSyncedChecklistItems = (checklistItems: any[]) => {
    const updatedItems = checklistItems.map((cItem) => {
      const refItem = checklistItemsHashRef.current[cItem.id];
      const finalItem = refItem ?? cItem;
      if (finalItem.ChildItems && finalItem.ChildItems.length > 0) {
        return {
          ...finalItem,
          ChildItems: getSyncedChecklistItems(finalItem.ChildItems),
        };
      }
      return finalItem;
    });

    return updatedItems;
  };

  const handleAddFollowUpItem = async (checklistItem: any) => {
    const getUpdatedItems = (checklistItem: any, checklistItems: any[]) => {
      const updatedItems = checklistItems.map((item) => {
        const refItem = checklistItemsHashRef.current[item.id];
        const finalItem = refItem ?? item;
        if (finalItem.id === checklistItem.ParentId) {
          return {
            ...finalItem,
            ChildItems: finalItem.ChildItems
              ? [
                  ...finalItem.ChildItems.map((item) => {
                    const refItem = checklistItemsHashRef.current[item.id];
                    const finalItem = refItem ?? item;
                    return finalItem;
                  }),
                  checklistItem,
                ]
              : [checklistItem],
          };
        } else if (finalItem.ChildItems && finalItem.ChildItems.length > 0) {
          return {
            ...finalItem,
            ChildItems: getUpdatedItems(checklistItem, finalItem.ChildItems),
          };
        } else {
          return finalItem;
        }
      });
      return updatedItems;
    };

    const updatedItems = getUpdatedItems(checklistItem, checklistItems);

    setChecklistItems(updatedItems);
    autoSaveChecklistItem(checklistItem);
    handleItemCollapse(checklistItem.id, true, updatedItems);

    if (checklistItem.type === 'instruction') {
      setInstructionDialogOpen(true);
      setCurrInstructionItemData(checklistItem);
    }
  };

  const autoSaveChecklistItem = async (checklistItemData: any) => {
    if (!checklist || !checklistItemData) return;

    await xeniaApi.modifyChecklistItem(checklist.id, checklistItemData.id, {
      description: checklistItemData.description,
      order: checklistItemData.order,
      type: checklistItemData.type,
      attachments: checklistItemData.attachments,
      isImageOrNoteRequired: checklistItemData.isImageOrNoteRequired,
      unit: checklistItemData.unit,
      options: checklistItemData.options,
      isActive: checklistItemData.isActive,
      required: checklistItemData.required,
      ParentId: checklistItemData.ParentId,
      RelatedAutomationId: checklistItemData.RelatedAutomationId,
      score: checklistItemData.score,
    });
  };

  const deleteChecklistItemApi = async (checklistItemId: string) => {
    await xeniaApi.deleteChecklistItem(checklist.id, checklistItemId);
  };

  // ----------------Checklist CRUD----------------
  const saveChecklist = async (
    showNotification: boolean,
    checklistData: any = null,
    isInitialCreate?: boolean,
  ) => {
    let checklistObj = checklistData || checklist;
    if (checklistObj?.name && !paramChecklistId && showNotification) return;
    if (!checklistObj?.name && !showNotification) return;
    const checklistItemsArray = compact(
      checklistItems?.map((checklistItem: any, index: number) => {
        return {
          id: checklistItem.id,
          description: checklistItem.description,
          order: checklistItem.order,
          score: checklistItem.score,
          type: checklistItem.type,
          attachments: checklistItem.attachments,
          isImageOrNoteRequired: checklistItem.isImageOrNoteRequired,
          unit: checklistItem.unit,
          options: checklistItem.options,
          isActive: checklistItem.isActive,
          ...(isInitialCreate &&
            checklistItem?.type === STEP_TYPES.LOCATION && {
              options: { isDefaultLocation: true },
            }),
        };
      }),
    );
    checklistObj = {
      ...checklistObj,
      checklistItems: checklistItemsArray,
    };
    await updateChecklistHandler({
      name: checklistObj.name,
      ChecklistCategoryId: checklistObj.ChecklistCategoryId,
      ChecklistIndustryId: checklistObj.ChecklistIndustryId,
      ChecklistTypeId: checklistObj.ChecklistTypeId,
      icon: { ...checklistObj.icon },
      attachments: checklistObj.attachments,
      checklistItems: checklistObj.checklistItems,
      description: checklistObj.description,
      image: checklistObj.image,
      FolderId: checklistObj?.FolderId,
    });
  };

  const handleUpdateChecklistItems = async (
    parentId: null | undefined | string = null,
    newItems: any[],
  ) => {
    setAddingItem(true);
    const getUpdatedItems = (
      parentId: string,
      newItems: any[],
      checklistItems: any[],
    ) => {
      const updatedItems = checklistItems.map((item) => {
        const refItem = checklistItemsHashRef.current[item.id];
        const finalItem = refItem ?? item;
        if (finalItem.id === parentId) {
          return {
            ...finalItem,
            ChildItems: newItems.map((nItem) => {
              const refItem = checklistItemsHashRef.current[nItem.id];
              const finalItem = refItem ?? nItem;
              return {
                ...finalItem,
                order: nItem.order,
              };
            }),
          };
        } else if (finalItem.ChildItems && finalItem.ChildItems.length > 0) {
          return {
            ...finalItem,
            ChildItems: getUpdatedItems(
              parentId,
              newItems,
              finalItem.ChildItems,
            ),
          };
        } else {
          return finalItem;
        }
      });

      return updatedItems;
    };

    let finalChecklistItems: any[] = [];
    if (parentId) {
      finalChecklistItems = getUpdatedItems(
        parentId,
        newItems,
        checklistItemsArrayRef.current,
      );
    } else {
      finalChecklistItems = [...newItems];
    }

    setChecklistItems(finalChecklistItems);

    await updateChecklistApiCall(checklist, finalChecklistItems);
    setAddingItem(false);
  };

  // ----------------Utility Functions----------------

  const resetChecklistBuilder = () => {
    setChecklist(null);
  };

  const getFreshChecklist = () => {
    return {
      id: uuid(),
      name: '',
      icon: {
        icon: 'Default',
        color: '#FFA681',
      },
      attachments: [],
      ChecklistItems: [
        {
          id: uuid(),
          description: 'General Information',
          order: 0,
          type: STEP_TYPES.HEADER,
          attachments: [],
          isImageOrNoteRequired: false,
          isActive: true,
        },
        {
          id: uuid(),
          description: 'Select Location',
          order: 1,
          type: STEP_TYPES.LOCATION,
          attachments: [],
          isImageOrNoteRequired: false,
          isActive: true,
        },
        {
          id: uuid(),
          description: 'Inspector Name',
          order: 2,
          type: STEP_TYPES.TEXT_FIELD,
          attachments: [],
          isImageOrNoteRequired: false,
          isActive: true,
        },
        {
          id: uuid(),
          description: 'Enter Date & Time',
          order: 3,
          type: STEP_TYPES.DATE_TIME,
          attachments: [],
          isImageOrNoteRequired: false,
          isActive: true,
          unit: 'dateTime',
        },
      ],
    };
  };

  const hideAttachmentPreview = () => {
    setSelectedAttachment(null);
    setShowAttachmentPreview(false);
  };

  const handleGoNextMetadata = async (data: any, isInitial = false) => {
    setIsNextLoading(true);
    await saveChecklist(
      false,
      {
        ...checklist,
        ...data,
      },
      isInitial,
    );
    setIsNextLoading(false);
    setIsMetaModalOpen(false);
  };

  const handleSettingsClose = () => {
    setViewToggle('builder');
  };

  const handleOpenSettings = (activeTab = null) => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TEMPLATE_SETTINGS,
        open: true,
        data: {
          template: checklist,
          updateTemplateCb: (data) => setChecklist(data),
          onSettingsClose: handleSettingsClose,
          activeTab,
        },
      }),
    );
  };

  const handleViewToggleClick = (value) => {
    setViewToggle(value);
    if (value === 'settings') {
      handleOpenSettings();
    }
  };

  const handleClickMobile = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG,
        open: true,
        data: {
          title: 'Open Template on Mobile App',
          description: 'Scan the QR code below and go to the “Templates” Tab.',
        },
      }),
    );
  };

  const toggleSectionCollapse = (sectionId: string) => {
    const prevVisibility = sectionVisibility[sectionId];
    setSectionVisibility({
      ...sectionVisibility,
      [sectionId]: prevVisibility !== undefined ? !prevVisibility : false,
    });
    setSectionEditingId(null);
  };

  const handleSetFollowupVisibility = (
    checklistItemId: string,
    automationId: string,
  ) => {
    const prevVisibility = followupVisibility[checklistItemId] ?? {};
    const newVisibility = {
      ...prevVisibility,
      [automationId]: !prevVisibility[automationId],
    };
    setFollowupVisibility({
      ...followupVisibility,
      [checklistItemId]: newVisibility,
    });
  };

  const handleItemCollapse = (
    itemId: string,
    unCollapse = true,
    newChecklistItems = null,
    setAutoFocus = true,
  ) => {
    const findParents = (
      items: any[],
      childId: string,
      path: any[] = [],
    ): any[] | null => {
      for (const item of items) {
        if (item.id === childId) {
          return path;
        }
        if (item.ChildItems) {
          const newPath = [...path, item];
          const found = findParents(item.ChildItems, childId, newPath);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    const parentIds = findParents(newChecklistItems ?? checklistItems, itemId);

    const newCollapseState = Object.keys(itemCollapseState).reduce(
      (acc, id) => {
        if (parentIds && parentIds.some((p) => p.id === id)) {
          return { ...acc, [id]: true };
        }
        return { ...acc, [id]: false };
      },
      {},
    );
    newCollapseState[itemId] = unCollapse;
    if (setAutoFocus) {
      setAutoFocusItemId(unCollapse === false ? null : itemId);
    }
    setItemCollapseState(newCollapseState);
    setSectionEditingId(null);
    const currItem = checklistItemsHashRef.current[itemId];
    if (currItem?.type === STEP_TYPES.INSTRUCTION) {
      setInstructionDialogOpen(true);
      setCurrInstructionItemData(currItem);
    }
  };

  const getSyncedDescriptionsItems = (cItems: any[]) => {
    const items: any[] = [];
    for (let index = 0; index < cItems.length; index++) {
      const element = cItems[index];
      const refElement = checklistItemsHashRef.current[element.id];
      const finalElement = refElement ?? element;
      items.push({
        ...finalElement,
        ChildItems: finalElement?.ChildItems?.length
          ? getSyncedDescriptionsItems(finalElement.ChildItems)
          : null,
      });
    }

    return items;
  };

  const handleCollapseAllItems = (unCollapse = false) => {
    const newCollapseState = Object.keys(itemCollapseState).reduce(
      (acc, id) => ({ ...acc, [id]: unCollapse }),
      {},
    );
    setAutoFocusItemId(null);
    setItemCollapseState(newCollapseState);
    setSectionEditingId(null);
    setChecklistItems(getSyncedDescriptionsItems(checklistItems));
    handleUpdateChecklistItems(
      null,
      getSyncedDescriptionsItems(checklistItems),
    );
  };

  const updateChecklistItemsHash = (checklistItemId: string, itemData: any) => {
    checklistItemsHashRef.current[checklistItemId] = itemData;
    checklistItemsArrayRef.current = getChecklistItemsArrayFromHashRef(
      checklistItems,
      checklistItemsHashRef.current,
    );
  };

  const uploadAttachmentsToStep = async (
    itemId: string,
    acceptedFiles: any[],
  ) => {
    setUploadingItemId(itemId);
    const refItem = checklistItemsHashRef.current[itemId];
    const files = await xeniaApi.CreateTaskAttchmentFilesApi(acceptedFiles);
    const attachments = files.filePath.map((attachment: any) => {
      return files.baseUrl + attachment;
    });
    const prevAttachments = refItem.attachments || [];
    const syncedChecklist = getSyncedChecklistItems(
      checklistItemsArrayRef.current,
    );
    const updateObj = {
      ...refItem,
    };
    if (refItem.type === STEP_TYPES.PHOTO_ANNOTATE) {
      updateObj['options'] = {
        annotation: attachments,
      };
    } else {
      updateObj['attachments'] = [...prevAttachments, ...attachments];
    }
    updateChecklistItemById(itemId, updateObj, syncedChecklist);
    setUploadingItemId(null);
  };

  const parseAutoFocusItemId = (autoFocusId: string) => {
    if (autoFocusId.endsWith('-score')) {
      return autoFocusId.replace('-score', '');
    }
    return autoFocusId;
  };

  const updateActiveChecklistItemOnScroll = (removeFocus?: boolean) => {
    if (!autoFocusItemId) return;
    const itemId = `${parseAutoFocusItemId(autoFocusItemId)}`;
    removeFocus && setAutoFocusItemId(null);
    if (!checklistItems || !checklist) return;
    const item = checklistItemsHashRef.current[itemId];
    if (!item) return;
    updateChecklistItemById(itemId, item, checklistItemsArrayRef.current);
    setSectionEditingId(null);
  };

  return hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST) ? (
    <StyledCustomModal
      fullScreen
      open={true}
      transitionDuration={0}
      handleClose={() => console.log('')}
    >
      {!checklist ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <Box
          className="checklist-builder"
          sx={{
            minHeight: '93vh',
          }}
        >
          {checklist && isMetaModalOpen ? (
            <MetaDetailModal
              open={isMetaModalOpen}
              handleGoNextMetadata={handleGoNextMetadata}
              isXeniaAdmin={includes(features, 'Publish Checklists')}
              handleCloseModal={() => setIsMetaModalOpen(false)}
              isEditing={isChecklistEditing}
              checklist={checklist}
              isNextLoading={isNextLoading}
              folderId={folderId}
            />
          ) : (
            <></>
          )}

          <InstructionDialog
            open={instructionDialogOpen}
            handleClose={() => {
              setInstructionDialogOpen(false);
              setCurrInstructionItemData(null);
            }}
            checklistItem={currInstructionItemData}
            handleSave={(
              description: string,
              instruction: string,
              requireAcknowledgement: boolean,
            ) => {
              updateChecklistItemById(currInstructionItemData.id, {
                ...currInstructionItemData,
                description,
                options: {
                  requireAcknowledgement,
                  instruction,
                },
              });
            }}
          />

          <Header
            checklist={checklist}
            setShowStepType={setShowStepType}
            viewToggle={viewToggle}
            handleViewToggleClick={handleViewToggleClick}
            redirectTo={redirectTo}
            handleOpenSettings={handleOpenSettings}
            setChecklist={setChecklist}
            lastSavedAt={lastSavedAt}
          />
          <BodyWrapper topalert={topAlertState?.show ? 'true' : ''}>
            <BuilderBody>
              {checklist && checklistItemsWithVisibility ? (
                <ChecklistItems
                  checklist={checklist}
                  checklistItems={checklistItemsWithVisibility}
                  originalChecklistItems={checklistItems}
                  defaultLocationStep={defaultLocationStep}
                  setDefaultLocationStep={setDefaultLocationStep}
                  autoFocusIndex={autoFocusItemId}
                  automations={automations}
                  setAutoFocusIndex={(itemId) => {
                    setAutoFocusItemId(itemId);
                    setAutofocusCursorPosition(null);
                  }}
                  autoFocusCursorPosition={autofocusCursorPosition}
                  setAutofocusCursorPosition={setAutofocusCursorPosition}
                  handleUpdateChecklistItems={handleUpdateChecklistItems}
                  handleAddFollowUpItem={handleAddFollowUpItem}
                  deleteChecklistItemById={(itemId) =>
                    deleteChecklistItemById(
                      itemId,
                      checklistItemsArrayRef.current,
                    )
                  }
                  fetchAndSetChecklistAutomations={
                    fetchAndSetChecklistAutomations
                  }
                  handleAddChecklistItem={handleAddChecklistItem}
                  addingItem={addingItem}
                  sectionSteps={sectionSteps}
                  sectionVisibility={sectionVisibility}
                  toggleSectionCollapse={toggleSectionCollapse}
                  sectionEditingId={sectionEditingId}
                  setSectionEditingId={setSectionEditingId}
                  followupVisibility={followupVisibility}
                  handleSetFollowupVisibility={handleSetFollowupVisibility}
                  handleItemCollapse={handleItemCollapse}
                  itemCollapseState={itemCollapseState}
                  getSectionId={getSectionId}
                  handleCollapseAllItems={handleCollapseAllItems}
                  uploadingItemId={uploadingItemId}
                  setUploadingItemId={setUploadingItemId}
                  checklistItemsHashRef={checklistItemsHashRef.current}
                  updateChecklistItemsHash={updateChecklistItemsHash}
                  uploadAttachmentsToStep={uploadAttachmentsToStep}
                  updateActiveChecklistItemOnScroll={
                    updateActiveChecklistItemOnScroll
                  }
                />
              ) : null}
            </BuilderBody>
            <Emulator
              checklist={checklist}
              checklistItems={checklistItems}
              handleClickMobile={handleClickMobile}
              checklistItemsHashRef={checklistItemsHashRef.current}
            />
          </BodyWrapper>
          {!!showAttachmentPreview && (
            <Dialog
              className="attachment-preview-dialog"
              fullScreen
              open={showAttachmentPreview}
            >
              <ChecklistAttachmentPreview
                selectedAttachment={selectedAttachment}
                setSelectedAttachment={setSelectedAttachment}
                hideAttachmentPreview={hideAttachmentPreview}
              />
            </Dialog>
          )}
          <BootstrapModelComponent
            className="common-modal step-type-modal border-radius-16-px"
            id="step-type-modal"
            isShow={showStepType}
            handleClose={null}
            animation={false}
            showCloseBtn={false}
            arialabelledby="stepTypeModalLabel"
            centered={true}
            headerData={' '}
            headerHtml={
              <Box className="d-flex align-items-center justify-content-between w-100">
                <Typography
                  width="100%"
                  fontWeight={800}
                  fontSize="24px"
                  color="#212121"
                  textAlign="center"
                >
                  Select a step type to begin
                </Typography>
                <IconButton onClick={() => setShowStepType(false)}>
                  <CloseOutlinedIcon sx={{ color: '#757575' }} />
                </IconButton>
              </Box>
            }
            size={'lg'}
            bodyData={
              <Stack alignItems="center" spacing={2}>
                <LearnMoreBtn
                  startIcon={<InfoTwoToneIcon />}
                  variant="text"
                  onClick={() =>
                    window.open(
                      'https://help.xenia.team/hc/en-us/articles/16875413679771-Operations-Template-Step-Types-A-Comprehensive-Guide',
                      '_blank',
                    )
                  }
                >
                  Learn More About Step Types
                </LearnMoreBtn>
                <StepTypes
                  addChecklistItem={handleAddChecklistItem}
                  itemIndex={
                    checklistItems[checklistItems?.length - 1]?.id || 0
                  }
                />
              </Stack>
            }
            datatestid="stepTypeTestId"
          />
        </Box>
      )}
    </StyledCustomModal>
  ) : null;
};

export default ChecklistBuilder;
