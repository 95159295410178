import { createSvgIcon } from '@mui/material';

const DeleteIcon3 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_3065_150896"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3065_150896)">
        <path
          d="M7.5 21C6.95 21 6.47933 20.8043 6.088 20.413C5.696 20.021 5.5 19.55 5.5 19V6H4.5V4H9.5V3H15.5V4H20.5V6H19.5V19C19.5 19.55 19.3043 20.021 18.913 20.413C18.521 20.8043 18.05 21 17.5 21H7.5ZM17.5 6H7.5V19H17.5V6ZM9.5 17H11.5V8H9.5V17ZM13.5 17H15.5V8H13.5V17Z"
          fill={'currentColor'}
        />
      </g>
    </>,
    'DeleteIcon3',
  );
  return <IconCmp viewBox={'0 0 25 24'} {...props} />;
};
export default DeleteIcon3;
