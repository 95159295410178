// Icons
import NoTemplateActivity from 'components/Icons/noTemplateActivity';
import NoTemplateSubmission from 'components/Icons/noTemplateSubmission';

// Styled
import { Wrapper, BoldText, GreyText } from './styled';

interface EmptyStatePropTypes {
  type: 'activity' | 'submission';
}

const EmptyState = ({ type }: EmptyStatePropTypes) => {
  return (
    <Wrapper>
      {type === 'activity' && (
        <NoTemplateActivity sx={{ width: 118, height: 85 }} />
      )}
      {type === 'submission' && (
        <NoTemplateSubmission sx={{ width: 65, height: 52 }} />
      )}
      <BoldText>
        {type === 'activity' ? 'No activity yet' : 'No reports yet'}{' '}
      </BoldText>
      <GreyText>
        {type === 'activity'
          ? 'All template related activity will show here'
          : 'All submission reports will show here'}
      </GreyText>
    </Wrapper>
  );
};

export default EmptyState;
