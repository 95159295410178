import TaskActivityTimeline from 'components/TaskActivityTimeline';
import { useParams } from 'react-router-dom';

function TaskActivityLogWebView() {
  const params = useParams();
  const currentTask = { id: params.taskId };
  return <TaskActivityTimeline currentTask={currentTask} />;
}

export default TaskActivityLogWebView;
