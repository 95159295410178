import { FC } from 'react';

import PreviewItem from './previewItem';

interface IProps {
  automation: any;
  field: any[];
  onEdit(): void;
  fetchAndSetChecklistAutomations: () => void;
}

const AutomationsPreview: FC<IProps> = (props) => {
  const { automation, field, onEdit, fetchAndSetChecklistAutomations } = props;
  return (
    <PreviewItem
      key={automation.id}
      automation={automation}
      field={field}
      onEdit={onEdit}
      fetchAndSetChecklistAutomations={fetchAndSetChecklistAutomations}
    />
  );
};

export default AutomationsPreview;
