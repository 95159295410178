// File Types Images
import pdfIcon from 'public/assets/img/file-types/pdf.svg';
import sopIcon from 'public/assets/img/file-types/sop.svg';
import wordIcon from 'public/assets/img/file-types/word.svg';
import excelIcon from 'public/assets/img/file-types/excel.svg';
import pptIcon from 'public/assets/img/file-types/ppt.svg';
import videoIcon from 'public/assets/img/file-types/video.svg';
import imageIcon from 'public/assets/img/file-types/image.svg';

const DATA = {
  pdf: {
    icon: pdfIcon,
    color: '#FAD7D7',
  },
  sop: {
    icon: sopIcon,
    color: '#E1E1FF',
  },
  word: {
    icon: wordIcon,
    color: '#D2E7FA',
  },
  excel: {
    icon: excelIcon,
    color: '#D9ECDA',
  },
  ppt: {
    icon: pptIcon,
    color: '#FDDCD2',
  },
  video: {
    icon: videoIcon,
    color: '#F7D1DF',
  },
  image: {
    icon: imageIcon,
    color: '',
  },
};

export const getByType = (type) => {
  return DATA[type];
};
