import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { AssetsMainCnt } from 'pages/Assets/assets.styles';
import AssetDetailView from 'pages/Assets/AssetDetail/AssetDetail.view';
import { getAuthAssetsState } from 'store/selectors/assets';
import AssetsMainView from './AssetsMain/assetsMain';

function AssetsView() {
  const assetsList = useSelector(getAuthAssetsState);

  return (
    <AssetsMainCnt>
      <Routes>
        {assetsList?.length > 0 && (
          <Route path="/:assetId" element={<AssetDetailView />} />
        )}
        {assetsList?.length > 0 && (
          <Route
            path="/:assetId/sa/:subAssetId"
            element={<AssetDetailView />}
          />
        )}
        <Route path="/" element={<AssetsMainView />} />
      </Routes>
    </AssetsMainCnt>
  );
}

export default AssetsView;
