import { AttachmentsWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import AttachmentsUpload from 'components/Attachments/Attachments';

const LocationAttachments = (props) => {
  const { location, updateAction } = props;

  const attachments = location?.attachments || [];

  const updateHandler = (value) => {
    updateAction({ attachments: value });
  };

  return (
    <AttachmentsWithLabel>
      <label>Attachments</label>
      <AttachmentsUpload
        attachments={attachments}
        updateHandler={updateHandler}
      />
    </AttachmentsWithLabel>
  );
};
export default LocationAttachments;
