// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 155px)',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '700',
  letterSpacing: '0.15px',
  lineHeight: '32px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const GreyText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: '0.17px',
  lineHeight: '20.02px',
  color: 'rgba(0, 0, 0, 0.6)',
  width: 296,
  textAlign: 'center',
}));
