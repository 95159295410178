import { useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import ChecklistIcon from 'components/ChecklistIcon';
import AvatarPopover from 'components/AvatarPopover';
import { formatDate } from 'utils/globalFunction';
import DateTime from 'utils/DateTime';
import { DATE_FORMAT } from 'utils/globalVariable';
import { getUserHotelTz } from 'store/selectors/auth';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import RecurringIcon from 'public/assets/img/recurring-v2.svg';
import moment from 'moment-timezone';
import useDateTime from 'utils/CustomHooks/useDateTime';
import {
  AvatarCnt,
  ByCnt,
  Content,
  DataSet,
  DetailsCnt,
  Dot,
  Heading,
  MainCnt,
  ValueCnt,
  ValueText,
} from './style';
import useResponsive from 'utils/CustomHooks/useResponsive';

// Details
const ScheduleDetailView = (props) => {
  const { data } = props;
  const { isMobileDeviceSm } = useResponsive();
  const [schedule, setSchedule] = useState<any>({});
  const { getUtcToTz } = useDateTime();
  const sidebarOpen = useSelector(selectors.getMenuState);
  const usersHash = useSelector(selectors.getHashedUserList);
  const teamsHash = useSelector(selectors.getTeamsHash);
  const locationsHash = useSelector(selectors.getLocationsHash);

  useEffect(() => {
    setSchedule(data ?? []);
  }, [data]);

  const tz = useSelector(getUserHotelTz) || 'America/Chicago';

  const checklistList = useSelector(selectors.getChecklistCompleteDataOnly);
  const categoryList = useSelector(selectors.getServiceAdmin);
  const { addNewAssignees } = useAssigneesUtils();

  const tranformScheduleData = useMemo(() => {
    if (schedule?.ServiceTypeId) {
      const selectedCategory = categoryList?.find(
        (c) => c.id == schedule?.ServiceTypeId,
      );
      schedule.transformedCategory = selectedCategory;
    }

    if (schedule?.assignees?.length > 0) {
      const selectedAssignees = addNewAssignees(schedule?.assignees);
      schedule.transformedAssignees = selectedAssignees;
    }

    if (schedule?.ChecklistId) {
      const Checklist = checklistList?.find(
        (item) => item?.id === schedule?.ChecklistId,
      );
      schedule.Checklist = Checklist;
    }

    return schedule;
  }, [
    schedule,
    checklistList,
    categoryList,
    usersHash,
    teamsHash,
    locationsHash,
  ]);

  const renderCreatorAndAssignee = (createdBy) => {
    return (
      <AvatarCnt>
        <AvatarPopover userId={createdBy} />
      </AvatarCnt>
    );
  };

  const formatTaskDates = (date) => {
    const fullFormat = 'MMM DD YYYY [at] hh:mm A';
    const timeOnly = '[at] hh:mm A';
    let dayString = '';

    const isToday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime().toTz(tz).toTzString(tz, DATE_FORMAT);
    const isYesterday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime()
        .toTz(tz)
        .transform({ subtract: [1, 'day'] })
        .toTzString(tz, DATE_FORMAT);

    if (isToday) {
      dayString = 'Today';
    } else if (isYesterday) {
      dayString = 'Yesterday';
    }
    return dayString.length
      ? `${dayString} ${formatDate(date, timeOnly, '', tz)}`
      : formatDate(date, fullFormat, '', tz);
  };

  return (
    <MainCnt
      style={{
        ...(!isMobileDeviceSm && {
          width: `calc(100vw - ${sidebarOpen ? '330' : '150'}px)`,
        }),
      }}
    >
      <DetailsCnt>
        {schedule?.title && (
          <DataSet>
            <Heading>Schedule Name</Heading>
            <Content>{schedule?.title}</Content>
          </DataSet>
        )}

        {tranformScheduleData?.description && (
          <DataSet>
            <Heading>Description</Heading>
            <Content>{schedule?.description}</Content>
          </DataSet>
        )}

        {tranformScheduleData?.attachment?.length > 0 && (
          <DataSet style={{ marginBottom: 20 }}>
            <Heading style={{ marginBottom: 10 }}>Attachments</Heading>
            <RenderAttachments attachments={schedule?.attachment} />
          </DataSet>
        )}

        {tranformScheduleData?.Checklist && (
          <DataSet style={{ marginBottom: 20 }}>
            <Heading style={{ marginBottom: 10 }}>Template</Heading>
            <Stack gap="8px" width="400px">
              <Stack
                p="10px 14px"
                direction={'row'}
                sx={{ borderRadius: '8px' }}
                alignItems={'center'}
                bgcolor={'#EBEAFF'}
              >
                {tranformScheduleData?.Checklist?.icon && (
                  <ChecklistIcon
                    width={36}
                    height={36}
                    icon={tranformScheduleData?.Checklist?.icon}
                  />
                )}
                <Stack maxWidth={'75%'} ml="10px">
                  <Typography
                    sx={{
                      maxWidth: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre',
                    }}
                    fontSize={'16px'}
                    fontWeight={'700'}
                    color="#000"
                  >
                    {tranformScheduleData?.Checklist?.name}
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'500'}
                    color="#616161"
                  >
                    {tranformScheduleData?.Checklist?.itemsCount} items
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </DataSet>
        )}

        {tranformScheduleData?.createdAt && (
          <DataSet>
            <Heading style={{ marginBottom: 5 }}>Created By</Heading>
            <ByCnt>
              {renderCreatorAndAssignee(tranformScheduleData?.CreatedBy)}

              <Dot>•</Dot>
              <ValueText customcolor="#757575">
                {formatTaskDates(tranformScheduleData?.createdAt)}
              </ValueText>
            </ByCnt>
          </DataSet>
        )}

        {tranformScheduleData?.AssetId && (
          <DataSet>
            <Heading style={{ marginBottom: 5 }}>Asset</Heading>
            <ValueText>{tranformScheduleData?.Asset?.name}</ValueText>
          </DataSet>
        )}

        {tranformScheduleData?.transformedAssignees?.length > 0 && (
          <DataSet>
            <Heading style={{ marginBottom: 5 }}>Assigned To</Heading>
            <Box
              sx={{
                '& p': {
                  fontWeight: 600,
                },
              }}
            >
              <AssigneesView
                type={
                  tranformScheduleData?.transformedAssignees?.length === 1
                    ? 'single'
                    : 'avatars'
                }
                data={tranformScheduleData?.transformedAssignees}
              />
            </Box>
          </DataSet>
        )}

        {tranformScheduleData?.recurringTask && (
          <DataSet>
            <Heading>Schedule</Heading>
            <ValueCnt>
              <img
                src={RecurringIcon}
                style={{ marginRight: 10 }}
                alt="Recurring task"
                title="Recurring"
                width="18"
                height="18"
              />

              <ValueText>
                {'Recurs '}
                {tranformScheduleData?.recPatternText.replace(
                  ' until never',
                  '',
                )}
                {tranformScheduleData?.parentTask?.startDate &&
                  ` starting from ${moment(
                    tranformScheduleData?.parentTask?.startDate,
                  ).format('MMM D, YYYY')}`}
              </ValueText>
            </ValueCnt>
          </DataSet>
        )}

        {tranformScheduleData?.NextDue?.dueDate && (
          <DataSet>
            <Heading>Next Task Due On</Heading>
            <Content>
              {getUtcToTz(
                tranformScheduleData?.NextDue?.dueDate,
                'MMM DD, YYYY • hh:mm A',
              ) || '-'}
            </Content>
          </DataSet>
        )}

        <DataSet>
          <Heading>Schedule Active Status</Heading>
          <ValueText>
            {tranformScheduleData?.isPaused ? 'Paused' : 'Active'}
          </ValueText>
        </DataSet>
      </DetailsCnt>
    </MainCnt>
  );
};

export default ScheduleDetailView;
