import { Grid } from '@mui/material';
import { logSubmissionStatuses } from 'helper/task/dropdownData';
import React, { useEffect, useState } from 'react';
import {
  SubmissionStatusCountCnt,
  TileInnerCnt,
} from 'pages/Widgets/TemplateSubmissionStatusCounts/styled';
import { getTaskStatusCounts } from 'api/reporting';
import xeniaApi from 'api/index';
import CircularProgress from '@mui/material/CircularProgress';
import { WidgetLoaderCnt } from 'pages/Widgets/widgets.style';
import { isEmpty } from 'lodash';

function TemplateSubmissionStatusCounts({
  filters,
  isFilterApplied,
  statsRef,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTaskStatusCounts = async () => {
    setLoading(true);
    const requestPayload = {
      ...filters,
    };
    const data = await xeniaApi.getTemplateStatusCounts(requestPayload);
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchTaskStatusCounts();
  }, [filters]);

  const statusFilter = filters?.taskStatuses;

  return (
    <SubmissionStatusCountCnt ref={statsRef}>
      <Grid container spacing={2}>
        {logSubmissionStatuses?.map((s) => {
          const itemCount = data?.[s?.id]?.count;
          const noCountText =
            (statusFilter?.includes(s?.id) && !itemCount) ||
            (isEmpty(statusFilter) && !itemCount)
              ? 0
              : 'N/A';
          return (
            <Grid item xs={12} sm={6}>
              <TileInnerCnt>
                {' '}
                {loading ? (
                  <WidgetLoaderCnt>
                    <CircularProgress size={20} />
                  </WidgetLoaderCnt>
                ) : (
                  <>
                    <label className={'statusText'} style={{ color: s?.color }}>
                      {s.label}
                    </label>
                    {itemCount || data?.[s?.id] ? (
                      <label className={'countsText'}>
                        {itemCount || data?.[s?.id]}
                      </label>
                    ) : (
                      <label className={'noCountText'}>{noCountText}</label>
                    )}
                  </>
                )}
              </TileInnerCnt>
            </Grid>
          );
        })}
      </Grid>
    </SubmissionStatusCountCnt>
  );
}

export default TemplateSubmissionStatusCounts;
