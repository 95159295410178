import React from 'react';
import CustomButton from 'components/Button/CustomButton';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

function DeleteWSAlertDialog({ open, handleClose, success }) {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this workspace account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton variant={'text'} onClick={handleClose}>
            Cancel
          </CustomButton>
          <CustomButton variant={'contained'} color={'error'} onClick={success}>
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteWSAlertDialog;
