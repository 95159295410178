import config from 'config/index';
import {
  ChangePasswordPostType,
  DeviceTokenInterface,
  ForgotPasswordInterFace,
  ILoginRequest,
  ProfilePicInterFace,
  UserProfileDetailInterFace,
} from 'models/index.m';
import { UserProfileModel } from '../models/index.m';
import {
  API_ROUTES,
  LOCALSTORAGE_KEYS,
  VALIDATION_SUCCESS_MESSAGES,
} from 'utils/globalVariable';
import {
  callApi,
  getHotelId,
  getItem,
  removeLoginStorage,
  SetAfterLoginStorage,
} from 'utils/globalFunction';

/*******************
  @Purpose : Used for Login api call
  @Parameter :
  @Author : INIC
  ******************/
export const loginApi = async (payload: ILoginRequest): Promise<any> => {
  const loginResponse = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.login,
    payload,
    'post',
    false,
    false,
    '',
    false,
  );

  if (loginResponse) {
    SetAfterLoginStorage(loginResponse);

    return { user: loginResponse?.data?.data };
  } else {
    return null;
  }
};

export const getUserContext = async (): Promise<any | null> => {
  const loginResponse = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.userContext,
    null,
    'get',
    false,
    false,
    '',
    false,
  );
  if (loginResponse !== null) {
    SetAfterLoginStorage(loginResponse);
    return { user: loginResponse?.data?.data };
  } else {
    return null;
  }
};

/*******************
  @Purpose : Used for changepassword api call
  @Parameter :
  @Author : INIC
  ******************/
export const changePasswordApi = async (
  changePasswordData: ChangePasswordPostType,
): Promise<boolean | undefined | null> => {
  try {
    const changePasswordResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.changePassword,
      changePasswordData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      VALIDATION_SUCCESS_MESSAGES.PASSWORD_UPDATED_SUCCESSFULLY,
    );
    return true;
  } catch (e) {
    return false;
  }
};

/*******************
  @Purpose : Used for forgotPassword api call
  @Parameter :
  @Author : INIC
  ******************/
export const forgotPasswordApi = async (
  forgotpasswordData: ForgotPasswordInterFace,
): Promise<boolean | undefined | null> => {
  const forgotPasswordResponse = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.forgotPassword,
    forgotpasswordData,
    'post',
    false,
    false,
    '',
    '',
  );
  if (forgotPasswordResponse !== null) {
    return true;
  } else {
    return null;
  }
};

/*******************
  @Purpose : Used for Logout api call
  @Parameter :
  @Author : INIC
  ******************/
export const logoutApi = async (): Promise<any> => {
  const token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
  const hotelId = getHotelId();
  removeLoginStorage();
  const userLogoutResponse = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.logout,
    { hotelId },
    'get',
    true,
    false,
    token,
    '',
    false,
  );
  if (userLogoutResponse == null) {
    return { user: null };
  }
};

/*******************
  @Purpose : Used for UserProfile Get Api call
  @Parameter :
  @Author : INIC
  ******************/
export const getMyProfile = async (): Promise<any> => {
  const userProfileResponse = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.userinfo,
    null,
    'get',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
  );
  if (userProfileResponse !== null) {
    return { user: userProfileResponse?.data?.data };
  } else {
    return { user: null };
  }
};

/*******************
  @Purpose : Used for UserProfile Get Api call
  @Parameter :
  @Author : INIC
  ******************/
export const getUserProfile = async (url): Promise<any> => {
  try {
    const userProfileResponse = await callApi(
      config.REACT_APP_BASE_URL + url,
      null,
      'get',
      false,
      false,
      null,
      '',
      false,
    );
    if (userProfileResponse !== null) {
      return { user: userProfileResponse?.data?.data };
    } else {
      return { user: null };
    }
  } catch (e) {
    console.log(e);
  }
};
/*******************
  @Purpose : Used for Industries Get Api call
  @Parameter :
  @Author : INIC
  ******************/
export const getIndustries = async (url): Promise<any> => {
  try {
    const industryResponse = await callApi(
      config.REACT_APP_BASE_URL + url,
      null,
      'get',
      false,
      false,
      null,
      '',
      false,
    );
    if (industryResponse !== null) {
      return { industries: industryResponse?.data?.data };
    } else {
      return { industries: null };
    }
  } catch (e) {
    console.log(e);
  }
};
/*******************
  @Purpose : Used for Category Get Api call
  @Parameter :
  @Author : INIC
  ******************/
export const getCategories = async (url): Promise<any> => {
  try {
    const categoryResponse = await callApi(
      config.REACT_APP_BASE_URL + url,
      null,
      'get',
      false,
      false,
      null,
      '',
      false,
    );
    if (categoryResponse !== null) {
      return { category: categoryResponse?.data?.data };
    } else {
      return { category: null };
    }
  } catch (e) {
    console.log(e);
  }
};
/*******************
  @Purpose : Used for Industr and Goals Patch Api call
  @Parameter :
  @Author : INIC
  ******************/
export const addIndustryAndGoals = async (url, data): Promise<any> => {
  try {
    const industryResponse = await callApi(
      config.REACT_APP_BASE_URL + url,
      data,
      'patch',
      false,
      false,
      '',
      '',
      false,
    );

    if (industryResponse !== null) {
      return { user: industryResponse?.data?.data };
    } else {
      return { user: null };
    }
  } catch (e) {
    console.log(e);
  }
};
/*******************
  @Purpose : Used for Email Authentication Post Api call
  @Parameter :
  @Author : INIC
  ******************/
export const emailOrPhoneAuth = async (url, data): Promise<any> => {
  try {
    const userProfileResponse = await callApi(
      config.REACT_APP_BASE_URL + url,
      data,
      'post',
      false,
      false,
      null,
      '',
      false,
      '',
      false,
    );

    if (!userProfileResponse.error) {
      return {
        user: userProfileResponse?.data?.data,
        message: userProfileResponse?.data?.message,
      };
    } else {
      return { user: null, message: userProfileResponse.error };
    }
  } catch (e) {
    console.log(e);
  }
};
/*******************
  @Purpose : Used for Create New Hotel Post Api call
  @Parameter :
  @Author : INIC
  ******************/
export const createNewHotel = async (url, data): Promise<any> => {
  try {
    const userProfileResponse = await callApi(
      config.REACT_APP_BASE_URL + url,
      data,
      'post',
      false,
      false,
      null,
      '',
      false,
    );
    if (userProfileResponse !== null) {
      return {
        user: userProfileResponse?.data?.data,
        message: userProfileResponse?.data?.message,
      };
    } else {
      return { user: null };
    }
  } catch (e) {
    console.log(e);
  }
};
/*******************
 @Purpose : Used for send magic link login
 @Parameter :
 @Author : INIC
 ******************/
export const sendLoginMagicLink = async (data): Promise<any> => {
  try {
    const userProfileResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.loginMagicLink,
      data,
      'post',
      false,
      false,
      null,
      '',
      true,
    );
    if (!userProfileResponse) {
      return { user: userProfileResponse?.data?.data };
    } else {
      return { user: null };
    }
  } catch (e) {
    console.log(e);
  }
};
/*******************
 @Purpose : Used to send otp to mobile
 @Parameter :
 @Author : INIC
 ******************/
export const setOtpLogin = async (data): Promise<any> => {
  try {
    const otpLoginResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.sendOtpLogin,
      data,
      'post',
      false,
      false,
      null,
      '',
      true,
    );
    if (!otpLoginResponse) {
      return { user: otpLoginResponse?.data?.data };
    } else {
      return { user: null };
    }
  } catch (e) {
    console.log(e);
  }
};

/*******************
 @Purpose : Used to verify otp sent to mobile
 @Parameter :
 @Author : INIC
 ******************/
export const verityOtp = async (data): Promise<any> => {
  try {
    const otpVerifyResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.verityOtp,
      data,
      'post',
      false,
      false,
      null,
      '',
      false,
    );
    if (otpVerifyResponse) {
      SetAfterLoginStorage(otpVerifyResponse);
      return { user: otpVerifyResponse?.data?.data };
    } else {
      return { user: null };
    }
  } catch (e) {
    console.log(e);
  }
};
/*******************
  @Purpose : Used for UserProfile Get Api call
  @Parameter :
  @Author : INIC
  ******************/
export const setUserProfilePic = async (
  profiledata: ProfilePicInterFace | any,
): Promise<any> => {
  const formData = new FormData();
  formData.append('file', profiledata.file);

  const userProfileResponse = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.updateUserProfilePic,
    formData,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
  );

  if (userProfileResponse !== null) {
    return userProfileResponse?.data?.data;
  } else {
    return null;
  }
};

/*******************
  @Purpose : Used for UserProfile Get Api call
  @Parameter :
  @Author : INIC
  ******************/
export const updateUserProfileInfo = async (
  userProfileUpdateData: UserProfileDetailInterFace,
): Promise<any> => {
  try {
    const userProfileResponse = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.updateUserProfileInfo +
        getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID),
      userProfileUpdateData,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (userProfileResponse && userProfileResponse !== null) {
      return { user: userProfileResponse?.data?.data };
    } else {
      return { user: null };
    }
  } catch (e) {
    console.log(e);
  }
};

/*******************
  @Purpose : Used for setting user password
  @Parameter :
  @Author : INIC
  ******************/
export const setUserPassword = async (
  userProfileUpdateData: UserProfileDetailInterFace,
): Promise<any> => {
  try {
    const { password, emailId, phoneNo, HotelId } = userProfileUpdateData;
    const hotelId = HotelId || getHotelId();
    const userId = getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID);
    const userProfileResponse = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/hotels/${hotelId?.trim()}/users/${userId?.trim()}/reset`,
      {
        password,
        emailId,
        phoneNo,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (userProfileResponse !== null) {
      return { user: userProfileResponse?.data?.data };
    } else {
      return { user: null };
    }
  } catch (e) {
    console.log(e);
  }
};

/*******************
  @Purpose : Used for getUserAdminProfile Get Api call
  @Parameter :
  @Author : INIC
  ******************/
export const getUserAdminProfile = async (profileId: number): Promise<any> => {
  const userAdminProfileResponseData = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.getUserProfile + profileId,
    null,
    'get',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
  );
  if (userAdminProfileResponseData !== null) {
    return userAdminProfileResponseData?.data?.data;
  } else {
    return null;
  }
};

/*******************
  @Purpose : Used for set Device Token
  @Parameter :
  @Author : INIC
  ******************/
export const setDeviceTokenApiCall = async (
  postData: DeviceTokenInterface,
  data: any,
): Promise<any> => {
  try {
    const deviceTokenRes = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.deviceToken + data.userId,
      {
        ...postData,
        hotelId: data.hotelId || getHotelId(),
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (deviceTokenRes !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const listenLoggedInUserChange = (
  handler: (user: UserProfileModel | null) => unknown,
): void => {
  setTimeout(async () => {
    const session = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
    if (!session || session === 'undefined' || session === null) {
      removeLoginStorage();
      handler(null);
      return;
    }
    const userInfo = await getMyProfile();
    handler(userInfo);
  }, 1000);
};

export const updateMyStatus = async (status: any): Promise<any> => {
  try {
    const statusResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.updateMyStatus + '/' + status,
      { status },
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    if (statusResponse !== null) {
      return statusResponse?.data?.data?.availabilityStatus;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for send password reset instructions
 @Parameter :
 @Author : Tauqeer Abbas
 ******************/
export const passwordResetInstructionsApi = async (
  postParam: any,
): Promise<any> => {
  await callApi(
    `${config.REACT_APP_BASE_URL}${API_ROUTES.userManagement.forgotInstructions}`,
    { ...postParam },
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    true,
  );
};

export const getMyWorkspaces = async (): Promise<any> => {
  const userAdminProfileResponseData = await callApi(
    config.REACT_APP_BASE_URL + API_ROUTES.getMyWorkspaces,
    null,
    'get',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    false,
  );
  if (userAdminProfileResponseData !== null) {
    return userAdminProfileResponseData?.data?.data;
  } else {
    return null;
  }
};

export const loginMethod = async (payload): Promise<any> => {
  const response = await callApi(
    config.REACT_APP_BASE_URL + 'hotel/login-method',
    payload,
    'post',
    false,
    false,
    '',
    false,
  );

  if (response) {
    return response?.data?.data;
  } else {
    return null;
  }
};

export const loginBySSO = async (payload = {}): Promise<any> => {
  const loginResponse = await callApi(
    config.REACT_APP_BASE_URL + 'hotel/login-by-sso',
    payload,
    'post',
    false,
    false,
    '',
    false,
  );

  if (loginResponse !== null) {
    SetAfterLoginStorage(loginResponse);
    return { user: loginResponse?.data?.data };
  } else {
    return null;
  }
};
