import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const TablesIcon = ({ filled }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.75 8.25H19.25M2.75 13.75H19.25M11 2.75V19.25M7.15 2.75H14.85C16.3901 2.75 17.1602 2.75 17.7485 3.04973C18.2659 3.31338 18.6866 3.73408 18.9503 4.25153C19.25 4.83978 19.25 5.60986 19.25 7.15V14.85C19.25 16.3901 19.25 17.1602 18.9503 17.7485C18.6866 18.2659 18.2659 18.6866 17.7485 18.9503C17.1602 19.25 16.3901 19.25 14.85 19.25H7.15C5.60986 19.25 4.83978 19.25 4.25153 18.9503C3.73408 18.6866 3.31338 18.2659 3.04973 17.7485C2.75 17.1602 2.75 16.3901 2.75 14.85V7.15C2.75 5.60986 2.75 4.83978 3.04973 4.25153C3.31338 3.73408 3.73408 3.31338 4.25153 3.04973C4.83978 2.75 5.60986 2.75 7.15 2.75Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TablesIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M10.0833 3.30016C10.0833 2.78678 10.0833 2.53009 9.98334 2.33401C9.89546 2.16152 9.75523 2.02129 9.58274 1.93341C9.38666 1.8335 9.12997 1.8335 8.61659 1.8335H5.64792C5.16472 1.83348 4.74783 1.83347 4.40448 1.86152C4.04208 1.89113 3.68163 1.9565 3.33478 2.13323C2.81733 2.39688 2.39664 2.81758 2.13299 3.33502C1.95626 3.68187 1.89089 4.04232 1.86128 4.40472C1.83322 4.74807 1.83324 5.16493 1.83325 5.64813C1.83325 5.93678 1.83325 6.0811 1.86547 6.19939C1.95083 6.51277 2.19564 6.75759 2.50902 6.84294C2.62732 6.87516 2.77164 6.87516 3.06028 6.87516H8.61659C9.12997 6.87516 9.38666 6.87516 9.58274 6.77525C9.75523 6.68737 9.89546 6.54714 9.98334 6.37465C10.0833 6.17857 10.0833 5.92188 10.0833 5.4085V3.30016Z"
        fill="#4E48FA"
      />
      <path
        d="M3.29992 8.7085C2.78654 8.7085 2.52985 8.7085 2.33376 8.80841C2.16128 8.89629 2.02105 9.03652 1.93316 9.209C1.83325 9.40509 1.83325 9.66178 1.83325 10.1752L1.83325 11.8252C1.83325 12.3385 1.83325 12.5952 1.93316 12.7913C2.02105 12.9638 2.16128 13.104 2.33376 13.1919C2.52985 13.2918 2.78654 13.2918 3.29992 13.2918H8.61659C9.12997 13.2918 9.38666 13.2918 9.58274 13.1919C9.75523 13.104 9.89546 12.9638 9.98334 12.7913C10.0833 12.5952 10.0833 12.3385 10.0833 11.8252L10.0833 10.1752C10.0833 9.66178 10.0833 9.40509 9.98334 9.209C9.89546 9.03652 9.75523 8.89629 9.58274 8.80841C9.38666 8.7085 9.12997 8.7085 8.61659 8.7085H3.29992Z"
        fill="#4E48FA"
      />
      <path
        d="M3.06025 15.1252C2.77164 15.1252 2.62733 15.1252 2.50905 15.1574C2.19565 15.2427 1.95082 15.4876 1.86547 15.801C1.83325 15.9192 1.83325 16.0635 1.83325 16.3522C1.83324 16.8354 1.83322 17.2523 1.86128 17.5956C1.89089 17.958 1.95626 18.3185 2.13299 18.6653C2.39664 19.1828 2.81733 19.6034 3.33478 19.8671C3.68163 20.0438 4.04208 20.1092 4.40448 20.1388C4.74783 20.1669 5.16469 20.1668 5.6479 20.1668H8.61659C9.12997 20.1668 9.38666 20.1668 9.58275 20.0669C9.75523 19.979 9.89546 19.8388 9.98334 19.6663C10.0833 19.4702 10.0833 19.2135 10.0833 18.7002V16.5918C10.0833 16.0784 10.0833 15.8218 9.98334 15.6257C9.89546 15.4532 9.75523 15.313 9.58275 15.2251C9.38666 15.1252 9.12997 15.1252 8.61659 15.1252H3.06025Z"
        fill="#4E48FA"
      />
      <path
        d="M11.9166 18.7002C11.9166 19.2135 11.9166 19.4702 12.0165 19.6663C12.1044 19.8388 12.2446 19.979 12.4171 20.0669C12.6132 20.1668 12.8699 20.1668 13.3833 20.1668H16.3519C16.8352 20.1668 17.252 20.1669 17.5954 20.1388C17.9578 20.1092 18.3182 20.0438 18.6651 19.8671C19.1825 19.6034 19.6032 19.1828 19.8669 18.6653C20.0436 18.3185 20.109 17.958 20.1386 17.5956C20.1666 17.2523 20.1666 16.8354 20.1666 16.3522C20.1666 16.0635 20.1666 15.9192 20.1344 15.8009C20.049 15.4876 19.8042 15.2427 19.4908 15.1574C19.3725 15.1252 19.2282 15.1252 18.9396 15.1252H13.3833C12.8699 15.1252 12.6132 15.1252 12.4171 15.2251C12.2446 15.313 12.1044 15.4532 12.0165 15.6257C11.9166 15.8218 11.9166 16.0784 11.9166 16.5918V18.7002Z"
        fill="#4E48FA"
      />
      <path
        d="M18.6999 13.2918C19.2133 13.2918 19.47 13.2918 19.6661 13.1919C19.8386 13.104 19.9788 12.9638 20.0667 12.7913C20.1666 12.5952 20.1666 12.3385 20.1666 11.8252V10.1752C20.1666 9.66178 20.1666 9.40509 20.0667 9.209C19.9788 9.03652 19.8386 8.89629 19.6661 8.80841C19.47 8.7085 19.2133 8.7085 18.6999 8.7085H13.3833C12.8699 8.7085 12.6132 8.7085 12.4171 8.80841C12.2446 8.89629 12.1044 9.03652 12.0165 9.20901C11.9166 9.40509 11.9166 9.66178 11.9166 10.1752L11.9166 11.8252C11.9166 12.3385 11.9166 12.5952 12.0165 12.7913C12.1044 12.9638 12.2446 13.104 12.4171 13.1919C12.6132 13.2918 12.8699 13.2918 13.3833 13.2918H18.6999Z"
        fill="#4E48FA"
      />
      <path
        d="M20.1666 5.64815C20.1666 5.93678 20.1666 6.08109 20.1344 6.19938C20.049 6.51277 19.8042 6.75759 19.4908 6.84295C19.3725 6.87516 19.2282 6.87516 18.9396 6.87516H13.3833C12.8699 6.87516 12.6132 6.87516 12.4171 6.77525C12.2446 6.68737 12.1044 6.54714 12.0165 6.37465C11.9166 6.17857 11.9166 5.92188 11.9166 5.4085V3.30016C11.9166 2.78678 11.9166 2.53009 12.0165 2.33401C12.1044 2.16152 12.2446 2.02129 12.4171 1.93341C12.6132 1.8335 12.8699 1.8335 13.3833 1.8335H16.3519C16.8351 1.83348 17.252 1.83347 17.5954 1.86152C17.9578 1.89113 18.3182 1.9565 18.6651 2.13323C19.1825 2.39688 19.6032 2.81758 19.8669 3.33502C20.0436 3.68187 20.109 4.04232 20.1386 4.40472C20.1666 4.74808 20.1666 5.16493 20.1666 5.64815Z"
        fill="#4E48FA"
      />
    </>,
    'TablesIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} />
  );
};
export default TablesIcon;
