import { Fragment, useRef, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'; // if using DnD
import 'pages/task/TaskListView/TaskCalendar/taskCalendar.scss';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import 'moment-timezone';
import CustomToolbar from 'pages/task/TaskListView/TaskCalendar/Toolbar/toolbar';
import { setDialog } from 'store/actions/dialogActions';
import CustomEventWrapper from 'pages/task/TaskListView/TaskCalendar/Renderers/EventWrapper';
import CustomEvent from 'pages/task/TaskListView/TaskCalendar/Renderers/CustomEvent';
import DIALOGS from 'utils/dialogIds';
import useDateTime, { tzDateFormat } from 'utils/CustomHooks/useDateTime';
import TimeLineEventHeader from 'pages/task/TaskListView/TaskCalendar/Renderers/TimeLineEventHeader';
import DateCell from 'pages/task/TaskListView/TaskCalendar/Renderers/DateCell';
import { useTaskEdit } from 'controller/useTaskEdit';
import useWorkingHours from 'utils/CustomHooks/useWorkingHours';

const DragAndDropCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);
function TaskCalendar({ handleEditDetailTask }) {
  const currentViewRef = useRef<any>(Views.MONTH);
  const { getTodayDateInTz, getUtcToTz } = useDateTime();
  const { editTaskEnabler } = useTaskEdit(null);
  const [view, setView] = useState<string>(Views.MONTH);
  const taskList: any = useSelector(selectors.getTaskList);
  const dispatch = useDispatch();
  const { Shift, getEndOfDay, getStartOfDay } = useWorkingHours();

  const topAlertState = useSelector(selectors.getTopAlertState);
  //When event is moved on the calendar, the dates should be updated respectively
  const moveEvent = ({ event, start, end }) => {
    //In case task is future instance
    if (!event.obj.taskNumber) {
      return;
    }
    const startDateOnly = moment(start).format('YYYY/MM/DD');

    const time = {
      taskTime:
        currentViewRef?.current == Views.MONTH
          ? { startTime: Shift?.startTime, dueTime: Shift?.endTime }
          : {
              startTime: moment(start)?.format('hh:mm A'),
              dueTime: moment(end)?.format('hh:mm A'),
            },
    };
    const taskObj = {
      ...event.obj,
      ...(currentViewRef?.current == Views.MONTH && {
        startTime: getStartOfDay(startDateOnly).toString(),
      }),
      ...(currentViewRef?.current == Views.MONTH && {
        dueTime: getEndOfDay(startDateOnly).toString(),
      }),
    };
    editTaskEnabler(taskObj, { ...time, isStateChanged: true });
  };
  //When user click on empty slot, create task dialog is opened to create new task on that specific date
  const handleSelectSlot = ({ start, end, slots, action, ...rest }) => {
    const isGreaterThanOneDay = moment(end).diff(moment(start), 'days') > 0;
    if (
      currentViewRef?.current == Views.MONTH ||
      (currentViewRef?.current !== Views.MONTH && isGreaterThanOneDay)
    ) {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createEditTask',
          data: {
            returnAllSchedule: true,
            dialogState: {
              viewLess: false,
            },
            taskTime: {
              startTime: Shift?.startTime,
              dueTime: Shift.dueTime,
            },
            taskData: {
              startTime: moment(start).format(tzDateFormat),
              dueTime: moment(end).subtract(1, 'day').format(tzDateFormat),
            },
          },
        }),
      );
    } else {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createEditTask',
          data: {
            returnAllSchedule: true,
            dialogState: {
              viewLess: false,
            },
            taskTime: {
              startTime: moment(start)?.format('hh:mm A'),
              dueTime: moment(end)?.format('hh:mm A'),
            },
            taskData: {
              startTime: moment(start).toString(),
              dueTime: moment(end).toString(),
            },
          },
        }),
      );
    }
    // }
  };

  const handleSelectEvent = (event) => {
    if (event.obj.taskNumber) {
      handleEditDetailTask(event?.id, 'detail');
    }
  };

  //Handle View Switch Month, Day, Week
  const handleViewSwitch = (view) => {
    setView(view);
    currentViewRef.current = view;
  };

  //Custom Components
  const getStartDate = (task) => {
    const startDate = task?.startTime;
    return startDate ? getUtcToTz(startDate) : '';
  };
  const getRecurranceDate = (task) => {
    const recurranceDate = task?.startDate;
    return recurranceDate ? getUtcToTz(recurranceDate) : '';
  };
  const getRecurranceDueDate = (task) => {
    const recurranceDueDate = task?.dueDate;
    return recurranceDueDate ? getUtcToTz(recurranceDueDate) : '';
  };
  const getDueDate = (task) => {
    const dueDate = task?.dueTime;
    return dueDate ? getUtcToTz(dueDate) : '';
  };

  const allCalendarTasks = () => {
    return taskList?.rows?.reduce((r, cv) => {
      const startDate = getStartDate(cv);
      const dueDate = getDueDate(cv);
      const recurrenceDate = getRecurranceDate(cv);
      const recurrenceDueDate = getRecurranceDueDate(cv);

      if (startDate || dueDate || (cv?.recurringTask && !cv.taskNumber)) {
        r = cv.taskNumber
          ? [
              ...r,
              {
                id: cv.id,
                title: cv.title,
                start:
                  (dueDate && startDate) || (!dueDate && startDate)
                    ? moment(startDate.format('YYYY-MM-DD HH:mm')).toDate()
                    : moment(
                        dueDate
                          .clone()
                          .subtract(30, 'minutes')
                          .format('YYYY-MM-DD HH:mm'),
                      ).toDate(),
                end:
                  (dueDate && startDate) || (dueDate && !startDate)
                    ? moment(dueDate.format('YYYY-MM-DD HH:mm')).toDate()
                    : moment(
                        startDate
                          .clone()
                          .add(30, 'minutes')
                          .format('YYYY-MM-DD HH:mm'),
                      ).toDate(),
                obj: cv,
              },
            ]
          : [
              ...r,
              {
                id: cv.id,
                title: cv.title,
                start: moment(
                  recurrenceDate.format('YYYY-MM-DD HH:mm'),
                ).toDate(),
                end: recurrenceDueDate
                  ? moment(
                      recurrenceDueDate.format('YYYY-MM-DD HH:mm'),
                    ).toDate()
                  : moment(
                      recurrenceDate
                        .clone()
                        .add(30, 'minutes')
                        .format('YYYY-MM-DD HH:mm'),
                    ).toDate(),
                obj: cv,
              },
            ];
      }
      return r;
    }, []);
  };
  const onShowMore = (tasksList) => {
    if (!tasksList?.length) return;
    const tasksObjList = tasksList.map((task) => {
      return task.obj;
    });
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.SHOW_MORE_TASKS,
        data: { tasks: tasksObjList },
      }),
    );
  };

  return (
    <Fragment>
      <div
        style={{
          height: topAlertState?.show
            ? 'calc(100vh - 210px)'
            : 'calc(100vh - 154px)',
          minHeight: 720,
          padding: '0 24px',
          flex: 1,
        }}
      >
        <DragAndDropCalendar
          components={{
            eventWrapper: CustomEventWrapper,
            dateCellWrapper: DateCell,
            month: {
              event: CustomEvent,
            },
            day: {
              header: TimeLineEventHeader,
            },
            week: {
              header: TimeLineEventHeader,
            },
            toolbar: (toolbar) =>
              CustomToolbar(toolbar, currentViewRef.current, handleViewSwitch),
          }}
          defaultDate={getTodayDateInTz().toDate()}
          defaultView={Views.MONTH}
          showMultiDayTimes={true}
          events={allCalendarTasks()}
          onSelectSlot={handleSelectSlot}
          localizer={localizer}
          onEventDrop={moveEvent}
          onEventResize={moveEvent}
          draggableAccessor={(event: any) => Boolean(event?.obj?.taskNumber)}
          onSelectEvent={handleSelectEvent}
          scrollToTime={getTodayDateInTz().toDate()}
          selectable
          resizable
          view={currentViewRef.current}
          onShowMore={onShowMore}
        />
      </div>
    </Fragment>
  );
}

export default TaskCalendar;
