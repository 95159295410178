import React from 'react';

import { Stack, Typography } from '@mui/material';

// Icons
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

interface IProps {
  heading: string;
  options: {
    id: number;
    label: string;
  }[];
  footerNote: string;
  selectedOptionId: number;
  onClickOption: (option: any) => void;
}

const LocationConfigBoxLayout = ({
  heading,
  footerNote,
  options,
  selectedOptionId,
  onClickOption,
}: IProps) => {
  return (
    <Stack gap={'12px'}>
      <Typography fontSize={'14px'} fontWeight={'600'} color={'#212121'}>
        {heading}
      </Typography>
      <Stack direction={'row'} flexWrap={'wrap'} gap={'12px'}>
        {options.map((o) => (
          <Stack
            onClick={() => onClickOption(o)}
            key={o.id}
            padding={'8px 12px'}
            border={`1px solid ${
              o.id === selectedOptionId ? 'rgba(104, 104, 254, 1)' : '#E0E0E0'
            }`}
            bgcolor={
              o.id === selectedOptionId ? 'rgba(235, 234, 255, 1)' : '#fff'
            }
            borderRadius={'4px'}
            sx={{
              cursor: 'pointer',
            }}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flex={1}
          >
            <Typography
              fontSize={'14px'}
              fontWeight={o.id === selectedOptionId ? '600' : '400'}
              color={o.id === selectedOptionId ? '#6868FE' : '#212121'}
            >
              {o.label}
            </Typography>
            {o.id === selectedOptionId && (
              <DoneRoundedIcon
                sx={{
                  color: '#6868FE',
                }}
              />
            )}
          </Stack>
        ))}
      </Stack>
      <Stack alignItems={'center'} direction={'row'} gap={'6px'}>
        <InfoRoundedIcon
          sx={{
            color: 'rgba(2, 136, 209, 1)',
            fontSize: '20px',
          }}
        />
        <Typography fontSize={'12px'} fontWeight={'600'} color={'#424242'}>
          {footerNote}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LocationConfigBoxLayout;
