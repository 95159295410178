// Icons
import DownloadIcon2 from 'components/Icons/downloadIcon2';

// Styles
import { DownloadButton } from './styled';

function TemplateActions(props) {
  const { disabled, handleDownloadReportApi, template } = props;
  return (
    <DownloadButton
      isdisabled={disabled ? 'true' : ''}
      startIcon={<DownloadIcon2 className="download-icon" />}
      data-attribute={'cell-click'}
      disabled={disabled}
      onClick={() =>
        handleDownloadReportApi({
          option: 'csv',
          templateId: template.id,
          status: [],
        })
      }
    >
      Download
    </DownloadButton>
  );
}

export default TemplateActions;
