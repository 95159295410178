import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { DescriptionInputWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import { CreateLocationContext } from 'components/AllDialogs/locations/createLocation/context/context';

const LocationDescriptionInput = ({ editable, updateAction, location }) => {
  const handleInputChange = (e) => {
    const value = e.target.value;
    updateAction({ description: value });
  };
  return (
    <DescriptionInputWithLabel>
      <label>Description</label>
      {editable ? (
        <CustomInput
          muiLabel={false}
          grayInput={true}
          fieldProps={{
            multiline: true,
            maxRows: 3,
            minRows: 3,
            placeholder: 'Add description (Optional)',
            onChange: handleInputChange,
            value: location.description,
          }}
        />
      ) : (
        <label className={'value'}>{location.description || '-'}</label>
      )}
    </DescriptionInputWithLabel>
  );
};

export default LocationDescriptionInput;
