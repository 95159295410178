/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { GroupMessagesRenderProps } from 'models/index.m';
import { useProfileChannel } from 'controller/useProfileChannel';
import { LeftMenuChannelActions } from './LeftMenuChannelActions';
import { isMutedChannel } from 'services/getStreamServices';
import UserAvatar from 'components/Avatar';
import { ListSingleChatCard } from './ListSingleChat.style';
import { Badge, List, ListItem } from '@mui/material';
import VolumeImg from 'public/assets/img/volume-x.svg';

export const GroupMessagesRender: FC<GroupMessagesRenderProps> = ({
  singleData,
  handleSetChannel,
  setIsArchiveShow,
  openChannelActionModal,
  selectedChannelId,
}: GroupMessagesRenderProps) => {
  const channel: any = singleData;
  const isMute = (channel && isMutedChannel(channel)) || false;
  const { memberObj }: any = useProfileChannel(channel);

  return (
    <React.Fragment key={channel.id}>
      <List style={{ minHeight: '52px', padding: 0 }}>
        <ListItem
          className={`click link ${
            selectedChannelId === channel?.id && 'selected'
          }`}
          onClick={() => {
            setIsArchiveShow(false);
            handleSetChannel(channel);
          }}
        >
          <ListSingleChatCard>
            <div className="avatarContainer">
              <Badge
                color="error"
                badgeContent={parseInt(channel?.state?.unreadCount)}
              >
                {
                  <UserAvatar
                    width={32}
                    height={32}
                    userId={memberObj?.userId}
                    firstName={channel?.data?.name || memberObj?.name}
                    profilePic="@@#6868FE"
                  />
                }
              </Badge>
            </div>
            <div className="nameContainer">
              <div className="leftMenuChannelHeader nameBox">
                <h5 className="elEllipses">
                  {channel?.data?.name || memberObj.name}
                </h5>
                {isMute && (
                  <span className="mutedChannel">
                    <img src={VolumeImg} />
                  </span>
                )}
                {/* <ChannelUnreadMuteStatus channel={channel} /> */}
              </div>
              <span className="lastMessage">
                {channel?.data?.member_count} members
              </span>
            </div>
          </ListSingleChatCard>
          <LeftMenuChannelActions
            channel={channel}
            openChannelActionModal={openChannelActionModal}
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
};
