import { useMemo, useCallback } from 'react';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';

import { columns, defaultColDef } from './constants';

// Styles
import './style.css';

interface IProps {
  height?: number | string;
  rowData: any;
  onRowClick?: (rowId: string) => void;
  onColumnChange?: (columns: any) => void;
  onTaskActionSuccess?: () => void;
  updateColumnStateCb?: (columnState: any) => void;
  isSizeColumnFit?: boolean;
  columnState?: any;
  columnConfig?: {
    name?: boolean;
    members?: boolean;
    locations?: boolean;
    actionCol?: boolean;
  };
  teamActionDropdownProps?: any;
}

const defaultColumnConfig = {
  name: true,
  members: true,
  locations: true,
  actionCol: true,
};

const TeamsTable = ({
  rowData,
  height = 400,
  columnConfig,
  columnState,
  onRowClick,
  updateColumnStateCb,
  teamActionDropdownProps,
}: IProps) => {
  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);

  const onColumnResized = (event) => {
    if (event?.finished && event.source !== 'api') {
      updateColumnStateCb?.(event.columnApi.getColumnState());
    }
  };

  const handleUpdateColumnState = (event) => {
    if (event.type === 'dragStopped') {
      updateColumnStateCb?.(event.columnApi.getColumnState());
    }
  };

  const gridColumns = useMemo(() => {
    const staticColumns = columns({
      onRowClick,
      columnConfig: {
        ...defaultColumnConfig,
        ...columnConfig,
      },
      teamActionDropdownProps,
    });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = columnState?.find((c) => c.colId === col.colId);
      const index = columnState?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [columnState, onRowClick, columnConfig, rowData]);

  return (
    <CustomListView
      rootProps={{
        className: 'teams-table',
        style: {
          height,
          boxShadow: 'none',
          flex: 1,
        },
      }}
      onRowClick={onRowClick}
      gridProps={{
        ref: (ref) => {
          globalThis.teamsTable = ref;
        },
        onGridReady: (params) => {
          params.api.sizeColumnsToFit();
        },
        headerHeight: 30,
        onSortChanged: handleUpdateColumnState,
        onDragStopped: handleUpdateColumnState,
        onColumnResized: onColumnResized,
        onColumnVisible: handleUpdateColumnState,
        onColumnPinned: handleUpdateColumnState,
        onColumnMoved: handleUpdateColumnState,
        rowData,
        suppressChangeDetection: true,
        defaultColDef: defaultColDef,
        columnDefs: gridColumns,
        suppressCellFocus: true,
        getRowHeight: getRowHeight,
        suppressRowClickSelection: true,
        getRowId: (params) => params.data.id,
      }}
    />
  );
};

export default TeamsTable;
