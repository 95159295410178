import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CTabs from 'components/CTabs';
import ManageSchedules from 'pages/Schedules/ManageSchedules/ManageSchedules';
import { Container } from 'pages/Schedules/style';
import AssetsView from 'pages/Assets/assets';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';

function AssetsTabs() {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showTabs, setShowTabs] = useState(false);
  const location = useLocation();

  const { hasPermission } = useCustomPermission();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_ASSETS)) {
      navigateWithWorkspaceUrl('/dashboard');
    }
  }, []);

  const tabs = useMemo(
    () => [
      {
        label: 'Assets',
        content: () => (activeTab === 0 ? <AssetsView /> : <></>),
      },
      {
        label: 'Asset Schedules',
        content: () =>
          activeTab === 1 ? (
            <ManageSchedules customPayload={{ hasAssets: true }} />
          ) : (
            <></>
          ),
      },
    ],
    [activeTab],
  );

  useEffect(() => {
    if (
      location?.pathname?.endsWith('/assets') ||
      location?.pathname?.endsWith('/assets/')
    ) {
      setShowTabs(true);
    } else {
      setShowTabs(false);
    }
  }, [location?.pathname]);

  const onTabChangeCb = (activeTab: number) => {
    setActiveTab(activeTab);
  };

  return (
    <Container>
      <CTabs
        data={tabs}
        onTabChangeCb={onTabChangeCb}
        activeTab={activeTab}
        variant="scrollable"
        rootSx={{ ...(!showTabs && { display: 'none' }) }}
      />
    </Container>
  );
}

export default AssetsTabs;
