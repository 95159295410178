import React, { useCallback } from 'react';
import TaskSummaryHeader from 'pages/ReportingV2/Reports/EmployeeProductivity/EmployeeProductivityHeader/EmployeeProductivityHeader';
import { EmployeeProductivityContext } from 'pages/ReportingV2/Reports/EmployeeProductivity/context/employeeProductivityContext';
import TaskStatusCounts from 'pages/Widgets/TaskStatusCounts/taskStatusCounts';
import TasksListSummary from 'pages/Widgets/TaskListing/TaskListView/tasksListSummary';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { INITIAL_LAYOUT_EMPLOYEE_PRODUCTIVITY_REPORT } from 'components/CustomTilesGrid/utils';
import CustomDragTilesGrid from 'components/CustomDragTilesGrid';
import useReports from 'pages/ReportingV2/Reports/useReports';
import { REPORTING_CONFIG_CONSTANTS } from 'pages/ReportingV2/Reports/constants';
import TasksByEmployee from 'pages/Widgets/TasksByEmployee/tasksByEmployee';
import TasksByTeam from 'pages/Widgets/TasksByTeam/tasksByTeam';
import { transformLayouts } from 'utils/globalFunction';
import { THRESHOLD } from 'components/CustomDragTilesGrid/utils';

function EmployeeProductivity() {
  const { workspaceId } = useWorkspaceHook();
  const {
    defaultProps,
    state,
    updateState,
    clearAllFilters,
    updateGridSettings,
    isFilterApplied,
  } = useReports(REPORTING_CONFIG_CONSTANTS.TASK_EMPLOYEE_PRODUCTIVITY_REPORT);

  const transformedLayouts = useCallback(() => {
    return state?.gridSettings
      ? transformLayouts(
          state?.gridSettings,
          INITIAL_LAYOUT_EMPLOYEE_PRODUCTIVITY_REPORT,
          THRESHOLD,
        )
      : null;
  }, [state?.gridSettings]);

  return (
    <EmployeeProductivityContext.Provider
      value={{ state, updateState, clearAllFilters }}
    >
      <div>
        <TaskSummaryHeader />
        <TaskStatusCounts {...defaultProps} isFilterApplied={isFilterApplied} />

        {state?.isLoading ? null : (
          <div style={{ margin: '20px 30px' }}>
            <CustomDragTilesGrid
              key={workspaceId}
              customLayout={
                transformedLayouts() ||
                INITIAL_LAYOUT_EMPLOYEE_PRODUCTIVITY_REPORT
              }
              onChangeCB={updateGridSettings}
            >
              <TasksByEmployee key="tasksByEmployee" {...defaultProps} />
              <TasksByTeam key="tasksByTeam" {...defaultProps} />
              <TasksListSummary key="taskListSummary" {...defaultProps} />
            </CustomDragTilesGrid>
          </div>
        )}
      </div>
    </EmployeeProductivityContext.Provider>
  );
}

export default EmployeeProductivity;
