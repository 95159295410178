// Custom components
import { SubmissionsOverviewHeaderCnt } from 'pages/ReportingV2/Reports/TemplateSubmissions/TemplateSubmissionsHeader/tempalteSubmissionsHeader.style';
import { TemplateSubmissionsContext } from 'pages/ReportingV2/Reports/TemplateSubmissions/context';
import StatusFilterDropdown from 'pages/Widgets/TemplateSubmissionsFilters/StatusFilter/StatusFilter';
import EmployeeFilterDropdown from 'pages/Widgets/TemplateSubmissionsFilters/EmployeeFilter/EmployeeFilter';
import TemplateFilterDropdown from 'pages/Widgets/TemplateSubmissionsFilters/TemplateFilter/TemplateFilter';
import LocationFilterDropdown from 'pages/Widgets/TemplateSubmissionsFilters/LocationFilter/LocationFilter';
import Tooltip from 'components/Tooltip';

// MUI
import { IconButton } from '@mui/material';

// Icons
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

// Hooks
import useTemplateSubmissionsFilters from 'pages/ReportingV2/Reports/useTemplateSubmissionsFilters';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import useResponsive from 'utils/CustomHooks/useResponsive';
import {
  dateRangePickerSideMenuCommon,
  reportingInitialRange,
} from 'utils/datePicker';

function TemplateSubmissionsHeader({ headerRef }) {
  const {
    isFilterApplied,
    selectedTemplates,
    selectedEmployees,
    selectedStatus,
    selectedLocations,
    clearAllFilters,
    state,
    handleClearDateFilter,
    handleClearFilter,
    handleFilterChange,
    handleDateChange,
  } = useTemplateSubmissionsFilters(TemplateSubmissionsContext);

  const { isMobileDeviceSm } = useResponsive();

  return (
    <SubmissionsOverviewHeaderCnt ref={headerRef}>
      <div className="taskOverviewHeaderLeft">
        <h3 className={'headingText'}>Template Submissions</h3>
        <TemplateFilterDropdown
          selected={selectedTemplates}
          onChangeCallback={(options) => {
            handleFilterChange(
              options.filter((o) => o.id !== 'all'),
              'checklists',
            );
          }}
          onClearCallback={() => handleClearFilter('checklists')}
        />
      </div>
      <div className="taskOverviewHeaderRight">
        <LocationFilterDropdown
          selectedIds={selectedLocations}
          onChangeCallback={(option) => handleFilterChange(option, 'locations')}
          onClearCallback={() => handleClearFilter('locations')}
        />
        <DateRangePickerCommon
          clearProps={{
            dateLabel: 'Last 30 Days',
            onClick: handleClearDateFilter,
            initialRange: reportingInitialRange,
            showClearBtn: state?.selectedDateLabel != 'Last 30 Days',
          }}
          buttonProps={{
            hideSelected: true,
          }}
          handleDateChange={handleDateChange}
          selectedDateLabel={state?.selectedDateLabel}
          showLabelPrefix="Date Range: "
          selectedDateRange={{
            from: state?.filters?.fromDate,
            to: state?.filters?.toDate,
          }}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
          popperProps={{
            placement: 'bottom-start',
          }}
        />

        <StatusFilterDropdown
          selected={selectedStatus}
          onChangeCallback={(option) => handleFilterChange(option, 'statuses')}
          onClearCallback={() => handleClearFilter('statuses')}
        />
        <EmployeeFilterDropdown
          showAuthorizedAssignees
          selected={selectedEmployees}
          onChangeCallback={(option) => handleFilterChange(option, 'users')}
          onClearCallback={() => handleClearFilter('users')}
        />
        {isFilterApplied ? (
          <Tooltip title="Clear All Filters">
            <IconButton onClick={clearAllFilters}>
              <FilterAltOffIcon sx={{ color: '#EF5350' }} />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    </SubmissionsOverviewHeaderCnt>
  );
}

export default TemplateSubmissionsHeader;
