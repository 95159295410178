// React
import { useMemo } from 'react';

// MUI components
import { Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import SelectItemsDropdown from 'components/Dropdown/SelectItemsDropdown/SelectItemsDropdown';

// Utilities
import { generateChecklistFiltersData } from 'helper/task/dropdownData';

const ChecklistFilters = () => {
  const dispatch = useDispatch();

  // filters from store
  const selectedFilters = useSelector(selectors.getChecklistFilters);

  const checklistTypes: any = useSelector(selectors.getChecklistTypes);
  const checklistIndustries: any = useSelector(
    selectors.getChecklistIndustries,
  );

  const typeDropdownData = useMemo(() => {
    return generateChecklistFiltersData(checklistTypes);
  }, [checklistTypes]);

  const industryDropdownData = useMemo(() => {
    return generateChecklistFiltersData(checklistIndustries);
  }, [checklistIndustries]);

  const handleUpdateFilter = (values, key) => {
    const updatedFilters = { ...selectedFilters, [key]: values };
    dispatch(actions.setChecklistFilters(updatedFilters));
  };

  const handleClearFilter = () => {
    dispatch(actions.setChecklistFilters({ types: [], industries: [] }));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <SelectItemsDropdown
        buttonProps={{
          id: 'typeDropdownBtn',
          variant: 'dropdown',
          style: { marginRight: 7 },
        }}
        selectedOptions={selectedFilters['types']}
        controlled={true}
        checkbox={true}
        canSearch={true}
        popperProps={{
          id: 'typeDropdown',
          style: { width: 288 },
          placement: 'bottom-start',
        }}
        options={typeDropdownData}
        handleChangeCallback={(values) => handleUpdateFilter(values, 'types')}
      >
        Type
      </SelectItemsDropdown>
      <SelectItemsDropdown
        buttonProps={{
          id: 'industryDropdownBtn',
          variant: 'dropdown',
          style: { marginRight: 7 },
        }}
        selectedOptions={selectedFilters['industries']}
        controlled={true}
        checkbox={true}
        canSearch={true}
        popperProps={{
          id: 'industryDropdown',
          style: { width: 288 },
          placement: 'bottom-start',
        }}
        options={industryDropdownData}
        handleChangeCallback={(values) =>
          handleUpdateFilter(values, 'industries')
        }
      >
        Industry
      </SelectItemsDropdown>
      <CustomButton
        variant={'text'}
        startIcon={<ClearIcon />}
        buttonType={'gray'}
        onClick={handleClearFilter}
      >
        <>Clear all</>
      </CustomButton>
    </Box>
  );
};

export default ChecklistFilters;
