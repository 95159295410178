// Custom components
import UserStatusDropdown from 'components/layout/navbar/userStatusDropdown';

// Styled components
import {
  Container,
  Section,
  StyledDivider,
  Title,
  ListWrapper,
  ListItem,
} from './styled';

// Icons
// Organization Icons
import OrganizationIcon from '@mui/icons-material/CasesOutlined';
import UsersIcon from '@mui/icons-material/PeopleAltOutlined';
import TeamsIcon from '@mui/icons-material/GroupsOutlined';
import BillingIcon from '@mui/icons-material/ReceiptLong';
import InviteAvatarIcon from 'components/Icons/inviteAvatarIcon';
// My Account Icons
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
// Help Icons
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

// Custom hooks.ts
import { useSettingsPopup } from './useSettingsPopup';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// Constants
import { TALK_TO_SALES_URL } from 'utils/constants';
import ActivityLogIcon from 'components/Icons/activityLogIcon';

const SettingsPopup = ({ handleClosePopper }) => {
  const { PERMISSIONS, hasPermission } = useCustomPermission();
  const {
    navigate,
    handleLogout,
    handleClickInviteUser,
    handleClickDownloadMobileApp,
  } = useSettingsPopup();

  const canConfigWorkSpace = hasPermission(
    PERMISSIONS.CAN_MANAGE_WORKSPACE_CONFIG,
  );
  const canManageUsers = hasPermission(PERMISSIONS.CAN_MANAGE_USERS);
  const canManageTeams = hasPermission(PERMISSIONS.CAN_MANAGE_TEAMS);
  const canManageBilling = hasPermission(PERMISSIONS.CAN_MANAGE_BILLING);
  const canManageActivityLogs = hasPermission(
    PERMISSIONS.CAN_MANAGE_ACTIVITY_LOGS,
  );

  return (
    <Container>
      <Section width={140}>
        <>
          <Title>Organization</Title>
          <ListWrapper onClick={handleClosePopper}>
            <ListItem
              onClick={() => navigate('/settings/organization-management')}
              startIcon={<OrganizationIcon />}
            >
              Organization
            </ListItem>

            {canManageUsers && (
              <ListItem
                onClick={() => navigate('/users-and-teams?tab=users')}
                startIcon={<UsersIcon />}
                endIcon={
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClosePopper();
                      handleClickInviteUser();
                    }}
                  >
                    <InviteAvatarIcon style={{ fontSize: 24, marginLeft: 8 }} />
                  </div>
                }
              >
                Users
              </ListItem>
            )}

            {canManageTeams && (
              <ListItem
                onClick={() => navigate('/users-and-teams?tab=teams')}
                startIcon={<TeamsIcon />}
              >
                Teams
              </ListItem>
            )}

            {canManageBilling && (
              <ListItem
                onClick={() => navigate('/settings/billing')}
                startIcon={<BillingIcon />}
              >
                Billing
              </ListItem>
            )}

            {canManageActivityLogs && (
              <ListItem
                onClick={() => navigate('/settings/activity-logs')}
                startIcon={<ActivityLogIcon />}
              >
                Activity Logs
              </ListItem>
            )}
          </ListWrapper>{' '}
          <StyledDivider />{' '}
        </>

        <Title>My Account</Title>
        <ListWrapper onClick={handleClosePopper}>
          <ListItem
            onClick={() =>
              navigate(
                canConfigWorkSpace
                  ? '/settings/organization-management'
                  : '/settings/my-profile',
              )
            }
            startIcon={<SettingsIcon />}
          >
            Settings
          </ListItem>
          <ListItem
            onClick={() => {
              handleLogout();
            }}
            startIcon={<LogoutIcon />}
          >
            Logout
          </ListItem>
        </ListWrapper>
      </Section>
      <StyledDivider orientation="vertical" direction="vertical" flexItem />
      <Section>
        <Title>Status</Title>
        <ListWrapper>
          <UserStatusDropdown onOptionClick={handleClosePopper} />
        </ListWrapper>

        <StyledDivider />

        <Title>Help</Title>
        <ListWrapper onClick={handleClosePopper}>
          <ListItem
            onClick={() => window.open('mailto:support@xenia.team', '_blank')}
            startIcon={<SupportAgentIcon />}
          >
            Contact Support
          </ListItem>
          <ListItem
            onClick={() => window.open('https://help.xenia.team', '_blank')}
            startIcon={<HelpOutlineOutlinedIcon />}
          >
            Help Center
          </ListItem>
          <ListItem
            onClick={() => handleClickDownloadMobileApp()}
            startIcon={<DownloadForOfflineOutlinedIcon />}
          >
            Download Mobile App
          </ListItem>
          <ListItem
            onClick={() => window.open(TALK_TO_SALES_URL, '_blank')}
            startIcon={<CallOutlinedIcon />}
          >
            Book a Call with Sales
          </ListItem>
        </ListWrapper>
      </Section>
    </Container>
  );
};

export default SettingsPopup;
