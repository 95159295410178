import { styled } from '@mui/system';

export const EditMessageModal = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: 500,
  padding: 30,
  '.addEditDepartmentForm': {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  '.title': {
    fontWeight: 700,
    fontSize: 20,
    color: 'rgba(0,0,0,0.87)',
  },
});
