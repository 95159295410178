import { useState } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import CustomButton from 'components/Button/CustomButton';
import MagicLinkIcon from 'components/Icons/magicLinkIcon';
import PhoneLinkIcon from 'components/Icons/phoneLinkIcon';
import PasswordInput from 'components/Form/TextField/PasswordField.cmp';
import { getItem, validateEmail } from 'utils/globalFunction';
import {
  LOCALSTORAGE_KEYS,
  VALIDATION_ERROR_MESSAGES,
} from 'utils/globalVariable';
import { useReactRouter } from 'controller/useReactRouter';
import xeniaApi from 'api/index';
import useUserLogin from 'utils/CustomHooks/useUserLogin';
import {
  DividerLine,
  EmailContainer,
  ForgotCnt,
  GetLoginLink,
  LabelTitle,
  LoginThroughPhoneBtn,
  MainTitle,
  NextButton,
  OrDivider,
  PasswordContainer,
  SignUpContent,
  SubTitle,
} from './style';
import XeniaLogoSymbol from 'public/assets/img/xenia-logo-symbol.svg';
import { Stack } from '@mui/material';

function LoginWithEmail(props) {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showSSOButton, setShowSSOButton] = useState<boolean>(false);
  const [showSSOLink, setShowSSOLink] = useState<string | null>(null);
  const [loginBtnLoader, setLoginBtnLoader] = useState<boolean>(false);
  const [magicLinkBtnLoader, setMagicLinkBtnLoader] = useState<boolean>(false);
  const { handleRedirectToRoute } = useReactRouter();
  const { setLoggedInUser, setDeviceToken } = useUserLogin();

  //Handle Email Input Change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmailError('');
    setEmail(value);
  };
  //Handle password input change
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPasswordError('');
    setPassword(value);
  };
  //Login User API call and dispatch user in store
  const loginUserCall = (credentials) => {
    xeniaApi
      .loginApi(credentials)
      .then((res) => {
        if (res) {
          setLoggedInUser(res);
        }
      })
      .catch((e) => {
        setLoginBtnLoader(false);
      });
  };

  //Validating email and passsword on login and making api call
  const handleLogin = async () => {
    const isEmailValid = validateEmailInput();
    if (!isEmailValid) return;
    setLoginBtnLoader(true);
    if (!password) {
      setPasswordError(VALIDATION_ERROR_MESSAGES.REQUIRED_PASSWORD_ID);
      setLoginBtnLoader(false);
      return;
    }
    await setDeviceToken();
    const credentials = {
      emailId: email.trim(),
      password,
      device: 'WEB',
      accessType: 'WEB',
      deviceToken: getItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN),
    };

    loginUserCall(credentials);
  };
  //Validate Email Input
  const validateEmailInput = () => {
    if (!email) {
      setEmailError(VALIDATION_ERROR_MESSAGES.REQUIRED_EMAIL_ID);
      return false;
    }
    if (!validateEmail(email)) {
      {
        setEmailError(VALIDATION_ERROR_MESSAGES.REQUIRED_VALID_EMAIL_ID);
        return false;
      }
    }
    return true;
  };
  //Entering email and click on next to show password screen
  const handleNext = () => {
    const isEmailValid = validateEmailInput();

    isEmailValid && handleCheckLoginMethod();
    // isEmailValid && setShowPassword(true);
  };

  const handleCheckLoginMethod = async () => {
    const response = await xeniaApi.loginMethod({
      emailId: email,
    });
    const { sso, password } = response;
    if (sso.enabled && sso.link) {
      if (password.enabled) {
        setShowPassword(true);
        setShowSSOButton(true);
        setShowSSOLink(sso.link);
      } else {
        window.location.href = sso.link;
      }
    } else if (password.enabled) {
      setShowPassword(true);
    }
  };

  const navigateToLoginWithPhone = () => {
    handleRedirectToRoute('/sign-in/phone-number');
  };
  const navigateToResetPassword = () => {
    handleRedirectToRoute('/sign-in/reset-password');
  };
  const handleSendMagicLink = async () => {
    setMagicLinkBtnLoader(true);
    if (!validateEmailInput()) {
      setMagicLinkBtnLoader(false);
      return;
    }
    await xeniaApi.sendLoginMagicLink({
      emailId: email,
    });
    setMagicLinkBtnLoader(false);
  };
  //When user press enter key
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode == 13 && showPassword) {
      handleLogin();
      return;
    }
    if (keyCode == 13) {
      handleNext();
    }
  };
  const navigateToRegister = () => {
    handleRedirectToRoute('/register');
  };

  return (
    <>
      <img
        src={XeniaLogoSymbol}
        className="logo"
        height={40}
        width={70}
        style={{ marginBottom: 20 }}
      />
      <MainTitle>Log in to Xenia</MainTitle>
      <SubTitle>Enter your email to log into your account.</SubTitle>

      <EmailContainer>
        <LabelTitle>Email</LabelTitle>
        <CustomInput
          error={emailError}
          muiLabel={true}
          fieldProps={{
            autoFocus: true,
            onKeyDown: handleKeyDown,
            onChange: handleEmailChange,
            autoComplete: 'off',
            name: 'email',
            type: 'email',
            value: email,
            placeholder: 'Enter your email',
            style: {
              borderRadius: 8,
              height: 47,
            },
          }}
        />
      </EmailContainer>

      {showPassword && (
        <PasswordContainer>
          <LabelTitle>Password</LabelTitle>
          <PasswordInput
            muiLabel={true}
            error={passwordError}
            style={{
              display: !showPassword ? 'none' : 'flex',
              marginBottom: 10,
            }}
            fieldProps={{
              onChange: handlePasswordChange,
              onKeyDown: handleKeyDown,
              value: password,
              placeholder: 'Type your password',
              style: {
                borderRadius: 8,
                height: 47,
              },
            }}
          />
        </PasswordContainer>
      )}

      {showPassword && (
        <ForgotCnt>
          <CustomButton
            variant={'text'}
            buttonType="gray"
            onClick={navigateToResetPassword}
          >
            Forgot Password?
          </CustomButton>
        </ForgotCnt>
      )}
      <Stack width={'100%'} flexDirection={'column'} gap={2}>
        {!showPassword ? (
          <NextButton variant="contained" onClick={handleNext}>
            Next
          </NextButton>
        ) : (
          <NextButton
            variant="contained"
            buttonLoader={loginBtnLoader}
            onClick={handleLogin}
          >
            Log in
          </NextButton>
        )}
        {showPassword && showSSOButton && (
          <>
            <OrDivider>OR</OrDivider>
            <NextButton
              variant="contained"
              buttonLoader={loginBtnLoader}
              onClick={handleLogin}
              href={showSSOLink}
            >
              Log in with SSO
            </NextButton>
          </>
        )}
      </Stack>
      <GetLoginLink
        variant={'outlined'}
        onClick={handleSendMagicLink}
        buttonLoader={magicLinkBtnLoader}
        startIcon={<MagicLinkIcon />}
      >
        No password? Get login link
      </GetLoginLink>

      <DividerLine />

      <LoginThroughPhoneBtn
        variant={'outlined'}
        onClick={navigateToLoginWithPhone}
        startIcon={<PhoneLinkIcon />}
      >
        Log in using your phone number
      </LoginThroughPhoneBtn>

      <SignUpContent>
        Don't have an account?{' '}
        <span className="link" onClick={navigateToRegister}>
          Sign Up
        </span>
      </SignUpContent>
    </>
  );
}
export default LoginWithEmail;
