import { Stack, Typography } from '@mui/material';
import { useCurrency } from 'customhooks/index';

interface IProps {
  cost: number;
}

const TaskCost = ({ cost }: IProps) => {
  const { currency } = useCurrency();
  return (
    <Stack height={'100%'} justifyContent={'center'}>
      <Typography
        fontWeight={'400'}
        fontSize={'14px'}
        color={'rgba(0, 0, 0, 0.6)'}
        letterSpacing={'0.17px'}
      >
        {cost ? `${currency?.symbol} ${cost}` : '-'}
      </Typography>
    </Stack>
  );
};

export default TaskCost;
