/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
// import CheckNavigation from 'components/layout/checkNavigation';
import WithReduxStore from 'store/with-redux-store';
import { ToastContainer } from 'react-toastify';
// style integration
import 'bootstrap/dist/css/bootstrap.min.css';
import 'public/assets/scss/style.scss';
import { firebaseConfig } from 'utils/globalVariable';
import { onMessageListener } from 'components/firebase';
import AttachmentPreviewDialog from 'components/AttachmentPreviewDialog';

import GetStreamIO from 'components/GetStreamIO';
import SocketConnect from 'components/SocketConnect';
import PushNotification from 'components/PushNotification';

import AppRoutes from 'components/Routes/routes';
import redirectToNewDomain from 'utils/redirectToNewDomain';
import ServiceBell from 'components/ServiceBell';
import Appcues from 'components/Appcues';
import useSentry from 'utils/CustomHooks/useSentry';
import useBuilderIO from 'utils/CustomHooks/useBuilderIO';
import useHistoryBuilder from 'utils/CustomHooks/useHistoryBuilder';

function App(props) {
  useSentry();
  useHistoryBuilder();
  const { initBuilder } = useBuilderIO();

  useEffect(() => {
    redirectToNewDomain();
    initBuilder();

    const firebaseData = new URLSearchParams(firebaseConfig as any).toString();
    if (typeof window !== 'undefined') {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register(`/firebase-messaging-sw.js?${firebaseData}`)
          .then(
            function () {
              console.log('Register Service Successfully');
            },
            function (err) {
              console.log('Register Service failed', err);
            },
          );
        onMessageListener();
      }
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <GetStreamIO />
      <SocketConnect />
      <PushNotification />
      <ServiceBell />
      <Appcues />
      <AttachmentPreviewDialog />
      <AppRoutes />
      {/*<CheckNavigation>*/}
      {/*  <Component {...pageProps} />*/}
      {/*</CheckNavigation>*/}
    </>
  );
}

export default WithReduxStore(App);
