import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MainCnt, Value } from 'pages/documents/listing/listView/list.style';
import UserOrTeamAvatarGroup from 'components/Avatar/UserOrTeamAvatarGroup';
import selectors from 'store/selectors';
import { WORKSPACE_ACCESS } from 'components/DocumentAccess/constants';
import { ContentCnt } from 'pages/documents/listing/listView/renderers/editors/style';

function Viewers(props) {
  const { document } = props;
  const { DocumentAccess, workspaceAccess } = document;

  const users = useSelector(selectors.getUserAdmin);
  const viewers = DocumentAccess?.filter((item) => !item?.canEdit);

  const data = useMemo(() => {
    if (viewers?.length === 1) {
      return users?.find((user) => user?.id === viewers?.[0]?.AccessorId);
    }
    return null;
  }, [viewers]);

  return (
    <MainCnt>
      {workspaceAccess === WORKSPACE_ACCESS?.workspaceView && (
        <Value>All users & teams</Value>
      )}

      {workspaceAccess !== WORKSPACE_ACCESS?.workspaceView &&
      viewers?.length > 0 ? (
        <ContentCnt>
          <UserOrTeamAvatarGroup
            members={viewers?.map((access) => access?.AccessorId)}
          />
          {viewers?.length === 1 && (
            <Value style={{ marginLeft: 7 }}>
              {data?.firstName} {data?.lastName}
            </Value>
          )}
        </ContentCnt>
      ) : (
        workspaceAccess !== WORKSPACE_ACCESS?.workspaceView && <Value>-</Value>
      )}
    </MainCnt>
  );
}

export default Viewers;
