import { Paper } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { MainCnt } from 'pages/documents/detail/linkUnavailable/style';
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import CTypography from 'components/CTypography';

import IconLinkUnavailable from 'public/assets/img/IconLinkUnavailable.svg';

const LinkUnavailable = () => {
  const goToHome = () => {
    window.location.href = '/';
  };

  return (
    <MainCnt>
      <Paper className="contentCnt">
        <img
          src={XeniaLogo}
          width={110}
          height={22}
          style={{ marginBottom: 50 }}
        />
        <div className="innerCnt">
          <img src={IconLinkUnavailable} />
          <CTypography className="title">Link Unavailable</CTypography>
          <CTypography className="statement">
            This link has either expired or the document is no longer public.
            Please contact your administrator for assistance.
          </CTypography>

          <CustomButton
            variant="contained"
            className="goToHome"
            sx={{ padding: '2px 16px' }}
            onClick={goToHome}
          >
            Go to Xenia Home
          </CustomButton>
        </div>
      </Paper>
    </MainCnt>
  );
};

export default LinkUnavailable;
