import { createSvgIcon } from '@mui/material';

const LightbulbIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_10569_192561)">
        <path
          opacity="0.4"
          d="M9.99996 1.66666V2.49999M2.49996 9.99999H1.66663M4.58329 4.58332L4.08321 4.08324M15.4166 4.58332L15.9168 4.08324M18.3333 9.99999H17.5M8.33329 11.25H11.6666M9.99996 11.25V15.4167"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.9167 14.0616C14.1784 13.154 15 11.6729 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 11.6729 5.82161 13.154 7.08333 14.0616V15.6667C7.08333 16.6001 7.08333 17.0668 7.26499 17.4233C7.42478 17.7369 7.67975 17.9919 7.99335 18.1517C8.34987 18.3333 8.81658 18.3333 9.75 18.3333H10.25C11.1834 18.3333 11.6501 18.3333 12.0067 18.1517C12.3203 17.9919 12.5752 17.7369 12.735 17.4233C12.9167 17.0668 12.9167 16.6001 12.9167 15.6667V14.0616Z"
          stroke="#757575"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10569_192561">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </>,
    'LightbulbIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default LightbulbIcon;
