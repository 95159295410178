import { Fab } from '@mui/material';
import styled from '@mui/system/styled';

export const FlexCnt = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '100%',
    gap: 10,
  },
}));

export const HeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 0 12px 0',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'start',
    flexDirection: 'column',
  },
  '.lefCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  '.rightCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
      justifyContent: 'end',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },

    '.folders-action-dropdown': {
      zIndex: 1,
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        top: -60,
        right: 0,
      },

      '& button': {
        padding: '4px 10px',
        [theme.breakpoints.down('sm')]: {
          position: 'fixed',
          bottom: 10,
          right: 10,
          padding: 0,
          minWidth: 45,
          height: 45,
          borderRadius: '50%',
        },
      },
    },
  },
}));
