import {
  Container,
  TitleText,
  GreyText,
  ScheduleCnt,
  LocationNameCnt,
} from './renderer.style';

// Icons
import RecurringIcon from 'components/Icons/recurringIcon';

// Constants
import { weeks } from 'components/RepeatTask/Weekly/constants';
import { months } from 'components/RepeatTask/Monthly/constants';
import { useSelector } from 'react-redux';
import { getAuthLocationsHash } from 'store/selectors/locations';
import { useMemo } from 'react';

export const Title = ({ title }: { title: string }) => {
  return (
    <Container>
      <TitleText className="schedule-title">{title}</TitleText>
    </Container>
  );
};

export const Location = ({ location }) => {
  const authLocationsHash = useSelector(getAuthLocationsHash);

  const parentLocation = useMemo(() => {
    if (location?.ParentId) {
      const parentNode = authLocationsHash?.[location?.ParentId];
      return parentNode?.authorized ? parentNode : null;
    }

    return null;
  }, [location?.ParentId]);

  return (
    <LocationNameCnt isParent={parentLocation?.name}>
      <div className="content">
        <span className={'locationName'}> {location?.name}</span>
        {parentLocation?.name && (
          <span className="parentLocationName"> {parentLocation?.name}</span>
        )}
      </div>
    </LocationNameCnt>
  );
};

export const Schedule = ({ recurringByEvery }: { recurringByEvery: any }) => {
  const scheduleList = [...weeks, ...months];
  const schedule = scheduleList?.find(
    (item) => item.id === recurringByEvery,
  )?.displayTitle;
  if (!schedule) return <>-</>;
  return (
    <ScheduleCnt>
      <div className="innerCnt">
        <RecurringIcon />
        <GreyText>{schedule}</GreyText>
      </div>
    </ScheduleCnt>
  );
};
