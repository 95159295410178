import { Stack, Typography, styled } from '@mui/material';

export const Container = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'center',
}));

export const Value = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#212121',
}));
