import styled from '@mui/system/styled';
import Chip from '@mui/material/Chip';
import CTypography from 'components/CTypography';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const IconButtonStyled = styled(IconButton)({
  color: '#0000008A',
  position: 'absolute',
  bottom: 0,
  right: 0,
  background: 'white',
  boxShadow: '1px 1px 4px 2px #979797',
  '&: hover': {
    background: 'white',
  },
});

export const StackItem = styled('div')({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  paddingTop: '4px',
  '& span': {
    paddingLeft: '8px',
    paddingTop: '2px',
    display: 'inline-block',
  },
});

export const AvatarContainer = styled('div')({
  width: '80px',
  marginRight: '24px',
  position: 'relative',
});

export const ChipWrap = styled('div')({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '32px',
  color: '#000000DE',
  paddingTop: '10px',
  paddingBottom: '2px',
});

export const ChipStyled = styled(Chip)({
  color: 'white',
  background: '#5555FF',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '18px',
});

export const CTypographyStyled = styled(CTypography)({
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '22px',
  color: '#000000DE',
  paddingTop: '16px',
  paddingLeft: '16px',
});

export const LabelField = styled('label')({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  marginBottom: 0,
  paddingTop: '7px',
  paddingLeft: '16px',
  color: '#00000099',
  width: 288,
});

export const CTypographyProfile = styled(CTypography)({
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '22px',
  color: '#000000DE',
  paddingTop: '16px',
  paddingLeft: '16px',
});

export const LabelFieldProfile = styled('label')({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  marginBottom: 0,
  paddingTop: '7px',
  paddingLeft: '16px',
  color: '#00000099',
});

export const LocationBtnCnt = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 320,
  height: 40,
  border: '1px solid #80808073',
  borderRadius: 4,
  padding: '10.5px 14px',
  alignItems: 'center',
  gap: 20,
  cursor: 'pointer',
  '&:hover': {
    border: '1px solid rgba(0, 0, 0, 0.87)',
  },
  '& .text': {
    color: '#757575',
    fontWeight: 500,
    fontSize: 14,
  },
});

export const LocationsActionWrapper = styled(Box)({
  padding: '10px 0px',
  borderTop: '1px solid #0000001F',
});

export const LocationsActionBtn = styled(CustomButton)({
  fontWeight: '500',
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#6868FE',
});

export const SelectedText = styled(Typography)({
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '19.12px',
  color: '#212121',
});
