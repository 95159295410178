import { Box, Grid, Stack, Typography } from '@mui/material';
import AddIcon from 'components/Icons/addIcon';
import { CreateNewBtn, MainCnt, SectionCnt } from './styled';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';

import ProjectStarted from 'public/assets/img/projects-started.svg';
import AssetBasedProject from 'public/assets/img/asset-based-project.svg';
import UserBasedProject from 'public/assets/img/user-based-project.svg';
import actions from 'store/actions';
import { TALK_TO_SALES_URL } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useMemo } from 'react';

const ProjectsEmptyState = (props) => {
  const { createProjectCallback } = props;

  const dispatch = useDispatch();
  const { hasFeature, FEATURES, hasPermission, PERMISSIONS } =
    useCustomPermission();

  const canCreateProject = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_CREATE_PROJECT);
  }, [PERMISSIONS]);

  const createProject = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_PROJECT,
        data: {
          createProjectCallback,
        },
      }),
    );
  };

  const talkToSales = () => {
    dispatch(
      actions.setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <MainCnt>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SectionCnt>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <Typography className="title">
                  Get Started with Projects
                </Typography>
                <Typography className="subTitle" style={{ width: 510 }}>
                  Projects allow you to create, assign, and edit multiple
                  schedules at once. Create separate schedules for each user,
                  asset, team, or location, and manage all of them from one
                  page.
                </Typography>
                {hasFeature(FEATURES.PROJECTS) ? (
                  canCreateProject ? (
                    <CreateNewBtn
                      startIcon={<AddIcon />}
                      variant="contained"
                      onClick={createProject}
                      style={{
                        padding: '7px 16px',
                        borderRadius: '8px',
                        fontWeight: 600,
                      }}
                    >
                      Create Your First Project
                    </CreateNewBtn>
                  ) : (
                    <></>
                  )
                ) : (
                  <CreateNewBtn
                    variant="contained"
                    onClick={talkToSales}
                    style={{
                      padding: '7px 16px',
                      borderRadius: '8px',
                      fontWeight: 600,
                    }}
                  >
                    Talk to Sales
                  </CreateNewBtn>
                )}
              </div>

              <img src={ProjectStarted} />
            </Stack>
          </SectionCnt>
        </Grid>

        <Grid item xs={6}>
          <SectionCnt>
            <Typography className="title">User-based Projects</Typography>
            <Box style={{ minHeight: 100 }}>
              <Typography className="subTitle">
                User based projects are used to assign projects across multiple
                locations.
              </Typography>
              <Typography className="subTitle">
                Using projects allows you to report on a initiative like an
                opening or closing checklist or inspection from one place with
                ease of editing across all of them
              </Typography>
            </Box>

            <img src={UserBasedProject} style={{ width: '100%' }} />
          </SectionCnt>
        </Grid>

        <Grid item xs={6}>
          <SectionCnt>
            <Typography className="title">Asset-based Projects</Typography>
            <Box style={{ minHeight: 100 }}>
              <Typography className="subTitle">
                Asset-based projects are used for bulk scheduling, checks,
                inspections or PM’s across many assets at one time.
              </Typography>
              <Typography className="subTitle">
                Best used for preventative maintenance, yearly asset inspections
                and more.
              </Typography>
            </Box>
            <img src={AssetBasedProject} style={{ width: '100%' }} />
          </SectionCnt>
        </Grid>
      </Grid>
    </MainCnt>
  );
};

export default ProjectsEmptyState;
