import { MainCnt, Value } from './styled';

import MembersTableActions from './renderers/actions/actions';
import UserAvatar from 'components/Avatar';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { Stack } from '@mui/material';
import RoleRenderer from './renderers/role';

export const rowHeight = 52;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};

// Columns listing

export const columns: any = (props) => {
  return [
    {
      field: 'Member.fullName',
      headerName: 'Name',
      colId: 'name',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      flex: 2,
      cellRenderer: cellRenderer.name,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: 'Member.RoleId',
      headerName: 'Role',
      colId: 'role',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      flex: 1,
      cellRenderer: cellRenderer.role,
    },
    {
      field: 'status',
      headerName: 'Status',
      colId: 'status',
      flex: 1,
      suppressMovable: true,
      suppressMenu: true,
      valueGetter: valueGetters.status,
      sortable: true,
      cellRenderer: (params) =>
        cellRenderer.status({
          ...params,
          ...props,
        }),
    },
    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      width: 140,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: 'disableRowClick',
      cellRenderer: (params) => cellRenderer.actionColumn(params, props),
    },
  ];
};

// Cell Renderer
export const cellRenderer = {
  name: (params) => {
    if (!params?.data) return;
    const { Member, MemberId } = params.data;
    return (
      <MainCnt>
        <Stack direction={'row'} gap={'6px'} alignItems={'center'}>
          {Member?.type === 'team' ? (
            <TeamAvatar teamId={MemberId} width={28} height={28} />
          ) : (
            <UserAvatar width={28} height={28} userId={MemberId} />
          )}
          <Value>{Member?.fullName || Member?.name}</Value>
        </Stack>
      </MainCnt>
    );
  },
  role: (params) => {
    if (!params?.data) return;
    const { Member } = params.data;
    return (
      <MainCnt>
        {Member && Member?.RoleId ? <RoleRenderer member={Member} /> : '-'}
      </MainCnt>
    );
  },
  status: (params) => {
    if (!params?.data) return;
    const { status } = params.data;
    const currentStatus = status?.currentStatus ?? 'offline';
    return (
      <MainCnt>
        <Value
          sx={{
            color: params.userStatusColors?.[currentStatus]?.color,
          }}
        >
          {params.userStatusColors?.[currentStatus]?.label}
        </Value>
      </MainCnt>
    );
  },
  actionColumn: (params, props) => {
    if (!params?.data) return;
    return (
      <MembersTableActions
        disabled={props.disableRemove}
        onClick={props.handleClickRemove}
        memberId={params.data.MemberId}
      />
    );
  },
};

// Value Getters
export const valueGetters = {
  name: (params) => {
    const { name } = params.data;
    return name || '-';
  },
  status: (params) => {
    const { status } = params.data;
    return status;
  },
};
