import { MainCnt } from 'pages/documents/listing/listView/list.style';
import FolderName from 'pages/documents/listing/listView/renderers/folderName/folderName';
import FileName from 'pages/documents/listing/listView/renderers/fileName/fileName';

function Title(props) {
  const { data } = props;

  return (
    <MainCnt>
      {!data?.fileType ? (
        <FolderName folder={data} />
      ) : (
        <FileName file={data} />
      )}
    </MainCnt>
  );
}

export default Title;
