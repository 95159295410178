import {
  TimelineDate,
  WorkOrderHistoryCnt,
  WorkOrderHistoryTile,
  WorkOrderTitle,
  TaskCategoryChip,
  AssigneeCnt,
  TaskStatusChip,
} from 'pages/Assets/AssetDetail/AssetHistory/assetHistory.style';
import UserAvatar from 'components/Avatar';
import xeniaApi from 'api/index';
import useAssetDetail from 'pages/Assets/AssetDetail/useAssetDetail';
import { useDispatch, useSelector } from 'react-redux';
import { assetTaskHistorySelector } from 'store/selectors/assets';
import { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';
import CustomToolTip from 'components/Tooltip';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import ChildItemIcon from 'components/Icons/childItemIcon';
import { setAssetTaskHistory } from 'store/actions/assetActions';
import { setDialog } from 'store/actions/dialogActions';
import AssetsWorkOrderEmptyState from 'pages/Assets/AssetDetail/AssetHistory/EmptyState';
import { useUserConfig } from 'controller/useUserConfig';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import { CircularProgress, Stack } from '@mui/material';
import TaskAndWorkOrderTable from 'components/DataTables/TaskAndWorkOrderTable';
import SearchAndToggleHeader from '../common/SearchAndToggleHeader';
import BulkAction from 'pages/task/TaskListView/BulkAction';
import { useTaskUtils } from 'utils/CustomHooks/useTaskUtils';

function AssetHistory(props) {
  const { loadTabsData, setLoadTabsData } = props;
  const { isMobileDeviceSm } = useResponsive();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const { transformTasksList } = useTaskUtils();
  const [selectedView, setSelectedView] = useState<ViewType | null>(null);
  const [selectedAssetTasks, setSelectedAssetTasks] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [configKey, setConfigKey] = useState<any>(null);
  const [tableConfig, setTableConfig] = useState<any>(null);
  const { selectedAsset } = useAssetDetail();

  const dispatch = useDispatch();
  const taskHistory = useSelector(assetTaskHistorySelector);
  const [searchText, setSearchText] = useState('');

  const fetchTableConfig = async () => {
    const config = await getUserConfig(`assetsHistoryTableConfig:${userId}`);
    if (config) {
      const colConfig = config;
      setTableConfig(colConfig);
    }
  };

  const updateColumns = async (columnState: any[]) => {
    const previousTableConfig = tableConfig || [];
    setTableConfig(columnState);
    const updatedConfig = await editUserConfig(
      `assetsHistoryTableConfig:${userId}`,
      columnState,
    );
    if (!updatedConfig) {
      setTableConfig(previousTableConfig);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchTableConfig();
    }
  }, [userId]);

  useEffect(() => {
    if (loadTabsData?.history) {
      fetchAssetsTaskHistory();
    } else {
      setLoadTabsData({
        ...loadTabsData,
        history: true,
      });
    }
  }, [selectedAsset]);

  useEffect(() => {
    if (isMobileDeviceSm) {
      setSelectedView('grid');
    }
  }, [isMobileDeviceSm]);

  useEffect(() => {
    if (userId) {
      setConfigKey(`assetsTaskHistoryViewType::${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (configKey) {
      setAssetsViewType();
    }
  }, [configKey]);

  const setAssetsViewType = async () => {
    const userConfig = await getUserConfig(configKey);
    if (isMobileDeviceSm) {
      setSelectedView('grid');
      return;
    }
    if (userConfig?.assetsTaskHistoryViewType) {
      setSelectedView(userConfig.assetsTaskHistoryViewType);
    } else {
      setSelectedView('list');
    }
  };

  const setToggleStateConfig = async (value: ViewType) => {
    const prevView = selectedView;
    setSelectedView(value);
    const config = await editUserConfig(configKey, {
      assetsTaskHistoryViewType: value,
    });
    if (!config) {
      setSelectedView(prevView);
    }
  };

  const fetchAssetsTaskHistory = async (showLoader = true) => {
    showLoader && setFetching(true);
    const taskHistoryList = await xeniaApi.getAssetTaskHistory(
      selectedAsset?.id,
    );
    dispatch(setAssetTaskHistory(taskHistoryList));
    setFetching(false);
  };

  const transformedTasksHistory = useMemo(() => {
    if (taskHistory) {
      return transformTasksList(taskHistory);
    }
    return null;
  }, [taskHistory]);

  const handleSearchInput = (value) => {
    setSearchText(value);
  };
  const filteredData = useMemo(() => {
    return searchText
      ? transformedTasksHistory?.filter((obj) =>
          obj?.title?.includes(searchText),
        )
      : transformedTasksHistory;
  }, [searchText, transformedTasksHistory]);

  const formatDate = (item) =>
    moment(item.createdAt).format('ddd, MMM DD, YYYY');

  const taskHistoryGroupedData: any = useMemo(() => {
    return filteredData && filteredData?.length > 0
      ? groupBy(filteredData, formatDate)
      : {};
  }, [searchText, filteredData]);

  const groupedDataKeys = Object.keys(taskHistoryGroupedData);

  const handleTaskClick = (taskId: string) => {
    const task = taskHistory.find((t) => t.id === taskId);
    // If on mobile app then send a message to RN WebView //
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          navigateTo: 'TaskDetail',
          data: task,
        }),
      );
      return;
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: fetchAssetsTaskHistory,
          taskId: task.id,
        },
      }),
    );
  };

  const onBulkSelection = (selected) => {
    const filtered = selected.filter(
      (node) => !node.group && !node.data.fullWidth,
    );
    setSelectedAssetTasks(filtered);
  };

  const clearSelection = () => {
    globalThis.taskAndWorkOrdersTable.api.deselectAll();
    setSelectedAssetTasks([]);
    fetchAssetsTaskHistory(false);
  };

  return (
    <>
      {selectedAssetTasks?.length > 0 && (
        <BulkAction
          selected={selectedAssetTasks}
          onClearSelection={clearSelection}
          isArchiveFilter={false}
        />
      )}
      {fetching && (
        <Stack height="calc(100vh - 223px)" alignItems={'center'} pt="100px">
          <CircularProgress />
        </Stack>
      )}

      {transformedTasksHistory &&
        transformedTasksHistory?.length === 0 &&
        !fetching && <AssetsWorkOrderEmptyState />}

      {transformedTasksHistory &&
        transformedTasksHistory?.length > 0 &&
        !fetching &&
        selectedView && (
          <WorkOrderHistoryCnt>
            <SearchAndToggleHeader
              selectedView={selectedView}
              setSelectedView={setToggleStateConfig}
              handleSearchInput={handleSearchInput}
            />
            <Stack
              sx={{
                height: 'calc(100vh - 310px)',
                overflow: 'auto',
              }}
            >
              {selectedView === 'list' ? (
                <TaskAndWorkOrderTable
                  onRowClick={handleTaskClick}
                  rowData={filteredData}
                  onTaskActionSuccess={() => fetchAssetsTaskHistory(false)}
                  columnState={tableConfig}
                  updateColumnStateCb={updateColumns}
                  onBulkSelection={onBulkSelection}
                  columnConfig={{
                    asset: false,
                    recurringByEvery: false,
                    dueTime: false,
                    timeSpent: false,
                    startTime: false,
                    CompletedBy: false,
                    description: false,
                  }}
                />
              ) : (
                <Stack>
                  {groupedDataKeys?.map((key) => {
                    const currentTaskHistory = taskHistoryGroupedData[key];
                    return (
                      <>
                        <TimelineDate>{key}</TimelineDate>
                        {currentTaskHistory.map((t) => {
                          return (
                            <WorkOrderHistoryTile
                              key={t.id}
                              onClick={() => handleTaskClick(t.id)}
                            >
                              <div className={'tileLeft'}>
                                <WorkOrderTitle className={'workOrderTitle'}>
                                  {t.title}
                                </WorkOrderTitle>
                                <div className={'tileLeftBottomCnt'}>
                                  {t?.ServiceId ? (
                                    <TaskCategoryChip
                                      color={t?.categoryColor?.textColor}
                                      background={t?.categoryColor?.color}
                                    >
                                      {t?.categoryDetails?.type}
                                    </TaskCategoryChip>
                                  ) : null}
                                  <span className={'assetName'}>
                                    {selectedAsset?.ParentId && (
                                      <ChildItemIcon className="subAssetIcon" />
                                    )}
                                    {selectedAsset?.name}
                                  </span>
                                </div>
                              </div>
                              <div className={'tileRight'}>
                                {t?.Assigned ? (
                                  <AssigneeCnt>
                                    <CustomToolTip
                                      title={`${t?.Assigned?.firstName} ${t?.Assigned?.lastName}`}
                                      placement={'top'}
                                    >
                                      <span>
                                        <UserAvatar
                                          userId={t?.Assigned?.id}
                                          width={28}
                                          height={28}
                                        />
                                      </span>
                                    </CustomToolTip>
                                  </AssigneeCnt>
                                ) : null}
                                {t?.Team ? (
                                  <AssigneeCnt>
                                    <label>Assignee: </label>
                                    <CustomToolTip
                                      title={`Team: ${t?.Team?.name}`}
                                      placement={'top'}
                                    >
                                      <span>
                                        <TeamAvatar
                                          width={28}
                                          height={28}
                                          teamId={t?.Team?.id}
                                        />
                                      </span>
                                    </CustomToolTip>
                                  </AssigneeCnt>
                                ) : null}
                                <TaskStatusChip
                                  color={t?.statusDetails?.color}
                                  background={t?.statusDetails?.backgroundColor}
                                >
                                  {t.taskStatus}
                                </TaskStatusChip>
                              </div>
                            </WorkOrderHistoryTile>
                          );
                        })}
                      </>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </WorkOrderHistoryCnt>
        )}
    </>
  );
}

export default AssetHistory;
