import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomButton from 'components/Button/CustomButton';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { Stack } from '@mui/material';
import { ToggleContainer } from './style';
import ListGridToggle from 'components/ListGridToggle';
import TeamsSearchInput from '../Search/search';

function TeamsViewHeader(props) {
  const { PAYWALL_LIMITS } = useCustomPermission();
  const { toggleDrawer, selectedView, setSelectedView, onSearch } = props;

  return (
    <Stack
      p="16px 0px"
      justifyContent="space-between"
      direction="row"
      alignItems="center"
    >
      <TeamsSearchInput onSearch={onSearch} />
      <Stack justifyContent="end" alignItems="center" direction="row">
        <ToggleContainer>
          <ListGridToggle
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
        </ToggleContainer>

        <VerifyFeatureAccess paywallUsageId={PAYWALL_LIMITS.TEAMS_WALL}>
          <CustomButton
            variant="contained"
            startIcon={<AddOutlinedIcon />}
            onClick={toggleDrawer}
            sx={{ width: '145px', borderRadius: '8px' }}
          >
            Create Team
          </CustomButton>
        </VerifyFeatureAccess>
      </Stack>
    </Stack>
  );
}

export default TeamsViewHeader;
