import styled from '@mui/system/styled';
import heic2any from 'heic2any';

export const ContentCnt = styled('div')({
  width: 1000,
  height: '100%',
  overflow: 'hidden',
  '& label': {
    padding: 0,
    margin: 0,
    '& .content': {
      fontSize: 13,
    },
  },
  '& .contentInner': {
    height: '100%',
    display: 'flex',
    '& >div': {
      width: '50%',
      flex: 1,
      overflow: 'auto',
    },
  },
});

export const Title = styled('div')({
  fontSize: 20,
  color: 'black',
  padding: '15px 0px 15px 30px',
  fontWeight: 600,
  borderBottom: '1px solid lightgray',
  marginBottom: 10,
});
