import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { getParentSchedules } from 'api/schedulesApi';
import ProjectsChildsTableView from './Table';
import SchedulesTableHeader from './Table/header';
import { LoaderCnt, MainContainer } from './style';
import { getScheduleAnalytics } from 'api/projectsApi';
import Stats from './Stats';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { PROJECT_TYPES } from 'utils/globalVariable';
import { cloneDeep } from 'lodash';
import { getAssetsState } from 'store/selectors/assets';
import { useSelector } from 'react-redux';

const ProjectSchedules = (props) => {
  const { project, updateProjectCallback, getProjectDetails } = props;
  const [projectSchedules, setProjectSchedules] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [scheduleStats, setScheduleStats] = useState([]);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const assetsList = useSelector(getAssetsState);

  const getProjectSchedules = async (load = true) => {
    if (load) {
      setLoading(true);
    }
    const projectSchedules = await getParentSchedules({
      advanceFilters: {
        condition: 'AND',
        filters: [
          {
            comparator: 'is',
            filterName: 'projects',
            value: [project?.id],
          },
        ],
      },
      offset: 0,
      limit: 9999999999,
      queryParams: {
        advancelocation: false,
      },
    });
    if (load) {
      setLoading(false);
    }

    let schedulesList = cloneDeep(projectSchedules?.rows ?? []);

    if (project?.type === PROJECT_TYPES.assetBasedProject) {
      schedulesList = schedulesList?.map((schedule) => {
        if (schedule?.AssetId) {
          const asset = assetsList?.find(
            (item) => item?.id === schedule?.AssetId,
          );

          return {
            ...schedule,
            ...(asset?.Location && {
              Location: asset?.Location,
            }),
          };
        }
        return schedule;
      });
    }

    setProjectSchedules(schedulesList);
  };

  const getSchedulesStats = async () => {
    const response = await getScheduleAnalytics(project?.id, {
      advancelocation: false,
    });
    setScheduleStats(response);
  };

  useEffect(() => {
    getProjectSchedules();
    getSchedulesStats();
  }, []);

  const removeScheduleHandler = (scheduleId) => {
    const updatedList = projectSchedules?.filter(
      (item) => item?.id !== scheduleId,
    );

    if (updatedList?.length === 0) {
      navigateWithWorkspaceUrl('/projects');
    } else {
      setProjectSchedules(updatedList);
      getProjectDetails?.(false);
    }
  };

  const pauseScheduleHandler = (scheduleId) => {
    const updatedList = projectSchedules?.map((item) => {
      if (item?.id === scheduleId) {
        return {
          ...item,
          isPaused: !item?.isPaused,
        };
      }
      return item;
    });

    setProjectSchedules(updatedList);
    getProjectDetails?.(false);
  };

  const toggleUpdateHandler = (isPaused) => {
    const updatedSchedules = projectSchedules?.map((item) => {
      return {
        ...item,
        isPaused,
      };
    });

    setProjectSchedules(updatedSchedules);
    updateProjectCallback({ ...project, isPaused });
    getProjectDetails?.(false);
    getSchedulesStats();
  };

  return (
    <MainContainer>
      {loading ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : (
        <Box>
          <Stats scheduleStats={scheduleStats} />
          <SchedulesTableHeader
            project={project}
            projectSchedules={projectSchedules}
            toggleUpdateHandlerCallback={toggleUpdateHandler}
            getProjectSchedules={getProjectSchedules}
            getSchedulesStats={getSchedulesStats}
            getProjectDetails={getProjectDetails}
          />
          <ProjectsChildsTableView
            height="calc(100vh - 360px)"
            project={project}
            rowData={projectSchedules}
            removeScheduleHandler={removeScheduleHandler}
            pauseScheduleHandler={pauseScheduleHandler}
            onRowClick={() => console.log('row clicked')}
          />
        </Box>
      )}
    </MainContainer>
  );
};

export default ProjectSchedules;
