// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  height: '100%',
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  height: 30,
  padding: '4px 10px',
}));

export const SubmissionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: 4,
  borderRadius: 6,
  alignItems: 'center',
  '&:hover': {
    background: 'rgba(235, 234, 255, 1)',
    '& > button': {
      display: 'flex',
    },
  },
}));

export const AvatarWrapper = styled(Box)(({ theme }) => ({
  marginRight: 8,
}));

export const Dot = styled(Box)(({ theme }) => ({
  width: 4,
  height: 4,
  background: 'rgba(33, 33, 33, 1)',
  borderRadius: 4,
  margin: '0 6px',
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '500',
  letterSpacing: '0.17px',
  lineHeight: '17.16px',
  color: 'rgba(33, 33, 33, 1)',
}));

export const ReportButton = styled(Button)(({ theme }) => ({
  height: 25,
  padding: '4px 12px',
  marginLeft: 'auto',
  fontSize: '12px',
  fontWeight: '400',
  letterSpacing: '0.17px',
  lineHeight: '17.16px',
}));

export const AnonymousAvatar = styled(Box)(({ theme }) => ({
  height: 28,
  width: 28,
  borderRadius: 28,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
