// React
import React, { useState, useMemo, useRef, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import ScheduleGridItem from 'pages/Schedules/common/ScheduleGridItem/ScheduleGridItem';
import InfiniteVirtualList from 'components/InfiniteVirtualList/InfiniteVirtualList';

// Icons
import ErrorIcon from '@mui/icons-material/Error';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Styled
import { Parent, Container, Date } from './styled';

// Utilities
import { groupBy } from 'lodash';
import { isEqual, omit } from 'lodash';

// Hooks
import { useWindowResize } from 'controller/useWindowResize';
import useDateTime from 'utils/CustomHooks/useDateTime';

interface GridPropTypes {
  tasks: any[];
  overDueTasks: any[];
  totalOverDueTasks: number;
  isNextPageLoading: boolean;
  advanceFilters: any;
  handleLoadNextPage: (offset: number) => Promise<void>;
  fetchData: any;
  groupByDate?: boolean;
}

function Grid({
  tasks,
  overDueTasks,
  totalOverDueTasks,
  isNextPageLoading,
  advanceFilters,
  handleLoadNextPage,
  fetchData,
  groupByDate = true,
}: GridPropTypes) {
  const dispatch = useDispatch();

  const parentRef = useRef<HTMLDivElement | null>(null);

  const size = useWindowResize();
  const { getUtcToTz } = useDateTime();

  const [parentHeight, setParentHeight] = useState<any>(null);

  useEffect(() => {
    if (parentRef?.current?.clientHeight) {
      setParentHeight(parentRef.current.clientHeight);
    }
  }, [parentRef?.current?.clientHeight, size]);

  const [expanded, setExpanded] = useState<boolean>(true);
  const formatDate = (item) => getUtcToTz(item.dueDate, 'ddd, MMM DD, YYYY');

  const handleClickScheduleItem = (task) => {
    // If on mobile app then send a message to RN WebView //
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          navigateTo: 'TaskDetail',
          data: task,
        }),
      );
      return;
    }

    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: fetchData,
          taskId: task.id,
        },
      }),
    );
  };

  const taskGroupedData: any = useMemo(() => {
    return tasks && tasks?.length > 0 ? groupBy(tasks, formatDate) : {};
  }, [tasks, totalOverDueTasks]);

  const groupedDataKeys = Object.keys(taskGroupedData);

  const tasksWithDatesRows = useMemo(() => {
    const groupedRows = groupedDataKeys?.map((key) => {
      const date = <Date>{key}</Date>;
      const currentTasks = taskGroupedData[key].map((task) => (
        <ScheduleGridItem
          onClick={handleClickScheduleItem}
          hideLeftBar
          task={task}
        />
      ));
      return [date, ...currentTasks];
    });
    const finalRows: any[] = [];
    groupedRows.forEach((row) => finalRows.push(...row));
    return finalRows.length ? finalRows : [];
  }, [tasks, totalOverDueTasks, groupByDate]);

  const tasksWithoutDatesRows = useMemo(() => {
    return tasks?.map((task) => (
      <ScheduleGridItem
        onClick={handleClickScheduleItem}
        hideLeftBar
        task={task}
      />
    ));
  }, [tasks, totalOverDueTasks, groupByDate]);

  const overDueDropdownJsx = useMemo(
    () =>
      overDueTasks?.length ? (
        <Container>
          <div
            className={'overdueGroupTitleCnt'}
            onClick={() => setExpanded((prevState) => !prevState)}
          >
            <div className={'overdueGroupTitleLeftCnt'}>
              <ErrorIcon className={'warningIcon'} />{' '}
              <span className={'overdueScheduleTitle'}>
                {overDueTasks?.length} schedule(s) overdue
              </span>{' '}
            </div>
            {expanded ? (
              <ArrowUpIcon className={'arrowIcon'} />
            ) : (
              <ArrowDownIcon className={'arrowIcon'} />
            )}
          </div>
          {!!(expanded && overDueTasks?.length) && (
            <div className="overdueTaskCnt">
              {overDueTasks.map((task) => (
                <ScheduleGridItem
                  onClick={handleClickScheduleItem}
                  hideLeftBar
                  key={task.id}
                  task={task}
                />
              ))}
            </div>
          )}
        </Container>
      ) : null,
    [overDueTasks, expanded],
  );

  const finalRows: any[] = useMemo(() => {
    if (!groupByDate) {
      return tasksWithoutDatesRows;
    }

    let rows: any = [];
    if (overDueDropdownJsx && !advanceFilters) {
      rows.push(overDueDropdownJsx);
    }
    rows = [...rows, ...tasksWithDatesRows];
    return rows;
  }, [overDueDropdownJsx, tasksWithDatesRows, groupByDate]);

  return (
    <Parent ref={parentRef}>
      <InfiniteVirtualList
        handleLoadNextPage={handleLoadNextPage}
        rows={finalRows}
        height={parentHeight ?? 500}
        totalCount={totalOverDueTasks}
        isNextPageLoading={isNextPageLoading}
        overrideRowCount={tasks?.length}
      />
    </Parent>
  );
}

const arePropsEqual = (prevProps: GridPropTypes, nextProps: GridPropTypes) => {
  return isEqual(
    omit(prevProps, ['handleLoadNextPage']),
    omit(nextProps, ['handleLoadNextPage']),
  );
};

export default React.memo(Grid, arePropsEqual);
