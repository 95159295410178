import { Container, Value } from './style';
import { Assignee, NextDue, ScheduleActions } from './renderers';

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};

export const cellRenderer = {
  assigned: (params) => {
    if (!params?.data) return;
    const { TaskAssignees } = params.data;
    return <Assignee assignees={TaskAssignees} />;
  },
  asset: (params) => {
    if (!params?.data) return;
    const { Asset } = params.data;
    const assetName = Asset?.name || '-';
    return (
      <Container>
        <Value>{assetName}</Value>
      </Container>
    );
  },
  location: (params) => {
    if (!params?.data) return;
    const { Location } = params.data;
    const locationName = Location?.name || '-';
    return (
      <Container>
        <Value>{locationName}</Value>
      </Container>
    );
  },

  nextDue: (params) => {
    if (!params?.data) return;
    const { NextDue: NextTask } = params.data;
    return (
      <Container>
        <NextDue dueDate={NextTask?.dueDate} />
      </Container>
    );
  },
  action: (params, props) => {
    if (!params?.data) return;
    const { removeScheduleHandler, pauseScheduleHandler, project } = props;
    return (
      <ScheduleActions
        data={params.data}
        removeScheduleHandler={removeScheduleHandler}
        pauseScheduleHandler={pauseScheduleHandler}
        project={project}
      />
    );
  },
};

export const valueGetters = {
  action: (params) => {
    if (!params?.data) return;
    const { isPaused } = params.data;
    return isPaused;
  },
  location: (params) => {
    if (!params?.data) return;
    const { location } = params.data;
    return location?.name;
  },
  asset: (params) => {
    if (!params?.data) return;
    const { Asset } = params.data;
    return Asset?.name;
  },
  assigned: (params) => {
    if (!params?.data) return;
    const { TaskAssignees } = params.data;
    const names =
      TaskAssignees?.map((item) => {
        if (item?.Assigned) {
          return item?.Assigned?.fullName;
        }
        if (item?.Team) {
          return item?.Team?.name;
        }
        if (item?.Location) {
          return item?.Location?.name;
        }
        return '';
      }) ?? [];

    return names;
  },
};

export const columns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'assignees',
      headerName: 'Assigned To',
      colId: 'assignees',
      sortable: true,
      valueGetter: valueGetters.assigned,
      cellRenderer: cellRenderer.assigned,
      resizable: true,
    },
    {
      field: 'asset',
      headerName: 'Asset',
      colId: 'asset',
      sortable: true,
      valueGetter: valueGetters.asset,
      cellRenderer: cellRenderer.asset,
    },
    {
      field: 'location',
      headerName: 'Location',
      colId: 'location',
      sortable: true,
      cellRenderer: cellRenderer.location,
    },
    {
      field: 'nextDue',
      headerName: 'Next Due',
      colId: 'nextDue',
      sortable: true,
      cellRenderer: cellRenderer.nextDue,
    },
    {
      field: 'actions',
      headerName: '',
      colId: 'actions',
      sortable: false,
      pinned: 'right',
      valueGetter: valueGetters.action,
      cellRenderer: (params) => cellRenderer.action(params, props),
    },
  ];
  return tableCells.filter((cell) => columnConfig[cell.field]);
};
