import {
  EmptyStateCnt,
  EmptyStateText,
} from './taskListWidgetEmptyState.style';
import React from 'react';
import BarChartIcon from 'components/Icons/barChartIcon';

const TasksByAssigneeEmptyState = (props) => {
  return (
    <EmptyStateCnt>
      <BarChartIcon />
      <EmptyStateText>No record available</EmptyStateText>
    </EmptyStateCnt>
  );
};

export default TasksByAssigneeEmptyState;
