import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const ResourcesCnt = styled('div')({
  marginBottom: 35,
  '& .label': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28.8px',
    letterSpacing: '0.15px',
    marginBottom: 10,
    color: colors.black,
  },
  '& .cnt': {
    '& .box': {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      cursor: 'pointer',
      maxWidth: 250,
      border: '1px solid transparent',
      borderRadius: 7,
      padding: '5px 10px',
      '&:hover': {
        border: '1px solid #6868FE',
      },
      '& p': {
        fontWeight: 600,
        fontSize: 15,
        lineHeight: '20.49px',
        letterSpacing: '0.1px',
        color: '#212121',
      },
      '& .iconCnt': {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        gap: 10,
        width: 48,
        height: 48,
        border: '1px solid #CCCBFF',
        borderRadius: 14,
      },
    },
  },
});
