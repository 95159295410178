// React
import { useState, useEffect, useContext, useCallback } from 'react';

// Custom components
import Submissions from './Submissions';

// Types
import { SubmissionContext, SubmissionReportType } from './context';
import { DashboardContext } from 'pages/TemplateDashboard/context';

// Utils
import xeniaApi from 'api/index';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const SubmissionsProvider = () => {
  const dashCtx = useContext(DashboardContext);
  const template = dashCtx?.template;

  // main source of truth
  const [submissionsLog, setSubmissionsLog] = useState<
    SubmissionReportType[] | undefined
  >(undefined);

  // current submission showing on the right side
  const [activeSubmission, setActiveSubmission] = useState<
    SubmissionReportType | undefined
  >(undefined);

  // filtered submission list that shows on left side panel
  const [submissionsLogFiltered, setSubmissionsLogFiltered] = useState<
    SubmissionReportType[] | undefined
  >(undefined);

  // selected submissions with bulk checkmark
  const [selected, setSelected] = useState<SubmissionReportType[]>([]);
  const [bulkGroupSelected, setBulkGroupSelected] = useState<string[]>([]);

  // advanced filters state
  const [advancedFilters, setAdvancedFilters] = useState<any[]>([]);
  const [includePublicSubmissions, setIncludePublicSubmissions] =
    useState(false);

  // initial data load empty state
  const [empty, setEmpty] = useState(false);
  // empty flag for filtered data
  const [emptyResultFilters, setEmptyResultFilters] = useState(false);

  const { workspaceId } = useWorkspaceHook();

  // fetch data initially and load logs
  useEffect(() => {
    fetchInitialData();
  }, []);

  // listen to advanced filter state change and call api
  useEffect(() => {
    if (submissionsLog) {
      handleFilterSubmissionReports();
    }
  }, [advancedFilters]);

  useEffect(() => {
    if (dashCtx?.shouldUpdate && dashCtx?.shouldUpdate > 0) {
      setSelected([]);
      setBulkGroupSelected([]);
      if (
        advancedFilters.length !== 0 &&
        validateAdvancedFilter(advancedFilters)
      ) {
        handleFilterSubmissionReports();
      } else {
        if (submissionsLog) {
          fetchInitialData(true);
        }
      }
    }
  }, [dashCtx?.shouldUpdate]);

  // fetch submissions with / without filters
  const fetchSubmissions = async (filters: any = null) => {
    const submissions = await xeniaApi.getChecklistLogs({
      checklistId: template?.id ?? '',
      hotelId: workspaceId,
      // filtered: true,
      detailed: false,
      postData: filters
        ? {
            advanceFilters: filters,
          }
        : {},
      trimmed: true,
    } as any);
    return submissions?.data;
  };

  const fetchInitialData = async (preserveState = false) => {
    const submissions = await fetchSubmissions();
    if (!submissions.length) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }

    setStateAfterFetch(submissions, preserveState);
  };

  // sets all the states for submissions
  const setStateAfterFetch = (submissions, preserveState = false) => {
    setSubmissionsLog(submissions);
    setSubmissionsLogFiltered(submissions);
    if (submissions?.length === 0) {
      setEmptyResultFilters(true);
    } else {
      setEmptyResultFilters(false);
    }
    const firstReport = submissions?.length ? submissions[0] : undefined;
    if (!preserveState) {
      setActiveSubmission(firstReport);
    } else {
      const lastActive = submissions.find((s) => s.id === activeSubmission?.id);
      if (lastActive) {
        setActiveSubmission(lastActive);
      } else {
        setActiveSubmission(firstReport);
      }
    }
  };

  const reconcileBulkSelectionWithSelected = (
    selected: SubmissionReportType[],
  ) => {
    const bulkSelection = [...bulkGroupSelected];
    const newBulkSelection: string[] = [];
    for (let index = 0; index < bulkSelection.length; index++) {
      const element = bulkSelection[index];
      // if selection found with current status
      if (selected.some((log) => log.status === element)) {
        newBulkSelection.push(element);
      }
    }
    setBulkGroupSelected(newBulkSelection);
  };

  const handleClickSubmission = useCallback(
    (log: SubmissionReportType) => {
      let newSelected: SubmissionReportType[] = [];
      if (!selected.find((s) => s.id === log.id)) {
        newSelected = [...selected, log];
      } else {
        newSelected = selected.filter((s) => s.id !== log.id);
      }
      setSelected(newSelected);
      reconcileBulkSelectionWithSelected(newSelected);
    },
    [selected],
  );

  const handleSetActiveSubmission = (submission: SubmissionReportType) => {
    setActiveSubmission(submission);
  };

  const validateAdvancedFilter = (advancedFilters: any[]) => {
    return advancedFilters.every(
      (f) =>
        !!f.filterName && !!f.comparator && !!f.conditional && !!f.value.length,
    );
  };

  const formatAdvancedFiltersForApi = (advancedFilters: any[]) => {
    const formatDateFilters = (filters: any[]) => {
      return filters.map((f) =>
        f.filterName === 'date'
          ? { ...f, value: [f.value[0].startDate, f.value[0].endDate] }
          : f,
      );
    };

    const formatUserFilters = (filters: any[]) => {
      return filters.filter((f) => {
        if (f.filterName === 'user' && f.value.includes('all')) {
          return false;
        }
        return true;
      });
    };

    const condition = advancedFilters?.[0]?.conditional?.toUpperCase();
    return {
      condition,
      filters: formatDateFilters(formatUserFilters(advancedFilters)),
    };
  };

  const handleFilterSubmissionReports = async () => {
    let submissions: any = undefined;

    if (validateAdvancedFilter(advancedFilters)) {
      submissions = await fetchSubmissions(
        formatAdvancedFiltersForApi(advancedFilters),
      );
    } else if (
      !validateAdvancedFilter(advancedFilters) &&
      advancedFilters.length === 1 &&
      !advancedFilters[0].value.length
    ) {
      submissions = await fetchSubmissions();
    }
    if (submissions) {
      setStateAfterFetch(submissions);
      setEmptyResultFilters(false);
    }
    if (submissions?.length === 0) {
      setEmptyResultFilters(true);
    }
  };

  const handleSetAdvancedFilters = (filters: any[]) =>
    setAdvancedFilters(filters);

  const filterSubmissionsByPersonName = (
    personName: string,
    list: SubmissionReportType[],
  ) => {
    const lowercasePersonName = personName.toLowerCase();

    const getUpdaterName = (log: SubmissionReportType) => {
      const updater = log.LastItemUpdater ?? log.Updater;
      return updater
        ? `${updater.firstName} ${updater.lastName}`
        : log.submitterName ?? 'Anonymous';
    };

    return list.filter((s) =>
      getUpdaterName(s).toLowerCase().includes(lowercasePersonName),
    );
  };

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      const filtered = filterSubmissionsByPersonName(
        searchTerm,
        submissionsLog ?? [],
      );
      setSubmissionsLogFiltered(filtered);
      if (filtered.length === 0) {
        setEmptyResultFilters(true);
      } else {
        setEmptyResultFilters(false);
      }
    } else {
      setEmptyResultFilters(false);
      setSubmissionsLogFiltered(submissionsLog);
    }
  };

  const handleArchiveApi = async (logIds: string[]) => {
    await xeniaApi.deleteChecklistLogs({
      checklistId: template?.id,
      hotelId: workspaceId,
      postData: {
        logIds,
      },
    });
  };

  const handleArchiveLogs = async (logs: SubmissionReportType[]) => {
    const logIds = logs.map((l) => l.id);
    await handleArchiveApi(logIds);
    const newFiltered = submissionsLogFiltered?.filter(
      (log) => !logIds.includes(log.id),
    );
    const newLogs = submissionsLog?.filter((log) => !logIds.includes(log.id));
    setSubmissionsLog(newLogs);
    setSubmissionsLogFiltered(newFiltered);
    setActiveSubmission(newFiltered?.length ? newFiltered[0] : undefined);
    setSelected(selected.filter((log) => !logIds.includes(log.id)));

    if (newLogs?.length === 0) {
      setEmpty(true);
    } else if (newFiltered?.length === 0) {
      setEmptyResultFilters(true);
    }

    dashCtx?.setShouldUpdate();
  };

  const bulkCheckGroup = (
    statusName: 'In Progress' | 'Submitted' | 'Incomplete',
  ) => {
    const checked = bulkGroupSelected.includes(statusName);

    if (checked) {
      setBulkGroupSelected(bulkGroupSelected.filter((g) => g !== statusName));
      setSelected(selected.filter((s) => s.status !== statusName));
    } else {
      setBulkGroupSelected([...bulkGroupSelected, statusName]);
      const oldSelectedWithoutThisStatus =
        selected?.filter((s) => s.status !== statusName) ?? [];
      const newLogs =
        submissionsLog?.filter((s) => s.status === statusName) ?? [];
      setSelected([...oldSelectedWithoutThisStatus, ...newLogs]);
    }
  };

  const handleClearSelection = () => {
    setSelected([]);
    setBulkGroupSelected([]);
  };

  const context = {
    empty,
    template,
    submissionsLog: submissionsLogFiltered,
    selected,
    activeSubmission: activeSubmission,
    advancedFilters,
    emptyResultFilters,
    shouldUpdate: dashCtx?.shouldUpdate,
    bulkGroupSelected,
    includePublicSubmissions,
    setSelected,
    handleClickSubmission,
    handleSearch,
    setActiveSubmission: handleSetActiveSubmission,
    handleSetAdvancedFilters,
    handleArchiveLogs,
    bulkCheckGroup,
    handleClearSelection,
    setIncludePublicSubmissions,
  };

  return (
    <SubmissionContext.Provider value={context}>
      <Submissions />
    </SubmissionContext.Provider>
  );
};

export default SubmissionsProvider;
