// React
import { useContext, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';

// Styled components
import {
  HeaderWrapper,
  ActionItemsRow,
  ActionItemWrapper,
  ActionItemIconWrapper,
  ActionItemIcon,
  ActionItemTextWrapper,
  ActionItemHeading,
  ActionItemDescription,
  CloseIconButton,
} from 'pages/documents/mainHeader/styles';

// Utilities
import { getHeaderItems } from 'pages/documents/mainHeader/constants';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import DIALOGS from 'utils/dialogIds';

import CloseIcon from '@mui/icons-material/Close';
import { getItem, setItem } from 'utils/globalFunction';
import { setDialog } from 'store/actions/dialogActions';
import { DocumentsContext } from 'pages/documents/context/context';
import UploadDocument from 'pages/documents/uploadDocument';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useResponsive from 'utils/CustomHooks/useResponsive';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const PageHeader = ({ setShowPageHeader }) => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { updateState } = useContext(DocumentsContext);
  const { createDocument } = useDocumentsFolders();

  const { addFolder, addDocument, state } = useContext(DocumentsContext);
  const { isMobileDeviceSm } = useResponsive();
  const { selectedFolder, isArchive } = state;

  const [activeActionItem, setActiveActionItem] = useState<null | number>(null);
  const uploadDocumentHandler = async (data) => {
    if (selectedFolder) {
      data = {
        ...data,
        FolderId: selectedFolder?.id,
      };
    }

    const response = await createDocument(data);
    if (isArchive) {
      updateState({
        isArchive: false,
      });
      navigateWithWorkspaceUrl(`/documents/my-documents`);
    } else {
      addDocument(response);
    }

    manageLoader(false);

    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
      }),
    );
  };

  const createFolderHandler = (data) => {
    if (isArchive) {
      updateState({
        isArchive: false,
      });
      navigateWithWorkspaceUrl(`/documents/my-documents`);
    } else {
      addFolder(data);
    }
  };

  const actionItems = getHeaderItems([
    () => {
      setActiveActionItem(0);
    },
    async () => {
      const response = await createDocument(
        {
          title: 'New Document',
        },
        false,
      );

      const urlTab = `${window.location.origin}/document-detail/${response?.id}?source=list`;
      window.open(urlTab, '_self');

      setActiveActionItem(1);
    },

    () => {
      dispatch(
        setDialog({
          open: true,
          dialogId: DIALOGS?.CREATE_DOCUMENT_FOLDER_DIALOG,
          data: {
            onSuccessCallback: createFolderHandler,
          },
        }),
      );
      setActiveActionItem(2);
    },
  ]);
  const handleHideShowHeader = () => {
    const headerState = getItem('hideDocumentsHeader');
    const headerStateValueParsed = headerState != 'true';
    setShowPageHeader(!headerStateValueParsed);
    setItem('hideDocumentsHeader', headerStateValueParsed);
  };

  const manageLoader = (loading) => {
    updateState({ loading });
  };

  const ActionDetails = (item, _) => {
    return (
      <>
        <ActionItemIconWrapper bgcolor={item.borderColor}>
          <ActionItemIcon
            svgdimensions={item.icon.styles ?? null}
            bgcolor={item.icon.iconBackgroundColor}
          >
            {<item.icon.component />}
          </ActionItemIcon>
        </ActionItemIconWrapper>

        <ActionItemTextWrapper>
          <ActionItemHeading>{item.heading}</ActionItemHeading>
          {item.descriptionRenderer ?? (
            <ActionItemDescription>{item.description}</ActionItemDescription>
          )}
        </ActionItemTextWrapper>
      </>
    );
  };

  const generateItems = () => {
    return actionItems.map((item, index) => {
      return (
        <ClickAwayListener
          onClickAway={() => {
            if (activeActionItem !== null) setActiveActionItem(null);
          }}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          {index === 0 ? (
            <ActionItemWrapper
              bgcolor={item.bgColor}
              bordercolor={activeActionItem === index && item.borderColor}
              onClick={item.onClick}
              key={index}
            >
              <UploadDocument
                updateHandler={uploadDocumentHandler}
                manageLoader={manageLoader}
              >
                <div style={{ display: 'flex', gap: 10 }}>
                  {ActionDetails(item, index)}
                </div>
              </UploadDocument>
            </ActionItemWrapper>
          ) : (
            <ActionItemWrapper
              bgcolor={item.bgColor}
              bordercolor={activeActionItem === index && item.borderColor}
              onClick={item.onClick}
              key={index}
            >
              {ActionDetails(item, index)}
            </ActionItemWrapper>
          )}
        </ClickAwayListener>
      );
    });
  };

  return (
    <>
      <HeaderWrapper>
        <ActionItemsRow>
          {isMobileDeviceSm ? (
            <Slider {...settings}>{generateItems()}</Slider>
          ) : (
            generateItems()
          )}
        </ActionItemsRow>
        <CloseIconButton onClick={handleHideShowHeader}>
          <CloseIcon />
        </CloseIconButton>
      </HeaderWrapper>
    </>
  );
};

export default PageHeader;
