import { Box, Grid } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { EmptyCheckListStyled } from './EmptyChecklist.styled';

import IntroVideoThumbnail from 'public/assets/img/checklists/intro-video.svg';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import appConstants from 'utils/appConstants';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function EmptyChecklist() {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const openCheckListCreate = () => {
    navigateWithWorkspaceUrl('/checklist/new');
  };

  const openExploreTemplate = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TEMPLATE_LIBRARY,
        open: true,
      }),
    );
  };
  const handleVideoIntroClick = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.PLAY_VIDEO,
        open: true,
        data: {
          videoUrl:
            'https://cdn.xeniaplatform.io/xenia-videos/checklist-empty-state-video-v-8.mp4',
        },
      }),
    );
  };

  return (
    <EmptyCheckListStyled>
      <Grid
        className="container"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box className="infoContainer">
          <p className="title">Streamline Your Operations with Templates</p>
          <p className="description">
            Create inspections, SOPs, checklists, logs, and forms to standardize
            your data collection and processes. Pick from{' '}
            <span className="count">100's</span> of free templates and use our
            template builder to improve work clarity, increase accountability,
            log critical data, standardize processes, and more.
          </p>
          <Box className="buttonsContainer">
            <CustomButton
              variant="contained"
              onClick={() => openExploreTemplate()}
            >
              Explore Templates
            </CustomButton>
            <CustomButton
              variant="outlined"
              onClick={() => openCheckListCreate()}
            >
              Build From Scratch
            </CustomButton>
          </Box>
        </Box>
        <Box className="thumbnailContainer" onClick={handleVideoIntroClick}>
          <img src={IntroVideoThumbnail} />
        </Box>
      </Grid>
    </EmptyCheckListStyled>
  );
}

export default EmptyChecklist;
