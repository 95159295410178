import { createSvgIcon } from '@mui/material';

const PhoneIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M11.3 12.5C9.86667 12.5 8.46956 12.1804 7.10867 11.5413C5.74733 10.9027 4.54178 10.0582 3.492 9.008C2.44178 7.95822 1.59733 6.75267 0.958667 5.39133C0.319556 4.03044 0 2.63333 0 1.2C0 1 0.0666666 0.833333 0.2 0.7C0.333333 0.566667 0.5 0.5 0.7 0.5H3.4C3.55556 0.5 3.69444 0.55 3.81667 0.65C3.93889 0.75 4.01111 0.877778 4.03333 1.03333L4.46667 3.36667C4.48889 3.52222 4.48622 3.66378 4.45867 3.79133C4.43067 3.91933 4.36667 4.03333 4.26667 4.13333L2.65 5.76667C3.11667 6.56667 3.70289 7.31667 4.40867 8.01667C5.114 8.71667 5.88889 9.32222 6.73333 9.83333L8.3 8.26667C8.4 8.16667 8.53067 8.09156 8.692 8.04133C8.85289 7.99156 9.01111 7.97778 9.16667 8L11.4667 8.46667C11.6222 8.5 11.75 8.57489 11.85 8.69133C11.95 8.80822 12 8.94444 12 9.1V11.8C12 12 11.9333 12.1667 11.8 12.3C11.6667 12.4333 11.5 12.5 11.3 12.5ZM2.01667 4.5L3.11667 3.4L2.83333 1.83333H1.35C1.40556 2.28889 1.48333 2.73889 1.58333 3.18333C1.68333 3.62778 1.82778 4.06667 2.01667 4.5ZM10.6667 11.1333V9.66667L9.1 9.35L7.98333 10.4667C8.41667 10.6556 8.85844 10.8056 9.30867 10.9167C9.75844 11.0278 10.2111 11.1 10.6667 11.1333Z"
        fill="black"
        fillOpacity="0.6"
      />
    </>,
    'PhoneIcon',
  );
  return <IconCmp viewBox={'0 0 12 13'} {...props} />;
};
export default PhoneIcon;
