/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import {
  DirectMessagesRenderProps,
  UserManagementHotelAdminModel,
} from 'models/index.m';
import { useProfileChannel } from 'controller/useProfileChannel';
import { LeftMenuChannelActions } from './LeftMenuChannelActions';
import { isMutedChannel } from 'services/getStreamServices';
import { Badge, List, ListItem } from '@mui/material';
import { ListSingleChatCard } from './ListSingleChat.style';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import UserAvatar from 'components/Avatar';
import VolumeImg from 'public/assets/img/volume-x.svg';

export const DirectMessagesRender: FC<DirectMessagesRenderProps> = ({
  singleData,
  handleSetChannel,
  setIsArchiveShow,
  openChannelActionModal,
  selectedChannelId,
}: DirectMessagesRenderProps) => {
  const channel: any = singleData;
  const isMute = (channel && isMutedChannel(channel)) || false;
  const { memberObj }: any = useProfileChannel(channel);
  const userList = useSelector(selectors.getUserAdmin);
  const user: UserManagementHotelAdminModel = userList?.find(
    (u) => u.id === memberObj.userId,
  ) as UserManagementHotelAdminModel;
  return (
    <List style={{ padding: 0 }}>
      <ListItem
        className={`click ${selectedChannelId === channel?.id && 'selected'}`}
        onClick={() => {
          setIsArchiveShow(false);
          handleSetChannel(channel);
        }}
      >
        <ListSingleChatCard>
          <div className="avatarContainer">
            <Badge
              color="error"
              badgeContent={parseInt(channel?.state?.unreadCount)}
            >
              <UserAvatar
                width={32}
                height={32}
                userId={memberObj?.userId}
                firstName={memberObj?.name}
                profilePic={memberObj?.profileImage}
              />
              {/* <AvatarPopover
                avatarProps={{ width: 32, height: 32 }}
                showAvatarOnly
                data={{
                  id: user?.id,
                  emailId: user?.emailId,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  photo: user?.photo,
                  phoneNo: user?.phoneNo,
                  title: user?.title,
                  status: user?.status,
                  UserHotels: user?.UserHotels,
                }}
              /> */}
            </Badge>
          </div>
          <div className="nameContainer">
            <div
              className="leftMenuChannelHeader nameBox"
              style={{ marginRight: '-22px' }}
            >
              <h5 className="elEllipses">{memberObj.name}</h5>
              {isMute && (
                <span className="mutedChannel">
                  <img src={VolumeImg} />
                </span>
              )}
              {/* <ChannelUnreadMuteStatus channel={channel} /> */}
            </div>
          </div>
        </ListSingleChatCard>
        <LeftMenuChannelActions
          channel={channel}
          openChannelActionModal={openChannelActionModal}
        />
      </ListItem>
    </List>
  );
};
