import { FC } from 'react';

import { Stack, Typography, Checkbox } from '@mui/material';

import OperationsIcon from 'components/Icons/goalIcons/OperationsIcon';
import EnvironmentIcon from 'components/Icons/goalIcons/EnvironmentIcon';
import QualityIcon from 'components/Icons/goalIcons/QualityIcon';
import MaintenanceIcon from 'components/Icons/goalIcons/MaintenanceIcon';
import MultiSiteIcon from 'components/Icons/goalIcons/MultiSiteIcon';
import { GOALS } from 'utils/globalVariable';

type Goal = {
  label: string;
  subTitle: string;
  value: string;
  id: string;
};

interface IProps {
  goal: Goal;
  checked: boolean;
  onCheck: (goal: Goal) => void;
}

const Goal: FC<IProps> = (props) => {
  const { goal, checked, onCheck } = props;

  const getGoalIcon = (label: string) => {
    const iconSx = {
      fontSize: '48px',
    };
    switch (label) {
      case GOALS.operations:
        return <OperationsIcon sx={iconSx} />;
      case GOALS.healthAndSafety:
        return <EnvironmentIcon sx={iconSx} />;
      case GOALS.qualityAssurance:
        return <QualityIcon sx={iconSx} />;
      case GOALS.maintenance:
        return <MaintenanceIcon sx={iconSx} />;
      case GOALS.unitOperations:
        return <MultiSiteIcon sx={iconSx} />;
      default:
        return <OperationsIcon sx={iconSx} />;
    }
  };

  const Icon = getGoalIcon(goal.label);
  return (
    <Stack
      bgcolor={checked ? '#EBEAFF' : '#FFF'}
      direction="row"
      p="16px 20px 16px 16px"
      border={checked ? `2px solid #6868FE` : `1px solid #E0E0E0`}
      borderRadius={'8px'}
      gap="20px"
      alignItems={'center'}
      width="100%"
      onClick={() => onCheck(goal)}
      sx={{ cursor: 'pointer' }}
    >
      {Icon}
      <Stack gap="4px">
        <Typography fontWeight={'700'} fontSize={'18px'}>
          {goal.label}
        </Typography>
        <Typography fontWeight={'700'} fontSize={'15px'} color="#424242">
          {goal.subTitle}
        </Typography>
      </Stack>
      <Checkbox checked={checked} sx={{ marginLeft: 'auto' }} />
    </Stack>
  );
};

export default Goal;
