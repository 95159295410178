import { Grid, Stack } from '@mui/material';
import { IDashboardItem, IDashboardList } from 'pages/Dashboards/interfaces';
import DashboardsGridItem from 'pages/Dashboards/DashboardListing/GridView/dashboardsGridItem/dashboardsGridItem';
import { QUERY_KEYS } from 'api/constants';
import { useQuery, useQueryClient } from 'react-query';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCallback, useContext, useMemo } from 'react';
import { DashboardsContext } from 'pages/Dashboards/context/context';
import DashboardMainEmptyState from 'pages/Dashboards/EmptyState/mainEmptyState';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';

function DashboardsGridListing() {
  const queryClient = useQueryClient();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dashboards: IDashboardList | undefined = queryClient.getQueryData([
    QUERY_KEYS.GET_DASHBOARDS,
  ]);
  const { dashboardsState, updateDashboardsState } =
    useContext(DashboardsContext);
  const { searchQuery } = dashboardsState;

  const handleItemClick = useCallback(
    (item: IDashboardItem) => {
      navigateWithWorkspaceUrl(`/dashboards/${item.id}`);
    },
    [dashboards],
  );
  const filteredDashboards: IDashboardItem[] = useMemo(
    () =>
      dashboards?.dashboards?.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()),
      ) || [],
    [dashboards, searchQuery],
  );
  const dashboardsData: IDashboardItem[] | undefined = searchQuery
    ? filteredDashboards
    : dashboards?.dashboards;
  return (
    <>
      {dashboards?.dashboards?.length === 0 ? (
        <DashboardMainEmptyState />
      ) : searchQuery && dashboardsData?.length === 0 ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="column"
          height="300px"
        >
          <NoRecordsFound />
        </Stack>
      ) : (
        <Grid container spacing={3}>
          {dashboardsData?.map((item: IDashboardItem) => (
            <DashboardsGridItem
              key={item.id}
              item={item}
              onClick={() => handleItemClick(item)}
            />
          ))}
        </Grid>
      )}
    </>
  );
}

export default DashboardsGridListing;
