import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';

export const EmptyStateCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  '& svg': {
    color: '#90A4AE',
    fontSize: 46,
  },
});
export const EmptyStateText = styled('p')({
  margin: 0,
  fontSize: 18,
  fontWeight: 400,
  lineHeight: '24.59px',
  marginTop: 12,
});
export const EmptyStateHeading = styled(Typography)({
  fontSize: 18,
  fontWeight: 700,
  lineHeight: '21.86px',
  color: '#000000DE',
  marginBottom: 10,
  marginTop: 5,
});
export const EmptyStateSubText = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '21.86px',
  color: '#000000',
  width: 331,
  textAlign: 'center',
});
