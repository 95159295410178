import SearchInput from 'components/Form/TextField/SearchField.cmp';

const Search = ({ searchTerm, handleChangeSearch }) => {
  return (
    <SearchInput
      fieldProps={{
        value: searchTerm,
        onChange: (e) => handleChangeSearch(e.target.value),
      }}
      sx={{
        maxWidth: { sm: 'calc(100vw - 140px)', md: 250 },
        width: { sm: '100%', md: 350 },
      }}
    />
  );
};

export default Search;
