import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from 'moment';
import mime from 'mime';
import { Typography } from '@mui/material';
import { UsersActivityEnum } from 'utils/globalVariable';
import actions from 'store/actions';
import {
  LoginIconHOC,
  LogoutIconHOC,
  SubmissionIconHOC,
  TaskIconHOC,
} from '../icons';

const ActivityLogItem = (props) => {
  const { timelineElement } = props;
  const dispatch = useDispatch();

  const ICONS_LIST = {
    [UsersActivityEnum.Loggedin]: <LoginIconHOC />,
    [UsersActivityEnum.Loggedout]: <LogoutIconHOC />,
    [UsersActivityEnum.SubmittedSubmission]: <SubmissionIconHOC />,
    [UsersActivityEnum.ChangedStatus]: <TaskIconHOC />,
  };

  const STATUS_COLORS = {
    Open: '#3B8EF0',
    'In Progress': '#F58500',
    'On Hold': '#64636D',
    Completed: '#04B86C',
  };

  const generateNodeForEachCase = (timelineElement) => {
    let renderer: ReactNode = <></>;

    let selfieData: any = null;
    if (timelineElement?.log?.selfie) {
      selfieData = [
        {
          url: timelineElement?.log?.selfie,
          type: mime.getType(timelineElement?.log?.selfie),
        },
      ];
    }

    switch (timelineElement.type) {
      case UsersActivityEnum.Loggedin:
        renderer = (
          <>
            <Typography className="time">
              {moment(timelineElement?.createdAt).format('hh:mm A')}
            </Typography>
            <Typography className="content">
              {timelineElement?.User?.fullName}{' '}
              <span style={{ color: '#007DFF' }}>logged in</span>
              <span>
                {timelineElement?.log?.isKioskMode ? ' to the Kiosk mode.' : ''}
              </span>
            </Typography>
            {timelineElement?.log?.selfie && (
              <div style={{ height: 30, width: 30, marginTop: 3 }}>
                <img
                  src={timelineElement?.log?.selfie}
                  onClick={() => {
                    const index = 0;
                    dispatch(
                      actions.setAttachmentsPreviewData({
                        index,
                        attachments: selfieData,
                      } as any),
                    );
                  }}
                />
              </div>
            )}
          </>
        );
        break;
      case UsersActivityEnum.Loggedout:
        renderer = (
          <>
            <Typography className="time">
              {moment(timelineElement?.createdAt).format('hh:mm A')}
            </Typography>
            <Typography className="content">
              {timelineElement?.User?.fullName}{' '}
              <span style={{ color: '#007DFF' }}>logged out</span>
              <span>
                {timelineElement?.log?.isKioskMode ? ' to the Kiosk mode.' : ''}
              </span>
            </Typography>
          </>
        );
        break;
      case UsersActivityEnum.SubmittedSubmission:
        renderer = (
          <>
            <Typography className="time">
              {moment(timelineElement?.createdAt).format('hh:mm A')}
            </Typography>
            <Typography className="content">
              {timelineElement?.User?.fullName}{' '}
              <span style={{ color: '#007DFF' }}>submitted a submission </span>
              {timelineElement?.log?.name + '.'}
            </Typography>
          </>
        );
        break;
      case UsersActivityEnum.ChangedStatus:
        renderer = (
          <>
            <Typography className="time">
              {moment(timelineElement?.createdAt).format('hh:mm A')}
            </Typography>
            <Typography className="content">
              {timelineElement?.User?.fullName} <span>changed status </span>
              <span>from </span>
              <span
                style={{ color: STATUS_COLORS[timelineElement?.log?.from] }}
              >
                {timelineElement?.log?.from?.toUpperCase()}{' '}
              </span>
              <span>to </span>
              <span style={{ color: STATUS_COLORS[timelineElement?.log?.to] }}>
                {timelineElement?.log?.to?.toUpperCase()}{' '}
              </span>
              {timelineElement?.log?.title + '.'}
            </Typography>
          </>
        );
        break;
      default:
        break;
    }

    return renderer;
  };

  return (
    <VerticalTimelineElement
      key={timelineElement?.id}
      className="vertical-timeline-element--work"
      contentStyle={{
        color: '#fff',
        left: '-5px',
      }}
      iconStyle={{ background: 'transparent' }}
      icon={ICONS_LIST?.[timelineElement?.type]}
    >
      {generateNodeForEachCase(timelineElement)}
    </VerticalTimelineElement>
  );
};

export default ActivityLogItem;
