import UsersTable from 'components/DataTables/Users';
import { RoleDetailsUsersCnt } from './style';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import AssignedUsersHeader from './header';
import { setAllUsers } from 'store/actions/userActions';

import { getUserListApi } from 'api/userApi';
import UsersAssignedEmptyState from './emptyState';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { getUserProfile } from 'store/selectors/auth';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function RoleDetailsUsers(props) {
  const dispatch = useDispatch();
  const { details, getCustomRoleDetails } = props;
  const [searchedTerm, setSearchedTerm] = useState('');
  const allUsersList = useSelector(selectors.getAllUsersList);
  const { hasPermission } = useCustomPermission();
  const { fetchCustomRoles } = useCustomRoles();
  const { workspaceId } = useWorkspaceHook();
  const userProfileData: any = useSelector(getUserProfile);

  const selectedUsers = useMemo(() => {
    if (details?.HotelUsers?.length > 0 && allUsersList?.length > 0) {
      const usersIds = details?.HotelUsers?.map((user) => user?.UserId);
      const usersListWithDetails = usersIds?.map((id) =>
        allUsersList?.find((user) => user?.id === id),
      );

      if (searchedTerm) {
        const searchedResults = usersListWithDetails?.filter((user) =>
          user?.fullName?.toLowerCase()?.includes(searchedTerm),
        );

        return searchedResults;
      }

      return usersListWithDetails || [];
    }

    return [];
  }, [details, allUsersList, searchedTerm]);

  const fetchUsersList = async () => {
    const result = await getUserListApi();
    const data = result?.data?.length > 0 ? result?.data : [];
    dispatch(setAllUsers(data));
  };

  useEffect(() => {
    if (allUsersList?.length > 0) return;
    fetchUsersList();
  }, []);

  const onSearch = (searched) => {
    setSearchedTerm(searched?.toLowerCase());
  };

  const PermissionsChangedTemplate = () => {
    return (
      <>
        <Typography fontSize="16px" fontWeight="500">
          Seems like your role / permissions has been updated, Please click on
          reload to apply it accordingly!
        </Typography>
      </>
    );
  };

  const onDeleteHandler = (userId) => {
    const isLoggedInUser = isEqual(userId, userProfileData?.id);

    if (isLoggedInUser) {
      dispatch(
        setDialog({
          dialogId: DIALOGS.CONFIRMATION,
          open: true,
          data: {
            confirmButtonProps: {
              style: {
                backgroundColor: '#D32F2F',
              },
            },
            hideCrossIcon: true,
            removeClickAwayClose: true,
            hideCancelBtn: true,
            ContentTemplate: PermissionsChangedTemplate,
            confirmationText: 'Reload',
            title: 'Your Role Changed',
            onConfirmCallback: () => {
              window.location.href = `/ws/${workspaceId}/users-and-teams?tab=roles`;
            },
          },
        }),
      );
    } else {
      getCustomRoleDetails?.();
      fetchCustomRoles({ payload: {}, forceLoad: true });
    }
  };

  return (
    <RoleDetailsUsersCnt>
      <AssignedUsersHeader
        details={details}
        getCustomRoleDetails={getCustomRoleDetails}
        onSearch={onSearch}
      />

      {!searchedTerm && selectedUsers?.length === 0 ? (
        <UsersAssignedEmptyState
          details={details}
          getCustomRoleDetails={getCustomRoleDetails}
        />
      ) : (
        <UsersTable
          rowData={selectedUsers}
          isSizeColumnFit={true}
          height={'calc(100vh - 400px)'}
          usersActions={{
            onDeleteHandler,
          }}
          columnConfig={{
            actionCol: hasPermission(PERMISSIONS.CAN_MANAGE_ROLES),
          }}
        />
      )}
    </RoleDetailsUsersCnt>
  );
}

export default RoleDetailsUsers;
