import { createSvgIcon } from '@mui/material';
import React from 'react';

const RecurringTaskUsageIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="34" height="34" rx="8" fill="#FFA726" />
      <circle cx="17" cy="17" r="10" fill="#FFF3E0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2172 11.64C20.6173 11.5328 21.0285 11.7702 21.1358 12.1703L21.7292 14.3851C21.8364 14.7852 21.599 15.1964 21.1989 15.3036L18.9841 15.8971C18.584 16.0043 18.1728 15.7669 18.0656 15.3668C17.9583 14.9667 18.1958 14.5554 18.5959 14.4482L18.7708 14.4013C18.2759 14.0686 17.6779 13.874 17.0325 13.874C15.548 13.874 14.3149 14.9044 14.0138 16.2677C13.9244 16.6722 13.5241 16.9277 13.1197 16.8383C12.7152 16.749 12.4597 16.3487 12.5491 15.9442C13.0012 13.8971 14.8408 12.374 17.0325 12.374C18.1189 12.374 19.1188 12.7486 19.9056 13.3747L19.6869 12.5586C19.5797 12.1585 19.8171 11.7472 20.2172 11.64ZM20.8719 16.1693C21.2861 16.1693 21.6219 16.505 21.6219 16.9193C21.6219 19.4377 19.5589 21.4645 17.0325 21.4645C15.8861 21.4645 14.8366 21.0477 14.0316 20.3582V20.7148C14.0316 21.1291 13.6958 21.4648 13.2816 21.4648C12.8674 21.4648 12.5316 21.1291 12.5316 20.7148L12.5316 18.4377C12.5316 18.0235 12.8674 17.6877 13.2816 17.6877L15.5588 17.6877C15.973 17.6877 16.3088 18.0235 16.3088 18.4377C16.3088 18.8519 15.973 19.1877 15.5588 19.1877H14.9712C15.5176 19.6705 16.2395 19.9645 17.0325 19.9645C18.7469 19.9645 20.1219 18.5929 20.1219 16.9193C20.1219 16.505 20.4577 16.1693 20.8719 16.1693Z"
        fill="#F57C00"
      />
    </>,
    'RecurringTaskUsageIcon',
  );
  return <IconCmp viewBox={'0 0 34 34'} fill="none" {...props} />;
};

export default RecurringTaskUsageIcon;
