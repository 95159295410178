import styled from '@mui/system/styled';

export const ColorsDropdown = styled('div')({
  padding: '16px',
  '& .mainTitle': {
    fontSize: 16,
    fontWeight: '600',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .subtitle': {
    fontSize: 14,
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

export const ColorContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  width: '59px',
  height: '45px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.6,
  '&:hover': {
    borderRadius: '6px',
    border: '1px solid',
    borderColor: `${color}`,
    backgroundColor: `${color}10`,
  },
}));

export const ColorSpan = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  backgroundColor: `${color}`,
  width: '24px',
  height: '24px',
  borderRadius: '12px',
}));

export const UploadDiv = styled('div')({
  display: 'flex',
  borderTop: '1px solid #E0E0E0',
  marginTop: '10px',
  paddingTop: '15px',
});

export const UploadLink = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const UploadText = styled('span')({
  fontSize: '15px',
  color: '#6868FE',
  lineHeight: '24px',
  marginLeft: '10px',
  marginRight: '10px',
});

export const RemoveImageLink = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '15px',
  color: '#EF5350',
  lineHeight: '24px',
  marginLeft: '10px',
});
