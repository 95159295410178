import { useEffect, useState } from 'react';

import actions from 'store/actions';
import { useDispatch } from 'react-redux';

import { useParams, useSearchParams } from 'react-router-dom';

import DIALOGS from 'utils/dialogIds';

const FullScreenTemplate = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams, _] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [dialogData, setDialogData] = useState<any>({});

  const openDialog = () => {
    dispatch(actions.setDialog(dialogData));
  };

  const fetchData = async () => {
    setLoading(true);
    const checklistLogId = searchParams.get('logId');
    const taskId = searchParams.get('taskId');
    const prevUrl = searchParams.get('prevUrl');
    setDialogData({
      dialogId: DIALOGS.FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG,
      open: true,
      data: {
        checklistId: params?.checklistId,
        ...(checklistLogId && { checklistLogId }),
        ...(taskId && { taskId }),
        ...(prevUrl && { prevUrl }),
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      openDialog();
    }
  }, [loading]);

  useEffect(() => {
    if (params?.checklistId) {
      fetchData();
    }
    return () => {
      dispatch(
        actions.closeDialog(DIALOGS.FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG),
      );
    };
  }, []);

  return <></>;
};

export default FullScreenTemplate;
