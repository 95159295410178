import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PreviewChecklistBody from 'components/PreviewChecklistBody';
import { getPublicChecklist } from 'api/checklistV2Api';
import XeniaLogo from 'components/common/xenia-logo';
import { Container } from './style';

const TemplatePreview: FC = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const [checklist, setChecklist] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (templateId) {
          const res = await getPublicChecklist(templateId);
          if (res.data) {
            setChecklist(res.data);
          }
        }
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    })();
  }, [templateId]);

  return (
    <Container>
      {loading ? (
        <div>
          <XeniaLogo />
        </div>
      ) : (
        checklist && <PreviewChecklistBody checklist={checklist} isPublic />
      )}
    </Container>
  );
};

export default TemplatePreview;
