import { flatMap } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getLocationsHash } from 'store/selectors/locations';
import { CHECKLIST_ITEM_TYPE } from 'utils/globalVariable';

export function useSubmissionsUtils() {
  const locationsHash = useSelector(getLocationsHash);

  const getFlaggedResponse = (submission) => {
    const { options, type, answers } = submission || {};

    switch (type) {
      case CHECKLIST_ITEM_TYPE.PROCEDURE:
        return answers?.value ? 'Checked' : 'Unchecked';

      case CHECKLIST_ITEM_TYPE.DROPDOWN:
      case CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE:
        return options?.find(
          (item) =>
            item?.id === answers?.value?.[0] || item?.id === answers?.value,
        )?.label;

      case CHECKLIST_ITEM_TYPE.LOCATION:
        return answers?.value
          ? locationsHash?.[answers?.value]?.name || 'N/A'
          : 'N/A';
      default:
        return answers?.value;
    }
  };

  const getFlaggedCategory = (submission) => {
    const automationFlagCategory =
      submission?.TaskChecklistItemFlagCategories?.[0]?.FlagCategory;
    if (automationFlagCategory) {
      return automationFlagCategory;
    }

    // Non Automation Flow
    if (
      [
        CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE,
        CHECKLIST_ITEM_TYPE.DROPDOWN,
      ].includes(submission?.type)
    ) {
      const option = submission?.options?.find(
        (item) =>
          item?.id === submission?.answers?.value?.[0] ||
          item?.id === submission?.answers?.value,
      );

      return option?.flagCategory;
    }
  };

  const transformaToFlaggedItems = (data) => {
    const flatData = flatMap(data, (item) => {
      return item?.TaskChecklistItems?.map((submission) => ({
        template: { name: item?.name, icon: item?.icon },
        question: submission?.description,
        flaggedResponse: getFlaggedResponse(submission),
        date: moment(item?.updatedAt).format('MMM DD, YYYY'),
        submitter: item?.LastItemUpdater,
        locations: item?.locationsInfo,
        flaggedCategoryId: getFlaggedCategory(submission),
      }));
    });

    return flatData;
  };

  const transformaToFlaggedItemsQuestions = (data, itemId) => {
    const flatData = flatMap(data, (item) => {
      const items = item?.TaskChecklistItems?.filter(
        (item) => item?.ChecklistItemId === itemId,
      );

      return items?.map((submission) => ({
        template: { name: item?.name, icon: item?.icon },
        question: submission?.description,
        flaggedResponse: getFlaggedResponse(submission),
        date: moment(item?.updatedAt).format('MMM DD, YYYY'),
        submitter: item?.LastItemUpdater,
        locations: item?.locationsInfo,
        flaggedCategoryId: getFlaggedCategory(submission),
      }));
    });

    return flatData;
  };

  return {
    transformaToFlaggedItems,
    transformaToFlaggedItemsQuestions,
  };
}
