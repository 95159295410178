import { createSvgIcon } from '@mui/material';
import React from 'react';

const WorkOrderUsageIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="34" height="34" rx="8" fill="#66BB6A" />
      <path
        opacity="0.12"
        d="M23.528 12.5276L19.639 8.63853C19.4038 8.40338 19.1305 8.21522 18.8335 8.08008V11.8664C18.8335 12.3798 18.8335 12.6365 18.9334 12.8326C19.0213 13.005 19.1615 13.1453 19.334 13.2332C19.5301 13.3331 19.7868 13.3331 20.3002 13.3331H24.0865C23.9514 13.0361 23.7632 12.7628 23.528 12.5276Z"
        fill="#2E7D32"
      />
      <path
        d="M9.6665 12.233C9.6665 10.6929 9.6665 9.92279 9.96624 9.33453C10.2299 8.81709 10.6506 8.39639 11.168 8.13274C11.7563 7.83301 12.5264 7.83301 14.0665 7.83301H18.8332L24.3332 13.333V21.7663C24.3332 23.3065 24.3332 24.0766 24.0334 24.6648C23.7698 25.1823 23.3491 25.603 22.8316 25.8666C22.2434 26.1663 21.4733 26.1663 19.9332 26.1663H14.0665C12.5264 26.1663 11.7563 26.1663 11.168 25.8666C10.6506 25.603 10.2299 25.1823 9.96624 24.6648C9.6665 24.0766 9.6665 23.3065 9.6665 21.7663V12.233Z"
        fill="#E8F5E9"
      />
      <path d="M15.1665 14.2497H13.3332H15.1665Z" fill="#E8F5E9" />
      <path
        d="M18.8332 8.08008V11.8664C18.8332 12.3798 18.8332 12.6365 18.9331 12.8326C19.021 13.005 19.1612 13.1453 19.3337 13.2332C19.5298 13.3331 19.7865 13.3331 20.2998 13.3331H24.0862M20.6665 17.9163H13.3332M20.6665 21.583H13.3332M15.1665 14.2497H13.3332M18.8332 7.83301H14.0665C12.5264 7.83301 11.7563 7.83301 11.168 8.13274C10.6506 8.39639 10.2299 8.81709 9.96624 9.33453C9.6665 9.92279 9.6665 10.6929 9.6665 12.233V21.7663C9.6665 23.3065 9.6665 24.0766 9.96624 24.6648C10.2299 25.1823 10.6506 25.603 11.168 25.8666C11.7563 26.1663 12.5264 26.1663 14.0665 26.1663H19.9332C21.4733 26.1663 22.2434 26.1663 22.8316 25.8666C23.3491 25.603 23.7698 25.1823 24.0334 24.6648C24.3332 24.0766 24.3332 23.3065 24.3332 21.7663V13.333L18.8332 7.83301Z"
        stroke="#2E7D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'WorkOrderUsageIcon',
  );
  return <IconCmp viewBox={'0 0 34 34'} fill="none" {...props} />;
};

export default WorkOrderUsageIcon;
