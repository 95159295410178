import styled from '@mui/system/styled';

export const BreadCrumbsCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '16px 0',
  gap: 3,
  '.heading': {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '21.86px',
    letterSpacing: '-0.5px',
    ':hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '.arrowIcon': {
      fontSize: 16,
      color: '#757575',
      marginRight: 3,
    },
  },
  '.folderNameCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 5,

    '.folderName': {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      ':hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  },
});
