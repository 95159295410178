import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { RootState } from '../../../store';
import {
  generateAssigneeDropdownData,
  generateTeamsFilterDropdownData,
  logSubmissionStatuses,
} from 'helper/task/dropdownData';
import { cloneDeep, isEmpty } from 'lodash';

function useTemplateSubmissionsFilters(context) {
  const {
    state,
    updateState,
    clearAllFilters,
  }: { state: any; updateState: any; clearAllFilters: any } =
    useContext(context);
  const templates = useSelector(selectors.getChecklistCompleteDataOnly);
  const userList = useSelector(selectors.getUserAdmin);
  const teams = useSelector(selectors.getTeams);

  const assigneeDropdownData = useMemo(() => {
    return generateAssigneeDropdownData(userList);
  }, [userList]);

  // Generating Teams data for dropdown
  const teamsDropdownData = useMemo(() => {
    return generateTeamsFilterDropdownData(teams);
  }, [teams]);

  const taskAssigneesData = [
    { label: 'Unassigned', id: 'unassigned' },
    ...(teamsDropdownData?.length
      ? [
          {
            label: 'Teams',
            id: 'teams',
            child: teamsDropdownData,
          },
        ]
      : []),
    { label: 'People', id: 'people', child: assigneeDropdownData },
  ];
  //Selected status in filters objects
  const selectedStatus = useMemo(() => {
    return logSubmissionStatuses?.filter((s) =>
      state?.filters['statuses']?.includes(s.id),
    );
  }, [state?.filters]);

  //Selected status in filters objects
  const selectedLocations = useMemo(() => {
    return state?.filters['locations'];
  }, [state?.filters]);

  const selectedTemplates = useMemo(() => {
    return templates?.filter((t) =>
      state?.filters['checklists']?.includes(t.id),
    );
  }, [state?.filters, templates]);

  const selectedEmployees = useMemo(() => {
    return taskAssigneesData?.reduce((pre: any, curr) => {
      if (state?.filters['users']?.includes(curr.id)) pre.push(curr);
      const users = curr.child?.filter((x) =>
        state?.filters['users']?.includes(x.id),
      );
      if (users?.length) pre = pre.concat(users);
      return pre;
    }, []);
  }, [state?.filters, taskAssigneesData]);

  const handleDateChange = (date): any => {
    updateState({
      selectedDateLabel: date?.selectedDateOption,
      filters: {
        ...state?.filters,
        fromDate: date?.from,
        toDate: date?.to,
      },
    });
  };

  const handleFilterChange = (values, type) => {
    const postData = values.map((v) => v.id);
    updateState({ filters: { ...state.filters, [type]: postData } });
  };

  const handleClearFilter = (type) => {
    const filtersCopy = cloneDeep(state.filters);
    delete filtersCopy[type];
    updateState({ filters: filtersCopy });
  };
  const handleClearDateFilter = (date) => {
    handleDateChange(date);
  };
  const isFilterApplied = useMemo(() => {
    const { selectedDateLabel, filters } = state;
    const { fromDate, toDate, ...rest } = filters;
    const isFilterApplied =
      selectedDateLabel !== 'Last 30 Days' || !isEmpty(rest);
    return isFilterApplied;
  }, [state?.filters]);

  return {
    isFilterApplied,
    selectedTemplates,
    selectedEmployees,
    selectedStatus,
    selectedLocations,
    clearAllFilters,
    state,
    handleClearDateFilter,
    handleClearFilter,
    handleFilterChange,
    handleDateChange,
  };
}

export default useTemplateSubmissionsFilters;
