import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { CircularProgress, Stack, Typography } from '@mui/material';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { PERMISSIONS_ENTITY_KEYWORDS } from 'utils/constants';
import { getPermissionsForRoles } from 'store/selectors/permissions';
import PermissionsSearch from './search';
import { QUERY_KEYS } from 'api/constants';
import {
  EmptyCnt,
  LoaderCnt,
  MainPermission,
  PermissionsListingCnt,
  SubPermission,
} from './style';

function RoleDetailsPermissions(props) {
  const { details } = props;
  const [searchedTerm, setSearchedTerm] = useState('');
  const [filteredPermissionsList, setFilteredPermissionsList] = useState<any>(
    [],
  );

  const permissions = useSelector(getPermissionsForRoles);
  const { list: permissionsList } = permissions || {};
  const { getPermissions } = useCustomRoles();
  const [loading, setLoading] = useState(true);

  useQuery(QUERY_KEYS.GET_PERMISSIONS, getPermissions);

  useEffect(() => {
    setFilteredPermissionsList(permissionsList ?? {}); // Initialize filtered list with all permissions
  }, [permissionsList]);

  const selectedPermissions = useMemo(() => {
    return (
      details?.RolePermissions?.map((permission) => {
        return permission?.PermissionId;
      }) ?? []
    );
  }, [details]);

  useEffect(() => {
    if (details && loading) {
      setLoading(false);
    }
  }, [details, loading]);

  const handleInputChange = useCallback(
    (searched) => {
      const searchTerm = searched?.toLowerCase();
      setSearchedTerm(searchTerm);
      const filteredList = {};

      // Filter permissionsList based on search term
      Object.keys(permissionsList).forEach((key) => {
        const filteredItems = permissionsList[key].filter((item) =>
          item.name.toLowerCase().includes(searchTerm),
        );
        if (filteredItems.length > 0) {
          filteredList[key] = filteredItems;
        }
      });

      setFilteredPermissionsList(filteredList);
    },
    [permissionsList],
  );

  const checkPermissions = (selectedItems, permissions) => {
    const permissionIds = permissions.map((permission) => permission.id);
    const allPresent = permissionIds.every((id) => selectedItems.includes(id));

    if (allPresent) return 'all';
    const nonePresent = permissionIds.every(
      (id) => !selectedItems.includes(id),
    );

    if (nonePresent) return 'none';

    return null;
  };

  return loading ? (
    <LoaderCnt>
      <CircularProgress />
    </LoaderCnt>
  ) : (
    <>
      <PermissionsSearch onSearch={handleInputChange} />
      {searchedTerm && Object.keys(filteredPermissionsList)?.length === 0 ? (
        <EmptyCnt>
          <NoRecordsFound />
        </EmptyCnt>
      ) : (
        <PermissionsListingCnt>
          {Object.keys(filteredPermissionsList).map((key) => {
            const completeStatus = checkPermissions(
              selectedPermissions,
              filteredPermissionsList[key],
            );

            return (
              <div key={key}>
                <MainPermission direction="row" gap="5px" alignItems="center">
                  {completeStatus === 'all' && (
                    <CheckCircleTwoToneIcon
                      sx={{ color: '#4CAF50', marginLeft: '-20px' }}
                    />
                  )}

                  {completeStatus === 'none' && (
                    <CancelTwoToneIcon
                      sx={{ color: '#EF5350', marginLeft: '-20px' }}
                    />
                  )}
                  <Typography className="content">
                    {PERMISSIONS_ENTITY_KEYWORDS[key]}
                  </Typography>
                </MainPermission>
                {filteredPermissionsList[key].map((item) => (
                  <SubPermission
                    key={item.id}
                    direction="row"
                    gap="5px"
                    alignItems="start"
                  >
                    {selectedPermissions?.includes(item?.id) ? (
                      <CheckCircleTwoToneIcon
                        sx={{ color: '#4CAF50', marginTop: '-2px' }}
                      />
                    ) : (
                      <CancelTwoToneIcon
                        sx={{ color: '#EF5350', marginTop: '-2px' }}
                      />
                    )}
                    <Stack gap="5px">
                      <Typography className="title">{item?.name}</Typography>
                      <Typography className="description">
                        {item?.description}
                      </Typography>
                    </Stack>
                  </SubPermission>
                ))}
              </div>
            );
          })}
        </PermissionsListingCnt>
      )}
    </>
  );
}

export default RoleDetailsPermissions;
