import { createSvgIcon } from '@mui/material';

const NoTemplateSubmission = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M0.436279 51.9016V8.89697H20.3693L24.8853 12.9212H54.5373V51.9016H0.436279Z"
        fill="#6868FE"
      />
      <path
        d="M54.5372 52.2499H0.436248C0.343761 52.2499 0.255061 52.2132 0.189663 52.1478C0.124265 52.0824 0.0875244 51.9937 0.0875244 51.9012V8.89658C0.0875244 8.80409 0.124265 8.71539 0.189663 8.64999C0.255061 8.58459 0.343761 8.54785 0.436248 8.54785H20.3693C20.4541 8.54797 20.5359 8.57897 20.5994 8.63503L25.0178 12.5721H54.5372C54.6297 12.5721 54.7184 12.6089 54.7838 12.6743C54.8492 12.7397 54.8859 12.8284 54.8859 12.9208V51.9012C54.8859 51.9937 54.8492 52.0824 54.7838 52.1478C54.7184 52.2132 54.6297 52.2499 54.5372 52.2499ZM0.784972 51.5524H54.1885V13.2696H24.8957C24.8098 13.2703 24.7265 13.2393 24.6621 13.1824L20.2472 9.2453H0.784972V51.5524Z"
        fill="#093F68"
      />
      <path
        d="M2.7511 4.9082L48.4506 0.596373L52.7844 46.5285L7.08488 50.8403L2.7511 4.9082Z"
        fill="#FFBC0E"
      />
      <path
        d="M7.08287 51.1896C6.99611 51.1901 6.91231 51.1581 6.84783 51.1001C6.78335 51.0421 6.74282 50.9621 6.73414 50.8758L2.40299 4.9419C2.39425 4.84988 2.4224 4.75816 2.48125 4.68689C2.5401 4.61561 2.62484 4.57062 2.71684 4.56179L48.4171 0.251564C48.5091 0.242823 48.6008 0.270971 48.6721 0.329821C48.7434 0.388671 48.7884 0.473408 48.7972 0.565416L53.1283 46.4993C53.1374 46.591 53.1098 46.6825 53.0516 46.7539C53.0223 46.7894 52.9863 46.8188 52.9456 46.8403C52.9049 46.8619 52.8603 46.8752 52.8145 46.8794L7.13169 51.1896H7.08287ZM3.12834 5.22436L7.38625 50.4608L52.3925 46.2168L48.1346 0.97691L3.12834 5.22436Z"
        fill="#093F68"
      />
      <path d="M7.21191 6.25342H53.1144V47.769H7.21191V6.25342Z" fill="white" />
      <path
        d="M53.1144 48.1208H7.21188C7.1194 48.1208 7.0307 48.084 6.9653 48.0186C6.8999 47.9532 6.86316 47.8645 6.86316 47.7721V6.25302C6.86316 6.16053 6.8999 6.07183 6.9653 6.00644C7.0307 5.94104 7.1194 5.9043 7.21188 5.9043H53.1144C53.2069 5.9043 53.2956 5.94104 53.361 6.00644C53.4264 6.07183 53.4631 6.16053 53.4631 6.25302V47.7721C53.4631 47.8645 53.4264 47.9532 53.361 48.0186C53.2956 48.084 53.2069 48.1208 53.1144 48.1208ZM7.56061 47.4233H52.7657V6.60174H7.56061V47.4233Z"
        fill="#093F68"
      />
      <path
        d="M47.4895 12.1716H38.3042C38.2117 12.1716 38.123 12.1348 38.0576 12.0694C37.9922 12.004 37.9554 11.9153 37.9554 11.8228C37.9554 11.7304 37.9922 11.6417 38.0576 11.5763C38.123 11.5109 38.2117 11.4741 38.3042 11.4741H47.4895C47.582 11.4741 47.6707 11.5109 47.7361 11.5763C47.8015 11.6417 47.8383 11.7304 47.8383 11.8228C47.8383 11.9153 47.8015 12.004 47.7361 12.0694C47.6707 12.1348 47.582 12.1716 47.4895 12.1716Z"
        fill="#70CC40"
      />
      <path
        d="M47.4895 16.5715H13.346C13.2535 16.5715 13.1648 16.5347 13.0994 16.4693C13.034 16.4039 12.9973 16.3152 12.9973 16.2227C12.9973 16.1303 13.034 16.0416 13.0994 15.9762C13.1648 15.9108 13.2535 15.874 13.346 15.874H47.4895C47.582 15.874 47.6707 15.9108 47.7361 15.9762C47.8015 16.0416 47.8382 16.1303 47.8382 16.2227C47.8382 16.3152 47.8015 16.4039 47.7361 16.4693C47.6707 16.5347 47.582 16.5715 47.4895 16.5715Z"
        fill="#70CC40"
      />
      <path
        d="M47.4895 20.9728H13.346C13.2535 20.9728 13.1648 20.9361 13.0994 20.8707C13.034 20.8053 12.9973 20.7166 12.9973 20.6241C12.9973 20.5316 13.034 20.4429 13.0994 20.3775C13.1648 20.3121 13.2535 20.2754 13.346 20.2754H47.4895C47.582 20.2754 47.6707 20.3121 47.7361 20.3775C47.8015 20.4429 47.8382 20.5316 47.8382 20.6241C47.8382 20.7166 47.8015 20.8053 47.7361 20.8707C47.6707 20.9361 47.582 20.9728 47.4895 20.9728Z"
        fill="#70CC40"
      />
      <path
        d="M47.4895 25.3776H13.346C13.2535 25.3776 13.1648 25.3409 13.0994 25.2755C13.034 25.2101 12.9973 25.1214 12.9973 25.0289C12.9973 24.9364 13.034 24.8477 13.0994 24.7823C13.1648 24.7169 13.2535 24.6802 13.346 24.6802H47.4895C47.582 24.6802 47.6707 24.7169 47.7361 24.7823C47.8015 24.8477 47.8382 24.9364 47.8382 25.0289C47.8382 25.1214 47.8015 25.2101 47.7361 25.2755C47.6707 25.3409 47.582 25.3776 47.4895 25.3776Z"
        fill="#70CC40"
      />
      <path
        d="M0.436279 51.9009L8.68359 23.8182H34.0149L37.7462 20.3833H64.5526L54.9313 51.9009H0.436279Z"
        fill="#6868FE"
      />
      <path
        d="M54.9313 52.2493H0.436248C0.382111 52.2493 0.328717 52.2367 0.280295 52.2125C0.231873 52.1882 0.189751 52.1531 0.157268 52.1098C0.124786 52.0665 0.102834 52.0162 0.0931491 51.9629C0.0834647 51.9097 0.0863154 51.8549 0.101474 51.8029L8.34878 23.7202C8.37053 23.6454 8.41668 23.5801 8.47988 23.5346C8.54308 23.4891 8.61969 23.466 8.69751 23.4691H33.8893L37.523 20.1249C37.587 20.0667 37.6702 20.0344 37.7566 20.0342H64.563C64.6172 20.0342 64.6706 20.0468 64.719 20.071C64.7674 20.0952 64.8095 20.1304 64.842 20.1737C64.875 20.2174 64.8973 20.2683 64.907 20.3222C64.9167 20.3761 64.9135 20.4316 64.8978 20.484L55.273 52.0017C55.251 52.0746 55.2056 52.1383 55.1439 52.183C55.0821 52.2278 55.0075 52.251 54.9313 52.2493ZM0.903539 51.5518H54.6732L64.0888 20.7316H37.8822L34.238 24.0759C34.1732 24.1348 34.0885 24.1672 34.0009 24.1666H8.9451L0.903539 51.5518Z"
        fill="#093F68"
      />
      <path
        d="M52.5705 47.9885H41.3764C41.2839 47.9885 41.1952 47.9517 41.1298 47.8863C41.0645 47.8209 41.0277 47.7322 41.0277 47.6397C41.0277 47.5473 41.0645 47.4586 41.1298 47.3932C41.1952 47.3278 41.2839 47.291 41.3764 47.291H52.5705C52.6629 47.291 52.7516 47.3278 52.817 47.3932C52.8824 47.4586 52.9192 47.5473 52.9192 47.6397C52.9192 47.7322 52.8824 47.8209 52.817 47.8863C52.7516 47.9517 52.6629 47.9885 52.5705 47.9885Z"
        fill="#F56132"
      />
      <path
        d="M17.3912 12.1716H13.346C13.2535 12.1716 13.1648 12.1348 13.0994 12.0694C13.034 12.004 12.9973 11.9153 12.9973 11.8228C12.9973 11.7304 13.034 11.6417 13.0994 11.5763C13.1648 11.5109 13.2535 11.4741 13.346 11.4741H17.3912C17.4837 11.4741 17.5724 11.5109 17.6378 11.5763C17.7032 11.6417 17.7399 11.7304 17.7399 11.8228C17.7399 11.9153 17.7032 12.004 17.6378 12.0694C17.5724 12.1348 17.4837 12.1716 17.3912 12.1716Z"
        fill="#70CC40"
      />
    </>,
    'NoTemplateSubmission',
  );
  return <IconCmp viewBox={'0 0 65 53'} {...props} />;
};
export default NoTemplateSubmission;
