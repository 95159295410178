import React from 'react';

import CustomToolTip from 'components/Tooltip/tooltip';
import CommentAction from './CommentsAction';
import TaskActionDropdown from './TaskActionDropdown';

import { ActionsCellCnt } from './styled';

interface IProps {
  task: any;
  onRowClick: (taskId: string, action: string) => void;
  unreadCommentsCountValue: number;
  onTaskActionSuccess: any;
}

const ActionCell = ({
  task,
  onRowClick,
  unreadCommentsCountValue,
  onTaskActionSuccess,
}: IProps) => {
  return (
    <ActionsCellCnt data-attribute={'cell-click'}>
      <CustomToolTip title={'Comments'}>
        <span
          onClick={(e) => {
            onRowClick(task?.id, 'comments');
          }}
        >
          {unreadCommentsCountValue ? (
            <CommentAction variant="unread" count={unreadCommentsCountValue} />
          ) : !task?.commentsCount?.messages?.length ? (
            <CommentAction variant="add" />
          ) : (
            <CommentAction
              variant="read"
              count={task?.commentsCount?.messages?.length || 0}
            />
          )}
        </span>
      </CustomToolTip>

      <span style={{ marginLeft: 15 }}>
        <TaskActionDropdown task={task} successCB={onTaskActionSuccess} />
      </span>
    </ActionsCellCnt>
  );
};

export default ActionCell;
