import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const UserStatusChip = styled('span')({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
  padding: '4px 10px',
  borderRadius: 14,
  textTransform: 'capitalize',
});

export const TeamRow = styled('div')({
  '& .listItem': {
    '&:hover': {
      backgroundColor: '#EBEAFF !important',
    },
  },
  '& .iconBlock': {
    minWidth: 20,
  },
  '& .subList': {
    '& .iconBlock': {
      minWidth: 'unset',
      marginRight: 10,
    },
  },
  '& .content': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& .mainLabel': {
      color: colors.black,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '22.4px',
      letterSpacing: '0.15px',
      maxWidth: 300,
    },
    '& .usersCount': {
      color: '#212121',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '17.16px',
      letterSpacing: '0.17px',
      backgroundColor: '#F5F5F5',
      padding: '5px 8px',
      borderRadius: 60,
    },
  },
});

export const TemplateRow = styled('div')({
  height: 38,
  padding: 4,
  borderRadius: 6,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#EBEAFF',
    '& .secondColumn': {
      '& .logs': {
        display: 'none',
      },
      '& .actionBtn': {
        '& button': {
          display: 'inline-block',
        },
      },
    },
  },
  '& .firstColumn': {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginLeft: 8,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20.02px',
      letterSpacing: '0.17px',
      color: colors.black,
    },
  },
  '& .secondColumn': {
    '& .logs': {
      border: '1px solid #E0E0E0',
      borderRadius: 62,
      width: 63,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '17.16px',
        letterSpacing: '0.17px',
        color: colors.black,
      },
    },
    '& .actionBtn': {
      '& button': {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '17.16px',
        letterSpacing: '0.17px',
        display: 'none',
      },
    },
  },
});

export const FiltersCnt = styled('div')({
  marginBottom: 10,
  '& button': {
    padding: '6px 14px',
    gap: 8,
    width: 75,
    height: 31,
    borderRadius: '100px !important',
    color: '#424242',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 19,
    letterSpacing: '0.01em',
    border: '1px solid #DBE2F0 !important',
  },
  '& .selected': {
    backgroundColor: '#6868FE',
    color: 'white',
    '&:hover': {
      backgroundColor: '#6868FE',
      color: 'white',
    },
  },
});

export const MainCnt = styled('div')({
  padding: 20,
  height: '100%',
  border: '1px solid #E0E0E0',
  borderRadius: 10,
  overflow: 'hidden',
});

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .title': {
    marginLeft: 7,
    color: colors.black,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28.8px',
    letterSpacing: '0.15px',
  },
  '& btn': {
    height: 37,
    borderRadius: 8,
  },
});

export const Content = styled('div')({
  height: 'calc(100% - 110px)',
  overflowY: 'auto',
  '& .customRow': {
    display: 'flex',
    justfiyContent: 'space-between',
    alignItems: 'center',
    '& .label': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20.02px',
      letterSpacing: '0.17px',
    },
  },
});
