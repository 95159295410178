// Components
import CustomInput from 'components/Form/TextField/TextField.cmp';

type RequestDescriptionInputProps = {
  label: string;
  placeholder: string;
  value: string;
  textarea?: boolean;
  handleChange: (value: string) => void;
};

const RequestTextInput = ({
  label,
  placeholder,
  value,
  textarea,
  handleChange,
}: RequestDescriptionInputProps) => {
  const handleInputChange = (event) => {
    const rawValue = event.target.value;
    const value = rawValue === '' ? null : rawValue;
    handleChange(value);
  };

  return (
    <CustomInput
      suppressErrorSpace
      topLabelInput={true}
      muiLabel={false}
      label={label}
      //   error={errors?.[objKey]}
      fieldProps={{
        ...(textarea && {
          multiline: true,
          maxRows: 4,
          minRows: 4,
        }),
        placeholder,
        onChange: handleInputChange,
        value: value,
      }}
      sx={{
        width: '100%',
        mb: '0px',
        '& label': {
          fontWeight: 600,
          fontSize: 13,
          color: '#212121',
          letterSpacing: 0.15,
        },
        '& .MuiInputBase-root > textarea': {
          fontSize: 14,
          fontWeight: 400,
        },
      }}
    />
  );
};

export default RequestTextInput;
