import UserAvatar from 'components/Avatar';
import { Value, MainCnt } from 'pages/Templates/FoldersListView/list.style';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import moment from 'moment-timezone';
import FolderActions from './renderers/actions/actions';
import FolderName from './renderers/folderName/folderName';

export const rowHeight = 48;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};

// columns
export const columns: any = (props) => {
  return [
    {
      field: 'title',
      headerName: 'Folder Name',
      colId: 'title',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 300,
      cellRenderer: cellRenderer.folderName,
    },
    {
      field: 'checklistCount',
      headerName: 'No. of items',
      colId: 'checklistCount',
      valueGetter: valueGetters.count,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 150,
      cellRenderer: cellRenderer.count,
    },
    {
      field: 'CreatedBy',
      headerName: 'Created by',
      colId: 'CreatedBy',
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 300,
      cellRenderer: cellRenderer.createdBy,
    },
    {
      field: 'createdAt',
      headerName: 'Created on',
      colId: 'createdAt',
      minWidth: 150,
      valueGetter: valueGetters.createdOn,
      cellRenderer: cellRenderer.createdOn,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Last updated',
      colId: 'updatedAt',
      minWidth: 150,
      valueGetter: valueGetters.lastUpdated,
      cellRenderer: cellRenderer.lastUpdated,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      width: 110,
      minWidth: 100,

      cellClass: 'disableRowClick',
      cellRenderer: (params) => cellRenderer.actionColumn(params, props),
    },
  ];
};

// Cell Renderers

export const cellRenderer = {
  folderName: (params) => {
    if (!params?.data) return;
    return <FolderName folder={params?.data} />;
  },
  count: (params) => {
    if (!params?.data) return;
    const { itemsCount } = params.data;
    return (
      <MainCnt>
        <Value>{itemsCount}</Value>
      </MainCnt>
    );
  },
  createdBy: (params) => {
    if (!params?.data) return;
    const { Creator } = params.data;
    return (
      <MainCnt>
        <UserAvatar
          width={24}
          height={24}
          firstName={Creator?.firstName}
          profilePic={Creator?.photo}
          userId={Creator?.id}
        />
        <Value style={{ marginLeft: 7 }}>
          {Creator?.firstName} {Creator?.lastName}
        </Value>
      </MainCnt>
    );
  },

  createdOn: (params) => {
    if (!params?.data) return;
    const { createdAt } = params.data;
    return (
      <MainCnt>
        <Value>{moment(createdAt).format('MM/DD/YY')}</Value>
      </MainCnt>
    );
  },

  lastUpdated: (params) => {
    if (!params?.data) return;
    const { updatedAt } = params.data;
    return (
      <MainCnt>
        <Value>{moment(updatedAt).format('MM/DD/YY')}</Value>
      </MainCnt>
    );
  },
  actionColumn: (params, props) => {
    if (!params?.data) return;
    return <FolderActions folder={params?.data} />;
  },
};

// Value Getters
export const valueGetters = {
  folderName: (params) => {
    const { title } = params.data;
    return title || '-';
  },
  count: (params) => {
    const { itemsCount } = params.data;
    return itemsCount;
  },
  createdBy: (params) => {
    const { CreatedBy } = params.data;
    return CreatedBy?.firstName || '-';
  },
  createdOn: (params) => {
    const { createdAt } = params.data;
    return createdAt || '-';
  },
  lastUpdated: (params) => {
    const { updatedAt } = params.data;
    return updatedAt || '-';
  },
};
