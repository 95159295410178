import { getMyProfile } from 'api/authApi';
import xeniaApi from 'api/index';
import { useListenForUserLoggedIn } from 'customhooks/index';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import {
  getChecklistCompleteList,
  initializeNewChecklist,
} from 'store/actions/checklistV2Actions';
import {
  setTeams,
  setAuthorizedTeams,
  setTeamsHash,
} from 'store/actions/teamActions';
import { setPaywallUsage } from 'store/actions/paywallActions';
import { setAssets, setAuthAssets } from 'store/actions/assetActions';
import { setLocations } from 'store/actions/locationActions';
import useTaskList from './useTasksList';
import { createUserIdentity } from 'utils/guide';
import useBento from 'utils/CustomHooks/useBento';
import { useSidebarConfig } from 'utils/CustomHooks/useSidebarConfig';
import { createHashFromLocations } from '../../utils/locations';
import useCustomizeLocationsHierarcy from './useCustomizeLocationsHierarcy';
import useTasksTemplates from './useTasksTemplates';
import { getProjects } from 'api/projectsApi';
import { getParentSchedules } from 'api/schedulesApi';
import useCustomRoles from './useCustomRoles';

export const getListParams = ({
  page = 1,
  pageSize = 10,
  searchText = '',
  sortType = 'DESC',
  sortBy = 'createdAt',
  type = 'ALL',
}) => {
  const params = {
    page,
    pageSize,
    searchText,
    sort: {
      [sortBy]: sortType,
    },
    ...(type != null && { type }),
  };

  return params;
};

export function useInitialDataLoad(workspaceId: string) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { getTasks } = useTaskList();
  const { updateBentoUser, updateBentoCategories } = useBento();
  const { handleLoadAndSyncConfig } = useSidebarConfig();
  const { getLocationsHierarcyInfo } = useCustomizeLocationsHierarcy();
  const { getTasksTemplates } = useTasksTemplates();
  const { fetchCustomRoles } = useCustomRoles();

  const getUserDetails = async () => {
    const data = await getMyProfile();

    // if token was verified we set the state.
    if (data?.user) {
      useListenForUserLoggedIn(dispatch);
      updateBentoUser(data?.user);
      createUserIdentity(data?.user);
      dispatch(actions.setLoggedInUser(data?.user));
    }
  };

  const getAllTeams = async () => {
    const teamsList = await xeniaApi.getTeams();
    const authTeamsList = await xeniaApi.getAuthorizedTeams();
    const authTeamsWithMembers = authTeamsList?.map((team) => {
      return {
        ...team,
        Members: teamsList?.find((t) => t.id === team.id)?.Members ?? [],
      };
    });
    const teamsHash = teamsList?.reduce((acc, team) => {
      return { ...acc, [team.id]: team };
    }, {});
    dispatch(setTeams(teamsList ?? []));
    dispatch(setAuthorizedTeams(authTeamsWithMembers ?? []));
    dispatch(setTeamsHash(teamsHash));
  };

  const getServiceList = () => {
    dispatch(
      actions.serviceListApiCall(
        getListParams({ sortBy: 'type', sortType: 'ASC' }),
        'task',
      ),
    );
  };

  const getChecklists = () => {
    dispatch(initializeNewChecklist());
    dispatch(
      getChecklistCompleteList({
        includeArchived: true,
      }),
    );
  };

  const getGoalsCategories = async () => {
    const categories = await xeniaApi.getChecklistCategories();
    updateBentoCategories(categories?.data);
    dispatch(actions.setChecklistCategories(categories));
  };

  const getUserList = async () => {
    dispatch(
      actions.userListApiCall(
        getListParams({ sortBy: 'firstName', sortType: 'ASC', type: 'ALL' }),
        'task',
      ),
    );
  };

  const getSubscriptionInfo = async () => {
    const subscriptionInfo = await xeniaApi.getSubscriptionInfo(workspaceId);
    dispatch(actions.setBillingInfo(subscriptionInfo.data));
  };

  const getStripeProducts = async () => {
    const stripeProducts = await xeniaApi.getStripeProducts(workspaceId);
    dispatch(actions.setStripeProducts(stripeProducts.data));
    dispatch(actions.setStripeProductsPaymentFrequency(stripeProducts.data));
  };

  const getLocationsCall = async () => {
    const locationsListRes = await xeniaApi.getLocations(null, {});

    const authLocationsRes = await xeniaApi.getAuthorizedLocations();

    const locationsHash = (locationsListRes || []).reduce((acc, location) => {
      const subLocations = location?.Sublocations ?? [];
      const subLocationsHash = createHashFromLocations(subLocations);
      return {
        ...acc,
        [location.id]: { ...location, Sublocations: subLocationsHash },
        ...subLocationsHash,
      };
    }, {});

    const authLocationsHash = (authLocationsRes || []).reduce(
      (acc, location) => {
        const subLocations = location?.Sublocations ?? [];
        const subLocationsHash = createHashFromLocations(subLocations);
        return {
          ...acc,
          [location.id]: { ...location, Sublocations: subLocationsHash },
          ...subLocationsHash,
        };
      },
      {},
    );

    dispatch(setLocations(locationsListRes));
    dispatch(actions.setAuthorizedLocations(authLocationsRes ?? []));
    dispatch(actions.setLocationsHash(locationsHash));
    dispatch(actions?.setAuthLocationsHash(authLocationsHash));
  };

  const getPaywallLimits = async () => {
    const paywallLimitsRes = await xeniaApi.getPaywallLimits();
    dispatch(setPaywallUsage(paywallLimitsRes));
  };

  const getAssetsCall = async () => {
    const assetsListRes = await xeniaApi.getAssets();
    dispatch(setAssets(assetsListRes));

    const authAssets = await xeniaApi.getAuthAssets();
    dispatch(setAuthAssets(authAssets));
  };

  const getWorkspaceConfigCall = async () => {
    const wsConfig = await xeniaApi.getWorkspaceConfigApi();
    dispatch(actions.setWorkspaceConfig(wsConfig));
  };

  const getLocationsLevelsCall = async () => {
    const levels = await getLocationsHierarcyInfo();
    dispatch(actions.setLocationsLevels(levels));
  };

  const getTaskTemplatesCall = async () => {
    await getTasksTemplates();
  };

  const getProjectsCall = async () => {
    const response = await getProjects({
      offset: 0,
      limit: 100000,
      detailed: false,
      all: true,
    });
    dispatch(actions.setProjects(response ?? []));
  };

  const getSchedules = async () => {
    const response = await getParentSchedules({
      offset: 0,
      limit: 100000,
      projects: false,
      detailed: false,
    });

    dispatch(actions.setSchedules(response?.rows ?? []));
  };

  const getSchedulesCall = async () => {
    await getSchedules();
  };

  const getRolesCall = () => {
    fetchCustomRoles({ payload: {}, forceLoad: true });
  };

  const loadData = () => {
    handleLoadAndSyncConfig();
    getGoalsCategories();
    getSubscriptionInfo();
    getStripeProducts();
    getAllTeams();
    getLocationsCall();
    getAssetsCall();
    getServiceList();
    getUserList();
    getUserDetails();
    getChecklists();
    getPaywallLimits();
    setIsLoading(false);
    // getTasks();
    getWorkspaceConfigCall();
    getLocationsLevelsCall();
    getTaskTemplatesCall();
    getProjectsCall();
    getSchedulesCall();
    getRolesCall();
  };

  return { loadData, isLoading, getLocationsCall };
}
