import { Stack, Typography } from '@mui/material';

import ChildItemIcon from 'components/Icons/childItemIcon';
import AssetsIcon from 'components/Icons/assetsIcon';

interface Props {
  type: 'sublocations' | 'assets' | 'addAsset';
  label: number | string;
  onClickAddAsset?: (event) => void;
}

const Chip = ({ type, label, onClickAddAsset }: Props) => {
  return (
    <Stack
      borderRadius={'60px'}
      padding={'4px 8px'}
      alignItems={'center'}
      gap={'6px'}
      direction={'row'}
      sx={{
        cursor: 'pointer',
        '& svg': {
          fontSize: '14px',
          color:
            type === 'addAsset'
              ? 'rgba(104, 104, 254, 1)'
              : 'rgba(117, 117, 117, 1)',
        },
        '&:hover': {
          background: type === 'addAsset' ? '#F5F5FF' : '#F5F5F5',
        },
      }}
      border={'1px solid #EEE'}
      onClick={onClickAddAsset}
    >
      {type === 'sublocations' && <ChildItemIcon />}
      {(type === 'assets' || type === 'addAsset') && (
        <AssetsIcon
          primary={
            type === 'addAsset'
              ? 'rgba(104, 104, 254, 1)'
              : 'rgba(117, 117, 117, 1)'
          }
        />
      )}
      <Typography
        fontSize={'12px'}
        fontWeight={'500'}
        color={type === 'addAsset' ? 'rgba(104, 104, 254, 1)' : '#212121'}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default Chip;
