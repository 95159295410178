import styled from '@mui/system/styled';

export const VideoThumbnail = styled('div')({
  flex: 0.5,
  position: 'relative',
  marginRight: 10,
  marginTop: 8,
  '& img': {
    borderRadius: 24,
  },
  '& .guideThumbnail': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  '& .youtubeIcon': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  },
});

export const BookCall = styled('div')({
  padding: '15px 28px 20px 22px',
  background: 'url(/assets/img/onboarding-logo.png) #EBEAFF no-repeat 100% 1px',
  backgroundSize: 'contain',
  gap: 10,
  borderRadius: 12,
  height: 'auto',
  minHeight: 134,
  marginBottom: 35,
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',

  '& .content': {
    flex: 1.5,

    '& .startedGuideBtn': {
      marginRight: 10,
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '26px',
      letterSpacing: '0.46px',
    },

    '& .bookCallBtn': {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '26px',
      letterSpacing: '0.46px',
    },
  },
  '& .removeIconBtn': {
    position: 'absolute',
    right: 6,
    top: 5,
  },

  '& .label': {
    fontWeight: 700,
    fontSize: 30,
    lineHeight: '41.99px',
    letterSpacing: '0.25px',
    color: '#212121',
    marginBottom: 10,
  },
  '& .subText': {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '27px',
    letterSpacing: '0.15px',
    color: '#212121',
    marginBottom: 30,
  },
});
