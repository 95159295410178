import { createSvgIcon } from '@mui/material';

const CalendarWithBlobIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M67.9588 39.2844C87.4221 38.9016 106.177 30.7552 121.928 19.5404C192.349 -39.0663 293.755 45.989 210.402 118.809C169.479 156.67 130.769 132.633 85.3233 130.528C-6.60259 130.1 -3.98707 66.894 8.57379 49.6444C21.3452 32.7456 49.2036 40.1009 67.9588 39.2844Z"
        fill="#6868FE"
      />
      <path
        opacity="0.9"
        d="M67.9588 39.2844C87.4221 38.9016 106.177 30.7552 121.928 19.5404C192.349 -39.0663 293.755 45.989 210.402 118.809C169.479 156.67 130.769 132.633 85.3233 130.528C-6.60259 130.1 -3.98707 66.894 8.57379 49.6444C21.3452 32.7456 49.2036 40.1009 67.9588 39.2844Z"
        fill="white"
      />
      <path
        d="M0 163.891H156.223L167.45 66.7027H11.234L0 163.891Z"
        fill="#6868FE"
      />
      <path
        opacity="0.4"
        d="M0 163.891H156.223L167.45 66.7027H11.234L0 163.891Z"
        fill="white"
      />
      <path
        d="M186.907 167.534H30.6845L11.234 66.7027H167.45L186.907 167.534Z"
        fill="#6868FE"
      />
      <path
        opacity="0.6"
        d="M186.907 167.534H30.6845L11.234 66.7027H167.45L186.907 167.534Z"
        fill="white"
      />
      <path
        d="M180.924 162.124H34.5759L18.8636 80.6542H165.211L180.924 162.124Z"
        fill="white"
      />
      <g opacity="0.5">
        <path
          d="M45.9693 100.411H30.6653L27.7117 85.1006H43.0157L45.9693 100.411Z"
          fill="#6868FE"
        />
        <path
          d="M161.033 100.411H145.723L142.775 85.1006H158.079L161.033 100.411Z"
          fill="#6868FE"
        />
        <path
          d="M141.85 100.411H126.546L123.593 85.1006H138.903L141.85 100.411Z"
          fill="#6868FE"
        />
        <path
          d="M122.674 100.411H107.37L104.417 85.1006H119.727L122.674 100.411Z"
          fill="#6868FE"
        />
        <path
          d="M103.498 100.411H88.194L85.2404 85.1006H100.544L103.498 100.411Z"
          fill="#6868FE"
        />
        <path
          d="M84.3217 100.411H69.0178L66.0641 85.1006H81.3681L84.3217 100.411Z"
          fill="#6868FE"
        />
        <path
          d="M65.1455 100.411H49.8416L46.8879 85.1006H62.1919L65.1455 100.411Z"
          fill="#6868FE"
        />
        <path
          opacity="0.5"
          d="M49.6502 119.498H34.3462L31.3926 104.194H46.6965L49.6502 119.498Z"
          fill="#6868FE"
        />
        <path
          d="M164.714 119.498H149.41L146.456 104.194H161.76L164.714 119.498Z"
          fill="#6868FE"
        />
        <path
          opacity="0.5"
          d="M145.538 119.498H130.234L127.28 104.194H142.584L145.538 119.498Z"
          fill="#6868FE"
        />
        <path
          d="M126.361 119.498H111.051L108.104 104.194H123.408L126.361 119.498Z"
          fill="#6868FE"
        />
        <path
          d="M107.179 119.498H91.8748L88.9212 104.194H104.232L107.179 119.498Z"
          fill="#6868FE"
        />
        <path
          d="M88.0026 119.498H72.6986L69.745 104.194H85.049L88.0026 119.498Z"
          fill="#6868FE"
        />
        <path
          d="M68.8264 119.498H53.5224L50.5688 104.194H65.8728L68.8264 119.498Z"
          fill="#6868FE"
        />
        <path
          d="M53.331 138.585H38.0271L35.0735 123.281H50.3774L53.331 138.585Z"
          fill="#6868FE"
        />
        <path
          d="M168.395 138.585H153.091L150.137 123.281H165.441L168.395 138.585Z"
          fill="#6868FE"
        />
        <path
          d="M149.218 138.585H133.915L130.961 123.281H146.265L149.218 138.585Z"
          fill="#6868FE"
        />
        <path
          d="M130.042 138.585H114.738L111.785 123.281H127.089L130.042 138.585Z"
          fill="#6868FE"
        />
        <path
          d="M110.866 138.585H95.5557L92.6085 123.281H107.912L110.866 138.585Z"
          fill="#6868FE"
        />
        <path
          d="M91.6835 138.585H76.3795L73.4259 123.281H88.7362L91.6835 138.585Z"
          fill="#6868FE"
        />
        <path
          d="M72.5072 138.585H57.2033L54.2497 123.281H69.5536L72.5072 138.585Z"
          fill="#6868FE"
        />
        <path
          d="M57.0119 157.678H41.7079L38.7543 142.368H54.0646L57.0119 157.678Z"
          fill="#6868FE"
        />
        <path
          d="M172.076 157.678H156.772L153.818 142.368H169.122L172.076 157.678Z"
          fill="#6868FE"
        />
        <path
          d="M152.899 157.678H137.595L134.642 142.368H149.946L152.899 157.678Z"
          fill="#6868FE"
        />
        <path
          d="M133.723 157.678H118.419L115.466 142.368H130.769L133.723 157.678Z"
          fill="#6868FE"
        />
        <path
          d="M114.547 157.678H99.2429L96.2893 142.368H111.593L114.547 157.678Z"
          fill="#6868FE"
        />
        <path
          d="M95.3707 157.678H80.0603L77.1131 142.368H92.4171L95.3707 157.678Z"
          fill="#6868FE"
        />
        <path
          opacity="0.5"
          d="M76.1945 157.678H60.8842L57.9369 142.368H73.2409L76.1945 157.678Z"
          fill="#6868FE"
        />
      </g>
      <path
        d="M30.4995 73.6242L30.3017 72.6099C32.1071 72.6099 33.115 69.9051 32.4962 66.7027C31.8774 63.5003 29.8296 60.7891 28.0307 60.7891C26.2317 60.7891 25.2174 63.5003 25.8298 66.7027H24.8219C24.0691 62.8177 25.396 59.7811 27.8329 59.7811C30.2698 59.7811 32.7705 62.8177 33.5169 66.7027C34.2633 70.5877 32.9364 73.6242 30.4995 73.6242Z"
        fill="#263238"
      />
      <path
        d="M45.6057 73.6242L45.4079 72.6099C47.2133 72.6099 48.2212 69.9051 47.6024 66.7027C46.9836 63.5003 44.9358 60.7891 43.1369 60.7891C41.3379 60.7891 40.3236 63.5003 40.936 66.7027H39.9281C39.1753 62.8177 40.5022 59.7811 42.9391 59.7811C45.376 59.7811 47.8767 62.8177 48.6231 66.7027C49.3695 70.5877 48.0426 73.6242 45.6057 73.6242Z"
        fill="#263238"
      />
      <path
        d="M60.7438 73.6242L60.5461 72.6099C62.3514 72.6099 63.3593 69.9051 62.7405 66.7027C62.1217 63.5003 60.074 60.7891 58.275 60.7891C56.4761 60.7891 55.4554 63.5003 56.0741 66.7027H55.0024C54.2497 62.8177 55.5766 59.7811 58.0135 59.7811C60.4504 59.7811 62.9511 62.8177 63.6974 66.7027C64.4438 70.5877 63.1488 73.6242 60.7438 73.6242Z"
        fill="#263238"
      />
      <path
        d="M75.8117 73.6242L75.6203 72.6099C77.4257 72.6099 78.4336 69.9051 77.8148 66.7027C77.196 63.5003 75.1483 60.7891 73.3493 60.7891C71.5503 60.7891 70.536 63.5003 71.1548 66.7027H70.1405C69.3941 62.8177 70.721 59.7811 73.1579 59.7811C75.5948 59.7811 78.0891 62.8177 78.8419 66.7027C79.5946 70.5877 78.255 73.6242 75.8117 73.6242Z"
        fill="#263238"
      />
      <path
        d="M90.9179 73.6242L90.7265 72.6099C92.5319 72.6099 93.5398 69.9051 92.921 66.7027C92.3022 63.5003 90.2545 60.7891 88.4555 60.7891C86.6565 60.7891 85.6422 63.5003 86.261 66.7027H85.2467C84.5003 62.8177 85.8272 59.7811 88.2641 59.7811C90.701 59.7811 93.1953 62.8177 93.9481 66.7027C94.7008 70.5877 93.3612 73.6242 90.9179 73.6242Z"
        fill="#263238"
      />
      <path
        d="M106.037 73.6242L105.846 72.6099C107.651 72.6099 108.659 69.9051 108.04 66.7027C107.421 63.5003 105.373 60.7891 103.574 60.7891C101.776 60.7891 100.761 63.5003 101.38 66.7027H100.366C99.6193 62.8177 100.94 59.7811 103.383 59.7811C105.826 59.7811 108.314 62.8177 109.067 66.7027C109.82 70.5877 108.467 73.6242 106.037 73.6242Z"
        fill="#263238"
      />
      <path
        d="M121.13 73.6242L120.939 72.6099C122.744 72.6099 123.752 69.9051 123.133 66.7027C122.515 63.5003 120.467 60.7891 118.668 60.7891C116.869 60.7891 115.855 63.5003 116.473 66.7027H115.459C114.713 62.8177 116.033 59.7811 118.477 59.7811C120.92 59.7811 123.408 62.8177 124.16 66.7027C124.913 70.5877 123.574 73.6242 121.13 73.6242Z"
        fill="#263238"
      />
      <path
        d="M136.237 73.6242L136.045 72.6099C137.851 72.6099 138.858 69.9051 138.24 66.7027C137.621 63.5003 135.573 60.7891 133.774 60.7891C131.975 60.7891 130.961 63.5003 131.58 66.7027H130.565C129.819 62.8177 131.14 59.7811 133.583 59.7811C136.026 59.7811 138.514 62.8177 139.267 66.7027C140.02 70.5877 138.673 73.6242 136.237 73.6242Z"
        fill="#263238"
      />
      <path
        d="M151.33 73.6242L151.139 72.6099C152.944 72.6099 153.952 69.9051 153.333 66.7027C152.714 63.5003 150.667 60.7891 148.868 60.7891C147.069 60.7891 146.054 63.5003 146.673 66.7027H145.659C144.912 62.8177 146.233 59.7811 148.676 59.7811C151.119 59.7811 153.607 62.8177 154.354 66.7027C155.1 70.5877 153.78 73.6242 151.33 73.6242Z"
        fill="#263238"
      />
      <path
        d="M86.0633 98.2548H32.4005L29.4469 82.9508H83.1097L86.0633 98.2548Z"
        fill="#6868FE"
      />
      <path
        d="M108.933 117.348H93.6228L90.6755 102.038H105.98L108.933 117.348Z"
        fill="#6868FE"
      />
      <path
        d="M156.816 136.945L155.987 136.224C154.285 134.734 152.39 133.479 150.354 132.493L150.883 130.93C152.78 131.847 154.565 132.98 156.204 134.304C159.029 130.114 162.729 126.586 167.049 123.963L168.191 125.52C163.793 128.192 160.07 131.839 157.307 136.18L156.816 136.945Z"
        fill="#6868FE"
      />
      <path
        d="M103.319 104.57H101.935L99.6193 108.761L95.696 104.57H94.3117L99.109 109.693L96.2829 114.809H97.6736L99.9829 110.624L103.906 114.809H105.297L100.493 109.693L103.319 104.57Z"
        fill="white"
      />
      <path
        d="M131.688 141.232L134.24 154.507H120.971L118.419 141.232H131.695M132.517 140.218H117.207L120.161 155.528H135.471L132.524 140.218H132.517Z"
        fill="#6868FE"
      />
      <path
        d="M93.425 136.435H58.9448L55.9912 121.131H90.4714L93.425 136.435Z"
        fill="#263238"
      />
      <path
        d="M69.6748 127.236C69.7667 127.592 69.7741 127.964 69.6964 128.323C69.6188 128.681 69.4582 129.017 69.2276 129.303C68.997 129.589 68.7027 129.817 68.3683 129.968C68.0339 130.12 67.6687 130.192 67.3017 130.177C66.4733 130.146 65.6788 129.84 65.0435 129.308C64.4082 128.775 63.9682 128.046 63.7931 127.236C63.7027 126.881 63.6963 126.509 63.7745 126.151C63.8527 125.792 64.0134 125.457 64.2437 125.172C64.474 124.886 64.7677 124.658 65.1014 124.506C65.4351 124.354 65.7997 124.282 66.1662 124.295C66.9943 124.327 67.7883 124.633 68.4234 125.166C69.0585 125.698 69.4987 126.426 69.6748 127.236Z"
        fill="white"
      />
      <path
        d="M70.3829 130.017C70.0584 130.468 69.628 130.833 69.1297 131.079C68.6314 131.324 68.0804 131.444 67.525 131.427C66.2588 131.397 65.0491 130.896 64.1312 130.024C63.5632 130.331 63.1068 130.81 62.8273 131.392C62.5477 131.974 62.4593 132.629 62.5747 133.264H73.1898C72.9978 132.546 72.6454 131.881 72.1593 131.319C71.6731 130.756 71.0658 130.311 70.3829 130.017Z"
        fill="white"
      />
      <path
        d="M87.6773 127.829H75.9393L75.614 126.139H87.3519L87.6773 127.829Z"
        fill="white"
      />
      <path
        d="M84.4429 131.421H76.6347L76.3093 129.73H84.1176L84.4429 131.421Z"
        fill="white"
      />
      <path
        d="M146.073 84.3798C149.097 84.3798 153.378 84.8199 156.612 86.9315C157.2 87.278 157.712 87.7388 158.118 88.2866C158.525 88.8345 158.817 89.4583 158.979 90.1211C159.547 92.6282 158.213 95.3649 156.74 96.8513C154.411 99.1989 150.788 100.679 146.533 100.972C146.048 101.011 145.55 101.03 145.059 101.03C142.063 101.003 139.098 100.415 136.319 99.2946C133.583 98.2037 130.68 96.7429 129.864 93.8977C129.65 93.2421 129.585 92.5475 129.672 91.8636C129.759 91.1798 129.997 90.5237 130.368 89.9425C130.849 89.2048 131.488 88.582 132.237 88.118C135.771 85.9319 139.799 84.6705 143.949 84.4499C144.657 84.4053 145.372 84.3798 146.073 84.3798ZM145.882 83.3973C145.123 83.3973 144.389 83.3973 143.7 83.4739C139.406 83.6998 135.238 85.0029 131.58 87.2632C130.739 87.7867 130.022 88.4878 129.481 89.3173C129.048 90.007 128.772 90.783 128.672 91.5909C128.571 92.3988 128.649 93.2189 128.9 93.9934C129.857 97.2979 133.13 99.0139 136.103 100.194C139.008 101.368 142.11 101.983 145.244 102.006C145.761 102.006 146.271 102.006 146.782 101.955C150.826 101.661 154.858 100.283 157.512 97.6041C160.165 94.9248 161.856 89.2408 157.039 86.1213C153.792 84.0225 149.544 83.3973 145.876 83.3973H145.882Z"
        fill="#263238"
      />
    </>,
    'CalendarWithBlobIcon',
  );
  return (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 242 168'} {...props} />
  );
};
export default CalendarWithBlobIcon;
