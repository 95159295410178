import { createSvgIcon } from '@mui/material';

const KeyIcon = (props) => {
  const IconCmp = createSvgIcon(
    <path
      d="M13.1667 6.49995C13.1667 6.07343 13.0039 5.64691 12.6785 5.32149C12.3531 4.99605 11.9265 4.83333 11.5 4.83333M11.5 11.5C14.2614 11.5 16.5 9.26142 16.5 6.5C16.5 3.73858 14.2614 1.5 11.5 1.5C8.73858 1.5 6.5 3.73858 6.5 6.5C6.5 6.72807 6.51527 6.95256 6.54484 7.17253C6.59348 7.53432 6.6178 7.71521 6.60143 7.82966C6.58438 7.94888 6.56267 8.01312 6.50391 8.11825C6.4475 8.21917 6.34809 8.31857 6.14928 8.51739L1.89052 12.7761C1.7464 12.9203 1.67433 12.9923 1.6228 13.0764C1.57711 13.151 1.54344 13.2323 1.52303 13.3173C1.5 13.4132 1.5 13.5151 1.5 13.719V15.1667C1.5 15.6334 1.5 15.8667 1.59083 16.045C1.67072 16.2018 1.79821 16.3293 1.95501 16.4092C2.13327 16.5 2.36662 16.5 2.83333 16.5H4.83333V14.8333H6.5V13.1667H8.16667L9.48261 11.8507C9.68143 11.6519 9.78083 11.5525 9.88175 11.4961C9.98688 11.4373 10.0511 11.4156 10.1703 11.3986C10.2848 11.3822 10.4657 11.4065 10.8275 11.4552C11.0474 11.4847 11.2719 11.5 11.5 11.5Z"
      stroke="#424242"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    'KeyIcon',
  );
  return (
    <IconCmp
      width="18"
      height="18"
      viewBox="0 0 18 18"
      style={{
        fill: 'none',
      }}
      {...props}
    />
  );
};
export default KeyIcon;
