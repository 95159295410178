import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DocumentsContext } from 'pages/documents/context/context';

import CustomListView from 'components/ListView/Listview.cmp';
import { columns, defaultColDef, rowHeight } from './constants';
import { ListCnt } from './list.style';
import { union } from 'lodash';
import { useParams } from 'react-router-dom';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function ListView(props) {
  const { folders, documents } = props;
  const { state = {}, updateState } = useContext(DocumentsContext);
  const {
    selectedFolder,
    filtersHeight,
    mainHeadHeight,
    breadCrumbHeight,
    isArchive,
  } = state;
  const [tableHeight, setTableHeight] = useState<any>(0);

  const colConfig = useMemo(() => columns({}), []);
  const { params } = useParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const handleFolderSelect = (folder) => {
    updateState({ loading: true, selectedFolder: folder });
    if (isArchive) {
      navigateWithWorkspaceUrl(
        params?.['*']
          ? `/documents/archive/folder/${params?.['*'] + '/' + folder?.id}`
          : `/documents/archive/folder/${folder?.id}`,
      );
    } else {
      navigateWithWorkspaceUrl(
        params?.['*']
          ? `/documents/my-documents/folder/${params?.['*'] + '/' + folder?.id}`
          : `/documents/my-documents/folder/${folder?.id}`,
      );
    }
  };

  const onRowClick = (id) => {
    const clickedRow = filteredData?.find((item) => item?.id === id);

    if (!clickedRow?.fileType) {
      handleFolderSelect(clickedRow);
    } else {
      if (isArchive) return;

      const isSop = clickedRow?.fileType === 'sop';

      const queryParams = isSop
        ? !clickedRow?.FolderId
          ? '?source=list'
          : `?source=list&folderId=${clickedRow?.FolderId}`
        : '';

      const urlTab = `${window.location.origin}/document-detail/${clickedRow?.id}${queryParams}`;
      window.open(urlTab, isSop ? '_self' : '_blank');
    }
  };

  const getRowHeight = useCallback(() => {
    return rowHeight;
  }, []);

  const filteredData = useMemo(() => {
    return union(folders, documents) as any[];
  }, [folders, documents, selectedFolder]);

  const onGridResize = (event) => {
    event.api.sizeColumnsToFit();
  };

  useEffect(() => {
    const appHeadHeight =
      document.getElementsByClassName('main-header')?.[0]?.clientHeight || 0;
    const tabsHeight =
      document.getElementsByClassName('MuiTabs-root')?.[0]?.clientHeight || 0;
    const topAlertHeight =
      document.getElementById('topAlert')?.clientHeight || 0;

    const padding = topAlertHeight ? 85 : 65;

    setTableHeight(
      `calc(100vh - ${
        filtersHeight +
        mainHeadHeight +
        breadCrumbHeight +
        appHeadHeight +
        tabsHeight +
        topAlertHeight +
        padding
      }px)`,
    );
  }, [filtersHeight, mainHeadHeight, breadCrumbHeight]);

  return (
    <ListCnt>
      <CustomListView
        rootProps={{
          style: {
            height: tableHeight,
            boxShadow: 'none',
            border: '1px solid #d3d3d382',
            borderRadius: 8,
            overflow: 'hidden',
          },
        }}
        onRowClick={onRowClick}
        gridProps={{
          rowData: filteredData,
          ref: (ref) => {
            globalThis.documentsListView = ref;
          },
          onGridSizeChanged: onGridResize,
          headerHeight: 30,
          suppressChangeDetection: true,
          defaultColDef: defaultColDef,
          columnDefs: colConfig,
          suppressCellFocus: true,

          getRowHeight: 100,
          suppressRowClickSelection: true,
          getRowId: (params) => params.data.id,
        }}
      />
    </ListCnt>
  );
}

export default ListView;
