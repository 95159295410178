import styled from '@mui/system/styled';
export const BulkActionCnt = styled('ul')({
  background: '#ffffff',
  borderRadius: 8,
  width: 175,
  overflow: 'hidden',
  display: 'flex',
  boxShadow:
    '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
  position: 'absolute',
  bottom: 45,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 111,
});

export const BulkActionSelection = styled('li')({
  background: '#6868FE',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 8,
  '.count': {
    fontWeight: 600,
    fontSize: 16,
    color: '#fff',
  },
  '.selectedText': {
    fontWeight: 500,
    fontSize: 10,
    color: '#fff',
  },
});
export const BulkActionItem = styled('li')({
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px 14px',
  '&:hover': {
    cursor: 'pointer',
    background: '#EBEAFF',
    transition: 'ease background 0.2s',
  },
  '.actionIcon': {
    fontSize: 22,
    color: '#0000008A',
    marginBottom: 6,
  },
  '.actionText': {
    fontWeight: 500,
    fontSize: 10,
    color: '#0000008A',
    margin: 0,
  },
});
export const BulkActionCancelItem = styled('li')({
  background: '#0000000A',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  '&:hover': {
    cursor: 'pointer',
  },

  svg: {
    fontSize: 24,
    color: '#00000099',
  },
});
