import React, { FC, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { Grid, IconButton, Typography } from '@mui/material';
import AutomationsPreview from 'components/common/jsxrender/checklistV2/builder/automations/automationsPreview';
import CustomButton from 'components/Button/CustomButton';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';

interface IProps {
  onEdit(): void;
  onDelete(ids: string[]): void;
  automations: any[];
  field: any;
  fetchAndSetChecklistAutomations: () => void;
}

const Automations: FC<IProps> = (props) => {
  const {
    field,
    automations,
    onEdit,
    onDelete,
    fetchAndSetChecklistAutomations,
  } = props;

  const onAutomationsDelete = useCallback(
    (automationId) => {
      onDelete([automationId]);
    },
    [automations],
  );

  return (
    <Grid item xs={12}>
      <Stack direction={'row'} width="100%" gap={'8px'}>
        <Typography mt="4px" fontSize="12px" fontWeight={700}>
          Automations:
        </Typography>
        <Stack flex={1} gap="8px">
          {automations.map((automation) => {
            return (
              <Stack
                sx={{ background: '#EBEAFF' }}
                paddingY={'5px'}
                paddingX={'16px'}
                borderRadius={'8px'}
                alignItems="center"
                direction={'row'}
                flex={1}
                justifyContent={'space-between'}
              >
                <AutomationsPreview
                  onEdit={onEdit}
                  automation={automation}
                  field={field}
                  fetchAndSetChecklistAutomations={
                    fetchAndSetChecklistAutomations
                  }
                />
                <Stack gap="8px" alignItems="center" direction={'row'}>
                  <IconButton size="small" onClick={onEdit}>
                    <EditIconPencilBottomLine sx={{ fontSize: '18px' }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => onAutomationsDelete(automation.id)}
                  >
                    <DeleteIcon
                      style={{
                        fontSize: '18px',
                        color: 'rgba(211, 47, 47, 1)',
                      }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default Automations;
