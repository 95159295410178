// Styled
import { Container, Heading, DetailText } from './styled';

// Icons
import ManWithChecklistIcon from 'components/Icons/manWithChecklistIcon';

const EmptyState = () => {
  return (
    <Container>
      <ManWithChecklistIcon
        style={{ height: '112px', width: 'auto', marginBottom: '18px' }}
      />
      <Heading>Nothing to see here...yet</Heading>
      <DetailText>All Completed scheduled work will appear here</DetailText>
    </Container>
  );
};

export default EmptyState;
