import { createSvgIcon } from '@mui/material';

const PassFailIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.38481 0.847469C7.55827 0.833296 7.76311 0.833321 7.97342 0.833346L12.0266 0.833346C12.2369 0.833321 12.4417 0.833296 12.6152 0.847469C12.8057 0.863033 13.0305 0.899756 13.2567 1.015C13.5703 1.17479 13.8252 1.42976 13.985 1.74336C14.1003 1.96955 14.137 2.19433 14.1525 2.38482C14.1557 2.42396 14.1582 2.46469 14.1601 2.50672C14.4895 2.51735 14.7715 2.54455 15.0295 2.61367C16.1798 2.92189 17.0783 3.82039 17.3865 4.97069C17.5007 5.39707 17.5005 5.88929 17.5001 6.55211L17.5001 14.3679C17.5001 15.0387 17.5001 15.5923 17.4633 16.0433C17.425 16.5117 17.3428 16.9423 17.1368 17.3467C16.8172 17.9739 16.3073 18.4839 15.68 18.8034C15.2756 19.0095 14.845 19.0917 14.3766 19.1299C13.9256 19.1668 13.372 19.1668 12.7012 19.1668H7.29898C6.62818 19.1668 6.07455 19.1668 5.62356 19.1299C5.15514 19.0917 4.72456 19.0095 4.32011 18.8034C3.69291 18.4839 3.18297 17.9739 2.86339 17.3467C2.65732 16.9423 2.57518 16.5117 2.53691 16.0433C2.50006 15.5923 2.50007 15.0386 2.50008 14.3678L2.50004 6.55211C2.49968 5.88929 2.49941 5.39707 2.61366 4.97069C2.92188 3.82039 3.82038 2.92189 4.97068 2.61367C5.22859 2.54456 5.51059 2.51736 5.83987 2.50672C5.84179 2.46469 5.84426 2.42396 5.84746 2.38482C5.86302 2.19433 5.89975 1.96955 6.01499 1.74336C6.17478 1.42976 6.42975 1.17479 6.74335 1.015C6.96954 0.899756 7.19432 0.863033 7.38481 0.847469ZM7.59083 2.95502C7.5 3.13328 7.5 3.36663 7.5 3.83334V4.16668H12.5V3.83334C12.5 3.36663 12.5 3.13328 12.4092 2.95502C12.3293 2.79822 12.2018 2.67073 12.045 2.59084C11.8667 2.50001 11.6334 2.50001 11.1667 2.50001H8.83333C8.36662 2.50001 8.13327 2.50001 7.95501 2.59084C7.79821 2.67073 7.67072 2.79822 7.59083 2.95502ZM13.5059 11.0059C13.8314 10.6805 13.8314 10.1529 13.5059 9.82742C13.1805 9.50198 12.6528 9.50198 12.3274 9.82742L9.16667 12.9882L8.08926 11.9108C7.76382 11.5853 7.23618 11.5853 6.91074 11.9108C6.58531 12.2362 6.58531 12.7638 6.91074 13.0893L8.57741 14.7559C8.90285 15.0814 9.43049 15.0814 9.75592 14.7559L13.5059 11.0059Z"
        fill="#03A9F4"
      />
    </>,
    'PassFailIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default PassFailIcon;
