import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CTabs from 'components/CTabs';
import CustomModal from 'components/CustomModal';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import { colors } from 'components/theme/constants';
import SendBtnIcon from 'components/Icons/sendBtnIcon';
import BulkInviteByEmailPhoneForm from 'components/BulkInvite/bulkInviteByEmailPhoneForm';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';

import { InviteUserDiv, CustomActionsCnt } from './styles';

const InviteUsersModal = (props: any) => {
  const dialogState = useSelector(selectors.getDialogState);
  const [buttonIsSubmitting, setButtonIsSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const hotelId = useSelector(selectors.getUserHotelId);

  const [selected, setSelected] = useState(0);

  // Dispatch to close modal
  const handleClose = () => {
    dispatch(
      setDialog({
        dialogId: 'inviteUserDialog',
        open: false,
      }),
    );
    setSelected(0);
  };

  const onInviteSuccess = () => {
    dialogState?.[props.dialogId]?.data?.onSuccessCallback();
    handleClose();
  };

  // Bulk Invite Reusable Component
  const customActions = (
    <CustomActionsCnt>
      <CustomButton
        variant="text"
        type="button"
        fullWidth
        className="cancelBtn"
        onClick={handleClose}
      >
        Cancel
      </CustomButton>
      <CustomButton
        variant="contained"
        fullWidth
        className="inviteBtn"
        type="submit"
        buttonLoader={buttonIsSubmitting}
        endIcon={<SendBtnIcon color={colors.white} />}
      >
        Send Invite
      </CustomButton>
    </CustomActionsCnt>
  );

  const inviteForm = (type: string, inviteBy: string) => (
    <BulkInviteByEmailPhoneForm
      dialogId="BulkInviteUsers"
      setButtonIsSubmitting={setButtonIsSubmitting}
      showSubmitButton
      hotelId={hotelId?.toString()}
      onSuccess={onInviteSuccess}
      customActions={customActions}
      type={type}
      inviteBy={inviteBy}
    />
  );

  const inviteUsersTabs = [
    {
      label: 'Invite By Email',
      content: <div>{inviteForm('Email', 'email')}</div>,
    },
    {
      label: 'Invite By Phone Number',
      content: <div>{inviteForm('Phone Number', 'phoneNumber')}</div>,
    },
    {
      label: 'Invite By WhatsApp',
      content: <div>{inviteForm('WhatsApp Number', 'whatsApp')}</div>,
    },
  ];

  return (
    <CustomModal
      open={dialogState?.[props.dialogId]?.open}
      handleClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: 900,
          },
        },
      }}
    >
      <InviteUserDiv>
        <CTypography className="title">Invite Users</CTypography>
        <CTypography className="subTitle">
          New team members will gain access to Tasks & Work Orders, Templates
          and Chats.
        </CTypography>

        <div>
          <div className={'cardContent'}>
            <CTabs
              data={inviteUsersTabs}
              callBack={(newValue) => {
                setSelected(newValue);
              }}
              labelProps={{
                style: {
                  textTransform: 'capitalize',
                },
              }}
            />
          </div>
        </div>
      </InviteUserDiv>
    </CustomModal>
  );
};

export default InviteUsersModal;
