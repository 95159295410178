import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getOnboardingStatus = async (): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/hotels/${hotelId}/onboarding-status`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
