/* eslint-disable max-len */
import React, { FC } from 'react';
import LoaderXenia from 'public/assets/img/loader-xenia.png';

const XeniaLogo: FC<any> = () => (
  <div
    className={`xenia-logo splash-center`}
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    <img src={LoaderXenia} className="xenia-logo-blink" width={400} />
  </div>
);

export default XeniaLogo;
