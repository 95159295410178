import React from 'react';

import NoRecordsFound from 'components/Icons/noRecordFoundIcon';

import { EmptyStateWrapper, CenterColumn, BoldText, GreyText } from './styled';

const EmptySearch = () => {
  return (
    <EmptyStateWrapper>
      <NoRecordsFound style={{ width: 307, height: 231 }} />
      <CenterColumn>
        <BoldText>No results found</BoldText>
        <GreyText>
          We couldn’t find what you’re looking for. Please try different
          criteria.
        </GreyText>
      </CenterColumn>
    </EmptyStateWrapper>
  );
};

export default EmptySearch;
