import UserAvatar from 'components/Avatar';
import ChecklistIcon from 'components/ChecklistIcon';
import { useState, useMemo } from 'react';

import ActionDropdown from 'components/Dropdown/ActionDropdown';
import { Stack, Typography } from '@mui/material';
import { Chip } from './styled';

import ReportingIcon from 'components/Icons/ReportingIcon';
import DownloadIcon from 'components/Icons/downloadIcon';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import GlobeIcon from 'components/Icons/globeIcon';

import useDateTime from 'utils/CustomHooks/useDateTime';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useLogActions from 'pages/TemplateDashboard/Tabs/Submissions/hooks/useLogActions';
import { AnonymousAvatar } from 'components/ChecklistPreview/styled';

interface IProps {
  submission: any;
}

const TemplateSubmissionTile = ({ submission: sub }: IProps) => {
  const { getDateInTz, getUtcToTzTime } = useDateTime();
  const { workspaceId } = useWorkspaceHook();
  const { handleDownloadReportApi } = useLogActions();
  const [downloading, setDownloading] = useState(false);

  const completedBy = useMemo(() => {
    if (sub?.anonymousId) {
      if (sub.submitterName) return sub.submitterName;
      if (sub.submitterEmail) return sub.submitterEmail;
      return 'Anonymous';
    } else {
      return sub?.Submitter?.fullName;
    }
  }, [sub]);

  const dropdownOptions = [
    {
      label: 'View Report',
      id: 'viewReport',
      icon: <ReportingIcon />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
    },
    {
      label: 'Download PDF',
      id: 'download',
      icon: <DownloadIcon />,
      iconAlign: 'start',
    },
  ];

  const handleDownloadReport = async () => {
    setDownloading(true);
    if (downloading) return;
    await handleDownloadReportApi({
      logIds: [sub.id],
      option: 'pdf',
      templateId: sub.ChecklistId,
    });
    setDownloading(false);
  };

  const handleClickDropdownOption = (option: any) => {
    switch (option.id) {
      case 'viewReport':
        window.open(
          `/ws/${workspaceId}/checklistlogs/${sub.id}/report`,
          '_blank',
        );
        break;
      case 'download':
        handleDownloadReport();
        break;
      default:
        break;
    }
  };

  return (
    <Stack
      key={sub?.id}
      borderRadius={'12px'}
      bgcolor={'#fff'}
      p="16px"
      gap="8px"
      boxShadow={'0px 2px 14px 0px rgba(0, 0, 0, 0.08)'}
      width={'270px'}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          border: '1px solid rgba(104, 104, 254, 1)',
          padding: '15px',
        },
      }}
    >
      <Stack
        justifyContent={'space-between'}
        alignItems={'center'}
        direction="row"
      >
        <ChecklistIcon icon={sub?.icon} />
        <ActionDropdown
          handleOptionSelect={handleClickDropdownOption}
          options={dropdownOptions}
          popperProps={{
            placement: 'bottom-end',
          }}
        >
          <MoreHoriz />
        </ActionDropdown>
      </Stack>
      <Typography
        fontWeight={'700'}
        fontSize={'13px'}
        letterSpacing={'-0.25px'}
        color="#212121"
      >
        {sub?.name}
      </Typography>
      <Stack direction={'row'} gap={'4px'}>
        <Chip>
          {sub?.progress?.completedCount} of {sub?.progress?.count} items
        </Chip>
        {sub?.Checklist?.isScoring && (
          <Chip>
            Score: {sub?.score?.percentage}% ({sub?.score?.earned} of{' '}
            {sub?.score?.total})
          </Chip>
        )}
      </Stack>
      <Stack gap="8px" direction="row" alignItems={'center'}>
        {sub.anonymousId && (
          <AnonymousAvatar>
            <GlobeIcon style={{ color: '#fff', fontSize: '16px' }} />
          </AnonymousAvatar>
        )}
        {!sub.anonymousId && (
          <UserAvatar width={20} height={20} userId={sub.Submitter.id} />
        )}
        <Stack>
          <Typography
            fontSize={'11px'}
            fontWeight={'600'}
            color={'rgba(0,0,0,0.87)'}
          >
            Completed by {completedBy}
          </Typography>
          <Typography fontSize={'10px'} fontWeight={'500'} color={'#212121'}>
            {getDateInTz(sub?.completedAt, 'MMM DD YYYY')} |{' '}
            {getUtcToTzTime(sub?.completedAt, 'hh:mm A')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TemplateSubmissionTile;
