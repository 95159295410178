import { styled } from '@mui/material/styles';
import CustomButton from 'components/Button/CustomButton';

export const TalkToSalesButton = styled(CustomButton)({
  borderRadius: '8px',
  padding: '8px 22px',
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.4px',
});
