import TaskDashboardImage from 'public/assets/img/task-dashboard.svg';
import TemplateSubmissionDashboard from 'public/assets/img/template-submission-dashboard.svg';
import ScheduleReportDashboard from 'public/assets/img/schedule-report.svg';
import LocationDashboard from 'public/assets/img/location-dashboard.svg';
import ScoreDashboard from 'public/assets/img/score-dashboard.svg';

import { ImgBox } from './templateItem.style';
import TaskIcon from 'components/Icons/taskIcon';
import ScheduledIcon from 'components/Icons/sidebarIcons/scheduledIcon';
import RepeatIcon from 'components/Icons/repeatIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import MarkCompletedIcon from 'components/Icons/markCompletedIcon';
import RepeatScheduleIcon from 'components/Icons/repeatScheduleIcon';
import TickInsideSquareIcon from 'components/Icons/tickInsideSquareIcon';

const TaskSummaryImage = () => {
  return (
    <ImgBox
      sx={{
        backgroundColor: '#E8F5E9',
      }}
    >
      <TaskIcon secondary={'white'} primary={'#388E3C'} />
    </ImgBox>
  );
};

const ScheduledReportImage = () => {
  return (
    <ImgBox
      sx={{
        backgroundColor: '#EBEAFF',
        '& path': {
          stroke: '#4032E1',
        },
      }}
    >
      <ScheduledIcon />
    </ImgBox>
  );
};

const EmployeeProductivityImage = () => {
  return (
    <ImgBox
      sx={{
        backgroundColor: '#E1F5FE',
        '& path': {
          stroke: '#0288D1',
        },
      }}
    >
      <UsersAndTeamsIcon secondary={'white'} primary={'#388E3C'} />
    </ImgBox>
  );
};

const CompletedWorkImage = () => {
  return (
    <ImgBox
      sx={{
        backgroundColor: '#E8F5E9',
        '& path': {
          fill: '#43A047',
        },
      }}
    >
      <TickInsideSquareIcon secondary={'white'} primary={'#388E3C'} />
    </ImgBox>
  );
};

const TemplateSubmissionsImage = () => {
  return (
    <ImgBox
      sx={{
        backgroundColor: '#EDE7F6',
        '& path': {
          stroke: '#512DA8',
        },
      }}
    >
      <MarkCompletedIcon secondary={'white'} primary={'#388E3C'} />
    </ImgBox>
  );
};

export const dashboardImages = {
  'task-summary': <TaskSummaryImage />,
  'schedule-report': <ScheduledReportImage />,
  'employee-productivity': <EmployeeProductivityImage />,
  'completed-work-report': <CompletedWorkImage />,
  'template-submission': <TemplateSubmissionsImage />,
  'template-submissions': <TemplateSubmissionsImage />,
};
