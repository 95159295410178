import React, { useCallback } from 'react';
import { QRControlWithLabel } from './assetDetailTab.style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import QRCodeContainerView from 'components/QRCode/contianer';
import QrCodeIcon from '@mui/icons-material/QrCode2';
import CustomButton from 'components/Button/CustomButton';

import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

interface IProps {
  asset: any;
  handleAddQR?: () => void;
}

const AssetQRcode = (props: IProps) => {
  const { asset, handleAddQR } = props;
  const { isQREnable } = asset;
  const { workspaceId } = useWorkspaceHook();
  const { FEATURES } = useCustomPermission();

  const dispatch = useDispatch();
  const url = asset.ParentId
    ? `${window.location.origin}/ws/${workspaceId}/assets/${asset.ParentId}/sa/${asset.id}`
    : `${window.location.origin}/ws/${workspaceId}/assets/${asset.id}`;

  const openQRDialog = useCallback(() => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.QR_CODE,
        open: true,
        data: {
          qrText: url,
          headerText: 'View Asset from QR Code',
          subText:
            'Download / print this QR code to allow Users to scan and access details about this Asset via the Mobile App.',
        },
      }),
    );
  }, [dispatch, url]);

  return (
    <QRControlWithLabel>
      <label className="title">QR Code</label>

      {isQREnable ? (
        <QRCodeContainerView
          headerText={asset?.name}
          subText={'Created in Xenia'}
          qrText={url}
          handleView={openQRDialog}
        />
      ) : (
        <VerifyFeatureAccess featureId={FEATURES.SCAN_QR_CODE}>
          <CustomButton
            variant="outlined"
            startIcon={<QrCodeIcon />}
            onClick={handleAddQR}
          >
            Add QR Code
          </CustomButton>
        </VerifyFeatureAccess>
      )}
    </QRControlWithLabel>
  );
};

export default AssetQRcode;
