import { createSvgIcon } from '@mui/material';

const PhoneIconOutlined = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M6.28521 7.03992C6.80721 8.12713 7.5188 9.14611 8.41998 10.0473C9.32116 10.9485 10.3401 11.6601 11.4274 12.1821C11.5209 12.227 11.5676 12.2494 11.6268 12.2667C11.8371 12.328 12.0953 12.2839 12.2733 12.1564C12.3234 12.1205 12.3663 12.0777 12.452 11.9919C12.7143 11.7297 12.8454 11.5986 12.9772 11.5129C13.4744 11.1897 14.1153 11.1897 14.6125 11.5129C14.7443 11.5986 14.8754 11.7297 15.1376 11.9919L15.2838 12.1381C15.6823 12.5367 15.8816 12.736 15.9899 12.95C16.2052 13.3757 16.2052 13.8784 15.9899 14.304C15.8816 14.5181 15.6823 14.7174 15.2838 15.116L15.1655 15.2342C14.7683 15.6314 14.5697 15.83 14.2997 15.9817C14 16.15 13.5347 16.271 13.191 16.27C12.8813 16.2691 12.6696 16.209 12.2463 16.0888C9.97125 15.4431 7.82448 14.2248 6.0335 12.4338C4.24251 10.6428 3.02415 8.49603 2.37843 6.22098C2.25827 5.79764 2.19819 5.58598 2.19727 5.27627C2.19625 4.9326 2.31727 4.46723 2.48559 4.1676C2.63727 3.89758 2.83588 3.69897 3.2331 3.30175L3.35132 3.18353C3.74991 2.78494 3.9492 2.58565 4.16323 2.4774C4.5889 2.26209 5.0916 2.26209 5.51727 2.47739C5.73131 2.58565 5.9306 2.78494 6.32918 3.18353L6.47533 3.32968C6.73754 3.59188 6.86864 3.72298 6.95436 3.85482C7.2776 4.35199 7.2776 4.99292 6.95435 5.49009C6.86864 5.62192 6.73754 5.75303 6.47533 6.01523C6.3896 6.10096 6.34673 6.14383 6.31085 6.19394C6.18334 6.37201 6.13932 6.63021 6.20061 6.84047C6.21786 6.89964 6.24031 6.9464 6.28521 7.03992Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'PhoneIconOutlined',
  );
  return <IconCmp viewBox={'0 0 18 19'} style={{ fill: 'none' }} {...props} />;
};
export default PhoneIconOutlined;
