// Task Template
import styled from '@mui/system/styled';
import { Box } from '@mui/material';

export const TemplateContainer = styled(Box)({
  paddingLeft: '16px',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '& p': {
    marginLeft: 8,
    fontWeight: 400,
    fontSize: 14,
    color: '#212121',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
      transition: 'ease all 0.2s',
      fontWeight: 700,
    },
  },
});

export const TaskIconCnt = styled(Box)(() => ({
  '& svg': {
    backgroundColor: '#BA68C8',
    borderRadius: 4,
    padding: 4,
    '& path': {
      stroke: 'white',
    },
  },
}));
