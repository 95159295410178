// React
import { useMemo } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Custom components
import UserAvatar from 'components/Avatar';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { CategoryChip } from 'pages/Schedules/common/components';

// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import RepeatIconMultiColor from 'components/Icons/repeatIconMultiColor';
import LocationIcon from 'components/Icons/locationIcon';
import ChecklistIcon from 'components/Icons/checklistIcon';
import CalendarIconRounded from 'components/Icons/calendarIconRounded';
import AssetsIcon from 'components/Icons/assetsIcon';
import MissedIcon from 'components/Icons/missedIcon';

// Styled
import {
  InfoChip,
  ScheduleItemCnt,
  LeftBorder,
  CreatedByWrapper,
} from './styled';

// Hooks
import { useSchedule } from 'pages/Schedules/common/useSchedule';
import { Stack, Typography } from '@mui/material';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import IosSwitch from 'components/IosSwitch';
import DeleteIcon from 'components/Icons/deleteIcon';
import { colors } from 'components/theme/constants';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { togglePause } from 'api/schedulesApi';
import { archiveSeries } from 'api/tasksApi';
import AssigneesView from 'components/AssigneesView/AssigneesView';

interface MyScheduleItemPropTypes {
  task: any;
  hideLeftBar?: boolean;
  onClick?: (task: any) => void;
  fetchDataCallback?: () => void;
  showActions?: boolean;
}

function ScheduleGridItem({
  task,
  hideLeftBar = false,
  onClick,
  fetchDataCallback,
  showActions,
}: MyScheduleItemPropTypes) {
  const {
    taskTemplate,
    taskAssignee,
    isTaskOverdue,
    taskInterval,
    isTaskMissed,
    getFormattedDates,
  } = useSchedule(task);

  const dispatch = useDispatch();
  const formattedDates = useMemo(() => getFormattedDates(), [task]);

  const DeleteScheduleTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to delete this schedule ?
      </Typography>
    );
  };

  const actionsDropdownHandler = async (options) => {
    switch (options?.id) {
      case 'delete':
        dispatch(
          setDialog({
            dialogId: 'confirmationDialog',
            open: true,
            data: {
              title: 'Delete Project Schedule',
              ContentTemplate: DeleteScheduleTemplate,
              onConfirmCallback: async () => {
                const result = await archiveSeries(task?.taskUniqueId);
                if (result) {
                  fetchDataCallback?.();
                }
              },
              confirmationText: 'Delete Schedule',
            },
          }),
        );
        break;

      default:
        return null;
    }
  };

  const PauseScheduleTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to pause this schedule ?
      </Typography>
    );
  };

  const schedulePauseHandler = async () => {
    if (!task?.isPaused) {
      dispatch(
        setDialog({
          dialogId: 'confirmationDialog',
          open: true,
          data: {
            title: 'Pause Schedule',
            ContentTemplate: PauseScheduleTemplate,
            onConfirmCallback: async () => {
              const result = await togglePause(task?.taskUniqueId);
              if (result) {
                fetchDataCallback?.();
              }
            },
            confirmationText: 'Pause Schedule',
          },
        }),
      );
      return;
    }

    const result = await togglePause(task?.parentSeriesId);
    if (result) {
      fetchDataCallback?.();
    }
  };

  return (
    <ScheduleItemCnt
      clickable={onClick ? 'true' : ''}
      onClick={() => onClick?.(task)}
    >
      {!hideLeftBar && <LeftBorder status={task.taskStatus} />}
      <div className={'header'}>
        <div className={'column'} style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <h3 className={'title'}>{task.title}</h3>
              {/* Has no due date and is not completed yet */}
              {!task.Finisher && !task.dueDate && (
                <CreatedByWrapper>
                  <span className={'createdBy'}>Created by:</span>
                  <div className={'user'}>
                    <UserAvatar
                      width={16}
                      height={16}
                      userId={task.Creator?.id}
                    />
                    <span className={'createdBy'}>
                      {task.Creator?.fullName}
                    </span>
                  </div>
                </CreatedByWrapper>
              )}
              {/* Has not been completed and not overdue */}
              {!task.Finisher && task.dueDate && !isTaskOverdue && (
                <div className={'dueOrcompleted'}>
                  <CalendarIconRounded />
                  Due by ({formattedDates.dueDate})
                </div>
              )}
              {/* Has been completed */}
              {task.Finisher && (
                <div className={'dueOrcompleted'}>
                  <CalendarIconRounded />
                  Completed on {formattedDates.completedOn}
                </div>
              )}
              {/* Is not finished but is overdue */}
              {isTaskOverdue && !isTaskMissed && (
                <div className={'overdue'}>
                  <AccessTimeRoundedIcon sx={{ fontSize: 16 }} />
                  Overdue ({formattedDates.dueDate})
                </div>
              )}
              {isTaskMissed && (
                <div className={'overdue'}>
                  <MissedIcon sx={{ fontSize: 16 }} />
                  Missed ({formattedDates.dueDate})
                </div>
              )}
            </div>
            {showActions && (
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                className={'right'}
              >
                <IosSwitch
                  height={20}
                  width={32}
                  checked={!task?.isPaused}
                  onChange={schedulePauseHandler}
                  onClick={(e) => e.stopPropagation()}
                  thumbSize={16}
                  sx={{ marginLeft: 2 }}
                />

                <ActionDropdown
                  paperProps={{
                    style: {
                      minWidth: 145,
                    },
                  }}
                  options={[
                    {
                      label: 'Delete',
                      id: 'delete',
                      icon: (
                        <DeleteIcon
                          style={{ color: colors.red, fill: 'none' }}
                        />
                      ),
                      iconAlign: 'start',
                      sx: {
                        color: `${colors.red}`,
                      },
                    },
                  ]}
                  handleOptionSelect={actionsDropdownHandler}
                >
                  <MoreHorizIcon />
                </ActionDropdown>
              </Stack>
            )}
          </Stack>
        </div>
        {task.Finisher && (
          <InfoChip>
            <span className={'infoChipIcon'}>
              <CheckCircleIcon
                sx={{ fontSize: 14, color: 'rgba(4, 184, 108, 1)' }}
              />
            </span>
            <span className={'infoChipText'}>{task.Finisher.fullName}</span>
          </InfoChip>
        )}
      </div>
      {(taskTemplate ||
        task.requestThisTask ||
        task.NextDue ||
        task.Location ||
        task.ServiceId ||
        (taskAssignee && !task.Team) ||
        task.Team) && (
        <div className={'itemFooter'}>
          {taskTemplate && (
            <InfoChip>
              <span className={'infoChipIcon'}>
                <ChecklistIcon style={{ fontSize: 14, fill: 'none' }} />
              </span>
              <span className={'infoChipText'}>{taskTemplate.name}</span>
            </InfoChip>
          )}
          {taskInterval && (
            <InfoChip>
              <span className={'infoChipIcon'}>
                <RepeatIconMultiColor style={{ fontSize: 14, fill: 'none' }} />
              </span>
              <span className={'infoChipText'}>{taskInterval}</span>
            </InfoChip>
          )}
          {task.NextDue && task?.Asset?.name && (
            <InfoChip>
              <span className={'infoChipIcon'}>
                <AssetsIcon style={{ fontSize: 14, fill: 'none' }} />
              </span>
              <span className={'infoChipText'}>{task?.Asset?.name}</span>
            </InfoChip>
          )}
          {task.NextDue && (
            <InfoChip>
              <span className={'infoChipIcon'}>
                <CalendarIconRounded
                  style={{ fill: 'none', fontSize: 14 }}
                  sx={{
                    '& path': {
                      stroke: 'rgba(117, 117, 117, 1)',
                    },
                  }}
                />
              </span>
              <span className={'infoChipText'}>
                Next Due on {formattedDates.nextDue}
              </span>
            </InfoChip>
          )}
          {task.Location && task.LocationId && (
            <InfoChip>
              <span className={'infoChipIcon'}>
                <LocationIcon style={{ fontSize: 14, fill: 'none' }} />
              </span>
              <span className={'infoChipText'}>{task.Location.name}</span>
            </InfoChip>
          )}
          {task.ServiceId && (
            <CategoryChip
              sx={{ borderRadius: '100px', '& svg': { fontSize: 16 } }}
              border
              startIcon
              task={task}
            />
          )}

          {task?.TaskAssignees?.length > 0 && (
            <InfoChip>
              <AssigneesView
                type={task?.TaskAssignees?.length === 1 ? 'single' : 'avatars'}
                data={task?.TaskAssignees}
                assigneesProps={{
                  height: 22,
                  width: 22,
                }}
                singleAssigneeProps={{
                  height: 22,
                  width: 22,
                  textStyles: {
                    fontSize: 12,
                    color: '#424242',
                    fontWeight: 500,
                  },
                  locationIconStyles: {
                    container: {
                      border: 'none',
                      height: 'unset',
                      width: 'unset',
                    },
                    main: {
                      gap: '5px',
                    },
                    childIcon: {
                      fontSize: 17,
                      color: '#71777ac9',
                    },
                    parentIcon: {
                      fontSize: 17,
                      color: '#78909C',
                    },
                  },
                }}
              />
            </InfoChip>
          )}

          {task.Team && (
            <InfoChip>
              <TeamAvatar teamId={task.Team.id} width={16} height={16} />
              <span className={'infoChipText'}>{task.Team.name}</span>
            </InfoChip>
          )}
        </div>
      )}
    </ScheduleItemCnt>
  );
}

export default ScheduleGridItem;
