import { Box, Stack, Typography, styled } from '@mui/material';

export const MainContainer = styled(Box)(() => ({}));

export const DeleteText = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '21.86px',
  color: '#000000',
});

export const IntroKioskModeCnt = styled(Stack)(() => ({
  borderRadius: 12,
  background: 'url(/assets/img/onboarding-logo.png) #EBEAFF no-repeat 100% 1px',
  backgroundSize: 'contain',
  gap: 5,
  padding: '16px 20px 16px 20px',
  height: 150,
  marginBottom: 20,
  position: 'relative',

  '& .crossCnt': {
    borderRadius: '50%',
    position: 'absolute',
    right: -5,
    top: -6,
    backgroundColor: '#78909C',
    height: 20,
    width: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      height: 15,
      '& path': {
        stroke: 'white',
      },
    },
  },

  '& img': {
    height: 36,
    width: 36,
  },

  '& .title': {
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '24.59px',
    letterSpacing: '0.15px',
    color: '#000000',
  },
  '& .statement': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22.4px',
    letterSpacing: '0.15px',
    color: '#000000',
    maxWidth: 800,
  },
}));

export const PinCodeBanner = styled(Stack)(() => ({
  borderRadius: 8,
  padding: '7px 12px 6px 12px',
  gap: '12px',
  marginBottom: 20,
  position: 'relative',
  backgroundColor: '#E1F5FE',

  '& .content': {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16.39px',

    '& .pinLink': {
      color: '#6868FE',
      cursor: 'pointer',
    },
  },

  '& .infoIcon': {
    color: '#0288D1',
    height: 29,
    width: 19,
  },

  '& .crossIcon': {
    height: 18,
    width: 18,
    cursor: 'pointer',
    '& path': {
      stroke: '#616161',
    },
  },
}));

export const BoxContainer = styled(Box)(() => ({
  borderRadius: 8,
  border: '1px solid #E0E0E0',
  padding: '12px 16px 12px 16px',
  gap: '12px',
  marginBottom: 20,
}));

export const RedirectToCnt = styled(Stack)(() => ({
  padding: '10px 0px 10px 20px',
  cursor: 'pointer',
}));

export const SelectedRedirectToItem = styled(Stack)(() => ({
  border: '1px solid #BDBDBD',
  padding: 5,
  borderRadius: 6,
  cursor: 'pointer',
  '& svg': {
    fontSize: 20,
  },
  '& .title': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#000000',
  },
}));

export const RedirectToItem = styled(Stack)(() => ({
  cursor: 'pointer',
  '&:hover': {
    '& svg': {
      '& path': {
        stroke: 'black',
      },
    },
    '& .title': {
      fontWeight: 700,
    },
  },
  '& svg': {
    fontSize: 20,
  },
  '& .title': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#000000',
  },
}));

export const TimeDurationCnt = styled(Box)({
  '& .MuiFormControl-root': {
    width: '50px !important',

    '& input': {
      padding: '11.5px 0px !important',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#000000',
      textAlign: 'center',
    },
  },

  '& fieldset': {
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid #757575',
    borderColor: '#757575 !important',
    borderWidth: '1px !important',
  },
});
