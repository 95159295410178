import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MainCnt, Value } from 'pages/documents/listing/listView/list.style';
import UserOrTeamAvatarGroup from 'components/Avatar/UserOrTeamAvatarGroup';
import selectors from 'store/selectors';
import { WORKSPACE_ACCESS } from 'components/DocumentAccess/constants';
import { ContentCnt } from 'pages/documents/listing/listView/renderers/editors/style';

function Editors(props) {
  const { document } = props;
  const { DocumentAccess, workspaceAccess } = document;

  const users = useSelector(selectors.getUserAdmin);
  const editors = DocumentAccess?.filter((item) => item?.canEdit);

  const data = useMemo(() => {
    if (editors?.length === 1) {
      return users?.find((user) => user?.id === editors?.[0]?.AccessorId);
    }
    return null;
  }, [editors]);

  return (
    <MainCnt>
      {workspaceAccess === WORKSPACE_ACCESS?.workspaceEdit && (
        <Value>All users & teams</Value>
      )}

      {workspaceAccess !== WORKSPACE_ACCESS?.workspaceEdit &&
        editors?.length > 0 && (
          <ContentCnt>
            <UserOrTeamAvatarGroup
              members={editors?.map((access) => access?.AccessorId)}
            />
            {editors?.length === 1 && (
              <Value style={{ marginLeft: 7 }}>
                {data?.firstName} {data?.lastName}
              </Value>
            )}
          </ContentCnt>
        )}
    </MainCnt>
  );
}

export default Editors;
