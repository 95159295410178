// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '8px',
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },

  '& .MuiTabs-root': {
    margin: '0px 25px',
  },
}));
