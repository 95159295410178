// MUI
import { Stack, Typography } from '@mui/material';

// Icons
import CalendarWithBlobAndPeopleIcon from 'components/Icons/calendarWithBlobAndPeople';

const EmptyState = () => {
  return (
    <Stack height="calc(100vh - 223px)" alignItems={'center'} pt={'100px'}>
      <CalendarWithBlobAndPeopleIcon
        style={{ width: '200px', height: 'auto' }}
      />
      <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={18}
        fontWeight={700}
        color="#212121"
      >
        No schedules found
      </Typography>
      <Typography
        margin={'10px 0px 4px 0px'}
        fontSize={14}
        fontWeight={400}
        color="#616161"
        textAlign={'center'}
        width={'365px'}
      >
        No work schedules are currently linked to this location. Create a new
        schedule and assign it to this location.
      </Typography>
    </Stack>
  );
};

export default EmptyState;
