import { createSvgIcon } from '@mui/material';

const RocketIcon = (props) => {
  const { primary = '#EBEAFF', secondary = '#3922D6' } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M14.2358 3.46764C14.6639 3.01003 15.2309 2.70622 15.849 2.6032L21.5732 1.64916C23.1524 1.38596 24.5216 2.7551 24.2584 4.33435L23.3043 10.0586C23.2013 10.6767 22.8975 11.2436 22.4399 11.6717L14.1926 19.3869C12.0548 21.3868 8.71628 21.3312 6.64632 19.2612C4.57636 17.1912 4.52073 13.8527 6.52058 11.715L14.2358 3.46764Z"
        fill={primary}
      />
      <path
        d="M14.1665 11.8333L3.08316 22.9167M15.3547 3.1282C16.7757 4.0711 18.1415 5.18273 19.4178 6.45906C20.7052 7.74639 21.825 9.12479 22.7731 10.559M9.79728 8.21215L6.44327 7.09414C6.057 6.96539 5.63155 7.04732 5.32074 7.31032L1.98738 10.1309C1.30497 10.7083 1.49884 11.8072 2.33766 12.1162L5.49606 13.2798M12.6277 20.4113L13.7913 23.5697C14.1004 24.4085 15.1993 24.6024 15.7767 23.9199L18.5972 20.5866C18.8602 20.2758 18.9421 19.8503 18.8134 19.4641L17.6954 16.11M21.5732 1.64916L15.849 2.6032C15.2309 2.70622 14.6639 3.01003 14.2358 3.46764L6.52058 11.715C4.52073 13.8527 4.57636 17.1912 6.64632 19.2612C8.71628 21.3312 12.0548 21.3868 14.1926 19.3869L22.4399 11.6717C22.8975 11.2436 23.2013 10.6767 23.3043 10.0586L24.2584 4.33435C24.5216 2.7551 23.1524 1.38596 21.5732 1.64916Z"
        stroke={secondary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={primary}
      />
    </>,
    'RocketIcon',
  );
  return (
    <IconCmp
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    />
  );
};
export default RocketIcon;
