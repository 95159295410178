// React
import { useEffect, useState, useMemo, Fragment } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import Search from './Search';
import MembersTable from './MembersTable';
import EmptyState from './EmptyState';
import Paywall from './Paywall';

// MUI
import { Stack, CircularProgress } from '@mui/material';

// Icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// Styles
import { AddMembersButton } from './locationHistory.style';

// Hooks
import useUsers from 'utils/CustomHooks/useUsers';
import useLocationDetail from 'pages/Locations/LocationDetail/useLocationDetail';
import useUpdateLocation from 'utils/CustomHooks/useUpdateLocation';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// Constants
import DIALOGS from 'utils/dialogIds';

// Utilities
import xeniaApi from 'api/index';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { showMessageNotification } from 'utils/globalFunction';

function LocationMembers() {
  const { fetchAuthLocations } = useLocationsUtils();
  const dispatch = useDispatch();
  const { selectedLocation } = useLocationDetail();
  const { updateLocationMembersRedux } = useUpdateLocation();
  const { FEATURES, hasFeature } = useCustomPermission();

  const { getUserAvailabilityStatus } = useUsers();

  const [searchText, setSearchText] = useState('');
  const [members, setMembers] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [fetching, setFetching] = useState(true);

  const hasMembersFeature = useMemo(
    () => hasFeature(FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT),
    [],
  );

  const fetchLocationMembers = async () => {
    if (hasMembersFeature) {
      setFetching(true);
      const locationMembers = await xeniaApi.getLocationMembers(
        selectedLocation?.id,
      );
      setMembers(locationMembers);
      setFetching(false);
    }
  };

  const handleUpdateLocationMembersRedux = (members: any[]) => {
    updateLocationMembersRedux({
      location: selectedLocation,
      members: members.map((m) => m.Member),
    });
  };

  const handleRemoveMember = async (memberId: string) => {
    const prevMembers = [...members];
    const newMembers = prevMembers.filter((m) => m.MemberId !== memberId);
    setMembers(newMembers);
    const response = await xeniaApi.removeLocationMember({
      locationId: selectedLocation?.id,
      memberId,
    });
    if (!response) {
      setMembers(prevMembers);
    } else {
      handleUpdateLocationMembersRedux(newMembers);
      fetchAuthLocations();
    }
  };

  const handleBulkRemove = async () => {
    const prevMembers = [...members];
    const newMembers = members.filter((m) => !selected.includes(m.id));
    setMembers(newMembers);
    const response = await xeniaApi.updateLocationMembers({
      locationId: selectedLocation?.id,
      memberIds: newMembers.map((m) => m?.MemberId),
    });
    if (!response) {
      setMembers(prevMembers);
    } else {
      setMembers(response);

      if (response.length > newMembers.length) {
        showMessageNotification(
          "User having this location as default location can't be removed",
          'error',
        );
      }

      handleUpdateLocationMembersRedux(response);
    }
  };

  const handleAddMembers = async (
    newMembers: string[],
    { onSuccessCb, onErrorCb },
  ) => {
    const updatedMembers = await xeniaApi.updateLocationMembers({
      locationId: selectedLocation?.id,
      memberIds: newMembers,
    });
    if (updatedMembers) {
      onSuccessCb();
      setMembers(updatedMembers);
      handleUpdateLocationMembersRedux(updatedMembers);
      fetchAuthLocations();
    } else {
      onErrorCb();
    }
  };

  const membersWithStatus = useMemo(
    () =>
      !members
        ? []
        : members?.map((m) => ({
            ...m,
            status: getUserAvailabilityStatus(m?.MemberId),
          })),
    [members],
  );

  const handleClickAddMembers = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.ADD_SELECT_USERS_DIALOG,
        open: true,
        data: {
          people: members
            .filter((m) => m?.Member?.type === 'user')
            .map((m) => m?.MemberId),
          teams: members
            .filter((m) => m?.Member?.type === 'team')
            .map((m) => m?.MemberId),
          onClickAdd: (users: string[], { onSuccessCb, onErrorCb }) =>
            handleAddMembers(users, { onSuccessCb, onErrorCb }),
        },
      }),
    );
  };

  useEffect(() => {
    fetchLocationMembers();
  }, [selectedLocation?.id, hasMembersFeature]);

  return (
    <Stack gap={'16px'}>
      {fetching && (
        <Stack height="calc(100vh - 223px)" alignItems={'center'} pt="100px">
          <CircularProgress />
        </Stack>
      )}
      {membersWithStatus.length === 0 && !fetching && hasMembersFeature && (
        <EmptyState onClickAddMembers={handleClickAddMembers} />
      )}
      {membersWithStatus.length > 0 && !fetching && hasMembersFeature && (
        <Fragment>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Search
              searchTerm={searchText}
              handleChangeSearch={(term) => setSearchText(term)}
            />
            <AddMembersButton
              onClick={handleClickAddMembers}
              startIcon={<AddRoundedIcon />}
              variant="outlined"
            >
              Add Members
            </AddMembersButton>
          </Stack>
          <MembersTable
            searchTerm={searchText}
            members={membersWithStatus?.filter((item) => item?.Member) ?? []}
            onClickRemove={handleRemoveMember}
            onBulkSelection={(selectedIds) => setSelected(selectedIds)}
            onClickCancelSelection={() => setSelected([])}
            selected={selected}
            handleBulkRemove={handleBulkRemove}
          />
        </Fragment>
      )}
      {!hasMembersFeature && <Paywall />}
    </Stack>
  );
}

export default LocationMembers;
