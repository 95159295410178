// React
import { useMemo } from 'react';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';

// Styles
import { buttonProps, buttonStyles } from '../commonStyles';

type Option = {
  id: string;
  label: string;
};

interface IsOrNotPropTypes {
  value: 'is' | 'isNot' | '';
  onSelect: (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    id: string,
  ) => void;
}

const IsOrNot = ({ value, onSelect }: IsOrNotPropTypes) => {
  const options = [
    {
      id: 'is',
      label: 'Is',
    },
    {
      id: 'isNot',
      label: 'Is not',
    },
  ];

  const handleOptionSelect = (option: Option) => {
    onSelect('comparator', option.id);
  };

  const label = useMemo(() => {
    return options.find((o) => o.id === value)?.label ?? '--';
  }, [value]);

  return (
    <ActionDropdown
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={options}
      buttonProps={{
        sx: {
          width: '100px',
          ...buttonStyles,
        },
        endIcon: <ExpandMoreRoundedIcon />,
        ...buttonProps,
      }}
      paperProps={{
        sx: {
          minWidth: '100px',
          width: '100px',
        },
      }}
    >
      {label}
    </ActionDropdown>
  );
};

export default IsOrNot;
