import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import DashboardCmpHeader from 'pages/Dashboards/DashboardCmp/DashboardCmpHeader/dashboardCmpHeader';
import DashboardCmpContent from 'pages/Dashboards/DashboardCmp/DashboardCmpContent/dashboardCmpContent';
import DashboardEmptyState from 'pages/Dashboards/EmptyState/emptyState';
import DashboardCmpDraftHeader from 'pages/Dashboards/DashboardCmp/DashboardCmpDraftHeader/dashboardCmpDraftHeader';
import { useDashboard } from 'pages/Dashboards/hooks/useDashboard';
import CustomModal from 'components/CustomModal';
import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import { DashboardsContext } from 'pages/Dashboards/context/context';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import { initialState } from 'pages/Dashboards';
function DashboardCmp() {
  const params = useParams();
  const { dashboardId } = params;
  const { dashboardQuery } = useDashboard({ id: dashboardId });
  const { dashboardsState, updateDashboardsState } =
    useContext(DashboardsContext);
  const { fullScreen } = dashboardsState;
  useEffect(() => {
    dashboardQuery.refetch();
    updateDashboardsState(initialState);
  }, [dashboardId]);
  const ContentRenderer = useCallback(() => {
    return (
      <Stack
        bgcolor="#FFF"
        height="calc(100vh - 200px)"
        width="100%"
        alignItems={'center'}
      >
        <WithLoader isLoading={dashboardQuery.isLoading}>
          {dashboardQuery?.data?.publish ? (
            <DashboardCmpHeader />
          ) : (
            <DashboardCmpDraftHeader />
          )}
          {dashboardQuery?.data?.DashboardWidgets?.length &&
          !dashboardQuery.isLoading ? (
            <DashboardCmpContent />
          ) : (
            <DashboardEmptyState />
          )}{' '}
        </WithLoader>
      </Stack>
    );
  }, [dashboardQuery?.data]);
  return (
    <WithLoader isLoading={dashboardQuery.isLoading}>
      <>
        {fullScreen ? (
          <CustomModal
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '0px',
                height: '100%',
                width: '100%',
                maxWidth: 'unset',
                margin: 0,
                maxHeight: 'unset',
              },
            }}
            fullWidth
            open={fullScreen}
          >
            <ContentRenderer />
          </CustomModal>
        ) : (
          <ContentRenderer />
        )}
      </>
    </WithLoader>
  );
}

export default DashboardCmp;
