import { useState } from 'react';
import AddIcon from 'components/Icons/addIcon';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import ErrorIcon from '@mui/icons-material/Error';
import ToolTip from 'components/Tooltip/tooltip';
import {
  AddContentCnt,
  CreateTask,
} from 'pages/task/TaskListView/taskListView.style';
import { colors } from 'components/theme/constants';
import useCreateTask from 'utils/CustomHooks/useCreateTask';
import { setDialog } from 'actions/dialogActions';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { isEmpty } from 'lodash';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { HasPermission } from 'components/HasPermission';

export default (params) => {
  const [isCreate, setIsCreate] = useState(false);
  const [taskTitle, setTaskTitle]: any = useState('');
  const { createTask } = useCreateTask();
  const [error, setError] = useState('');
  const { PAYWALL_LIMITS, PERMISSIONS } = useCustomPermission();

  const handleTaskTitleInput = (e) => {
    setError('');
    setTaskTitle(e.target.value);
  };

  const clearAdd = () => {
    setTaskTitle('');
    setError('');
  };
  const validateTaskTitle = () => {
    let error;
    if (!taskTitle) {
      error = VALIDATION_ERROR_MESSAGES.TASK_TITLE_REQUIRED;
    } else if (taskTitle?.length > 250) {
      error = VALIDATION_ERROR_MESSAGES.TASK_TITLE_CHARACTER_LIMIT;
    }
    setError(error);
    return error;
  };
  const addTask = async () => {
    if (validateTaskTitle()) {
      return;
    }
    const postObj = {
      title: taskTitle,
      //In case task is added in a group by status view then task status will be same as group status
      ...(params?.data?.addTask && { ...params?.data?.createData }),
    };
    const newCreatedTask = await createTask(postObj);
    if (newCreatedTask) {
      setTaskTitle('');
    }
  };

  const keyDownHandler = (e) => {
    if (e.keyCode === 13) {
      addTask();
    }
  };

  return isCreate ? (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={(e) => {
          setIsCreate(false);
        }}
      >
        <CreateTask data-attribute="cell-click">
          <CustomInput
            sx={{ mb: 0 }}
            fieldProps={{
              placeholder: 'Enter task title',
              value: taskTitle,
              autoFocus: true,
              onClick: (e) => e.stopPropagation(),
              onChange: handleTaskTitleInput,
              onKeyDown: keyDownHandler,
              className: 'inputCnt',
              endAdornment: error ? (
                <ToolTip placement="top" arrow title={error}>
                  <ErrorIcon className="error" />
                </ToolTip>
              ) : null,
            }}
            suppressBorder={true}
          />
          <CustomButton
            variant={'text'}
            className="cancelBtn"
            disableElevation
            onClick={() => {
              setIsCreate(false);
              clearAdd();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            disableElevation
            className="addBtn"
            onClick={addTask}
          >
            Add
          </CustomButton>
        </CreateTask>
      </ClickAwayListener>
      {error && <span className="error">{error}</span>}
    </>
  ) : params?.data?.createData?.taskStatus === 'Missed' ? (
    <></>
  ) : (
    <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
      <AddContentCnt
        data-attribute="cell-click"
        onClick={(e) => {
          setIsCreate(true);
        }}
      >
        <AddIcon style={{ color: colors.white, fontSize: 17 }} />
        <CTypography className="content">Add New</CTypography>
      </AddContentCnt>
    </HasPermission>
  );
};
