import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import CTabs from 'components/CTabs';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import RoleDetailsHeader from './header';
import RoleDetailsPermissions from './permissions';
import RoleDetailsUsers from './users';
import { MainCnt } from './style';

function CustomRoleDetails() {
  const [searchParams, _] = useSearchParams();
  const tab = searchParams.get('tab');
  const [activeTab, setActiveTab] = useState(0);
  const { fetchCustomRoleDetails, fetchCustomRoles } = useCustomRoles();
  const [details, setDetails] = useState(null);

  const params = useParams();

  const getCustomRoleDetails = () => {
    const roleId = params?.roleId;
    fetchCustomRoleDetails({}, roleId, (res) => {
      setDetails(res?.[0]);
    });
  };

  const tabs = useMemo(() => {
    return [
      {
        label: 'Permissions',
        content: <RoleDetailsPermissions details={details} />,
        panelProps: { sx: { p: '0px !important' } },
      },
      {
        label: 'Users',
        content: (
          <RoleDetailsUsers
            details={details}
            getCustomRoleDetails={getCustomRoleDetails}
          />
        ),
        panelProps: { sx: { p: '0px !important' } },
      },
    ];
  }, [details]);

  useEffect(() => {
    if (tab !== undefined && tab !== null) setActiveTab(parseInt(tab));
  }, [tab]);

  useEffect(() => {
    fetchCustomRoles({});
    getCustomRoleDetails();
  }, []);

  return (
    <MainCnt>
      <RoleDetailsHeader
        details={details}
        getCustomRoleDetails={getCustomRoleDetails}
      />

      <CTabs
        activeTab={tab ? activeTab : undefined}
        rootSx={{ margin: '0px !important' }}
        data={tabs}
      />
    </MainCnt>
  );
}

export default CustomRoleDetails;
