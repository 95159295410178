/* eslint-disable @typescript-eslint/ban-types */
import { createContext } from 'react';

export type FieldType = {
  required: boolean;
  show: boolean;
};

export type RequestConfigType = {
  id: string;
  logo: null | string;
  HotelId: string;
  title: string | null;
  enabled: boolean;
  isDefault: boolean;
  instructions: any | null;
  fields: {
    email: FieldType;
    phone: FieldType;
    title: FieldType;
    AssetId: FieldType;
    fullName: FieldType;
    priority: FieldType;
    CategoryId: FieldType;
    LocationId: FieldType;
    attachments: FieldType;
    description: FieldType;
  };
  notifyRequester: boolean;
};

interface RequestConfigContextType {
  config: RequestConfigType | null;
  updateConfig: (key: string, data: any) => void;
  handleUpdateFieldVisibility: (field: string, visibilty: any) => void;
  updateConfigState?: (data: any) => void;
}

export const RequestConfigContext = createContext<
  RequestConfigContextType | undefined
>(undefined);
