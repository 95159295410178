import { useEffect } from 'react';
import xeniaApi from 'api/index';
import DowngradeModal from 'components/AllDialogs/billing/DowngradeModal';
import PlansModal from 'components/AllDialogs/billing/PlansModal';
import ConfirmationDialog from 'components/ConfirmationDialog';
import DowngradePlanIcon from 'components/Icons/downgradePlanIcon';
import UpSellModal from 'components/UpSellModal';
import { OnBoardingBackground } from 'pages/Login/style';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import useAppNavigation from 'utils/CustomHooks/useAppNavigation';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DIALOGS from 'utils/dialogIds';
import CalendlyDialog from 'components/Calendly';
import {
  StarIconWrapper,
  StyledStarIcon,
  LockIconWrapper,
  StyledLockIcon,
} from './styled';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';

const PlanExpired = () => {
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();
  const { navigateToHome } = useAppNavigation();
  const dialogId: string = DIALOGS.UPSELL_MODAL;
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId];

  const handleCloseUpgradePopup = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  const handleShowExpiredPopup = () => {
    dispatch(
      actions.setDialog({
        dialogId,
        open: true,
        data: {
          isEscapable: false,
          icon: (
            <StarIconWrapper>
              <StyledStarIcon />
              <LockIconWrapper>
                <StyledLockIcon />
              </LockIconWrapper>
            </StarIconWrapper>
          ),
          title: 'Your 14 Day Xenia Trial has Ended',
          description: (
            <>
              No worries, your data is safe and secure. You can keep using our
              powerful features by upgrading to <b>Xenia Premium</b> plan.
            </>
          ),
          primaryButtonText: 'Upgrade Now',
          primaryButtonClickCB: () => {
            dispatch(
              actions.setDialog({
                dialogId: DIALOGS.BILLING.PLANS_MODAL,
                open: true,
                data: {
                  hideCloseIcon: true,
                  handleShowExpiredPopup,
                },
              }),
            );
            handleCloseUpgradePopup();
          },
          outlinedButtonText: 'Downgrade to Free Plan',
          outlinedButtonClickCB: () => {
            const downgradeCB = async () => {
              const res = await xeniaApi.downgradeToFreeForeverPlan(
                workspaceId as string,
              );
              dispatch(actions.setBillingInfo(res?.data));
              dispatch(
                actions.setDialog({
                  dialogId: DIALOGS.DOWNGRADE_MODAL,
                  open: false,
                }),
              );
              const dialogId: string = DIALOGS.UPSELL_MODAL;
              dispatch(
                actions.setDialog({
                  dialogId,
                  open: true,
                  data: {
                    isEscapable: false,
                    icon: <DowngradePlanIcon />,
                    title: 'You have successfully downgraded to Free Plan',
                    description: (
                      <>
                        You have lost access to your <b>Xenia Premium</b> data.
                        <br />
                        You can restore Xenia Premium at any time from{' '}
                        <b>Settings &#62; Billing</b> tab to access it.
                      </>
                    ),
                    primaryButtonText: 'Continue',
                    primaryButtonClickCB: () => {
                      navigateToHome();
                      dispatch(
                        actions.setDialog({
                          dialogId,
                          open: false,
                        }),
                      );
                    },
                  },
                }),
              );
            };
            handleCloseUpgradePopup();
            dispatch(
              actions.setDialog({
                dialogId: DIALOGS.DOWNGRADE_MODAL,
                open: true,
                data: {
                  downgradeToFreeForever: true,
                  downgradeCB,
                  hideCloseIcon: true,
                  handleShowExpiredPopup,
                },
              }),
            );
          },
        },
      }),
    );
  };

  useEffect(() => handleShowExpiredPopup(), [workspaceId]);

  return (
    <>
      {modalState?.open && <UpSellModal />}
      <PlansModal />
      <DowngradeModal />
      <ConfirmationDialog dialogId={DIALOGS.CONFIRMATION} />
      <OnBoardingBackground></OnBoardingBackground>
      <CalendlyDialog />
    </>
  );
};

export default PlanExpired;
