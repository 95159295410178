import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';

export const CTypographyInvite = styled(CTypography)({
  paddingLeft: '10px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
});

export const InviteUserDiv = styled('div')({
  padding: '20px 50px',
  '& .title': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '32.78px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '-0.15px',
    marginBottom: 3,
  },
  '& .subTitle': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    color: '#616161',
  },
  '& .cardContent': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const CustomActionsCnt = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '& .cancelBtn': {
    width: 65,
    display: 'flex',
    marginRight: 10,
    minWidth: 'unset',
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
  },
  '& .inviteBtn': {
    width: 153,
  },
});

export const TitleAndCounter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 20,
});
