import IconButton from '@mui/material/IconButton';
import DeleteIcon from 'components/Icons/deleteIcon';
import CellEditor from './cellEditor';
import { CreatedBy, FlaggedCategoryName } from './renderers/renderers';

export const Columns = (props) => {
  return [
    {
      headerName: 'Title',
      field: 'name',
      colId: 'name',
      headerClass: 'header-category',
      suppressMovable: true,
      suppressMenu: true,
      cellRenderer: (params) => cellRenderer.title(params, props),
      editable: true,
      cellEditorSelector: cellEditorSelector,
      valueSetter: (params) => ValueSetter.title(params, props),
    },
    {
      headerName: 'Created By',
      colId: 'createdBy',
      suppressMovable: true,
      headerClass: 'header-category',
      suppressMenu: true,
      cellRenderer: cellRenderer.createdBy,
      valueGetter: valueGetters.createdBy,
    },
    {
      field: '',
      maxWidth: 80,
      headerName: '',
      colId: 'actionCol',
      suppressMovable: true,
      suppressMenu: true,
      cellRenderer: (params) => cellRenderer.actionColumn(params, props),
    },
  ];
};

const valueGetters = {
  createdBy: (params) => {
    const { User } = params.data;
    return `${User?.firstName} ${User?.lastName}`;
  },
};

const cellRenderer = {
  title: (params, props) => {
    if (!params?.data) return null;
    const { actionsProps } = props;
    return <FlaggedCategoryName params={params} actionsProps={actionsProps} />;
  },
  createdBy: (params) => {
    if (!params?.data) return null;
    return <CreatedBy data={params?.data} />;
  },
  actionColumn: (params, props) => {
    const { actionsProps } = props;
    const { removeHandler } = actionsProps;
    return (
      <div className="actions" style={{ display: 'none' }}>
        <IconButton onClick={() => removeHandler(params.data)}>
          <DeleteIcon style={{ color: 'red' }} />
        </IconButton>
      </div>
    );
  },
};

const cellEditorSelector = () => {
  return {
    component: CellEditor,
  };
};

const ValueSetter = {
  title: async (params, props) => {
    const { actionsProps } = props;
    const { updateHandler } = actionsProps;
    const data = params.data;

    if (data.name !== params.newValue) {
      updateHandler({ name: params.newValue, id: data?.id });
      return true;
    } else {
      return false;
    }
  },
};
