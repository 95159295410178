import { useReducer } from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardCmp from 'pages/Dashboards/DashboardCmp/DashboardCmp';
import { DashboardsContext } from './context/context';
import DateTime from 'utils/DateTime';
import store from '../../store';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import ReportDashboard from 'pages/ReportingV2/ReportDashboard/ReportDashboard';
import DashboardTabs from './tabs';
export interface initialStateType {
  fullScreen: boolean;
  searchQuery: string;
  editable?: boolean;
  currentLayout?: any;
  filters: {
    location: string[] | null;
    dateRange: Date[] | null;
  };
}

export const initialState = () => {
  const tz =
    store.getState()?.authData?.user?.Hotel?.timezone || DEFAULT_TIMEZONE;
  return {
    fullScreen: false,
    searchQuery: '',
    editable: false,
    currentLayout: null,
    filters: null,
  };
};
const Dashboards = () => {
  const [dashboardsState, updateDashboardsState] = useReducer(
    (prevState, value) => {
      return { ...prevState, ...value };
    },
    initialState(),
  );

  return (
    <DashboardsContext.Provider
      value={{ dashboardsState, updateDashboardsState }}
    >
      <div>
        <Routes>
          <Route path="/:dashboardId" element={<DashboardCmp />} />
          <Route path="/template/:reportId" element={<ReportDashboard />} />
          <Route path="/" element={<DashboardTabs />} />
        </Routes>
      </div>
    </DashboardsContext.Provider>
  );
};

export default Dashboards;
