// MUI Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
const popperStyles = {
  width: 220,
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow:
    '0px 15px 20px rgba(0, 0, 0, 0.07), 0px 3.35045px 13.0956px rgba(0, 0, 0, 0.0417275), 0px 0.997515px 9.19427px rgba(0, 0, 0, 0.0282725)',
};

// Custom Icons
import EditIcon from 'components/Icons/editIcon';
import StartIcon from 'components/Icons/startIcon';
import ArchiveIcon from 'components/Icons/archiveIcon';
import GlobeIcon from 'components/Icons/globeIcon';
import DisabledGlobeIconIcon from 'components/Icons/disabledGlobeIcon';
import DuplicateIconRounded from 'components/Icons/duplicateIconRounded';
import { Schedule } from '@mui/icons-material';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import AddToFolderIcon from 'components/Icons/addToFolderIcon';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { isEmpty } from 'lodash';
import TemplateShareIcon from 'components/Icons/templateShareIcon';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';

const buttonStyles = {
  background: 'rgba(255, 255, 255, 1)',
  width: 17,
  height: 17,
  color: '#7571C5',
  '&:hover': {
    background: 'rgba(255, 255, 255, 1)',
  },
};

const rootStyles = {
  position: 'absolute',
  right: 16,
  top: 16,
  zIndex: 20,
  height: 17,
  display: 'flex',
};

const ChecklistGridActionDropdown = ({
  editChecklist,
  startChecklist,
  assignChecklist,
  archiveChecklist,
  togglePublishState,
  onAddTemplateToFolder,
  onMoveFolder,
  onMoveOutOfFolder,
  setAnchorEl,
  popperProps,
  buttonProps,
  folder,
  checklist,
  actionRootProps = {},
  variant = 'horizontal',
}) => {
  const dispatch = useDispatch();
  const { hasFeature, FEATURES } = useCustomPermission();
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const wsFeatureAccess = hasFeature(FEATURES.MULTI_WORKSPACES);
  const checklistPermission = hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST);
  const openShareTemplateDialog = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TEMPLATE_SHARING,
        data: { checklist },
      }),
    );
  };
  const duplicateChecklist = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TEMPLATE_COPY,
        data: { checklist },
      }),
    );
  };
  const handleMoveToFolder = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.MOVE_TO_FOLDER_DIALOG,
        data: {
          folder,
          template: checklist.id,
          onSuccessCallback: (fromId, toId) => {
            onMoveFolder(fromId, toId);
          },
        },
      }),
    );
  };
  const handleMoveOutOfFolder = () => {
    onMoveOutOfFolder(folder.id, checklist);
  };
  const handleAddToFolder = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.ADD_TO_FOLDER_DIALOG,
        data: {
          templateId: checklist?.id,
          onSuccessCallback: onAddTemplateToFolder,
        },
      }),
    );
  };
  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'edit':
        editChecklist();
        break;
      case 'start':
        startChecklist();
        break;
      case 'assign':
        assignChecklist();
        break;
      case 'archive':
        archiveChecklist();
        break;
      case 'duplicate':
        duplicateChecklist();
        break;
      case 'move-to-folder':
        handleMoveToFolder();
        break;
      case 'move-out-of-folder':
        handleMoveOutOfFolder();
        break;
      case 'add-to-folder':
        handleAddToFolder();
        break;
      case 'togglePublish':
        togglePublishState();
        break;
      case 'shareTemplate':
        openShareTemplateDialog();
        break;
      default:
        return;
    }
    setAnchorEl(null);
  };

  const iconStyles = {
    sx: { fill: 'none', fontSize: 14 },
    style: { color: 'rgba(0, 0, 0, 0.54)' },
  };

  return (
    <ActionDropdown
      clickaway={false}
      onClickAwayCallback={() => setAnchorEl(null)}
      popperProps={{
        style: popperStyles,
        placement: 'bottom-end',
        disablePortal: false,
        ...popperProps,
      }}
      options={[
        ...(checklist?.HotelChecklist?.isEditable
          ? [
              {
                label: 'Edit',
                id: 'edit',
                icon: <EditIcon {...iconStyles} />,
                iconAlign: 'start',
              },
            ]
          : []),
        {
          label: 'Start',
          id: 'start',
          icon: <StartIcon {...iconStyles} />,
          iconAlign: 'start',
        },
        {
          label: 'Schedule',
          id: 'assign',
          icon: <Schedule />,
          iconAlign: 'start',
        },
        ...(!folder
          ? [
              {
                label: 'Add to folder',
                id: 'add-to-folder',
                icon: <AddToFolderIcon sx={{ fill: 'none' }} />,
                iconAlign: 'start',
              },
            ]
          : []),
        ...(folder
          ? [
              {
                label: 'Move to folder',
                id: 'move-to-folder',
                icon: <FolderOpenIcon />,
                iconAlign: 'start',
              },
              {
                label: 'Move out of Folder',
                id: 'move-out-of-folder',
                icon: <DriveFileMoveOutlinedIcon />,
                iconAlign: 'start',
              },
            ]
          : []),
        ...(checklist?.HotelChecklist?.isEditable
          ? [
              {
                label: checklist.isPublished ? 'Unpublish' : 'Publish',
                id: 'togglePublish',
                icon: checklist.isPublished ? (
                  <DisabledGlobeIconIcon {...iconStyles} />
                ) : (
                  <GlobeIcon {...iconStyles} />
                ),
                iconAlign: 'start',
              },
            ]
          : []),
        ...(wsFeatureAccess &&
        checklistPermission &&
        checklist?.HotelChecklist?.isEditable
          ? [
              {
                id: 'shareTemplate',
                label: 'Share this template',
                iconAlign: 'start',
                icon: <TemplateShareIcon style={{ color: '#000' }} />,
              },
            ]
          : []),
        {
          id: 'duplicate',
          label: 'Duplicate',
          iconAlign: 'start',
          icon: <DuplicateIconRounded />,
        },
        {
          label: 'Archive',
          id: 'archive',
          icon: (
            <ArchiveIcon
              style={{ color: 'rgba(211, 47, 47, 1)' }}
              sx={{ fill: 'none', fontSize: 14 }}
            />
          ),
          iconAlign: 'start',
        },
      ]}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: buttonStyles,
        sx: {
          '& svg': {
            fontSize: 17,
          },
        },
        ...buttonProps,
        id: `${Math.random() * 10}`,
      }}
      rootProps={{
        style: rootStyles,
        id: `${Math.random() * 25}`,
        zIndex: 10000,
      }}
      {...actionRootProps}
    >
      {variant === 'horizontal' ? <MoreHorizIcon /> : <MoreVertOutlinedIcon />}
    </ActionDropdown>
  );
};

export default ChecklistGridActionDropdown;
