import { createSvgIcon } from '@mui/material';

const MaintenanceIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect y="0.548828" width="48" height="48" rx="8" fill="#C8E6C9" />
      <path
        opacity="0.12"
        d="M32.4688 13.7464L29.4032 16.8119C28.9412 17.2739 28.7102 17.5049 28.6236 17.7713C28.5475 18.0056 28.5475 18.258 28.6236 18.4924C28.7102 18.7587 28.9412 18.9897 29.4032 19.4518L29.68 19.7286C30.142 20.1906 30.373 20.4216 30.6394 20.5082C30.8737 20.5843 31.1261 20.5843 31.3605 20.5082C31.6268 20.4216 31.8578 20.1906 32.3199 19.7286L35.1874 16.861C35.4963 17.6125 35.6666 18.4356 35.6666 19.2985C35.6666 22.8423 32.7938 25.7152 29.2499 25.7152C28.8227 25.7152 28.4052 25.6734 28.0013 25.5938C27.4342 25.4819 27.1506 25.426 26.9787 25.4431C26.7959 25.4613 26.7058 25.4887 26.5439 25.5754C26.3915 25.6569 26.2387 25.8097 25.9331 26.1153L17.5833 34.4652C16.6168 35.4317 15.0498 35.4317 14.0833 34.4652C13.1168 33.4987 13.1168 31.9316 14.0833 30.9652L22.4331 22.6153C22.7387 22.3097 22.8915 22.1569 22.9731 22.0046C23.0597 21.8426 23.0871 21.7526 23.1053 21.5698C23.1225 21.3979 23.0665 21.1143 22.9547 20.5471C22.875 20.1432 22.8333 19.7257 22.8333 19.2985C22.8333 15.7547 25.7061 12.8818 29.2499 12.8818C30.423 12.8818 31.5226 13.1966 32.4688 13.7464Z"
        fill="#2E7D32"
      />
      <path
        d="M17.0002 17.5485L22.2502 22.7985L17.0002 17.5485Z"
        fill="#E8F5E9"
      />
      <path
        d="M13.5002 12.8818L17.0002 14.0485V17.5485H13.5002L12.3335 14.0485L13.5002 12.8818Z"
        fill="#E8F5E9"
      />
      <path
        d="M32.469 13.7464L29.4034 16.8119C28.9414 17.2739 28.7104 17.5049 28.6238 17.7713C28.5477 18.0056 28.5477 18.258 28.6238 18.4924C28.7104 18.7587 28.9414 18.9897 29.4034 19.4518L29.6802 19.7286C30.1423 20.1906 30.3733 20.4216 30.6396 20.5082C30.874 20.5843 31.1264 20.5843 31.3607 20.5082C31.6271 20.4216 31.8581 20.1906 32.3201 19.7286L35.1877 16.861C35.4965 17.6125 35.6668 18.4356 35.6668 19.2985C35.6668 22.8423 32.794 25.7152 29.2502 25.7152C28.8229 25.7152 28.4054 25.6734 28.0015 25.5938C27.4344 25.4819 27.1508 25.426 26.9789 25.4431C26.7961 25.4613 26.706 25.4887 26.5441 25.5754C26.3918 25.6569 26.239 25.8097 25.9334 26.1153L17.5835 34.4652C16.617 35.4317 15.05 35.4317 14.0835 34.4652C13.117 33.4987 13.117 31.9316 14.0835 30.9652L22.4334 22.6153C22.739 22.3097 22.8918 22.1569 22.9733 22.0046C23.0599 21.8426 23.0873 21.7526 23.1056 21.5698C23.1227 21.3979 23.0668 21.1143 22.9549 20.5471C22.8753 20.1432 22.8335 19.7257 22.8335 19.2985C22.8335 15.7547 25.7063 12.8818 29.2502 12.8818C30.4232 12.8818 31.5228 13.1966 32.469 13.7464Z"
        fill="#E8F5E9"
      />
      <path
        d="M24.0002 28.0484L30.4168 34.465C31.3833 35.4315 32.9503 35.4315 33.9168 34.465C34.8833 33.4985 34.8833 31.9315 33.9168 30.965L28.638 25.6863C28.2643 25.651 27.9 25.5836 27.5477 25.4869C27.0938 25.3623 26.5959 25.4527 26.2631 25.7856L24.0002 28.0484Z"
        fill="#E8F5E9"
      />
      <path
        d="M17.0002 17.5485L22.2502 22.7985M17.0002 17.5485H13.5002L12.3335 14.0485L13.5002 12.8818L17.0002 14.0485V17.5485ZM32.469 13.7464L29.4034 16.8119C28.9414 17.2739 28.7104 17.5049 28.6238 17.7713C28.5477 18.0056 28.5477 18.258 28.6238 18.4924C28.7104 18.7587 28.9414 18.9897 29.4034 19.4518L29.6802 19.7286C30.1422 20.1906 30.3733 20.4216 30.6396 20.5082C30.874 20.5843 31.1264 20.5843 31.3607 20.5082C31.6271 20.4216 31.8581 20.1906 32.3201 19.7286L35.1877 16.861C35.4965 17.6125 35.6668 18.4356 35.6668 19.2985C35.6668 22.8423 32.794 25.7152 29.2502 25.7152C28.8229 25.7152 28.4054 25.6734 28.0015 25.5938C27.4344 25.4819 27.1508 25.426 26.9789 25.4431C26.7961 25.4613 26.706 25.4887 26.5441 25.5754C26.3918 25.6569 26.239 25.8097 25.9334 26.1153L17.5835 34.4652C16.617 35.4316 15.05 35.4317 14.0835 34.4652C13.117 33.4987 13.117 31.9316 14.0835 30.9652L22.4334 22.6153C22.739 22.3097 22.8918 22.1569 22.9733 22.0046C23.0599 21.8426 23.0873 21.7526 23.1056 21.5698C23.1227 21.3979 23.0668 21.1143 22.9549 20.5471C22.8753 20.1432 22.8335 19.7257 22.8335 19.2985C22.8335 15.7547 25.7063 12.8818 29.2502 12.8818C30.4232 12.8818 31.5228 13.1966 32.469 13.7464ZM24.0002 28.0484L30.4168 34.465C31.3833 35.4315 32.9503 35.4315 33.9168 34.465C34.8833 33.4985 34.8833 31.9315 33.9168 30.965L28.638 25.6863C28.2643 25.651 27.9 25.5836 27.5477 25.4869C27.0938 25.3623 26.5959 25.4527 26.2631 25.7856L24.0002 28.0484Z"
        stroke="#2E7D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'MaintenanceIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 48 49'} {...props} />;
};
export default MaintenanceIcon;
