// React
import { useContext } from 'react';

// Custom components
import FiltersDropdown from 'components/TemplateDashboard/SubmissionListWithFilters/FiltersDropdown';
import SearchBar from 'components/TemplateDashboard/SubmissionListWithFilters/SearchBar';
import SubmissionList from 'components/TemplateDashboard/SubmissionListWithFilters/SubmissionList';
import EmptyState from 'components/AttachChecklistModal/EmptyFilter/EmptyFilter';

// Styled
import { Column, FilterAndSearchWrapper, ListWrapper } from './styled';

// Context
import { SubmissionContext } from 'pages/TemplateDashboard/Tabs/Submissions/context';

const SubmissionListWithFilters = () => {
  const ctx = useContext(SubmissionContext);

  return (
    <Column>
      <FilterAndSearchWrapper>
        <FiltersDropdown />
        <SearchBar />
      </FilterAndSearchWrapper>
      <ListWrapper>
        {ctx?.emptyResultFilters ? <EmptyState /> : <SubmissionList />}
      </ListWrapper>
    </Column>
  );
};

export default SubmissionListWithFilters;
