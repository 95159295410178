// Components
import NotificationsCard from './components/NotificationsCard';
import NotificationsPageHeader from './components/Header/Header';

// Styled
import { PageWrapper, NotificationWrapper } from './Notifications.styled';

const Notifications = () => {
  return (
    <PageWrapper>
      <NotificationsPageHeader />
      <NotificationWrapper>
        <NotificationsCard />
      </NotificationWrapper>
    </PageWrapper>
  );
};

export default Notifications;
