import {
  minutesToHoursAndMinuteString,
  smartRoundToOneDecimalPlace,
} from './globalFunction';

export const getRenderValue = (value, isPercentage, isDuration) => {
  let finalValue;
  if (isPercentage) {
    finalValue = `${smartRoundToOneDecimalPlace(value)}%`;
  } else if (isDuration) {
    finalValue = minutesToHoursAndMinuteString(value);
  } else {
    finalValue = smartRoundToOneDecimalPlace(value);
  }
  return finalValue;
};
