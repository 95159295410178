/* eslint-disable prettier/prettier */
import xeniaApi from 'api/index';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { Background, CircleDiv, MainDiv } from './onBoarding.style';
import LoaderAnimated from 'components/Loader/LoaderAnimated';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useAppNavigation from 'utils/CustomHooks/useAppNavigation';
const OnBoardingStatus = () => {
  const navigate = useNavigate();
  const { navigateToHome } = useAppNavigation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      try {
        const token = searchParams.get('token');
        const tokenType = searchParams.get('stytch_token_type');
        const resp = await xeniaApi.getUserProfile(
          `hotel/auth/magic_link/login?stytch_token_type=${tokenType}&token=${token}`,
        );
        const {
          emailId,
          HotelId,
          fullName,
          isAdmin,
          phoneNo,
          status,
          title,
          id,
          verificationToken,
          loginRedirect,
          UserHotels,
        } = resp.user;
        setItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN, verificationToken);
        setItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID, id);
        const activeHotelId = HotelId || UserHotels?.[0].Hotel.id;
        if (loginRedirect.toLowerCase() === 'profile') {
          const url = `/on-boarding?email=${emailId}&inviteStatus=true&HotelId=${HotelId}&fullName=${fullName}&isAdmin=${isAdmin}&phoneNo=${
            phoneNo || ''
          }&status=${status}&title=${title}`;
          navigate(url);
        } else if (loginRedirect.toLowerCase() === 'homepage') {
          navigateToHome(activeHotelId);
        } else if (loginRedirect.toLowerCase() === 'passwordreset') {
          navigate('/set-password');
        } else {
          navigate('/sign-in');
        }
      } catch (e) {
        navigate('/sign-in');
      }
    })();
  }, []);
  return (
    <Background>
      <CircleDiv>
        <MainDiv>
          <LoaderAnimated />
        </MainDiv>
      </CircleDiv>
    </Background>
  );
};

export default OnBoardingStatus;
