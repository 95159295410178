// React
import { ReactElement } from 'react';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';

// Icons
import SubItemIcon from 'components/Icons/sidebarIcons/subItemIcon';

// Styles
import { SubItemWrapper } from './sidebarOperationTemplate.style';

interface SubItemPropTypes {
  title: string;
  icon: ReactElement;
  isSelected: boolean;
  onClick: () => void;
}

const SubItem = ({ title, icon, isSelected, onClick }: SubItemPropTypes) => {
  return (
    <SubItemWrapper onClick={onClick}>
      <SubItemIcon className="subitem-icon" />
      <SidebarItem
        sidebarOpen={true}
        isSelected={isSelected}
        icon={icon}
        title={title}
        gray
      />
    </SubItemWrapper>
  );
};

export default SubItem;
