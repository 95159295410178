import { statusDropdownData } from 'helper/task/dropdownData';
import React from 'react';
import { EventWrapperStyled } from 'pages/task/TaskListView/TaskCalendar/Renderers/renderer.style';

const CustomEventWrapper = (event: any) => {
  const { children } = event;
  const status = statusDropdownData.find(
    (s) => s.id === event.event.obj.taskStatus,
  );
  return (
    <EventWrapperStyled
      data-attribute="event-wrapper"
      isScheduled={!event.event.obj.taskNumber ? true : false}
      className={'event-wrapper'}
      color={status?.color}
    >
      {children}
    </EventWrapperStyled>
  );
};

export default CustomEventWrapper;
