import { useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import ChecklistIcon from 'components/ChecklistIcon';
import AvatarPopover from 'components/AvatarPopover';
import { formatDate } from 'utils/globalFunction';
import DateTime from 'utils/DateTime';
import { DATE_FORMAT, PROJECT_TYPES } from 'utils/globalVariable';
import { getUserHotelTz } from 'store/selectors/auth';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import RecurringIcon from 'public/assets/img/recurring-v2.svg';
import moment from 'moment-timezone';
import useDateTime from 'utils/CustomHooks/useDateTime';
import {
  AvatarCnt,
  ByCnt,
  CategoryChip,
  CategoryCnt,
  Content,
  DataSet,
  DetailsCnt,
  Dot,
  Heading,
  MainCnt,
  PriorityContent,
  ValueCnt,
  ValueText,
} from './style';

const ProjectDetailView = (props) => {
  const { data } = props;
  const [project, setProject] = useState<any>({});
  const { getUtcToTz } = useDateTime();
  const sidebarOpen = useSelector(selectors.getMenuState);
  const usersHash = useSelector(selectors.getHashedUserList);
  const teamsHash = useSelector(selectors.getTeamsHash);
  const locationsHash = useSelector(selectors.getLocationsHash);

  useEffect(() => {
    setProject(data?.NextTask?.[0]);
  }, [data]);

  const tz = useSelector(getUserHotelTz) || 'America/Chicago';

  const checklistList = useSelector(selectors.getChecklistCompleteDataOnly);
  const categoryList = useSelector(selectors.getServiceAdmin);
  const { addNewAssignees } = useAssigneesUtils();

  const tranformProjectData = useMemo(() => {
    if (project) {
      if (project?.ServiceTypeId) {
        const selectedCategory = categoryList?.find(
          (c) => c.id == project?.ServiceTypeId,
        );
        project.transformedCategory = selectedCategory;
      }

      if (
        data?.type === PROJECT_TYPES.assetBasedProject &&
        project?.assignees?.length > 0
      ) {
        const selectedAssignees = addNewAssignees(project?.assignees);
        project.transformedAssignees = selectedAssignees;
      }

      if (
        data?.type === PROJECT_TYPES.userBasedProject &&
        data?.entityIds?.length > 0
      ) {
        const selectedAssignees = addNewAssignees(data?.entityIds);
        project.transformedAssignees = selectedAssignees;
      }

      return project;
    } else {
      return {};
    }
  }, [
    project,
    checklistList,
    categoryList,
    usersHash,
    teamsHash,
    locationsHash,
  ]);

  const renderCreatorAndAssignee = (createdBy) => {
    return (
      <AvatarCnt>
        <AvatarPopover userId={createdBy} />
      </AvatarCnt>
    );
  };

  const formatTaskDates = (date) => {
    const fullFormat = 'MMM DD YYYY [at] hh:mm A';
    const timeOnly = '[at] hh:mm A';
    let dayString = '';

    const isToday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime().toTz(tz).toTzString(tz, DATE_FORMAT);
    const isYesterday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime()
        .toTz(tz)
        .transform({ subtract: [1, 'day'] })
        .toTzString(tz, DATE_FORMAT);

    if (isToday) {
      dayString = 'Today';
    } else if (isYesterday) {
      dayString = 'Yesterday';
    }
    return dayString.length
      ? `${dayString} ${formatDate(date, timeOnly, '', tz)}`
      : formatDate(date, fullFormat, '', tz);
  };

  const isDaily = useMemo(() => {
    if (tranformProjectData) {
      if (
        tranformProjectData?.requestThisTask === 'Weekly' &&
        tranformProjectData?.intervalWeek?.length === 7 &&
        tranformProjectData?.recurringByEvery === 'Week1'
      ) {
        return 'Daily';
      }
    }
  }, [tranformProjectData]);

  return (
    <MainCnt
      style={{ width: `calc(100vw - ${sidebarOpen ? '330' : '150'}px)` }}
    >
      <DetailsCnt>
        {data?.title && (
          <DataSet>
            <Heading>Project Name</Heading>
            <Content>{data?.title}</Content>
          </DataSet>
        )}

        {tranformProjectData?.description && (
          <DataSet>
            <Heading>Description</Heading>
            <Content>{project?.description}</Content>
          </DataSet>
        )}

        {tranformProjectData?.attachment?.length > 0 && (
          <DataSet style={{ marginBottom: 20 }}>
            <Heading style={{ marginBottom: 10 }}>Attachments</Heading>
            <RenderAttachments attachments={project?.attachment} />
          </DataSet>
        )}

        {tranformProjectData?.Checklist && (
          <DataSet style={{ marginBottom: 20 }}>
            <Heading style={{ marginBottom: 10 }}>Template</Heading>
            <Stack gap="8px" width="400px">
              <Stack
                p="10px 14px"
                direction={'row'}
                sx={{ borderRadius: '8px' }}
                alignItems={'center'}
                bgcolor={'#EBEAFF'}
              >
                {tranformProjectData?.Checklist?.icon && (
                  <ChecklistIcon
                    width={36}
                    height={36}
                    icon={tranformProjectData?.Checklist?.icon}
                  />
                )}
                <Stack maxWidth={'75%'} ml="10px">
                  <Typography
                    sx={{
                      maxWidth: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre',
                    }}
                    fontSize={'16px'}
                    fontWeight={'700'}
                    color="#000"
                  >
                    {tranformProjectData?.Checklist?.name}
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'500'}
                    color="#616161"
                  >
                    {tranformProjectData?.Checklist?.itemsCount} items
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </DataSet>
        )}

        {data?.createdAt && (
          <DataSet>
            <Heading style={{ marginBottom: 5 }}>Created By</Heading>
            <ByCnt>
              {renderCreatorAndAssignee(data?.CreatedBy)}

              <Dot>•</Dot>
              <ValueText customcolor="#757575">
                {formatTaskDates(data?.createdAt)}
              </ValueText>
            </ByCnt>
          </DataSet>
        )}

        {tranformProjectData?.transformedAssignees?.length > 0 && (
          <DataSet>
            <Heading style={{ marginBottom: 5 }}>Assigned To</Heading>
            <Box
              sx={{
                '& p': {
                  fontWeight: 600,
                },
              }}
            >
              <AssigneesView
                type={
                  tranformProjectData?.transformedAssignees?.length === 1
                    ? 'single'
                    : 'avatars'
                }
                data={tranformProjectData?.transformedAssignees}
              />
            </Box>
          </DataSet>
        )}

        {tranformProjectData?.recurringTask && (
          <DataSet>
            <Heading>Schedule</Heading>
            <ValueCnt>
              <img
                src={RecurringIcon}
                style={{ marginRight: 10 }}
                alt="Recurring task"
                title="Recurring"
                width="18"
                height="18"
              />

              <ValueText>
                {'Recurs '}
                {isDaily ||
                  tranformProjectData?.recPatternText.replace(
                    ' until never',
                    '',
                  )}
              </ValueText>
            </ValueCnt>
          </DataSet>
        )}

        {tranformProjectData?.dueDate && (
          <DataSet>
            <Heading>Next Task Due On</Heading>
            <Content>
              {getUtcToTz(
                tranformProjectData?.dueDate,
                'MMM DD, YYYY • hh:mm A',
              ) || '-'}
            </Content>
          </DataSet>
        )}

        {tranformProjectData?.priority && (
          <DataSet>
            <Heading>Priority</Heading>
            <PriorityContent>
              <div
                className={`icon-priority-${project?.priority?.toLowerCase()}`}
                style={{ marginRight: 10 }}
              />
              <ValueText>{project?.priority}</ValueText>
            </PriorityContent>
          </DataSet>
        )}

        {tranformProjectData?.ServiceType && (
          <DataSet>
            <Heading style={{ marginBottom: 5 }}>Category</Heading>
            <CategoryCnt>
              <CategoryChip
                bg={project?.ServiceType?.color}
                color={project?.transformedCategory?.textColor}
                shouldDisplayFlex={true}
                bordercolor={project?.transformedCategory?.borderColor}
              >
                {project?.ServiceType?.type?.charAt(0)?.toUpperCase() +
                  project?.ServiceType?.type?.slice(1)}
              </CategoryChip>
            </CategoryCnt>
          </DataSet>
        )}

        <DataSet>
          <Heading>Project State</Heading>
          <Content>
            {data?.state?.charAt?.(0)?.toUpperCase() + data?.state?.slice?.(1)}
          </Content>
        </DataSet>

        {data?.isPaused != null && (
          <DataSet>
            <Heading>Project Active Status</Heading>
            <Content>{data?.isPaused ? 'Paused' : 'Active'}</Content>
          </DataSet>
        )}
      </DetailsCnt>
    </MainCnt>
  );
};

export default ProjectDetailView;
