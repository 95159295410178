import { Stack } from '@mui/material';
import CircularProgressPercentage from 'components/CircularPercantage';
import { Container, Value } from './style';

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};
export const cellRenderer = {
  title: (params) => {
    if (!params?.data) return;
    const { name } = params.data;
    return (
      <Container>
        <Value
          sx={{
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {name || '-'}
        </Value>
      </Container>
    );
  },
  open: (params) => {
    if (!params?.data) return;
    const { Open_Count } = params.data;
    return (
      <Container>
        <Value>{Open_Count || '-'}</Value>
      </Container>
    );
  },
  inProgress: (params) => {
    if (!params?.data) return;
    const { InProgress_Count } = params.data;
    return (
      <Container>
        <Value>{InProgress_Count || '-'}</Value>
      </Container>
    );
  },
  onHold: (row) => {
    if (!row?.data) return;
    const { OnHold_Count } = row.data;
    return (
      <Container>
        <Value>{OnHold_Count || '-'}</Value>
      </Container>
    );
  },
  completed: (row) => {
    if (!row?.data) return;
    const { Completed_Count } = row.data;
    return (
      <Container>
        <Value>{Completed_Count || '-'}</Value>
      </Container>
    );
  },
  missed: (row) => {
    if (!row?.data) return;
    const { Missed_Count } = row.data;
    return (
      <Container>
        <Value>{Missed_Count || '-'}</Value>
      </Container>
    );
  },
  completedOnTime: (row) => {
    if (!row?.data) return;
    const { CompletedOnTime_Percent } = row.data;
    return (
      <Container>
        <Stack direction="row" alignItems="center" gap="10px">
          <CircularProgressPercentage
            value={CompletedOnTime_Percent}
            primaryColor={'#4CAF50'}
            secondaryColor={'#E0E0E0'}
            containerProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />

          <Value>{CompletedOnTime_Percent}%</Value>
        </Stack>
      </Container>
    );
  },
  completedLate: (row) => {
    if (!row?.data) return;
    const { CompletedLate_Percent } = row.data;
    return (
      <Container>
        <Stack direction="row" alignItems="center" gap="10px">
          <CircularProgressPercentage
            value={CompletedLate_Percent}
            primaryColor={'#EF5350'}
            secondaryColor={'#E0E0E0'}
            containerProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />

          <Value>{CompletedLate_Percent}%</Value>
        </Stack>
      </Container>
    );
  },
  incomplete: (row) => {
    if (!row?.data) return;
    const { Incomplete_Percent } = row.data;
    return (
      <Container>
        <Stack direction="row" alignItems="center" gap="10px">
          <CircularProgressPercentage
            value={Incomplete_Percent}
            primaryColor={'#FB8C00'}
            secondaryColor={'#E0E0E0'}
            containerProps={{
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />

          <Value>{Incomplete_Percent}%</Value>
        </Stack>
      </Container>
    );
  },
};

//List View value getters
//Returned value will be the values to be shown in the cell
export const valueGetters = {
  title: (params) => {
    if (!params?.data) return;
    const { name } = params.data;
    return name;
  },
  open: (params) => {
    if (!params?.data) return;
    const { Open_Count } = params.data;
    return Open_Count;
  },
  inProgress: (params) => {
    if (!params?.data) return;
    const { InProgress_Count } = params.data;
    return InProgress_Count;
  },
  onHold: (params) => {
    if (!params?.data) return;
    const { OnHold_Count } = params.data;
    return OnHold_Count;
  },
  completed: (params) => {
    if (!params?.data) return;
    const { Completed_Count } = params.data;
    return Completed_Count;
  },
  missed: (params) => {
    if (!params?.data) return;
    const { Missed_Count } = params.data;
    return Missed_Count;
  },
  completedOnTime: (params) => {
    if (!params?.data) return;
    const { CompletedOnTime_Percent } = params.data;
    return CompletedOnTime_Percent;
  },
  completedLate: (params) => {
    if (!params?.data) return;
    const { CompletedLate_Percent } = params.data;
    return CompletedLate_Percent;
  },
  incomplete: (params) => {
    if (!params?.data) return;
    const { Incomplete_Percent } = params.data;
    return Incomplete_Percent;
  },
};

export const columns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'title',
      headerName: 'Location',
      colId: 'title',
      sortable: true,
      cellRenderer: cellRenderer.title,
      width: 170,
    },
    {
      field: 'open',
      headerName: 'Open',
      colId: 'open',
      sortable: true,
      valueGetter: valueGetters.open,
      cellRenderer: cellRenderer.open,
      resizable: true,
      width: 120,
    },
    {
      field: 'inProgress',
      headerName: 'In Progress',
      colId: 'inProgress',
      sortable: true,
      valueGetter: valueGetters.inProgress,
      cellRenderer: cellRenderer.inProgress,
      resizable: true,
      width: 120,
    },
    {
      field: 'onHold',
      headerName: 'On Hold',
      colId: 'onHold',
      valueGetter: valueGetters.onHold,
      cellRenderer: cellRenderer.onHold,
      sortable: true,
      width: 120,
    },
    {
      field: 'completed',
      headerName: 'Completed',
      colId: 'completed',
      valueGetter: valueGetters.completed,
      sortable: true,
      cellRenderer: cellRenderer.completed,
      width: 120,
    },
    {
      field: 'missed',
      headerName: 'Missed',
      colId: 'missed',
      sortable: true,
      cellRenderer: cellRenderer.missed,
      valueGetter: valueGetters.missed,
      width: 120,
    },
    {
      field: 'completedOnTime',
      headerName: 'Completion on Time',
      colId: 'completedOnTime',
      sortable: true,
      cellRenderer: cellRenderer.completedOnTime,
      valueGetter: valueGetters.completedOnTime,
      width: 180,
    },
    {
      field: 'completedLate',
      headerName: 'Completed Late',
      colId: 'completedLate',
      sortable: true,
      cellRenderer: cellRenderer.completedLate,
      valueGetter: valueGetters.completedLate,
      width: 150,
    },
    {
      field: 'incomplete',
      headerName: 'Incomplete',
      colId: 'incomplete',
      sortable: true,
      cellRenderer: cellRenderer.incomplete,
      valueGetter: valueGetters.incomplete,
      width: 150,
    },
  ];
  return tableCells.filter((cell) => columnConfig[cell.field]);
};
