import { createSvgIcon } from '@mui/material';

interface IProps {
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const VideoIcon = (props: IProps) => {
  const { bgColor = '#D81B60', fillColor = 'white', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <rect width="32" height="32" rx="6" fill={bgColor} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.181 10H12.069C11.4653 9.99999 10.967 9.99998 10.5611 10.0331C10.1396 10.0676 9.75203 10.1415 9.38803 10.327C8.82354 10.6146 8.3646 11.0735 8.07698 11.638C7.89151 12.002 7.81759 12.3896 7.78315 12.8111C7.74998 13.217 7.74999 13.7153 7.75 14.319V17.681C7.74999 18.2847 7.74998 18.783 7.78315 19.1889C7.81759 19.6104 7.89151 19.998 8.07698 20.362C8.3646 20.9265 8.82354 21.3854 9.38803 21.673C9.75203 21.8585 10.1396 21.9324 10.5611 21.9669C10.967 22 11.4653 22 12.069 22H16.181C16.7847 22 17.283 22 17.6889 21.9669C18.1104 21.9324 18.498 21.8585 18.862 21.673C19.4265 21.3854 19.8854 20.9265 20.173 20.362C20.3585 19.998 20.4324 19.6104 20.4669 19.1889C20.4976 18.8124 20.4998 18.3565 20.5 17.8106L21.9651 19.2758C22.1085 19.4192 22.2494 19.5601 22.373 19.6636C22.4832 19.7558 22.7153 19.9409 23.0367 19.9662C23.3957 19.9944 23.7466 19.8491 23.9805 19.5752C24.1899 19.33 24.2232 19.0351 24.2358 18.8919C24.2501 18.7315 24.25 18.5321 24.25 18.3294V13.6705C24.25 13.4678 24.2501 13.2684 24.2358 13.108C24.2232 12.9648 24.1899 12.6699 23.9805 12.4247C23.7466 12.1508 23.3957 12.0055 23.0367 12.0337C22.7153 12.059 22.4832 12.2441 22.373 12.3363C22.2495 12.4398 22.1085 12.5807 21.9652 12.7241L20.5 14.1893C20.4998 13.6435 20.4976 13.1876 20.4669 12.8111C20.4324 12.3896 20.3585 12.002 20.173 11.638C19.8854 11.0735 19.4265 10.6146 18.862 10.327C18.498 10.1415 18.1104 10.0676 17.6889 10.0331C17.283 9.99998 16.7847 9.99999 16.181 10Z"
        fill={fillColor}
      />
    </>,
    'VideoIcon',
  );
  return <IconCmp viewBox={'0 0 32 32'} {...rest} />;
};
export default VideoIcon;
