// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Custom components
import CustomModal from 'components/CustomModal/CustomModal';

export const Content = styled(Box)(({ theme }) => ({
  padding: '0 24px 0px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

export const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    borderRadius: 0,
  },
});
