import CTabs from 'components/CTabs';
import AssetDetailHeader from 'pages/Assets/AssetDetail/AssetDetailHeader/assetDetailHeader';
import AssetDetailTab from 'pages/Assets/AssetDetail/AssetDetailTab/AssetDetailTab';
import AssetHistory from 'pages/Assets/AssetDetail/AssetHistory/AssetHistory.view';
import { WorkOrderHistoryUpdateDetails } from 'pages/Assets/AssetDetail/AssetHistory/assetHistory.style';
import useAssetDetail from 'pages/Assets/AssetDetail/useAssetDetail';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import useResponsive from 'utils/CustomHooks/useResponsive';
import AssetsSchedules from './AssetSchedules/AssetSchedules.view';
import { useEffect, useState } from 'react';
import ServicesLog from './ServicesLog/ServicesLog';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

function AssetDetailView() {
  const { selectedAsset } = useAssetDetail();

  const userList = useSelector(selectors.getUserAdmin);
  const { getDateInTz } = useDateTime();
  const [fetchSchedulesCounter, setFetchSchedulesCounter] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [loadTabsData, setLoadTabsData] = useState({
    scheduling: true,
    history: true,
  });

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const tabNumber = Number(tab);
      if (tabNumber >= 0 && tabNumber <= 3) {
        setSelectedTab(tabNumber);
      }
    }
  }, [searchParams]);

  const tabs = [
    {
      label: 'Details',
      content: <AssetDetailTab />,
    },
    {
      label: 'Task History',
      content: (
        <AssetHistory
          loadTabsData={loadTabsData}
          setLoadTabsData={setLoadTabsData}
        />
      ),
    },
    {
      label: ' Schedules',
      content: (
        <AssetsSchedules
          fetchSchedulesCounter={fetchSchedulesCounter}
          loadTabsData={loadTabsData}
          setLoadTabsData={setLoadTabsData}
        />
      ),
    },
    ...(selectedAsset && !selectedAsset.ParentId
      ? [
          {
            label: 'Service Log',
            content: (
              <ServicesLog
                isSelected={selectedTab === 3}
                setLoadTabsData={setLoadTabsData}
              />
            ),
          },
        ]
      : []),
  ];

  const { assetNumber, createdAt, updatedAT, CreatedBy, UpdatedBy } =
    selectedAsset;
  const createdAtFormated = getDateInTz(createdAt, 'MMM DD YYYY');
  const updatedAtFormated = getDateInTz(updatedAT, 'MMM DD YYYY');
  const createdByUser = userList?.find((u) => u?.id == CreatedBy);
  const updatedByUser = userList?.find((u) => u?.id == UpdatedBy);
  const createdByFullName = `${createdByUser?.firstName} ${createdByUser?.lastName}`;
  const updatedByFullName = `${updatedByUser?.firstName} ${updatedByUser?.lastName}`;

  const { isMobileDeviceSm } = useResponsive();

  const fetchSchedulesUpdate = () => {
    setFetchSchedulesCounter(fetchSchedulesCounter + 1);
  };

  return (
    <>
      <AssetDetailHeader onAssignWorkCb={fetchSchedulesUpdate} />
      <CTabs
        data={tabs}
        activeTab={selectedTab}
        onTabChangeCb={(selected) => {
          if (selected === 2) {
            fetchSchedulesUpdate();
          }

          setSelectedTab(selected);
          navigate(`${location.pathname}?tab=${selected}`);
        }}
        rootSx={{ margin: '0px !important' }}
      />
      <WorkOrderHistoryUpdateDetails>
        <div className={'historyUpdateDetailsItem'}>
          <label className={'label'}>Asset ID:</label>
          <span className="value">&nbsp;{assetNumber}</span>
        </div>

        <div className={'historyUpdateDetailsItem'}>
          {isMobileDeviceSm ? (
            <label className={'label'}>Created by:</label>
          ) : (
            <label className={'label'}>&nbsp;•&nbsp;Created by:</label>
          )}
          <span className="value">
            <>
              {' '}
              &nbsp;{createdByFullName} on {createdAtFormated}
            </>
          </span>
        </div>
        <div className={'historyUpdateDetailsItem'}>
          {isMobileDeviceSm ? (
            <label className={'label'}>Last Updated by:&nbsp;</label>
          ) : (
            <label className={'label'}>
              &nbsp;•&nbsp;Last Updated by:&nbsp;
            </label>
          )}
          <span className="value">
            <>
              {updatedByFullName} on &nbsp;{updatedAtFormated}
            </>
          </span>
        </div>
      </WorkOrderHistoryUpdateDetails>
    </>
  );
}

export default AssetDetailView;
