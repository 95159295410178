import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const IconCnt = styled('span')({
  display: 'flex',
  '& svg': {
    fontSize: 18,
  },
});
export const GroupByBtn = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected }) => ({
  height: 30,
  padding: '6px 8px',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  color: selected ? '#6868FE' : '#000000DE',
  background: selected ? '#EBEAFF' : '#fff',
  marginRight: 8,
  '& .MuiButton-startIcon': {
    marginRight: 4,
    height: 18,
    width: 18,
    alignItems: 'center',
    '& svg': {
      color: selected ? '#6868FE' : '#000000DE',
      fontSize: 18,
    },
  },
}));
