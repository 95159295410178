import React from 'react';

// Styled
import { Container, StyledIconButton } from './styled';

// Icons
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

export type ViewType = 'list' | 'grid';

interface ListGridTogglePropTypes {
  selectedView: ViewType;
  setSelectedView: (view: ViewType) => void;
}

const ListGridToggle = ({
  selectedView,
  setSelectedView,
}: ListGridTogglePropTypes) => {
  return (
    <Container>
      <StyledIconButton
        isactive={selectedView === 'list'}
        variant={'text'}
        onClick={() => setSelectedView('list')}
        startIcon={<FormatListBulletedOutlinedIcon />}
      >
        List
      </StyledIconButton>
      <StyledIconButton
        isactive={selectedView === 'grid'}
        variant={'text'}
        onClick={() => setSelectedView('grid')}
        startIcon={<GridViewOutlinedIcon />}
      >
        Grid
      </StyledIconButton>
    </Container>
  );
};

export default ListGridToggle;
