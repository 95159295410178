import { useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SchedulesTable from 'components/DataTables/Reports/SchedulesTable';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import {
  WidgetCnt,
  WidgetHeading,
  WidgetLoaderCnt,
  WidgetSubHeading,
} from './style';

function SchedulesCompletionStats(props) {
  const { loading, data, title, globalFilters } = props;
  const dispatch = useDispatch();

  const clickHandler = (id) => {
    const advancedFilters: any = [
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'schedules',
        value: [id],
      },
      {
        comparator: 'is',
        conditional: 'and',
        filterName: 'taskTypes',
        value: ['recurring'],
      },
    ];

    if (globalFilters?.fromDate && globalFilters?.toDate) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'dueDate',
        value: [globalFilters?.fromDate, globalFilters?.toDate],
      });
    }

    if (globalFilters?.locations?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'locations',
        value: globalFilters.locations,
      });
    }

    if (globalFilters?.projects?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'projects',
        value: globalFilters.projects,
      });
    }

    if (globalFilters?.schedules?.length > 0) {
      advancedFilters.push({
        comparator: 'is',
        conditional: 'and',
        filterName: 'schedules',
        value: globalFilters.schedules,
      });
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TASK_TABLE_DIALOG,
        data: {
          filters: advancedFilters,
        },
      }),
    );
  };

  return (
    <WidgetCnt>
      <WidgetHeading>{title}</WidgetHeading>
      {!loading && <WidgetSubHeading>{data?.summary?.total}</WidgetSubHeading>}
      {loading ? (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      ) : (
        <Stack height={'400px'}>
          <SchedulesTable rowData={data?.table} onRowClick={clickHandler} />
        </Stack>
      )}
    </WidgetCnt>
  );
}

export default SchedulesCompletionStats;
