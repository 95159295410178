import DateTime from 'utils/DateTime';

export const dateRangePickerSideMenuFuture = (tz) => {
  return [
    {
      label: 'Today',
      range: {
        from: new DateTime().toTz(tz).transform({ startOf: 'day' }).toJSDate(),
        to: new DateTime().toTz(tz).transform({ endOf: 'day' }).toJSDate(),
      },
    },
    {
      label: 'Next 7 days',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ add: [0, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ add: [6, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'Next 14 days',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ add: [0, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ add: [13, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'Next 21 days',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ add: [0, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ add: [20, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'Next 28 days',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ add: [0, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ add: [28, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'Custom',
      range: { from: undefined, to: undefined },
    },
  ];
};

export const dateRangePickerSideMenuCommon = (tz) => {
  return [
    {
      label: 'Any',
      range: {
        from: null,
        to: null,
      },
    },
    {
      label: 'Today',
      range: {
        from: new DateTime().toTz(tz).transform({ startOf: 'day' }).toJSDate(),
        to: new DateTime().toTz(tz).transform({ endOf: 'day' }).toJSDate(),
      },
    },
    {
      label: 'Yesterday',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ subtract: [1, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ subtract: [1, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'This Week',
      range: {
        from: new DateTime().toTz(tz).transform({ startOf: 'week' }).toJSDate(),
        to: new DateTime().toTz(tz).transform({ endOf: 'week' }).toJSDate(),
      },
    },
    {
      label: 'Last 7 Days',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ subtract: [6, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ subtract: [0, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'Last 2 Weeks',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ subtract: [13, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ subtract: [0, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'This Month',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ startOf: 'month' })
          .toJSDate(),
        to: new DateTime().toTz(tz).transform({ endOf: 'month' }).toJSDate(),
      },
    },
    {
      label: 'Last 30 Days',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ subtract: [29, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ subtract: [0, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'Last 90 Days',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ subtract: [89, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ subtract: [0, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'Last 2 Months',
      range: {
        from: new DateTime()
          .toTz(tz)
          .transform({ subtract: [59, 'day'], startOf: 'day' })
          .toJSDate(),
        to: new DateTime()
          .toTz(tz)
          .transform({ subtract: [0, 'day'], endOf: 'day' })
          .toJSDate(),
      },
    },
    {
      label: 'Custom',
      range: { from: undefined, to: undefined },
    },
  ];
};

export const reportingInitialRange = (tz) => {
  return {
    from: new DateTime()
      .toTz(tz)
      .transform({ subtract: [29, 'day'], startOf: 'day' })
      .toJSDate(),
    to: new DateTime()
      .toTz(tz)
      .transform({ subtract: [0, 'day'], endOf: 'day' })
      .toJSDate(),
  };
};
