import { createSvgIcon } from '@mui/material';

interface IProps {
  bgColor?: string;
  fillColor?: string;
  [x: string]: any;
}

const CSVIcon = (props: IProps) => {
  const { bgColor = '#009688', fillColor = 'white', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <rect width="32" height="32" rx="6" fill={bgColor} />
      <path
        d="M15.1563 8.9125C15.1563 8.43996 15.1563 8.20368 15.0643 8.0232C14.9834 7.86443 14.8543 7.73536 14.6956 7.65446C14.5151 7.5625 14.2788 7.5625 13.8063 7.5625H11.0737C10.629 7.56249 10.2452 7.56247 9.9292 7.5883C9.59562 7.61555 9.26385 7.67572 8.94459 7.83839C8.4683 8.08107 8.08107 8.4683 7.83839 8.94459C7.67572 9.26385 7.61555 9.59562 7.5883 9.9292C7.56247 10.2452 7.56249 10.6289 7.5625 11.0737C7.5625 11.3394 7.5625 11.4722 7.59216 11.5811C7.67072 11.8696 7.89606 12.0949 8.18452 12.1735C8.2934 12.2031 8.42624 12.2031 8.69193 12.2031H13.8063C14.2788 12.2031 14.5151 12.2031 14.6956 12.1112C14.8543 12.0303 14.9834 11.9012 15.0643 11.7424C15.1563 11.5619 15.1563 11.3257 15.1563 10.8531V8.9125Z"
        fill={fillColor}
      />
      <path
        d="M8.9125 13.8906C8.43996 13.8906 8.20368 13.8906 8.0232 13.9826C7.86443 14.0635 7.73536 14.1926 7.65446 14.3513C7.5625 14.5318 7.5625 14.7681 7.5625 15.2406L7.5625 16.7594C7.5625 17.2319 7.5625 17.4682 7.65446 17.6487C7.73536 17.8074 7.86443 17.9365 8.0232 18.0174C8.20368 18.1094 8.43996 18.1094 8.9125 18.1094H13.8063C14.2788 18.1094 14.5151 18.1094 14.6956 18.0174C14.8543 17.9365 14.9834 17.8074 15.0643 17.6487C15.1563 17.4682 15.1563 17.2319 15.1563 16.7594L15.1563 15.2406C15.1563 14.7681 15.1563 14.5318 15.0643 14.3513C14.9834 14.1926 14.8543 14.0635 14.6956 13.9826C14.5151 13.8906 14.2788 13.8906 13.8063 13.8906H8.9125Z"
        fill={fillColor}
      />
      <path
        d="M8.6919 19.7969C8.42624 19.7969 8.29341 19.7969 8.18454 19.8265C7.89607 19.9051 7.67071 20.1304 7.59215 20.4189C7.5625 20.5278 7.5625 20.6606 7.5625 20.9263C7.56249 21.371 7.56247 21.7548 7.5883 22.0708C7.61555 22.4044 7.67572 22.7362 7.83839 23.0554C8.08107 23.5317 8.4683 23.9189 8.94459 24.1616C9.26385 24.3243 9.59562 24.3845 9.9292 24.4117C10.2452 24.4375 10.6289 24.4375 11.0737 24.4375H13.8063C14.2788 24.4375 14.5151 24.4375 14.6956 24.3455C14.8543 24.2646 14.9834 24.1356 15.0643 23.9768C15.1563 23.7963 15.1563 23.56 15.1563 23.0875V21.1469C15.1563 20.6743 15.1563 20.4381 15.0643 20.2576C14.9834 20.0988 14.8543 19.9697 14.6956 19.8888C14.5151 19.7969 14.2788 19.7969 13.8063 19.7969H8.6919Z"
        fill={fillColor}
      />
      <path
        d="M16.8438 23.0875C16.8438 23.56 16.8438 23.7963 16.9357 23.9768C17.0166 24.1356 17.1457 24.2646 17.3044 24.3455C17.4849 24.4375 17.7212 24.4375 18.1938 24.4375H20.9263C21.3711 24.4375 21.7548 24.4375 22.0708 24.4117C22.4044 24.3845 22.7362 24.3243 23.0554 24.1616C23.5317 23.9189 23.9189 23.5317 24.1616 23.0554C24.3243 22.7362 24.3845 22.4044 24.4117 22.0708C24.4375 21.7548 24.4375 21.3711 24.4375 20.9263C24.4375 20.6606 24.4375 20.5278 24.4078 20.4189C24.3293 20.1304 24.1039 19.9051 23.8155 19.8265C23.7066 19.7969 23.5738 19.7969 23.3081 19.7969H18.1938C17.7212 19.7969 17.4849 19.7969 17.3044 19.8888C17.1457 19.9697 17.0166 20.0988 16.9357 20.2576C16.8438 20.4381 16.8438 20.6743 16.8438 21.1469V23.0875Z"
        fill={fillColor}
      />
      <path
        d="M23.0875 18.1094C23.56 18.1094 23.7963 18.1094 23.9768 18.0174C24.1356 17.9365 24.2646 17.8074 24.3455 17.6487C24.4375 17.4682 24.4375 17.2319 24.4375 16.7594V15.2406C24.4375 14.7681 24.4375 14.5318 24.3455 14.3513C24.2646 14.1926 24.1356 14.0635 23.9768 13.9826C23.7963 13.8906 23.56 13.8906 23.0875 13.8906H18.1938C17.7212 13.8906 17.4849 13.8906 17.3044 13.9826C17.1457 14.0635 17.0166 14.1926 16.9357 14.3513C16.8438 14.5318 16.8438 14.7681 16.8438 15.2406L16.8438 16.7594C16.8438 17.2319 16.8438 17.4682 16.9357 17.6487C17.0166 17.8074 17.1457 17.9365 17.3044 18.0174C17.4849 18.1094 17.7212 18.1094 18.1938 18.1094H23.0875Z"
        fill={fillColor}
      />
      <path
        d="M24.4375 11.0737C24.4375 11.3394 24.4375 11.4722 24.4078 11.5811C24.3293 11.8696 24.1039 12.0949 23.8155 12.1735C23.7066 12.2031 23.5738 12.2031 23.3081 12.2031H18.1938C17.7212 12.2031 17.4849 12.2031 17.3044 12.1112C17.1457 12.0303 17.0166 11.9012 16.9357 11.7424C16.8438 11.5619 16.8438 11.3257 16.8438 10.8531V8.9125C16.8438 8.43996 16.8438 8.20368 16.9357 8.0232C17.0166 7.86443 17.1457 7.73536 17.3044 7.65446C17.4849 7.5625 17.7212 7.5625 18.1938 7.5625H20.9263C21.371 7.56249 21.7548 7.56247 22.0708 7.5883C22.4044 7.61555 22.7362 7.67572 23.0554 7.83839C23.5317 8.08107 23.9189 8.4683 24.1616 8.94459C24.3243 9.26385 24.3845 9.59562 24.4117 9.9292C24.4375 10.2452 24.4375 10.6289 24.4375 11.0737Z"
        fill={fillColor}
      />
    </>,
    'CSVIcon',
  );
  return <IconCmp viewBox={'0 0 32 32'} {...rest} />;
};
export default CSVIcon;
