import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import actions from 'store/actions';

import { Stack, Typography, Box } from '@mui/material';
import CustomAvatarGroup from 'components/Avatar/AvatarGroup/AvatarGroup.cmp';

// Components
import Chip from './Chip';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';

// Icons
import ImageIcon from 'components/Icons/imageIcon';
import ChildItemIcon from 'components/Icons/childItemIcon';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useUpdateLocation from 'utils/CustomHooks/useUpdateLocation';

// Constants
import DIALOGS from 'utils/dialogIds';

interface Props {
  location: any;
  hideMembers?: boolean;
}

const LocationTile = ({ location, hideMembers = false }: Props) => {
  const dispatch = useDispatch();
  const { updateLocation, incrementLocationAssetCountRedux } =
    useUpdateLocation();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const treshold = 3;
  const hasMoreMembers = useMemo(
    () => location?.Members?.length > treshold,
    [location],
  );

  const handleClickTile = () => {
    const urlToNavigate = location.ParentId
      ? `/locations/${location?.ParentId}/sl/${location?.id}`
      : `/locations/${location?.id}`;
    navigateWithWorkspaceUrl(urlToNavigate);
  };

  const handleUpdateLocationColor = (color) => {
    updateLocation?.({ ...location, avatar: { color } });
  };

  const handleClickAddAsset = (event) => {
    event.stopPropagation();
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_ASSET,
        data: {
          onAssetCreateCb: () => incrementLocationAssetCountRedux(location),
        },
      }),
    );
  };

  return (
    <Stack
      onClick={handleClickTile}
      borderRadius={'12px'}
      gap={'10px'}
      paddingBottom={'14px'}
      border={'1px solid #EEE'}
      boxShadow={'0px 2px 14px 0px rgba(0, 0, 0, 0.04)'}
      minHeight={'183px'}
      maxHeight={'183px'}
      width={'100%'}
      bgcolor={'#fff'}
      overflow={'hidden'}
      sx={{
        transition: '0.2s all',
        cursor: 'pointer',
        '&:hover': {
          border: '1px solid #6868FE',
        },
      }}
    >
      {location.attachments?.length > 0 ? (
        <img
          height={'100px'}
          width={'100%'}
          style={{
            objectFit: 'cover',
          }}
          src={location.attachments[0]}
        />
      ) : (
        <Stack
          bgcolor={'#ECEFF1'}
          height={'100px'}
          width="100%"
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ImageIcon />
        </Stack>
      )}

      <Stack padding={'0px 16px'} gap={'8px'}>
        <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
          {location.ParentId ? (
            <Box
              sx={{
                '& svg': {
                  fontSize: '16px',
                  color: 'rgba(117, 117, 117, 1)',
                },
              }}
              height={'max-content'}
            >
              <ChildItemIcon />
            </Box>
          ) : (
            <Box onClick={(e) => e.stopPropagation()}>
              <ColorsDropdown
                selected={location?.avatar?.color}
                changeHandler={handleUpdateLocationColor}
                iconStyles={{
                  width: 10,
                  height: 10,
                  borderRadius: '2px',
                  border: 'none',
                }}
              />
            </Box>
          )}

          <Typography
            maxWidth={'90%'}
            fontSize={'16px'}
            fontWeight={'700'}
            color={'#212121'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'pre'}
          >
            {location.name}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} gap={'6px'} alignItems={'center'}>
            {location?.Sublocations?.length > 0 && (
              <Chip
                type={'sublocations'}
                label={location?.Sublocations?.length}
              />
            )}
            {location?.assetCount > 0 && (
              <Chip type={'assets'} label={location?.assetCount} />
            )}
            {location?.assetCount === 0 && (
              <Chip
                onClickAddAsset={handleClickAddAsset}
                type={'addAsset'}
                label={'Add asset'}
              />
            )}
          </Stack>
          {!hideMembers && (
            <Stack direction={'row'} gap={'1px'} alignItems={'center'}>
              <CustomAvatarGroup
                width={24}
                height={24}
                members={
                  hasMoreMembers
                    ? location.Members.slice(0, treshold)
                    : location.Members
                }
                max={3}
              />
              {hasMoreMembers && (
                <Typography
                  fontSize={'13px'}
                  fontWeight={'700'}
                  color={'rgba(78, 72, 250, 1)'}
                >
                  +{location?.Members?.length - treshold}
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LocationTile;
