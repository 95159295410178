import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import CustomListView from 'components/ListView/Listview.cmp';
import { columns, defaultColDef, rowHeight } from './constants';
import { ListCnt } from './list.style';
import { useQueryClient } from 'react-query';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { IDashboardItem, IDashboardList } from 'pages/Dashboards/interfaces';
import { QUERY_KEYS } from 'api/constants';
import { DashboardsContext } from 'pages/Dashboards/context/context';
import DashboardMainEmptyState from 'pages/Dashboards/EmptyState/mainEmptyState';

interface IActionDropodwnProps {
  onDeleteCallback: (dashboardId: string) => void;
}
interface IColsProps {
  actionDropodwnProps: IActionDropodwnProps;
}
function DashboardsListView(props) {
  const queryClient = useQueryClient();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dashboards: IDashboardList | undefined = queryClient.getQueryData([
    QUERY_KEYS.GET_DASHBOARDS,
  ]);
  const onDeleteCallback = (dashboardId) => {
    queryClient.setQueryData([QUERY_KEYS.GET_DASHBOARDS], () => {
      return {
        ...dashboards,
        dashboards: dashboards?.dashboards?.filter(
          (item: IDashboardItem) => item.id !== dashboardId,
        ),
      };
    });
  };
  const columnProps: IColsProps = { actionDropodwnProps: { onDeleteCallback } };
  const colConfig = useMemo(() => columns(columnProps), []);
  const { dashboardsState, updateDashboardsState } =
    useContext(DashboardsContext);
  const { searchQuery } = dashboardsState;
  const onRowClick = (item: IDashboardItem) => {
    navigateWithWorkspaceUrl(`/dashboards/${item}`);
  };

  // const filteredData = useMemo(() => {
  //   return selectedFolder ? documents : union(folders, documents);
  // }, [folders, documents, selectedFolder]);

  const onGridResize = (event) => {
    event.api.sizeColumnsToFit();
  };
  const filteredDashboards: IDashboardItem[] = useMemo(
    () =>
      dashboards?.dashboards?.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()),
      ) || [],
    [dashboards, searchQuery],
  );
  const dashboardsData: IDashboardItem[] | undefined = searchQuery
    ? filteredDashboards
    : dashboards?.dashboards;
  return dashboards?.dashboards?.length === 0 ? (
    <DashboardMainEmptyState />
  ) : (
    <ListCnt>
      <CustomListView
        rootProps={{
          style: {
            height: 'calc(100vh - 200px)',
            boxShadow: 'none',
            border: '1px solid #d3d3d382',
            borderRadius: 8,
            overflow: 'hidden',
          },
        }}
        onRowClick={onRowClick}
        gridProps={{
          rowData: dashboardsData,
          ref: (ref) => {
            globalThis.dashboardsListView = ref;
          },
          onGridSizeChanged: onGridResize,
          headerHeight: 30,
          suppressChangeDetection: true,
          defaultColDef: defaultColDef,
          columnDefs: colConfig,
          suppressCellFocus: true,
          getRowHeight: 100,
          suppressRowClickSelection: true,
          getRowId: (params) => params.data.id,
        }}
      />
    </ListCnt>
  );
}

export default DashboardsListView;
