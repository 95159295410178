import CustomButton from 'components/Button/CustomButton';
import ComposeMessageIcon from 'components/Icons/composeMessageIcon';
import MessageEmptyStateChatIcon from 'components/Icons/messageEmptyStateChatIcon';
import React, { FC } from 'react';
import { MessageEmptyStateStyled } from './MessageEmptyState.styled';
export const MessageEmptyState: FC<any> = ({ onBtnClick }: any) => {
  return (
    <MessageEmptyStateStyled>
      <MessageEmptyStateChatIcon />
      <p className="title">No chats</p>
      <p className="description">
        Tap on the button below to start
        <br /> a new conversation
      </p>
      <CustomButton
        startIcon={<ComposeMessageIcon />}
        variant="contained"
        onClick={onBtnClick}
      >
        Start Conversation
      </CustomButton>
    </MessageEmptyStateStyled>
  );
};
