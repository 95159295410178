import React, { useMemo, useRef, useState } from 'react';
import { cloneDeep, map } from 'lodash';
import {
  Field,
  Form,
  Formik,
  getIn,
  useFormikContext,
  FieldArray,
} from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { createBulkUsers } from 'api/userApi';
import { CTypographyInvite } from 'pages/user-management/styles';
import PhoneEmailInput from 'components/Dropdown/PhoneCodeInput';
import CustomButton from 'components/Button/CustomButton';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from 'components/Icons/deleteIcon';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { IconButton, Stack } from '@mui/material';
import { DeleteCnt } from './invite.style';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { useUserRoles } from 'utils/CustomHooks/useUserRoles';
import {
  LocationBtnWrapper,
  LocationMembershipWrapper,
  LocationsActionBtn,
  LocationsActionWrapper,
  UserTypeWrapper,
} from './bulkInviteV2/style';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { setDialog } from 'store/actions/dialogActions';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';

const FormSubmitState = ({ setButtonIsSubmitting }) => {
  const { values, isSubmitting } = useFormikContext();

  React.useEffect(() => {
    setButtonIsSubmitting(isSubmitting);
  }, [isSubmitting]);
  return null;
};

const defaultInvite = {
  fullName: '',
  emailPhone: '',
  userType: '',
  locations: [],
};
const BulkInviteByEmailPhoneForm = ({
  dialogId,
  setButtonIsSubmitting,
  showSubmitButton = false,
  hotelId = null as string | null | undefined,
  skipButton = null as any,
  customActions: CustomActions = null as any,
  type = 'both',
  inviteBy = 'email',
  hideElements = {} as any,
  onSuccess = () => {
    console.log('invites sent');
  },
}) => {
  const { addNewAssignees } = useAssigneesUtils();
  const { config } = useWorkspaceConfig();
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const dispatch = useDispatch();
  const [details, setDetails] = useState<any>([[]]);
  const [selectedLoc, setSelectedLoc] = useState<any>([[]]);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const { roles } = useUserRoles();
  const formikRef: any = useRef(null);
  const fullNameRef: any = useRef(null);
  const [phoneCode, setPhoneCode] = useState<any>(null);
  const initialValues = {
    invites: [defaultInvite],
  };

  const validationSchemaModel = Yup.object({
    invites: Yup.array().of(
      Yup.object().shape({
        fullName: Yup.string()
          .max(80, 'Must be 80 characters or less')
          .required('Please provide valid full name (first & last)'),
        emailPhone: Yup.string()
          .required(
            type === 'both'
              ? 'Please provide valid email address / phone'
              : `Please provide valid ${type}`,
          )
          .test(
            'Validate email or phone',
            'Please enter valid input',
            function (value): boolean {
              if (value !== null && value !== undefined) {
                const type: any = value.includes('@') ? 'email' : 'phone';
                if (
                  type === 'email' &&
                  /^([a-z0-9_.-]+)@([\da-z.-]+).([a-z.]{2,6})$/i.test(value)
                ) {
                  return true;
                } else if (/^[+]*[0-9]{5,14}$/.test(value)) {
                  return true;
                }
                return false;
              }
              return false;
            },
          ),
        userType: Yup.string()
          .required('User Type is required')
          .test('select a value', 'User Type is required', (value) => {
            if (value === '0') return false;
            return true;
          }),
        locations: Yup.array().of(Yup.string()),
      }),
    ),
  });

  const handleSubmit = async (values, resetForm) => {
    const rows = map(values?.invites, (invite) => {
      const name = invite.fullName.split(' ');
      const firstName = name.shift();
      const lastName = name.join(' ');

      let inviteUser: any = {
        firstName: firstName,
        lastName: lastName,
        roleId: invite.userType,
        invite: 'xenia',
        hotelId,
        ...(invite?.locations?.length > 0 && {
          locations: invite?.locations,
        }),
      };
      if (invite.emailPhone.includes('@')) {
        inviteUser = {
          ...inviteUser,
          emailId: invite.emailPhone.toLowerCase(),
        };
      } else {
        inviteUser = {
          ...inviteUser,
          phoneNo: phoneCode.obj.dial_code + invite.emailPhone,
        };
      }
      return { data: inviteUser };
    });

    const response = await createBulkUsers(
      { invite: true, isOnboardingFlow: true, rows, inviteBy },
      hotelId,
    );

    if (response) {
      onSuccess();
    }
  };

  const handleLocationsChange = (selectedLocations, updateIndex) => {
    const inviteUsers = formikRef?.current?.values?.invites;
    const updated = inviteUsers.map((item, i) => {
      if (i === updateIndex) {
        return {
          ...item,
          locations: selectedLocations || [],
        };
      }
      return item;
    });
    formikRef.current.values.invites = updated;
  };

  const locationsUpdateHandler = (data, updatedIndex) => {
    const locationsDetails = addNewAssignees(
      data?.selectedList?.locations ?? [],
    );

    const updatedDetails = details?.map((item, index) => {
      if (index === updatedIndex) {
        return locationsDetails;
      }

      return item;
    });

    setDetails(updatedDetails);

    const updatedSelectedLocations = selectedLoc?.map((item, index) => {
      if (index === updatedIndex) {
        return data?.selectedList ?? [];
      }

      return item;
    });

    setSelectedLoc(updatedSelectedLocations);
    handleLocationsChange?.(data?.selectedList?.locations ?? [], updatedIndex);
  };

  const locationsSelectedText = useMemo(() => {
    return details?.map((item) => {
      if (item?.length > 1) {
        return `${item?.[0]?.Location?.name?.slice(0, 7)} ${
          item?.[0]?.Location?.name?.length > 7 ? '... ' : ''
        }, +${item?.length - 1}`;
      }

      if (item?.length === 1) {
        return `${item?.[0]?.Location?.name?.slice(0, 10)} ${
          item?.[0]?.Location?.name?.length > 10 ? '... ' : ''
        }`;
      }

      return 'Select';
    });
  }, [details]);

  const updateLocationsAfterRemove = (index) => {
    const clonedDetails = cloneDeep(details);
    clonedDetails.splice(index, 1);
    setDetails(clonedDetails);

    const clonedSelectedLoc = cloneDeep(selectedLoc);
    clonedSelectedLoc.splice(index, 1);
    setSelectedLoc(clonedSelectedLoc);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaModel}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await handleSubmit(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({ touched, values, errors, getFieldProps, isSubmitting }) => (
        <Form id={dialogId}>
          <FormSubmitState setButtonIsSubmitting={setButtonIsSubmitting} />
          <Grid container spacing={1} sx={{ pt: 1 }}>
            <Grid
              item
              xs={
                !hideElements?.locationMembership &&
                config?.advancedLocationBasedAssignment
                  ? 3
                  : 4
              }
            >
              <CTypographyInvite>Full Name</CTypographyInvite>
            </Grid>
            <Grid
              item
              xs={
                !hideElements?.locationMembership &&
                config?.advancedLocationBasedAssignment
                  ? 4
                  : 5
              }
            >
              <CTypographyInvite>
                {type === 'both' ? 'Email/Phone' : type}
              </CTypographyInvite>
            </Grid>
            <Grid
              item
              xs={
                !hideElements?.locationMembership &&
                config?.advancedLocationBasedAssignment
                  ? 2
                  : 3
              }
            >
              <CTypographyInvite>User Type</CTypographyInvite>
            </Grid>

            {!hideElements?.locationMembership &&
              config?.advancedLocationBasedAssignment && (
                <Grid item xs={3}>
                  <CTypographyInvite>Location Membership</CTypographyInvite>
                </Grid>
              )}

            <FieldArray name="invites">
              {({ remove, push }) => (
                <>
                  {values.invites.length > 0 &&
                    values.invites.map((invite, index) => {
                      return (
                        <>
                          <Grid
                            item
                            xs={
                              !hideElements?.locationMembership &&
                              config?.advancedLocationBasedAssignment
                                ? 3
                                : 4
                            }
                          >
                            <Field name={`invites.${index}.fullName`}>
                              {(props) => {
                                const error = getIn(
                                  props.form.errors,
                                  `invites.${index}.fullName`,
                                );
                                const touch = getIn(
                                  props.form.touched,
                                  `invites.${index}.fullName`,
                                );

                                return (
                                  <CustomInput
                                    fwdRef={fullNameRef}
                                    error={touch && error}
                                    sx={{
                                      mb: '10px',
                                      '& .select-box__control': {
                                        border: '1px solid transparent',
                                        cursor: 'pointer',
                                      },
                                    }}
                                    fullWidth
                                    fieldProps={{
                                      ...props.field,
                                      ...getFieldProps(
                                        `invites.${index}.fullName`,
                                      ),
                                      autoFocus: true,
                                      placeholder: 'Full name',
                                    }}
                                    grayInput={true}
                                  />
                                );
                              }}
                            </Field>
                          </Grid>

                          <Grid
                            item
                            xs={
                              !hideElements?.locationMembership &&
                              config?.advancedLocationBasedAssignment
                                ? 4
                                : 5
                            }
                          >
                            <Field name={`invites.${index}.emailPhone`}>
                              {(props) => {
                                const error = getIn(
                                  props.form.errors,
                                  `invites.${index}.emailPhone`,
                                );
                                const touch = getIn(
                                  props.form.touched,
                                  `invites.${index}.emailPhone`,
                                );
                                return type === 'Email' ? (
                                  <CustomInput
                                    error={touch && error}
                                    muiLabel={true}
                                    grayInput={true}
                                    fieldProps={{
                                      ...props.field,
                                      ...getFieldProps(
                                        `invites.${index}.emailPhone`,
                                      ),
                                      placeholder: 'Email',
                                    }}
                                  />
                                ) : (
                                  <PhoneEmailInput
                                    suppressEmail={type != 'both'}
                                    error={touch && error}
                                    fieldProps={{
                                      ...props.field,
                                      ...getFieldProps(
                                        `invites.${index}.emailPhone`,
                                      ),
                                      placeholder:
                                        type === 'both'
                                          ? 'Email or Phone Number'
                                          : 'Phone Number',
                                    }}
                                    onPhoneCodeSelect={setPhoneCode}
                                    formControlProps={{
                                      grayInput: true,
                                    }}
                                  />
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid
                            item
                            xs={
                              !hideElements?.locationMembership &&
                              config?.advancedLocationBasedAssignment
                                ? 2
                                : 3
                            }
                            style={{ position: 'relative' }}
                          >
                            <Field
                              as="Select"
                              name={`invites.${index}.userType`}
                            >
                              {(props) => {
                                const error = getIn(
                                  props.form.errors,
                                  `invites.${index}.userType`,
                                );
                                const touch = getIn(
                                  props.form.touched,
                                  `invites.${index}.userType`,
                                );

                                const propsData = {
                                  ...props.field,
                                  ...getFieldProps(`invites.${index}.userType`),
                                };

                                return (
                                  <UserTypeWrapper>
                                    <CustomSelect
                                      name="userType"
                                      placeholder="Select"
                                      error={touch && error}
                                      selectedOption={
                                        roles?.find(
                                          (item) =>
                                            item.value === propsData?.value,
                                        ) || null
                                      }
                                      onChange={(selected) => {
                                        propsData.onChange({
                                          target: {
                                            value: selected.value,
                                            name: selected.name,
                                          },
                                        });
                                      }}
                                      options={roles.map((item) => {
                                        return {
                                          ...item,
                                          name: `invites.${index}.userType`,
                                        };
                                      })}
                                      styles={{
                                        menu: (styles) => ({
                                          ...styles,
                                          width: '300px',
                                        }),
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      grayInput={true}
                                    />
                                  </UserTypeWrapper>
                                );
                              }}
                            </Field>
                          </Grid>
                          {!hideElements?.locationMembership &&
                            config?.advancedLocationBasedAssignment && (
                              <Grid
                                item
                                xs={3}
                                style={{ position: 'relative' }}
                              >
                                <>
                                  <LocationMembershipWrapper>
                                    <AssigneeDropdown
                                      handleChangeCallback={(data) =>
                                        locationsUpdateHandler(data, index)
                                      }
                                      buttonRendererProps={{
                                        width: 155,
                                        style: {
                                          pointerEvents: 'all',
                                        },
                                      }}
                                      buttonRenderer={
                                        <LocationBtnWrapper>
                                          <CustomButton
                                            variant="outlined"
                                            buttonType="grayWhite"
                                            style={{
                                              width: 155,
                                              height: 42,
                                            }}
                                            endIcon={
                                              <ArrowDropDownIcon
                                                style={{ fontSize: 24 }}
                                              />
                                            }
                                          >
                                            {locationsSelectedText?.[index]}
                                          </CustomButton>
                                        </LocationBtnWrapper>
                                      }
                                      showTabs={false}
                                      listProps={{
                                        locations: {
                                          showInlineSelection: true,
                                          isSearchable: true,
                                          isMulti: true,
                                          isSelectAll: true,
                                          hideActions: false,
                                          selected:
                                            selectedLoc?.[index]?.locations ||
                                            [],
                                          footer: (
                                            <LocationsActionWrapper>
                                              <Stack
                                                direction="row"
                                                alignItems="center"
                                                onClick={() => {
                                                  navigateWithWorkspaceUrl(
                                                    '/locations',
                                                  );
                                                  dispatch(
                                                    setDialog({
                                                      dialogId:
                                                        'inviteUserDialog',
                                                      open: false,
                                                    }),
                                                  );
                                                }}
                                              >
                                                <LocationsActionBtn>
                                                  Go to Locations
                                                </LocationsActionBtn>
                                                <ArrowForwardIosIcon
                                                  sx={{
                                                    color: '#6868FE',
                                                    cursor: 'pointer',
                                                    height: 15,
                                                    width: 15,
                                                  }}
                                                />
                                              </Stack>
                                            </LocationsActionWrapper>
                                          ),
                                        },
                                      }}
                                      displayOptions={{
                                        locations: true,
                                      }}
                                      popperProps={{ disablePortal: false }}
                                      allSelected={false}
                                    />
                                  </LocationMembershipWrapper>
                                  {values.invites.length > 1 && (
                                    <DeleteCnt style={{ right: -14 }}>
                                      <IconButton
                                        onClick={() => {
                                          updateLocationsAfterRemove(index);
                                          remove(index);
                                        }}
                                      >
                                        <DeleteIcon style={{ color: 'red' }} />
                                      </IconButton>{' '}
                                    </DeleteCnt>
                                  )}
                                </>
                              </Grid>
                            )}
                        </>
                      );
                    })}
                  <Grid item xs={12} direction="row">
                    <CustomButton
                      variant="outlined"
                      type="button"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        push(defaultInvite);
                        setDetails([...details, []]);
                        setSelectedLoc([...selectedLoc, []]);
                      }}
                    >
                      {'Add New'}
                    </CustomButton>
                  </Grid>
                </>
              )}
            </FieldArray>
          </Grid>
          {CustomActions || (
            <Grid
              spacing={1}
              container
              xs={12}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item direction="row" xs={4}>
                {showSubmitButton && (
                  <CustomButton
                    fullWidth
                    variant="contained"
                    style={{ color: 'white' }}
                    type="submit"
                    buttonLoader={isSubmitting}
                    endIcon={<SendIcon />}
                  >
                    {'Send Invite(s)'}
                  </CustomButton>
                )}
              </Grid>
              <Grid item xs={3}>
                {skipButton || null}
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default BulkInviteByEmailPhoneForm;
