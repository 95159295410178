import { Box, Typography, styled } from '@mui/material';

export const EmptyCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: 300,
});

export const MainCnt = styled(Box)({
  width: '100%',
});

export const Title = styled(Typography)({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '24.59px',
  color: '#000000',
  marginBottom: 10,
});

export const Banner = styled(Box)({
  width: '100%',
  borderRadius: 12,
  background: 'url(/assets/img/onboarding-logo.png) #EBEAFF no-repeat 100% 1px',
  backgroundSize: 'contain',
  gap: 5,
  padding: '16px 20px 16px 20px',
  marginBottom: 20,
  position: 'relative',

  '& .crossCnt': {
    borderRadius: '50%',
    position: 'absolute',
    right: -5,
    top: -6,
    backgroundColor: '#78909C',
    height: 20,
    width: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      height: 15,
      '& path': {
        stroke: 'white',
      },
    },
  },

  '& .title': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20.49px',
    color: '#000000',
  },
  '& .statement': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: '#212121',
  },
});
