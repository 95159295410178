import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import FlaggedCategories from './FlaggedCategories';
import FlagIcon from 'components/Icons/flagIcon';
import { useState } from 'react';

const drawerWidth = 220;
const menuItems = [
  { id: 'FLAG_CATEGORIES', text: 'Flag Categories', icon: <FlagIcon /> },
];

// Template Configurations
const TemplateConfigurations = () => {
  const [selectedContent, setSelectedContent] = useState(menuItems[0]?.id);
  const handleMenuItemClick = (content) => {
    setSelectedContent(content);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          position: 'relative',
          paddingRight: '18px',
          '& .MuiDrawer-paper': {
            width: '180px',
            position: 'absolute',
            borderRight: 'unset',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List sx={{ paddingTop: 0 }}>
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              onClick={() => handleMenuItemClick(item?.id)}
              sx={{
                padding: '5px 10px 5px 18px',
                backgroundColor: '#EBEAFF',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              <ListItemIcon sx={{ minWidth: '30px' }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  '& span': {
                    fontWeight: '500 !important',
                    lineHeight: '24px',
                    letterSpacing: '0.17px',
                    color: '#000000',
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {selectedContent === 'FLAG_CATEGORIES' && <FlaggedCategories />}
    </Box>
  );
};

export default TemplateConfigurations;
