import { useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { updateDefaultSSORole } from 'api/workspaceApi';
import { BoldText, GreyText, InputField } from './style';
import CustomButton from 'components/Button/CustomButton';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import { getRoles } from 'store/selectors/roles';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import SelectItemsDropdownV2 from 'components/Dropdown/SelectItemsDropdownV2/selectItemsDropdown2';

const AssignRole = ({ isDone, disabled, ssoId, ssoRoleId, updateConfig }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;

  const { fetchCustomRoles } = useCustomRoles();

  useEffect(() => {
    fetchCustomRoles();
  }, []);

  const onChangeCallback = async (option) => {
    if (option.length > 0) {
      setSelectedOption(option[0].id);
    } else {
      setSelectedOption(null);
    }
  };
  const transformedDataToDropdown = useMemo(() => {
    return rolesList?.map((item) => {
      return {
        label: item?.title,
        value: item?.title,
        id: item?.id,
      };
    });
  }, [rolesList]);

  const selectedRole = useMemo(() => {
    return transformedDataToDropdown?.filter(
      (item) => item.id === (selectedOption || ssoRoleId),
    );
  }, [selectedOption, ssoRoleId]);
  const handleUpdateConfig = async () => {
    if (selectedOption) {
      setLoading(true);
      const payload = {
        id: ssoId,
        ssoRoleId: selectedOption,
      };
      const response: any = await updateDefaultSSORole(payload);
      updateConfig(response);
      setSelectedOption(null);
      setLoading(false);
    }
  };

  return (
    <Stack
      border={'1px solid #E0E0E0'}
      borderRadius={'8px'}
      padding={'12px 16px'}
    >
      <BoldText>
        <span
          className="stepCount"
          style={{
            ...(isDone && { background: '#4CAF50', borderColor: '#4CAF50' }),
          }}
        >
          <CheckIcon />
        </span>
        Step 3: Set Default Role
      </BoldText>
      <GreyText my={1}>
        All users without Xenia accounts, logging in for the first time will
        default to this role. Please note that you can change the role of any
        user whenever needed.
      </GreyText>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        width="100%"
        mb={2}
        gap={2}
      >
        <InputField>
          <label>Default Role</label>
          <SelectItemsDropdownV2
            onChangeCallback={onChangeCallback}
            selected={selectedRole}
            listProps={{
              controlled: true,
              options: transformedDataToDropdown,
              isMulti: false,
            }}
            buttonProps={{ disabled: disabled }}
            placeholder={'Select Role'}
          />
        </InputField>
        <CustomButton
          variant={'contained'}
          color={'primary'}
          onClick={handleUpdateConfig}
          disabled={!selectedOption}
          buttonLoader={loading}
          sx={{ borderRadius: '8px' }}
        >
          {selectedOption ? 'Save' : 'Saved'}
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default AssignRole;
