import NoWidgetImage from 'public/assets/img/widgets_empty_state.svg';
import DynamicChartsImage from 'public/assets/img/dynamic-chart-widgets.svg';
import CustomizeReportsImage from 'public/assets/img/customize-reports-with-filters.svg';
import StreamLinedDecisionImage from 'public/assets/img/streamlined-decision-making.svg';
import {
  DashboardEmptyStateCnt,
  EmptyStateDescription,
  EmptyStateTitle,
  MainTitle,
  SubTitle,
} from 'pages/Dashboards/EmptyState/dashboardEmptyState.style';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import WithDashboardPermission from 'pages/Dashboards/withDashboardPermission';
import { firstItemConfig } from 'pages/Dashboards/utils';
import { DashboardsContext } from '../context/context';
import {
  useDashboard,
  useUpdateDashboard,
} from 'pages/Dashboards/hooks/useDashboard';
import { IWidget } from 'pages/Dashboards/interfaces';
import { Stack } from '@mui/material';
const DashboardEmptyState = () => {
  const dispatch = useDispatch();
  const { updateDashboardsState } = useContext(DashboardsContext);
  const params = useParams();
  const { dashboardId } = params;
  const { dashboardQuery } = useDashboard({
    id: dashboardId,
  });
  const { updateDashboardQuery } = useUpdateDashboard();

  const handleGridSettingsChange = (gridSettings) => {
    const obj = {
      order: gridSettings,
      id: dashboardId as string,
    };
    updateDashboardQuery.mutate(obj, {
      onSuccess: () => {
        dashboardQuery.refetch();
      },
    });
  };
  const handleAddWidget = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.CREATE_WIDGET_SELECT_TYPE_DIALOG,
        open: true,
        data: {
          dashboardId,
          onSuccessCallback: (res: IWidget) => {
            const newItemConfig = firstItemConfig(res);
            const newGridLayout = {
              gridsLayouts: [newItemConfig],
            };
            handleGridSettingsChange(newGridLayout);
            updateDashboardsState({
              currentGridLayout: [newItemConfig],
            });
          },
        },
      }),
    );
  };
  return (
    <DashboardEmptyStateCnt>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <MainTitle>Start building your dashboard</MainTitle>
        <SubTitle>
          Build your dashboard by adding chart widgets to the builder.
          <br /> Refine the charts using the filters.
        </SubTitle>
        <WithDashboardPermission>
          <CustomButton
            variant="contained"
            style={{
              borderRadius: '6px',
              marginTop: 8,
              width: 130,
              height: 38,
            }}
            startIcon={<AddIcon />}
            onClick={handleAddWidget}
          >
            Add Chart
          </CustomButton>
        </WithDashboardPermission>
      </Stack>

      <div className={'dashboardBuilderInfo'}>
        <div className={'widgetEmptyStateTile'}>
          <EmptyStateTitle>Dynamic Chart Widgets</EmptyStateTitle>
          <EmptyStateDescription>
            Seamlessly craft insightful reports by choosing from a variety of
            chart types, including bar graphs, pie charts, line charts, and
            more.
          </EmptyStateDescription>
          <img src={DynamicChartsImage} />
        </div>
        <div className={'widgetEmptyStateTile'}>
          <EmptyStateTitle>Customize Reports with Filters</EmptyStateTitle>
          <EmptyStateDescription>
            Our Dashboard Builder allows you to refine your charts instantly,
            enabling a granular analysis of specific data sets.
          </EmptyStateDescription>
          <img src={CustomizeReportsImage} />
        </div>
        <div className={'widgetEmptyStateTile'}>
          <EmptyStateTitle>Streamlined Decision-Making</EmptyStateTitle>
          <EmptyStateDescription>
            Visualize trends, identify patterns, and make informed choices
            backed by comprehensive, tailor-made reports.
          </EmptyStateDescription>
          <img src={StreamLinedDecisionImage} />
        </div>
      </div>
    </DashboardEmptyStateCnt>
  );
};

export default DashboardEmptyState;
