import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getAuthorizedLocations = async (workspaceId?: string) => {
  try {
    const wsId = workspaceId ?? (getHotelId() as string);
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${wsId}/authorized/locations`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getLocations = async (
  workspaceId?: any,
  payload?: any,
  locationId?: string,
) => {
  try {
    const hotelId = workspaceId ?? (getHotelId() as string);
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/locations/${locationId ?? ''}`,
      payload,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getPublicLocations = async (workspaceId) => {
  try {
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${workspaceId}/locations/public`,
      null,
      'get',
      false,
      false,
      null,
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const createLocation = async (payload) => {
  try {
    const hotelId = getHotelId() as string;
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/locations`,
      payload,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};
export const updateLocation = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const { id, ...rest } = data;
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/locations/` +
        id,
      rest,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const deleteLocations = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const payload = { locationIds: data };
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/locations/`,
      payload,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations;
  } catch (res) {
    return res;
  }
};

export const getLocationTaskHistory = async (locationId) => {
  try {
    const hotelId = getHotelId() as string;
    const history = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/locations/${locationId}/tasks`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return history?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getLocationMembers = async (locationId) => {
  try {
    const hotelId = getHotelId() as string;
    const members = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${hotelId}/locations/${locationId}/members`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return members?.data?.data;
  } catch (res) {
    return res;
  }
};

export const updateLocationMembers = async ({
  locationId,
  memberIds,
}: {
  locationId: string;
  memberIds: string[];
}) => {
  try {
    const hotelId = getHotelId() as string;
    const members = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${hotelId}/locations/${locationId}/members`,
      {
        memberIds,
      },
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return members?.data?.data;
  } catch (res) {
    return res;
  }
};

export const removeLocationMember = async ({
  locationId,
  memberId,
}: {
  locationId: string;
  memberId: string;
}) => {
  try {
    const hotelId = getHotelId() as string;
    const member = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${hotelId}/locations/${locationId}/members/${memberId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return true;
  } catch (res) {
    return false;
  }
};
