import { Container, styled } from '@mui/material';
import { padding, margin } from '@mui/system';
import { before } from 'lodash';
import { colors } from 'components/theme/constants';

export const CommunicationLayoutWrapCnt = styled('div')({
  height: 'calc(100vh - 48px)',
  '.layoutWrap': {
    display: 'flex',
    height: 'auto',
  },
  '.communicationLayoutWrap': {
    borderTop: '1px solid #dbe2f0',
    height: '100%',
  },
});

export const CommunicationLeftSideBar = styled(Container)({
  width: ' 440px !important',
  boxShadow: '1px 0px 0px rgba(44, 36, 232, 0.11)',
  height: 'calc(100vh - 72px)',
  overflowY: 'hidden',
  overflowX: 'hidden',
  left: '0',
  top: '62px',
  padding: 0,
  background: 'white',
});

export const ArchiveSidebarTitle = styled('div')({
  width: '100%',
  fontWeight: 500,
  fontSize: 18,
  height: 54,
  lineHeight: '140%',
  color: '#252350',
  display: 'flex',
  alignItems: 'center',
  '.iconLeftAngle': {
    paddingRight: '34px !important',
    marginLeft: '22px !important',
    '&:before': {
      color: '#252350',
    },
  },
});

export const CommunicationContentWrap = styled('div')({
  backgroundColor: colors.white,
  width: '100%',
  zIndex: 0,
  boxShadow: '-1px 0px 0px rgba(44, 36, 232, 0.11)',
  '.archiveChatMsz': {
    zIndex: 999,
    '.unarchiveBtn': {
      color: '#6868fe',
      fontWeight: 500,
      cursor: 'pointer !important',
    },
  },
  '.contentTitleWrap': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '0',
    borderBottom: '1px solid rgba(37, 35, 80, 0.1)',
    height: '49px',
    padding: '12px 40px',
    '.actionbox': {
      display: 'flex',
      alignItems: 'center',
      '.btn': {
        span: {
          fontSize: 20,

          '&::before': {
            color: '#6868fe',
          },
        },
      },
    },
  },
});

export const ChatBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: 'calc(100vh - 98px)',
});
