// React
import { ReactNode } from 'react';

// Styled
import {
  Wrapper,
  Header,
  WidgetHeading,
  ActionButtonsWrapper,
  ContentWrapper,
} from './styled';

interface WidgetLayoutPropTypes {
  widgetHeading: string;
  actionButtons?: ReactNode;
  children: ReactNode;
}

const WidgetLayout = ({
  widgetHeading,
  actionButtons,
  children,
}: WidgetLayoutPropTypes) => {
  return (
    <Wrapper>
      <Header>
        <WidgetHeading>{widgetHeading}</WidgetHeading>
        <ActionButtonsWrapper>{actionButtons}</ActionButtonsWrapper>
      </Header>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

export default WidgetLayout;
