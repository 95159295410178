import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  GroupDetailsModalContainer,
  InputTextArea,
} from './groupDetailsModal.style';
import {
  ConstantChannelTypeString,
  LOCALSTORAGE_KEYS,
} from 'utils/globalVariable';
import { useRemoveMembers } from 'controller/useRemoveMembers';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { useQueryUsersList } from 'controller/useQueryUsersList';
import { generateGroupMembersDropdownData } from 'helper/task/dropdownData';
import { useAddNewMember } from 'controller/useAddMembers';
import { getItem } from 'utils/globalFunction';
import CustomButton from 'components/Button/CustomButton';

const GroupDetailsModal: FunctionComponent<any> = ({
  title,
  handleClose,
  members,
  messageHeaderState,
  setMessageHeaderState,
  isChannelTasktype,
  serviceType,
  description,
  channelName,
  setChannelName,
  taskType,
  getHideShowDetailText,
}) => {
  const currentUserId = useMemo(
    () => getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID),
    [],
  );
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const { handleRemoveMemberSubmit } = useRemoveMembers();
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);
  const { DIRECT_MESSAGE, FEED_STREAM, GROUP } = ConstantChannelTypeString;
  const keysMembers: any = Object.keys(members);

  const { queryUsersObj }: any = useQueryUsersList(
    messageHeaderState.showAddMember,
  );

  const { handleAddMemberSubmit } = useAddNewMember(activeStreamChannel);

  const { users } = queryUsersObj;

  const membersDropdownData = useMemo(() => {
    if (!users) return [];
    return generateGroupMembersDropdownData([
      { ...members[currentUserId as string].user, tooltipText: 'Leave' },
      ...users.map((u) => ({ ...u, tooltipText: 'Remove' })),
    ]);
  }, [users]);

  useEffect(() => {
    setSelectedMembers(
      membersDropdownData?.filter((m) => keysMembers.includes(m.id)),
    );
  }, [users, members]);

  const handleSelectMember = (option) => {
    setSelectedMembers(option);
  };

  const membersToRemove = () => {
    return keysMembers?.filter((userId) => {
      const selectedMemberIds = selectedMembers.map((member) => member.id);
      return !selectedMemberIds.includes(userId);
    });
  };

  return (
    <>
      <GroupDetailsModalContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid className="title" item>
            {title}
          </Grid>
          <Grid className="closeIcon" item>
            <CloseIcon onClick={handleClose} />
          </Grid>
        </Grid>
        <Container className="formContainer">
          <form className="create-group-modal-form">
            {isChannelTasktype() ? (
              <div className="info">
                <h6>{serviceType}</h6>
                {/* add className "view" in content on click View more details button */}
                <div className="content">
                  <p>
                    {messageHeaderState.showDetailDescription
                      ? description
                      : description?.slice(0, 250)}
                  </p>
                </div>
                <CustomButton
                  className="addMembersBtn"
                  variant="outlined"
                  onClick={() => {
                    setMessageHeaderState({
                      ...messageHeaderState,
                      showDetailDescription:
                        !messageHeaderState.showDetailDescription,
                    });
                  }}
                >
                  <>{getHideShowDetailText()}</>
                </CustomButton>
                <span className="badge">{taskType}</span>
              </div>
            ) : (
              <Container className="inputContainer">
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="name">
                    Name
                  </InputLabel>
                  <InputTextArea
                    id="name"
                    value={channelName}
                    onChange={(e) => {
                      setChannelName(e.target.value);
                    }}
                  />
                </FormControl>
              </Container>
            )}
          </form>
          {![DIRECT_MESSAGE, FEED_STREAM].includes(
            activeStreamChannel?.data?.channelType,
          ) ? (
            <Box sx={{ mt: '20px' }}>
              <CustomSelect
                label={'Members'}
                placeholder="Select Members"
                isSearchable={true}
                isMulti={true}
                closeMenuOnSelect={false}
                selectedOption={selectedMembers}
                options={membersDropdownData}
                closeToolTip="remove"
                onChange={handleSelectMember}
                isClearable={false}
              />
            </Box>
          ) : (
            ''
          )}
        </Container>
        <Grid
          container
          className="footer"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CustomButton
            className="cancelBtn"
            color="primary"
            size="medium"
            variant="text"
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
          <CustomButton
            color="primary"
            size="medium"
            variant="contained"
            onClick={() => {
              if (activeStreamChannel?.data?.channelType === GROUP) {
                handleAddMemberSubmit(
                  selectedMembers.map((member) => member.id),
                );
                handleRemoveMemberSubmit(membersToRemove(), 'leave');
              }
              handleClose();
            }}
          >
            Save
          </CustomButton>
        </Grid>
      </GroupDetailsModalContainer>
    </>
  );
};

export default GroupDetailsModal;
