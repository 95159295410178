import { Grid } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import LoginWithEmail from 'pages/Login/loginWithEmail';
import LoginWithPhone from 'pages/Login/loginWithPhone';
import ForgotPassword from 'pages/Login/ForgotPassword';
import AuthScreenInfo from 'components/BuilderUI/AuthScreen';
import { FormCnt, InfoCnt, SignInContainer } from './style';
import LoginWithSSO from './loginWithSSO';

const Login = () => {
  return (
    <SignInContainer container spacing={2}>
      <Grid item xs={12} md={6}>
        <InfoCnt>
          <AuthScreenInfo />
        </InfoCnt>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormCnt justifyContent={'center'} alignItems={'center'}>
          <Routes>
            <Route path="/" element={<LoginWithEmail />} />
            <Route path="/phone-number" element={<LoginWithPhone />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route path="/sso" element={<LoginWithSSO />} />
          </Routes>
        </FormCnt>
      </Grid>
    </SignInContainer>
  );
};

export default Login;
