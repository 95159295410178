// React
import { FC, useMemo, Fragment } from 'react';

// Redux
import { getLocationsState } from 'store/selectors/locations';
import { useSelector } from 'react-redux';

// Components
import LocationTile from './components/LocationTile';
import GlobalEmptyResult from 'components/GlobalEmptyResult';

// Mui
import { Stack } from '@mui/material';

// Styled
import { Grid } from './styled';

import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';

export const LocationsGridView: FC<any> = ({ searchTerm }) => {
  const locationsList = useSelector(getLocationsState);
  const { flattenLocations } = useLocationsUtils();
  const { config } = useWorkspaceConfig();

  const filteredLocations = useMemo(() => {
    const _locations = locationsList?.flatMap((location) =>
      flattenLocations(location),
    );

    if (searchTerm) {
      return _locations.filter((location) =>
        location?.name?.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }
    return _locations;
  }, [locationsList, searchTerm]);

  return (
    <Fragment>
      {filteredLocations?.length === 0 && (
        <Stack width="100%" alignItems={'center'}>
          <GlobalEmptyResult />
        </Stack>
      )}
      {filteredLocations?.length > 0 && (
        <Grid>
          {filteredLocations?.map((location) => (
            <LocationTile
              hideMembers={!config?.advancedLocationBasedAssignment}
              key={location?.id}
              location={location}
            />
          ))}
        </Grid>
      )}
    </Fragment>
  );
};
