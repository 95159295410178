// React
import { useEffect, useState, useCallback } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Utils
import { debounce } from 'lodash';
import {
  getParentSchedules,
  getCompletedSchedules,
  getOverdueSchedules,
} from 'api/schedulesApi';

// Constants
import DIALOGS from 'utils/dialogIds';
import { formatFilters } from 'utils/Task/filters';

export const useScheduleTab = (
  tab: 2 | 3 | 4,
  pageSize = 100,
  customPayload = {},
  initialConfigLoad = true,
) => {
  const limit = pageSize || 100;
  const dispatch = useDispatch();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [advanceFilters, setAdvanceFilters] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const tabMap = {
    2: getParentSchedules,
    3: getOverdueSchedules,
    4: getCompletedSchedules,
  };

  useEffect(() => {
    if (initialConfigLoad) {
      fetchData();
    }
  }, [advanceFilters, initialConfigLoad]);

  const searchTermApiCall = useCallback(
    debounce(
      (value) => {
        fetchData(value);
      },
      1000,
      {
        trailing: true,
        leading: false,
      },
    ),
    [],
  );

  const handleChangeSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    searchTermApiCall(value);
  };

  // load more
  const handleLoadNextPage = async (offset: number) => {
    const paginationData = {
      offset,
      limit,
    };
    setIsNextPageLoading(true);
    let result: any = null;
    const postData = {
      advanceFilters: advanceFilters ? formatFilters(advanceFilters) : {},
      ...(searchTerm && { searchText: searchTerm }),
      ...paginationData,
      ...customPayload,
      ...(tab === 4 && { sort: ['updatedAt', 'desc'] }),
      projects: false,
    };

    const api = tabMap[tab];
    result = await api(postData);
    const prevData = data ?? [];
    const newRows = result?.rows ?? [];
    setData([...prevData, ...newRows]);
    setTotalCount(result?.count);
    setIsNextPageLoading(false);
  };

  const fetchData = async (searchText = '') => {
    const paginationData = {
      offset: 0,
      limit,
    };
    setLoading(true);
    let result: any = null;

    const postData = {
      advanceFilters: advanceFilters ? formatFilters(advanceFilters) : {},
      ...(searchText && {
        searchText: searchText,
      }),
      ...paginationData,
      ...customPayload,
      ...(tab === 4 && { sort: ['updatedAt', 'desc'] }),
      projects: false,
    };
    const api = tabMap[tab];
    result = await api(postData);
    const newRows = result?.rows ?? [];
    setData(newRows);
    setTotalCount(result?.count);
    setLoading(false);
    setNoResults(searchText || advanceFilters ? result?.count === 0 : false);
  };

  const handleClickScheduleItem = (task) => {
    // If on mobile app then send a message to RN WebView //
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          navigateTo: 'TaskDetail',
          data: task,
        }),
      );
      return;
    }

    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: fetchData,
          taskId: task.id,
        },
      }),
    );
  };

  return {
    data,
    advanceFilters,
    searchTerm,
    totalCount,
    isNextPageLoading,
    loading,
    noResults,
    setSearchTerm,
    handleLoadNextPage,
    fetchData,
    setAdvanceFilters,
    handleChangeSearch,
    handleClickScheduleItem,
  };
};
