// React
import { Fragment } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Styled
import {
  BannerWrapper,
  Headline,
  ClickHereButton,
  CrossButton,
} from './Banner.styled';

// Icons
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import mobileInHand from 'public/assets/img/imgMobileInHand.png';

// Constants
import DIALOGS from 'utils/dialogIds';

interface BannerPropTypes {
  handleClickClose: () => void;
}

const Banner = ({ handleClickClose }: BannerPropTypes) => {
  const dispatch = useDispatch();

  const handleClickHere = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG,
        open: true,
      }),
    );
  };

  return (
    <Fragment>
      <BannerWrapper>
        <img src={mobileInHand} className="mobile-in-hand-icon" />
        <Headline>Get Notifications on your Mobile App</Headline>
        <ClickHereButton onClick={handleClickHere}>Click here</ClickHereButton>
        <CrossButton onClick={handleClickClose}>
          <CloseRoundedIcon />
        </CrossButton>
      </BannerWrapper>
    </Fragment>
  );
};

export default Banner;
