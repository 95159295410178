import { styled } from '@mui/material/styles';

export const PreviewWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 10px',
  gap: 12,
  height: 64,
  background: '#EBEAFF',
  borderRadius: 6,
  margin: '0px auto 15px',
  maxWidth: 392,
  '& img': {
    width: 81.07,
    height: 48,
    borderRadius: 6,
  },
  '& .defaultIcon': {
    width: 81.07,
    height: 48,
    borderRadius: 6,
    backgroundColor: '#5555FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .templateTitle': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19px',
    letterSpacing: '-0.1px',
    color: '#212121',
  },
  '& .templateSubTitle': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '18px',
    letterSpacing: '-0.1px',
    color: '#212121',
  },
});
