import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const DownloadCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '& p': {
    color: colors.black,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28.8px',
    letterSpacing: '0.15px',
    textAlign: 'center',
    marginBottom: 7,
  },
});
