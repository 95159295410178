// Redux
import { useSelector } from 'react-redux';
import { getUserHotelTz } from 'store/selectors/auth';

// MUI
import { Box } from '@mui/material';

// Utils
import DateTime from 'utils/DateTime';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';

const ItemDateItem = function ({ itemDateTime, lineBreak = false }) {
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;

  return (
    <Box
      sx={{
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '18.26px',
        letterSpacing: '0.15px',
        color: 'rgba(33, 33, 33, 1)',
      }}
    >
      {lineBreak ? (
        <Box>
          <Box>
            {DateTime.fromUTCString(itemDateTime).toTzString(tz, 'MMM DD YYYY')}
          </Box>
          <Box>
            {DateTime.fromUTCString(itemDateTime).toTzString(tz, 'h:mm A')}
          </Box>
        </Box>
      ) : (
        <Box className="ml-1">
          {DateTime.fromUTCString(itemDateTime).toTzString(tz, 'MMM DD YYYY')}
          <span className="ml-1 mr-1">at</span>
          {DateTime.fromUTCString(itemDateTime).toTzString(tz, 'h:mm A')}
        </Box>
      )}
    </Box>
  );
};

export default ItemDateItem;
