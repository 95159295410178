// Redux
import actions from 'store/actions';
import { useDispatch } from 'react-redux';

// Utilities
import xeniaApi from 'api/index';
import { bulkUpdateTasks } from 'api/tasksApi';
import { setDialog } from 'store/actions/dialogActions';
import { PostApiCallbackRequestType } from 'models/taskModel';
import { TASK_STATUS } from 'utils/globalVariable';
import { showMessageNotification } from 'utils/globalFunction';
import DIALOGS from 'utils/dialogIds';
import * as constants from 'pages/task/TaskListView/BulkAction/constants';

import { includes } from 'lodash';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DateTime from 'utils/DateTime';
import { validate } from 'uuid';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { UpdateProjectApi } from 'api/projectsApi';

export const useTaskEdit = (taskData?: any) => {
  const dispatch = useDispatch();

  const { workspaceId } = useWorkspaceHook();
  const { isInPast } = useDateTime();

  const handleTaskReOpen = (taskId, successCB?: any) => {
    new Promise((resolve, reject) => {
      const getTaskSingleReopenData: PostApiCallbackRequestType = {
        callbackResolve: resolve,
        callbackReject: reject,
        postData: taskId,
      };
      dispatch(actions.setTaskReopen(getTaskSingleReopenData));
    }).then(() => {
      successCB && successCB(taskId);
    });
  };
  const handleTaskStatusChange = (prevTask, task, params = {}) => {
    const taskReopening =
      prevTask?.taskStatus === TASK_STATUS.Open &&
      task?.taskStatus === TASK_STATUS.Completed;
    if (taskReopening) {
      handleTaskReOpen(task?.id);
      return;
    }
    const statusPostData = {
      status: task?.taskStatus,
      id: task?.id,
      ...params,
    };
    dispatch(actions.changeTaskStatus({ postData: statusPostData }));
    dispatch(actions.setChangeTaskStatusSuccess(false));
  };

  const updateTaskHelper = async (task) => {
    dispatch(
      actions.editSingleTask({
        taskId: task?.id,
        task: { ...task, taskStatus: task?.taskStatus },
      }),
    );
  };

  const handleChangeSelectedOption = async (prevTask, task, params = {}) => {
    if (task?.taskStatus === 'Completed') {
      const taskChecklist = await xeniaApi.taskDetailChecklistData(task.id);
      const count = taskChecklist?.data?.progress?.count || 0;
      const completedCount = taskChecklist?.data?.progress?.completedCount || 0;
      const itemsCount = taskChecklist?.data?.TaskChecklistItems?.length || 0;
      if ((itemsCount && !count) || (count && count > completedCount)) {
        dispatch(
          actions.setDialog({
            dialogId: DIALOGS.CONFIRMATION,
            open: true,
            data: {
              title: 'Complete task',
              description:
                'Are you sure you want to mark this task as completed?',
              onConfirmCallback: updateTaskHelper,
              onConfirmCallbackParams: task,
              // onCancelCallback: () => setConfirmationData(null),
            },
          }),
        );
      } else {
        updateTaskHelper(task);
        handleTaskStatusChange(prevTask, task, params);
      }
    } else {
      updateTaskHelper(task);
      handleTaskStatusChange(prevTask, task, params);
    }
  };
  const openRecurringModal = async (task, data, successCB?: any) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'recurringTask',
        data: {
          onSuccessCallBack: successCB,
          taskData: taskData || task,
          ...data,
        },
      }),
    );
  };

  const openEditModal = async (task = null, data, successCB?: any) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'editTask',
        data: {
          onSuccessCallBack: successCB,
          dialogState: {
            viewLess: false,
          },
          ...data,
          taskData: taskData || task,
        },
      }),
    );
  };

  const editTaskEnabler = async (
    task: any = null,
    data = {},
    successCB?: any,
  ) => {
    if (isInPast(task?.dueDate)) {
      openEditModal(task, { ...data, editSeriesType: 'One' }, successCB);
      return;
    }
    if (taskData?.recurringTask || task?.recurringTask) {
      openRecurringModal(task, data, successCB);
    } else {
      openEditModal(task, data, successCB);
    }
  };

  // this function is used to update the task
  const handleUpdateTask = async (editData: any, keepOldTaskData = true) => {
    const { commentsCount, attachedCheckList, ...oldTask } = taskData;
    const updatedTaskData = {
      ...(keepOldTaskData ? oldTask : {}),
      ...editData,
      taskId: taskData.id,
    };
    // optimistic update (assuming that response would not result in error)
    const response = await xeniaApi.UpdateTaskApi(updatedTaskData);
    if (response) {
      dispatch(
        actions.editSingleTask({
          taskId: response?.data?.[0]?.id,
          task: response?.data?.[0],
        }),
      );
    }
    return response;
  };

  const handleUpdateProject = async (
    projectId,
    editData: any,
    keepOldTaskData = true,
  ) => {
    const { commentsCount, attachedCheckList, ...oldTask } = taskData;
    const updatedTaskData = {
      ...(keepOldTaskData ? oldTask : {}),
      ...editData,
    };
    // optimistic update (assuming that response would not result in error)
    const response = await UpdateProjectApi(projectId, updatedTaskData);
    return response;
  };

  const handleTaskArchiveCheckDialog = (task, isRecurring, successCB) => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS?.ARCHIEVE_CHECK,
        data: {
          successCB,
          currentArchiveObj: {
            id: task.id,
            taskUniqueId: task.taskUniqueId,
            isRecurring: isRecurring,
          },
        },
      }),
    );
  };

  const handleTaskArchive = (archiveType, task, successCB) => {
    const statusPostData = {
      archiveType: archiveType,
      id: task.id as string,
    };

    new Promise((resolve, reject) => {
      const getTaskArchiveData = {
        callbackResolve: resolve,
        callbackReject: reject,
        postData: statusPostData,
      };
      successCB?.();
      dispatch(actions.setTaskArchive(getTaskArchiveData));
    }).then(() => {
      dispatch(actions.bulkDeleteTasks([task]));
    });
  };

  const handleArchiveSeries = async (task, successCB) => {
    const result = await xeniaApi.archiveSeries(task.taskUniqueId);
    if (result) {
      successCB?.();
      dispatch(actions.bulkDeleteTasks([task]));
      dispatch(
        setDialog({
          dialogId: 'confirmationDialog',
          open: false,
        }),
      );
      dispatch(
        setDialog({
          dialogId: DIALOGS.TASK_SCHEDULES_DRAWER,
          open: false,
        }),
      );
    }
  };
  const handleArchiveSeriesCheckDialog = (task, successCB) => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'confirmationDialog',
        data: {
          title: 'Archive Task Series',
          description: 'Are you sure you want to archive this task series?',
          confirmButtonProps: { color: 'error' },
          confirmationText: 'Continue',
          onConfirmCallback: async () => handleArchiveSeries(task, successCB),
        },
      }),
    );
  };

  const handleTaskUnarchiveCheckDialog = (task, isRecurring) => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS?.UNARCHIVE_CHECK,
        data: {
          currentUnarchiveObj: {
            id: task.id,
            taskUniqueId: task.taskUniqueId,
            isRecurring: isRecurring,
          },
        },
      }),
    );
  };

  const handleTaskUnarchive = async (task, value) => {
    const result = await bulkUpdateTasks(workspaceId as string, {
      taskIds: [task.id],
      operationType: 'unarchive',
      values: value ? [value] : null,
    });
    if (result) {
      dispatch(actions.bulkDeleteTasks(result.data));
      showMessageNotification(
        constants.SUCCESS_MESSAGES['unarchive'].msg,
        constants.SUCCESS_MESSAGES['unarchive'].type,
      );
    }
  };

  const handleOpenTaskDetail = (taskId, activeTab = 'detail') => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          taskId,
          activeTab,
        },
      }),
    );
  };

  const handleEditDetailTask = async (taskData: any, type = 'task') => {
    if (!validate(taskData)) return;
    dispatch(actions.setLoader({ type: 'taskDetails', value: true }));
    if (includes(['detail', 'comments', 'checklist'], type)) {
      handleOpenTaskDetail(taskData?.id || taskData, type);
      if (type === 'checklist' && taskData?.ChecklistId) {
        dispatch(
          actions.createChecklistLog({
            checklistId: taskData?.ChecklistId,
            hotelId: workspaceId,
            postData: { taskId: taskData?.id },
          }),
        );
      }
    }
  };

  const handleTaskMarkAsComplete = (task, successCB?: any) => {
    const statusPostData = {
      status: 'Completed',
      time: new DateTime().toUTCString(),
      id: task?.id,
    };

    new Promise((resolve, reject) => {
      const getTaskSingleMarkCompleteData = {
        callbackResolve: resolve,
        callbackReject: reject,
        postData: statusPostData,
      };
      dispatch(actions.changeTaskStatus(getTaskSingleMarkCompleteData));
    }).then((succ) => {
      successCB && successCB(task?.id);
      if (succ) {
        dispatch(
          actions.editSingleTask({
            taskId: task?.id,
            task: { ...task, taskStatus: 'Completed' },
          }),
        );
      }
    });
  };

  const handleTaskAssign = (task, successCB?: any) => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS?.ASSIGNEES,
        data: {
          onSuccessCallBack: successCB,
          task,
          showAuthorizedAssignees: true,
        },
      }),
    );
  };

  const handleTaskCompleteClick = async (task, successCB?: any) => {
    const taskChecklist = await xeniaApi.taskDetailChecklistData(task?.id);
    if (taskChecklist?.data && taskChecklist?.data?.status !== 'Submitted') {
      markAsCompleteCheck(task, '', successCB);
    } else {
      handleTaskMarkAsComplete(task, successCB);
    }
  };

  const markAsCompleteCheck = (task, message, successCB?: any) => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.MARK_AS_COMPLETE,
        data: {
          successCB,
          task,
          message:
            message ||
            'Are you sure you want to complete this task without submitting checklist?',
        },
      }),
    );
  };

  const handleTaskDetailStatusChange = async (
    currentTask,
    selected,
    successCB?: any,
  ) => {
    const taskReopening =
      selected.label === TASK_STATUS.Open &&
      currentTask?.taskStatus === TASK_STATUS.Completed;

    if (taskReopening) {
      handleTaskReOpen(currentTask?.id, successCB);
      return handleEditDetailTask?.(currentTask?.id as any, 'detail');
    }

    new Promise((resolve, reject) => {
      const statusPostData = {
        callbackResolve: resolve,
        callbackReject: reject,
        postData: {
          status: selected.label,
          id: currentTask.id,
        },
      };
      dispatch(actions.changeTaskStatus(statusPostData));
    }).then(() => {
      successCB?.();
    });
  };

  return {
    handleUpdateProject,
    handleUpdateTask,
    editTaskEnabler,
    openRecurringModal,
    openEditModal,
    handleTaskDetailStatusChange,
    handleChangeSelectedOption,
    handleTaskArchiveCheckDialog,
    handleArchiveSeriesCheckDialog,
    handleTaskUnarchiveCheckDialog,
    handleTaskArchive,
    handleArchiveSeries,
    handleTaskUnarchive,
    handleOpenTaskDetail,
    handleEditDetailTask,
    handleTaskMarkAsComplete,
    handleTaskAssign,
    handleTaskCompleteClick,
    markAsCompleteCheck,
    handleTaskStatusChange,
    handleTaskReOpen,
  };
};
