import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CustomButton from 'components/Button/CustomButton';
import { PreviewChecklistBody } from 'components/common/jsxrender/checklistV2/PreviewChecklistBody';
import MobileIcon from 'components/Icons/mobileIcon';
import ChecklistTitleAndAttachments from 'components/TaskChecklist/ChecklistTitleAndAttachments';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import appConstants from 'utils/appConstants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DateTime from 'utils/DateTime';
import DIALOGS from 'utils/dialogIds';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import store from '../../store';
import ChecklistActionDropdown from './ChecklistActionDropdown';
import ChecklistLog from './ChecklistLog';
import ChecklistLogs from './ChecklistLogs';
import {
  ChecklistHeader,
  ChecklistHeaderActions,
} from './checklistPreview.style';
import { useTemplateAccess } from 'controller/useTemplateAccess';

interface ChecklistPreviewPropTypes {
  checklist?: any;
  setShowChecklistPreview?: any;
  onAssignClick?: any;
  duplicateChecklist?: any;
  activeTab?: any;
  setActiveTab?: any;
  onStartButtonClick?: any;
  archiveChecklist?: any;
  logId?: any;
  onArchiveCb?: any;
}

const ChecklistPreview = ({
  checklist,
  setShowChecklistPreview,
  onAssignClick,
  duplicateChecklist,
  activeTab,
  setActiveTab,
  onStartButtonClick,
  archiveChecklist,
  logId = null,
  onArchiveCb = null,
}: ChecklistPreviewPropTypes) => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const [currentSelectedLogId, setCurrentSelectedLogId] = useState<
    string | null
  >(logId ?? null);
  const checklistLog = useSelector(selectors.getChecklistLog);
  const checklistLogs = useSelector(selectors.getChecklistLogs);
  const [showStartChecklist, setShowStartChecklist] = useState<boolean>(false);
  const { isTemplateAdmin } = useTemplateAccess();
  const { PAYWALL_LIMITS } = useCustomPermission();

  useEffect(() => {
    setCurrentSelectedLogId(logId);
  }, [logId]);

  // remove checklist log when log tab is closed
  useEffect(() => {
    if (activeTab !== 'log') {
      dispatch(
        actions.setChecklistLog({
          data: {},
          meta: {},
        }),
      );
    }
  }, [activeTab]);

  const tz =
    store.getState()?.authData?.user?.Hotel?.timezone || DEFAULT_TIMEZONE;
  const logTabTitle = DateTime.fromUTCString(
    checklistLog?.lastItemUpdatedAt,
  ).toTzString(tz, `MMM DD YYYY | hh:mm A`);

  const setChecklistLog = (logData: any) => {
    dispatch(
      actions.setChecklistLog({
        data: logData,
        meta: {},
      }),
    );
  };

  const openChecklistLog = () => {
    setShowStartChecklist(false);
    onStartButtonClick(checklist.id);
  };

  const handleTaskClick = (taskId) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          taskId,
        },
      }),
    );
  };

  const handleRowClick = (id, type, checklistLog) => {
    const finalChecklistLog = checklistLog?.data || checklistLog;
    if (type === 'detail') {
      navigateWithWorkspaceUrl(
        `/in-progress-checklist/${
          checklist.id
        }/?logId=${id}&prevUrl=${'/checklist'}${
          finalChecklistLog?.TaskId
            ? `&taskId=${finalChecklistLog?.TaskId}`
            : ''
        }`,
      );
    } else if (type === 'task') {
      handleTaskClick(id);
    }
  };

  const setActiveTabCmp = (tab: string) => {
    dispatch(actions.setLoader({ type: 'checkListLogs', value: true }));
    dispatch(actions.setChecklistLogSubmitted(false));
    setActiveTab(tab);
  };

  const handleCloseChecklistPreview = () => {
    setShowChecklistPreview(false);
    setActiveTabCmp('preview');
    setChecklistLog(null);
  };

  const openMobileModal = () => {
    const dialogId: string = DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG;
    dispatch(
      actions.setDialog({
        dialogId,
        open: true,
        data: {
          title: 'Open Template on Mobile App',
          description: `Scan the QR code below and go to the “${appConstants.checklist.singular.base}” Tab.`,
        },
      }),
    );
  };

  const handleClickChecklistStart = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.START_TEMPLATE_CHECK,
        open: true,
        data: {
          handleStartChecklistClick: openChecklistLog,
        },
      }),
    );
  };

  return (
    <div className="flex-grow-1">
      <ChecklistHeader container>
        <ChecklistTitleAndAttachments checklist={checklist} isPreview={true} />
        <ChecklistHeaderActions>
          <CustomButton
            className="openMobileBtn"
            sx={{
              height: 'fit-content',
              mr: 1,
            }}
            variant="outlined"
            buttonType="grayWhite"
            onClick={openMobileModal}
            startIcon={<MobileIcon className="mobileIcon" />}
          >
            <span>Open On Mobile</span>
          </CustomButton>
          {activeTab === 'preview' && isTemplateAdmin(checklist) && (
            <>
              <ChecklistActionDropdown
                data={checklist}
                handleEditChecklist={() =>
                  navigateWithWorkspaceUrl(`/checklist/${checklist?.id}`)
                }
                handleDuplicateChecklist={() =>
                  duplicateChecklist(checklist?.id)
                }
                handleDeleteChecklist={() => {
                  archiveChecklist(checklist?.id);
                  handleCloseChecklistPreview();
                }}
              />
            </>
          )}
          <IconButton sx={{ p: 0 }} onClick={handleCloseChecklistPreview}>
            <CloseIcon />
          </IconButton>
        </ChecklistHeaderActions>
      </ChecklistHeader>
      <Box className="checklist-list">
        <nav className="checklist-tabs w-100 nav">
          <a
            className={`nav-item nav-link ${
              activeTab === 'preview' ? 'active' : ''
            }`}
            onClick={() => setActiveTabCmp('preview')}
          >
            Preview
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === 'logs' ? 'active' : ''
            }`}
            onClick={() => setActiveTabCmp('logs')}
          >
            {`Submissions ${
              checklistLogs?.length ? `(${checklistLogs.length})` : ''
            }`}
          </a>
          <a
            className={`nav-item nav-link ${
              activeTab === 'log' ? 'active' : ''
            }`}
            hidden={activeTab !== 'log'}
            onClick={() => setActiveTabCmp('log')}
          >
            {logTabTitle}
          </a>
        </nav>
      </Box>
      {activeTab === 'preview' && (
        <Box sx={{ display: 'flex', gap: 2 }} className="px-24 pb-10 pt-10">
          <VerifyFeatureAccess
            paywallUsageId={PAYWALL_LIMITS.OPERATIONS_TEMPLATES}
          >
            <CustomButton
              variant="contained"
              onClick={() => onStartButtonClick(checklist.id)}
            >
              Start
            </CustomButton>
          </VerifyFeatureAccess>
          <VerifyFeatureAccess
            paywallUsageId={PAYWALL_LIMITS.OPERATIONS_TEMPLATES}
          >
            <CustomButton
              variant="outlined"
              className="text-capitalize"
              onClick={() => {
                onAssignClick(checklist);
              }}
            >
              Schedule
            </CustomButton>
          </VerifyFeatureAccess>
        </Box>
      )}
      <div>
        {activeTab === 'preview' && (
          <div className="px-24 py-4">
            <div onClick={() => handleClickChecklistStart()}>
              <PreviewChecklistBody checklist={checklist} />
            </div>
          </div>
        )}
        {activeTab === 'logs' && (
          <ChecklistLogs
            onArchiveCb={onArchiveCb}
            checklist={checklist}
            onRowClick={handleRowClick}
          />
        )}
      </div>
    </div>
  );
};

export default ChecklistPreview;
