import { createSvgIcon } from '@mui/material';

const RadioButtonUnChecked = () => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_2_27302"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2_27302)">
        <path
          d="M16 28C14.3333 28 12.7724 27.6836 11.3173 27.0507C9.86133 26.4169 8.59467 25.5609 7.51733 24.4827C6.43911 23.4053 5.58311 22.1387 4.94933 20.6827C4.31644 19.2276 4 17.6667 4 16C4 14.3333 4.31644 12.772 4.94933 11.316C5.58311 9.86089 6.43911 8.59422 7.51733 7.516C8.59467 6.43867 9.86133 5.58311 11.3173 4.94933C12.7724 4.31644 14.3333 4 16 4C17.6667 4 19.228 4.31644 20.684 4.94933C22.1391 5.58311 23.4058 6.43867 24.484 7.516C25.5613 8.59422 26.4169 9.86089 27.0507 11.316C27.6836 12.772 28 14.3333 28 16C28 17.6667 27.6836 19.2276 27.0507 20.6827C26.4169 22.1387 25.5613 23.4053 24.484 24.4827C23.4058 25.5609 22.1391 26.4169 20.684 27.0507C19.228 27.6836 17.6667 28 16 28ZM16 26.6667C18.9778 26.6667 21.5 25.6333 23.5667 23.5667C25.6333 21.5 26.6667 18.9778 26.6667 16C26.6667 13.0222 25.6333 10.5 23.5667 8.43333C21.5 6.36667 18.9778 5.33333 16 5.33333C13.0222 5.33333 10.5 6.36667 8.43333 8.43333C6.36667 10.5 5.33333 13.0222 5.33333 16C5.33333 18.9778 6.36667 21.5 8.43333 23.5667C10.5 25.6333 13.0222 26.6667 16 26.6667Z"
          fill="white"
        />
      </g>
    </>,
    'RadioButtonChecked',
  );
  return <IconCmp viewBox="0 0 32 32" />;
};
export default RadioButtonUnChecked;
