import { styled } from '@mui/material/styles';
import { Container, fontSize } from '@mui/system';

export const ReadReceiptSidebarContainer = styled('div')(() => ({
  borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
  '.readReceiptBox': {
    width: '320px',
    position: 'relative',
    height: 'calc(100vh - 69px)',
    overflow: 'scroll',
    '.message-user-tag': {
      fontWeight: 'bold',
      color: 'rgba(0,0,0,0.87)',
    },
  },
  '.closeIcon': {
    right: 16,
    top: 16,
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 20,
    cursor: 'pointer',
  },
  '.readBox': {
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 8,
    fontWeight: 400,
    fontSize: 14,
    span: {
      marginRight: 10,
    },
    '.checkIcon': {
      width: 14,
      height: 14,
      fontSize: 16,
      color: 'rgba(46, 125, 50, 1)',
    },
  },
  '.readerCard': {
    padding: '12px 16px',
    '.nameContainer': {
      display: 'flex',
      '.name': {
        marginLeft: 8,
        fontWeight: 600,
        fontSize: 14,
      },
    },

    '.date': {
      fontWeight: 400,
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
}));
