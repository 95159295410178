// MUI Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';

// Custom Icons
import EditIcon from 'components/Icons/editIcon';
import ArchiveIcon from 'components/Icons/archiveIcon';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import AddToFolderIcon from 'components/Icons/addToFolderIcon';
import TemplateShareIcon from 'components/Icons/templateShareIcon';
import DuplicateIcon from 'components/Icons/duplicateIcon';
import LinkExternalIcon from 'components/Icons/linkExternal';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GlobeIcon from 'components/Icons/globeIcon';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import DisabledGlobeIcon from 'components/Icons/disabledGlobeIcon';
import { useSnackbar } from 'notistack';
import DownloadIcon2 from 'components/Icons/downloadIcon2';
import React from 'react';

const ExportWidgetDropdown = ({ exportChartToImage }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'png':
        exportChartToImage();
        break;
      default:
        return;
    }
  };

  const iconStyles = {
    sx: { fill: 'none', fontSize: 14 },
    style: { color: 'rgba(0, 0, 0, 0.54)' },
  };

  return (
    <ActionDropdown
      rootProps={{
        id: 'export-action-dropdown',
      }}
      iconButton={true}
      popperProps={{
        style: { width: 180 },
        placement: 'bottom-start',
      }}
      options={[
        {
          label: 'PNG',
          id: 'png',
          iconAlign: 'start',
          icon: <DownloadIcon2 sx={{ fill: 'none', fontSize: 18 }} />,
          style: { padding: '6px 16px' },
        },
      ]}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: {
          padding: 12,
          borderRadius: 0,
        },
      }}
    >
      <DownloadIcon2 sx={{ fill: 'none', fontSize: 18 }} />
    </ActionDropdown>
  );
};

export default ExportWidgetDropdown;
