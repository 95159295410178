import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import { deleteWidget, getWidgetById } from 'api/dashboards';
import { useCallback } from 'react';
import { WidgetData } from 'pages/Dashboards/interfaces';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch, useSelector } from 'react-redux';
import {
  flaggedItemsFiltersMap,
  metricsAndFiltersMap,
  submissionMetricsAndFilters,
} from 'pages/Dashboards/Widgets/constants';
import moment from 'moment';
import { getUserHotelTz } from 'store/selectors/auth';
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import { cloneDeep } from 'lodash';
interface IUseWidget {
  apiParams: {
    dashboardId: string | undefined;
    widgetId: string;
    data?: any;
  };
  onSuccess?: (data: any) => void;
}
function useWidget(params: IUseWidget) {
  const { dashboardId, widgetId, data } = params.apiParams;
  const queryClient = useQueryClient();
  const getWidgetQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_WIDGET_BY_ID, widgetId],
    queryFn: () => getWidgetById({ dashboardId, widgetId, data }),
    enabled: false,
    retry: false,
  });
  const queryWidgetById: () => WidgetData = useCallback(() => {
    return queryClient.getQueryData([
      QUERY_KEYS.GET_WIDGET_BY_ID,
      widgetId,
    ]) as WidgetData;
  }, []);
  return { getWidgetQuery, queryWidgetById };
}
function useDeleteWidget(params: IUseWidget) {
  const deleteWidgetQuery = useMutation({
    mutationFn: deleteWidget,
    mutationKey: QUERY_KEYS.DELETE_WIDGET,
  });

  return { deleteWidgetQuery };
}

const useViewTasks = () => {
  const dispatch = useDispatch();
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;
  const insertValueInFilters = (
    filters,
    key,
    value,
    globalFilters,
    isScheduled = false,
  ) => {
    const globalFiltersObj = {
      ...(globalFilters?.location?.length && {
        locations: globalFilters.location,
      }),
      ...(globalFilters?.dateRange?.[0] && {
        fromDate: globalFilters?.dateRange?.[0],
      }),
      ...(globalFilters?.dateRange?.[1] && {
        toDate: globalFilters?.dateRange?.[1],
      }),
    };

    const globalFiltersAdvance = [
      ...(globalFiltersObj.locations
        ? [
            {
              filterName: 'locations',
              comparator: 'is',
              value: globalFiltersObj.locations,
              conditional: 'and',
            },
          ]
        : []),
      ...(isScheduled
        ? [
            {
              filterName: 'taskTypes',
              comparator: 'is',
              value: ['recurring'],
              conditional: 'and',
            },
          ]
        : []),
      ...(globalFiltersObj.fromDate || globalFiltersObj.toDate
        ? [
            {
              filterName: isScheduled ? 'dueDate' : 'createdDate',
              comparator: 'is',
              value:
                key == 'dateCreated'
                  ? [
                      moment.tz(value, tz).startOf('day').utc().format(),
                      moment.tz(value, tz).endOf('day').utc().format(),
                    ]
                  : [globalFiltersObj.fromDate, globalFiltersObj.toDate],
              conditional: 'and',
            },
          ]
        : []),
    ];
    const doesFilterAlreadyExist = filters?.filters?.some((f) => {
      return f?.filterName == key;
    });

    const updateFiltersWithSelectedValue = filters?.filters?.map((f) => {
      if (f.filterName === key) {
        const newValue = f?.value?.includes(value)
          ? [value]
          : [...f.value, value];
        return { ...f, value: newValue };
      }
      return f;
    });
    const selectedValueFilterObj = [
      {
        filterName: metricsAndFiltersMap[key],
        comparator: 'is',
        value:
          key === 'dateCompleted'
            ? [
                moment.tz(value, tz).startOf('day').utc().format(),
                moment.tz(value, tz).endOf('day').utc().format(),
              ]
            : [value],
        // value: [value],
        conditional: 'and',
      },
    ];
    if (doesFilterAlreadyExist) {
      return [...updateFiltersWithSelectedValue, ...globalFiltersAdvance];
    } else if (filters?.filters?.length) {
      return [
        ...filters.filters,
        ...selectedValueFilterObj,
        ...globalFiltersAdvance,
      ];
    } else {
      return [...selectedValueFilterObj, ...globalFiltersAdvance];
    }
  };
  const handleViewTasks = async (
    widget,
    value,
    handleReload,
    globalFilters,
    isScheduled = false,
  ) => {
    const taskAdvanceFilters = insertValueInFilters(
      widget.filters,
      widget.config.metric,
      value.id,
      globalFilters,
      isScheduled,
    );

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TASK_TABLE_DIALOG,
        data: {
          successCB: handleReload,
          filters: taskAdvanceFilters,
        },
      }),
    );
  };
  return { handleViewTasks, insertValueInFilters };
};

const useViewFlaggedItems = () => {
  const dispatch = useDispatch();
  const tz: string = useSelector(getUserHotelTz) || DEFAULT_TIMEZONE;

  const removeFilter = (filters, type) => {
    return filters?.filter((item) => item?.filterName !== type) ?? [];
  };

  const insertValueInFilters = (filters, key, value, globalFilters) => {
    let localFilters = cloneDeep(filters?.filters ?? []);
    const globalAdvanceFilters = {
      condition: 'AND',
      filters: [
        ...(key !== 'day' &&
        globalFilters &&
        globalFilters.dateRange &&
        globalFilters.dateRange[0] &&
        globalFilters.dateRange[1]
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'date',
                value: [globalFilters.dateRange[0], globalFilters.dateRange[1]],
              },
            ]
          : []),
        ...(key === 'day'
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'date',
                value: [
                  moment.utc(value).startOf('day').format(),
                  moment.utc(value).endOf('day').format(),
                ],
              },
            ]
          : []),
        ...(globalFilters &&
        globalFilters.location &&
        globalFilters.location.length > 0
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'locations',
                value: globalFilters.location,
              },
            ]
          : []),
      ],
    };

    if (flaggedItemsFiltersMap[key]) {
      localFilters = removeFilter(localFilters, flaggedItemsFiltersMap[key]);
      const selectedValueFilterObj = [
        {
          filterName: flaggedItemsFiltersMap[key],
          comparator: 'is',
          value: [value],
          conditional: 'and',
        },
      ];

      localFilters = [...localFilters, ...selectedValueFilterObj];
    }

    const combinedFilters = {
      globalFilters: globalAdvanceFilters,
      ...(localFilters?.length > 0 && {
        advanceFilters: {
          condition: localFilters?.[0]?.conditional?.toUpperCase(),
          filters: localFilters,
        },
      }),
    };

    return combinedFilters;
  };

  const handleViewFlaggedItems = async (
    widget,
    value,
    handleReload,
    globalFilters,
  ) => {
    const filters: any = insertValueInFilters(
      widget.filters,
      widget.config.metric,
      value,
      globalFilters,
    );

    if (widget?.config?.metric === 'question') {
      filters['advanceLogItemFilters'] = {
        condition: 'AND',
        filters: [
          {
            comparator: 'is',
            conditional: 'and',
            filterName: 'item',
            value: [value],
          },
        ],
      };
    }

    if (!filters.advanceFilters) {
      filters.advanceFilters = {};
    }

    const flagCategories = filters?.advanceFilters?.filters?.find(
      (item) => item?.filterName === 'flagCategories',
    );

    if (flagCategories) {
      filters.flagCategoryIds = flagCategories?.value ?? [];
    }

    if (widget.config.metric === 'flagCategory') {
      filters.flagCategoryIds = value?.id ? [value?.id] : [];
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.FLAGGED_ITEMS_REPORT_DIALOG,
        data: {
          successCB: handleReload,
          filters,
          title: widget?.title,
          type: widget?.config?.metric,
          itemId: value,
        },
      }),
    );
  };

  const handleViewFlaggedItemsChartWidgets = async (
    widget,
    value,
    handleReload,
    globalFilters,
  ) => {
    const filters: any = insertValueInFilters(
      widget.filters,
      widget.config.metric,
      value?.id,
      globalFilters,
    );

    if (!filters.advanceFilters) {
      filters.advanceFilters = {};
    }

    let itemId = null;
    if (widget?.config?.metric === 'question') {
      filters['advanceLogItemFilters'] = {
        condition: 'AND',
        filters: [
          {
            comparator: 'is',
            conditional: 'and',
            filterName: 'item',
            value: [value?.question?.id],
          },
        ],
      };

      itemId = value?.question?.id;
    }

    const flagCategories = filters?.advanceFilters?.filters?.find(
      (item) => item?.filterName === 'flagCategories',
    );

    if (flagCategories) {
      filters.flagCategoryIds = flagCategories?.value ?? [];
    }

    if (widget.config.metric === 'flagCategory') {
      filters.flagCategoryIds = value?.id
        ? Array.isArray(value?.id)
          ? value?.id
          : [value?.id]
        : [];
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.FLAGGED_ITEMS_REPORT_CHART_BASED_DIALOG,
        data: {
          successCB: handleReload,
          filters,
          title: widget?.title,
          type: widget?.config?.metric,
          itemId,
        },
      }),
    );
  };

  return {
    handleViewFlaggedItemsChartWidgets,
    handleViewFlaggedItems,
    insertValueInFilters,
  };
};

// Use View Submissions
const useViewSubmissions = () => {
  const dispatch = useDispatch();
  const insertValueInFilters = (
    filters,
    key,
    value,
    globalFilters,
    submissionFilters,
  ) => {
    let localFilters = cloneDeep(filters?.filters ?? []);
    const localItemFilters = cloneDeep(submissionFilters ?? []);

    const globalAdvanceFilters = {
      condition: 'AND',
      filters: [
        ...(key !== 'dateStarted' &&
        globalFilters &&
        globalFilters.dateRange &&
        globalFilters.dateRange[0] &&
        globalFilters.dateRange[1]
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'date',
                value: [globalFilters.dateRange[0], globalFilters.dateRange[1]],
              },
            ]
          : []),

        ...(key === 'dateStarted'
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'date',
                value: [
                  moment.utc(value).startOf('day').format(),
                  moment.utc(value).endOf('day').format(),
                ],
              },
            ]
          : []),
        ...(globalFilters &&
        globalFilters.location &&
        globalFilters.location.length > 0
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'locations',
                value: globalFilters.location,
              },
            ]
          : []),
      ],
    };

    localFilters = localFilters?.filter(
      (filter) =>
        !(filter?.filterName === 'user' && filter?.value?.[0] === 'all'),
    );

    const selectedValueFilterObj = [
      {
        filterName: submissionMetricsAndFilters[key],
        comparator: 'is',
        value: [value],
        conditional: 'and',
      },
    ];

    localFilters = [...localFilters, ...selectedValueFilterObj];

    const combinedFilters = {
      globalFilters: globalAdvanceFilters,
      ...(localFilters?.length > 0 && {
        advanceFilters: {
          condition: localFilters?.[0]?.conditional?.toUpperCase(),
          filters: localFilters,
        },
      }),
      ...(localItemFilters?.length > 0 && {
        advanceItemFilters: {
          condition: 'AND',
          filters: localItemFilters,
        },
      }),
    };

    return combinedFilters;
  };

  const handleViewSubmissions = (
    widget,
    value,
    handleReload,
    globalFilters,
  ) => {
    const filters: any = insertValueInFilters(
      widget.filters,
      widget.config.metric,
      value.id,
      globalFilters,
      widget?.additionalFilters?.submissionFilters?.filters,
    );

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TEMPLATE_SUBMISSIONS_DIALOG,
        data: {
          filters: filters,
          isLogs: true,
        },
      }),
    );
  };
  return { handleViewSubmissions };
};
export {
  useWidget,
  useDeleteWidget,
  useViewFlaggedItems,
  useViewTasks,
  useViewSubmissions,
};
