import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import NotificationsIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import {
  SettingNavCnt,
  StyledListItem,
} from 'pages/settings/settingsNav/settingsNav.style';
import CategoryIcon from '@mui/icons-material/ListOutlined';
import OrganizationIcon from '@mui/icons-material/CasesOutlined';
import { useLocation } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useIsAdmin, useUserProfileName } from 'customhooks/index';
import CTypography from 'components/CTypography';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import { HasPermission } from 'components/HasPermission';
import { PERMISSIONS, TALK_TO_SALES_URL } from 'utils/constants';
import useBilling from 'controller/useBilling';
import KioskModeIcon from 'components/Icons/kioskModeIcon';
import ActivityLogIcon from 'components/Icons/activityLogIcon';
import DIALOGS from 'utils/dialogIds';
import { closeDialog, setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import FeatureLockIcon from 'components/Icons/featureLockIcon';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import KeyIcon from 'components/Icons/keyIcon';

function SettingsNavigation() {
  const { billingData } = useBilling();
  const { hasFeature, FEATURES } = useCustomPermission();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const currentPath = useLocation().pathname;
  const listItemBtnStyles = {
    minHeight: 48,
    justifyContent: 'center',
    px: 2.5,
  };

  const userName: any = useUserProfileName();

  const upSellModalForFeature = () => {
    const dialogId = DIALOGS.UPSELL_MODAL;
    dispatch(
      setDialog({
        dialogId,
        open: true,
        data: {
          isEscapable: true,
          icon: <FeatureLockIcon />,
          title: 'You’ve discovered a Paid Feature!',
          description: (
            <>
              <b>{FEATURES.KIOSK_MODE}</b> isn’t available on your current plan.
              Upgrade Now to access this feature.
            </>
          ),
          primaryButtonText: 'Talk to sales',
          primaryButtonClickCB: () => {
            dispatch(closeDialog(dialogId));
            dispatch(
              setCalendlyInfo({
                url: TALK_TO_SALES_URL,
                open: true,
              }),
            );
          },
          buttonDisplayDirectionCol: true,
        },
      }),
    );
  };

  //Handle Navigate to path
  const handleNavClick = (path) => {
    navigateWithWorkspaceUrl(path);
  };
  return (
    <SettingNavCnt>
      <List className="sidebarMenuList">
        <HasPermission
          permissions={[
            PERMISSIONS.CAN_MANAGE_WORKSPACES,
            PERMISSIONS.CAN_MANAGE_CATEGORIES,
            PERMISSIONS.CAN_MANAGE_USERS,
            PERMISSIONS.CAN_MANAGE_TEAMS,
            PERMISSIONS.CAN_MANAGE_BILLING,
            PERMISSIONS.CAN_TOGGLE_KIOSK_MODE,
            PERMISSIONS.CAN_MANAGE_ACTIVITY_LOGS,
            PERMISSIONS.CAN_MANAGE_WORKSPACE_CONFIG,
          ]}
        >
          <>
            <StyledListItem
              disablePadding
              sx={{ display: 'block' }}
              isSelected={currentPath.includes(
                '/settings/organization-management',
              )}
            >
              <ListItemButton
                onClick={(event) =>
                  handleNavClick('/settings/organization-management')
                }
                sx={listItemBtnStyles}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                  }}
                >
                  <OrganizationIcon />
                </ListItemIcon>
                <ListItemText primary={'Workspace'} sx={{ opacity: 1 }} />
              </ListItemButton>
            </StyledListItem>

            <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_CATEGORIES]}>
              <StyledListItem
                disablePadding
                sx={{ display: 'block' }}
                isSelected={currentPath.includes(
                  '/settings/service-management',
                )}
              >
                <ListItemButton
                  onClick={(event) =>
                    handleNavClick('/settings/service-management')
                  }
                  sx={listItemBtnStyles}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: 'center',
                    }}
                  >
                    <CategoryIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Categories'} sx={{ opacity: 1 }} />
                </ListItemButton>
              </StyledListItem>
            </HasPermission>
            {!billingData?.billingInfo?.isCustom && (
              <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_BILLING]}>
                <StyledListItem
                  disablePadding
                  sx={{ display: 'block' }}
                  isSelected={currentPath.includes('/settings/billing')}
                >
                  <ListItemButton
                    onClick={(event) => handleNavClick('/settings/billing')}
                    sx={listItemBtnStyles}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: 'center',
                      }}
                    >
                      <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Billing'} sx={{ opacity: 1 }} />
                  </ListItemButton>
                </StyledListItem>
              </HasPermission>
            )}

            <HasPermission permissions={[PERMISSIONS.CAN_TOGGLE_KIOSK_MODE]}>
              <StyledListItem
                disablePadding
                sx={{ display: 'block' }}
                isSelected={currentPath.includes('/settings/kiosk')}
              >
                <ListItemButton
                  onClick={() => {
                    handleNavClick('/settings/kiosk');
                  }}
                  sx={listItemBtnStyles}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: 'center',
                    }}
                  >
                    <KioskModeIcon
                      style={{
                        fill: 'none',
                        color: '#000000c4',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={'Kiosk Mode'} sx={{ opacity: 1 }} />
                </ListItemButton>
              </StyledListItem>
            </HasPermission>

            <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_SSO]}>
              <StyledListItem
                disablePadding
                sx={{ display: 'block' }}
                isSelected={currentPath.includes('/settings/sso')}
              >
                <ListItemButton
                  onClick={() => {
                    handleNavClick('/settings/sso');
                  }}
                  sx={listItemBtnStyles}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: 'center',
                    }}
                  >
                    <KeyIcon sx={{ height: 20 }} />
                    {/* <KioskModeIcon
                      style={{
                        fill: 'none',
                        color: '#000000c4',
                      }}
                    /> */}
                  </ListItemIcon>
                  <ListItemText
                    primary={'Single Sign-On'}
                    sx={{ opacity: 1 }}
                  />
                </ListItemButton>
              </StyledListItem>
            </HasPermission>
            <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ACTIVITY_LOGS]}>
              <StyledListItem
                disablePadding
                sx={{ display: 'block' }}
                isSelected={currentPath.includes('/settings/activity-logs')}
              >
                <ListItemButton
                  onClick={() => handleNavClick('/settings/activity-logs')}
                  sx={listItemBtnStyles}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      justifyContent: 'center',
                    }}
                  >
                    <ActivityLogIcon
                      style={{
                        fill: 'none',
                        color: '#0000007a',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={'Activity Logs'} sx={{ opacity: 1 }} />
                </ListItemButton>
              </StyledListItem>
            </HasPermission>
            <Divider />
          </>
        </HasPermission>
      </List>

      <CTypography
        sx={{
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '22px',
          color: '#000000',
          mt: 2,
        }}
      >
        {userName}
      </CTypography>
      <List className="sidebarMenuList">
        <StyledListItem
          disablePadding
          sx={{ display: 'block' }}
          isSelected={currentPath.includes('/settings/my-profile')}
        >
          <ListItemButton
            onClick={(event) => handleNavClick('/settings/my-profile')}
            sx={listItemBtnStyles}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: 'center',
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'My Settings'} sx={{ opacity: 1 }} />
          </ListItemButton>
        </StyledListItem>

        <StyledListItem
          disablePadding
          sx={{ display: 'block' }}
          isSelected={currentPath.includes('/settings/notifications')}
        >
          <ListItemButton
            onClick={(event) => handleNavClick('/settings/notifications')}
            sx={listItemBtnStyles}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: 'center',
              }}
            >
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary={'Notifications'} sx={{ opacity: 1 }} />
          </ListItemButton>
        </StyledListItem>

        <StyledListItem
          disablePadding
          sx={{ display: 'block' }}
          isSelected={currentPath.includes('/settings/my-workspaces')}
        >
          <ListItemButton
            onClick={(event) => handleNavClick('/settings/my-workspaces')}
            sx={listItemBtnStyles}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: 'center',
              }}
            >
              <DomainAddIcon />
            </ListItemIcon>
            <ListItemText primary={'My Workspaces'} sx={{ opacity: 1 }} />
          </ListItemButton>
        </StyledListItem>
      </List>
    </SettingNavCnt>
  );
}

export default SettingsNavigation;
