import styled from '@mui/system/styled';
import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

export const DashboardTemplateItemCnt = styled(Grid)({});
export const StyledItem = styled('div')({
  border: '1px solid #E0E0E0',
  boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.06)',
  borderRadius: 14,
  overflow: 'hidden',
  '&:hover': {
    border: '1px solid #6868FE',
    cursor: 'pointer',
  },
  '.contentCnt': {
    padding: '10px 16px 22px 16px',
    '.heading': {
      color: '#212121',
      fontSize: 15,
      fontWeight: 700,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: 5,
      width: '98%',
    },
    '.subTitle': {
      color: '#616161',
      fontSize: 13,
      fontWeight: 400,
    },
  },
  '.imageCnt': {
    height: 100,
    width: '100%',
    background: '#EBEAFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.chartIcon': {
      color: '#6868FE',
      fontSize: 36,
    },
  },
});
export const TemplateImage = styled('div')({
  height: 100,
  width: '100%',
});
export const CreatedBy = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '11px',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.87)',
}));
export const EditedTime = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '14px',
  color: '#212121',
}));

export const DraftChip = styled(Chip)(() => ({
  background: '#CCCBFF !important',
  color: '#4E48FA !important',
  height: '22px',
  padding: '0 8px',
  alignSelf: 'flex-end',
  marginLeft: 'auto',
  '& .MuiChip-label': {
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: '14px',
  },
}));
