import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIconPencilBottom from 'components/Icons/editIconPencilBottomLine';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import CustomButton from 'components/Button/CustomButton';
import DeleteIcon from 'components/Icons/deleteIcon';
import { colors } from 'components/theme/constants';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { setDialog } from 'store/actions/dialogActions';
import { HeaderCnt } from './style';
import xeniaApi from 'api/index';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { archiveSeries } from 'api/tasksApi';
import { togglePause } from 'api/schedulesApi';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const ScheduleDetailHeader = (props) => {
  const { schedule, getScheduleDetails } = props;
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { addNewAssignees } = useAssigneesUtils();
  const { isMobileDeviceSm } = useResponsive();

  const goBackHandler = () => {
    navigateWithWorkspaceUrl('/scheduled-work/manage-schedules');
  };

  const editScheduleHandler = async () => {
    const taskDetails = await xeniaApi.taskDetailSingleListing({
      id: schedule?.parentSeriesId,
    });

    dispatch(
      setDialog({
        open: true,
        dialogId: 'editTask',
        data: {
          onSuccessCallBack: getScheduleDetails,
          dialogState: {
            viewLess: false,
          },
          editSeriesType: 'All',
          taskData: taskDetails?.data,
        },
      }),
    );
  };

  const DeleteScheduleTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to delete this schedule ?
      </Typography>
    );
  };

  const PauseScheduleTemplate = () => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        Are you sure you want to pause this schedule ?
      </Typography>
    );
  };

  const actionsDropdownHandler = async (options) => {
    switch (options?.id) {
      case 'delete':
        dispatch(
          setDialog({
            dialogId: 'confirmationDialog',
            open: true,
            data: {
              title: 'Delete Schedule',
              ContentTemplate: DeleteScheduleTemplate,
              onConfirmCallback: async () => {
                const result = await archiveSeries(schedule?.taskUniqueId);
                if (result) {
                  goBackHandler();
                }
              },
              confirmationText: 'Delete Schedule',
            },
          }),
        );

        break;

      case 'pause_resume': {
        if (!schedule?.isPaused) {
          dispatch(
            setDialog({
              dialogId: 'confirmationDialog',
              open: true,
              data: {
                title: 'Pause Schedule',
                ContentTemplate: PauseScheduleTemplate,
                onConfirmCallback: async () => {
                  const result = await togglePause(schedule?.id);
                  if (result) {
                    getScheduleDetails?.();
                  }
                },
                confirmationText: 'Pause Schedule',
              },
            }),
          );
          return;
        }

        const response = await togglePause(schedule?.id);
        if (response) {
          getScheduleDetails?.();
        }
        break;
      }

      case 'edit':
        editScheduleHandler();
        break;

      default:
        return null;
    }
  };

  return (
    <HeaderCnt
      sx={{
        ...(isMobileDeviceSm && {
          justifyContent: 'end',
        }),
      }}
    >
      {!isMobileDeviceSm && (
        <Stack alignItems={'center'} direction={'row'}>
          <ArrowBackRoundedIcon
            sx={{ marginRight: '12px', cursor: 'pointer' }}
            onClick={goBackHandler}
          />
          <Typography
            fontSize={'20px'}
            fontWeight={'400'}
            style={{ cursor: 'pointer' }}
            onClick={goBackHandler}
          >
            Schedules
          </Typography>
          <KeyboardArrowRightRoundedIcon />
          <Typography fontSize={'20px'} fontWeight={'700'}>
            {schedule?.title}
          </Typography>
        </Stack>
      )}

      <Stack gap="12px" alignItems={'center'} direction={'row'}>
        {!isMobileDeviceSm && (
          <CustomButton
            onClick={editScheduleHandler}
            style={{
              padding: '7px 16px',
              borderRadius: '8px',
              fontWeight: 600,
            }}
            startIcon={
              <EditIconPencilBottom
                sx={{
                  '& path': {
                    stroke: 'white',
                    strokeOpacity: 1,
                  },
                }}
              />
            }
            variant="contained"
          >
            Edit Schedule
          </CustomButton>
        )}

        <ActionDropdown
          paperProps={{
            style: {
              minWidth: 145,
            },
          }}
          options={[
            ...(isMobileDeviceSm
              ? [
                  {
                    label: 'Edit',
                    id: 'edit',
                    icon: <EditIconPencilBottom />,
                    iconAlign: 'start',
                  },
                ]
              : []),
            {
              label: schedule?.isPaused ? 'Resume Schedule' : 'Pause Schedule',
              id: 'pause_resume',
              icon: schedule?.isPaused ? (
                <PlayArrowIcon style={{ fontSize: 21 }} />
              ) : (
                <PauseIcon style={{ fontSize: 17 }} />
              ),
              iconAlign: 'start',
            },
            {
              label: 'Delete',
              id: 'delete',
              icon: <DeleteIcon style={{ color: colors.red, fill: 'none' }} />,
              iconAlign: 'start',
              sx: {
                color: `${colors.red}`,
              },
            },
          ]}
          handleOptionSelect={actionsDropdownHandler}
        >
          <MoreHorizIcon />
        </ActionDropdown>
      </Stack>
    </HeaderCnt>
  );
};

export default ScheduleDetailHeader;
