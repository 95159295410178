import { useState } from 'react';
import { useDispatch } from 'react-redux';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReOpenIcon from '@mui/icons-material/CachedOutlined';

import { setDialog } from 'store/actions/dialogActions';
import actions from 'store/actions';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import DeleteIcon from 'components/Icons/deleteIcon';

import DIALOGS from 'utils/dialogIds';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';

const actionsDropdown = [
  { label: 'Restore', id: 'restore', icon: <ReOpenIcon />, iconAlign: 'start' },
  {
    label: 'Delete',
    id: 'delete',
    icon: <DeleteIcon sx={{ color: 'red' }} />,
    iconAlign: 'start',
    sx: {
      color: 'red',
    },
  },
];

function ArchiveDocumentActionDropdown(props) {
  const { document, deleteDocumentCallback } = props;
  const { unarchiveDocument, deleteDocument } = useDocumentsFolders();
  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  const dispatch = useDispatch();

  const deleteDocumentHandler = async () => {
    setDeleteButtonLoader(true);
    await deleteDocument(document?.id);
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.CONFIRMATION,
      }),
    );
    setDeleteButtonLoader(false);
    deleteDocumentCallback(document?.id);
  };

  // UnArchive Document
  const handleRestoreDocument = async () => {
    await unarchiveDocument(document?.id);
    deleteDocumentCallback(document?.id);
  };

  //Handler for option select of action dropdown
  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'delete':
        dispatch(
          actions.setDialog({
            dialogId: DIALOGS.CONFIRMATION,
            open: true,
            data: {
              title: 'Delete File',
              description: 'Are you sure you want to delete this file?',
              onConfirmCallback: deleteDocumentHandler,
              confirmButtonProps: {
                buttonLoader: deleteButtonLoader,
              },
            },
          }),
        );
        break;
      case 'restore':
        handleRestoreDocument();
        break;
    }
  };

  return (
    <ActionDropdown
      rootProps={{
        id: 'documents-action-dropdown',
        className: 'documents-action-dropdown',
      }}
      options={actionsDropdown}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { padding: 2 },
      }}
      popperProps={{
        sx: {
          zIndex: 1301,
        },
      }}
      paperProps={{
        style: {
          minWidth: 145,
        },
      }}
    >
      <MoreHorizIcon />
    </ActionDropdown>
  );
}

export default ArchiveDocumentActionDropdown;
