import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getOnboardingStatus } from 'api/webOnboardingApi';
import MaintenanceChecklist from './maintenance';
import OperationsChecklist from './operations';
import { Container } from './style';
import GetStarted from '../getStarted';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { GOALS } from 'utils/globalVariable';

const OnboardingChecklist = () => {
  const [onboardingStatus, setOnboardingStatus] = useState<any>(null);
  const checklistCategories: any = useSelector(
    selectors.getChecklistCategories,
  );
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const hasTaskPermission = hasPermission(PERMISSIONS.CAN_MANAGE_TASKS);

  const userProfile: any = useSelector(selectors.getUserProfile);

  const onboardingGoal = useMemo(() => {
    let isOperations = false;
    let isMaintenance = false;

    userProfile?.Hotel?.ChecklistCategoryIds?.forEach(function (goalId) {
      const goal = checklistCategories?.find(
        (item) => item.id === goalId,
      )?.name;

      if (goal === GOALS.operations || goal === GOALS.unitOperations) {
        isOperations = true;
      }

      if (goal === GOALS.maintenance) {
        isMaintenance = true;
      }
    });

    if (isOperations) {
      return GOALS.operations;
    }

    if (isMaintenance) {
      return GOALS.maintenance;
    }
  }, [userProfile, checklistCategories]);

  const getOnboardingChecklistDetails = async () => {
    const onboardingStatus = await getOnboardingStatus();
    setOnboardingStatus(onboardingStatus);
  };

  useEffect(() => {
    if (
      (onboardingGoal === GOALS.maintenance ||
        onboardingGoal === GOALS.operations) &&
      !userProfile?.Hotel?.onboardingCompleted
    ) {
      getOnboardingChecklistDetails();
    }
  }, [onboardingGoal]);

  const showOnboardingChecklist = useMemo(() => {
    return (
      hasTaskPermission &&
      (onboardingGoal === GOALS.maintenance ||
        onboardingGoal === GOALS.operations) &&
      !onboardingStatus?.onboardingCompleted &&
      !userProfile?.Hotel?.onboardingCompleted
    );
  }, [hasTaskPermission, onboardingGoal, onboardingStatus]);

  const showQuickActions = useMemo(() => {
    if (!hasTaskPermission) {
      return true;
    }
    if (
      onboardingGoal !== GOALS.maintenance &&
      onboardingGoal !== GOALS.operations
    ) {
      return true;
    }
    if (
      (onboardingGoal === GOALS.maintenance ||
        onboardingGoal === GOALS.operations) &&
      (onboardingStatus?.onboardingCompleted ||
        userProfile?.Hotel?.onboardingCompleted)
    ) {
      return true;
    }

    return false;
  }, [hasTaskPermission, onboardingGoal, onboardingStatus]);

  return (
    <Container>
      {showQuickActions && <GetStarted />}
      {showOnboardingChecklist && (
        <>
          {onboardingGoal === GOALS.maintenance && (
            <MaintenanceChecklist
              onboardingStatus={onboardingStatus}
              getOnboardingChecklistDetails={getOnboardingChecklistDetails}
            />
          )}
          {onboardingGoal === GOALS.operations && (
            <OperationsChecklist
              onboardingStatus={onboardingStatus}
              getOnboardingChecklistDetails={getOnboardingChecklistDetails}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default OnboardingChecklist;
