import { createSvgIcon } from '@mui/material';

const PieChartIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M22 5.59112C22 4.40689 22 3.81477 21.7091 3.33615C21.477 2.9542 21.012 2.59281 20.5845 2.46217C20.0489 2.29848 19.5498 2.42566 18.5515 2.68001C9.03662 5.1043 2 13.7305 2 24.0001C2 36.1504 11.8497 46.0001 24 46.0001C26.8697 46.0001 29.611 45.4507 32.1246 44.4512C33.0812 44.0709 33.5595 43.8807 33.8968 43.4334C34.1659 43.0765 34.3299 42.5107 34.2932 42.0652C34.2473 41.5068 33.8993 41.0279 33.2033 40.0699L22.3955 25.1943C22.1471 24.861 22 24.4477 22 24.0001V5.59112Z"
        fill="currentColor"
      />
      <path
        d="M42.409 26.0001C43.5932 26.0001 44.1853 26.0001 44.6639 26.291C45.0459 26.5231 45.4072 26.9881 45.5379 27.4155C45.7016 27.9511 45.5745 28.45 45.3203 29.4479C44.5045 32.6502 42.9862 35.5718 40.9392 38.0389C40.28 38.8334 39.9504 39.2307 39.4208 39.4133C38.9978 39.5591 38.4097 39.5402 37.9969 39.3675C37.4801 39.1513 37.1317 38.6717 36.4347 37.7124L29.771 28.5406C29.1754 27.7208 28.8776 27.3109 28.8883 26.9687C28.8977 26.6708 29.0394 26.3926 29.2749 26.2099C29.5455 26.0001 30.0521 26.0001 31.0654 26.0001H42.409Z"
        fill="currentColor"
      />
      <path
        d="M26 5.59112C26 4.40689 26 3.81478 26.2909 3.33616C26.523 2.9542 26.988 2.59281 27.4155 2.46218C27.9511 2.29848 28.4502 2.42565 29.4485 2.68C37.221 4.66033 43.3398 10.7791 45.3201 18.5517C45.5744 19.5499 45.7016 20.049 45.5379 20.5847C45.4073 21.0121 45.0459 21.4771 44.6639 21.7092C44.1853 22.0001 43.5932 22.0001 42.409 22.0001L27.6 22.0001C27.0399 22.0001 26.7599 22.0001 26.546 21.8911C26.3578 21.7952 26.2049 21.6423 26.109 21.4541C26 21.2402 26 20.9602 26 20.4001V5.59112Z"
        fill="currentColor"
      />
    </>,
    'PieChartIcon',
  );
  return <IconCmp viewBox="0 0 48 48" fill="none" {...props} />;
};
export default PieChartIcon;
