// React
import { useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ChecklistAttachmentPopover from 'components/common/jsxrender/checklistV2/builder/checklistAttachmentPopover';

// Icons
import MobileIcon from 'components/Icons/mobileIcon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIconPencilBox from 'components/Icons/editIconPencilBox';

// Styled
import {
  StyledHeader,
  TemplateNameAndAttachmentWrapper,
  AttachmentBox,
  TemplateName,
  StyledButton,
  ButtonsWrapper,
  CustomizeTemplateBtn,
} from './styled';

// Constants
import DIALOGS from 'utils/dialogIds';

// Hooks
import { useAttachments } from 'controller/useAttachments';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface HeaderPropTypes {
  data:
    | {
        templateId: string;
        name: string;
        attachments: string[];
        template: any;
      }
    | undefined;
}

const Header = ({ data }: HeaderPropTypes) => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickMobile = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG,
        open: true,
        data: {
          title: 'Open Template on Mobile App',
          description: 'Scan the QR code below and go to the “Templates” Tab.',
        },
      }),
    );
  };

  const handleEditTemplate = () => {
    const templateId = data?.templateId;
    navigateWithWorkspaceUrl(`/checklist/${templateId}`, '', {
      state: { redirectTo: `/templates/${templateId}` },
    });
  };

  const { formattedAttachments } = useAttachments(data);

  return (
    <StyledHeader>
      <TemplateNameAndAttachmentWrapper>
        <TemplateName>{data?.name}</TemplateName>
        {data?.attachments && data?.attachments?.length > 0 && (
          <CustomDropdown
            clickaway
            onClickawayCallback={() => setAnchorEl(null)}
            buttonRenderer={
              <AttachmentBox
                onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
              >
                <AttachFileIcon />
                {data.attachments.length}
              </AttachmentBox>
            }
            popperProps={{
              sx: {
                width: '512px',
                zIndex: 1400,
              },
              className: 'checklist-attachments',
              placement: 'bottom-start',
              anchorEl,
              open: Boolean(anchorEl),
              content: (
                <ChecklistAttachmentPopover
                  attachments={formattedAttachments}
                  handleCloseAttachmentPopover={() => setAnchorEl(null)}
                  showPreviews
                />
              ),
            }}
          />
        )}
      </TemplateNameAndAttachmentWrapper>
      <ButtonsWrapper>
        {data?.template?.HotelChecklist?.isEditable && (
          <CustomizeTemplateBtn
            startIcon={<EditIconPencilBox style={{ fill: 'none' }} />}
            variant="contained"
            onClick={handleEditTemplate}
          >
            Customize Template
          </CustomizeTemplateBtn>
        )}
        <StyledButton
          startIcon={<MobileIcon />}
          variant="outlined"
          buttonType="grayWhite"
          onClick={handleClickMobile}
        >
          Open On Mobile
        </StyledButton>
      </ButtonsWrapper>
    </StyledHeader>
  );
};

export default Header;
