import React, { useContext, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { DocumentsContext } from 'pages/documents/context/context';

import { DocumentsCnt, LoaderCnt } from 'pages/documents/style';
import MyDocumentsHeader from 'pages/documents/subHeader/Header';
import Listing from 'pages/documents/listing';
import ArchiveEmptyState from 'pages/documents/emptyStates/archive/archive';
import FolderDocumentsEmpty from 'pages/documents/emptyStates/folderDocuments/folderDocuments';
import BreadCrumbs from 'pages/documents/foldersDetailView/BreadCrumbs/breadCrumbs';
import EmptyState from 'pages/documents/emptyStates/main/empty';
import useMeasure from 'react-use-measure';
import {
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';

const MyDocuments = () => {
  const { state, updateState, getFolderAndDocuments } =
    useContext(DocumentsContext);
  const [headerRef, headerBounds] = useMeasure();
  const [breadcrumbRef, breadcrumbBounds] = useMeasure();
  const { isArchive, data, loading, selectedFolder } = state;
  const { lastFolderId, getFolderIdsFromUrl } = useCurrentFolderId();
  const { getFolders } = useDocumentsFolders();
  const params = useParams();
  const location = useLocation();

  const updateSelectedFolderByQueryId = async (folderId) => {
    const foldersList = await getFolders({
      folderId,
      ...(isArchive ? { archived: true } : {}),
    });
    const selected = foldersList?.find((folder) => folder?.id === folderId);
    updateState({
      selectedFolder: selected,
    });
  };

  useEffect(() => {
    let newData: any = {};
    const paths = window.location.pathname.split('/');

    if (state?.isArchive && paths.indexOf('archive') > -1) {
      newData.archived = true;
    }

    if (state?.isArchive && paths.includes('my-documents')) {
      updateState({
        isArchive: false,
      });

      newData = {
        archived: false,
      };
    }
    if (lastFolderId && params?.['*']?.startsWith('folder/')) {
      newData.folderId = lastFolderId;
      updateSelectedFolderByQueryId(lastFolderId);
      getFolderAndDocuments(newData);
    } else {
      getFolderAndDocuments(newData);
    }
  }, [location.pathname]);

  useEffect(() => {
    updateState({
      filtersHeight: headerBounds?.height,
    });
  }, [headerBounds]);

  useEffect(() => {
    updateState({
      breadCrumbHeight: breadcrumbBounds?.height,
    });
  }, [breadcrumbBounds]);

  const isData = data?.folders?.length > 0 || data?.documents?.length > 0;

  return (
    <>
      <div ref={headerRef}>
        <MyDocumentsHeader
          isData={isData}
          getFolderAndDocuments={getFolderAndDocuments}
        />
      </div>

      <DocumentsCnt>
        {selectedFolder && (
          <div ref={breadcrumbRef}>
            <BreadCrumbs />
          </div>
        )}

        {loading && (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        )}

        {!loading && !isData && !isArchive && !selectedFolder && <EmptyState />}
        {!loading && !isData && isArchive && <ArchiveEmptyState />}
        {!loading && !isData && !isArchive && selectedFolder && (
          <FolderDocumentsEmpty />
        )}
        <Routes>
          <Route
            path={'/folder/*'}
            element={!loading && isData && <Listing />}
          ></Route>
          <Route path={'/'} element={!loading && isData && <Listing />}></Route>
        </Routes>
      </DocumentsCnt>
    </>
  );
};

export default MyDocuments;
