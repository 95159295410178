import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  aiRequestHandler,
  uploadImageHandler,
} from 'components/SOPEditor/utils';
import { EditorCnt } from 'components/SOPEditor/style';
import AIAssitantIcon from 'public/assets/img/ai-assistant.svg';
import { v4 as uuidV4 } from 'uuid';
import { Modal, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { uploadPublicDocumentCall } from 'api/documents';
interface Props {
  onChangeCallback?: (value: string) => void;
  initialValue?: string;
  doc?: any;
  editorHeight?: any;
  metaInfo?: any;
  isMobile?: boolean;
}

function RichTextEditor(props: Props) {
  const {
    onChangeCallback,
    initialValue = '',
    doc = {},
    editorHeight = '600px',
    metaInfo = {},
  } = props;

  const editorRef: any = useRef(null);
  const [value, setValue] = React.useState(props.initialValue ?? '');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  const handleEditorChange = (content: string) => {
    setValue(content);
    onChangeCallback?.(content);
  };

  const viewConfig = {
    readonly: true, // Make the editor read-only
    menubar: false, // Hide the menubar
    toolbar: false, // Hide the toolbar
    plugins: '', // Disable all plugins
    skin: 'borderless',
    branding: false,
    height: editorHeight,
    resize: false,
  };

  const editConfig = {
    height: editorHeight,
    placeholder:
      'Type here or click the "AI Assistant" button and ask AI to generate content for you',
    branding: false,
    images_file_types: 'jpg,svg,webp,heic,png,jpeg',
    plugins:
      'ai advtable powerpaste casechange searchreplace autolink advcode image link media codesample table  tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker',
    toolbar:
      'customAIBtn | image media alignleft aligncenter alignright alignjustify lineheight checklist bullist numlist undo redo print spellcheckdialog formatpainter bold italic underline forecolor backcolor link removeformat',
    images_upload_handler: (blobInfo) => uploadImageHandler(blobInfo, doc),
    file_picker_callback: (cb, value, meta) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'video/*');
      input.onchange = function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const file = this.files[0];
        const reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          const id = uuidV4();
          const blobCache = editorRef?.current?.editorUpload?.blobCache;
          const base64 = reader?.result?.split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          setUploading(true);

          uploadPublicDocumentCall([blobInfo.blob()], doc?.id).then(
            (response) => {
              if (response) {
                cb(response?.baseUrl + response?.filePath?.[0]);
                setUploading(false);
              } else {
                setUploading(false);
              }
            },
          );
        };
      };
      input.click();
    },
    setup: function (editor) {
      editor.ui.registry.addIcon(
        'aiIcon',
        `<div id="aiBtnCnt"><img src="${AIAssitantIcon}" /></div>`,
      );
      editor.ui.registry.addButton('customAIBtn', {
        icon: 'aiIcon',

        onAction: function () {
          editor.execCommand('mceAIDialog');
        },
      });
    },

    ai_request: (request, respondWith) =>
      aiRequestHandler(request, respondWith, doc),
  };

  return (
    <EditorCnt>
      <Editor
        apiKey={'cvkqth1ciysbtqvtbghlus939nh1o93wfor3mt3rw8219an1'}
        onInit={(_, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        value={value}
        onEditorChange={metaInfo?.canEdit && handleEditorChange}
        init={metaInfo?.canEdit ? editConfig : viewConfig}
        disabled={metaInfo?.canEdit ? false : true}
      />
      <Modal
        sx={{ zIndex: 19999 }}
        open={uploading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack height="100vh" justifyContent={'center'} alignItems={'center'}>
          <Stack
            bgcolor="#fff"
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
            p={12}
            borderRadius={8}
          >
            <CircularProgress />
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please hold on, upload in progress...
            </Typography>
          </Stack>
        </Stack>
      </Modal>
    </EditorCnt>
  );
}

export default RichTextEditor;
