/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import actions from 'store/actions';
import BootstrapModelComponent from '../../htmlTags/bootstrapModel';
import ButtonComponent from '../../form/buttons';
import { Btntype } from 'interfaces/htmlTags';
import { ConstantChannelTypeString } from 'utils/globalVariable';

import {
  isMutedChannel,
  muteStreamChannel,
  unmuteStreamChannel,
  hideStreamChannel,
} from 'services/getStreamServices';

import { useArchiveChannel } from 'controller/useArchiveChannel';
import CustomButton from 'components/Button/CustomButton';
import Grid from '@mui/material/Grid';

export const ChannelActionModelRender: FC<any> = ({
  modalToShow = '',
  channel = null,
  handleClose,
}: any) => {
  const dispatch = useDispatch();

  const channelType = channel?.data?.channelType || false;
  const isMute = (channel && isMutedChannel(channel)) || false;
  const isArchived = channel?.data?.isArchived || false;
  const { handleArchiveChannelSubmit } = useArchiveChannel();

  const handleSubmit = async () => {
    if (modalToShow === 'mute') {
      if (isMute) {
        await unmuteStreamChannel(channel);
      } else {
        await muteStreamChannel(channel);
      }
    }

    if (modalToShow === 'hide') {
      await hideStreamChannel(channel);
      dispatch(actions.setIsRefreshing(true));
    }

    if (modalToShow === 'archive') {
      await handleArchiveChannelSubmit(channel);
    }

    handleClose();
  };

  const getChannelType = () => {
    if (channelType === ConstantChannelTypeString.DIRECT_MESSAGE) {
      return 'Direct Message';
    }

    if (channelType === ConstantChannelTypeString.FEED_STREAM) {
      return 'Public Feed';
    }

    return 'Group';
  };

  const getTitle = () => {
    if (modalToShow === 'mute')
      return `${isMute ? 'Unmute' : 'Mute'} ${getChannelType()}`;

    if (modalToShow === 'hide') return `Hide ${getChannelType()}`;

    if (modalToShow === 'archive')
      return `${isArchived ? 'Unarchive' : 'Archive'} ${getChannelType()}`;
  };

  const renderBody = () => {
    if (modalToShow === 'mute') {
      return (
        <>
          <p>
            Are you sure you want to {isMute ? 'unmute' : 'mute'} this{' '}
            {getChannelType()}?
          </p>
          <Grid
            mt={3}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CustomButton
              variant="text"
              onClick={handleClose}
              id="removeModelCloseTestId"
              style={{ marginRight: 10 }}
            >
              Cancel
            </CustomButton>
            <CustomButton variant="contained" onClick={handleSubmit}>
              Yes, {isMute ? 'Unmute' : 'Mute'}
            </CustomButton>
          </Grid>
        </>
      );
    }
    if (modalToShow === 'hide') {
      return (
        <>
          <p>Are you sure you want to hide this {getChannelType()}?</p>
          <Grid
            mt={3}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CustomButton
              variant="text"
              onClick={handleClose}
              id="removeModelCloseTestId"
              style={{ marginRight: 10 }}
            >
              Cancel
            </CustomButton>
            <CustomButton variant="contained" onClick={handleSubmit}>
              Yes, Hide
            </CustomButton>
          </Grid>
        </>
      );
    }

    if (modalToShow === 'archive') {
      return (
        <>
          <p>Are you sure to {isArchived && 'un'}archive this chat?</p>
          <Grid
            mt={3}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <CustomButton
              variant="text"
              onClick={handleClose}
              id="removeModelCloseTestId"
              sx={{ mr: '10px' }}
            >
              Cancel
            </CustomButton>
            <CustomButton variant="contained" onClick={handleSubmit}>
              Yes, {isArchived ? 'Unarchive' : 'Archive'}
            </CustomButton>
          </Grid>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <BootstrapModelComponent
        className="common-modal add-edit-floor-modal"
        isShow={!!modalToShow}
        handleClose={handleClose}
        animation={false}
        arialabelledby="contained-modal-title-vcenter"
        centered={true}
        headerData={getTitle()}
        bodyData={
          <form className="add-edit-department-form">{renderBody()}</form>
        }
        showCloseBtn={false}
      />
    </>
  );
};
