import { useMutation, useQuery } from 'react-query';
import CorrectiveScheduledTasks from 'pages/Widgets/TemplateLibrary/ScheduledTasks/CorrectiveActions';
import { getTasksApi } from 'api/tasksApi';
import { useEffect, useMemo, useState } from 'react';
import { useTaskUtils } from 'utils/CustomHooks/useTaskUtils';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

function CorrectiveActions(props) {
  const { filters } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({});
  const checkList = useSelector(selectors.getChecklistCompleteDataOnly);
  const serviceList = useSelector(selectors.getServiceAdmin);

  const { getCategoryDetails, getChecklistDetails } = useTaskUtils();

  const getTasksQuery = useMutation({
    mutationKey: 'getTasks',
    mutationFn: getTasksApi,
  });

  const dataTransformation = (data) => {
    return data?.map((item) => {
      let serviceCategory: any = null;
      let template: any = null;

      if (item?.ServiceId) {
        serviceCategory = getCategoryDetails(item);
      }

      if (item?.ChecklistId) {
        template = getChecklistDetails(item);
      }

      return {
        ...item,
        ...(serviceCategory && { ServiceType: serviceCategory }),
        ...(template && {
          Checklist: template,
        }),
      };
    });
  };

  const getData = async () => {
    setLoading(true);
    const requestPayload = {
      hideCompleted: false,
      limit: 1000000,
      offset: 0,
      view: 'list',
      viewType: 'current',
      advanceFilters: {
        condition: 'AND',
        filters: [
          ...(filters?.fromDate && filters?.toDate
            ? [
                {
                  comparator: 'is',
                  conditional: 'and',
                  filterName: 'dueDate',
                  value: [filters?.fromDate, filters?.toDate],
                },
              ]
            : []),
          ...(filters?.locations?.length > 0
            ? [
                {
                  comparator: 'is',
                  conditional: 'and',
                  filterName: 'locations',
                  value: filters.locations,
                },
              ]
            : []),
          ...(filters?.schedules?.length > 0
            ? [
                {
                  comparator: 'is',
                  conditional: 'and',
                  filterName: 'schedules',
                  value: filters.schedules,
                },
              ]
            : []),
          {
            comparator: 'is',
            conditional: 'and',
            filterName: 'taskTypes',
            value: ['recurring'],
          },
          {
            comparator: 'is',
            conditional: 'and',
            filterName: 'taskTypes',
            value: ['corrective'],
          },
        ],
      },
    };

    getTasksQuery.mutate(requestPayload, {
      onSuccess: (data) => {
        setData({
          table: data?.data?.rows || [],
          summary: {
            total: data?.data?.count,
          },
        });

        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
      },
    });
  };

  const transformedData = useMemo(() => {
    const tableList = cloneDeep(data?.table || []);
    const transformed = dataTransformation(tableList);

    return {
      ...data,
      table: transformed,
    };
  }, [serviceList, checkList, data]);

  useEffect(() => {
    if (
      (filters?.fromDate && filters?.toDate) ||
      (!filters?.fromDate && !filters?.toDate)
    ) {
      getData();
    }
  }, [filters]);

  return (
    <CorrectiveScheduledTasks
      title="Corrective Actions"
      data={transformedData}
      loading={loading}
    />
  );
}

export default CorrectiveActions;
