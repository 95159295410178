// React
import { useContext } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import CustomToolTip from 'components/Tooltip';

// Icons
import PlayIconRounded from 'components/Icons/playIconRounded';
import GearIcon from 'components/Icons/gearIcon';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import { MoreVertOutlined } from '@mui/icons-material';

// Styled
import {
  Wrapper,
  SettingsIconWrapper,
  StyledButton,
  StartButton,
} from './styled';

// Utils
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import xeniaApi from 'api/index';

// Context
import { DashboardContext } from 'pages/TemplateDashboard/context';

// Constants
import { DropdownOptionType, dropdownOptions } from './constants';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';

const RightNav = () => {
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashboardContext);
  const { navigateWithWorkspaceUrl, workspaceId } = useWorkspaceHook();
  const template = dashboardContext?.template;
  const templateId = template?.id;

  const handleEditTemplate = () => {
    navigateWithWorkspaceUrl(`/checklist/${templateId}`, '', {
      state: { redirectTo: `/templates/${templateId}` },
    });
  };

  const verifyIfPublished = (fn: any) => {
    if (template?.isPublished) {
      fn();
    } else {
      showStartConfirmation();
    }
  };

  const handleClickSettings = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TEMPLATE_SETTINGS,
        open: true,
        data: {
          template: dashboardContext?.template,
          updateTemplateCb: (data) => {
            dashboardContext?.setTemplate(data);
            dispatch(actions.setChecklistItem(data, -1));
          },
        },
      }),
    );
  };

  const handleAttachTemplateToTask = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          taskData: {
            checklistId: templateId,
          },
        },
      }),
    );
  };

  const showStartConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Publish Template',
          description:
            'In order to Start or Assign/Schedule this template you must Publish it.',
          confirmationText: 'Go to Builder',
          onConfirmCallback: () => {
            navigateWithWorkspaceUrl(`/checklist/${templateId}`);
          },
        },
      }),
    );
  };

  const handleClickStartButton = () => {
    navigateWithWorkspaceUrl(
      `/in-progress-checklist/${templateId}?prevUrl=${`/templates/${templateId}?tab=1`}`,
    );
  };

  const handleArchive = () => {
    dispatch(actions.archiveChecklistApiCall(template?.id));
    navigateWithWorkspaceUrl('/checklist');
  };

  const handleDuplicate = (templateId: string, saveToLibrary = false) => {
    dispatch(actions.duplicateChecklistApiCall(templateId, saveToLibrary));
  };

  const handleOpenConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.CONFIRMATION,
        open: true,
        data: {
          title: 'Delete Template',
          description: 'Are you sure you want to delete this template?',
          confirmButtonProps: {
            color: 'error',
          },
          onConfirmCallback: () => handleArchive(),
        },
      }),
    );
  };

  const togglePublishState = async (templateId: string) => {
    await xeniaApi.toggleChecklistPublishState(templateId);
    dashboardContext?.template &&
      dashboardContext?.setTemplate({
        ...dashboardContext?.template,
        isPublished: !dashboardContext.template.isPublished,
      });
    dispatch(
      actions.getChecklistCompleteList({
        detailed: true,
        includeArchived: true,
      }),
    );
  };
  const openShareTemplateDialog = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.TEMPLATE_SHARING,
        data: { checklist: template },
      }),
    );
  };
  const handleOptionSelect = (option: DropdownOptionType) => {
    switch (option.id) {
      case 'start':
        verifyIfPublished(handleClickStartButton);
        break;
      case 'schedule':
        verifyIfPublished(handleAttachTemplateToTask);
        break;
      case 'edit':
        handleEditTemplate();
        break;
      case 'settings':
        handleClickSettings();
        break;
      case 'duplicate':
        handleDuplicate(template?.id as string);
        break;
      case 'shareTemplate':
        openShareTemplateDialog();
        break;
      case 'publish':
        togglePublishState(template?.id as string);
        break;
      case 'unpublish':
        togglePublishState(template?.id as string);
        break;
      case 'delete':
        handleOpenConfirmation();
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      {template?.HotelChecklist?.isEditable && (
        <>
          <CustomToolTip title="Template Settings">
            <SettingsIconWrapper onClick={handleClickSettings}>
              <GearIcon sx={{ fill: 'none', fontSize: 18 }} />
            </SettingsIconWrapper>
          </CustomToolTip>
          <StyledButton
            onClick={handleEditTemplate}
            startIcon={
              <EditIconPencilBottomLine
                sx={{
                  '& path': {
                    stroke: '#212121',
                    strokeOpacity: 1,
                    strokeWidth: 1,
                  },
                }}
              />
            }
            variant="outlined"
          >
            Edit
          </StyledButton>
        </>
      )}
      <StartButton
        onClick={() => verifyIfPublished(handleClickStartButton)}
        startIcon={<PlayIconRounded />}
        variant="contained"
      >
        Start
      </StartButton>
      <ActionDropdown
        options={dropdownOptions(template).filter((o) =>
          template?.isPublished ? o.id !== 'publish' : o.id !== 'unpublish',
        )}
        buttonProps={{ sx: { ml: '-8px' } }}
        handleOptionSelect={handleOptionSelect}
        popperProps={{
          placement: 'bottom-end',
        }}
      >
        <MoreVertOutlined />
      </ActionDropdown>
    </Wrapper>
  );
};

export default RightNav;
