// React
import { useState, useEffect } from 'react';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import AdvancedFilterBuilder from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/AdvancedFilterBuilder';

// Icons
import FiltersIcon from 'components/Icons/filtersIcon';
import CancelIcon from '@mui/icons-material/Cancel';

// Hooks
import useResponsive from 'utils/CustomHooks/useResponsive';

interface FiltersDropdownPropTypes {
  selectedFilters: any;
  onChange: (filters: any) => void;
  hideFilters?: string;
  handleClearFilters?: () => void;
}

const FiltersDropdown = ({
  selectedFilters,
  onChange,
  hideFilters,
  handleClearFilters,
}: FiltersDropdownPropTypes) => {
  const [isActive, setIsActive] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobileDeviceSm } = useResponsive();

  const deleteAllFilters = (e) => {
    e.stopPropagation();
    handleClearFilters?.();
    handleClose();
  };

  const handleCallback = (elem: any) => {
    setIsActive(!!elem);
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  useEffect(() => {
    if (selectedFilters && selectedFilters?.filters?.length) {
      setHasFilters(true);
    } else {
      setHasFilters(false);
    }
  }, [selectedFilters]);

  return (
    <CustomDropdown
      popperProps={{
        style: {
          width: isMobileDeviceSm ? 420 : 623,
        },
        content: (
          <AdvancedFilterBuilder
            hideFilters={hideFilters}
            handleClearFilters={handleClearFilters}
            handleSetFiltersCb={(filters) => {
              onChange({
                condition: filters[0].conditional.toUpperCase(),
                filters,
              });
            }}
            handleClose={handleClose}
            filters={selectedFilters?.filters}
          />
        ),
        placement: 'bottom-start',
        open: Boolean(anchorEl),
        anchorEl,
      }}
      paperProps={{
        sx: {
          padding: '20px 24px',
        },
      }}
      handleCallback={handleCallback}
      onClickawayCallback={() => handleCallback(null)}
      iconButton={false}
      buttonProps={{
        variant: 'outlined',
        startIcon: <FiltersIcon />,
        endIcon: <CancelIcon onClick={deleteAllFilters} />,
        sx: {
          color: 'rgba(97, 97, 97, 1)',
          border: '1px solid rgba(224, 224, 224, 1)',
          height: '40px',
          borderRadius: '8px',
          background: isActive || hasFilters ? 'rgba(104, 104, 254, 0.12)' : '',
          '&:hover': {
            border: '1px solid rgba(224, 224, 224, 1)',
            background: 'rgba(104, 104, 254, 0.12)',
          },
          ...((isActive || hasFilters) && {
            '& .MuiButton-endIcon svg': {
              color: '#EF5350',
            },
            '& svg': {
              color: 'rgba(104, 104, 254, 1)',
            },
          }),
          '& .MuiButton-endIcon': {
            position: 'absolute',
            zIndex: 50,
            right: '-2px',
            top: '-6px',
            color: '#EF5350',
            background: 'white',
            borderRadius: '20px',
            '& svg': {
              fontSize: '16px !important',
            },
            ...(!hasFilters && { display: 'none' }),
          },
        },
      }}
    >
      Filters
    </CustomDropdown>
  );
};

export default FiltersDropdown;
