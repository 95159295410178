import React, { useState, useEffect, useMemo, useCallback } from 'react';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import { Stack, Typography, Button, Badge } from '@mui/material';
import IosSwitch from 'components/IosSwitch';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import { debounce } from 'lodash';
import { TASK_STATUS } from 'utils/globalVariable';
import { useSelector } from 'react-redux';
import useTaskFilters from 'utils/CustomHooks/useTaskFilters/useTaskFilters';
import selectors from 'store/selectors';

type KeyType = 'completed' | 'overdue' | 'missed';
const keys: { key: KeyType; label: string }[] = [
  {
    key: 'completed',
    label: 'Show Completed',
  },
  {
    key: 'overdue',
    label: 'Show Overdue',
  },
  {
    key: 'missed',
    label: 'Show Missed',
  },
];
const defaultToggleState = {
  completed: true,
  overdue: true,
  missed: true,
};

interface VisibilityTogglePropTypes {
  isMainView: boolean;
  forcedAdvanceFilters: any;
  currentTabInTasksView?: any;
  dateFilter?: any;
  formatDateFilter?: any;
}

const VisibilityToggle: React.FC<VisibilityTogglePropTypes> = (props) => {
  const {
    isMainView,
    forcedAdvanceFilters,
    currentTabInTasksView,
    dateFilter,
    formatDateFilter,
  } = props;
  const { handleSetAdvanceFiltersCommon } = useTaskFilters();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [toggleState, setToggleState] = useState(defaultToggleState);

  const allTabsfilterData: any = useSelector(selectors.getTaskListFilters);

  const filterData = useMemo(() => {
    return allTabsfilterData?.tabs?.[currentTabInTasksView]?.filters;
  }, [currentTabInTasksView, allTabsfilterData]);

  const setInitialToggleState = async () => {
    const dbFilters = filterData
      ? filterData
      : { condition: 'AND', filters: [] };
    const newToggleState = { ...defaultToggleState };
    if (dbFilters?.filters) {
      const filters = dbFilters.filters;
      const statusFilter = filters.find(
        (f: any) => f.filterName === 'taskStatuses',
      );
      const overdueFilter = filters.find(
        (f: any) => f.filterName === 'isOverDue',
      );

      if (
        statusFilter?.value &&
        !statusFilter.value.includes(TASK_STATUS.Completed)
      ) {
        newToggleState.completed = false;
      }
      if (
        statusFilter?.value &&
        !statusFilter.value.includes(TASK_STATUS.Missed)
      ) {
        newToggleState.missed = false;
      }
      if (
        overdueFilter &&
        overdueFilter.comparator === 'isNot' &&
        overdueFilter.value
      ) {
        newToggleState.overdue = false;
      }
    }
    setToggleState(newToggleState);
  };

  useEffect(() => {
    setInitialToggleState();
  }, [filterData]);

  const debouncedGetTasks = useCallback(
    debounce((filters) => {
      handleSetAdvanceFiltersCommon(
        filters,
        [...(forcedAdvanceFilters ?? []), formatDateFilter(dateFilter)],
        {
          skipFilterSave: false,
        },
        currentTabInTasksView,
      );
    }, 1000),
    [forcedAdvanceFilters, currentTabInTasksView, dateFilter],
  );

  const handleChangeAdvanceFilters = (toggleState: any) => {
    const dbFilters = filterData
      ? filterData
      : { condition: 'AND', filters: [] };
    const overdueFilter: any[] = [];
    const shouldFilterTaskStatus =
      !toggleState.completed || !toggleState.missed;
    const taskStatusFilter: any[] = shouldFilterTaskStatus
      ? [TASK_STATUS.Open, TASK_STATUS.InProgress, TASK_STATUS.OnHold]
      : [];
    keys.forEach((key) => {
      if (
        key.key === 'completed' &&
        toggleState['completed'] &&
        shouldFilterTaskStatus
      ) {
        taskStatusFilter.push(TASK_STATUS.Completed);
      }
      if (
        key.key === 'missed' &&
        toggleState['missed'] &&
        shouldFilterTaskStatus
      ) {
        taskStatusFilter.push(TASK_STATUS.Missed);
      }
      if (key.key === 'overdue' && !toggleState['overdue']) {
        overdueFilter.push('Yes');
      }
    });

    const newFiltersArray = dbFilters?.filters?.filter(
      (f: any) => !['taskStatuses', 'isOverDue'].includes(f?.filterName) ?? [],
    );

    if (taskStatusFilter.length > 0) {
      newFiltersArray.push({
        filterName: 'taskStatuses',
        comparator: 'is',
        value: taskStatusFilter,
        conditional: 'and',
      });
    }
    if (overdueFilter.length > 0) {
      newFiltersArray.push({
        filterName: 'isOverDue',
        comparator: 'isNot',
        value: overdueFilter[0],
        conditional: 'and',
      });
    }

    const newAdvancedFilters = {
      ...dbFilters,
      filters: newFiltersArray,
    };

    return newAdvancedFilters;
  };

  const handleToggle = (event: any, key: KeyType) => {
    const value = event.target.checked;
    const newToggleState = { ...toggleState, [key]: value };
    setToggleState(newToggleState);
    const advanceFilters = handleChangeAdvanceFilters(newToggleState);
    debouncedGetTasks(advanceFilters);
  };

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isOneKeyDisabled = useMemo(() => {
    return keys.some((key) => toggleState[key.key]);
  }, [toggleState]);

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        placement: 'bottom-start',
        sx: {
          '& .MuiPaper-root': {
            minWidth: '180px',
            boxShadow: '0px 5px 16px 0px rgba(0,0,0,0.22)',
          },
        },
        content: (
          <Stack width="180px" p="16px" gap="16px">
            {keys.map((key) => (
              <Stack
                alignItems={'center'}
                justifyContent={'space-between'}
                key={key.key}
                direction={'row'}
                width="100%"
              >
                <Typography fontWeight={'500'} fontSize={'13px'}>
                  {key.label}
                </Typography>
                <IosSwitch
                  checked={toggleState[key.key]}
                  onChange={(e) => handleToggle(e, key.key)}
                  width={28}
                  height={18}
                  thumbSize={14}
                />
              </Stack>
            ))}
          </Stack>
        ),
      }}
      buttonRenderer={
        <Button
          onClick={handleClickButton}
          sx={{
            color: '#212121',
            fontSize: '13px',
            fontWeight: '400',
            background: anchorEl ? '#EBEAFF' : 'transparent',
            '& .MuiButton-startIcon': {
              marginRight: '3px',
            },
          }}
          startIcon={
            <Stack position={'relative'}>
              <VisibilityOffOutlined
                sx={{ fontSize: 18, '& path': { color: 'rgba(33,33,33,0.7)' } }}
              />
              {!isOneKeyDisabled && (
                <Stack zIndex={1} position={'absolute'} right={3} top={5}>
                  <Badge
                    sx={{
                      '& .MuiBadge-badge': {
                        transform: 'scale(0.8) translate(50%,-50%)',
                      },
                    }}
                    variant="dot"
                    color="primary"
                  />
                </Stack>
              )}
            </Stack>
          }
        >
          Show/Hide
        </Button>
      }
    />
  );
};

export default VisibilityToggle;
