// Custom components
import CustomButton from 'components/Button/CustomButton';

// Styled components
import { Container, IconWrapper, BoldText, NormalText } from './styled';

// Icons
import AssetsIcon from 'components/Icons/assetsIcon';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const EmptyState = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const handleClick = () => {
    navigateWithWorkspaceUrl('/assets');
  };

  return (
    <Container>
      <IconWrapper>
        <AssetsIcon primary={'rgba(104, 104, 254, 1)'} secondary={'#6868FE'} />
      </IconWrapper>
      <BoldText>No Assets</BoldText>
      <NormalText>
        Create and manage all assets, equipment, inventory and tools in one
        place. Every asset can attach to work orders, operations templates, and
        data reports for ultimate clarity of maintenance health.
      </NormalText>
      <CustomButton
        sx={{ borderRadius: '6px' }}
        onClick={handleClick}
        variant="contained"
      >
        Go to Assets
      </CustomButton>
    </Container>
  );
};

export default EmptyState;
