import { createSvgIcon } from '@mui/material';

const CostIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99992 0.833344C4.93731 0.833344 0.833252 4.9374 0.833252 10C0.833252 15.0626 4.93731 19.1667 9.99992 19.1667C15.0625 19.1667 19.1666 15.0626 19.1666 10C19.1666 4.9374 15.0625 0.833344 9.99992 0.833344ZM10.8333 4.58334C10.8333 4.12311 10.4602 3.75001 9.99992 3.75001C9.53968 3.75001 9.16658 4.12311 9.16658 4.58334V5.00001C7.55575 5.00001 6.24992 6.30585 6.24992 7.91668C6.24992 9.52751 7.55575 10.8333 9.16658 10.8333H10.8333C11.5236 10.8333 12.0833 11.393 12.0833 12.0833C12.0833 12.7737 11.5236 13.3333 10.8333 13.3333H9.0277C8.41405 13.3333 7.91658 12.8359 7.91658 12.2222C7.91658 11.762 7.54349 11.3889 7.08325 11.3889C6.62301 11.3889 6.24992 11.762 6.24992 12.2222C6.24992 13.7564 7.49357 15 9.0277 15H9.16658V15.4167C9.16658 15.8769 9.53968 16.25 9.99992 16.25C10.4602 16.25 10.8333 15.8769 10.8333 15.4167V15C12.4441 15 13.7499 13.6942 13.7499 12.0833C13.7499 10.4725 12.4441 9.16668 10.8333 9.16668H9.16658C8.47623 9.16668 7.91658 8.60703 7.91658 7.91668C7.91658 7.22632 8.47623 6.66668 9.16658 6.66668H10.9721C11.5858 6.66668 12.0833 7.16414 12.0833 7.77779C12.0833 8.23802 12.4563 8.61112 12.9166 8.61112C13.3768 8.61112 13.7499 8.23802 13.7499 7.77779C13.7499 6.24366 12.5063 5.00001 10.9721 5.00001H10.8333V4.58334Z"
        fill="#FFA726"
      />
    </>,
    'CostIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default CostIcon;
