import {
  EmptyStateCnt,
  EmptyStateText,
} from './taskListWidgetEmptyState.style';
import GridIcon from 'components/Icons/gridIcon';
import React from 'react';
import NoRecordsFound from 'components/Icons/noRecordFoundIcon';

const TaskListWidgetEmptyState = (props) => {
  return (
    <EmptyStateCnt>
      <NoRecordsFound style={{ width: 200, height: 150 }} />

      <EmptyStateText>No record available</EmptyStateText>
    </EmptyStateCnt>
  );
};

export default TaskListWidgetEmptyState;
