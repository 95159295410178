import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const DownloadButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isdisabled',
})(({ isdisabled }) => ({
  fontWeight: 500,
  '& .download-icon': {
    fontSize: 18,
    '& path': {
      fill: isdisabled ? 'rgba(0,0,0,0.26)' : 'rgba(104, 104, 254, 1)',
      strokeOpacity: 1,
      fillOpacity: 1,
    },
  },
}));
