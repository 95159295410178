import { ReactProps } from 'interfaces/Common';
import React, { FC } from 'react';
import { ButtonProps, Btntype } from 'interfaces/htmlTags';
const ButtonComponent: FC<ButtonProps & ReactProps> = ({
  type = Btntype.BUTTON,
  className,
  onClick,
  disabled = false,
  datatestid,
  id = '',
  style = {},
  datatoggle,
  datatarget,
  datadismiss,
  children,
}: ButtonProps & ReactProps) => {
  /*******************
    @purpose : Render Common Button Component
    @Author : INIC
    ******************/

  return (
    <button
      type={type}
      className={className}
      disabled={disabled}
      id={id}
      style={style}
      onClick={onClick ? onClick : undefined}
      data-testid={datatestid ? datatestid : null}
      data-toggle={datatoggle ? datatoggle : null}
      data-target={datatarget ? datatarget : null}
      data-dismiss={datadismiss ? datadismiss : null}
    >
      {children}
    </button>
  );
};

export default ButtonComponent;
