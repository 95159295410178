import styled from '@mui/system/styled';

export const Container = styled('div')({
  padding: '0 24px',
});

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 120px)',
  marginTop: 30,
});

export const LoadingContainer = styled('div')(({ theme }) => ({
  padding: '24px',
  height: 'calc(100vh - 220px)',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 50px)',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
