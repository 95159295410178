/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react';

import { AddMemberRenderProps } from 'models/index.m';
import ButtonComponent from 'components/common/form/buttons';
import { Btntype } from 'interfaces/htmlTags';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

export const AddMemberRender: FC<AddMemberRenderProps> = ({
  messageHeaderState,
  setMessageHeaderState,
}: AddMemberRenderProps) => {
  const dispatch = useDispatch();
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);

  useEffect(() => {
    dispatch(actions.setIsRefreshing(true));
  }, [setMessageHeaderState]);

  return (
    <>
      <div className="actionbox">
        <ButtonComponent
          type={Btntype.BUTTON}
          className="btn btn-primary-link p-0"
          onClick={() => {
            setMessageHeaderState({
              ...messageHeaderState,
              showGroupDetailModel: true,
            });
          }}
          style={{ textTransform: 'unset', color: '#4E48FA' }}
          disabled={activeStreamChannel.data.isArchived}
        >
          Group Detail
        </ButtonComponent>
      </div>
    </>
  );
};
