import { useContext, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SearchInput from 'components/Form/TextField/SearchField.cmp';
import ListGridToggle from 'components/ListGridToggle';
import AddTemplateFolderDropdown from 'pages/Templates/MyTemplates/Header/addTemplateFolderDropdown/addTemplateFolderDropdown';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import { HeaderCnt } from 'pages/Templates/MyTemplates/Header/style';
import { getItem, setItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import selectors from 'store/selectors';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';

function MyTemplatesHeader() {
  const { search } = useLocation();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const checklistPermission = hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST);
  const dispatch = useDispatch();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const myTemplatesData = useSelector(selectors.getChecklistListDataOnly);
  const { lastFolderId } = useCurrentFolderId();
  const { state = {}, isArchive, updateState } = useContext(MyTemplatesContext);
  const { searchTerm, isLoading } = state;
  const params = useParams();
  const selectedView = state?.selectedView;

  const updateToggleHandler = (selectedView) => {
    setItem(LOCALSTORAGE_KEYS.TEMPLATES_SELECTED_VIEW, selectedView);
    updateState({ ...state, selectedView });
    navigateWithWorkspaceUrl(
      params?.['*']
        ? `/checklist/folder/${
            params?.['*'] + '/' + lastFolderId
          }?view=${selectedView}`
        : `/checklist?view=${selectedView}`,
    );
  };
  const handleChangeSearch = (e) => {
    const searchTerm = e.target.value;
    updateState({ searchTerm });
  };

  useEffect(() => {
    const view = searchParams.get('view') ?? '';
    if (['list', 'grid'].includes(view)) {
      updateToggleHandler(view);
    }
    const selected = getItem(LOCALSTORAGE_KEYS.TEMPLATES_SELECTED_VIEW);
    if (selected) {
      updateState({ ...state, selectedView: selected });
    }
  }, [searchParams]);

  const handleOpenSearch = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.SEARCH_TEMPLATES,
      }),
    );
  };
  const showSearchAndToggle =
    !isLoading?.isLoading &&
    (state?.data?.length > 0 || myTemplatesData?.length > 0);

  return (
    <HeaderCnt>
      <div className={'lefCnt'}>
        {showSearchAndToggle && (
          <SearchInput
            onClick={handleOpenSearch}
            readonly={true}
            fieldProps={{
              value: searchTerm,
              onChange: (e) => handleChangeSearch(e),
            }}
            sx={{
              maxWidth: { sm: 'calc(100vw - 140px)', md: 250 },
              width: { sm: '100%', md: 350 },
            }}
          />
        )}
        {/*<SortByDropdown />*/}
      </div>
      <div className={'rightCnt'}>
        {showSearchAndToggle && (
          <ListGridToggle
            selectedView={selectedView}
            setSelectedView={updateToggleHandler}
          />
        )}
        {!isArchive && checklistPermission && <AddTemplateFolderDropdown />}
      </div>
    </HeaderCnt>
  );
}

export default MyTemplatesHeader;
