import TeamsEmptyState from 'pages/teams/emptyState/emptyState.view';
import { useEffect, useState } from 'react';
import CreateTeamDrawer from 'pages/teams/createTeamDrawer/createTeam';
import TeamsViewHeader from 'pages/teams/teamsList/TeamsViewHeader/teamsViewHeader.view';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import TeamsGridView from 'pages/teams/teamsList/teamsGrid.view';
import selectors from 'store/selectors';
import { Box, Stack } from '@mui/material';
import { ViewType } from 'components/ListGridToggle/ListGridToggle';
import TeamsTable from 'components/DataTables/Teams';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';
import { editTeam } from 'store/actions/teamActions';
import xeniaApi from 'api/index';

function TeamsDashboard(props) {
  const [open, setOpen] = useState(false);
  const [selectedEditTeam, setSelectedEditTeam] = useState(null);
  const [selectedView, setSelectedView] = useState<ViewType>('grid');
  const [initialLoad, setInitialLoad] = useState(true);
  const teams = useSelector(selectors.getTeams);

  const [filteredTeams, setFilteredTeams] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredTeams(teams);
    if (initialLoad && teams?.length > 0) {
      setInitialLoad(false);
    }
  }, [teams]);

  const toggleDrawer = (callback) => {
    setOpen(!open);
    callback && callback?.();
    setSelectedEditTeam(null);
  };
  const handleEditTeam = (data) => {
    setSelectedEditTeam(data);
    setOpen(true);
  };

  const handleUpdateCall = async (postData) => {
    const newTeamObj = await xeniaApi.editTeam(postData);
    dispatch(editTeam(newTeamObj.data, {}));
  };

  const onSearch = (searched) => {
    const filtered = teams?.filter((team) =>
      team?.name?.toLowerCase()?.includes?.(searched?.toLowerCase()),
    );
    setFilteredTeams(filtered);
  };

  return (
    <Stack>
      <TeamsViewHeader
        toggleDrawer={toggleDrawer}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        onSearch={onSearch}
      />
      <CreateTeamDrawer
        heading="Add Team"
        open={open}
        onClose={toggleDrawer}
        selectedEditTeam={selectedEditTeam}
      />

      {isEmpty(teams) && <TeamsEmptyState toggleDrawer={toggleDrawer} />}
      {!isEmpty(teams) && selectedView === 'list' && (
        <Box>
          <TeamsTable
            height="calc(100vh - 200px)"
            rowData={filteredTeams}
            teamActionDropdownProps={{ handleEditTeam, handleUpdateCall }}
          />
        </Box>
      )}

      {!isEmpty(teams) &&
        selectedView === 'grid' &&
        (!initialLoad && filteredTeams?.length === 0 ? (
          <Stack justifyContent="center" alignItems="center" textAlign="center">
            <Box style={{ width: 310, marginTop: 150 }}>
              <NoRecordsFound />
            </Box>
          </Stack>
        ) : (
          <TeamsGridView
            data={filteredTeams}
            toggleDrawer={toggleDrawer}
            taskActionDropdownProps={{ handleEditTeam, handleUpdateCall }}
          />
        ))}
    </Stack>
  );
}

export default TeamsDashboard;
