import LocationNameInput from 'pages/Assets/AssetDetail/AssetDetailTab/assetName.cmp';
import LocationDescriptionInput from 'pages/Assets/AssetDetail/AssetDetailTab/assetDescription.cmp';
import CustomButton from 'components/Button/CustomButton';
import EditIcon from 'components/Icons/editIcon';
import {
  ActionBtnCnt,
  AssetDetailTabCnt,
} from 'pages/Assets/AssetDetail/AssetDetailTab/assetDetailTab.style';
import { useEffect, useReducer, useState } from 'react';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useAssetDetail from 'pages/Assets/AssetDetail/useAssetDetail';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { useSelector } from 'react-redux';
import { getAssetsState } from 'store/selectors/assets';
import AddSubAsset from 'components/AllDialogs/assets/createAsset/AddSubAsset/addSubAsset';
import useUpdateAsset from 'utils/CustomHooks/useUpdateAsset';
import CreateEditAssetForm from 'pages/Assets/AssetForm/AssetForm';
import AssetFormDetails from 'pages/Assets/AssetDetail/AssetDetailTab/assetDetails';
import UpcomingServiceBanner from './upcomingServiceBanner.cmp';
import useResponsive from 'utils/CustomHooks/useResponsive';

function AssetDetailTab() {
  const { isMobileDeviceSm } = useResponsive();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [editable, setEditable] = useState(false);
  const [assetNameError, setAssetNameError] = useState('');
  const assetsList = useSelector(getAssetsState);
  const { selectedAsset } = useAssetDetail();
  const { updateAsset, addSubAsset } = useUpdateAsset();
  const [assetState, updateAssetState] = useReducer((prevState, value) => {
    return { ...prevState, ...value };
  }, {});
  //Setting selected asset in state for editing
  useEffect(() => {
    updateAssetState(selectedAsset);
  }, [selectedAsset]);
  const isAssetNameDuplicate = () => {
    const parentAsset = assetsList.find((l) => l.id == selectedAsset?.ParentId);
    if (selectedAsset?.ParentId) {
      return parentAsset?.SubAssets?.some(
        (sl) =>
          sl.id !== assetState.id &&
          sl.name?.trim()?.toLowerCase() ===
            assetState?.name?.trim()?.toLowerCase(),
      );
    } else {
      return assetsList?.some(
        (sl) =>
          sl.id !== assetState.id &&
          sl.name?.trim()?.toLowerCase() ===
            assetState?.name?.trim()?.toLowerCase(),
      );
    }
  };

  const showEditView = () => {
    setEditable(true);
  };
  const handleUpdateAsset = (data = null) => {
    const postObj = data || assetState;
    if (!assetState.name) {
      setAssetNameError(VALIDATION_ERROR_MESSAGES.ASSET_NAME_REQUIRED_NAME);
      return;
    }
    updateAsset(postObj).then((res) => {
      setEditable(false);
    });
  };
  const handleAddSubAsset = (value) => {
    const { name, description } = value;
    const postObj = { name, description, ParentId: selectedAsset.id };
    addSubAsset(postObj);
  };
  const navigateToSubAsset = (id) => {
    navigateWithWorkspaceUrl(`/assets/${selectedAsset?.id}/sa/${id}`);
  };
  const isSubAsset = Boolean(selectedAsset.ParentId);
  return (
    <AssetDetailTabCnt>
      {assetState?.NextService?.date && (
        <UpcomingServiceBanner nextService={assetState?.NextService} />
      )}

      <div className={'assetDetailTabInnerCnt'}>
        {!editable && (
          <>
            <CustomButton
              variant={'outlined'}
              className={'editButton'}
              onClick={showEditView}
              startIcon={<EditIcon className="editIcon" />}
              style={{
                top: assetState?.NextService?.date
                  ? isMobileDeviceSm
                    ? 80
                    : 60
                  : 0,
              }}
            >
              Edit
            </CustomButton>
            <AssetFormDetails
              asset={assetState}
              onEdit={showEditView}
              updateAssetState={updateAssetState}
            />
          </>
        )}
        {editable && (
          <CreateEditAssetForm
            asset={assetState}
            updateAsset={updateAssetState}
            assetNameError={assetNameError}
            setAssetNameError={setAssetNameError}
          />
        )}

        {editable && (
          <ActionBtnCnt>
            <CustomButton
              variant={'contained'}
              className="saveButton"
              onClick={() => handleUpdateAsset()}
            >
              Save
            </CustomButton>
          </ActionBtnCnt>
        )}
      </div>
      {!isSubAsset && (
        <AddSubAsset
          editAction={handleUpdateAsset}
          subAssets={assetState?.SubAssets}
          parentAsset={assetState}
          addAction={handleAddSubAsset}
          onSubAssetClick={navigateToSubAsset}
        />
      )}
    </AssetDetailTabCnt>
  );
}

export default AssetDetailTab;
