import { AssigneeDropdownCnt } from 'components/AllDialogs/tasks/createTask/createTask.style';
import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import CreateEditTaskAssigneeDropdown from 'components/common/jsxrender/task/CreateEdit/CreateEditAssigneeDropdown';
import { cloneDeep, union } from 'lodash';

const TaskAssigneeDropdown = (props) => {
  const { showAutoTagLocation = true } = props;
  const { updateTask, newTask } = useContext(CreateTaskContext);

  const getNewOverdueWatchers = (assignees) => {
    const OldOverdueWatchers = cloneDeep(
      newTask?.notification?.overdue?.recipients || [],
    );

    const CurrentAssignees = cloneDeep(assignees || []);
    const finaldata = union(OldOverdueWatchers, CurrentAssignees);
    return finaldata || [];
  };

  const handleUpdateAssignee = (assignees) => {
    updateTask({
      assignees,
      isMultiTasks:
        assignees?.length < 2 ? false : newTask?.isMultiTasks || false,
      notification: {
        ...newTask?.notification,
        overdue: {
          recipients: getNewOverdueWatchers(assignees),
        },
      },
    });
  };

  return (
    <AssigneeDropdownCnt>
      <label>Assignee:</label>
      <CreateEditTaskAssigneeDropdown
        handleChangeCallback={handleUpdateAssignee}
        assignees={newTask?.assignees || []}
        task={newTask}
        autoTagLocation={{
          show: false,
          isAutoTagLocation: newTask?.isAutoTagLocation,
        }}
      />
    </AssigneeDropdownCnt>
  );
};

export default TaskAssigneeDropdown;
