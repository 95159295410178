import TemplatesEmpty from 'public/assets/img/templatesEmpty.svg';
import CTypography from 'components/CTypography';
import { EmptyCnt } from 'pages/Templates/MyTemplates/emptyStates/myTemplates/styles';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import { useContext } from 'react';
import { useCurrentFolderId } from 'pages/Templates/hooks/useCurrentFolderId';

function FolderTemplatesEmpty() {
  const dispatch = useDispatch();
  const { state } = useContext(MyTemplatesContext);
  const { lastFolderId } = useCurrentFolderId();
  const handleCreateTemplate = (folderId) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_TEMPLATE_DIALOG,
        data: { folderId: folderId },
      }),
    );
  };
  return (
    <EmptyCnt>
      <div className="content">
        <img alt="templates" src={TemplatesEmpty} />
        <CTypography className="title">No templates</CTypography>
        <CTypography className="subtitle">
          There are no templates in this folder. To add a template, you can
          either choose a folder or directly drag-and-drop templates into this
          folder.
        </CTypography>

        <CustomButton
          variant={'contained'}
          className={'addTemplateButton'}
          onClick={() => handleCreateTemplate(lastFolderId)}
        >
          Create new template
        </CustomButton>
      </div>
    </EmptyCnt>
  );
}

export default FolderTemplatesEmpty;
