// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getDialogState } from 'store/selectors/dialogSelectors';
import CreateLocationDialog from 'components/AllDialogs/locations/createLocation';
import SubLocationEditDialog from 'components/AllDialogs/locations/subLocation';
import CostDialog from './cost';
import QRCodeDialog from './qrcode';

// Custom components
import ConfirmationDialog from 'components/ConfirmationDialog';
import InviteUsersModal from 'pages/user-management/inviteUsersModal';
import TemplateLibraryModal from 'components/TemplateLibrary';
import WorkspaceFeatureRequestModal from 'components/WorkspaceFeatureRequestModal';
import CreateTask from 'components/AllDialogs/tasks/createTask';
import DownloadMobileAppModal from 'components/DownloadMobileAppModal';
import Counter from 'components/Billing/Counter';
import TaskDetailDialog from 'components/TaskDetailDialog';
import AttachChecklistModal from 'components/AttachChecklistModal';
import ChecklistCreateOptionsModal from 'components/ChecklistCreateOptionsModal';
import PaymentSuccess from 'components/AllDialogs/billing/PaymentSuccess';
import PlansModal from 'components/AllDialogs/billing/PlansModal';
import TemplateSettingsModal from 'components/TemplateDashboard/TemplateSettingsModal';
import ChecklistPreviewModal from 'components/common/jsxrender/checklistV2/checklistPreviewModal/ChecklistPreviewModal';
import ScheduleDrawer from 'pages/Schedules/SchedulesDrawer/ScheduleDrawer';
import AutomationDialog from './automation';
import NotificationAutomationDialog from './notificationAutomation';
import SubmissinShareDialog from './submissionShare';
import CustomizeSidebarDialog from 'components/AllDialogs/customizeSidebarDialog/CustomizeSidebarDialog';
import InstructionStepDialog from './instructionStep';
import AddSelectUsersDialog from './AddSelectUsersDialog';

// Utilities
import TemplatePreview from 'components/TemplateLibrary/TemplatePreview';
import DIALOGS from 'utils/dialogIds';
import PlayVideoModal from 'components/PlayVideoModal';
import CalendlyDialog from 'components/Calendly';
import CreateAssetDialog from 'components/AllDialogs/assets/createAsset';
import SubAssetEditDialog from 'components/AllDialogs/assets/subAsset';
import ShowAllTasks from 'components/AllDialogs/tasks/showAllTasks/ShowAllTasks';
import ScheduleWorkOrderAlert from 'components/AllDialogs/tasks/scheduleWorkOrderAlert/scheduleWorkOrderAlert';
import UpSellModal from 'components/UpSellModal';
import DowngradeModal from './billing/DowngradeModal';
import RecurringDialog from './tasks/recurring/recurringDialog';
import InviteUsersDialogV2 from 'components/AllDialogs/inviteUsers';
import CreateRequest from 'components/AllDialogs/requests/createRequest';
import SavedOptions from './savedOptions';
import EditTask from 'components/AllDialogs/tasks/editTask';
import MarkAsCompleteCheck from 'components/AllDialogs/tasks/updateStatus/markAsCompleted/markAsCompleted';
import OperationsTemplateSubmitCheck from 'components/AllDialogs/operationTemplates/submitCheck/submitCheck';
import AddTemplateFolderDialog from 'components/AllDialogs/operationTemplates/createFolder/addTemplateFolderDialog';
import RenameTemplateFolderDialog from 'components/AllDialogs/operationTemplates/renameFolder/renameTemplateFolderDialog';
import TemplateFolderColorDialog from 'components/AllDialogs/operationTemplates/folderColor/templateFolderColorDialog';
import MoveToFolderDialog from 'components/AllDialogs/operationTemplates/moveToFolder/moveToFolderDialog';
import OperationsTemplateStartCheck from 'components/AllDialogs/operationTemplates/startCheck/startCheck';
import ArchieveCheck from 'components/AllDialogs/tasks/archieve/archieve';
import UnarchiveCheck from 'components/AllDialogs/tasks/unarchive/unarchive';
import Assignees from 'components/AllDialogs/assign/assign';
import TemplateSharingDialog from 'components/AllDialogs/templates/templateSharing';
import TemplateCopyDialog from 'components/AllDialogs/templates/templateCopy';
import AddToFolderDialog from 'components/AllDialogs/operationTemplates/addToFolder/addToFolderDialog';
import CreateTemplateDialog from 'components/AllDialogs/operationTemplates/createTemplate/createTemplateDialog';
import FolderArchiveDialog from 'components/AllDialogs/operationTemplates/folderArchiveDialog/folderArchiveDialog';
import CreateDocumentDialog from 'components/AllDialogs/documents/createDocument';
import AddDocumentFolderDialog from 'components/AllDialogs/documents/createFolder';
import RenameDocumentFolderDialog from 'components/AllDialogs/documents/renameFolder';
import DocumentsFolderArchiveDialog from 'components/AllDialogs/documents/folderArchiveDialog/folderArchiveDialog';
import AccessDocumentDialog from 'components/AllDialogs/documents/accessDocument';
import DocumentAddToFolderDialog from 'components/AllDialogs/documents/addToFolder';
import RenameDocumentDialog from 'components/AllDialogs/documents/renameFile';
import DocumentCopyDialog from 'components/AllDialogs/documents/duplicate';
import DocumentFolderColorDialog from 'components/AllDialogs/documents/folderColor';
import DocumentMoveToFolderDialog from 'components/AllDialogs/documents/moveToFolder/moveToFolderDialog';
import FullscreenTemplateInProgress from 'components/AllDialogs//FullscreenTemplateInProgress';
import CustomizeLocationHierarcy from 'components/AllDialogs/customizeLocationHierarcy';
import FileUploadTemplateMobDialog from 'components/AllDialogs/fileUploadTemplateMobDialog';
import CreateWidgetTypeSelectDialog from 'components/AllDialogs/Dashboards/createWidgetTypeSelect/createWidgetTypeSelectDialog';
import CreateWidgetDialog from 'components/AllDialogs/Dashboards/createWidget/createWidgetDialog';
import EditWidgetDialog from 'components/AllDialogs/Dashboards/editWidget/editWidgetDialog';
import AccessDashboardDialog from 'components/AllDialogs/Dashboards/DashboardAccess';
import TemplateSubmissionsDialog from 'components/AllDialogs/templates/templateSubmissions/templateSubmissions';
import TaskTableDialog from 'components/AllDialogs/Dashboards/taskTable';

import AssetServiceDetail from 'components/AssetServiceDetails/AssetServiceDetail';
import AssetServiceMarkComplete from 'components/AllDialogs/assetServicing/assetServiceMarkComplete';
import AssetServiceCompleted from 'components/AllDialogs/assetServicing/assetServiceCompleted';
import CreateEditAssetServiceModal from 'components/AllDialogs/assetServicing/createEditAssetService';
import AttachTemplatesModal from 'components/AllDialogs/taskTemplatesListModal';
import TaskTemplateDetails from 'components/AllDialogs/TaskTemplateDetails';
import TasksTemplateDialog from 'components/AllDialogs/tasksTemplateDialog';
import RequestFormFields from 'components/AllDialogs/requestFormFields';
import CreateEditPinCode from 'components/AllDialogs/createEditPinCode';
import KioskModeConfigurations from 'components/AllDialogs/kioskModeConfigurations';
import CreateProjectDialog from 'components/AllDialogs//createProject';
import FlaggedItemsTableDialog from 'components/AllDialogs/Dashboards/flaggedItemsTable';
import FlaggedItemsTableChartDialog from 'components/AllDialogs/Dashboards/flaggedItemsTable/chartBasedTable';
import TemplatesSearch from 'components/AllDialogs/templates/templatesSearch';
import DocumentsSearch from 'components/AllDialogs/documents/documentsSearch';
import FlagAutomationDialog from 'components/AllDialogs/FlagCagegoryDialog';
import ProjectAccessDialog from 'components/AllDialogs/ProjectAccess';
import CustomRolesDetailForm from 'components/AllDialogs/CustomRoles/detailForm';
import SetEditAssignRoles from 'components/AllDialogs/CustomRoles/setEditAssignRoles';
import DeleteCustomRole from 'components/AllDialogs/CustomRoles/deleteRole';
import DeleteCustomRoleUser from './CustomRoles/removeUser';

function AllDialogs() {
  const dialogState = useSelector(getDialogState);
  const checklistPreviewModalConfig = useSelector(
    selectors.getPreviewChecklistModalConfig,
  );

  return (
    <>
      <InviteUsersModal dialogId="inviteUserDialog" />
      <ConfirmationDialog dialogId="confirmationDialog" />
      <DownloadMobileAppModal dialogId={DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG} />
      <CostDialog dialogId={DIALOGS.TASK_COST} />
      <QRCodeDialog dialogId={DIALOGS.QR_CODE} />
      {dialogState?.[DIALOGS.SAVED_OPTIONS]?.open && (
        <SavedOptions dialogId={DIALOGS.SAVED_OPTIONS} />
      )}
      {dialogState?.[DIALOGS.CREATE_FOLDER_DIALOG]?.open && (
        <AddTemplateFolderDialog />
      )}
      {dialogState?.[DIALOGS.RENAME_FOLDER_DIALOG]?.open && (
        <RenameTemplateFolderDialog />
      )}
      {dialogState?.[DIALOGS.FOLDER_COLOR_DIALOG]?.open && (
        <TemplateFolderColorDialog />
      )}
      {dialogState?.[DIALOGS.MOVE_TO_FOLDER_DIALOG]?.open && (
        <MoveToFolderDialog />
      )}
      {dialogState?.[DIALOGS.ADD_TO_FOLDER_DIALOG]?.open && (
        <AddToFolderDialog />
      )}
      {dialogState?.[DIALOGS.TEMPLATE_SHARING]?.open && (
        <TemplateSharingDialog />
      )}
      {dialogState?.[DIALOGS.PLAY_VIDEO]?.open && <PlayVideoModal />}
      {dialogState?.[DIALOGS.TEMPLATE_COPY]?.open && <TemplateCopyDialog />}
      {dialogState?.[DIALOGS.AUTOMATION]?.open && (
        <AutomationDialog dialogId={DIALOGS.AUTOMATION} />
      )}
      {dialogState?.[DIALOGS.SEARCH_TEMPLATES]?.open && <TemplatesSearch />}
      {dialogState?.[DIALOGS.SEARCH_DOCUMENTS]?.open && <DocumentsSearch />}
      {dialogState?.[DIALOGS.NOTIFICATION_AUTOMATION]?.open && (
        <NotificationAutomationDialog
          dialogId={DIALOGS.NOTIFICATION_AUTOMATION}
        />
      )}
      {dialogState?.[DIALOGS.TEMPLATE_SUBMISSIONS_DIALOG]?.open && (
        <TemplateSubmissionsDialog />
      )}
      {dialogState?.[DIALOGS?.TASK_TABLE_DIALOG]?.open && <TaskTableDialog />}
      {dialogState?.[DIALOGS.UPSELL_MODAL]?.open && <UpSellModal />}
      {dialogState?.[DIALOGS.CREATE_WIDGET_SELECT_TYPE_DIALOG]?.open && (
        <CreateWidgetTypeSelectDialog />
      )}
      {dialogState?.[DIALOGS.CREATE_WIDGET_DIALOG]?.open && (
        <CreateWidgetDialog />
      )}
      {dialogState?.[DIALOGS.EDIT_WIDGET_DIALOG]?.open && <EditWidgetDialog />}
      {dialogState?.[DIALOGS.CONFIRMATION]?.open && (
        <ConfirmationDialog dialogId={DIALOGS.CONFIRMATION} />
      )}
      {dialogState?.[DIALOGS.TEMPLATE_LIBRARY]?.open && (
        <TemplateLibraryModal />
      )}
      {dialogState?.[DIALOGS.FILE_UPLOAD_TEMPLATE_MOB]?.open && (
        <FileUploadTemplateMobDialog />
      )}
      <TemplatePreview />
      <WorkspaceFeatureRequestModal dialogId="workspaceFeatureDialog" />
      <Counter />
      {dialogState?.createEditTask?.open && <CreateTask />}
      {dialogState?.editTask?.open && <EditTask />}
      {dialogState?.[DIALOGS.CREATE_EDIT_REQUEST]?.open && <CreateRequest />}
      {dialogState?.[DIALOGS.CREATE_TEMPLATE_DIALOG]?.open && (
        <CreateTemplateDialog />
      )}

      {dialogState?.recurringTask?.open && <RecurringDialog />}

      <TaskDetailDialog open={dialogState?.taskDetail?.open} />
      {dialogState?.createLocation?.open && <CreateLocationDialog />}
      {dialogState?.editSubLocation?.open && <SubLocationEditDialog />}
      {dialogState?.[DIALOGS.SHOW_MORE_TASKS]?.open && <ShowAllTasks />}
      {dialogState?.[DIALOGS.ARCHIVE_FOLDER_DIALOG]?.open && (
        <FolderArchiveDialog />
      )}
      {dialogState?.[DIALOGS.ATTACH_CHECKLIST]?.open && (
        <AttachChecklistModal />
      )}
      {dialogState?.[DIALOGS.SCHEDULE_WORK_ORDER_ALERT]?.open && (
        <ScheduleWorkOrderAlert />
      )}
      <ChecklistCreateOptionsModal />
      <PaymentSuccess />
      <PlansModal />
      <DowngradeModal />
      <CalendlyDialog />
      {dialogState?.inviteUserDialogV2?.open && (
        <InviteUsersDialogV2 dialogId="inviteUserDialogV2" />
      )}
      {dialogState?.[DIALOGS.TEMPLATE_SETTINGS]?.open && (
        <TemplateSettingsModal />
      )}
      {dialogState?.[DIALOGS.CREATE_ASSET]?.open && <CreateAssetDialog />}
      {dialogState?.editSubAsset?.open && <SubAssetEditDialog />}
      {checklistPreviewModalConfig?.open && <ChecklistPreviewModal />}
      {dialogState?.[DIALOGS.TASK_SCHEDULES_DRAWER]?.open && <ScheduleDrawer />}
      {dialogState?.[DIALOGS.CHECKLIST_SUBMIT_CHECK]?.open && (
        <OperationsTemplateSubmitCheck />
      )}
      {dialogState?.[DIALOGS.MARK_AS_COMPLETE]?.open && <MarkAsCompleteCheck />}
      {dialogState?.[DIALOGS.START_TEMPLATE_CHECK]?.open && (
        <OperationsTemplateStartCheck />
      )}
      {dialogState?.[DIALOGS.ARCHIEVE_CHECK]?.open && <ArchieveCheck />}
      {dialogState?.[DIALOGS.UNARCHIVE_CHECK]?.open && <UnarchiveCheck />}
      {dialogState?.[DIALOGS.ASSIGNEES]?.open && <Assignees />}
      {dialogState?.[DIALOGS.CREATE_DOCUMENT_DIALOG]?.open && (
        <CreateDocumentDialog />
      )}

      {dialogState?.[DIALOGS.CREATE_DOCUMENT_FOLDER_DIALOG]?.open && (
        <AddDocumentFolderDialog />
      )}

      {dialogState?.[DIALOGS.RENAME_DOCUMENT_FOLDER_DIALOG]?.open && (
        <RenameDocumentFolderDialog />
      )}

      {dialogState?.[DIALOGS.ARCHIVE_DOCUMENT_FOLDER_DIALOG]?.open && (
        <DocumentsFolderArchiveDialog />
      )}

      {dialogState?.[DIALOGS.ACCESS_DOCUMENT_DIALOG]?.open && (
        <AccessDocumentDialog />
      )}
      {dialogState?.[DIALOGS.ACCESS_DASHBOARD_DIALOG]?.open && (
        <AccessDashboardDialog />
      )}

      {dialogState?.[DIALOGS.DOCUMENT_ADD_TO_FOLDER_DIALOG]?.open && (
        <DocumentAddToFolderDialog />
      )}

      {dialogState?.[DIALOGS.RENAME_DOCUMENT_DIALOG]?.open && (
        <RenameDocumentDialog />
      )}

      {dialogState?.[DIALOGS.DOCUMENT_COPY]?.open && <DocumentCopyDialog />}

      {dialogState?.[DIALOGS.DOCUMENT_FOLDER_COLOR_DIALOG]?.open && (
        <DocumentFolderColorDialog />
      )}

      {dialogState?.[DIALOGS.DOCUMENT_MOVE_TO_FOLDER_DIALOG]?.open && (
        <DocumentMoveToFolderDialog />
      )}
      {dialogState?.[DIALOGS.SUBMISSION_SHARE_DIALOG]?.open && (
        <SubmissinShareDialog dialogId={DIALOGS.SUBMISSION_SHARE_DIALOG} />
      )}
      {dialogState?.[DIALOGS.CUSTOMIZE_SIDEBAR_DIALOG]?.open && (
        <CustomizeSidebarDialog />
      )}
      {dialogState?.[DIALOGS.INSTRUCTION_STEP]?.open && (
        <InstructionStepDialog dialogId={DIALOGS.INSTRUCTION_STEP} />
      )}
      {dialogState?.[DIALOGS.ADD_SELECT_USERS_DIALOG]?.open && (
        <AddSelectUsersDialog />
      )}
      {dialogState?.[DIALOGS.FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG]?.open && (
        <FullscreenTemplateInProgress />
      )}

      {dialogState?.[DIALOGS.CUSTOMIZE_LOCATION_HIERARCY]?.open && (
        <CustomizeLocationHierarcy />
      )}

      {dialogState?.[DIALOGS.CREATE_EDIT_ASSET_SERVICE]?.open && (
        <CreateEditAssetServiceModal />
      )}

      {dialogState?.[DIALOGS.ASSET_SERVICE_DETAIL]?.open && (
        <AssetServiceDetail />
      )}

      {dialogState?.[DIALOGS.ASSET_SERVICE_MARK_COMPLETE]?.open && (
        <AssetServiceMarkComplete />
      )}

      {dialogState?.[DIALOGS.ASSET_SERVICE_COMPLETED]?.open && (
        <AssetServiceCompleted />
      )}

      {dialogState?.[DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG]?.open && (
        <TasksTemplateDialog />
      )}

      {dialogState?.[DIALOGS.ATTACH_TASK_TEMPLATE_MODAL]?.open && (
        <AttachTemplatesModal />
      )}

      {dialogState?.[DIALOGS.TASK_TEMPLATE_DETAILS]?.open && (
        <TaskTemplateDetails />
      )}

      {dialogState?.[DIALOGS.REQUEST_FORM_FIELDS]?.open && (
        <RequestFormFields />
      )}

      {dialogState?.[DIALOGS.CREATE_EDIT_PIN_CODE]?.open && (
        <CreateEditPinCode />
      )}

      {dialogState?.[DIALOGS.KIOSK_MODE_CONFIGURATIONS]?.open && (
        <KioskModeConfigurations />
      )}

      {dialogState?.[DIALOGS.CREATE_PROJECT]?.open && <CreateProjectDialog />}

      {dialogState?.[DIALOGS.PROJECT_ACCESS_CONTROL]?.open && (
        <ProjectAccessDialog />
      )}

      {dialogState?.[DIALOGS?.FLAGGED_ITEMS_REPORT_DIALOG]?.open && (
        <FlaggedItemsTableDialog />
      )}

      {dialogState?.[DIALOGS?.FLAGGED_ITEMS_REPORT_CHART_BASED_DIALOG]
        ?.open && <FlaggedItemsTableChartDialog />}

      {dialogState?.[DIALOGS?.FLAGGED_CATEGORY_SELECTION_AUTOMATION]?.open && (
        <FlagAutomationDialog
          dialogId={DIALOGS.FLAGGED_CATEGORY_SELECTION_AUTOMATION}
        />
      )}

      {dialogState?.[DIALOGS?.CUSTOM_ROLES_SET_DETAILS]?.open && (
        <CustomRolesDetailForm />
      )}

      {dialogState?.[DIALOGS?.CUSTOM_ROLES_SET_EDIT_ASSIGN_ROLE]?.open && (
        <SetEditAssignRoles />
      )}

      {dialogState?.[DIALOGS?.CUSTOM_ROLES_DELETE_ROLE]?.open && (
        <DeleteCustomRole />
      )}

      {dialogState?.[DIALOGS?.CUSTOM_ROLES_DELETE_USER]?.open && (
        <DeleteCustomRoleUser />
      )}
    </>
  );
}

export default AllDialogs;
