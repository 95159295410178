// React
import { Fragment } from 'react';

// Components
import RequestPendingIcon from 'components/Icons/requestPendingIcon';
import RequestDeclinedIcon from 'components/Icons/requestDeclinedIcon';
import RequestAcceptedIcon from 'components/Icons/requestAcceptedIcon';
import RequestCompleteIcon from 'components/Icons/requestCompleteIcon';
import UserAvatar from 'components/Avatar';
import AvatarPopover from 'components/AvatarPopover';
import { Typography, Stack } from '@mui/material';

// Icons
import GlobeIcon from 'components/Icons/globeIcon';

// Styles
import {
  RequestListItemContainer,
  AnonymousAvatar,
  UserName,
} from 'pages/Requests/RequestListing/requestListing.style';

// Hooks
import useRequests from 'pages/Requests/useRequests';
import useDateTime from 'utils/CustomHooks/useDateTime';

interface RequestListingItemProps {
  request: any;
  selected: boolean;
}
export const requestIcons = {
  Pending: {
    icon: <RequestPendingIcon />,
    color: '#E65100',
    background: '#FFF8E1',
    border: '#FFE082',
  },
  Declined: {
    icon: <RequestDeclinedIcon />,
    color: '#EF5350',
    background: '#FFEBEE',
    border: '#FFCDD2',
  },
  Accepted: {
    icon: <RequestAcceptedIcon />,
    color: '#0288D1',
  },
  Completed: {
    icon: <RequestCompleteIcon />,
    color: '#4CAF50',
  },
};
function RequestListingItem(props: RequestListingItemProps) {
  const { request, selected } = props;
  const { title, createdAt, Creator, status, id, fullName, isPublic } = request;
  const { getUtcToTz } = useDateTime();
  const { navigateToRequest } = useRequests();
  const handleRequestClick = () => {
    navigateToRequest(request.id);
  };
  const formattedDate = getUtcToTz(createdAt, 'MMM DD YYYY');
  return (
    <RequestListItemContainer
      selected={selected}
      onClick={handleRequestClick}
      id={id}
    >
      <Typography className="title" variant="h6">
        {title}
      </Typography>
      <span className="createdDate">Created on: {formattedDate}</span>
      <div className={'bottomInfoCnt'}>
        {Creator && !isPublic && (
          <div className={'userAvatarCnt'}>
            <AvatarPopover
              data={Creator}
              avatarProps={{ width: 24, height: 24 }}
              nameStyle={{
                fontSize: 12,
                marginLeft: 6,
              }}
            />
          </div>
        )}
        {isPublic && (
          <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
            {Creator.fullName ? (
              <Fragment>
                <UserAvatar
                  width={24}
                  height={24}
                  firstName={Creator.fullName}
                  avatarSx={{
                    backgroundColor: 'rgba(96,125,139,1)',
                  }}
                />
                <UserName>{Creator.fullName}</UserName>
              </Fragment>
            ) : (
              <>
                <AnonymousAvatar>
                  <GlobeIcon style={{ color: '#fff', fontSize: '16px' }} />
                </AnonymousAvatar>
                <UserName>Anonymous</UserName>
              </>
            )}
          </Stack>
        )}

        <span className="status">
          <span
            style={{ color: requestIcons?.[status]?.color }}
            className="statusIconCnt"
          >
            {requestIcons?.[status]?.icon}
          </span>{' '}
          {status}
        </span>
      </div>
    </RequestListItemContainer>
  );
}

export default RequestListingItem;
