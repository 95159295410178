import { Box, Stack, Typography, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const Parent = styled('div')({
  height: '100%',
});

export const MainCnt = styled(Box)({
  padding: '12px 12px 12px 16px',
  gap: '12px',
  borderRadius: '8px',
  border: '1px solid #E0E0E0',
  boxShadow: '0px 1px 0px rgba(0,0,0,0.08)',
  cursor: 'pointer',
});

export const MainTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '19.12px',
  letterSpacing: '0.17px',
  color: '#000000',
  marginBottom: 10,
});

export const BreadCrumbMain = styled(Typography)({
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '16.39px',
  letterSpacing: '0.17px',
  color: '#4E48FA',
  marginBottom: 2,
});

export const BreadCrumbSub = styled(Typography)({
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '16.39px',
  letterSpacing: '0.17px',
  color: '#424242',
  marginBottom: 2,
});

export const TagsCnt = styled(Stack)(({ theme }) => ({
  gap: '7px',
  flexDirection: 'row',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ServiceDateCnt = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'overdue',
})(({ _, overdue }) => ({
  padding: '6px 8px 6px 8px',
  borderRadius: '100px',
  gap: '6px',
  border: '1px solid #E0E0E0',
  height: 30,
  width: 'fit-content',
  backgroundColor: overdue ? '#FBECEC' : 'transparent',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ReminderCnt = styled(Box)({
  padding: '6px 8px 6px 8px',
  borderRadius: '100px',
  gap: '6px',
  border: '1px solid #E0E0E0',
  height: 30,
  width: 'fit-content',
  backgroundColor: 'transparent',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const CompletedCnt = styled(Box)({
  padding: '6px 8px 6px 8px',
  borderRadius: '100px',
  gap: '4px',
  border: '1px solid #E0E0E0',
  height: 30,
  width: 'fit-content',
  backgroundColor: 'transparent',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& .completedIcon': {
    height: 16,
    width: 16,
    '& path': {
      color: '#04B86C',
    },
  },

  '& .completedBy': {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16.39px',
    letterSpacing: '0.17px',
    color: '#000000',
  },

  '& .dateTime': {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16.39px',
    letterSpacing: '0.17px',
    color: '#000000',
  },
});

export const ServiceDate = styled(Box)({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16.39px',
  letterSpacing: '0.17px',
  color: '#000000',
  marginTop: 3,
});

export const ReminderInfo = styled(Typography)({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16.39px',
  letterSpacing: '0.17px',
  color: '#000000',
});

export const MarkAsCompleteBtn = styled(CustomButton)({
  borderRadius: 6,
  height: 30,
  backgroundColor: '#4CAF50',

  fontSize: 12,
  fontWeight: 600,
  lineHeight: 22,
});

export const MarkCompleteBtn = styled(CustomButton)({
  backgroundColor: '#4CAF50',
  height: 34,
  width: 'fit-content',
  borderRadius: '6px',
  border: 'unset',
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '22px',
  letterSpacing: '0.4px',

  '&:hover': {
    backgroundColor: '#4CAF50',
  },
});
