import { useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import useCustomizeLocationsHierarcy from 'utils/CustomHooks/useCustomizeLocationsHierarcy';
import { BoldText, GreyText } from './style';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

import FeatureLockIcon from 'components/Icons/featureLockIcon';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const LocationsLevels = () => {
  const { hasFeature, FEATURES } = useCustomPermission();
  const dispatch = useDispatch();
  useCustomizeLocationsHierarcy();

  const customizeHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CUSTOMIZE_LOCATION_HIERARCY,
        data: {},
      }),
    );
  };

  return (
    <VerifyFeatureAccess
      featureId={FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT}
    >
      <Stack
        border={'1px solid #E0E0E0'}
        borderRadius={'8px'}
        padding={'12px 16px'}
        gap={'4px'}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          ...(!hasFeature(FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT) && {
            cursor: 'pointer',
          }),
        }}
      >
        <div>
          <BoldText>Location Hierarchy & Levels</BoldText>
          <GreyText>
            Customize the location levels according to your team requirements.
          </GreyText>
        </div>
        {!hasFeature(FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT) ? (
          <FeatureLockIcon style={{ height: 36, width: 36 }} />
        ) : (
          <CustomButton
            variant={'outlined'}
            color={'primary'}
            onClick={customizeHandler}
            style={{ minWidth: 90 }}
          >
            Customize
          </CustomButton>
        )}
      </Stack>
    </VerifyFeatureAccess>
  );
};

export default LocationsLevels;
