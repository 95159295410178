import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import RolesTable from 'components/DataTables/Roles';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getRoles } from 'store/selectors/roles';
import RolesViewHeader from './header/header';
import RolesEmptyState from './emptyState';
import { IntroBannerCnt, LoaderCnt } from './style';
import { PERMISSIONS, TALK_TO_SALES_URL } from 'utils/constants';
import { getUserProfile } from 'store/selectors/auth';
import { closeDialog, setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import CustomButton from 'components/Button/CustomButton';
import FeatureLockIcon from 'components/Icons/featureLockIcon';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const CustomRolesView = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const [filteredRoles, setFilteredRoles] = useState<any>([]);
  const rolesList = useSelector(getRoles);
  const userProfileData: any = useSelector(getUserProfile);
  const dispatch = useDispatch();
  const { list: roles } = rolesList;

  const { workspaceId } = useWorkspaceHook();
  const { fetchCustomRoles } = useCustomRoles();
  const { FEATURES, hasFeature, hasPermission } = useCustomPermission();

  useEffect(() => {
    fetchCustomRoles({});
  }, []);

  useEffect(() => {
    setFilteredRoles(roles);
  }, [roles]);

  const onSearch = (searched) => {
    const filtered = roles?.filter((role) =>
      role?.title?.toLowerCase()?.includes?.(searched?.toLowerCase()),
    );
    setFilteredRoles(filtered);
  };

  const onRowClick = (id) => {
    navigateWithWorkspaceUrl(`/users-and-teams/roles/${id}`);
  };

  const onEditHandler = () => {
    fetchCustomRoles({ payload: {}, forceLoad: true });
  };

  const PermissionsChangedTemplate = () => {
    return (
      <>
        <Typography fontSize="16px" fontWeight="500">
          Seems like your role / permissions has been updated, Please click on
          reload to apply it accordingly!
        </Typography>
      </>
    );
  };

  const onDeleteHandler = (data) => {
    const usersIds = data?.HotelUsers?.map((item) => item?.UserId);
    const isLoggedInUser = usersIds?.includes(userProfileData?.id);

    if (isLoggedInUser) {
      dispatch(
        setDialog({
          dialogId: DIALOGS.CONFIRMATION,
          open: true,
          data: {
            confirmButtonProps: {
              style: {
                backgroundColor: '#D32F2F',
              },
            },
            hideCrossIcon: true,
            removeClickAwayClose: true,
            hideCancelBtn: true,
            ContentTemplate: PermissionsChangedTemplate,
            confirmationText: 'Reload',
            title: 'Your Role Changed',
            onConfirmCallback: () => {
              window.location.href = `/ws/${workspaceId}/users-and-teams?tab=roles`;
            },
          },
        }),
      );
    } else {
      fetchCustomRoles({ payload: {}, forceLoad: true });
    }
  };

  const getCustomRoles = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.UPSELL_MODAL,
        open: true,
        data: {
          isEscapable: true,
          icon: <FeatureLockIcon />,
          title: 'You’ve discovered a Paid Feature!',
          description: (
            <>
              <b>{FEATURES.CUSTOM_ROLES}</b> isn’t available on your current
              plan. Upgrade Now to access this feature.
            </>
          ),
          primaryButtonText: 'Talk to sales',
          primaryButtonClickCB: () => {
            dispatch(closeDialog(DIALOGS.UPSELL_MODAL));
            dispatch(
              setCalendlyInfo({
                url: TALK_TO_SALES_URL,
                open: true,
              }),
            );
          },
          buttonDisplayDirectionCol: true,
        },
      }),
    );
  };

  return (
    <Box style={{ height: 'calc(100vh - 200px)' }}>
      <RolesViewHeader onSearch={onSearch} />

      {rolesList?.loading && roles?.length === 0 ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : null}

      {!rolesList?.loading && roles?.length === 0 && <RolesEmptyState />}

      {!rolesList?.loading && !hasFeature(FEATURES.CUSTOM_ROLES) && (
        <IntroBannerCnt
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap="10px">
            <UsersAndTeamsIcon
              style={{ fill: 'none', width: 30, height: 30 }}
              sx={{
                '& path': {
                  stroke: '#0277BD',
                },
              }}
            />

            <Typography className="statement">
              <b>Custom Roles</b> let you make the most of Xenia by creating
              roles with custom permissions. This allows you to control what
              each type of user can see and do.
            </Typography>
          </Stack>

          <CustomButton
            variant="contained"
            onClick={getCustomRoles}
            sx={{
              width: '170px',
              marginTop: '10px',
              borderRadius: '8px',
              height: '40px',
            }}
          >
            Get Custom Roles
          </CustomButton>
        </IntroBannerCnt>
      )}

      {!rolesList?.loading && roles?.length > 0 && (
        <RolesTable
          rowData={filteredRoles}
          isSizeColumnFit={true}
          height={'calc(100vh - 200px)'}
          onRowClick={onRowClick}
          rolesActionDropdownProps={{
            onEditHandler,
            onDeleteHandler,
          }}
          columnConfig={{
            actionCol: hasPermission(PERMISSIONS.CAN_MANAGE_ROLES),
          }}
        />
      )}
    </Box>
  );
};

export default CustomRolesView;
