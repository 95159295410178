import { colors } from 'components/theme/constants';
import styled from '@mui/system/styled';

export const CheckListCnt = styled('div')({
  height: '100%',
  '& .templateAttached': {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    '& p': {
      marginLeft: 8,
      fontWeight: 300,
      fontSize: 14,
      color: colors.black,
      opacity: 0.87,
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
        transition: 'ease all 0.2s',
        fontWeight: 700,
      },
    },
  },
});

export const PlannedTaskIdChip = styled('span')({
  border: '1px solid rgba(104,104,254,1)',
  padding: '3px 10px',
  color: 'rgba(104,104,254,1)',
  fontSize: 12,
  borderRadius: 4,
});

export const ScheduleCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .innerCnt': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 8px',
    gap: 6,
    background: '#6868FE',
    borderRadius: 20,
    '& svg': {
      color: '#6868FE',
      height: 16,
      width: 16,
    },
    '& p': {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '18.59px',
      letterSpacing: '0.17px',
      color: colors.white,
    },
  },
});

export const AssigneeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

export const PriorityCellCnt = styled('div')({
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    color: 'rgba(0,0,0, 0.87)',
  },
  '& svg': {
    fontSize: 10,
  },
});

export const StatusCell = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'bg',
})(({ color, bg }) => ({
  color: color,
  background: bg,
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: 12,
  letterSpacing: '0.4px',
  // textAlign: 'center',
  paddingLeft: '16px',
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    // color: 'rgba(0,0,0, 0.87)',
    color: color,
  },
}));
