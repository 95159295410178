import React from 'react';
import { RightCnt, StyledButton } from 'pages/documents/builder/style';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import GlobeIcon from 'components/Icons/globeIcon';
import {
  DashboardCmpDraftHeaderCnt,
  GoBackCnt,
} from 'pages/Dashboards/DashboardCmp/DashboardCmpDraftHeader/dashboardCmpDraftHeader.style';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import CustomButton from 'components/Button/CustomButton';
import { IconButton } from '@mui/material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useQueryClient } from 'react-query';
import { IDashboardItem } from 'pages/Dashboards/interfaces';
import { QUERY_KEYS } from 'api/constants';
import {
  useDashboard,
  useUpdateDashboard,
} from 'pages/Dashboards/hooks/useDashboard';
import { showMessageNotification } from 'utils/globalFunction';
import WithDashboardPermission from 'pages/Dashboards/withDashboardPermission';

function DashboardCmpDraftHeader() {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const queryClient = useQueryClient();
  const dashboards: IDashboardItem | undefined = queryClient.getQueryData([
    QUERY_KEYS.GET_DASHBOARD_BY_ID,
  ]);

  const { dashboardQuery } = useDashboard({
    id: dashboards?.id,
  });

  const { updateDashboardQuery } = useUpdateDashboard();
  const goBackHandler = () => {
    navigateWithWorkspaceUrl('/dashboards?tab=custom-dashboards');
  };
  const handleUpdateTitle = (value) => {
    if (value) {
      const obj = {
        title: value as string,
        id: dashboards?.id as string,
      };
      updateDashboardQuery.mutate(obj);
    }
  };
  const handleInputBlur = (e) => {
    handleUpdateTitle(e.target.value);
  };
  const handlePublish = () => {
    const obj = {
      publish: true as boolean,
      id: dashboards?.id as string,
    };
    updateDashboardQuery.mutate(obj, {
      onSuccess: () => {
        showMessageNotification(
          'Dashboard has been Published Successfully',
          'success',
        );
        dashboardQuery?.refetch();
      },
    });
  };
  return (
    <DashboardCmpDraftHeaderCnt>
      <GoBackCnt>
        <IconButton sx={{ padding: '3px' }} onClick={goBackHandler}>
          <ArrowBackRoundedIcon />
        </IconButton>

        <CustomInput
          className={'dashboardTitleInput'}
          fieldProps={{
            defaultValue: dashboards?.title,
            placeholder: 'Name your Dashboard',
            // onChange: onTitleChange,
            onBlur: handleInputBlur,
          }}
          suppressErrorSpace={true}
          suppressBorder={true}
        />
      </GoBackCnt>

      <RightCnt>
        <WithDashboardPermission>
          <CustomButton
            onClick={handlePublish}
            startIcon={<GlobeIcon />}
            variant="contained"
          >
            Publish
          </CustomButton>
        </WithDashboardPermission>
      </RightCnt>
    </DashboardCmpDraftHeaderCnt>
  );
}

export default DashboardCmpDraftHeader;
