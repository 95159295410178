import { Fab, Stack, Tooltip, Typography } from '@mui/material';
import AddIcon from 'components/Icons/addIcon';
import {
  CreateNewServiceBtn,
  FilterAction,
  FloatingBtn,
  HeaderCnt,
} from './style';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useResponsive from 'utils/CustomHooks/useResponsive';
interface IProps {
  createNewServiceHandler(data?: any): void;
  filterActionUpdater(data: string): void;
  filters?: any;
  serviceMetaData?: any;
}

function ServicesLogHeader(props: IProps) {
  const {
    filterActionUpdater,
    createNewServiceHandler,
    filters,
    serviceMetaData,
  } = props;
  const { isMobileDeviceMd } = useResponsive();

  return (
    <HeaderCnt>
      <Stack gap="5px" flexDirection="row">
        <FilterAction
          onClick={() => {
            filterActionUpdater('all');
          }}
          style={{
            ...(filters?.status === 'all' && {
              backgroundColor: '#6868FE',
              color: 'white',
            }),
          }}
        >
          All
        </FilterAction>
        <FilterAction
          onClick={() => {
            filterActionUpdater('upcoming');
          }}
          style={{
            ...(filters?.status === 'upcoming' && {
              backgroundColor: '#6868FE',
              color: 'white',
            }),
          }}
        >
          Upcoming
        </FilterAction>
        <FilterAction
          onClick={() => {
            filterActionUpdater('overdue');
          }}
          style={{
            ...(filters?.status === 'overdue' && {
              backgroundColor: '#6868FE',
              color: 'white',
            }),
          }}
        >
          Overdue
          {serviceMetaData?.overdue > 0 && (
            <div className="overdueCnt">
              <Typography className="count">
                {serviceMetaData?.overdue}
              </Typography>
            </div>
          )}
        </FilterAction>
        <FilterAction
          onClick={() => {
            filterActionUpdater('completed');
          }}
          style={{
            ...(filters?.status === 'completed' && {
              backgroundColor: '#6868FE',
              color: 'white',
            }),
          }}
        >
          Completed
        </FilterAction>
      </Stack>

      {isMobileDeviceMd ? (
        <FloatingBtn onClick={createNewServiceHandler}>
          <Tooltip title="Add Service" arrow>
            <Fab color="primary" style={{ height: 54, width: 54 }}>
              <AddRoundedIcon />
            </Fab>
          </Tooltip>
        </FloatingBtn>
      ) : (
        <CreateNewServiceBtn
          variant="outlined"
          sx={{ padding: '2px 16px' }}
          onClick={createNewServiceHandler}
          startIcon={<AddIcon />}
        >
          Create Service
        </CreateNewServiceBtn>
      )}
    </HeaderCnt>
  );
}

export default ServicesLogHeader;
