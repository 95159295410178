// Styled
import { Container, ContentContainer, Heading, DetailText } from './styled';

// Icons
import CalendarWithBlobAndPeopleIcon from 'components/Icons/calendarWithBlobAndPeople';
import SchedulesUseCases from 'components/Icons/schedulesUseCases';

const EmptyState = () => {
  return (
    <Container>
      <ContentContainer>
        <Heading>Assign scheduled work to your team</Heading>
        <DetailText>
          Ensure tasks & work orders are completed on time by scheduling and
          assigning them to your team.
        </DetailText>
        <CalendarWithBlobAndPeopleIcon
          style={{ height: '206px', width: '250px' }}
        />
      </ContentContainer>
      <SchedulesUseCases className="useCases" />
    </Container>
  );
};

export default EmptyState;
