// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Components
import NotificationMessage from '../NotificationMessage';
import UserAvatar from 'components/Avatar';

// Styled
import {
  StyledNotificationBox,
  AvatarAndNotificationWrapper,
  UnreadDot,
  AvatarBox,
  TimeText,
} from './NotificationsBox.styled';

// Utils
import { NotificationResponseModel } from 'models/taskModel';
import { streamConstant } from 'utils/globalVariable';
import { showMessageNotification } from 'utils/globalFunction';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useNotification } from 'components/NotificationV2/useNotification';
import useDateTime from 'utils/CustomHooks/useDateTime';

// Icons
import InfoIcon from 'public/assets/img/notifications/info-icon.svg';
import OverDueIcon from 'public/assets/img/notifications/overdue-icon.svg';
import KioskModeIcon from 'components/Icons/kioskModeIcon';
import { Box } from '@mui/material';
import { PERMISSIONS } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

interface PropTypes {
  notification: NotificationResponseModel;
}

const NotificationBox = ({ notification }: PropTypes) => {
  const { getUtcToTz } = useDateTime();
  const { setMarkAllAsRead } = useNotification();
  const { navigateToOtherWorkspace, navigateWithWorkspaceUrl } =
    useWorkspaceHook();

  const { hasPermission } = useCustomPermission();
  const canAccessTasks = hasPermission(PERMISSIONS.CAN_VIEW_TASKS);
  const canAccessChat = hasPermission(PERMISSIONS.CAN_USE_CHAT);
  const canViewRequests = hasPermission(PERMISSIONS.CAN_VIEW_TASK_REQUESTS);
  const canManageWSConfig = hasPermission(
    PERMISSIONS.CAN_MANAGE_WORKSPACE_CONFIG,
  );
  const canManageChecklist = hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST);
  const canManageAssets = hasPermission(PERMISSIONS.CAN_MANAGE_ASSETS);

  const currentUserId: string | number | null = useSelector(
    selectors.getUserId,
  );

  const markNotificationAsRead = async (
    notification: NotificationResponseModel,
  ) => {
    try {
      const postData: any = {
        currentUserId,
        notificationId: notification.id,
      };
      await setMarkAllAsRead(postData);
    } catch (error) {
      // console.log('error', error);
    }
  };

  const getNotificationOnClickHandler = (
    notification: NotificationResponseModel,
  ) => {
    let onClickCB: () => void;
    switch (notification.type) {
      case 1:
      case 3:
        onClickCB = () => {
          markNotificationAsRead(notification);

          if (canAccessTasks) {
            navigateToOtherWorkspace({
              id: notification?.HotelId,
              url: '/tasks/list',
              searchPrm: `?taskId=${notification.TaskId}`,
            });
          }
        };
        break;
      case 2:
      case 4:
        onClickCB = () => {
          if (!notification.TaskId) {
            showMessageNotification(
              'Marked notification as read.',
              streamConstant.SUCCESS,
            );
          } else {
            markNotificationAsRead(notification);
            if (canAccessTasks) {
              navigateToOtherWorkspace({
                id: notification?.HotelId,
                url: '/tasks/list',
                searchPrm: `?taskId=${notification.TaskId}`,
              });
            }
          }
        };
        break;
      case 5:
        onClickCB = () => {
          markNotificationAsRead(notification);
          if (canAccessTasks) {
            navigateToOtherWorkspace({
              id: notification?.HotelId,
              url: '/tasks/list',
              searchPrm: `?taskId=${notification.TaskId}`,
            });
          }
        };
        break;
      case 6: {
        const msgType = notification?.notificationObject?.msgType;
        const taskId = notification?.ChannelId?.split('task_comments_')?.[1];
        onClickCB = () => {
          msgType === 'TaskComment' && taskId
            ? canAccessTasks &&
              navigateToOtherWorkspace({
                id: notification?.HotelId,
                url: '/tasks/list',
                searchPrm: `?taskId=${taskId}&comments=1`,
              })
            : canAccessChat &&
              navigateToOtherWorkspace({
                id: notification?.HotelId,
                url: `/messages/${notification?.notificationObject?.channelID}`,
              });

          markNotificationAsRead(notification);
        };
        break;
      }
      case 7:
        onClickCB = () => {
          if (canViewRequests) {
            navigateToOtherWorkspace({
              id: notification?.HotelId,
              url: `/requests/${notification?.data?.taskRequestId}`,
            });
          }

          markNotificationAsRead(notification);
        };
        break;
      case 8:
        onClickCB = () => {
          if (canManageWSConfig) {
            navigateToOtherWorkspace({
              id: notification?.HotelId,
              url: `/settings/my-workspaces`,
            });
          }

          markNotificationAsRead(notification);
        };
        break;
      case 9:
        onClickCB = () => {
          if (canManageChecklist) {
            navigateToOtherWorkspace({
              id: notification?.HotelId,
              url: `/templates/${notification?.data?.templateId}`,
            });
          }

          markNotificationAsRead(notification);
        };
        break;
      case 10:
        onClickCB = () => {
          markNotificationAsRead(notification);
          if (canManageAssets) {
            navigateToOtherWorkspace({
              id: notification?.HotelId,
              url: `/assets/${notification?.data?.assetId}`,
              searchPrm: `?tab=3&service=${notification?.data?.serviceId}`,
            });
          }
        };
        break;
      case 11:
        onClickCB = () => {
          markNotificationAsRead(notification);
          navigateWithWorkspaceUrl('/settings/my-profile');
        };
        break;
      default:
        return;
    }
    return onClickCB;
  };

  return (
    <StyledNotificationBox
      unread={notification.isRead ? '' : 'true'}
      key={notification.id}
      onClick={getNotificationOnClickHandler(notification)}
    >
      <AvatarAndNotificationWrapper>
        <AvatarBox>
          {!notification.isRead && <UnreadDot />}
          {notification?.type === 6 && (
            <>
              <UserAvatar
                width={32}
                height={32}
                userId={notification.notificationObject?.fromUserId}
                firstName={notification.notificationObject?.userName}
                avatarSx={{
                  fontWeight: 800,
                }}
              />
            </>
          )}

          {notification?.type === 11 && (
            <Box
              style={{
                backgroundColor: '#6868FE',
                borderRadius: 49,
                height: 32,
                width: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <KioskModeIcon
                style={{ height: 22, width: 22, fill: 'none', color: 'white' }}
              />
            </Box>
          )}

          {notification?.type !== 6 && notification?.type !== 11 && (
            <>
              <img
                src={
                  notification?.title.toLowerCase().includes('overdue')
                    ? OverDueIcon
                    : InfoIcon
                }
                width="32"
                height="32"
              />
            </>
          )}
        </AvatarBox>
        <NotificationMessage
          title={notification.title}
          workspaceId={notification.HotelId}
        />
      </AvatarAndNotificationWrapper>

      <TimeText>{getUtcToTz(notification.createdAt, 'LT')}</TimeText>
    </StyledNotificationBox>
  );
};

export default NotificationBox;
