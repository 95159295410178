import { Typography } from '@mui/material';
import { Name } from './renderers/renderers';
import { MainCnt } from './renderers/style';

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};

export const cellRenderer = {
  template: (params) => {
    if (!params?.data) return;
    const { template } = params.data;
    return <Name template={template} />;
  },
  flaggedResponses: (params) => {
    if (!params?.data) return;
    const { flaggedResponses } = params.data;
    return (
      <MainCnt>
        <Typography
          fontWeight="600"
          fontSize="13px"
          lineHeight="17.76px"
          color="#212121"
        >
          {flaggedResponses}
        </Typography>
      </MainCnt>
    );
  },
};

export const valueGetters = {
  template: (params) => {
    const { template } = params.data;
    return template?.name || '-';
  },
  flaggedResponses: (params) => {
    const { flaggedResponses } = params.data;
    return flaggedResponses || '-';
  },
};
export const columns: any = (props) => {
  return [
    {
      field: 'template',
      headerName: 'Template',
      colId: 'template',
      sortable: true,
      cellRenderer: cellRenderer.template,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: 'flaggedResponses',
      headerName: 'Flagged Responses',
      colId: 'flaggedResponses',
      valueGetter: valueGetters.flaggedResponses,
      cellRenderer: cellRenderer.flaggedResponses,
      sortable: true,
      suppressMovable: true,
      suppressMenu: true,
    },
  ];
};
