import styled from '@mui/system/styled';
import { display } from '@mui/system';

export const HeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0 24px 12px 24px',
  position: 'relative',
  '.lefCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
}));

export const ToggleContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
