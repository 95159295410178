import { AttachmentsWithLabel } from 'components/AllDialogs/assets/createAsset/createAsset.style';
import { useMemo } from 'react';
import { PhotosCnt } from './assetDetailTab.style';
import DownloadIcon from 'components/Icons/downloadIcon';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import mime from 'mime';
import { IconButton } from '@mui/material';
import {
  downloadFile,
  getFileName,
  getUrlExtension,
} from 'utils/globalFunction';

const AssetsPhotosPreview = (props) => {
  const { asset } = props;
  const dispatch = useDispatch();

  const isImage = (url) => {
    const splitData = url?.split('/');
    const nameExt = splitData?.[splitData?.length - 1]?.split('.');
    const fileExtension = nameExt?.[1];

    return ['jpeg', 'jpg', 'gif', 'png', 'gif', 'svg'].includes(fileExtension);
  };

  const photos = useMemo(() => {
    return asset?.attachments?.filter((item) => isImage(item));
  }, [asset?.attachments]);

  return (
    <AttachmentsWithLabel>
      <label className="title">Photos</label>
      <PhotosCnt>
        {photos?.length > 0
          ? photos?.map((url, index) => (
              <div
                className="imgCnt"
                key={index}
                onClick={() => {
                  dispatch(
                    actions.setAttachmentsPreviewData({
                      index: 0,
                      attachments: [
                        {
                          url: url,
                          type: mime.getType(url),
                        },
                      ],
                    } as any),
                  );
                }}
              >
                <img className="imgView" src={url} />
                <IconButton
                  className="download"
                  onClick={(event) => {
                    event.stopPropagation();
                    downloadFile(
                      url,
                      `${getFileName(url)}.${getUrlExtension(url)}`,
                    );
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            ))
          : '-'}
      </PhotosCnt>
    </AttachmentsWithLabel>
  );
};
export default AssetsPhotosPreview;
