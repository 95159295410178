import { useMutation } from 'react-query';
import OnTimeVsLateCompletionByLocation from 'pages/Widgets/TemplateLibrary/ScheduledTasks/OnTimeVsLateCompletionByLocation';
import { useEffect, useState } from 'react';
import { getOnTimeVsLateCompletion } from 'api/scheduledReportsApi';

function OnTimeVsLateByLocation(props) {
  const { filters } = props;

  const [data, setData] = useState<any>({});

  const getQuery = useMutation({
    mutationKey: 'getOnTimeVsLateCompletion',
    mutationFn: getOnTimeVsLateCompletion,
  });

  const error = getQuery.error;
  const loading = getQuery.isLoading;

  const getData = async () => {
    const requestPayload = {
      filters: {
        projects: filters.projects,
        schedules: filters.schedules,
        location: filters.locations,
        ...((filters?.fromDate || filters?.toDate) && {
          dateRange: [filters?.fromDate, filters?.toDate],
        }),
      },
    };

    getQuery.mutate(requestPayload, {
      onSuccess: (data) => {
        setData({
          chart: data?.charts || [],
          summary: {
            total: data?.summary?.completedOnTime || 0,
          },
        });
      },
    });
  };

  useEffect(() => {
    if (
      (filters?.fromDate && filters?.toDate) ||
      (!filters?.fromDate && !filters?.toDate)
    ) {
      getData();
    }
  }, [filters]);

  return (
    <OnTimeVsLateCompletionByLocation
      title="On-time vs Late Completion % by Location"
      data={data}
      loading={loading}
      error={error}
      reload={getData}
    />
  );
}

export default OnTimeVsLateByLocation;
