import { Box, styled } from '@mui/material';

export const MainCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0px 25px 15px 25px',
  borderBottom: '1px solid #EEEEEE',
});

export const MoreItemsCnt = styled('div')({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 15px',
});

export const ItemCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  marginBottom: -8,
  paddingBottom: 10,
  cursor: 'pointer',

  '&:hover': {
    '& .label': {
      textDecoration: 'underline',
    },
  },
  '& .label': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#424242',
  },
});

export const ItemContainer = styled(Box)({
  cursor: 'pointer',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .iconAndContent': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },

  '&:hover': {
    '& .label': {
      textDecoration: 'underline',
    },
  },
  '& .label': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#424242',
  },
});

export const ActionsDropdownCnt = styled(Box)({
  padding: '10px 10px 3px 10px',

  '& .rowCnt': {
    alignItems: 'center',
    gap: 10,
    marginBottom: 5,
    cursor: 'pointer',
  },
  '& .label': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
  },

  '& .delete': {
    color: '#D32F2F',
  },

  '& .deleteIcon': {
    fontSize: 18,
    color: '#D32F2F',
  },
});

export const ActionsItemsCnt = styled(Box)({
  padding: '10px 15px 5px 15px',
});
