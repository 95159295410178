import xeniaApi from 'api/index';
class Utils {
  static adminMetadataTypes: {
    name: string;
    description?: string | undefined;
    ChecklistType?: any;
    ChecklistIndustry?: any;
    access?: string[];
    image?: any;
    icon: any;
    attachments?: any[];
    FolderId?: any;
  };
  static userMetadataTypes: {
    name: string;
    icon: any;
    attachments?: any[];
    FolderId?: any;
  };
  static propTypes: {
    open: any;
    handleGoNextMetadata: any;
    isXeniaAdmin: any;
    isEditing: boolean;
    handleCloseModal: any;
    checklist: any;
    isNextLoading: boolean;
    folderId: string;
  };
  adminMetadata;
  userMetaData;
  types;
  industries;
  requiredFields;
  isXeniaAdmin;
  constructor({ types, industries, isXeniaAdmin }) {
    this.adminMetadata = {
      name: '',
      description: '',
      ChecklistCategory: null,
      ChecklistIndustry: null,
      image: null,
      attachments: [],
      FolderId: null,
    };
    this.userMetaData = {
      name: '',
      FolderId: null,
    };
    this.types = types;
    this.industries = industries;
    this.isXeniaAdmin = isXeniaAdmin;
    this.requiredFields = [
      'name',
      'description',
      'ChecklistType',
      'ChecklistIndustry',
    ];
  }

  isNextDisabled(metadata) {
    if (!this.isXeniaAdmin) return !metadata['name'];
    if (
      this.requiredFields.every(function (x) {
        return x in metadata && !!metadata[x];
      })
    ) {
      return false;
    }
    return true;
  }

  #formatChecklistAttachments(attachments) {
    return attachments.map((attachment) => {
      const chunks = attachment.split('.');
      const extension = chunks[chunks.length - 1];
      if (['png', 'jpg', 'jpeg', 'webp'].includes(extension)) {
        return {
          type: 'image',
          url: attachment,
          aws: true,
        };
      }
      return {
        type: 'file',
        url: attachment,
        aws: true,
      };
    });
  }

  getInitialChecklistAccess(checklist) {
    if (!checklist?.ChecklistAccess?.length) return ['all'];

    return checklist.ChecklistAccess.map((access) => access.AccessorId);
  }

  setDefaultChecklistState(checklist) {
    const type = this.types?.find((c) => c.id === checklist.ChecklistTypeId);
    const industry = this.industries?.find(
      (c) => c.id === checklist.ChecklistIndustryId,
    );
    const normalUserData = {
      name: checklist?.name,
      icon: checklist?.icon,
      access: this.getInitialChecklistAccess(checklist),
      attachments: checklist?.attachments
        ? this.#formatChecklistAttachments(checklist.attachments)
        : [],
    };
    if (!this.isXeniaAdmin) return normalUserData;
    return {
      ...normalUserData,
      name: checklist?.name,
      description: checklist?.description ?? '',
      ChecklistType: type ? { value: type?.id, label: type?.name } : null,
      ChecklistIndustry: industry
        ? { value: industry?.id, label: industry?.name }
        : null,
      image: checklist?.image ? { preview: checklist.image, aws: true } : null,
      icon: checklist?.icon,
    };
  }

  async handleUploadToAws(attachments) {
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(attachments);
    return response;
  }

  async handleUploadAttachments(attachments) {
    // upload new attachments to AWS
    const pendingUploads = this.filterAttachmentsForUploadingToAws(attachments);
    const finalAttachments = [...this.filterUploadedAttachments(attachments)];
    if (pendingUploads.length > 0) {
      const uploads = await this.handleUploadToAws(pendingUploads);
      finalAttachments.push(...uploads.filePath);
    }
    return finalAttachments;
  }

  async handleUploadTemplateImage(image) {
    if (!image?.aws) {
      const data = await this.handleUploadToAws([image]);
      return `${data.baseUrl}${data.filePath[0]}`;
    }
    return image.preview;
  }

  async goNext(metadata) {
    const attachments = await this.handleUploadAttachments(
      metadata.attachments,
    );

    const normalUserData = {
      name: metadata.name,
      icon: metadata.icon,
      access: metadata?.access?.filter((item) => item !== 'all'),
      attachments,
      ...(metadata?.FolderId && { FolderId: metadata?.FolderId }),
    };
    if (!this.isXeniaAdmin) return normalUserData;
    return {
      ...normalUserData,
      description: metadata.description,
      ChecklistTypeId: metadata.ChecklistType.value,
      ChecklistIndustryId: metadata.ChecklistIndustry.value,
    };
  }

  handleUploadImage(acceptedFiles, multiple = false) {
    const localFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );

    return multiple ? localFiles : localFiles[0];
  }

  handleDeleteChecklistAttachment(metadata, index) {
    const attachments = [...metadata.attachments];
    return attachments.filter((file, idx) => idx !== index);
  }

  filterAttachmentsForUploadingToAws(attachments) {
    return attachments.filter((file) => !file.aws);
  }

  filterUploadedAttachments(attachments) {
    const filtered = attachments.filter((file) => file.aws);
    return filtered.map((file) => file.url);
  }
}

export { Utils };
