import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import RequestListing from 'pages/Requests/RequestListing/RequestListing';
import { RequestsContext } from 'pages/Requests/context/context';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import RequestsHeader from 'pages/Requests/RequestsHeader/RequestsHeader';
import RequestDetail from 'pages/Requests/RequestDetail/RequestDetail';
import {
  RequestContentCnt,
  RequestsLoaderCnt,
} from 'pages/Requests/requests.style';
import useRequests from 'pages/Requests/useRequests';
import CircularProgress from '@mui/material/CircularProgress';
import xeniaApi from 'api/index';
import { isEmpty } from 'lodash';
import RequestsFeatureEmptyState from 'pages/Requests/RequestsFeatureEmptyState';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import RequestsEmptyState from 'pages/Requests/RequestsEmptyState';
import CTabs from 'components/CTabs';
import PublicRequestConfig from 'components/PublicRequestConfig';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

export interface initialStateType {
  requests: any[];
  selectedRequest: any;
  searchQuery: string;
  loading: boolean;
  collapsed: string[];
}
const initialState: initialStateType = {
  requests: [],
  selectedRequest: null,
  searchQuery: '',
  loading: false,
  collapsed: [],
};

function RequestsTabs() {
  const { isMobileDeviceSm } = useResponsive();
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const [activeTab, setActiveTab] = useState<number>(0);

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_VIEW_TASK_REQUESTS)) {
      navigateWithWorkspaceUrl('/dashboard');
      return;
    }
  }, []);

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const tabNumber = Number(tab);
      if (tabNumber >= 0 && tabNumber <= 1) {
        setActiveTab(tabNumber);
      }
    }
  }, [searchParams]);

  const tabs = useMemo(
    () => [
      {
        label: 'Requests',
        content: () => (activeTab === 0 ? <RequestListingDashboard /> : <></>),
      },
      {
        label: 'Public Work Request Portal',
        content: () => (activeTab === 1 ? <PublicRequestConfig /> : <></>),
      },
    ],
    [activeTab],
  );

  const onTabChangeCb = (activeTab: number) => {
    setActiveTab(activeTab);
    navigate(`${location.pathname}?tab=${activeTab}`);
  };

  if (!hasPermission(PERMISSIONS.CAN_VIEW_TASK_REQUESTS)) {
    return null;
  }

  return globalThis?.platform !== 'mobile' &&
    !isMobileDeviceSm &&
    hasPermission(PERMISSIONS.CAN_CONFIGURE_PUBLIC_TASK_REQUEST) ? (
    <CTabs
      data={tabs}
      onTabChangeCb={onTabChangeCb}
      activeTab={activeTab}
      variant="scrollable"
      rootSx={{ padding: '0px 20px' }}
    />
  ) : (
    <Box style={{ marginTop: 10 }}>
      <RequestListingDashboard />
    </Box>
  );
}

export default RequestsTabs;

function RequestListingDashboard() {
  const [requestsState, updateRequestsState] = useReducer(
    (prevState, value) => {
      return { ...prevState, ...value };
    },
    initialState,
  );

  return (
    <RequestsContext.Provider value={{ requestsState, updateRequestsState }}>
      <RequestListingDashboardWrapper />
    </RequestsContext.Provider>
  );
}
function RequestListingDashboardWrapper() {
  const { requestsState, updateRequestsState } = useContext(RequestsContext);
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const params = useParams();
  const { navigateToRequest } = useRequests();
  const { hasFeature, FEATURES } = useCustomPermission();

  const getRequestsData = async () => {
    if (!hasFeature(FEATURES.CAN_REQUEST_TASK)) return;
    updateRequestsState({ loading: true });
    const response = await xeniaApi.getRequests();
    if (!isEmpty(response)) {
      updateRequestsState({ requests: response });
    }
    if (!params?.['*'] && !isMediumDown) {
      navigateToRequest(response?.[0]?.id); // navigate to request detail page
    }
    updateRequestsState({ loading: false });
  };
  useEffect(() => {
    getRequestsData();
  }, []);
  const { loading, requests } = requestsState;
  return (
    <>
      <RequestsHeader />
      {loading && (
        <RequestsLoaderCnt>
          <CircularProgress />
        </RequestsLoaderCnt>
      )}
      {!params?.['*'] && !loading && requests?.length ? (
        <Routes>
          <Route
            path="/"
            element={
              <RequestContentCnt>
                <RequestListing />
              </RequestContentCnt>
            }
          />
        </Routes>
      ) : null}
      {!loading && requests?.length ? (
        <Routes>
          <Route
            path="/:requestId"
            element={
              <RequestContentCnt>
                <RequestListing />
                <RequestDetail />
              </RequestContentCnt>
            }
          />
        </Routes>
      ) : null}
      {!loading &&
      !requests?.length &&
      !hasFeature(FEATURES.CAN_REQUEST_TASK) ? (
        <RequestsFeatureEmptyState />
      ) : null}
      {!loading &&
      !requests?.length &&
      hasFeature(FEATURES.CAN_REQUEST_TASK) ? (
        <RequestsEmptyState />
      ) : null}
    </>
  );
}
