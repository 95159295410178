import { useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';

// Custom components
import TemplateSubmissionsHeader from 'pages/ReportingV2/Reports/TemplateSubmissions/TemplateSubmissionsHeader';
import TemplateSubmissionStatusCounts from 'pages/Widgets/TemplateSubmissionStatusCounts';
import SubmissionsListSummary from 'pages/Widgets/SubmissionsListing/SubmissionListView/submissionsListSummary';

// Context
import { TemplateSubmissionsContext } from 'pages/ReportingV2/Reports/TemplateSubmissions/context';

// Hooks
import useReports from 'pages/ReportingV2/Reports/useReports';

// Constants
import { REPORTING_CONFIG_CONSTANTS } from 'pages/ReportingV2/Reports/constants';

function TemplateSubmissions() {
  const { defaultProps, state, updateState, clearAllFilters, isFilterApplied } =
    useReports(REPORTING_CONFIG_CONSTANTS.TEMPLATE_SUBMISSIONS_REPORT);

  const [elementsHeight, setElementsHeight] = useState(0);

  const [headerRef, headerBounds] = useMeasure();
  const [statsRef, statsBounds] = useMeasure();

  useEffect(() => {
    const mainHeaderHeight =
      document.getElementsByClassName('main-header')?.[0]?.clientHeight || 0;

    setElementsHeight(
      headerBounds?.height + statsBounds?.height + mainHeaderHeight,
    );
  }, [headerRef, statsRef]);

  return (
    <TemplateSubmissionsContext.Provider
      value={{ state, updateState, clearAllFilters }}
    >
      <div>
        <TemplateSubmissionsHeader headerRef={headerRef} />
        <TemplateSubmissionStatusCounts
          {...defaultProps}
          isFilterApplied={isFilterApplied}
          statsRef={statsRef}
        />

        {state?.isLoading ? null : (
          <div style={{ margin: '0px 30px' }}>
            <SubmissionsListSummary
              key="templateSubmissionsList"
              {...defaultProps}
              otherElementsHeight={elementsHeight}
            />
          </div>
        )}
      </div>
    </TemplateSubmissionsContext.Provider>
  );
}

export default TemplateSubmissions;
