import { TimePickerDropdown } from 'components/Dropdown/TimePicker/timePicker.cmp';
import React from 'react';
import { WorkingHoursCnt } from 'pages/organization-management/WorkingHours/workingHours.style';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomToolTip from 'components/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledSwitch } from '../../../styles/commonStyles/switch';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useDispatch } from 'react-redux';
import { updateWorkspace } from 'store/actions/workspaceActions';
import { hoursArr } from 'components/Dropdown/Task/DateRangePicker/constants';
import { createShift, deleteShift, updateShift } from 'api/workspaceApi';
import { Alert } from '@mui/material';
const workingHoursText = `Set Your Workspace’s Working Hours:
Specify your team's daily working hours (from 'start of day' to 'end of day').
This helps to tailor your experience and notifications around your active work schedules.`;
function WorkingHours() {
  const { selectedWorkspace } = useWorkspaceHook();
  const {
    Hotel: { Shift },
  } = selectedWorkspace();
  const dispatch = useDispatch();

  const handleSelectStartTime = async (key, value) => {
    const newShift = { ...Shift, [key]: value };
    const postObj = {
      [key]: value,
      id: newShift?.id,
    };
    const oldShift = Shift;
    dispatch(updateWorkspace(newShift));
    const updatedShift = await updateShift(postObj);
    if (updatedShift) {
      //Update if api success
      dispatch(updateWorkspace(updatedShift));
    } else {
      // Rollback if api does not success
      dispatch(updateWorkspace(oldShift));
    }
  };

  const handleWorkingHoursChange = async () => {
    const oldShift = Shift;
    if (Shift) {
      // Delete shift
      dispatch(updateWorkspace(null));
      const deletedShift = await deleteShift(Shift.id);
      if (!deletedShift) {
        // Rollback if api does not success
        dispatch(updateWorkspace(oldShift));
      }
    } else {
      const postObj = {
        startTime: hoursArr[0],
        endTime: hoursArr[hoursArr.length - 1],
      };
      dispatch(updateWorkspace(postObj));
      const updatedShift = await createShift(postObj);
      if (updatedShift) {
        //Update if api success
        dispatch(updateWorkspace(updatedShift));
        return;
      }
      // Rollback if api does not success
      dispatch(updateWorkspace(oldShift));
      return;
    }
  };
  return (
    <WorkingHoursCnt>
      <div className={'workingHoursHeadingCnt'}>
        <div>
          <label className={'workHoursHeading'}>
            Working Hours{' '}
            <CustomToolTip title={workingHoursText}>
              <InfoOutlinedIcon />
            </CustomToolTip>
          </label>
        </div>
        <FormControlLabel
          checked={!!Shift}
          label={''}
          onChange={handleWorkingHoursChange}
          control={<StyledSwitch sx={{ m: 1 }} />}
          sx={{
            margin: 0,
          }}
        />
      </div>
      {Shift ? (
        <div className={'timePickerDropdownCnt'}>
          <TimePickerDropdown
            onChange={(value) => handleSelectStartTime('startTime', value)}
            selectedTime={Shift?.startTime}
            buttonText={'Select Time'}
            label={'Start of Day'}
            placeholder={'Select Time'}
            isTimeEdit={true}
            style={{ flex: 1 }}
            suppressClearIcon={true}
          />
          <span className={'dash'}>-</span>
          <TimePickerDropdown
            onChange={(value) => handleSelectStartTime('endTime', value)}
            selectedTime={Shift?.endTime}
            buttonText={'Select Time'}
            label={'End of Day'}
            placeholder={'Select Time'}
            isTimeEdit={true}
            style={{ flex: 1 }}
            suppressClearIcon={true}
          />
        </div>
      ) : null}

      {Shift?.isNightShift ? (
        <Alert severity="info">
          The working hours are set to as night shift
        </Alert>
      ) : null}
    </WorkingHoursCnt>
  );
}

export default WorkingHours;
