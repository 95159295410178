import styled from '@mui/system/styled';

export const EmptyCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& .content': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 550,
    '& .title': {
      marginTop: 10,
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '30.02px',
      color: '#212121',
      marginBottom: 10,
    },
    '& .subtitle': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '21.86px',
      color: '#424242',
      textAlign: 'center',
    },
  },
});
