import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import CustomButton from 'components/Button/CustomButton';
import { generateAssigneeDropdownData } from '../../../helper/task/dropdownData';
import selectors from 'store/selectors';
import {
  createTeam,
  editTeam,
  setAuthorizedTeams,
  setTeams,
  setTeamsHash,
} from 'store/actions/teamActions';
import xeniaApi from 'api/index';
import { isEmpty, uniqueId } from 'lodash';
import useUsers from 'utils/CustomHooks/useUsers';
import {
  CustomDrawerInnerCnt,
  TeamsDialog,
  StyledEndIconCnt,
} from 'pages/teams/createTeamDrawer/createTeam.style';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { getAuthLocationsHash } from 'store/selectors/locations';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function CreateTeamDrawer(props) {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [teamName, setTeamName] = useState('');
  const [locationId, setLocationId] = useState<any>(null);
  const { open, onClose, selectedEditTeam } = props;
  const dispatch = useDispatch();
  const userList = useSelector(selectors.getUserAdmin);
  const { taskAssignees } = useUsers();
  const authLocationsHash = useSelector(getAuthLocationsHash);

  useEffect(() => {
    if (selectedEditTeam && open) {
      const filterSelectedMembers = membersDropdownData.filter((m) =>
        selectedEditTeam.Members.some((member) => member.MemberId == m.id),
      );
      setSelectedMembers(filterSelectedMembers);
      setTeamName(selectedEditTeam.name);
      setLocationId(selectedEditTeam?.Location?.id);
    }
  }, [open]);

  //Handle Team name input change
  const handleTeamNameInput = (e) => {
    const value = e?.target?.value;
    setTeamName(value);
  };
  //Updating member select
  const handleSelectMember = (option) => {
    setSelectedMembers(option);
  };

  const handleLocation = (locationId) => {
    setLocationId(locationId);
  };

  const location = useMemo(() => {
    if (locationId) {
      return authLocationsHash?.[locationId];
    }
    return null;
  }, [authLocationsHash, locationId]);

  //Generating Members data for dropdown
  const membersDropdownData = useMemo(() => {
    return generateAssigneeDropdownData(taskAssignees());
  }, [userList]);

  const getAllTeams = async () => {
    const teamsList = await xeniaApi.getTeams();
    const authTeamsList = await xeniaApi.getAuthorizedTeams();
    const authTeamsWithMembers = authTeamsList?.map((team) => {
      return {
        ...team,
        Members: teamsList?.find((t) => t.id === team.id)?.Members ?? [],
      };
    });
    const teamsHash = teamsList?.reduce((acc, team) => {
      return { ...acc, [team.id]: team };
    }, {});
    dispatch(setTeams(teamsList ?? []));
    dispatch(setAuthorizedTeams(authTeamsWithMembers ?? []));
    dispatch(setTeamsHash(teamsHash));
  };

  //On Create team button click
  const handleCreateTeam = async () => {
    const postData = {
      id: uniqueId(),
      name: teamName.trim(),
      Members: selectedMembers?.map((m: any) => m?.id),
      isActive: true,
      LocationId: locationId || null,
    };
    handleCloseDrawer();

    try {
      const newTeamObj = await xeniaApi.createTeam(postData);
      if (newTeamObj) dispatch(createTeam(newTeamObj.data));
      dispatch(editTeam(newTeamObj.data, postData));
      getAllTeams();
    } catch (err) {
      /***/
    }
  };
  //On Update team button click
  const handleEditTeam = async () => {
    const postData = {
      id: selectedEditTeam.id,
      name: teamName.trim(),
      Members: selectedMembers?.map((m: any) => m?.id),
      isActive: true,
      LocationId: locationId || null,
    };
    handleCloseDrawer();
    const newTeamObj = await xeniaApi.editTeam(postData);
    dispatch(editTeam(newTeamObj.data, {}));
  };
  const handleCloseDrawer = () => {
    onClose();
    setSelectedMembers([]);
    setTeamName('');
    setLocationId(null);
  };
  const addBtnDisabled = isEmpty(selectedMembers) || !teamName;

  return (
    <TeamsDialog onClose={handleCloseDrawer} open={open}>
      <CustomDrawerInnerCnt>
        <div className="headerCnt">
          <h2>{selectedEditTeam ? 'Edit Team' : 'Create Team'}</h2>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon style={{ color: 'rgba(117, 113, 197, 1)' }} />
          </IconButton>
        </div>
        <div className="teamsDrawerContentCnt">
          <CustomInput
            label="Name"
            topLabelInput={true}
            muiLabel={false}
            id="teamNameInput"
            fieldProps={{
              placeholder: 'Enter Team Name',
              value: teamName,
            }}
            onChange={handleTeamNameInput}
          />
          <CustomSelect
            label={'Members'}
            placeholder="Select Members"
            isSearchable={true}
            isMulti={true}
            closeMenuOnSelect={false}
            selectedOption={selectedMembers}
            options={membersDropdownData.filter((o) => o.id != 'unassigned')}
            onChange={handleSelectMember}
          />
          <Box>
            <Typography className="title">Location</Typography>

            <AssigneeDropdown
              handleChangeCallback={(data) => {
                handleLocation(data?.currentSelected);
              }}
              buttonRendererProps={{
                width: '100%',
              }}
              buttonRenderer={
                <CustomButton
                  variant="outlined"
                  buttonType="grayWhite"
                  endIcon={
                    <StyledEndIconCnt>
                      <ArrowDropDownIcon />
                    </StyledEndIconCnt>
                  }
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    padding: '6px 17px 6px 10px',
                    height: 40,
                    borderRadius: 6,
                    fontWeight: 400,
                    color: '#858585',
                  }}
                >
                  {location?.name || 'Select Location'}
                </CustomButton>
              }
              showTabs={false}
              listProps={{
                locations: {
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: false,
                  hideActions: false,
                  selected: locationId ? [locationId] : [],
                  addLocationByTag: false,
                },
              }}
              displayOptions={{
                locations: true,
              }}
              popperProps={{
                disablePortal: false,
              }}
              allSelected={false}
            />
          </Box>
        </div>
        <div className="btnActionCnt">
          <CustomButton
            variant="text"
            buttonType={'gray'}
            style={{ marginRight: 16 }}
            onClick={handleCloseDrawer}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            disabled={addBtnDisabled}
            onClick={selectedEditTeam ? handleEditTeam : handleCreateTeam}
          >
            {selectedEditTeam ? 'Update' : 'Create'}
          </CustomButton>
        </div>
      </CustomDrawerInnerCnt>
    </TeamsDialog>
  );
}

export default CreateTeamDrawer;
