// MUI components
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const StyledNotificationBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'unread',
})(({ unread }) => ({
  position: 'relative',
  display: 'flex',
  gap: 40,
  padding: '10px 24px 10px 26px',
  background: unread ? '#F5F4FF' : '#fff',
  borderBottom: unread ? '1px solid #fff' : '1px solid #EEE',
  cursor: 'pointer',
}));

export const AvatarAndNotificationWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 12,
}));

export const UnreadDot = styled(Box)(() => ({
  display: 'flex',
  width: 6,
  height: 6,
  borderRadius: 6,
  background: 'rgba(239, 83, 80, 1)',
  position: 'absolute',
  top: '50%',
  left: '-11px',
  transform: 'translate(-50%, -50%)',
}));

export const AvatarBox = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  height: 'fit-content',
}));

export const TimeText = styled(Typography)(() => ({
  fontWeight: 600,
  color: 'rgba(117, 117, 117, 1)',
  fontSize: 12,
  marginLeft: 'auto',
}));
