import styled from '@mui/system/styled';

export const LocationsSearchInputCnt = styled('div')(({ theme }) => ({
  width: 310,
  marginRight: 24,
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginRight: 0,
    maxWidth: '295px',
  },
  '& input': {
    padding: '8.5px 14px 8.5px 7px',
    '&::-webkit-input-placeholder': {
      fontSize: 14,
    },
  },
  '& svg': {
    color: '#0000008A',
  },
  //will be removed once bootstrap is removed from app
  '& legend': {
    width: 0,
  },
}));
