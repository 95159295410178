import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getHotelId } from 'utils/globalFunction';

export function useUserRole() {
  const userProfile = useSelector(selectors.getUserProfile);
  const workspaceId = getHotelId();

  const isBasicUser = useMemo(() => {
    const selectedWorkspace = userProfile?.UserHotels?.find(
      (h) => h?.Hotel?.id == workspaceId,
    );

    return selectedWorkspace?.Role?.title === 'Basic User';
  }, [userProfile, workspaceId]);

  return {
    isBasicUser,
  };
}
