import UserAvatar from 'components/Avatar';

import { useSelector } from 'react-redux';
import { getHashedUserList } from 'store/selectors/users';
import { CreatorName, ItemWrapper, ReportToContent } from './style';
import { getRoles } from 'store/selectors/roles';
import { useMemo } from 'react';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import AssigneesView from 'components/AssigneesView/AssigneesView';

const CreatedBy = ({ userId }: { userId: string }) => {
  const hashedUsers = useSelector(getHashedUserList);
  const user = hashedUsers?.[userId];
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
  return (
    <>
      <UserAvatar width={24} height={24} userId={userId} />
      <CreatorName style={{ marginLeft: 10 }}>{fullName}</CreatorName>
    </>
  );
};

export const CreatedByRenderer = ({ createdBy }) => {
  return (
    <ItemWrapper>
      <CreatedBy userId={createdBy} />
    </ItemWrapper>
  );
};

export const ReportsToRenderer = ({ reportTo }) => {
  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;

  const reportToRole = useMemo(() => {
    return rolesList?.find((role) => role?.id === reportTo);
  }, [rolesList]);

  return (
    <ItemWrapper>
      <ReportToContent>{reportToRole?.title}</ReportToContent>
    </ItemWrapper>
  );
};

export const UsersRenderer = ({ users }) => {
  const { addNewAssignees } = useAssigneesUtils();
  const usersHash = useSelector(getHashedUserList);

  const selectedUsers = useMemo(() => {
    const usersIds = users?.map((user) => user?.UserId) ?? [];
    return addNewAssignees(usersIds);
  }, [users, usersHash]);

  return (
    <ItemWrapper>
      {selectedUsers?.length > 0 ? (
        <AssigneesView
          type="avatars"
          data={selectedUsers}
          assigneesProps={{
            height: 28,
            width: 28,
            popperProps: {
              sx: {
                zIndex: 1700,
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'white !important',
                  boxShadow:
                    'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                  minWidth: 300,
                  maxHeight: 300,
                  overflowY: 'auto',
                  borderRadius: '15px',
                },
              },
            },
          }}
        />
      ) : (
        '-'
      )}
    </ItemWrapper>
  );
};
