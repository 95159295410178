// React
import { Fragment, useRef, useState, useEffect } from 'react';

// Custom components
import ListView from './ListView';
import GridView from 'pages/Schedules/common/GridView';
import EmptyState from './EmptyState';
import Header from 'pages/Schedules/Overdue/Header';
import EmptyResultsFilter from '../common/EmptyResultsFilter';

// MUI
import { CircularProgress } from '@mui/material';

// Styled
import { Container, Content, LoadingContainer } from './styled';

// Context
import { OverdueSchedulesCtx } from './context';

// Hooks
import { useScheduleTab } from 'pages/Schedules/common/useScheduleTab';
import { useWindowResize } from 'controller/useWindowResize';
import useResponsive from 'utils/CustomHooks/useResponsive';

const Overdue = () => {
  const size = useWindowResize();
  const [height, setHeight] = useState<any>(0);
  const ref = useRef<any>(null);
  const { isMobileDeviceSm } = useResponsive();

  useEffect(() => {
    setHeight(
      isMobileDeviceSm
        ? ref?.current?.clientHeight + 40
        : ref?.current?.clientHeight - 30,
    );
  }, [size, ref?.current?.clientHeight]);

  const {
    data,
    advanceFilters,
    searchTerm,
    totalCount,
    isNextPageLoading,
    loading,
    noResults,
    setSearchTerm,
    setAdvanceFilters,
    handleLoadNextPage,
    handleChangeSearch,
    handleClickScheduleItem,
  } = useScheduleTab(3);

  const contextValue = {
    data,
    loading,
    advanceFilters,
    searchTerm,
    setAdvanceFilters,
    setSearchTerm,
    handleChangeSearch,
  };

  return (
    <OverdueSchedulesCtx.Provider value={contextValue}>
      <Content ref={ref}>
        <Header />
        <Fragment>
          {loading && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
          {data !== null && !loading && (
            <Fragment>
              {data?.length === 0 ? (
                <Fragment>
                  {noResults ? <EmptyResultsFilter /> : <EmptyState />}
                </Fragment>
              ) : (
                <Container>
                  <GridView
                    isNextPageLoading={isNextPageLoading}
                    handleLoadNextPage={handleLoadNextPage}
                    totalCount={totalCount}
                    tasks={data}
                    height={height}
                    onClickTask={handleClickScheduleItem}
                  />
                </Container>
              )}
            </Fragment>
          )}
        </Fragment>
      </Content>
    </OverdueSchedulesCtx.Provider>
  );
};

export default Overdue;
