import {
  AbstractBackgroundCnt,
  LoginCnt,
  LoginOuterCnt,
  DividerWithText,
} from 'pages/Login/style';
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import { Typography } from '@mui/material';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { useState } from 'react';
import CustomButton from 'components/Button/CustomButton';

import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { useSelector } from 'react-redux';
import xeniaApi from 'api/index';
import PasswordInput from 'components/Form/TextField/PasswordField.cmp';
import selectors from 'store/selectors';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useAppNavigation from 'utils/CustomHooks/useAppNavigation';
function SetNewPassword(props) {
  const [newPassword, setNewPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmNewPassword] = useState<string>('');
  const [showConfirmPassword, setShowConfirmPassword] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const userProfileData = useSelector(selectors.getUserProfile);
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
  const { navigateToHome } = useAppNavigation();

  const [changePassBtnLoader, setChangePassBtnLoader] =
    useState<boolean>(false);
  const hotelId: any = useSelector(selectors.getUserHotelId);
  //Handle Email Input Change
  const handleNewPassowrdChange = (e) => {
    const value = e.target.value;
    setNewPasswordError('');
    setNewPassword(value);
  };
  //Handle password input change
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPasswordError('');
    setConfirmNewPassword(value);
  };

  //Validating email and passsword on login and making api call
  const handleLogin = async () => {
    if (newPassword?.length < 6 || newPassword?.length > 80) {
      setNewPasswordError(VALIDATION_ERROR_MESSAGES.PASSWORD_MIN_MAX_LIMIT);
      setConfirmPasswordError(VALIDATION_ERROR_MESSAGES.PASSWORD_MIN_MAX_LIMIT);
      return;
    }

    if (!newPassword) {
      setNewPasswordError(VALIDATION_ERROR_MESSAGES.REQUIRED_NEW_PASSWORD_ID);
      return;
    }
    if (!confirmPassword) {
      setConfirmPasswordError(
        VALIDATION_ERROR_MESSAGES.REQUIRED_CONFIRM_PASSWORD_ID,
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      setNewPasswordError(VALIDATION_ERROR_MESSAGES.PASSWORD_NOT_MATCHED);
      setConfirmPasswordError(VALIDATION_ERROR_MESSAGES.PASSWORD_NOT_MATCHED);
      return;
    }

    const { emailId, phoneNo }: any = userProfileData;
    const payload: any = {
      password: newPassword,
      emailId,
      phoneNo,
      HotelId: hotelId,
    };
    const resp = await xeniaApi.setUserPassword(payload);
    if (resp) {
      navigateToHome(hotelId);
    }
  };
  const onShowPassword = (value) => {
    setShowPassword(value);
  };
  const onShowConfirmPassword = (value) => {
    setShowConfirmPassword(value);
  };
  //When user press enter key
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode == 13) {
      handleLogin();
      return;
    }
  };
  return (
    <AbstractBackgroundCnt>
      <LoginOuterCnt>
        <img className={'xeniaLogo'} src={XeniaLogo} />
        <LoginCnt>
          <div className="headingCnt">
            <Typography variant="h4">Reset your Password</Typography>
          </div>
          <PasswordInput
            label="New password"
            error={newPasswordError}
            showPasswordCallback={onShowPassword}
            muiLabel={true}
            fieldProps={{
              onKeyDown: handleKeyDown,
              onChange: handleNewPassowrdChange,
              autoComplete: 'off',
              name: 'password',
              value: newPassword,
              placeholder: 'Enter new password',
            }}
          />
          <PasswordInput
            label="Confirm new password"
            error={confirmPasswordError}
            showPasswordCallback={onShowConfirmPassword}
            muiLabel={true}
            fieldProps={{
              onKeyDown: handleKeyDown,
              onChange: handleConfirmPasswordChange,
              autoComplete: 'off',
              name: 'newPassword',
              value: confirmPassword,
              placeholder: 'Confirm new password',
              type: showConfirmPassword ? 'text' : 'password',
            }}
          />
          <CustomButton
            variant="contained"
            buttonLoader={changePassBtnLoader}
            sx={{ width: 320 }}
            onClick={handleLogin}
          >
            Submit
          </CustomButton>
        </LoginCnt>
      </LoginOuterCnt>
    </AbstractBackgroundCnt>
  );
}
export default SetNewPassword;
