import { createSvgIcon } from '@mui/material';

const TemperatureIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66658 0.833344C5.05576 0.833344 3.74992 2.13918 3.74992 3.75001V11.0477C2.73323 11.8874 2.08325 13.1593 2.08325 14.5833C2.08325 17.1146 4.13528 19.1667 6.66658 19.1667C9.19789 19.1667 11.2499 17.1146 11.2499 14.5833C11.2499 13.1593 10.5999 11.8874 9.58325 11.0477V3.75001C9.58325 2.13918 8.27742 0.833344 6.66658 0.833344ZM6.66658 12.9167C5.74611 12.9167 4.99992 13.6629 4.99992 14.5833C4.99992 15.5038 5.74611 16.25 6.66658 16.25C7.58706 16.25 8.33325 15.5038 8.33325 14.5833C8.33325 13.6629 7.58706 12.9167 6.66658 12.9167Z"
        fill="#EC407A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6666 2.50001C11.6666 2.03977 12.0397 1.66668 12.4999 1.66668L17.4999 1.66668C17.9602 1.66668 18.3333 2.03977 18.3333 2.50001C18.3333 2.96025 17.9602 3.33334 17.4999 3.33334L12.4999 3.33334C12.0397 3.33334 11.6666 2.96025 11.6666 2.50001Z"
        fill="#EC407A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6666 5.83334C11.6666 5.37311 12.0397 5.00001 12.4999 5.00001L17.4999 5.00001C17.9602 5.00001 18.3333 5.37311 18.3333 5.83334C18.3333 6.29358 17.9602 6.66668 17.4999 6.66668H12.4999C12.0397 6.66668 11.6666 6.29358 11.6666 5.83334Z"
        fill="#EC407A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6666 9.16668C11.6666 8.70644 12.0397 8.33334 12.4999 8.33334L17.4999 8.33334C17.9602 8.33334 18.3333 8.70644 18.3333 9.16668C18.3333 9.62691 17.9602 10 17.4999 10L12.4999 10C12.0397 10 11.6666 9.62691 11.6666 9.16668Z"
        fill="#EC407A"
      />
    </>,
    'TemperatureIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default TemperatureIcon;
