import { FC, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import { DialogActions, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomButton from 'components/Button/CustomButton';
import {
  getAnswerView,
  getConditionText,
} from 'components/common/jsxrender/checklistV2/builder/automations/previewItem';
import CategoryDropdown from 'components/Dropdown/TemplateCategoryDropdown/TemplateCategoryDropdown';
import Cover from './components/cover';
import { getEntityPreview } from '../automation/utils/getEntityPreview';
import actions from 'store/actions';
import DialogIds from 'utils/dialogIds';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import xeniaApi from 'api/index';
import { Content } from './styled';

interface Automation {
  id: string;
  conditions: any[];
  actions: any[];
}

interface IProps {
  field: any;
  automation: Automation;
  onClose(): void;
  onSave(): any;
}

const DialogContent: FC<IProps> = (props) => {
  const { field, automation, onClose, onSave } = props;
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();

  const [action] = automation?.actions || [];
  const [condition] = automation?.conditions || [];

  const [selectedCategory, setSelectedCategory] = useState<string[]>(
    action?.data?.flagCategoryId,
  );

  const [isLoading, setIsLoading] = useState(false);

  // useEffect

  const saveAndClose = useCallback(async () => {
    setIsLoading(true);

    const data = {
      flagCategoryId: selectedCategory,
      when: 'onSubmit',
    };

    const newAction = { ...action, data };
    const updatedAutomation = { ...automation, actions: [newAction] };
    await xeniaApi.upsertAutomations({
      automations: [updatedAutomation],
    });

    if (workspaceId) {
      dispatch(
        actions.fetchChecklistItemsAutomations(workspaceId, field.checklistId),
      );
    }
    await onSave?.();
    dispatch(
      actions.setDialog({
        dialogId: DialogIds.FLAGGED_CATEGORY_SELECTION_AUTOMATION,
        open: false,
        data: null,
      }),
    );
    setIsLoading(false);
  }, [selectedCategory, automation, action, workspaceId]);

  return (
    <>
      <Content>
        <Stack spacing={1}>
          <Cover
            title={field.description}
            preview={getEntityPreview(field)}
            conditionText={getConditionText(condition.logic)}
            answerView={getAnswerView(field, condition)}
          />
          <Typography
            width="100%"
            color="#424242"
            fontWeight={600}
            fontSize="14px"
            mb="4px"
          >
            Select Flag Category
          </Typography>

          <CategoryDropdown
            selected={selectedCategory}
            onChangeCallback={(value) => {
              setSelectedCategory(value.id);
            }}
            placeholder="Select"
            buttonRenderer
          />
        </Stack>
      </Content>
      <DialogActions
        sx={{ padding: '16px 22px', borderTop: '1px solid #eff0ef' }}
      >
        <CustomButton disabled={isLoading} variant="outlined" onClick={onClose}>
          Go Back
        </CustomButton>
        <CustomButton
          buttonLoader={isLoading}
          variant="contained"
          onClick={saveAndClose}
        >
          Save
        </CustomButton>
      </DialogActions>
    </>
  );
};

export default DialogContent;
