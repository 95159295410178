import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import xeniaApi from 'api/index';

import {
  Main,
  WSList,
  WSContainer,
  WSActionButtons,
  WSInfo,
  WSName,
  WorkspaceAdd,
  WSInviteContainer,
  InvitedInWSName,
  InviteDetails,
  InviteActionButtons,
} from 'pages/MyWorkspaces/styles';
import WorkspaceAvatar from 'components/Avatar/WorkspaceAvatar/index';
import DeleteIcon2 from 'components/Icons/deleteIcon2';
import CheckboxRoundChecked2 from 'components/Icons/checkboxRoundChecked2';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import {
  SettingsHeader,
  SettingsTitle,
} from 'components/common/styledComponents/settings.style';
import CustomButton from 'components/Button/CustomButton';

const MyWorkspaces = () => {
  const [myWorkspaces, setMyWorkspaces] = useState([]);
  const [reactingToInvite, setReactingToInvite] = useState('');
  const { workspaceId, deleteWorkspace, navigateToOtherWorkspace } =
    useWorkspaceHook();
  const { hasPermission, hasFeature, PERMISSIONS, FEATURES } =
    useCustomPermission();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const refreshWorkspaces = () => {
    xeniaApi.getMyWorkspaces().then((workspaces) => {
      setMyWorkspaces(workspaces);
      setReactingToInvite('');
      dispatch(actions.reloadUserContext());
    });
  };

  useEffect(() => {
    dispatch(actions.fetchWorkspacesUnreadCount());
    refreshWorkspaces();
  }, []);

  const acceptInvite = (invite) => {
    setReactingToInvite('ACCEPTING');
    xeniaApi.acceptInvite(invite.HotelId).then(() => {
      refreshWorkspaces();
    });
  };

  const declineInvite = (invite) => {
    setReactingToInvite('DECLINING');
    xeniaApi.declineInvite(invite.HotelId).then(() => {
      refreshWorkspaces();
    });
  };

  const handleAskConfirmation = (ws) => {
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Delete Workspace',
          description:
            'Are you sure you want to delete this workspace account?',
          confirmButtonProps: {
            color: 'error',
          },
          confirmationText: 'Delete',
          onConfirmCallback: () => {
            deleteWorkspace(ws);
          },
        },
      }),
    );
  };

  const handleDeclineConfirmation = (ws) => {
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Decline Invite',
          description: 'Are you sure you want to decline invite?',
          confirmButtonProps: {
            color: 'error',
          },
          confirmationText: 'Decline',
          onConfirmCallback: () => {
            declineInvite(ws);
          },
        },
      }),
    );
  };

  const renderActiveWS = (ws) => {
    const isSelected = ws?.Hotel?.id === workspaceId;
    const canDeleteWS = hasPermission(
      PERMISSIONS.CAN_MANAGE_WORKSPACES,
      ws?.Hotel?.id,
    );

    return (
      <WSContainer isSelected={isSelected}>
        <WSActionButtons>
          <div>
            {isSelected ? (
              <CheckboxRoundChecked2 />
            ) : (
              <div style={{ opacity: 0 }}> none </div>
            )}
          </div>
          <div>
            {(canDeleteWS && (
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => handleAskConfirmation(ws?.Hotel?.id)}
              >
                <DeleteIcon2 />
              </IconButton>
            )) ||
              ''}
          </div>
        </WSActionButtons>
        <WSInfo>
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => navigateToOtherWorkspace({ id: ws?.Hotel?.id })}
            className="ws-navigator"
          >
            <WorkspaceAvatar
              width={80}
              height={80}
              workspaceId={ws?.Hotel?.id}
              showBadge
            />
          </IconButton>
          <WSName isSelected={isSelected}>
            {ws?.Hotel?.name || 'Workspace'}
          </WSName>
        </WSInfo>
      </WSContainer>
    );
  };

  const renderPendingWS = (ws) => {
    return (
      <WSInviteContainer>
        <WSInfo>
          <WorkspaceAvatar
            width={56}
            height={56}
            workspaceId={ws?.Hotel?.id}
            showBadge
            defaultAvatar={ws?.Hotel?.avatar || null}
            wsName={ws?.Hotel?.name || 'Workspace'}
          />
          <InvitedInWSName>{ws?.Hotel?.name || 'Workspace'}</InvitedInWSName>
          <InviteDetails>
            {ws?.InvitedBy?.fullName || 'An admin'} has invited you to the “
            {ws?.Hotel?.name || ''}” workspace
          </InviteDetails>
        </WSInfo>
        <InviteActionButtons>
          <CustomButton
            variant={'text'}
            onClick={() => {
              handleDeclineConfirmation(ws);
            }}
            sx={{ padding: '2px 26px' }}
            disabled={!!reactingToInvite}
            buttonLoader={reactingToInvite === 'DECLINING'}
          >
            Decline
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={() => {
              acceptInvite(ws);
            }}
            sx={{ padding: '2px 26px' }}
            disabled={!!reactingToInvite}
            buttonLoader={reactingToInvite === 'ACCEPTING'}
          >
            Accept
          </CustomButton>
        </InviteActionButtons>
      </WSInviteContainer>
    );
  };

  const renderWS = (ws) => {
    if (ws.status === 'Pending') {
      return renderPendingWS(ws);
    }

    return renderActiveWS(ws);
  };

  return (
    <Main>
      <SettingsHeader style={{ display: 'flex', alignItems: 'center' }}>
        <SettingsTitle style={{ marginRight: 5 }}>My Workspaces</SettingsTitle>
      </SettingsHeader>
      <WSList>
        {(hasPermission(PERMISSIONS.CAN_MANAGE_WORKSPACES) && (
          <WSContainer>
            <WSActionButtons>
              <div style={{ opacity: 0 }}> none </div>
            </WSActionButtons>
            <WSInfo>
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => {
                  if (hasFeature(FEATURES.MULTI_WORKSPACES)) {
                    navigate(`/ws/${workspaceId}/new`);
                  } else {
                    dispatch(
                      actions.setDialog({
                        dialogId: 'workspaceFeatureDialog',
                        open: true,
                      }),
                    );
                  }
                }}
              >
                <WorkspaceAdd>+</WorkspaceAdd>
              </IconButton>
              <WSName>{'Add Workspace'}</WSName>
            </WSInfo>
          </WSContainer>
        )) ||
          null}
        {myWorkspaces.map((ws) => renderWS(ws))}
      </WSList>
    </Main>
  );
};

export default MyWorkspaces;
