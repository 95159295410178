import { Navigate, Route, Routes } from 'react-router-dom';
import OnBoarding from 'pages/onboarding';
import OnBoardingStatus from 'pages/onboarding/OnBoardingStatus';
import EmailConfirmation from 'pages/email-confirmation';
import TaskHome from 'pages/index';
import Layout from 'components/layout/layout';
import ChecklistV2 from 'pages/checklistV2';
import Documents from 'pages/documents';
import DocumentBuilder from 'pages/documents/builder';
import DocumentDetail from 'pages/documents/detail';
import ChecklistBuilder from 'pages/checklistV2/Builder/index';
import Messages from 'pages/messages';
import Guides from 'pages/guides';
import Dashboard from 'pages/dashboard';
import OrganizationManagement from 'pages/organization-management';
import KioskModeConfig from 'pages/KioskConfig';
import UserManagement from 'pages/user-management';
import ServiceManagement from 'pages/service-management';
import withAuth from 'components/Routes/authHoc';
import withPublicRoute from 'components/Routes/withPublicRoute';
import TeamsDashboard from 'pages/teams/teams.view';
import Settings from 'pages/settings/settings.view';
import MySettings from 'pages/my-settings';
import Login from 'pages/Login';
import SetNewPassword from 'pages/Login/SetNewPassword';
import ChecklistLogReport from 'components/ChecklistReport';
import SignUpWithEmail from 'pages/Login/signUpWithEmail';
import CreateWorkspace from 'pages/CreateWorkspace';
import InviteWorkspaceUsers from 'pages/CreateWorkspace/InviteUsers';
import MyWorkspaces from 'pages/MyWorkspaces';
import NotificationSettings from 'pages/notification-settings';
import LoginWithParams from 'pages/Login/loginWithParams';
import withTitleHoc from 'components/Routes/withTitleHoc';
import { PAGE_TITLES } from 'utils/globalVariable';
import TemplatePreview from 'pages/template-preview';
import Billing from 'pages/Billing';
import Locked from 'pages/PlanExpired';
import LocationsView from 'pages/Locations/index';
import Verify from 'pages//verify';
import TemplateDashboard from 'pages/TemplateDashboard';
import InternalSubmissionShare from 'pages/InternalSubmissionShare';
import AssetsView from 'pages/Assets';
import PricingStandalonePage from 'pages/PricingStandalonePage';
import ReportingDashboard from 'pages/ReportingV2';
import PublicTemplateSubmission from 'pages/PublicTemplateSubmission';
import RequestListingDashboard from 'pages/Requests/Requests';
import TaskActivityLogWebView from 'pages/WebViews/task/activityLog';
import ScheduledWork from 'pages/Schedules';
import AgendaView from 'pages/Schedules/agendaView';
import Projects from 'pages/Projects';
import FullScreenSpreadsheet from 'pages/FullScreenSpreadsheet';
import PublicRequestorSubmission from 'pages/PublicRequesterSubmission';
import TemplateSubmissions from 'pages/ReportingV2/Reports/TemplateSubmissions';
import TemplateTableView from 'pages/Templates/TemplateTableView';
import Notifications from 'pages/Notifications';
import FullScreenTemplate from 'pages/FullScreenTemplate';
import UsersAndTeams from 'pages/UsersAndTeams';
import FullScreenTemplateLibrary from 'pages/FullScreenTemplateLibrary';

// Builder IO Edit Preview
import TemplateFileUpload from 'components/BuilderUI/TemplateFileUpload';
import TemplateFileUploadSuccess from 'components/BuilderUI/TemplateFileUpload/successInfo';
import AuthScreenDetails from 'components/BuilderUI/AuthScreen';
import FileUploadTemplateMobile from 'pages/FileUploadTemplateMobile';
import Dashboards from 'pages/Dashboards';
import ScheduleReporting from 'pages/Dashboards/Reports/ScheduledWork';
import ActivityLogsPage from 'pages/WorkspaceActivityLogs';
import ProjectDetails from 'pages/ProjectDetails';
import ScheduleDetails from 'pages/SchedulesDetails';
import CustomRoleDetails from 'pages/roles/details';
import SSOConfigrations from 'pages/SSO';

const FeatureRedirectCmp = () => {
  <div></div>;
};
const FeatureRedirect = withAuth(FeatureRedirectCmp);
const LayoutComponentAuth = withAuth(Layout);
const InternalSubmissionShareAuth = withAuth(InternalSubmissionShare);
const SetPasswordCmp = withAuth(SetNewPassword);
const ChecklistLogReportCmp = ChecklistLogReport;
const LoginCmp = withPublicRoute(Login);
const TemplateFileUploader = withPublicRoute(TemplateFileUpload);
const TemplateFileUploadSuccessInfo = withPublicRoute(
  TemplateFileUploadSuccess,
);
const AuthScreenInfo = withPublicRoute(AuthScreenDetails);
const SignupCmp = withPublicRoute(SignUpWithEmail);
const OnBoardingCmp = OnBoarding;
const OnBoardingStatusCmp = OnBoardingStatus;

const EmailConfirmationCmp = withPublicRoute(EmailConfirmation);
const TaskHomeCmp = withTitleHoc(TaskHome);
const MyWorkspacesCmp = withTitleHoc(MyWorkspaces);
const NotificationSettingsCmp = withTitleHoc(NotificationSettings);
const UserManagementCmp = withTitleHoc(UserManagement);
const ServiceManagementCmp = withTitleHoc(ServiceManagement);
const OrganizationManagementCmp = withTitleHoc(OrganizationManagement);
const KioskModeConfigurationCmp = withTitleHoc(KioskModeConfig);
const MySettingsCmp = withTitleHoc(MySettings);
const TeamsDashboardCmp = withTitleHoc(TeamsDashboard);
const SettingsCmp = withTitleHoc(Settings);
const MessagesCmp = withTitleHoc(Messages);
const GuidesCmp = withTitleHoc(Guides);
const DashboardCmp = withTitleHoc(Dashboard);
const ChecklistBuilderCmp = withTitleHoc(ChecklistBuilder);
const DocumentsBuilderCmp = withTitleHoc(DocumentBuilder);
const RequestListingDashboardCmp = withTitleHoc(RequestListingDashboard);
const ActivityLogsCmp = withTitleHoc(ActivityLogsPage);
const SSOConfigrationsCmp = withTitleHoc(SSOConfigrations);
const ChecklistV2Cmp = withTitleHoc(ChecklistV2);
const DocumentsCmp = withTitleHoc(Documents);
const ScheduledWorkCmp = withTitleHoc(ScheduledWork);
const AgendaViewCmp = withTitleHoc(AgendaView);
const ProjectsWorkCmp = withTitleHoc(Projects);
const ProjectDetailsCmp = withTitleHoc(ProjectDetails);
const ScheduleDetailsCmp = withTitleHoc(ScheduleDetails);
const ReportingDashboardCmp = withTitleHoc(ReportingDashboard);
const DashboardsCmp = withTitleHoc(Dashboards);
const BillingView = withAuth(withTitleHoc(Billing));
const LockedView = withAuth(Locked);
const PricingView = withAuth(PricingStandalonePage);
const LocationsCmp = withTitleHoc(LocationsView);
const AssetsCmp = withTitleHoc(AssetsView);
const SubmissionsReport = withTitleHoc(TemplateSubmissions);
const TemplatesTableView = withTitleHoc(TemplateTableView);
const UsersAndTeamsView = withTitleHoc(UsersAndTeams);
const CustomRoleDetailsView = withTitleHoc(CustomRoleDetails);
const ScheduledTasksReporting = withTitleHoc(ScheduleReporting);
const {
  task,
  checklist,
  documents,
  reporting,
  dashboard,
  messages,
  settings,
  notifications,
  teams,
  myProfile,
  myWorkspaces,
  newChecklist,
  requests,
  organizationManagement,
  kioskMode,
  locations,
  assets,
  serviceManagement,
  userManagement,
  billing,
  guides,
  dashboards,
  activityLogs,
  sso,
  roles,
  'scheduled-work': scheduledWork,
  projects,
  usersAndTeams,
} = PAGE_TITLES;
function AppRoutes() {
  return (
    <Routes>
      <Route path="/fr/:id*" element={<FeatureRedirect />} />
      <Route path="/" element={<Navigate to={'/sign-in'} />} />
      <Route path="/ws/:workspaceId/plan-expired" element={<LockedView />} />
      <Route path="/ws/:workspaceId/pricing" element={<PricingView />} />
      <Route path="/ws/:workspaceId/new" element={<CreateWorkspace />}></Route>
      <Route
        path="/ws/:workspaceId/invites-users"
        element={<InviteWorkspaceUsers />}
      />
      <Route path="/ws/:workspaceId" element={<LayoutComponentAuth />}>
        <Route path="dashboard" element={<DashboardCmp title={dashboard} />} />
        <Route path="tasks/*" element={<TaskHomeCmp title={task} />} />
        <Route
          path="activity/task/:taskId"
          element={<TaskActivityLogWebView />}
        />
        <Route
          path="documents/:tab/*"
          element={<DocumentsCmp title={documents} />}
          exact
        />

        <Route
          path="documents/new"
          exact
          element={<DocumentsBuilderCmp title={documents} />}
        />

        <Route
          path="projects"
          exact
          element={
            <ProjectsWorkCmp title={scheduledWork} childTitle={projects} />
          }
        />

        <Route
          path="projects/:projectId"
          exact
          element={
            <ProjectDetailsCmp title={scheduledWork} childTitle={projects} />
          }
        />

        <Route
          path="schedule-details/:scheduleId"
          exact
          element={<ScheduleDetailsCmp title={scheduledWork} />}
        />

        <Route
          path="scheduled-work"
          exact
          element={<Navigate to={'manage-schedules'} replace={true} />}
        />

        <Route
          path="agenda-view"
          exact
          element={<Navigate to={'schedules'} replace={true} />}
        />

        <Route
          path="agenda-view/:tab"
          exact
          element={<AgendaViewCmp title={scheduledWork} childTitle="Agenda" />}
        />

        <Route
          path="scheduled-work/:tab"
          element={
            <ScheduledWorkCmp title={scheduledWork} childTitle="Schedules" />
          }
        />
        <Route
          path="reporting/*"
          element={<ReportingDashboardCmp title={reporting} />}
        />
        <Route
          path="dashboards/*"
          element={<DashboardsCmp title={dashboards} />}
        />
        <Route
          path="submission-reports/:reportId"
          element={<SubmissionsReport title={reporting} />}
        />

        <Route
          path="schedule-reports/:reportId"
          element={<ScheduledTasksReporting title={reporting} />}
        />
        <Route
          path="checklist/:checklistId"
          element={<ChecklistBuilderCmp title={newChecklist} />}
        />
        <Route
          path="checklist/new"
          element={<ChecklistBuilderCmp title={newChecklist} />}
        />
        <Route
          path="checklist/new/folder/:folderId"
          element={<ChecklistBuilderCmp title={newChecklist} />}
        />
        <Route
          path="checklist/*"
          element={<ChecklistV2Cmp title={checklist} />}
        />
        <Route
          path="in-progress-checklist/:checklistId"
          element={<FullScreenTemplate />}
        />
        <Route
          path="checklist/:checklistId/folder/:folderId"
          element={<ChecklistBuilderCmp title={newChecklist} />}
        />
        <Route
          path="requests/*"
          element={<RequestListingDashboardCmp title={requests} />}
        />
        <Route path="templates/:templateId" element={<TemplateDashboard />} />
        <Route
          path="templates-table-view"
          element={<TemplatesTableView title={checklist} childTitle="Tables" />}
        />
        <Route
          path="templates/spreadsheet/:templateId"
          element={<FullScreenSpreadsheet />}
        />
        <Route path="messages" element={<MessagesCmp title={messages} />} />
        <Route path="guides" element={<GuidesCmp title={guides} />} />
        <Route
          path="messages/:channelId"
          element={<MessagesCmp title={messages} />}
        />
        <Route path="my-notifications" element={<Notifications />} />

        <Route
          path="settings"
          element={<SettingsCmp title={settings} childTitle={myWorkspaces} />}
        >
          <Route
            path="sso"
            element={<SSOConfigrationsCmp title={settings} childTitle={sso} />}
          />
          <Route
            path="activity-logs"
            element={
              <ActivityLogsCmp title={settings} childTitle={activityLogs} />
            }
          />

          <Route
            path="teams"
            element={<TeamsDashboardCmp title={settings} childTitle={teams} />}
          />
          <Route
            path="my-profile"
            element={<MySettingsCmp title={settings} childTitle={myProfile} />}
          />
          <Route
            path="organization-management"
            element={
              <OrganizationManagementCmp
                title={settings}
                childTitle={organizationManagement}
              />
            }
          />
          <Route
            path="kiosk"
            element={
              <KioskModeConfigurationCmp
                title={settings}
                childTitle={kioskMode}
              />
            }
          />
          <Route
            path="service-management"
            element={
              <ServiceManagementCmp
                title={settings}
                childTitle={serviceManagement}
              />
            }
          />
          <Route
            path="my-workspaces"
            element={
              <MyWorkspacesCmp title={settings} childTitle={myWorkspaces} />
            }
          />
          <Route
            path="notifications"
            element={
              <NotificationSettingsCmp
                title={notifications}
                childTitle={notifications}
              />
            }
          />
          <Route
            path="billing"
            element={
              <BillingView title={settings} childTitle={billing} showLoader />
            }
          />
        </Route>

        <Route
          path="locations/*"
          element={<LocationsCmp renderGoBackIcon title={locations} />}
        />
        <Route
          path="template-library"
          element={<FullScreenTemplateLibrary />}
        />
        <Route
          path="upload-file-template-mob"
          element={<FileUploadTemplateMobile />}
        />
        <Route path="assets/*" element={<AssetsCmp title={assets} />} />

        <Route
          path="users-and-teams"
          element={<UsersAndTeamsView title={usersAndTeams} />}
        />

        <Route
          path="users-and-teams/roles/:roleId"
          element={
            <CustomRoleDetailsView title={usersAndTeams} childTitle={roles} />
          }
        />
      </Route>

      <Route
        path="/public-report/:templateId"
        element={<PublicTemplateSubmission />}
      />
      <Route
        path="/ws/:workspaceId/public-request"
        element={<PublicRequestorSubmission />}
      />
      <Route path="/document-detail/:documentId" element={<DocumentDetail />} />
      <Route path="/set-password" element={<SetPasswordCmp />} />
      <Route exact path="/sign-in/*" element={<LoginCmp />} />
      <Route exact path="/register" element={<SignupCmp />} />
      <Route exact path="/on-boarding" element={<OnBoardingCmp />} />
      <Route exact path="/login-with-params" element={<LoginWithParams />} />
      <Route
        exact
        path="/on-boarding?email=email"
        element={<OnBoardingCmp />}
      />
      <Route
        exact
        path="/magic-link/sign-in"
        element={<OnBoardingStatusCmp />}
      />
      <Route
        exact
        path="/email-confirmation"
        element={<EmailConfirmationCmp />}
      />
      <Route
        path="/ws/:workspaceId/checklistlogs/:checklistLogId/report"
        element={<ChecklistLogReportCmp />}
      />
      <Route
        path="/public-report/:workspaceId/:checklistLogId"
        element={<ChecklistLogReportCmp />}
      />
      <Route exact path="/verify" element={<Verify />} />
      <Route
        exact
        path="/templates/:templateId/preview"
        element={<TemplatePreview />}
      />
      <Route
        path="/ws/:workspaceId/:templateId/:logId"
        element={<InternalSubmissionShareAuth />}
      />

      <Route
        exact
        path="/__builder_editing__/file-upload-template"
        element={<TemplateFileUploader />}
      />
      <Route
        exact
        path="/__builder_editing__/template-file-upload-success-section"
        element={<TemplateFileUploadSuccessInfo />}
      />
      <Route
        exact
        path="/__builder_editing__/auth-screen-info"
        element={<AuthScreenInfo />}
      />
    </Routes>
  );
}

export default AppRoutes;
