// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const TimelinesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  flexDirection: 'column',
}));

export const ActivityLineWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  flex: 1,
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '500',
  letterSpacing: '0.15px',
  lineHeight: '16.39px',
  color: 'rgba(66, 66, 66, 1)',
}));

export const AnonymousAvatar = styled(Box)(({ theme }) => ({
  height: 28,
  width: 28,
  borderRadius: 28,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
