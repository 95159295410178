import IconButton from '@mui/material/IconButton';
import CTypography from 'components/CTypography';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';
import EditIcon from 'components/Icons/editIcon';

import {
  TitleCnt,
  CellContentWrapper,
} from 'pages/Locations/LocationsList/locationsList.styles';

export const defaultColDef = {
  lockVisible: true,
};

export const getRowStyle = (params) => {
  if (params.node.data.ParentId) {
    const isLast =
      params.node.parent.allChildrenCount - 1 === params.node.childIndex;
    return isLast
      ? {
          borderBottom: '6px solid #f5f5f5',
          borderRadius: '0px 0px 8px 8px',
        }
      : {
          borderRadius: '0px',
        };
  }
};
import {
  ActionColumnRenderer,
  LocationNameRenderer,
  OpenLocationBtnRenderer,
  PhotosAttachment,
  LocationMembersRenderer,
  LocationLevelRenderer,
} from 'pages/Locations/LocationsList/columnRenderers';

export const Columns = (
  props: {
    hideMembersCol?: boolean;
  } = {},
) => {
  const { hideMembersCol } = props;
  const allCols = [
    {
      field: '',
      headerName: '',
      colId: 'openLocationAction',
      width: 150,
      suppressMenu: true,
      resizable: true,
      cellRenderer: (params) => cellRenderer.openLocationAction(params),
    },
    {
      headerName: 'Level',
      field: 'level',
      colId: 'level',
      minWidth: 150,
      suppressMenu: true,
      resizable: true,
      valueGetter: valueGetters?.level,
      cellRenderer: (params) => cellRenderer?.level(params),
    },
    {
      headerName: 'Description',
      field: 'description',
      colId: 'description',
      minWidth: 150,
      suppressMenu: true,
      resizable: true,
      valueGetter: valueGetters?.description,
    },
    {
      field: 'attachment',
      headerName: 'Photos/Attachments',
      colId: 'attachment',
      sortable: true,
      flex: 1,
      resizable: true,
      suppressMenu: true,
      valueGetter: (params) => valueGetters?.attachment(params),
      cellRenderer: (params) => cellRenderer?.attachment(params),
    },
    {
      field: 'Members',
      headerName: 'Members',
      colId: 'members',
      sortable: false,
      flex: 1,
      suppressMenu: true,
      resizable: true,
      cellRenderer: (params) => cellRenderer?.members(params),
    },
    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      suppressMovable: true,
      suppressMenu: true,
      width: 200,
      cellRenderer: (params) => cellRenderer.actionColumn(params),
    },
  ];
  if (hideMembersCol) {
    return allCols.filter((col) => col.colId !== 'members');
  }
  return allCols;
};

const valueGetters = {
  description: (params) => {
    const { description } = params.data;
    return description || '-';
  },
  level: (params) => {
    const { Level } = params.data;
    return Level?.title || '-';
  },
  attachment: (params) => {
    if (!params?.data) return;
    return params?.data?.attachment?.filter((url) =>
      /\.(jpe?g|png|gif)$/i.test(url),
    )?.length;
  },
};

export const cellRenderer = {
  title: (params) => {
    const { id, type, color, inProgress } = params.data;
    return (
      <TitleCnt inProgress={inProgress}>
        <ColorsDropdown
          selected={color}
          changeHandler={(color) => {
            // updateHandler({ type: type, color: color }, id);
          }}
        />
        <CTypography className="rowContent">{type}</CTypography>
        <IconButton
          style={{
            marginLeft: 10,
            display: 'none',
            backgroundColor: 'transparent',
            padding: 0,
            marginBottom: 4,
          }}
          className="editIcon"
          onClick={() => {
            // onBtStartEditing(params);
          }}
        >
          <EditIcon style={{ fontSize: 15, color: '#8e8484' }} />
        </IconButton>
      </TitleCnt>
    );
  },
  attachment: (params) => {
    return (
      <CellContentWrapper data-attribute="cell-click" style={{ width: '100%' }}>
        <PhotosAttachment location={params?.data} />
      </CellContentWrapper>
    );
  },
  name: (params) => {
    return <LocationNameRenderer params={params} />;
  },
  level: (params) => {
    return <LocationLevelRenderer params={params} />;
  },
  members: (params) => {
    const { Members } = params.data;
    return <LocationMembersRenderer members={Members} />;
  },
  openLocationAction: (params) => {
    return <OpenLocationBtnRenderer params={params} />;
  },
  actionColumn: (params) => {
    return <ActionColumnRenderer params={params} />;
  },
};
