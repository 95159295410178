import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLocationsState } from 'store/selectors/locations';
import { useCallback, useEffect, useMemo } from 'react';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getAssetsState, getAuthAssetsState } from 'store/selectors/assets';
import { cloneDeep } from 'lodash';

function useLocationDetail() {
  const params = useParams();
  const { locationId, subLocationId } = params;
  const locationsList = useSelector(getLocationsState);
  const assetsList = useSelector(getAssetsState);
  const authAssetsList = useSelector(getAuthAssetsState);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const getLocationAssets = useCallback(
    (id) => {
      const flatAllAssets = assetsList?.reduce((r, cv) => {
        r.push(cv);
        if (cv?.SubAssets?.length) {
          r.push(...cv.SubAssets);
        }
        return r;
      }, []);
      const locationAssets = flatAllAssets?.filter(
        (a) => a.LocationId == locationId || a.LocationId == id,
      );
      return locationAssets;
    },
    [locationId, subLocationId, assetsList],
  );

  const findObjectById = (id, dataArray) => {
    for (let i = 0; i < dataArray.length; i++) {
      const currentObject = dataArray[i];

      if (currentObject.id === id) {
        return currentObject;
      }

      if (currentObject?.Sublocations?.length > 0) {
        const foundObject = findObjectById(id, currentObject?.Sublocations);
        if (foundObject) {
          return foundObject;
        }
      }
    }

    return null;
  };

  // Find the selected location from the list
  const selectedLocation = useMemo(() => {
    const filterLocation = findObjectById(locationId, locationsList);
    const subLocation = filterLocation?.Sublocations?.find(
      (l) => l.id == subLocationId,
    );

    const data = subLocation ? subLocation : filterLocation;
    return cloneDeep(data);
  }, [locationId, subLocationId, locationsList]);

  const getLocationAssetsIncludingSub = (id) => {
    const flatAllAssets = authAssetsList?.reduce((r, cv) => {
      r.push(cv);
      if (cv?.SubAssets?.length) {
        r.push(...cv.SubAssets);
      }
      return r;
    }, []);
    const locationAssets = flatAllAssets?.filter((a) => a.LocationId == id);

    return locationAssets;
  };

  // If the selected location is not found, redirect to the locations page
  useEffect(() => {
    if (!selectedLocation) {
      navigateWithWorkspaceUrl('/locations');
    }
  }, []);
  return {
    selectedLocation,
    getLocationAssetsIncludingSub,
    getLocationAssets,
  };
}

export default useLocationDetail;
