/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { ConstantChannelTypeString } from 'utils/globalVariable';
import { trim } from 'lodash';

export const useProfileChannel = (props): any => {
  const { state, _client, data } = props;
  const [memberObj, setMemberObj] = useState({});
  const _user = _client?._user;

  useEffect(() => {
    if (!_user) return;
    const names: any = [];
    let profileImage = null;
    let userId = null;
    const isDirectMessage =
      data?.channelType == ConstantChannelTypeString.DIRECT_MESSAGE;
    const noChannelName = !data.name || trim(data.name) === '';
    if (isDirectMessage || noChannelName) {
      Object.keys(state.members).map((key) => {
        if (state.members[key].user.id != _user.id) {
          const name = state.members[key].user?.name;
          names.push(name);
          profileImage = state.members[key].user?.profileImage;
          userId = state.members[key].user?.id;
        }
      });
      setMemberObj({
        name: names.join(', '),
        profileImage,
        userId,
      });
    } else {
      setMemberObj(data);
    }
  }, [data, _user]);

  return {
    memberObj,
  };
};
