// Hooks
import useBannerData from './useBannerData';

// Styled
import {
  BannerWrapper,
  BannerTileWrapper,
  BannerIconWrapper,
  BannerTileTextWrapper,
  BannerTileTextSmall,
  BannerTileTextLarge,
  StyledDivider,
} from './styled';
import useBilling from 'controller/useBilling';

const Banner = () => {
  const { bannerData } = useBannerData();
  const { billingData } = useBilling();

  return (
    <>
      {billingData.billingInfo?.isCustom ? (
        <BannerWrapper style={{ justifyContent: 'space-around' }}>
          <BannerTileWrapper flex={true}>
            <BannerTileTextWrapper>
              <BannerTileTextSmall>Current Plan</BannerTileTextSmall>
              <BannerTileTextLarge>Enterprise</BannerTileTextLarge>
            </BannerTileTextWrapper>
          </BannerTileWrapper>
          <StyledDivider variant="middle" flexItem orientation="vertical" />
          <BannerTileWrapper flex={true}>
            <BannerTileTextWrapper>
              <BannerTileTextSmall>Billing Cycle</BannerTileTextSmall>
              <BannerTileTextLarge>Custom</BannerTileTextLarge>
            </BannerTileTextWrapper>
          </BannerTileWrapper>
          <StyledDivider variant="middle" flexItem orientation="vertical" />
          <BannerTileWrapper flex={true}>
            <BannerTileTextWrapper>
              <BannerTileTextSmall>Users</BannerTileTextSmall>
              <BannerTileTextLarge>Unlimited</BannerTileTextLarge>
            </BannerTileTextWrapper>
          </BannerTileWrapper>
        </BannerWrapper>
      ) : (
        <BannerWrapper>
          {bannerData.map((banner, index) => (
            <>
              {banner.divider ? (
                <StyledDivider
                  key={index}
                  variant="middle"
                  flexItem
                  orientation="vertical"
                />
              ) : (
                <>
                  {banner.customRenderer ?? (
                    <BannerTileWrapper flex={banner?.flex} key={index}>
                      {banner.icon?.cmp && (
                        <BannerIconWrapper background={banner.icon?.background}>
                          {banner.icon?.cmp}
                        </BannerIconWrapper>
                      )}
                      <BannerTileTextWrapper>
                        <BannerTileTextSmall>
                          {banner.heading?.small}
                        </BannerTileTextSmall>
                        <BannerTileTextLarge>
                          {banner.heading?.large}
                        </BannerTileTextLarge>
                      </BannerTileTextWrapper>
                    </BannerTileWrapper>
                  )}
                </>
              )}
            </>
          ))}
        </BannerWrapper>
      )}
    </>
  );
};

export default Banner;
