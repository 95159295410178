import styled from '@mui/system/styled';

export const TaskStatusCountCnt = styled(
  'div',
  {},
)(() => ({
  margin: '0px 0px 20px 0px',
}));
export const TileInnerCnt = styled(
  'div',
  {},
)(() => ({
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  border: '1px solid #EEEEEE',
  height: 105,
  display: 'flex',
  padding: '18px 24px',
  flexDirection: 'column',
  borderRadius: 12,
  '.statusText': {
    fontWeight: 700,
    fontSize: 15,
    margin: 0,
  },
  '.countsText': {
    fontWeight: 800,
    fontSize: 32,
    lineHeight: '44px',
    margin: 0,
    color: '#212121',
  },
  '.noCountText': {
    fontWeight: 800,
    fontSize: 32,
    margin: 0,
    color: '#212121',
    lineHeight: '44px',
  },
}));
