// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '8px 0px',
  margin: 'auto 0px 10px 0px',
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

export const TextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}));

export const Dot = styled(Box)(({ theme }) => ({
  height: 4,
  width: 4,
  background: 'rgba(117, 117, 117, 1)',
  borderRadius: 4,
  margin: '0 8px',
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '600',
  color: 'rgba(117, 117, 117, 1)',
}));

export const PrimaryText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '700',
  color: 'rgba(66, 66, 66, 1)',
}));
