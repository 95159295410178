import styled from '@mui/system/styled';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import OutlinedInput from '@mui/material/OutlinedInput';

export const AssigneeDropdownContentCnt = styled('div')({
  '& .tabsCnt': {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '& .MuiDivider': {
      margin: '0 16px',
    },
    '& .tab': {
      fontSize: 12,
      fontWeight: 400,
      marginRight: 8,
      borderRadius: 16,
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '7px 11.5px',
      background: 'rgba(0, 0, 0, 0.08)',
      lineHeight: '18px',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.15)',
      },
      '& .tabCount': {
        fontWeight: 400,
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.38)',
        lineHeight: '18px',
      },
    },
    '& .tab.selectedTab': {
      background: 'rgba(255, 132, 68, 1)',
      color: '#fff',
      '& .tabCount': {
        color: 'rgba(255, 218, 199, 1)',
      },
    },
  },
});
export const UserStatusChip = styled('span')({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
  padding: '4px 10px',
  borderRadius: 14,
  textTransform: 'capitalize',
});
export const AddIconButton = styled('span')({
  border: '1px dashed rgba(0, 0, 0, 0.38)',
  padding: 2,
  borderRadius: '50%',
  cursor: 'pointer',
});
export const DropdownBtnCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledList = styled(List)({
  padding: '8px 0 8px 0',
  maxHeight: 300,
  overflowY: 'auto',
});
export const SearchInputCnt = styled('div')({
  padding: '10px 20px 0 20px',
  '& .listHeading': {
    fontSize: 14,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 8,
    display: 'inline-block',
  },
});
export const SelectedAssigneeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& .selectedAssigneeLabel': {
    marginLeft: 5,
    fontSize: 14,
  },
});

export const StyledSearchField = styled(OutlinedInput)({
  '& input': {
    padding: '6.5px 14px 6.5px 7px',
  },
  '& svg': {
    color: '#0000008A',
  },
  //will be removed once bootstrap is removed from app
  '& legend': {
    width: 0,
  },
});
