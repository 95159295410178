import { Grid } from '@mui/material';
import { FC } from 'react';

const Guides: FC = () => {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="bento-videos"></div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="bento-book"></div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="bento-premium"></div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="bento-feature"></div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="bento-recommend-us"></div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Guides;
