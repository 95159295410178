// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Chip } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const NameAndBack = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 16px',
  gap: 20,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid rgba(224, 224, 224, 1)`,
}));

export const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  left: 16,
  fontWeight: 700,
}));

export const MetaWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid rgba(224, 224, 224, 1)',
  padding: '6px 4px 6px 20px',
  borderTop: 0,
  background: '#ebeaff',
}));

export const TemplateNameText = styled(Typography)({
  fontSize: '22px',
  fontWeight: '900',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginRight: 16,
  maxWidth: '800px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  cursor: 'default',
});

export const TemplateStatusChip = styled(Chip)(() => ({
  background: 'rgba(235, 234, 255, 1) !important',
  color: '#4E48FA !important',
  height: '30px',
  padding: '4px 14px',
  border: '1px solid rgba(204, 203, 255, 1)',
  gap: 8,
  marginRight: 24,
  '& .MuiChip-icon': {
    margin: 0,
  },
  '& .MuiChip-label': {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '22px',
    padding: 0,
  },
  '& svg': {
    fontSize: '15px !important',
    color: '#4E48FA !important',
  },
}));

export const TemplateOwnerWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const TemplateOwnerText = styled(Typography)({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.17px',
  color: 'rgba(97, 97, 97, 1)',
  marginRight: 4,
});

export const AllUsersText = styled(Typography)({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.17px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const NavWrapper = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
}));
