// React
import { useEffect, useState, Fragment, useCallback } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import Grid from 'pages/Schedules/AllSchedules/Grid';
import Header from 'pages/Schedules/AllSchedules/Header';
import EmptyState from './EmptyState';
import EmptyResultsFilter from '../common/EmptyResultsFilter';

// MUI
import { CircularProgress } from '@mui/material';

// Styles
import { MySchedulesContainer, LoadingContainer } from './styled';

// Utilities
import { getOverdueSchedules, getAllSchedules } from 'api/schedulesApi';
import { debounce } from 'lodash';
import DateTime from 'utils/DateTime';

// Context
import { MySchedulesCtx } from './context';
import { formatFilters } from 'utils/Task/filters';

// Constants
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import { useUserConfig } from 'controller/useUserConfig';

function AllSchedules() {
  const limit = 100;
  const tz: string = useSelector(selectors.getUserHotelTz) || DEFAULT_TIMEZONE;
  const [data, setData] = useState<any>(null);
  const [overDueTasks, setOverDueTasks] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [advanceFilters, setAdvanceFilters] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [configKey, setConfigKey] = useState<any>(null);
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const [initialConfigLoad, setInitialConfigLoad] = useState(false);

  useEffect(() => {
    if (userId) {
      setConfigKey(`agendaView::${userId}`);
    }
  }, [userId]);

  const getAgendaViewConfig = async () => {
    const userAgendaViewConfig = await getUserConfig(configKey);

    setAdvanceFilters(userAgendaViewConfig?.filters || null);

    setTimeout(() => {
      setInitialConfigLoad(true);
    }, 1000);
  };

  useEffect(() => {
    if (configKey) {
      getAgendaViewConfig();
    }
  }, [configKey]);

  const searchTermApiCall = useCallback(
    debounce(
      (value) => {
        fetchData(value);
      },
      1000,
      {
        trailing: true,
        leading: false,
      },
    ),
    [],
  );

  const handleChangeSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    searchTermApiCall(value);
  };

  const fetchData = async (searchText = '') => {
    setLoading(true);
    let overDueResult = {
      count: 0,
      rows: [],
    };
    if (!advanceFilters) {
      overDueResult = await getOverdueSchedules({
        advanceFilters: {
          condition: 'AND',
          filters: [
            {
              comparator: 'is',
              filterName: 'dueDate',
              value: [
                new DateTime()
                  .toTz(tz)
                  .transform({ startOf: 'day' })
                  .toJSDate(),
                new DateTime().toTz(tz).transform({ endOf: 'day' }).toJSDate(),
              ],
            },
          ],
        },
        searchText,
        offset: 0,
        limit: 9999999999,
        allSchedules: true,
      });
    }

    await editUserConfig(`agendaView::${userId}`, {
      filters: advanceFilters,
    });

    const result = await getAllSchedules({
      advanceFilters: advanceFilters ? formatFilters(advanceFilters) : null,
      searchText,
      limit,
      sort: ['dueDate', 'ASC'],
    });
    setOverDueTasks(overDueResult?.rows);
    setData(result?.rows);
    setTotalCount(result?.count);
    setLoading(false);
    setNoResults(searchText || advanceFilters ? result?.count === 0 : false);
  };

  const handleLoadNextPage = async (offset: number) => {
    const paginationData = {
      offset,
      limit,
    };
    setIsNextPageLoading(true);
    const postData = {
      advanceFilters: advanceFilters ? formatFilters(advanceFilters) : null,
      ...(searchTerm && { searchText: searchTerm }),
      ...paginationData,
      sort: ['dueDate', 'ASC'],
    };
    const result = await getAllSchedules(postData);

    const prevData = data ?? [];
    const newRows = result?.rows ?? [];
    setData([...prevData, ...newRows]);
    setTotalCount(result?.count);
    setIsNextPageLoading(false);
  };

  useEffect(() => {
    if (initialConfigLoad) {
      fetchData();
    }
  }, [advanceFilters, initialConfigLoad, configKey]);

  const contextValue = {
    data,
    loading,
    advanceFilters,
    searchTerm,
    setAdvanceFilters,
    setSearchTerm,
    setData,
    fetchData,
    handleChangeSearch,
  };

  return (
    <MySchedulesCtx.Provider value={contextValue}>
      <Header createTaskCallback={() => fetchData()} />
      <Fragment>
        {loading && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
        {data !== null && overDueTasks !== null && !loading && (
          <Fragment>
            {data?.length === 0 && overDueTasks?.length === 0 ? (
              <Fragment>
                {noResults ? <EmptyResultsFilter /> : <EmptyState />}
              </Fragment>
            ) : (
              <MySchedulesContainer>
                <Grid
                  handleLoadNextPage={handleLoadNextPage}
                  totalOverDueTasks={totalCount}
                  isNextPageLoading={isNextPageLoading}
                  overDueTasks={overDueTasks}
                  tasks={data}
                  advanceFilters={advanceFilters}
                  fetchData={fetchData}
                />
              </MySchedulesContainer>
            )}
          </Fragment>
        )}
      </Fragment>
    </MySchedulesCtx.Provider>
  );
}

export default AllSchedules;
