import { FC, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// MUI
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import GooglePlayBadge from 'public/assets/img/google-play-badge.svg';
import AppStoreBadge from 'public/assets/img/app-store-badge.svg';
import Confetti from './confetti.svg';
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded';

// Third party
import { Image } from 'react-bootstrap';

// Styles
import { Container } from './style';

// Constants
import config from 'config/index';
import appConstants from 'utils/appConstants';

// Hooks
import { useTemplateAccess } from 'controller/useTemplateAccess';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';

interface IProps {
  dialogId: string;
  checklist: TemplateType;
}

const ChecklistPublishSuccessModal: FC<IProps> = (props) => {
  const dialogState = useSelector(selectors.getDialogState);
  const { dialogId, checklist } = props;
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { isTemplateAdmin } = useTemplateAccess();

  const [showQR, setShowQR] = useState(false);

  const handleGoToTemplate = () => {
    dispatch(actions.closeDialog(dialogId));
    if (isTemplateAdmin(checklist)) {
      navigateWithWorkspaceUrl(`/templates/${checklist?.id}`);
      return;
    }
    navigateWithWorkspaceUrl(`/checklist`);
    dispatch(actions.setPreviewChecklistData(checklist));

    dispatch(
      actions.setPreviewChecklistModalConfig({
        open: true,
        activeTab: 'preview',
        checklistScope: 'myTemplates',
      }),
    );
  };

  return (
    <Dialog maxWidth="sm" open={dialogState?.[dialogId]?.open}>
      <Container>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(actions.closeDialog(dialogId));
            setTimeout(() => {
              setShowQR(false);
            }, 500);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        {showQR ? (
          <div className="content">
            <h3>Open Template on Mobile App</h3>
            <p>
              Scan the QR code below and go to the “
              {appConstants.checklist.singular.base}” Tab.
            </p>
            <img
              src={`/assets/img/${
                config.REACT_APP_BASE_URL?.includes('api.xenia.team')
                  ? 'prod'
                  : 'beta'
              }-qr-code.svg`}
              alt="Xenia QR code"
              className="qr-code"
            />
            <div className="d-flex justify-content-center">
              <div role="button" style={{ marginRight: '16px' }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.xenia.xeniaApp&hl=en_US&gl=US"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={GooglePlayBadge} alt="google play badge" />
                </a>
              </div>
              <div role="button">
                <a
                  href="https://apps.apple.com/us/app/xenia-team/id1605760701"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={AppStoreBadge} alt="app store badge" />
                </a>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <Image src={Confetti} />
            </div>
            <div className="content">
              <h3>Congratulations! Template Published</h3>
              <p>Your template has been published and is available for use.</p>
              <div>
                <Button
                  variant="outlined"
                  className="sales-btn"
                  onClick={handleGoToTemplate}
                >
                  Go to template
                </Button>
                <Button
                  startIcon={<PhoneIphoneRoundedIcon />}
                  variant="contained"
                  className="sales-btn"
                  onClick={() => {
                    setShowQR(true);
                  }}
                >
                  Open on Mobile App
                </Button>
              </div>
            </div>
          </>
        )}
      </Container>
    </Dialog>
  );
};

export default ChecklistPublishSuccessModal;
