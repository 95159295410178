import { createSvgIcon } from '@mui/material';

const PlaneFlyingIcon = ({ style = {}, ...rest }) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M17.9959 1.21461C17.6661 1.10443 17.3591 1.18411 17.2064 1.22845C17.04 1.27677 16.8418 1.35418 16.6428 1.43185L2.51193 6.94635C2.28957 7.03309 2.0742 7.11711 1.90797 7.1993C1.76433 7.27032 1.45905 7.42839 1.28865 7.7564C1.10073 8.11812 1.10098 8.54874 1.28933 8.91024C1.46012 9.23805 1.76558 9.39576 1.9093 9.46661C2.07562 9.5486 2.29105 9.63235 2.51351 9.71883L6.44686 11.2485C6.74029 11.3626 6.88701 11.4196 7.03297 11.4231C7.16199 11.4262 7.28996 11.3993 7.4068 11.3445C7.53899 11.2825 7.6503 11.1712 7.87293 10.9486L11.9108 6.91077C12.2362 6.58533 12.7638 6.58533 13.0893 6.91077C13.4147 7.2362 13.4147 7.76384 13.0893 8.08928L9.05144 12.1271C8.82881 12.3497 8.7175 12.4611 8.65551 12.5932C8.60071 12.7101 8.57381 12.8381 8.5769 12.9671C8.58041 13.113 8.63746 13.2597 8.75158 13.5532L10.2812 17.4865C10.3677 17.7089 10.4514 17.9244 10.5334 18.0907C10.6043 18.2345 10.762 18.5399 11.0898 18.7107C11.4513 18.8991 11.8819 18.8993 12.2436 18.7114C12.5716 18.541 12.7297 18.2357 12.8007 18.0921C12.8829 17.9258 12.9669 17.7105 13.0537 17.4882L18.5682 3.3572C18.6459 3.15827 18.7233 2.96001 18.7716 2.79364C18.8159 2.64099 18.8956 2.33394 18.7854 2.00413C18.661 1.63151 18.3685 1.33909 17.9959 1.21461Z"
        fill="currentColor"
      />
    </>,
    'PlaneFlyingIcon',
  );
  return (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 20 20'}
      {...rest}
    />
  );
};
export default PlaneFlyingIcon;
