import { Box, Stack, Typography } from '@mui/material';
import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const UpcomingServiceBannerCnt = styled(Stack)({
  borderRadius: 6,
  padding: '10px 15px 10px 15px',
  gap: 8,
  backgroundColor: '#E1F5FE',
  marginBottom: 10,

  '& .icon': {
    height: 16,
    width: 16,
  },

  '& .info': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',
  },

  '& .viewDetails': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '17.76px',
    textDecoration: 'underline',
  },
});

export const NextServiceDateCnt = styled(Box)({
  '& .label': {
    margin: 0,
    marginRight: 18,
    fontSize: 14,
    fontWeight: 700,
    color: 'rgb(97, 97, 97)',
  },

  '& .innerCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',

    '& .dateTime': {
      fontWeight: 400,
      fontSize: 14,
      color: '#424242',
    },
  },
});

export const LastServiceDateCnt = styled(Box)({
  '& .label': {
    margin: 0,
    marginRight: 18,
    fontSize: 14,
    fontWeight: 700,
    color: 'rgb(97, 97, 97)',
  },

  '& .innerCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',

    '& .dateTime': {
      fontWeight: 400,
      fontSize: 14,
      color: '#424242',
    },
  },
});

export const ReminderCnt = styled(Box)({
  padding: '6px 8px 6px 8px',
  borderRadius: '100px',
  gap: '6px',
  border: '1px solid #E0E0E0',
  height: 30,
  width: 'fit-content',
  backgroundColor: 'transparent',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ReminderInfo = styled(Typography)({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16.39px',
  letterSpacing: '0.17px',
  color: '#000000',
});

export const PhotosCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 10,
  '& .imgCnt': {
    position: 'relative',
    '& .imgView': {
      width: 150,
      height: 100,
      objectFit: 'cover',
      borderRadius: 8,
      cursor: 'pointer',
    },
    '& .download': {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      right: 0,
      boxShadow: '0px -1px 5px rgb(0 0 0 / 10%)',
      '& svg': {
        fontSize: 17,
        '& path': {
          fill: colors.black,
          fillOpacity: 1,
        },
        '&:hover': {
          transform: 'scale(1.1)',
          '& path': {
            fill: colors.white,
          },
        },
      },
    },
  },
});

export const FilesCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 10,
  '& .fileInnerCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    width: 157,
    height: 40,
    border: '1px solid #EEEEEE',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.04)',
    borderRadius: 8,
    padding: '4px 8px',
    position: 'relative',
    '& .download': {
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      right: 5,
      transform: 'translateY(-50%)',
      '& svg': {
        fontSize: 14,
        '& path': {
          fill: colors.black,
          fillOpacity: 0.6,
        },
      },
    },
    '& .fileDetailsCnt': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
      marginLeft: 10,
      '& .fileName': {
        lineHeight: '20.02px',
        fontSize: 14,
        color: '#424242',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 80,
        whiteSpace: 'nowrap',
        display: 'block',
      },
    },
  },
});

export const FileIconCnt = styled('div')({
  width: 18,
});

export const AssetDetailTabCnt = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 'calc(100vh - 244px)',
  [theme.breakpoints.down('sm')]: {
    height: 430,
  },
  paddingRight: 5,
  overflowY: 'auto',

  '.assetDetailTabInnerCnt': {
    width: 630,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: 'unset',
    },
    '.saveButton': {
      alignSelf: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '.editButton': {
    position: 'absolute',
    right: 5,
    top: 0,
    '.editIcon': {
      fontSize: 18,
      color: '#6868FE',
    },
  },
}));

export const AssetDetailsCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,

  '& .title': {
    fontSize: 14,
    fontWeight: 700,
    color: 'rgb(97, 97, 97)',
  },
});
export const ActionBtnCnt = styled('div')({
  textAlign: 'right',
  marginTop: 12,
});
export const LocationNameValueCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const QRControlWithLabel = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  '& label': {
    fontSize: 13,
    fontWeight: 400,
    color: '#424242',
  },
});
