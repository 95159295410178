import styled from '@mui/system/styled';

export const EditorCnt = styled('div')({
  '& .tox-tbtn': {
    minWidth: '34px !important',
    minHeight: '38px !important',
    width: 'unset !important',
    margin: '0px !important',
  },

  '& .tox-toolbar__primary': {
    backgroundImage: 'unset !important',
  },
});
