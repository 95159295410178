import { useMemo } from 'react';
import { Typography } from '@mui/material';
import useDateTime from 'utils/CustomHooks/useDateTime';
import ReminderIcon from 'components/Icons/reminderIcon';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';

import {
  NextServiceDateCnt,
  ReminderCnt,
  ReminderInfo,
} from './assetDetailTab.style';

interface IProps {
  nextService?: any;
}

const NextService = (props: IProps) => {
  const { nextService } = props;
  const { getUtcToTz } = useDateTime();
  const { addNewAssignees } = useAssigneesUtils();

  const nextServiceDateTime = useMemo(() => {
    if (nextService?.date) {
      const TZDateTime = getUtcToTz(nextService?.date, 'MMM DD, YYYY  h:mm A');
      return TZDateTime;
    }
  }, [nextService?.date]);

  const notifiers = useMemo(() => {
    if (nextService?.reminder?.recipients?.length > 0) {
      return addNewAssignees(nextService?.reminder?.recipients);
    }
  }, [nextService?.reminder?.recipients]);

  const isReminderExists = useMemo(() => {
    const { reminder } = nextService || {};
    return (
      reminder?.time?.value &&
      reminder?.time?.unit &&
      reminder?.recipients?.length > 0
    );
  }, [nextService]);

  return (
    <NextServiceDateCnt>
      <div className="label">Next Service Date</div>
      <div className="innerCnt">
        <Typography className="dateTime">
          {nextServiceDateTime || '-'}
        </Typography>

        {isReminderExists && (
          <ReminderCnt>
            <ReminderIcon
              style={{ height: 17, width: 17, fill: 'none' }}
              sx={{
                '& path': {
                  stroke: '#6868FE',
                },
              }}
            />

            <ReminderInfo>
              {nextService?.reminder?.time?.value}{' '}
              {nextService?.reminder?.time?.unit}s before service date
            </ReminderInfo>

            <AssigneesView
              type="avatars"
              data={notifiers}
              assigneesProps={{
                height: 20,
                width: 20,
                popperProps: {
                  sx: {
                    zIndex: 1700,
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'white !important',
                      boxShadow:
                        'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                      minWidth: 300,
                      maxHeight: 300,
                      overflowY: 'auto',
                      borderRadius: '15px',
                    },
                  },
                },
              }}
            />
          </ReminderCnt>
        )}
      </div>
    </NextServiceDateCnt>
  );
};

export default NextService;
