import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { find } from 'lodash';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import CustomButton from 'components/Button/CustomButton';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { useSnackbar } from 'notistack';
import { updateProfileApi } from 'api/userApi';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import {
  CTypographyProfile,
  LabelFieldProfile,
  LocationBtnCnt,
  LocationsActionBtn,
  LocationsActionWrapper,
  SelectedText,
} from 'pages/my-settings/styles';
import PhoneEmailInput from 'components/Dropdown/PhoneCodeInput';
import { getCountryCode, getNumberWithoutCode } from '../../helper/phoneNumber';
import actions from 'store/actions';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import selectors from 'store/selectors';
import { updateDefaultLocation } from 'store/actions/workspaceActions';

const MyProfileForm = ({ userProfileData, picCdnPath, setPicCdnPath }) => {
  const { workspaceId } = useWorkspaceHook();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { addNewAssignees } = useAssigneesUtils();
  const locationsHash = useSelector(selectors.getLocationsHash);
  const [selectedDefaultLocation, setSelectedDefaultLocation] =
    useState<any>(null);
  const {
    fullName,
    emailId,
    phoneNo,
    id: userId,
    UserHotels,
  } = userProfileData;

  const currentUserHotel = find(
    UserHotels,
    (userHotel) => userHotel.Hotel.id === workspaceId,
  );

  const { title, defaultLocationId, pin } = currentUserHotel || {};
  const dispatch = useDispatch();
  const formikRef = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [phoneCode, setPhoneCode] = useState<any>(null);
  const selectedPhoneCode = phoneNo && getCountryCode(phoneNo);
  const phoneNumberWithoutCode = phoneNo && getNumberWithoutCode(phoneNo);
  const initialValues = {
    fullName: fullName,
    phone: phoneNumberWithoutCode,
    email: emailId,
    position: title,
    defaultLocationId: defaultLocationId,
  };

  const validationSchemaModel = Yup.object({
    fullName: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please provide valid full name (first & last)')
      .nullable(),
    phone: Yup.string()
      .matches(/^[+]*[0-9]{5,14}$/, 'Please enter valid phone number')
      .nullable(),
    email: Yup.string()
      .email('Please provide valid email address')
      .nullable()
      .test('require phone or email', function (email) {
        const phone = this.resolve(Yup.ref('phone'));
        if (!email && !phone) {
          return this.createError({
            message: `Please provide valid email address or phone`,
            path: 'email',
          });
        } else return true;
      }),
    position: Yup.string().nullable(),
    defaultLocationId: Yup.string().nullable(),
  });

  const handleSubmit = async (values, resetForm) => {
    const postObj = {
      ...values,
      phone: values?.phone ? phoneCode?.obj?.dial_code + values?.phone : null,
    };

    if (!postObj?.defaultLocationId) {
      delete postObj?.defaultLocationId;
    }

    const response = await updateProfileApi(userId, postObj, picCdnPath);
    if (response) {
      setPicCdnPath('');
      resetForm({
        values: {
          fullName: values.fullName,
          phone: values.phone,
          email: values.email,
          position: values.position,
          defaultLocationId: values?.defaultLocationId,
        },
      });

      if (postObj?.defaultLocationId) {
        dispatch(updateDefaultLocation(postObj?.defaultLocationId));
      }
    }
    dispatch(actions.reloadUsers());
  };
  const handlePhoneCodeSelect = (value) => {
    setPhoneCode(value);
  };

  useEffect(() => {
    if (defaultLocationId) {
      setSelectedDefaultLocation(defaultLocationId);
    }
  }, [defaultLocationId]);

  const locationsUpdateHandler = (data) => {
    if (data?.currentSelected != selectedDefaultLocation) {
      formikRef.current.values.defaultLocationId = data?.currentSelected;
      setSelectedDefaultLocation(data?.currentSelected);
    }
  };

  const locationsSelectedText = useMemo(() => {
    if (selectedDefaultLocation) {
      const assignedLocation = addNewAssignees([selectedDefaultLocation]);
      return assignedLocation?.[0]?.Location?.name ?? '';
    }
    return '';
  }, [selectedDefaultLocation, locationsHash]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaModel}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
      onSubmit={async (values, formikHelpers) => {
        const { setSubmitting, resetForm } = formikHelpers;
        await handleSubmit(values, resetForm);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { touched, values, errors, getFieldProps, dirty, isSubmitting } =
          props;
        return (
          <Form autoComplete="off">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <CTypographyProfile>Profile Details</CTypographyProfile>
                <Divider sx={{ pt: 2, mb: 3 }} />
              </Grid>
              <Grid item xs={3}>
                <LabelFieldProfile htmlFor="fullName">
                  Full Name
                </LabelFieldProfile>
              </Grid>
              <Grid item xs={9}>
                <Field name="fullName">
                  {(props) => {
                    return (
                      <CustomInput
                        suppressErrorSpace={true}
                        error={touched.fullName && errors.fullName}
                        sx={{ mb: '10px' }}
                        fieldProps={{
                          ...props.field,
                          sx: { width: 320 },
                          ...getFieldProps('fullName'),
                        }}
                      />
                    );
                  }}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <LabelFieldProfile htmlFor="phone">Phone</LabelFieldProfile>
              </Grid>
              <Grid item xs={9}>
                <Field name="phone">
                  {(props) => (
                    <PhoneEmailInput
                      suppressEmail={true}
                      error={touched.phone && errors.phone}
                      onPhoneCodeSelect={handlePhoneCodeSelect}
                      selectedCode={selectedPhoneCode}
                      formControlProps={{
                        suppressErrorSpace: true,
                        sx: { mb: '10px' },
                      }}
                      fieldProps={{
                        ...props.field,
                        sx: { width: 320 },
                        ...getFieldProps('phone'),
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <LabelFieldProfile>Email</LabelFieldProfile>
              </Grid>
              <Grid item xs={9}>
                <Field name="email">
                  {(props) => (
                    <CustomInput
                      suppressErrorSpace={true}
                      error={touched.email && errors.email}
                      sx={{ mb: '10px' }}
                      fieldProps={{
                        ...props.field,
                        sx: { width: 320 },
                        placeholder: 'Enter email address',
                        ...getFieldProps('email'),
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <LabelFieldProfile>Position</LabelFieldProfile>
              </Grid>
              <Grid item xs={9}>
                <Field name="position">
                  {(props) => (
                    <CustomInput
                      suppressErrorSpace={true}
                      error={touched.position && errors.position}
                      sx={{ mb: '10px' }}
                      fieldProps={{
                        ...props.field,
                        sx: { width: 320 },
                        placeholder: 'Enter your position',
                        ...getFieldProps('position'),
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <LabelFieldProfile>Default Location</LabelFieldProfile>
              </Grid>
              <Grid item xs={9}>
                <Field name="defaultlocation">
                  {() => (
                    <AssigneeDropdown
                      handleChangeCallback={(data) =>
                        locationsUpdateHandler(data)
                      }
                      buttonRendererProps={{
                        width: '100%',
                      }}
                      buttonRenderer={
                        locationsSelectedText ? (
                          <LocationBtnCnt>
                            <SelectedText>{locationsSelectedText}</SelectedText>
                            <ArrowDropDownIcon
                              style={{
                                fontSize: 24,
                                color: 'rgba(0, 0, 0, 0.54)',
                              }}
                            />
                          </LocationBtnCnt>
                        ) : (
                          <LocationBtnCnt>
                            <Typography className="text">
                              Select location
                            </Typography>
                            <ArrowDropDownIcon
                              style={{
                                fontSize: 24,
                                color: 'rgba(0, 0, 0, 0.54)',
                              }}
                            />
                          </LocationBtnCnt>
                        )
                      }
                      showTabs={false}
                      listProps={{
                        locations: {
                          showInlineSelection: true,
                          isSearchable: true,
                          isMulti: true,
                          isSelectAll: false,
                          hideActions: false,
                          selected: selectedDefaultLocation
                            ? [selectedDefaultLocation]
                            : [],
                          footer: (
                            <LocationsActionWrapper>
                              <Stack
                                direction="row"
                                alignItems="center"
                                onClick={() => {
                                  navigateWithWorkspaceUrl('/locations');
                                }}
                              >
                                <LocationsActionBtn>
                                  Go to Locations
                                </LocationsActionBtn>
                                <ArrowForwardIosIcon
                                  sx={{
                                    color: '#6868FE',
                                    cursor: 'pointer',
                                    height: 15,
                                    width: 15,
                                  }}
                                />
                              </Stack>
                            </LocationsActionWrapper>
                          ),
                        },
                      }}
                      displayOptions={{
                        locations: true,
                      }}
                      popperProps={{
                        disablePortal: false,
                      }}
                      allSelected={false}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <LabelFieldProfile style={{ marginTop: 10 }}>
                  PIN Code
                </LabelFieldProfile>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    lineHeight: '20.02px',
                    letterSpacing: '0.17px',
                    marginTop: 18,
                  }}
                >
                  {pin || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ my: 2 }}>
                {(dirty ||
                  picCdnPath !== '' ||
                  defaultLocationId != selectedDefaultLocation) && (
                  <CustomButton
                    variant="contained"
                    type="submit"
                    startIcon={<DoneIcon />}
                    buttonLoader={isSubmitting}
                  >
                    Save Changes
                  </CustomButton>
                )}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MyProfileForm;
