import styled from '@mui/system/styled';

export const ErrorStateCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  height: '100%',
  '& svg': {
    color: '#90A4AE',
    fontSize: 46,
    filter: 'grayscale(1)',
  },
});
export const ErrorStateText = styled('p')({
  margin: 0,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '24.59px',
  marginTop: 12,
  marginBottom: 14,
});
export const ErrorStateHeading = styled('p')({
  margin: 0,
  fontSize: 18,
  fontWeight: 700,
  lineHeight: '24.59px',
  marginTop: 12,
});
