import React, { useMemo } from 'react';

import ChecklistIconTemplate from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistIcon';
import ArrowInCircleIcon from 'components/Icons/arrowInCircleIcon';
import { Stack, Button, Typography } from '@mui/material';

import Chip from './Chip';
import useDateTime from 'utils/CustomHooks/useDateTime';

interface TileProps {
  row: any;
  onClickContinue: (checklistId: string, rowId: string) => void;
  style: any;
  [key: string]: any;
}

const Tile: React.FC<TileProps> = (props) => {
  const {
    row,
    onClickContinue,
    style,
    numCols,
    rowIndex,
    columnIndex,
    numRows,
  } = props;
  const { getDateInTz } = useDateTime();

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  if (!row) return <></>;

  const timeText = useMemo(() => {
    const str = getDateInTz(row.updatedAt).fromNow();
    return str === 'a few seconds ago' ? 'just now' : str;
  }, [row]);

  return (
    <Stack
      p="16px"
      borderRadius={'12px'}
      border="1px solid #E0E0E0"
      bgcolor={'#fff'}
      direction={'row'}
      key={row?.id}
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation}
      position={'relative'}
      sx={{
        '&:hover': {
          border: '2px solid #6868FE',
          padding: '15px',
          cursor: 'pointer',
          '& .continue-overlay': {
            display: 'flex',
          },
        },
      }}
      style={{
        ...style,
        height: '82px',
        width:
          columnIndex === numCols - 1
            ? `${Number(style.width)}px`
            : `${Number(style.width) - 16}px`,
      }}
    >
      <Stack
        position={'absolute'}
        bgcolor={'rgba(0, 0, 0, 0.5)'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          backdropFilter: 'blur(1px)',
        }}
        height="100%"
        width="100%"
        top={0}
        left={0}
        borderRadius={'10px'}
        className={'continue-overlay'}
        display={'none'}
      >
        <Button
          onClick={() => onClickContinue(row.ChecklistId, row.id)}
          variant="contained"
          startIcon={
            <ArrowInCircleIcon
              sx={{
                fontSize: '16px',
                '& path': {
                  stroke: '#FFF',
                  strokeOpacity: 1,
                },
              }}
            />
          }
        >
          Continue Submission
        </Button>
      </Stack>
      <Stack width={'100%'} gap={'12px'} direction="row" className={'content'}>
        <ChecklistIconTemplate icon={row.icon} style={{ minWidth: 24 }} />
        <Stack width="85%" gap="6px">
          <Typography
            width="100%"
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            fontSize={'14px'}
            fontWeight={'700'}
            color="#000"
            whiteSpace={'pre'}
          >
            {row.name}
          </Typography>
          <Stack overflow={'hidden'} direction="row" gap="6px">
            <Chip
              text={`${row.progress.completedCount}/${row.progress.count} items`}
            />
            <Chip text={timeText} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Tile;
