import { createSvgIcon } from '@mui/material';

const ColorPickerIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M1.66602 9.99984C1.66602 14.6022 5.39698 18.3332 9.99935 18.3332C11.3801 18.3332 12.4993 17.2139 12.4993 15.8332V15.4165C12.4993 15.0295 12.4993 14.836 12.5207 14.6735C12.6684 13.5517 13.5512 12.6689 14.673 12.5212C14.8355 12.4998 15.029 12.4998 15.416 12.4998H15.8327C17.2134 12.4998 18.3327 11.3805 18.3327 9.99984C18.3327 5.39746 14.6017 1.6665 9.99935 1.6665C5.39698 1.6665 1.66602 5.39746 1.66602 9.99984Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83268 10.8332C6.29292 10.8332 6.66602 10.4601 6.66602 9.99984C6.66602 9.5396 6.29292 9.1665 5.83268 9.1665C5.37244 9.1665 4.99935 9.5396 4.99935 9.99984C4.99935 10.4601 5.37244 10.8332 5.83268 10.8332Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3327 7.49984C13.7929 7.49984 14.166 7.12674 14.166 6.6665C14.166 6.20627 13.7929 5.83317 13.3327 5.83317C12.8724 5.83317 12.4993 6.20627 12.4993 6.6665C12.4993 7.12674 12.8724 7.49984 13.3327 7.49984Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33268 6.6665C8.79292 6.6665 9.16602 6.29341 9.16602 5.83317C9.16602 5.37293 8.79292 4.99984 8.33268 4.99984C7.87244 4.99984 7.49935 5.37293 7.49935 5.83317C7.49935 6.29341 7.87244 6.6665 8.33268 6.6665Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ColorPickerIcon',
  );
  return <IconCmp width="20" height="20" viewBox="0 0 20 20" {...props} />;
};
export default ColorPickerIcon;
