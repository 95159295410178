import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const AddContentCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  cursor: 'pointer',
  backgroundColor: '#F0F0FF',
  paddingLeft: 24,
  '& svg': {
    marginRight: 10,
    color: colors.white,
    background: colors.primary.main,
    borderRadius: '50%',
    padding: 3,
  },
  '& .content': {
    fontSize: 14,
    fontWeight: 500,
    color: colors.primary.main,
  },
});
export const CreateCategory = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  padding: '0px 5px 0px 24px',
  '& .inputCnt': {
    '& input': {
      fontSize: 15,
      padding: '10.5px 14px 10.5px 11px',
    },
  },
  '& .addBtn': {
    width: 70,
    height: 30,
    fontWeight: 500,
    fontSize: 13,
  },
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 13,
    color: colors.primary.main,
  },
  borderBottom: `1px solid ${colors.primary.main}`,
});
export const TitleCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'inProgress',
})(({ theme, inProgress }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  opacity: inProgress ? 0.5 : 1,
  '& .rowContent': {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 10,
    color: colors.black,
  },
}));

export const CreatedByCnt = styled('div')({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  '& .userIcon': {
    fontWeight: 600,
  },
  '& .rowContent': {
    fontSize: 13,
    fontWeight: 400,
    marginLeft: 10,
    color: '#252350',
  },
});

export const CellEditorCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  marginLeft: 15,
  '& .inputCnt': {
    '& input': {
      padding: '10.5px 14px 10.5px 11px',
      fontSize: 14,
    },
  },
});
