import styled from '@mui/system/styled';

export const TileCnt = styled('div')({
  border: '1px solid transparent',
  display: 'block',
  '&:hover': {
    border: '1px solid #6868FE',
    borderRadius: 13,
    '& .dragIcon': {
      display: 'block',
    },
  },
  '& .dragIcon': {
    display: 'none',
    position: 'absolute',
    top: 28,
    left: 4,
    color: 'gray',
    fontSize: 22,
    cursor: 'grab',
    '&:hover': {
      color: 'mediumslateblue',
    },
  },
});
