import React, { FC, useEffect, useState } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import XeniaLogoSymbol from 'public/assets/img/xenia-logo-symbol.svg';
import CustomButton from 'components/Button/CustomButton';
import xeniaApi from 'api/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import {
  AbstractBackgroundCnt,
  WSCreateContainer,
  WSCreateHeader,
} from 'pages/CreateWorkspace/style';

import CreateWorkspaceLeftNav from './LeftNav';
import WSColorsDropdown from './WSColorsDropdown';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';

const CreateWorkspace: FC = () => {
  const { workspaceId, navigateToOtherWorkspace, navigateWithWorkspaceUrl } =
    useWorkspaceHook();
  const [color, setColor] = useState('#C7ADFE');
  const [name, setName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();
  const { hasPermission } = useCustomPermission();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_WORKSPACES)) {
      navigateWithWorkspaceUrl('/dashboard');
      return;
    }
  }, []);

  const handleInputChange = (e) => {
    setName(e?.target?.value || '');
  };

  const handleWorkspaceCreate = async () => {
    setIsCreating(true);
    if (!name?.length) return;
    const resp = await xeniaApi.createWorkspace({
      name,
      color,
      hotelId: workspaceId,
    });
    if (resp) {
      dispatch(actions.removeRoles({}));
      navigateToOtherWorkspace({
        id: resp.id,
        url: '/invites-users',
        params: { replace: true },
      });
    }
    setIsCreating(false);
  };

  const workspaceForm = (
    <div style={{ width: 'calc(100% - 297px)' }}>
      <WSCreateHeader>
        <img
          style={{ marginTop: '60px' }}
          src={XeniaLogoSymbol}
          width={70}
          height={40}
        />
      </WSCreateHeader>
      <div style={{ paddingLeft: '15%', paddingTop: '85px', maxWidth: '70%' }}>
        <div className="mainTitle">Name your Workspace</div>
        <div className="subtitle">Enter the name of your workspace</div>
        <div style={{ height: '40px' }} />
        <CustomInput
          label="Workspace Title"
          error={''}
          muiLabel={true}
          size="medium"
          helpText={'Your workspace name can be your company or organization'}
          fieldProps={{
            onChange: handleInputChange,
            autoComplete: 'off',
            name: 'workspace',
            type: 'text',
            placeholder: 'Dev Team',
            value: name,
            sx: { height: 54, maxWidth: 500 },
            endAdornment: (
              <div style={{ marginRight: 8 }}>
                <WSColorsDropdown setColor={setColor} color={color} />
              </div>
            ),
          }}
        />
        <CustomButton
          variant="contained"
          onClick={handleWorkspaceCreate}
          sx={{ width: 320, borderRadius: '8px' }}
          disabled={!name?.length}
          buttonLoader={isCreating}
        >
          Continue
        </CustomButton>
      </div>
    </div>
  );
  return hasPermission(PERMISSIONS.CAN_MANAGE_WORKSPACES) ? (
    <AbstractBackgroundCnt>
      <WSCreateContainer>
        <CreateWorkspaceLeftNav step={1} />
        {workspaceForm}
      </WSCreateContainer>
    </AbstractBackgroundCnt>
  ) : null;
};

export default CreateWorkspace;
