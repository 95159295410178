import styled from '@mui/system/styled';

export const CustomToolbarCnt = styled('div')({
  padding: '0 0 12px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .toolbarDate': {
    fontWeight: 600,
    fontSize: 20,
    marginRight: 16,
    display: 'inline-block',
  },
  '.calendarDayNavigateCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '.arrowsCnt': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '.arrowNextBtn': {
        transform: 'rotate(90deg)',
        padding: 0,
        '& svg': {
          fontSize: 28,
        },
      },
      '.arrowBackBtn': {
        transform: 'rotate(90deg)',
        padding: 0,
        '& svg': {
          fontSize: 28,
        },
      },
    },
    '.dateCnt': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '.day': {
        fontSize: 16,
        fontWeight: 400,
        color: '#616161',
        // ...(isToday && { color: '#6868fe' }),
      },
      '.dayNumber': {
        fontSize: 20,
        fontWeight: 700,
        color: '#000000DE',
        // ...(isToday && { color: '#6868fe' }),
      },
    },
  },
  '& .calendarNavigateCnt': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .arrowBtn': {
    padding: 0,
    '& svg': {
      fontSize: 28,
    },
  },
  '& .CalendarSettingCnt': {
    display: 'flex',
  },
});
