import styled from '@mui/system/styled';
export const WorkOrderHistoryCnt = styled('div')(({ theme }) => ({
  margin: '0 auto',
  overflowY: 'auto',
  paddingTop: 2,

  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const WorkOrderHistoryUpdateDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
  '.label': {
    fontWeight: 400,
    fontSize: 12,
    span: {
      marginLeft: 3,
    },
  },
  '.value': {
    fontWeight: 700,
    fontSize: 12,
    marginLeft: 3,
  },
}));

export const TimelineDate = styled('span')({
  fontSize: 14,
  fontWeight: 700,
  color: '#000000DE',
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  marginBottom: 8,
  marginTop: 14,
  display: 'block',
});

export const WorkOrderHistoryTile = styled('div')(({ theme }) => ({
  padding: '12px 16px',
  margin: '0px 1px 8px 1px',
  background: '#FFFFFF',
  boxShadow: '0px 8px 24px 0px rgba(149, 157, 165, 0.20)',
  borderRadius: 8,
  display: 'flex',
  '&:hover': {
    outline: '1px solid #6868FE',
    transition: 'ease outline 0.1s',
    cursor: 'pointer',
    '.workOrderTitle': {
      textDecoration: 'underline',
    },
  },
  '& .tileLeft': {
    flex: 1,
    '.tileLeftBottomCnt': {
      display: 'flex',
      alignItems: 'center',
      marginTop: 8,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    '.assetName': {
      fontSize: 14,
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '7px 0px',
      },
      '& .subAssetIcon': {
        color: '#757575',
        marginRight: '12px',
        fontSize: 16,
      },
    },
  },
  '& .tileRight': {
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
    },
  },
}));

export const AssigneeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& label': {
    fontSize: 13,
    fontWeight: 400,
    marginRight: 6,
    marginBottom: 0,
  },
});
export const TaskStatusChip = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color' || prop !== 'background',
})(({ theme, color, background }) => ({
  fontSize: 12,
  fontWeight: 700,
  padding: '6px 12px',
  marginTop: 8,
  borderRadius: 5,
  color,
  background,
}));
export const TaskCategoryChip = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color' || prop !== 'background',
})(({ theme, color, background }) => ({
  fontSize: 12,
  fontWeight: 400,
  padding: '6px 12px',
  borderRadius: 5,
  marginRight: 8,
  color,
  background,
}));

export const WorkOrderTitle = styled('div')({
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 8,
});
