// React
import { useMemo, useRef } from 'react';

// Components
import CustomInput from 'components/Form/TextField/TextField.cmp';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

const SearchBar = ({
  onSearch,
  width = '265px',
  searchTerm = '',
  setSearchTerm = undefined,
}: any) => {
  const showCross = useMemo(() => !!searchTerm, [searchTerm]);
  const inputRef = useRef<any>(null);

  const handleChange = (e) => {
    onSearch(e?.target?.value);
  };

  const onClickCancel = () => {
    setSearchTerm?.('');
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
      inputRef?.current?.focus();
    }
  };

  return (
    <CustomInput
      suppressErrorSpace={true}
      grayInput={true}
      fwdRef={inputRef}
      fieldProps={{
        onChange: handleChange,
        startAdornment: <SearchIcon />,
        ...(showCross && {
          endAdornment: (
            <CancelIcon
              onClick={onClickCancel}
              sx={{
                color: 'rgba(0,0,0,0.2)',
                fontSize: '18px',
                marginRight: '4px',
                cursor: 'pointer',
              }}
            />
          ),
        }),
        placeholder: 'Search',
        sx: {
          width,
        },
      }}
      label=""
    />
  );
};

export default SearchBar;
