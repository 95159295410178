import React from 'react';

// Custom components
import PassFail from 'components/TemplatePreview/Fields/PassFail';
import MultipleChoice from 'components/TemplatePreview/Fields/MultipleChoice';
import Procedure from 'components/TemplatePreview/Fields/Procedure';
import TakePhoto from 'components/TemplatePreview/Fields/TakePhoto';
import Number from 'components/TemplatePreview/Fields/Number';
import Text from 'components/TemplatePreview/Fields/Text';
import Signature from 'components/TemplatePreview/Fields/Signature';
import DateTime from 'components/TemplatePreview/Fields/DateTime';
import ItemAttachments from './ItemAttachments';
import Instruction from 'components/TemplatePreview/Fields/Instruction';
import Dropdown from 'components/TemplatePreview/Fields/Dropdown';
import GeoStamp from 'components/TemplatePreview/Fields/GeoStamp';
import Location from 'components/TemplatePreview/Fields/Location';
import PhotoAnnotate from 'components/TemplatePreview/Fields/PhotoAnnotate';

// Utilities
import { isEqual } from 'lodash';

import { Stack } from '@mui/material';

// Styled
import {
  NotesAndSectionWrapper,
  SectionAndAttachmentsWrapper,
  SectionWrapper,
  SectionName,
} from './styled';

const TemplateItem = ({
  item,
  isEmulatorView = false,
  onChangeCb,
}: {
  item: any;
  isEmulatorView?: boolean;
  onChangeCb?: (value: any) => void;
}) => {
  const getItem = (
    type:
      | 'header'
      | 'number'
      | 'passFail'
      | 'multipleChoice'
      | 'procedure'
      | 'textField'
      | 'takePhoto'
      | 'temperature'
      | 'cost'
      | 'signature'
      | 'dateTime'
      | 'instruction'
      | 'dropdown'
      | 'geoStamp'
      | 'location'
      | 'photoAnnotate',
    item: any,
  ) => {
    switch (type) {
      case 'header':
        return isEmulatorView ? (
          <Stack
            borderRadius={'4px'}
            direction={'row'}
            p="7px 9px"
            justifyContent={'space-between'}
            sx={{
              background: 'rgba(0, 125, 255, 1)',
            }}
            width="100%"
          >
            <SectionName isEmulatorView={isEmulatorView ? 'true' : ''}>
              {item.description}
            </SectionName>
          </Stack>
        ) : (
          <SectionName isEmulatorView={isEmulatorView ? 'true' : ''}>
            {item.description}
          </SectionName>
        );
      case 'passFail':
        return (
          <PassFail
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'multipleChoice':
        return (
          <MultipleChoice
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'procedure':
        return (
          <Procedure
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'takePhoto':
        return (
          <TakePhoto
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'number':
      case 'cost':
      case 'temperature':
        return (
          <Number
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'textField':
        return (
          <Text
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'signature':
        return (
          <Signature
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'dateTime':
        return (
          <DateTime
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'instruction':
        return <Instruction isEmulatorView={isEmulatorView} item={item} />;
      case 'dropdown':
        return (
          <Dropdown
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'geoStamp':
        return (
          <GeoStamp
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'location':
        return (
          <Location
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      case 'photoAnnotate':
        return (
          <PhotoAnnotate
            isEmulatorView={isEmulatorView}
            onChangeCb={onChangeCb}
            item={item}
          />
        );
      default:
        break;
    }
  };
  return (
    <NotesAndSectionWrapper>
      {item.type !== 'header' ? (
        <SectionAndAttachmentsWrapper>
          <SectionWrapper>{getItem(item.type, item)}</SectionWrapper>
          {item.attachments?.length > 0 && item.type !== 'photoAnnotate' && (
            <ItemAttachments
              isEmulatorView={isEmulatorView}
              attachments={item.attachments}
            />
          )}
        </SectionAndAttachmentsWrapper>
      ) : (
        <>{getItem(item.type, item)}</>
      )}
    </NotesAndSectionWrapper>
  );
};

export default React.memo(TemplateItem, (prevProps, nextProps) => {
  const prev = {
    item: prevProps.item,
    isEmulatorView: prevProps.isEmulatorView,
  };
  const next = {
    item: nextProps.item,
    isEmulatorView: nextProps.isEmulatorView,
  };
  return isEqual(prev, next);
});
