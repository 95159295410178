import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { ServiceHotelAdminModel } from 'models/index.m';
import { GetCategoriesListing } from 'pages/service-management/categories';
import LoaderAnimated from 'components/Loader/LoaderAnimated';
import { setDialog } from 'store/actions/dialogActions';
import useCategory from 'controller/useCategory';

import {
  SettingsMain,
  SettingsHeader,
  SettingsTitle,
} from 'components/common/styledComponents/settings.style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';

const ServiceManagement: FC = () => {
  const dispatch = useDispatch();
  const [request] = useState({
    page: 1,
    pageSize: 1000,
    searchText: '',
    sort: {
      createdAt: 'DESC',
    },
  });
  const isListLoading = useSelector(selectors.getServiceIsFetchingAdmin);
  const serviceList: ServiceHotelAdminModel[] | null = useSelector(
    selectors.getServiceAdmin,
  );
  const { updateHandler, removeHandler } = useCategory(request);

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasPermission } = useCustomPermission();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_CATEGORIES)) {
      navigateWithWorkspaceUrl('/dashboard');
      return;
    }
  }, []);

  const handleShowRemove = (
    currentRemoveServiceParam: ServiceHotelAdminModel,
  ) => {
    dispatch(
      setDialog({
        dialogId: 'confirmDeleteCategory',
        open: true,
        data: {
          content: 'Are you sure you want to delete this Category?',
          handleSave: () => {
            removeHandler({
              id: currentRemoveServiceParam?.id,
            });
          },
        },
      }),
    );
  };

  return hasPermission(PERMISSIONS.CAN_MANAGE_CATEGORIES) ? (
    <SettingsMain>
      <SettingsHeader style={{ display: 'flex', alignItems: 'center' }}>
        <SettingsTitle style={{ marginRight: 5 }}>Categories</SettingsTitle>
      </SettingsHeader>

      {!isListLoading && (
        <GetCategoriesListing
          serviceList={serviceList}
          handleShowRemove={handleShowRemove}
          updateHandler={updateHandler}
        />
      )}
      {isListLoading && <LoaderAnimated />}
    </SettingsMain>
  ) : null;
};

export default ServiceManagement;
