/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddFeed } from './addFeed';
import { ArchiveCard } from './ArchiveCard';

import selectors from 'store/selectors';

import { ConstantChannelTypeString } from 'utils/globalVariable';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ChannelMessagesRender } from 'components/common/jsxrender/messages/channelMessagesRender';
import { DirectMessagesRender } from 'components/common/jsxrender/messages/directMessagesRender';
import { GroupMessagesRender } from 'components/common/jsxrender/messages/groupMessagesRender';
import DownArrowIcon from 'public/assets/img/messaging/arrow_drop_down.svg';
import { setDialog } from 'store/actions/dialogActions';
import { ChatSidebarStyled } from './chatSidebar.styled';
import AddIcon from './icons/AddIcon';
import CustomButton from 'components/Button/CustomButton';

export const SectionChatList: FC<any> = ({
  setShowAddGroup,
  channelsSectionList,
  getArchivedChatList,
  getChannelListPromise,
  setIsArchiveShow,
  handleSetChannel,
  openChannelActionModal,
  selectedChannelId,
}: any) => {
  const [showAddPublicFeeds, setShowAddPublicFeeds] = useState(false);
  const channelsHash = useSelector(selectors.getStreamChannelHash);

  const { GROUP, DIRECT_MESSAGE, FEED_STREAM } = ConstantChannelTypeString;
  const dispatch = useDispatch();
  const [activeChannelId, setActiveChannelId] = useState();

  const renderGroups = () => {
    const channels = channelsSectionList[GROUP] || [];
    return channels.map((ch, index) => {
      const channel = channelsHash[ch.id] || ch;
      return (
        <GroupMessagesRender
          key={ch.id}
          index={index}
          singleData={channel}
          handleSetChannel={(data) => {
            setActiveChannelId(data.id);
            handleSetChannel(data);
          }}
          setIsArchiveShow={setIsArchiveShow}
          openChannelActionModal={openChannelActionModal}
          selectedChannelId={selectedChannelId}
        />
      );
    });
  };

  const renderDirectMessages = () => {
    const channels = channelsSectionList[DIRECT_MESSAGE] || [];
    return channels.map((ch, index) => {
      const channel = channelsHash[ch.id] || ch;
      return (
        <DirectMessagesRender
          key={ch.id}
          index={index}
          singleData={channel}
          handleSetChannel={handleSetChannel}
          setIsArchiveShow={setIsArchiveShow}
          openChannelActionModal={openChannelActionModal}
          selectedChannelId={selectedChannelId}
        />
      );
    });
  };

  const renderFeedChannels = () => {
    const channels = channelsSectionList[FEED_STREAM] || [];
    return channels.map((ch, index) => {
      const channel = channelsHash[ch.id] || ch;
      return (
        <ChannelMessagesRender
          key={ch.id}
          index={index}
          singleData={channel}
          handleSetChannel={handleSetChannel}
          setIsArchiveShow={setIsArchiveShow}
          openChannelActionModal={openChannelActionModal}
          selectedChannelId={selectedChannelId}
        />
      );
    });
  };

  return (
    <>
      <ChatSidebarStyled>
        <Grid
          container
          className="gridBox"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <a
            className="collapse accordionLink"
            data-toggle="collapse"
            href="#groupNav"
          >
            <span>
              <img src={DownArrowIcon}></img>
            </span>
            <p className="headText">Groups</p>
          </a>
          <CustomButton
            className="btnAdd"
            variant="outlined"
            size="medium"
            onClick={() => {
              setShowAddGroup(true);
            }}
          >
            <AddIcon />
          </CustomButton>
        </Grid>
        <div id="groupNav" className="collapse show">
          <div>{renderGroups()}</div>
        </div>
        <hr />
        <Grid
          container
          className="gridBox"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <a
            className="collapse accordionLink"
            data-toggle="collapse"
            href="#directMsgNav"
          >
            <span>
              <img src={DownArrowIcon}></img>
            </span>
            <p className="headText">Direct Messages</p>
          </a>
          <CustomButton
            className="btnAdd"
            variant="outlined"
            size="medium"
            onClick={() => {
              setShowAddGroup(true);
            }}
          >
            <AddIcon />
          </CustomButton>
        </Grid>
        <div id="directMsgNav" className="collapse show">
          <div>{renderDirectMessages()}</div>
        </div>
        <div className="btnInviteUsersBox">
          <CustomButton
            className="btnInviteUsers"
            variant="outlined"
            size="medium"
            onClick={() =>
              dispatch(
                setDialog({
                  dialogId: 'inviteUserDialog',
                  open: true,
                }),
              )
            }
          >
            <AddIcon /> Add teammates to Xenia
          </CustomButton>
        </div>
        <hr />
        <Grid
          container
          className="gridBox"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <a
            className="collapse accordionLink"
            data-toggle="collapse"
            href="#departmentNav"
          >
            <span>
              <img src={DownArrowIcon}></img>
            </span>
            <p className="headText">Public Feeds</p>
          </a>
          <CustomButton
            className="btnAdd"
            variant="outlined"
            size="medium"
            onClick={() => {
              setShowAddPublicFeeds(true);
            }}
          >
            <AddIcon />
          </CustomButton>
        </Grid>
        <div
          id="departmentNav"
          className="collapse show"
          style={showAddPublicFeeds ? { display: 'block' } : {}}
        >
          <AddFeed
            showAddPublicFeeds={showAddPublicFeeds}
            setShowAddPublicFeeds={setShowAddPublicFeeds}
            getChannelListPromise={getChannelListPromise}
          />
          <div>{renderFeedChannels()}</div>
        </div>
        <ArchiveCard
          getArchivedChatList={getArchivedChatList}
          setIsArchiveShow={setIsArchiveShow}
        />
      </ChatSidebarStyled>
    </>
  );
};
