import { Fab, Stack, Tooltip } from '@mui/material';
import LocationsSearchInput from '../assetsSearchInput/assetsSearchInput';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { FloatingBtn, FilterBadge } from './style';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { isMobileApp } from 'utils/constants';
import { postToMobileApp } from 'utils/globalFunction';
import LocationsFilter from './locationsFilter';
import FiltersIcon from 'components/Icons/filtersIcon';

interface Props {
  onSearch?: (value: string) => void;
  hideAction?: boolean;
  handleChangeLocation: (locations: any) => void;
  locationsFilter: any;
}

const AssetsMobileHeader = ({
  onSearch,
  hideAction = false,
  handleChangeLocation,
  locationsFilter,
}: Props) => {
  const dispatch = useDispatch();

  const onClickAdd = () => {
    if (isMobileApp) {
      postToMobileApp('navigateTo', 'CreateAsset');
    } else {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createAsset',
        }),
      );
    }
  };

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      padding={'10px 0px 16px 0px'}
    >
      <Stack
        direction={'row'}
        gap={'16px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <LocationsSearchInput onSearch={onSearch} hideIcon />
      </Stack>

      {!hideAction && (
        <FloatingBtn onClick={onClickAdd}>
          <Tooltip title="Add Asset" arrow>
            <Fab color="primary" style={{ height: 54, width: 54 }}>
              <AddRoundedIcon />
            </Fab>
          </Tooltip>
        </FloatingBtn>
      )}

      <LocationsFilter
        BtnRenderer={
          <div style={{ marginRight: 15 }}>
            <FilterBadge
              color="error"
              badgeContent={locationsFilter?.length || 0}
            >
              <FiltersIcon style={{ height: 28, width: 28 }} />
            </FilterBadge>
          </div>
        }
        onChangeCallback={(options) => {
          const transformedLocations = options?.map((id) => {
            return {
              id,
            };
          });
          handleChangeLocation(transformedLocations);
        }}
      />
    </Stack>
  );
};

export default AssetsMobileHeader;
