// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

import PriorityDropdown from 'components/Dropdown/Task/Priority/TaskPriorityDropdown';
import StatusDropdown from 'components/Dropdown/Task/Status/TaskStatusDropdown';
import ExportDropdown from 'pages/task/TaskListView/ExportDropdown';

// Styled
import {
  BulkActionSelection,
  BulkActionCnt,
  BulkActionItem,
  BulkActionCancelItem,
} from 'components/common/styledComponents/bulkAction.style';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';
import IosShareIcon from '@mui/icons-material/IosShare';

// Icons
import AssignIcon from 'components/Icons/multipleUserIcon';
import PriorityFlagIcon from 'components/Icons/flagIcon';
import ChecklistIcon from 'components/Icons/checklistIconWithoutBg';
import ArchiveIcon from 'components/Icons/archiveArrowIcon';
import DeleteIcon from 'components/Icons/deleteIcon3';

// Constants
import DIALOGS from 'utils/dialogIds';
import * as constants from './constants';

// Utils
import { bulkUpdateTasks } from 'api/tasksApi';
import { showMessageNotification } from 'utils/globalFunction';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import MultiAssigneeBulk from './MultiAssignee/MultiAssignee';

interface BulkActionPropTypes {
  selected: any[];
  onClearSelection: (data?: any) => void;
  isArchiveFilter: any;
}

const BulkAction = ({
  selected,
  onClearSelection,
  isArchiveFilter,
}: BulkActionPropTypes) => {
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();

  const isDeletionOperation = (operationType: string) =>
    ['archive', 'unarchive', 'delete'].includes(operationType);

  const handleUpdateTaskList = (
    operationType:
      | 'status'
      | 'priority'
      | 'addAssignee'
      | 'removeAssignee'
      | 'archive'
      | 'unarchive'
      | 'delete',
    newTasks: any[],
  ) => {
    if (isDeletionOperation(operationType)) {
      dispatch(actions.bulkDeleteTasks(newTasks));
    } else {
      dispatch(actions.bulkUpdateTasks(newTasks));
    }
  };

  const handleBulkUpdate = async (
    operationType:
      | 'status'
      | 'priority'
      | 'addAssignee'
      | 'removeAssignee'
      | 'archive'
      | 'unarchive'
      | 'delete',
    value: any,
  ) => {
    const mappedTaskIds = selected
      .filter(
        (row) =>
          row.data.taskStatus !== 'Missed' ||
          isDeletionOperation(operationType),
      )
      .map((s) => s.data.id);
    if (mappedTaskIds.length === 0) {
      onClearSelection();
      return;
    }

    const result = await bulkUpdateTasks(workspaceId as string, {
      taskIds: mappedTaskIds,
      operationType,
      values: value,
    });

    if (isDeletionOperation(operationType)) {
      handleUpdateTaskList(
        operationType,
        selected.map((s) => s.data),
      );
    }

    if (result) {
      handleUpdateTaskList(operationType, result.data);
      const message = constants?.SUCCESS_MESSAGES[operationType]?.msg.replace(
        '{{count}}',
        `${result.data?.length} out of ${mappedTaskIds.length}`,
      );
      showMessageNotification(
        message,
        constants?.SUCCESS_MESSAGES[operationType]?.type,
      );
    }

    if (['addAssignee', 'removeAssignee'].includes(operationType)) {
      onClearSelection({
        updateData: true,
      });
      return;
    }

    onClearSelection();
  };

  const handleOpenConfirmation = (type: 'archive' | 'delete' | 'unarchive') => {
    const upperCase =
      // eslint-disable-next-line no-constant-condition
      type === 'archive'
        ? 'Archive'
        : type === 'unarchive'
        ? 'Un-archive'
        : 'Delete';
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.CONFIRMATION,
        open: true,
        data: {
          title: `${upperCase} tasks`,
          description: `Are you sure you want to ${type} these tasks?`,
          confirmationText: upperCase,
          onConfirmCallback: () => handleBulkUpdate(type, null),
          confirmButtonProps: {
            color: 'error',
          },
        },
      }),
    );
  };

  return (
    <BulkActionCnt>
      <BulkActionSelection>
        <span className={'count'}>{selected.length}</span>
        <span className={'selectedText'}>
          Task{selected?.length > 1 ? 's' : ''} Selected
        </span>
      </BulkActionSelection>

      <MultiAssigneeBulk
        popperProps={constants.popperProps}
        handleBulkUpdate={handleBulkUpdate}
        buttonRenderer={
          <BulkActionItem className={'borderRight'}>
            <AssignIcon
              style={{ fill: 'none' }}
              className={'assigneeIcon actionIcon'}
            />
            <label className={'actionText'}>Assignee</label>
          </BulkActionItem>
        }
      />

      <PriorityDropdown
        onlyReturnValue
        customRenderer={
          <BulkActionItem className={'borderRight'}>
            <PriorityFlagIcon className={'actionIcon'} />
            <label className={'actionText'}>Priority</label>
          </BulkActionItem>
        }
        popperProps={constants.popperProps}
        onChangeCallback={(val) => handleBulkUpdate('priority', [val[0].id])}
      />

      <StatusDropdown
        onlyReturnValue
        popperProps={constants.popperProps}
        onChangeCallback={(val) => handleBulkUpdate('status', [val[0].id])}
        customRenderer={
          <BulkActionItem className={'borderRight'}>
            <ChecklistIcon className={'actionIcon'} />
            <label className={'actionText'}>Status</label>
          </BulkActionItem>
        }
      />

      <ExportDropdown
        onlySelected={true}
        buttonProps={{
          variant: 'text',
          style: {
            padding: 0,
            margin: 0,
            minWidth: 'max-content',
            justifyContent: 'space-between',
            height: '100%',
          },
        }}
        popperProps={constants.popperProps}
        customRenderer={
          <BulkActionItem className={'borderRight'}>
            <IosShareIcon className={'actionIcon'} />
            <label className={'actionText'}>Export</label>
          </BulkActionItem>
        }
      />
      {!isArchiveFilter && (
        <div>
          <BulkActionItem
            onClick={() => handleOpenConfirmation('archive')}
            className={'borderRight'}
          >
            <ArchiveIcon className={'actionIcon'} />
            <label className={'actionText'}>Archive</label>
          </BulkActionItem>
        </div>
      )}
      {isArchiveFilter && (
        <div>
          <BulkActionItem
            onClick={() => handleOpenConfirmation('unarchive')}
            className={'borderRight'}
          >
            <ArchiveIcon className={'actionIcon'} />
            <label className={'actionText'}>Un-archive</label>
          </BulkActionItem>
        </div>
      )}
      <div>
        <BulkActionItem
          onClick={() => handleOpenConfirmation('delete')}
          className={'borderRight'}
          noborder="true"
        >
          <DeleteIcon className={'actionIcon'} />
          <label className={'actionText'}>Delete</label>
        </BulkActionItem>
      </div>
      <BulkActionCancelItem onClick={onClearSelection}>
        {' '}
        <CloseIcon className={'actionIcon'} />
      </BulkActionCancelItem>
    </BulkActionCnt>
  );
};

export default BulkAction;
