import { createSvgIcon } from '@mui/material';
import React from 'react';

const RequestCompleteIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_84_25568"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_84_25568)">
        <path
          d="M7.06683 11.0667L11.7668 6.36668L10.8335 5.43334L7.06683 9.20001L5.16683 7.30001L4.2335 8.23334L7.06683 11.0667ZM8.00016 14.6667C7.07794 14.6667 6.21127 14.4916 5.40016 14.1413C4.58905 13.7916 3.8835 13.3167 3.2835 12.7167C2.6835 12.1167 2.20861 11.4111 1.85883 10.6C1.50861 9.7889 1.3335 8.92223 1.3335 8.00001C1.3335 7.07779 1.50861 6.21112 1.85883 5.40001C2.20861 4.5889 2.6835 3.88334 3.2835 3.28334C3.8835 2.68334 4.58905 2.20823 5.40016 1.85801C6.21127 1.50823 7.07794 1.33334 8.00016 1.33334C8.92239 1.33334 9.78905 1.50823 10.6002 1.85801C11.4113 2.20823 12.1168 2.68334 12.7168 3.28334C13.3168 3.88334 13.7917 4.5889 14.1415 5.40001C14.4917 6.21112 14.6668 7.07779 14.6668 8.00001C14.6668 8.92223 14.4917 9.7889 14.1415 10.6C13.7917 11.4111 13.3168 12.1167 12.7168 12.7167C12.1168 13.3167 11.4113 13.7916 10.6002 14.1413C9.78905 14.4916 8.92239 14.6667 8.00016 14.6667Z"
          fill="currentColor"
        />
      </g>
    </>,
    'RequestCompleteIcon',
  );
  return <IconCmp viewBox={'0 0 16 16'} fill="none" {...props} />;
};

export default RequestCompleteIcon;
