// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Checkbox } from '@mui/material';

export const StyledAccordion = styled(Accordion)(() => ({
  borderRadius: '0px !important',
  boxShadow: 'none',
  border: 'none',
  margin: '0px !important',

  '& .Mui-expanded': {
    minHeight: 'max-content',
    margin: '0px !important',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: '6px 16px',
  borderRadius: '0px !important',
  borderTop: 'none',
  boxShadow: 'none',
  minHeight: 'max-content',

  background: 'rgba(245,245,245, 1)',

  color: 'rgba(0,0,0,0.6)',
  fontSize: 13,
  fontWeight: 500,

  '&:hover': {
    '& .dropdown-icon': {
      fill: 'rgba(97,97,97,1)',
    },
  },

  '& svg': {
    fontSize: 22,
  },
  '& .MuiButtonBase-root': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
  background: 'none',
}));

export const NotesHeader = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const AccordionCheckboxAndTitle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '& .status': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21.98px',
    letterSpacing: '0.1px',
    color: 'rgba(0,0,0,0.87)',
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  marginRight: 8,
  '& .MuiSvgIcon-root': { fontSize: 24 },
}));
