// MUI components
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const ItemWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));

export const TaskIconCnt = styled(Box)(() => ({
  '& svg': {
    backgroundColor: '#BA68C8',
    borderRadius: 4,
    padding: 4,
    '& path': {
      stroke: 'white',
    },
  },
}));

export const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#212121',
  cursor: 'pointer',
  marginLeft: 10,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const CreatorName = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#212121',
  marginLeft: 5,
}));

export const DueDateContent = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#212121',
}));

export const DescriptionContent = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#212121',
}));
