import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TemplatesCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px 0 24px',
  [theme.breakpoints.down('md')]: {
    width: '100vw',
  },
  gap: 16,
}));

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  marginTop: 100,
});

export const TabContentCnt = styled('div')(({ theme }) => ({}));
