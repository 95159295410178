/* eslint-disable prettier/prettier */
import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const LogoCnt = styled('figure')({
  display: 'flex',
  justifyContent: 'center',
  margin: '25px 0px 20px 0px',
});

export const Background = styled('div')({
  background: 'linear-gradient(180deg, #232750 19.39%, #DBE2F0 100%)',
  height: '100vh',
  overflow: 'auto',
});

export const CircleDiv = styled('div')({
  height: '100vh',
});

export const MainDiv = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .containerDiv': {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 650,
    gap: '5px',
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
      width: '100%',
      height: 'inherit',
    },
  },
  '& .onBoardingBox': {
    backgroundColor: colors.white,
    borderRadius: 25,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      height: 'inherit',
    },
    '& .stepperCnt': {
      position: 'relative',
      padding: '15px 30px',
      borderBottom: '1px solid #edeaea',
    },
  },
  '& .onboardingContentsDiv': {
    padding: '10px 120px 10px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 20px',
      marginTop: 50,
    },
  },
  '& .inviteBox': {
    width: 1150,
  },
  '& .skipContainerDiv': {
    position: 'absolute',
    top: 17,
    left: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& .backBtnContainerDiv': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      visibility: 'hidden',
      cursor: 'pointer',
      '& p': {
        color: colors.primary.main,
        fontWeight: 700,
        marginLeft: -5,
        letterSpacing: '1px',
        fontSize: 15,
      },
      '& svg': {
        color: colors.primary.main,
        height: 20,
        width: 24,
      },
    },
    '& .skipBtnContainerDiv': {
      cursor: 'pointer',
      visibility: 'hidden',
      '& svg': {
        color: 'rgba(0,0,0,0.87)',
      },
      '& p': {
        color: 'rgba(0,0,0,0.87)',
      },
    },
  },
  '& .showContent': {
    visibility: 'visible!important',
  },
}));
