// React
import { useContext } from 'react';

// Custom components
import SearchInput from 'components/Form/TextField/SearchField.cmp';
import FiltersDropdown from 'components/TaskAdvanceFilters/FiltersDropdown';

// Styles
import { HeaderCnt } from './header.style';

// Context
import { OverdueSchedulesCtx } from 'pages/Schedules/Overdue/context';

// Utils
import AddScheduleBtn from 'pages/Schedules/common/Header/addScheduleBtn';
import useHeader from 'pages/Schedules/common/useHeader';

interface MySchedulesHeaderPropTypes {
  createTaskCallback?: () => void;
}

function SchedulesHeader({ createTaskCallback }: MySchedulesHeaderPropTypes) {
  const { advanceFilters, setAdvanceFilters, searchTerm, handleChangeSearch } =
    useContext(OverdueSchedulesCtx);
  const { handleSetAdvanceFilters } = useHeader();

  return (
    <HeaderCnt>
      <div className={'lefCnt'}>
        <SearchInput
          fieldProps={{
            value: searchTerm,
            onChange: (e) => handleChangeSearch(e),
          }}
          sx={{ maxWidth: { sm: 'calc(100vw - 140px)', md: 250 } }}
        />
        <FiltersDropdown
          selectedFilters={advanceFilters}
          hideFilters={
            'futureStartDate futureDueDate completedBy archived taskTemplates priority createdBy scheduleFrequency projects'
          }
          onChange={(filters) =>
            handleSetAdvanceFilters(filters, setAdvanceFilters)
          }
          handleClearFilters={() =>
            handleSetAdvanceFilters(undefined, setAdvanceFilters)
          }
        />
      </div>
      {/*<AddScheduleBtn createTaskCallback={createTaskCallback} />*/}
    </HeaderCnt>
  );
}

export default SchedulesHeader;
