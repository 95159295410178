// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ViewWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  padding: '14px 0px',
  height: '100%',
}));

export const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 0px',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TotalRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '8px 16px',
  width: '100vw',
  gap: 6,
  position: 'fixed',
  bottom: 0,
  left: 0,
  fontSize: 14,
  background: 'rgba(0, 0, 0, 0.04)',
  color: 'rgba(0, 0, 0, 0.87)',
  '& .normal': {
    fontWeight: 400,
  },
  '& .bold': {
    fontWeight: 700,
  },
}));
