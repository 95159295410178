import styled from '@mui/system/styled';
import { Typography } from '@mui/material';

export const RequestListItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  borderLeft: selected ? '3px solid #4E48FA' : '3px solid #ffffff',
  borderBottom: '1px solid #0000001F',
  background: selected ? '#EBEAFF' : null,
  padding: 16,
  '&:hover': {
    cursor: 'pointer',
  },
  '& .title': {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    lineHeight: '17px',
  },
  '& .createdDate': {
    fontSize: 12,
    fontWeight: 400,
    color: '#00000099',
    lineHeight: '8px',
  },
  '& .userName': {
    fontSize: 12,
    fontWeight: 400,
    color: '#000000DE',
  },
  '& .bottomInfoCnt': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 12,
    '& .userAvatarCnt': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    '& .status': {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '0',
      gap: 6,
      color: '#424242',
      alignItems: 'center',
      display: 'flex',
      '& .statusIconCnt': {
        '& svg': {
          fontSize: 14,
        },
      },
    },
  },
}));

export const RequestListingList = styled('div')(({ theme }) => ({
  width: 400,
  padding: '7px 0',
  display: 'flex',
  flexDirection: 'column',
  borderRight: '1px solid #0000001F',
  borderTop: '1px solid #0000001F',
  overflowY: 'auto',
  [theme.breakpoints.down('lg')]: {
    width: 250,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: 0,
  },
}));
export const StatusGroupAccordion = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#F5F5F5',
  minHeight: 34,
  padding: '0 8px',
  '& :hover': {
    cursor: 'pointer',
  },
  '& svg': {
    color: '#BDBDBD',
  },
  '& .statusLabel': {
    fontSize: 14,
    fontWeight: 600,
    color: '#000000DE',
  },
});

export const AnonymousAvatar = styled('div')(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 24,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const UserName = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.87)',
}));
