import { AttachmentsWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import { useMemo } from 'react';
import { FileIconCnt, FilesCnt } from './locationDetailTab.style';
import DownloadIcon from 'components/Icons/downloadIcon';
import { FileIcon, defaultStyles } from 'react-file-icon';
import {
  downloadFile,
  getFileName,
  getUrlExtension,
} from 'utils/globalFunction';
import { IconButton } from '@mui/material';

const LocationFilesPreview = (props) => {
  const { location } = props;

  const isImage = (url) => {
    const splitData = url?.split('/');
    const nameExt = splitData?.[splitData?.length - 1]?.split('.');
    const fileExtension = nameExt?.[1];

    return ['jpeg', 'jpg', 'gif', 'png', 'gif', 'svg'].includes(fileExtension);
  };

  const files = useMemo(() => {
    return location?.attachments?.filter((item) => !isImage(item));
  }, [location?.attachments]);

  return (
    <AttachmentsWithLabel>
      <label>Files</label>
      <FilesCnt>
        {files?.length > 0
          ? files?.map((url, index) => {
              const extension = getUrlExtension(url);
              const fileName = getFileName(url);
              return (
                <div className="fileInnerCnt" key={index}>
                  <FileIconCnt>
                    <FileIcon
                      color="#d6d6d6"
                      extension={extension}
                      {...defaultStyles[extension]}
                    />
                  </FileIconCnt>
                  <div className={'fileDetailsCnt'}>
                    <span className={'fileName'} title={fileName}>
                      {fileName}.{extension}
                    </span>
                  </div>
                  <IconButton
                    className="download"
                    onClick={() => {
                      downloadFile(url, `${fileName}.${extension}`);
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </div>
              );
            })
          : '-'}
      </FilesCnt>
    </AttachmentsWithLabel>
  );
};
export default LocationFilesPreview;
