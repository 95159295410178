import React, { useState } from 'react';
import CustomButton from 'components/Button/CustomButton';
import OtpInput from '../../../components/OTP/lib/OtpInput';
import { OtpDiv } from './OTPContainer.style';

const OtpVerification = (props) => {
  const { otpChangeCallback } = props;
  const [state, setState] = useState({
    otp: '',
    numInputs: 6,
    separator: '',
    isDisabled: false,
    hasErrored: false,
    isInputNum: false,
    isInputSecure: false,
    placeholder: '------',
  });
  const handleOtpChange = (otp) => {
    setState({ ...state, otp });
    otpChangeCallback({ ...state, otp });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(state.otp);
  };
  const handleResend = (e) => {
    e.preventDefault();
    alert(state.otp);
  };
  return (
    <OtpDiv>
      <OtpInput
        inputStyle="inputStyle"
        numInputs={state.numInputs}
        isDisabled={state.isDisabled}
        hasErrored={state.hasErrored}
        errorStyle="error"
        onChange={handleOtpChange}
        separator={<span>{state.separator}</span>}
        isInputNum={state.isInputNum}
        isInputSecure={state.isInputSecure}
        shouldAutoFocus
        value={state.otp}
        placeholder={state.placeholder}
      />
    </OtpDiv>
  );
};

export default OtpVerification;
