// React
import { useMemo } from 'react';

// Custom components
import Header from 'components/TemplatePreview/Header';
import TemplateBody from 'components/TemplatePreview/TemplateBody';
import Footer from 'components/TemplatePreview/Footer';

// Styled
import { Wrapper, BodyWrapper } from './styled';

// Context
import { TemplateType } from 'pages/TemplateDashboard/context';

const TemplatePreview = ({ template }: { template?: TemplateType }) => {
  const headerData = useMemo(() => {
    const data = template;
    if (data)
      return {
        templateId: data.id,
        name: data.name,
        attachments: data.attachments,
        template,
      };
    return null;
  }, [template]);

  return (
    <Wrapper>
      {!!headerData && <Header data={headerData} />}
      <BodyWrapper>
        <TemplateBody checklistItems={template?.ChecklistItems} />
      </BodyWrapper>
      {!!template && <Footer template={template} />}
    </Wrapper>
  );
};

export default TemplatePreview;
