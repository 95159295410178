import { forwardRef, useImperativeHandle, useState } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import ColorIcon from 'components/Icons/colorIcon';
import { isError } from './utils';
import { CellEditorCnt } from './categories.style';
import ToolTip from 'components/Tooltip/tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import { COLORS_LIST } from 'utils/constants';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

export default forwardRef((props: any, ref) => {
  const [value, setValue] = useState(props?.data?.type);
  const [error, setError] = useState('');
  const serviceList: any = useSelector(selectors.getServiceAdmin);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  const changeHandler = (event) => {
    error && setError('');

    const { value } = event.target;
    isError(value, serviceList, setError);
    setValue(event.target.value);
  };

  const selectedColor = COLORS_LIST.find(
    (item) => item.color === props.data.color,
  );

  const blurHandler = () => {
    globalThis?.CategoriesGrid?.api?.stopEditing();
  };

  return (
    <CellEditorCnt>
      <ColorIcon
        primary={selectedColor?.color}
        secondary={selectedColor?.borderColor}
      />
      <CustomInput
        sx={{ mb: 0 }}
        fieldProps={{
          value: value,
          onClick: (e) => e.stopPropagation(),
          onChange: changeHandler,
          onBlur: blurHandler,
          className: 'inputCnt',
          endAdornment: error ? (
            <ToolTip placement="top" arrow title={error}>
              <ErrorIcon className="error" />
            </ToolTip>
          ) : null,
          autoFocus: true,
        }}
        suppressBorder={true}
      />
    </CellEditorCnt>
  );
});
