import { FC, useState } from 'react';
import {
  DialogContent,
  DialogActions,
  Divider,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import CustomButton from 'components/Button/CustomButton';
import RichTextEditor from 'components/RichTextEditor';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { StyledDialog, StyledCustomInput } from './styled';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';

interface IProps {
  checklistItem: any;
  handleSave: (
    description: string,
    instruction: string,
    acknowledgement: boolean,
  ) => void;
  open: boolean;
  handleClose: () => void;
}

const InstructionStepDialog: FC<IProps> = (props) => {
  const { checklistItem, handleSave, open, handleClose } = props;

  if (!open) return <></>;

  const { requireAcknowledgement, instruction: initialInstruction } =
    checklistItem.options;

  const [instruction, setInstruction] = useState<string>(initialInstruction);
  const [description, setDescription] = useState<string>(
    checklistItem.description,
  );
  const [acknowledgement, setAcknowledgement] = useState<boolean>(
    requireAcknowledgement || false,
  );

  return (
    <StyledDialog disableEnforceFocus={true} open={open}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pl={2}
        py={2}
        spacing={1}
      >
        <FormatAlignLeftIcon />
        <StyledCustomInput
          grayInput
          fieldProps={{
            onChange: (e) => {
              setDescription(e.target.value);
            },
            defaultValue: description,
            placeholder: 'Enter description / title here',
            autoFocus: true,
          }}
        />
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <Divider />
      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            padding: '0px',
          },
        }}
      >
        <RichTextEditor value={instruction} onChange={setInstruction} />
      </DialogContent>
      <Divider sx={{ mb: 1 }} />
      <Box ml="24px">
        <Tooltip
          arrow
          disableInteractive
          placement="top"
          title={
            <Stack color="#fff" spacing={1}>
              <Box bgcolor="#fff" color="#000" borderRadius={1.5} p={0.8}>
                <FormControlLabel
                  sx={{ m: 0 }}
                  control={
                    <CustomCheckbox
                      disableRipple
                      sx={{
                        p: 0,
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="11px">
                      acknowledge that I've read and understand the above
                      information
                    </Typography>
                  }
                />
              </Box>
              <Typography color="#fff" fontSize="12px">
                The above message will appear to ensure user acknowledgement.
              </Typography>
            </Stack>
          }
        >
          <FormControlLabel
            control={
              <CustomCheckbox
                defaultChecked={acknowledgement}
                disableRipple
                onChange={(e) => setAcknowledgement(e.target.checked)}
              />
            }
            label="Require acknowledgement"
          />
        </Tooltip>
      </Box>
      <Divider />
      <DialogActions>
        <CustomButton variant="outlined" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={() => {
            handleSave(description, instruction, acknowledgement);
            handleClose();
          }}
        >
          Save
        </CustomButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default InstructionStepDialog;
