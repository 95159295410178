import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import useAssetsServices from 'utils/CustomHooks/useAssetsServices';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import useAssetDetail from '../useAssetDetail';
import AssetServicesEmptyState from './emptyState';
import ServicesLogHeader from './header';
import ServicesLogGrid from './GridView';
import { LoaderCnt } from './style';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import AssetServicesPaidFeature from './paidFeature';
import { useSearchParams } from 'react-router-dom';
import { getAssetById } from 'api/assets';
import { setAuthAssets, updateAssets } from 'store/actions/assetActions';
import xeniaApi from 'api/index';

function ServicesLog(props) {
  const { isSelected, setLoadTabsData, loadTabsData } = props;
  const { selectedAsset } = useAssetDetail();
  const { FEATURES, hasFeature } = useCustomPermission();

  const limit = 100;

  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [servicesMeta, setServicesMeta] = useState<any>({});
  const [payload, setPayload] = useState({
    filters: {
      status: 'all',
      asset: [selectedAsset?.id],
    },
    offset: 0,
    limit: limit,
    sort: [['date', 'ASC']],
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const handleLoadNextPage = async (offset: number) => {
    const paginationData = {
      offset,
      limit,
    };
    setIsNextPageLoading(true);
    const postData = {
      ...payload,
      ...paginationData,
    };

    const response: any = await getServicesList(postData, true);

    const prevData = services ?? [];
    const newRows = response?.services ?? [];
    setServices([...prevData, ...newRows]);
    setServicesMeta({
      total: response?.total,
      overdue: response?.overdue,
      upcoming: response?.upcoming,
      completed: response?.completed,
    });
    setIsNextPageLoading(false);
  };

  const { getAssetAllServices } = useAssetsServices();

  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState<any>([]);

  const dispatch = useDispatch();

  const getServicesList = async (postData = {}, hideLoader = false) => {
    if (!hideLoader) setLoading(true);
    const response = await getAssetAllServices(postData);
    setServices(response?.services);
    setServicesMeta({
      total: response?.total,
      overdue: response?.overdue,
      upcoming: response?.upcoming,
      completed: response?.completed,
    });
    if (!hideLoader) setLoading(false);
  };

  const updateServices = () => {
    const updatedPayload = {
      ...payload,
      offset: 0,
      limit: limit,
      sort: [['date', 'ASC']],
    };
    getServicesList(updatedPayload);
    assetUpdater();
  };

  const assetUpdater = async () => {
    const updatedAsset = await getAssetById(selectedAsset?.id);
    setLoadTabsData({
      ...loadTabsData,
      history: false,
      scheduling: false,
    });
    dispatch(updateAssets(updatedAsset));

    const authAssets = await xeniaApi.getAuthAssets();
    dispatch(setAuthAssets(authAssets));
  };

  const editServiceInState = (updatedService) => {
    const updatedServicesState = services?.map((service) => {
      if (service?.id === updatedService?.id) {
        return updatedService;
      }
      return service;
    });

    setServices(updatedServicesState);
    assetUpdater();
  };

  const deleteServiceFromState = (serviceId) => {
    const filteredServices = services?.filter(
      (service) => service?.id != serviceId,
    );

    setServices(filteredServices);
    assetUpdater();
  };

  const markCompleteServiceInState = (service) => {
    if (payload?.filters?.status === 'all') {
      editServiceInState(service);
    } else {
      deleteServiceFromState(service?.id);
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.ASSET_SERVICE_COMPLETED,
        data: {
          asset: selectedAsset,
        },
      }),
    );
  };

  const openDetailView = (id) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.ASSET_SERVICE_DETAIL,
        data: {
          id,
          editSuccessCallback: updateServices,
          deleteSuccessCallback: deleteServiceFromState,
          markCompleteSuccessCallback: markCompleteServiceInState,
        },
      }),
    );
  };

  useEffect(() => {
    const serviceLog = searchParams.get('service');
    if (serviceLog) {
      openDetailView?.(serviceLog);
      searchParams.delete('service');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isSelected) {
      getServicesList(payload);
    }
  }, [isSelected]);

  const createNewServiceHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_ASSET_SERVICE,
        data: {
          asset: selectedAsset,
          onSuccessCallback: updateServices,
        },
      }),
    );
  };

  const filterActionUpdater = (type) => {
    const updatedPayload = {
      ...payload,
      filters: {
        ...payload?.filters,
        status: type,
      },
      offset: 0,
      limit: limit,
      sort: [['date', 'ASC']],
    };

    setPayload(updatedPayload);
    getServicesList(updatedPayload);
  };

  const hasAssetsServicesFeature = useMemo(
    () => hasFeature(FEATURES.ASSET_SERVICE),
    [],
  );

  return (
    <>
      {hasAssetsServicesFeature && (
        <ServicesLogHeader
          createNewServiceHandler={createNewServiceHandler}
          filterActionUpdater={filterActionUpdater}
          filters={payload?.filters}
          serviceMetaData={servicesMeta}
        />
      )}

      {loading && (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      )}

      {!loading && !hasAssetsServicesFeature && <AssetServicesPaidFeature />}

      {!loading && hasAssetsServicesFeature && (
        <div>
          {services?.length === 0 ? (
            <AssetServicesEmptyState
              addSuccessCallback={updateServices}
              type={payload?.filters?.status}
            />
          ) : (
            <ServicesLogGrid
              handleLoadNextPage={handleLoadNextPage}
              isNextPageLoading={isNextPageLoading}
              services={services}
              servicesMeta={servicesMeta}
              callbackActions={{
                addSuccessCallback: updateServices,
                editSuccessCallback: updateServices,
                deleteSuccessCallback: deleteServiceFromState,
                markCompleteSuccessCallback: markCompleteServiceInState,
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default ServicesLog;
