import { createSvgIcon } from '@mui/material';

const RepeatIconMultiColor = ({
  primary = 'black',
  secondary = '#D9D9D9',
  ...rest
}: any) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.4"
        d="M11.9167 20.1666L9.16667 17.4166M9.16667 17.4166L11.9167 14.6666M9.16667 17.4166H13.75C17.2938 17.4166 20.1667 14.5438 20.1667 11C20.1667 8.44028 18.6679 6.23065 16.5 5.20081"
        stroke={primary}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 16.7992C3.33213 15.7694 1.83333 13.5597 1.83333 11C1.83333 7.45621 4.70617 4.58337 8.25 4.58337H12.8333M12.8333 4.58337L10.0833 1.83337M12.8333 4.58337L10.0833 7.33337"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>,
    'RepeatIconMultiColor',
  );
  return (
    <IconCmp
      style={{ fill: 'none' }}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    />
  );
};

export default RepeatIconMultiColor;
