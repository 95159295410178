// React
import { useMemo } from 'react';

// MUI components
import Grid from '@mui/material/Grid';

// Selectors
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import ChecklistTileCmp from './ChecklistTileCmp';
import ChecklistFilters from '../ChecklistFilters';

// Utilities
import { searchChecklist } from '../utils';
interface ChecklistGridPropTypes {
  checklistScope: 'myTemplates' | 'publicTemplates';
  folder?: any;
}

const ChecklistGrid = ({ checklistScope, folder }: ChecklistGridPropTypes) => {
  const checklistStore =
    checklistScope === 'myTemplates'
      ? useSelector(selectors.getChecklistListDataOnly)
      : useSelector(selectors.getPublicChecklists);

  const searchTerm: any = useSelector(selectors.getSearchTextFromStore);
  const selectedFilters = useSelector(selectors.getChecklistFilters);

  const checklistData = useMemo(
    () =>
      searchChecklist({
        searchTerm,
        checklistList: checklistStore,
        selectedFilters:
          checklistScope === 'myTemplates' ? null : selectedFilters,
      }),
    [searchTerm, selectedFilters],
  );

  return (
    <Grid container spacing={2}>
      {checklistScope === 'publicTemplates' && (
        <Grid item xs={12}>
          <ChecklistFilters />
        </Grid>
      )}
      {checklistData.map((c, index) => (
        <Grid key={index} item xs={6} md={4} lg={3} xl={3}>
          <ChecklistTileCmp
            key={index}
            checklist={c}
            checklists={checklistData}
            checklistScope={checklistScope}
            folder={folder}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ChecklistGrid;
