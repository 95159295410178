import React, { useState } from 'react';

// Custom components
import SubmissionEntry from 'components/TemplateDashboard/SubmissionListWithFilters/SubmissionList/SubmissionEntry';

// Styled
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  AccordionCheckboxAndTitle,
  StyledCheckbox,
} from './styled';

// Icons
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';

// Utils
import { isEqual } from 'lodash';

interface SubmissionAccordionsPropTypes {
  data: any[];
  handleClickSubmission: any;
  isCheckedHash: any;
  activeSubmission: any;
  setActiveSubmission: any;
  bulkGroupSelected: any;
  bulkCheckGroup: any;
}

const SubmissionAccordions = ({
  data,
  handleClickSubmission,
  isCheckedHash,
  activeSubmission,
  setActiveSubmission,
  bulkGroupSelected,
  bulkCheckGroup,
}: SubmissionAccordionsPropTypes) => {
  const [expanded, setExpanded] = useState<any>(['0', '1']);

  const handleClickAccordion = (accordionId: string) => {
    if (expanded.includes(accordionId)) {
      setExpanded(expanded.filter((id) => id !== accordionId));
    } else {
      setExpanded([...expanded, accordionId]);
    }
  };

  const handleClickCheckmark = (
    e: React.MouseEvent<HTMLElement>,
    statusName,
  ) => {
    e.stopPropagation();
    bulkCheckGroup(statusName);
  };

  return (
    <div>
      {data &&
        data.map((logGroup, idx) => (
          <StyledAccordion
            key={idx}
            onChange={() => handleClickAccordion(`${idx}`)}
            expanded={expanded.includes(`${idx}`)}
          >
            <StyledAccordionSummary
              expandIcon={
                <ArrowDropDownCircleOutlinedIcon className="dropdown-icon" />
              }
            >
              <AccordionCheckboxAndTitle>
                <StyledCheckbox
                  onClick={(e) => handleClickCheckmark(e, logGroup.status)}
                  checked={bulkGroupSelected.includes(logGroup.status)}
                />
                <p className="status">
                  {logGroup.status} ({logGroup.data.length})
                </p>
              </AccordionCheckboxAndTitle>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {logGroup.data.map((s) => (
                <SubmissionEntry
                  onClickEntry={handleClickSubmission}
                  submission={s}
                  key={s.id}
                  active={!!isCheckedHash?.[s.id]}
                  isPreview={activeSubmission?.id === s.id}
                  handleClickEntry={() => setActiveSubmission(s)}
                />
              ))}
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
    </div>
  );
};

const arePropsEqual = (
  prevProps: SubmissionAccordionsPropTypes,
  nextProps: SubmissionAccordionsPropTypes,
) => {
  const {
    bulkCheckGroup: p1,
    setActiveSubmission: p2,
    handleClickSubmission: p3,
    ...restPrev
  } = prevProps;
  const {
    bulkCheckGroup,
    setActiveSubmission,
    handleClickSubmission,
    ...restNext
  } = nextProps;

  return isEqual(restPrev, restNext);
};

export default React.memo(SubmissionAccordions, arePropsEqual);
