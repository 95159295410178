import styled from '@mui/system/styled';

export const AssetsSearchInputCnt = styled('div')(({ theme }) => ({
  width: 250,
  borderRadius: 8,
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginRight: 0,
  },
  '& input': {
    padding: '8.5px 14px 8.5px 7px',
    '&::-webkit-input-placeholder': {
      fontSize: 14,
    },
  },
  '& svg': {
    color: '#0000008A',
  },
  '& legend': {
    width: 0,
  },
}));
