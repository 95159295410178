import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const UsageDetailsContainerStyled = styled(Box)({
  border: '1px solid #E0E0E0',
  borderRadius: 8,
  padding: '16px 20px',
  marginBottom: 30,
  marginRight: 24,

  '.title': {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '133.4%',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '.usage-section': {
    '.usage-item': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 16,
      '.usage-type': {
        alignItems: 'center',
        display: 'flex',
        fontSize: 34,
        '.title': {
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '133.4%',
          color: '#212121',
          marginLeft: 12,
        },
        '.icon': {
          fill: 'none',
        },
      },
      '.numbers': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        gap: 4,
        '.description': {
          fontWeight: 400,
          fontSize: 13,
          lineHeight: '133.4%',
          color: '#424242',
        },
      },
    },
  },
});
