import { createSvgIcon } from '@mui/material';

const QuestionIcon = (props) => {
  const { primary = '#EBEAFF', secondary = '#3922D6' } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M7.62318 1.88008C7.82496 1.6783 7.92585 1.57741 8.04359 1.50526C8.14797 1.4413 8.26177 1.39416 8.38081 1.36558C8.51508 1.33334 8.65776 1.33334 8.94312 1.33334H17.0567C17.3421 1.33334 17.4848 1.33334 17.619 1.36558C17.7381 1.39416 17.8519 1.4413 17.9562 1.50526C18.074 1.57741 18.1749 1.6783 18.3767 1.88008L24.1199 7.62328C24.3216 7.82505 24.4225 7.92594 24.4947 8.04368C24.5586 8.14806 24.6058 8.26186 24.6343 8.38091C24.6666 8.51517 24.6666 8.65785 24.6666 8.94321V17.0568C24.6666 17.3422 24.6666 17.4848 24.6343 17.6191C24.6058 17.7382 24.5586 17.852 24.4947 17.9563C24.4225 18.0741 24.3216 18.175 24.1199 18.3767L18.3767 24.1199C18.1749 24.3217 18.074 24.4226 17.9562 24.4948C17.8519 24.5587 17.7381 24.6059 17.619 24.6344C17.4848 24.6667 17.3421 24.6667 17.0567 24.6667H8.94312C8.65776 24.6667 8.51508 24.6667 8.38081 24.6344C8.26177 24.6059 8.14797 24.5587 8.04359 24.4948C7.92585 24.4226 7.82496 24.3217 7.62318 24.1199L1.87999 18.3767C1.67821 18.175 1.57732 18.0741 1.50517 17.9563C1.4412 17.852 1.39407 17.7382 1.36549 17.6191C1.33325 17.4848 1.33325 17.3422 1.33325 17.0568V8.94321C1.33325 8.65785 1.33325 8.51517 1.36549 8.38091C1.39407 8.26186 1.4412 8.14806 1.50517 8.04368C1.57732 7.92594 1.67821 7.82505 1.87999 7.62328L7.62318 1.88008Z"
        fill={primary}
      />
      <path
        d="M9.60488 9.5C9.87917 8.72028 10.4206 8.06279 11.1332 7.64399C11.8458 7.22518 12.6836 7.07209 13.4983 7.21183C14.3129 7.35156 15.0518 7.77511 15.5841 8.40745C16.1164 9.03978 16.4078 9.84011 16.4065 10.6667C16.4065 13 12.9065 14.1667 12.9065 14.1667"
        fill={primary}
      />
      <path
        d="M9.60488 9.5C9.87917 8.72028 10.4206 8.06279 11.1332 7.64399C11.8458 7.22518 12.6836 7.07209 13.4983 7.21183C14.3129 7.35156 15.0518 7.77511 15.5841 8.40745C16.1164 9.03978 16.4078 9.84011 16.4065 10.6667C16.4065 13 12.9065 14.1667 12.9065 14.1667M12.9999 18.8333H13.0116M1.33325 8.94321V17.0568C1.33325 17.3422 1.33325 17.4848 1.36549 17.6191C1.39407 17.7382 1.4412 17.852 1.50517 17.9563C1.57732 18.0741 1.67821 18.175 1.87999 18.3767L7.62318 24.1199C7.82496 24.3217 7.92585 24.4226 8.04359 24.4948C8.14797 24.5587 8.26177 24.6059 8.38081 24.6344C8.51508 24.6667 8.65776 24.6667 8.94312 24.6667H17.0567C17.3421 24.6667 17.4848 24.6667 17.619 24.6344C17.7381 24.6059 17.8519 24.5587 17.9562 24.4948C18.074 24.4226 18.1749 24.3217 18.3767 24.1199L24.1198 18.3767C24.3216 18.175 24.4225 18.0741 24.4947 17.9563C24.5586 17.852 24.6058 17.7382 24.6343 17.6191C24.6666 17.4848 24.6666 17.3422 24.6666 17.0568V8.94321C24.6666 8.65785 24.6666 8.51517 24.6343 8.38091C24.6058 8.26186 24.5586 8.14806 24.4947 8.04368C24.4225 7.92594 24.3216 7.82505 24.1198 7.62328L18.3767 1.88008C18.1749 1.6783 18.074 1.57741 17.9562 1.50526C17.8519 1.4413 17.7381 1.39416 17.619 1.36558C17.4848 1.33334 17.3421 1.33334 17.0567 1.33334H8.94312C8.65776 1.33334 8.51508 1.33334 8.38081 1.36558C8.26177 1.39416 8.14797 1.4413 8.04359 1.50526C7.92585 1.57741 7.82496 1.6783 7.62318 1.88008L1.87999 7.62328C1.67821 7.82505 1.57732 7.92594 1.50517 8.04368C1.4412 8.14806 1.39407 8.26186 1.36549 8.38091C1.33325 8.51517 1.33325 8.65785 1.33325 8.94321Z"
        stroke={secondary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={primary}
      />
    </>,
    'QuestionIcon',
  );
  return (
    <IconCmp
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    />
  );
};
export default QuestionIcon;
