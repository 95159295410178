import { useState } from 'react';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { useReactRouter } from 'controller/useReactRouter';
import xeniaApi from 'api/index';
import PhoneEmailInput from 'components/Dropdown/PhoneCodeInput';
import EmailIcon from 'components/Icons/emailIcon';
import OtpVerification from 'pages/onboarding/OTPContainer';
import { setOtpLogin } from 'api/authApi';
import useUserLogin from 'utils/CustomHooks/useUserLogin';
import XeniaLogoSymbol from 'public/assets/img/xenia-logo-symbol.svg';

import {
  DividerLine,
  LoginThroughEmail,
  MainTitle,
  NextButton,
  OtpContainer,
  PhoneContainer,
  PhoneLabel,
  SignUpContent,
  SubTitle,
} from './style';

function LoginWithPhone(props) {
  const [showVerification, setShowVerification] = useState<boolean>(false);
  const [loginBtnLoader, setLoginBtnLoader] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState<any>({});
  const [phoneError, setPhoneError] = useState('');
  const [otp, setOtp] = useState('');
  const [resendingOtp, setResendingOtp] = useState(false);
  const { handleRedirectToRoute } = useReactRouter();
  const { setLoggedInUser, setDeviceToken } = useUserLogin();

  const handleVerify = async () => {
    setLoginBtnLoader(true);
    const postObj = {
      phoneNo: phoneCode.obj.dial_code + phoneNumber,
      code: otp,
    };
    const userProfile = await xeniaApi.verityOtp(postObj);
    if (userProfile) {
      await setDeviceToken();
      setLoggedInUser(userProfile);
    }
    setLoginBtnLoader(false);
  };
  //Otp input change callback
  const handleOtpInputCallback = (otp) => {
    setOtp(otp?.otp);
  };

  const handleGetOtp = async () => {
    if (!phoneNumber) {
      // If phone number is invalid show required phone message
      setPhoneError(VALIDATION_ERROR_MESSAGES.REQUIRED_USER_PHONE_NO);
      return;
    }
    const shouldUserLogin = await setOtpLogin({
      phoneNo: phoneCode.obj.dial_code + phoneNumber,
    });
    return shouldUserLogin;
  };

  const handleResendOtp = async () => {
    setResendingOtp(true);
    await handleGetOtp();
    setResendingOtp(false);
  };

  //When user enter a phone number and hit next to verify with OTP
  const handleNext = async () => {
    const shouldUserLogin = await handleGetOtp();
    if (shouldUserLogin) {
      setShowVerification(true);
    }
  };

  //On phone input Change callback
  const onPhoneInputChange = (value) => {
    setPhoneError('');
    setPhoneNumber(value);
  };

  //Phone code change callback
  const onPhoneCodeSelect = (value) => {
    setPhoneCode(value);
  };

  const navigateToLoginWithEmail = () => {
    handleRedirectToRoute('/sign-in');
  };
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode == 13 && !showVerification) {
      handleNext();
      return;
    }
    if (keyCode == 13) {
      handleVerify();
    }
  };

  const navigateToRegister = () => {
    handleRedirectToRoute('/register');
  };

  return (
    <>
      <img
        src={XeniaLogoSymbol}
        className="logo"
        height={40}
        width={70}
        style={{ marginBottom: 20 }}
      />

      <MainTitle>Log in to Xenia</MainTitle>
      <SubTitle>
        {showVerification
          ? 'Enter the code that we’ve sent you on your phone number.'
          : 'Enter your phone number and code to log into your account.'}
      </SubTitle>

      {showVerification ? (
        <OtpContainer>
          <OtpVerification otpChangeCallback={handleOtpInputCallback} />
        </OtpContainer>
      ) : (
        <PhoneContainer>
          <PhoneLabel>Phone Number</PhoneLabel>
          <PhoneEmailInput
            suppressEmail={true}
            autoFocus={true}
            error={phoneError}
            selectValueCallback={onPhoneInputChange}
            onPhoneCodeSelect={onPhoneCodeSelect}
            fieldProps={{
              onKeyDown: handleKeyDown,
              autoFocus: true,
              style: {
                borderRadius: 8,
                height: 47,
              },
            }}
            formControlProps={{
              muiLabel: true,
            }}
          />
        </PhoneContainer>
      )}
      {!showVerification ? (
        <NextButton variant="contained" onClick={handleNext}>
          Next
        </NextButton>
      ) : (
        <NextButton
          variant="contained"
          buttonLoader={loginBtnLoader}
          onClick={handleVerify}
        >
          Verify
        </NextButton>
      )}
      {showVerification && (
        <NextButton
          variant={'outlined'}
          onClick={handleResendOtp}
          buttonLoader={resendingOtp}
          style={{ marginTop: 10, fontWeight: 700 }}
        >
          Resend OTP
        </NextButton>
      )}

      <DividerLine />

      <LoginThroughEmail
        variant={'outlined'}
        onClick={navigateToLoginWithEmail}
        startIcon={<EmailIcon />}
      >
        Log in using email
      </LoginThroughEmail>

      <SignUpContent>
        Don't have an account?{' '}
        <span className="link" onClick={navigateToRegister}>
          Sign Up
        </span>
      </SignUpContent>
    </>
  );
}
export default LoginWithPhone;
