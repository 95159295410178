import React, { useMemo } from 'react';
// Styled
import {
  CellWrapper,
  Row,
  Text,
  StyledCheckbox,
  AttachmentsWrapper,
  Attachment,
  OpenChip,
  MCQChip,
  FlagIconCnt,
  PassFailCellWrapper,
  NameRow,
} from './styled';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import CustomToolTip from 'components/Tooltip/tooltip';

// Icons
import OpenBookIcon from 'components/Icons/openBookIcon';
import FlagIconRounded from 'components/Icons/flagIconRounded';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import TrashIcon from 'components/Icons/trashIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { statusDropdownData } from 'helper/checklist/dropDownData';
import { StatusCellCnt } from 'components/ChecklistPreview/checklistPreview.style';
import actions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import SignatureItem from 'components/ChecklistReport/ChecklistLogItem/SignatureItem';
import Stack from '@mui/material/Stack';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Chip } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getLogDuration } from 'utils/templates';
import AttachmentWithPreview from 'components/AttachmentWithPreview';
import FlaggedCategoryTag from 'components/FlaggedCategoryTag';
import { getFlaggedItemsCategories } from 'store/selectors/flaggedItemsCategories';

const isFlagCategory = (categories, option, selectedCategory) => {
  const flaggedCategory = categories?.find(
    (c) =>
      c?.id === option?.flagCategory ||
      c?.id === selectedCategory?.[0]?.FlagCategory,
  );

  return flaggedCategory;
};

const getValue = (obj) => {
  return obj?.answers?.value ?? null;
};
export const StatusRenderer = ({ obj }) => {
  const status = getValue(obj);
  const selectedStatus = statusDropdownData.find((s) => s.label === status);
  return (
    <StatusCellCnt
      style={{
        color: selectedStatus?.color,
      }}
    >
      {selectedStatus?.startIcon}
      <div className="statusText">{status}</div>
    </StatusCellCnt>
  );
};
const getAutomationTitle = (obj) => {
  return obj?.automationCases?.length > 0
    ? obj.automationCases.join('\n')
    : null;
};

const AutomationTooltip = ({ title }) => {
  return (
    <CustomToolTip title={title}>
      <InfoOutlinedIcon
        sx={{ color: 'rgba(2, 136, 209, 1)', fontSize: '20px' }}
      />
    </CustomToolTip>
  );
};

export const NameCell = (params) => {
  const { onClickOpenCallback, obj } = params;

  const getSubmitterName = () => {
    const submitter = getValue(obj);
    return submitter || 'Anonymous';
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    onClickOpenCallback?.();
    event.preventDefault();
  };

  return (
    <CellWrapper data-attribute={'cell-click'}>
      <NameRow bold={'true'} gap={'16'} style={{ overflow: 'hidden' }}>
        <Text width={'calc(100% - 20px)'}>{getSubmitterName()}</Text>
        <OpenChip
          onClick={handleClickOpen}
          icon={<OpenBookIcon />}
          label="Open"
          size="small"
        />
      </NameRow>
    </CellWrapper>
  );
};

export const DateCell = ({ obj }) => {
  const date = getValue(obj);
  const { getDateInTz, getUtcToTzTime } = useDateTime();

  return (
    <CellWrapper>
      {date ? (
        <Text>
          {getDateInTz(date, 'MM/DD/YYYY')}, {getUtcToTzTime(date, 'hh:mma')}
        </Text>
      ) : (
        <Text>-</Text>
      )}
    </CellWrapper>
  );
};

export const CheckboxCell = ({ obj }) => {
  const isChecked = getValue(obj);
  const automation = getAutomationTitle(obj);

  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};

  return (
    <CellWrapper>
      <Row gap={'8'}>
        <StyledCheckbox disabled={true} color="success" checked={isChecked} />
        <Text>{isChecked ? 'Complete' : 'Incomplete'}</Text>
        {(() => {
          const flaggedCategory = isFlagCategory(
            categoriesList,
            {},
            obj?.flagCategories,
          );

          return flaggedCategory?.name ? (
            <>
              <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
              <CustomToolTip title={'Flagged Answer'}>
                <FlagIconCnt>
                  <FlagIconRounded sx={{ fontSize: '16px' }} />
                </FlagIconCnt>
              </CustomToolTip>
            </>
          ) : null;
        })()}
        {automation && <AutomationTooltip title={automation} />}
      </Row>
    </CellWrapper>
  );
};

export const CostCell = ({ obj }) => {
  const { unit } = obj;
  const cost = getValue(obj) ?? '';
  const automation = getAutomationTitle(obj);

  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};

  return (
    <CellWrapper>
      <Row gap={'8'}>
        {cost ? (
          <Text>
            {unit}
            {cost}
          </Text>
        ) : (
          <Text>-</Text>
        )}
        {(() => {
          const flaggedCategory = isFlagCategory(
            categoriesList,
            {},
            obj?.flagCategories,
          );

          return flaggedCategory?.name ? (
            <>
              <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
              <CustomToolTip title={'Flagged Answer'}>
                <FlagIconCnt>
                  <FlagIconRounded sx={{ fontSize: '16px' }} />
                </FlagIconCnt>
              </CustomToolTip>
            </>
          ) : null;
        })()}
        {automation && <AutomationTooltip title={automation} />}
      </Row>
    </CellWrapper>
  );
};

export const TemperatureCell = ({ obj }) => {
  const { unit } = obj;
  const temp = getValue(obj) ?? '';
  const automation = getAutomationTitle(obj);
  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};
  return (
    <CellWrapper>
      <Row gap={'8'}>
        {temp ? (
          <Text>
            {temp} {unit}
          </Text>
        ) : (
          <Text>-</Text>
        )}
        {(() => {
          const flaggedCategory = isFlagCategory(
            categoriesList,
            {},
            obj?.flagCategories,
          );

          return flaggedCategory?.name ? (
            <>
              <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
              <CustomToolTip title={'Flagged Answer'}>
                <FlagIconCnt>
                  <FlagIconRounded sx={{ fontSize: '16px' }} />
                </FlagIconCnt>
              </CustomToolTip>
            </>
          ) : null;
        })()}
        {automation && <AutomationTooltip title={automation} />}
      </Row>
    </CellWrapper>
  );
};

export const NumberCell = ({ obj }) => {
  const { unit = '' } = obj;
  const data = getValue(obj)
    ? `${getValue(obj)}${unit ? ` ${unit}` : ''}`
    : null;
  const automation = getAutomationTitle(obj);
  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};
  return (
    <CellWrapper>
      <Text>{data || '-'}</Text>{' '}
      {(() => {
        const flaggedCategory = isFlagCategory(
          categoriesList,
          {},
          obj?.flagCategories,
        );

        return flaggedCategory?.name ? (
          <>
            <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
            <CustomToolTip title={'Flagged Answer'}>
              <FlagIconCnt>
                <FlagIconRounded sx={{ fontSize: '16px' }} />
              </FlagIconCnt>
            </CustomToolTip>
          </>
        ) : null;
      })()}
      {automation && <AutomationTooltip title={automation} />}
    </CellWrapper>
  );
};

export const TextCell = ({ obj }) => {
  const data = getValue(obj) ? `${getValue(obj)}` : null;
  const automation = getAutomationTitle(obj);
  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};
  return (
    <CellWrapper>
      <Text>{data || '-'}</Text>
      {(() => {
        const flaggedCategory = isFlagCategory(
          categoriesList,
          {},
          obj?.flagCategories,
        );

        return flaggedCategory?.name ? (
          <>
            <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
            <CustomToolTip title={'Flagged Answer'}>
              <FlagIconCnt>
                <FlagIconRounded sx={{ fontSize: '16px' }} />
              </FlagIconCnt>
            </CustomToolTip>
          </>
        ) : null;
      })()}

      {automation && <AutomationTooltip title={automation} />}
    </CellWrapper>
  );
};

export const DurationCell = ({ obj }) => {
  const duration = getLogDuration({ ...getValue(obj) });
  return (
    <CellWrapper>
      <Text>{duration || '-'}</Text>
    </CellWrapper>
  );
};

export const HeaderCell = ({ obj, template }) => {
  const { score } = obj;

  return (
    <CellWrapper>
      {template.isScoring ? (
        <Stack direction="row" spacing={1}>
          <Typography fontWeight={600} fontSize="14px">
            {score?.percentage}%
          </Typography>
          <Typography fontSize="14px">
            ({score?.earned} out of {score?.total} pts)
          </Typography>
        </Stack>
      ) : (
        'NA'
      )}
    </CellWrapper>
  );
};

export const DateTimeCell = ({ obj }) => {
  const value = getValue(obj);
  const { unit } = obj;
  const automation = getAutomationTitle(obj);

  const { getUtcToTz } = useDateTime();
  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};
  return (
    <CellWrapper>
      {value ? (
        <Text>
          {unit === 'dateTime'
            ? getUtcToTz(value, 'MM/DD/YYYY - hh:mm A')
            : getUtcToTz(value, 'MM/DD/YYYY')}
        </Text>
      ) : (
        <Text>-</Text>
      )}
      {(() => {
        const flaggedCategory = isFlagCategory(
          categoriesList,
          {},
          obj?.flagCategories,
        );

        return flaggedCategory?.name ? (
          <>
            <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
            <CustomToolTip title={'Flagged Answer'}>
              <FlagIconCnt>
                <FlagIconRounded sx={{ fontSize: '16px' }} />
              </FlagIconCnt>
            </CustomToolTip>
          </>
        ) : null;
      })()}
      {automation && <AutomationTooltip title={automation} />}
    </CellWrapper>
  );
};

export const SignatureCell = ({ obj }) => {
  const value = getValue(obj);
  const automation = getAutomationTitle(obj);

  return (
    <CellWrapper>
      {value ? <SignatureItem value={value} /> : <Text>-</Text>}
      {automation && <AutomationTooltip title={automation} />}
    </CellWrapper>
  );
};

export const InstructionCell = ({ obj }) => {
  const value = getValue(obj);

  return (
    <CellWrapper>
      {obj?.option?.requireAcknowledgement ? (
        value ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <CheckCircleOutlinedIcon sx={{ color: '#4CAF50' }} />
            <Typography color="#252350" fontSize="13px">
              Acknowledged by:
            </Typography>
            <Typography color="#252350" fontWeight={600} fontSize="13px">
              {value}
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" spacing={1} alignItems="center">
            <ErrorOutlineOutlinedIcon sx={{ color: '#EF5350' }} />
            <Typography color="#252350" fontSize="13px">
              Not acknowledged
            </Typography>
          </Stack>
        )
      ) : (
        <Text>-</Text>
      )}
    </CellWrapper>
  );
};

export const AttachmentsCell = ({ obj }) => {
  const attachments = getValue(obj) ?? [];
  const automation = getAutomationTitle(obj);
  return (
    <CellWrapper>
      <Row gap={'8'}>
        {attachments?.length > 0 ? (
          <AttachmentWithPreview attachments={attachments}>
            {(handleClickAttachment) => (
              <AttachmentsWrapper>
                <AttachmentsWrapper max={3}>
                  {attachments.map((att, idx) => (
                    <Attachment
                      onClick={() => handleClickAttachment(idx)}
                      src={att}
                    />
                  ))}
                </AttachmentsWrapper>
              </AttachmentsWrapper>
            )}
          </AttachmentWithPreview>
        ) : (
          <Text>-</Text>
        )}
        {automation && <AutomationTooltip title={automation} />}
      </Row>
    </CellWrapper>
  );
};

export const MultipleChoiceCell = ({ obj, flaggedCategories }) => {
  const data = getValue(obj) ?? '';
  const option = obj?.option;
  const automation = getAutomationTitle(obj);

  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};

  return (
    <CellWrapper>
      {data ? (
        <Row gap={'10'}>
          <MCQChip color={option?.color}>
            <p>{option?.label ?? '-'}</p>
          </MCQChip>
          {(() => {
            const flaggedCategory = isFlagCategory(
              categoriesList,
              option,
              obj?.flagCategories,
            );

            return flaggedCategory?.name ? (
              <Box sx={{ marginLeft: '-2px' }}>
                <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
              </Box>
            ) : null;
          })()}
          <Row gap={'8'}>
            {option?.flag && <FlagIconRounded sx={{ fontSize: '16px' }} />}
            {automation && <AutomationTooltip title={automation} />}
          </Row>
        </Row>
      ) : (
        <Text>-</Text>
      )}
    </CellWrapper>
  );
};

export const DropdownCell = ({ obj, flaggedCategories }) => {
  const data = getValue(obj) ?? '';
  const option = obj?.option;
  const automation = getAutomationTitle(obj);

  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};

  return (
    <CellWrapper>
      {data?.length ? (
        <Row gap={'6'}>
          {option?.map((item) => {
            const flaggedCategory = isFlagCategory(
              categoriesList,
              item,
              obj?.flagCategories,
            );
            return (
              <Stack
                justifyContent="space-between"
                direction="row"
                width="100%"
              >
                <Stack direction="row" gap="5px" width="inherit">
                  <Chip
                    size="small"
                    sx={{ background: item?.color, color: '#fff' }}
                    label={item?.label}
                  />
                  {flaggedCategory?.name && (
                    <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
                  )}
                </Stack>

                <Row>
                  {item?.flag && (
                    <FlagIconRounded
                      sx={{ fontSize: '16px', marginLeft: '10px' }}
                    />
                  )}
                </Row>
              </Stack>
            );
          })}
          <Row gap={'8'}>
            {automation && <AutomationTooltip title={automation} />}
          </Row>
        </Row>
      ) : (
        <Text>-</Text>
      )}
    </CellWrapper>
  );
};

export const LocationCell = ({ obj, locations }) => {
  const data = getValue(obj) ?? '';

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const location = locations?.[data];

  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};

  return (
    <CellWrapper>
      {location ? (
        <Row gap={'6'}>
          <CustomButton
            onClick={() => {
              const path = location.ParentId
                ? `/locations/${location?.ParentId}/sl/${location?.id}`
                : `/locations/${location?.id}`;

              navigateWithWorkspaceUrl(path);
            }}
          >
            {location?.name}
          </CustomButton>
        </Row>
      ) : (
        <Text>-</Text>
      )}
      {(() => {
        const flaggedCategory = isFlagCategory(
          categoriesList,
          {},
          obj?.flagCategories,
        );

        return flaggedCategory?.name ? (
          <>
            <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
            <CustomToolTip title={'Flagged Answer'}>
              <FlagIconCnt>
                <FlagIconRounded sx={{ fontSize: '16px' }} />
              </FlagIconCnt>
            </CustomToolTip>
          </>
        ) : null;
      })()}
    </CellWrapper>
  );
};

export const GeoStampCell = ({ obj }) => {
  const data = getValue(obj) ?? '';

  return (
    <CellWrapper>
      {data?.address?.length ? (
        <Stack>
          {data.lat && data.lng ? (
            <a
              href={`https://maps.google.com/?q=${data.lat},${data.lng}`}
              target="_blank"
              style={{ fontSize: '14px' }}
            >
              {data.address}
            </a>
          ) : (
            <Typography fontSize="14px">{data.address}</Typography>
          )}
        </Stack>
      ) : (
        <Text>-</Text>
      )}
    </CellWrapper>
  );
};

export const PassFailCell = ({ obj }) => {
  const data = getValue(obj) ?? '';
  const automation = getAutomationTitle(obj);
  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};

  const valueMap = {
    pass: {
      color: 'rgba(76, 175, 80, 1)',
      label: 'Pass',
    },
    fail: {
      color: 'rgba(239, 83, 80, 1)',
      label: 'Fail',
    },
    na: {
      color: 'rgba(0, 0, 0, 0.6)',
      label: 'NA',
    },
  };
  return (
    <CellWrapper>
      {data ? (
        <>
          <MCQChip color={valueMap[data]?.color}>
            <p>{valueMap[data]?.label}</p>
          </MCQChip>
          {(() => {
            const flaggedCategory = isFlagCategory(
              categoriesList,
              {},
              obj?.flagCategories,
            );

            return flaggedCategory?.name ? (
              <>
                <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
                <CustomToolTip title={'Flagged Answer'}>
                  <FlagIconCnt>
                    <FlagIconRounded sx={{ fontSize: '16px' }} />
                  </FlagIconCnt>
                </CustomToolTip>
              </>
            ) : null;
          })()}

          {automation && <AutomationTooltip title={automation} />}
        </>
      ) : (
        <Text>-</Text>
      )}
    </CellWrapper>
  );
};

export const DropdownMenu = (props) => {
  const dispatch = useDispatch();
  const dropdownOptions = [
    {
      id: 'delete',
      label: 'Delete',
      iconAlign: 'start',
      icon: <TrashIcon />,
      sx: {
        color: 'rgba(239, 83, 80, 1)',
      },
    },
  ];

  const handleDelete = () => {
    if (props?.logId) {
      dispatch(
        actions.setDialog({
          dialogId: 'confirmationDialog',
          open: true,
          data: {
            title: 'Delete Location',
            description: 'Are you sure you want to delete Submission(s)?',
            onConfirmCallback: () => {
              props?.handleArchiveLogs([props?.logId]);
            },

            confirmButtonProps: { color: 'error' },
            confirmationText: 'Delete',
          },
        }),
      );
    }
  };

  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'delete':
        handleDelete();
        break;
      default:
        break;
    }
  };

  return (
    <div data-attibute="cell-click">
      <ActionDropdown
        options={dropdownOptions}
        handleOptionSelect={handleOptionSelect}
        popperProps={{
          placement: 'bottom-end',
        }}
        buttonProps={{
          style: { padding: 0 },
        }}
      >
        <MoreVertOutlined sx={{ color: '#9E9E9E' }} />
      </ActionDropdown>
    </div>
  );
};
