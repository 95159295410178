import styled from '@mui/system/styled';
import ListItem from '@mui/material/ListItem';

export const SettingsCnt = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  '& .contentCnt': {
    flex: 1,
  },
});
