import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { ResourcesCnt } from './style';
import RocketIcon from 'components/Icons/rocketIcon';
import MonitorIcon from 'components/Icons/monitorIcon';
import QuestionIcon from 'components/Icons/questionIcon';
import BookCallIcon from 'components/Icons/bookCallIcon';
import CTypography from 'components/CTypography';
import { PopupModal } from 'react-calendly';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { TALK_TO_SALES_URL } from 'utils/constants';

const Resources: FC = () => {
  const dispatch = useDispatch();

  return (
    <ResourcesCnt>
      <CTypography className="label">Resources</CTypography>
      <Grid container className="cnt">
        <Grid item xs={3}>
          <div
            className="box"
            onClick={() => {
              window.open('https://help.xenia.team', '_blank');
            }}
          >
            <div className="iconCnt">
              <RocketIcon />
            </div>
            <p>Getting Started</p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            className="box"
            onClick={() => {
              dispatch(
                actions.setCalendlyInfo({
                  url: TALK_TO_SALES_URL,
                  open: true,
                }),
              );
            }}
          >
            <div className="iconCnt">
              <BookCallIcon />
            </div>
            <p>Book a Call</p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div
            className="box"
            onClick={() => {
              window.open(
                'https://www.youtube.com/playlist?list=PLRfCBv1GdZYl_FH4VWc8i991xA5GYdWb7',
                '_blank',
              );
            }}
          >
            <div className="iconCnt">
              <MonitorIcon />
            </div>
            <p>Video Tutorials</p>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className="box">
            <div className="iconCnt">
              <QuestionIcon />
            </div>
            <p>Step by Step Guides</p>
          </div>
        </Grid>
      </Grid>
    </ResourcesCnt>
  );
};

export default Resources;
