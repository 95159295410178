import { useSelector } from 'react-redux';
import { getAssetsState } from 'store/selectors/assets';
import { AssetNameCnt } from './style';

const AssetAttached = ({ asset }) => {
  const assetsList = useSelector(getAssetsState);
  const parentAsset = assetsList?.find((item) => item?.id === asset?.ParentId);

  return (
    <AssetNameCnt isParent={parentAsset?.name}>
      <div className="content">
        <span className={'assetName'}> {asset?.name}</span>
        {parentAsset?.name && (
          <span className="parentAssetName"> {parentAsset?.name}</span>
        )}
      </div>
    </AssetNameCnt>
  );
};

export default AssetAttached;
