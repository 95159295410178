import LocationNameInput from 'pages/Locations/LocationDetail/LocationDetailTab/locationName.cmp';
import LocationDescriptionInput from 'pages/Locations/LocationDetail/LocationDetailTab/locationDescription.cmp';
import LocationQRCode from 'pages/Locations/LocationDetail/LocationDetailTab/locationQRCode.cmp';
import CustomButton from 'components/Button/CustomButton';
import EditIcon from 'components/Icons/editIcon';
import { LocationDetailTabCnt } from 'pages/Locations/LocationDetail/LocationDetailTab/locationDetailTab.style';
import { useEffect, useMemo, useReducer, useState } from 'react';
import AddSubLocation from 'components/AllDialogs/locations/createLocation/AddSubLocation/addSubLocation';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useLocationDetail from 'pages/Locations/LocationDetail/useLocationDetail';
import useUpdateLocation from 'utils/CustomHooks/useUpdateLocation';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { useSelector } from 'react-redux';
import { getLocationsState } from 'store/selectors/locations';
import selectors from 'store/selectors';
import LocationAttachments from './locationAttachments.cmp';
import LocationPhotosPreview from './locationPhotosPreview.cmp';
import LocationFilesPreview from './locationFilesPreview.cmp';
import useResponsive from 'utils/CustomHooks/useResponsive';
import LocationLevel from './locationLevel.cmp';

function LocationDetailTab() {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [editable, setEditable] = useState(false);
  const [locationNameError, setLocationNameError] = useState('');
  const locationsList = useSelector(getLocationsState);
  const { selectedLocation } = useLocationDetail();
  const { updateLocation, addSublocation } = useUpdateLocation();
  const topAlertState = useSelector(selectors.getTopAlertState);
  const { isMobileDeviceSm } = useResponsive();
  const [locationState, updateLocationState] = useReducer(
    (prevState, value) => {
      return { ...prevState, ...value };
    },
    {},
  );
  //Setting selected location in state for editing
  useEffect(() => {
    updateLocationState({ Sublocations: [], ...selectedLocation });
  }, [selectedLocation]);

  const isLocationNameDuplicate = () => {
    const parentLocation = locationsList.find(
      (l) => l.id == selectedLocation?.ParentId,
    );
    if (selectedLocation?.ParentId) {
      return parentLocation?.Sublocations?.some(
        (sl) =>
          sl.id !== locationState.id &&
          sl.name?.trim()?.toLowerCase() ===
            locationState?.name?.trim()?.toLowerCase(),
      );
    } else {
      return locationsList?.some(
        (sl) =>
          sl.id !== locationState.id &&
          sl.name?.trim()?.toLowerCase() ===
            locationState?.name?.trim()?.toLowerCase(),
      );
    }
  };
  const showEditView = () => {
    setEditable(true);
  };
  const handleUpdateLocation = (data = null, params: any = {}) => {
    const postObj = data || locationState;
    if (!locationState.name) {
      setLocationNameError(
        VALIDATION_ERROR_MESSAGES.LOCATION_NAME_REQUIRED_NAME,
      );
      return;
    }
    if (isLocationNameDuplicate()) {
      setLocationNameError(VALIDATION_ERROR_MESSAGES.LOCATION_DUPLICATE_NAME);
      return;
    }
    updateLocation(postObj).then((res) => {
      setEditable(false);

      if (params?.redirect) {
        const path = postObj.ParentId
          ? `/locations/${postObj?.ParentId}/sl/${postObj?.id}`
          : `/locations/${postObj?.id}`;

        navigateWithWorkspaceUrl(path);
      }
    });
  };
  const handleAddSubLocation = (value) => {
    const { name, description, LevelId } = value;
    const postObj = {
      name,
      description,
      LevelId,
      ParentId: selectedLocation.id,
    };
    addSublocation(postObj);
  };

  const navigateToSubLocation = (id) => {
    navigateWithWorkspaceUrl(`/locations/${selectedLocation?.id}/sl/${id}`);
  };

  const taggedLocations = useMemo(() => {
    return locationState?.Sublocations?.filter((sl) => sl.LevelId) ?? [];
  }, [locationState]);

  const untaggedLocations = useMemo(() => {
    const untagged =
      locationState?.Sublocations?.filter((sl) => !sl.LevelId) ?? [];
    return untagged;
  }, [locationState]);

  return (
    <LocationDetailTabCnt
      style={{
        height: isMobileDeviceSm
          ? 400
          : topAlertState?.show
          ? 'calc(100vh - 295px)'
          : 'calc(100vh - 223px)',
      }}
    >
      <div className={'locationDetailTabInnerCnt'}>
        {!editable && (
          <CustomButton
            variant={'outlined'}
            className={'editButton'}
            onClick={showEditView}
            startIcon={<EditIcon className="editIcon" />}
          >
            Edit
          </CustomButton>
        )}
        <LocationNameInput
          editable={editable}
          location={locationState}
          updateAction={updateLocationState}
          setLocationNameError={setLocationNameError}
          locationNameError={locationNameError}
        />

        <LocationLevel
          editable={editable}
          location={locationState}
          updateAction={updateLocationState}
        />
        {!editable && <LocationPhotosPreview location={locationState} />}

        {editable && (
          <LocationAttachments
            location={locationState}
            updateAction={updateLocationState}
          />
        )}
        <LocationDescriptionInput
          editable={editable}
          location={locationState}
          updateAction={updateLocationState}
        />

        {!editable && <LocationFilesPreview location={locationState} />}
        <LocationQRCode
          editable={editable}
          location={locationState}
          updateAction={updateLocationState}
          handleAddQR={() => {
            setEditable(true);
          }}
        />
        {editable && (
          <CustomButton
            variant={'contained'}
            className="saveButton"
            onClick={() => handleUpdateLocation(null, { redirect: true })}
          >
            Save
          </CustomButton>
        )}
      </div>
      {taggedLocations?.length > 0 && (
        <AddSubLocation
          editAction={handleUpdateLocation}
          subLocations={taggedLocations}
          parentLocation={locationState}
          addAction={handleAddSubLocation}
          onSublocationClick={navigateToSubLocation}
        />
      )}

      {untaggedLocations?.length > 0 && (
        <AddSubLocation
          editAction={handleUpdateLocation}
          subLocations={untaggedLocations}
          parentLocation={locationState}
          addAction={handleAddSubLocation}
          onSublocationClick={navigateToSubLocation}
        />
      )}

      {taggedLocations?.length === 0 && untaggedLocations?.length === 0 && (
        <AddSubLocation
          editAction={handleUpdateLocation}
          subLocations={[]}
          parentLocation={locationState}
          addAction={handleAddSubLocation}
          onSublocationClick={navigateToSubLocation}
        />
      )}
    </LocationDetailTabCnt>
  );
}

export default LocationDetailTab;
