import TaskIcon from 'components/Icons/taskIcon';
import ChecklistIcon from 'components/Icons/checklistIcon';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import { ReportingListItemCnt } from 'pages/ReportingV2/ReportListing/ReportListingItem/reportListingItem.style';
import EmployeeIcon from 'components/Icons/employeeIcon';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CompleteIcon from '@mui/icons-material/BookmarkAddedOutlined';
import FeatureLockIcon from 'components/Icons/featureLockIcon';

const reportIcons = {
  'task-summary': <TaskIcon primary={'#388E3C'} secondary={'#E8F5E9'} />,
  'employee-productivity': (
    <EmployeeIcon primary={'#0288D1'} secondary={'#E1F5FE'} />
  ),
  'completed-work-report': <CompleteIcon color="success" />,
  'template-submissions': (
    <ChecklistIcon
      primary="rgba(81, 45, 168, 1)"
      secondary="rgba(237, 231, 246, 1)"
    />
  ),
};
function ReportListingItem({ report, onClick }) {
  return (
    <ReportingListItemCnt onClick={(e) => onClick(e, report)}>
      <div className="reportingListItemLeft">
        <div className="reportIconCnt">{reportIcons[report.slug]}</div>
        <div className={'reportTitleCnt'}>
          <div className="reportName">{report.name}</div>
          <div className="reportDescription">{report.description}</div>
        </div>
      </div>
      <ArrowRightIcon className={'arrowIcon'} />
    </ReportingListItemCnt>
  );
}

export default ReportListingItem;
