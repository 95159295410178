import styled from '@mui/system/styled';

export const WorkingHoursCnt = styled('div')({
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  marginLeft: 65,
  '.workingHoursHeadingCnt': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 630,
  },
  '& .workHoursHeading': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '170%',
  },
  '& .timePickerDropdownCnt': {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 630,
    gap: 12,
    '& .dash': {
      color: '#757575',
      fontSize: 16,
      fontWeight: 700,
      marginTop: 19,
    },
  },
});
