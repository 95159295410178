// React
import { useMemo } from 'react';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';

// Styles
import { buttonProps, buttonStyles } from '../commonStyles';

type Option = {
  id: string;
  label: string;
};

interface FilterTermPropTypes {
  value: string;
  options: Option[];
  onSelect: (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    id: string,
  ) => void;
}

const FilterTerm = ({ value, options, onSelect }: FilterTermPropTypes) => {
  const handleOptionSelect = (option: Option) => {
    onSelect('filterName', option.id);
  };

  const label = useMemo(() => {
    return options.find((o) => o.id === value)?.label ?? '--';
  }, [value]);

  return (
    <ActionDropdown
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={options}
      buttonProps={{
        sx: {
          background: '#fafafa',
          width: '150px',
          ...buttonStyles,
        },
        endIcon: <ExpandMoreRoundedIcon />,
        ...buttonProps,
      }}
      paperProps={{
        sx: {
          minWidth: '150px',
          width: '150px',
        },
      }}
    >
      {label}
    </ActionDropdown>
  );
};

export default FilterTerm;
