import UserManagement from 'pages/user-management';
import TeamsDashboard from 'pages/teams/teams.view';
import CTabs from 'components/CTabs';
import { Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import CustomRolesView from 'pages/roles';

const UsersAndTeams = () => {
  const [searchParams, _] = useSearchParams();
  const tab = searchParams.get('tab');
  const [activeTab, setActiveTab] = useState(0);

  const { PERMISSIONS, hasPermission } = useCustomPermission();
  const canManageTeams = hasPermission(PERMISSIONS.CAN_MANAGE_TEAMS);
  const canManageUsers = hasPermission(PERMISSIONS.CAN_MANAGE_USERS);

  const tabs = useMemo(() => {
    const myTabs: any[] = [];
    if (canManageUsers)
      myTabs.push({
        label: 'Users',
        content: <UserManagement />,
        panelProps: { sx: { p: '0px !important' } },
      });
    if (canManageTeams) {
      myTabs.push({
        label: 'Teams',
        content: <TeamsDashboard />,
        panelProps: { sx: { p: '0px !important' } },
      });
    }

    myTabs.push({
      label: 'Roles',
      content: <CustomRolesView />,
      panelProps: { sx: { p: '0px !important' } },
    });

    return myTabs;
  }, [canManageUsers, canManageUsers]);

  useEffect(() => {
    let selected = -1;
    if (tab !== undefined && tab !== null) {
      if (tab === 'users') {
        selected = 0;
      }
      if (tab === 'teams' && canManageUsers) {
        selected = 1;
      }
      if (tab === 'teams' && !canManageUsers) {
        selected = 0;
      }
      if (tab === 'roles' && canManageUsers && canManageTeams) {
        selected = 2;
      }
      if (tab === 'roles' && canManageUsers && !canManageTeams) {
        selected = 1;
      }
      if (tab === 'roles' && !canManageUsers && !canManageTeams) {
        selected = 0;
      }
      setActiveTab(selected);
    }
  }, [tab]);

  return (
    <Stack padding="8px 24px">
      <CTabs
        activeTab={activeTab}
        rootSx={{ margin: '0px !important' }}
        data={tabs}
      />
    </Stack>
  );
};

export default UsersAndTeams;
