// React
import { Box } from '@mui/material';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ChecklistIconPicker from 'components/common/jsxrender/checklistV2/builder/checklistIconPicker';

// Utilities
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { ReactComponent as EditIcon } from 'public/assets/img/edit-outlined-icon.svg';

const IconPicker = ({
  setIconPickerOpen,
  iconPickerOpen,
  icon,
  handlePickerSelect,
}) => {
  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? (
      <IconComponent style={{ fontSize: '20px' }} {...rest} />
    ) : null;
  };

  return (
    <CustomDropdown
      buttonProps={{
        variant: 'text',
        style: {
          width: '40px',
          height: '40px',
          minWidth: '40px',
          padding: '0',
        },
        onClick: () => setIconPickerOpen(!iconPickerOpen),
      }}
      popperProps={{
        sx: {
          width: '406px',
          inset: '100px auto auto 230px !important',
          zIndex: 1301,
        },
        className: 'checklist-icon-picker',
        placement: 'right',
        open: iconPickerOpen,
        content: (
          <ChecklistIconPicker
            selectedIcon={icon?.icon}
            selectedColor={icon?.color}
            handlePickerSelect={handlePickerSelect}
            setIconPickerOpen={setIconPickerOpen}
          />
        ),
      }}
    >
      <div className="position-relative">
        <Box
          sx={{
            backgroundColor: icon.color || 'transparent',
            border: `1px solid ${!icon.color ? '#110024' : icon.color}`,
            color: '#110024',
          }}
          className="checklist-icon-container d-flex"
        >
          <MuiIcon name={icon.icon} height={'20px'} width={'20px'} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: '#FAFAFA',
            boxShadow:
              '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)',
            border: '1px solid transparent',
            bottom: '-6px',
            right: '-9px',
            height: 20,
            width: 20,
            borderRadius: '20px',
          }}
          className="icon-container-overlay"
        >
          <EditIcon style={{ width: 12, height: 12 }} />
        </Box>
      </div>
    </CustomDropdown>
  );
};

export default IconPicker;
