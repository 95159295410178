// Custom components
import Header from 'pages/Billing/Header';
import Banner from 'pages/Billing/Banner';
import UsageDetails from 'pages/Billing/UsageDetails';
import Pricing from 'pages/Billing/Pricing';

import { ViewWrapper } from './styled';
import { SettingsContentWrapper } from 'components/common/styledComponents/settings.style';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

const Billing = () => {
  const topAlertState = useSelector(selectors.getTopAlertState);
  return (
    <ViewWrapper>
      <Header />
      <SettingsContentWrapper
        padding={'0px 0px 24px 0px'}
        topAlertState={topAlertState?.show}
      >
        <Banner />
        <UsageDetails />
        <Pricing setWidth={true} />
      </SettingsContentWrapper>
    </ViewWrapper>
  );
};

export default Billing;
