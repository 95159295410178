import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import InfiniteActivities from './infiniteActivities';
import ActivityLogsFilters from './filters/filters';
import KioskLogEmpty from './empty';
import { getWorkspaceActivityLogs } from 'api/workspaceApi';
import { LoaderCnt, PageWrapper } from './style';
import { PERMISSIONS } from 'utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const limit = 100;

const WorkspaceActivityLogsView = () => {
  const { hasPermission } = useCustomPermission();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [activityLogs, setActivityLogs] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showFiltersLoader, setShowFiltersLoader] = useState(false);
  const [payload, setPayload] = useState({
    page: 0,
    limit: limit,
    filters: {
      date: {
        from: null,
        to: null,
      },
      locations: [],
      users: [],
    },
  });

  const updateDateRangeCB = (date) => {
    const updatedPayload = {
      ...payload,
      page: 0,
      limit,
      filters: {
        ...payload?.filters,
        date: {
          ...payload?.filters?.date,
          from: date?.from,
          to: date?.to,
        },
      },
    };

    setPayload(updatedPayload);
    setShowFiltersLoader(true);
    getActivityLogs(updatedPayload, false, true);
  };

  const updateLocationCB = (locations) => {
    const updatedPayload = {
      ...payload,
      page: 0,
      limit,
      filters: {
        ...payload?.filters,
        locations,
      },
    };

    setPayload(updatedPayload);
    setShowFiltersLoader(true);
    getActivityLogs(updatedPayload, false, true);
  };

  const updateUsersCB = (users) => {
    const updatedPayload = {
      ...payload,
      page: 0,
      limit,
      filters: {
        ...payload?.filters,
        users,
      },
    };
    setPayload(updatedPayload);
    setShowFiltersLoader(true);
    getActivityLogs(updatedPayload, false, true);
  };

  const getActivityLogs = async (
    payload,
    initialLoad = false,
    filtersLoad = false,
  ) => {
    const response: any = await getWorkspaceActivityLogs(payload);
    setActivityLogs(response?.workspaceLogs?.rows ?? []);
    setTotalCount(response?.workspaceLogs?.count ?? 0);

    if (initialLoad) {
      setIsLoading(false);
    }
    if (filtersLoad) {
      setShowFiltersLoader(false);
    }
  };

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_MANAGE_ACTIVITY_LOGS)) {
      navigateWithWorkspaceUrl('/dashboard');
      return;
    }

    setIsLoading(true);
    getActivityLogs(payload, true);
  }, []);

  const handleLoadNextPage = async (offset: number) => {
    const paginationData = {
      page: offset,
      limit,
    };
    setIsNextPageLoading(true);
    const postData = {
      ...payload,
      ...paginationData,
    };

    const response: any = await getWorkspaceActivityLogs(postData);
    const newRows = response?.workspaceLogs?.rows;

    const prevData = activityLogs ?? [];
    setActivityLogs([...prevData, ...newRows]);
    setTotalCount(response?.workspaceLogs?.count ?? 0);
    setIsNextPageLoading(false);
  };

  return hasPermission(PERMISSIONS.CAN_MANAGE_ACTIVITY_LOGS) ? (
    <PageWrapper>
      {isLoading ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : totalCount > 0 ? (
        <>
          <ActivityLogsFilters
            filters={payload?.filters}
            updateDateRangeCB={updateDateRangeCB}
            updateLocationCB={updateLocationCB}
            updateUsersCB={updateUsersCB}
          />

          {showFiltersLoader ? (
            <LoaderCnt>
              <CircularProgress />
            </LoaderCnt>
          ) : (
            <InfiniteActivities
              handleLoadNextPage={handleLoadNextPage}
              isNextPageLoading={isNextPageLoading}
              logs={activityLogs}
              totalCount={totalCount}
            />
          )}
        </>
      ) : (
        <>
          <ActivityLogsFilters
            filters={payload?.filters}
            updateDateRangeCB={updateDateRangeCB}
            updateLocationCB={updateLocationCB}
            updateUsersCB={updateUsersCB}
          />

          {showFiltersLoader ? (
            <LoaderCnt>
              <CircularProgress />
            </LoaderCnt>
          ) : (
            <KioskLogEmpty />
          )}
        </>
      )}
    </PageWrapper>
  ) : null;
};

export default WorkspaceActivityLogsView;
