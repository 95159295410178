import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import Icon from 'public/assets/img/tasks/ImgCorrectiveActions.png';

function CreateTaskInfo() {
  const dispatch = useDispatch();

  return (
    <Stack alignItems={'center'} justifyContent={'center'} width="100%">
      <img
        style={{ maxWidth: 'none' }}
        src={Icon}
        width={'180px'}
        height={'auto'}
      />
      <Typography
        color="rgba(37, 35, 80, 1)"
        fontSize={'20px'}
        fontWeight={'800'}
        mb="10px"
        mt="-30px"
      >
        No Corrective Actions Yet
      </Typography>
      <Typography
        textAlign={'center'}
        color="#000"
        fontSize={'14px'}
        fontWeight={'500'}
        width={'700px'}
      >
        Corrective actions will be displayed here once initiated through
        operations templates. This approach ensures a systematic response to
        failed inspections and identified hazards. Start by configuring
        corrective actions within your operations templates to promote
        compliance, safety, and operational excellence.
      </Typography>
    </Stack>
  );
}

export default CreateTaskInfo;
