// React
import React from 'react';

// Custom components
import InfiniteVirtualList from 'components/InfiniteVirtualList/InfiniteVirtualList';

// Utilities
import { isEqual, omit } from 'lodash';
import ProjectGridItem from '../GridItem';
interface GridViewPropTypes {
  projects: any[];
  totalCount: number;
  isNextPageLoading?: boolean;
  height: number;
  onClickTask?: (taskId: string) => void;
  deleteProjectHandler?: (projectId: string) => void;
  updateProjectHandler?: (project: any) => void;
  handleLoadNextPage?: (offset: number) => Promise<void>;
  createProjectCallback?: () => void;
}

const GridView = ({
  projects,
  totalCount,
  isNextPageLoading = false,
  height,
  deleteProjectHandler,
  updateProjectHandler,
  handleLoadNextPage,
  createProjectCallback,
}: GridViewPropTypes) => {
  return (
    <InfiniteVirtualList
      handleLoadNextPage={handleLoadNextPage}
      rows={projects?.map((project) => (
        <ProjectGridItem
          createProjectCallback={createProjectCallback}
          key={project?.id}
          project={project}
          deleteProjectHandler={deleteProjectHandler}
          updateProjectHandler={updateProjectHandler}
        />
      ))}
      height={height}
      totalCount={totalCount}
      isNextPageLoading={isNextPageLoading}
    />
  );
};

const arePropsEqual = (
  prevProps: GridViewPropTypes,
  nextProps: GridViewPropTypes,
) => {
  return isEqual(
    omit(prevProps, ['handleLoadNextPage', 'onClickTask']),
    omit(nextProps, ['handleLoadNextPage', 'onClickTask']),
  );
};

export default React.memo(GridView, arePropsEqual);
