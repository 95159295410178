import styled from '@mui/system/styled';

export const DashboardCmpHeaderCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  borderBottom: '1px solid #E0E0E0',
  '& .dashboardHeaderRightCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  [theme.breakpoints.down('md')]: {
    display: 'unset',
    justifyContent: 'unset',
    alignItems: 'unset',
    '& .createWO': {
      marginTop: 10,
    },
  },

  [theme.breakpoints.down('sm')]: {
    display: 'unset',
    justifyContent: 'unset',
    alignItems: 'unset',
    '& .createWO': {
      marginTop: 0,
      width: '98%',
    },
  },
}));

export const DashboardName = styled('span')(() => ({
  fontSize: 20,
  lineHeight: '27.32px',
  color: '#000000DE',
  fontWeight: 600,
  display: 'block',
  flex: 1,
}));
export const DashboardCmpHeading = styled('span')(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  color: '#000000DE',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
export const BreadcrumbHeadingCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  flex: 1,
  '.dashboardTitleInput': {
    flex: 0.9,
    input: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '27.32px',
      color: '#000000',
      padding: '5.5px 14px 5.5px 10px',
      '&:hover': {
        backgroundColor: '#f5f5f5',
        borderRadius: 8,
      },
      '&:focus': {
        backgroundColor: '#f5f5f5',
        borderRadius: 8,
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: 10,
  },
  '.rightArrowIcon': {
    margin: '0 0 0 4px',
    fontSize: 18,
  },
  '& button': {
    padding: 0,
    marginRight: 13,
    '& svg': {
      fontSize: 18,
    },
  },
}));
export const EditSwitchCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: 132,
  borderRadius: 6,
  border: '1px solid #BDBDBD',
  padding: '0 0 0 12px',
  height: 38,
  '.editLabel': {
    fontSize: 14,
    fontWeight: 500,
    color: '#000000',
    margin: 0,
  },
}));
