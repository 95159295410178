// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';

// MUI components
import Box from '@mui/material/Box';

// Utilities
import { ReactComponent as PublicChecklist } from 'public/assets/img/public-checklist.svg';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DIALOGS from 'utils/dialogIds';
import appConstants from 'utils/appConstants';
import { setDialog } from 'store/actions/dialogActions';
import TaskIcon from 'components/Icons/sidebarIcons/taskIcon';
import selectors from 'store/selectors';

const ChecklistCreate = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const { data = {} } = dialogState?.[DIALOGS?.CREATE_TEMPLATE_DIALOG] || [];
  const handleDialogClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_TEMPLATE_DIALOG,
      }),
    );
  };
  return (
    <Box>
      <Box className="d-flex align-items-center mb-4">
        <Box
          sx={{
            width: '294px',
            height: '168px',
            padding: '24px 30px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
            '&:hover': {
              border: '1px solid #6868FE',
            },
            '&:active': {
              background: '#EBEAFF',
            },
          }}
          className="text-center mr-3 pointer-cursor"
          onClick={() => {
            handleDialogClose();
            navigateWithWorkspaceUrl(
              `/checklist/new${
                data.folderId ? '/folder/' + data.folderId : ''
              }`,
            );
          }}
        >
          <Box className="mb-3">
            <TaskIcon />
          </Box>
          <Box
            className="mb-2"
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              color: '#212121',
            }}
          >
            Build from scratch
          </Box>
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              color: '#666666',
            }}
          >
            Start an {appConstants.checklist.singular.lowercase} from scratch
          </Box>
        </Box>
        <Box
          sx={{
            width: '294px',
            height: '168px',
            padding: '24px 16px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
            '&:hover': {
              border: '1px solid #6868FE',
            },
            '&:active': {
              background: '#EBEAFF',
            },
          }}
          className="text-center pointer-cursor"
          onClick={() => {
            handleDialogClose();
            dispatch(
              actions.setDialog({
                dialogId: DIALOGS.TEMPLATE_LIBRARY,
                open: true,
              }),
            );
          }}
        >
          <Box className="mb-3">
            <PublicChecklist />
          </Box>
          <Box
            className="mb-2"
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              color: '#212121',
            }}
          >
            Use a Public Template
          </Box>
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              color: '#666666',
            }}
          >
            Browse and select a template from the public template library
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          fontWeight: '500',
          fontSize: '12px',
          color: '666666',
        }}
        className="text-center"
      >
        Want us to digitize your form?
        <a
          className="email-link"
          href="mailto:templates@xenia.team"
          style={{ fontSize: 14 }}
        >
          &nbsp;Click here and send it to us.
        </a>
      </Box>
    </Box>
  );
};

export default ChecklistCreate;
