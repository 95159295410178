import { Stack } from '@mui/material';
import { statusDropdownData } from 'helper/task/dropdownData';
import { TaskStatusCountCnt, TileInnerCnt } from './taskStatusCounts.style';

function TaskStatusCounts(props) {
  const { taskStats } = props;

  return (
    <TaskStatusCountCnt>
      <Stack flexDirection="row" gap="10px">
        {statusDropdownData?.map((s) => {
          const itemCount = taskStats?.[s?.value] ?? 0;

          return (
            <div style={{ width: '100%' }}>
              <TileInnerCnt>
                <>
                  <label className={'statusText'} style={{ color: s?.color }}>
                    {s.label}
                  </label>

                  <label className={'countsText'}>{itemCount}</label>
                </>
              </TileInnerCnt>
            </div>
          );
        })}
      </Stack>
    </TaskStatusCountCnt>
  );
}

export default TaskStatusCounts;
