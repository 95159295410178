import { useContext } from 'react';
import { CreateWidgetContext } from '../../context/context';
import {
  WidegetPreviewCnt,
  WidgetContentWrapper,
  WidgetHeading,
} from './widegetPreview.style';
import { Stack, Typography } from '@mui/material';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import BarChartImg from 'public/assets/img/chart-preview/bar-chart.svg';
import PieChartImg from 'public/assets/img/chart-preview/pie-chart.svg';
import LineChartImg from 'public/assets/img/chart-preview/line-chart.svg';
import TableImg from 'public/assets/img/chart-preview/table.svg';
import { WIDGET_TYPE_IDS } from '../chartTypes/constants';
import { CountCnt } from 'pages/Dashboards/Widgets/numerWidget/numberWidget.style';

function WidegetPreview({ widgetData }) {
  const { createWidgetState } = useContext(CreateWidgetContext);

  const loading = false;
  return (
    <WidegetPreviewCnt>
      <WidgetHeading>
        <Stack direction="column">
          <Typography className="info">
            This is a preview with sample data
          </Typography>
          <Typography className="title">
            {widgetData?.title || 'Chart Title'}
          </Typography>
        </Stack>
      </WidgetHeading>

      <WithLoader isLoading={loading}>
        <WidgetContentWrapper>
          {widgetData?.widgetType == WIDGET_TYPE_IDS.BAR && (
            <img className="previewImage" src={BarChartImg} />
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.LINE && (
            <img className="previewImage" src={LineChartImg} />
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.PIE && (
            <img className="previewImage" src={PieChartImg} />
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.NUMBER && (
            <CountCnt>
              <label className={'countsText'}>50</label>
            </CountCnt>
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.TABLE && (
            <img className="previewImage" src={TableImg} />
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.CUSTOM_TABLE && (
            <img className="previewImage" src={TableImg} />
          )}
        </WidgetContentWrapper>
      </WithLoader>
    </WidegetPreviewCnt>
  );
}

export default WidegetPreview;
