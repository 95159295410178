import { useState } from 'react';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
import { FiltersCnt } from './style';

function ProjectTasksFilters(props) {
  const { isMobileDeviceSm } = useResponsive();
  const { filters, updateDateRangeCB } = props;
  const [selectedDateOption, setSelectedDateOption] = useState('Any');

  const handleDateChange = (date) => {
    setSelectedDateOption(date?.selectedDateOption);
    updateDateRangeCB(date);
  };

  return (
    <FiltersCnt>
      <DateRangePickerCommon
        buttonProps={{
          hideSelected: true,
        }}
        handleDateChange={handleDateChange}
        selectedDateLabel={selectedDateOption}
        showLabelPrefix="Date Range: "
        selectedDateRange={{
          from: filters?.value?.[0] ?? null,
          to: filters?.value?.[1] ?? null,
        }}
        contentProps={{
          displaySideOptions: !isMobileDeviceSm,
          allowInputs: !isMobileDeviceSm,
          numberOfMonths: isMobileDeviceSm ? 1 : 2,
          sideMenu: dateRangePickerSideMenuCommon,
        }}
        popperProps={{
          placement: 'bottom-start',
        }}
      />
    </FiltersCnt>
  );
}

export default ProjectTasksFilters;
