import styled from '@mui/system/styled';

export const TaskOverviewHeaderCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px 30px',
  '.headingText': {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '32px',
    margin: 0,
  },
  '.taskOverviewHeaderLeft': {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  '.taskOverviewHeaderRight': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
});
