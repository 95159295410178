// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '700',
  letterSpacing: '0.15px',
  lineHeight: '31.5px',
  color: 'rgba(33, 33, 33, 1)',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  height: 24,
  width: 24,
  '& svg': {
    fontSize: 24,
  },
}));
