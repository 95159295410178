import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddPhotoIcon from 'components/Icons/addPhotoIcon';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import { ReactComponent as AddSectionIcon } from 'public/assets/img/add-section-icon.svg';
import CustomButton from 'components/Button/CustomButton';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import HideDialog from './HideDeleteDialog/HideDialog';
import DeleteDialog from './HideDeleteDialog/DeleteDialog';
import { categoryToGroupUnitsMapping } from 'pages/checklistV2/mappings';
import StepTypes from 'pages/checklistV2/stepTypes';
import ChecklistAttachmentPreview from 'components/common/jsxrender/checklistV2/checklistAttachmentPreview';
import { setDialog } from 'store/actions/dialogActions';
import Dropzone from 'react-dropzone';
import mime from 'mime';
import { debounce, includes, sample } from 'lodash';
import MultipleChoice from 'components/common/jsxrender/checklistV2/builder/multipleChoice';
import Automations from './automations';
import { v4 as uuid } from 'uuid';
import { _colors } from 'components/ColorPicker';
import {
  LearnMoreBtn,
  AddStepButton,
  ChecklistItemCnt,
  ChecklistItemParent,
  CircleIcon,
} from 'components/common/jsxrender/checklistV2/builder/checklistItem.style';
import DialogIds from 'utils/dialogIds';
import xeniaApi from 'api/index';
import Stack from '@mui/material/Stack';
import { AUTOMATION_ACTIONS } from 'components/AllDialogs/automation/utils/constants';
import { getAnswerView } from 'components/common/jsxrender/checklistV2/builder/automations/previewItem';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import ChecklistItems from 'pages/checklistV2/Builder/checklistItems';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Divider } from '@mui/material';
import ProcedureIcon from 'components/Icons/templateStepIcons2/procedureIcon';
import PassFailIcon from 'components/Icons/templateStepIcons2/passFailIcon';
import TakePhotoIcon from 'components/Icons/templateStepIcons2/takePhotoIcon';
import NumberIcon from 'components/Icons/templateStepIcons2/numberIcon';
import TemperatureIcon from 'components/Icons/templateStepIcons2/temperatureIcon';
import CostIcon from 'components/Icons/templateStepIcons2/costIcon';
import McqIcon from 'components/Icons/templateStepIcons2/mcqIcon';
import SignatureIcon from 'components/Icons/templateStepIcons2/signatureIcon';
import DateTimeIcon from 'components/Icons/templateStepIcons2/dateTimeIcon';
import LongDescriptionIcon from 'components/Icons/templateStepIcons2/longDescriptionIcon';
import GeoStampIcon from 'components/Icons/templateStepIcons2/geoStampIcon';
import DropdownIcon from 'components/Icons/templateStepIcons2/dropdownIcon';
import LocationIcon from 'components/Icons/templateStepIcons2/locationIcon';
import PhotoAnnotateIcon from 'components/Icons/templateStepIcons2/photoAnnotateIcon';
import TextFieldIcon from 'components/Icons/templateStepIcons2/textFieldIcon';
import AutomationIconRounded from 'components/Icons/automationIconRounded';
import IosSwitch from 'components/IosSwitch';
import { NUMBER_UNIT_OPTIONS } from 'utils/constants';
import {
  ChevronRightRounded,
  DoneRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import DeleteIcon from 'components/Icons/deleteIcon';
import { Collapse } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FileIcon from 'components/FileIcon';
import { showMessageNotification } from 'utils/globalFunction';
import { upsertAutomations } from 'api/automations';
import InstructionDialog from './InstructionDialog';
import { STEP_TYPES } from 'utils/templates';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FileIconWithAttachment from 'components/Icons/fileIconWithAttachment';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { DiscriptionCtn } from './multipleChoice/styled';
import StepDescriptionDialog from './StepDescriptionDialog/StepDescriptionDialog';
import ImageFileUpload from 'components/ImageFileUpload';
import CustomListItem from 'components/List/ListItem/ListItem';
import CustomToolTip from 'components/Tooltip/tooltip';

const nonAutomationFields = {
  takePhoto: true,
  signature: true,
  instruction: true,
  geoStamp: true,
  photoAnnotate: true,
};

const ChecklistItem: FC<any> = (props) => {
  const {
    checklist,
    checklistId,
    checklistItems,
    checklistItemData,
    checklistItemIndex,
    defaultLocationStep,
    setDefaultLocationStep,
    addChecklistItem,
    autoFocusItemId,
    setAutoFocusItemId,
    sortableProps,
    automations,
    fetchAndSetChecklistAutomations,
    handleAddFollowUpItem,
    handleAddChecklistItem,
    childItems,
    deleteChecklistItemById,
    handleUpdateChecklistItems,
    addingItem,
    sectionSteps,
    sectionVisibility,
    toggleSectionCollapse,
    sectionEditingId,
    setSectionEditingId,
    originalChecklistItems,
    handleSetFollowupVisibility,
    followupVisibility,
    itemCollapseState,
    handleItemCollapse,
    autoFocusCursorPosition,
    setAutofocusCursorPosition,
    getSectionId,
    handleCollapseAllItems,
    uploadingItemId,
    setUploadingItemId,
    checklistItemsHashRef,
    updateChecklistItemsHash,
    uploadAttachmentsToStep,
  } = props;

  const dispatch = useDispatch();
  const [showUnits, setShowUnits] = useState<any>(null);
  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const [selectedAttachment, setSelectedAttachment]: any = useState(null);
  const [attachmentsData, setAttachmentsData]: any = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showHideDialog, setShowHideDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { workspaceId } = useWorkspaceHook();
  const { setNodeRef, listeners, attributes } = sortableProps || {};
  const { FEATURES, hasFeature } = useCustomPermission();
  const descriptionRef = useRef<HTMLInputElement>(null);
  const [stepDropdownRef, setStepDropdownRef] = useState<any>(null);
  const [localChecklistItemData, setLocalChecklistItemData] = useState<any>(
    checklistItemsHashRef?.[checklistItemData?.id] ?? checklistItemData,
  );
  const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);
  const [addingItemLocal, setAddingItemLocal] = useState(false);
  const [instructionDialogOpen, setInstructionDialogOpen] = useState(false);
  // const [optionDescription, setOptionDescription] = useState('');

  const getMainItemUpdatesScore = (itemData: any) => {
    const newItem = {
      ...itemData,
    };

    if (
      checklist?.isScoring &&
      (itemData.type === STEP_TYPES.MULTIPLE_CHOICE ||
        itemData.type === STEP_TYPES.DROPDOWN)
    ) {
      if (itemData.options.length) {
        if (itemData.type === STEP_TYPES.MULTIPLE_CHOICE) {
          newItem.score = Math.max(
            0,
            ...(itemData?.options
              ?.filter(
                (item) => item?.score !== null && item?.score !== undefined,
              )
              ?.map((o) => o?.score) || []),
          );
        }
        if (
          itemData.type === STEP_TYPES.DROPDOWN &&
          itemData.unit === 'multiple'
        ) {
          newItem.score = itemData.options.reduce(
            (acc, o) => acc + (o?.score || 0),
            0,
          );
        }
        if (
          itemData.type === STEP_TYPES.DROPDOWN &&
          (itemData.unit === 'single' || !itemData.unit)
        ) {
          newItem.score = Math.max(
            0,
            ...(itemData?.options
              ?.filter(
                (item) => item?.score !== null && item?.score !== undefined,
              )
              ?.map((o) => o?.score) || []),
          );
        }
      } else {
        newItem.score = 0;
      }
    }
    return newItem;
  };

  const updateItemApiCall = async (itemData = null) => {
    const obj = itemData || localChecklistItemData;
    await xeniaApi.modifyChecklistItem(checklist.id, obj.id, {
      description: obj.description,
      order: obj.order,
      type: obj.type,
      attachments: obj.attachments,
      isImageOrNoteRequired: obj.isImageOrNoteRequired,
      unit: obj.unit,
      options: obj.options,
      isActive: obj.isActive,
      required: obj.required,
      ParentId: obj.ParentId,
      RelatedAutomationId: obj.RelatedAutomationId,
      score: obj.score,
      info: {
        optionDescription: obj?.info?.optionDescription || null,
      },
    });
  };

  const updateItemHandler = async (
    key: string,
    value: any,
    apiCall = false,
  ) => {
    const updatedItem = getMainItemUpdatesScore({
      ...localChecklistItemData,
      [key]: value,
    });
    setLocalChecklistItemData(updatedItem);
    updateChecklistItemsHash(localChecklistItemData.id, updatedItem);
    if (apiCall) {
      await updateItemApiCall(updatedItem);
    }
  };

  const updateItemObjHandler = async (itemData: any, apiCall = false) => {
    const updatedItem = getMainItemUpdatesScore(itemData);
    setLocalChecklistItemData(updatedItem);
    updateChecklistItemsHash(localChecklistItemData.id, updatedItem);
    if (apiCall) {
      await updateItemApiCall(updatedItem);
    }
  };

  const isUploading = useMemo(
    () => uploadingItemId === localChecklistItemData.id,
    [uploadingItemId, localChecklistItemData],
  );

  const isPhotoAnnotationStep = useMemo(
    () => localChecklistItemData.type === STEP_TYPES.PHOTO_ANNOTATE,
    [localChecklistItemData],
  );

  const hasAttachments = useMemo(
    () => localChecklistItemData.attachments?.length > 0,
    [localChecklistItemData],
  );

  const hasAnnotation = useMemo(
    () => localChecklistItemData.options?.annotation?.length > 0,
    [localChecklistItemData],
  );

  const annotationImage = useMemo(
    () => (hasAnnotation ? localChecklistItemData.options?.annotation : []),
    [hasAnnotation, localChecklistItemData],
  );

  const { ParentId } = localChecklistItemData || {};

  useEffect(() => {
    if (localChecklistItemData?.attachments?.length) {
      setAttachmentsData(
        localChecklistItemData.attachments.map((attachment: any) => {
          return {
            url: attachment,
            type: mime.getType(attachment),
          };
        }),
      );
    }
  }, [localChecklistItemData?.attachments?.length]);

  const itemAutomations = useMemo(() => {
    return automations?.[localChecklistItemData.id] || [];
  }, [automations]);

  const isSectionActive = useMemo(() => {
    if (ParentId) return true;
    if (localChecklistItemData.type === STEP_TYPES.HEADER) {
      return localChecklistItemData.isActive;
    }
    const sectionId = getSectionId?.(checklistItemIndex);
    if (!sectionId) return true;
    const sectionItem = originalChecklistItems.find(
      (item) => item.id === sectionId,
    );
    return sectionItem?.isActive;
  }, [localChecklistItemData, originalChecklistItems]);

  const previewAttachment = (attachmentIndex: any) => {
    setSelectedAttachment(attachmentIndex);
    setShowAttachmentPreview(true);
  };

  const hideAttachmentPreview = () => {
    setSelectedAttachment(null);
    setShowAttachmentPreview(false);
  };

  const removeUnits = () => {
    setShowUnits(false);
    updateItemHandler('unit', '', true);
    // handleChecklistItemChange({
    //   ...localChecklistItemData,
    //   unit: '',
    //   description: descriptionRef?.current?.value,
    // });
  };

  const handleStepTypeChange = (stepType: string) => {
    const itemType = stepType;
    const isImageOrNoteRequired =
      localChecklistItemData.type !== STEP_TYPES.TAKE_PHOTO &&
      stepType === STEP_TYPES.TAKE_PHOTO
        ? false
        : localChecklistItemData.isImageOrNoteRequired;

    const itemData = {
      ...localChecklistItemData,
      isImageOrNoteRequired,
      type: itemType,
    };

    if (includes([STEP_TYPES.COST, STEP_TYPES.TEMPERATURE], itemType)) {
      itemData.unit = itemType === STEP_TYPES.COST ? '$' : '°F';
    } else if (itemType === STEP_TYPES.DATE_TIME) {
      itemData.unit = 'date';
    } else {
      itemData.unit = '';
    }
    if (itemType === STEP_TYPES.PASS_FAIL) {
      itemData.options = [
        {
          label: 'Pass',
          value: 'pass',
          type: 'success',
        },
        {
          label: 'Fail',
          value: 'fail',
          type: 'error',
        },
        {
          label: 'N/A',
          value: 'na',
          type: 'info',
        },
      ];
    } else if (
      itemType === STEP_TYPES.MULTIPLE_CHOICE ||
      itemType === STEP_TYPES.DROPDOWN
    ) {
      itemData.options = [
        {
          id: uuid(),
          label: '',
          order: 0,
          color: sample(_colors) as string,
          flag: false,
          ...(checklist?.isScoring && { score: 1 }),
        },
      ];
    } else if (itemType === STEP_TYPES.INSTRUCTION) {
      itemData.options = {
        requireAcknowledgement: true,
        instruction: '',
      };
    } else if (itemType === STEP_TYPES.PHOTO_ANNOTATE) {
      itemData.options = {
        annotation: [],
      };
    } else {
      itemData.options = null;
    }

    if (itemType === STEP_TYPES.INSTRUCTION) {
      openInstructionDialog();
    }

    const automationIds = itemAutomations.map(
      (automation: any) => automation.id,
    );
    if (automationIds?.length) onAutomationsDelete(automationIds);
    updateItemObjHandler(itemData, true);
  };

  const handleSyncDescription = () => {
    updateItemHandler('description', descriptionRef?.current?.value, true);
  };

  const handleAddUnit = (unit: string) => {
    updateItemHandler('unit', unit, true);
  };

  const onDescriptionChange = useCallback(
    debounce((event) => {
      updateItemApiCall();
    }, 100),
    [localChecklistItemData],
  );

  const onScoreChange = useCallback(
    debounce((event) => {
      if (
        Number.isFinite(Number(event.target.value)) ||
        event.target.value === null
      ) {
        updateItemApiCall();
      } else if (event.target.value === '/') {
        updateItemHandler('score', null, true);
      } else {
        showMessageNotification('Score must be a number', 'error');
      }
    }, 100),
    [localChecklistItemData],
  );

  const onMultipleChoiceItemChange = useCallback(
    (options) => {
      updateItemHandler('options', options, true);
    },
    [localChecklistItemData],
  );
  // const onInstructionChange = useCallback(
  //   debounce((text) => {
  //     setOptionDescription(text);
  //     updateItemHandler('info', { optionDescription: text }, true);
  //   }, 500),
  //   [localChecklistItemData],
  // );
  // useEffect(() => {
  //   setOptionDescription(localChecklistItemData.info?.optionDescription || '');
  // }, [localChecklistItemData]);
  const getMimeType = (attachmentUrl: string): string => {
    return mime.getType(attachmentUrl) || '';
  };

  // function AlertDialog({ open, handleClose, onDeleteAll, onDeleteSection }) {
  //   return (
  //     <div onClick={(e) => e.stopPropagation()}>
  //       <Dialog
  //         sx={{
  //           '& .MuiPaper-root': {
  //             borderRadius: '8px',
  //             width: '444px',
  //             maxHeight: 'none',
  //           },
  //         }}
  //         open={open}
  //         onClose={handleClose}
  //       >
  //         <DialogTitle
  //           sx={{
  //             fontWeight: 700,
  //             fontSize: '20px',
  //             textAlign: 'center',
  //             p: '20px 24px 8px 24px',
  //           }}
  //         >
  //           Delete
  //         </DialogTitle>
  //         <DialogContent
  //           sx={{
  //             p: '8px 24px',
  //             display: 'flex',
  //             flexDirection: 'column',
  //             alignItems: 'center',
  //           }}
  //         >
  //           <Typography
  //             sx={{
  //               color: 'rgba(0, 0, 0, 0.87)',
  //               fontSize: '16px',
  //               textAlign: 'center',
  //               width: '300px',
  //             }}
  //           >
  //             This section contains one or more steps. What do you want to
  //             delete?
  //           </Typography>
  //         </DialogContent>

  //         <DialogActions
  //           sx={{
  //             flexDirection: 'column',
  //             alignItems: 'center',
  //             p: '8px 24px 36px 24px',
  //             gap: '12px',
  //             '& button': {
  //               borderRadius: '4px',
  //               width: '300px',
  //               fontSize: '14px',
  //               fontWeight: '500',
  //               height: '36px',
  //               textTransform: 'none',
  //             },
  //           }}
  //         >
  //           <CustomButton
  //             variant={'contained'}
  //             color="error"
  //             onClick={onDeleteAll}
  //           >
  //             Delete Section & its Steps
  //           </CustomButton>
  //           <CustomButton
  //             variant={'outlined'}
  //             color={'error'}
  //             onClick={onDeleteSection}
  //             sx={{
  //               m: '0px !important',
  //             }}
  //           >
  //             Delete Section Only
  //           </CustomButton>
  //         </DialogActions>
  //       </Dialog>
  //     </div>
  //   );
  // }

  function AlertDialog({ open, handleClose, success }) {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{ fontWeight: 700 }}>Delete Section</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                Are you sure you want to delete this Section?
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ marginRight: 2, marginBottom: 2 }}>
            <CustomButton variant={'text'} onClick={handleClose}>
              <Box sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Cancel</Box>
            </CustomButton>
            <CustomButton
              className="text-capitalize"
              variant={'contained'}
              color={'error'}
              onClick={success}
            >
              Delete
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    setDeleteDialogOpen(false);
    deleteChecklistItemById(localChecklistItemData.id);
  };

  const _handleDeleteChecklistItem = (e) => {
    if (addingItem || isUploading) return;
    e.stopPropagation();
    onDescriptionChange.cancel();
    deleteChecklistItemById(localChecklistItemData.id);
  };

  const _handleAddFollowUpItem = ({
    automationId,
    itemType = STEP_TYPES.TEXT_FIELD,
  }: {
    automationId?: string;
    itemType?: string;
  }) => {
    const newChildItem = {
      id: uuid(),
      description: '',
      order: childItems?.length,
      type: itemType,
      score: 1,
      isActive: true,
      attachments: [],
      ParentId: localChecklistItemData.id,
      RelatedAutomationId:
        automationId ?? localChecklistItemData.RelatedAutomationId,
      isImageOrNoteRequired: false,
      ...([STEP_TYPES.MULTIPLE_CHOICE, STEP_TYPES.DROPDOWN].includes(
        localChecklistItemData.type,
      ) && { options: localChecklistItemData.options }),
    };
    handleAddFollowUpItem(newChildItem);
  };

  const handleAddAnotherQuestion = (
    itemType = STEP_TYPES.TEXT_FIELD,
    prevItemData?: any,
  ) => {
    const newChildItem = {
      id: uuid(),
      description: '',
      order: originalChecklistItems?.length,
      type: itemType,
      score: 1,
      isActive: true,
      attachments: [],
      ParentId: localChecklistItemData.ParentId,
      RelatedAutomationId: localChecklistItemData.RelatedAutomationId,
      isImageOrNoteRequired: false,
      ...([
        STEP_TYPES.MULTIPLE_CHOICE,
        STEP_TYPES.DROPDOWN,
        STEP_TYPES.INSTRUCTION,
      ].includes(localChecklistItemData.type) && {
        options: localChecklistItemData.options,
      }),
    };
    handleAddFollowUpItem(newChildItem, prevItemData);
  };

  // Bind Task from Template
  const autoCreateTaskFromTemplate = async (data, automation) => {
    const [action] = automation?.actions || [];
    const newAction = { ...action, data };
    const updatedAutomation = {
      ...automation,
      actions: [newAction],
    };

    await upsertAutomations({
      automations: [updatedAutomation],
    });

    fetchAndSetChecklistAutomations?.();
  };

  const onAddOrEditAutomation = useCallback(() => {
    if (addingItem || isUploading) return;
    dispatch(
      setDialog({
        open: true,
        dialogId: DialogIds.AUTOMATION,
        data: {
          entity: 'checklistItem',
          field: localChecklistItemData,
          automations: itemAutomations,
          onAutomationsDelete,
          onClose: async (refetch, automation) => {
            if (refetch && workspaceId) {
              fetchAndSetChecklistAutomations();
            }

            dispatch(
              actions.setDialog({
                dialogId: DialogIds.AUTOMATION,
                open: false,
                data: null,
              }),
            );

            if (refetch && automation) {
              const [action] = automation.actions;

              switch (action?.type) {
                case AUTOMATION_ACTIONS.FOLLOWUP_QUESTIONS:
                  {
                    _handleAddFollowUpItem({
                      automationId: automation.id,
                    });
                  }
                  break;

                case AUTOMATION_ACTIONS.SEND_NOTIFICATION:
                  dispatch(
                    actions.setDialog({
                      dialogId: DialogIds.NOTIFICATION_AUTOMATION,
                      open: true,
                      data: {
                        field: { ...localChecklistItemData, checklistId },
                        automation,
                        onSave: () => fetchAndSetChecklistAutomations(),
                      },
                    }),
                  );
                  break;
                case AUTOMATION_ACTIONS.FLAG_RESPONSE:
                  dispatch(
                    actions.setDialog({
                      dialogId: DialogIds.FLAGGED_CATEGORY_SELECTION_AUTOMATION,
                      open: true,
                      data: {
                        field: { ...localChecklistItemData, checklistId },
                        automation,
                        onSave: () => fetchAndSetChecklistAutomations(),
                      },
                    }),
                  );
                  break;

                case AUTOMATION_ACTIONS.AUTO_CREATE_TASK_FROM_TEMPLATE:
                  dispatch(
                    actions.setDialog({
                      dialogId: DialogIds.ATTACH_TASK_TEMPLATE_MODAL,
                      open: true,
                      data: {
                        onConfirm: (data) =>
                          autoCreateTaskFromTemplate(data, automation),
                      },
                    }),
                  );
                  break;
              }
            }
          },
        },
      }),
    );
  }, [checklistId, localChecklistItemData, itemAutomations, workspaceId]);

  const onAutomationsDelete = useCallback(
    async (automationIds: string[]) => {
      if (workspaceId) {
        await xeniaApi.deleteAutomation({
          automationIds,
        });
        fetchAndSetChecklistAutomations();
      }
    },

    [checklistId, workspaceId],
  );

  const onUploadAttachment = useCallback(
    async (acceptedFiles) => {
      uploadAttachmentsToStep(localChecklistItemData.id, acceptedFiles);
    },

    [localChecklistItemData, descriptionRef],
  );

  const onDeleteAttachment = useCallback(
    (attachmentIndex: number) => {
      const attachments = [...localChecklistItemData.attachments];
      attachments.splice(attachmentIndex, 1);
      updateItemHandler('attachments', attachments, true);
    },

    [localChecklistItemData],
  );

  const onDeleteAnnotation = useCallback(() => {
    updateItemHandler(
      'options',
      {
        ...(localChecklistItemData.options || {}),
        annotation: [],
      },
      true,
    );
  }, [localChecklistItemData]);

  const toggleStepVisibility = () => {
    const newItemData = {
      ...localChecklistItemData,
      isActive: !localChecklistItemData.isActive,
      description: descriptionRef?.current?.value,
    };
    // updateChecklistItemById(localChecklistItemData.id, newItemData);
    updateItemHandler('isActive', !localChecklistItemData.isActive, true);
    handleItemCollapse(localChecklistItemData.id);
  };

  const handleHideStep = () => {
    toggleStepVisibility();
    handleItemCollapse(localChecklistItemData.id, false);
    setShowHideDialog(false);
    setShowDeleteDialog(false);
  };

  const handleHideSection = () => {
    const sectionIdToHide = localChecklistItemData.id;
    const newSteps = originalChecklistItems.map((cItem, index) => {
      const sectionIdForStep = getSectionId?.(index);
      if (
        cItem.id === sectionIdToHide ||
        sectionIdForStep === sectionIdToHide
      ) {
        return {
          ...cItem,
          isActive: false,
        };
      }
      return cItem;
    });
    handleUpdateChecklistItems(ParentId, newSteps);
    setAutoFocusItemId?.(null);
  };

  const handleUnhideSection = () => {
    const sectionIdToShow = localChecklistItemData.id;
    const newSteps = originalChecklistItems.map((cItem, index) => {
      const sectionIdForStep = getSectionId?.(index);
      if (
        cItem.id === sectionIdToShow ||
        sectionIdForStep === sectionIdToShow
      ) {
        return {
          ...cItem,
          isActive: true,
        };
      }
      return cItem;
    });
    handleUpdateChecklistItems(ParentId, newSteps);
  };

  const handleUnhideStep = () => {
    toggleStepVisibility();
  };

  const dropdownOptions = [
    {
      id: 'hideStep',
      label: 'Hide Step',
      icon: <VisibilityOffRoundedIcon />,
      sx: {
        '& svg': {
          fontSize: 20,
          color: '#000',
        },
      },
      iconAlign: 'start',
    },
  ];

  const refrenceDropdownOptions = [
    {
      id: 'addFile',
      label: 'Add File',
      itemRenderer: (
        <div onClick={(e) => e.stopPropagation()}>
          <ImageFileUpload
            onUploadCallback={(files) => {
              onUploadAttachment(files);
            }}
            isPreview={isUploading}
            options={{
              maxFiles: isPhotoAnnotationStep ? 1 : undefined,
              accept: {
                ...(isPhotoAnnotationStep
                  ? {
                      'image/jpeg': [],
                      'image/png': [],
                      'image/jpg': [],
                    }
                  : undefined),
              },
              multiple: isPhotoAnnotationStep ? false : true,
            }}
          >
            <CustomListItem
              key={'Change photo'}
              id={'Change photo' + 'option'}
              checkbox={false}
              startIcon={
                isPhotoAnnotationStep ? (
                  <AddPhotoIcon
                    sx={{
                      fontSize: '18px',
                      '& path': {
                        stroke: '#616161',
                      },
                    }}
                  />
                ) : (
                  <FileIconWithAttachment
                    style={{
                      fontSize: 20,
                      color: 'transparent',
                    }}
                  />
                )
              }
            >
              {isPhotoAnnotationStep
                ? hasAnnotation
                  ? 'Replace Photo'
                  : 'Add Photo to Annotate'
                : 'Add File'}
            </CustomListItem>
          </ImageFileUpload>
        </div>
      ),
      // icon: <FileIconWithAttachment />,
      // sx: {
      //   '& svg': {
      //     fontSize: 20,
      //     color: 'transparent',
      //   },
      // },
      // iconAlign: 'start',
    },
    // {
    //   id: 'addPhoto',
    //   label: 'Add Photo',
    //   icon: <UploadImage />,
    //   sx: {
    //     '& svg': {
    //       fontSize: 20,
    //       '& path': {
    //         stroke: '#616161',
    //       },
    //     },
    //   },
    //   iconAlign: 'start',
    // },
    {
      id: 'addInstruction',
      label: localChecklistItemData?.info?.optionDescription
        ? 'Update Instruction'
        : 'Add Instruction',
      icon: <FormatAlignLeftIcon />,
      sx: {
        '& svg': {
          fontSize: 20,
          color: '#616161',
        },
      },
      iconAlign: 'start',
    },
  ];

  const handleHelpOptionSelect = (option: any) => {
    switch (option.id) {
      case 'hideStep':
        if (addingItem || isUploading) return;
        setShowHideDialog(true);
        return;
      default:
        break;
    }
  };
  const handleReferenceOptionSelect = (option: any) => {
    switch (option.id) {
      case 'addInstruction':
        {
          setDescriptionDialogOpen(true);
          updateItemHandler('isInstructionEnabled', true, false);
        }
        return;
      default:
        break;
    }
  };

  const deleteReference = async (e) => {
    e.stopPropagation();
    const updatedItem = getMainItemUpdatesScore({
      ...localChecklistItemData,
      isInstructionEnabled: false,
      info: {},
    });
    setLocalChecklistItemData(updatedItem);
    updateChecklistItemsHash(localChecklistItemData.id, updatedItem);
    await updateItemApiCall(updatedItem);
    handleItemCollapse(localChecklistItemData.id, true, null, false);
  };
  const openInstructionDialog = () => {
    setInstructionDialogOpen(true);
  };

  const handleDescriptionClick = (e) => {
    if (!localChecklistItemData.isActive) return;
    e.stopPropagation();
    if (autoFocusItemId !== localChecklistItemData.id) {
      handleItemClick(null);
    }
  };

  const handleItemClick = (e) => {
    if (!localChecklistItemData.isActive || isUploading) return;
    handleItemCollapse(localChecklistItemData.id);
  };

  const showAddButtons = useMemo(
    () =>
      checklistItems &&
      !(
        checklistItems?.length === 1 &&
        checklistItems[checklistItems?.length - 1].type === STEP_TYPES.HEADER
      ) &&
      (checklistItemIndex === checklistItems?.length - 1 ||
        checklistItems[checklistItemIndex + 1]?.type === STEP_TYPES.HEADER) &&
      sectionVisibility[localChecklistItemData.id] !== false,
    [checklistItems, checklistItemIndex],
  );

  const noSteps = useMemo(
    () =>
      originalChecklistItems &&
      originalChecklistItems.length === 1 &&
      (!checklistItems[checklistItemIndex + 1] ||
        checklistItems[checklistItemIndex + 1]?.type === STEP_TYPES.HEADER) &&
      checklistItems[checklistItemIndex].type === STEP_TYPES.HEADER,
    [checklistItems, checklistItemIndex],
  );

  const isUnderSection = useMemo(() => {
    return (
      checklistItems &&
      checklistItems.filter(
        (item, idx) =>
          item.type === STEP_TYPES.HEADER && idx < checklistItemIndex,
      ).length > 0
    );
  }, [checklistItems, checklistItemIndex]);

  const unitOptionsTempOrCostOptions = useMemo(() => {
    if (!localChecklistItemData) return [];
    if (
      ![STEP_TYPES.COST, STEP_TYPES.TEMPERATURE].includes(
        localChecklistItemData.type,
      )
    )
      return [];
    const currOptionMap =
      categoryToGroupUnitsMapping[localChecklistItemData.type];
    const currOptionKeys = Object.keys(currOptionMap);
    return currOptionKeys.map((unit, index) => {
      return {
        id: unit,
        value: currOptionMap[unit].value,
        label: unit,
      };
    });
  }, [localChecklistItemData]);

  const selectedUnit = useMemo(() => {
    if (localChecklistItemData.type === STEP_TYPES.NUMBER) {
      let allChilds: any = [];
      NUMBER_UNIT_OPTIONS.forEach((o) => {
        allChilds = [...allChilds, ...o.child];
      });
      const selectedChild = allChilds.find(
        (o) => o.id === localChecklistItemData.unit,
      );
      return selectedChild;
    } else if (
      localChecklistItemData.type === STEP_TYPES.COST ||
      localChecklistItemData.type === STEP_TYPES.TEMPERATURE
    ) {
      return unitOptionsTempOrCostOptions
        ? unitOptionsTempOrCostOptions.find(
            (o) => o.id === localChecklistItemData.unit,
          )
        : null;
    }
    return null;
  }, [localChecklistItemData]);

  const currentOptionsForUnits = useMemo(
    () =>
      localChecklistItemData.type === STEP_TYPES.NUMBER
        ? NUMBER_UNIT_OPTIONS
        : unitOptionsTempOrCostOptions,
    [localChecklistItemData],
  );

  const getNextItemType = () => {
    return localChecklistItemData.type === STEP_TYPES.HEADER
      ? STEP_TYPES.TEXT_FIELD
      : localChecklistItemData.type;
  };

  const getAddStepUi = () => {
    return (
      <Stack
        px="12px"
        direction={'row'}
        gap="10px"
        alignItems={'center'}
        width="100%"
      >
        <Divider sx={{ flex: 1 }} />
        <AddStepButton
          variant={'outlined'}
          className={`py-1 px-3 pointer-cursor`}
          startIcon={<AddRoundedIcon fontSize="small" />}
          size={'large'}
          onClick={(e) => {
            e.stopPropagation();
            onDescriptionChange.flush();
            const itemType = getNextItemType();
            ParentId
              ? handleAddAnotherQuestion(itemType, {
                  ...localChecklistItemData,
                  description: descriptionRef?.current?.value,
                })
              : addChecklistItem(
                  itemType,
                  localChecklistItemData.id,
                  descriptionRef?.current?.value,
                );
          }}
          disabled={addingItem}
        >
          {ParentId ? 'Add Another Question' : 'Add Step'}
        </AddStepButton>
        {!ParentId && (
          <AddStepButton
            variant={'outlined'}
            className={`py-1 px-3 pointer-cursor ${
              ParentId && 'child-step-btn'
            }`}
            startIcon={<AddSectionIcon />}
            size={'small'}
            onClick={(e) => {
              onDescriptionChange.cancel();
              e.stopPropagation();
              addChecklistItem(
                STEP_TYPES.HEADER,
                localChecklistItemData.id,
                descriptionRef?.current?.value,
              );
            }}
            disabled={addingItem}
          >
            Add Section
          </AddStepButton>
        )}
        <Divider sx={{ flex: 1 }} />
      </Stack>
    );
  };

  const getAddStepForActiveItemUi = () => {
    return (
      <Stack
        pl="32px"
        pr="0px"
        direction={'row'}
        gap="10px"
        alignItems={'center'}
        width="100%"
      >
        <Divider sx={{ flex: 1, borderColor: 'rgba(104, 104, 254, 1)' }} />
        <Stack
          role="button"
          height="24px"
          width={'24px'}
          borderRadius={'24px'}
          bgcolor={'rgba(104, 104, 254, 1)'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={(e) => {
            e.stopPropagation();
            onDescriptionChange.cancel();
            if (addingItem) return;
            const itemType = getNextItemType();
            addChecklistItem(
              itemType,
              localChecklistItemData.id,
              descriptionRef?.current?.value,
            );
          }}
        >
          <AddRoundedIcon sx={{ color: '#fff' }} fontSize="small" />
        </Stack>
        <Divider sx={{ flex: 1, borderColor: 'rgba(104, 104, 254, 1)' }} />
      </Stack>
    );
  };

  const collapsed = useMemo(() => {
    if (ParentId) return false;
    return (
      itemCollapseState[localChecklistItemData.id] === false ||
      itemCollapseState[localChecklistItemData.id] === undefined
    );
  }, [itemCollapseState, localChecklistItemData]);

  const handleSectionOptionSelect = (option: any) => {
    switch (option.id) {
      case 'rename':
        setSectionEditingId(localChecklistItemData.id);
        setAutoFocusItemId?.(localChecklistItemData.id);
        return;
      case 'hideSection':
        if (localChecklistItemData.isActive) {
          setShowHideDialog(true);
        } else {
          handleUnhideSection();
        }
        return;
      case 'delete':
        !addingItem && setDeleteDialogOpen(true);
        return;
      default:
        break;
    }
  };

  const getFileNameFromUrl = (url: string): string => {
    const parts: string[] = url.split('/');
    const fileName: string = parts[parts.length - 1];
    const fileNameWithoutQueryParams: string = fileName.split('?')[0];
    return fileNameWithoutQueryParams;
  };

  const getFileExtensionFromUrl = (url: string) => {
    const parts = url.split('.');
    const lastPart = parts[parts.length - 1];
    return lastPart.split('?')[0];
  };

  const handleDeleteSectionAndSteps = () => {
    setDeleteDialogOpen(false);
    console.log('handleDeleteSectionAndSteps');
  };

  const handleDeleteSectionOnly = () => {
    setDeleteDialogOpen(false);
    console.log('handleDeleteSectionOnly');
  };

  const changeDefaultLocationStep = (checked: boolean) => {
    if (checked && defaultLocationStep === '') {
      setDefaultLocationStep(localChecklistItemData.id);
    } else if (checked && defaultLocationStep === null) {
      const defaultLocationStep = checklistItems.find(
        (item) =>
          item.type === STEP_TYPES.LOCATION && item?.options?.isDefaultLocation,
      );

      if (defaultLocationStep) {
        setDefaultLocationStep(defaultLocationStep.id);
        return;
      }

      setDefaultLocationStep(localChecklistItemData.id);
    } else if (checked && defaultLocationStep) {
      return;
    } else if (!checked) {
      setDefaultLocationStep('');
    }

    updateItemHandler(
      'options',
      {
        ...localChecklistItemData.options,
        isDefaultLocation: checked,
      },
      true,
    );
  };

  const stepMap = {
    procedure: {
      icon: <ProcedureIcon />,
      label: 'Procedure',
      bgColor: 'rgba(232, 245, 233, 1)',
    },
    passFail: {
      icon: <PassFailIcon />,
      label: 'Pass/Fail',
      bgColor: 'rgba(225, 245, 254, 1)',
    },
    takePhoto: {
      icon: <TakePhotoIcon />,
      label: 'Take Photo',
      bgColor: 'rgba(255, 249, 196, 1)',
    },
    textField: {
      icon: <TextFieldIcon />,
      label: 'Text Answer',
      bgColor: 'rgba(224, 242, 241, 1)',
    },
    number: {
      icon: <NumberIcon />,
      label: 'Number',
      bgColor: 'rgba(236, 239, 241, 1)',
    },
    temperature: {
      icon: <TemperatureIcon />,
      label: 'Temperature',
      bgColor: 'rgba(252, 228, 236, 1)',
    },
    cost: {
      icon: <CostIcon />,
      label: 'Cost',
      bgColor: 'rgba(255, 243, 224, 1)',
    },
    multipleChoice: {
      icon: <McqIcon />,
      label: 'Multiple Choice',
      bgColor: 'rgba(224, 247, 250, 1)',
    },
    signature: {
      icon: <SignatureIcon />,
      label: 'Signature',
      bgColor: 'rgba(254, 235, 238, 1)',
    },
    dateTime: {
      icon: <DateTimeIcon />,
      label: 'Date & Time',
      bgColor: 'rgba(240, 244, 195, 1)',
    },
    instruction: {
      icon: <LongDescriptionIcon />,
      label: 'Long Description',
      bgColor: 'rgba(251, 233, 231, 1)',
    },
    geoStamp: {
      icon: <GeoStampIcon />,
      label: 'Geo Stamp',
      bgColor: 'rgba(239, 235, 233, 1)',
    },
    dropdown: {
      icon: <DropdownIcon />,
      label: 'Dropdown',
      bgColor: 'rgba(241, 248, 233, 1)',
    },
    location: {
      icon: <LocationIcon />,
      label: 'Location',
      bgColor: 'rgba(224, 242, 241, 1)',
    },
    photoAnnotate: {
      icon: <PhotoAnnotateIcon />,
      label: 'Photo Annotate',
      bgColor: 'rgba(243, 229, 245, 1)',
    },
  };
  const openDescriptionDialog = (e) => {
    e.stopPropagation();
    setDescriptionDialogOpen(true);
  };
  const nullScoring = () => {
    updateItemHandler('score', null, true);
  };
  return (
    <>
      <HideDialog
        open={showHideDialog}
        handleClose={() => {
          setShowHideDialog(false);
        }}
        onClickCancel={() => {
          setShowHideDialog(false);
        }}
        onClickHide={
          localChecklistItemData.type === STEP_TYPES.HEADER
            ? handleHideSection
            : handleHideStep
        }
        isSection={localChecklistItemData.type === STEP_TYPES.HEADER}
      />
      <DeleteDialog
        open={showDeleteDialog}
        handleClose={() => setShowDeleteDialog(false)}
        onClickHide={handleHideStep}
        onClickDelete={handleDeleteConfirm}
      />
      <InstructionDialog
        open={instructionDialogOpen}
        handleClose={() => setInstructionDialogOpen(false)}
        checklistItem={localChecklistItemData}
        handleSave={(description, instruction, requireAcknowledgement) => {
          updateItemObjHandler(
            {
              ...localChecklistItemData,
              description,
              options: {
                requireAcknowledgement,
                instruction,
              },
            },
            true,
          );
        }}
      />
      <StepDescriptionDialog
        open={descriptionDialogOpen}
        handleClose={() => setDescriptionDialogOpen(false)}
        checklistItem={localChecklistItemData}
        handleSave={(description) => {
          updateItemHandler('info', { optionDescription: description }, true);
          handleItemCollapse(localChecklistItemData.id, true, null, false);
        }}
      />
      <>
        {/* Header Step */}
        {localChecklistItemData.type === STEP_TYPES.HEADER ? (
          <Stack gap="12px">
            <Stack
              bgcolor={'#EDEFF7'}
              p="8px 12px"
              direction="row"
              alignItems={'center'}
              borderRadius={
                sectionVisibility[localChecklistItemData.id] === false
                  ? '8px'
                  : '8px 8px 0px 0px'
              }
              gap="8px"
            >
              {!localChecklistItemData.isActive && (
                <Tooltip placement="right" arrow title="Show Section">
                  <VisibilityRoundedIcon
                    sx={{
                      color: 'rgba(104, 104, 254, 1)',
                      cursor: 'pointer',
                    }}
                    onClick={handleUnhideSection}
                  />
                </Tooltip>
              )}
              {/* {sectionVisibility?.[localChecklistItemData.id] === false && (
              <Box>
                <DragIndicatorOutlinedIcon
                  sx={{ color: '#706F70', cursor: 'pointer' }}
                  ref={setNodeRef}
                  {...listeners}
                  {...attributes}
                />
              </Box>
            )} */}

              {checklistItems &&
                !!checklistItems.length &&
                localChecklistItemData.type === STEP_TYPES.HEADER &&
                checklistItems[checklistItemIndex + 1] &&
                checklistItems[checklistItemIndex + 1]?.type !==
                  STEP_TYPES.HEADER && <></>}
              <Stack
                bgcolor={'rgba(140, 147, 176, 1)'}
                width={'28px'}
                height={'28px'}
                borderRadius={'28px'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => toggleSectionCollapse(localChecklistItemData.id)}
              >
                {sectionVisibility?.[localChecklistItemData.id] === undefined ||
                sectionVisibility?.[localChecklistItemData.id] === true ? (
                  <ChevronRightRounded
                    sx={{ color: '#fff', rotate: '90deg' }}
                  />
                ) : (
                  <ChevronRightRounded sx={{ color: '#fff' }} />
                )}
              </Stack>
              <TextField
                hidden={sectionEditingId !== localChecklistItemData.id}
                inputRef={descriptionRef}
                id={`description-${localChecklistItemData.id}`}
                placeholder="Enter Section Name"
                defaultValue={localChecklistItemData.description}
                autoFocus={
                  !ParentId && localChecklistItemData.id === autoFocusItemId
                    ? true
                    : false
                }
                sx={{
                  width: '95%',
                  '& .MuiInputBase-root': {
                    bgcolor: '#fff',
                    ...(autoFocusItemId === localChecklistItemData.id && {
                      outline: '1px solid rgba(78, 72, 250, 1)',
                    }),
                  },
                  '& input': {
                    fontSize: '20px',
                    fontWeight: 700,
                    borderRadius: '6px',
                    padding: '4px 8px',
                    color: '#000',
                  },
                  '& fieldset': { display: 'none' },
                }}
                InputLabelProps={{
                  style: {
                    paddingLeft: 12,
                    fontSize: 20,
                    fontWeight: 700,
                    color: '#A4A4A4',
                  },
                }}
                onBlur={onDescriptionChange}
                variant="outlined"
                onClick={() => {
                  if (
                    descriptionRef.current &&
                    autoFocusItemId !== localChecklistItemData.id
                  ) {
                    setAutoFocusItemId?.(localChecklistItemData.id);
                    setAutofocusCursorPosition(
                      descriptionRef.current.selectionStart,
                    );
                  }
                }}
                disabled={addingItem}
                onKeyUp={(event: any) => {
                  if (event.code === 'Enter') {
                    const data = {
                      ...localChecklistItemData,
                      description: event.target.value,
                    };
                    updateItemApiCall();
                    // updateChecklistItemById(data.id, data);
                    setSectionEditingId(null);
                  }
                }}
                onChange={(e) =>
                  updateItemHandler('description', e.target.value)
                }
              />
              {sectionEditingId !== localChecklistItemData.id && (
                <Stack
                  sx={{
                    opacity: localChecklistItemData.isActive ? 1 : 0.6,
                  }}
                >
                  <Stack direction="row" gap="8px" alignItems={'center'}>
                    <Typography
                      color="#132362"
                      fontSize={'20px'}
                      fontWeight={700}
                      maxWidth="800px"
                      whiteSpace={'pre'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                    >
                      {localChecklistItemData.description || 'Untitled Section'}
                    </Typography>
                    <EditIconPencilBottomLine
                      sx={{
                        fontSize: '18px',
                        cursor: localChecklistItemData.isActive
                          ? 'pointer'
                          : 'not-allowed',
                      }}
                      onClick={(e) => {
                        if (!localChecklistItemData.isActive || addingItem)
                          return;
                        e.stopPropagation();
                        setSectionEditingId(localChecklistItemData.id);
                        setAutoFocusItemId?.(localChecklistItemData.id);
                      }}
                    />
                  </Stack>
                  {sectionVisibility[localChecklistItemData.id] === false && (
                    <Typography
                      color="#132362"
                      fontSize={'14px'}
                      fontWeight={500}
                    >
                      {sectionSteps[localChecklistItemData.id]} steps
                    </Typography>
                  )}
                </Stack>
              )}
              {localChecklistItemData.isActive && (
                <ActionDropdown
                  popperProps={{
                    style: { width: 180 },
                    placement: 'bottom-end',
                  }}
                  options={[
                    {
                      label: 'Rename',
                      id: 'rename',
                      icon: (
                        <EditIconPencilBottomLine
                          sx={{
                            fontSize: '18px !important',
                            '& path': {
                              stroke: 'rgba(117, 117, 117, 1)',
                              strokeOpacity: 1,
                              strokeWidth: 1,
                            },
                          }}
                        />
                      ),
                      iconAlign: 'start',
                    },
                    {
                      label: !localChecklistItemData.isActive
                        ? 'Unhide Section'
                        : 'Hide Section',
                      id: 'hideSection',
                      icon: !localChecklistItemData.isActive ? (
                        <VisibilityRoundedIcon
                          sx={{ fontSize: '18px !important' }}
                        />
                      ) : (
                        <VisibilityOffRoundedIcon
                          sx={{ fontSize: '18px !important' }}
                        />
                      ),
                      iconAlign: 'start',
                    },
                    {
                      label: 'Delete',
                      id: 'delete',
                      icon: (
                        <DeleteIcon
                          sx={{ color: '#D32F2F', fontSize: '18px !important' }}
                        />
                      ),
                      iconAlign: 'start',
                    },
                  ]}
                  handleOptionSelect={handleSectionOptionSelect}
                  buttonProps={{
                    style: {
                      padding: 2,
                      // color: '#7571C5',
                    },
                  }}
                  rootProps={{
                    style: {
                      marginLeft: 'auto',
                    },
                  }}
                >
                  {' '}
                  <MoreHorizRoundedIcon />
                </ActionDropdown>
              )}
            </Stack>
            {showAddButtons && <>{getAddStepUi()}</>}
          </Stack>
        ) : (
          // Non-Header Step
          <ChecklistItemParent
            rounded={showAddButtons ? 'true' : ''}
            undersection={isUnderSection ? 'true' : ''}
            gap="12px"
            p={ParentId ? '0px' : '8px 16px'}
            mb={ParentId ? '12px' : '0px'}
            issectionactive={isSectionActive || !isUnderSection ? 'true' : ''}
          >
            <Stack
              gap="8px"
              width="100%"
              direction={'row'}
              alignItems={'center'}
            >
              <Box>
                {localChecklistItemData.isActive && (
                  <DragIndicatorOutlinedIcon
                    sx={{ color: '#706F70', cursor: 'grab' }}
                    ref={setNodeRef}
                    {...listeners}
                    {...attributes}
                  />
                )}
                {!localChecklistItemData.isActive && isSectionActive && (
                  <Tooltip placement="right" arrow title="Show Step">
                    <VisibilityRoundedIcon
                      sx={{
                        color: 'rgba(104, 104, 254, 1)',
                        cursor: 'pointer',
                      }}
                      onClick={handleUnhideStep}
                    />
                  </Tooltip>
                )}
              </Box>
              <ChecklistItemCnt
                hidden={!localChecklistItemData.isActive ? 'true' : ''}
                focused={
                  autoFocusItemId === localChecklistItemData.id ? 'true' : ''
                }
                gap="14px"
                sx={{
                  opacity: localChecklistItemData.isActive ? 1 : 0.6,
                }}
                onClick={handleItemClick}
              >
                {/* ChecklistItem First Row */}
                <Stack gap="12px" width="100%" direction="row">
                  <Stack display="inline-flex" flex={1}>
                    <TextField
                      inputRef={descriptionRef}
                      id={`description-${localChecklistItemData.id}`}
                      autoFocus={localChecklistItemData.id === autoFocusItemId}
                      inputProps={{
                        placeholder:
                          localChecklistItemData.type === STEP_TYPES.INSTRUCTION
                            ? 'Click to edit...'
                            : 'Enter step description',
                      }}
                      InputLabelProps={{
                        shrink: !!localChecklistItemData.description,
                      }}
                      sx={{
                        '& input': { fontWeight: 500 },
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& .MuiInputBase-root': {
                          borderRadius: '8px',
                          '& fieldset': {
                            borderColor: '#E0E0E0',
                          },
                        },
                      }}
                      value={localChecklistItemData.description}
                      {...(localChecklistItemData.type ===
                        STEP_TYPES.INSTRUCTION && {
                        value: localChecklistItemData.description,
                      })}
                      onKeyDown={(event: any) => {
                        if (event.code == 'Enter') {
                          if (addingItem || addingItemLocal) return;

                          setAddingItemLocal(true);
                          onDescriptionChange.flush();
                          updateItemApiCall();
                          if (ParentId) {
                            let itemType = getNextItemType();
                            if (itemType === STEP_TYPES.HEADER)
                              itemType = 'procedure';
                            handleAddAnotherQuestion(itemType, {
                              ...localChecklistItemData,
                              description: event.target.value,
                            });
                          } else {
                            addChecklistItem(
                              getNextItemType(),
                              localChecklistItemData.id,
                              event.target.value,
                            );
                          }
                        }
                      }}
                      onBlur={onDescriptionChange}
                      disabled={addingItem || isUploading || addingItemLocal}
                      size="small"
                      variant="outlined"
                      onClick={handleDescriptionClick}
                      onChange={(e) => {
                        updateItemHandler('description', e.target.value);
                      }}
                    />
                  </Stack>

                  {/* -----------Step Type---------- */}
                  <Stack width={'240px'}>
                    <CustomDropdown
                      clickaway
                      popperProps={{
                        open: Boolean(stepDropdownRef),
                        content: (
                          <Stack
                            p="8px"
                            gap="2px"
                            width="240px"
                            height="350px"
                            overflow="scroll"
                          >
                            {Object.keys(stepMap).map((key) => {
                              return (
                                <Stack
                                  key={key}
                                  p="6px 8px"
                                  width="100%"
                                  borderRadius={'8px'}
                                  alignItems={'center'}
                                  gap="8px"
                                  direction={'row'}
                                  height="100%"
                                  sx={{
                                    cursor: 'pointer',
                                    ...(localChecklistItemData.type === key && {
                                      backgroundColor: '#EBEAFF',
                                    }),
                                    '&:hover': {
                                      backgroundColor: '#EBEAFF',
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleStepTypeChange(key);
                                    setAutoFocusItemId?.(
                                      localChecklistItemData.id,
                                    );
                                    handleItemCollapse(
                                      localChecklistItemData.id,
                                    );
                                    setStepDropdownRef(null);
                                  }}
                                >
                                  {stepMap[key]?.icon}
                                  <Typography
                                    color="#000"
                                    fontSize="14px"
                                    fontWeight="500"
                                  >
                                    {stepMap[key]?.label}
                                  </Typography>
                                  {localChecklistItemData.type === key && (
                                    <DoneRounded
                                      sx={{
                                        color: '#6868FE',
                                        fontSize: '20px',
                                        marginLeft: 'auto',
                                      }}
                                    />
                                  )}
                                </Stack>
                              );
                            })}
                          </Stack>
                        ),
                        anchorEl: stepDropdownRef,
                      }}
                      onClickawayCallback={() => {
                        if (stepDropdownRef) {
                          setStepDropdownRef(null);
                          handleSyncDescription();
                        }
                      }}
                      buttonRenderer={
                        <Stack
                          borderRadius={'8px'}
                          border="1px solid #E0E0E0"
                          p="7px 8px"
                          width="100%"
                          alignItems={'center'}
                          gap="8px"
                          direction={'row'}
                          height="100%"
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            if (isUploading) return;
                            onDescriptionChange.cancel();
                            e.stopPropagation();
                            setStepDropdownRef(
                              stepDropdownRef ? null : e.currentTarget,
                            );
                            if (stepDropdownRef) {
                              handleSyncDescription();
                            }
                          }}
                        >
                          <Stack
                            p="2px"
                            bgcolor={
                              stepMap[localChecklistItemData.type]?.bgColor
                            }
                            borderRadius={'6px'}
                          >
                            {React.cloneElement(
                              stepMap[localChecklistItemData.type]?.icon ?? (
                                <></>
                              ),
                              {
                                sx: { fontSize: '20px' },
                              },
                            )}
                          </Stack>
                          <Typography
                            color="#000"
                            fontSize="14px"
                            fontWeight="500"
                          >
                            {stepMap[localChecklistItemData.type]?.label}
                          </Typography>
                          <KeyboardArrowDownRounded
                            sx={{ marginLeft: 'auto', color: '#616161' }}
                          />
                        </Stack>
                      }
                    />
                  </Stack>

                  {checklist?.isScoring && (
                    <TextField
                      sx={{
                        width: '100px',
                        '& .MuiInputBase-root': {
                          paddingLeft: '12px',
                          height: '100%',
                        },
                        '& .Mui-focused': {
                          '& .cancelIcon': {
                            opacity: 1,
                          },
                        },
                        '& :hover': {
                          '& .cancelIcon': {
                            opacity: 1,
                          },
                        },
                        '& input': {
                          color: '#000',
                          fontWeight: '600',
                          fontSize: '14px',
                          paddingRight: '12px',
                          textAlign: 'right',
                        },
                        '& fieldset': {
                          borderRadius: '8px',
                          borderColor: '#E0E0E0',
                          top: '0px',
                          '& legend': {
                            display: 'none',
                          },
                        },
                      }}
                      disabled={
                        addingItem ||
                        isUploading ||
                        addingItemLocal ||
                        !localChecklistItemData.isActive ||
                        [
                          STEP_TYPES.MULTIPLE_CHOICE,
                          STEP_TYPES.DROPDOWN,
                        ].includes(localChecklistItemData.type)
                      }
                      variant="outlined"
                      onBlur={onScoreChange}
                      onChange={(e) => {
                        let score = 0;
                        if (Number.isFinite(Number(e.target.value))) {
                          score = Number(e.target.value);
                          updateItemHandler('score', score);
                        }
                        if (e.target.value === '/') {
                          updateItemHandler('score', null);
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAutoFocusItemId(
                          `${localChecklistItemData.id}-score`,
                        );
                      }}
                      autoFocus={
                        `${localChecklistItemData.id}-score` === autoFocusItemId
                      }
                      size="small"
                      value={
                        localChecklistItemData.score == null
                          ? '/'
                          : localChecklistItemData.score
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              '& .MuiTypography-root': {
                                color: '#616161',
                                fontWeight: '400',
                                fontSize: '14px',
                              },
                            }}
                            position="start"
                          >
                            Score:
                          </InputAdornment>
                        ),
                        endAdornment:
                          addingItem ||
                          isUploading ||
                          addingItemLocal ||
                          !localChecklistItemData.isActive ||
                          [
                            STEP_TYPES.MULTIPLE_CHOICE,
                            STEP_TYPES.DROPDOWN,
                          ].includes(localChecklistItemData.type) ? (
                            ''
                          ) : (
                            <CustomToolTip
                              placement={'top'}
                              title={
                                <>
                                  This will exclude the question's score from
                                  the overall
                                  <br />
                                  submission score, effectively nullifying its
                                  impact.
                                </>
                              }
                            >
                              <CircleIcon
                                className={'cancelIcon'}
                                onClick={nullScoring}
                              >
                                <DoNotDisturbIcon />
                              </CircleIcon>
                            </CustomToolTip>
                          ),
                      }}
                    />
                  )}
                </Stack>
                {/* step instructions */}
                {localChecklistItemData?.info?.optionDescription && (
                  <Stack>
                    <DiscriptionCtn>
                      {!collapsed && (
                        <div
                          className="actionWrapper"
                          style={{
                            position: 'absolute',
                            top: 8,
                            right: 10,
                            zIndex: 3,
                            cursor: 'pointer',
                          }}
                        >
                          <span
                            style={{ marginRight: 6 }}
                            onClick={(event) => openDescriptionDialog(event)}
                          >
                            <EditIconPencilBottomLine
                              sx={{
                                fontSize: '18px',
                              }}
                            />
                          </span>
                          <span onClick={(event) => deleteReference(event)}>
                            <DeleteIcon
                              sx={{
                                color: '#D32F2F',
                                fontSize: '18px !important',
                              }}
                            />
                          </span>
                        </div>
                      )}
                      <p>
                        <b>Instructions:</b>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            localChecklistItemData?.info?.optionDescription ||
                            '<p></p>',
                        }}
                      />
                    </DiscriptionCtn>
                  </Stack>
                )}
                {/* ChecklistItem Second Row */}

                {[STEP_TYPES.MULTIPLE_CHOICE, STEP_TYPES.DROPDOWN].includes(
                  localChecklistItemData.type,
                ) && (
                  <Stack direction="row">
                    {[STEP_TYPES.MULTIPLE_CHOICE, STEP_TYPES.DROPDOWN].includes(
                      localChecklistItemData.type,
                    ) && (
                      <MultipleChoice
                        isScoring={checklist?.isScoring}
                        options={localChecklistItemData.options}
                        onChange={onMultipleChoiceItemChange}
                        autoFocusItemId={autoFocusItemId}
                        setAutoFocusItemId={setAutoFocusItemId}
                        collapsed={collapsed}
                        itemId={localChecklistItemData.id}
                        isUploading={isUploading}
                        showSave={true}
                      />
                    )}
                  </Stack>
                )}
                {(localChecklistItemData.type === STEP_TYPES.MULTIPLE_CHOICE ||
                  localChecklistItemData.type === STEP_TYPES.DROPDOWN ||
                  itemAutomations?.length > 0) &&
                  !collapsed && <Divider />}
                {/* Automations */}
                {itemAutomations?.length && !collapsed ? (
                  <Automations
                    field={{ ...localChecklistItemData, checklistId }}
                    automations={itemAutomations}
                    onEdit={onAddOrEditAutomation}
                    onDelete={onAutomationsDelete}
                    fetchAndSetChecklistAutomations={
                      fetchAndSetChecklistAutomations
                    }
                  />
                ) : null}
                {!collapsed && (
                  <>
                    {childItems?.map(([RelatedAutomationId, items]) => {
                      const key =
                        followupVisibility?.[localChecklistItemData.id]?.[
                          RelatedAutomationId
                        ];
                      const isFollowUpVisible =
                        key === true || key === undefined;
                      return (
                        <Stack
                          sx={{
                            background: '#F9F0FB',
                            borderRadius: '8px',
                            border: '1px solid #E1BEE7',
                          }}
                          gap={isFollowUpVisible ? '8px' : '0px'}
                          key={RelatedAutomationId}
                          p="16px"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Stack gap={'10px'} direction="row">
                            <Stack
                              bgcolor={'#E1BEE7'}
                              width={'24px'}
                              height={'24px'}
                              borderRadius={'24px'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSetFollowupVisibility(
                                  localChecklistItemData.id,
                                  RelatedAutomationId,
                                );
                              }}
                            >
                              <KeyboardArrowDownRounded
                                sx={{
                                  color: 'rgba(106, 27, 154, 1)',
                                  transform: !isFollowUpVisible
                                    ? 'rotate(-90deg)'
                                    : 'rotate(0deg)',
                                  transition: 'transform 0.1s ease-in-out',
                                }}
                              />
                            </Stack>
                            <Stack>
                              <Typography
                                fontSize="14px"
                                fontWeight={'700'}
                                color="#6A1B9A"
                              >
                                Follow Up Questions
                              </Typography>
                              {!isFollowUpVisible && (
                                <Typography
                                  fontSize="13px"
                                  fontWeight={'500'}
                                  color="#424242"
                                >
                                  {items.length}{' '}
                                  {items.length > 1 ? 'questions' : 'question'}
                                </Typography>
                              )}
                            </Stack>
                            <div>
                              {getAnswerView(
                                localChecklistItemData,
                                itemAutomations?.find(
                                  (a: any) => a.id === RelatedAutomationId,
                                )?.conditions[0],
                              )}
                            </div>
                          </Stack>
                          <Collapse in={isFollowUpVisible}>
                            <Stack>
                              <ChecklistItems
                                parentId={localChecklistItemData.id}
                                relatedAutomationId={RelatedAutomationId}
                                handleAddFollowUpItem={handleAddFollowUpItem}
                                checklistItems={items}
                                checklist={checklist}
                                defaultLocationStep={defaultLocationStep}
                                setDefaultLocationStep={setDefaultLocationStep}
                                fetchAndSetChecklistAutomations={
                                  fetchAndSetChecklistAutomations
                                }
                                handleUpdateChecklistItems={
                                  handleUpdateChecklistItems
                                }
                                automations={automations}
                                virtualList={false}
                                handleAddChecklistItem={handleAddChecklistItem}
                                setAutoFocusIndex={setAutoFocusItemId}
                                autoFocusIndex={autoFocusItemId}
                                deleteChecklistItemById={
                                  deleteChecklistItemById
                                }
                                addingItem={addingItem}
                                sectionSteps={sectionSteps}
                                sectionVisibility={sectionVisibility}
                                toggleSectionCollapse={toggleSectionCollapse}
                                sectionEditingId={sectionEditingId}
                                setSectionEditingId={setSectionEditingId}
                                originalChecklistItems={items}
                                handleSetFollowupVisibility={
                                  handleSetFollowupVisibility
                                }
                                followupVisibility={followupVisibility}
                                itemCollapseState={itemCollapseState}
                                handleItemCollapse={handleItemCollapse}
                                setAutofocusCursorPosition={
                                  setAutofocusCursorPosition
                                }
                                autoFocusCursorPosition={
                                  autoFocusCursorPosition
                                }
                                handleCollapseAllItems={handleCollapseAllItems}
                                uploadingItemId={uploadingItemId}
                                setUploadingItemId={setUploadingItemId}
                                checklistItemsHashRef={checklistItemsHashRef}
                                updateChecklistItemsHash={
                                  updateChecklistItemsHash
                                }
                                uploadAttachmentsToStep={
                                  uploadAttachmentsToStep
                                }
                              />
                            </Stack>
                          </Collapse>
                        </Stack>
                      );
                    })}
                  </>
                )}
                {hasAttachments &&
                  !isPhotoAnnotationStep &&
                  !collapsed &&
                  ![STEP_TYPES.DROPDOWN, STEP_TYPES.MULTIPLE_CHOICE].includes(
                    localChecklistItemData.type,
                  ) && <Divider />}
                {/* Attachments */}
                {hasAttachments && !collapsed && !isPhotoAnnotationStep && (
                  <Stack gap="8px" direction={'row'}>
                    <Typography mt="4px" fontSize="12px" fontWeight={700}>
                      Attachments:
                    </Typography>
                    <Stack
                      flexWrap={'wrap'}
                      gap="8px"
                      alignItems={'center'}
                      direction={'row'}
                    >
                      {localChecklistItemData.attachments.map(
                        (attachment: any, index: number) => {
                          const extension = getFileExtensionFromUrl(attachment);
                          return (
                            <Stack
                              p="4px 8px 4px 4px"
                              borderRadius={'8px'}
                              bgcolor={'#EFF3F5'}
                              gap="8px"
                              alignItems={'center'}
                              width="200px"
                              direction={'row'}
                              onClick={(e) => {
                                e.stopPropagation();
                                previewAttachment(index);
                              }}
                              sx={{
                                cursor: 'pointer',
                              }}
                            >
                              {getMimeType(attachment).includes('image') &&
                              extension !== 'svg' ? (
                                <Box
                                  sx={{
                                    width: '32px',
                                    height: '32px',
                                    backgroundImage: `url("${attachment}")`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    borderRadius: '4px',
                                  }}
                                />
                              ) : (
                                <FileIcon
                                  sx={{
                                    fontSize: '32px',
                                  }}
                                  url={attachment}
                                />
                              )}

                              <Typography
                                fontSize={'13px'}
                                fontWeight={'400'}
                                color="#000"
                                maxWidth={'115px'}
                                overflow={'hidden'}
                                textOverflow={'ellipsis'}
                                whiteSpace={'pre'}
                              >
                                {getFileNameFromUrl(attachment)}
                              </Typography>

                              <CloseRoundedIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (isUploading) return;
                                  onDescriptionChange.cancel();
                                  onDeleteAttachment(index);
                                }}
                                sx={{
                                  color: 'rgba(117, 117, 117, 1)',
                                  cursor: 'pointer',
                                  ml: 'auto',
                                }}
                              />
                            </Stack>
                          );
                        },
                      )}
                    </Stack>
                  </Stack>
                )}
                {/* Photo Annotate Image */}
                {!collapsed && isPhotoAnnotationStep && hasAnnotation && (
                  <Stack gap="8px" direction={'row'}>
                    {annotationImage.map((url: string, index: number) => (
                      <Stack
                        width={80}
                        height={80}
                        borderRadius={'8px'}
                        position={'relative'}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <Stack
                          position={'absolute'}
                          top={'0'}
                          right={'0'}
                          sx={{
                            cursor: 'pointer',
                          }}
                          role="button"
                          bgcolor={'rgba(117, 117, 117, 1)'}
                          borderRadius={'1000px'}
                          border="2px solid #fff"
                          margin="-6px -6px 0px 0px"
                          p="1px"
                        >
                          <CloseRoundedIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              if (isUploading) return;
                              onDescriptionChange.cancel();
                              onDeleteAnnotation();
                            }}
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                            }}
                          />
                        </Stack>
                        <img
                          width={'100%'}
                          height={'100%'}
                          src={url}
                          style={{ objectFit: 'cover', borderRadius: '8px' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            previewAttachment(index);
                          }}
                        />
                      </Stack>
                    ))}
                  </Stack>
                )}
                {hasAnnotation && !collapsed && isPhotoAnnotationStep && (
                  <Divider />
                )}
                {/* ChecklistItem Third Row */}
                {!collapsed && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                  >
                    {/* Left side items */}
                    <Stack direction="row" alignItems="center" gap="20px">
                      {localChecklistItemData.type === STEP_TYPES.DATE_TIME && (
                        <Stack direction="row" alignItems={'center'} gap="8px">
                          <IosSwitch
                            disabled={addingItem || isUploading}
                            onChange={(event: any) => {
                              updateItemHandler(
                                'unit',
                                event.target.checked
                                  ? STEP_TYPES.DATE_TIME
                                  : 'date',
                                true,
                              );
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onDescriptionChange.cancel();
                            }}
                            checked={
                              localChecklistItemData.unit ===
                              STEP_TYPES.DATE_TIME
                            }
                            thumbSize={13}
                            height={17}
                            width={26.667}
                            sx={{
                              '& .MuiSwitch-track': {
                                backgroundColor: 'rgba(176, 190, 197, 1)',
                              },
                            }}
                          />
                          <Typography
                            color={
                              isUploading
                                ? 'rgba(117, 117, 117, 1)'
                                : 'rgba(78, 72, 250, 1)'
                            }
                            fontSize={'13px'}
                            fontWeight="500"
                          >
                            Add Time
                          </Typography>
                        </Stack>
                      )}
                      {[
                        STEP_TYPES.NUMBER,
                        STEP_TYPES.TEMPERATURE,
                        STEP_TYPES.COST,
                      ].includes(localChecklistItemData.type) && (
                        <CustomDropdown
                          onClickawayCallback={() => {
                            if (showUnits) {
                              setShowUnits(null);
                              handleSyncDescription();
                            }
                          }}
                          clickaway
                          popperProps={{
                            anchorEl: showUnits,
                            open: Boolean(showUnits),
                            placement: 'bottom-start',
                            content: (
                              <Stack
                                maxHeight="350px"
                                overflow={'scroll'}
                                p="16px"
                                gap="10px"
                              >
                                <Stack>
                                  {currentOptionsForUnits.map((optionGroup) => (
                                    <Stack>
                                      <Stack
                                        direction="row"
                                        borderRadius={'6px'}
                                        p="4px 8px"
                                        sx={{
                                          ...(localChecklistItemData.type !==
                                            STEP_TYPES.NUMBER && {
                                            cursor: 'pointer',
                                            '&:hover': {
                                              bgcolor: 'rgba(235, 234, 255, 1)',
                                            },
                                            background:
                                              localChecklistItemData.unit ===
                                              optionGroup.id
                                                ? 'rgba(235, 234, 255, 1)'
                                                : '',
                                          }),
                                        }}
                                        justifyContent={'space-between'}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (
                                            localChecklistItemData.type !==
                                            STEP_TYPES.NUMBER
                                          )
                                            handleAddUnit(optionGroup.id);
                                        }}
                                      >
                                        <Typography
                                          fontSize={'13px'}
                                          fontWeight={
                                            localChecklistItemData.type ===
                                            STEP_TYPES.NUMBER
                                              ? '700'
                                              : '500'
                                          }
                                          color={
                                            localChecklistItemData.unit ===
                                            optionGroup.id
                                              ? 'rgba(78, 72, 250, 1)'
                                              : ''
                                          }
                                        >
                                          {optionGroup.label}
                                        </Typography>
                                        {selectedUnit?.id ===
                                          optionGroup.id && (
                                          <DoneRounded
                                            sx={{
                                              color: 'rgba(78, 72, 250, 1)',
                                              fontSize: '20px',
                                            }}
                                          />
                                        )}
                                      </Stack>

                                      {optionGroup?.child?.map((child) => (
                                        <Stack
                                          p="7px 8px"
                                          direction="row"
                                          justifyContent={'space-between'}
                                          borderRadius={'6px'}
                                          bgcolor={
                                            selectedUnit?.id === child.id
                                              ? 'rgba(235, 234, 255, 1)'
                                              : ''
                                          }
                                          sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                              bgcolor: 'rgba(235, 234, 255, 1)',
                                            },
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAddUnit(child.id);
                                          }}
                                        >
                                          <Typography
                                            fontSize="13px"
                                            fontWeight={500}
                                            color={
                                              selectedUnit?.id === child.id
                                                ? 'rgba(78, 72, 250, 1)'
                                                : '#212121'
                                            }
                                          >
                                            {child.label}
                                          </Typography>
                                          {selectedUnit?.id === child.id && (
                                            <DoneRounded
                                              sx={{
                                                color: 'rgba(78, 72, 250, 1)',
                                                fontSize: '20px',
                                              }}
                                            />
                                          )}
                                        </Stack>
                                      ))}
                                    </Stack>
                                  ))}
                                </Stack>
                              </Stack>
                            ),
                          }}
                          buttonRenderer={
                            <Stack
                              direction="row"
                              gap="6px"
                              alignItems="center"
                              onClick={(e) => {
                                e.stopPropagation();
                                onDescriptionChange.cancel();
                                setShowUnits(e.currentTarget);
                                if (showUnits) {
                                  handleSyncDescription();
                                }
                              }}
                              sx={{ cursor: 'pointer' }}
                            >
                              {!localChecklistItemData.unit && (
                                <AddRoundedIcon
                                  fontSize="small"
                                  sx={{ color: 'rgba(78, 72, 250, 1)' }}
                                />
                              )}
                              {localChecklistItemData.unit &&
                                localChecklistItemData.type ===
                                  STEP_TYPES.NUMBER && (
                                  <RemoveCircleOutlineIcon
                                    fontSize="small"
                                    sx={{
                                      color: 'rgba(211, 47, 47, 1)',
                                      cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                      onDescriptionChange.cancel();
                                      e.stopPropagation();
                                      removeUnits();
                                    }}
                                  />
                                )}
                              <Typography
                                color="rgba(78, 72, 250, 1)"
                                fontSize={'13px'}
                                fontWeight="500"
                              >
                                {localChecklistItemData.unit
                                  ? selectedUnit?.label
                                  : 'Add Unit'}
                              </Typography>
                              {localChecklistItemData.unit && (
                                <KeyboardArrowDownRounded
                                  fontSize="small"
                                  sx={{ color: 'rgba(78, 72, 250, 1)' }}
                                />
                              )}
                            </Stack>
                          }
                        />
                      )}
                      {localChecklistItemData.type === STEP_TYPES.DROPDOWN && (
                        <Stack direction="row" alignItems={'center'} gap="8px">
                          <IosSwitch
                            disabled={addingItem || isUploading}
                            onChange={(event: any) => {
                              event.stopPropagation();
                              updateItemHandler(
                                'unit',
                                event.target.checked ? 'multiple' : 'single',
                                true,
                              );
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onDescriptionChange.cancel();
                            }}
                            checked={localChecklistItemData.unit === 'multiple'}
                            thumbSize={13}
                            height={17}
                            width={26.667}
                            sx={{
                              '& .MuiSwitch-track': {
                                backgroundColor: 'rgba(176, 190, 197, 1)',
                              },
                            }}
                          />
                          <Typography
                            color={
                              isUploading
                                ? 'rgba(117, 117, 117, 1)'
                                : 'rgba(78, 72, 250, 1)'
                            }
                            fontSize={'13px'}
                            fontWeight="500"
                          >
                            Allow Multiple Selection
                          </Typography>
                        </Stack>
                      )}
                      {localChecklistItemData.type ===
                        STEP_TYPES.INSTRUCTION && (
                        <Stack direction="row" alignItems={'center'} gap="8px">
                          <IosSwitch
                            disabled={addingItem || isUploading}
                            onChange={(event: any) => {
                              updateItemHandler(
                                'options',
                                {
                                  ...localChecklistItemData.options,
                                  requireAcknowledgement: event.target.checked,
                                },
                                true,
                              );
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onDescriptionChange.cancel();
                            }}
                            checked={
                              localChecklistItemData?.options
                                ?.requireAcknowledgement
                            }
                            thumbSize={13}
                            height={17}
                            width={26.667}
                            sx={{
                              '& .MuiSwitch-track': {
                                backgroundColor: 'rgba(176, 190, 197, 1)',
                              },
                            }}
                          />
                          <Typography
                            color={
                              isUploading
                                ? 'rgba(117, 117, 117, 1)'
                                : 'rgba(78, 72, 250, 1)'
                            }
                            fontSize={'13px'}
                            fontWeight="500"
                          >
                            Require Acknowledgement
                          </Typography>
                        </Stack>
                      )}
                      {[
                        STEP_TYPES.DATE_TIME,
                        STEP_TYPES.NUMBER,
                        STEP_TYPES.TEMPERATURE,
                        STEP_TYPES.COST,
                        STEP_TYPES.DROPDOWN,
                        STEP_TYPES.INSTRUCTION,
                      ].includes(localChecklistItemData.type) && (
                        <Divider orientation="vertical" flexItem />
                      )}
                      {!nonAutomationFields[localChecklistItemData.type] && (
                        <VerifyFeatureAccess featureId={FEATURES.AUTOMATIONS}>
                          <Stack
                            onClick={onAddOrEditAutomation}
                            direction="row"
                            alignItems={'center'}
                            gap="8px"
                            color={
                              isUploading
                                ? 'rgba(117, 117, 117, 1)'
                                : 'rgba(78, 72, 250, 1)'
                            }
                            fontSize={'13px'}
                            fontWeight={500}
                            sx={{
                              cursor: 'pointer',
                              '& svg': {
                                fontSize: '18px',
                              },
                            }}
                          >
                            <AutomationIconRounded />
                            <span>
                              {itemAutomations?.length
                                ? 'Add More Automations / Logic'
                                : 'Add Automations / Logic'}
                            </span>
                          </Stack>
                        </VerifyFeatureAccess>
                      )}
                      {/* <Dropzone
                        disabled={isUploading}
                        onDrop={onUploadAttachment}
                        maxFiles={isPhotoAnnotationStep ? 1 : undefined}
                        accept={
                          isPhotoAnnotationStep
                            ? {
                                'image/jpeg': [],
                                'image/png': [],
                                'image/jpg': [],
                              }
                            : undefined
                        }
                        multiple={isPhotoAnnotationStep ? false : true}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <Stack
                            direction="row"
                            alignItems={'center'}
                            gap="8px"
                            color={
                              isUploading
                                ? 'rgba(117, 117, 117, 1)'
                                : 'rgba(78, 72, 250, 1)'
                            }
                            fontSize={'13px'}
                            fontWeight={500}
                            {...getRootProps({
                              className: 'dropzone pointer-cursor',
                            })}
                          >
                            <input
                              disabled={isUploading}
                              {...getInputProps()}
                            />
                            {isUploading && (
                              <CircularProgress
                                sx={{
                                  color: isUploading
                                    ? 'rgba(117, 117, 117, 1)'
                                    : 'rgba(78, 72, 250, 1)',
                                }}
                                size={16}
                              />
                            )}
                            {!isUploading && (
                              <>
                                {isPhotoAnnotationStep ? (
                                  <AddPhotoIcon
                                    sx={{
                                      fontSize: '18px',
                                    }}
                                  />
                                ) : (
                                  <AttachFileOutlinedIcon
                                    sx={{
                                      transform: 'rotate(45deg)',
                                      fontSize: '18px',
                                    }}
                                  />
                                )}
                              </>
                            )}
                            <span>
                              {isPhotoAnnotationStep
                                ? hasAnnotation
                                  ? 'Replace Photo'
                                  : 'Add Photo to Annotate'
                                : 'Add Attachment'}
                            </span>
                          </Stack>
                        )}
                      </Dropzone> */}

                      <ActionDropdown
                        buttonProps={{
                          startIcon: isUploading ? (
                            <CircularProgress
                              sx={{
                                color: isUploading
                                  ? 'rgba(117, 117, 117, 1)'
                                  : 'rgba(78, 72, 250, 1)',
                              }}
                              size={16}
                            />
                          ) : (
                            <AddIcon />
                          ),
                          disabled: isUploading,
                          sx: {
                            p: '0px',
                            backgroundColor: 'transparent !important',
                            boxShadow: 'none !important',
                            fontSize: 13,
                            color: '#4E48FA',
                            '& svg ': {
                              color: '#4E48FA',
                            },
                          },
                        }}
                        iconButton={false}
                        options={refrenceDropdownOptions}
                        handleOptionSelect={(option) => {
                          handleReferenceOptionSelect(option);
                        }}
                      >
                        Add Reference
                      </ActionDropdown>
                      {localChecklistItemData.type === STEP_TYPES.LOCATION && (
                        <Divider orientation="vertical" flexItem />
                      )}
                      {localChecklistItemData.type === STEP_TYPES.LOCATION && (
                        <Stack direction="row" alignItems={'center'} gap="8px">
                          <IosSwitch
                            disabled={
                              isUploading ||
                              (defaultLocationStep !==
                                localChecklistItemData.id &&
                                defaultLocationStep !== null &&
                                defaultLocationStep !== '')
                            }
                            onChange={(event: any) => {
                              event.stopPropagation();
                              changeDefaultLocationStep(event.target.checked);
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onDescriptionChange.cancel();
                            }}
                            checked={
                              localChecklistItemData?.options?.isDefaultLocation
                            }
                            thumbSize={13}
                            height={17}
                            width={26.667}
                            sx={{
                              '& .MuiSwitch-track': {
                                backgroundColor: 'rgba(176, 190, 197, 1)',
                              },
                            }}
                          />
                          <Typography
                            color={
                              isUploading
                                ? 'rgba(117, 117, 117, 1)'
                                : 'rgba(78, 72, 250, 1)'
                            }
                            fontSize={'13px'}
                            fontWeight="500"
                          >
                            Submission Location
                          </Typography>

                          <Tooltip
                            arrow
                            placement="right"
                            PopperProps={{
                              sx: {
                                '& .MuiTooltip-tooltip': {
                                  borderRadius: '12px',
                                },
                                '& .MuiTooltip-arrow': {
                                  color: '#424242',
                                },
                              },
                            }}
                            title={
                              <Box
                                sx={{
                                  padding: '5px',

                                  '& p': {
                                    color: 'white',
                                    fontWeight: 500,
                                    fontSize: 12,
                                    lineHeight: '16px',
                                  },
                                }}
                              >
                                <Typography>
                                  If enabled then this location will be
                                </Typography>
                                <Typography>
                                  considered as the location of the
                                </Typography>
                                <Typography>
                                  submission for reporting
                                </Typography>
                              </Box>
                            }
                          >
                            <HelpOutlineIcon
                              style={{
                                cursor: 'pointer',
                                color: '#616161',
                                marginLeft: -3,
                                height: 18,
                                width: 18,
                              }}
                            />
                          </Tooltip>
                        </Stack>
                      )}
                    </Stack>

                    {/* Right side items */}
                    <Stack direction="row" alignItems="center" gap="20px">
                      <FormControlLabel
                        sx={{
                          color: 'rgba(0, 0, 0)',
                          fontSize: '13px !important',
                          fontWeight: '400',
                          letterSpacing: '0.15px',
                          margin: '0px',
                          '& span': {
                            margin: '0px',
                          },
                        }}
                        disableTypography={true}
                        control={
                          <CustomCheckbox
                            edge="start"
                            disabled={isUploading}
                            tabIndex={-1}
                            disableRipple
                            checked={localChecklistItemData.required}
                            size="small"
                            onChange={(event: any) => {
                              onDescriptionChange.cancel();
                              updateItemHandler(
                                'required',
                                event.target.checked,
                                true,
                              );
                            }}
                          />
                        }
                        label="Required"
                      />
                      {localChecklistItemData.type !== 'takePhoto' &&
                        !isPhotoAnnotationStep && (
                          <FormControlLabel
                            sx={{
                              color: 'rgba(0, 0, 0)',
                              fontSize: '13px !important',
                              letterSpacing: '0.15px',
                              fontWeight: '400',
                              margin: '0px',
                              '& span': {
                                margin: '0px',
                              },
                            }}
                            disableTypography={true}
                            control={
                              <CustomCheckbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                disabled={isUploading}
                                checked={
                                  localChecklistItemData.isImageOrNoteRequired
                                }
                                size="small"
                                onChange={(event: any) => {
                                  onDescriptionChange.cancel();
                                  updateItemHandler(
                                    'isImageOrNoteRequired',
                                    event.target.checked,
                                    true,
                                  );
                                }}
                              />
                            }
                            label="Required Image"
                          />
                        )}

                      <Stack
                        sx={{ cursor: 'pointer' }}
                        onClick={_handleDeleteChecklistItem}
                        direction="row"
                        gap="6px"
                        alignItems={'center'}
                      >
                        <DeleteIcon
                          sx={{
                            color: isUploading
                              ? 'rgba(117, 117, 117, 1)'
                              : '#D32F2F',
                            fontSize: '20px',
                          }}
                        />
                        <Typography fontSize={'13px'} fontWeight={'400'}>
                          Delete
                        </Typography>
                      </Stack>
                      {localChecklistItemData.isActive && (
                        <ActionDropdown
                          buttonProps={{
                            sx: {
                              p: '0px',
                            },
                          }}
                          onDropdownClick={({ anchorEl }) => {
                            onDescriptionChange.cancel();
                            if (anchorEl) {
                              handleSyncDescription();
                            }
                          }}
                          onClickAwayCallback={({ anchorEl }) => {
                            if (anchorEl) {
                              handleSyncDescription();
                            }
                          }}
                          rootProps={{ style: { display: 'flex' } }}
                          options={dropdownOptions}
                          handleOptionSelect={handleHelpOptionSelect}
                        >
                          <MoreHorizRoundedIcon
                            sx={{
                              color: 'rgba(117, 117, 117, 1)',
                              fontSize: '20px',
                            }}
                          />
                        </ActionDropdown>
                      )}
                    </Stack>
                  </Stack>
                )}
              </ChecklistItemCnt>
            </Stack>
            {showAddButtons && <>{getAddStepUi()}</>}
            {!showAddButtons && !collapsed && !ParentId && isSectionActive && (
              <>{getAddStepForActiveItemUi()}</>
            )}
          </ChecklistItemParent>
        )}
        {!!showAttachmentPreview &&
          localChecklistItemData.attachments[selectedAttachment] && (
            <Dialog
              className="attachment-preview-dialog"
              fullScreen
              open={showAttachmentPreview}
            >
              <ChecklistAttachmentPreview
                selectedAttachment={selectedAttachment}
                setSelectedAttachment={setSelectedAttachment}
                hideAttachmentPreview={hideAttachmentPreview}
                attachments={attachmentsData}
              />
            </Dialog>
          )}
        {((localChecklistItemData.type === STEP_TYPES.HEADER &&
          sectionVisibility[localChecklistItemData.id] === false &&
          !ParentId) ||
          showAddButtons) && (
          <Stack sx={{ bgColor: '#fff' }} width={'100%'} height="12px" />
        )}
      </>
      {noSteps && (
        <Stack
          bgcolor="#F5F6FB"
          borderRadius="8px"
          p="24px"
          marginTop="34px"
          spacing={2}
          alignItems="center"
        >
          <Typography
            width="100%"
            fontWeight={800}
            fontSize="24px"
            color="#212121"
            textAlign="center"
          >
            Select a step type to begin
          </Typography>
          <LearnMoreBtn
            startIcon={<InfoTwoToneIcon />}
            variant="text"
            onClick={() =>
              window.open(
                'https://help.xenia.team/hc/en-us/articles/16875413679771-Operations-Template-Step-Types-A-Comprehensive-Guide',
                '_blank',
              )
            }
          >
            Learn More About Step Types
          </LearnMoreBtn>
          <StepTypes
            addChecklistItem={addChecklistItem}
            itemIndex={originalChecklistItems?.[0]?.id}
            onDescriptionChange={onDescriptionChange}
          />
        </Stack>
      )}
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        success={handleDeleteConfirm}
        // onDeleteAll={handleDeleteSectionAndSteps}
        // onDeleteSection={handleDeleteSectionOnly}
      />
    </>
  );
};

export default ChecklistItem;
