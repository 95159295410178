import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { useEffect, useState } from 'react';
import CustomListItem from 'components/List/ListItem/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import {
  SearchInputCnt,
  StyledList,
  StyledSearchField,
} from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';

function SelectItemsList(props) {
  const {
    popperProps = {},
    searchCntProps = {},
    options,
    isMulti = true,
    children,
    selectedOptions,
    controlled = false,
    handleChangeCallback,
    canSearch = false,
    checkbox = false,
    searchPlaceholder = '',
    listProps = {},
    minOneSelected,
    searchFieldProps,
    labelRenderer,
    childLabelRenderer,
    footerRenderer = null,
    checkboxProps = {},
    searchedEmptyRenderer = null,
    searchQueryCallback,
    showSearchIcon = true,
  } = props;
  const [selected, setSelected] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expand, setExpand] = useState<any[]>([]);
  //handle list item open/collapse
  const handleListExpand = (id: string) => {
    if (expand.includes(id)) {
      const newIdsList = expand.filter((e) => e !== id);
      setExpand(newIdsList);
    } else {
      setExpand([...expand, id]);
    }
  };

  //handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  useEffect(() => {
    if (controlled) {
      setSelected(selectedOptions || []);
    }
  }, [selectedOptions]);
  //Toggle Check/Uncheck of options list for single select dropdown
  const handleSingleSelect = (value) => () => {
    //If option has a child just expand the list
    if (value.child) {
      handleListExpand(value.id);
      return;
    }
    const isAlreadyExist = value.id == selected[0]?.id;
    if (selected.length && isAlreadyExist && !minOneSelected) {
      if (controlled) {
        handleChangeCallback([]);
      } else {
        setSelected([]);
      }
    } else {
      if (controlled) {
        handleChangeCallback([value]);
      }
      setSelected([value]);
    }
  };
  //handle Select all children
  const handleSelectAll = (event, value) => {
    if (value.child) {
      event.stopPropagation();
    }
    if (!isMulti) {
      return;
    }
    if (value.child) {
      const allChildIds = value.child.map((c) => c.id);
      const isAllSelected = allChildIds.every((s) =>
        selected.some((x) => x.id == s),
      );
      const newChecked = isAllSelected
        ? selected.filter((s) => !value.child.some((x) => x.id == s.id))
        : [...selected, ...value.child];
      if (controlled) {
        handleChangeCallback(newChecked);
      } else {
        setSelected(newChecked);
      }
    }
  };
  //Toggle Check/Uncheck of options list for multiselect dropdown
  const handleMultiSelect = (value) => () => {
    //If option has a child just expand the list
    if (value.child) {
      handleListExpand(value.id);
      return;
    }
    const selectedIndex = selected.findIndex((o) => o.id == value.id);
    const newChecked = [...selected];
    // Multi Select
    if (selectedIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(selectedIndex, 1);
    }
    if (controlled) {
      handleChangeCallback(newChecked);
    } else {
      setSelected(newChecked);
    }
  };
  //Filtering options based on serach query
  const filteredOptions = () => {
    if (!searchQuery) {
      return options;
    }
    const optionsList = options.reduce((r, cv) => {
      if (cv.child) {
        r = [...r, ...cv.child];
      } else {
        r.push(cv);
      }
      return r;
    }, []);
    const optionsAfterFilter = optionsList.filter((obj) => {
      return obj.label.toLowerCase().includes(searchQuery.toLowerCase());
    });
    return optionsAfterFilter;
  };

  useEffect(() => {
    if (
      searchedEmptyRenderer &&
      searchQuery &&
      filteredOptions()?.length === 0
    ) {
      searchQueryCallback?.({ isHideFooter: true, searchQuery });
    } else {
      searchQueryCallback?.({ isHideFooter: false, searchQuery });
    }
  }, [searchQuery, filteredOptions, searchedEmptyRenderer]);

  return (
    <>
      {canSearch && (
        <SearchInputCnt
          className="searchbar"
          style={{ padding: '10px 24px 10px 24px' }}
          {...searchCntProps}
        >
          <StyledSearchField
            id="SearchInput"
            startAdornment={showSearchIcon && <SearchIcon />}
            onChange={handleSearch}
            placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
            value={searchQuery}
            label={''}
            autoFocus={true}
            fullWidth={true}
            endAdornment={
              searchQuery ? (
                <CancelIcon
                  onClick={() => setSearchQuery('')}
                  sx={{
                    color: 'rgba(146, 145, 167, 1)',
                    cursor: 'pointer',
                    fontSize: '22px',
                    marginRight: '6px',
                  }}
                />
              ) : null
            }
            {...searchFieldProps}
          />
        </SearchInputCnt>
      )}
      <StyledList
        sx={{ width: '100%' }}
        dense={true}
        disablePadding
        {...listProps}
      >
        {searchedEmptyRenderer && searchQuery && filteredOptions()?.length === 0
          ? searchedEmptyRenderer
          : filteredOptions().map((option, i) => {
              const isOpen = expand.includes(option.id);
              const { startIcon, endIcon, listItemProps } = option;
              const childIds = option?.child?.map((c) => c.id);
              const expandCollapseIcon = isOpen ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              );
              const allSelected = childIds?.every((s) =>
                selected.some((x) => x.id == s),
              );
              const someSelected = selected.some((s) => option.id == s.id);
              const startIconRenderer = (
                <span style={{ color: option.color }}>{option.startIcon}</span>
              );
              const endIconRenderer = (
                <span style={{ color: option.color }}>{option.endIcon}</span>
              );
              const { sx = {}, ...restListItemProps } = listItemProps
                ? listItemProps
                : {};
              return (
                <>
                  <CustomListItem
                    key={option.id}
                    id={option.id}
                    sx={{
                      borderTop:
                        option.child && '1px solid rgba(0, 0, 0, 0.12)',
                      ...sx,
                    }}
                    {...restListItemProps}
                    style={{ padding: '7px 16px 7px 30px' }}
                    selected={option.child ? allSelected : someSelected}
                    checkbox={checkbox}
                    onClick={
                      isMulti
                        ? handleMultiSelect(option)
                        : handleSingleSelect(option)
                    }
                    checkboxProps={{
                      id: 'checkbox' + option.label + i,
                      size: 'medium',
                      onClick: (event) => handleSelectAll(event, option),
                      radio: !isMulti && checkbox,
                      indeterminate:
                        option.child && !allSelected && someSelected,
                      ...checkboxProps,
                    }}
                    startIcon={startIcon && startIconRenderer}
                    endIcon={
                      option.child
                        ? expandCollapseIcon
                        : endIcon
                        ? endIconRenderer
                        : null
                    }
                    {...restListItemProps}
                  >
                    {labelRenderer?.(option, {
                      isSelected: option.child ? allSelected : someSelected,
                    }) || option.label}
                  </CustomListItem>
                  {option.child && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <StyledList
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                          maxHeight: '100%',
                        }}
                        dense={true}
                        disablePadding
                      >
                        {option.child.map((chOption, index) => {
                          const {
                            startIcon,
                            endIcon,
                            customProps = {},
                          } = chOption;
                          const startIconRenderer = (
                            <span style={{ color: option.color }}>
                              {startIcon}
                            </span>
                          );
                          const endIconRenderer = (
                            <span style={{ color: option.color }}>
                              {endIcon}
                            </span>
                          );
                          return (
                            <CustomListItem
                              key={chOption.id}
                              id={chOption.id}
                              style={{ paddingLeft: '36px' }}
                              selected={selected.some(
                                (s) => chOption.id == s.id,
                              )}
                              onClick={
                                isMulti
                                  ? handleMultiSelect(chOption)
                                  : handleSingleSelect(chOption)
                              }
                              checkboxProps={{
                                id: 'checkbox' + chOption.label + index,
                                size: 'medium',
                                ...checkboxProps,
                              }}
                              startIcon={startIcon && startIconRenderer}
                              endIcon={endIcon && endIconRenderer}
                              checkbox={checkbox}
                              {...customProps}
                            >
                              {childLabelRenderer?.(chOption, {
                                isSelected: selected.some(
                                  (s) => chOption.id == s.id,
                                ),
                              }) || chOption.label}
                            </CustomListItem>
                          );
                        })}
                      </StyledList>
                    </Collapse>
                  )}
                </>
              );
            })}
      </StyledList>
      {footerRenderer}
    </>
  );
}
export default SelectItemsList;
