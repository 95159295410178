import CustomButton from 'components/Button/CustomButton';
import React, { useCallback, useContext } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  DashboardCmpHeaderCnt,
  BreadcrumbHeadingCnt,
  DashboardCmpHeading,
  EditSwitchCnt,
  DashboardName,
} from 'pages/Dashboards/DashboardCmp/DashboardCmpHeader/dashboardCmpHeader.style';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledSwitch } from '../../../../styles/commonStyles/switch';
import {
  useDashboard,
  useUpdateDashboard,
} from 'pages/Dashboards/hooks/useDashboard';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { IconButtonBox } from 'pages/TemplateDashboard/Tabs/Spreadsheet/Header/styled';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CustomToolTip from 'components/Tooltip';
import { DashboardsContext } from 'pages/Dashboards/context/context';
import AddIcon from '@mui/icons-material/Add';
import DashboardActionDropdown from 'pages/Dashboards/dashboardActionDropdown';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import WithDashboardPermission from 'pages/Dashboards/withDashboardPermission';
import CustomInput from 'components/Form/TextField/TextField.cmp';

function DashboardCmpHeader() {
  const { selectedDashboard, updateDashboardInCache } = useDashboard();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const { updateDashboardQuery } = useUpdateDashboard();
  const {
    dashboardsState: { fullScreen, editable },
    updateDashboardsState,
  } = useContext(DashboardsContext);
  const goToDashboardsHome = useCallback(() => {
    navigateWithWorkspaceUrl('/dashboards?tab=custom-dashboards');
  }, []);
  const toggleFullScreen = useCallback(() => {
    updateDashboardsState({ fullScreen: !fullScreen });
  }, []);

  const handleEditableSwitch = useCallback((e) => {
    updateDashboardsState({ editable: e.target.checked });
  }, []);

  const onDeleteCallback = useCallback(() => {
    goToDashboardsHome();
  }, []);

  const handleUpdateTitle = (value) => {
    if (value) {
      const obj = {
        title: value as string,
        id: selectedDashboard?.id as string,
      };
      updateDashboardQuery.mutate(obj);
      updateDashboardInCache({ title: value });
    }
  };
  const handleInputBlur = (e) => {
    handleUpdateTitle(e.target.value);
  };
  const handleDashboardAccess = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.ACCESS_DASHBOARD_DIALOG,
        data: {
          dashboard: selectedDashboard,
          onSuccessCallback: () => {
            console.log('dashboard access updated');
          },
        },
      }),
    );
  };

  return (
    <DashboardCmpHeaderCnt>
      <BreadcrumbHeadingCnt>
        <DashboardCmpHeading
          className={'heading'}
          clickable={true}
          onClick={goToDashboardsHome}
        >
          Dashboards
        </DashboardCmpHeading>
        <ChevronRightIcon className={'rightArrowIcon'} />

        {editable ? (
          <CustomInput
            className={'dashboardTitleInput'}
            fieldProps={{
              defaultValue: selectedDashboard?.title,
              placeholder: 'Name your Dashboard',
              // onChange: onTitleChange,
              onBlur: handleInputBlur,
            }}
            suppressErrorSpace={true}
            suppressBorder={true}
          />
        ) : (
          <DashboardName>{selectedDashboard?.title}</DashboardName>
        )}
      </BreadcrumbHeadingCnt>
      <div className={'dashboardHeaderRightCnt'}>
        <WithDashboardPermission>
          <CustomButton
            variant={'outlined'}
            startIcon={<SettingsOutlinedIcon />}
            style={{ padding: '6px 16px', borderRadius: '6px' }}
            onClick={handleDashboardAccess}
          >
            Access
          </CustomButton>
          <EditSwitchCnt>
            <label className={'editLabel'}>Edit Mode:</label>
            <FormControlLabel
              checked={editable}
              label={''}
              onChange={handleEditableSwitch}
              control={<StyledSwitch sx={{ m: 1 }} />}
              sx={{
                margin: 0,
              }}
            />
          </EditSwitchCnt>
        </WithDashboardPermission>
        <CustomToolTip
          placement="top"
          title={fullScreen ? 'Exit Full Screen' : 'Full Screen View'}
        >
          <IconButtonBox onClick={toggleFullScreen}>
            <div className="content">
              {fullScreen ? (
                <CloseFullscreenIcon
                  className="icon"
                  sx={{ color: 'rgba(97, 97, 97, 1)', fontSize: '18px' }}
                />
              ) : (
                <OpenInFullOutlinedIcon
                  className="icon"
                  sx={{ color: 'rgba(97, 97, 97, 1)', fontSize: '18px' }}
                />
              )}
            </div>
          </IconButtonBox>
        </CustomToolTip>
        <WithDashboardPermission>
          <DashboardActionDropdown
            dashboardId={selectedDashboard?.id || ''}
            onDeleteCallback={onDeleteCallback}
          />
        </WithDashboardPermission>
      </div>
    </DashboardCmpHeaderCnt>
  );
}

export default DashboardCmpHeader;
