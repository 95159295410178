import { findPhoneNumbersInText } from 'libphonenumber-js';

export const getPhoneCode = (number) => {
  const phoneNumberObj = findPhoneNumbersInText(number);
  return phoneNumberObj[0]?.number?.countryCallingCode;
};
export const getCountryCode = (number) => {
  const phoneNumberObj = findPhoneNumbersInText(number);
  return phoneNumberObj[0]?.number?.country;
};

export const getNumberWithCode = (number) => {
  const phoneNumberObj = findPhoneNumbersInText(number);
  return phoneNumberObj[0]?.number?.number;
};

export const getNumberWithoutCode = (number) => {
  const phoneNumberObj = findPhoneNumbersInText(number);
  return phoneNumberObj[0]?.number?.nationalNumber;
};
