import React, { FC } from 'react';

import CheckboxRoundChecked from 'components/Icons/checkboxRoundChecked';
import RadioButtonChecked from 'components/Icons/radioButtonChecked';
import RadioButtonUnChecked from 'components/Icons/radioButtonUnChecked';

import { LeftNavCnt } from 'pages/CreateWorkspace/style';

const CreateWorkspaceLeftNav = ({ step = 1 as any }) => {
  const getCheckbox = (st) => {
    if (st === step) return <RadioButtonChecked />;
    else if (st < step) return <CheckboxRoundChecked />;
    return <RadioButtonUnChecked />;
  };

  const stepStyle = {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    color: '#fff',
  };

  const separatorStyle = {
    margin: '12px',
    borderLeft: '1px dashed rgba(255, 255, 255, 0.62)',
    height: '100px',
    borderStyle: 'dashed',
    borderRight: '0px',
    borderTop: '0px',
    borderBottom: '0px',
  };
  return (
    <LeftNavCnt>
      <div>
        <div style={stepStyle}>{getCheckbox(1)} STEP 1</div>
        <div style={separatorStyle} />
        <div style={stepStyle}>{getCheckbox(2)} STEP 2</div>
      </div>
    </LeftNavCnt>
  );
};

export default CreateWorkspaceLeftNav;
