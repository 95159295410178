import { ActionItemDescription } from 'pages/documents/mainHeader/styles';
import AddToFolderIcon from 'components/Icons/addToFolderIcon';
import AddDocuemntIcon from 'components/Icons/addDocumentIcon';
import UploadIcon from 'components/Icons/uploadIcon';

export const getHeaderItems = (clickHandlers) => {
  return [
    {
      icon: {
        component: UploadIcon,
        iconBackgroundColor: 'rgba(139, 195, 74, 1)',
        styles: { width: 22, height: 22, color: 'white' },
      },
      heading: 'Upload a File',
      descriptionRenderer: (
        <>
          <ActionItemDescription component="span">
            Effortlessly upload and share documents, images, and more.
          </ActionItemDescription>
        </>
      ),

      bgColor: 'rgba(241, 248, 233, 1)',
      borderColor: 'rgba(139, 195, 74, 1)',
      onClick: clickHandlers[0],
    },
    {
      icon: {
        component: AddDocuemntIcon,
        iconBackgroundColor: 'rgba(149, 117, 205, 1)',
        styles: { width: 22, height: 22, color: 'white' },
      },
      heading: 'Write an SOP with AI',
      description:
        'Need help? Let our AI tool augment, revise, or even create your SOP from scratch.',
      bgColor: 'rgba(237, 231, 246, 1)',
      borderColor: 'rgba(126, 87, 194, 1)',
      onClick: clickHandlers[1],
    },
    {
      icon: {
        component: AddToFolderIcon,
        iconBackgroundColor: 'rgba(3, 169, 244, 1)',
        styles: {
          width: 22,
          height: 22,
          fill: 'none',
          '& path': {
            stroke: 'white',
            strokeOpacity: 1,
          },
        },
      },
      heading: 'Create a Folder',
      description:
        'Categorize your documents into separate folders for quick access and stay organized.',
      bgColor: 'rgba(225, 245, 254, 1)',
      borderColor: 'rgba(3, 169, 244, 1)',
      onClick: clickHandlers[2],
    },
  ];
};
