import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import UserAvatar from 'components/Avatar';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';
import EditIcon from 'components/Icons/editIcon';
import { getUserAdmin } from 'store/selectors/users';
import { CreatedByCnt, MainCnt } from './style';

export const FlaggedCategoryName = (props) => {
  const { params, actionsProps } = props;
  const { data } = params || {};
  const { id, meta, name } = data || {};
  const { onRowStartEditing, updateHandler } = actionsProps;
  return (
    <MainCnt>
      <ColorsDropdown
        selected={meta?.color}
        changeHandler={(color) => {
          updateHandler({ name, meta: { color }, id });
        }}
      />
      <Typography
        fontWeight="500"
        lineHeight="18.68px"
        fontSize="14px"
        color="#000000"
        marginLeft="12px"
      >
        {name}
      </Typography>
      <IconButton
        style={{
          marginLeft: 10,
          display: 'none',
          backgroundColor: 'transparent',
          padding: 0,
          marginBottom: 4,
        }}
        className="editIcon"
        onClick={() => {
          onRowStartEditing(params);
        }}
      >
        <EditIcon style={{ fontSize: 15, color: '#8e8484' }} />
      </IconButton>
    </MainCnt>
  );
};

export const CreatedBy = (props) => {
  const { data } = props;
  const { CreatedBy } = data || {};
  const usersList = useSelector(getUserAdmin);

  const user = useMemo(() => {
    if (CreatedBy) {
      const selectedUser = usersList?.find((item) => item?.id === CreatedBy);
      return selectedUser;
    }

    return null;
  }, [CreatedBy]);

  return (
    <MainCnt>
      <CreatedByCnt>
        <UserAvatar
          width={24}
          height={24}
          userId={CreatedBy}
          className="userIcon"
        />
        <Typography className="name">
          {user?.firstName} {user?.lastName}
        </Typography>
      </CreatedByCnt>
    </MainCnt>
  );
};
