import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinesEllipsis from 'react-lines-ellipsis';
import { Grid, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import DIALOGS from 'utils/dialogIds';
import selectors from 'store/selectors';
import { setDialog } from 'store/actions/dialogActions';
import { getSuggestedChecklists } from 'api/checklistV2Api';
import {
  filterTemplates,
  flattenTemplateObject,
  formatTemplateLibraryData,
} from 'components/TemplateLibrary/utils';

import { ChecklistBox, Container, Content, Header, StyledChip } from './style';

// Browse Sample Templates
const BrowseSampleTemplates = () => {
  const dispatch = useDispatch();
  const [industryTemplates, setIndustryTemplates] = useState<any[]>([]);
  const user = useSelector(selectors.getLoggedInUser);

  const getTemplates = async () => {
    const templates = await getSuggestedChecklists();
    setIndustryTemplates(templates?.data || []);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const viewAllHandler = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.TEMPLATE_LIBRARY,
        open: true,
        data: {
          filters: {
            industries: [
              {
                id: user?.Hotel?.Industry?.id,
              },
            ],
          },
        },
      }),
    );
  };

  const templateClickHandler = (template) => {
    const filtered = filterTemplates(null, industryTemplates);
    const formattedTemplates = formatTemplateLibraryData(filtered);

    dispatch(
      setDialog({
        dialogId: DIALOGS.TEMPLATE_PREVIEW,
        open: true,
        data: {
          templates: flattenTemplateObject(formattedTemplates),
          selectedTemplate: template,
        },
      }),
    );
  };

  return industryTemplates?.length === 0 ? (
    <></>
  ) : (
    <Container>
      <Header>
        <Typography className="title">
          Browse sample{' '}
          <span className="industry">{user?.Hotel?.Industry?.name}</span>{' '}
          templates
        </Typography>

        <CustomButton
          className="viewAll"
          variant="outlined"
          onClick={viewAllHandler}
        >
          View All
        </CustomButton>
      </Header>

      <Content>
        <Grid container spacing={2}>
          {industryTemplates?.slice?.(0, 4)?.map((template) => {
            return (
              <Grid item xs={3}>
                <ChecklistBox onClick={() => templateClickHandler(template)}>
                  {template?.ChecklistType?.name ? (
                    <StyledChip
                      label={template?.ChecklistType?.name}
                      type="type"
                      typelabel={template?.ChecklistType?.name}
                    />
                  ) : (
                    <div style={{ height: 35 }} />
                  )}
                  <LinesEllipsis
                    className="title"
                    text={template?.name || ''}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                  <LinesEllipsis
                    className="description"
                    text={template?.description || 'by Xenia Team'}
                    maxLine="2"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </ChecklistBox>
              </Grid>
            );
          })}
        </Grid>
      </Content>
    </Container>
  );
};

export default BrowseSampleTemplates;
