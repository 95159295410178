// React
import { useContext, useMemo, Fragment } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// MUI
import { Stack, Typography, Divider, Box } from '@mui/material';

// Components
import IosSwitch from 'components/IosSwitch';
import LogoUpload from 'components/PublicRequestConfig/components/LogoUpload';
import TextInput from 'components/PublicRequestConfig/components/TextInput';
import Layout from './Layout';
import FieldWithActions from 'components/PublicRequestConfig/components/FieldWithActions';
import RichTextEditor from 'components/RichTextEditor';

// Icons
import SingleUserIcon from 'components/Icons/singleUserIcon';
import EnvelopeIcon from 'components/Icons/envelopeIcon';
import PhoneIconOutlined from 'components/Icons/phoneIconOutlined';
import GlobeIcon from 'components/Icons/globeIcon';
import LinkIconRotated from 'components/Icons/LinkIconRotated';
import QRCodeIcon from 'components/Icons/qrcodeIcon';
import QRCodeIcon2 from 'components/Icons/qrcodeIcon2';

// Utils
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { showMessageNotification } from 'utils/globalFunction';
import DIALOGS from 'utils/dialogIds';

// Styled
import { CopyLinkBox, CopyLinkText } from './styled';

import { RequestConfigContext } from '../context';
import CustomButton from 'components/Button/CustomButton';
import EditIcon from 'components/Icons/editIcon';

const RIGHT_WIDTH = '600px';

const PortalSettings = () => {
  const ctx = useContext(RequestConfigContext);
  const { workspaceId } = useWorkspaceHook();
  const dispatch = useDispatch();

  const handleChangeSharePortal = (event: any) => {
    ctx?.updateConfig('enabled', event.target?.checked);
  };

  const handleToggleRequestorInfoVisibility = (event: any) => {
    const toggleState = event.target?.checked;
    const visibility = {
      show: toggleState,
      required: false,
    };
    ctx?.updateConfig('fields', {
      ...ctx?.config?.fields,
      fullName: visibility,
      email: visibility,
      phone: visibility,
    });
  };

  const isPortalEnabled = useMemo(() => !!ctx?.config?.enabled, [ctx?.config]);
  const requestorInfoVisible = useMemo(() => {
    const fields = ctx?.config?.fields;
    return (
      !!fields?.fullName?.show || !!fields?.email?.show || !!fields?.phone?.show
    );
  }, [ctx?.config]);

  const infoForm = useMemo(() => {
    const config = ctx?.config;
    const fields = [
      {
        key: 'fullName',
        label: 'Full Name',
        icon: <SingleUserIcon />,
      },
      {
        key: 'email',
        label: 'Email Address',
        icon: <EnvelopeIcon />,
      },
      {
        key: 'phone',
        label: 'Phone Number',
        icon: <PhoneIconOutlined />,
      },
    ];

    return fields.map((field) => ({
      ...field,
      visibility: config?.fields[field.key],
    }));
  }, [ctx?.config]);

  const handleUpdateInfoFormVisibility = (field, visibility: string) => {
    if (
      field.key === 'email' &&
      visibility === 'Hidden' &&
      ctx?.config?.notifyRequester
    ) {
      showMessageNotification(
        'Email notifications are enabled. Please disable email notifications to hide email field.',
        'error',
      );
      return;
    }
    ctx?.handleUpdateFieldVisibility(field.key, visibility);
  };

  const handleUpdateEmailNotification = (event: any) => {
    if (event.target?.checked && !ctx?.config?.fields?.email?.show) {
      showMessageNotification(
        'Email field is hidden. Please enable email field to enable email notifications.',
        'error',
      );
      return;
    }
    ctx?.updateConfig('notifyRequester', event.target?.checked);
  };

  const getLink = () => {
    return `${window.location.origin}/ws/${workspaceId}/public-request/${
      ctx?.config?.isDefault ? '' : `?configId=${ctx?.config?.id}`
    }`;
  };

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(getLink());
    showMessageNotification('Copied to clipboard');
  };

  const handleShowQRDialog = async () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.QR_CODE,
        data: {
          qrText: getLink(),
          headerText: 'Share Portal',
          subText:
            'Download / print this QR code to allow people to scan and access this portal via the Mobile App.',
        },
        open: true,
      }),
    );
  };

  const customizeFormFields = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.REQUEST_FORM_FIELDS,
        open: true,
        data: {
          successCallbackHandler: ctx?.updateConfigState,
        },
      }),
    );
  };

  return (
    <Fragment>
      {/* First Section */}
      <Layout
        margin={'8px 24px 0px 24px'}
        leftText={{
          heading: 'Share Portal',
          description: `Anyone with the link will be able to access this portal. They don’t
          have to log in to their Xenia account.`,
        }}
      >
        <Stack width={RIGHT_WIDTH} gap={'16px'}>
          <Stack
            marginBottom={'6px'}
            direction={'row'}
            gap={'8px'}
            alignItems={'center'}
          >
            <IosSwitch
              checked={isPortalEnabled}
              onChange={handleChangeSharePortal}
            />
            <Typography fontSize={14} fontWeight={600} color={'#212121'}>
              {isPortalEnabled ? 'Enabled' : 'Disabled'}
            </Typography>
          </Stack>
          {isPortalEnabled && (
            <Stack gap={'12px'}>
              <LogoUpload
                logo={ctx?.config?.logo ?? null}
                handleUpload={(logoPath) => ctx?.updateConfig('logo', logoPath)}
                handleRemove={() => ctx?.updateConfig('logo', null)}
              />
              <TextInput
                label="Portal Name"
                placeholder="Enter Portal Name"
                value={ctx?.config?.title ?? ''}
                handleChange={(value) =>
                  ctx?.updateConfig('title', value ?? 'Request Portal Name')
                }
              />
              <Stack gap={'4px'}>
                <Typography
                  fontWeight={600}
                  fontSize={13}
                  color={'#212121'}
                  letterSpacing={0.15}
                >
                  Instructions
                </Typography>
                <Box
                  padding={'2px'}
                  borderRadius={'8px'}
                  border={'1px solid #d8dadd'}
                >
                  <RichTextEditor
                    value={ctx?.config?.instructions ?? ''}
                    onChange={(value) =>
                      ctx?.updateConfig('instructions', value ?? null)
                    }
                    height={275}
                  />
                </Box>
              </Stack>
              <Stack
                direction={'row'}
                gap={'8px'}
                alignItems={'center'}
                padding={'12px'}
                border="1px solid #E0E0E0"
                borderRadius={'8px'}
              >
                <GlobeIcon
                  style={{ color: 'rgba(104, 104, 254, 1)', fontSize: '22px' }}
                />
                <Typography
                  flex={1}
                  fontSize={14}
                  fontWeight={500}
                  color={'#212121'}
                >
                  {getLink()}
                </Typography>
                <CopyLinkBox onClick={handleCopyLink}>
                  <LinkIconRotated
                    sx={{ color: 'rgba(104, 104, 254, 1)', fontSize: '18px' }}
                  />
                  <CopyLinkText>Copy Link</CopyLinkText>
                </CopyLinkBox>
              </Stack>

              <Stack
                direction={'row'}
                gap={'8px'}
                alignItems={'center'}
                padding={'6px 12px'}
                border="1px solid #E0E0E0"
                borderRadius={'8px'}
                justifyContent={'space-between'}
              >
                <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
                  <QRCodeIcon style={{ fontSize: '64px' }} />
                  <Stack>
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      color={'#212121'}
                    >
                      {ctx?.config?.title ?? ''}
                    </Typography>
                    <Typography
                      fontSize={12}
                      fontWeight={400}
                      color={'#616161'}
                    >
                      Created in Xenia
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  border={'1px solid rgba(132, 134, 255, 1)'}
                  borderRadius={'4px'}
                  gap={'8px'}
                  alignItems={'center'}
                  onClick={handleShowQRDialog}
                  direction={'row'}
                  padding={'4px 10px'}
                  sx={{ cursor: 'pointer' }}
                >
                  <QRCodeIcon2
                    style={{
                      fontSize: '18px',
                      fill: 'none',
                      color: 'rgba(104, 104, 254, 1)',
                    }}
                  />
                  <CopyLinkText>View</CopyLinkText>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Layout>

      {isPortalEnabled && (
        <Layout
          margin={'20px 24px 0px 24px'}
          leftText={{
            heading: 'Request Form Fields',
            description: `Specify what information is required from the requestor`,
          }}
        >
          <Stack
            direction={'row'}
            gap={'8px'}
            height="fit-content"
            alignItems={'center'}
          >
            <CustomButton
              variant="outlined"
              onClick={customizeFormFields}
              startIcon={<EditIcon />}
            >
              Customize Form Fields
            </CustomButton>
          </Stack>
        </Layout>
      )}

      {isPortalEnabled && (
        <Layout
          margin={'20px 24px 0px 24px'}
          leftText={{
            heading: 'Requester Information',
            description: `Specify what information is required from the requestor`,
          }}
        >
          <Stack width={RIGHT_WIDTH} gap={'16px'}>
            <Stack
              marginBottom={'14px'}
              direction={'row'}
              gap={'8px'}
              alignItems={'center'}
            >
              <IosSwitch
                checked={requestorInfoVisible}
                onChange={handleToggleRequestorInfoVisibility}
              />
              <Typography fontSize={14} fontWeight={600} color={'#212121'}>
                {requestorInfoVisible ? 'Visible' : 'Hidden'}
              </Typography>
            </Stack>
            {requestorInfoVisible && (
              <Stack>
                {infoForm.map((field, idx) => (
                  <Fragment>
                    <FieldWithActions
                      icon={field.icon}
                      label={field.label}
                      visibility={field.visibility}
                      onVisibilityChange={(visibility) =>
                        handleUpdateInfoFormVisibility(field, visibility)
                      }
                    />
                    {idx !== infoForm.length - 1 && (
                      <Divider sx={{ my: '8px' }} />
                    )}
                  </Fragment>
                ))}
              </Stack>
            )}
          </Stack>
        </Layout>
      )}
      {/* Third Section */}
      {isPortalEnabled && (
        <Layout
          margin={'20px 24px 0px 24px'}
          leftText={{
            heading: 'Email Notifications',
            description: `Send email notifications of status updates to the requester`,
          }}
          supressBorder
        >
          <Stack
            direction={'row'}
            gap={'8px'}
            height="fit-content"
            alignItems={'center'}
          >
            <IosSwitch
              checked={ctx?.config?.notifyRequester}
              onChange={handleUpdateEmailNotification}
            />
            <Typography fontSize={14} fontWeight={600} color={'#212121'}>
              {ctx?.config?.notifyRequester ? 'Enabled' : 'Disabled'}
            </Typography>
          </Stack>
        </Layout>
      )}
    </Fragment>
  );
};

export default PortalSettings;
