// React
import { useState, Fragment, useEffect } from 'react';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import SubItemsList from './SubItemsList';
import SubItemsListWithPopper from './SubItemsListWithPopper';

// Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './sidebarOperationTemplate.style';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';

// Constants
import appConstants from 'utils/appConstants';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// React Router
import { useLocation } from 'react-router-dom';

// Utils
import { getReports } from 'api/reporting';

interface PropTypes {
  sidebarOpen: boolean;
}

const SidebarOperationTemplateDropdown = (props: PropTypes) => {
  const { sidebarOpen } = props;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const [submissionReportsId, setSubmissionReportsId] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const handleClickAccordion = (
    event: React.SyntheticEvent,
    expanded: boolean,
  ) => {
    setExpanded(expanded);
  };

  const getAndSetSubmissionReportsId = async () => {
    const reports = await getReports();
    if (reports) {
      const report = reports?.find((r) => r?.slug === 'template-submissions');
      setSubmissionReportsId(report?.id);
    }
  };

  useEffect(() => {
    getAndSetSubmissionReportsId();
  }, []);

  return (
    <Fragment>
      {sidebarOpen ? (
        <StyledAccordion onChange={handleClickAccordion} expanded={expanded}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SidebarItem
              title={appConstants.checklist.plural.base}
              icon={<ChecklistIcon />}
              isSelected={false}
              sidebarOpen={sidebarOpen}
              disableHover
              onClick={() => navigateWithWorkspaceUrl('/checklist?view=grid')}
            />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <SubItemsList submissionReportsId={submissionReportsId} />
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : (
        <SubItemsListWithPopper submissionReportsId={submissionReportsId} />
      )}
    </Fragment>
  );
};

export default SidebarOperationTemplateDropdown;
