import { NoAddedCnt, TextCnt, MainText, SubText } from './noAddedRecords.style';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import CustomButton from 'components/Button/CustomButton';
import { isMobileApp } from 'utils/constants';
import { postToMobileApp } from 'utils/globalFunction';

const NoAddedRecords = () => {
  const dispatch = useDispatch();

  const onClickAdd = () => {
    if (isMobileApp) {
      postToMobileApp('navigateTo', 'CreateAsset');
    } else {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createAsset',
        }),
      );
    }
  };

  return (
    <NoAddedCnt>
      <img src={'/assets/img/assets_empty_state.svg'} />
      <TextCnt>
        <MainText>
          Track your Asset details & maintenance history with ease
        </MainText>
        <SubText>
          Create and manage all assets, equipment, inventory and tools in one
          place. Every asset can attach to work orders, operations templates,
          and data reports for ultimate clarity of maintenance health.
        </SubText>
      </TextCnt>
      <CustomButton className="addBtn" variant="contained" onClick={onClickAdd}>
        Add Asset
      </CustomButton>
    </NoAddedCnt>
  );
};

export default NoAddedRecords;
