import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CTabs from 'components/CTabs';
import MyDocuments from 'pages/documents/myDocuments';
import { DocumentsContext } from 'pages/documents/context/context';
import { TabsCnt } from 'pages/documents/style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useMeasure from 'react-use-measure';

function DocumentsTabs() {
  const { updateState } = useContext(DocumentsContext);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { tab } = useParams();

  const tabs = useMemo(
    () => [
      {
        label: 'My Documents',
        content: () => (tab === 'my-documents' ? <MyDocuments /> : <></>),
        path: 'my-documents',
      },
      {
        label: 'Archive',
        content: () => (tab === 'archive' ? <MyDocuments /> : <></>),
        path: 'archive',
      },
    ],
    [tab],
  );

  useEffect(() => {
    if (!tab) {
      navigateWithWorkspaceUrl(`/documents/my-documents`);
    }
  }, []);

  //Return object of tab based on the url path
  const getActiveTab = useCallback(
    (activeTab) => tabs.findIndex((t) => t.path === activeTab),
    [tab],
  );

  //Callback function to change the tab based on the url path
  const onTabChangeCb = (activeTab: number) => {
    const path = tabs[activeTab].path;
    navigateWithWorkspaceUrl(`/documents/${path}`);
    updateState({
      selectedFolder: '',
      loading: true,
      isArchive: path === 'my-documents' ? false : true,
    });
  };

  return (
    <TabsCnt>
      <CTabs
        data={tabs}
        onTabChangeCb={onTabChangeCb}
        activeTab={getActiveTab(tab)}
        variant="scrollable"
      />
    </TabsCnt>
  );
}

export default DocumentsTabs;
