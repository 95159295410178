import { Grid } from '@mui/material';
import StatBox from './statBox';

const Stats = (props) => {
  const { scheduleStats } = props;

  return (
    <Grid container spacing={2} style={{ marginBottom: 30 }}>
      <Grid item xs={12} sm={4}>
        <StatBox
          title={'Completed on Time'}
          count={
            scheduleStats?.find((item) => item?.status === 'Completed on Time')
              ?.data ?? 0
          }
          percentage={
            scheduleStats?.find((item) => item?.status === 'Completed on Time')
              ?.percentage ?? 0
          }
          color={'rgba(4, 184, 108, 1)'}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <StatBox
          title={'Completed Late'}
          count={
            scheduleStats?.find((item) => item?.status === 'Completed Late')
              ?.data ?? 0
          }
          percentage={
            scheduleStats?.find((item) => item?.status === 'Completed Late')
              ?.percentage ?? 0
          }
          color={'rgba(211, 47, 47, 1)'}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <StatBox
          title={'Incomplete'}
          count={
            scheduleStats?.find((item) => item?.status === 'Incomplete')
              ?.data ?? 0
          }
          percentage={
            scheduleStats?.find((item) => item?.status === 'Incomplete')
              ?.percentage ?? 0
          }
          color={'rgba(237, 108, 2, 1)'}
        />
      </Grid>
    </Grid>
  );
};

export default Stats;
