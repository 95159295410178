export const isError = (data, currentList, errorFunc) => {
  let error: any = null;
  if (!data) {
    error = 'Title is the required field';
  } else if (data?.length > 250) {
    error = 'Title must be less or equal to 250 characters';
  } else {
    const isExists = currentList.find((item) => item.type === data);
    if (isExists) {
      error = 'Title already exists';
    }
  }

  if (error && errorFunc) {
    errorFunc(error);
  }
  return error;
};
