import { statusDropdownData } from 'helper/task/dropdownData';
import React from 'react';
import moment from 'moment/moment';
import { WeekCustomHeader } from 'pages/task/TaskListView/TaskCalendar/Renderers/renderer.style';

const TimeLineEventHeader = ({ date }) => {
  const day = moment(date).format('ddd');
  const dayNumber = moment(date).format('DD');
  const isToday = moment().isSame(date, 'day');
  return (
    <WeekCustomHeader isToday={isToday}>
      <span className="day">{day}</span>
      <span className="dayNumber">{dayNumber}</span>
    </WeekCustomHeader>
  );
};

export default TimeLineEventHeader;
