import { MainCnt } from 'pages/Templates/FoldersListView/list.style';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useMemo } from 'react';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';
import { ActionBtn, Value } from '../../list.style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import UserOrTeamAvatarGroup from 'components/Avatar/UserOrTeamAvatarGroup';
import selectors from 'store/selectors';
import CTypography from 'components/CTypography';

function TemplateAdmins(props) {
  const { template } = props;

  const templateAdmins = template?.ChecklistAccess?.filter(
    (access) => access.role === 'admin',
  );

  const users = useSelector(selectors.getUserAdmin);

  const data = useMemo(() => {
    if (templateAdmins?.length === 1) {
      return users?.find(
        (user) => user?.id === templateAdmins?.[0]?.AccessorId,
      );
    }
    return null;
  }, [templateAdmins, users]);

  return (
    <MainCnt>
      {templateAdmins?.length ? (
        <>
          <div className="pointer-cursor">
            <UserOrTeamAvatarGroup
              members={templateAdmins.map((access) => access?.AccessorId)}
            />
          </div>
          <Value style={{ marginLeft: 7 }}>
            {data?.firstName} {data?.lastName}
          </Value>
        </>
      ) : (
        <Value>All users & teams</Value>
      )}
    </MainCnt>
  );
}

export default TemplateAdmins;
