import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  padding: '16px 72px 16px 24px',
  gap: 24,
  width: '100%',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  background: '#E5EBF5',
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    padding: '8px 15px 8px 40px',
  },
  '.slick-slider': {
    width: 'calc(100vw - 100px)',
  },
  '.slick-slide': {
    padding: '0 10px',
  },
}));
export const CloseIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '24px',
  padding: 2,
  top: 14,
  zIndex: 1,
  '& > svg': {
    fontSize: '22px',
  },
}));

export const HeaderText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const ActionItemsRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '22px',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const ActionItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgcolor' && prop !== 'bordercolor',
})(({ theme, bgcolor, bordercolor }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: 16,
  borderRadius: '10px',
  cursor: 'pointer',
  flex: 1,
  transition: '0.15s all ease-in-out',
  border: `2px solid`,
  borderColor: bordercolor ? bordercolor : 'transparent',
  backgroundColor: '#ffffff',
  padding: '16px 14px',
  '&:hover': {
    backgroundColor: bgcolor ?? 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 120,
    alignItems: 'center',
  },
}));

export const ActionItemIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgcolor',
})(({ bgcolor }) => ({
  padding: '3px',
  height: '43px',
  width: '43px',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: `linear-gradient(${
    bgcolor.split(',').slice(0, 3).join(',') + ', 0.4)'
  }, rgba(255,255,255,0))`,
}));

export const ActionItemIcon = styled(Box, {
  shouldForwardProp: (prop) => !['bgcolor', 'svgdimensions'].includes(prop),
})(({ bgcolor, svgdimensions = {} }) => ({
  padding: '10px',
  backgroundColor: bgcolor,
  height: '40px',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  '& > svg': {
    fill: 'none',
    color: 'white',
    height: '20px',
    width: '20px',
    strokeWidth: '1px',
    ...svgdimensions,
  },
}));

export const ActionItemTextWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  width: '80%',
  textAlign: 'left',
}));

export const ActionItemHeading = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '21.86px',
  color: '#212121',
}));

export const ActionItemDescription = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 600,
  lineHeight: '17.76px',
  color: '#212121',
}));
