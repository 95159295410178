/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { uniq } from 'lodash';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { queryStreamUsers } from 'services/getStreamServices';
import { fixStreamUsers } from 'api/userApi';
export const useQueryUsersList = (showAddMember): any => {
  const [queryUsersObj, setQueryUsersObj] = useState<any>({ users: null });
  const [searchStr, setSearchStr] = useState('');
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const hotelId: any = useSelector(selectors.getUserHotelId);

  const users = useSelector(selectors.getUserAdmin);
  const getQueryUserData = async () => {
    try {
      const activeUsers =
        users
          ?.filter(({ id, UserHotels, firstName, lastName }) => {
            const status = UserHotels?.[0]?.status;
            let matched =
              userProfileData?.id !== id &&
              ['Active', 'Reset'].includes(status);
            if (matched && searchStr?.length) {
              matched = `${firstName || ''} ${lastName || ''}`
                .trim()
                .includes(searchStr);
            }
            return matched;
          })
          .map((u) => ({
            id: u.id,
            role: 'user',
            teams: [u.HotelId],
            name: `${u.firstName || ''} ${u.lastName || ''}`.trim(),
            hotelId: u.HotelId,
            profileImage: u.photo,
          })) || [];
      setQueryUsersObj({ users: activeUsers });
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const updateGetStreamUsers = async () => {
      try {
        const result = await queryStreamUsers({
          hotelId,
        });
        const { users: streamUsers = [] } = result;
        const streamUserIds: any = streamUsers.map(({ id }) => id);
        const streamUserIdsStr = uniq(streamUserIds).sort().join(', ');
        const userIds: any =
          users
            ?.filter(({ UserHotels }) =>
              ['Active', 'Reset'].includes(UserHotels?.[0]?.status),
            )
            .map(({ id }) => id) || [];
        const userIdsStr = uniq(userIds).sort().join(', ');
        if (streamUserIdsStr !== userIdsStr) {
          await fixStreamUsers({ streamUsers });
          getQueryUserData();
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    updateGetStreamUsers();
  }, []);

  useEffect(() => {
    if (showAddMember) {
      getQueryUserData();
    }
  }, [searchStr, showAddMember, users]);

  const handleTextChange = useCallback((e) => {
    setSearchStr(e.target.value);
  }, []);

  return {
    queryUsersObj,
    handleTextChange,
    searchStr,
  };
};
