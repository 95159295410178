// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ComponentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bannervisible',
})(({ bannervisible }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  paddingTop: bannervisible ? '22px' : '16px',
  height: 'calc(100vh - 190px)',
  overflow: 'auto',
}));

export const BannerContainer = styled(Box)(({ bannervisible }) => ({
  padding: '0px 16px',
}));
