// React
import { Fragment, useRef, useState, useEffect } from 'react';

// Custom components
import EmptyState from './EmptyState';
import GridView from 'pages/Projects/GridView';
import Header from 'pages/Projects/Header';
import EmptyResultsFilter from './EmptyResultsFilter';

// MUI
import { CircularProgress } from '@mui/material';

// Styled
import { Container, Content, LoadingContainer } from './styled';

// Context
import { ProjectsCtx } from './context';

// Hooks
import { useWindowResize } from 'controller/useWindowResize';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useProjects } from './useProjects';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const Projects = ({ customPayload = {} }) => {
  const size = useWindowResize();
  const { isMobileDeviceSm } = useResponsive();
  const { hasFeature, FEATURES } = useCustomPermission();

  const [height, setHeight] = useState<any>(0);
  const [selectedView, setSelectedView] = useState<'grid' | 'list'>('grid');
  const ref = useRef<any>(null);

  useEffect(() => {
    setHeight(
      isMobileDeviceSm
        ? ref?.current?.clientHeight + 40
        : ref?.current?.clientHeight - 30,
    );
  }, [size, ref?.current?.clientHeight]);

  const {
    data,
    advanceFilters,
    searchTerm,
    totalCount,
    isNextPageLoading,
    loading,
    noResults,
    setSearchTerm,
    setAdvanceFilters,
    handleLoadNextPage,
    fetchData,
    handleChangeSearch,
    removeProject,
    updateProject,
  } = useProjects(1000000, customPayload);

  const contextValue = {
    data,
    loading,
    advanceFilters,
    searchTerm,
    selectedView,
    setAdvanceFilters,
    setSearchTerm,
    handleChangeSearch,
    setSelectedView,
  };

  const deleteProjectHandler = (projectId) => {
    removeProject(projectId);
  };

  const updateProjectHandler = (project) => {
    updateProject(project);
  };

  useEffect(() => {
    if (isMobileDeviceSm) {
      setSelectedView('grid');
    }
  }, [isMobileDeviceSm]);

  return (
    <ProjectsCtx.Provider value={contextValue}>
      <Content
        ref={ref}
        sx={{
          ...(!hasFeature(FEATURES.PROJECTS) && {
            marginTop: 0,
          }),
        }}
      >
        {hasFeature(FEATURES.PROJECTS) && (
          <Header createProjectCallback={() => fetchData()} />
        )}
        <div>
          {loading && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
          {data !== null && !loading && (
            <Fragment>
              {data?.length === 0 ? (
                <Fragment>
                  {noResults ? (
                    <EmptyResultsFilter />
                  ) : (
                    <EmptyState createProjectCallback={() => fetchData()} />
                  )}
                </Fragment>
              ) : (
                <Container>
                  <GridView
                    createProjectCallback={() => fetchData()}
                    isNextPageLoading={isNextPageLoading}
                    handleLoadNextPage={handleLoadNextPage}
                    totalCount={totalCount}
                    projects={data}
                    height={height}
                    deleteProjectHandler={deleteProjectHandler}
                    updateProjectHandler={updateProjectHandler}
                  />
                </Container>
              )}
            </Fragment>
          )}
        </div>
      </Content>
    </ProjectsCtx.Provider>
  );
};

export default Projects;
