import ArchiveEmpty from 'public/assets/img/archiveEmpty.svg';
import CTypography from 'components/CTypography';
import { EmptyCnt } from 'pages/Templates/MyTemplates/emptyStates/myTemplates/styles';

function AchiveEmpty() {
  return (
    <EmptyCnt>
      <div className="content">
        <img alt="templates" src={ArchiveEmpty} />
        <CTypography className="title">Nothing here</CTypography>
        <CTypography className="subtitle">
          It seems like you haven't archived any templates yet. Once you do,
          they will appear here. Archiving helps keep your workspace clean and
          organized, while still retaining older templates for potential future
          use.
        </CTypography>
      </div>
    </EmptyCnt>
  );
}

export default AchiveEmpty;
