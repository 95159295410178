// React
import { useEffect, useState } from 'react';

// Redux

// Custom components
import CustomListItem from 'components/List/ListItem/ListItem';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';

// Icons
import ColumnsIcon from 'components/Icons/columnsIcon';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';

// Styles
import {
  SearchInputCnt,
  StyledList,
  StyledSearchField,
} from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';

// Constants
import { columns } from 'components/DataTables/TaskAndWorkOrderTable/constants';

const defaultColumnConfig = {
  taskNumber: true,
  title: true,
  assignee: true,
  taskStatus: true,
  location: true,
  asset: true,
  recurringByEvery: true,
  createdAt: true,
  dueTime: true,
  ServiceType: true,
  Checklist: true,
  priority: true,
  timeSpent: true,
  description: true,
  cost: true,
  startTime: true,
  endTime: true,
  CompletedBy: true,
  actionCol: true,
};

function ColumnSelectionDropdown(props) {
  const { listProps = {}, taskColumns } = props;
  const [columnsList, setColumnsList] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  //handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    const columnConfig = columns({
      columnConfig: {
        ...defaultColumnConfig,
      },
    });

    const newCols = columnConfig
      .filter(
        (newCol) =>
          !taskColumns?.some((col) => col.colId === newCol.colId) &&
          newCol.colId !== 'actionCol',
      )
      .map((col) => ({ ...col, hide: false }));
    const columnsWithSavedState =
      taskColumns?.map((c) => {
        const currentColumnState = columnConfig?.find(
          (tc) => tc.colId == c.colId,
        );
        return { ...c, ...currentColumnState };
      }) || columnConfig;
    if (newCols?.length) {
      columnsWithSavedState.push(...newCols);
    }

    setColumnsList(columnsWithSavedState);
  }, [taskColumns]);

  const hideShowColumns = (colId, value) => {
    globalThis?.projectTasksTable?.columnApi.applyColumnState({
      state: [
        {
          colId,
          hide: value,
        },
      ],
    });
  };
  //Toggle Check/Uncheck of options list for dropdown
  const handleSelect = (value) => () => {
    hideShowColumns(value.colId, !value.hide);
  };

  //Filtering options based on search query
  const filteredOptions = () => {
    const actualColumns = columnsList?.filter((c) => c.headerName);
    if (!searchQuery) {
      return actualColumns;
    }
    const optionsList = actualColumns?.reduce((r, cv) => {
      r.push(cv);
      return r;
    }, []);
    const optionsAfterFilter = optionsList.filter((obj) => {
      return obj.headerName.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return optionsAfterFilter;
  };

  const ListItems = () => {
    return (
      <>
        <SearchInputCnt>
          <span className={'listHeading'}>Show/Hide Columns</span>
          <StyledSearchField
            id="SearchInput"
            startAdornment={<SearchIcon />}
            onChange={handleSearch}
            placeholder={'Search'}
            value={searchQuery}
            notched={false}
            label={''}
            autoFocus={true}
            fullWidth={true}
          />
        </SearchInputCnt>
        <StyledList
          sx={{ width: '100%' }}
          dense={true}
          disablePadding
          {...listProps}
        >
          {filteredOptions().map((c, i) => {
            const isSelected = !c.hide;
            return (
              <CustomListItem
                key={c.headerName}
                id={c.headerName + 'column'}
                selected={isSelected}
                checkbox={false}
                onClick={handleSelect(c)}
                endIcon={isSelected && <DoneIcon />}
              >
                {c.headerName}
              </CustomListItem>
            );
          })}
        </StyledList>
      </>
    );
  };

  return (
    <CustomDropdown
      popperProps={{
        content: <ListItems />,
        id: 'columnSelectionDropdown',
        style: { width: 292 },
      }}
      buttonProps={{
        id: 'columnSelectionDropdown',
        variant: 'text',
        style: {
          padding: '6px 8px',
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: 12,
          fontWeight: 500,
          lineHeight: 22,
          letterSpacing: '0.14px',
          height: 30,
          marginRight: 8,
        },
        sx: {
          '& .MuiButton-startIcon': {
            marginRight: '2px',
            height: 18,
            width: 18,
            alignItems: 'center',
          },
        },
        startIcon: (
          <ColumnsIcon
            style={{
              fontSize: 16,
              color: 'rgba(117, 117, 117, 1)',
              fill: 'none',
            }}
          />
        ),
      }}
    >
      Columns
    </CustomDropdown>
  );
}

export default ColumnSelectionDropdown;
