import { styled } from '@mui/material/styles';
import CustomButton from 'components/Button/CustomButton';

export const AddMembersButton = styled(CustomButton)({
  borderRadius: '8px',
  padding: '6px 16px',
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.4px',
  marginBottom: 14,
});
