import HomeIcon from 'components/Icons/sidebarIcons/homeIcon';
import TaskIcon from 'components/Icons/sidebarIcons/taskIcon';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';
import MessagingIcon from 'components/Icons/sidebarIcons/messagingIcon';
import AgendaViewIcon from 'components/Icons/agendaViewIcon';

export const REDIRECT_TO = [
  {
    id: 'home',
    icon: HomeIcon,
    title: 'Home',
  },
  {
    id: 'work',
    icon: TaskIcon,
    title: 'Work',
  },
  {
    id: 'templates',
    icon: ChecklistIcon,
    title: 'Templates',
  },
  {
    id: 'agendaView',
    icon: AgendaViewIcon,
    title: 'Agenda View',
  },
  {
    id: 'chats',
    icon: MessagingIcon,
    title: 'Chats',
  },
];
