import { useEffect, useState } from 'react';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import useUsers from 'utils/CustomHooks/useUsers';
import { PERMISSIONS } from 'utils/constants';
import { difference } from 'lodash';

function MultiAssigneeBulk(props) {
  const { getBacklistUsersByPermission } = useUsers();
  const { config } = useWorkspaceConfig();
  const { handleBulkUpdate, buttonRenderer } = props;

  const [dropdownConfig, setDropdownConfig] = useState({
    people: true,
    teams: true,
    locations: false,
  });

  const [selected, setSelected] = useState({
    ...(dropdownConfig?.people && { people: [] }),
    ...(dropdownConfig?.teams && { teams: [] }),
    ...(dropdownConfig?.locations && { locations: [] }),
  });

  useEffect(() => {
    setDropdownConfig({
      ...dropdownConfig,
      locations: config?.advancedLocationBasedAssignment ?? false,
    });
  }, [config]);

  const updateMultiAssignees = (data) => {
    const previous = [
      ...(selected?.people ?? []),
      ...(selected?.teams ?? []),
      ...(selected?.locations ?? []),
    ];

    const current = [
      ...(data?.selectedList?.people ?? []),
      ...(data?.selectedList?.teams ?? []),
      ...(data?.selectedList?.locations ?? []),
    ];

    const addedItems = difference(current, previous);
    const removedItems = difference(previous, current);

    if (addedItems.length > 0) {
      handleBulkUpdate('addAssignee', addedItems);
    } else {
      handleBulkUpdate('removeAssignee', removedItems);
    }

    setSelected(data?.selectedList);
  };

  return (
    <AssigneeDropdown
      handleChangeCallback={updateMultiAssignees}
      showTabs={true}
      buttonRenderer={
        <div
          onClick={() => {
            setSelected({
              ...(dropdownConfig?.people && { people: [] }),
              ...(dropdownConfig?.teams && { teams: [] }),
              ...(dropdownConfig?.locations && { locations: [] }),
            });
          }}
        >
          {buttonRenderer}
        </div>
      }
      listProps={{
        all: {
          showInlineSelection: false,
          isSearchable: true,
          isMulti: true,
          placeholder: 'Type to Search',
          hideActions: false,
          partition: true,
          selected: [
            ...(selected?.people ?? []),
            ...(selected?.teams ?? []),
            ...(selected?.locations ?? []),
          ],
        },
        people: {
          showInlineSelection: false,
          isSearchable: true,
          isMulti: true,
          hideActions: false,
          partition: true,
          selected: selected?.people ?? [],
        },
        teams: {
          showInlineSelection: false,
          isSearchable: true,
          isMulti: true,
          hideActions: false,
          selected: selected?.teams ?? [],
        },
        ...(dropdownConfig?.locations && {
          locations: {
            selectOnly: true,
            showInlineSelection: false,
            isSearchable: true,
            isMulti: true,
            hideActions: false,
            selected: selected?.locations ?? [],
          },
        }),
      }}
      displayOptions={{
        all: true,
        ...dropdownConfig,
      }}
      blacklistedIds={getBacklistUsersByPermission(
        PERMISSIONS.CAN_CHANGE_TASK_STATUS,
        true,
      )}
      popperProps={{ disablePortal: false }}
      allSelected={false}
    />
  );
}

export default MultiAssigneeBulk;
