/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { streamConstant } from 'utils/globalVariable';
const getExistMember = (channel) => {
  return channel ? Object.keys(channel?.state?.members) : [];
};
export const useSelectedExistMember = (channel, type): any => {
  const [selectedMembers, setSelectedMember] = useState<any>([]);
  const isRefreshing: boolean | string = useSelector(selectors.getisRefreshing);
  const existMember = getExistMember(channel);
  useEffect(() => {
    if (type == streamConstant.ADD_MEMBER) {
      setSelectedMember(existMember);
    }
  }, [channel, isRefreshing]);

  return {
    selectedMembers,
    setSelectedMember,
    existMember,
  };
};
