import { createSvgIcon } from '@mui/material';

const TakePhotoIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.96469 0.833347C7.87286 0.833324 7.79297 0.833305 7.71673 0.837922C6.7527 0.896307 5.90852 1.50475 5.54828 2.40085C5.51979 2.47172 5.49455 2.54752 5.46553 2.63465L5.45936 2.65316C5.4207 2.76914 5.41232 2.79312 5.40519 2.81085C5.28511 3.10955 5.00372 3.31236 4.68237 3.33183C4.66353 3.33297 4.63526 3.33335 4.50458 3.33335L4.45804 3.33334C4.19566 3.33329 4.00287 3.33325 3.83794 3.34959C2.25689 3.50624 1.00615 4.75698 0.849505 6.33803C0.833168 6.50292 0.833206 6.68796 0.833258 6.93781L0.833263 13.5344C0.833252 14.2052 0.833244 14.7589 0.870091 15.2099C0.908362 15.6783 0.990499 16.1089 1.19658 16.5133C1.51615 17.1405 2.02609 17.6505 2.6533 17.97C3.05774 18.1761 3.48833 18.2583 3.95674 18.2965C4.40773 18.3334 4.96136 18.3334 5.63216 18.3333H14.3677C15.0385 18.3334 15.5921 18.3334 16.0431 18.2965C16.5115 18.2583 16.9421 18.1761 17.3466 17.97C17.9738 17.6505 18.4837 17.1405 18.8033 16.5133C19.0094 16.1089 19.0915 15.6783 19.1298 15.2099C19.1666 14.7589 19.1666 14.2053 19.1666 13.5345L19.1666 6.9378C19.1667 6.68796 19.1667 6.50291 19.1504 6.33803C18.9937 4.75698 17.743 3.50624 16.1619 3.34959C15.997 3.33325 15.8042 3.33329 15.5418 3.33334L15.4953 3.33335C15.3646 3.33335 15.3363 3.33297 15.3175 3.33183C14.9961 3.31236 14.7147 3.10955 14.5947 2.81085C14.5875 2.79312 14.5792 2.76914 14.5405 2.65316L14.5343 2.63464C14.5053 2.54754 14.4801 2.4717 14.4516 2.40085C14.0913 1.50475 13.2472 0.896307 12.2831 0.837922C12.2069 0.833305 12.127 0.833324 12.0352 0.833347H7.96469ZM13.3333 10.4167C13.3333 12.2576 11.8409 13.75 9.99992 13.75C8.15897 13.75 6.66658 12.2576 6.66658 10.4167C6.66658 8.57573 8.15897 7.08334 9.99992 7.08334C11.8409 7.08334 13.3333 8.57573 13.3333 10.4167Z"
        fill="#F9A825"
      />
    </>,
    'TakePhotoIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default TakePhotoIcon;
