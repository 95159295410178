import AddIcon from 'components/Icons/addIcon';
import IconButton from '@mui/material/IconButton';
import { NoAddedCnt, TextCnt, MainText, SubText } from './noAddedRecords.style';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';

const NoAddedRecords = (props) => {
  const dispatch = useDispatch();
  const handleCreateTask = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
      }),
    );
  };
  return (
    <NoAddedCnt>
      <img src={'/assets/img/no-rows.png'} />{' '}
      <TextCnt>
        <MainText>No Tasks & Work Orders</MainText>
        <SubText>
          Click <span className="inner-text">"+ Add"&nbsp;</span>
          to create a task
        </SubText>
      </TextCnt>
      <IconButton
        className="addBtn"
        size="large"
        disableRipple
        onClick={handleCreateTask}
      >
        <AddIcon />
      </IconButton>
    </NoAddedCnt>
  );
};

export default NoAddedRecords;
