import { FC, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';

import {
  SettingsHeader,
  SettingsMain,
  SettingsTitle,
} from 'components/common/styledComponents/settings.style';
import { colors } from 'components/theme/constants';
import KioskModeConfigComponent from 'components/Kiosk/Configurations';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { PERMISSIONS } from 'utils/constants';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const KioskModeConfig: FC = () => {
  const { hasFeature, hasPermission, FEATURES } = useCustomPermission();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  useEffect(() => {
    if (!hasPermission(PERMISSIONS.CAN_TOGGLE_KIOSK_MODE)) {
      navigateWithWorkspaceUrl('/dashboard');
    }
  }, []);

  const isFeatureEnabled = useMemo(() => {
    return hasFeature(FEATURES.KIOSK_MODE);
  }, []);

  return (
    hasPermission(PERMISSIONS.CAN_TOGGLE_KIOSK_MODE) && (
      <SettingsMain>
        <SettingsHeader style={{ display: 'flex', alignItems: 'center' }}>
          <SettingsTitle style={{ marginRight: 5 }}>Kiosk Mode</SettingsTitle>
        </SettingsHeader>
        <div className="layout-wrap hotal-management-layout">
          <div
            className="hotel-manage-content-wrap common-content-wrap"
            style={{ backgroundColor: colors.white }}
          >
            <Form className="organization-manage-form">
              <KioskModeConfigComponent />
            </Form>
          </div>
        </div>
      </SettingsMain>
    )
  );
};

export default KioskModeConfig;
