import { Box, InputBase } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { colors, elevations } from '../../../../components/theme/constants';

export const GroupDetailsModalContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 660,
  background: 'white',
  border: '1px solid #fff',
  boxShadow: elevations.elevation5,
  borderRadius: '16px',
  padding: '24px 16px',
  '.title': {
    fontWeight: 700,
    fontSize: '20px',
    color: colors.light.text.primary,
  },
  '.closeIcon': {
    fontSize: 20,
    color: colors.light.action.active,
    cursor: 'pointer',
  },
  '.formContainer': {
    marginTop: 32,
    padding: 0,
    '.inputContainer': {
      marginBottom: 8,
      padding: 0,
    },
    '.addMembersBtn': {
      width: '100%',
    },
  },
  '.footer': {
    marginTop: 32,
    '.cancelBtn': {
      marginRight: 8,
      color: colors.primary.main,
    },
  },
}));

export const InputTextArea = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
