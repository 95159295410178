import { createSvgIcon } from '@mui/material';

const CloudIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.0002 9.60833C7.70016 9.60833 5.8335 11.475 5.8335 13.775C5.8335 14.3917 5.96685 14.975 6.21685 15.4917C6.28351 15.6417 6.35848 15.7833 6.44181 15.9167C7.15848 17.125 8.4835 17.9417 10.0002 17.9417C11.5168 17.9417 12.8418 17.125 13.5585 15.9167C13.6418 15.7833 13.7168 15.6417 13.7835 15.4917C14.0335 14.975 14.1668 14.3917 14.1668 13.775C14.1668 11.475 12.3002 9.60833 10.0002 9.60833ZM11.7252 13.4167L9.95016 15.0583C9.8335 15.1667 9.67516 15.225 9.52516 15.225C9.36682 15.225 9.20852 15.1667 9.08352 15.0417L8.25847 14.2167C8.01681 13.975 8.01681 13.575 8.25847 13.3333C8.50014 13.0917 8.90014 13.0917 9.1418 13.3333L9.54184 13.7333L10.8752 12.5C11.1335 12.2667 11.5251 12.2833 11.7585 12.5333C11.9918 12.7833 11.9752 13.175 11.7252 13.4167Z"
        fill="#4CAF50"
      />
      <path
        d="M4.61689 9.26669C0.716894 9.54169 0.716894 15.2167 4.61689 15.4917H6.21693"
        stroke="#4CAF50"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.65868 9.26668C1.98368 1.82502 13.267 -1.14998 14.5586 6.66669C18.167 7.12502 19.6253 11.9334 16.892 14.325C16.0587 15.0834 14.9837 15.5 13.8587 15.4917H13.7836"
        stroke="#4CAF50"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CloudIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default CloudIcon;
