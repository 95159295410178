// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)(() => ({
  padding: '0px 24px',
}));

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 168px)',
});

export const LoadingContainer = styled('div')(({ theme }) => ({
  padding: '24px',
  height: 'calc(100vh - 220px)',
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 50px)',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
