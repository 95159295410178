import CTypography from 'components/CTypography';
import TemplateDefaultIcon from 'components/Icons/templateDefaultIcon';
import DefaultImage from 'public/assets/img/default-template-preview.png';
import { PreviewWrapper } from './styled';

const OnboardingPreview = ({ template, wrapper = {} }) => {
  return (
    <PreviewWrapper {...wrapper}>
      {template?.image ? (
        <img src={template?.image} />
      ) : (
        <div className="defaultIcon">
          <TemplateDefaultIcon
            primaryColor="white"
            style={{ height: 32, width: 32, fill: 'none' }}
          />
        </div>
      )}
      <div>
        <CTypography className="templateTitle">{template?.name}</CTypography>
        <CTypography className="templateSubTitle">by Xenia Team</CTypography>
      </div>
    </PreviewWrapper>
  );
};

export default OnboardingPreview;
