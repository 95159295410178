import { createSvgIcon } from '@mui/material';

const AddDocuemntIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M17.0001 8.75002V5.66669C17.0001 4.26656 17.0001 3.56649 16.7276 3.03171C16.4879 2.56131 16.1055 2.17885 15.6351 1.93917C15.1003 1.66669 14.4002 1.66669 13.0001 1.66669H7.66675C6.26662 1.66669 5.56655 1.66669 5.03177 1.93917C4.56137 2.17885 4.17892 2.56131 3.93923 3.03171C3.66675 3.56649 3.66675 4.26656 3.66675 5.66669V14.3334C3.66675 15.7335 3.66675 16.4336 3.93923 16.9683C4.17892 17.4387 4.56137 17.8212 5.03177 18.0609C5.56655 18.3334 6.26662 18.3334 7.66675 18.3334H10.3334M12.0001 9.16669H7.00008M8.66675 12.5H7.00008M13.6667 5.83335H7.00008M15.3334 17.5V12.5M12.8334 15H17.8334"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AddDocumentIcon',
  );
  return (
    <IconCmp
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    />
  );
};
export default AddDocuemntIcon;
