import { Box } from '@mui/material';
import styled from '@mui/system/styled';
import { colors } from '../../../components/theme/constants';

export const EmptyCheckListStyled = styled(Box)({
  marginTop: '10%',
  '.container': {
    height: 320,
    gap: 70,
    background: colors.white,
    '.infoContainer': {
      flex: 1,
      '.title': {
        fontWeight: 800,
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.87)',
        lineHeight: '160%',
      },
      '.description': {
        marginTop: 13,
        fontWeight: 500,
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.6)',
        '.count': {
          fontWeight: 600,
          color: colors.black,
        },
      },
      '.buttonsContainer': {
        marginTop: 28,
        display: 'flex',
        gap: 17,
      },
    },
    '.thumbnailContainer': {
      width: 338,
      cursor: 'pointer',
    },
  },
});
