import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { THRESHOLD } from 'components/CustomTilesGrid/utils';
import CustomDragTilesGrid from 'components/CustomDragTilesGrid';
import DashboardsCmpFilters from 'pages/Dashboards/DashboardCmp/DashboardCmpContent/DashboardCmpFilters/dashboardsCmpFilters';
import BarChartWidget from 'pages/Dashboards/Widgets/barChart';
import PieChartWidget from 'pages/Dashboards/Widgets/pieChart';
import {
  useDashboard,
  useUpdateDashboard,
} from 'pages/Dashboards/hooks/useDashboard';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GlobalFiltersCnt } from 'pages/Dashboards/DashboardCmp/DashboardCmpContent/dashboardCmpContent.style';
import { DashboardsContext } from 'pages/Dashboards/context/context';
import LineChartWidget from 'pages/Dashboards/Widgets/lineChart';
import { generateNewItemConfig } from 'pages/Dashboards/utils';
import WithDashboardPermission from 'pages/Dashboards/withDashboardPermission';
import TasksListSummary from 'pages/Dashboards/Widgets/TaskListing/TaskListView/tasksListSummary';
import SubmissionListSummary from 'pages/Dashboards/Widgets/SubmissionsListing/SubmissionListView/submissionsListSummary';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import FlaggedItemsTableByTemplates from 'pages/Dashboards/Widgets/FlaggedItems/TableByTemplate';
import FlaggedItemsTableByQuestions from 'pages/Dashboards/Widgets/FlaggedItems/TableByQuestion';
import FlaggedItemsTableByItems from 'pages/Dashboards/Widgets/FlaggedItems/TableByItem';
import NumberWidget from 'pages/Dashboards/Widgets/numerWidget';
import useFlaggedCategoriesConfig from 'utils/CustomHooks/useFlaggedCategoriesConfig';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';

const WidgetRenderers = {
  bar: BarChartWidget,
  pie: PieChartWidget,
  line: LineChartWidget,
  number: NumberWidget,
  taskTable: TasksListSummary,
  scheduledTaskTable: TasksListSummary,
  submissionTable: SubmissionListSummary,
  flaggedItems: {
    template: FlaggedItemsTableByTemplates,
    question: FlaggedItemsTableByQuestions,
    item: FlaggedItemsTableByItems,
  },
};

function DashboardCmpContent() {
  const {
    dashboardsState: { editable, currentGridLayout, ...dashboardsState },
    updateDashboardsState,
  } = useContext(DashboardsContext);
  const { getFlaggedCategories } = useFlaggedCategoriesConfig();
  const filters = dashboardsState?.filters;
  const params = useParams();

  const { dashboardId } = params;

  const { workspaceId } = useWorkspaceHook();
  const { selectedDashboard, updateDashboardInCache, dashboardQuery } =
    useDashboard({ id: dashboardId });

  const savedGridConfig = selectedDashboard?.order?.gridsLayouts;
  const { updateDashboardQuery } = useUpdateDashboard();
  const handleGridSettingsChange = (gridSettings) => {
    const obj = {
      order: gridSettings,
      id: dashboardId as string,
    };
    updateDashboardQuery.mutate(obj);
    updateDashboardsState({
      currentGridLayout: gridSettings?.gridsLayouts,
    });
  };

  useEffect(() => {
    getFlaggedCategories();
    return () => {
      updateDashboardsState({
        filters: null,
      });
    };
  }, []);

  const dispatch = useDispatch();
  const generateLayoutWithDefaultValues = useCallback(
    (widgets) => {
      return widgets?.map((w, i) => {
        return {
          x: i % 2 === 0 ? 0 : 6,
          y: i % 2 === 0 ? i * (12 - 1) : i * 12,
          w: 6,
          h: 12,
          i: `.$${w?.id}`,
          ...THRESHOLD,
        };
      });
    },
    [selectedDashboard],
  );

  const gridLayoutSettings: any[] = savedGridConfig
    ? savedGridConfig
    : generateLayoutWithDefaultValues(selectedDashboard?.DashboardWidgets);
  const handleAddWidget = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.CREATE_WIDGET_SELECT_TYPE_DIALOG,
        open: true,
        data: {
          dashboardId,
          onSuccessCallback: (res: any) => {
            if (savedGridConfig?.length) {
              const newItemGridConfig = generateNewItemConfig(
                currentGridLayout || gridLayoutSettings,
                res,
              );

              let newGridLayout = {
                gridsLayouts: [...gridLayoutSettings, newItemGridConfig],
              };

              if (currentGridLayout) {
                newGridLayout = {
                  gridsLayouts: [...currentGridLayout, newItemGridConfig],
                };
              }
              updateDashboardInCache({
                order: newGridLayout,
                DashboardWidgets: [
                  ...(selectedDashboard?.DashboardWidgets || []),
                  res,
                ],
              });
              handleGridSettingsChange(newGridLayout);
              updateDashboardsState({
                currentGridLayout: [...currentGridLayout, newItemGridConfig],
              });
            } else {
              handleGridSettingsChange({
                gridsLayouts: currentGridLayout || gridLayoutSettings,
              });
              updateDashboardsState({
                currentGridLayout: null,
              });
            }
          },
        },
      }),
    );
  };

  /**
   * Determines if the dashboard is editable.
   * The dashboard is editable if the `editable` flag from the `dashboardsState` is true,
   * or if the `publish` property of the `selectedDashboard` is false.
   *
   * @type {boolean}
   */
  const isEditable: boolean = editable || !selectedDashboard?.publish;

  const transformedOrder: any = useMemo(() => {
    if (!selectedDashboard) return [];
    const updatedLayout = gridLayoutSettings
      ?.map((item) => item?.i?.substring(2))
      ?.map((id) =>
        selectedDashboard?.DashboardWidgets?.find((obj) => obj?.id === id),
      );

    return updatedLayout?.filter((item) => item);
  }, [selectedDashboard?.DashboardWidgets, gridLayoutSettings]);

  return (
    <div style={{ width: '100%' }}>
      <GlobalFiltersCnt>
        <DashboardsCmpFilters selectedDashboard={selectedDashboard} />
        <WithDashboardPermission>
          <CustomButton
            variant="contained"
            style={{ borderRadius: '6px' }}
            startIcon={<AddIcon />}
            onClick={handleAddWidget}
          >
            Add Chart
          </CustomButton>
        </WithDashboardPermission>
      </GlobalFiltersCnt>

      <WithLoader isLoading={!filters}>
        <div style={{ margin: '20px 30px' }}>
          {transformedOrder && transformedOrder?.length > 0 && (
            <CustomDragTilesGrid
              key={workspaceId}
              isDraggable={isEditable}
              isResizable={isEditable}
              isDropable={isEditable}
              draggableHandle={isEditable}
              customLayout={gridLayoutSettings}
              onChangeCB={handleGridSettingsChange}
            >
              {transformedOrder?.map((w) => {
                if (selectedDashboard?.id !== dashboardId) return <></>;
                let WidgetRenderer =
                  w?.type == WIDGET_TYPE_IDS?.TABLE
                    ? w?.config?.variable == 'flaggedItems.item'
                      ? WidgetRenderers?.['flaggedItems']['item']
                      : WidgetRenderers?.[w?.config?.variable]
                    : WidgetRenderers[w?.type];

                if (
                  w?.entity === DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS &&
                  w?.type === WIDGET_TYPE_IDS?.CUSTOM_TABLE
                ) {
                  WidgetRenderer =
                    WidgetRenderers?.[w?.entity]?.[w?.config?.metric];
                }

                if (!WidgetRenderer) return <></>;
                return (
                  <WidgetRenderer
                    key={`${w.id}`}
                    widgetId={w.id}
                    editable={isEditable}
                    globalFilters={filters}
                    {...(w.entity ===
                      DASHBOARDS_CONST.WIDGET_TYPE_SCHEDULED_TASKS && {
                      isScheduled: true,
                    })}
                  />
                );
              })}
            </CustomDragTilesGrid>
          )}
        </div>
      </WithLoader>
    </div>
  );
}

export default DashboardCmpContent;
