import { Box, Stack, styled } from '@mui/material';

export const MainCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const CreatedByCnt = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  '& .userIcon': {
    fontWeight: 600,
  },
  '& .name': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18.59px',
    color: '#252350',
  },
});
