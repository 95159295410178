import { createContext } from 'react';

import { ContextBaseType } from 'pages/Schedules/common/types';

interface ManageSchedulesCtxType extends ContextBaseType {
  selectedView: 'grid' | 'list';
  setSelectedView: any | ((view: 'grid' | 'list') => void);
}

export const ManageSchedulesCtx = createContext<ManageSchedulesCtxType>({
  loading: true,
  advanceFilters: null,
  setAdvanceFilters: null,
  searchTerm: '',
  setSearchTerm: null,
  handleChangeSearch: null,
  selectedView: 'grid',
  setSelectedView: null,
});
