import React, { useState } from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import { useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';

import { LocationsFormCnt } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import useLocations from 'components/AllDialogs/locations/createLocation/useLocations';
import LocationNameInput from 'components/AllDialogs/locations/createLocation/locationName.cmp';
import LocationDescriptionInput from 'components/AllDialogs/locations/createLocation/locationDescription.cmp';
import QRCodeControl from 'components/AllDialogs/locations/createLocation/qrCodeControl.cmp';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { CreateLocationContext } from 'components/AllDialogs/locations/createLocation/context/context';
import SubLocation from 'components/AllDialogs/locations/createLocation/subLocation';
import { Divider, Grid } from '@mui/material';
import LocationAttachments from './locationAttachments.cmp';
import useResponsive from 'utils/CustomHooks/useResponsive';
import LocationMembers from './locationMembers.cmp';
import LocationsLevels from './locationsLevels/locationsLevels.cmp';

function CreateLocationDialog() {
  const dialogState = useSelector(getDialogState);
  const data = dialogState?.createLocation?.data;
  const { state, handleLocationFormSubmit, closeAction } = useLocations();
  const { isMobileDeviceSm } = useResponsive();
  const [isLocLevels, setIsLocLevels] = useState();

  const updateLocLevels = (data) => {
    setIsLocLevels(data);
  };

  return (
    <CreateLocationContext.Provider value={state}>
      <CustomDialog
        actionButtonCntProps={{
          primaryBtnProps: {
            onClick: () => handleLocationFormSubmit(data?.onSuccessCallback),
          },
          secondaryBtnProps: { onClick: closeAction },
        }}
        primaryBtnText="Add Location"
        header={
          <CustomDialogHeader onClose={closeAction}>
            Add New Location
          </CustomDialogHeader>
        }
        dialogProps={{
          open: dialogState?.createLocation?.open,
          maxWidth: false,
          onClose: closeAction,
          fullScreen: isMobileDeviceSm,
        }}
      >
        <LocationsFormCnt>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={isLocLevels ? 6 : 12}>
              <LocationNameInput />
            </Grid>

            <LocationsLevels
              isLocLevels={isLocLevels}
              updateLocLevelsCallback={updateLocLevels}
              selected={data}
            />

            <Grid item xs={12}>
              <LocationMembers />
            </Grid>
            <Grid item xs={12}>
              <LocationDescriptionInput />
            </Grid>
            <Grid item xs={12}>
              <LocationAttachments />
            </Grid>
            <Grid item xs={12}>
              <>
                <Divider />
                <QRCodeControl />
              </>
            </Grid>
            <Grid item xs={12}>
              <SubLocation />
            </Grid>
          </Grid>
        </LocationsFormCnt>
      </CustomDialog>
    </CreateLocationContext.Provider>
  );
}

export default CreateLocationDialog;
