import { Box, Typography, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const MainCnt = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 240px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: 600,
  textAlign: 'center',
  margin: '0 auto',

  [theme.breakpoints.down('sm')]: {
    width: 'unset',
    padding: '0px 5px',
  },
}));

export const LockCnt = styled(Box)(({ theme }) => ({
  height: 80,
  width: 80,
  borderRadius: 150,
  backgroundColor: '#B3E5FC',
  marginBottom: 30,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    marginBottom: 20,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 26,
  lineHeight: '37.35px',
  color: '#000000DE',
  marginBottom: 10,

  [theme.breakpoints.down('sm')]: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 'unset',
  },
}));

export const Statement = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 17,
  lineHeight: '26px',
  color: '#424242',
  textAlign: 'center',

  '& .servicelog': {
    fontWeight: 700,
    fontSize: 17,
    color: '#424242',
    lineHeight: 'unset',
  },

  [theme.breakpoints.down('sm')]: {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: 'unset',
    '& .servicelog': {
      fontWeight: 700,
      fontSize: 15,
      lineHeight: 'unset',
    },
  },
}));

export const TalkToSalesBtn = styled(CustomButton)({
  fontWeight: 500,
  fontSize: 15,
  lineHeight: '26px',
  color: '#FFFFFF',
  letterSpacing: '0.1px',
  height: 42,
  borderRadius: 6,
  marginTop: 20,
});
