// React
import { useCallback, useEffect, useMemo, Fragment, useRef } from 'react';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import MembersBulkActions from '../MembersBulkActions';

// Constants
import { columns, defaultColDef, rowHeight } from './constants';

// Styles
import {
  ListCnt,
  LoaderCnt,
} from 'pages/Templates/TemplateListView/list.style';
import { CircularProgress } from '@mui/material';
import './table.css';

// Hooks
import useUsers from 'utils/CustomHooks/useUsers';

interface MembersTablePropTypes {
  searchTerm: string;
  members: any[];
  disableRemove?: boolean;
  selected: any[];
  onClickRemove: (memberId: string) => void;
  onBulkSelection: (selectedIds: string[]) => void;
  onClickCancelSelection: () => void;
  handleBulkRemove: () => void;
}

function MembersTable({
  searchTerm,
  members,
  disableRemove,
  selected,
  onClickRemove,
  onBulkSelection,
  onClickCancelSelection,
  handleBulkRemove,
}: MembersTablePropTypes) {
  const gridRef = useRef<any>();
  const { userStatusColors } = useUsers();

  const filteredData = useMemo(() => {
    if (!searchTerm) {
      return members;
    }
    return members?.filter((f) => {
      return (f?.Member?.fullName || f?.Member?.name)
        ?.toLowerCase()
        ?.includes(searchTerm.toLowerCase());
    });
  }, [searchTerm, members]);

  const colConfig = useMemo(
    () =>
      columns({
        disabled: disableRemove,
        handleClickRemove: onClickRemove,
        userStatusColors,
      }),
    [disableRemove, onClickRemove],
  );

  const onRowClick = (id) => {
    console.log(id);
  };

  const getRowHeight = useCallback(() => {
    return rowHeight;
  }, []);

  const onSelectionChanged = (event) => {
    const selected = event.api.getSelectedNodes();
    onBulkSelection(selected.map((s) => s.data.id));
  };

  const onClickCancelBulkSelection = () => {
    gridRef?.current?.api?.deselectAll();
    onClickCancelSelection();
  };

  useEffect(() => {
    return () => {
      globalThis.locationMembersTable = null;
    };
  }, []);

  return (
    <ListCnt>
      {!members ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : (
        <Fragment>
          {members?.length === 0 ? (
            <></>
          ) : (
            <CustomListView
              rootProps={{
                className: 'location-members-table',
                style: {
                  height: 'calc(100vh - 279px)',
                  boxShadow: 'none',
                  border: '1px solid #d3d3d382',
                  flex: 1,
                  borderRadius: 8,
                  overflow: 'hidden',
                  padding: '0px 10px 12px 10px',
                  background: '#F5F5F5',
                },
              }}
              onRowClick={onRowClick}
              gridProps={{
                onGridReady: (params) => {
                  params.api.sizeColumnsToFit();
                },
                rowData: filteredData,
                ref: gridRef,
                headerHeight: 30,
                suppressChangeDetection: true,
                defaultColDef: defaultColDef,
                columnDefs: colConfig,
                rowSelection: 'multiple',
                getRowHeight: getRowHeight,
                onSelectionChanged,
                checkboxSelection: true,
                suppressRowClickSelection: true,
                getRowId: (params) => params.data.id,
              }}
            />
          )}
        </Fragment>
      )}
      {selected?.length > 0 && (
        <MembersBulkActions
          selected={selected}
          handleRemove={handleBulkRemove}
          onClearSelection={onClickCancelBulkSelection}
        />
      )}
    </ListCnt>
  );
}

export default MembersTable;
