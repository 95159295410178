import React, { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment-timezone';

import { DatePicker } from 'components/Dropdown/DatePicker/datePicker.cmp';
import CalendarInputIcon from 'components/Icons/calendarInputIcon';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import { TimePickerList } from 'components/Dropdown/TimePicker/timePicker.cmp';
import useDateTime from 'utils/CustomHooks/useDateTime';
import useResponsive from 'utils/CustomHooks/useResponsive';

import { SelectBoxEmpty, ContentCnt, DateTimeCnt } from './style';
import { useSelector } from 'react-redux';
import { getUserHotelTz } from 'store/selectors/auth';

interface IProps {
  isEdit?: boolean;
  data?: any;
  oldDate?: any;
  clearOldDate(): void;
  onChangeCallback(data: any, isOld?: boolean): void;
}

const ServiceDate = (props: IProps) => {
  const tz = useSelector(getUserHotelTz) || 'America/Chicago';
  const { isMobileDeviceSm } = useResponsive();
  const { getUtcToTz, getDateInTz } = useDateTime();
  const { onChangeCallback, data, oldDate, clearOldDate, isEdit } = props;

  const [dateTime, setDateTime] = useState({
    date: '',
    time: '',
  });
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    if (isEdit && data) {
      const TZDateTime = getUtcToTz(data);
      const formattedDate = TZDateTime.format('YYYY-MM-DD');
      const formattedTime = TZDateTime.format('hh:mm A');

      setSelectedDay(TZDateTime?.toDate());

      setDateTime({
        date: formattedDate,
        time: formattedTime,
      });
    }
  }, [isEdit, data]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const dateTimeConversion = (date, format) => {
    const dateAndTime = moment(date, 'YYYY-MM-DD hh:mm A').format(
      'YYYY-MM-DDTHH:mm:ss',
    );
    const utcTzDate = moment.tz(dateAndTime, tz);
    const formattedDate = utcTzDate?.format(format);
    return formattedDate;
  };

  const handleDateSelect = (date) => {
    if (!date) return;
    const formattedDate = getDateInTz(date).format('YYYY-MM-DD');
    setDateTime({
      ...dateTime,
      date: formattedDate,
    });
    setSelectedDay(date);

    const givenDate = moment(formattedDate, 'YYYY-MM-DD');
    const currentDate = moment()?.subtract(1, 'days');
    const isNew = currentDate.isBefore(givenDate);

    if (!dateTime?.time && !isNew && !isEdit) {
      const DateTimeWithTimeZone = dateTimeConversion(
        formattedDate + ' ' + '12:00 AM',
        'YYYY-MM-DDTHH:mmZ',
      );
      onChangeCallback?.(DateTimeWithTimeZone, true);
      return;
    }

    if (oldDate) {
      clearOldDate?.();
    }

    if (formattedDate && dateTime?.time) {
      const DateTimeWithTimeZone = dateTimeConversion(
        formattedDate + ' ' + dateTime?.time,
        'YYYY-MM-DDTHH:mmZ',
      );

      onChangeCallback?.(DateTimeWithTimeZone);
    }
  };

  const handleTimeSelect = (time) => {
    setDateTime({ ...dateTime, time });

    if (dateTime?.date && time) {
      const DateTimeWithTimeZone = dateTimeConversion(
        dateTime?.date + ' ' + time,
        'YYYY-MM-DDTHH:mmZ',
      );

      onChangeCallback?.(DateTimeWithTimeZone);
    }
  };

  const selectedLabel = useMemo(() => {
    if (oldDate) {
      const oldFormatted = moment(oldDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
      return oldFormatted;
    }

    if (data) {
      return getUtcToTz?.(data, 'MMM DD, YYYY • hh:mm A');
    }
  }, [data, oldDate]);

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      buttonRenderer={
        <div>
          <SelectBoxEmpty
            onClick={handleDropDownOpen}
            style={{
              ...(data && { border: '1px solid #E0E0E0', height: 33 }),
            }}
          >
            <CalendarInputIcon
              style={{ height: 16, width: 15, fill: 'none' }}
              sx={{
                '& path': {
                  ...(data && { stroke: '#6868FE' }),
                },
              }}
            />
            {selectedLabel ? (
              <Typography className="selectedLabel">{selectedLabel}</Typography>
            ) : (
              <Typography className="label">Service Date</Typography>
            )}
          </SelectBoxEmpty>
        </div>
      }
      popperProps={{
        open: Boolean(anchorEl),
        anchorEl: anchorEl,
        sx: {
          width: isMobileDeviceSm ? 325 : 453,
        },
        placement: 'bottom-start',
        content: (
          <ContentCnt>
            <DateTimeCnt>
              <DatePicker
                setSelectedDay={handleDateSelect}
                selectedDay={selectedDay}
                datePickerProps={{ disabled: false }}
              />
              <TimePickerList
                onChange={handleTimeSelect}
                setTime={null}
                time={dateTime?.time}
                handleDropdownClose={null}
                isTimeDisabled={null}
              />
            </DateTimeCnt>
          </ContentCnt>
        ),
      }}
    />
  );
};

export default ServiceDate;
