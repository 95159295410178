import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const SubmissionReportPieCharIcon = ({ filled }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.9999 1.8335C12.2037 1.8335 13.3957 2.0706 14.5079 2.53127C15.62 2.99194 16.6305 3.66715 17.4817 4.51835C18.3329 5.36956 19.0081 6.38008 19.4688 7.49224C19.9295 8.60439 20.1666 9.79638 20.1666 11.0002M10.9999 1.8335V11.0002M10.9999 1.8335C5.93731 1.8335 1.83325 5.93755 1.83325 11.0002C1.83325 16.0628 5.93731 20.1668 10.9999 20.1668C16.0625 20.1668 20.1666 16.0628 20.1666 11.0002M10.9999 1.8335C16.0625 1.8335 20.1666 5.93756 20.1666 11.0002M20.1666 11.0002L10.9999 11.0002M20.1666 11.0002C20.1666 12.4468 19.8242 13.8728 19.1675 15.1617C18.5107 16.4507 17.5583 17.5659 16.3879 18.4162L10.9999 11.0002"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'SubmissionReportPieCharIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M10.0834 2.5624C10.0834 2.01963 10.0834 1.74824 9.9501 1.52888C9.84371 1.35381 9.63058 1.18818 9.43466 1.1283C9.18917 1.05328 8.9604 1.11157 8.50284 1.22815C4.14186 2.33928 0.916748 6.29293 0.916748 10.9999C0.916748 16.5687 5.43121 21.0832 11.0001 21.0832C12.3154 21.0832 13.5718 20.8314 14.7239 20.3733C15.1623 20.199 15.3815 20.1118 15.5361 19.9068C15.6595 19.7432 15.7346 19.4839 15.7178 19.2797C15.6968 19.0238 15.5372 18.8042 15.2182 18.3652L10.2647 11.5472C10.1508 11.3944 10.0834 11.205 10.0834 10.9999V2.5624Z"
        fill="#4E48FA"
      />
      <path
        d="M19.4375 11.9165C19.9803 11.9165 20.2517 11.9165 20.471 12.0498C20.6461 12.1562 20.8117 12.3693 20.8716 12.5652C20.9466 12.8107 20.8884 13.0394 20.7719 13.4968C20.398 14.9645 19.7021 16.3035 18.7639 17.4343C18.4618 17.7985 18.3107 17.9806 18.0679 18.0642C17.8741 18.1311 17.6045 18.1224 17.4153 18.0433C17.1785 17.9442 17.0188 17.7243 16.6993 17.2846L13.6451 13.0809C13.3721 12.7052 13.2356 12.5173 13.2406 12.3605C13.2449 12.2239 13.3098 12.0964 13.4178 12.0127C13.5418 11.9165 13.774 11.9165 14.2384 11.9165H19.4375Z"
        fill="#4E48FA"
      />
      <path
        d="M11.9167 2.5624C11.9167 2.01963 11.9167 1.74825 12.0501 1.52888C12.1565 1.35382 12.3696 1.18818 12.5655 1.1283C12.811 1.05328 13.0398 1.11156 13.4973 1.22814C17.0597 2.13579 19.8641 4.94024 20.7718 8.50265C20.8884 8.96019 20.9467 9.18895 20.8716 9.43444C20.8118 9.63035 20.6461 9.84348 20.4711 9.94988C20.2517 10.0832 19.9803 10.0832 19.4375 10.0832L12.6501 10.0832C12.3934 10.0832 12.265 10.0832 12.167 10.0332C12.0808 9.98929 12.0106 9.91917 11.9667 9.83293C11.9167 9.73489 11.9167 9.60654 11.9167 9.34986V2.5624Z"
        fill="#4E48FA"
      />
    </>,
    'SubmissionReportPieCharIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} />
  );
};
export default SubmissionReportPieCharIcon;
