import { useEffect, useMemo, useRef, useState } from 'react';

// Custom components
// import './style.css';
// AG Grid

// Utils
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import WithErrorState from 'pages/Dashboards/Widgets/errorStateCmp/errorState';
import WidgetAdvanceFilter from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter';
import WidgetActions from 'pages/Dashboards/Widgets/widgetActions';
import {
  WidgetCnt,
  WidgetHeading,
} from 'pages/Dashboards/Widgets/widgets.style';
import { useLoadWidget } from 'pages/Dashboards/Widgets/useLoadWidget';
import { exportToPng } from 'utils/globalFunction';
import { Stack, Typography } from '@mui/material';
import FlaggedItemsDetails from 'components/DataTables/Reports/FlaggedItems/FlaggedItemsDetails';
import { useMutation } from 'react-query';
import xeniaApi from 'api/index';
import { cloneDeep } from 'lodash';
import { useSubmissionsUtils } from 'utils/CustomHooks/useSubmissionsUtils';

interface ITaskTableWidgetProps {
  widgetId: string;
  editable?: boolean;
  globalFilters?: any;
}
const FlaggedItemsTableByItems = ({
  widgetId,
  editable,
  globalFilters,
}: ITaskTableWidgetProps) => {
  const chartRef: any = useRef(null);
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>();
  const getSubmissionsQuery = useMutation({
    mutationKey: 'getSubmissionList',
    mutationFn: (requestPayload?: any) =>
      xeniaApi.getAllChecklistLogs(requestPayload, true),
  });

  const { widget, summary } = useLoadWidget(widgetId, globalFilters);
  const { transformaToFlaggedItems } = useSubmissionsUtils();

  const exportChartToImage = () => {
    exportToPng(chartRef.current, widget?.title);
  };

  const fetchFlaggedItems = async () => {
    const localFilters = cloneDeep(widget?.filters?.filters);

    const globalAdvanceFilters = {
      condition: 'AND',
      filters: [
        ...(globalFilters &&
        globalFilters.dateRange &&
        globalFilters.dateRange[0] &&
        globalFilters.dateRange[1]
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'date',
                value: [globalFilters.dateRange[0], globalFilters.dateRange[1]],
              },
            ]
          : []),
        ...(globalFilters &&
        globalFilters.location &&
        globalFilters.location.length > 0
          ? [
              {
                comparator: 'is',
                conditional: 'and',
                filterName: 'locations',
                value: globalFilters.location,
              },
            ]
          : []),
      ],
    };
    const requestPayload = {
      globalFilters: globalAdvanceFilters,
      ...(localFilters?.length > 0
        ? {
            advanceFilters: {
              condition: localFilters?.[0]?.conditional?.toUpperCase(),
              filters: localFilters,
            },
          }
        : { advanceFilters: {} }),
    };

    getSubmissionsQuery.mutate(requestPayload, {
      onSuccess: (data) => {
        const transformData = transformaToFlaggedItems(data?.data);
        setData(transformData);
        setCount(transformData.length);
      },
      onError: (error) => {
        console.log('error', error);
      },
    });
  };
  useEffect(() => {
    if (widget?.id) {
      fetchFlaggedItems();
    }
  }, [globalFilters, widget]);
  return (
    <WidgetCnt ref={chartRef}>
      <WidgetHeading>
        <Stack
          direction="column"
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Typography className="title" title={widget?.title}>
            {widget?.title}
          </Typography>
          {count >= 0 && <Typography className="count">{count}</Typography>}
        </Stack>
        <div className={'widgetActionsCnt'}>
          <WidgetAdvanceFilter
            widgetId={widgetId}
            globalFilters={globalFilters}
            hideFilters={
              'status duration completedAt completedBy flagCategories'
            }
          />{' '}
          <WidgetActions
            exportChartToImage={exportChartToImage}
            widgetId={widgetId}
            editable={editable}
          />
        </div>
      </WidgetHeading>

      <WithLoader
        isLoading={
          getSubmissionsQuery?.status === 'idle' ||
          getSubmissionsQuery?.isLoading
        }
      >
        <WithErrorState
          type={'table'}
          error={getSubmissionsQuery?.error}
          reload={fetchFlaggedItems}
        >
          <FlaggedItemsDetails rowData={data} />
        </WithErrorState>
      </WithLoader>
    </WidgetCnt>
  );
};

export default FlaggedItemsTableByItems;
