// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 166px)',
  width: '100%',
  position: 'relative',
  padding: '0px 16px',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 82px)',
  },

  '.useCases': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    height: '715px',
    width: 'auto',
    maxHeight: '100%',
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '130px auto auto 50px',
  maxWidth: 525,
  [theme.breakpoints.down(1180)]: {
    maxWidth: 'calc(100% - 500px)',
  },
  [theme.breakpoints.down('md')]: {
    margin: 'auto 0px',
    maxWidth: '100%',
    alignItems: 'center',
  },
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '700',
  letterSpacing: '0.23px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginBottom: 16,
  maxWidth: 277,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    maxWidth: 'max-content',
  },
}));

export const DetailText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '500',
  letterSpacing: '0.23px',
  color: 'rgba(0, 0, 0, 0.6)',
  marginBottom: 40,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));
