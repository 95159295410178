import { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  ListCnt,
  LoaderCnt,
} from 'pages/Templates/TemplateListView/list.style';
import {
  columns,
  defaultColDef,
  rowHeight,
} from 'pages/Templates/TemplateListView/constants';
import CustomListView from 'components/ListView/Listview.cmp';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { CircularProgress } from '@mui/material';
import { getItem } from 'utils/globalFunction';
import { MyTemplatesContext } from 'pages/Templates/MyTemplates/myTemplatesContext.tsx/context';

const ROW_HEIGHT = 62;
const NO_DATA_HEIGHT = 320;
const TABLE_HEIGHT = 350;
const HEADER_HEIGHT = 36;
const BREAK_POINT = 5;

function TemplatesListView({ folders, selectedFolder }) {
  const templatesList = useSelector(selectors.getChecklistListDataOnly);
  const headerState = getItem('hideChecklistHeader');
  const { state = {} } = useContext(MyTemplatesContext);
  const { templatesHeadHeight } = state;

  const colConfig = useMemo(() => columns({ selectedFolder }), []);

  const onRowClick = (id) => {
    console.log(id);
  };

  const getRowHeight = useCallback(() => {
    return rowHeight;
  }, []);
  useEffect(() => {
    return () => {
      globalThis.templateListView = null;
    };
  }, []);

  const withFoldersCalculations = () => {
    if (templatesList?.length > BREAK_POINT && selectedFolder) {
      return HEADER_HEIGHT + templatesList?.length * ROW_HEIGHT;
    }
    if (templatesList?.length < BREAK_POINT) {
      return HEADER_HEIGHT + templatesList?.length * ROW_HEIGHT;
    }
    return TABLE_HEIGHT;
  };

  const withoutFoldersCalculations = () => {
    const appBarHeight =
      document.getElementsByClassName('main-header')?.[0]?.clientHeight || 0;
    const tabsHeight =
      document.getElementsByClassName('MuiTabs-root')?.[0]?.clientHeight;

    const templatesMenuHeight: any = document.getElementById(
      'opertion-templates-menu',
    )?.clientHeight;

    const spacing = selectedFolder ? 45 : 90;

    const totalHeightDeduction =
      appBarHeight +
      templatesMenuHeight +
      templatesHeadHeight +
      tabsHeight +
      spacing;

    return `calc(100vh - ${Math.ceil(totalHeightDeduction)}px)`;
  };

  const calculatedHeight = useMemo(() => {
    if (folders?.length === 0) {
      return withoutFoldersCalculations();
    } else {
      return withFoldersCalculations();
    }
  }, [templatesList, folders?.length, templatesHeadHeight, headerState]);
  return (
    <ListCnt>
      {!templatesList ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : (
        <CustomListView
          rootProps={{
            style: {
              height: calculatedHeight,
              boxShadow: 'none',
              border: '1px solid #d3d3d382',
              flex: 1,
              borderRadius: 8,
              overflow: 'hidden',
            },
          }}
          onRowClick={onRowClick}
          gridProps={{
            rowData: templatesList,
            ref: (ref) => {
              globalThis.templateListView = ref;
            },
            headerHeight: 30,
            suppressChangeDetection: true,
            defaultColDef: defaultColDef,
            columnDefs: colConfig,
            suppressCellFocus: true,

            getRowHeight: getRowHeight,
            suppressRowClickSelection: true,
            getRowId: (params) => params.data.id,
          }}
        />
      )}
    </ListCnt>
  );
}

export default TemplatesListView;
