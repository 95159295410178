import styled from '@mui/system/styled';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';

export const SettingNavCnt = styled('div')({
  padding: '18px 24px',
  maxWidth: 244,
});
export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  background: isSelected ? '#e3e3ff' : '',
  marginBottom: 8,
  borderRadius: 4,
  '& svg': {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '& .MuiListItemText-primary': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '&:hover': {
    background: '#e3e3ff',
  },
  '& .MuiListItemButton-root': {
    padding: '4px 16px 4px 15px',
  },
}));
