import { createSvgIcon } from '@mui/material';

const TemplateDefaultIcon = (props) => {
  const { primaryColor = 'white' } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M16.4933 11.8398H23.4933"
        stroke={primaryColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.50667 11.8398L9.50667 12.8398L12.5067 9.83984"
        stroke={primaryColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.4933 21.1733H23.4933"
        stroke={primaryColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.50667 21.1733L9.50667 22.1733L12.5067 19.1733"
        stroke={primaryColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 29.3332H20C26.6667 29.3332 29.3333 26.6665 29.3333 19.9998V11.9998C29.3333 5.33317 26.6667 2.6665 20 2.6665H12C5.33333 2.6665 2.66667 5.33317 2.66667 11.9998V19.9998C2.66667 26.6665 5.33333 29.3332 12 29.3332Z"
        stroke={primaryColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TemplateDefaultIcon',
  );
  return <IconCmp width="32" height="32" viewBox="0 0 32 32" {...props} />;
};
export default TemplateDefaultIcon;
