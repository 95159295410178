import styled from '@mui/system/styled';

export const DashboardCmpFiltersCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  '.taskOverviewHeaderLeft': {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  '.taskOverviewHeaderRight': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
});
