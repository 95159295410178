// React
import React, { useState, useMemo, useRef, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import AssigneeListAndSearch from './AssigneeListAndSearch';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import UserAvatar from 'components/Avatar';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// Styled
import { SelectedUsersBox, Chip, AddedUsers } from './styled';
import { Typography, Stack } from '@mui/material';
import { colors } from 'components/theme/constants';

interface MultipleUserDropdownPropTypes {
  placeholder?: string;
  listProps?: any;
  handleChangeCallback: (users: string[]) => void;
  popperProps?: any;
  buttonRenderer?: any;
  selected: string[];
  renderChips?: boolean;
  blacklistedIds?: string[];
  hideAddUser?: boolean;
  hideAllUserOption?: boolean;
}

function MultipleUserDropdown({
  listProps = {},
  handleChangeCallback,
  popperProps = {},
  buttonRenderer,
  selected,
  renderChips,
  blacklistedIds,
  placeholder,
  hideAddUser = false,
  hideAllUserOption = false,
}: MultipleUserDropdownPropTypes) {
  const userList = useSelector(selectors.getUserAdmin);
  const teams = useSelector(selectors.getTeams);

  const chipsRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasMore, setHasMore] = useState(0);

  useEffect(() => {
    const parentWidth = chipsRef?.current?.clientWidth;
    let overflowingChips = 0;
    let childrenWidthTotal = 0;
    const childChips = chipsRef?.current?.children ?? [];
    for (let index = 0; index < childChips?.length; index++) {
      const element = childChips[index];
      if (childrenWidthTotal > parentWidth) {
        overflowingChips++;
      }
      childrenWidthTotal += element.clientWidth;
    }
    setHasMore(overflowingChips);
  }, [selected, blacklistedIds, userList, teams]);

  const handleSelect = (value) => {
    const filterAllOption = (options: any[]) =>
      options.filter((o) => o !== 'all');

    if (value.id === 'all') return handleChangeCallback([]);

    const isAlreadyExist = selected.some((v) => v === value.id);
    if (isAlreadyExist) {
      handleChangeCallback(
        filterAllOption(selected.filter((s) => s !== value.id)),
      );
    } else {
      handleChangeCallback &&
        handleChangeCallback(filterAllOption([...selected, value.id]));
    }
  };

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const ListItems = () => {
    return (
      <AssigneeListAndSearch
        listProps={listProps}
        selectedIds={selected}
        closeCB={onDropdownClose}
        handleSingleSelect={handleSelect}
        blacklistedIds={blacklistedIds}
        hideAddUser={hideAddUser}
        hideAllUserOption={hideAllUserOption}
      />
    );
  };

  const chips: any = useMemo(() => {
    let names = selected.map((v) => {
      if (v === 'all')
        return {
          name: 'All users & teams',
          avatar: null,
        };
      else {
        const user = userList?.find((user) => user.id === v);
        const team = teams?.find((user) => user.id === v);

        if (user) {
          return {
            name: user?.firstName
              ? `${user?.firstName} ${user?.lastName}`.trim()
              : user?.emailId || user?.phoneNo,
            avatar: <UserAvatar userId={user?.id} width={18} height={18} />,
          };
        }

        if (team) {
          return {
            name: team?.name,
            avatar: <TeamAvatar width={18} height={18} teamId={team?.id} />,
          };
        }
      }
    });

    names = names?.filter((item) => item);

    return {
      names,
      hasMore: null,
    };
  }, [selected, renderChips]);

  return (
    <CustomDropdown
      closeAction={onDropdownClose}
      buttonRenderer={
        renderChips ? (
          <SelectedUsersBox onClick={handleDropDownOpen}>
            {placeholder &&
              (selected.length === 0 || chips?.names?.length === 0) && (
                <Typography color={colors.grey600} pl="12px" fontSize="14px">
                  {placeholder}
                </Typography>
              )}
            <Stack
              maxWidth={'85%'}
              direction={'row'}
              gap={'4px'}
              alignItems={'center'}
              overflow={'hidden'}
              ref={chipsRef}
            >
              {chips?.names?.map((c, index) => (
                <Chip key={`${c}-${index}`}>
                  {c?.avatar}
                  {c?.name}
                </Chip>
              ))}
            </Stack>
            {hasMore > 0 && <AddedUsers>{`+${hasMore}`}</AddedUsers>}
            <ExpandMoreRoundedIcon className="expand-icon" />
          </SelectedUsersBox>
        ) : (
          <span onClick={handleDropDownOpen}>{buttonRenderer}</span>
        )
      }
      popperProps={{
        content: <ListItems />,
        placement: 'bottom-start',
        open: open,
        modifiers: {
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport',
          },
        },
        anchorEl: anchorEl,
        style: {
          zIndex: 1301,
          width: 350,
        },
        ...popperProps,
      }}
    />
  );
}

export default MultipleUserDropdown;
