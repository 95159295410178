import styled from '@mui/system/styled';

export const FoldersCnt = styled('div')({
  '.foldersViewHeading': {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    margin: '16px 0',
  },
});

export const EmptyCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});
