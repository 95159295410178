import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import { BreadCrumbsCnt } from 'pages/documents/foldersDetailView/BreadCrumbs/breadCrumbs.style';

import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import { DocumentsContext } from 'pages/documents/context/context';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function BreadCrumbs() {
  const { state = {}, updateState } = useContext(DocumentsContext);
  const { selectedFolder } = state;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const backToFolderHome = () => {
    updateState({ loading: true, selectedFolder: null });
    navigateWithWorkspaceUrl('/documents/my-documents');
  };
  const handleClickFolderLink = (id: string) => {
    //Filter all paths whose index is less than the index of object with the id passed above
    const folderIndex = selectedFolder?.path?.findIndex((f) => f.id === id);
    const path = selectedFolder?.path?.filter(
      (f, index) => index <= folderIndex,
    );
    const urlToNavigate =
      '/documents/my-documents/folder/' + path.map((f) => f.id).join('/');
    navigateWithWorkspaceUrl(urlToNavigate);
  };
  return (
    <BreadCrumbsCnt>
      <Typography
        variant={'h1'}
        className={'heading'}
        onClick={backToFolderHome}
      >
        Folders
      </Typography>

      {selectedFolder?.path?.map((f, index) => {
        return (
          <div
            key={index}
            className={'folderNameCnt'}
            onClick={() => handleClickFolderLink(f.id)}
          >
            <ArrowRightIcon className={'arrowIcon'} />
            <TemplateFolderIcon
              primaryColor={f?.meta?.primaryColor}
              secondaryColor={f?.meta?.secondaryColor}
            />
            <Typography variant={'h1'} className={'folderName'}>
              {f?.title}
            </Typography>
          </div>
        );
      })}
      <div className={'folderNameCnt'}>
        <ArrowRightIcon className={'arrowIcon'} />
        <TemplateFolderIcon
          primaryColor={selectedFolder?.meta?.primaryColor}
          secondaryColor={selectedFolder?.meta?.secondaryColor}
        />
        <Typography variant={'h1'} className={'folderName'}>
          {selectedFolder?.title}
        </Typography>
      </div>
    </BreadCrumbsCnt>
  );
}

export default BreadCrumbs;
