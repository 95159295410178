import { useDispatch, useSelector } from 'react-redux';
import FlaggedCategoriesTable from './table';
import selectors from 'store/selectors';
import { useEffect, useState } from 'react';
import useFlaggedCategoriesConfig from 'utils/CustomHooks/useFlaggedCategoriesConfig';
import { setDialog } from 'store/actions/dialogActions';
import { Stack, Typography } from '@mui/material';
import { Banner, MainCnt, Title } from './style';

import FlagCategoriesIcon from 'public/assets/img/flag-categories-icon.svg';
import CrossIcon from 'components/Icons/crossIcon';
import { useUserConfig } from 'controller/useUserConfig';

// Template Configurations
const FlaggedCategories = () => {
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const [generalConfigKey, setGeneralConfigKey] = useState<any>(null);
  const [hideMainBanner, setHideMainBanner] = useState<any>(true);
  const categories = useSelector(selectors.getFlaggedItemsCategories);
  const {
    getFlaggedCategories,
    addFlaggedCategory,
    updateFlaggedCategory,
    removeFlaggedCategory,
  } = useFlaggedCategoriesConfig();

  const dispatch = useDispatch();

  useEffect(() => {
    getFlaggedCategories();
  }, []);

  const getGeneralConfig = async () => {
    const userConfig = await getUserConfig(generalConfigKey);
    setHideMainBanner(userConfig?.hideFlagCategoriesBanner);
  };

  useEffect(() => {
    if (userId) {
      setGeneralConfigKey(`FlagCategories::${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (generalConfigKey) {
      getGeneralConfig();
    }
  }, [generalConfigKey]);

  const DeleteFlagCategoryTemplate = (name) => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        {`Are you sure you want to delete the `}
        <span style={{ fontWeight: 700 }}>{name}</span>
        {` category everywhere in this workspace?`}
      </Typography>
    );
  };

  // Flagged Items Categories Handlers

  const addHandler = (data) => {
    addFlaggedCategory(data);
  };

  const updateHandler = (data) => {
    updateFlaggedCategory(data);
  };

  const removeHandler = (data) => {
    dispatch(
      setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Delete Flag Category',
          ContentTemplate: () => DeleteFlagCategoryTemplate(data?.name),
          onConfirmCallback: () => {
            removeFlaggedCategory(data?.id);
          },
          confirmButtonProps: { color: 'error' },
          confirmationText: 'Delete Category',
        },
      }),
    );
  };

  const closeBanner = async () => {
    const config = await editUserConfig(generalConfigKey, {
      hideFlagCategoriesBanner: true,
    });

    if (config) {
      setHideMainBanner(true);
    }
  };

  return (
    <MainCnt>
      <Title>Flag Categories</Title>
      {!hideMainBanner && (
        <Banner>
          <Stack direction="row" alignItems="center" gap="10px">
            <div className="crossCnt" onClick={closeBanner}>
              <CrossIcon className="icon" />
            </div>
            <img src={FlagCategoriesIcon} />{' '}
            <Stack direction="column" gap="5px">
              <Typography className="title">Manage Flag Categories</Typography>
              <Typography className="statement">
                You can create, edit and delete Flag Categories from this
                section. Assign a category to each flagged response to gain
                detailed insights into recurring issues, enabling targeted
                improvements and strategic planning.
              </Typography>
            </Stack>
          </Stack>
        </Banner>
      )}

      <FlaggedCategoriesTable
        categoriesList={categories?.list ?? []}
        hideMainBanner={hideMainBanner}
        isLoading={categories?.loading}
        actionsProps={{
          addHandler,
          updateHandler,
          removeHandler,
        }}
      />
    </MainCnt>
  );
};

export default FlaggedCategories;
