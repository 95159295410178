import { useContext, useEffect, useMemo } from 'react';
import { DocumentsContext } from 'pages/documents/context/context';
import GridView from 'pages/documents/listing/gridView';
import ListView from 'pages/documents/listing/listView';
import { EmptyCnt } from 'pages/documents/listing/style';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';
import useResponsive from 'utils/CustomHooks/useResponsive';

function Listing() {
  const { isMobileDeviceSm } = useResponsive();
  const { state } = useContext(DocumentsContext);
  const { selectedView, searchTerm, data } = state;

  const folders = useMemo(() => {
    if (!searchTerm) {
      return data?.folders;
    }
    return data?.folders?.filter((f) => {
      return f.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [searchTerm, data?.folders]);

  const documents = useMemo(() => {
    if (!searchTerm) {
      return data?.documents;
    }
    return data?.documents?.filter((f) => {
      return f.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [searchTerm, data?.documents]);

  return (
    <>
      {selectedView === 'grid' &&
      searchTerm &&
      folders?.length === 0 &&
      documents?.length === 0 ? (
        <EmptyCnt>
          <NoRecordsFound />
        </EmptyCnt>
      ) : isMobileDeviceSm || selectedView === 'grid' ? (
        <GridView folders={folders} documents={documents} />
      ) : (
        <ListView folders={folders} documents={documents} />
      )}
    </>
  );
}

export default Listing;
