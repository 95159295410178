// MUI components
import { styled } from '@mui/material/styles';
import { Box, Button, Checkbox } from '@mui/material';

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 16,
  background: 'white',
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  padding: '8px 16px',
  alignItems: 'center',
  '& .heading': {
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '0.15px',
    color: '#212121',
  },
}));

export const SearchAndShowAll = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: '8px 16px',
  borderTop: '1px solid #F5F5F5',
  borderBottom: '1px solid #F5F5F5',
  '& .showAll': {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 28px',
    gap: 4,
    '& .showAllText': {
      fontSize: '14px',
      fontWeight: 600,
      color: 'rgba(66, 66, 66, 1)',
    },
  },
}));

export const AllCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  color: 'rgba(158, 158, 158, 1)',
}));

export const List = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  borderBottom: '1px solid #F5F5F5',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    height: '200px',
    overflow: 'auto',
  },
}));

export const ListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }) => ({
  display: 'flex',
  padding: '8px 2px',
  gap: 2,
  alignItems: 'center',
  background: active ? 'rgba(235, 234, 255, 1)' : 'transparent',
  borderRadius: 6,
  '& .icon': {
    color: active ? 'rgba(104, 104, 254, 1)' : 'rgba(158, 158, 158, 1)',
  },
  '& .columnNameWrapper': {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    '& .columnNameText': {
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.15px',
      color: active ? 'rgba(104, 104, 254, 1)' : '#000',
    },
  },
  left: 'auto !important',
  top: 'auto !important',
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '8px 16px',
}));
