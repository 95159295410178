import styled from '@mui/system/styled';

export const BoxContainer = styled('div')({
  borderRadius: 12,
  border: '1px solid #EEEEEE',
  gap: '10px',
  height: 182,
  cursor: 'pointer',
  '&:hover': {
    border: '1px solid #6868FE',
  },

  '& .imgContainer': {
    height: 100,
    backgroundColor: '#ECEFF1',
    borderTopRightRadius: 'inherit',
    borderTopLeftRadius: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  '& .contentContainer': {
    padding: 14,
    '& .title': {
      fontSize: 16,
      lineHeight: '21.86px',
      fontWeight: '700',
      width: '90%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      textAlign: 'left',
      marginBottom: 0,
    },

    '& .titleContainer': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      '& .colorIcon': {
        marginRight: 7,
      },
      '& .childIcon': {
        height: 17,
        marginRight: 3,
        color: '#757575',
      },
    },
  },
});

export const SubContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& .subAssetsCount': {
    padding: '4px 8px 4px 8px',
    gap: '5px',
    border: '1px solid #EEEEEE',
    borderRadius: '60px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',

    '& .childIcon': {
      color: '#757575',
      height: 14,
    },

    '& .count': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16.39px',
      letterSpacing: '0.37px',
      color: '#212121',
    },
  },
});

export const LocationContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'addLocation',
})(({ addLocation }) => ({
  padding: '4px 8px 4px 8px',
  gap: '5px',
  border: '1px solid #EEEEEE',
  borderRadius: '60px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: 200,
  textOverflow: 'ellipsis',

  '&:hover': {
    backgroundColor: addLocation ? '#F5F5FF' : 'unset',
  },

  '& .icon': {
    height: 16,
    color: '#757575',
  },

  '& .name': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16.39px',
    letterSpacing: '0.37px',
    color: '#212121',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
