import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { CreateTaskContext } from './context/context';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import TaskTemplateIcon from 'components/Icons/taskTemplateIcon';
import SaveIcon from 'components/Icons/saveIcon';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';

const templateActionDropdownOptions = [
  {
    label: 'Use Template',
    id: 'use_template',
    icon: (
      <TaskTemplateIcon
        sx={{
          fill: 'none',
          height: 21,
          width: 21,
        }}
      />
    ),
    iconAlign: 'start',
  },
  {
    label: 'Save as Template',
    id: 'save_as_template',
    icon: <SaveIcon style={{ fill: 'none', height: 18, width: 18 }} />,
    iconAlign: 'start',
  },
];

const TaskTemplateDropdown = (props) => {
  const { getStartDate, getDueDate, returnAllSchedule, taskTemplate } = props;
  const dispatch = useDispatch();
  const { addNewAssignees } = useAssigneesUtils();
  const { newTask, attachments, errors, setErrors } =
    useContext(CreateTaskContext);

  const transformTemplateToTask = (template) => {
    const {
      title,
      priority,
      LocationId,
      ChecklistId,
      description,
      AssetId,
      attachment,
      assignees,
      ServiceTypeId,
      notification,
    } = template;

    const taskInputs = {
      ...(title && { title }),
      ...(priority && { priority }),
      ...(LocationId && { locationIds: [LocationId] }),
      ...(description && { description }),
      ...(AssetId && { AssetId }),
      ...(ChecklistId && { ChecklistId }),
      ...(ServiceTypeId && {
        ServiceId: ServiceTypeId,
        serviceTypeId: ServiceTypeId,
      }),
      ...(assignees?.length > 0 && {
        assignees,
        TaskAssignees: addNewAssignees(assignees),
      }),
      ...(attachment && { attachment }),
      ...(notification && { notification }),
    };

    return taskInputs;
  };

  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'use_template':
        dispatch(
          setDialog({
            dialogId: DIALOGS?.ATTACH_TASK_TEMPLATE_MODAL,
            open: true,
            data: {
              dialogState: {
                hideWhenTaskCreate: true,
              },
              onConfirm: (data) => {
                const transformedData = transformTemplateToTask(data?.template);
                dispatch(
                  setDialog({
                    open: true,
                    dialogId: 'createEditTask',
                    data: {
                      returnAllSchedule,
                      dialogState: {
                        viewLess: false,
                        taskTemplate: {
                          enable: true,
                          id: data?.taskTemplateId,
                        },
                      },
                      taskData: transformedData,
                    },
                  }),
                );
              },
            },
          }),
        );

        dispatch(
          setDialog({
            open: false,
            dialogId: 'createEditTask',
          }),
        );

        break;
      case 'save_as_template':
        if (!newTask.title) {
          setErrors({
            ...errors,
            title: VALIDATION_ERROR_MESSAGES.TASK_TITLE_REQUIRED,
          });
          return;
        }
        dispatch(
          setDialog({
            open: true,
            dialogId: DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG,
            data: {
              taskTemplate: {
                metaData: {
                  ...newTask,
                  startTime: getStartDate(),
                  dueTime: getDueDate(),
                  ...(attachments?.length > 0 && {
                    attachments,
                  }),
                },
              },
              goBackCustomHandler: (data) => {
                dispatch(
                  setDialog({
                    open: true,
                    dialogId: 'createEditTask',
                    data: {
                      returnAllSchedule,
                      dialogState: {
                        viewLess: false,
                        taskTemplate: {
                          isPrefill: true,
                          enable: true,
                          id: taskTemplate?.id,
                        },
                      },
                      taskData: data,
                    },
                  }),
                );
              },
            },
          }),
        );

        dispatch(
          setDialog({
            open: false,
            dialogId: 'createEditTask',
          }),
        );
        break;
    }
  };

  return (
    <ActionDropdown
      options={templateActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      iconButton={false}
      buttonProps={{
        startIcon: (
          <TaskTemplateIcon
            sx={{
              '& path': {
                stroke: '#6868FE',
              },
            }}
          />
        ),
        variant: 'outlined',
        style: {
          padding: '4px 10px 4px 14px',
          height: 36,
          fontWeight: 500,
          borderRadius: 5,
          fontSize: 14,
          lineHeight: '24px',
          letterSpacing: '0.4px',
        },
      }}
      popperProps={{
        placement: 'bottom-start',
        sx: {
          zIndex: 1301,
        },
      }}
    >
      Templates
    </ActionDropdown>
  );
};

export default TaskTemplateDropdown;
