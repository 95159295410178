// React
import { useState, useMemo, useEffect } from 'react';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import AdvancedFilterBuilder from 'components/TemplateDashboard/SubmissionListWithFilters/AdvancedFilterBuilder';
import CustomButton from 'components/Button/CustomButton';

// Icons
import FiltersIcon from 'components/Icons/filtersIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import { getChecklist } from 'api/checklistV2Api';

const SubmissionsFiltersDropdown = ({
  handleSetAdvancedFilters,
  handleSetItemsAdvancedFilters,
  advancedFilters,
  additionalFilters,
  handleClearFilter,
  hideFilters,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [templateItems, setTemplateItems] = useState<any>(null);

  const handleCallback = (elem: any) => {
    setIsActive(!!elem);
    setAnchorEl(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const handleSetFiltersCb = (filters: any[]) => {
    handleSetAdvancedFilters({
      condition: filters[0].conditional.toUpperCase(),
      filters,
    });
  };

  const handleSetItemFiltersCb = (filters: any[], empty = false) => {
    handleSetItemsAdvancedFilters(
      {
        filters,
      },
      empty,
    );
  };

  const deleteAllFilters = (e) => {
    e.stopPropagation();
    handleClearFilter();
  };

  const hasFilters = useMemo(() => {
    return advancedFilters && advancedFilters?.length > 0;
  }, [advancedFilters]);

  const handleClickFilterDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getTemplateInformation = async (id) => {
    const response = await getChecklist(id);
    setTemplateItems({
      id: response?.data?.id,
      items: response?.data?.ChecklistItems ?? [],
    });
  };

  useEffect(() => {
    const templateFilterFound = advancedFilters?.find(
      (filter) =>
        filter?.filterName === 'template' && filter?.value?.length === 1,
    );

    if (templateFilterFound) {
      if (
        templateFilterFound?.value?.[0] !== templateItems?.id &&
        templateFilterFound?.comparator === 'is'
      ) {
        getTemplateInformation(templateFilterFound?.value?.[0]);
        return;
      }

      if (templateFilterFound?.comparator === 'isNot') {
        setTemplateItems(null);
      }
    } else {
      setTemplateItems(null);
    }
  }, [advancedFilters]);

  return (
    <CustomDropdown
      popperProps={{
        content: (
          <AdvancedFilterBuilder
            handleSetFiltersCb={handleSetFiltersCb}
            handleSetItemFiltersCb={handleSetItemFiltersCb}
            handleClose={handleClose}
            filters={advancedFilters?.length ? advancedFilters : null}
            submissionFilters={
              additionalFilters?.submissionFilters?.filters?.length
                ? additionalFilters?.submissionFilters?.filters
                : null
            }
            templateItems={templateItems?.items}
            hideFilters={hideFilters}
          />
        ),
        placement: 'bottom-start',
        open: Boolean(anchorEl),
        anchorEl,
      }}
      paperProps={{
        sx: {
          padding: '20px 24px',
        },
      }}
      handleCallback={handleCallback}
      onClickawayCallback={() => handleCallback(null)}
      iconButton={false}
      buttonRenderer={
        <CustomButton
          onClick={handleClickFilterDropdown}
          variant="outlined"
          startIcon={<FiltersIcon />}
          endIcon={<CancelIcon onClick={deleteAllFilters} />}
          sx={{
            color: 'rgba(97, 97, 97, 1)',
            border: '1px solid rgba(224, 224, 224, 1)',
            height: '40px',
            borderRadius: '8px',
            background:
              isActive || hasFilters ? 'rgba(104, 104, 254, 0.12)' : '',
            '&:hover': {
              border: '1px solid rgba(224, 224, 224, 1)',
              background: 'rgba(104, 104, 254, 0.12)',
            },
            ...(isActive && {
              '& svg': {
                color: 'rgba(104, 104, 254, 1)',
              },
            }),
            ...((isActive || hasFilters) && {
              '& .MuiButton-endIcon svg': {
                color: '#EF5350',
              },
              '& svg': {
                color: 'rgba(104, 104, 254, 1)',
              },
            }),
            '& .MuiButton-endIcon': {
              position: 'absolute',
              zIndex: 50,
              right: '-2px',
              top: '-6px',
              color: '#EF5350',
              background: 'white',
              borderRadius: '20px',
              '& svg': {
                fontSize: '16px !important',
              },
              ...(!hasFilters && { display: 'none' }),
            },
          }}
        >
          Filters
        </CustomButton>
      }
    />
  );
};

export default SubmissionsFiltersDropdown;
