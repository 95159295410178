/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { ArchiveChatListRenderProps } from 'models/index.m';
import { useProfileChannel } from 'controller/useProfileChannel';
import UserPlaceholderIcon from 'public/assets/img/Image.png';

export const ArchiveChatListRender: FC<ArchiveChatListRenderProps> = ({
  index,
  singleData,
  handleSetChannel,
  setIsArchiveShow,
}: ArchiveChatListRenderProps) => {
  const { memberObj }: any = useProfileChannel(singleData);
  return (
    <>
      <div
        key={index}
        className={`border-0`}
        onClick={() => {
          handleSetChannel(singleData);
          setIsArchiveShow(false);
        }}
      >
        <a href={void 0} className="link ">
          {' '}
          <div>
            {singleData?.data?.name ? (
              <img src={UserPlaceholderIcon} className={'userIcon'} />
            ) : (
              <img src={memberObj.profileImage} width="20" height="20" />
            )}

            {singleData?.data?.name ? (
              <h5 style={{ marginLeft: 10 }}>{singleData?.data?.name}</h5>
            ) : (
              <>
                <h5 style={{ marginLeft: 10 }}>{memberObj.name}</h5>
              </>
            )}
            <span className="archiveChatMember">
              {singleData?.data?.member_count} members
            </span>
          </div>
        </a>
      </div>
    </>
  );
};
