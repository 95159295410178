import KioskLogIcon from 'public/assets/img/kiosk-log.svg';
import { MainCnt, Statement, Title } from './style';

const KioskLogEmpty = () => {
  return (
    <MainCnt>
      <img src={KioskLogIcon} />
      <Title>App Activity Log</Title>

      <Statement>
        No activities found for the applied filters or search criteria. Logs of
        user interactions will appear here when available.
      </Statement>
    </MainCnt>
  );
};

export default KioskLogEmpty;
