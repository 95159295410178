import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useSelector } from 'react-redux';
import { getAuthorizedLocations } from 'store/selectors/locations';
import { Stack, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import LocationItemsContainer from 'components/Dropdown/LocationsSelectItemsList/LocationItemsContainer';

function LocationList(props) {
  const { setSelectedOptions, selectedOptions, onChangeCallback } = props;
  const locationsList = useSelector(getAuthorizedLocations);

  return (
    <LocationItemsContainer
      data={locationsList}
      selected={selectedOptions || []}
      containerProps={{
        style: {
          padding: '0px 10px',
        },
      }}
      contentProps={{
        isSearch: true,
        isSelectAll: true,
        isMulti: true,
        style: {
          maxHeight: 300,
          overflow: 'auto',
        },
      }}
      changeHandler={(_, values) => {
        setSelectedOptions(values);
        onChangeCallback?.(values);
      }}
    />
  );
}

export default function LocationsFilter(props) {
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const { BtnRenderer, onChangeCallback, selected } = props;

  React.useEffect(() => {
    setSelectedOptions(selected);
  }, [selected]);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => {
    setOpen(open);
  };
  return (
    <>
      <Box onClick={() => toggleDrawer(true)}>{BtnRenderer}</Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding="20px 20px 5px 25px"
        >
          <Typography
            color="#252350"
            fontWeight="600"
            fontSize="18px"
            letterSpacing="0.15px"
            lineHeight="28.8px"
          >
            Select Location
          </Typography>
          <CustomButton
            color="primary"
            variant="text"
            onClick={() => toggleDrawer(false)}
            style={{
              fontWeight: 600,
              fontSize: 16,
              lineHeight: '28px',
              letterSpacing: '0.15px',
            }}
          >
            Done
          </CustomButton>
        </Stack>
        <LocationList
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          onChangeCallback={onChangeCallback}
        />
      </Drawer>
    </>
  );
}
