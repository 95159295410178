import { createSvgIcon } from '@mui/material';

const GeoStampIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M12.4999 9.99998C12.4999 11.3807 11.3806 12.5 9.99992 12.5C8.61921 12.5 7.49992 11.3807 7.49992 9.99998C7.49992 8.61927 8.61921 7.49998 9.99992 7.49998C11.3806 7.49998 12.4999 8.61927 12.4999 9.99998Z"
        fill="#8D6E63"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99992 0.833313C10.4602 0.833313 10.8333 1.20641 10.8333 1.66665V2.54575C14.3096 2.93004 17.0699 5.6903 17.4541 9.16665H18.3333C18.7935 9.16665 19.1666 9.53974 19.1666 9.99998C19.1666 10.4602 18.7935 10.8333 18.3333 10.8333H17.4541C17.0699 14.3097 14.3096 17.0699 10.8333 17.4542V18.3333C10.8333 18.7935 10.4602 19.1666 9.99992 19.1666C9.53968 19.1666 9.16658 18.7935 9.16658 18.3333V17.4542C5.69024 17.0699 2.92998 14.3097 2.54569 10.8333H1.66659C1.20635 10.8333 0.833252 10.4602 0.833252 9.99998C0.833252 9.53974 1.20635 9.16665 1.66659 9.16665H2.54569C2.92998 5.6903 5.69024 2.93004 9.16658 2.54575V1.66665C9.16658 1.20641 9.53968 0.833313 9.99992 0.833313ZM10.8333 15.7742V15C10.8333 14.5397 10.4602 14.1666 9.99992 14.1666C9.53968 14.1666 9.16658 14.5397 9.16658 15V15.7742C6.61184 15.4088 4.59109 13.3881 4.22566 10.8333H4.99992C5.46016 10.8333 5.83325 10.4602 5.83325 9.99998C5.83325 9.53974 5.46016 9.16665 4.99992 9.16665H4.22566C4.59109 6.6119 6.61184 4.59115 9.16658 4.22572V4.99998C9.16658 5.46022 9.53968 5.83331 9.99992 5.83331C10.4602 5.83331 10.8333 5.46022 10.8333 4.99998V4.22572C13.388 4.59115 15.4087 6.6119 15.7742 9.16665H14.9999C14.5397 9.16665 14.1666 9.53974 14.1666 9.99998C14.1666 10.4602 14.5397 10.8333 14.9999 10.8333H15.7742C15.4087 13.3881 13.388 15.4088 10.8333 15.7742Z"
        fill="#8D6E63"
      />
    </>,
    'GeoStampIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default GeoStampIcon;
