import { FC } from 'react';
import { Box, Typography } from '@mui/material';
// import { ReactComponent as ProcedureIcon } from 'public/assets/img/procedure-icon.svg';
// import { ReactComponent as PassFailIcon } from 'public/assets/img/pass-fail-icon.svg';
// import { ReactComponent as TakePhotoIcon } from 'public/assets/img/take-photo-icon.svg';
// import SignatureIcon from 'components/Icons/signatureIcon';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
// import GeoStampIcon from 'components/Icons/geoStampIcon';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NumbersIcon from '@mui/icons-material/Numbers';
// import CostIcon from 'components/Icons/costIcon';
// import TemperatureIcon from 'components/Icons/temperatureIcon';
import CalendarIcon from 'components/Icons/calendarIcon';
import { StepTypesGrid, StepTypesItem } from './styled';
import Stack from '@mui/material/Stack';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { FEATURES } from 'utils/CustomHooks/useCustomPermission';

import ProcedureIcon from 'components/Icons/templateStepIcons2/procedureIcon';
import PassFailIcon from 'components/Icons/templateStepIcons2/passFailIcon';
import TakePhotoIcon from 'components/Icons/templateStepIcons2/takePhotoIcon';
import NumberIcon from 'components/Icons/templateStepIcons2/numberIcon';
import TemperatureIcon from 'components/Icons/templateStepIcons2/temperatureIcon';
import CostIcon from 'components/Icons/templateStepIcons2/costIcon';
import McqIcon from 'components/Icons/templateStepIcons2/mcqIcon';
import SignatureIcon from 'components/Icons/templateStepIcons2/signatureIcon';
import DateTimeIcon from 'components/Icons/templateStepIcons2/dateTimeIcon';
import LongDescriptionIcon from 'components/Icons/templateStepIcons2/longDescriptionIcon';
import GeoStampIcon from 'components/Icons/templateStepIcons2/geoStampIcon';
import DropdownIcon from 'components/Icons/templateStepIcons2/dropdownIcon';
import LocationIcon from 'components/Icons/templateStepIcons2/locationIcon';
import TextFieldIcon from 'components/Icons/templateStepIcons2/textFieldIcon';
import PhotoAnnotateIcon from 'components/Icons/templateStepIcons2/photoAnnotateIcon';

const stepTypes = [
  {
    name: 'Checkbox',
    icon: <ProcedureIcon />,
    description:
      'Short step-by-step punch list items, great for to-do lists and procedures.',
    color: 'rgba(232, 245, 233, 1)',
    borderColor: '#C8E6C9',
    type: 'procedure',
  },
  {
    name: 'Pass/Fail',
    icon: <PassFailIcon />,
    description: 'Pass or fail items to facilitate inspections & audits.',
    color: 'rgba(225, 245, 254, 1)',
    borderColor: '#B3E5FC',
    type: 'passFail',
  },
  {
    name: 'Take Photo',
    icon: <TakePhotoIcon />,
    description: 'Capture an image for a step.',
    color: 'rgba(255, 249, 196, 1)',
    borderColor: '#FFE0B2',
    type: 'takePhoto',
  },
  {
    name: 'Text Answer',
    icon: <TextFieldIcon />,
    description: 'Prompt team members to respond to a step with text.',
    color: 'rgba(236, 239, 241, 1)',
    borderColor: '#CFD8DC',
    type: 'textField',
  },
  {
    name: 'Number Answer',
    icon: <NumberIcon />,
    description:
      'Prompt team members to respond to a step with a number value and units.',
    color: 'rgba(232, 234, 246, 1)',
    borderColor: '#D1C4E9',
    type: 'number',
  },
  {
    name: 'Temperature',
    icon: <TemperatureIcon width={24} height={24} />,
    description:
      'Prompt team members to respond to a step with a temperature (°F, °C, or K).',
    color: 'rgba(252, 228, 236, 1)',
    borderColor: '#F8BBD0',
    type: 'temperature',
  },
  {
    name: 'Cost',
    icon: <CostIcon width={24} height={24} />,
    description:
      'Prompt team members to respond to a step with amount ($USD, €Euro).',
    color: 'rgba(255, 243, 224, 1)',
    borderColor: '#FDD835',
    type: 'cost',
  },
  {
    name: 'Multiple Choice',
    icon: <McqIcon />,
    description:
      'Prompt team members to respond to a multiple choice question.',
    color: 'rgba(224, 247, 250, 1)',
    borderColor: '#B2EBF2',
    type: 'multipleChoice',
  },
  {
    name: 'Signature',
    icon: <SignatureIcon />,
    description: 'Prompt team members to respond to a step with a signature.',
    color: 'rgba(254, 235, 238, 1)',
    borderColor: '#FECDD2',
    type: 'signature',
  },
  {
    name: 'Date/Time',
    icon: <DateTimeIcon />,
    description: 'Prompt team members to respond to a step with a Date/Time.',
    color: 'rgba(240, 244, 195, 1)',
    borderColor: '#F0F4C3',
    type: 'dateTime',
  },
  {
    name: 'Long Description / Instruction',
    icon: <LongDescriptionIcon />,
    description:
      'Create descriptions or instructions for team members (+ embed media files).',
    color: 'rgba(251, 233, 231, 1)',
    borderColor: '#FFAB91',
    type: 'instruction',
  },
  {
    name: 'Dropdown',
    icon: <DropdownIcon />,
    description: 'Prompt team members to select from a menu of options.',
    color: 'rgba(241, 248, 233, 1)',
    borderColor: '#b0bec5',
    type: 'dropdown',
  },
  {
    name: 'Geo Stamp',
    icon: <GeoStampIcon />,
    description: 'Prompt team members to provide their geolocation.',
    color: 'rgba(239, 235, 233, 1)',
    borderColor: '#BCAAA4',
    type: 'geoStamp',
    feature: FEATURES.GEOSTAMP_STEP,
  },
  {
    name: 'Location',
    icon: <LocationIcon />,
    description:
      'Prompt team members to add a Location from a list of options.',
    color: 'rgba(224, 242, 241, 1)',
    borderColor: '#B2DFDB',
    type: 'location',
  },
  {
    name: 'Photo Annotate',
    icon: <PhotoAnnotateIcon />,
    description:
      'Allow team members to add annotations to your photo or drawing.',
    color: 'rgba(243, 229, 245, 1)',
    borderColor: '#B2DFDB',
    type: 'photoAnnotate',
  },
];

const StepTypes: FC<any> = ({
  addChecklistItem,
  itemIndex,
  onDescriptionChange = undefined,
}) => {
  return (
    <StepTypesGrid>
      {stepTypes.map((stepType) => {
        return (
          <VerifyFeatureAccess featureId={stepType.feature}>
            <StepTypesItem
              key={stepType.type}
              onClick={() => {
                addChecklistItem(stepType.type, itemIndex);
                if (onDescriptionChange) {
                  onDescriptionChange?.cancel();
                }
              }}
            >
              <Stack direction="row" gap={'16px'}>
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  bgcolor={stepType.color}
                  borderRadius="8px"
                  p="4px"
                  alignSelf="flex-start"
                  color="#fff"
                >
                  {stepType.icon}
                </Stack>
                <Stack>
                  <Typography fontSize="14px" fontWeight={700} color="#000">
                    {stepType.name}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="rgba(97, 97, 97, 1)"
                  >
                    {stepType.description}
                  </Typography>
                </Stack>
              </Stack>
            </StepTypesItem>
          </VerifyFeatureAccess>
        );
      })}
    </StepTypesGrid>
  );
};

export default StepTypes;
