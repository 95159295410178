import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, Stack } from '@mui/material';
import xeniaApi from 'api/index';
import TaskAndWorkOrderTable from 'components/DataTables/TaskAndWorkOrderTable';
import { setDialog } from 'store/actions/dialogActions';
import TaskStatusCounts from './TaskStatusCounts/taskStatusCounts';
import { LoaderCnt, MainContainer } from './style';
import ProjectTasksFilters from './filters/filters';

import useSchedulesHeader from 'pages/Schedules/common/useHeader';
import { cloneDeep, sortBy } from 'lodash';
import { useTaskUtils } from 'utils/CustomHooks/useTaskUtils';
import ColumnSelectionDropdown from './hideShowColumns';
import FiltersDropdown from './filters/advancedFilters';
import { useUserConfig } from 'controller/useUserConfig';
import { useParams } from 'react-router-dom';
import useResponsive from 'utils/CustomHooks/useResponsive';
import ScheduleGridItem from './gridItem';
import EmptyResultsFilter from 'pages/Schedules/common/EmptyResultsFilter';

const ProjectTasks = (props) => {
  const { schedule } = props;
  const params = useParams();
  const { getChecklistDetails } = useTaskUtils();
  const [tableConfig, setTableConfig] = useState<any>(null);
  const [projectTasks, setProjectTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { handleSetAdvanceFiltersUpdated } = useSchedulesHeader();
  const { getUserConfig, editUserConfig, userId } = useUserConfig();
  const [advanceFilters, setAdvanceFilters] = useState<any>({});
  const [date, setDate] = useState({
    from: null,
    to: null,
  });
  const [payload, setPayload] = useState<any>({});
  const [taskStats, setTasksStats] = useState({});
  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();

  const getScheduleTasks = async (payload) => {
    const scheduleTasks = await xeniaApi.getTasksApi(payload);

    let tasksData = cloneDeep(scheduleTasks?.data?.rows);

    tasksData = tasksData?.map((task) => {
      if (task?.ChecklistId) {
        const Checklist = getChecklistDetails(task);
        return {
          ...task,
          Checklist,
        };
      }

      return task;
    });

    tasksData = sortBy(tasksData, (task) => new Date(task?.dueDate));

    setProjectTasks(tasksData);
    getSchedulesStats(tasksData);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const payload = {
      advanceFilters: {
        condition: 'AND',
        filters: [
          {
            comparator: 'is',
            filterName: 'schedules',
            conditional: 'and',
            value: [params?.scheduleId],
          },
          {
            comparator: 'is',
            filterName: 'createdDate',
            conditional: 'and',
            value: [null, null],
          },
        ],
      },
      offset: 0,
      limit: 9999999999,
      view: 'calendar',
      viewType: '',
    };
    setPayload(payload);
    getScheduleTasks(payload);
  }, []);

  const handleTaskClick = (taskId: string) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: { successCB: () => refetch(), taskId },
      }),
    );
  };

  const getSchedulesStats = async (data) => {
    const stats = {};

    data?.forEach(({ taskStatus }) => {
      if (stats[taskStatus] >= 0) {
        stats[taskStatus] = stats[taskStatus] + 1;
      } else {
        stats[taskStatus] = 1;
      }
    });

    setTasksStats(stats);
  };

  const updateDateRangeCB = (date) => {
    setDate(date);
  };

  const refetch = () => {
    const filters = {
      condition: 'AND',
      filters: [
        {
          comparator: 'is',
          filterName: 'schedules',
          conditional: 'and',
          value: [params?.scheduleId],
        },
        {
          comparator: 'is',
          filterName: 'createdDate',
          conditional: 'and',
          value: [date?.from, date?.to],
        },
      ],
    };

    if (advanceFilters?.filters?.length > 0) {
      advanceFilters.filters.forEach(({ value, filterName }) => {
        if (value?.length > 0) {
          filters.filters.push({
            comparator: 'is',
            filterName,
            conditional: 'and',
            value,
          });
        } else if (value?.from || value?.to) {
          filters.filters.push({
            comparator: 'is',
            filterName,
            conditional: 'and',
            value: [value?.from ?? null, value?.to ?? null],
          });
        }
      });
    }

    const payload = {
      advanceFilters: filters,
      offset: 0,
      limit: 9999999999,
      view: 'calendar',
      viewType: '',
    };

    setPayload(payload);
    getScheduleTasks(payload);
  };

  useEffect(() => {
    refetch();
  }, [advanceFilters, date]);

  const fetchTableConfig = async () => {
    const config = await getUserConfig(`schedulesTasksTableConfig:${userId}`);
    if (config) {
      const colConfig = config;
      setTableConfig(colConfig);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchTableConfig();
    }
  }, [userId]);

  const updateColumns = async (columnState: any[]) => {
    const previousTableConfig = tableConfig || [];
    setTableConfig(columnState);
    const updatedConfig = await editUserConfig(
      `schedulesTasksTableConfig:${userId}`,
      columnState,
    );
    if (!updatedConfig) {
      setTableConfig(previousTableConfig);
    }
  };

  return (
    <MainContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          ...(isMobileDeviceSm && {
            alignItems: 'baseline',
          }),
          overflow: 'hidden',
        }}
      >
        <ProjectTasksFilters
          updateDateRangeCB={updateDateRangeCB}
          filters={payload?.advanceFilters?.filters?.find?.(
            (item) => item?.filterName === 'createdDate',
          )}
        />
        <Stack direction="row" alignItems="center" gap="10px">
          {!isMobileDeviceSm && (
            <ColumnSelectionDropdown taskColumns={tableConfig ?? []} />
          )}
          <FiltersDropdown
            hideFilters={
              'status createdDate completedDate completedBy futureStartDate futureDueDate archived taskTemplates priority createdBy scheduleFrequency projects schedules taskTypes'
            }
            selectedFilters={advanceFilters}
            onChange={(filters) => {
              handleSetAdvanceFiltersUpdated(filters, setAdvanceFilters);
            }}
            handleClearFilters={() =>
              handleSetAdvanceFiltersUpdated(undefined, setAdvanceFilters)
            }
          />
        </Stack>
      </Stack>

      {loading ? (
        <LoaderCnt>
          <CircularProgress />
        </LoaderCnt>
      ) : (
        <>
          {!isMobileDeviceSm && <TaskStatusCounts taskStats={taskStats} />}

          {isMobileDeviceSm && !(projectTasks?.length > 0) && (
            <EmptyResultsFilter />
          )}

          {isMobileDeviceSm ? (
            <>
              {projectTasks?.map((task) => (
                <ScheduleGridItem
                  onClick={handleTaskClick}
                  hideLeftBar
                  task={task}
                />
              ))}
            </>
          ) : (
            <Box style={{ height: 'calc(100vh - 375px)' }}>
              <TaskAndWorkOrderTable
                height={'calc(100vh - 375px)'}
                onRowClick={handleTaskClick}
                tableId="schedulesTasksTable"
                rowData={projectTasks ?? []}
                updateColumnStateCb={updateColumns}
                columnState={tableConfig}
                columnConfig={{
                  asset: false,
                  title: false,
                  taskNumber: false,
                  actionCol: false,
                }}
              />
            </Box>
          )}
        </>
      )}
    </MainContainer>
  );
};

export default ProjectTasks;
