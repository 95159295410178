import { Button, Grid } from '@mui/material';
import { useAddNewMember } from 'controller/useAddMembers';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { JoinGroupModalStyled } from './JoinGroupModal.styled';
import CustomButton from 'components/Button/CustomButton';

const JoinGroupModal: FunctionComponent<any> = ({ title }) => {
  const dispatch = useDispatch();

  const getJoinedChannelData: any = useSelector(selectors.getJoinedChannelData);
  const currentUserId: any = useSelector(selectors.getUserId);

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { handleAddMemberSubmit } = useAddNewMember(
    getJoinedChannelData ? getJoinedChannelData.channelid : null,
  );

  return (
    <>
      <JoinGroupModalStyled>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid className="title" item>
            {title}
          </Grid>
        </Grid>
        <p className="description">
          For Access this channel you have to join this channel. Join Now?
        </p>
        <Grid
          mt={3}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CustomButton
            variant="text"
            size="large"
            onClick={() => {
              dispatch(actions.setJoinchannel(null));
              navigateWithWorkspaceUrl('/tasks/list');
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            sx={{ ml: 2 }}
            variant="contained"
            size="large"
            onClick={() => {
              handleAddMemberSubmit([currentUserId]);
              dispatch(actions.setJoinchannel(null));
            }}
          >
            Join Now
          </CustomButton>
        </Grid>
      </JoinGroupModalStyled>
    </>
  );
};

export default JoinGroupModal;
