// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const SubmissionTabWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 'calc(100vh - 155px)',
  flexWrap: 'wrap',
}));

export const SubmissionListWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3, 2, 0),
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  width: '458px',
  height: '100%',
  position: 'relative',
}));

export const SubmissionPreviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 0, 0, 3),
  height: '100%',
  overflow: 'auto',
  flex: 1,
}));
