/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MessageInput,
  useChannelActionContext,
  useMessageContext,
  useChannelStateContext,
  VirtualizedMessageList,
} from 'stream-chat-react';
import { logChatPromiseExecution } from 'stream-chat';
import selectors from 'store/selectors/index';
import actions from 'store/actions';

import { CustomMessageRender } from './customMessage';
import { ConstantChannelTypeString } from 'utils/globalVariable';
import { MessagesChannelInnerStyled } from './messagesChannelInner.styled';
import { useNotification } from 'components/NotificationV2/useNotification';

import '@stream-io/stream-chat-css/dist/css/index.css';

export const MessageChannelInner: FC<any> = ({ handleSetChannel }: any) => {
  const { setMarkAllAsRead, getNotificationCount } = useNotification();
  const { messages } = useChannelStateContext();
  const { sendMessage } = useChannelActionContext();
  const dispatch = useDispatch();
  const [userTypingEvent, setUserTypingEvent] = useState(null as any);
  const userProfileData = useSelector(selectors.getUserProfile);
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);
  const channelMessageScrollTo: any = useSelector(
    selectors.getScrollToChannelMessage,
  );
  const { GROUP, FEED_STREAM } = ConstantChannelTypeString;

  useEffect(() => {
    if (channelMessageScrollTo) {
      const { channel, message } = channelMessageScrollTo;
      if (channel.id === activeStreamChannel?.id && message?.id) {
        setTimeout(() => {
          const el = document.getElementById(`message_${message.id}`);
          el?.scrollIntoView();
          dispatch(actions.scrollToChannelMessage(null));
        }, 800);
      }
    }
  }, [channelMessageScrollTo]);

  const markNotificationsArRead = async () => {
    try {
      if (!activeStreamChannel?.id || !userProfileData?.id) return;
      const postData: any = {
        currentUserId: userProfileData?.id,
        ChannelId: activeStreamChannel.id,
      };
      await setMarkAllAsRead(postData);
    } catch (error) {
      // report error here
    }
  };

  const scrollToLatestMessages = () => {
    if (activeStreamChannel) {
      setTimeout(() => {
        const div: any =
          document.getElementsByClassName('messagesSection')?.[0];
        if (div) {
          div.scrollTop = div?.scrollHeight;
        }
      }, 500);
    }
  };

  const setTypingListener = () => {
    if (activeStreamChannel && activeStreamChannel.on) {
      activeStreamChannel.on('typing.start', (event) => {
        const idLoggedInUser =
          userProfileData?.id &&
          event?.user?.id &&
          userProfileData.id === event.user.id;
        if (idLoggedInUser) return;
        if (activeStreamChannel?.id === event.channel_id) {
          setUserTypingEvent(event);
        }
      });

      // stop typing event handling
      activeStreamChannel?.on('typing.stop', (event) => {
        setUserTypingEvent(null);
      });
    }
  };

  useEffect(() => {
    scrollToLatestMessages();
    setTypingListener();
    markNotificationsArRead();
  }, [activeStreamChannel?.id]);

  const overrideSubmitHandler = (message) => {
    if (!activeStreamChannel?.data.isArchived) {
      const updatedMessage = {
        attachments: message.attachments,
        mentioned_users: message.mentioned_users,
        parent_id: message.parent?.id,
        parent: message.parent,
        text: message.text,
      };
      if (sendMessage) {
        const sendMessagePromise = sendMessage(updatedMessage);
        logChatPromiseExecution(sendMessagePromise, 'send message');
      }
    }
    scrollToLatestMessages();
  };

  const CustomMessage = () => {
    const { message, readBy } = useMessageContext();
    return (
      <CustomMessageRender
        message={message ?? {}}
        readBy={readBy ?? []}
        handleSetChannel={handleSetChannel}
        isGroupChat={
          activeStreamChannel?.data.channelType === GROUP ||
          activeStreamChannel?.data.channelType === FEED_STREAM
        }
      />
    );
  };

  const renderTypingMessage = () => {
    if (!userTypingEvent) return '';
    const name = userTypingEvent?.user?.name || 'A user';
    const profileImage = userTypingEvent?.user?.profileImage || '';
    return (
      <div className="user-typing-message">
        {(profileImage && <img height={15} width={15} src={profileImage} />) ||
          ''}
        {` ${name} is typing... `}
      </div>
    );
  };

  return (
    <>
      <MessagesChannelInnerStyled>
        <div className="msgWrap">
          <div id="message-list" className="msgOuter w-100">
            <VirtualizedMessageList
              messages={messages}
              messageLimit={30}
              disableDateSeparator={false}
              Message={CustomMessage}
              additionalVirtuosoProps={{
                className: 'messagesSection',
                alignToBottom: true,
                followOutput: true,
                initialTopMostItemIndex: 0,
                topItemCount: 0,
              }}
            />
          </div>
        </div>

        <MessageInput
          overrideSubmitHandler={overrideSubmitHandler}
          disabled={activeStreamChannel?.data?.isArchived && true}
          noFiles={activeStreamChannel?.data?.isArchived && true}
          focus={true}
          grow={true}
          additionalTextareaProps={{
            placeholder: 'Type your message here...',
          }}
        />
        {renderTypingMessage()}
      </MessagesChannelInnerStyled>
    </>
  );
};
