import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const MainCnt = styled('div')({
  width: 600,
  marginTop: 80,
});

export const MainText = styled('p')({
  fontWeight: 600,
  fontSize: 20,
  color: colors.black,
  opacity: 0.87,
  margin: '30px 0px 10px 0px',
});

export const Details = styled('p')({
  fontWeight: 400,
  fontSize: 14,
  color: colors.black,
  opacity: 0.7,
});
