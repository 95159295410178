// MUI components
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const GroupWrapper = styled(Box)(({ theme }) => ({
  padding: '10px 16px',
  borderRadius: 8,
  background: 'rgba(245, 246, 251, 1)',
  display: 'flex',
  margin: '0px 16px',
  marginBottom: 8,
}));

export const LoadingWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 80,
}));

export const GroupHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: 'rgba(97, 97, 97, 1)',
  fontSize: 13,
  letterSpacing: 0.13,
}));

export const NotificationList = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

export const NotificationBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'unread',
})(({ unread }) => ({
  position: 'relative',
  display: 'flex',
  gap: 40,
  padding: '10px 24px 10px 26px',
  background: unread ? '#F5F4FF' : '#fff',
  borderBottom: '1px solid #EEE',
  cursor: 'pointer',
}));

export const AvatarAndNotificationWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 12,
}));

export const UnreadDot = styled(Box)(() => ({
  display: 'flex',
  width: 6,
  height: 6,
  borderRadius: 6,
  background: 'rgba(239, 83, 80, 1)',
  position: 'absolute',
  top: '50%',
  left: '-11px',
  transform: 'translate(-50%, -50%)',
}));

export const AvatarBox = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  height: 'fit-content',
}));

export const NotificationAndWorkspaceWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
}));

export const NotificationTextAndSeeMoreWrapper = styled(Box)(() => ({
  maxWidth: 505,
  minWidth: 505,
}));

export const NotificationText = styled('span')(() => ({
  fontWeight: 500,
  color: '#212121',
  fontSize: 14,
}));

export const SeeMore = styled('span')(() => ({
  fontWeight: 600,
  color: '#6868FE',
  fontSize: 13,
  cursor: 'pointer',
  marginLeft: '3px',
}));

export const WorkspaceAvatarAndNameWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 6,
  alignItems: 'center',
}));

export const WorkspaceText = styled(Typography)(() => ({
  fontWeight: 500,
  color: 'rgba(97, 97, 97, 1)',
  fontSize: 12,
  letterSpacing: 0.14,
}));

export const TimeText = styled(Typography)(() => ({
  fontWeight: 600,
  color: 'rgba(117, 117, 117, 1)',
  fontSize: 12,
  marginLeft: 'auto',
}));
